import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filterPipe'
})

export class FilterPipe implements PipeTransform {
  results: any[];
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;

    searchText = (searchText.toLowerCase()).replace(/ /g, "");

    // this.results = items.filter(it => {

    //   return (it.toLowerCase().replace(/ /g, "")).includes(searchText);
    // });

    // console.log(this.results);

    // return items.filter(it => {
    //   return (it.toLowerCase().replace(/ /g, "")).includes(searchText);
    // });

    return items.filter(function(data){
      return JSON.stringify(data).toLowerCase().replace(/ /g, "").includes(searchText);
  });
  }
}