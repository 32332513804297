import { Quote } from '../models/quote';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RfqService } from '../services/rfq.service';
import { RFQ } from '../models/rfq';
import { quoteState } from '../models/quoteState';
import { ReloadViewRfqs } from '../models/reloadViewRfqs';
import { ViewQuoteServiceService } from '../services/view-quote-service.service';
import { OpenQuotesComponent } from '../open-quotes/open-quotes.component';
import { InProgressQuotesComponent } from '../in-progress-quotes/in-progress-quotes.component';
import { CompletedQuotesComponent } from '../completed-quotes/completed-quotes.component';
import { ExpiredQuotesComponent } from '../expired-quotes/expired-quotes.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrNotificationService } from '../services/toastr-notification.service';

@Component({
  selector: 'app-view-quote',
  templateUrl: './view-quote.component.html',
  styleUrls: ['./view-quote.component.css']
})
export class ViewQuoteComponent implements OnInit {

  @ViewChild('tab3') goToTab3: ElementRef;

  @ViewChild('tab2') goToTab2: ElementRef;

  @ViewChild('tab4') goToTab4: ElementRef;

  @ViewChild('OpenTab') initiateOpenQuotes: OpenQuotesComponent;

  @ViewChild('IPTab') initiateIPQuotes: InProgressQuotesComponent;

  @ViewChild('ClosedTab') initiateClosedQuotes: CompletedQuotesComponent;

  @ViewChild('ExpiredTab') initiateExpiredQuotes: ExpiredQuotesComponent;

  quoteStatus: boolean;

  quoteState: string;
  rfqState: string;
  rfqPaymentRequirement: string;

  sentQID: number;

  normalQuoteLoad: ReloadViewRfqs;

  quotes: Quote[] = [];
  rfqs: RFQ[] = [];
  openQuotes: RFQ[] = [];
  inProgressQuotes: RFQ[] = [];
  closedQuotes: RFQ[] = [];
  openTab = "";
  inProgressTab = "";
  closedTab = "";
  expireTab = "";

  reloadOpenQuote: boolean = false;
  reloadInProgressQuotes: boolean = false;
  reloadClosedQuotes: boolean = false;
  reloadExpiredQuotes: boolean = false;



  // VARIABLES SENT FOR CHAT COMPONENT TO KNOW IF A BUYER OR SUPPLIER HAS OPENED THE CHAT
  Buyer: boolean = false;
  Supplier: boolean = true;

  // ROLE STRING USED FOR THE GET CHAT HISTORY METHOD
  Role: string;

  state: string;
  quoteId: number;
  openQuoteId: number = 0;
  inProgressQuoteId: number = 0;
  closedQuoteId: number = 0;
  expiredQuoteId: number = 0;
  closedQuoteArchived: string = "";;
  closedQuoteArchivedState: string = "";

  openQuoteNav: string = "";
  inProgressQuoteNav: string = "";
  closedQuoteNav: string = "";
  expiredQuoteNav: string = "";



  // NEEDED FOR CHAT OPEN STATUS
  openChat: boolean = false;

  constructor(
    private rfqService: RfqService, private reloader: ViewQuoteServiceService, private route: ActivatedRoute, private router: Router,
    private toastrNotification: ToastrNotificationService,) { }

  ngOnInit(): void {

    this.SetRoleStringValue();

    this.route.paramMap.subscribe(params => {

      this.state = params.get('state');
      this.quoteId = +params.get('quoteId');

      if (this.state == 'Open' || this.state == 'InProgress' || this.state == 'Closed' || this.state == 'Expired') {

        if (params.get('state') == 'Open') {

          this.openQuoteId = +params.get('quoteId');
          this.openQuoteNav = params.get('nav');
          this.triggerOpenQuoteReloading();

        } else if (params.get('state') == 'InProgress') {

          this.inProgressQuoteId = +params.get('quoteId');
          this.inProgressQuoteNav = params.get('nav');
          this.triggerInProgressQuoteReloading();

        } else if (params.get('state') == 'Closed') {

          this.closedQuoteId = +params.get('quoteId');
          this.closedQuoteNav = params.get('nav');
          this.triggerClosedQuoteReloading();

        } else if (params.get('state') == 'Expired') {

          this.expiredQuoteId = +params.get('quoteId');
          this.expiredQuoteNav = params.get('nav');
          this.triggerExpiredQuoteReloading();

        }

      } else {
       // this.toastrNotification.error("Invalid URL");
        this.router.navigate(['404']);
      }

    });

    this.route.queryParamMap.subscribe(params => {

      this.closedQuoteArchivedState = params.get('archived');

      // if (this.state == 'Closed' && (params.get('archived') != 'true' && params.get('archived') != 'false') ) {
      //   this.router.navigate(['404']);
      // } 
    });

  }

  setQuoteArchivedState(state) {
    this.closedQuoteArchived = state;
  }

  quoteStatusSend(status: boolean) {
    this.quoteStatus = status;
  }

  quoteStateSend(status: string) {
    this.quoteState = status;
  }

  rfqStateSend(status: string) {
    this.rfqState = status;
  }

  rfqPaymentRequirementSend(status: string) {
    this.rfqPaymentRequirement = status;
  }


  triggerOpenQuoteReloading() {

    this.reloadOpenQuote = true;
    this.reloadInProgressQuotes = false;
    this.reloadClosedQuotes = false;
    this.reloadExpiredQuotes = false;

    // this.initiateOpenQuotes.getOpenQuotes(0);

    this.openTab = "active";
    this.inProgressTab = "";
    this.closedTab = "";
    this.expireTab = "";

    //CLOSES PRIVATE CHAT WHEN SWITCHING TABS
    this.openChat = false;

    // SET QUOTE ARCHIVE STATUS BOOL TO FALSE FOR CHAT
    this.quoteStatus = false;
  }

  triggerInProgressQuoteReloading() {

    this.reloadOpenQuote = false;
    this.reloadInProgressQuotes = true;
    this.reloadClosedQuotes = false;
    this.reloadExpiredQuotes = false;

    // this.initiateIPQuotes.getInProgressQuotes();

    this.openTab = "";
    this.inProgressTab = "active";
    this.closedTab = "";
    this.expireTab = "";

    //CLOSES PRIVATE CHAT WHEN SWITCHING TABS
    this.openChat = false;


    // SET QUOTE ARCHIVE STATUS BOOL TO FALSE FOR CHAT
    this.quoteStatus = false;
  }

  triggerClosedQuoteReloading() {

    this.reloadOpenQuote = false;
    this.reloadInProgressQuotes = false;
    this.reloadClosedQuotes = true;
    this.reloadExpiredQuotes = false;

    // this.initiateClosedQuotes.getCompletedQuotes(0);

    this.openTab = "";
    this.inProgressTab = "";
    this.closedTab = "active";
    this.expireTab = "";


    //CLOSES PRIVATE CHAT WHEN SWITCHING TABS
    this.openChat = false;
  }

  triggerExpiredQuoteReloading() {

    this.reloadOpenQuote = false;
    this.reloadInProgressQuotes = false;
    this.reloadClosedQuotes = false;
    this.reloadExpiredQuotes = true;

    //this.initiateExpiredQuotes.getExpiredQuotes(0);

    this.openTab = "";
    this.inProgressTab = "";
    this.closedTab = "";
    this.expireTab = "active";


  }

  changeClosedTab(cond: boolean) {
    // setTimeout(() => {
    //   this.goToTab3.nativeElement.click();
    // }, 200);

    this.reloadOpenQuote = false;
    this.reloadInProgressQuotes = false;
    this.reloadClosedQuotes = true;
    this.reloadExpiredQuotes = false;


    this.openTab = "";
    this.inProgressTab = "";
    this.closedTab = "active";
    this.expireTab = "";

  }

  changeInProgressTab(cond: boolean) {
    // setTimeout(() => {
    //   this.goToTab2.nativeElement.click();
    // }, 200);

    this.reloadOpenQuote = false;
    this.reloadInProgressQuotes = true;
    this.reloadClosedQuotes = false;
    this.reloadExpiredQuotes = false;

    this.openTab = "";
    this.inProgressTab = "active";
    this.closedTab = "";
    this.expireTab = "";


  }

  changeExpiredTab(cond: boolean) {
    // setTimeout(() => {
    //   this.goToTab2.nativeElement.click();
    // }, 200);

    this.reloadOpenQuote = false;
    this.reloadInProgressQuotes = false;
    this.reloadClosedQuotes = false;
    this.reloadExpiredQuotes = true;

    this.openTab = "";
    this.inProgressTab = "";
    this.closedTab = "";
    this.expireTab = "active";


  }


  triggerChatOpen(value: boolean) {
    this.openChat = value;
  }

  triggerQid(quoteid: number) {
    this.sentQID = quoteid;

  }

  SetRoleStringValue() {
    if (this.Buyer == true && this.Supplier == false) {

      this.Role = "Buyer";

    }
    if (this.Buyer == false && this.Supplier == true) {

      this.Role = "Supplier";

    }

  }



}
