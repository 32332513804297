import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { finalize, first } from 'rxjs/operators';
import { RFQ } from '../models/rfq';
import { rfqState } from '../models/rfqState';
import { QuoteSubmitted } from '../models/quote';
import { ThisReceiver } from '@angular/compiler';
import { ToastrNotificationService } from '../services/toastr-notification.service';
import { RfqService } from '../services/rfq.service';
import { SupplierServices } from '../models/supplierServices';
import { QuoteService } from '../services/quote.service';
import { View_QuotesRFQs } from '../models/view_quote';
import { QuoteEdit } from '../models/quoteEdit';
import { Account } from '../models/account';
import { AuthService } from '../auth/auth.service';
import { QuoteFileUpload } from '../models/quoteFile';

@Component({
  selector: 'app-edit-quote',
  templateUrl: './edit-quote.component.html',
  styleUrls: ['./edit-quote.component.css']
})
export class EditQuoteComponent implements OnChanges, OnInit {

  @Input() quoteRfq: View_QuotesRFQs;
  @Output() reloadRfqs = new EventEmitter<boolean>();
  @Output() periodicUpdateCond = new EventEmitter<boolean>();

  triggerReloadRfqs(value: boolean) {
    this.reloadRfqs.emit(value);
  }

  triggerperiodicUpdate(value: boolean) {
    this.periodicUpdateCond.emit(value);
  }

  
  form: FormGroup;
  quoteFileName = '';
  termsCondFileName = '';
  uploadProgress: number;
  quoteFileUploadComplete: boolean;
  termsCondUploadComplete: boolean;
  uploadSub: Subscription;
  quoteFile: File;
  termsCondFile: File;
  allservices: SupplierServices[];
  quoteValidCond: boolean;

  serviceListSelect: any[] = [];
  serviceList: any[] = [];

  quote: QuoteEdit = { QuoteId: null, Amount: null, LeadTime: null, EditedBy: null };
  isQuoted: boolean = false;

  // needed for date calcs
  public dateNow = new Date();
  public dDay?: Date;
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  public mtimeDifference;
  public msecondsToDday;
  public mminutesToDday;
  public mhoursToDday;
  public mdaysToDday;

  account: Account;
  currentAmount: string;

  collectionStreetAddress: string;
  collectionCity: string;
  collectionPostalCode: string;
  collectionProvince: string;

  collectionDeliveryAddressCond: string = null;

  quoteFileNameChange: string;

  constructor(
    public authService: AuthService,
    private formBuilder: FormBuilder,
    private currencyPipe: CurrencyPipe,
    private http: HttpClient,
    private toastrNotification: ToastrNotificationService,
    private rfqService: RfqService,
    private quoteService: QuoteService) {
    this.authService.account.subscribe(x => this.account = x);
  }

  ngOnInit() {
    this.getSupplierServices();
  }

  ngOnChanges(): void {

    this.quoteFileNameChange = this.quoteRfq.quoteDetails.quoteFileName;
   // this.currentAmount = this.currencyPipe.transform((this.quoteRfq.quoteDetails.amount + "").replace(/\D/g, '').replace(/^0+/, ''), 'R', 'symbol', '1.0-0')

    this.currentAmount = this.formatMoney(this.quoteRfq.quoteDetails.amount + "");

    if (this.quoteRfq.quoteDetails.collectionStreetAddress != null) {
      this.collectionStreetAddress = this.quoteRfq.quoteDetails.collectionStreetAddress;
    } else {
      this.collectionStreetAddress = "";
    }

    if (this.quoteRfq.quoteDetails.collectionCity != null) {
      this.collectionCity = this.quoteRfq.quoteDetails.collectionCity;
    } else {
      this.collectionCity = "";
    }

    if (this.quoteRfq.quoteDetails.collectionPostalCode != null) {
      this.collectionPostalCode = this.quoteRfq.quoteDetails.collectionPostalCode;
    } else {
      this.collectionPostalCode = "";
    }
    if (this.quoteRfq.quoteDetails.collectionProvince != null) {
      this.collectionProvince = this.quoteRfq.quoteDetails.collectionProvince;
    } else {
      this.collectionProvince = "";
    }

    this.form = this.formBuilder.group({

      totalAmount: [this.currentAmount, Validators.required],
      leadTime: [this.quoteRfq.quoteDetails.leadTime, Validators.required],
      services: [null],

      collectionAddressDifYes: [null, Validators.required],
      collectionAddressDifNo: [null, Validators.required],

      collectionStreetAddress: [this.collectionStreetAddress, Validators.required],
      collectionCity: [this.collectionCity, Validators.required],
      collectionProvince: [this.collectionProvince , Validators.required],
      collectionPostalCode: [this.collectionPostalCode, Validators.required],

    });

    // this.form.valueChanges.subscribe(form => {
    //   if (form.totalAmount) {
    //     this.form.patchValue({
    //       totalAmount: this.currencyPipe.transform(form.totalAmount.replace(/\D/g, '').replace(/^0+/, ''), 'R', 'symbol', '1.0-0')
    //     }, { emitEvent: false });
    //   }
    // });

    this.serviceListSelect = [];

    for (let index of this.quoteRfq.rfqServicesRequired) {
      this.serviceListSelect.push(index);
    }

    if (this.quoteRfq.deliveryRequirements == "Collection") {
      if (this.quoteRfq.quoteDetails.collectionStreetAddress != null) {
        this.collectionAddressDifferent("No");
      } else {
        this.collectionAddressDifferent("Yes");
      }
    }
  }

  collectionAddressDifferent(answer) {
    if (answer == 'Yes') {
      this.f.collectionAddressDifYes.setValue(true);
      if (this.collectionDeliveryAddressCond == null) {
        this.collectionDeliveryAddressCond = "Yes";
      } else if (this.collectionDeliveryAddressCond == "No") {
        this.f.collectionAddressDifNo.setValue(false);
        this.collectionDeliveryAddressCond = "Yes";
      }
    } else if (answer == 'No') {
      this.f.collectionAddressDifNo.setValue(true);
      if (this.collectionDeliveryAddressCond == null) {
        this.collectionDeliveryAddressCond = "No";
      } else if (this.collectionDeliveryAddressCond == "Yes") {
        this.f.collectionAddressDifYes.setValue(false);
        this.collectionDeliveryAddressCond = "No";
      }
    }
  }

  totalValidator(value) {
    if (value) {      
        if (value.match(/[.]/g).length <= 1) {
          return true;
        } else {
          return false;
        }
      }
    
  }

  transformTotal() {

    const value = this.form.controls.totalAmount.value;
    this.form.controls.totalAmount.setValue(
      this.formatMoney(value.replace(/\,/g, "")),
      { emitEvent: false }
    );
  }

  formatMoney(value) {
    const temp = `${value}`.replace(/\,/g, "");
    return this.currencyPipe.transform(temp).replace("$", "");
  }

  stringValidation(value) {
    if (value.replace(/ /g, "") == "") {
      return true
    } else {
      return false;
    }
  }
  quoteValid() {
    if (this.f.totalAmount.value == null || this.f.leadTime.value == null || this.f.leadTime.value < 0 || this.f.totalAmount.value.match(/[.]/g).length > 1
      || (this.collectionDeliveryAddressCond == "No"
        && (this.f.collectionStreetAddress.value.replace(/ /g, "") == ""
          || this.f.collectionCity.value.replace(/ /g, "") == ""
          || this.f.collectionPostalCode.value.replace(/ /g, "") == ""
          || this.f.collectionProvince.value.replace(/ /g, "") == ""))) {
      return false;
    } else {
      this.quoteValidCond = true;
      return true;
    }

  }

  quoteValidCheck() {

    if (this.quoteValid() == false) {
      this.quoteValidCond = false
    } else {
      this.quoteValidCond = true
    }
  }

  leadTimeValidCheck() {
    if (this.f.leadTime.value > this.getMaxLeadTime(this.quoteRfq.maxLeadTime, this.quoteRfq.quoteSubmissionClosingDate)) {
      return true;
    } else {
      return false;
    }
  }

  leadTimeNegativeCheck() {
    if (this.f.leadTime.value < 0) {
      return true;
    } else {
      return false;
    }
  }

  getMaxLeadTime(deliveryDate, closingDate) {
    this.getDateDifference(new Date(deliveryDate), new Date(closingDate));
    return this.mdaysToDday;

  }

  getDateDifference(deliveryDate, closingDate) {
    var maxLeadDif = deliveryDate.getTime() - closingDate.getTime();
    this.allocateMaxLeadTimeUnits(maxLeadDif);
  }

  private allocateMaxLeadTimeUnits(timeDifference) {
    this.msecondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.mminutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.mhoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.mdaysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }

  getSupplierServices() {
    this.rfqService.getSupplierServices()
      .subscribe(supplierServices => this.allservices = supplierServices)
  }

  submitQuote() {

    // if (this.quoteRfq.quoted == true){
    //  this.toastrNotification.success('Quote successfully edited');
    // }else{
    //  this.toastrNotification.success('Quote successfully created');
    //  }

    if (this.f.totalAmount.value != "" && this.f.leadTime.value != "" && this.f.totalAmount.value != null && this.f.leadTime.value != null) {
      this.quote = { QuoteId: null, Amount: null, LeadTime: null, EditedBy: null };
      this.quote.EditedBy = this.account.account.userName
      this.quote.QuoteId = this.quoteRfq.quoteDetails.quoteId;
      this.quote.Amount = Number(this.f.totalAmount.value.replace(/R/, '').replace(/,/g, ''));
      this.quote.LeadTime = this.f.leadTime.value;

      if (this.collectionDeliveryAddressCond == "No") {
        this.quote.CollectionStreetAddress = this.f.collectionStreetAddress.value;
        this.quote.CollectionCity = this.f.collectionCity.value;
        this.quote.CollectionPostalCode = this.f.collectionPostalCode.value;
        this.quote.CollectionProvince = this.f.collectionProvince.value;
      } else {
        this.quote.CollectionStreetAddress = null;
        this.quote.CollectionCity = null;
        this.quote.CollectionPostalCode = null;
        this.quote.CollectionProvince = null;
      }

      // for (let service of this.serviceListSelect){
      //   for (let supplierService of this.allservices){
      //     if (service == supplierService.serviceName){
      //       this.quote.SupplierServiceIds.push(supplierService.supplierServiceId);
      //     } 
      //   }
      // }

      this.isQuoted = true;
      //this.quoteRfq.quoted = true;

      this.editQuote();
      //Check if success from backend then user  will open edit quote when clicking on submit quote
    } else {
      this.toastrNotification.error('error in submit quote form');
    }

  }

  @ViewChild('EditQuoteBtn') EditQuoteBtn: ElementRef;

  editingQuote: boolean = false;

  editQuote() {
    //async
    var result;

    this.editingQuote = true;

    this.quoteService.editQuote(this.quote).pipe(first())
      .subscribe({
        next: (response: any) => {
          result = response;
          this.triggerReloadRfqs(true);
          this.triggerperiodicUpdate(false);
          this.toastrNotification.info("Edit Quote Succeeded");
          this.EditQuoteBtn.nativeElement.click();
          this.editingQuote = false;
        },
        error: error => {
          this.toastrNotification.error("Edit Quote Failed");
          this.editingQuote = false;
        }
      });
  }

  selectService() {
    this.serviceList.push(this.f.services.value);
    this.serviceListSelect.splice(this.serviceListSelect.indexOf(this.f.services.value), 1);
    this.f.services.setValue(null);
  }

  deleteService(service) {
    this.serviceList.splice(this.serviceList.indexOf(service), 1);
    this.serviceListSelect.push(service);
    this.f.services.setValue(null);
  }

  // quoteFileSelected(event) {
  //   this.quoteFileUploadComplete = false;
  //   this.quoteFile = event.target.files[0];

  //   if (this.quoteFile) {

  //     this.quoteFileName = this.quoteFile.name;

  //     const formData = new FormData();

  //     formData.append("thumbnail", this.quoteFile);

  //     //   const upload$ = this.http.post("/api/thumbnail-upload", formData, {
  //     //     reportProgress: true,
  //     //     observe: 'events'
  //     // })
  //     // .pipe(
  //     //     finalize(() => this.reset())
  //     // );

  //     // this.uploadSub = upload$.subscribe(event => {
  //     //   if (event.type == HttpEventType.UploadProgress) {
  //     //     this.uploadProgress = Math.round(100 * (event.loaded / event.total));
  //     //   }
  //     // })
  //   }
  //   if (this.quoteFileName != "") {
  //     this.quoteFileUploadComplete = true;
  //   } else {
  //     this.quoteFileUploadComplete = false;
  //   }
  // }

  quoteDeleteFile() {
    this.quoteFileName = "";
    this.quoteFileUploadComplete = false;
  }

  termsCondFileSelected(event) {
    this.termsCondUploadComplete = false;
    this.termsCondFile = event.target.files[0];

    if (this.termsCondFile) {

      this.termsCondFileName = this.termsCondFile.name;
      const formData = new FormData();

      formData.append("thumbnail", this.termsCondFile);

      //   const upload$ = this.http.post("/api/thumbnail-upload", formData, {
      //     reportProgress: true,
      //     observe: 'events'
      // })
      // .pipe(
      //     finalize(() => this.reset())
      // );

      // this.uploadSub = upload$.subscribe(event => {
      //   if (event.type == HttpEventType.UploadProgress) {
      //     this.uploadProgress = Math.round(100 * (event.loaded / event.total));
      //   }
      // })
    }
    if (this.termsCondFileName != "") {
      this.termsCondUploadComplete = true;
    } else {
      this.termsCondUploadComplete = false;
    }
  }
  termsCondDeleteFile() {
    this.termsCondFileName = "";
    this.termsCondUploadComplete = false;
  }
  
  get f() { return this.form.controls; }

  quoteFileUpload: QuoteFileUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
  requiredFileType: string = ".pdf";
  @ViewChild('clearInput', { static: false }) clearInputVar: ElementRef;
  quoteFileEditing: boolean = false;

  quoteFileSelected(event) {

    this.quoteFileUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };

    this.quoteFileUploadComplete = false;
    this.quoteFile = event.target.files[0];
    const reader = new FileReader();

    if (this.quoteFile) {

      this.quoteFileEditing = true

      this.quoteFileName = this.quoteFile.name;

      reader.readAsDataURL(this.quoteFile);
      reader.onload = () => {
       
        this.quoteFileUpload.file = (reader.result + "").substring((reader.result + "").indexOf(",") + 1, (reader.result + "").length);
     
        this.quoteFileUpload.quoteId = this.quoteRfq.quoteDetails.quoteId;
        this.quoteFileUpload.rfqId = this.quoteRfq.rfqId;
        this.quoteFileUpload.buyerId = null;
        this.quoteFileUpload.supplierId = this.account.account.supplierId;
        this.quoteFileUpload.userId = this.account.account.userId;
        this.quoteFileUpload.fileName = this.quoteFile.name;
        this.quoteService.ulpoadQuoteFile(this.quoteFileUpload).pipe(first())
        .subscribe( {        
          next: (response: any) => {
            this.quoteService.deleteQuoteFile(this.quoteRfq.quoteDetails.quoteFileId).pipe(first())
            .subscribe( {
              next: (response: any) => {
              //this.selectedQuote.quoteFileName =  this.quoteFile.name;
             // this.triggerReloadRfqs(true);
             this.quoteFileNameChange =  this.quoteFile.name;
              this.clearInputVar.nativeElement.value = "";
              this.quoteFileEditing = false;
            },
            error: error => {
             
              this.toastrNotification.error("Quote File Edit Failed")
              this.quoteFileEditing = false;
              this.clearInputVar.nativeElement.value = "";
            }
                
            })
  
          },
          error: error => {
            
            this.toastrNotification.error("Quote File Edit Failed")
            this.quoteFileEditing = false;
            this.clearInputVar.nativeElement.value = "";
          }
        });
      };
      

    }
   
    if (this.quoteFileName != "") {
      this.quoteFileUploadComplete = true;
    } else {
      this.quoteFileUploadComplete = false;
    }
  }
}
