import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Quote } from '../models/quote';
import { RFQ } from '../models/rfq';
import { QuoteHeaddisplay, RFQHeaddisplay } from '../models/header';

import { QuoteService } from '../services/quote.service';
import { View_Quotes, View_QuotesRFQs } from '../models/view_quote';
import { SortCriteria } from '../pipes/sort-asc-dsc.pipe';
import { Account } from '../models/account';
import { AuthService } from '../auth/auth.service';
import { RfqService } from '../services/rfq.service';
import { QuoteFileUpload } from '../models/quoteFile';
import { Observable, of, Subscription, interval, fromEventPattern, pipe } from 'rxjs';
import { switchMap, finalize, first } from 'rxjs/operators';
import { ViewQuoteServiceService } from '../services/view-quote-service.service';
import { QuoteHeadings } from '../models/quoteHeadings';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrNotificationService } from '../services/toastr-notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'app-open-quotes',
  templateUrl: './open-quotes.component.html',
  styleUrls: ['./open-quotes.component.css']
})
export class OpenQuotesComponent implements OnInit, OnDestroy, OnChanges {

  account: Account;

  @Input() reloadOpenQuoteTab: boolean;

  @Input() openQuoteId: number;

  @Input() state: string;

  @Input() openQuoteNav: string;


  // USED TO CHECK IF EDITED RFQs HAS BEEN SEEN AND SETS THEM TO SEEN ON CLICK METHOD
  setEditedRFQtoSeen: string;
  seenEditedRFQ: string;

  //NEEDED FOR CREATING Q&A QUESTIONS AND REPLIES
  isBuyer: boolean = false;
  isSupplier: boolean = true;
  supplierId: number;
  rfqStatusPC: string = "Open";

  //NEEDED FOR SELECTED RFQ ON SIDEBAR 
  SideSelectedRFQ: View_QuotesRFQs;
  selectedRfqId: number;


  //NEEDED FOR GET QUESTIONS AND ANSWERS
  getQA: boolean = false;


  public criteria: SortCriteria;
  selectedRFQ: View_QuotesRFQs;
  selectedQuote: View_Quotes;


  rfqIndex: number;
  RFQHeadings: string[] = [];

  sortbyHeadings: QuoteHeaddisplay[] = [];
  sortbyHeadingsUpdate: QuoteHeaddisplay[] = [];
  singleSortHeadings: QuoteHeaddisplay = { rfqHeadName: null, rfq: null, id: null };

  headingId: number;
  check: boolean = false;
  dataLoading: boolean = false;
  searchedKeyword: string;
  searchrfq: string;
  rfqUsed: boolean = false;
  //used for blue modals Like the public chat
  modalheader: string = 'blue';

  // QUOTE HEADINGS OBJECT FOR THE SIDENAV
  viewquotes: QuoteHeadings[] = [];
  viewquotesUpdate: QuoteHeadings[] = [];

  // SELECTED RFQ ON THE SIDENAV
  selectedQuoteHeading: QuoteHeadings;

  reloadingSelectedRfq: boolean;
  loadingRfq: boolean = false;



  private subscriptionOQ: Subscription;
  constructor(
    private formBuilder: FormBuilder,
    private rfqService: RfqService,
    private quoteService: QuoteService,
    public authService: AuthService,
    private router: Router,
    private toastrNotification: ToastrNotificationService,
    private reloader: ViewQuoteServiceService,
    private route: ActivatedRoute) {
    this.criteria = {
      property: 'supplierServices',
      descending: true
    };

    this.authService.account.subscribe(x => {
      this.account = x
      this.supplierId = this.account.account.supplierId;
    });
  }

  ngOnChanges() {

    if (this.state == "Open" && ((this.viewquotes.length == 0 || this.openQuoteId == 0) || this.openQuoteNav == 'true')) {
      this.getOpenQuotes(this.openQuoteId);

    } else if (this.state == "Open" && this.viewquotes.length > 0 && this.openQuoteNav == 'false') {
      this.onSelectRFQ(this.openQuoteId);
    }
  }

  ngOnInit(): void {

    this.reloadingSelectedRfq = false;

    // this.headingId = 1;
    // this.singleSortHeadings.rfqHeadName = "Multiple Services";
    // this.singleSortHeadings.rfq = [];
    // this.sortbyHeadings.push(this.singleSortHeadings);
    // this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
    // this.headingId++;

    // this.reloader.currentNavToNewQuote.subscribe(newQuoteCond => {
    //   if (newQuoteCond.reloadCondition) {
    //     this.NavFromCreateQuote(newQuoteCond.reloadRfqId);
    //   } else {
    //    if(this.viewquotes.length == 0){
    //     this.getOpenQuotes(0);
    //    }
    //   }
    // });

    this.subscriptionOQ = interval(300000)
      .subscribe(x => { this.periodicReload(); });

    // this.route.queryParamMap.subscribe(params => {

    //   this.openQuoteNav = params.get('nav');

    // });

    //SETS RFQ SEARCH FORM VALUES TO FALSE
    this.RFQsearch = this.formBuilder.group({

      complete: [false],
      rawmat: [false],
      cod: [false],
      cbd: [false],
      thirtyDays: [false],
      sixtyDays: [false],
      paymentOther: [false],
      delivery: [false],
      collection: [false],
      boxed: [false],
      pallets: [false],
      strapped: [false],
      loose: [false],
      packagingOther: [false],
      testCertificationYes: [false],
      testCertificationNo: [false]

    });

  }

  ngOnDestroy() {
    this.subscriptionOQ.unsubscribe();
  }



  // CHECKS IF EDITED RFQ HAS BEEN SEEN
  getEditedRFQseenCheck(rfqId) {

    this.quoteService.getRFQseenCheck(rfqId, this.account.account.userId).pipe(
      finalize(() => this.dataLoading = false)
    ).subscribe(response => {
      this.seenEditedRFQ = response;

    })
  }


  // SETS EDITED RFQ TO SEEN
  EditedRFQtoSeen(rfqId) {

    this.quoteService.getRFQsetSeen(rfqId, this.account.account.userId).pipe(
      finalize(() => this.dataLoading = false)
    ).subscribe(response => {
      this.setEditedRFQtoSeen = response;

      //CHECKS AGAIN TO UPDATE THE VALUE TO SEEN AND REMOVE THE BADGE
      this.getEditedRFQseenCheck(this.selectedRfqId);

    })


  }





  getOpenQuotesNav(rfqId) {

    this.dataLoading = true;

    this.quoteService.getQuoteHeadings(this.account.account.supplierId, 'Open').pipe(
      finalize(() => this.dataLoading = false)
    ).subscribe(viewquotes => {
      this.viewquotes = viewquotes;
      for (const { index, value } of viewquotes.map((value, index) => ({ index, value }))) {
        if (value.rfqId == rfqId) {
          this.onSelectRFQ(viewquotes[index].quoteDetails.quoteId);
        }
      }
      this.sortByHeadings();

    })
  }

  NavFromCreateQuote(rfqId) {

    this.viewquotes = [];
    this.sortbyHeadings = [];
    this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

    this.headingId = 1;
    this.singleSortHeadings.rfqHeadName = "Multiple Services";
    this.singleSortHeadings.rfq = [];
    this.sortbyHeadings.push(this.singleSortHeadings);
    this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
    this.headingId++;

    this.getOpenQuotesNav(rfqId);
  }

  togglePeriodicReload(cond) {
    if (cond) {
      this.rfqUsed = true;

    } else if (!cond) {
      this.rfqUsed = false;

    }

  }

  togglePubChat(cond) {
    if (cond) {
      // this.rfqUsed = true;
      this.getQA = true;
    } else if (!cond) {
      //  this.rfqUsed = false;
      this.getQA = false;
    }
  }

  periodicReload() {
    if (this.rfqUsed == false && this.reloadOpenQuoteTab) {

      this.sortbyHeadingsUpdate = [];
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

      this.headingId = 1;
      this.singleSortHeadings.rfqHeadName = "Multiple Services";
      this.singleSortHeadings.rfq = [];
      this.singleSortHeadings.id = this.headingId;
      this.sortbyHeadingsUpdate.push(this.singleSortHeadings);
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
      this.headingId++;

      this.getOpenRFQSAfterUpdate();
    }
  }

  getOpenRFQSAfterUpdate() {

    this.reloadingSelectedRfq = true;

    this.quoteService.getQuoteHeadings(this.account.account.supplierId, 'Open')
      .subscribe(quotes => {

        if (quotes.length > 0) {
          this.viewquotesUpdate = quotes;

          //IF THE FILTERS ARE USED ON PERIODIC RELAOD , THE FILTERED RFQ ARRAY MUST BE UPDATED
          if (this.filtersUsed) {
            this.filterUpdateRfqs(quotes);
          }


          if (this.viewquotes.length > 0) {

            var newRfqIds: number[] = [];
            for (let newRfq of this.viewquotesUpdate) {
              newRfqIds.push(newRfq.rfqId)
            }

            if (newRfqIds.indexOf(this.selectedRFQ.rfqId) != -1) {
              for (const { index, value } of this.viewquotesUpdate.map((value, index) => ({ index, value }))) {
                if (value.rfqId == this.selectedRFQ.rfqId) {
                  this.rfqIndex = index;
                  this.onSelectRFQ(quotes[index].quoteDetails.quoteId);
                }
              }

              this.sortRFQHeadArrayUpdate();
              this.replaceRfqsOnUpdate();

            } else {

              this.sortRFQHeadArrayUpdate();
              this.replaceRfqsOnUpdate();
              this.onSelectRFQ(this.sortbyHeadings[0].rfq[0].quoteDetails.quoteId);
            }

            var oldRfqIds: number[] = [];
            for (let oldRfq of this.viewquotes) {
              oldRfqIds.push(oldRfq.rfqId)
            }

            for (let rfq of this.viewquotesUpdate) {
              if (oldRfqIds.indexOf(rfq.rfqId) == -1) {
                this.viewquotes.push(rfq);
              }
            }

            for (const { index, value } of this.viewquotes.map((value, index) => ({ index, value }))) {
              if (newRfqIds.indexOf(value.rfqId) == -1) {
                this.viewquotes.splice(index, 1);
              }
            }

          } else {

            for (let rfq of this.viewquotesUpdate) {
              this.viewquotes.push(rfq);
            }

            this.sortbyHeadings = [];
            this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

            this.headingId = 1;
            this.singleSortHeadings.rfqHeadName = "Multiple Services";
            this.singleSortHeadings.rfq = [];
            this.sortbyHeadings.push(this.singleSortHeadings);
            this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
            this.headingId++;

            for (let rfq of this.viewquotes) {

              rfq.SideSelectedRFQ = false;
            }
            //

            this.sortByHeadings();
            this.onSelectRFQ(this.sortbyHeadings[0].rfq[0].quoteDetails.quoteId);
          }
        } else {
          this.viewquotes = [];
          this.selectedRFQ = null;
          this.selectedQuote = null;
        }
      });
  }

  replaceRfqsOnUpdate() {

    var newHeadings: string[] = [];
    var oldHeadings: string[] = [];

    for (let heading of this.sortbyHeadings) {
      oldHeadings.push(heading.rfqHeadName)
      for (let newHead of this.sortbyHeadingsUpdate) {
        if (heading.rfqHeadName == newHead.rfqHeadName) {
          heading.rfq = newHead.rfq;
          heading.openQuotesCounter = newHead.openQuotesCounter;
        }
      }
    }

    for (let newHeading of this.sortbyHeadingsUpdate) {
      newHeadings.push(newHeading.rfqHeadName);
    }

    // if (this.sortbyHeadings.length != this.sortbyHeadingsUpdate.length) {
    //  if (this.sortbyHeadings.length < this.sortbyHeadingsUpdate.length) {

    for (let newHead of this.sortbyHeadingsUpdate) {
      if (oldHeadings.indexOf(newHead.rfqHeadName) == -1) {
        this.sortbyHeadings.push(newHead);
      }
    }

    // } else {
    for (const { index, value } of this.sortbyHeadings.map((value, index) => ({ index, value }))) {
      if (newHeadings.indexOf(value.rfqHeadName) == -1) {
        this.sortbyHeadings.splice(index, 1);
      }
      //  }
      // }
    }
  }

  //Used for periodic updating of RFQS
  sortRFQHeadArrayUpdate() {

    for (let indexrfq of this.viewquotesUpdate) {
      for (let service of indexrfq.rfqServicesRequired) {
        this.check = false;
        if (indexrfq.rfqServicesRequired.length == 1) {
          for (let name of this.sortbyHeadingsUpdate) {
            if (service == name.rfqHeadName) {
              this.check = true;
            }
          }
          if (this.check == false) {

            this.singleSortHeadings.rfqHeadName = service;
            this.singleSortHeadings.rfq = [];
            this.singleSortHeadings.id = this.headingId;
            this.sortbyHeadingsUpdate.push(this.singleSortHeadings);
            this.headingId++;
            this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
          }
        }
      }
      if (indexrfq.rfqServicesRequired.length > 1) {
        this.sortbyHeadingsUpdate[0].rfq.push(indexrfq);

      }

    }

    this.populateRFQHeadArrayUpdate();
    this.getClosingTime(Date);
  }


  populateRFQHeadArrayUpdate(): void {
    for (let indexrfq of this.viewquotesUpdate) {
      for (let service of indexrfq.rfqServicesRequired) {
        if (indexrfq.rfqServicesRequired.length == 1) {
          for (let val of this.sortbyHeadingsUpdate) {
            if (val.rfqHeadName == service) {
              val.rfq.push(indexrfq)

            }
          }
        }

      }
    }
    if (this.sortbyHeadingsUpdate[0].rfq.length == 0) {
      this.sortbyHeadingsUpdate.splice(0, 1);
    }

  }

  triggerReloadRfqs(value: boolean) {
    if (value) {

      this.sortbyHeadings = [];
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

      this.headingId = 1;
      this.singleSortHeadings.rfqHeadName = "Multiple Services";
      this.singleSortHeadings.rfq = [];
      this.sortbyHeadings.push(this.singleSortHeadings);
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
      this.headingId++;

      this.getOpenQuotesAfterEdit(this.selectedRfqId);
    }

  }
  getOpenQuotesAfterEdit(rfqId) {

    this.dataLoading = true;

    this.quoteService.getQuoteHeadings(this.account.account.supplierId, 'Open').pipe(
      finalize(() => this.dataLoading = false)
    ).subscribe(viewquotes => {
      this.viewquotes = viewquotes;
      for (const { index, value } of this.viewquotes.map((value, index) => ({ index, value }))) {
        if (value.rfqId == this.selectedRFQ.rfqId) {
          this.onSelectRFQ(viewquotes[index].quoteDetails.quoteId);
        }
      }
      this.sortByHeadings();
    })
  }

  getOpenQuotes(selectedQuoteId) {

    this.sortbyHeadings = [];
    this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
    this.selectedRFQ = null;
    this.selectedQuote = null;
    this.viewquotes = [];

    this.dataLoading = true;

    this.quoteService.getQuoteHeadings(this.account.account.supplierId, 'Open').pipe(
      finalize(() => this.dataLoading = false)
    ).subscribe(viewquotes => {

      if (viewquotes.length > 0) {

        this.headingId = 1;
        this.singleSortHeadings.rfqHeadName = "Multiple Services";
        this.singleSortHeadings.rfq = [];
        this.sortbyHeadings.push(this.singleSortHeadings);
        this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
        this.headingId++;

        this.viewquotes = viewquotes;

        for (let rfq of viewquotes) {
          rfq.SideSelectedRFQ = false;
        }

        this.sortByHeadings();

        if (selectedQuoteId == 0) {
          this.router.navigate(['supplier-home/view-quote', 'Open', this.sortbyHeadings[0].rfq[0].quoteDetails.quoteId, 'false']);
        } else {
          this.onSelectRFQ(selectedQuoteId);
        }
      }
    });


  }

  @ViewChild('quoteWithdrawalDismissBtn') quoteWithdrawalDismissBtn: ElementRef;
  @ViewChild('quoteWithdrawalBtn') quoteWithdrawalBtn: ElementRef;

  withdrawingQuote: boolean = false;

  withdrawQuote() {
    this.withdrawingQuote = true;
    this.quoteService.getQuoteAvailability(this.selectedRFQ.quoteDetails.quoteId).pipe(first())
      .subscribe({
        next: (response: any) => {

          if (!response.quoteWithdrawn) {
            if (response.quoteAvailable) {
              this.quoteService.withdrawQuote(this.selectedRFQ.quoteDetails.quoteId).pipe(first())
                .subscribe({
                  next: (response: any) => {
                    this.withdrawingQuote = false;
                    this.quoteWithdrawalBtn.nativeElement.click();
                  },
                  error: error => {
                    this.toastrNotification.error("Quote Withdrawal failed")
                    this.withdrawingQuote = false;
                  }
                });
            } else {
              this.toastrNotification.info("Quote Already Accepted")
            }
          } else {
            this.toastrNotification.info("Quote Already Withdrawn")
          }

        },
        error: error => {
          this.toastrNotification.error("Quote Withdrawal failed")
          this.withdrawingQuote = false;
        }

      });
  }

  reloadAfterQuoteWithdrawal() {
    this.router.navigate(['supplier-home/view-quote', 'Open', 0, 'true']);
  }


  sortByHeadings(): void {

    for (let indexrfq of this.viewquotes) {
      for (let service of indexrfq.rfqServicesRequired) {
        this.check = false;
        if (indexrfq.rfqServicesRequired.length == 1) {
          for (let name of this.sortbyHeadings) {
            if (service == name.rfqHeadName) {
              this.check = true;
            }
          }
          if (this.check == false) {

            this.singleSortHeadings.rfqHeadName = service;
            this.singleSortHeadings.rfq = [];
            this.singleSortHeadings.id = this.headingId;
            this.sortbyHeadings.push(this.singleSortHeadings);
            this.headingId++;
            this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
          }
        }
      }
      if (indexrfq.rfqServicesRequired.length > 1) {
        this.sortbyHeadings[0].rfq.push(indexrfq);

      }
    }

    this.populateRFQHeadArray();

    // LOGIC FOR SUPPLIER USER ACTIONS 
    for (let heading of this.sortbyHeadings) {
      for (let rfq of heading.rfq) {
        heading.openQuotesCounter = 0;
        // AFTER QUOTE ACCEPTED IF SALES ORDER FILE HAS NOT BEEN UPLOADED
        if (rfq.unreadPublicMessages == true) {
          heading.openQuotesCounter = 1;
          break;
        }
        else {
          heading.openQuotesCounter = 0;

        }
      }
    }
  }

  populateRFQHeadArray(): void {
    for (let indexrfq of this.viewquotes) {
      for (let service of indexrfq.rfqServicesRequired) {
        if (indexrfq.rfqServicesRequired.length == 1) {
          for (let val of this.sortbyHeadings) {
            if (val.rfqHeadName == service) {
              val.rfq.push(indexrfq)

            }
          }
        }

      }
    }
    if (this.sortbyHeadings[0].rfq.length == 0) {
      this.sortbyHeadings.splice(0, 1);
    }

    // sort Rfq Heading in Asc order using String.prototype.localCompare()
    this.sortbyHeadings.sort((a, b) =>
      a.rfqHeadName.localeCompare(b.rfqHeadName)
    );

    // Re-inserts Multiple Services heading to first position in array
    var index = this.sortbyHeadings.findIndex(x => x.rfqHeadName == "Multiple Services");
    if (index != -1) {
      var head = this.sortbyHeadings.filter(x => x.rfqHeadName == "Multiple Services")[0];
      this.sortbyHeadings.splice(index, 1);
      this.sortbyHeadings.splice(0, 0, head);
    }
  }

  triggerSelectRfqLoading() {
    this.reloadingSelectedRfq = false;
  }

  loadingRfqSpinnerId: number;

  onSelectRFQNav(quote: QuoteHeadings): void {
    this.loadingRfqSpinnerId = quote.rfqId;
    this.router.navigate(['supplier-home/view-quote', 'Open', quote.quoteDetails.quoteId, 'false']);
  }

  onSelectRFQ(quoteId: number): void {

    if (!this.reloadingSelectedRfq) {
      this.loadingRfq = true;
    }

    this.quoteService.getQuoteById(quoteId).pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.quoteDetails.state == "Open") {

            this.selectedQuoteHeading = this.viewquotes.filter(x => x.quoteDetails.quoteId == quoteId)[0];
            this.selectedRfqId = this.selectedQuoteHeading.rfqId;
            this.selectedRFQ = response;
            this.selectedQuote = this.selectedRFQ.quoteDetails;
            this.loadingRfq = false

            this.router.navigate(['supplier-home/view-quote', 'Open', response.quoteDetails.quoteId, 'false']);

            //INDICATES WHICH THING RFQ BUTTON IS SELECTED FOR THE OPEN QUOTES OBJECT
            for (let siderfq of this.viewquotes) {
              if (this.selectedRfqId == siderfq.rfqId) {

                siderfq.SideSelectedRFQ = true;

              }
              else {
                siderfq.SideSelectedRFQ = false;
              }
            }
            //INDICATES WHICH THING RFQ BUTTON IS SELECTED FOR THE OPEN QUOTES AFTER RELOAD OBJECT
            for (let siderfq of this.viewquotesUpdate) {
              if (this.selectedRfqId == siderfq.rfqId) {

                siderfq.SideSelectedRFQ = true;

              }
              else {
                siderfq.SideSelectedRFQ = false;
              }
            }

            this.getEditedRFQseenCheck(this.selectedRfqId);
            // this.EditedRFQtoSeen(this.selectedRfqId);

          } else {
            this.router.navigate(['supplier-home/view-quote', 'Open', 0, 'false']);
            this.toastrNotification.error("Invalid Selected Quote")
          }

        },
        error: error => {
          this.router.navigate(['supplier-home/view-quote', 'Open', this.sortbyHeadings[0].rfq[0].quoteDetails.quoteId, 'false']);
          this.toastrNotification.error("Invalid Selected Quote")
          this.loadingRfq = false
        }

      });

  }

  //Date Calcs
  public dateNow = new Date();
  public dDay?: Date;
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  private getTimeDifference() {
    this.timeDifference = this.dDay.getTime() - new Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
  }

  public getClosingTime(date) {
    this.dDay = new Date(date);
    this.getTimeDifference();
    if (this.daysToDday < 0) {
      return 'Closed';
    } else {
      return this.daysToDday + ' Days ' + this.hoursToDday + ' Hrs ' + this.minutesToDday + ' Mins';
    }

  }

  private allocateTimeUnits(timeDifference) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }

  //Files
  quoteFile: File;
  quoteFileUploadComplete: boolean;
  quoteFileName = '';
  quoteFileUpload: QuoteFileUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
  requiredFileType: string = ".pdf";

  @ViewChild('clearInput', { static: false }) clearInputVar: ElementRef;

  resetQuoteFileInput() {
    this.clearInputVar.nativeElement.value = "";
  }

  downloadQuoteFiles(quoteFileId, quoteId) {
    this.quoteService.getQuoteFile(quoteFileId, this.account.account.userId, "Supplier").subscribe({

      next: (response: any) => {
        var FileContent = response.fileContent;
        var FileName = response.fileName;
        this.downloadFile(FileContent, FileName);
        // this.downloadingQuoteFile = false;

      },
      error: error => {
        this.toastrNotification.info("Quote File out of date, new Quote File loaded. Please retry download")
        this.quoteService.getQuoteFileDetails(quoteId, "Quotes").subscribe(x => {
          this.selectedRFQ.quoteDetails.quoteFileId = x.quoteFileId;
          this.selectedRFQ.quoteDetails.quoteFileName = x.quoteFileName;
          // this.downloadingQuoteFile = false;
        });
      }
    });

  }

  downloadDrawingFile(rfqFileId) {
    this.rfqService.getRfqDrawingFile(rfqFileId).subscribe({

      next: (response: any) => {
        this.downloadFile(response.fileContent, response.fileName)
      },
      error: error => {
        this.onSelectRFQ(this.selectedRFQ.quoteDetails.quoteId);
        this.toastrNotification.info("Rfq File is out dated, updated Rfq is being loaded")
      }
    })
  }
  uploadNewQuoteFile(quoteFileId) {

  }


  downloadFile(base64String, fileName) {

    // if(window.navigator && window.navigator.msSaveOrOpenBlob){ 
    //   // download PDF in IE
    //   let byteChar = atob(base64String);
    //   let byteArray = new Array(byteChar.length);
    //   for(let i = 0; i < byteChar.length; i++){
    //     byteArray[i] = byteChar.charCodeAt(i);
    //   }
    //   let uIntArray = new Uint8Array(byteArray);
    //   let blob = new Blob([uIntArray], {type : 'application/pdf'});
    //   window.navigator.msSaveOrOpenBlob(blob, `${fileName}.pdf`);
    // } 

    // Download PDF in Chrome etc.
    const source = `data:application/;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}`
    link.click();
  }

  quoteFileSelected(event) {

    this.quoteFileUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };

    this.quoteFileUploadComplete = false;
    this.quoteFile = event.target.files[0];
    const reader = new FileReader();

    if (this.quoteFile) {

      this.quoteFileName = this.quoteFile.name;

      reader.readAsDataURL(this.quoteFile);
      reader.onload = () => {

        this.quoteFileUpload.file = (reader.result + "").substring((reader.result + "").indexOf(",") + 1, (reader.result + "").length);

        this.quoteFileUpload.quoteId = this.selectedQuote.quoteId;
        this.quoteFileUpload.rfqId = this.selectedRFQ.rfqId;
        this.quoteFileUpload.buyerId = null;
        this.quoteFileUpload.supplierId = this.account.account.supplierId;
        this.quoteFileUpload.userId = this.account.account.userId;
        this.quoteFileUpload.fileName = this.quoteFile.name;
        this.quoteService.ulpoadQuoteFile(this.quoteFileUpload).subscribe(x => {
          this.quoteService.deleteQuoteFile(this.selectedQuote.quoteFileId).subscribe(y => {
            //this.selectedQuote.quoteFileName =  this.quoteFile.name;
            this.triggerReloadRfqs(true);
            this.clearInputVar.nativeElement.value = "";
          })
        });
      };


    }

    if (this.quoteFileName != "") {
      this.quoteFileUploadComplete = true;
    } else {
      this.quoteFileUploadComplete = false;
    }
  }

  // RFQ NAV SEARCH FORM
  RFQsearch: FormGroup;
  // RFQ SEARCH FORM
  get rs() { return this.RFQsearch.controls; }

  //  CLEAR RFQ SEARCH FILTER VALUES
  filtersUsed: boolean = false;
  filteredNavRfqs: QuoteHeadings[] = [];
  filteredNavRfqsUpdate: QuoteHeadings[] = [];
  filterSortbyHeadings: QuoteHeaddisplay[] = [];
  filterSortbyHeadingsUpdate: QuoteHeaddisplay[] = [];


  filterRfqType: string[] = [];
  filterPaymentOptions: string[] = [];
  filterPackagingOptions: string[] = [];
  filterCollectorDelivery: string[] = [];
  filtertestCertification: boolean[] = [];





  //  FILTER RFQ NAV
  RFQsearchFilter() {

    this.filterRfqType = [];
    this.filterPaymentOptions = [];
    this.filterCollectorDelivery = [];
    this.filterPackagingOptions = [];
    this.filtertestCertification = [];


    this.filteredNavRfqs = [];

    // POPULATES THE FILTER RFQ ARRAY EVERY VALUE IN THE OPEN RFQS ARRAY
    for (let value of this.viewquotes) {
      this.filteredNavRfqs.push(value);

    }

    // IF ANY OF THE FILTER VALUES ARE SET TO TRUE THEN THE FILTER IS USED AND THE HTML DISPLAYS THE filteredNavRfqs ARRAY
    if (this.rs.complete.value || this.rs.rawmat.value || this.rs.cod.value || this.rs.cbd.value || this.rs.thirtyDays.value || this.rs.sixtyDays.value ||
      this.rs.paymentOther.value || this.rs.delivery.value || this.rs.collection.value || this.rs.boxed.value || this.rs.pallets.value ||
      this.rs.strapped.value || this.rs.loose.value || this.rs.packagingOther.value || this.rs.testCertificationYes.value || this.rs.testCertificationNo.value) {

      this.filtersUsed = true;
    }
    else {
      this.filtersUsed = false;

    }

    // BUILD UP TYPE OF SUPPLY ARRAY
    if (this.rs.complete.value == true) {
      this.filterRfqType.push("Complete");
    }

    if (this.rs.rawmat.value == true) {
      this.filterRfqType.push("Raw Material");
    }


    // BUILD UP COLLECT OR DELIVERY ARRAY
    if (this.rs.delivery.value == true) {
      this.filterCollectorDelivery.push("Delivery");

    }

    if (this.rs.collection.value == true) {
      this.filterCollectorDelivery.push("Collection");

    }
    // BUILD UP PAYMENT ARRAY
    if (this.rs.cod.value == true) {
      this.filterPaymentOptions.push("COD");

    }

    if (this.rs.cbd.value == true) {
      this.filterPaymentOptions.push("CBD");

    }

    if (this.rs.thirtyDays.value == true) {
      this.filterPaymentOptions.push("30 Days");

    }


    if (this.rs.sixtyDays.value == true) {
      this.filterPaymentOptions.push("60 Days");

    }


    if (this.rs.paymentOther.value == true) {
      this.filterPaymentOptions.push("Other");

    }

    // BUILD UP PACKAGING OPTION ARRAY
    if (this.rs.boxed.value == true) {
      this.filterPackagingOptions.push("Boxed");

    }

    if (this.rs.pallets.value == true) {
      this.filterPackagingOptions.push("Pallet");

    }

    if (this.rs.strapped.value == true) {
      this.filterPackagingOptions.push("Strapped");

    }

    if (this.rs.loose.value == true) {
      this.filterPackagingOptions.push("Loose");

    }

    if (this.rs.packagingOther.value == true) {
      this.filterPackagingOptions.push("Other");

    }

    // TEST CERTIFICATION ARRAY POPULATE
    if (this.rs.testCertificationYes.value == true) {

      this.filtertestCertification.push(true);
    }

    // TEST CERTIFICATION ARRAY POPULATE
    if (this.rs.testCertificationNo.value == true) {

      this.filtertestCertification.push(true);
    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE TYPE OP SUPPLY OPTIONS
    if (this.filterRfqType.length > 0) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => this.filterRfqType.some(r => rfq.typeOfSupply.includes(r)));

    }


    // FILTERS filterOpenRfq ARRAY FOR ALL THE PAYMENT REQUIREMENT OPTIONS
    if (this.filterPaymentOptions.length > 0) {
      if (this.filteredNavRfqs != null) {
        this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => this.filterPaymentOptions.some(r => rfq.paymentRequirement?.includes(r)));
      }
    }


    // FILTERS filterOpenRfq ARRAY FOR ALL THE DELIVERY REQUIREMENT OPTIONS
    if (this.filterCollectorDelivery.length > 0) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => this.filterCollectorDelivery.some(r => rfq.deliveryRequirements.includes(r)));
    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE PACKAGING REQUIREMENT OPTIONS FIRST CHECKS IF THE PACKAGING REQUIREMENT OPTIONS IS NOT NULL
    if (this.filterPackagingOptions.length > 0) {

      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => rfq.packagingRequirements != null).filter(rfq => this.filterPackagingOptions.some(r => rfq.packagingRequirements.includes(r)));
    }


    // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE OR FALSE
    if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == true) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => rfq.productCertificationRequirements == true || rfq.productCertificationRequirements == false);

    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE
    if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == false) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => rfq.productCertificationRequirements == true);

    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS FALSE
    if (this.rs.testCertificationNo.value == true && this.rs.testCertificationYes.value == false) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => rfq.productCertificationRequirements == false);

    }



    // FILTERS filterOpenRfq ARRAY IF NOT NULL
    if (this.filteredNavRfqs.length > 0) {

      this.filterSortbyHeadings = [];
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

      this.headingId = 1;
      this.singleSortHeadings.rfqHeadName = "Multiple Services";
      this.singleSortHeadings.rfq = [];
      this.filterSortbyHeadings.push(this.singleSortHeadings);
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
      this.headingId++;



      this.sortFilterRFQHeadArray();
    }
    else {
      // MAKE MAKE THE ARRAY THAT BUILDS UP THE SIDE NAV NULL
      this.filterSortbyHeadings = [];
    }


  }


  // BUILDS RFQ SIDENAV AFTER A FILTER IS SELECTED
  sortFilterRFQHeadArray() {

    // PUSHES ALL VALUES FROM OPEN RFQS ARRAY INTO THE FILTER RFQS ARRAY

    for (let indexrfq of this.filteredNavRfqs) {
      for (let service of indexrfq.rfqServicesRequired) {
        this.check = false;
        if (indexrfq.rfqServicesRequired.length == 1) {
          for (let name of this.filterSortbyHeadings) {
            if (service == name.rfqHeadName) {
              this.check = true;
            }
          }
          if (this.check == false) {

            this.singleSortHeadings.rfqHeadName = service;
            this.singleSortHeadings.rfq = [];
            this.singleSortHeadings.id = this.headingId;
            this.filterSortbyHeadings.push(this.singleSortHeadings);
            this.headingId++;
            this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
          }
        }
      }

      if (indexrfq.rfqServicesRequired.length > 1) {
        this.filterSortbyHeadings[0].rfq.push(indexrfq);
      }

    }
    this.populateSearchRFQHeadArray();
    this.getClosingTime(Date);

    // LOGIC FOR SUPPLIER USER ACTIONS AFTER NAV RELOAD
    for (let heading of this.sortbyHeadings) {
      for (let rfq of heading.rfq) {
        heading.openQuotesCounter = 0;
        // AFTER QUOTE ACCEPTED IF SALES ORDER FILE HAS NOT BEEN UPLOADED
        if (rfq.unreadPublicMessages == true) {
          heading.openQuotesCounter = 1;
          break;
        }
        else {
          heading.openQuotesCounter = 0;

        }
      }
    }


  }


  populateSearchRFQHeadArray(): void {
    for (let rfq of this.filteredNavRfqs) {
      for (let service of rfq.rfqServicesRequired) {
        if (rfq.rfqServicesRequired.length == 1) {
          for (let val of this.filterSortbyHeadings) {
            if (val.rfqHeadName == service) {
              val.rfq.push(rfq)

            }
          }
        }

      }
    }
    if (this.filterSortbyHeadings[0].rfq.length == 0) {
      this.filterSortbyHeadings.splice(0, 1);
    }
  }



  // FILTER NAV ON PERIODIC RELOAD
  filterUpdateRfqs(array: QuoteHeadings[]) {

    this.filteredNavRfqsUpdate = [];

    if (array.length > 0) {

      for (let rfq of array) {
        this.filteredNavRfqsUpdate.push(rfq);
      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE TYPE OP SUPPLY OPTIONS
      if (this.filterRfqType.length > 0) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => this.filterRfqType.some(r => rfq.typeOfSupply.includes(r)));

      }
      // FILTERS filterOpenRfq ARRAY FOR ALL THE PAYMENT REQUIREMENT OPTIONS
      if (this.filterPaymentOptions.length > 0) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => this.filterPaymentOptions.some(r => rfq.paymentRequirement.includes(r)));
      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE DELIVERY REQUIREMENT OPTIONS
      if (this.filterCollectorDelivery.length > 0) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => this.filterCollectorDelivery.some(r => rfq.deliveryRequirements.includes(r)));
      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE PACKAGING REQUIREMENT OPTIONS FIRST CHECKS IF THE PACKAGING REQUIREMENT OPTIONS IS NOT NULL
      if (this.filterPackagingOptions.length > 0) {

        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => rfq.packagingRequirements != null).filter(rfq => this.filterPackagingOptions.some(r => rfq.packagingRequirements.includes(r)));
      }


      // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE OR FALSE
      if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == true) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => rfq.productCertificationRequirements == true || rfq.productCertificationRequirements == false);

      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE
      if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == false) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => rfq.productCertificationRequirements == true);

      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS FALSE
      if (this.rs.testCertificationNo.value == true && this.rs.testCertificationYes.value == false) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => rfq.productCertificationRequirements == false);

      }


      // FILTERS filterOpenRfq ARRAY IF NOT NULL
      if (this.filteredNavRfqsUpdate.length > 0) {

        this.filterSortbyHeadingsUpdate = [];
        this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

        this.headingId = 1;
        this.singleSortHeadings.rfqHeadName = "Multiple Services";
        this.singleSortHeadings.rfq = [];
        this.filterSortbyHeadingsUpdate.push(this.singleSortHeadings);
        this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
        this.headingId++;

        for (let indexrfq of this.filteredNavRfqsUpdate) {
          for (let service of indexrfq.rfqServicesRequired) {
            this.check = false;
            if (indexrfq.rfqServicesRequired.length == 1) {
              for (let name of this.filterSortbyHeadingsUpdate) {
                if (service == name.rfqHeadName) {
                  this.check = true;
                }
              }
              if (this.check == false) {

                this.singleSortHeadings.rfqHeadName = service;
                this.singleSortHeadings.rfq = [];
                this.singleSortHeadings.id = this.headingId;
                this.filterSortbyHeadingsUpdate.push(this.singleSortHeadings);
                this.headingId++;
                this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
              }
            }
          }

          if (indexrfq.rfqServicesRequired.length > 1) {
            this.filterSortbyHeadingsUpdate[0].rfq.push(indexrfq);
          }

        }

        this.getClosingTime(Date);



        for (let rfq of this.filteredNavRfqsUpdate) {
          for (let service of rfq.rfqServicesRequired) {
            if (rfq.rfqServicesRequired.length == 1) {
              for (let val of this.filterSortbyHeadingsUpdate) {
                if (val.rfqHeadName == service) {
                  val.rfq.push(rfq)

                }
              }
            }

          }
        }
        if (this.filterSortbyHeadingsUpdate[0].rfq.length == 0) {
          this.filterSortbyHeadingsUpdate.splice(0, 1);
        }


      }
      else {
        // MAKE MAKE THE ARRAY THAT BUILDS UP THE SIDE NAV NULL
        this.filterSortbyHeadingsUpdate = [];
      }

      this.replaceFilteredRfqsOnUpdate();

    }

  }

  //PUSH OR SPLICE RFQS IF THEY HAVE LEFT THE ARRAY OR IF NEW ONES HAVE COME IN
  replaceFilteredRfqsOnUpdate() {

    var newHeadings: string[] = [];
    var oldHeadings: string[] = [];

    for (let heading of this.filterSortbyHeadings) {
      oldHeadings.push(heading.rfqHeadName)
      for (let newHead of this.filterSortbyHeadingsUpdate) {
        if (heading.rfqHeadName == newHead.rfqHeadName) {
          heading.rfq = newHead.rfq;
          heading.openQuotesCounter = newHead.openQuotesCounter;
        }
      }
    }

    for (let newHeading of this.filterSortbyHeadingsUpdate) {
      newHeadings.push(newHeading.rfqHeadName);
    }

    // if (this.sortbyHeadings.length != this.sortbyHeadingsUpdate.length) {
    //  if (this.sortbyHeadings.length < this.sortbyHeadingsUpdate.length) {

    for (let newHead of this.filterSortbyHeadingsUpdate) {
      if (oldHeadings.indexOf(newHead.rfqHeadName) == -1) {
        this.filterSortbyHeadings.push(newHead);
      }
    }

    // } else {
    for (const { index, value } of this.filterSortbyHeadings.map((value, index) => ({ index, value }))) {
      if (newHeadings.indexOf(value.rfqHeadName) == -1) {
        this.filterSortbyHeadings.splice(index, 1);
      }
    }

    //  }

    // }

  }


  clearRFQFilter() {

    this.filtersUsed = false;

    this.rs.complete.setValue(false);
    this.rs.rawmat.setValue(false);
    this.rs.cod.setValue(false);
    this.rs.cbd.setValue(false);
    this.rs.thirtyDays.setValue(false);
    this.rs.sixtyDays.setValue(false);
    this.rs.paymentOther.setValue(false);
    this.rs.delivery.setValue(false);
    this.rs.collection.setValue(false);
    this.rs.boxed.setValue(false);
    this.rs.pallets.setValue(false);
    this.rs.strapped.setValue(false);
    this.rs.loose.setValue(false);
    this.rs.packagingOther.setValue(false);
    this.rs.testCertificationYes.setValue(false);
    this.rs.testCertificationNo.setValue(false);
  }

  filterOpen: boolean = false;
  filterIconToggle() {
    this.filterOpen = !this.filterOpen;
  }

  // TOGGLES PUBLIC CHAT BADGE TO FALSE WHEN PUBLIC CHAT IS OPENED
  togglePublicChatMessages() {
    this.selectedQuoteHeading.unreadPublicMessages = false;
  }

}
