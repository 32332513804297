<li class="nav-item dropdown open no-arrow NotifsDropdown" style="font-family: Roboto, sans-serif;">

    <a class="dropdown-toggle nav-link blue" aria-expanded="false" data-bs-toggle="dropdown"
        data-bs-auto-close="outside">
        <span *ngIf="notifications.length > 0" class="badge bg-danger badge-counter">{{notifCount}}</span><i
            class="fas fa-bell fa-fw"></i>Notifications&nbsp; &nbsp;</a>

    <div class="dropdown-menu dropdown-menu-end dropdown-list animated--grow-in notifmenu">

        <h6 class="dropdown-header notifheader" style="background: var(--mainblue);border-style: none;">Notifications
        </h6>

        <div *ngFor=" let notif of notifications">
            <div class="dropdown-item d-flex  align-items-center justify-content-start notifitem">

                <div (click)="navFromNotification(notif)"
                    class=" d-flex d-lg-flex justify-content-center align-items-center justify-content-lg-center align-items-lg-center">

                    <div *ngIf="notif.type == 'ReplySubmitted' || notif.type == 'Unread Public Message' ">
                        <div class="me-3">
                            <div style="background-color: var(--darkblue); " class=" icon-circle"><svg
                                    _ngcontent-jxm-c64="" xmlns="http://www.w3.org/2000/svg" width="1em" height="2em"
                                    viewBox="0 0 20 20" style="color:white;" fill="none">
                                    <path _ngcontent-jxm-c64="" fill-rule="evenodd" clip-rule="evenodd"
                                        d="M18 3C18 2.65342 17.8205 2.33156 17.5257 2.14935C17.2309 1.96714 16.8628 1.95058 16.5528 2.10557L8.76393 6H5C3.34315 6 2 7.34315 2 9C2 10.6569 3.34315 12 5 12H5.27925L7.05132 17.3162C7.18744 17.7246 7.56958 18 8.00001 18H9.00001C9.55229 18 10 17.5523 10 17V12.618L16.5528 15.8944C16.8628 16.0494 17.2309 16.0329 17.5257 15.8507C17.8205 15.6684 18 15.3466 18 15V3Z"
                                        fill="currentColor"></path>
                                </svg></div>
                        </div>
                    </div>

                    
                    <div *ngIf = "notif.type == 'BuyerPrivateChat' || notif.type == 'SupplierPrivateChat'" class="me-3">
                        <div class="bg-primary icon-circle"><svg class="bi bi-chat-right-fill text-white" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" style="color: var(--white);">       
                                  <path d="M14 0a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z"></path>     
                             </svg>
                        </div> 
                    </div>


                    <div *ngIf="notif.type == 'NewRfq' || notif.type == 'NewQuote' ||  notif.type == 'RFQClosingDate' ">
                        <div class="me-3">
                            <div class="bg-warning icon-circle"><i class="fas fa-file text-white"></i>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="notif.type == 'QuoteExpired' || notif.type == 'RfqCancelled'  " class="me-3">     
                        <div class="bg-warning icon-circle">
                            <i class="fas fa-minus-circle text-white"></i>
                        </div> 
                    </div>


                    <div
                        *ngIf="notif.type == 'SalesOrderUpload' || notif.type == 'PurchaseOrderUpload' || notif.type == 'FinalInvoiceUpload' ">
                        <div class="me-3">
                            <div class="bg-primary icon-circle"><i class="fas fa-file-alt text-white"></i></div>
                        </div>
                    </div>

                    <div
                        *ngIf="notif.type == 'QuoteAccepted' || notif.type == 'DeliveryComplete' || notif.type == 'RfqCompleted' ">
                        <div class="me-3">
                            <div class="bg-success icon-circle"><i class="fas fa-check text-white"></i></div>
                        </div>
                    </div>

                    <div *ngIf="notif.type.includes('Payment') || notif.type == 'CreditApplicationSubmitted' || notif.type == 'CreditApplicationRejected' || notif.type == 'CreditApplicationAccepted' ">
                        <div class="me-3">
                            <div class="bg-success icon-circle"><i class="fas fa-donate text-white"></i></div>
                        </div>
                    </div>


                    <div><span class="NotifDate small text-gray-500">{{notif.type}}
                            {{getMinimalisticRelativeTime(notif.createdAt)}} </span>
                        <p>{{notif.content}}</p>
                    </div>

                </div>

                <div>
                    <p class="d-flex d-lg-flex flex-column justify-content-lg-end">

                        <ng-container *ngIf="!notif.seen">
                            <i
                                class="fa fa-circle d-flex d-xl-flex justify-content-center align-items-center justify-content-xl-center"></i>
                        </ng-container>

                        <button [disabled]="notifBeingRemoved && notif.notificationId == selectedNotifId"
                            (click)="removeNotification(notif.notificationId)" class="btn btn-secondary btn-sm trash"
                            type="button" style=" margin-top: 10px;background: rgba(133,135,150,0);border-style: none;">
                            <i class="fa fa-trash-o" style="color: rgb(133,135,150);"></i><span style="color: black;"
                                *ngIf="notifBeingRemoved && notif.notificationId == selectedNotifId"
                                class="spinner-border spinner-border-sm mr-1"></span></button>
                    </p>
                </div>

            </div>

        </div>
    </div>
</li>