import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BuyerCompany, CreditApplicationCreate, CreditApplicationSuppliersRequest } from '../models/buyerCompany';
import { ContactDetailsEdit } from '../models/contactDetailsEdit';
import { EditRfqDetails } from '../models/generalRfqDetailsEdit';
import { AddPreferedSupplier, PreferedSupplierFinal, preferedSupplierInitial } from '../models/preferedSupplier';
import { UploadRfqFile } from '../models/rfqCreate';
import { RfqFileDownload } from '../models/rfqFileDownload';
import { CreditApplicationRfq } from '../models/viewRfq';
import { MessageService } from './message.service';
import { ToastrNotificationService } from './toastr-notification.service';

const baseUrl = `${environment.apiUrl}/BuyerCompany`;


@Injectable({
  providedIn: 'root'
})
export class BuyerCompanyService {

  constructor(
    private messageService: MessageService,
    private http: HttpClient,
    private toastrNotification: ToastrNotificationService
  ) { }

  /** GET buyer company data from server */
  getBuyerCompanyData(buyerId: number): Observable<BuyerCompany> {
    const url = `${baseUrl}/BuyerDetails?buyerId=${buyerId}`
    return this.http.get<any>(url)
      .pipe(
        map(buyerCompanyData => { return buyerCompanyData.results }),
        tap(_ => this.log('fetched buyer company data')),
        catchError(this.handleError<BuyerCompany>('getBuyerCompanyData'))
      );
  }

  /** POST: edit buyer details */
  editBuyerDetails(details: ContactDetailsEdit): Observable<ContactDetailsEdit> {
    const url = `${baseUrl}/EditBuyerDetails`;
    return this.http.post<any>(url, details, this.httpOptions)
      .pipe(
        map(editResult => {  return editResult.message }),
        tap((editResult: ContactDetailsEdit) => this.log(`buyer details succesfully`)),
        catchError(this.handleError<ContactDetailsEdit>('buyer details edit'))
      );
  }

  /** POST:buyer general rfq details */
  editBuyerGeneralRfqDetails(details: EditRfqDetails): Observable<EditRfqDetails> {
    const url = `${baseUrl}/EditGeneralRfqDetails`;
    return this.http.post<any>(url, details, this.httpOptions)
      .pipe(
        map(editResult => {  return editResult.message }),
        tap((editResult: EditRfqDetails) => this.log(`general Rfq details succesfully`)),
        catchError(this.handleError<EditRfqDetails>('general Rfq details edit'))
      );
  }

  /** GET initial supplier  data from server */
  getPreferedSupplierInitial(userId: string): Observable<preferedSupplierInitial> {
    const url = `${baseUrl}/InitialSuppliers?userId=${userId}`
    return this.http.get<any>(url)
      .pipe(
        map(suppliers => { return suppliers.results }),
        tap(_ => this.log('fetched possible preffered supplier data')),
        catchError(this.handleError<preferedSupplierInitial>('getPreferedSupplierInitial'))
      );
  }

  /** GET supplier  data from server */
  getPreferedSupplierFinal(supplierId: number, buyerId: number): Observable<PreferedSupplierFinal> {
    const url = `${baseUrl}/SelectedPreferedSupplier?supplierId=${supplierId}&buyerId=${buyerId}`
    return this.http.get<any>(url)
      .pipe(
        map(suppliers => { return suppliers.results }),
        tap(_ => this.log('fetched  preffered supplier data')),
        catchError(this.handleError<PreferedSupplierFinal>('getPreferedSupplierFinal'))
      );
  }

  /** POST:buyer general rfq details */
  addPreferedSupplier(details: AddPreferedSupplier): Observable<AddPreferedSupplier> {
    const url = `${baseUrl}/AddPreferedSupplier`;
    return this.http.post<any>(url, details, this.httpOptions)
      .pipe(
        map(editResult => { return editResult.message }),
        tap((editResult: AddPreferedSupplier) => this.log(`prefered supplier added succesfully`)),
        catchError(this.handleError<AddPreferedSupplier>('prefered supplier add'))
      );
  }

  /** POST:buyer general rfq details */
  removePreferedSupplier(details: AddPreferedSupplier): Observable<AddPreferedSupplier> {
    const url = `${baseUrl}/DeletePreferedSupplier`;
    return this.http.post<any>(url, details, this.httpOptions)
      .pipe(
        map(editResult => { return editResult.message }),
        tap((editResult: AddPreferedSupplier) => this.log(`prefered supplier removed succesfully`)),
        catchError(this.handleError<AddPreferedSupplier>('prefered supplier removed'))
      );
  }

  uploadCompanyLogo(rfqFile: UploadRfqFile) {
    const url = `${baseUrl}/Upload/CompanyLogoFile`;
    return this.http.post<any>(url, rfqFile, this.httpOptions)
      .pipe(
         map(fileName => { return fileName.message }),
        tap((buyerFile: UploadRfqFile) => this.log(`Company Logo uploaded succesfully`)),
        catchError(this.handleError<UploadRfqFile>('uploadCompanyLogo'))
      );
  }

  deleteCompanyLogoFile(buyerId: number) {
    const url = `${baseUrl}/Delete/CompanyLogoFile/${buyerId}`;
    return this.http.delete<any>(url)
      .pipe(
        tap(_ => this.log(`delete Company Log oFile`)),
        catchError(this.handleError<RfqFileDownload>(`deleteCompanyLogoFile}`))
      );
  }

  getCompanyLogoFile(buyerId: number): Observable<RfqFileDownload> {
    const url = `${baseUrl}/Download/CompanyLogo/${buyerId}`;
    return this.http.get<any>(url)
      .pipe(
        map(rfqDrawingFile => { return rfqDrawingFile.file }),
        tap(_ => this.log(`fetched Company Logo File`)),
        catchError(this.handleError<RfqFileDownload>(`getCompanyLogoFile}`))
      );
  }

  getAvailableCreditSuppliers(userId: string): Observable<CreditApplicationSuppliersRequest> {
    const url = `${baseUrl}/CreditApplicationSuppliers?userId=${userId}`
    return this.http.get<any>(url)
      .pipe(
        map(availableCreditSupplier => { return availableCreditSupplier.results }),
        tap(_ => this.log('fetched available supplier data')),
        catchError(this.handleError<CreditApplicationSuppliersRequest>('getAvailableCreditSuppliers'))
      );
  }

  getReapplyCreditSuppliers(buyerId: number,supplierId: number): Observable<CreditApplicationSuppliersRequest> {
    const url = `${baseUrl}/CreditReApplicationSupplier/${buyerId}/${supplierId}`
    return this.http.get<any>(url)
      .pipe(
        map(availableCreditSupplier => { return availableCreditSupplier.results }),
        tap(_ => this.log('fetched available supplier data')),
        catchError(this.handleError<CreditApplicationSuppliersRequest>('getReapplyCreditSuppliers'))
      );
  }

  uploadCreditApplicationFile(rfqFile: UploadRfqFile) {
    const url = `${baseUrl}/Upload/CreditApplicationFile`;
    return this.http.post<any>(url, rfqFile, this.httpOptions)
      .pipe(
         map(fileName => { return fileName.message }),
        tap((buyerFile: UploadRfqFile) => this.log(`Credit Application File uploaded succesfully`)),
        catchError(this.handleError<UploadRfqFile>('uploadCreditApplicationFile'))
      );
  }

  createCreditApplication(details: CreditApplicationCreate): Observable<CreditApplicationCreate> {
    const url = `${baseUrl}/CreateCreditApplication`;
    return this.http.post<any>(url, details, this.httpOptions)
      .pipe(
        map(editResult => { return editResult.message }),
        tap((editResult: CreditApplicationCreate) => this.log(`credit application created succesfully`)),
        catchError(this.handleError<CreditApplicationCreate>('create credit application'))
      );
  }

  editCreditApplication(details: CreditApplicationCreate): Observable<CreditApplicationCreate> {
    const url = `${baseUrl}/EditCreditApplication`;
    return this.http.post<any>(url, details, this.httpOptions)
      .pipe(
        map(editResult => { return editResult.message }),
        tap((editResult: CreditApplicationCreate) => this.log(`credit application edited succesfully`)),
        catchError(this.handleError<CreditApplicationCreate>('edit credit application'))
      );
  }

  getCreditApplicationFile(buyerId: number, supplierId: number): Observable<RfqFileDownload> {
    const url = `${baseUrl}/Download/CreditApplication/${buyerId}/${supplierId}`;
    return this.http.get<any>(url)
      .pipe(
        map(rfqDrawingFile => { return rfqDrawingFile.file }),
        tap(_ => this.log(`fetched Company Logo File`)),
        catchError(this.handleError<RfqFileDownload>(`getCompanyLogoFile}`))
      );
  }

  getCreditApplication(buyerId: number,supplierId: number): Observable<CreditApplicationRfq> {
    const url = `${baseUrl}/GetCreditApplication/${buyerId}/${supplierId}`
    return this.http.get<any>(url)
      .pipe(
        map(availableCreditSupplier => { return availableCreditSupplier.results }),
        tap(_ => this.log('fetched credit application data')),
        catchError(this.handleError<CreditApplicationRfq>('getCreditApplication'))
      );
  }

  deleteCreditApplication(buyerId: number, supplierId: number) : Observable<string> {
    const url = `${baseUrl}/Delete/CreditApplication/${buyerId}/${supplierId}`;
    return this.http.delete<any>(url)
      .pipe(
        map(deleteResult => { return deleteResult.message }),
        tap(_ => this.log(`delete Company Log oFile`)),
        catchError(this.handleError<string>(`deleteCompanyLogoFile}`))
      );
  }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`BuyerCompanyService: ${message}`);
  }

  private rfqUrl = 'api/rfqs';  // URL to web api



   /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
    private handleError<T>(operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
        this.toastrNotification.error(`${operation} failed: ${error.message}`);
  
        throw error;
      };
    }

}
