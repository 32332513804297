import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//Guards
import { AuthGuard } from './auth/auth.guard';
import { CanDeactivateGuard } from './can-deactivate.guard';

//Models
import { Role } from './models/role';

//Components
import { BuyerHomeComponent } from './buyer-home/buyer-home.component';
import { SupplierHomeComponent } from './supplier-home/supplier-home.component';
import { MessagesComponent } from './messages/messages.component';
import { ViewRfqComponent } from './view-rfq/view-rfq.component';
import { ViewQuoteComponent } from './view-quote/view-quote.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SearchRfqComponent } from './search-rfq/search-rfq.component';
import { CreateRfqComponent } from './create-rfq/create-rfq.component';
import { BuyerProfileComponent } from './buyer-profile/buyer-profile.component';
import { SupplierProfileComponent } from './supplier-profile/supplier-profile.component';
import { BuyerReportingComponent } from './buyer-reporting/buyer-reporting.component';
import { SupplierReportingComponent } from './supplier-reporting/supplier-reporting.component';


const accountModule = () => import('./account/account.module').then(x => x.AccountModule);
const adminModule = () => import('./admin/admin.module').then(x => x.AdminModule);
const profileModule = () => import('./profile/profile.module').then(x => x.ProfileModule);

//Order from least specific to most specific/ wild card always last...
const routes: Routes = [
  { path: 'account', loadChildren: accountModule },
  { path: 'profile', loadChildren: profileModule, canActivate: [AuthGuard] },
  { path: 'admin', loadChildren: adminModule, canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
  { path: 'buyer-home', component: BuyerHomeComponent, canActivate: [AuthGuard], data: { roles: [Role.Buyer, Role.Admin] } },
  { path: 'supplier-home', component: SupplierHomeComponent, canActivate: [AuthGuard], data: { roles: [Role.Supplier, Role.Admin] } },
  { path: 'supplier-home/view-quote/:state/:quoteId/:nav', component: ViewQuoteComponent, data: { animation: 'rfqs', roles: [Role.Supplier, Role.Admin] }, canActivate: [AuthGuard] },
  { path: 'supplier-home/search-rfq', component: SearchRfqComponent, canActivate: [AuthGuard], data: { roles: [Role.Supplier, Role.Admin] } },
  { path: 'supplier-home/supplier-profile', component: SupplierProfileComponent, data: { animation: 'rfqs', roles: [Role.Supplier, Role.Admin] }, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'buyer-home/view-rfq/:state/:rfqId/:nav', component: ViewRfqComponent, data: { animation: 'rfqs', roles: [Role.Buyer, Role.Admin] }, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'buyer-home/buyer-profile', component: BuyerProfileComponent, data: { animation: 'rfqs', roles: [Role.Buyer, Role.Admin] }, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'buyer-reporting', component: BuyerReportingComponent, data: { animation: 'rfqs', roles: [Role.Buyer, Role.Admin] }, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'supplier-reporting', component: SupplierReportingComponent, data: { animation: 'rfqs', roles: [Role.Supplier, Role.Admin] }, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'create-rfq', component: CreateRfqComponent, canActivate: [AuthGuard], data: { roles: [Role.Buyer, Role.Admin] } },
  { path: '404', component: PageNotFoundComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: '', redirectTo: '/404', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent } 
  //{ path: 'view-quote', component: ViewQuoteComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
];

@NgModule({
  imports: [
    //RouterModule.forRoot(routes)],
    RouterModule.forRoot(
      routes,
      { enableTracing: false } // <-- debugging purposes only
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
