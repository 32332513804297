import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { RFQ } from '../models/rfq';
import { RFQS } from '../models/mock-data';
import { FilterPipe } from '../pipes/filter.pipe';
import { Pipe, PipeTransform } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowseRfqService {

  getMockSearchRFQs(): Observable<RFQ[]> {
    const rfqs = of(RFQS);
    return rfqs ;
  }

  constructor() { }
}
