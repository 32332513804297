<ng-container *ngIf="supplierCompanyDetails   && !dataLoading">
    <div class="sprofile">
        <main class="main" style="padding-top: 0px;">
            <div class="container-fluid d-flex d-xl-flex justify-content-center align-items-center justify-content-sm-center justify-content-xl-center align-items-xl-center bluecont"
                style="height: 51px;">
                <h1 class="text-center" style="font-size: 20px;">Company Profile</h1>
            </div>
            <div class="container" style="max-width: 1500px;">
                <div class="row" style="padding-top: 1rem;padding-bottom: 50px;">
                    <div class="col-xxl-8 offset-xxl-1" style="min-width: 900px;">
                        <div class="uirow bg-white rounded shadow-sm">
                            <div class="row g-0" style="margin-bottom: 0.5rem;">
                                <div class="col d-flex d-xxl-flex align-items-center align-items-xxl-center"
                                    style="padding: 0.5rem;padding-bottom: 0px;background: var(--shadowgrey);"><i
                                        class="fa fa-user-circle" style="font-size: 38px;color: var(--yellow);"></i>
                                    <h1 class="text-nowrap text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel"
                                        style="font-size: 18px;margin-bottom: 0;">Business Details</h1>
                                    <!-- <div class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                        style="background: var(--white);padding: 7px;border-radius: 40px;"><i
                                            class="fa fa-check-circle d-xl-flex d-xxl-flex align-items-xl-center align-items-xxl-center"
                                            style="border-color: var(--lightblue);color: var(--lightblue);font-size: 15px;padding-left: 0;"></i>
                                        <p class="text-nowrap"
                                            style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;">
                                             Credit Gaurantee</p>
                                    </div> -->

                                    <button data-bs-toggle="modal" data-bs-target="#supplierdetails"
                                        [disabled]="account.account.roles[0] != 'Admin' "
                                        class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                        role="button" [disabled]="account.account.roles[0] != 'Admin' "
                                        style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                            class="fa fa-edit"
                                            style="font-size: 14px;color: var(--lightblue);"></i> Edit</button>

                                    <button class="btn accordion-button collapsed" data-bs-toggle="collapse"
                                        data-bs-target=".buyerdetails" type="button" aria-controls></button>
                                </div>
                            </div>
                            <div class="row g-0 buyerdetails collapse show"
                                style="padding: 0.5rem;padding-top: 0;margin-bottom: 0.5rem;">
                                <div class="col-xxl-12 uidetailblock" style="padding-bottom: 0px;">
                                    <div>
                                        <div class="row row-cols-3 d-flex d-xxl-flex justify-content-between justify-content-xl-start justify-content-xxl-start"
                                            style="max-width: 1099px;">
                                            <div
                                                class="col-xxl-4 d-flex flex-column justify-content-center align-items-lg-start justify-content-xxl-center">
                                                <div style="padding-bottom: 0.5rem;">
                                                    <p
                                                        class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                        Company Name</p>
                                                    <p
                                                        class="d-sm-flex justify-content-sm-start align-items-xxl-center bluehead">
                                                        {{supplierCompanyDetails.name}}<br /></p>
                                                </div>
                                            </div>
                                            <div
                                                class="col-md-3 col-xxl-3 d-flex flex-column justify-content-center align-items-center align-items-lg-start justify-content-xxl-center align-items-xxl-center">
                                                <img [src]="companyLogo" style="width: 99px;" />
                                            </div>
                                            <div
                                                class="col-xxl-4 d-flex flex-column justify-content-center align-items-lg-start justify-content-xxl-center">

                                                <div style="padding-bottom: 0.5rem;">
                                                    <p
                                                        class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                        Registration Number</p>
                                                    <p
                                                        class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                        {{supplierCompanyDetails.companyRegistrationNumber}}</p>
                                                </div>
                                                <div style="padding-bottom: 0.5rem;">
                                                    <p
                                                        class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                        Vat Number</p>
                                                    <p
                                                        class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                        {{supplierCompanyDetails.vatNumber}}</p>
                                                </div>
                                                <div style="padding-bottom: 0px;">
                                                    <p
                                                        class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                        CIBD Rating</p>
                                                    <p
                                                        class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                        8.2</p>
                                                </div>
                                                <div style="padding-bottom: 0px;">
                                                    <p
                                                        class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                        Description</p>
                                                    <p
                                                        class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                        {{supplierCompanyDetails.companyDescription}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row row-cols-3 d-flex d-xxl-flex justify-content-between justify-content-xxl-start"
                                            style="max-width: 1099px;padding-bottom: 0px;">
                                            <div
                                                class="col-md-4 col-xl-3 col-xxl-4 d-flex flex-column justify-content-center align-items-lg-start justify-content-xxl-center">
                                                <div style="padding-bottom: 0.5rem;">
                                                    <p
                                                        class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                        Rating</p>
                                                    <p
                                                        class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                        <i class="fa fa-star"
                                                            style="font-size: 15px;color: var(--yellow);"></i>

                                                        <ng-container *ngIf="supplierCompanyDetails.rating != null">
                                                            {{supplierCompanyDetails.rating | number :'1.0-1'}}
                                                        </ng-container>
                                                        <ng-container *ngIf="supplierCompanyDetails.rating == null">
                                                            --
                                                        </ng-container>
                                                    </p>
                                                </div>
                                                <div style="padding-bottom: 0.5rem;">
                                                    <p
                                                        class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                        Enterprise Size</p>
                                                    <p
                                                        class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                        {{supplierCompanyDetails.companySize}}</p>
                                                </div>
                                                <div style="padding-bottom: 0.5rem;">
                                                    <p
                                                        class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                        Number of Employees</p>
                                                    <p
                                                        class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                        {{supplierCompanyDetails.numberOfEmployees}}</p>
                                                </div>
                                                <div style="padding-bottom: 0.5rem;">
                                                    <p
                                                        class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                        Holding Company</p>
                                                    <p
                                                        class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                        {{supplierCompanyDetails.holdingCompany}}</p>
                                                </div>
                                                <div style="padding-bottom: 0.5rem;">
                                                    <p
                                                        class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                        Date of Commencement</p>
                                                    <p
                                                        class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                        {{supplierCompanyDetails.dateOfBusinessCommencement |
                                                        date:'mediumDate'}}</p>
                                                </div>
                                            </div>
                                            <!-- <div class="col-md-3 col-xl-2 col-xxl-3 d-flex flex-column justify-content-end align-items-center justify-content-md-end align-items-lg-start justify-content-xxl-end align-items-xxl-center"
                                                style="padding-bottom: 0.5rem;">
                                                <div class="d-flex flex-column align-items-center align-items-sm-center align-items-xxl-center shadow-sm rounded mb-2"
                                                    style="width: 150.2812px; background: var(--shadowgrey);">
                                                    <p class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext"
                                                        style="margin-bottom: 0px;"><i class="fab fa-adobe"
                                                            style="margin: 10px;color: var(--yellow);margin-left: 0;"></i><br /><strong>File
                                                            1</strong><br /><br /><a></a></p>
                                                    <p class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext"
                                                        style="margin-bottom: 0px;"><i class="fab fa-adobe"
                                                            style="margin: 10px;color: var(--yellow);margin-left: 0;"></i><br /><strong>File
                                                            2</strong><br /><br /><a></a></p>
                                                    <p class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext"
                                                        style="margin-bottom: 0px;"><i class="fab fa-adobe"
                                                            style="margin: 10px;color: var(--yellow);margin-left: 0;"></i><br /><strong>File
                                                            3</strong><br /><br /><a></a></p>
                                                    <p class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext"
                                                        style="margin-bottom: 0px;"><i class="fab fa-adobe"
                                                            style="margin: 10px;color: var(--yellow);margin-left: 0;"></i><br /><strong>File
                                                            4</strong><br /><br /><a></a></p>
                                                    <p class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext"
                                                        style="margin-bottom: 0px;"><i class="fab fa-adobe"
                                                            style="margin: 10px;color: var(--yellow);margin-left: 0;"></i><br /><strong>File
                                                            5</strong><br /><br /><a></a></p>
                                                </div>
                                            </div> -->
                                            <!-- <div
                                                class="col-xl-5 col-xxl-4 d-flex flex-column justify-content-center align-items-lg-start justify-content-xxl-end">
                                                <div style="padding-bottom: 0.5rem;">

                                                    <div class="shadow-sm rounded mb-2"
                                                        style="padding: 19px;padding-bottom: 0.5rem;padding-top: 12px;background: var(--shadowgrey);">
                                                        <p
                                                            class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                            Vendor Number</p>
                                                        <div
                                                            class="d-flex d-sm-flex d-xl-flex justify-content-between align-items-center align-items-sm-center align-items-xl-center">
                                                            <p
                                                                class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                                Raven Soft  </p>
                                                            <p
                                                                class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                                123234</p>
                                                        </div>
                                                        <div
                                                        class="d-flex d-sm-flex d-xl-flex justify-content-between align-items-center align-items-sm-center align-items-xl-center">
                                                        <p
                                                            class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                            Company Name </p>
                                                        <p
                                                            class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                            123234</p>
                                                    </div>
                                                        <div
                                                        class="d-flex d-sm-flex d-xl-flex justify-content-between align-items-center align-items-sm-center align-items-xl-center">
                                                        <p
                                                            class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                            Company Name </p>
                                                        <p
                                                            class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                            123234</p>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="uirow bg-white rounded shadow-sm">
                            <div class="row g-0" style="margin-bottom: 0.5rem;">
                                <div class="col d-flex d-xxl-flex align-items-center align-items-xxl-center"
                                    style="padding: 0.5rem;padding-bottom: 0px;background: var(--shadowgrey);"><i
                                        class="fa fa-user-circle" style="font-size: 38px;color: var(--yellow);"></i>
                                    <h1 class="text-nowrap text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel"
                                        style="font-size: 18px;margin-bottom: 0;">Tradely Specific Details</h1><button
                                        data-bs-toggle="modal" data-bs-target="#tradelydetails"
                                        class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                        role="button" [disabled]="account.account.roles[0] != 'Admin' "
                                        style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                            class="fa fa-edit"
                                            style="font-size: 14px;color: var(--lightblue);"></i> Edit</button><button
                                        class="btn accordion-button collapsed" data-bs-toggle="collapse"
                                        data-bs-target=".tradelydetails" type="button" aria-controls></button>
                                </div>
                            </div>
                            <div class="row g-0 tradelydetails collapse show"
                                style="padding: 0.5rem;padding-top: 0;margin-bottom: 0.5rem;">
                                <div class="col-xxl-12 uidetailblock" style="padding-bottom: 0px;">
                                    <div>
                                        <div class="row">
                                            <div class="col-xxl-3 d-flex d-xl-flex d-xxl-flex align-items-center align-items-xl-center justify-content-xxl-start align-items-xxl-center"
                                                style="width: 30%;">
                                                <p class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                                    style="color: #0e0e82;padding-right: 18px;margin-left: 9px;">
                                                    Services
                                                    Provided</p>
                                            </div>
                                            <div class="col">

                                                <ng-container
                                                    *ngFor="let service of supplierCompanyDetails.servicesProvided">
                                                    <button class="btn text-nowrap" type="button"
                                                        style="font-size: 12px;background: var(--mainblue);color: var(--shadowgrey);border-radius: 166px;margin: 2px;">
                                                        {{service}}<a class="closeicon"
                                                            style="color: rgb(255,255,255);"></a></button>
                                                </ng-container>

                                                <!-- <button
                                                class="btn text-nowrap" type="button"
                                                style="font-size: 12px;background: var(--mainblue);color: var(--shadowgrey);border-radius: 166px;margin: 2px;">CNC
                                                Machining<a class="closeicon"
                                                    style="color: rgb(255,255,255);"></a></button>
                                                    
                                                    <button
                                                class="btn text-nowrap" type="button"
                                                style="font-size: 12px;background: var(--mainblue);color: var(--shadowgrey);border-radius: 166px;margin: 2px;">bending <a
                                                    class="closeicon"
                                                    style="color: rgb(255,255,255);"></a></button><button
                                                class="btn text-nowrap" type="button"
                                                style="font-size: 12px;background: var(--mainblue);color: var(--shadowgrey);border-radius: 166px;margin: 2px;">Laser
                                                Cutting<a class="closeicon"
                                                    style="color: rgb(255,255,255);"></a></button><button
                                                class="btn text-nowrap" type="button"
                                                style="font-size: 12px;background: var(--mainblue);color: var(--shadowgrey);border-radius: 166px;margin: 2px;">Drilling<a
                                                    class="closeicon" style="color: rgb(255,255,255);"></a></button> -->
                                            </div>
                                        </div>
                                        <div class="row row-cols-4 d-flex d-lg-flex justify-content-around justify-content-xxl-start"
                                            style="padding: auto;margin: auto;width: auto;">




                                            <div class="col d-flex d-lg-flex d-xxl-flex justify-content-center align-items-start justify-content-lg-center justify-content-xxl-center"
                                                style="padding: auto;padding-top: 1rem;padding-bottom: 1rem;padding-left: 1rem;">
                                                <div class="row g-0 rfqdetailrow"
                                                    style="width: 315px;padding-top: 0;max-width: 315px;">
                                                    <div class="col">
                                                        <div>
                                                            <p
                                                                class="text-nowrap d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                                Partnership Requirements</p>
                                                            <ng-container
                                                                *ngFor="let pr of supplierCompanyDetails.partnershipRequirements">
                                                                <p
                                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                                    {{pr.partnershipOptionName}}</p>
                                                            </ng-container>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col d-flex d-lg-flex d-xxl-flex justify-content-center align-items-start justify-content-lg-center justify-content-xxl-center"
                                                style="padding: auto;padding-top: 1rem;padding-bottom: 1rem;padding-left: 1rem;">
                                                <div class="row g-0 rfqdetailrow"
                                                    style="width: 315px;padding-top: 0;max-width: 315px;">
                                                    <div class="col">
                                                        <div>
                                                            <p
                                                                class="text-nowrap d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                                Payment Requirements</p>
                                                            <ng-container
                                                                *ngFor="let po of supplierCompanyDetails.paymentRequirements">
                                                                <p
                                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                                    {{po.paymentOptionName}}</p>
                                                            </ng-container>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col d-flex d-lg-flex d-xxl-flex justify-content-center align-items-start justify-content-lg-center justify-content-xxl-center"
                                                style="padding: auto;padding-top: 1rem;padding-bottom: 1rem;padding-left: 1rem;">
                                                <div class="row g-0 rfqdetailrow"
                                                    style="width: 315px;padding-top: 0;max-width: 315px;">
                                                    <div class="col">
                                                        <div>
                                                            <p
                                                                class="text-nowrap d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                                Packaging Requirements</p>
                                                            <ng-container
                                                                *ngFor="let package of supplierCompanyDetails.packagingCapabilities">
                                                                <p
                                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                                    {{package.packagingName}}</p>
                                                            </ng-container>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="col d-flex d-lg-flex d-xxl-flex justify-content-center align-items-start justify-content-lg-center justify-content-xxl-center"
                                                style="padding: auto;padding-top: 1rem;padding-bottom: 1rem;padding-left: 1rem;">
                                                <div class="row g-0 rfqdetailrow"
                                                    style="width: 315px;padding-top: 0;max-width: 315px;">
                                                    <div class="col">
                                                        <div>
                                                            <p
                                                                class="text-nowrap d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                                Delivery Capabilities</p>

                                                            <ng-container
                                                                *ngFor="let location of supplierCompanyDetails.deliveryCapabilities">
                                                                <p
                                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                                    {{location.locationName}}</p>
                                                            </ng-container>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>






                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="uirow bg-white rounded shadow-sm">
                            <div class="row g-0 rfqinner" style="padding: 0ox;padding-top: 0;margin-bottom: 0.5rem;">
                                <div class="col">
                                    <div>
                                        <ul role="tablist"
                                            class="nav nav-tabs d-flex d-sm-flex d-xxl-flex justify-content-start justify-content-sm-start justify-content-xxl-start">
                                            <li role="presentation" class="nav-item"><a #STab1 (click) = "changeTab(1)" role="tab" data-bs-toggle="tab"
                                                    class="nav-link bluetab {{userTab}}" href="#tab-1">Users</a></li>
                                            <!-- <li role="presentation" class="nav-item"><a role="tab" data-bs-toggle="tab"
                                                class="nav-link bluetab" href="#tab-2">RFQ Requirements</a></li> -->
                                            <li role="presentation" class="nav-item"><a #STab3 (click) = "changeTab(3)" role="tab" data-bs-toggle="tab"
                                                    class="nav-link {{preferredBuyerTab}}  bluetab" href="#tab-3">Preferred Buyers</a></li>
                                            <li class="nav-item" role="presentation"><a #STab4 (click) = "changeTab(4)" class="nav-link {{creditApplicationTab}} bluetab"
                                                    role="tab" data-bs-toggle="tab" href="#tab-4">Credit Applications</a></li>
                                        </ul>
                                        <div class="tab-content">
                                            <div role="tabpanel" class="tab-pane {{userTab}}" id="tab-1">
                                                <h1 class="text-center d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center"
                                                    style="font-size: 20px;color: var(--white);background: var(--lightblue);padding-top: 0.5rem;padding-bottom: 0.5rem;">
                                                    User Management</h1>
                                                <div style="padding: 1rem;padding-top: 0px;">
                                                    <div class="d-flex align-items-center">
                                                        <div class="input-group tablesearch"><input type="text"
                                                                [(ngModel)]="searchUsers"
                                                                (ngModelChange)="searchUserChange()"
                                                                class="bg-light form-control border-0 small info"
                                                                placeholder="Search for ..." /><button class="btn py-0"
                                                                type="button"
                                                                style="background: var(--lightblue);color: var(--shadowgrey);"><i
                                                                    class="fas fa-search"></i></button></div><button
                                                            data-bs-toggle="modal" data-bs-target="#adduser"
                                                            class="btn btn-secondary d-flex d-xxl-flex justify-content-around align-items-center justify-content-xxl-center align-items-xxl-center invoicebtn"
                                                            role="button"
                                                            [disabled]="account.account.roles[0] != 'Admin' "
                                                            style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                                class="fa fa-plus-circle"
                                                                style="font-size: 14px;color: var(--lightblue);"></i> Add
                                                            User</button>
                                                        <div class="d-md-flex align-items-md-center" id="modal-open">
                                                        </div>
                                                    </div>


                                                    <!-- INITIAL USERS TABLE DISPLAYED WITH NO SEARCH TEXT -->
                                                    <ng-container *ngIf="stringValidation(searchUserCheck)">


                                                        <div class="row">
                                                            <div class="col-xxl-12">
                                                                <section>
                                                                    <div class="table-responsive">
                                                                        <table class="table table-sm my-0 mydatatable">
                                                                            <thead class="buyerimage">
                                                                                <tr class="text-start">
                                                                                    <th class="blueheadsmall"
                                                                                        style="text-align: center;">NAME
                                                                                    </th>
                                                                                    <th class="blueheadsmall"
                                                                                        style="text-align: center;">
                                                                                        EMAIL
                                                                                    </th>
                                                                                    <th class="blueheadsmall"
                                                                                        style="text-align: center;">ROLE
                                                                                    </th>
                                                                                    <th class="blueheadsmall"
                                                                                        style="text-align: center;">TEAM
                                                                                    </th>
                                                                                    <th class="blueheadsmall"
                                                                                        style="text-align: center;">
                                                                                        NOTIFICATION
                                                                                    </th>
                                                                                    <th class="blueheadsmall"
                                                                                        style="text-align: center;">
                                                                                        STATUS
                                                                                    </th>
                                                                                    <th class="blueheadsmall"
                                                                                        style="text-align: center;">EDIT
                                                                                    </th>
                                                                                    <th class="blueheadsmall"></th>
                                                                                    <th class="blueheadsmall"></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>

                                                                                <ng-container
                                                                                    *ngIf="supplierCompanyDetails.supplierUsers.length > 0">
                                                                                    <ng-container
                                                                                        *ngFor="let user of pageOfItems ; let i = index">
                                                                                        <tr class="tablerow"
                                                                                            style="height: 0px;">
                                                                                            <td class="text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                {{user.firstName}}
                                                                                                {{user.lastName}} </td>
                                                                                            <td class="text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                {{user.userName}}
                                                                                            </td>
                                                                                            <td class="text-nowrap text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                {{user.role}}</td>
                                                                                            <td class="text-nowrap text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                {{user.team}}</td>
                                                                                            <td class="text-center"
                                                                                                style="padding-top: 9px;">
                                                                                                <div
                                                                                                    class="d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center">
                                                                                                    <div class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start"
                                                                                                        style="padding-right: 2px;">
                                                                                                        <ng-container
                                                                                                            *ngIf="!user.emailPreference">
                                                                                                            <i class="fa fa-minus-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                style="/*border-color: var(--lightblue);*/color: var(--bs-gray);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                        </ng-container>
                                                                                                        <ng-container
                                                                                                            *ngIf="user.emailPreference">
                                                                                                            <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                style="border-color: var(--lightblue);color: var(--lightblue);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                        </ng-container>
                                                                                                        <p
                                                                                                            class="bluetextthin">
                                                                                                            Email</p>
                                                                                                    </div>
                                                                                                    <!-- <div
                                                                                                        class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start">
                                                                                                        <ng-container
                                                                                                            *ngIf="!user.smsPreference">
                                                                                                            <i class="fa fa-minus-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                style="/*border-color: var(--lightblue);*/color: var(--bs-gray);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                        </ng-container>
                                                                                                        <ng-container
                                                                                                            *ngIf="user.smsPreference">
                                                                                                            <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                style="border-color: var(--lightblue);color: var(--lightblue);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                        </ng-container>
                                                                                                        <p
                                                                                                            class="bluetextthin">
                                                                                                            SMS</p>
                                                                                                    </div> -->
                                                                                                </div>
                                                                                            </td>
                                                                                            <td class="text-center bluetextthin"
                                                                                                style="text-align: center;padding-top: 9px;">
                                                                                                <div *ngIf="user.emailConfirmed"
                                                                                                    class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start">
                                                                                                    <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                        style="border-color: var(--lightblue);color: var(--lightblue);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                    <p
                                                                                                        class="bluetextthin">
                                                                                                        Active</p>
                                                                                                </div>
                                                                                                <div *ngIf="!user.emailConfirmed"
                                                                                                    class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start">
                                                                                                    <i class="fa fa-minus-circle d-xxl-flex justify-content-xxl-center"
                                                                                                        style="/*border-color: var(--lightblue);*/color: var(--bs-gray);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                    <p
                                                                                                        class="bluetextthin">
                                                                                                        Pending</p>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td
                                                                                                style="text-align: center;">
                                                                                                <button
                                                                                                    data-bs-toggle="modal"
                                                                                                    (click)="userModalPosition(i)"
                                                                                                    [attr.data-bs-target]="'#editUser' + i "
                                                                                                    class="btn btn-secondary invoicebtn"
                                                                                                    role="button"
                                                                                                    [disabled]="account.account.roles[0] != 'Admin' "
                                                                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                                                        class="fa fa-external-link"
                                                                                                        style="font-size: 14px;color: var(--lightblue);"></i></button>

                                                                                                <button
                                                                                                    style="display: none;"
                                                                                                    #CloseEditUserModal
                                                                                                    data-bs-toggle="modal"
                                                                                                    [attr.data-bs-target]="'#editUser' + userModalPos"></button>

                                                                                                <button
                                                                                                    style="display: none;"
                                                                                                    #DeleteUserModal
                                                                                                    data-bs-toggle="modal"
                                                                                                    [attr.data-bs-target]="'#deleteUser' + userModalPos"></button>
                                                                                            </td>
                                                                                        </tr>

                                                                                        <!-- EDIT USER -->

                                                                                        <div role="dialog" tabindex="-1"
                                                                                            class="modal fade"
                                                                                            data-bs-backdrop="static"
                                                                                            [attr.id]="'editUser' + i">
                                                                                            <div class="modal-dialog modal-dialog-centered"
                                                                                                role="document">
                                                                                                <div
                                                                                                    class="modal-content">

                                                                                                    <app-edit-user
                                                                                                        [editorUsername]="account.account.userName"
                                                                                                        [profileType]="'Supplier'"
                                                                                                        [greenformheader]="greenformheader"
                                                                                                        [blueformheader]="blueformheader"
                                                                                                        (closeEditUserModalCond)="EditUserModalTrigger($event)"
                                                                                                        (openDeleteUserModal)="openDeleteUserModal($event)"
                                                                                                        [currentUser]="user">
                                                                                                    </app-edit-user>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <!-- DELETE USER MODAL -->
                                                                                        <div role="dialog" tabindex="-1"
                                                                                            class="modal fade "
                                                                                            data-bs-backdrop="static"
                                                                                            [attr.id]="'deleteUser' + i">
                                                                                            <div class="modal-dialog modal-dialog-centered"
                                                                                                role="document">
                                                                                                <div
                                                                                                    class="modal-content">
                                                                                                    <div
                                                                                                        class="modal-header lightblueformheader">
                                                                                                        <h1 class="d-xl-flex align-items-xl-center whitehead"
                                                                                                            style="font-size: 16px;margin-bottom: 0;">
                                                                                                            Delete User

                                                                                                        </h1>

                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="modal-body">
                                                                                                        <div>
                                                                                                            <div
                                                                                                                class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                                                                                                <div class="col"
                                                                                                                    style="padding-left: 0;">
                                                                                                                    <p class="d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-center justify-content-md-center justify-content-lg-center blueheadsmall"
                                                                                                                        style="margin-bottom: 0;padding-left: 32px;">

                                                                                                                        Confirm
                                                                                                                        that
                                                                                                                        User
                                                                                                                        {{user.firstName}}
                                                                                                                        {{user.lastName}}
                                                                                                                        Is
                                                                                                                        to
                                                                                                                        be
                                                                                                                        Removed?
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div
                                                                                                        class="modal-footer d-lg-flex align-items-lg-center">

                                                                                                        <a #cancelRfqDismissBtn
                                                                                                            class="btn text-gray-500"
                                                                                                            role="button"
                                                                                                            data-bs-dismiss="modal">Close</a>

                                                                                                        <button
                                                                                                            (click)="deleteUser(user)"
                                                                                                            [disabled]="deletingUser"
                                                                                                            class="btn d-sm-flex d-lg-flex justify-content-sm-center align-items-sm-center justify-content-lg-center align-items-lg-center yellowbtn"
                                                                                                            role="button"
                                                                                                            style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Confirm
                                                                                                            <span
                                                                                                                *ngIf="deletingUser"
                                                                                                                class="spinner-border spinner-border-sm mr-1"></span>
                                                                                                        </button>

                                                                                                        <a #cancelRfqBtn
                                                                                                            data-bs-dismiss="modal"
                                                                                                            class="btn d-sm-flex d-lg-flex justify-content-sm-center align-items-sm-center justify-content-lg-center align-items-lg-center yellowbtn"
                                                                                                            role="button"
                                                                                                            style="display: none !important;border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Confirm
                                                                                                            Hidden</a>


                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>


                                                                                    </ng-container>
                                                                                </ng-container>


                                                                                <!-- <tr class="tablerow" style="height: 0px;">
                                                                                                                                    <td class="text-center bluetextthin"
                                                                                                                                        style="padding-top: 9px;">Askuus1</td>
                                                                                                                                    <td class="text-center bluetextthin"
                                                                                                                                        style="padding-top: 9px;">1dgd@gmail.com
                                                                                                                                    </td>
                                                                                                                                    <td class="text-center bluetextthin"
                                                                                                                                        style="padding-top: 9px;">Buyer</td>
                                                                                                                                    <td class="text-center bluetextthin"
                                                                                                                                        style="padding-top: 9px;">Team Name</td>
                                                                                                                                    <td class="text-center"
                                                                                                                                        style="padding-top: 9px;">
                                                                                                                                        <div
                                                                                                                                            class="d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center">
                                                                                                                                            <div
                                                                                                                                                class="form-check form-check-inline">
                                                                                                                                                <input type="checkbox"
                                                                                                                                                    class="form-check-input"
                                                                                                                                                    id="formCheck-8" /><label
                                                                                                                                                    class="form-check-label radio bluetext thin"
                                                                                                                                                    for="formCheck-1">Email</label>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                class="form-check form-check-inline">
                                                                                                                                                <input type="checkbox"
                                                                                                                                                    class="form-check-input"
                                                                                                                                                    id="formCheck-9" /><label
                                                                                                                                                    class="form-check-label radio bluetext thin"
                                                                                                                                                    for="formCheck-1">SMS</label>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </td>
                                                                                                                                    <td class="text-center bluetextthin"
                                                                                                                                        style="text-align: center;padding-top: 9px;">
                                                                                                                                        <div
                                                                                                                                            class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start">
                                                                                                                                            <i class="fa fa-minus-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                                style="/*border-color: var(--lightblue);*/color: var(--bs-gray);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                                                            <p class="bluetextthin">Pending</p>
                                                                                                                                        </div>
                                                                                                                                    </td>
                                                                                                                                    <td style="text-align: center;"><a
                                                                                                                                            class="btn btn-secondary invoicebtn"
                                                                                                                                            role="button"
                                                                                                                                            style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                                                                                                class="fa fa-external-link"
                                                                                                                                                style="font-size: 14px;color: var(--lightblue);"></i></a>
                                                                                                                                    </td>
                                                                                                                                </tr> -->
                                                                                <!-- <tr class="tablerow" style="height: 0px;">
                                                                                                                                    <td class="text-center bluetextthin"
                                                                                                                                        style="padding-top: 9px;">Askuus1</td>
                                                                                                                                    <td class="text-center bluetextthin"
                                                                                                                                        style="padding-top: 9px;">1dgd@gmail.com
                                                                                                                                    </td>
                                                                                                                                    <td class="text-center bluetextthin"
                                                                                                                                        style="padding-top: 9px;">Buyer</td>
                                                                                                                                    <td class="text-center bluetextthin"
                                                                                                                                        style="padding-top: 9px;">Team Name</td>
                                                                                                                                    <td class="text-center"
                                                                                                                                        style="padding-top: 9px;">
                                                                                                                                        <div
                                                                                                                                            class="d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center">
                                                                                                                                            <div
                                                                                                                                                class="form-check form-check-inline">
                                                                                                                                                <input type="checkbox"
                                                                                                                                                    class="form-check-input"
                                                                                                                                                    id="formCheck-10" /><label
                                                                                                                                                    class="form-check-label radio bluetext thin"
                                                                                                                                                    for="formCheck-1">Email</label>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                class="form-check form-check-inline">
                                                                                                                                                <input type="checkbox"
                                                                                                                                                    class="form-check-input"
                                                                                                                                                    id="formCheck-11" /><label
                                                                                                                                                    class="form-check-label radio bluetext thin"
                                                                                                                                                    for="formCheck-1">SMS</label>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </td>
                                                                                                                                    <td class="text-center bluetextthin"
                                                                                                                                        style="text-align: center;padding-top: 9px;">
                                                                                                                                        <div
                                                                                                                                            class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start">
                                                                                                                                            <i class="fa fa-times-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                                style="/*border-color: var(--lightblue);*/color: var(--bs-red);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                                                            <p class="text-nowrap bluetextthin">Not
                                                                                                                                                Active</p>
                                                                                                                                        </div>
                                                                                                                                    </td>
                                                                                                                                    <td style="text-align: center;"><a
                                                                                                                                            class="btn btn-secondary invoicebtn"
                                                                                                                                            role="button"
                                                                                                                                            style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                                                                                                class="fa fa-external-link"
                                                                                                                                                style="font-size: 14px;color: var(--lightblue);"></i></a>
                                                                                                                                    </td>
                                                                                                                                </tr> -->


                                                                            </tbody>
                                                                        </table>
                                                                    </div>


                                                                </section>

                                                            </div>


                                                        </div>
                                                        <div class="d-flex d-xxl-flex justify-content-end align-items-center justify-content-xxl-end align-items-xxl-center"
                                                            style="height: 0px;padding-top: 0px;margin-top: 2rem;">
                                                            <!-- pagination  -->
                                                            <pagination [items]="supplierCompanyDetails.supplierUsers"
                                                                (changePage)="onChangePage($event)"
                                                                [pageSize]="maxUserListSize"></pagination>

                                                        </div>
                                                    </ng-container>
                                                </div>



                                                <!-- USERS DISPLAY WITH SEARCH TEXT -->
                                                <ng-container *ngIf="!stringValidation(searchUserCheck)">
                                                    <div class="row">
                                                        <div class="col-xxl-12">
                                                            <section style="padding:1rem;">
                                                                <div class="table-responsive">
                                                                    <table class="table table-sm my-0 mydatatable">
                                                                        <thead class="buyerimage">
                                                                            <tr class="text-start">
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">NAME
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">EMAIL
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">ROLE
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">TEAM
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">
                                                                                    NOTIFICATION
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">STATUS
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">EDIT
                                                                                </th>
                                                                                <th class="blueheadsmall"></th>
                                                                                <th class="blueheadsmall"></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <ng-container
                                                                                *ngIf="supplierCompanyDetails.supplierUsers.length > 0">
                                                                                <ng-container
                                                                                    *ngIf="(supplierCompanyDetails.supplierUsers | filterPipe: removeSFWhiteSpace(searchUsers)) as result">
                                                                                    <ng-container
                                                                                        *ngFor="let user of result; let i = index">
                                                                                        <tr class="tablerow"
                                                                                            style="height: 0px;">
                                                                                            <td class="text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                {{user.firstName}}
                                                                                                {{user.lastName}} </td>
                                                                                            <td class="text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                {{user.userName}}
                                                                                            </td>
                                                                                            <td class="text-nowrap text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                {{user.role}}</td>
                                                                                            <td class="text-nowrap text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                {{user.team}}</td>
                                                                                            <td class="text-center"
                                                                                                style="padding-top: 9px;">
                                                                                                <div
                                                                                                    class="d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center">
                                                                                                    <div class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start"
                                                                                                        style="padding-right: 2px;">
                                                                                                        <ng-container
                                                                                                            *ngIf="!user.emailPreference">
                                                                                                            <i class="fa fa-minus-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                style="/*border-color: var(--lightblue);*/color: var(--bs-gray);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                        </ng-container>
                                                                                                        <ng-container
                                                                                                            *ngIf="user.emailPreference">
                                                                                                            <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                style="border-color: var(--lightblue);color: var(--lightblue);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                        </ng-container>
                                                                                                        <p
                                                                                                            class="bluetextthin">
                                                                                                            Email</p>
                                                                                                    </div>
                                                                                                    <!-- <div
                                                                                                        class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start">
                                                                                                        <ng-container
                                                                                                            *ngIf="!user.smsPreference">
                                                                                                            <i class="fa fa-minus-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                style="/*border-color: var(--lightblue);*/color: var(--bs-gray);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                        </ng-container>
                                                                                                        <ng-container
                                                                                                            *ngIf="user.smsPreference">
                                                                                                            <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                style="border-color: var(--lightblue);color: var(--lightblue);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                        </ng-container>
                                                                                                        <p
                                                                                                            class="bluetextthin">
                                                                                                            SMS</p>
                                                                                                    </div>-->
                                                                                                </div>
                                                                                            </td>
                                                                                            <td class="text-center bluetextthin"
                                                                                                style="text-align: center;padding-top: 9px;">
                                                                                                <div *ngIf="user.emailConfirmed"
                                                                                                    class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start">
                                                                                                    <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                        style="border-color: var(--lightblue);color: var(--lightblue);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                    <p
                                                                                                        class="bluetextthin">
                                                                                                        Active</p>
                                                                                                </div>
                                                                                                <div *ngIf="!user.emailConfirmed"
                                                                                                    class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start">
                                                                                                    <i class="fa fa-minus-circle d-xxl-flex justify-content-xxl-center"
                                                                                                        style="/*border-color: var(--lightblue);*/color: var(--bs-gray);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                    <p
                                                                                                        class="bluetextthin">
                                                                                                        Pending</p>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td
                                                                                                style="text-align: center;">
                                                                                                <a data-bs-toggle="modal"
                                                                                                    (click)="userModalPosition(i)"
                                                                                                    [attr.data-bs-target]="'#editUser' + i "
                                                                                                    class="btn btn-secondary invoicebtn"
                                                                                                    role="button"
                                                                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                                                        class="fa fa-external-link"
                                                                                                        style="font-size: 14px;color: var(--lightblue);"></i></a>

                                                                                                <button
                                                                                                    style="display: none;"
                                                                                                    #CloseEditUserModal
                                                                                                    data-bs-toggle="modal"
                                                                                                    [attr.data-bs-target]="'#editUser' + userModalPos"></button>

                                                                                                <button
                                                                                                    style="display: none;"
                                                                                                    #DeleteUserModal
                                                                                                    data-bs-toggle="modal"
                                                                                                    [attr.data-bs-target]="'#deleteUser' + userModalPos"></button>
                                                                                            </td>
                                                                                        </tr>

                                                                                        <!-- EDIT USER -->

                                                                                        <div role="dialog" tabindex="-1"
                                                                                            class="modal fade"
                                                                                            data-bs-backdrop="static"
                                                                                            [attr.id]="'editUser' + i">
                                                                                            <div class="modal-dialog modal-dialog-centered"
                                                                                                role="document">
                                                                                                <div
                                                                                                    class="modal-content">

                                                                                                    <app-edit-user
                                                                                                        [editorUsername]="account.account.userName"
                                                                                                        [profileType]="'Supplier'"
                                                                                                        [greenformheader]="greenformheader"
                                                                                                        [blueformheader]="blueformheader"
                                                                                                        (closeEditUserModalCond)="EditUserModalTrigger($event)"
                                                                                                        (openDeleteUserModal)="openDeleteUserModal($event)"
                                                                                                        [currentUser]="user">
                                                                                                    </app-edit-user>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <!-- DELETE USER MODAL -->
                                                                                        <div role="dialog" tabindex="-1"
                                                                                            class="modal fade "
                                                                                            data-bs-backdrop="static"
                                                                                            [attr.id]="'deleteUser' + i">
                                                                                            <div class="modal-dialog modal-dialog-centered"
                                                                                                role="document">
                                                                                                <div
                                                                                                    class="modal-content">
                                                                                                    <div
                                                                                                        class="modal-header lightblueformheader">
                                                                                                        <h1 class="d-xl-flex align-items-xl-center whitehead"
                                                                                                            style="font-size: 16px;margin-bottom: 0;">
                                                                                                            Delete User

                                                                                                        </h1>

                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="modal-body">
                                                                                                        <div>
                                                                                                            <div
                                                                                                                class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                                                                                                <div class="col"
                                                                                                                    style="padding-left: 0;">
                                                                                                                    <p class="d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-center justify-content-md-center justify-content-lg-center blueheadsmall"
                                                                                                                        style="margin-bottom: 0;padding-left: 32px;">

                                                                                                                        Confirm
                                                                                                                        that
                                                                                                                        User
                                                                                                                        {{user.firstName}}
                                                                                                                        {{user.lastName}}
                                                                                                                        Is
                                                                                                                        to
                                                                                                                        be
                                                                                                                        Removed?
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div
                                                                                                        class="modal-footer d-lg-flex align-items-lg-center">

                                                                                                        <a #cancelRfqDismissBtn
                                                                                                            class="btn text-gray-500"
                                                                                                            role="button"
                                                                                                            data-bs-dismiss="modal">Close</a>

                                                                                                        <button
                                                                                                            (click)="deleteUser(user)"
                                                                                                            [disabled]="deletingUser"
                                                                                                            class="btn d-sm-flex d-lg-flex justify-content-sm-center align-items-sm-center justify-content-lg-center align-items-lg-center yellowbtn"
                                                                                                            role="button"
                                                                                                            style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Confirm
                                                                                                            <span
                                                                                                                *ngIf="deletingUser"
                                                                                                                class="spinner-border spinner-border-sm mr-1"></span>
                                                                                                        </button>

                                                                                                        <a #cancelRfqBtn
                                                                                                            data-bs-dismiss="modal"
                                                                                                            class="btn d-sm-flex d-lg-flex justify-content-sm-center align-items-sm-center justify-content-lg-center align-items-lg-center yellowbtn"
                                                                                                            role="button"
                                                                                                            style="display: none !important;border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Confirm
                                                                                                            Hidden</a>


                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </ng-container>

                                                                                    <div *ngIf="result.length == 0">
                                                                                        <div
                                                                                            class="d-flex flex-column justify-content-center align-items-center">
                                                                                            <h1 class="text-center text-center bluehead thin"
                                                                                                style="font-size: 16px;margin-top: 1rem; ">
                                                                                                No search results
                                                                                            </h1>

                                                                                        </div>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </ng-container>
                                                                            <!-- <tr class="tablerow" style="height: 0px;">
                                                                                                                                        <td class="text-center bluetextthin"
                                                                                                                                            style="padding-top: 9px;">Askuus1</td>
                                                                                                                                        <td class="text-center bluetextthin"
                                                                                                                                            style="padding-top: 9px;">1dgd@gmail.com
                                                                                                                                        </td>
                                                                                                                                        <td class="text-center bluetextthin"
                                                                                                                                            style="padding-top: 9px;">Buyer</td>
                                                                                                                                        <td class="text-center bluetextthin"
                                                                                                                                            style="padding-top: 9px;">Team Name</td>
                                                                                                                                        <td class="text-center"
                                                                                                                                            style="padding-top: 9px;">
                                                                                                                                            <div
                                                                                                                                                class="d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center">
                                                                                                                                                <div
                                                                                                                                                    class="form-check form-check-inline">
                                                                                                                                                    <input type="checkbox"
                                                                                                                                                        class="form-check-input"
                                                                                                                                                        id="formCheck-8" /><label
                                                                                                                                                        class="form-check-label radio bluetext thin"
                                                                                                                                                        for="formCheck-1">Email</label>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    class="form-check form-check-inline">
                                                                                                                                                    <input type="checkbox"
                                                                                                                                                        class="form-check-input"
                                                                                                                                                        id="formCheck-9" /><label
                                                                                                                                                        class="form-check-label radio bluetext thin"
                                                                                                                                                        for="formCheck-1">SMS</label>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </td>
                                                                                                                                        <td class="text-center bluetextthin"
                                                                                                                                            style="text-align: center;padding-top: 9px;">
                                                                                                                                            <div
                                                                                                                                                class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start">
                                                                                                                                                <i class="fa fa-minus-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                                    style="/*border-color: var(--lightblue);*/color: var(--bs-gray);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                                                                <p class="bluetextthin">Pending</p>
                                                                                                                                            </div>
                                                                                                                                        </td>
                                                                                                                                        <td style="text-align: center;"><a
                                                                                                                                                class="btn btn-secondary invoicebtn"
                                                                                                                                                role="button"
                                                                                                                                                style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                                                                                                    class="fa fa-external-link"
                                                                                                                                                    style="font-size: 14px;color: var(--lightblue);"></i></a>
                                                                                                                                        </td>
                                                                                                                                    </tr> -->
                                                                            <!-- <tr class="tablerow" style="height: 0px;">
                                                                                                                                        <td class="text-center bluetextthin"
                                                                                                                                            style="padding-top: 9px;">Askuus1</td>
                                                                                                                                        <td class="text-center bluetextthin"
                                                                                                                                            style="padding-top: 9px;">1dgd@gmail.com
                                                                                                                                        </td>
                                                                                                                                        <td class="text-center bluetextthin"
                                                                                                                                            style="padding-top: 9px;">Buyer</td>
                                                                                                                                        <td class="text-center bluetextthin"
                                                                                                                                            style="padding-top: 9px;">Team Name</td>
                                                                                                                                        <td class="text-center"
                                                                                                                                            style="padding-top: 9px;">
                                                                                                                                            <div
                                                                                                                                                class="d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center">
                                                                                                                                                <div
                                                                                                                                                    class="form-check form-check-inline">
                                                                                                                                                    <input type="checkbox"
                                                                                                                                                        class="form-check-input"
                                                                                                                                                        id="formCheck-10" /><label
                                                                                                                                                        class="form-check-label radio bluetext thin"
                                                                                                                                                        for="formCheck-1">Email</label>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    class="form-check form-check-inline">
                                                                                                                                                    <input type="checkbox"
                                                                                                                                                        class="form-check-input"
                                                                                                                                                        id="formCheck-11" /><label
                                                                                                                                                        class="form-check-label radio bluetext thin"
                                                                                                                                                        for="formCheck-1">SMS</label>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </td>
                                                                                                                                        <td class="text-center bluetextthin"
                                                                                                                                            style="text-align: center;padding-top: 9px;">
                                                                                                                                            <div
                                                                                                                                                class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start">
                                                                                                                                                <i class="fa fa-times-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                                    style="/*border-color: var(--lightblue);*/color: var(--bs-red);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                                                                <p class="text-nowrap bluetextthin">Not
                                                                                                                                                    Active</p>
                                                                                                                                            </div>
                                                                                                                                        </td>
                                                                                                                                        <td style="text-align: center;"><a
                                                                                                                                                class="btn btn-secondary invoicebtn"
                                                                                                                                                role="button"
                                                                                                                                                style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                                                                                                    class="fa fa-external-link"
                                                                                                                                                    style="font-size: 14px;color: var(--lightblue);"></i></a>
                                                                                                                                        </td>
                                                                                                                                    </tr> -->


                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>
                                                    <!-- <nav class="d-flex d-xxl-flex justify-content-end align-items-center justify-content-xxl-end align-items-xxl-center"
                                                                                                                style="height: 0px;padding-top: 0px;margin-top: 2rem;">
                                                                                                                <ul class="pagination pagination-sm">
                                                                                                                    <li class="page-item"><a class="page-link"
                                                                                                                            aria-label="Previous"><span
                                                                                                                                aria-hidden="true">«</span></a>
                                                                                                                    </li>
                                                                                                                    <li class="page-item"><a class="page-link">1</a></li>
                                                                                                                    <li class="page-item"><a class="page-link">2</a></li>
                                                                                                                    <li class="page-item"><a class="page-link">3</a></li>
                                                                                                                    <li class="page-item"><a class="page-link">4</a></li>
                                                                                                                    <li class="page-item"><a class="page-link">5</a></li>
                                                                                                                    <li class="page-item"><a class="page-link"
                                                                                                                            aria-label="Next"><span aria-hidden="true">»</span></a>
                                                                                                                    </li>
                                                                                                                </ul>
                                                                                                            </nav> -->

                                                </ng-container>



                                            </div>
                                            <div role="tabpanel" class="tab-pane" id="tab-2">
                                                <h1 class="text-center d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center"
                                                    style="font-size: 20px;color: var(--white);background: var(--lightblue);padding-top: 0.5rem;padding-bottom: 0.5rem;">
                                                    RFQ Requirements</h1>
                                                <div class="row">
                                                    <div class="col d-flex d-lg-flex d-xxl-flex justify-content-center align-items-center justify-content-lg-center justify-content-xxl-center align-items-xxl-center"
                                                        style="padding: auto;padding-top: 1rem;padding-bottom: 1rem;padding-left: 1rem;">
                                                        <div class="row g-0 rfqdetailrow"
                                                            style="width: 315px;padding-top: 0;max-width: 315px;">
                                                            <div class="col">
                                                                <div>
                                                                    <p
                                                                        class="text-nowrap d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                                        <input type="checkbox"
                                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />Vendor
                                                                        Requirements <a><i class="fa fa-question-circle"
                                                                                style="color: var(--lightblue);font-size: 12px;"></i></a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col d-flex d-md-flex d-xxl-flex justify-content-end align-items-center justify-content-md-end justify-content-xxl-end align-items-xxl-center">
                                                                <a data-bs-toggle="modal" data-bs-target="#RFQdetails"
                                                                    class="btn btn-secondary d-md-flex align-items-md-center invoicebtn"
                                                                    role="button"
                                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);padding-bottom: 3px;margin-left: 0;"><i
                                                                        class="fa fa-external-link"
                                                                        style="font-size: 14px;color: var(--lightblue);"></i></a>
                                                            </div>
                                                            <div class="col shadow-sm rounded mb-2"
                                                                style="background: var(--shadowgrey);padding-top: 0.5rem;padding-bottom: 0.5rem;">
                                                                <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;">
                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin shift"
                                                                        style="margin-bottom: 0;">Vendor Name</p>
                                                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                        style="margin-bottom: 0;">ASKUUUUS</p>
                                                                </div>
                                                                <div class="d-flex d-xxl-flex justify-content-between align-items-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;">
                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                        style="margin-bottom: 0;">Vendor Number</p>
                                                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                        style="margin-bottom: 0;">12123323<br /></p>
                                                                </div>
                                                                <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                                    style="width: 223.391px;"></div>
                                                            </div>
                                                            <div class="col">
                                                                <div>
                                                                    <p
                                                                        class="text-nowrap d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                                        <input type="checkbox"
                                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />B-BBEE
                                                                        Requirements <a><i class="fa fa-question-circle"
                                                                                style="color: var(--lightblue);font-size: 12px;"></i></a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col d-flex d-md-flex d-xxl-flex justify-content-end align-items-center justify-content-md-end justify-content-xxl-end align-items-xxl-center">
                                                                <a data-bs-toggle="modal" data-bs-target="#RFQdetails"
                                                                    class="btn btn-secondary d-md-flex align-items-md-center invoicebtn"
                                                                    role="button"
                                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);padding-bottom: 3px;margin-left: 0;"><i
                                                                        class="fa fa-external-link"
                                                                        style="font-size: 14px;color: var(--lightblue);"></i></a>
                                                            </div>
                                                            <div class="col d-lg-flex align-items-lg-center shadow-sm rounded mb-2"
                                                                style="background: var(--shadowgrey);padding-top: 0.5rem;padding-bottom: 0.5rem;">
                                                                <div class="d-flex d-lg-flex d-xxl-flex flex-row justify-content-center align-items-center align-items-lg-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;">
                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin shift"
                                                                        style="margin-bottom: 0;">Level</p>
                                                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                        style="margin-bottom: 0;"></p>
                                                                    <div class="d-flex d-lg-flex" role="group"
                                                                        aria-label="Basic checkbox toggle button group">
                                                                        <div class="btn-group bluebtn" role="group"
                                                                            aria-label="Basic checkbox toggle button group"
                                                                            style="padding-top: 5px;"><input
                                                                                type="radio" id="lvl1"
                                                                                class="btn-check form-check-input"
                                                                                name="flexRadioDefaultRFQ" /><label
                                                                                class="form-label form-check-label btn btn-outline-info"
                                                                                for="lvl1"
                                                                                style="border-radius: -113px;font-size: 14px;"
                                                                                name="flexRadioDefault">1</label></div>
                                                                        <div class="btn-group bluebtn" role="group"
                                                                            aria-label="Basic checkbox toggle button group"
                                                                            style="padding-top: 5px;"><input
                                                                                type="radio" id="lvl2"
                                                                                class="btn-check form-check-input"
                                                                                name="flexRadioDefaultRFQ" /><label
                                                                                class="form-label form-check-label btn btn-outline-info"
                                                                                for="lvl2"
                                                                                style="border-radius: -113px;font-size: 14px;"
                                                                                name="flexRadioDefault">2</label></div>
                                                                        <div class="d-lg-flex align-items-lg-center btn-group bluebtn"
                                                                            role="group"
                                                                            aria-label="Basic checkbox toggle button group"
                                                                            style="padding-top: 5px;"><input
                                                                                type="radio" id="lvl3"
                                                                                class="btn-check form-check-input"
                                                                                name="flexRadioDefaultRFQ" /><label
                                                                                class="form-label form-check-label btn btn-outline-info"
                                                                                for="lvl3"
                                                                                style="border-radius: -113px;font-size: 14px;"
                                                                                name="flexRadioDefault">3</label></div>
                                                                        <div class="btn-group bluebtn" role="group"
                                                                            aria-label="Basic checkbox toggle button group"
                                                                            style="padding-top: 5px;"><input
                                                                                type="radio" id="lvl4"
                                                                                class="btn-check form-check-input"
                                                                                name="flexRadioDefaultRFQ" /><label
                                                                                class="form-label form-check-label btn btn-outline-info"
                                                                                for="lvl4"
                                                                                style="border-radius: -113px;font-size: 14px;"
                                                                                name="flexRadioDefault">4</label></div>
                                                                        <div class="btn-group bluebtn" role="group"
                                                                            aria-label="Basic checkbox toggle button group"
                                                                            style="padding-top: 5px;"><input
                                                                                type="radio" id="lvl5"
                                                                                class="btn-check form-check-input"
                                                                                name="flexRadioDefaultRFQ" /><label
                                                                                class="form-label form-check-label btn btn-outline-info"
                                                                                for="lvl5"
                                                                                style="border-radius: -113px;font-size: 14px;"
                                                                                name="flexRadioDefault">5</label></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col d-flex d-lg-flex d-xxl-flex justify-content-center align-items-center justify-content-lg-center justify-content-xxl-center align-items-xxl-center"
                                                        style="padding-top: 1rem;padding-bottom: 1rem;">
                                                        <div class="row g-0 d-xxl-flex align-items-xxl-center"
                                                            style="max-width: 300px;">
                                                            <div class="col shadow-sm rounded"
                                                                style="background: var(--shadowgrey);padding: 0;padding-bottom: 0px;padding-top: 0px;">
                                                                <div>
                                                                    <p
                                                                        class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                                        <input type="checkbox"
                                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />HACCP
                                                                        Cert requrements <a><i
                                                                                class="fa fa-question-circle"
                                                                                style="color: var(--lightblue);font-size: 12px;"></i></a>
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p
                                                                        class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                                        <input type="checkbox"
                                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />SUV
                                                                        Requirements <a><i class="fa fa-question-circle"
                                                                                style="color: var(--lightblue);font-size: 12px;"></i></a>
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p
                                                                        class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                                        <input type="checkbox"
                                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />TUV
                                                                        Cert Requirements <a><i
                                                                                class="fa fa-question-circle"
                                                                                style="color: var(--lightblue);font-size: 12px;"></i></a>
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p
                                                                        class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                                        <input type="checkbox"
                                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />ISO
                                                                        Requirement <a><i class="fa fa-question-circle"
                                                                                style="color: var(--lightblue);font-size: 12px;"></i></a>
                                                                    </p>
                                                                </div>
                                                                <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div role="tabpanel" class="tab-pane {{preferredBuyerTab}}" id="tab-3">
                                                <div>
                                                    <h1 class="text-center d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center"
                                                        style="font-size: 20px;color: var(--white);background: var(--lightblue);padding-top: 0.5rem;padding-bottom: 0.5rem;">
                                                        Buyers</h1>
                                                </div>
                                                <div style="padding: 1rem;padding-top: 0px;">
                                                    <div
                                                        class="d-flex d-sm-flex d-md-flex d-xxl-flex align-items-center justify-content-sm-start align-items-sm-center justify-content-md-start justify-content-xxl-start align-items-xxl-center">
                                                        <div class="input-group tablesearch"><input
                                                                class="bg-light form-control border-0 small info ng-pristine ng-valid ng-touched"
                                                                type="text" [(ngModel)]="searchPreferredBuyers"
                                                                class="bg-light form-control border-0 small"
                                                                placeholder="Search for ..." /><button
                                                                style="border:0px; background: var(--lightblue); color: var(--shadowgrey);"
                                                                class="btn  py-0" type="button"><i
                                                                    class="fas fa-search"></i></button></div>
                                                        <div class="d-md-flex align-items-md-center" id="modal-open-1">
                                                        </div>
                                                        <button (click)="getInitialBuyerList()"
                                                            [disabled]="openBuyerModal || account.account.roles[0] != 'Admin' "
                                                            class="btn btn-secondary d-flex d-xxl-flex justify-content-around align-items-center justify-content-xxl-center align-items-xxl-center invoicebtn"
                                                            role="button"
                                                            style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                                class="fa fa-plus-circle"
                                                                style="font-size: 14px;color: var(--lightblue);"></i><span
                                                                *ngIf="openBuyerModal"
                                                                class="spinner-border spinner-border-sm mr-1"></span>Add
                                                            Preferred Buyer</button>

                                                        <button #addBuyerOpenBtn style="display: none !important;"
                                                            data-bs-toggle="modal" data-bs-target="#AddBuyer"></button>
                                                    </div>

                                                    <!-- display with search -->
                                                    <ng-container *ngIf="!stringValidation(searchPreferredBuyers)">
                                                        <div class="row">
                                                            <div class="col-xxl-12">
                                                                <section>
                                                                    <div class="table-responsive">
                                                                        <table class="table table-sm my-0 mydatatable">
                                                                            <thead class="buyerimage">
                                                                                <tr class="text-start">
                                                                                    <th class="blueheadsmall"
                                                                                        style="text-align: center;">
                                                                                        BUYER
                                                                                    </th>

                                                                                    <th class="blueheadsmall"
                                                                                        style="text-align: center;">TEAM
                                                                                    </th>
                                                                                    <th class="blueheadsmall"
                                                                                        style="text-align: center;">
                                                                                        EMAIL
                                                                                    </th>
                                                                                    <th class="blueheadsmall"
                                                                                        style="text-align: center;">
                                                                                        PHONE
                                                                                    </th>
                                                                                    <th class="blueheadsmall"></th>
                                                                                    <th
                                                                                        class="text-center blueheadsmall">
                                                                                        REMOVE
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <ng-container
                                                                                    *ngIf="(supplierCompanyDetails.preferredBuyers | filterPipe: removeSFWhiteSpace(searchPreferredBuyers)) as result">
                                                                                    <ng-container
                                                                                        *ngFor="let buyer of result">
                                                                                        <tr class="tablerow"
                                                                                            style="height: 0px;">
                                                                                            <td class="text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                {{buyer.buyerName}}
                                                                                            </td>

                                                                                            <td class="text-nowrap text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                {{buyer.team}}</td>
                                                                                            <td class="text-nowrap text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                <a class="btn btn-secondary invoicebtn"
                                                                                                    role="button"
                                                                                                    style="font-size: 14px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);padding: 0;">
                                                                                                    {{buyer.buyerEmail}} <i
                                                                                                        class="icon ion-android-mail"
                                                                                                        style="font-size: 14px;color: var(--lightblue);"></i></a>
                                                                                            </td>
                                                                                            <td class="text-nowrap text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                {{buyer.buyerContactNumber}}
                                                                                            </td>
                                                                                            <td class="text-center bluetextthin"
                                                                                                style="text-align: center;padding-top: 0;">
                                                                                                <p class="bluetextthin">
                                                                                                </p>
                                                                                            </td>
                                                                                            <td
                                                                                                style="text-align: center;">
                                                                                                <button
                                                                                                    (click)="removePreferedBuyer(buyer.buyerId, buyer.team)"
                                                                                                    class="btn btn-secondary invoicebtn"
                                                                                                    role="button"
                                                                                                    [disabled]="(removingPreferredBuyer && selectedPreferredBuyerId == buyer.buyerID)  || account.account.roles[0] != 'Admin'"
                                                                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                                                        class="fa fa-trash"
                                                                                                        style="font-size: 14px;color: var(--bs-red);"></i>
                                                                                                    <span
                                                                                                        *ngIf="removingPreferredBuyer && selectedPreferredBuyerId == buyer.buyerID "
                                                                                                        class="spinner-border spinner-border-sm mr-1"></span></button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-container>

                                                                                    <div *ngIf="result.length == 0">
                                                                                        <div
                                                                                            class="d-flex flex-column justify-content-center align-items-center">
                                                                                            <h1 class="text-center text-center bluehead thin"
                                                                                                style="font-size: 16px;margin-top: 1rem; ">
                                                                                                No search results
                                                                                            </h1>

                                                                                        </div>
                                                                                    </div>

                                                                                </ng-container>
                                                                                <!-- <tr class="expandedrow InProgress collapse">
                                                                                                    <td class="blueheadsmall" colspan="9"
                                                                                                        style="width: 886px;">
                                                                                                        <div
                                                                                                            class="d-md-flex d-xl-flex align-items-xxl-center">
                                                                                                            <div class="col-5 col-xxl-6 shift"
                                                                                                                style="padding-left: 1rem;">
                                                                                                                <div>
                                                                                                                    <div class="row">
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Company:</p>
                                                                                                                        </div>
                                                                                                                        <div class="col-sm-6">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Magic Metal</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row">
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Verified:</p>
                                                                                                                        </div>
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Yes</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row">
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Past Jobs:</p>
                                                                                                                        </div>
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                3</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row">
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Rating:</p>
                                                                                                                        </div>
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="uiinfo"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                <i class="fa fa-star"
                                                                                                                                    style="color: var(--yellow);"></i><i
                                                                                                                                    class="fa fa-star"
                                                                                                                                    style="color: var(--yellow);"></i><i
                                                                                                                                    class="fa fa-star"
                                                                                                                                    style="color: var(--yellow);"></i><i
                                                                                                                                    class="fa fa-star-o"
                                                                                                                                    style="color: var(--yellow);"></i>
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row">
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Location:</p>
                                                                                                                        </div>
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Boksburg,
                                                                                                                                Gauteng
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <div class="row">
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Delivery
                                                                                                                                available:
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Yes</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row">
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Vendor numbers:
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                A1B2C3D4</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row">
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                ISO:</p>
                                                                                                                        </div>
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Yes</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row">
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                BEE level:</p>
                                                                                                                        </div>
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                5</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row">
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Purchase Order
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div class="col"><a
                                                                                                                                class="btn btn-secondary d-flex d-xxl-flex align-items-center \"
                                                                                                                                role="button"
                                                                                                                                style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);padding: 0;"><i
                                                                                                                                    class="fas fa-upload"
                                                                                                                                    style="font-size: 14px;color: var(--lightblue);"></i> View</a>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="d-md-flex d-xl-flex d-xxl-flex justify-content-md-center align-items-md-center justify-content-lg-center align-items-xl-center justify-content-xxl-center align-items-xxl-center"
                                                                                                                style="width: 100%;">
                                                                                                                <div class="col-auto"
                                                                                                                    style="background: var(--shadowgrey);border-radius: 10px;padding: 0.5rem;width: 182.812px;">
                                                                                                                    <div class="row row-cols-2 d-md-flex align-items-md-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-md-3 col-lg-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                            </p><i
                                                                                                                                class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                style="border-color: var(--lightblue);color: var(--lightblue);font-size: 20px;padding-left: 0;"></i>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Verified</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row row-cols-2 d-md-flex align-items-md-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-md-3 col-lg-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                    style="border-color: var(--lightblue);color: var(--lightblue);font-size: 20px;padding-left: 0;"></i>
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 col-xxl-8 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="text-nowrap bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Gold Member</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row row-cols-2 d-md-flex align-items-md-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                    style="border-color: var(--lightblue);color: var(--lightblue);font-size: 20px;padding-left: 0;"></i>
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 col-xxl-9 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="text-nowrap bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Credit Approved
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row row-cols-2 d-md-flex justify-content-xxl-start align-items-xxl-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                <i class="fa fa-times-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                    style="border-color: var(--lightblue);color: var(--mainblue);font-size: 20px;padding-left: 0;"></i>
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Manufacturer</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row row-cols-2 d-md-flex justify-content-xxl-start align-items-xxl-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <i class="fa fa-times-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                style="border-color: var(--lightblue);color: var(--mainblue);font-size: 20px;padding-left: 0;"></i>
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Packaged</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row row-cols-2 d-md-flex justify-content-xxl-start align-items-xxl-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <i class="fa fa-times-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                style="border-color: var(--lightblue);color: var(--mainblue);font-size: 20px;padding-left: 0;"></i>
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 col-xxl-9 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="text-nowrap bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Vendor Number
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr> -->

                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>


                                                    </ng-container>

                                                    <!-- display without search -->
                                                    <ng-container *ngIf="stringValidation(searchPreferredBuyers)">
                                                        <div class="row">
                                                            <div class="col-xxl-12">
                                                                <section>
                                                                    <div class="table-responsive">
                                                                        <table class="table table-sm my-0 mydatatable">
                                                                            <thead class="buyerimage">
                                                                                <tr class="text-start">
                                                                                    <th class="blueheadsmall"
                                                                                        style="text-align: center;">
                                                                                        SUPPLIER
                                                                                    </th>

                                                                                    <th class="blueheadsmall"
                                                                                        style="text-align: center;">TEAM
                                                                                    </th>
                                                                                    <th class="blueheadsmall"
                                                                                        style="text-align: center;">
                                                                                        EMAIL
                                                                                    </th>
                                                                                    <th class="blueheadsmall"
                                                                                        style="text-align: center;">
                                                                                        PHONE
                                                                                    </th>
                                                                                    <th class="blueheadsmall"></th>
                                                                                    <th
                                                                                        class="text-center blueheadsmall">
                                                                                        REMOVE
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <ng-container>
                                                                                    <ng-container
                                                                                        *ngFor="let buyer of pageOfItemsPS">
                                                                                        <tr class="tablerow"
                                                                                            style="height: 0px;">
                                                                                            <td class="text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                {{buyer.buyerName}}
                                                                                            </td>

                                                                                            <td class="text-nowrap text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                {{buyer.team}}</td>
                                                                                            <td class="text-nowrap text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                <a class="btn btn-secondary invoicebtn"
                                                                                                    role="button"
                                                                                                    style="font-size: 14px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);padding: 0;">
                                                                                                    {{buyer.buyerEmail}} <i
                                                                                                        class="icon ion-android-mail"
                                                                                                        style="font-size: 14px;color: var(--lightblue);"></i></a>
                                                                                            </td>
                                                                                            <td class="text-nowrap text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                {{buyer.buyerContactNumber}}
                                                                                            </td>
                                                                                            <td class="text-center bluetextthin"
                                                                                                style="text-align: center;padding-top: 0;">
                                                                                                <p class="bluetextthin">
                                                                                                </p>
                                                                                            </td>
                                                                                            <td
                                                                                                style="text-align: center;">
                                                                                                <button
                                                                                                    (click)="removePreferedBuyer(buyer.buyerId, buyer.team)"
                                                                                                    class="btn btn-secondary invoicebtn"
                                                                                                    role="button"
                                                                                                    [disabled]="(removingPreferredBuyer && selectedPreferredBuyerId == buyer.buyerID)  || account.account.roles[0] != 'Admin'"
                                                                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                                                        class="fa fa-trash"
                                                                                                        style="font-size: 14px;color: var(--bs-red);"></i></button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-container>
                                                                                </ng-container>
                                                                                <!-- <tr class="expandedrow InProgress collapse">
                                                                                                    <td class="blueheadsmall" colspan="9"
                                                                                                        style="width: 886px;">
                                                                                                        <div
                                                                                                            class="d-md-flex d-xl-flex align-items-xxl-center">
                                                                                                            <div class="col-5 col-xxl-6 shift"
                                                                                                                style="padding-left: 1rem;">
                                                                                                                <div>
                                                                                                                    <div class="row">
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Company:</p>
                                                                                                                        </div>
                                                                                                                        <div class="col-sm-6">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Magic Metal</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row">
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Verified:</p>
                                                                                                                        </div>
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Yes</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row">
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Past Jobs:</p>
                                                                                                                        </div>
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                3</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row">
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Rating:</p>
                                                                                                                        </div>
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="uiinfo"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                <i class="fa fa-star"
                                                                                                                                    style="color: var(--yellow);"></i><i
                                                                                                                                    class="fa fa-star"
                                                                                                                                    style="color: var(--yellow);"></i><i
                                                                                                                                    class="fa fa-star"
                                                                                                                                    style="color: var(--yellow);"></i><i
                                                                                                                                    class="fa fa-star-o"
                                                                                                                                    style="color: var(--yellow);"></i>
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row">
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Location:</p>
                                                                                                                        </div>
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Boksburg,
                                                                                                                                Gauteng
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <div class="row">
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Delivery
                                                                                                                                available:
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Yes</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row">
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Vendor numbers:
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                A1B2C3D4</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row">
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                ISO:</p>
                                                                                                                        </div>
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Yes</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row">
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                BEE level:</p>
                                                                                                                        </div>
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                5</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row">
                                                                                                                        <div class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Purchase Order
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div class="col"><a
                                                                                                                                class="btn btn-secondary d-flex d-xxl-flex align-items-center \"
                                                                                                                                role="button"
                                                                                                                                style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);padding: 0;"><i
                                                                                                                                    class="fas fa-upload"
                                                                                                                                    style="font-size: 14px;color: var(--lightblue);"></i> View</a>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="d-md-flex d-xl-flex d-xxl-flex justify-content-md-center align-items-md-center justify-content-lg-center align-items-xl-center justify-content-xxl-center align-items-xxl-center"
                                                                                                                style="width: 100%;">
                                                                                                                <div class="col-auto"
                                                                                                                    style="background: var(--shadowgrey);border-radius: 10px;padding: 0.5rem;width: 182.812px;">
                                                                                                                    <div class="row row-cols-2 d-md-flex align-items-md-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-md-3 col-lg-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                            </p><i
                                                                                                                                class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                style="border-color: var(--lightblue);color: var(--lightblue);font-size: 20px;padding-left: 0;"></i>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Verified</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row row-cols-2 d-md-flex align-items-md-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-md-3 col-lg-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                    style="border-color: var(--lightblue);color: var(--lightblue);font-size: 20px;padding-left: 0;"></i>
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 col-xxl-8 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="text-nowrap bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Gold Member</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row row-cols-2 d-md-flex align-items-md-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                    style="border-color: var(--lightblue);color: var(--lightblue);font-size: 20px;padding-left: 0;"></i>
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 col-xxl-9 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="text-nowrap bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Credit Approved
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row row-cols-2 d-md-flex justify-content-xxl-start align-items-xxl-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                <i class="fa fa-times-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                    style="border-color: var(--lightblue);color: var(--mainblue);font-size: 20px;padding-left: 0;"></i>
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Manufacturer</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row row-cols-2 d-md-flex justify-content-xxl-start align-items-xxl-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <i class="fa fa-times-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                style="border-color: var(--lightblue);color: var(--mainblue);font-size: 20px;padding-left: 0;"></i>
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Packaged</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row row-cols-2 d-md-flex justify-content-xxl-start align-items-xxl-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <i class="fa fa-times-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                style="border-color: var(--lightblue);color: var(--mainblue);font-size: 20px;padding-left: 0;"></i>
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 col-xxl-9 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="text-nowrap bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Vendor Number
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr> -->

                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex d-xxl-flex justify-content-end align-items-center justify-content-xxl-end align-items-xxl-center"
                                                            style="height: 0px;padding-top: 0px;margin-top: 2rem;">
                                                            <pagination [items]="supplierCompanyDetails.preferredBuyers"
                                                                (changePage)="onChangePagePS($event)"
                                                                [pageSize]="maxUserListSize"></pagination>
                                                        </div>

                                                    </ng-container>

                                                    <!-- <nav class="d-flex d-xxl-flex justify-content-end align-items-center justify-content-xxl-end align-items-xxl-center"
                                                                            style="height: 0px;padding-top: 0px;margin-top: 2rem;">
                                                                            <ul class="pagination pagination-sm">
                                                                                <li class="page-item"><a class="page-link"
                                                                                        aria-label="Previous"><span
                                                                                            aria-hidden="true">«</span></a>
                                                                                </li>
                                                                                <li class="page-item"><a class="page-link">1</a></li>
                                                                                <li class="page-item"><a class="page-link">2</a></li>
                                                                                <li class="page-item"><a class="page-link">3</a></li>
                                                                                <li class="page-item"><a class="page-link">4</a></li>
                                                                                <li class="page-item"><a class="page-link">5</a></li>
                                                                                <li class="page-item"><a class="page-link"
                                                                                        aria-label="Next"><span aria-hidden="true">»</span></a>
                                                                                </li>
                                                                            </ul>
                                                                        </nav> -->



                                                </div>
                                            </div>
                                            <div id="tab-4" class="tab-pane {{creditApplicationTab}}" role="tabpanel">
                                                <div>
                                                    <h1 class="text-center d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center"
                                                        style="font-size: 20px;color: var(--white);background: var(--lightblue);padding-top: 0.5rem;padding-bottom: 0.5rem;">
                                                        Credit Application Forms &amp; Statuses</h1>
                                                </div>
                                                <div style="padding: 1rem;padding-top: 0px;">
                                                    <div *ngIf="!supplierCompanyDetails.creditAppTemplateFileName"
                                                        class="d-flex d-xxl-flex flex-column align-items-center align-items-lg-center justify-content-xxl-center align-items-xxl-center"
                                                        style="padding-top: 15px;padding-bottom: 15px;">
                                                        <div>
                                                            <div
                                                                class="d-flex justify-content-center align-items-center">
                                                                <img src="assets/supplierman.png" />
                                                            </div>
                                                        </div>
                                                        <p class="text-center d-flex d-xxl-flex flex-column justify-content-center align-items-center justify-content-xxl-center bluetextthin"
                                                            style="max-width: 400px;margin-top: 5px;">You currently do
                                                            not have a credit application file uploaded.
                                                            Click the button below to upload one.<br /></p><button
                                                            data-bs-toggle="modal" data-bs-target="#credutUpload"
                                                            class="btn btn-secondary invoicebtn" type="button"
                                                            style="font-size: 12px;background: var(--lightblue);border-style: none;width: 127.344px;height: 36px;margin: 10px;">Upload
                                                            File</button>
                                                    </div>
                                                    <ng-container
                                                        *ngIf="supplierCompanyDetails.creditAppTemplateFileName">
                                                        <div style="margin-bottom: 0.5rem;">
                                                            <div class="row g-0">
                                                                <div class="col d-flex d-xxl-flex align-items-center align-items-xxl-center"
                                                                    style="background: var(--shadowgrey);padding: 0.5rem;padding-bottom: 0px;">
                                                                    <h1 class="text-nowrap text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel"
                                                                        style="font-size: 18px;margin-bottom: 0;padding: 0;">
                                                                    </h1>
                                                                    <p
                                                                        class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                                        <i class="far fa-credit-card d-xxl-flex justify-content-xxl-center"
                                                                            style="width: 30px;margin: 10px;color: var(--lightblue);"></i><br /><strong>Your
                                                                            Credit
                                                                            Application Form</strong><br /><br />
                                                                    </p>
                                                                </div>
                                                                <div class="col d-flex d-xxl-flex justify-content-end align-items-center justify-content-xxl-end align-items-xxl-center"
                                                                    style="background: var(--shadowgrey);">
                                                                    <p class="text-nowrap d-xxl-flex"
                                                                        style="font-weight: normal;margin-right: 2rem;font-size: 12px;">
                                                                        <i class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center"
                                                                            style="margin-right: 3px;"></i>{{supplierCompanyDetails.creditAppTemplateFileName}}
                                                                    </p><button [disabled]="creditAppDownload"
                                                                        (click)="getCreditApplicationTemplateFile()"
                                                                        class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                                        role="button"
                                                                        style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                                            class="fa fa-download"
                                                                            style="font-size: 14px;color: var(--lightblue);"></i>&nbsp;
                                                                        Download
                                                                        <span *ngIf="creditAppDownload"
                                                                            class="spinner-border spinner-border-sm mr-1"></span>
                                                                    </button>
                                                                    <button [disabled]="EditingCreditApplication"
                                                                        (click)="editCreditAppTemplate.click()"
                                                                        class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                                        role="button"
                                                                        style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                                            class="fa fa-edit"
                                                                            style="font-size: 14px;color: var(--lightblue);"></i> Edit
                                                                        <span *ngIf="EditingCreditApplication"
                                                                            class="spinner-border spinner-border-sm mr-1"></span>
                                                                    </button>




                                                                    <input #clearEditCreditAppInput type="file"
                                                                        style="display: none;"
                                                                        (change)="editApplicationTemplate($event)"
                                                                        #editCreditAppTemplate
                                                                        accept="{{requiredCRFileType}}">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ng-container
                                                            *ngIf="supplierCompanyDetails.creditApplicants.length > 0">
                                                            <div
                                                                class="d-flex d-sm-flex d-md-flex d-xxl-flex align-items-center justify-content-sm-start align-items-sm-center justify-content-md-start justify-content-xxl-start align-items-xxl-center">
                                                                <div class="input-group tablesearch"><input [(ngModel)]="searchCreditApplications"
                                                                        class="bg-light form-control border-0 small info"
                                                                        type="text"
                                                                        placeholder="Search for ..." /><button
                                                                        class="btn py-0" type="button"
                                                                        style="background: var(--lightblue);color: var(--shadowgrey);"><i
                                                                            class="fas fa-search"></i></button></div>
                                                                <div id="modal-open-1"
                                                                    class="d-md-flex align-items-md-center">
                                                                </div>
                                                            </div>

                                                            <!-- With Search -->
                                                        <ng-container *ngIf="!stringValidation(searchCreditApplications)"> 
                                                            <div class="row" *ngIf = "supplierCompanyDetails.creditApplicants.length > 0">
                                                                <div class="col-xxl-12">
                                                                    <section>
                                                                        <div class="table-responsive">
                                                                            <table
                                                                                class="table table-sm my-0 mydatatable">
                                                                                <thead class="buyerimage">
                                                                                    <tr class="text-start">
                                                                                        <th class="blueheadsmall"
                                                                                            style="text-align: center;">
                                                                                            COMPANY
                                                                                        </th>
                                                                                        <th class="blueheadsmall"
                                                                                            style="text-align: center;">
                                                                                            PERIOD
                                                                                        </th>
                                                                                        <th class="blueheadsmall"
                                                                                            style="text-align: center;">
                                                                                            APPLICATION</th>
                                                                                        <th class="blueheadsmall"
                                                                                            style="text-align: center;">
                                                                                            STATUS
                                                                                        </th>
                                                                                        <th class="blueheadsmall"></th>
                                                                                        <th
                                                                                            class="text-center blueheadsmall">
                                                                                            UPLAODED</th>
                                                                                        <th
                                                                                            class="text-center blueheadsmall">
                                                                                            ACTIONS</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <ng-container
                                                                                        *ngIf="(supplierCompanyDetails.creditApplicants | filterPipe: removeSFWhiteSpace(searchCreditApplications)) as results">
                                                                                        <ng-container
                                                                                            *ngFor="let creditApplicant of results">
                                                                                            <tr class="tablerow"
                                                                                                style="height: 0px;">
                                                                                                <td class="text-center bluetextthin"
                                                                                                    style="padding-top: 9px;">
                                                                                                    {{creditApplicant.buyerCompanyName}}
                                                                                                </td>
                                                                                                <td class="text-nowrap text-center bluetextthin"
                                                                                                    style="padding-top: 9px;">
                                                                                                    {{creditApplicant.period}}
                                                                                                </td>
                                                                                                <td class="text-nowrap text-center bluetextthin"
                                                                                                    style="padding-top: 9px;">
                                                                                                    <a class="btn btn-secondary invoicebtn"
                                                                                                        role="button"
                                                                                                        style="font-size: 14px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);padding: 0;">{{creditApplicant.creditAppFileFileName}} <i
                                                                                                            class="fas fa-external-link-alt"
                                                                                                            style="font-size: 14px;color: var(--lightblue);"></i></a>
                                                                                                </td>
                                                                                                <td class="text-nowrap text-center bluetextthin"
                                                                                                    style="padding-top: 9px;">
                                                                                                    <div
                                                                                                        class="d-flex d-xxl-flex justify-content-center justify-content-lg-center justify-content-xxl-center">


                                                                                                        <div *ngIf = "creditApplicant.status == 'Pending'" class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"><i class="fa fa-minus-circle d-xxl-flex justify-content-xxl-center" style="/*border-color: var(--maingreen);*/color: var(--bs-gray);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                            <p class="bluetextthin">Pending</p>
                                                                                                        </div>
                                                                                                        <div *ngIf = "creditApplicant.status == 'Accepted'"  class="d-flex d-xxl-flex justify-content-center justify-content-lg-center justify-content-xxl-center"><i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center" style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                            <p class="bluetextthin">Accepted</p>
                                                                                                        </div>
                                                                                                        <div *ngIf = "creditApplicant.status == 'Declined'"  class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"><i class="fa fa-times-circle d-xxl-flex justify-content-xxl-center" style="/*border-color: var(--maingreen);*/color: var(--bs-red);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                            <p class="text-nowrap bluetextthin">Declined</p>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </td>
                                                                                                <td class="text-center bluetextthin"
                                                                                                    style="text-align: center;padding-top: 0;">
                                                                                                    <p class="bluetextthin">
                                                                                                    </p>
                                                                                                </td>
                                                                                                <td class="bluetextthin"
                                                                                                    style="text-align: center;padding-top: 9px;">
                                                                                                    {{creditApplicant.createdAt|
                                                                                                    date:'mediumDate'}}<br />
                                                                                                </td>
                                                                                                <td 
                                                                                                    style="text-align: center;">
                                                                                                    <a *ngIf = "creditApplicant.status == 'Pending'" data-bs-target="#creditAppAction"
                                                                                                        data-bs-toggle="modal"
                                                                                                        (click)="setSelectedCreditApplicant(creditApplicant)"
                                                                                                        class="bluetextthin"
                                                                                                        style="text-decoration: underline; cursor: pointer;">Accept/Reject
                                                                                                        Application</a>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </ng-container>
                                                                                    <div *ngIf="results.length == 0">
                                                                                        <div
                                                                                            class="d-flex flex-column justify-content-center align-items-center">
                                                                                            <h1 class="text-center text-center bluehead thin"
                                                                                                style="font-size: 16px;margin-top: 1rem; ">
                                                                                                No search results
                                                                                            </h1>
        
                                                                                        </div>
                                                                                    </div>
                                                                                </ng-container>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </div>
                                                        </ng-container>

                                                            <!-- Without Search -->
                                                            <ng-container *ngIf="stringValidation(searchCreditApplications)"> 
                                                            <div class="row" *ngIf = "supplierCompanyDetails.creditApplicants.length > 0">
                                                                <div class="col-xxl-12">
                                                                    <section>
                                                                        <div class="table-responsive">
                                                                            <table
                                                                                class="table table-sm my-0 mydatatable">
                                                                                <thead class="buyerimage">
                                                                                    <tr class="text-start">
                                                                                        <th class="blueheadsmall"
                                                                                            style="text-align: center;">
                                                                                            COMPANY
                                                                                        </th>
                                                                                        <th class="blueheadsmall"
                                                                                            style="text-align: center;">
                                                                                            PERIOD
                                                                                        </th>
                                                                                        <th class="blueheadsmall"
                                                                                            style="text-align: center;">
                                                                                            APPLICATION</th>
                                                                                        <th class="blueheadsmall"
                                                                                            style="text-align: center;">
                                                                                            STATUS
                                                                                        </th>
                                                                                        <th class="blueheadsmall"></th>
                                                                                        <th
                                                                                            class="text-center blueheadsmall">
                                                                                            UPLAODED</th>
                                                                                        <th
                                                                                            class="text-center blueheadsmall">
                                                                                            ACTIONS</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <ng-container
                                                                                        *ngFor="let creditApplicant of pageOfItemsCA">
                                                                                        <tr class="tablerow"
                                                                                            style="height: 0px;">
                                                                                            <td class="text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                {{creditApplicant.buyerCompanyName}}
                                                                                            </td>
                                                                                            <td class="text-nowrap text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                {{creditApplicant.period}}
                                                                                            </td>
                                                                                            <td class="text-nowrap text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                <a class="btn btn-secondary invoicebtn"
                                                                                                    role="button"
                                                                                                    style="font-size: 14px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);padding: 0;">{{creditApplicant.creditAppFileFileName}} <i
                                                                                                        class="fas fa-external-link-alt"
                                                                                                        style="font-size: 14px;color: var(--lightblue);"></i></a>
                                                                                            </td>
                                                                                            <td class="text-nowrap text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                <div
                                                                                                    class="d-flex d-xxl-flex justify-content-center justify-content-lg-center justify-content-xxl-center">


                                                                                                    <div *ngIf = "creditApplicant.status == 'Pending'" class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"><i class="fa fa-minus-circle d-xxl-flex justify-content-xxl-center" style="/*border-color: var(--maingreen);*/color: var(--bs-gray);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                        <p class="bluetextthin">Pending</p>
                                                                                                    </div>
                                                                                                    <div *ngIf = "creditApplicant.status == 'Accepted'"  class="d-flex d-xxl-flex justify-content-center justify-content-lg-center justify-content-xxl-center"><i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center" style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                        <p class="bluetextthin">Accepted</p>
                                                                                                    </div>
                                                                                                    <div *ngIf = "creditApplicant.status == 'Declined'"  class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"><i class="fa fa-times-circle d-xxl-flex justify-content-xxl-center" style="/*border-color: var(--maingreen);*/color: var(--bs-red);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                        <p class="text-nowrap bluetextthin">Declined</p>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </td>
                                                                                            <td class="text-center bluetextthin"
                                                                                                style="text-align: center;padding-top: 0;">
                                                                                                <p class="bluetextthin">
                                                                                                </p>
                                                                                            </td>
                                                                                            <td class="bluetextthin"
                                                                                                style="text-align: center;padding-top: 9px;">
                                                                                                {{creditApplicant.createdAt|
                                                                                                date:'mediumDate'}}<br />
                                                                                            </td>
                                                                                            <td 
                                                                                                style="text-align: center;">
                                                                                                <a *ngIf = "creditApplicant.status == 'Pending'" data-bs-target="#creditAppAction"
                                                                                                    data-bs-toggle="modal"
                                                                                                    (click)="setSelectedCreditApplicant(creditApplicant)"
                                                                                                    class="bluetextthin"
                                                                                                    style="text-decoration: underline; cursor: pointer;">Accept/Reject
                                                                                                    Application</a>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-container>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                                <div class="d-flex d-xxl-flex justify-content-end align-items-center justify-content-xxl-end align-items-xxl-center"
                                                    style="height: 0px;padding-top: 0px;margin-top: 2rem;">
                                                    <pagination [items]="supplierCompanyDetails.creditApplicants"
                                                        (changePage)="onChangePageCA($event)"
                                                        [pageSize]="maxUserListSize"></pagination>
                                                     </div>
                                                            </div>
                                                        </ng-container>



                                                        </ng-container>
                                                        <div *ngIf="supplierCompanyDetails.creditApplicants.length == 0"
                                                            style="padding-top: 15px;">
                                                            <div>
                                                                <div
                                                                    class="d-flex justify-content-center align-items-center">
                                                                    <i class="fa fa-folder-open-o"
                                                                        style="font-size: 30px;color: var(--lightblue);"></i>
                                                                </div>
                                                            </div>
                                                            <p
                                                                class="text-center d-flex d-xxl-flex flex-column justify-content-center align-items-center justify-content-xxl-center bluetextthin">
                                                                You do not have any credit applications.<br />Start
                                                                submitting quotations to increase your
                                                                sales.<br /><button
                                                                    routerLink="/supplier-home/search-rfq"
                                                                    class="btn btn-secondary invoicebtn" type="button"
                                                                    style="font-size: 12px;background: var(--lightblue);border-style: none;width: 127.344px;height: 36px;margin: 10px;">Search
                                                                    RFQs</button></p>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xxl-3">
                        <div class="row g-0 shadow-sm rounded mb-2" style="width: 315px;padding-top: 0;">
                            <div class="col d-flex d-xxl-flex justify-content-start align-items-center justify-content-xxl-start align-items-xxl-center"
                                style="background: var(--shadowgrey);padding: 1rem;padding-bottom: 0px;padding-top: 0px;">
                                <div></div>
                                <p class="d-xxl-flex align-items-xxl-center bluehead"><img src="assets/Contact.png"
                                        style="width: 30px;margin: 10px;" />Contact</p>
                            </div>
                            <div class="col d-flex d-xxl-flex justify-content-end align-items-center justify-content-xxl-end align-items-xxl-center"
                                style="background: var(--shadowgrey);padding: 1rem;padding-bottom: 0px;padding-top: 0px;">
                                <div></div><button data-bs-toggle="modal" data-bs-target="#contactLoc"
                                    class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                    role="button" [disabled]="account.account.roles[0] != 'Admin' "
                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                        class="fa fa-edit"
                                        style="font-size: 14px;color: var(--lightblue);"></i> Edit</button>
                            </div>
                        </div>
                        <div class="row g-0 rfqdetailrow" style="width: 315px;padding-top: 0;">
                            <div class="col shadow-sm rounded mb-2"
                                style="background: var(--shadowgrey);padding: 1rem;padding-bottom: 0px;padding-top: 0px;">





                                <p
                                    class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                    <i class="fas fa-building d-xxl-flex justify-content-xxl-center"
                                        style="width: 30px;margin: 10px;color: var(--yellow);"></i>Address
                                </p>
                                <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                    style="width: 223.391px;margin-left: 50px;">
                                    <p class="d-sm-flex justify-content-sm-start bluetextthin shift"
                                        style="margin-bottom: 10px;">
                                        {{supplierCompanyDetails.streetAddress}}<br />{{supplierCompanyDetails.city}}<br />
                                        {{supplierCompanyDetails.postalCode}}<br />
                                        {{supplierCompanyDetails.province}}<br /></p>
                                </div>
                            </div>
                            <div class="col shadow-sm rounded mb-2"
                                style="background: var(--shadowgrey);padding: 1rem;padding-bottom: 0px;padding-top: 0px;">
                                <p
                                    class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20"
                                        fill="none" class="d-xxl-flex justify-content-xxl-center"
                                        style="width: 30px;margin: 10px;color: var(--yellow);">
                                        <path
                                            d="M2.00333 5.88355L9.99995 9.88186L17.9967 5.8835C17.9363 4.83315 17.0655 4 16 4H4C2.93452 4 2.06363 4.83318 2.00333 5.88355Z"
                                            fill="currentColor"></path>
                                        <path
                                            d="M18 8.1179L9.99995 12.1179L2 8.11796V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V8.1179Z"
                                            fill="currentColor"></path>
                                    </svg>Email Address
                                </p>
                                <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                    style="width: 223.391px;margin-left: 50px;">
                                    <p class="d-sm-flex justify-content-sm-start bluetextthin shift"
                                        style="margin-bottom: 10px;"> {{supplierCompanyDetails.companyEmail}}</p>
                                </div>
                                <p
                                    class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                    <i class="fas fa-phone-alt d-xxl-flex justify-content-xxl-center"
                                        style="width: 30px;margin: 10px;color: var(--yellow);"></i>Phone Number
                                </p>
                                <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                    style="width: 223.391px;margin-left: 50px;">
                                    <p class="d-sm-flex justify-content-sm-start bluetextthin shift"
                                        style="margin-bottom: 10px;">{{supplierCompanyDetails.companyPhoneNumber}}</p>
                                </div>
                            </div>

                            <!-- 
                        EDIT DOCUMENTS AND Tc&Cs BLOCK -->
                            <!-- <div class="col">
                            <div class="row g-0 shadow-sm rounded mb-2" style="width: 315px;padding-top: 0;">
                                <div class="col d-flex d-xxl-flex justify-content-start align-items-center justify-content-xxl-start align-items-xxl-center"
                                    style="background: var(--shadowgrey);padding: 1rem;padding-bottom: 0px;padding-top: 0px;">
                                    <div></div>
                                    <p
                                        class="d-flex d-sm-flex d-xxl-flex align-items-center align-items-sm-center align-items-xxl-center bluehead">
                                        <img src="assets/4567.png" style="width: 30px;margin: 10px;" />Documents
                                    </p>
                                </div>
                                <div class="col d-flex d-xxl-flex justify-content-end align-items-center justify-content-xxl-end align-items-xxl-center"
                                    style="background: var(--shadowgrey);padding: 1rem;padding-bottom: 0px;padding-top: 0px;">
                                    <div></div><a
                                        class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                        role="button"
                                        style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                            class="fa fa-edit"
                                            style="font-size: 14px;color: var(--lightblue);"></i> Edit</a>
                                </div>
                            </div>
                        </div>
                        <div class="col shadow-sm rounded mb-2"
                            style="background: var(--shadowgrey);padding: 1rem;padding-bottom: 0px;padding-top: 0px;">
                            <p
                                class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                <i class="fas fa-file-alt d-xxl-flex justify-content-xxl-center"
                                    style="width: 30px;margin: 10px;color: var(--yellow);"></i>Terms &amp; Conditions
                            </p>
                            <div class="d-flex d-lg-flex d-xxl-flex justify-content-start align-items-center align-items-lg-center justify-content-xxl-start align-items-xxl-center"
                                style="width: 223.391px;margin-left: 50px;">
                                <p class="d-sm-flex justify-content-sm-start bluetextthin shift"
                                    style="margin-bottom: 10px;"><br /></p>
                                <p class="text-nowrap d-xxl-flex"
                                    style="font-weight: normal;margin-right: 2rem;font-size: 12px;color: var(--darkblue);">
                                    <i class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center"
                                        style="margin-right: 3px;"></i>SalesOrder.dpf
                                </p><a
                                    class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                    role="button"
                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);padding-bottom: 8px;"><i
                                        class="fa fa-edit"
                                        style="font-size: 14px;color: var(--lightblue);"></i> View</a>
                            </div>
                            <div class="d-flex d-lg-flex d-xxl-flex justify-content-start align-items-center align-items-lg-center justify-content-xxl-start align-items-xxl-center"
                                style="width: 223.391px;margin-left: 50px;">
                                <p class="d-sm-flex justify-content-sm-start bluetextthin shift"
                                    style="margin-bottom: 10px;"><br /></p>
                                <p class="text-nowrap d-xxl-flex"
                                    style="font-weight: normal;margin-right: 2rem;font-size: 12px;color: var(--darkblue);">
                                    <i class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center"
                                        style="margin-right: 3px;"></i>SalesOrder.dpf
                                </p><a
                                    class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                    role="button"
                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);padding-bottom: 8px;"><i
                                        class="fa fa-edit"
                                        style="font-size: 14px;color: var(--lightblue);"></i> View</a>
                            </div>
                        </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </main>





        <!-- MODALS -->

        <!-- SUPPLIER DETAILS -->

        <div role="dialog" tabindex="-1" class="modal fade" id="supplierdetails">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header" style="background: var(--lightblue);">
                        <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">
                            Edit
                            Supplier Details</h1><button type="button" class="btn-close" data-bs-dismiss="modal"
                            (click)="resetComapnyLogoPreview()" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                <div class="col" style="padding-left: 0;">
                                    <p class="d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-center justify-content-md-center justify-content-lg-center justify-content-xl-start blueheadsmall"
                                        style="margin-bottom: 0;padding-left: 32px;">Edit the below fields and click the
                                        save
                                        button when done.</p>
                                </div>
                            </div>
                            <div class="row rfqdetailrow" style="padding-bottom: 5px;padding-top: 5px;">
                                <div class="col-xl-12 col-xxl-12 rfqsearchblock"
                                    style="border-radius: 0px 0px 20px 20px;padding: 16px;padding-bottom: 16px;">
                                    <div class="shadow-sm"
                                        style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;padding-bottom: 0.5rem;">

                                        <div class="row" style="padding-top: 0.5rem; padding-left: 15px;">
                                            <div class="col-sm-4 d-flex flex-column justify-content-xxl-center">
                                                <p
                                                    class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                                    Company Logo</p>

                                            </div>
                                            <input #clearPPIPInput type="file" style="display: none;"
                                                (change)="companyLogoSelected($event)" #CompanyLogoUpload
                                                accept="{{requiredFileType}}">
                                            <div
                                                class="col d-flex d-xxl-flex align-items-center align-items-xxl-center">

                                                <button *ngIf="supplierCompanyDetails.profilePictureFileName == null"
                                                    class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                    role="button" (click)="CompanyLogoUpload.click()"
                                                    [disabled]="companyLogoUploading "
                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);">
                                                    <i class="fas fa-upload"
                                                        style="font-size: 14px;color: var(--lightblue);"></i> Upload
                                                    <span *ngIf="companyLogoUploading"
                                                        class="spinner-border spinner-border-sm mr-1"></span></button>

                                                <button *ngIf="supplierCompanyDetails.profilePictureFileName != null"
                                                    (click)="CompanyLogoUpload.click()"
                                                    [disabled]="companyLogoUploading "
                                                    class="btn btn-secondary invoicebtn" role="button"
                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);padding-bottom: 3px;"><i
                                                        class="fa fa-edit"
                                                        style="font-size: 14px;color: var(--lightblue);"></i>
                                                    Edit <span *ngIf="companyLogoUploading"
                                                        class="spinner-border spinner-border-sm mr-1"></span></button>
                                            </div>
                                        </div>

                                        <div class="d-flex justify-content-center" style="padding-top: 1rem;">

                                            <ng-container>
                                                <img [src]="companyLogoPreview" style="width: 99px;" />
                                            </ng-container>

                                        </div>

                                        <p *ngIf="supplierCompanyDetails.profilePictureFileName != null"
                                            class="text-nowrap d-xxl-flex justify-content-center"
                                            style="font-weight: normal;font-size: 12px; padding-top: 2px; "><i
                                                class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center"
                                                style="margin-right: 3px;"></i>{{companyLogoFileNamePreview}}
                                        </p>

                                        <!-- <div class="row" style="padding-top: 0.5rem;">
                                            <div class="col-sm-4 d-flex flex-column justify-content-xxl-center">
                                                <p
                                                    class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                                    Company Logo</p>
                                                <p class="text-nowrap d-xxl-flex"
                                                    style="font-weight: normal;margin-right: 2rem;font-size: 12px;"><i
                                                        class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center"
                                                        style="margin-right: 3px;"></i>No file Uploaded.pdf</p>
                                            </div>
                                            <input #clearPPIPInput type="file"  style="display: none;"
                                            (change)="companyLogoSelected($event)" #CompanyLogoUpload
                                             accept="{{requiredFileType}}">
                                            <div
                                                class="col d-flex d-xxl-flex align-items-center align-items-xxl-center">
                                                <a class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                    role="button" (click)="CompanyLogoUpload.click()"
                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                        class="fas fa-upload"
                                                        style="font-size: 14px;color: var(--lightblue);"></i> Upload</a>
                                            </div>
                                        </div> -->
                                        <!-- <div class="row" style="padding-top: 0.5rem;">
                                            <div class="col-sm-4 d-flex flex-column justify-content-xxl-center">
                                                <p
                                                    class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                                    CMA Certification</p>
                                                <p class="text-nowrap d-xxl-flex"
                                                    style="font-weight: normal;margin-right: 2rem;font-size: 12px;color: var(--darkblue);">
                                                    <i class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center"
                                                        style="margin-right: 3px;"></i>SalesOrder.pdf  <i
                                                        class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                                        style="border-color: var(--lightblue);color: var(--lightblue);font-size: 15px;padding-left: 0;"></i>
                                                </p>
                                            </div>
                                            <div
                                                class="col d-flex d-xxl-flex align-items-center align-items-xxl-center">
                                                <a class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                    role="button"
                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                        class="fa fa-edit"
                                                        style="font-size: 14px;color: var(--lightblue);"></i> View</a><a
                                                    class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                    role="button"
                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                        class="fa fa-edit"
                                                        style="font-size: 14px;color: var(--lightblue);"></i> Edit</a>
                                            </div>
                                        </div> -->
                                        <!-- <div class="row" style="padding-top: 0.5rem;">
                                            <div class="col-sm-4 d-flex flex-column justify-content-xxl-center">
                                                <p
                                                    class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                                    HASAB Certification</p>
                                                <p class="text-nowrap d-xxl-flex"
                                                    style="font-weight: normal;margin-right: 2rem;font-size: 12px;"><i
                                                        class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center"
                                                        style="margin-right: 3px;"></i>No file Uploaded.pdf</p>
                                            </div>
                                            <div
                                                class="col d-flex d-xxl-flex align-items-center align-items-xxl-center">
                                                <a class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                    role="button"
                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                        class="fas fa-upload"
                                                        style="font-size: 14px;color: var(--lightblue);"></i> Upload</a>
                                            </div>
                                        </div> -->
                                        <!-- <div class="row" style="padding-top: 0.5rem;">
                                            <div class="col-sm-4 d-flex flex-column justify-content-xxl-center">
                                                <p
                                                    class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                                    TUV Certification</p>
                                                <p class="text-nowrap d-xxl-flex"
                                                    style="font-weight: normal;margin-right: 2rem;font-size: 12px;"><i
                                                        class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center"
                                                        style="margin-right: 3px;"></i>No file Uploaded.pdf</p>
                                            </div>
                                            <div
                                                class="col d-flex d-xxl-flex align-items-center align-items-xxl-center">
                                                <a class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                    role="button"
                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                        class="fas fa-upload"
                                                        style="font-size: 14px;color: var(--lightblue);"></i> Upload</a>
                                            </div>
                                        </div> -->
                                        <!-- <div class="row" style="padding-top: 0.5rem;">
                                            <div class="col-sm-4 d-flex flex-column justify-content-xxl-center">
                                                <p
                                                    class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                                    ISO Certificate</p>
                                                <p class="text-nowrap d-xxl-flex"
                                                    style="font-weight: normal;margin-right: 2rem;font-size: 12px;color: var(--darkblue);">
                                                    <i class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center"
                                                        style="margin-right: 3px;"></i>ISO.pdf <i
                                                        class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                                        style="border-color: var(--lightblue);color: var(--lightblue);font-size: 15px;padding-left: 0;"></i>
                                                </p>
                                            </div>
                                            <div
                                                class="col d-flex d-xxl-flex align-items-center align-items-xxl-center">
                                                <a class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                    role="button"
                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                        class="fa fa-edit"
                                                        style="font-size: 14px;color: var(--lightblue);"></i> View</a><a
                                                    class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                    role="button"
                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                        class="fa fa-edit"
                                                        style="font-size: 14px;color: var(--lightblue);"></i> Edit</a>
                                            </div>
                                        </div> -->
                                        <!-- <div class="row" style="padding-top: 0.5rem;">
                                            <div class="col-sm-4 d-flex flex-column justify-content-xxl-center">
                                                <p
                                                    class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                                    MACS Certificate</p>
                                                <p class="text-nowrap d-xxl-flex"
                                                    style="font-weight: normal;margin-right: 2rem;font-size: 12px;color: var(--darkblue);">
                                                    <i class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center"
                                                        style="margin-right: 3px;"></i>MACS.pdf <i
                                                        class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                                        style="border-color: var(--lightblue);color: var(--lightblue);font-size: 15px;padding-left: 0;"></i>
                                                </p>
                                            </div>
                                            <div
                                                class="col d-flex d-xxl-flex align-items-center align-items-xxl-center">
                                                <a class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                    role="button"
                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                        class="fa fa-edit"
                                                        style="font-size: 14px;color: var(--lightblue);"></i> View</a><a
                                                    class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                    role="button"
                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                        class="fa fa-edit"
                                                        style="font-size: 14px;color: var(--lightblue);"></i> Edit</a>
                                            </div>
                                        </div> -->
                                        <!-- <div class="row" style="padding-top: 0.5rem;">
                                            <div class="col-sm-4 d-flex flex-column justify-content-xxl-center">
                                                <p
                                                    class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                                    B-BBEE Certificate</p>
                                                <p class="text-nowrap d-xxl-flex"
                                                    style="font-weight: normal;margin-right: 2rem;font-size: 12px;color: var(--darkblue);">
                                                    <i class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center"
                                                        style="margin-right: 3px;"></i>BEE.pdf <i
                                                        class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                                        style="border-color: var(--lightblue);color: var(--lightblue);font-size: 15px;padding-left: 0;"></i>
                                                </p>
                                            </div>
                                            <div
                                                class="col d-flex d-xxl-flex align-items-center align-items-xxl-center">
                                                <a class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                    role="button"
                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                        class="fa fa-edit"
                                                        style="font-size: 14px;color: var(--lightblue);"></i> View</a><a
                                                    class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                    role="button"
                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                        class="fa fa-edit"
                                                        style="font-size: 14px;color: var(--lightblue);"></i> Edit</a>
                                            </div>
                                        </div> -->
                                    </div>
                                    <!-- <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                    <div class="col" style="padding-left: 0;">
                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;padding-left: 30px;">Description</p>
                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;">
                                        </p>
                                        <div style="margin-top: 1rem;width: 100%;">
                                            <div style="height: auto;margin-left: 2rem;margin-top: 1rem;width: 25rem;">
                                                <textarea style="width: 417px;margin-left: 0px;"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                </div>
                                <!-- <div class="col-xl-12 col-xxl-12 rfqsearchblock"
                                style="border-radius: 0px 0px 20px 20px;padding: 16px;padding-bottom: 16px;">
                                <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                    <div class="col" style="padding-left: 0;">
                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;padding-left: 30px;">Company Logo</p>
                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;">
                                        </p>
                                        <div style="margin-top: 1rem;width: 100%;">
                                            <div style="height: auto;margin-left: 2rem;margin-top: 1rem;width: 25rem;">
                                            </div>
                                        </div>
                                        <div style="margin-top: 1rem;width: 100%;">
                                            <div style="height: auto;margin-left: 2rem;margin-top: 1rem;width: 25rem;">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;">Upload Image</p>
                                                <div class="d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center dragdropblock"
                                                    style="height: 2rem;margin-top: 1rem;width: 100%;border-width: 2px;border-style: dashed;">
                                                    <p class="d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-start justify-content-xxl-center align-items-xxl-center bluetextthin"
                                                        style="margin-bottom: 0;padding: 1rem;">Drag &amp; Drop or <a
                                                            class="btn btn-link btn-sm d-xxl-flex align-items-xxl-start"
                                                            role="button" style="padding: 0px;">Browse</a></p>
                                                </div>
                                                <div class="d-xl-flex justify-content-xl-center"
                                                    style="padding-top: 1rem;">
                                                    <img src="assets/Slice 12.jpg" style="width: 99px;" />
                                                </div>
                                                <div class="d-xl-flex justify-content-xl-center"
                                                    style="padding-top: 1rem;">
                                                    <a class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn"
                                                        role="button"
                                                        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Remove
                                                        Photo</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            </div>
                            <div class="row d-flex d-xxl-flex justify-content-center align-items-center rfqdetailrow"
                                style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;">
                                <div class="col">
                                    <p class="text-nowrap bluetextthin" style="font-weight: normal;">Want to edit more
                                        fields?
                                    </p>
                                </div>
                                <div class="col d-flex d-xxl-flex justify-content-end justify-content-xxl-end">
                                    <p class="bluetextthin" style="font-weight: normal;"><a
                                            class="text-nowrap bluetextthin" href="#"
                                            style="text-decoration:  underline;">Tradely Support</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer d-lg-flex align-items-lg-center"><a class="btn text-gray-500" role="button"
                            (click)="resetComapnyLogoPreview()" data-bs-dismiss="modal">Cancel</a>

                        <button #closeBuyerDetailsMdl style="display: none; " data-bs-dismiss="modal"></button>

                        <button (click)="uploadCompanyLogo()" [disabled]="companyLogoUploading"
                            class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn"
                            role="button"
                            style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Save
                            <span *ngIf="companyLogoUploading" class="spinner-border spinner-border-sm mr-1"
                                style="margin-left: 3px;"></span>

                        </button>
                    </div>
                </div>
            </div>
        </div>


        <!-- TRADELYDETAILS -->

        <div class="modal fade" role="dialog" tabindex="-1" id="tradelydetails">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content">
                    <div class="modal-header" style="background: var(--lightblue);">
                        <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">
                            Edit
                            Tradely Specific Details</h1><button type="button" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                <div class="col" style="padding-left: 0;">
                                    <p class="d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-center justify-content-md-center justify-content-lg-center justify-content-xl-start blueheadsmall"
                                        style="margin-bottom: 0;padding-left: 32px;">Select the appropriate options by
                                        checking
                                        the below boxes.<br /></p>
                                </div>
                            </div>
                            <form [formGroup]="editSupplierSpecificDetails">
                                <div class="row row-cols-1 rfqdetailrow" style="padding-bottom: 5px;padding-top: 5px;">

                                    <!-- PARNERSHIP REQUIREMENTS -->
                                    <div class="col-xl-12 col-xxl-12 rfqsearchblock"
                                        style="border-radius: 0px 0px 20px 20px;padding: 16px;padding-bottom: 16px;padding-top: 0px;">
                                        <div class="shadow-sm"
                                            style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;padding-bottom: 0.5rem;">
                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;padding-top: 0.5rem;">
                                                <div>
                                                    <p class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext"
                                                        style="margin-left: 1rem;">Partnership Requirements</p>
                                                </div>
                                            </div>
                                            <!-- <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;">
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center align-items-lg-center align-items-xxl-center bluetext">
                                                        <input
                                                            (change)="PartnershipOptionCheck(esd.verified.value, 'Verified')"
                                                            type="checkbox" formControlName="verified"
                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />Verified<a
                                                            href="#"></a>
                                                    </p>
                                                </div>
                                            </div> -->
                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                            style="width: 223.391px;">
                                            <div>
                                                <p
                                                    class="d-flex d-sm-flex justify-content-start align-items-center align-items-lg-center align-items-xxl-center bluetext">
                                                    <input
                                                        (change)="PartnershipOptionCheck(esd.preferredBuyers.value, 'Preferred Buyers')"
                                                        type="checkbox" formControlName="preferredBuyers"
                                                        style="width: 30px;margin: 10px;background: var(--lightblue);" />Preferred Buyers<a
                                                        href="#"></a>
                                                </p>
                                            </div>
                                        </div>
                                            <!-- <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                            style="width: 223.391px;">
                                            <div>
                                                <p
                                                    class="d-flex d-sm-flex justify-content-start align-items-center align-items-lg-center align-items-xxl-center bluetext">
                                                    <input type="checkbox"
                                                        style="width: 30px;margin: 10px;background: var(--lightblue);" />Vendor<a
                                                        href="#"></a>
                                                </p>
                                            </div>
                                        </div> -->
                                        </div>
                                    </div>

                                    <!-- PAYMENT REQUIREMENTS -->
                                    <div class="col-xl-12 col-xxl-12 rfqsearchblock"
                                        style="border-radius: 0px 0px 20px 20px;padding: 16px;padding-bottom: 16px;padding-top: 0px;">
                                        <div class="shadow-sm"
                                            style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;padding-bottom: 0.5rem;">
                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;padding-top: 0.5rem;">
                                                <div>
                                                    <p class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext"
                                                        style="margin-left: 1rem;">Payment Requirements</p>
                                                </div>
                                            </div>
                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;">
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center align-items-lg-center align-items-xxl-center bluetext">
                                                        <input (change)="PaymentOptionCheck(esd.cod.value, 'COD')"
                                                            formControlName="cod" type="checkbox" id="cod"
                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />COD<a
                                                            href="#"></a>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;">
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center align-items-lg-center align-items-xxl-center bluetext">
                                                        <input (change)="PaymentOptionCheck(esd.cbd.value, 'CBD')"
                                                            formControlName="cbd" type="checkbox" id="cbd"
                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />CBD
                                                        <a href="#"></a>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;">
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center align-items-lg-center align-items-xxl-center bluetext">
                                                        <input
                                                            (change)="PaymentOptionCheck(esd.thirty_day.value, '30 Days')"
                                                            type="checkbox" formControlName="thirty_day" type="checkbox"
                                                            id="thirty_day"
                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />30
                                                        Day<a href="#"></a>
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;">
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center align-items-lg-center align-items-xxl-center bluetext">
                                                        <input
                                                            (change)="PaymentOptionCheck(esd.sixty_day.value, '60 Days')"
                                                            type="checkbox" formControlName="sixty_day" type="checkbox"
                                                            id="sixty_day"
                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />60
                                                        Day<a href="#"></a>
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;">
                                                <!-- <div>
                                                                                                                <p
                                                                                                                    class="d-flex d-sm-flex justify-content-start align-items-center align-items-lg-center align-items-xxl-center bluetext">
                                                                                                                    <input type="checkbox" formControlName="no_preference"
                                                                                                                        type="checkbox" id="no_preference"
                                                                                                                        style="width: 30px;margin: 10px;background: var(--lightblue);" />No
                                                                                                                    Preference
                                                                                                                    <a href="#"></a>
                                                                                                                </p>
                                                                                                            </div> -->
                                            </div>
                                        </div>
                                    </div>

                                    <!-- PACKAGING REQUIREMENTS -->
                                    <div class="col-xl-12 col-xxl-12 rfqsearchblock"
                                        style="border-radius: 0px 0px 20px 20px; padding-top:0px; padding-bottom: 16px;">
                                        <div class="shadow-sm"
                                            style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;padding-bottom: 0.5rem;">
                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;padding-top: 0.5rem;">
                                                <div>
                                                    <p class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext"
                                                        style="margin-left: 1rem;">Packaging Capabilities</p>
                                                </div>
                                            </div>
                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;">
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center align-items-lg-center align-items-xxl-center bluetext">
                                                        <input (change)="PackagingCheck(esd.boxed.value, 'Boxed')"
                                                            formControlName="boxed" type="checkbox" id="boxed"
                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />Boxed<a
                                                            href="#"></a>
                                                    </p>

                                                </div>


                                            </div>
                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;">
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center align-items-lg-center align-items-xxl-center bluetext">
                                                        <input (change)="PackagingCheck(esd.pallets.value, 'Pallet')"
                                                            formControlName="pallets" type="checkbox" id="pallets"
                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />Pallets<a
                                                            href="#"></a>
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;">
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center align-items-lg-center align-items-xxl-center bluetext">
                                                        <input (change)="PackagingCheck(esd.strapped.value, 'Strapped')"
                                                            formControlName="strapped" type="checkbox" id="strapped"
                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />Strapped<a
                                                            href="#"></a>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;">
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center align-items-lg-center align-items-xxl-center bluetext">
                                                        <input (change)="PackagingCheck(esd.loose.value, 'Loose')"
                                                            formControlName="loose" type="checkbox" id="loose"
                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />Loose<a
                                                            href="#"></a>
                                                    </p>
                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                    <!-- DELIVERY CAPABILITIES -->
                                    <div class="col-xl-12 col-xxl-12 rfqsearchblock"
                                        style="border-radius: 0px 0px 20px 20px;padding: 16px;padding-bottom: 16px;padding-top: 0px;">
                                        <div class="shadow-sm"
                                            style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;padding-bottom: 0.5rem;">
                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;padding-top: 0.5rem;">
                                                <div>
                                                    <p class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext"
                                                        style="margin-left: 1rem;">Delivery Capabilities</p>
                                                </div>
                                            </div>

                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;">
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center align-items-lg-center align-items-xxl-center bluetext">
                                                        <input
                                                            (change)="LocationCheck(esd.westernCape.value, 'Western Cape')"
                                                            formControlName="westernCape" type="checkbox"
                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />Western
                                                        Cape
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;">
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center align-items-lg-center align-items-xxl-center bluetext">
                                                        <input
                                                            (change)="LocationCheck(esd.easternCape.value, 'Eastern Cape')"
                                                            formControlName="easternCape" type="checkbox"
                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />Eastern
                                                        Cape
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;">
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center align-items-lg-center align-items-xxl-center bluetext">
                                                        <input
                                                            (change)="LocationCheck(esd.freeState.value, 'Free State')"
                                                            formControlName="freeState" type="checkbox" id="freeState"
                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />Free
                                                        State

                                                    </p>
                                                </div>
                                            </div>
                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;">
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center align-items-lg-center align-items-xxl-center bluetext">
                                                        <input (change)="LocationCheck(esd.gauteng.value, 'Gauteng')"
                                                            type="checkbox" formControlName="gauteng" type="checkbox"
                                                            id="gauteng"
                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />Gauteng

                                                    </p>
                                                </div>
                                            </div>

                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;">
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center align-items-lg-center align-items-xxl-center bluetext">
                                                        <input (change)="LocationCheck(esd.kzn.value, 'KwaZulu-Natal')"
                                                            type="checkbox" formControlName="kzn" type="checkbox"
                                                            id="kzn"
                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />KwaZulu-Natal

                                                    </p>
                                                </div>
                                            </div>

                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;">
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center align-items-lg-center align-items-xxl-center bluetext">
                                                        <input (change)="LocationCheck(esd.limpopo.value, 'Limpopo')"
                                                            type="checkbox" formControlName="limpopo" type="checkbox"
                                                            id="limpopo"
                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />Limpopo

                                                    </p>
                                                </div>
                                            </div>

                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;">
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center align-items-lg-center align-items-xxl-center bluetext">
                                                        <input
                                                            (change)="LocationCheck(esd.mpumalanga.value, 'Mpumalanga')"
                                                            type="checkbox" formControlName="mpumalanga" type="checkbox"
                                                            id="mpumalanga"
                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />Mpumalanga

                                                    </p>
                                                </div>
                                            </div>


                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;">
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center align-items-lg-center align-items-xxl-center bluetext">
                                                        <input
                                                            (change)="LocationCheck(esd.northernCape.value, 'Northern Cape')"
                                                            type="checkbox" formControlName="northernCape"
                                                            type="checkbox" id="northernCape"
                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />Northern
                                                        Cape
                                                        <a href="#"></a>
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;">
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center align-items-lg-center align-items-xxl-center bluetext">
                                                        <input
                                                            (change)="LocationCheck(esd.northWest.value, 'North West')"
                                                            type="checkbox" formControlName="northWest" type="checkbox"
                                                            id="northWest"
                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />North
                                                        West

                                                        <a href="#"></a>
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                style="width: 223.391px;">
                                                <!-- <div>
                                                                                    <p
                                                                                        class="d-flex d-sm-flex justify-content-start align-items-center align-items-lg-center align-items-xxl-center bluetext">
                                                                                        <input type="checkbox" formControlName="no_preference"
                                                                                            type="checkbox" id="no_preference"
                                                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />No
                                                                                        Preference
                                                                                        <a href="#"></a>
                                                                                    </p>
                                                                                </div> -->
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>

                            <div class="row d-flex d-xxl-flex justify-content-center align-items-center rfqdetailrow"
                                style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;">
                                <div class="col">
                                    <p class="text-nowrap bluetextthin" style="font-weight: normal;">Want to edit more
                                        fields?
                                    </p>
                                </div>
                                <div class="col d-flex d-xxl-flex justify-content-end justify-content-xxl-end">
                                    <p class="bluetextthin" style="font-weight: normal;"><a
                                            class="text-nowrap bluetextthin" href="#"
                                            style="text-decoration:  underline;">Tradely Support</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer d-lg-flex align-items-lg-center">



                        <a #editTradelyDetailsBtn data-bs-dismiss="modal"
                            class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn"
                            role="button"
                            style="display: none !important;border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Save
                            Hidden</a>

                        <button [disabled]="editTradelyDetails" (click)="SupplierDetailsEdit()"
                            class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn"
                            role="button"
                            style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Save
                            <span *ngIf="editTradelyDetails"
                                class="spinner-border spinner-border-sm mr-1"></span></button>


                    </div>
                </div>


            </div>
        </div>

        <!-- CONTACTDETAILS -->
        <div role="dialog" tabindex="-1" class="modal fade" id="contactLoc">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header" style="background: var(--lightblue);">
                        <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">
                            Edit
                            Contact and Location Details</h1><button type="button" class="btn-close"
                            data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                <div class="col" style="padding-left: 0;">
                                    <p class="d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-center justify-content-md-center justify-content-lg-center justify-content-xl-start blueheadsmall"
                                        style="margin-bottom: 0;padding-left: 32px;">Edit the below fields and click the
                                        save
                                        button when done.</p>
                                </div>
                            </div>

                            <form [formGroup]="suppliercontactForm">

                                <div class="row rfqdetailrow" style="padding-bottom: 5px;padding-top: 5px;">
                                    <div class="col-xl-12 col-xxl-12 rfqsearchblock"
                                        style="border-radius: 0px 0px 20px 20px;padding: 16px;padding-bottom: 16px;">
                                        <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                            <div class="col" style="padding-left: 0;">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluehead"
                                                    style="margin-bottom: 0;padding-left: 30px;">Company Address</p>
                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;">
                                                </p>
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;padding-left: 30px; padding-top: 15px;">
                                                    Street
                                                    Address

                                                    <ng-container
                                                        *ngIf="(cf.streetAddress.touched && contactDetailsErrorDisplay &&  stringValidation(cf.streetAddress.value) )">
                                                        <span class="p-1 bluetext thin"
                                                            style="color:#a51d29;">(Required)</span>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="(cf.streetAddress.touched && contactDetailsErrorDisplay &&  cf.streetAddress.value.length > 100 )">
                                                        <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds
                                                            Max Length)</span>
                                                    </ng-container>

                                                </p>
                                                <div
                                                    style="height: 2rem;margin-left: 2rem;margin-top: 0.5rem;width: 18rem;">
                                                    <input formControlName="streetAddress" placeholder="Street Address"
                                                        type="text" class="form-control" autocomplete="off"
                                                        style="width: 315px;" />
                                                </div>
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;padding-left: 30px; padding-top: 10px;">City

                                                    <ng-container
                                                        *ngIf="(cf.city.touched && contactDetailsErrorDisplay &&  stringValidation(cf.city.value) )">
                                                        <span class="p-1 bluetext thin"
                                                            style="color:#a51d29;">(Required)</span>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="(cf.city.touched && contactDetailsErrorDisplay &&  cf.city.value.length > 50 )">
                                                        <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds
                                                            Max Length)</span>
                                                    </ng-container>

                                                </p>
                                                <div
                                                    style="height: 2rem;margin-left: 2rem;margin-top: 0.5rem;width: 18rem;">
                                                    <input formControlName="city" placeholder="City" type="text"
                                                        class="form-control" autocomplete="off"
                                                        style="width: 315px;height: 2rem;" />
                                                </div>
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;padding-left: 30px; padding-top: 10px;">
                                                    Postal
                                                    Code
                                                    <ng-container
                                                        *ngIf="(cf.postalCode.touched && contactDetailsErrorDisplay &&  stringValidation(cf.postalCode.value) )">
                                                        <span class="p-1 bluetext thin"
                                                            style="color:#a51d29;">(Required)</span>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="(cf.postalCode.touched && contactDetailsErrorDisplay &&  cf.postalCode.value.length > 10 )">
                                                        <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds
                                                            Max Length)</span>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="(cf.postalCode.touched  && !postalCodeValid(this.cf.postalCode.value))">
                                                        <span class="p-1 bluetext thin"
                                                            style="color:#a51d29;">(Invalid)</span>
                                                    </ng-container>

                                                </p>
                                                <div
                                                    style="height: 2rem;margin-left: 2rem;margin-top: 0.5rem;width: 18rem;">
                                                    <input formControlName="postalCode" placeholder="Postal Code"
                                                        type="text" class="form-control" autocomplete="off"
                                                        style="width: 315px;height: 2rem;" />
                                                </div>
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;padding-left: 30px; padding-top: 10px;">
                                                    Province

                                                    <ng-container
                                                        *ngIf="(cf.province.touched && contactDetailsErrorDisplay &&  stringValidation(cf.province.value) )">
                                                        <span class="p-1 bluetext thin"
                                                            style="color:#a51d29;">(Required)</span>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="(cf.province.touched && contactDetailsErrorDisplay &&  cf.province.value.length > 50 )">
                                                        <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds
                                                            Max Length)</span>
                                                    </ng-container>

                                                </p>
                                                <div
                                                    style="height: 2rem;margin-left: 2rem;margin-top: 0.5rem;width: 18rem;">
                                                    <select formControlName="province"
                                                        class="form-select d-flex align-items-center form-select"
                                                        style="width: 315px;height: 2rem;">
                                                        <option [disabled]="true" [ngValue]="null" selected="">
                                                            Province </option>
                                                        <option *ngFor="let selectedlocation of provinces"
                                                            [value]="selectedlocation">
                                                            {{selectedlocation}}</option>

                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-xxl-12 rfqsearchblock"
                                        style="border-radius: 0px 0px 20px 20px;padding: 16px;padding-bottom: 16px;">
                                        <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                            <div class="col" style="padding-left: 0;">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;padding-left: 30px;">Email Address

                                                    <ng-container
                                                        *ngIf="(cf.companyEmail.touched && contactDetailsErrorDisplay &&  stringValidation(cf.companyEmail.value) )">
                                                        <span class="p-1 bluetext thin"
                                                            style="color:#a51d29;">(Required)</span>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="(cf.companyEmail.touched && contactDetailsErrorDisplay &&  cf.companyEmail.value.length > 50 )">
                                                        <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds
                                                            Max Length)</span>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="(cf.companyEmail.touched && cf.companyEmail.errors)">
                                                        <span class="p-1 bluetext thin"
                                                            style="color:#a51d29;">(Invalid)</span>
                                                    </ng-container>

                                                </p>
                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;">
                                                </p>
                                                <div
                                                    style="height: 2rem;margin-left: 2rem;margin-top: 0.5rem;width: 18rem;">
                                                    <input formControlName="companyEmail" placeholder="Company Email"
                                                        type="text" class="form-control" autocomplete="off"
                                                        style="width: 315px;height: 2rem;" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                            <div class="col" style="padding-left: 0;">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;padding-left: 30px;">Phone Number

                                                    <ng-container
                                                        *ngIf="(cf.companyPhoneNumber.touched && contactDetailsErrorDisplay &&  stringValidation(cf.companyPhoneNumber.value) )">
                                                        <span class="p-1 bluetext thin"
                                                            style="color:#a51d29;">(Required)</span>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="(cf.companyPhoneNumber.touched && contactDetailsErrorDisplay &&  cf.companyPhoneNumber.value.length > 15 )">
                                                        <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds
                                                            Max Length)</span>
                                                    </ng-container>


                                                </p>
                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;">
                                                </p>
                                                <div
                                                    style="height: 2rem;margin-left: 2rem;margin-top: 0.5rem;width: 18rem;">
                                                    <input formControlName="companyPhoneNumber"
                                                        placeholder="Company Number" type="text" class="form-control"
                                                        autocomplete="off" style="width: 315px;height: 2rem;" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>

                        </div>
                    </div>
                    <div class="modal-footer d-lg-flex align-items-lg-center"><a class="btn text-gray-500" role="button"
                            data-bs-dismiss="modal">Cancel</a>

                        <a #editContactDetailsBtn data-bs-dismiss="modal"
                            class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn"
                            role="button"
                            style="display: none !important;border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Save
                            Hidden</a>

                        <button *ngIf="contactDetailsValid() && !cf.companyEmail.errors"
                            [disabled]="editingContactDetails" (click)="contactDetailsEdit()"
                            class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn"
                            role="button"
                            style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Save
                            <span *ngIf="editingContactDetails"
                                class="spinner-border spinner-border-sm mr-1"></span></button>

                        <button *ngIf="!contactDetailsValid() || cf.companyEmail.errors"
                            (click)="contactDetailsValidCond()"
                            class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn"
                            role="button"
                            style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Save
                            <span *ngIf="editingContactDetails"
                                class="spinner-border spinner-border-sm mr-1"></span></button>
                    </div>
                </div>
            </div>




        </div>

        <!-- ADDUSER -->
        <div role="dialog" tabindex="-1" class="modal fade" id="adduser">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header" style="background: var(--lightblue);">
                        <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">
                            Add
                            User
                        </h1><button type="button" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="row text-end">
                                <div class="col" style="padding-left: 0;">
                                    <p class="text-center blueheadsmall">Complete the form Below and click 'Add User'
                                        when
                                        done.</p>
                                </div>
                            </div>
                            <form [formGroup]="addNewUserForm">
                                <div class="row rfqdetailrow">
                                    <div class="col d-flex align-items-center">
                                        <p
                                            class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                            First Name

                                            <ng-container
                                                *ngIf="(addUserErrorDisplay &&  stringValidation(nu.firstName.value) )">
                                                <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                                            </ng-container>

                                            <ng-container
                                                *ngIf="(addUserErrorDisplay &&  nu.firstName.value.length > 50 )">
                                                <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds Max
                                                    Length)</span>
                                            </ng-container>

                                        </p>
                                    </div>
                                    <div class="col"><input formControlName="firstName" type="text" class="form-control"
                                            autocomplete="off" placeholder="" style="height: 2rem;width: 315px;" />
                                    </div>
                                </div>
                                <div class="row rfqdetailrow">
                                    <div class="col d-flex align-items-center">
                                        <p
                                            class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                            Last Name

                                            <ng-container
                                                *ngIf="( addUserErrorDisplay &&  stringValidation(nu.lastName.value) )">
                                                <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                                            </ng-container>

                                            <ng-container
                                                *ngIf="( addUserErrorDisplay &&  nu.lastName.value.length > 50 )">
                                                <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds Max
                                                    Length)</span>
                                            </ng-container>


                                        </p>
                                    </div>
                                    <div class="col"><input formControlName="lastName" type="text" class="form-control"
                                            autocomplete="off" placeholder="" style="height: 2rem;width: 315px;" />
                                    </div>
                                </div>
                                <div class="row rfqdetailrow">
                                    <div class="col d-flex align-items-center">
                                        <p
                                            class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                            Email
                                            Address

                                            <ng-container
                                                *ngIf="( addUserErrorDisplay &&  stringValidation(nu.userName.value) )">
                                                <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                                            </ng-container>

                                            <ng-container
                                                *ngIf="( addUserErrorDisplay &&  nu.userName.value.length > 50 )">
                                                <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds Max
                                                    Length)</span>
                                            </ng-container>

                                            <ng-container *ngIf="( nu.userName.errors && nu.userName.touched )">
                                                <span class="p-1 bluetext thin" style="color:#a51d29;">(Invalid)</span>
                                            </ng-container>

                                        </p>
                                    </div>
                                    <div class="col"><input formControlName="userName" type="text" class="form-control"
                                            autocomplete="off" placeholder="" style="height: 2rem;width: 315px;" />
                                    </div>
                                </div>
                                <div class="row rfqdetailrow">
                                    <div class="col d-flex align-items-center">
                                        <p
                                            class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                            Role

                                            <!-- <ng-container
                                                *ngIf="(nu.role.touched && addUserErrorDisplay &&  stringValidation(nu.role.value) )">
                                                <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                                            </ng-container> -->


                                        </p>
                                    </div>
                                    <div class="col">

                                        <input formControlName="role" type="text" class="form-control"
                                            autocomplete="off" placeholder="Supplier"
                                            style="height: 2rem;width: 315px;" />

                                        <!-- <select class="form-select" style="width: 315px;height: 2rem;"
                                            formControlName="role">

                                            <option disabled value=null selected>Role</option>
                                            <option value="Buyer">Buyer</option>
                                            <option value="Supplier">Supplier</option>
                                            <option value="Merchant">Merchant</option>

                                        </select> -->

                                    </div>
                                </div>
                                <div class="row rfqdetailrow">
                                    <div class="col d-flex align-items-center">
                                        <p
                                            class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                            Team

                                            <ng-container
                                                *ngIf="( addUserErrorDisplay &&  stringValidation(nu.team.value) )">
                                                <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                                            </ng-container>

                                            <ng-container *ngIf="( addUserErrorDisplay &&  nu.team.value.length > 50 )">
                                                <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds Max
                                                    Length)</span>
                                            </ng-container>

                                        </p>
                                    </div>
                                    <div class="col"><input formControlName="team" type="text" class="form-control"
                                            autocomplete="off" placeholder="" style="height: 2rem;width: 315px;" />
                                    </div>
                                </div>

                                <div class="row rfqdetailrow">
                                    <div class="col d-flex align-items-center">
                                        <p
                                            class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                            Notification Type(s)</p>
                                    </div>
                                    <div class="col">
                                        <div
                                            class="d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center">
                                            <div class="form-check form-check-inline">
                                                <input type="checkbox" formControlName="emailPreference"
                                                    class="form-check-input" id="formCheck-50" /><label
                                                    class="form-check-label radio bluetext thin"
                                                    for="formCheck-1">Email</label>
                                            </div>
                                            <!-- <div class="form-check form-check-inline">
                                                <input type="checkbox" formControlName="sMSPreference"
                                                    class="form-check-input" id="formCheck-12" /><label
                                                    class="form-check-label radio bluetext thin"
                                                    for="formCheck-1">SMS</label>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="row d-flex d-xxl-flex justify-content-center align-items-center rfqdetailrow"
                                style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;">
                                <div class="col d-flex d-xl-flex justify-content-center justify-content-xl-center">
                                    <p class="text-nowrap bluetextthin" style="font-weight: normal;">All fields are
                                        required
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer d-lg-flex align-items-lg-center"><a class="btn text-gray-500" role="button"
                            data-bs-dismiss="modal">Cancel</a>

                        <button *ngIf="userDetailsValid()  && !nu.userName.errors" (click)="addNewUser()"
                            [disabled]="addingNewUser"
                            class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn"
                            role="button"
                            style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Add User
                            <span *ngIf="addingNewUser" class="spinner-border spinner-border-sm mr-1"></span></button>

                        <button *ngIf="!userDetailsValid() || nu.userName.errors" (click)="userDetailsValidCond()"
                            class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn"
                            role="button"
                            style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Add User
                        </button>

                        <button data-bs-dismiss="modal" #CloseAddUserModal
                            class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn"
                            role="button"
                            style="display: none !important ;border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Add
                            User</button>
                    </div>
                </div>
            </div>
        </div>


        <!-- EDITUSER -->
        <div role="dialog" tabindex="-1" class="modal fade" id="editUser">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header" style="background: var(--lightblue);">
                        <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">
                            Edit
                            User</h1><button type="button" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="row text-end">
                                <div class="col" style="padding-left: 0;">
                                    <p class="text-center blueheadsmall">Edit the below fields and click the save button
                                        when
                                        done.</p>
                                </div>
                            </div>
                            <div class="row rfqdetailrow">
                                <div class="col d-flex align-items-center">
                                    <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                        First
                                        Name
                                    </p>
                                </div>
                                <div class="col"><input type="text" class="form-control" autocomplete="off"
                                        placeholder="s" style="height: 2rem;width: 315px;" /></div>
                            </div>
                            <div class="row rfqdetailrow">
                                <div class="col d-flex align-items-center">
                                    <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                        Last
                                        Name
                                    </p>
                                </div>
                                <div class="col"><input type="text" class="form-control" autocomplete="off"
                                        placeholder="fsfssf" style="height: 2rem;width: 315px;" /></div>
                            </div>
                            <div class="row rfqdetailrow">
                                <div class="col d-flex align-items-center">
                                    <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                        Role
                                    </p>
                                </div>
                                <div class="col"><select class="form-select" style="width: 315px;height: 2rem;">
                                        <optgroup label="This is a group">
                                            <option value="12" selected>This is item 1</option>
                                            <option value="13">This is item 2</option>
                                            <option value="14">This is item 3</option>
                                        </optgroup>
                                    </select></div>
                            </div>
                            <div class="row rfqdetailrow">
                                <div class="col d-flex align-items-center">
                                    <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                        Email
                                        Adress</p>
                                </div>
                                <div class="col"><input type="text" class="form-control" autocomplete="off"
                                        placeholder="sfafsaf@gdgdgd" style="height: 2rem;width: 315px;" />
                                    <div class="d-lg-flex d-xxl-flex justify-content-between align-items-lg-center align-items-xxl-center"
                                        style="padding-top: 8px;"><a
                                            class="btn btn-secondary d-flex d-xxl-flex align-items-center invoicebtn"
                                            role="button"
                                            style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                class="material-icons"
                                                style="font-size: 14px;color: var(--lightblue);border-color: var(--lightblue);">mail</i> Send
                                            Invitation</a>
                                        <div class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                            style="background: var(--shadowgrey);padding: 7px;border-radius: 40px;"><i
                                                class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                                style="border-color: var(--lightblue);color: var(--lightblue);font-size: 15px;padding-left: 0;"></i>
                                            <p
                                                style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;">
                                                 Active</p>
                                        </div>
                                        <div class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                            style="background: var(--shadowgrey);padding: 7px;border-radius: 40px;"><i
                                                class="fa fa-minus-circle d-xxl-flex align-items-xxl-center"
                                                style="border-color: var(--lightblue);color: var(--textgrey);font-size: 15px;padding-left: 0;"></i>
                                            <p
                                                style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;">
                                                 Pending</p>
                                        </div><a href="#"><i class="fa fa-question-circle"
                                                style="color: var(--lightblue);font-size: 12px;"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex d-xxl-flex justify-content-center align-items-center rfqdetailrow"
                                style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;">
                                <div class="col d-flex d-xl-flex justify-content-center justify-content-xl-center">
                                    <p class="text-nowrap bluetextthin" style="font-weight: normal;">No Fields Edited
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer d-lg-flex align-items-lg-center"><a class="btn text-gray-500" role="button"
                            data-bs-dismiss="modal">Cancel</a><a
                            class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn"
                            role="button"
                            style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Save</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- RFQDETAILS -->

        <div role="dialog" tabindex="-1" class="modal fade" id="RFQdetails">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header" style="background: var(--lightblue);height: 56px;"><img
                            src="assets/Group 28.png" style="width: 42px;margin-right: 10px;" />
                        <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">
                            General
                            RFQ Requirements</h1><button type="button" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="col">
                                <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                    <div class="col">
                                        <div class="row g-0 d-xxl-flex align-items-xxl-center" style="width: 464px;">
                                            <div class="col">
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                        <input type="checkbox"
                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />HACCP
                                                        Cert requrements <a href="#"><i class="fa fa-question-circle"
                                                                style="color: var(--lightblue);font-size: 12px;"></i></a>
                                                    </p>
                                                </div>
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                        <input type="checkbox"
                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />SUV
                                                        Requirements <a href="#"><i class="fa fa-question-circle"
                                                                style="color: var(--lightblue);font-size: 12px;"></i></a>
                                                    </p>
                                                </div>
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                        <input type="checkbox"
                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />TUV
                                                        Cert Requirements <a href="#"><i class="fa fa-question-circle"
                                                                style="color: var(--lightblue);font-size: 12px;"></i></a>
                                                    </p>
                                                </div>
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                        <input type="checkbox"
                                                            style="width: 30px;margin: 10px;background: var(--lightblue);" />ISO
                                                        Requirement <a href="#"><i class="fa fa-question-circle"
                                                                style="color: var(--lightblue);font-size: 12px;"></i></a>
                                                    </p>
                                                </div>
                                                <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                    style="width: 223.391px;margin-left: 50px;"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                    <div class="col">
                                        <div class="row g-0 d-xxl-flex align-items-xxl-center" style="width: 464px;">
                                            <div class="col shadow-sm rounded" style="background: var(--shadowgrey);">
                                                <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                                    <div class="col" style="padding-left: 0;">
                                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                            style="margin-bottom: 0;padding-left: 30px;">Vendor Name</p>
                                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                                            style="margin-bottom: 0;"></p>
                                                        <div
                                                            style="height: 2rem;margin-left: 2rem;margin-top: 1rem;width: 18rem;">
                                                            <input type="text" class="form-control" autocomplete="off"
                                                                placeholder="gddsd@gmail.com"
                                                                style="width: 315px;height: 2rem;" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                                    <div class="col" style="padding-left: 0;">
                                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                            style="margin-bottom: 0;padding-left: 30px;">Vendor Number
                                                        </p>
                                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                                            style="margin-bottom: 0;"></p>
                                                        <div
                                                            style="height: 2rem;margin-left: 2rem;margin-top: 1rem;width: 18rem;">
                                                            <input type="text" class="form-control" autocomplete="off"
                                                                placeholder="081 222 4442"
                                                                style="width: 315px;height: 2rem;" />
                                                        </div>
                                                    </div>
                                                    <div class="col" style="padding-top: 10px;"><a
                                                            class="btn d-flex d-md-flex d-lg-flex justify-content-center align-items-center justify-content-md-center align-items-md-center justify-content-lg-center align-items-lg-center yellowbtn"
                                                            role="button"
                                                            style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;margin-left: 19px;">Add</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext"
                                            style="padding-top: 1rem;"><input type="checkbox"
                                                style="width: 30px;margin: 10px;background: var(--lightblue);" />B-BBEE
                                            Requirement <a href="#"><i class="fa fa-question-circle"
                                                    style="color: var(--lightblue);font-size: 12px;"></i></a></p>
                                        <div class="row g-0 d-xxl-flex align-items-xxl-center" style="width: 464px;">
                                            <div class="col shadow-sm rounded" style="background: var(--shadowgrey);">
                                                <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                                    <div class="col d-xxl-flex justify-content-xxl-center">
                                                        <div
                                                            class="d-flex d-lg-flex d-xxl-flex flex-row justify-content-center align-items-center align-items-lg-center align-items-xxl-center">
                                                            <p class="d-sm-flex justify-content-sm-start bluetextthin shift"
                                                                style="margin-bottom: 0;">Level</p>
                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                style="margin-bottom: 0;"></p>
                                                            <div class="d-flex d-lg-flex" role="group"
                                                                aria-label="Basic checkbox toggle button group">
                                                                <div class="btn-group bluebtn" role="group"
                                                                    aria-label="Basic checkbox toggle button group"
                                                                    style="padding-top: 5px;"><input type="radio"
                                                                        id="lvl1" class="btn-check form-check-input"
                                                                        name="flexRadioDefaultRFQ" /><label
                                                                        class="form-label form-check-label btn btn-outline-info"
                                                                        for="lvl1"
                                                                        style="border-radius: -113px;font-size: 14px;"
                                                                        name="flexRadioDefault">1</label></div>
                                                                <div class="btn-group bluebtn" role="group"
                                                                    aria-label="Basic checkbox toggle button group"
                                                                    style="padding-top: 5px;"><input type="radio"
                                                                        id="lvl2" class="btn-check form-check-input"
                                                                        name="flexRadioDefaultRFQ" /><label
                                                                        class="form-label form-check-label btn btn-outline-info"
                                                                        for="lvl2"
                                                                        style="border-radius: -113px;font-size: 14px;"
                                                                        name="flexRadioDefault">2</label></div>
                                                                <div class="d-lg-flex align-items-lg-center btn-group bluebtn"
                                                                    role="group"
                                                                    aria-label="Basic checkbox toggle button group"
                                                                    style="padding-top: 5px;"><input type="radio"
                                                                        id="lvl3" class="btn-check form-check-input"
                                                                        name="flexRadioDefaultRFQ" /><label
                                                                        class="form-label form-check-label btn btn-outline-info"
                                                                        for="lvl3"
                                                                        style="border-radius: -113px;font-size: 14px;"
                                                                        name="flexRadioDefault">3</label></div>
                                                                <div class="btn-group bluebtn" role="group"
                                                                    aria-label="Basic checkbox toggle button group"
                                                                    style="padding-top: 5px;"><input type="radio"
                                                                        id="lvl4" class="btn-check form-check-input"
                                                                        name="flexRadioDefaultRFQ" /><label
                                                                        class="form-label form-check-label btn btn-outline-info"
                                                                        for="lvl4"
                                                                        style="border-radius: -113px;font-size: 14px;"
                                                                        name="flexRadioDefault">4</label></div>
                                                                <div class="btn-group bluebtn" role="group"
                                                                    aria-label="Basic checkbox toggle button group"
                                                                    style="padding-top: 5px;"><input type="radio"
                                                                        id="lvl5" class="btn-check form-check-input"
                                                                        name="flexRadioDefaultRFQ" /><label
                                                                        class="form-label form-check-label btn btn-outline-info"
                                                                        for="lvl5"
                                                                        style="border-radius: -113px;font-size: 14px;"
                                                                        name="flexRadioDefault">5</label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                                    <div class="col" style="padding-top: 10px;"><a
                                                            class="btn d-flex d-md-flex d-lg-flex justify-content-center align-items-center justify-content-md-center align-items-md-center justify-content-lg-center align-items-lg-center yellowbtn"
                                                            role="button"
                                                            style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;margin-left: 19px;">Add</a>
                                                    </div>
                                                    <div class="col" style="padding-top: 10px;">
                                                        <p class="d-flex d-sm-flex justify-content-center justify-content-sm-center justify-content-lg-center bluetextthin shift"
                                                            style="margin-bottom: 0;">Level 4 Selected </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext"
                                            style="padding-top: 1rem;"><input type="checkbox"
                                                style="width: 30px;margin: 10px;background: var(--lightblue);" />ISO
                                            Requirement <a href="#"><i class="fa fa-question-circle"
                                                    style="color: var(--lightblue);font-size: 12px;"></i></a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer d-lg-flex align-items-lg-center"><a class="btn text-gray-500" role="button"
                            data-bs-dismiss="modal">Cancel</a><a
                            class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn"
                            role="button"
                            style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Save</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- ADD PREFFEREDBUYER -->


        <div role="dialog" tabindex="-1" class="modal fade" id="AddBuyer" data-bs-backdrop="static">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header" style="background: var(--lightblue);">
                        <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">
                            Add
                            Preferred Buyer</h1><button type="button" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="row text-end">
                                <div class="col">
                                    <p class="text-start blueheadsmall">Search for a preferred Buyer and click the save
                                        button when done.</p>
                                </div>
                            </div>


                            <div class="row rfqdetailrow">
                                <div class="col-sm-4 d-flex align-items-center">
                                    <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                        Select
                                        Buyer
                                        <span *ngIf="gettingBuyerDetails"
                                            class="spinner-border spinner-border-sm mr-1"></span>
                                    </p>
                                </div>
                                <div class="col">
                                    <div class="dropdown">
                                        <button class="btn btn dropdown-toggle" data-bs-toggle="dropdown" type="button"
                                            style="width: 100%; color:white; background-color: var(--lightblue);">Preferred
                                            buyer</button>
                                        <div class="dropdown-menu" style="padding-top: 0px;width: 306px;">
                                            <input [(ngModel)]="searchBuyers" type="search" class="form-control"
                                                style="padding-top: 8px;" placeholder="Search buyers" />

                                            <ng-container
                                                *ngIf="(initialBuyers | filterPipe: removeSFWhiteSpace(searchBuyers)) as result">
                                                <ng-container *ngFor="let buyer of result">
                                                    <a class="dropdown-item"
                                                        (click)="getPreferredBuyerSelect(buyer.buyerId)">{{buyer.name}}</a>
                                                </ng-container>
                                                <ng-container *ngIf="result.length == 0">
                                                    <p style="padding-top: 2px; padding-left: 2px; color: black;"
                                                        class="bluetext thin">No Search Results</p>
                                                </ng-container>
                                            </ng-container>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <form [formGroup]="addPreferedBuyerForm">

                                <div class="row rfqdetailrow">
                                    <div class="col d-flex align-items-center">
                                        <p
                                            class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                            Buyer Name
                                        </p>
                                    </div>
                                    <div class="col">
                                        <input formControlName="buyerName" type="text" class="form-control"
                                            autocomplete="off" placeholder="" style="height: 2rem;width: 315px;" />
                                    </div>
                                </div>

                                <div class="row rfqdetailrow">
                                    <div class="col d-flex align-items-center">
                                        <p
                                            class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                            Email
                                            Address</p>
                                    </div>
                                    <div class="col"><input formControlName="buyerEmail" type="text"
                                            class="form-control" autocomplete="off" placeholder=""
                                            style="height: 2rem;width: 315px;" />
                                        <!-- <div class="d-lg-flex d-xxl-flex justify-content-start align-items-lg-center align-items-xxl-center"
                                        style="padding-top: 8px;">
                                        <div class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                            style="background: var(--shadowgrey);padding: 7px;border-radius: 40px;margin-right: 5px;">
                                            <i class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                                style="border-color: var(--lightblue);color: var(--lightblue);font-size: 15px;padding-left: 0;"></i>
                                            <p
                                                style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;">
                                                 Active</p>
                                        </div>
                                        
                                        <a><i class="fa fa-question-circle"
                                                style="color: var(--lightblue);font-size: 12px;"></i></a>
                                    </div> -->
                                    </div>
                                </div>
                                <div class="row rfqdetailrow">
                                    <div class="col d-flex align-items-center">
                                        <p
                                            class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                            Phone
                                            Number</p>
                                    </div>
                                    <div class="col">
                                        <input formControlName="buyerContactNumber" type="text" class="form-control"
                                            autocomplete="off" placeholder="" style="height: 2rem;width: 315px;" />
                                    </div>
                                </div>



                                <div class="row rfqdetailrow">
                                    <div class="col d-flex align-items-center">
                                        <p
                                            class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                            Team

                                            <ng-container
                                                *ngIf="( preferredBuyerCond &&  stringValidation(pb.team.value) )">
                                                <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                                            </ng-container>

                                        </p>
                                    </div>
                                    <div class="col"><select formControlName="team" class="form-select"
                                            style="width: 315px;height: 2rem;">
                                            <option value="" disabled selected></option>
                                            <ng-container *ngFor="let team of selectedBuyer.teams">
                                                <option [value]="team">{{team}}</option>
                                            </ng-container>

                                        </select></div>
                                </div>

                            </form>

                            <!-- <div class="row d-flex d-xxl-flex justify-content-center align-items-center rfqdetailrow"
                                style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;margin-top: 16px;">
                                <div class="col d-flex d-xl-flex justify-content-center justify-content-xl-center">
                                    <p class="text-nowrap bluetextthin" style="font-weight: normal;">No Fields Edited</p>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="modal-footer d-lg-flex align-items-lg-center"><a class="btn text-gray-500" role="button"
                            data-bs-dismiss="modal">Cancel</a>

                        <button *ngIf="pb.team.value != ''" (click)=" addPreferredBuyer()"
                            [disabled]="addingPreferredBuyer"
                            class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn"
                            role="button"
                            style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Add
                            <span *ngIf="addingPreferredBuyer" class="spinner-border spinner-border-sm mr-1"></span>
                        </button>

                        <a *ngIf="pb.team.value == ''" (click)="addPreferredBuyerValid()"
                            class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn"
                            role="button"
                            style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Add</a>

                        <button #addPreferredBuyerBtn style="display: none;" data-bs-dismiss="modal"></button>

                    </div>
                </div>
            </div>
        </div>


        <!-- CREDIT APPS MODALS -->



        <!-- UPLOAD MODAL -->

        <div id="credutUpload" class="modal fade" role="dialog" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header" style="background: var(--lightblue);height: 56px;">
                        <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">
                            Credit
                            Application Form Upload</h1><button class="btn-close" type="button" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="col">
                                <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                    <div class="col">
                                        <div class="row text-end" style="padding-bottom: 10px;">
                                            <div class="col">
                                                <p class="text-center blueheadsmall">You are about to Upload a credit
                                                    application template</p>
                                            </div>
                                        </div>
                                        <div style="height: auto;margin-left: 2rem;margin-top: 1rem;width: 25rem;">
                                            <p class="d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-start justify-content-xxl-center bluetext"
                                                style="margin-bottom: 0;">Credit Application Template</p>
                                            <div
                                                class="d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center">
                                                <input #clearCreditAppInput type="file" style="display: none;"
                                                    (change)="uploadCreditApplicationTemplateFileInitial($event)"
                                                    #CreditAppTemplateUpload accept="{{requiredCRFileType}}">
                                                <p class="d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-start justify-content-xxl-center align-items-xxl-center bluetextthin"
                                                    style="margin-bottom: 0;padding: 5px;"><a
                                                        class="btn btn-link btn-sm d-xxl-flex align-items-xxl-start"
                                                        role="button" style="padding: 0px;"
                                                        (click)="CreditAppTemplateUpload.click()">Browse</a></p>
                                            </div>
                                            <div class="d-flex d-xl-flex justify-content-center justify-content-xl-center"
                                                style="padding-top: 1rem;"><img src="assets/suppliercredUpload.png"
                                                    style="width: 99px;" /></div>
                                            <div class="d-xl-flex justify-content-xl-center" style="padding-top: 1rem;">
                                                <div class="row g-0">
                                                    <div class="col">
                                                        <a *ngIf="CreditApplicationFileNamePreview == ''"
                                                            class="btn btn-secondary invoicebtn" role="button"
                                                            style="font-size: 14px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);padding: 0;"><i
                                                                class="fa fa-file-pdf-o"></i>{{supplierCompanyDetails.creditAppTemplateFileName}} </a>
                                                        <a *ngIf="CreditApplicationFileNamePreview != ''"
                                                            class="btn btn-secondary invoicebtn" role="button"
                                                            style="font-size: 14px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);padding: 0;"><i
                                                                class="fa fa-file-pdf-o"></i>{{CreditApplicationFileNamePreview}} </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer d-lg-flex align-items-lg-center"><a class="btn text-gray-500" role="button"
                            data-bs-dismiss="modal">Cancel</a>
                        <button [disabled]="creditApplicationTemplateUpload.file == null || CreatingCreditApplication"
                            class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn text-nowrap"
                            role="button" style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;"
                            (click)="uploadCreditApplicationTemplateFileFinal()"> Confirm
                            Upload<span *ngIf="CreatingCreditApplication" class="spinner-border spinner-border-sm mr-1"
                                style="margin-left: 3px;"></span></button>
                        <button style="display: none" data-bs-target="#templateUploadSucc" data-bs-toggle="modal"
                            data-bs-dismiss="modal" #closeCreditAppTemplateUploadMdl> </button>
                    </div>
                </div>
            </div>
        </div>

        <!--Confirmation Modal-->
        <div id="templateUploadSucc" class="modal fade" role="dialog" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header" style="background: var(--lightblue);">
                        <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">
                            Credit Applications</h1><button class="btn-close" type="button" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="row text-end">
                                <div class="col">
                                    <p class="text-start blueheadsmall">Upload Sucesfull<span><i
                                                class="fa fa-check-circle"
                                                style="color: var(--maingreen);margin-left: 10px;font-size: 18px;"></i></span>
                                    </p>
                                </div>
                            </div>
                            <div class="row rfqdetailrow">
                                <div class="col">
                                    <p class="bluetextthin">Your credit application template has been successfully
                                        uploaded. You can now start receiving credit application requests.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer d-lg-flex align-items-lg-center"><a class="btn text-gray-500" role="button"
                            data-bs-dismiss="modal">Close</a></div>
                </div>
            </div>
        </div>



        <!-- ACCEPT/REJECT -->


        <div id="creditAppAction" class="modal fade" role="dialog" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header" style="background: var(--lightblue);height: 56px;">
                        <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">
                            Accept/Reject Application </h1><button class="btn-close" type="button"
                            data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="col">
                                <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                    <div class="col">
                                        <div class="row text-end" style="padding-bottom: 10px;"
                                            *ngIf="selectedCreditApplicant != null">
                                            <div class="col">
                                                <p class="text-center "><span class="bluetextthin">You are about to
                                                        Accept or Reject
                                                        this
                                                        Credit Application for </span><span
                                                        class="blueheadsmall">{{selectedCreditApplicant.buyerCompanyName}}</span><span
                                                        class="bluetextthin"> for a period of </span> <span
                                                        class="blueheadsmall">{{selectedCreditApplicant.period}}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <form [formGroup]="creditAppActionForm">
                                            <div class="row g-0 d-xxl-flex align-items-xxl-center"
                                                style="width: 464px;">
                                                <div class="col">
                                                    <div>
                                                        <p
                                                            class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                            <input type="checkbox"
                                                                (change)="creditAppCheckboxToggle('Accepted')"
                                                                style="width: 30px;margin: 10px;background: var(--maingreen);"
                                                                formControlName="Accepted" />Accept
                                                            Credit Application <a>
                                                                <!-- <div class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                                                    style="background: var(--shadowgrey);padding: 7px;border-radius: 40px;margin-left: 30px;">
                                                                    <i class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 0;"></i>
                                                                    <p
                                                                        style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;">
                                                                         Accepted</p>
                                                                </div> -->
                                                            </a>
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p
                                                            class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                            <input type="checkbox"
                                                                (change)="creditAppCheckboxToggle('Rejected')"
                                                                style="width: 30px;margin: 10px;background: var(--maingreen);"
                                                                formControlName="Rejected" />Reject
                                                            Credit Application 
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <!-- <div class="row text-nowrap d-flex d-xxl-flex justify-content-center align-items-center rfqdetailrow"
                                    style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;">
                                    <div class="col d-flex d-xl-flex justify-content-center justify-content-xl-center">
                                        <p class="text-nowrap bluetextthin" style="font-weight: normal;">You Have
                                            Accepted
                                            Buyer1&#39;s credit application for 30 day Period</p>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer d-lg-flex align-items-lg-center"><a class="btn text-gray-500" role="button"
                            data-bs-dismiss="modal">Cancel</a><button [disabled]="caa.Accepted.value == false && caa.Rejected.value == false"
                            class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn"
                            role="button"
                            (click) = "takeCreditAppAction()"
                            style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Save<span *ngIf="CreatingCreditApplication"
                            class="spinner-border spinner-border-sm mr-1"></span></button>
                            <button #creditAppActionClose data-bs-target="#creditAppAction" data-bs-toggle="modal"
                            class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn"
                            role="button"
                            style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px; display: none !important">Save</button>
                    </div>
                </div>
            </div>
        </div>







    </div>
</ng-container>


<ng-container #loading *ngIf="dataLoading">



    <div class="container-fluid d-flex justify-content-center align-items-center"
        style="padding-bottom:10rem;min-height: 100vh; background-color: var(--supplierbg); ">
        <div class="text-center text-center bluehead"><span class="spinner-border spinner-border-sm mr-1"></span>
            Loading...
        </div>
    </div>

</ng-container>