<form (ngSubmit)="createChatMessage()" [formGroup]='form' #test="ngForm" class="chatform"
  style="width: 328px ;    z-index: 5;">
  <!-- {{test.value | json}}   -->
  <div
    class="row row-cols-2 text-center d-flex d-sm-flex d-lg-flex d-xl-flex justify-content-between justify-content-sm-center justify-content-lg-center justify-content-xl-center align-items-xl-center lightbluechatheader"
    style="height: 46px;width: 328px;margin-left: 0px;">
    <div
      class="col d-flex d-lg-flex d-xxl-flex align-items-center align-items-lg-center justify-content-xxl-start align-items-xxl-center"
      style="height: 40px;padding-top: 6px;"><a class="btn" role="button" style="color: var(--white);"
        data-bs-target=".collapsechat" data-bs-toggle="collapse" aria-expanded="true"><svg
          xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
          class="bi bi-chat-left-fill">
          <path fill-rule="evenodd"
            d="M2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z">
          </path>
        </svg></a>
      <p style="color: var(--shadowgrey);">Chat</p>










    </div>
    <div
      class="col text-end d-flex d-flex d-flex justify-content-end justify-content-end align-items-center align-items-center justify-content-end align-items-center"
      style="height: 40px;"><span class="d-xxl-flex justify-content-xxl-center align-items-xxl-center">


        <ng-container *ngIf="this.chatHistoryNewMessageCheck == true">

          <button (click)=" newMessages(scrollMe)" class=" btn " type=" button"
            style="background: var(--yellow);margin-right: 1rem;padding-top: 0px;padding-bottom: 0px;border-radius: 93px;"><span
              class="text-nowrap" style="font-size: 12px;">New Unread Messages</span></button>


        </ng-container>

        <button (click)="triggerChatOpen(false)" class="btn chat " type="button"><svg xmlns="http://www.w3.org/2000/svg"
            width="1em" height="1em" viewBox="0 0 24 24" fill="none" style="color: var(--shadowgrey);">
            <path
              d="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
              fill="currentColor"></path>
          </svg></button>
      </span></div>
  </div>




  <div #scrollMe id="chatblock" class="collapsechat  shadow p show">
    <div class="row">
      <div class="col-xl-12 col-xxl-12 chatblock"
        style="padding: 2rem;border-radius: 0px 0px 20px 20px;padding-bottom: 0px;background: rgb(245, 248, 255);">
        <div>

          <ng-container *ngFor="let message of chatHistory.chatMessages ">
            <ng-container *ngIf="message.isSupplier">
              <div class="leftchat" style="padding-bottom:1rem;">
                <div class="row d-xxl-flex" style="padding-bottom: 0px;">
                  <div class="col-1 col-sm-1" style="padding-left: 0;">
                    <p class="d-sm-flex justify-content-sm-start bluetext"
                      style="margin-bottom: 0;padding-bottom: 1rem;">
                      <i class="fa fa-user-circle" style="font-size: 30px;color: #4272F740;"></i>
                    </p>
                  </div>
                  <div class="col d-flex d-lg-flex justify-content-start align-items-xxl-center"
                    style="max-width: 60%;padding: 7px;">
                    <p class="text-break text-start d-sm-flex bluetextthin chatleft">{{message.messageContent}}</p>
                  </div>
                </div>
                <div class="row d-xxl-flex" style="padding-bottom: 0px;">
                  <div class="col-1 col-sm-1" style="padding-left: 0;"></div>
                  <div
                    class="col d-flex d-sm-flex justify-content-start align-items-start justify-content-sm-start align-items-sm-start justify-content-md-start align-items-xxl-start">
                    <a class="bluetextthin" href="#" data-bs-toggle="collapse" data-bs-target="#comment1"><span
                        style="line-height: 0px;font-size: 12px;">

                        {{correctDateTime(message.createdAt) | date:'shortTime'}}</span><span
                        style="line-height: 0px;font-size: 12px;"> | </span><span
                        style="line-height: 0px;font-size: 12px;">{{message.createdAt |
                        date:'mediumDate'}}
                      </span></a>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="message.isBuyer">
              <div class="rightchat" style="padding-bottom:1rem;">
                <div
                  class="row d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex justify-content-end justify-content-sm-end justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end"
                  style="padding-bottom: 0px;">
                  <div class="col d-flex d-sm-flex justify-content-end justify-content-sm-end" style="max-width: 60%;">
                    <!-- <p
                      class="d-sm-flex justify-content-sm-start justify-content-md-start justify-content-xl-start justify-content-xxl-start bluetextthin chatright">
                      {{message.messageContent}} </p> -->

                    <p class="text-break text-start d-flex justify-content-start bluetextthin chatright">
                      {{message.messageContent}} </p>
                  </div>
                  <div class="col-1 col-sm-1 col-xxl-1 justify-content-xxl-end align-items-xxl-center"
                    style="padding-left: 0;">
                    <p class="d-sm-flex justify-content-sm-start bluetext"><i
                        class="fa fa-user-circle d-xxl-flex justify-content-xxl-end"
                        style="font-size: 30px;color: #4272F740;"></i>
                    </p>
                  </div>
                </div>
                <div class="row d-xxl-flex rfqdetailrow" style="padding-bottom: 0px;">
                  <div
                    class="col d-flex d-sm-flex justify-content-end align-items-start justify-content-sm-end align-items-sm-start justify-content-md-end align-items-xxl-start">
                    <a class="bluetextthin" href="#" data-bs-toggle="collapse" data-bs-target="#comment1"><span
                        style="line-height: 0px;font-size: 12px;">




                        {{correctDateTime(message.createdAt) | date:'shortTime'}}</span><span
                        style="line-height: 0px;font-size: 12px;"> | </span><span
                        style="line-height: 0px;font-size: 12px;">{{message.createdAt |
                        date:'mediumDate'}}
                      </span></a>
                  </div>
                  <div class="col-1 col-sm-1" style="padding-left: 0;"></div>
                </div>
              </div>

            </ng-container>
          </ng-container>

        </div>

        <div *ngIf="chatHistory.active" class="row d-xxl-flex reply" style="padding-bottom: 0px;background: #ffffff;">
          <div class="col" style="padding: 0px;background: var(--supplierbg);width: 313px;">
            <div class="d-flex d-md-flex align-items-center align-items-md-center Chatreply">
              <div
                class="col-1 col-sm-1 col-md-1 d-flex d-xl-flex justify-content-center align-items-center align-items-xl-center"
                style="padding-left: 0;">
                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start align-items-sm-center bluetext">
                  <i class="fa fa-user-circle uiicon" style="font-size: 30px;color: #4272F740;"></i>
                </p>
              </div>


              <!-- Active -->
              <ng-container>
                <div
                  class="col-9 col-sm-11 col-md-11 col-xxl-11 d-flex d-sm-flex align-items-center justify-content-sm-center align-items-sm-center justify-content-xxl-end align-items-xxl-center"
                  style="padding: 1rem;padding-left: 1rem;margin: 0;margin-right: 1rem;margin-bottom: 0px;margin-left: 1rem;border-radius: 20px;background: rgb(245,248,255);">
                  <p>

                    <textarea matInput formControlName="message" class=" text-start form-control" role="textbox"
                      (keyup.enter)="createChatMessage()"
                      style="border-radius: 16px; background: #4272F71A; width: 225px; min-height: 70px; height: 70px; max-height: 70px;"
                      placeholder="Write...">
                  </textarea>

                  </p>
                  <a *ngIf="!sendingMessage" (click)="createChatMessage();"><i class="fa fa-send" id="send1"
                      style="padding: 1rem;background: rgb(245,248,255); cursor: pointer;"></i></a>
                  <i *ngIf="sendingMessage" class="spinner-border spinner-border-sm mr-1"></i>

                </div>
              </ng-container>

            </div>
          </div>
        </div>

        <!-- Archived -->
        <div *ngIf="!chatHistory.active" class="row d-xxl-flex reply" style="padding-bottom: 0px;background: #ffffff;">
          <div class="col" style="padding-right: 0px;margin-left: -60px;">
            <div class="d-flex d-md-flex align-items-center justify-content-md-center align-items-md-center"
              style="padding-top: 1rem;padding-bottom: 1rem;background: rgb(245, 248, 255);width: 404px;">
              <p class="bluehead" style="color: rgb(14,14,130);font-size: 18px;"><i class="fa fa-lock"
                  style="padding-right: 10px;"></i>Chat Archived</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</form>

<!-- <div class="ud-header">
    <h2 class="text-center">Public Chat</h2>
  </div>
  <div class="ud-body">
    <div class="msg-box">
      <ul>
        <li *ngFor="let mObj of msgInboxArray" [ngClass]="chatMessage.userId === chatMessage.userId ? 'in-msg' : 'ex-msg'">{{chatMessage.userId}} :<br /><span class="msg-italic-style">{{chatMessage.messageContent}}</span></li>
      </ul>
    </div>
    <div class="ud-flex-row">
      <input type="text" placeholder="Type message here" class="msg-input" [(ngModel)]="chatMessage.messageContent"  />
      <button class="send-btn" (click)= "send()"><i class="fa fa-paper-plane"></i></button>
    </div>
    <input type="text" placeholder="Your name" class="name-box" [(ngModel)]="chatMessage.userId" />
  </div> -->