<ng-container *ngIf="viewCompletedQuotes.length > 0 && !dataLoading">
    <div class="supplierbg">
        <main class="main" style="padding-top: 0px;background: var(--supplierbg);">
            <div>
                <div>
                    <div class="tab-content">
                        <div class="tab-pane active" role="tabpanel" id="tab-1">
                            <div class="container-fluid bluecont">
                                <h1 class="text-center" style="font-size: 20px;">Completed Quote For
                                    {{selectedRFQ.rfqName}}
                                </h1>
                            </div>
                            <div id="wrapper" style="background: var(--supplierbg);">
                                <nav class="navbar navbar-dark align-items-start navmargin sidebar sidebar-dark accordion p-0"
                                    style="padding: 0;margin-right: 24px;">
                                    <div class="container d-flex flex-column p-0">
                                        <hr class="sidebar-divider my-0">
                                        <ul class="navbar-nav text-light" id="accordionSidebar"
                                            style="min-width: 249px;">
                                            <li class="nav-item" data-bs-toggle="tooltip" data-bss-tooltip=""
                                                data-bs-placement="right" title="" style="padding-top: 10px;">
                                                <form [formGroup]="quoteStatusForm" #test="ngForm">
                                                    <div
                                                        class="row d-lg-flex d-xl-flex justify-content-evenly justify-content-xl-start justify-content-xxl-center align-items-xxl-center">
                                                        <div class="col d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group"><input
                                                                    type="checkbox" id="awaiting"
                                                                    formControlName="awaitingPayment"
                                                                    (click)="awaitOrArchivedRfqs('await',0)"
                                                                    (click)="triggerChatOpen(false)"
                                                                    class="btn-check form-check-input"
                                                                    name="diffparts"><label
                                                                    class="form-label form-check-label btn btn-outline-info"
                                                                    for="awaiting"
                                                                    style="border-radius: 0;font-size: 14px;width: 128.6094px;"
                                                                    name="flexRadioDefault2">Awaiting Payment</label>
                                                            </div>
                                                        </div>
                                                        <div class="col d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group"><input
                                                                    type="checkbox" id="archive"
                                                                    formControlName="archived"
                                                                    (click)="awaitOrArchivedRfqs('archived',0)"
                                                                    (click)="triggerChatOpen(false)"
                                                                    class="btn-check form-check-input"
                                                                    name="diffparts"><label
                                                                    class="form-label form-check-label btn btn-outline-info"
                                                                    for="archive"
                                                                    style="border-radius: 0;font-size: 14px;width: 128.6094px;"
                                                                    name="flexRadioDefault2">Archived</label></div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div class="input-group"><input
                                                        class="bg-light form-control border-0 small" type="text"
                                                        placeholder="Search for ..." [(ngModel)]="searchrfq"><button
                                                        class="btn btn-primary py-0" type="button"
                                                        style="background: var(--lightblue);"><i
                                                            class="fas fa-search"></i></button></div>
                                            </li>

                                            <!-- FILTER SECTION STARTS HERE -->

                                            <!-- FILTER SECTION STARTS HERE -->

                                            <li class="nav-item" data-bs-toggle="tooltip" data-bss-tooltip=""
                                                data-bs-placement="right" title="">




                                                <div class="accordion accordion-flush collapsed" role="tablist"
                                                    id="filterT">
                                                    <div class="accordion-item">
                                                        <h2 style="background-color: var(--shadowgrey);"
                                                            class="accordion-header" role="tab"><button
                                                                (click)="filterIconToggle()"
                                                                class="accordion-button collapsed sorthead"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#filterT .item-1" aria-expanded="false"
                                                                aria-controls="filter .item-1"
                                                                style="color: #0e0e82;font-size: 14px;  height: 40px;   padding: 0px;
                                                                                                                                                                                                                                                                            padding-left:15px; padding-right: 15px;">



                                                                <div
                                                                    *ngIf="this.filterOpen == false && this.filtersUsed == false ">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" fill="currentColor"
                                                                        class="bi bi-funnel" viewBox="0 0 16 16">
                                                                        <path
                                                                            d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z" />
                                                                    </svg>
                                                                </div>

                                                                <div
                                                                    *ngIf="this.filterOpen == true || this.filtersUsed == true">

                                                                    <svg class="bi bi-funnel-fill"
                                                                        xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="1em" fill="currentColor"
                                                                        viewBox="0 0 16 16"
                                                                        style="color: var(--darkblue);">
                                                                        <path
                                                                            d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z">
                                                                        </path>
                                                                    </svg>


                                                                </div>

                                                                FIlter
                                                            </button></h2>
                                                        <div class="accordion-collapse collapse item-1 sortdropdown"
                                                            style="    border-radius: 0px; max-height:400px;    overflow-y: scroll; overflow-x: hidden; "
                                                            role="tabpanel" data-bs-parent="#filterT">

                                                            <div class="d-flex justify-content-start"
                                                                style="position: sticky;top: 0;background: var(--shadowgrey); padding-bottom: 15px;     padding-right: 15px; padding-left: 1rem; border-radius: 0px; padding-top: 10px;">

                                                                <button (click)="clearRFQFilter()"
                                                                    class="btn btn-secondary applybtn"
                                                                    style="width:100px; background-color: var(--darkblue); margin-left: 5px; font-size: 12px;"
                                                                    type="button">Clear</button>
                                                            </div>
                                                            <div class="accordion-body">

                                                                <form [formGroup]="RFQsearch" #sheesh="ngForm">

                                                                    <div style="  padding-bottom: 3px;"><a>
                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                style="margin-bottom: 0;font-size: 8px;">
                                                                            </p>
                                                                            <p class="text-nowrap d-sm-flex justify-content-sm-start bluetext"
                                                                                style="margin-bottom: 0;font-size: 8px;">
                                                                                Type of RFQ</p>
                                                                        </a>
                                                                        <div class="form-check form-check-inline"><input
                                                                                (change)="RFQsearchFilter()"
                                                                                formControlName="complete"
                                                                                class="form-check-input" type="checkbox"
                                                                                id="complete"><label
                                                                                class="form-check-label radio bluetext thin"
                                                                                for="complete">Complete&nbsp; &nbsp;
                                                                                &nbsp;
                                                                                &nbsp;&nbsp;</label></div>
                                                                        <div class="form-check form-check-inline"><input
                                                                                formControlName="rawmat"
                                                                                (change)="RFQsearchFilter()"
                                                                                class="form-check-input" type="checkbox"
                                                                                id="rawmat"><label
                                                                                class="form-check-label radio bluetext thin"
                                                                                for="rawmat">Raw
                                                                                Material</label></div>
                                                                    </div>

                                                                    <div class="d-flex flex-column"
                                                                        style="padding-bottom: 3px;"><a>
                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                style="margin-bottom: 0;font-size: 8px;">
                                                                            </p>
                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                style="margin-bottom: 0;font-size: 8px;">
                                                                                Payment Options</p>
                                                                        </a>
                                                                        <div class="form-check form-check-inline"><input
                                                                                (change)="RFQsearchFilter()"
                                                                                formControlName="cod"
                                                                                class="form-check-input "
                                                                                type="checkbox" id="cod"><label
                                                                                class="form-check-label radio bluetext thin"
                                                                                for="cod">COD</label></div>

                                                                        <div class="form-check form-check-inline"><input
                                                                                (change)="RFQsearchFilter()"
                                                                                formControlName="cbd"
                                                                                class="form-check-input" type="checkbox"
                                                                                id="cbd"><label
                                                                                class="form-check-label radio bluetext thin"
                                                                                for="cbd">CBD</label></div>


                                                                        <div class="form-check form-check-inline"><input
                                                                                (change)="RFQsearchFilter()"
                                                                                formControlName="thirtyDays"
                                                                                class="form-check-input" type="checkbox"
                                                                                id="thirtyDays"><label
                                                                                class="form-check-label radio bluetext thin"
                                                                                for="thirtyDays">30 Days</label></div>
                                                                        <div class="form-check form-check-inline"><input
                                                                                (change)="RFQsearchFilter()"
                                                                                formControlName="sixtyDays"
                                                                                class="form-check-input" type="checkbox"
                                                                                id="sixtyDays"><label
                                                                                class="form-check-label radio bluetext thin"
                                                                                for="sixtyDays">60 Days</label></div>
                                                                        <div class="form-check form-check-inline"><input
                                                                                (change)="RFQsearchFilter()"
                                                                                formControlName="paymentOther"
                                                                                class="form-check-input" type="checkbox"
                                                                                id="paymentOther"><label
                                                                                class="form-check-label radio bluetext thin"
                                                                                for="paymentOther">Other</label></div>
                                                                    </div>
                                                                    <div class="d-flex flex-column"
                                                                        style="padding-bottom: 3px;"><a>
                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                style="margin-bottom: 0;font-size: 8px;">
                                                                            </p>
                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                style="margin-bottom: 0;font-size: 8px;">
                                                                                Delivery or Collection</p>
                                                                        </a>
                                                                        <div class="form-check form-check-inline"><input
                                                                                formControlName="delivery"
                                                                                (change)="RFQsearchFilter()"
                                                                                class="form-check-input" type="checkbox"
                                                                                id="delivery"><label
                                                                                class="form-check-label radio bluetext thin"
                                                                                for="delivery">Delivery</label></div>
                                                                        <div class="form-check form-check-inline"><input
                                                                                formControlName="collection"
                                                                                (change)="RFQsearchFilter()"
                                                                                class="form-check-input" type="checkbox"
                                                                                id="collection"><label
                                                                                class="form-check-label radio bluetext thin"
                                                                                for="collection">Collection</label>
                                                                        </div>

                                                                    </div>

                                                                    <div class="d-flex flex-column"
                                                                        style="padding-bottom: 3px;"><a>
                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                style="margin-bottom: 0;font-size: 8px;">
                                                                            </p>
                                                                            <p class="text-nowrap d-sm-flex justify-content-sm-start bluetext"
                                                                                style="margin-bottom: 0;font-size: 8px;">
                                                                                Packaging Options</p>
                                                                        </a>
                                                                        <div class="form-check form-check-inline"><input
                                                                                (change)="RFQsearchFilter()"
                                                                                formControlName="boxed"
                                                                                class="form-check-input" type="checkbox"
                                                                                id="boxed"><label
                                                                                class="form-check-label radio bluetext thin"
                                                                                for="boxed">Boxed</label></div>
                                                                        <div class="form-check form-check-inline"><input
                                                                                (change)="RFQsearchFilter()"
                                                                                formControlName="pallets"
                                                                                class="form-check-input" type="checkbox"
                                                                                id="pallets"><label
                                                                                class="form-check-label radio bluetext thin"
                                                                                for="pallets">Pallets</label></div>
                                                                        <div class="form-check form-check-inline"><input
                                                                                (change)="RFQsearchFilter()"
                                                                                formControlName="strapped"
                                                                                class="form-check-input" type="checkbox"
                                                                                id="strapped"><label
                                                                                class="form-check-label radio bluetext thin"
                                                                                for="strapped">Strapped</label></div>
                                                                        <div class="form-check form-check-inline"><input
                                                                                (change)="RFQsearchFilter()"
                                                                                formControlName="loose"
                                                                                class="form-check-input" type="checkbox"
                                                                                id="loose"><label
                                                                                class="form-check-label radio bluetext thin"
                                                                                for="loose">Loose</label></div>
                                                                        <div class="form-check form-check-inline"><input
                                                                                (change)="RFQsearchFilter()"
                                                                                formControlName="packagingOther"
                                                                                class="form-check-input" type="checkbox"
                                                                                id="packagingOther"><label
                                                                                class="form-check-label radio bluetext thin"
                                                                                for="packagingOther">Other</label></div>
                                                                    </div>


                                                                    <div class="d-flex flex-column"
                                                                        style="padding-bottom: 3px;"><a>
                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                style="margin-bottom: 0;font-size: 8px;">
                                                                            </p>
                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                style="margin-bottom: 0;font-size: 8px;">
                                                                                Test Certification</p>
                                                                        </a>
                                                                        <div class="form-check form-check-inline"><input
                                                                                (change)="RFQsearchFilter()"
                                                                                formControlName="testCertificationYes"
                                                                                class="form-check-input" type="checkbox"
                                                                                id="ftestCertificationYes"><label
                                                                                class="form-check-label radio bluetext thin"
                                                                                for="testCertificationYes">Yes </label>
                                                                        </div>
                                                                        <div class="form-check form-check-inline"><input
                                                                                (change)="RFQsearchFilter()"
                                                                                formControlName="testCertificationNo"
                                                                                class="form-check-input" type="checkbox"
                                                                                id="testCertificationNo"><label
                                                                                class="form-check-label radio bluetext thin"
                                                                                for="testCertificationNo">No</label>
                                                                        </div>

                                                                    </div>


                                                                </form>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>


                                            <li class="nav-item" data-bs-toggle="tooltip" data-bss-tooltip=""
                                                data-bs-placement="right" title="">
                                                <div class="greyline"></div>
                                            </li>
                                            <!-- SIDE RFQ NAV DISPLAY IF NO FILTER IS SELECTED -->
                                            <ng-container *ngIf="this.filtersUsed == false">
                                                <li class="nav-item" data-bs-toggle="tooltip" data-bss-tooltip=""
                                                    data-bs-placement="right" title=""
                                                    *ngFor="let heading of sortbyHeadings  | filterPipe:searchrfq">

                                                    <div
                                                        class="text-left text-white sideitem flex-container space-between  ">

                                                        <div
                                                            class="d-flex justify-content-between align-items-center text-nowrap">
                                                            <h1 class="flex-container space-between navlabel"
                                                                style="font-size: 16px;">
                                                                {{heading.rfqHeadName}}
                                                            </h1>
                                                            <span><button class="accordion-button collapsed sorthead"
                                                                    data-bs-toggle="collapse"
                                                                    [attr.data-bs-target]="'#b' + heading.id"
                                                                    aria-expanded="false"
                                                                    style="color: #0e0e82;font-size: 14px;height: 0px;">
                                                                    <!-- FLAG FOR BLIP WHEN SUPPLIER NEEDS TO TAKE ACTION -->
                                                                    <span *ngIf="heading.openQuotesCounter == 1">
                                                                        <i style="color:red" class="fas fa-circle"></i>
                                                                    </span>
                                                                </button></span>


                                                        </div>


                                                        <div class="blueline"></div>
                                                        <div [attr.id]="'b' + heading.id" class="collapse"
                                                            style="height:100px;">
                                                            <div class="scrollbox">
                                                                <div
                                                                    *ngFor="let rfq of heading.rfq | filterPipe:searchrfq">
                                                                    <button [disabled]="loadingRfq"
                                                                        (click)="triggerChatOpen(false)"
                                                                        [class.rfqbuttonsselected]=" this.rfq.SideSelectedRFQ == true"
                                                                        [class.rfqbuttons]=" this.rfq.SideSelectedRFQ == false"
                                                                        (click)="triggerSelectRfqLoading()"
                                                                        (click)="onSelectRFQNav(rfq)"
                                                                        class="rfqbuttonsselected  align-items-start sidebutton bluetext"
                                                                        type="button"
                                                                        style="margin-bottom: 10px;height:auto;font-size: 14px;padding: 5px;">
                                                                        {{rfq.rfqName}}<span
                                                                            *ngIf="loadingRfq && loadingRfqSpinnerId == rfq.rfqId "
                                                                            class="spinner-border spinner-border-sm mr-1"></span>
                                                                        <br /><span style="font-size: 12px;">({{
                                                                            rfq.quoteSubmissionClosingDate |
                                                                            date:'mediumDate'
                                                                            }})</span>
                                                                        <br /><br />
                                                                        <div class="d-flex justify-content-evenly align-items-center"
                                                                            style="margin-top: -15px;border-top-width: 25px;border-top-color: var(--textgrey);">
                                                                            <!-- IF ACTION NEEDED FROM SUPPLIER THEN BLIP -->

                                                                            <i *ngIf="((rfq.quoteDetails.invoiceQuoteFileId == null) || (rfq.quoteDetails.invoiceQuoteFileId != null && rfq.quoteDetails.paymentRefecence != null && rfq.quoteDetails.paymentReceived == false) || (rfq.productCertificationRequirements == true && rfq.quoteDetails.paymentReceived && rfq.quoteDetails.testCertificationFileId  == null)); else greybell"
                                                                                message-badge-bell="ss"
                                                                                [attr.data-badge]="" class="fas fa-bell"
                                                                                style="color: var(--darkblue);"></i>
                                                                            <!-- IF NO ACTION NEEDED FROM SUPPLIER THEN GREY BELL -->

                                                                            <ng-template #greybell>
                                                                                <i class=" fas fa-bell"
                                                                                    style="color: var(--textgrey);"></i>
                                                                            </ng-template>


                                                                            <!-- Messages Indicators -->
                                                                            <div>
                                                                                <div *ngIf="rfq.quoteDetails.unreadPrivateMessages == true"
                                                                                    message-badge-small=""
                                                                                    [attr.data-badge]="rfq.quoteDetails.unreadPrivateChatHistory.length">

                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                        width="1em" height="1em"
                                                                                        fill="currentColor"
                                                                                        viewBox="0 0 16 16"
                                                                                        class="bi bi-chat-right-fill"
                                                                                        style="color: var(--darkblue);">
                                                                                        <path
                                                                                            d="M14 0a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z">
                                                                                        </path>

                                                                                    </svg>

                                                                                </div>
                                                                                <div
                                                                                    *ngIf="rfq.quoteDetails.unreadPrivateMessages == false">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                        width="1em" height="1em"
                                                                                        fill="currentColor"
                                                                                        viewBox="0 0 16 16"
                                                                                        class="bi bi-chat-right-fill"
                                                                                        style="color: var(--textgrey);">
                                                                                        <path
                                                                                            d="M14 0a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z">
                                                                                        </path>

                                                                                    </svg>


                                                                                </div>

                                                                            </div>

                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                width="1em" height="2em"
                                                                                viewBox="0 0 20 20" fill="none"
                                                                                style="color: var(--textgrey);">
                                                                                <path fill-rule="evenodd"
                                                                                    clip-rule="evenodd"
                                                                                    d="M18 3C18 2.65342 17.8205 2.33156 17.5257 2.14935C17.2309 1.96714 16.8628 1.95058 16.5528 2.10557L8.76393 6H5C3.34315 6 2 7.34315 2 9C2 10.6569 3.34315 12 5 12H5.27925L7.05132 17.3162C7.18744 17.7246 7.56958 18 8.00001 18H9.00001C9.55229 18 10 17.5523 10 17V12.618L16.5528 15.8944C16.8628 16.0494 17.2309 16.0329 17.5257 15.8507C17.8205 15.6684 18 15.3466 18 15V3Z"
                                                                                    fill="currentColor"></path>
                                                                            </svg>

                                                                            <!-- PUBLIC CHAT BLIPS -->
                                                                            <!-- 
                                                                                                                                                                        <div>
                                                                                                                                                                    </div> -->
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </li>
                                            </ng-container>

                                            <!-- SIDE RFQ NAV DISPLAY IF A FILTER IS SELECTED -->
                                            <ng-container *ngIf="this.filtersUsed == true">
                                                <li class="nav-item" data-bs-toggle="tooltip" data-bss-tooltip=""
                                                    data-bs-placement="right" title=""
                                                    *ngFor="let heading of filterSortbyHeadings  | filterPipe:searchrfq">

                                                    <div
                                                        class="text-left text-white sideitem flex-container space-between  ">

                                                        <div
                                                            class="d-flex justify-content-between align-items-center text-nowrap">
                                                            <h1 class="flex-container space-between navlabel"
                                                                style="font-size: 16px;">
                                                                {{heading.rfqHeadName}}
                                                            </h1>
                                                            <span><button class="accordion-button collapsed sorthead"
                                                                    data-bs-toggle="collapse"
                                                                    [attr.data-bs-target]="'#b' + heading.id"
                                                                    aria-expanded="false"
                                                                    style="color: #0e0e82;font-size: 14px;height: 0px;">
                                                                    <!-- FLAG FOR BLIP WHEN SUPPLIER NEEDS TO TAKE ACTION -->
                                                                    <span *ngIf="heading.openQuotesCounter == 1">
                                                                        <i style="color:red" class="fas fa-circle"></i>
                                                                    </span>
                                                                </button></span>


                                                        </div>


                                                        <div class="blueline"></div>
                                                        <div [attr.id]="'b' + heading.id" class="collapse"
                                                            style="height:100px;">
                                                            <div class="scrollbox">
                                                                <div
                                                                    *ngFor="let rfq of heading.rfq | filterPipe:searchrfq">
                                                                    <button [disabled]="loadingRfq"
                                                                        (click)="triggerChatOpen(false)"
                                                                        [class.rfqbuttonsselected]=" this.rfq.SideSelectedRFQ == true"
                                                                        [class.rfqbuttons]=" this.rfq.SideSelectedRFQ == false"
                                                                        (click)="triggerSelectRfqLoading()"
                                                                        (click)="onSelectRFQNav(rfq)"
                                                                        class="rfqbuttonsselected  align-items-start sidebutton bluetext"
                                                                        type="button"
                                                                        style="margin-bottom: 10px;height:auto;font-size: 14px;padding: 5px;">
                                                                        {{rfq.rfqName}}<span
                                                                            *ngIf="loadingRfq && loadingRfqSpinnerId == rfq.rfqId "
                                                                            class="spinner-border spinner-border-sm mr-1"></span>
                                                                        <br /><span style="font-size: 12px;">({{
                                                                            rfq.quoteSubmissionClosingDate |
                                                                            date:'mediumDate'
                                                                            }})</span>
                                                                        <br /><br />
                                                                        <div class="d-flex justify-content-evenly align-items-center"
                                                                            style="margin-top: -15px;border-top-width: 25px;border-top-color: var(--textgrey);">
                                                                            <!-- IF ACTION NEEDED FROM SUPPLIER THEN BLIP -->
                                                                            <i *ngIf="((rfq.quoteDetails.invoiceQuoteFileId == null) || (rfq.quoteDetails.invoiceQuoteFileId != null && rfq.quoteDetails.paymentRefecence != null && rfq.quoteDetails.paymentReceived == false) || (rfq.productCertificationRequirements == true && rfq.quoteDetails.paymentReceived && rfq.quoteDetails.testCertificationFileId == null)); else greybell"
                                                                                message-badge-bell="ss"
                                                                                [attr.data-badge]="" class="fas fa-bell"
                                                                                style="color: var(--darkblue);"></i>
                                                                            <!-- IF NO ACTION NEEDED FROM SUPPLIER THEN GREY BELL -->

                                                                            <ng-template #greybell>
                                                                                <i class=" fas fa-bell"
                                                                                    style="color: var(--textgrey);"></i>
                                                                            </ng-template>


                                                                            <!-- Messages Indicators -->
                                                                            <div>
                                                                                <div *ngIf="rfq.quoteDetails.unreadPrivateMessages == true"
                                                                                    message-badge-small=""
                                                                                    [attr.data-badge]="rfq.quoteDetails.unreadPrivateChatHistory.length">

                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                        width="1em" height="1em"
                                                                                        fill="currentColor"
                                                                                        viewBox="0 0 16 16"
                                                                                        class="bi bi-chat-right-fill"
                                                                                        style="color: var(--darkblue);">
                                                                                        <path
                                                                                            d="M14 0a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z">
                                                                                        </path>

                                                                                    </svg>

                                                                                </div>
                                                                                <div
                                                                                    *ngIf="rfq.quoteDetails.unreadPrivateMessages == false">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                        width="1em" height="1em"
                                                                                        fill="currentColor"
                                                                                        viewBox="0 0 16 16"
                                                                                        class="bi bi-chat-right-fill"
                                                                                        style="color: var(--textgrey);">
                                                                                        <path
                                                                                            d="M14 0a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z">
                                                                                        </path>

                                                                                    </svg>


                                                                                </div>

                                                                            </div>

                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                width="1em" height="2em"
                                                                                viewBox="0 0 20 20" fill="none"
                                                                                style="color: var(--textgrey);">
                                                                                <path fill-rule="evenodd"
                                                                                    clip-rule="evenodd"
                                                                                    d="M18 3C18 2.65342 17.8205 2.33156 17.5257 2.14935C17.2309 1.96714 16.8628 1.95058 16.5528 2.10557L8.76393 6H5C3.34315 6 2 7.34315 2 9C2 10.6569 3.34315 12 5 12H5.27925L7.05132 17.3162C7.18744 17.7246 7.56958 18 8.00001 18H9.00001C9.55229 18 10 17.5523 10 17V12.618L16.5528 15.8944C16.8628 16.0494 17.2309 16.0329 17.5257 15.8507C17.8205 15.6684 18 15.3466 18 15V3Z"
                                                                                    fill="currentColor"></path>
                                                                            </svg>

                                                                            <!-- PUBLIC CHAT BLIPS -->
                                                                            <!-- 
                                                                                                                                                                                                                                <div>
                                                                                                                                                                                                                            </div> -->
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </nav>
                                <div *ngIf="!loadingRfq" class="d-flex flex-column" id="content-wrapper"
                                    style="background: var(--supplierbg);">


                                    <div class="d-xl-flex d-xxl-flex justify-content-xl-center justify-content-xxl-center"
                                        id="content" style="background: var(--supplierbg);">
                                        <div class="row row-cols-1 d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                            style="margin-left: 0;margin-right: 0;width: 100%;">
                                            <div class="col-xxl-8" style="width: 100%;">
                                                <div class="row" style="margin-right: 1px;">
                                                    <div class="col-lg-12 col-xl-12 col-xxl-11"
                                                        style="color: var(--darkblue);width: 100%;padding-left: 0; min-width: 700px;">
                                                        <div class="uirow bg-white rounded shadow-sm">
                                                            <div class="row g-0 d-flex" style="margin-bottom: 0.5rem;">
                                                                <div class="col d-flex d-flex justify-content-start align-items-center"
                                                                    style="padding: 0.5rem;padding-bottom: 0px;background: var(--shadowgrey);">
                                                                    <h1 class="text-nowrap text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel"
                                                                        style="font-size: 18px;margin-bottom: 0;">
                                                                        {{selectedRFQ.buyerDetails.name}}
                                                                    </h1>
                                                                    <div class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                                                        style="margin-left: 1rem;">
                                                                        <p class="d-xl-flex align-items-xl-center"
                                                                            style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;/*font-weight: 600;*/margin-right: 5px;">
                                                                            &nbsp;<img src="assets/loc.png"
                                                                                style="width: 21px;margin-right: 5px;">
                                                                            {{selectedRFQ.buyerDetails.city}},
                                                                            {{selectedRFQ.buyerDetails.province}}
                                                                        </p>
                                                                        <!-- <p class="d-xl-flex align-items-xl-center"
                                                                            style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;/*font-weight: 600;*/margin-right: 5px;">
                                                                            &nbsp;<img src="assets/353535.png"
                                                                                style="width: 21px;margin-right: 5px;">Jobs
                                                                            Completed</p> -->
                                                                        <p class="d-xl-flex align-items-xl-center"
                                                                            style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;/*font-weight: 600;*/margin-right: 5px;">
                                                                            &nbsp;<i class="fa fa-star"
                                                                                style="font-size: 15px;color: var(--yellow);"></i>&nbsp;Rating:
                                                                            <ng-container
                                                                                *ngIf="selectedRFQ.buyerDetails.rating != null">
                                                                                {{selectedRFQ.buyerDetails.rating |
                                                                                number
                                                                                :'1.0-1'}}
                                                                            </ng-container>
                                                                            <ng-container
                                                                                *ngIf="selectedRFQ.buyerDetails.rating == null">
                                                                                --
                                                                            </ng-container>
                                                                        </p>
                                                                        <p *ngIf="selectedRFQ.buyerDetails.isoRequirements"
                                                                            class="d-xl-flex align-items-xl-center"
                                                                            style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;/*font-weight: 600;*/margin-right: 5px;">
                                                                            <i class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                                                                style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 0;"></i>&nbsp;
                                                                            ISO Required
                                                                        </p>
                                                                        <p *ngIf="selectedRFQ.buyerDetails.beeCertificate"
                                                                            class="d-xl-flex align-items-xl-center"
                                                                            style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;/*font-weight: 600;*/margin-right: 5px;">
                                                                            <i class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                                                                style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 0;"></i>&nbsp;
                                                                            B-BBEE Level
                                                                            {{selectedRFQ.buyerDetails.beeRequirements}}
                                                                            Required
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="uirow bg-white rounded shadow-sm">
                                                            <div class="row g-0" style="margin-bottom: 0.5rem;">
                                                                <div class="col d-flex d-xxl-flex align-items-center align-items-xxl-center"
                                                                    style="padding: 0.5rem;padding-bottom: 0px;background: var(--shadowgrey);">
                                                                    <img src="assets/Group%2028.png"
                                                                        style="width: 42px;">
                                                                    <h1 class="text-nowrap text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel"
                                                                        style="font-size: 18px;margin-bottom: 0;">RFQ
                                                                        Details
                                                                    </h1>
                                                                    <!-- <div *ngIf="selectedRFQ.purchaseOrderFileId != null"
                                                                    class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                                                    style="background: var(--white);padding: 7px;border-radius: 40px;width: 349.844px;padding-left: 0;padding-right: 0;">
                                                                    <i class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 0;"></i>
                                                                    <p
                                                                        style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;">
                                                                        &nbsp;Purchase Order Uploaded</p>
                                                                </div> -->

                                                                    <!-- <ng-container
                                                                        *ngIf="selectedRFQ.quoteDetails.archived == false">
                                                                        <div *ngIf="selectedRFQ.purchaseOrderFileId != null"
                                                                            class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                                                            style="background: var(--white);padding: 7px;border-radius: 40px;width: 175px; min-width:175px; padding-left: 0;padding-right: 0;">
                                                                            <i class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                                                                style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 0;"></i>
                                                                            <p
                                                                                style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;">
                                                                                &nbsp;Purchase Order Uploaded

                                                                            </p>
                                                                        </div>

                                                                    </ng-container> -->

                                                                    <!-- Old Purchase Order Download Button -->
                                                                    <!-- <button
                                                                        [disabled]="selectedRFQ.purchaseOrderFileId == null"
                                                                        class="text-nowrap btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                                        role="button"
                                                                        (click)="downloadPurchaseOrderFile(selectedRFQ.purchaseOrderFileId)"
                                                                        style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                                            class="fa fa-download"
                                                                            style="font-size: 14px;color: var(--lightblue);"></i>&nbsp;Download
                                                                        Purchase Order

                                                                        <span class="text-nowrap d-xxl-flex"
                                                                            style="font-weight: normal;margin-right: 0rem;font-size: 12px; margin-left: 10px;">
                                                                            <i class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center"
                                                                                style="margin-right: 3px;"></i>{{selectedRFQ.purchaseOrderFileName}}
                                                                        </span>

                                                                        <div
                                                                            *ngIf="selectedRFQ.purchaseOrderFileId != null && selectedRFQ.quoteDetails.invoiceQuoteFileId == null && selectedRFQ.purchaseOrderDownloaded == false">
                                                                            <span
                                                                                class="d-flex justify-content-center align-items-center">
                                                                                <i style="margin-left:10px; padding:0px; font-size: 13px; color:red"
                                                                                    class="fas fa-circle"></i>
                                                                            </span>
                                                                        </div>


                                                                    </button> -->

                                                                    <!-- Download Rfq Files Modal Button -->
                                                                    <button
                                                                        class="text-nowrap btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                                        role="button" data-bs-toggle="modal"
                                                                        data-bs-target="#rfqFileDownloadsClosed"
                                                                        style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue); width: 320px;"><i
                                                                            class="fa fa-download"
                                                                            style="font-size: 14px;color: var(--lightblue);"></i>&nbsp;Download
                                                                        RFQ Files


                                                                        <div
                                                                            *ngIf="selectedRFQ.purchaseOrderFileId != null && selectedRFQ.quoteDetails.invoiceQuoteFileId == null && selectedRFQ.purchaseOrderDownloaded == false">
                                                                            <span
                                                                                class="d-flex justify-content-center align-items-center">
                                                                                <i style="margin-left:10px; padding:0px; font-size: 13px; color:red"
                                                                                    class="fas fa-circle"></i>
                                                                            </span>
                                                                        </div>


                                                                    </button>


                                                                    <div *ngIf="!paymentMadeCheck()" class=" d-flex
                                                                    d-xxl-flex justify-content-center
                                                                    justify-content-xxl-center"
                                                                        style="background: var(--white);padding: 7px;border-radius: 40px;">
                                                                        <i class="fa fa-check-circle d-flex d-xl-flex d-xxl-flex align-items-center align-items-xl-center align-items-xxl-center"
                                                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 0;"></i>
                                                                        <p
                                                                            style="margin-left:5px;font-size: 12px;color: var(--darkblue);margin-bottom: -1px;font-weight: 600;">
                                                                            Paid:</p>
                                                                        <p class="text-nowrap"
                                                                            style="margin-left:5px; font-size: 12px;color: var(--darkblue);margin-bottom: -1px;/*font-weight: 600;*/">
                                                                            {{selectedRFQ.quoteDetails.paymentReference}}
                                                                        </p>

                                                                    </div>
                                                                    <!-- <div *ngIf=" !paymentMadeCheck()"
                                                                        class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                                                        style="background: var(--white);padding: 7px;border-radius: 40px;">
                                                                        <i class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 0;"></i>
                                                                        <p
                                                                            style="font-size: 12px;color: var(--darkblue);margin-bottom: -1px;font-weight: 600;">
                                                                            &nbsp;Paid - Ref:</p>
                                                                        <p
                                                                            style="font-size: 12px;color: var(--darkblue);margin-bottom: -1px;/*font-weight: 600;*/">
                                                                            &nbsp;{{selectedRFQ.quoteDetails.paymentReference}}
                                                                        </p>
                                                                    </div> -->
                                                                    <button class="btn accordion-button collapsed"
                                                                        data-bs-toggle="collapse"
                                                                        data-bs-target=".rfqinner3" type="button"
                                                                        aria-controls=""></button>
                                                                </div>
                                                            </div>
                                                            <div class="row g-0 rfqinner3 collapse show"
                                                                style="padding: 0.5rem;padding-top: 0;">
                                                                <div class="col-xxl-12 uidetailblock">
                                                                    <div>
                                                                        <div class="row row-cols-2 rfqdetailrow"
                                                                            style="max-width: 1099px;">
                                                                            <div class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                                style="padding-right: 2rem;">
                                                                                <p class="text-nowrap bluetext"
                                                                                    style="color: #0e0e82;">RFQ Name:
                                                                                </p>
                                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                    style="margin-bottom: 0;">
                                                                                    {{selectedRFQ.rfqName}}

                                                                                </p>
                                                                            </div>
                                                                            <div class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                                style="padding-right: 2rem;">
                                                                                <p class="text-nowrap bluetext"
                                                                                    style="color: #0e0e82;">Type of
                                                                                    supply:
                                                                                </p>
                                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                    style="margin-bottom: 0;">
                                                                                    {{selectedRFQ.typeOfSupply}}

                                                                                </p>
                                                                            </div>
                                                                            <div class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                                style="padding-right: 2rem;">
                                                                                <p *ngIf="selectedRFQ.deliveryRequirements == 'Collection'"
                                                                                    class="text-nowrap bluetext"
                                                                                    style="color: #0e0e82;">Collection
                                                                                    Date:
                                                                                </p>
                                                                                <p *ngIf="selectedRFQ.deliveryRequirements == 'Delivery' || selectedRFQ.deliveryRequirements == null "
                                                                                    class="text-nowrap bluetext"
                                                                                    style="color: #0e0e82;">Delivery
                                                                                    Date:
                                                                                </p>
                                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                    style="margin-bottom: 0;">
                                                                                    {{selectedRFQ.maxLeadTime |
                                                                                    date:'mediumDate'}}
                                                                                </p>
                                                                            </div>

                                                                            <div *ngIf="selectedRFQ.deliveryRequirements == 'Delivery' || selectedRFQ.deliveryRequirements == null "
                                                                                class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                                style="padding-right: 2rem;">
                                                                                <p class="text-nowrap bluetext"
                                                                                    style="color: #0e0e82;">Delivery
                                                                                    Address 1:
                                                                                </p>
                                                                                <p *ngIf="selectedRFQ.deliveryStreetAddress == null"
                                                                                    class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                    style="margin-bottom: 0;">
                                                                                    {{selectedRFQ.buyerDetails.streetAddress}},
                                                                                    {{selectedRFQ.buyerDetails.city}}
                                                                                </p>
                                                                                <p *ngIf="selectedRFQ.deliveryStreetAddress != null"
                                                                                    class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                    style="margin-bottom: 0;">
                                                                                    {{selectedRFQ.deliveryStreetAddress}},
                                                                                    {{selectedRFQ.deliveryCity}}
                                                                                </p>
                                                                            </div>

                                                                            <div *ngIf="selectedRFQ.deliveryRequirements != null"
                                                                                class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                                style="padding-right: 2rem;">
                                                                                <p class="text-nowrap bluetext"
                                                                                    style="color: #0e0e82;">Delivery
                                                                                    Requirements:</p>
                                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                    style="margin-bottom: 0;">
                                                                                    {{selectedRFQ.deliveryRequirements}}
                                                                                </p>
                                                                            </div>

                                                                            <div *ngIf="selectedRFQ.deliveryRequirements == 'Delivery' || selectedRFQ.deliveryRequirements == null "
                                                                                class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                                style="padding-right: 2rem;">
                                                                                <p class="text-nowrap bluetext"
                                                                                    style="color: #0e0e82;">Delivery
                                                                                    Address 2:
                                                                                </p>
                                                                                <p *ngIf="selectedRFQ.deliveryStreetAddress == null"
                                                                                    class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                    style="margin-bottom: 0;">
                                                                                    {{selectedRFQ.buyerDetails.province}},
                                                                                    {{selectedRFQ.buyerDetails.postalCode}}

                                                                                </p>
                                                                                <p *ngIf="selectedRFQ.deliveryStreetAddress != null"
                                                                                    class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                    style="margin-bottom: 0;">
                                                                                    {{selectedRFQ.deliveryProvince}},
                                                                                    {{selectedRFQ.deliveryPostalCode}}

                                                                                </p>
                                                                            </div>


                                                                            <!-- <div class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                                style="padding-right: 2rem;">
                                                                                <p class="text-nowrap bluetext"
                                                                                    style="color: #0e0e82;">Closes on
                                                                                </p>
                                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                    style="margin-bottom: 0;">
                                                                                    {{selectedRFQ.quoteSubmissionClosingDate
                                                                                    | date:'mediumDate'}}
                                                                                </p>
                                                                            </div> -->



                                                                            <div *ngIf="selectedRFQ.testCertification != null"
                                                                                class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                                style="padding-right: 2rem;">
                                                                                <p class="text-nowrap bluetext"
                                                                                    style="color: #0e0e82;">Test
                                                                                    Certification:
                                                                                </p>
                                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                    style="margin-bottom: 0;">
                                                                                    {{selectedRFQ.testCertification}}
                                                                                </p>
                                                                            </div>
                                                                            <div *ngIf=" selectedRFQ.isoRequirement"
                                                                                class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                                style="padding-right: 2rem;">
                                                                                <p class="text-nowrap bluetext"
                                                                                    style="color: #0e0e82;">ISO
                                                                                    Requirement:
                                                                                </p>
                                                                                <p *ngIf="selectedRFQ.isoRequirement"
                                                                                    class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                    style="margin-bottom: 0;">
                                                                                    Required </p>
                                                                                <!-- <p *ngIf = "!selectedRFQ.isoRequirement" class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                style="margin-bottom: 0;">
                                                                                {{selectedRFQ.isoRequirement}} </p> -->
                                                                            </div>


                                                                            <div *ngIf="selectedRFQ.supplierLocationRequirements != null"
                                                                                class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                                style="padding-right: 2rem;">
                                                                                <p class="text-nowrap bluetext"
                                                                                    style="color: #0e0e82;">Supplier
                                                                                    Location Requirements:
                                                                                </p>
                                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                    style="margin-bottom: 0;">
                                                                                    {{selectedRFQ.supplierLocationRequirements}}
                                                                                </p>
                                                                            </div>

                                                                            <div *ngIf="selectedRFQ.paymentPreference != null"
                                                                                class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                                style="padding-right: 2rem;">
                                                                                <p class="text-nowrap bluetext"
                                                                                    style="color: #0e0e82;">Payment
                                                                                    Requirement:
                                                                                </p>
                                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                    style="margin-bottom: 0;">
                                                                                    {{selectedRFQ.paymentPreference}}
                                                                                </p>
                                                                            </div>

                                                                            <div *ngIf="selectedRFQ.beeRequirement != null"
                                                                                class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                                style="padding-right: 2rem;">
                                                                                <p class="text-nowrap bluetext"
                                                                                    style="color: #0e0e82;">B-BBEE
                                                                                    Requirement:
                                                                                </p>
                                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                    style="margin-bottom: 0;">
                                                                                    {{selectedRFQ.beeRequirement}}
                                                                                </p>
                                                                            </div>

                                                                            <div *ngIf="selectedRFQ.packagingRequirements != null"
                                                                                class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                                style="padding-right: 2rem;">
                                                                                <p class="text-nowrap bluetext"
                                                                                    style="color: #0e0e82;">Packaging
                                                                                    Requirement:
                                                                                </p>
                                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                    style="margin-bottom: 0;">
                                                                                    {{selectedRFQ.packagingRequirements}}
                                                                                </p>
                                                                            </div>

                                                                            <div *ngIf=" selectedRFQ.productCertificationRequirements != null"
                                                                                class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                                style="padding-right: 2rem;">
                                                                                <p class="text-nowrap bluetext"
                                                                                    style="color: #0e0e82;">Test
                                                                                    Certification: </p>
                                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                    style="margin-bottom: 0;">
                                                                                    <ng-container
                                                                                        *ngIf="selectedRFQ.productCertificationRequirements">
                                                                                        Required
                                                                                    </ng-container>
                                                                                    <ng-container
                                                                                        *ngIf="!selectedRFQ.productCertificationRequirements">
                                                                                        Not Required
                                                                                    </ng-container>
                                                                                </p>
                                                                            </div>

                                                                            <!-- <div *ngIf="selectedRFQ.verifiedFactoryRequirements != null"
                                                            class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                            style="padding-right: 2rem;">
                                                            <p class="text-nowrap bluetext"
                                                                style="color: #0e0e82;">Verified Factory Requirement
                                                            </p>
                                                            <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                style="margin-bottom: 0;">
                                                                {{selectedRFQ.verifiedFactoryRequirements}}
                                                            </p>
                                                        </div> -->

                                                                            <div *ngIf="selectedRFQ.partnerRequirement != null"
                                                                                class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                                style="padding-right: 2rem;">
                                                                                <p class="text-nowrap bluetext"
                                                                                    style="color: #0e0e82;">Partnership
                                                                                    Requirement:
                                                                                </p>
                                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                    style="margin-bottom: 0;">
                                                                                    {{selectedRFQ.partnerRequirement}}
                                                                                </p>
                                                                            </div>

                                                                        </div>
                                                                        <div *ngIf=" selectedRFQ.otherPaymentSpecification != '' &&  selectedRFQ.otherPaymentSpecification != null"
                                                                            style="color: var(--shadowgrey);background: var(--shadowgrey);border-radius: 14px;padding: 1rem;padding-bottom: 0;margin: 1rem;margin-left: 0;max-width: 600px;">
                                                                            <div
                                                                                class="row d-flex d-xxl-flex justify-content-center justify-content-xxl-center">
                                                                                <div
                                                                                    class="col d-flex d-xxl-flex justify-content-start align-items-xxl-center">
                                                                                    <p class="text-nowrap d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                                                                        style="color: #0e0e82;">Other
                                                                                        Payment Specifications:</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row rfqdetailrow">
                                                                                <div class="col">
                                                                                    <p
                                                                                        class="d-sm-flex justify-content-sm-start bluetextthin">
                                                                                        {{selectedRFQ.otherPaymentSpecification}}
                                                                                    </p>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                        style="margin-bottom: 0;"></p>
                                                                                </div>
                                                                            </div>
                                                                            <!-- <div class="row rfqdetailrow"
                                                                            style="padding: 0px;">
                                                                            <div class="col"><a href="/">
                                                                                    <p class="d-flex d-sm-flex justify-content-end justify-content-sm-start justify-content-xxl-end"
                                                                                        style="font-size: 12px;color: var(--darkblue);">
                                                                                        ... Read More</p>
                                                                                </a>
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="margin-bottom: 0;"></p>
                                                                            </div>
                                                                        </div> -->
                                                                        </div>
                                                                        <div *ngIf=" selectedRFQ.otherPackagingSpecification != '' &&  selectedRFQ.otherPackagingSpecification != null"
                                                                            style="color: var(--shadowgrey);background: var(--shadowgrey);border-radius: 14px;padding: 1rem;padding-bottom: 0;margin: 1rem;margin-left: 0;max-width: 600px;">
                                                                            <div
                                                                                class="row d-flex d-xxl-flex justify-content-center justify-content-xxl-center">
                                                                                <div
                                                                                    class="col d-flex d-xxl-flex justify-content-start align-items-xxl-center">
                                                                                    <p class="text-nowrap d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                                                                        style="color: #0e0e82;">Other
                                                                                        Packaging Specifications:</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row rfqdetailrow">
                                                                                <div class="col">
                                                                                    <p
                                                                                        class="d-sm-flex justify-content-sm-start bluetextthin">
                                                                                        {{selectedRFQ.otherPackagingSpecification}}
                                                                                    </p>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                        style="margin-bottom: 0;"></p>
                                                                                </div>
                                                                            </div>
                                                                            <!-- <div class="row rfqdetailrow"
                                                                        style="padding: 0px;">
                                                                        <div class="col"><a href="/">
                                                                                <p class="d-flex d-sm-flex justify-content-end justify-content-sm-start justify-content-xxl-end"
                                                                                    style="font-size: 12px;color: var(--darkblue);">
                                                                                    ... Read More</p>
                                                                            </a>
                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                style="margin-bottom: 0;"></p>
                                                                        </div>
                                                                    </div> -->
                                                                        </div>
                                                                        <div *ngIf="selectedRFQ.productIdentification != null"
                                                                            style="color: var(--shadowgrey);background: var(--shadowgrey);border-radius: 14px;padding: 1rem;padding-bottom: 0;margin: 1rem;margin-left: 0;max-width: 600px;">
                                                                            <div
                                                                                class="row d-flex d-xxl-flex justify-content-center justify-content-xxl-center">
                                                                                <div
                                                                                    class="col d-flex d-xxl-flex justify-content-start align-items-xxl-center">
                                                                                    <p class="text-nowrap d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                                                                        style="color: #0e0e82;">
                                                                                        Product Identification
                                                                                        Requirements:</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row rfqdetailrow">
                                                                                <div class="col">
                                                                                    <p
                                                                                        class="d-sm-flex justify-content-sm-start bluetextthin">
                                                                                        {{selectedRFQ.productIdentification}}
                                                                                    </p>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                        style="margin-bottom: 0;"></p>
                                                                                </div>
                                                                            </div>
                                                                            <!-- <div class="row rfqdetailrow"
                                                                            style="padding: 0px;">
                                                                            <div class="col"><a href="/">
                                                                                    <p class="d-flex d-sm-flex justify-content-end justify-content-sm-start justify-content-xxl-end"
                                                                                        style="font-size: 12px;color: var(--darkblue);">
                                                                                        ... Read More</p>
                                                                                </a>
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="margin-bottom: 0;"></p>
                                                                            </div>
                                                                        </div> -->
                                                                        </div>
                                                                        <div *ngIf="selectedRFQ.additionalComments != null"
                                                                            style="color: var(--shadowgrey);background: var(--shadowgrey);border-radius: 14px;padding: 1rem;padding-bottom: 0;margin: 1rem;margin-left: 0;max-width: 600px;">
                                                                            <div
                                                                                class="row d-flex d-xxl-flex justify-content-center justify-content-xxl-center">
                                                                                <div
                                                                                    class="col d-flex d-xxl-flex justify-content-start align-items-xxl-center">
                                                                                    <p class="text-nowrap d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                                                                        style="color: #0e0e82;">
                                                                                        Additional
                                                                                        Comments:</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row rfqdetailrow">
                                                                                <div class="col">
                                                                                    <p
                                                                                        class="d-sm-flex justify-content-sm-start bluetextthin">
                                                                                        {{selectedRFQ.additionalComments}}
                                                                                    </p>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                        style="margin-bottom: 0;"></p>
                                                                                </div>
                                                                            </div>
                                                                            <!-- <div class="row rfqdetailrow"
                                                                                style="padding: 0px;">
                                                                                <div class="col"><a href="/">
                                                                                        <p class="d-flex d-sm-flex justify-content-end justify-content-sm-start justify-content-xxl-end"
                                                                                            style="font-size: 12px;color: var(--darkblue);">
                                                                                            ... Read More</p>
                                                                                    </a>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                        style="margin-bottom: 0;"></p>
                                                                                </div>
                                                                            </div> -->
                                                                        </div>
                                                                        <div class="row d-flex d-sm-flex flex-row">
                                                                            <div
                                                                                class="col-xxl-2 text-nowrap d-flex d-xxl-flex align-items-center align-items-xxl-center">
                                                                                <p class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                                                                    style="color: #0e0e82;padding-right: 18px;">
                                                                                    Services Required:</p>
                                                                            </div>
                                                                            <div
                                                                                class="col-xxl-9 d-sm-flex d-lg-flex d-xxl-flex flex-row justify-content-sm-start align-items-sm-center justify-content-lg-start justify-content-xxl-start align-items-xxl-center">
                                                                                <div class="col"><button
                                                                                        *ngFor=" let service of selectedRFQ.rfqServicesRequired | sortAscDsc: criteria "
                                                                                        class="btn text-nowrap"
                                                                                        type="button"
                                                                                        style="font-size: 12px;background: var(--mainblue);color: var(--shadowgrey);border-radius: 166px;margin: 2px;">{{service}}
                                                                                        <a class="closeicon" href="#"
                                                                                            style="color: rgb(255,255,255);"></a></button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <!-- PUBLIC CHAT BUTTONS -->
                                                                        <div>
                                                                            <div>
                                                                                <div *ngIf="selectedRFQ.unreadPublicMessages == false"
                                                                                    class="col d-flex align-items-center">
                                                                                    <button data-bs-toggle="modal"
                                                                                        [attr.data-bs-target]="'#bwe' + selectedRFQ.rfqId"
                                                                                        class="btn btn-secondary invoicebtn"
                                                                                        type="button"
                                                                                        (click)="togglePeriodicReload(true)"
                                                                                        (click)=" togglePubChat(true)"
                                                                                        style="font-size: 12px;background: var(--lightblue);border-style: none;height: 36px;">Open
                                                                                        Public Chat</button>
                                                                                </div>
                                                                            </div>

                                                                            <div>
                                                                                <div *ngIf="selectedRFQ.unreadPublicMessages == true"
                                                                                    class="col d-flex align-items-center">
                                                                                    <button message-badge-smaller="ss"
                                                                                        [attr.data-badge]=""
                                                                                        data-bs-toggle="modal"
                                                                                        [attr.data-bs-target]="'#bwe' + selectedRFQ.rfqId"
                                                                                        class="btn btn-secondary invoicebtn"
                                                                                        type="button"
                                                                                        (click)="togglePeriodicReload(true)"
                                                                                        (click)=" togglePubChat(true)"
                                                                                        style="font-size: 12px;background: var(--lightblue);border-style: none;height: 36px;">Open
                                                                                        Public Chat</button>
                                                                                </div>
                                                                            </div>

                                                                        </div>




                                                                        <!-- <div class="row rfqdetailrow">
                                                                            <div class="col d-flex align-items-center">
                                                                                <button
                                                                                    (click)="togglePeriodicReload(true)"
                                                                                    data-bs-toggle="modal"
                                                                                    [attr.data-bs-target]="'#bwe' + selectedRFQ.rfqId"
                                                                                    class="btn btn-secondary invoicebtn"
                                                                                    type="button"
                                                                                    style="font-size: 12px;background: var(--lightblue);border-style: none;height: 36px;">Open
                                                                                    Public Chat</button>
                                                                            </div>
                                                                        </div> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="uirow bg-white rounded shadow-sm"
                                                            style="margin-bottom: 0.5rem;">
                                                            <div class="row g-0" id="quoteblock">
                                                                <div class="col d-flex d-xxl-flex align-items-center align-items-xxl-center"
                                                                    style="padding: 0.5rem;padding-bottom: 0px;background: var(--shadowgrey);">
                                                                    <h1 class="text-nowrap text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel"
                                                                        style="font-size: 18px;margin-bottom: 0;padding: 0;">
                                                                        <img src="assets/33333.png"
                                                                            style="padding-left: 6p;width: 42px;">
                                                                    </h1>
                                                                    <h1 class="text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel"
                                                                        style="font-size: 18px;margin-bottom: 0;">Quote
                                                                    </h1>
                                                                    <div class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                                                        style="background: var(--white);padding: 7px;border-radius: 40px;">
                                                                        <i class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 0;"></i>
                                                                        <p
                                                                            style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;">
                                                                            &nbsp;Accepted</p>
                                                                    </div>
                                                                    <!-- <a
                                                                        class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                                        role="button"
                                                                        style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                                            class="fa fa-edit"
                                                                            style="font-size: 14px;color: var(--lightblue);"></i>&nbsp;View</a><button
                                                                        class="btn accordion-button collapsed"
                                                                        data-bs-toggle="collapse"
                                                                        data-bs-target=".quoteInner3" type="button"
                                                                        aria-controls=""></button> -->
                                                                </div>
                                                            </div>
                                                            <div class="row g-0 quoteInner3 collapse show">
                                                                <div class="col-xxl-11 uidetailblock"
                                                                    style="padding: -2px;padding-top: 0px;width: 100%;">
                                                                    <div style="max-width: 1099px;">
                                                                        <div
                                                                            class="row d-xl-flex align-items-xl-center rfqdetailrow">
                                                                            <div class="col">
                                                                                <p class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                                                                    style="color: #0e0e82;">Total (Excl. VAT):</p>
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="margin-bottom: 0;"></p>
                                                                            </div>
                                                                            <div class="col">
                                                                                <p
                                                                                    class="d-sm-flex justify-content-sm-start bluetextthin">
                                                                                    {{selectedQuote.amount | currency:
                                                                                    'R '}}</p>
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="margin-bottom: 0;"></p>
                                                                            </div>
                                                                            <div class="col">
                                                                                <!-- <p class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                                                                    style="color: #0e0e82;">Terms &amp;
                                                                                    Conditions:</p>
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="margin-bottom: 0;"></p> -->
                                                                            </div>
                                                                            <div
                                                                                class="col d-flex d-xl-flex justify-content-start justify-content-xl-center justify-content-xxl-start">
                                                                                <!-- <a class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                                                    role="button"
                                                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                                                        class="fa fa-edit"
                                                                                        style="font-size: 14px;color: var(--lightblue);"></i>{{selectedQuote.termsAndConditions}}&nbsp;View</a> -->
                                                                                <!-- <a
                                                                                    class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                                                    role="button"
                                                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                                                        class="fa fa-edit"
                                                                                        style="font-size: 14px;color: var(--lightblue);"></i>&nbsp;Edit</a> -->
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            class="row d-xl-flex align-items-xl-center rfqdetailrow">
                                                                            <div class="col">
                                                                                <p class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                                                                    style="color: #0e0e82;">Lead Time:
                                                                                </p>
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="margin-bottom: 0;"></p>
                                                                            </div>
                                                                            <div class="col">
                                                                                <p
                                                                                    class="d-sm-flex justify-content-sm-start bluetextthin">
                                                                                    {{selectedQuote.leadTime}} days</p>
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="margin-bottom: 0;"></p>
                                                                            </div>
                                                                            <div class="col">
                                                                                <p class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                                                                    style="color: #0e0e82;">Quote:

                                                                                    <span class="text-nowrap d-xxl-flex"
                                                                                        style="font-weight: normal;margin-right: 2rem;font-size: 12px; margin-left: 10px;">
                                                                                        <i class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center"
                                                                                            style="margin-right: 3px;"></i>{{selectedRFQ.quoteDetails.quoteFileName}}
                                                                                    </span>

                                                                                </p>
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="margin-bottom: 0;"></p>
                                                                            </div>
                                                                            <div
                                                                                class="col d-flex d-xl-flex justify-content-start justify-content-xl-center justify-content-xxl-start">
                                                                                <a class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                                                    role="button"
                                                                                    (click)="downloadQuoteFiles(selectedQuote.quoteId)"
                                                                                    style="padding:0px;color:var(--darkblue);font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color:
                                                        var(--shadowgrey);"><i class="fa fa-download"
                                                                                        style="font-size: 14px;color: var(--lightblue);"></i>&nbsp;Download</a>

                                                                                <!-- <a
                                                                                    class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                                                    role="button"
                                                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                                                        class="fa fa-edit"
                                                                                        style="font-size: 14px;color: var(--lightblue);"></i>&nbsp;Edit</a> -->
                                                                            </div>
                                                                        </div>
                                                                        <div class="row d-flex d-sm-flex flex-row">
                                                                            <div
                                                                                class="col-xxl-2 text-nowrap d-flex d-xxl-flex align-items-center align-items-xxl-center">
                                                                                <p class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                                                                    style="color: #0e0e82;padding-right: 18px;">
                                                                                    Services Required:</p>
                                                                            </div>
                                                                            <div
                                                                                class="col-xxl-9 d-sm-flex d-lg-flex d-xxl-flex flex-row justify-content-sm-start align-items-sm-center justify-content-lg-start justify-content-xxl-start align-items-xxl-center">
                                                                                <div class="col"><button
                                                                                        *ngFor=" let qservice of selectedQuote.quotedServices | sortAscDsc: criteria "
                                                                                        class="btn text-nowrap"
                                                                                        type="button"
                                                                                        style="font-size: 12px;background: var(--mainblue);color: var(--shadowgrey);border-radius: 166px;margin: 2px;">{{qservice}}
                                                                                        <a class="closeicon" href="#"
                                                                                            style="color: rgb(255,255,255);"></a></button>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <ng-container
                                                                            *ngIf="selectedQuote.collectionStreetAddress != null">

                                                                            <div
                                                                                class="row d-xl-flex align-items-xl-center rfqdetailrow">
                                                                                <div class="col">
                                                                                    <p class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                                                                        style="color: #0e0e82;">
                                                                                        Collection Street Address:
                                                                                    </p>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                        style="margin-bottom: 0;"></p>
                                                                                </div>
                                                                                <div class="col">
                                                                                    <p
                                                                                        class="d-sm-flex justify-content-sm-start bluetextthin">
                                                                                        {{selectedQuote.collectionStreetAddress}}
                                                                                    </p>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                        style="margin-bottom: 0;"></p>
                                                                                </div>
                                                                                <div class="col">

                                                                                </div>
                                                                                <div
                                                                                    class="col d-flex d-xl-flex justify-content-start justify-content-xl-center justify-content-xxl-start">


                                                                                    <div class="col"
                                                                                        style="padding-left: 0;">

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                class="row d-xl-flex align-items-xl-center rfqdetailrow">
                                                                                <div class="col">
                                                                                    <p class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                                                                        style="color: #0e0e82;">
                                                                                        Collection City:
                                                                                    </p>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                        style="margin-bottom: 0;"></p>
                                                                                </div>
                                                                                <div class="col">
                                                                                    <p
                                                                                        class="d-sm-flex justify-content-sm-start bluetextthin">
                                                                                        {{selectedQuote.collectionCity}}
                                                                                    </p>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                        style="margin-bottom: 0;"></p>
                                                                                </div>
                                                                                <div class="col">

                                                                                </div>
                                                                                <div
                                                                                    class="col d-flex d-xl-flex justify-content-start justify-content-xl-center justify-content-xxl-start">


                                                                                    <div class="col"
                                                                                        style="padding-left: 0;">

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                class="row d-xl-flex align-items-xl-center rfqdetailrow">
                                                                                <div class="col">
                                                                                    <p class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                                                                        style="color: #0e0e82;">
                                                                                        Collection Postal Code:
                                                                                    </p>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                        style="margin-bottom: 0;"></p>
                                                                                </div>
                                                                                <div class="col">
                                                                                    <p
                                                                                        class="d-sm-flex justify-content-sm-start bluetextthin">
                                                                                        {{selectedQuote.collectionPostalCode}}
                                                                                    </p>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                        style="margin-bottom: 0;"></p>
                                                                                </div>
                                                                                <div class="col">

                                                                                </div>
                                                                                <div
                                                                                    class="col d-flex d-xl-flex justify-content-start justify-content-xl-center justify-content-xxl-start">


                                                                                    <div class="col"
                                                                                        style="padding-left: 0;">

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                class="row d-xl-flex align-items-xl-center rfqdetailrow">
                                                                                <div class="col">
                                                                                    <p class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                                                                        style="color: #0e0e82;">
                                                                                        Collection Province:
                                                                                    </p>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                        style="margin-bottom: 0;"></p>
                                                                                </div>
                                                                                <div class="col">
                                                                                    <p
                                                                                        class="d-sm-flex justify-content-sm-start bluetextthin">
                                                                                        {{selectedQuote.collectionProvince}}
                                                                                    </p>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                        style="margin-bottom: 0;"></p>
                                                                                </div>
                                                                                <div class="col">

                                                                                </div>
                                                                                <div
                                                                                    class="col d-flex d-xl-flex justify-content-start justify-content-xl-center justify-content-xxl-start">


                                                                                    <div class="col"
                                                                                        style="padding-left: 0;">

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </ng-container>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="uirow bg-white rounded shadow-sm"
                                                            style="margin-bottom: 0.5rem;">
                                                            <div class="row g-0" id="quoteblock"
                                                                style="padding-bottom: 4px;background: var(--shadowgrey);">
                                                                <div class="col d-flex d-xxl-flex align-items-center align-items-xxl-center"
                                                                    style="background: var(--shadowgrey);padding: 0.5rem;padding-bottom: 0px;">
                                                                    <h1 class="text-nowrap text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel"
                                                                        style="font-size: 18px;margin-bottom: 0;padding: 0;">
                                                                        <img src="assets/6543.png"
                                                                            style="padding-left: 6p;width: 42px;">
                                                                    </h1>
                                                                    <h1 class="text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel"
                                                                        style="font-size: 18px;margin-bottom: 0;">
                                                                        &nbsp;Sales
                                                                        Order</h1>
                                                                    <div *ngIf="selectedRFQ.quoteDetails.salesQuoteFileId != null"
                                                                        class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                                                        style="background: var(--white);padding: 7px;border-radius: 40px;">
                                                                        <i class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 0;"></i>
                                                                        <p
                                                                            style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;">
                                                                            &nbsp;Uploaded</p>
                                                                    </div>
                                                                </div>
                                                                <div class="col d-flex d-xxl-flex justify-content-end align-items-center justify-content-xxl-end align-items-xxl-center"
                                                                    style="background: var(--shadowgrey);">
                                                                    <p class="text-nowrap d-xxl-flex"
                                                                        style="font-weight: normal;margin-right: 2rem;font-size: 12px;">
                                                                        <i class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center"
                                                                            style="margin-right: 3px;"></i>{{selectedRFQ.quoteDetails.salesOrderFileName}}
                                                                    </p><button
                                                                        [disabled]="selectedRFQ.quoteDetails.salesQuoteFileId == null"
                                                                        class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                                        role="button"
                                                                        (click)="downloadSalesOrderFile(selectedRFQ.quoteDetails.salesQuoteFileId)"
                                                                        style="padding-right:10px;color:var(--darkblue);font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color:
                                                        var(--shadowgrey);"><i class="fa fa-download"
                                                                            style="font-size: 14px;color: var(--lightblue);"></i>&nbsp;Download</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="uirow bg-white rounded shadow-sm"
                                                            style="margin-bottom: 0.5rem;">
                                                            <div class="row g-0" id="quoteblock-1"
                                                                style="background: var(--shadowgrey);padding-bottom: 4px;">
                                                                <div class="col d-flex d-xxl-flex align-items-center align-items-xxl-center"
                                                                    style="padding: 0.5rem;padding-bottom: 0px;background: var(--shadowgrey);">
                                                                    <h1 class="text-nowrap text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel"
                                                                        style="font-size: 18px;margin-bottom: 0;padding: 0;">
                                                                        <img src="assets/6543.png"
                                                                            style="padding-left: 6p;width: 42px;">
                                                                    </h1>
                                                                    <h1 class="text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel"
                                                                        style="font-size: 18px;margin-bottom: 0;">
                                                                        &nbsp;Final
                                                                        Invoice</h1>
                                                                    <div *ngIf="selectedRFQ.quoteDetails.invoiceQuoteFileId != null"
                                                                        class="d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center"
                                                                        style="background: var(--white);padding: 7px;border-radius: 40px;">
                                                                        <i class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 0;"></i>
                                                                        <p
                                                                            style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;">
                                                                            &nbsp;Uploaded</p>
                                                                    </div>
                                                                    <div *ngIf="selectedRFQ.quoteDetails.invoiceQuoteFileId == null && !finalInvoiceUploading"
                                                                        class="col" style="padding-left: 0;">
                                                                        <input #clearInputInv type="file"
                                                                            class="file-input"
                                                                            (change)="InvoiceFileSelected($event)"
                                                                            #fileUpload412
                                                                            accept="{{requiredFileType}}">
                                                                        <button
                                                                            class="btn btn-secondary d-flex d-xxl-flex align-items-center invoicebtn"
                                                                            role="button"
                                                                            (click)="downloadedPurchaseOrderCheck(selectedRFQ.purchaseOrderFileId)"
                                                                            [disabled]="selectedRFQ.purchaseOrderFileId == null || finalInvoiceUploading || selectedRFQ.purchaseOrderDownloaded == false"
                                                                            style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);">
                                                                            <i class="fas fa-upload"
                                                                                style="font-size: 14px;color: var(--lightblue);"></i>&nbsp;Upload
                                                                            <span
                                                                                *ngIf="selectedRFQ.quoteDetails.invoiceQuoteFileId == null && selectedRFQ.purchaseOrderFileId != null && !finalInvoiceUploading && selectedRFQ.purchaseOrderDownloaded == true"
                                                                                class="d-flex justify-content-center align-items-center">
                                                                                <i style="margin-left:10px; padding:0px; font-size: 13px; color:red"
                                                                                    class="fas fa-circle"></i>
                                                                            </span>
                                                                        </button>



                                                                    </div>

                                                                    <div *ngIf="finalInvoiceUploading || finalInvoiceDeleting"
                                                                        class="text-center text-center bluehead thin"
                                                                        style="font-size: 14px;"><span
                                                                            class="spinner-border spinner-border-sm mr-1"></span>
                                                                        Uploading...
                                                                    </div>

                                                                </div>
                                                                <div class="col d-flex d-xxl-flex justify-content-end align-items-center justify-content-xxl-end align-items-xxl-center"
                                                                    style="background: var(--shadowgrey);">
                                                                    <p *ngIf="selectedRFQ.quoteDetails.invoiceQuoteFileId != null"
                                                                        class="text-nowrap d-xxl-flex"
                                                                        style="font-weight: normal;margin-right: 2rem;font-size: 12px;">
                                                                        <i class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center"
                                                                            style="margin-right: 3px;"></i>{{selectedRFQ.quoteDetails.invoiceOrderFileName}}
                                                                    </p>
                                                                    <button
                                                                        class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                                        role="button"
                                                                        (click)="downloadInvoiceFile(selectedRFQ.quoteDetails.invoiceQuoteFileId,selectedRFQ.quoteDetails.quoteId)"
                                                                        [disabled]="selectedRFQ.quoteDetails.invoiceQuoteFileId == null || finalInvoiceUploading || finalInvoiceDeleting"
                                                                        style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                                            class="fa fa-edit"
                                                                            style="font-size: 14px;color: var(--lightblue);"></i>&nbsp;Download</button>

                                                                    <input #clearInputInv type="file" class="file-input"
                                                                        (change)="InvoiceFileSelected($event)"
                                                                        #fileUpload5 accept="{{requiredFileType}}">
                                                                    <button
                                                                        [disabled]="selectedRFQ.quoteDetails.invoiceQuoteFileId == null || finalInvoiceUploading || finalInvoiceDeleting || selectedRFQ.quoteDetails.paymentReceived"
                                                                        (click)="fileUpload5.click()"
                                                                        class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                                        role="button"
                                                                        style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                                            class="fa fa-edit"
                                                                            style="font-size: 14px;color: var(--lightblue);"></i>&nbsp;Edit</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div *ngIf="selectedRFQ.productCertificationRequirements"
                                                            class="uirow bg-white rounded shadow-sm"
                                                            style="margin-bottom: 0.5rem;">
                                                            <div class="row g-0" id="quoteblock-1"
                                                                style="background: var(--shadowgrey);padding-bottom: 4px;">
                                                                <div class="col d-flex d-xxl-flex align-items-center align-items-xxl-center"
                                                                    style="padding: 0.5rem;padding-bottom: 0px;background: var(--shadowgrey);">
                                                                    <h1 class="text-nowrap text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel"
                                                                        style="font-size: 18px;margin-bottom: 0;padding: 0;">
                                                                        <img src="assets/6543.png"
                                                                            style="padding-left: 6p;width: 42px;">
                                                                    </h1>
                                                                    <h1 class="text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel"
                                                                        style="font-size: 18px;margin-bottom: 0;">
                                                                        &nbsp;Test Certificate</h1>

                                                                    <div *ngIf="selectedRFQ.quoteDetails.productCertificationQuoteFileId != null"
                                                                        class="d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center"
                                                                        style="background: var(--white);padding: 7px;border-radius: 40px;">
                                                                        <i class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 0;"></i>
                                                                        <p
                                                                            style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;">
                                                                            &nbsp;Uploaded</p>
                                                                    </div>
                                                                    <div *ngIf="selectedRFQ.quoteDetails.productCertificationQuoteFileId == null  && !testCertirficateUploading "
                                                                        class="col" style="padding-left: 0;">
                                                                        <input #clearTCInput type="file"
                                                                            class="file-input" #TCfileUpload
                                                                            (change)="TestCertificateSelected($event)"
                                                                            #fileUpload4 accept="{{requiredFileType}}">
                                                                        <button
                                                                            class="btn btn-secondary d-flex d-xxl-flex align-items-center invoicebtn"
                                                                            role="button" (click)="TCfileUpload.click()"
                                                                            [disabled]="testCertirficateUploading"
                                                                            style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);">
                                                                            <i class="fas fa-upload"
                                                                                style="font-size: 14px;color: var(--lightblue);"></i>&nbsp;Upload
                                                                            <div
                                                                                *ngIf="selectedRFQ.quoteDetails.productCertificationQuoteFileId == null && !testCertirficateUploading">
                                                                                <span
                                                                                    class="d-flex justify-content-center align-items-center">
                                                                                    <i style="margin-left:10px; padding:0px; font-size: 13px; color:red"
                                                                                        class="fas fa-circle"></i>
                                                                                </span>
                                                                            </div>


                                                                        </button>




                                                                    </div>

                                                                    <div *ngIf="testCertirficateUploading || testCetificateDeleting"
                                                                        class="text-center text-center bluehead thin"
                                                                        style="font-size: 14px;"><span
                                                                            class="spinner-border spinner-border-sm mr-1"></span>
                                                                        Uploading...
                                                                    </div>

                                                                </div>
                                                                <div class="col d-flex d-xxl-flex justify-content-end align-items-center justify-content-xxl-end align-items-xxl-center"
                                                                    style="background: var(--shadowgrey);">
                                                                    <p *ngIf="selectedRFQ.quoteDetails.productCertificationQuoteFileId != null"
                                                                        class="text-nowrap d-xxl-flex"
                                                                        style="font-weight: normal;margin-right: 2rem;font-size: 12px;">
                                                                        <i class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center"
                                                                            style="margin-right: 3px;"></i>{{selectedRFQ.quoteDetails.productCertificationFileName}}
                                                                    </p>
                                                                    <button
                                                                        class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                                        role="button"
                                                                        (click)="downloadProductCertificationFile(selectedRFQ.quoteDetails.productCertificationQuoteFileId,selectedRFQ.quoteDetails.quoteId)"
                                                                        [disabled]="selectedRFQ.quoteDetails.productCertificationQuoteFileId == null || testCertirficateUploading || testCetificateDeleting "
                                                                        style="padding:0px;color:var(--darkblue);font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color:
                                                                     var(--shadowgrey);"><i class="fa fa-download"
                                                                            style="font-size: 14px;color: var(--lightblue);"></i>&nbsp;Download</button>

                                                                    <input #clearTCInput type="file" class="file-input"
                                                                        (change)="TestCertificateSelected($event)"
                                                                        #fileUploadTCE accept="{{requiredFileType}}">
                                                                    <button
                                                                        [disabled]="selectedRFQ.quoteDetails.productCertificationQuoteFileId == null || testCertirficateUploading || testCetificateDeleting"
                                                                        (click)="fileUploadTCE.click()"
                                                                        class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                                        role="button"
                                                                        style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                                            class="fa fa-edit"
                                                                            style="font-size: 14px;color: var(--lightblue);"></i>&nbsp;Edit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="uirow bg-white rounded shadow-sm"
                                                            style="margin-top: 1rem;">
                                                            <div class="row g-0 row-cols-2" id="quoteblock-2"
                                                                style="background: var(--shadowgrey);">

                                                                <div class="col-xxl-2 offset-md-3 offset-lg-3 offset-xxl-5 d-flex d-xl-flex d-xxl-flex justify-content-center justify-content-xl-center justify-content-xxl-center"
                                                                    style="background: var(--shadowgrey);padding-top: 1rem;padding-bottom: 1rem;">

                                                                    <button data-bs-toggle="modal" #paymentReceivedBtn
                                                                        data-bs-target="#movedarchive"
                                                                        class="btn btn-secondary invoicebtn"
                                                                        type="button"
                                                                        style="display: none !important;font-size: 12px;background: var(--lightblue);border-style: none;width:180px;height: 3px;">Payment
                                                                        Received Hidden</button>
                                                                    <ng-container
                                                                        *ngIf="paymentMadeCheck() || selectedRFQ.quoteDetails.paymentReceived">
                                                                        <button disabled="true"
                                                                            class="btn btn-secondary invoicebtn"
                                                                            type="button"
                                                                            style="font-size: 12px;background: var(--lightblue);border-style: none;width:180px;height: 37px;">
                                                                            Mark Payment
                                                                            As Received <span *ngIf="confirmingPayment"
                                                                                class="spinner-border spinner-border-sm mr-1"></span></button>

                                                                    </ng-container>



                                                                    <button
                                                                        *ngIf="!paymentMadeCheck()  && selectedRFQ.quoteDetails.paymentReceived == false"
                                                                        message-badge-small="ss" [attr.data-badge]=""
                                                                        (click)="confirmPaymentCheck(selectedRFQ.rfqId,selectedRFQ.quoteDetails.quoteId,'Initial')"
                                                                        [disabled]=" paymentMadeCheck() || selectedRFQ.quoteDetails.paymentReceived || confirmingPayment"
                                                                        class="btn btn-secondary invoicebtn"
                                                                        type="button"
                                                                        style="font-size: 12px;background: var(--lightblue);border-style: none;width: 180px;height: 37px;">
                                                                        Mark Payment
                                                                        As Received <span *ngIf="confirmingPayment"
                                                                            class="spinner-border spinner-border-sm mr-1"></span></button>





                                                                </div>

                                                                <div *ngIf="selectedRFQ.quoteDetails.paymentReceived"
                                                                    class="col-md-2 col-lg-3 col-xxl-3 offset-md-0 d-flex d-xl-flex d-xxl-flex justify-content-start justify-content-xl-center justify-content-xxl-start"
                                                                    style="background: var(--shadowgrey);padding-top: 1rem;padding-bottom: 1rem;">
                                                                    <div class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                                                        style="background: var(--white);padding: 7px;border-radius: 40px;height: 31px;">
                                                                        <i class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 0;"></i>
                                                                        <p class="text-nowrap"
                                                                            style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;">
                                                                            &nbsp;Payment Received</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="uiblock" style="padding-bottom: 1rem;">
                                                            <!-- <button
                                                                class="btn btn-secondary active greybutton"
                                                                type="button"
                                                                style="background: #84848429;border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;color: #C0C0C0;">Back</button> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-start d-xxl-flex justify-content-xxl-center">
                                            <div class="container-fluid content-container" style="padding-left: 0;">
                                                <div class="row g-0 d-lg-flex flex-column justify-content-lg-center align-items-lg-center align-items-xl-center"
                                                    style="width: 100%;">
                                                    <div class="col offset-lg-0 d-xxl-flex justify-content-xxl-center">
                                                        <div class="row g-0 rfqdetailrow"
                                                            style="width: 315px;padding-top: 0;">
                                                            <div class="col shadow-sm rounded mb-2"
                                                                style="background: var(--shadowgrey);padding: 1rem;">
                                                                <p
                                                                    class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluehead">
                                                                    <img src="assets/353535.png"
                                                                        style="width: 30px;margin: 10px;">Status
                                                                </p>
                                                                <p *ngIf="selectedRFQ && !selectedRFQ.quoteDetails.archived"
                                                                    class="d-sm-flex justify-content-sm-start bluetext"
                                                                    style="margin-bottom: 0;margin-left: 51px;">RFQ
                                                                    COMPLETED</p>

                                                                <p *ngIf="selectedRFQ && selectedRFQ.quoteDetails.archived"
                                                                    class="d-sm-flex justify-content-sm-start bluetext"
                                                                    style="margin-bottom: 0;margin-left: 51px;">ARCHIVED
                                                                </p>

                                                                <ng-container
                                                                    *ngIf="!selectedRFQ.quoteDetails.archived && selectedRFQ.quoteDetails.paymentReference == null ">
                                                                    <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                        style="width: 223.391px;margin-left: 50px;">
                                                                        <p class="d-sm-flex justify-content-sm-start bluetextthin shift"
                                                                            style="margin-bottom: 0;">Payment Due On</p>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;">
                                                                            {{getPayDay(selectedRFQ.maxLeadTime) |
                                                                            date:'mediumDate'}}
                                                                        </p>
                                                                    </div>

                                                                    <div class="d-flex d-xxl-flex justify-content-between align-items-center align-items-xxl-center"
                                                                        style="width: 223.391px;margin-left: 50px;">
                                                                        <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                            style="margin-bottom: 0;">Time Left</p>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;">
                                                                            {{getClosingTime(selectedRFQ.maxLeadTime)}}<br>
                                                                        </p>

                                                                    </div>
                                                                </ng-container>

                                                                <ng-container
                                                                    *ngIf="!selectedRFQ.quoteDetails.archived && selectedRFQ.quoteDetails.paymentReference != null ">
                                                                    <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                        style="width: 223.391px;margin-left: 50px;">
                                                                        <p class="d-sm-flex justify-content-sm-start bluetextthin shift"
                                                                            style="margin-bottom: 0;">Awaiting Payment
                                                                            Confirmation</p>

                                                                    </div>


                                                                </ng-container>
                                                                <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                                    style="width: 223.391px;"></div>
                                                            </div>
                                                            <div class="col shadow-sm rounded mb-2"
                                                                style="background: var(--shadowgrey);padding: 1rem;">
                                                                <p
                                                                    class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluehead">
                                                                    <img src="assets/Group%20168.png"
                                                                        style="width: 30px;margin: 10px;">Private Chat
                                                                </p>
                                                                <!-- <ng-container
                                                                    *ngFor="let message of chatHistory.chatMessages let i=index">
                                                                    <div *ngIf="i == (chatHistory.chatMessages.length-1)"
                                                                        style="
                                                                                                                                    margin-left: 52px;">
                                                                        <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                            style="margin-bottom: 0;">

                                                                        </p>
                                                                        <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                            style="width: 223.391px;">
                                                                            <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                                style="margin-bottom: 0;">
                                                                                Last message:
                                                                            </p>
                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                style="margin-bottom: 0;">
                                                                                {{message.messageContent}}
                                                                            </p>

                                                                        </div>
                                                                        <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                            style="width: 223.391px;">
                                                                            <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                                style="margin-bottom: 0;">
                                                                                sent
                                                                            </p>
                                                                            <div class="bluetext"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target="#comment1"><span
                                                                                    style="line-height: 0px;font-size: 12px;">{{message.createdAt
                                                                                    |
                                                                                    date:'shortTime'}}</span><span
                                                                                    style="line-height: 0px;font-size: 12px;"> | </span><span
                                                                                    style="line-height: 0px;font-size: 12px;">{{message.createdAt
                                                                                    |
                                                                                    date:'mediumDate'}}
                                                                                </span></div>

                                                                        </div>
                                                  
                                                                    </div>
                                                                </ng-container> -->

                                                                <ng-container>
                                                                    <div class=" d-flex d-md-flex d-xxl-flex
                                                                    justify-content-center justify-content-md-center
                                                                    justify-content-xxl-center"
                                                                        style="padding-top: 0.5rem;padding-bottom: 0.5rem;">
                                                                        <ng-container
                                                                            *ngIf="this.unreadMessages == true">

                                                                            <button
                                                                                [attr.data-badge]="this.selectedQuote.unreadPrivateChatHistory.length"
                                                                                message-badge="" *ngIf="!openChat"
                                                                                (click)="togglePrivateChatbadge()"
                                                                                (click)="Sendquotedata(sentQID)"
                                                                                (click)="sendRfqStatus(this.selectedQuote.archived)"
                                                                                (click)="sendRfqState(this.selectedRFQ.state)"
                                                                                (click)="sendrfqPaymentRequirement(this.selectedRFQ.paymentPreference)"
                                                                                (click)="sendquoteState(this.selectedQuote.state)"
                                                                                (click)="triggerChatOpen(true)"
                                                                                (click)="this.unreadMessages = false;"
                                                                                class="btn btn-secondary invoicebtn"
                                                                                type="button"
                                                                                style="font-size: 12px;background: var(--lightblue);border-style: none;width: 127.344px;height: 36px ; margin-bottom: 1rem;">
                                                                                Open
                                                                                Chat
                                                                            </button>


                                                                        </ng-container>


                                                                        <ng-container
                                                                            *ngIf="this.unreadMessages == false">

                                                                            <button *ngIf="!openChat"
                                                                                (click)="Sendquotedata(sentQID)"
                                                                                (click)="sendRfqStatus(this.selectedQuote.archived)"
                                                                                (click)="sendRfqState(this.selectedRFQ.state)"
                                                                                (click)="sendrfqPaymentRequirement(this.selectedRFQ.paymentPreference)"
                                                                                (click)="sendquoteState(this.selectedQuote.state)"
                                                                                (click)="triggerChatOpen(true)"
                                                                                (click)="this.unreadMessages = false;"
                                                                                class="btn btn-secondary invoicebtn"
                                                                                type="button"
                                                                                style="font-size: 12px;background: var(--lightblue);border-style: none;width: 127.344px;height: 36px ; margin-bottom: 1rem;">
                                                                                Open
                                                                                Chat
                                                                            </button>


                                                                        </ng-container>

                                                                        <button *ngIf="openChat"
                                                                            (click)="triggerChatOpen(false)"
                                                                            class="btn btn-secondary invoicebtn"
                                                                            type="button"
                                                                            style="font-size: 12px;background: var(--lightblue);border-style: none;width: 127.344px;height: 36px; margin-bottom: 1rem;">
                                                                            Close
                                                                            Chat

                                                                        </button>

                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                            <div *ngIf="selectedRFQ.rfqFiles.length > 0 && selectedRFQ.dimensions.length == 0"
                                                                class="col shadow-sm rounded mb-2"
                                                                style="padding: 1rem;background: var(--shadowgrey);">
                                                                <p
                                                                    class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-sm-center align-items-xxl-center bluehead">
                                                                    <img src="assets/6543.png"
                                                                        style="width: 30px;margin: 10px;">View Drawings
                                                                </p>
                                                                <!-- <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                    style="margin-bottom: 0;margin-left: 51px;">Last
                                                                    updated: 5
                                                                    Jun ‘21 (12:34pm)<br></p> -->
                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-center justify-content-md-center justify-content-xxl-center"
                                                                    style="padding-top: 0.5rem;padding-bottom: 0.5rem;">
                                                                    <button class="btn btn-secondary invoicebtn"
                                                                        data-bs-target="#drawingsmodalclosed"
                                                                        data-bs-toggle="modal" type="button"
                                                                        style="font-size: 12px;background: var(--lightblue);border-style: none;width: 127.344px;height: 36px;">Drawings</button>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="selectedRFQ.dimensions.length > 0"
                                                                class="col shadow-sm rounded mb-2"
                                                                style="padding: 1rem;background: var(--shadowgrey);">
                                                                <p
                                                                    class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-sm-center align-items-xxl-center bluehead">
                                                                    <img src="assets/6543.png"
                                                                        style="width: 30px;margin: 10px;">View
                                                                    Dimensions
                                                                </p>
                                                                <!-- <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                    style="margin-bottom: 0;margin-left: 51px;">Last
                                                                    updated: 5
                                                                    Jun ‘21 (12:34pm)<br></p> -->
                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-center justify-content-md-center justify-content-xxl-center"
                                                                    style="padding-top: 0.5rem;padding-bottom: 0.5rem;">
                                                                    <button class="btn btn-secondary invoicebtn"
                                                                        data-bs-target="#dimensionsmodalclosed"
                                                                        data-bs-toggle="modal" type="button"
                                                                        style="font-size: 12px;background: var(--lightblue);border-style: none;width: 127.344px;height: 36px;">Dimensions</button>
                                                                </div>
                                                            </div>
                                                            <div class="col d-xxl-flex justify-content-xxl-center">
                                                                <div class="row g-0 rfqdetailrow" style="width: 315px;">

                                                                    <!-- HISTORY BLOCKS -->
                                                                    <div class="col shadow-sm rounded mb-2"
                                                                        style="padding: 1rem;background: var(--shadowgrey);">
                                                                        <p
                                                                            class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-sm-center align-items-xxl-center bluehead">
                                                                            <img src="assets/353535.png"
                                                                                style="width: 30px;margin: 10px;" />Checklist
                                                                        </p>
                                                                        <!-- QUOTE SUBMITTED -->
                                                                        <div>
                                                                            <div *ngIf=" selectedRFQ.quoteDetails.quoteId > 0"
                                                                                class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                style="width: 223.391px;margin-left: 50px;">
                                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                    style="width: 223.391px;"><i
                                                                                        class="fa fa-check-circle"
                                                                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                                        style="margin-bottom: 0;padding-left: 15px;">
                                                                                        Quote Submitted </p>
                                                                                </div>
                                                                            </div>

                                                                            <div *ngIf=" selectedRFQ.quoteDetails.quoteId == null"
                                                                                class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                style="width: 223.391px;margin-left: 50px;">
                                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                    style="width: 223.391px;"><i
                                                                                        class="fa fa-circle"
                                                                                        style="color: var(--textgrey);font-size: 20px;padding-left: 0;"></i>
                                                                                    <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                        style="margin-bottom: 0;padding-left: 15px;">
                                                                                        Quote Submitted</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <!-- QUOTE ACCEPTED -->
                                                                        <div>
                                                                            <div *ngIf=" selectedRFQ.quoteDetails.quoteAccepted == true"
                                                                                class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                style="width: 223.391px;margin-left: 50px;">
                                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                    style="width: 223.391px;"><i
                                                                                        class="fa fa-check-circle"
                                                                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                                        style="margin-bottom: 0;padding-left: 15px;">
                                                                                        Quote Accepted </p>
                                                                                </div>
                                                                            </div>

                                                                            <div *ngIf=" selectedRFQ.quoteDetails.quoteAccepted == false"
                                                                                class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                style="width: 223.391px;margin-left: 50px;">
                                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                    style="width: 223.391px;"><i
                                                                                        class="fa fa-circle"
                                                                                        style="color: var(--textgrey);font-size: 20px; padding-right: 15px; padding-left: 0;"></i>
                                                                                    <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                        style="margin-bottom: 0;padding-left: 0px;text-shadow: 0px 0px var(--lightblue);margin-left: 50p;border-bottom: 2px solid var(--maingreen) ;">
                                                                                        Quote Accepted</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <!-- SALES ORDER -->
                                                                        <div>
                                                                            <div *ngIf=" selectedRFQ.quoteDetails.salesQuoteFileId > 0"
                                                                                class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                style="width: 223.391px;margin-left: 50px;">
                                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                    style="width: 223.391px;"><i
                                                                                        class="fa fa-check-circle"
                                                                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                                        style="margin-bottom: 0;padding-left: 15px;">
                                                                                        Sales Order uploaded </p>
                                                                                </div>
                                                                            </div>

                                                                            <div *ngIf=" selectedRFQ.quoteDetails.salesQuoteFileId == null"
                                                                                class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                style="width: 223.391px;margin-left: 50px;">
                                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                    style="width: 223.391px;"><i
                                                                                        class="fa fa-circle"
                                                                                        style="color: var(--textgrey);font-size: 20px;padding-right:15px; padding-left: 0;"></i>
                                                                                    <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                        style="margin-bottom: 0;padding-left: 0px;text-shadow: 0px 0px var(--lightblue);margin-left: 50p;border-bottom: 2px solid var(--lightblue) ;
                                    ">
                                                                                        Upload Sales Order </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>



                                                                        <!-- DELIVERY MADE    -->
                                                                        <div
                                                                            *ngIf="selectedRFQ.deliveryRequirements == 'Delivery'">
                                                                            <div *ngIf="selectedRFQ.deliveryRequirements == 'Delivery' && selectedRFQ.quoteDetails.state == 'Closed'"
                                                                                class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                style="width: 223.391px;margin-left: 50px;">
                                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                    style="width: 223.391px;"><i
                                                                                        class="fa fa-check-circle"
                                                                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                                        style="margin-bottom: 0;padding-left: 15px;">
                                                                                        Delivery Marked as Complete</p>
                                                                                </div>
                                                                            </div>


                                                                            <div *ngIf="selectedRFQ.deliveryRequirements == 'Delivery' && selectedRFQ.quoteDetails.state != 'Closed'"
                                                                                class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                style="width: 223.391px;margin-left: 50px;">
                                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                    style="width: 223.391px;"><i
                                                                                        class="fa fa-circle"
                                                                                        style="color: var(--textgrey);font-size: 20px;padding-right:15px; padding-left: 0;"></i>
                                                                                    <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                        style="margin-bottom: 0;padding-left: 0px;text-shadow: 0px 0px var(--lightblue);margin-left: 50p;border-bottom: 2px solid var(--maingreen) ;">
                                                                                        Awaiting Delivey Confirmation
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <!-- COLLECTION MADE    -->
                                                                        <div
                                                                            *ngIf="selectedRFQ.deliveryRequirements == 'Collection'">
                                                                            <div *ngIf=" selectedRFQ.deliveryRequirements == 'Collection' && selectedRFQ.quoteDetails.buyerCollectionMade == true"
                                                                                class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                style="width: 223.391px;margin-left: 50px;">
                                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                    style="width: 223.391px;"><i
                                                                                        class="fa fa-check-circle"
                                                                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                                    <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                        style="margin-bottom: 0;padding-left: 15px;">
                                                                                        Collection Marked as Complete
                                                                                    </p>
                                                                                </div>
                                                                            </div>

                                                                            <div *ngIf=" selectedRFQ.deliveryRequirements == 'Collection' && selectedRFQ.quoteDetails.buyerCollectionMade == false"
                                                                                class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                style="width: 223.391px;margin-left: 50px;">
                                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                    style="width: 223.391px;"><i
                                                                                        class="fa fa-circle"
                                                                                        style="color: var(--textgrey);font-size: 20px;padding-right: 15px; padding-left: 0;"></i>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                                        style="margin-bottom: 0;padding-left: 0px;text-shadow: 0px 0px var(--lightblue);margin-left: 50p;border-bottom: 2px solid var(--lightblue) ;">
                                                                                        Buyer
                                                                                        to Collect</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <!-- PURCHASE ORDER -->
                                                                        <div>
                                                                            <div *ngIf=" selectedRFQ.purchaseOrderFileId > 0"
                                                                                class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                style="width: 223.391px;margin-left: 50px;">
                                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                    style="width: 223.391px;"><i
                                                                                        class="fa fa-check-circle"
                                                                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                                        style="margin-bottom: 0;padding-left: 15px;">
                                                                                        Received Purchase Order </p>
                                                                                </div>
                                                                            </div>

                                                                            <div>
                                                                                <div *ngIf=" selectedRFQ.purchaseOrderFileId != null && !selectedRFQ.purchaseOrderDownloaded"
                                                                                    class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                    style="width: 223.391px;margin-left: 50px;">
                                                                                    <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                        style="width: 223.391px;"><i
                                                                                            class="fa fa-circle"
                                                                                            style="color: var(--textgrey);font-size: 20px;padding-right: 15px; padding-left: 0;"></i>
                                                                                        <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                            style="margin-bottom: 0;padding-left: 0px;text-shadow: 0px 0px var(--lightblue);margin-left: 50p;border-bottom: 2px solid var(--lightblue) ;">
                                                                                            Download Purchase Order </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div *ngIf=" selectedRFQ.purchaseOrderFileId == null"
                                                                                class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                style="width: 223.391px;margin-left: 50px;">
                                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                    style="width: 223.391px;"><i
                                                                                        class="fa fa-circle"
                                                                                        style="color: var(--textgrey);font-size: 20px;padding-right: 15px; padding-left: 0;"></i>
                                                                                    <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                        style="margin-bottom: 0;padding-left: 0px;text-shadow: 0px 0px var(--lightblue);margin-left: 50p;border-bottom: 2px solid var(--maingreen) ;">
                                                                                        Awaiting Purchase Order </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <!-- FINAL INVOICE  -->
                                                                        <div>
                                                                            <div *ngIf=" selectedRFQ.quoteDetails.invoiceQuoteFileId > 0"
                                                                                class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                style="width: 223.391px;margin-left: 50px;">
                                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                    style="width: 223.391px;"><i
                                                                                        class="fa fa-check-circle"
                                                                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                                        style="margin-bottom: 0;padding-left: 15px;">
                                                                                        Final Invoice uploaded </p>
                                                                                </div>
                                                                            </div>

                                                                            <div *ngIf=" selectedRFQ.quoteDetails.invoiceQuoteFileId == null"
                                                                                class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                style="width: 223.391px;margin-left: 50px;">
                                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                    style="width: 223.391px;"><i
                                                                                        class="fa fa-circle"
                                                                                        style="color: var(--textgrey);font-size: 20px;padding-right: 15px; padding-left: 0;"></i>
                                                                                    <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                        style="margin-bottom: 0;padding-left: 0px;text-shadow: 0px 0px var(--lightblue);margin-left: 50p;border-bottom: 2px solid var(--lightblue);">
                                                                                        Upload Final Invoice </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>



                                                                        <!-- AWAITING PAYMENT -->
                                                                        <ng-container
                                                                            *ngIf="selectedRFQ.quoteDetails.paymentReference == null ">

                                                                            <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                style="width: 223.391px;margin-left: 50px;">
                                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                    style="width: 223.391px;"><i
                                                                                        class="fa fa-circle"
                                                                                        style="color: var(--textgrey);font-size: 20px;padding-right:15px; padding-left: 0;"></i>
                                                                                    <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                        style="margin-bottom: 0;padding-left: 0px;text-shadow: 0px 0px var(--lightblue);margin-left: 50p;border-bottom: 2px solid var(--maingreen) ;">
                                                                                        Awaiting Payment </p>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <!-- REFERENCE RECEIVED -->
                                                                        <ng-container
                                                                            *ngIf="selectedRFQ.quoteDetails.paymentReference != null ">

                                                                            <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                style="width: 223.391px;margin-left: 50px;">

                                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                    style="width: 223.391px;"><i
                                                                                        class="fa fa-check-circle"
                                                                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                                        style="margin-bottom: 0;padding-left: 15px;">
                                                                                        Payment Received </p>
                                                                                </div>

                                                                            </div>
                                                                        </ng-container>


                                                                        <!-- MARK PAYMENT AS RECEIVED -->
                                                                        <ng-container
                                                                            *ngIf="selectedRFQ.quoteDetails.paymentReceived == false ">

                                                                            <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                style="width: 223.391px;margin-left: 50px;">
                                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                    style="width: 223.391px;"><i
                                                                                        class="fa fa-circle"
                                                                                        style="color: var(--textgrey);font-size: 20px;padding-right: 15px; padding-left: 0;"></i>
                                                                                    <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                        style="margin-bottom: 0;padding-left: 0px;text-shadow: 0px 0px var(--lightblue);margin-left: 50p;border-bottom: 2px solid var(--lightblue) ;
">
                                                                                        Mark Payment as Received</p>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <!-- REFERENCE RECEIVED -->
                                                                        <ng-container
                                                                            *ngIf="selectedRFQ.quoteDetails.paymentReceived == true ">

                                                                            <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                style="width: 223.391px;margin-left: 50px;">

                                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                    style="width: 223.391px;"><i
                                                                                        class="fa fa-check-circle"
                                                                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                                        style="margin-bottom: 0;padding-left: 15px;">
                                                                                        Payment Marked as Received </p>
                                                                                </div>

                                                                            </div>
                                                                        </ng-container>

                                                                        <!-- TEST CERTIFICATE     -->
                                                                        <!-- IF TEST CERTIFICATE REQUIRED -->
                                                                        <div
                                                                            *ngIf=" selectedRFQ.productCertificationRequirements">

                                                                            <!-- FILE ID IS NULL -->

                                                                            <div *ngIf=" selectedRFQ.quoteDetails.productCertificationQuoteFileId == null"
                                                                                class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                style="width: 223.391px;margin-left: 50px;">
                                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                    style="width: 223.391px;"><i
                                                                                        class="fa fa-circle"
                                                                                        style="color: var(--textgrey);font-size: 20px;padding-right:15px; padding-left: 0;"></i>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                                        style="margin-bottom: 0;padding-left: 0px;text-shadow: 0px 0px var(--lightblue);margin-left: 50p;border-bottom: 2px solid var(--lightblue) ;">
                                                                                        Upload Test Certification</p>
                                                                                </div>
                                                                            </div>
                                                                            <!-- FILE ID NOT NULL -->
                                                                            <div *ngIf=" selectedRFQ.quoteDetails.productCertificationQuoteFileId != null"
                                                                                class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                style="width: 223.391px;margin-left: 50px;">
                                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                    style="width: 223.391px;"><i
                                                                                        class="fa fa-check-circle"
                                                                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                                        style="margin-bottom: 0;padding-left: 15px;">
                                                                                        Test Certification Uploaded </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <!-- REFERENCE RECEIVED -->
                                                                        <ng-container
                                                                            *ngIf="selectedRFQ.quoteDetails.archived == true ">

                                                                            <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                style="width: 223.391px;margin-left: 50px;">

                                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                    style="width: 223.391px;"><i
                                                                                        class="fa fa-check-circle"
                                                                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                                        style="margin-bottom: 0;padding-left: 15px;">
                                                                                        RFQ Archived </p>
                                                                                </div>

                                                                            </div>
                                                                        </ng-container>







                                                                        <!-- ARCHIVED  -->
                                                                        <!-- <div>
                                                                            <div *ngIf=" selectedRFQ.quoteDetails.archived == true"
                                                                                class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                style="width: 223.391px;margin-left: 50px;">
                                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                    style="width: 223.391px;"><i
                                                                                        class="fa fa-check-circle"
                                                                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                                        style="margin-bottom: 0;padding-left: 15px;">
                                                                                        Archived </p>
                                                                                </div>
                                                                            </div>

                                                                            <div *ngIf=" selectedRFQ.quoteDetails.archived == false"
                                                                                class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                                style="width: 223.391px;margin-left: 50px;">
                                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                    style="width: 223.391px;"><i
                                                                                        class="fa fa-circle"
                                                                                        style="color: var(--textgrey);font-size: 20px;padding-right: 15px; padding-left: 0;"></i>
                                                                                    <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                        style="margin-bottom: 0;padding-left: 0px;text-shadow: 0px 0px var(--lightblue);margin-left: 50p;border-bottom: 2px solid var(--lightblue) ;
">
                                                                                        Mark as Archived</p>
                                                                                </div>
                                                                            </div>
                                                                        </div> -->





                                                                    </div>

                                                                    <div class="col shadow-sm rounded mb-2"
                                                                        style="padding: 1rem;background: var(--shadowgrey);">
                                                                        <p
                                                                            class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-sm-center align-items-xxl-center bluehead">
                                                                            <img src="assets/Group%2028.png"
                                                                                style="width: 30px;margin: 10px;">View
                                                                            Quote
                                                                            Review

                                                                        </p>
                                                                        <!-- <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                                                                                                                                                                                        style="margin-bottom: 0;margin-left: 51px;">Last
                                                                                                                                                                                                                                        updated: 5
                                                                                                                                                                                                                                        Jun ‘21 (12:34pm)<br></p> -->
                                                                        <div class="d-flex d-md-flex d-xxl-flex justify-content-center justify-content-md-center justify-content-xxl-center"
                                                                            style="padding-top: 0.5rem;padding-bottom: 0.5rem;">
                                                                            <button (click)="getQuoteandRFQreviews()"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#quotechecklistfinal"
                                                                                class="btn btn-secondary invoicebtn"
                                                                                type="button"
                                                                                style="font-size: 12px;background: var(--lightblue);border-style: none;width: 127.344px;height: 36px;">
                                                                                View

                                                                                Review
                                                                                <!-- SPINNER IF THE CALL IS MADE FOR THE RFQ REVIEW OR THE QUOTE REVIEW DATA -->
                                                                                <span
                                                                                    *ngIf="loadingQuoteReview || loadingRfqReview"
                                                                                    class="spinner-border spinner-border-sm mr-1"></span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div class="col shadow-sm rounded mb-2"
                                                                        style="padding: 1rem;background: var(--shadowgrey);">
                                                                        <p
                                                                            class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-sm-center align-items-xxl-center bluehead">
                                                                            <img src="assets/353535.png"
                                                                                style="width: 30px;margin: 10px;">History
                                                                        </p>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                            style="margin-bottom: 0;margin-left: 51px;">
                                                                            Last
                                                                            updated: 5 Jun ‘21 (12:34pm)<br></p>
                                                                        <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                            style="width: 223.391px;margin-left: 50px;">
                                                                            <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                style="width: 223.391px;"><i
                                                                                    class="fa fa-check-circle"
                                                                                    style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                                <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                                    style="margin-bottom: 0;padding-left: 15px;">
                                                                                    Submitted</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                            style="width: 223.391px;margin-left: 50px;">
                                                                            <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                style="width: 223.391px;"><i
                                                                                    class="fa fa-check-circle"
                                                                                    style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                                <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                                    style="margin-bottom: 0;padding-left: 15px;">
                                                                                    Accepted</p>
                                                                                <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                                    style="margin-bottom: 0;padding-left: 15px;">
                                                                                    (0/3)</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                            style="width: 223.391px;margin-left: 50px;">
                                                                            <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                                style="width: 223.391px;"><i
                                                                                    class="fa fa-circle"
                                                                                    style="border-color: var(--maingreen);color: var(--textgrey);font-size: 20px;padding-left: 0;"></i>
                                                                                <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                                    style="margin-bottom: 0;padding-left: 15px;">
                                                                                    Awaiting Confirmation</p>
                                                                            </div>
                                                                        </div>
                                                                    </div> -->
                                                                    <!-- <div class="col shadow-sm rounded mb-2"
                                                                        style="padding: 1rem;background: var(--shadowgrey);">
                                                                        <p
                                                                            class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-sm-center align-items-xxl-center bluehead">
                                                                            <img src="assets/353535.png"
                                                                                style="width: 30px;margin: 10px;">Export
                                                                            RFQ
                                                                        </p>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                            style="margin-bottom: 0;margin-left: 51px;">
                                                                            Download
                                                                            all data related to RFQ</p>
                                                                        <div class="d-flex d-md-flex d-xxl-flex justify-content-center justify-content-md-center justify-content-xxl-center"
                                                                            style="padding-top: 0.5rem;padding-bottom: 0.5rem;">
                                                                            <button class="btn btn-secondary invoicebtn"
                                                                                type="button"
                                                                                style="font-size: 12px;background: var(--lightblue);border-style: none;width: 127.344px;height: 36px;">Export</button>
                                                                        </div>
                                                                    </div> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="loadingRfq" class="d-flex flex-column" id="content-wrapper">
                                    <div class="d-flex justify-content-center"
                                        style="min-height:100vh; margin-top:1rem; margin-right:1rem;  background-color: var(--shadowgrey);"
                                        id="content">

                                        <div class="text-center text-center bluehead" style="padding-top:15rem;"><span
                                                class="spinner-border spinner-border-sm mr-1"></span>
                                            Loading...
                                        </div>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <!-- MODALS -->

        <!-- DIMENSIONS MODAL -->

        <div role="dialog" tabindex="-1" class="modal fade" id="dimensionsmodalclosed">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header" style="background: var(--lightblue);">
                        <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">
                            Dimensions</h1><button type="button" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="table-responsive" style="padding: 0.5rem;">
                            <table class="table table-sm my-0 mydatatable">
                                <thead class="buyerimage">
                                    <tr class="text-start">
                                        <th class="blueheadsmall" style="text-align: center;">PART NUMBER</th>
                                        <th class="blueheadsmall" style="text-align: center;">TYPE</th>
                                        <th class="blueheadsmall" style="text-align: center;">MATERIAL GRADE
                                        </th>
                                        <th class="blueheadsmall" style="text-align: center;">DIMENSIONS</th>
                                        <th class="blueheadsmall" style="text-align: center;">QUANTITY</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="tablerow" *ngFor="let dimensions of selectedRFQ.dimensions">
                                        <td class="text-center bluetextthin">
                                            {{dimensions.partNo}}</td>
                                        <td class="text-center bluetextthin">{{dimensions.type}}</td>
                                        <td class="text-center bluetextthin">{{dimensions.materialGrade}}</td>
                                        <td class="text-center bluetextthin">{{dimensions.dimensions}}</td>
                                        <td class="text-center bluetextthin">{{dimensions.quantity}}</td>


                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer"><a class="btn text-gray-500" role="button"
                            data-bs-dismiss="modal">Close</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- DRAWINGS MODAL -->

        <div role="dialog" class="modal fade " id="drawingsmodalclosed">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header" style="background: var(--lightblue);">
                        <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">
                            Drawings
                        </h1><button type="button" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div
                                style="color: var(--shadowgrey);background: var(--shadowgrey);border-radius: 14px;padding: 1rem;padding-bottom: 0;margin: 1rem;margin-left: 0;">
                                <div class="row d-flex d-xxl-flex justify-content-center justify-content-xxl-center">
                                    <div class="col d-flex d-xxl-flex justify-content-start align-items-xxl-center">
                                        <p class="text-nowrap d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                            style="color: #0e0e82;">All Changes to Drawings Comments</p>
                                    </div>
                                </div>
                                <div class="row rfqdetailrow">
                                    <div class="col">
                                        <p class="d-sm-flex justify-content-sm-start bluetextthin">
                                            {{selectedRFQ.drawingsChangesComment}}
                                        </p>
                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;">
                                        </p>
                                    </div>
                                </div>
                                <!-- <div class="row rfqdetailrow" style="padding: 0px;">
                                    <div class="col"><a href="/">
                                            <p class="d-flex d-sm-flex justify-content-end justify-content-sm-end justify-content-xxl-end"
                                                style="font-size: 12px;color: var(--darkblue);">... Read More
                                            </p>
                                        </a>
                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;">
                                        </p>
                                    </div>
                                </div> -->
                            </div>
                            <div
                                class="d-flex d-sm-flex d-md-flex d-xxl-flex align-items-center justify-content-sm-start align-items-sm-center justify-content-md-start justify-content-xxl-start align-items-xxl-center searchdiv">
                                <div class="input-group tablesearch"><input type="text"
                                        class="bg-light form-control border-0 small"
                                        placeholder="Search for ..." /><button class="btn btn-primary py-0"
                                        type="button"
                                        style="background: var(--lightblue);border-color: var(--lightblue);"><i
                                            class="fas fa-search"></i></button></div>
                                <div class="d-md-flex align-items-md-center" id="modal-open">
                                    <div role="dialog" tabindex="-1" class="modal fade" id="rfqfilter">
                                        <div class="modal-dialog modal-dialog-centered" role="document">
                                            <div class="modal-content">
                                                <div class="modal-body" style="padding: 10px;">
                                                    <div>
                                                        <div style="padding-bottom: 3px;"><a href="/">
                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                    style="margin-bottom: 0;font-size: 8px;">
                                                                </p>
                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                    style="margin-bottom: 0;font-size: 8px;">
                                                                    Material
                                                                </p>
                                                            </a>
                                                            <div
                                                                class="d-lg-flex justify-content-lg-start align-items-lg-center">
                                                                <div class="form-check form-check-inline"><input
                                                                        type="checkbox" class="form-check-input"
                                                                        id="formCheck-8" /><label
                                                                        class="form-check-label radio bluetext thin"
                                                                        for="formCheck-1">Steel</label></div>
                                                                <div class="form-check form-check-inline"><input
                                                                        type="checkbox" class="form-check-input"
                                                                        id="formCheck-9" /><label
                                                                        class="form-check-label radio bluetext thin"
                                                                        for="formCheck-1">Aluminium</label>
                                                                </div>
                                                                <div class="form-check form-check-inline"><input
                                                                        type="checkbox" class="form-check-input"
                                                                        id="formCheck-10" /><label
                                                                        class="form-check-label radio bluetext thin"
                                                                        for="formCheck-1">Other</label></div>
                                                                <div class="form-check form-check-inline"><input
                                                                        type="checkbox" class="form-check-input"
                                                                        id="formCheck-11" /><label
                                                                        class="form-check-label radio bluetext thin"
                                                                        for="formCheck-1">Other</label></div>
                                                            </div>
                                                        </div>
                                                        <div style="padding-bottom: 3px;"><a href="/">
                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                    style="margin-bottom: 0;font-size: 8px;">
                                                                </p>
                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start bluetext"
                                                                    style="margin-bottom: 0;font-size: 8px;"> Test
                                                                    Certification
                                                                    Required</p>
                                                            </a>
                                                            <div class="form-check form-check-inline"><input
                                                                    type="checkbox" class="form-check-input"
                                                                    id="formCheck-12" /><label
                                                                    class="form-check-label radio bluetext thin"
                                                                    for="formCheck-1">Yes        </label></div>
                                                            <div class="form-check form-check-inline"><input
                                                                    type="checkbox" class="form-check-input"
                                                                    id="formCheck-13" /><label
                                                                    class="form-check-label radio bluetext thin"
                                                                    for="formCheck-1">No</label></div>
                                                        </div>
                                                        <div style="padding-bottom: 3px;"><a href="/">
                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                    style="margin-bottom: 0;font-size: 8px;">
                                                                </p>
                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start bluetext"
                                                                    style="margin-bottom: 0;font-size: 8px;">
                                                                    Packaging
                                                                    Required
                                                                </p>
                                                            </a>
                                                            <div class="form-check form-check-inline"><input
                                                                    type="checkbox" class="form-check-input"
                                                                    id="formCheck-14" /><label
                                                                    class="form-check-label radio bluetext thin"
                                                                    for="formCheck-1">Yes        </label></div>
                                                            <div class="form-check form-check-inline"><input
                                                                    type="checkbox" class="form-check-input"
                                                                    id="formCheck-15" /><label
                                                                    class="form-check-label radio bluetext thin"
                                                                    for="formCheck-1">No</label></div>
                                                        </div>
                                                        <div style="padding-bottom: 3px;"><a href="/">
                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                    style="margin-bottom: 0;font-size: 8px;">
                                                                </p>
                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start bluetext"
                                                                    style="margin-bottom: 0;font-size: 8px;">
                                                                    Complete
                                                                    Supply</p>
                                                            </a>
                                                            <div class="form-check form-check-inline"><input
                                                                    type="checkbox" class="form-check-input"
                                                                    id="formCheck-16" /><label
                                                                    class="form-check-label radio bluetext thin"
                                                                    for="formCheck-1">Yes       </label></div>
                                                            <div class="form-check form-check-inline"><input
                                                                    type="checkbox" class="form-check-input"
                                                                    id="formCheck-17" /><label
                                                                    class="form-check-label radio bluetext thin"
                                                                    for="formCheck-1">No</label></div>
                                                        </div>
                                                        <div style="padding-bottom: 3px;"><a href="/">
                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                    style="margin-bottom: 0;font-size: 8px;">
                                                                </p>
                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start bluetext"
                                                                    style="margin-bottom: 0;font-size: 8px;">
                                                                    Product
                                                                    Identification</p>
                                                            </a>
                                                            <div class="form-check form-check-inline"><input
                                                                    type="checkbox" class="form-check-input"
                                                                    id="formCheck-18" /><label
                                                                    class="form-check-label radio bluetext thin"
                                                                    for="formCheck-1">Yes       </label></div>
                                                            <div class="form-check form-check-inline"><input
                                                                    type="checkbox" class="form-check-input"
                                                                    id="formCheck-19" /><label
                                                                    class="form-check-label radio bluetext thin"
                                                                    for="formCheck-1">No</label></div>
                                                        </div>
                                                        <div
                                                            class="d-lg-flex d-xxl-flex justify-content-lg-end justify-content-xxl-end applybtndiv">
                                                            <button class="btn btn-secondary applybtn"
                                                                type="button">Apply</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xxl-12" style="margin-top: 10px;">
                                    <section>
                                        <div class="table-responsive">
                                            <table class="table table-sm my-0 mydatatable">
                                                <thead class="buyerimage">
                                                    <tr class="text-start">
                                                        <th class="blueheadsmall" style="text-align: center;">
                                                            FILE NAME
                                                        </th>
                                                        <th class="blueheadsmall" style="text-align: center;">
                                                            DATE UPLOADED</th>
                                                        <!-- <th class="blueheadsmall" style="text-align: center;">
                                                            FILE
                                                            NAME
                                                        </th>
                                                        <th class="blueheadsmall" style="text-align: center;">
                                                            EDIT
                                                        </th> -->
                                                        <th class="blueheadsmall" style="text-align: center;">
                                                            DOWNLOAD
                                                        </th>
                                                        <th class="blueheadsmall"></th>
                                                        <th class="blueheadsmall"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container *ngFor="let file of selectedRFQ.rfqFiles">
                                                        <ng-container *ngIf="file.fileType == 'Drawings'">
                                                            <tr class="tablerow">
                                                                <td class="text-center bluetextthin">
                                                                    {{file.fileName}}
                                                                </td>
                                                                <td class="text-center bluetextthin">{{file.createdAt
                                                                    |
                                                                    date:'mediumDate'}}
                                                                </td>
                                                                <!-- <td class="text-center bluetextthin">Drawing1.pdf
                                                            </td>
                                                            <td class="text-center"><a
                                                                    class="btn btn-secondary invoicebtn" role="button"
                                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                        class="icon ion-edit"
                                                                        style="font-size: 14px;color: var(--lightblue);"></i></a>
                                                            </td> -->
                                                                <td class="text-nowrap" style="text-align: center;">
                                                                    <a class="btn btn-secondary invoicebtn"
                                                                        role="button"
                                                                        style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                            class="fa fa-external-link"
                                                                            (click)="downloadDrawingFile(file.rfqFileId)"
                                                                            style="font-size: 14px;color: var(--lightblue);"></i></a>
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                    </ng-container>

                                                    <tr id="tbc2" class="expandedrow collapse"
                                                        style="background: rgba(132, 132, 132, 0.16);">
                                                        <td class="blueheadsmall" colspan="9" style="width: 886px;">
                                                            <div class="d-md-flex d-xl-flex align-items-xxl-center">
                                                                <div class="col-5 col-xxl-4">
                                                                    <div>
                                                                        <div class="row">
                                                                            <div class="col">
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="font-weight: normal;">
                                                                                    Company:</p>
                                                                            </div>
                                                                            <div class="col-sm-6">
                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                    style="margin-bottom: 0;">
                                                                                </p>
                                                                                <p class="uiinfoblack"
                                                                                    style="font-weight: normal;">
                                                                                    Magic
                                                                                    Metal</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col">
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="font-weight: normal;">
                                                                                    Verified:</p>
                                                                            </div>
                                                                            <div class="col">
                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                    style="margin-bottom: 0;">
                                                                                </p>
                                                                                <p class="uiinfoblack"
                                                                                    style="font-weight: normal;">
                                                                                    Yes
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col">
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="font-weight: normal;">
                                                                                    Past
                                                                                    Jobs:</p>
                                                                            </div>
                                                                            <div class="col">
                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                    style="margin-bottom: 0;">
                                                                                </p>
                                                                                <p class="uiinfoblack"
                                                                                    style="font-weight: normal;">
                                                                                    3
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col">
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="font-weight: normal;">
                                                                                    Rating:
                                                                                </p>
                                                                            </div>
                                                                            <div class="col">
                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                    style="margin-bottom: 0;">
                                                                                </p>
                                                                                <p class="uiinfo"
                                                                                    style="font-weight: normal;">
                                                                                    <i class="fa fa-star"
                                                                                        style="color: var(--yellow);"></i><i
                                                                                        class="fa fa-star"
                                                                                        style="color: var(--yellow);"></i><i
                                                                                        class="fa fa-star"
                                                                                        style="color: var(--yellow);"></i><i
                                                                                        class="fa fa-star-o"
                                                                                        style="color: var(--yellow);"></i>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col">
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="font-weight: normal;">
                                                                                    Location:</p>
                                                                            </div>
                                                                            <div class="col">
                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                    style="margin-bottom: 0;">
                                                                                </p>
                                                                                <p class="uiinfoblack"
                                                                                    style="font-weight: normal;">
                                                                                    Boksburg,
                                                                                    Gauteng</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="row">
                                                                            <div class="col">
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="font-weight: normal;">
                                                                                    Delivery
                                                                                    available:</p>
                                                                            </div>
                                                                            <div class="col">
                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                    style="margin-bottom: 0;">
                                                                                </p>
                                                                                <p class="uiinfoblack"
                                                                                    style="font-weight: normal;">
                                                                                    Yes
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col">
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="font-weight: normal;">
                                                                                    Vendor
                                                                                    numbers:
                                                                                </p>
                                                                            </div>
                                                                            <div class="col">
                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                    style="margin-bottom: 0;">
                                                                                </p>
                                                                                <p class="uiinfoblack"
                                                                                    style="font-weight: normal;">
                                                                                    A1B2C3D4</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col">
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="font-weight: normal;">
                                                                                    ISO:
                                                                                </p>
                                                                            </div>
                                                                            <div class="col">
                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                    style="margin-bottom: 0;">
                                                                                </p>
                                                                                <p class="uiinfoblack"
                                                                                    style="font-weight: normal;">
                                                                                    Yes
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col">
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="font-weight: normal;">
                                                                                    B-BBEE
                                                                                    level:</p>
                                                                            </div>
                                                                            <div class="col">
                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                    style="margin-bottom: 0;">
                                                                                </p>
                                                                                <p class="uiinfoblack"
                                                                                    style="font-weight: normal;">
                                                                                    5
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col">
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="font-weight: normal;">
                                                                                    Purchase Order
                                                                                </p>
                                                                            </div>
                                                                            <div class="col">
                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                    style="margin-bottom: 0;">
                                                                                </p>
                                                                                <p class="uiinfoblack"
                                                                                    style="font-weight: normal;">
                                                                                    <a href="/"><i
                                                                                            class="fa fa-upload"></i> Upload</a>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="d-md-flex d-xl-flex d-xxl-flex align-items-md-center align-items-xl-center align-items-xxl-center"
                                                                    style="width: 100%;">
                                                                    <div class="col-10 col-md-5 col-xxl-3 offset-xxl-2">
                                                                        <div class="row row-cols-2 d-md-flex align-items-md-center"
                                                                            style="padding: 5px;">
                                                                            <div
                                                                                class="col-1 col-sm-3 col-md-3 col-lg-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="font-weight: normal;">
                                                                                </p>

                                                                            </div>
                                                                            <div
                                                                                class="col-3 col-md-7 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                    style="margin-bottom: 0;">
                                                                                </p>
                                                                                <p class="bluetext"
                                                                                    style="font-weight: normal;">
                                                                                    Verified</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row row-cols-2 d-md-flex align-items-md-center"
                                                                            style="padding: 5px;">
                                                                            <div
                                                                                class="col-1 col-sm-3 col-md-3 col-lg-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="font-weight: normal;">

                                                                                </p>
                                                                            </div>
                                                                            <div
                                                                                class="col-3 col-md-7 col-xxl-8 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                    style="margin-bottom: 0;">
                                                                                </p>
                                                                                <p class="bluetext"
                                                                                    style="font-weight: normal;">
                                                                                    Gold
                                                                                    Member</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row row-cols-2 d-md-flex align-items-md-center"
                                                                            style="padding: 5px;">
                                                                            <div
                                                                                class="col-1 col-sm-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="font-weight: normal;">
                                                                                </p>

                                                                            </div>
                                                                            <div
                                                                                class="col-3 col-md-7 col-xxl-9 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                    style="margin-bottom: 0;">
                                                                                </p>
                                                                                <p class="bluetext"
                                                                                    style="font-weight: normal;">
                                                                                    Credit
                                                                                    Approved
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row row-cols-2 d-md-flex justify-content-xxl-start align-items-xxl-center"
                                                                            style="padding: 5px;">
                                                                            <div
                                                                                class="col-1 col-sm-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="font-weight: normal;">
                                                                                </p>

                                                                            </div>
                                                                            <div
                                                                                class="col-3 col-md-7 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                    style="margin-bottom: 0;">
                                                                                </p>
                                                                                <p class="bluetext"
                                                                                    style="font-weight: normal;">
                                                                                    Manufacturer
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row row-cols-2 d-md-flex justify-content-xxl-start align-items-xxl-center"
                                                                            style="padding: 5px;">
                                                                            <div
                                                                                class="col-1 col-sm-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="font-weight: normal;">
                                                                                </p>

                                                                            </div>
                                                                            <div
                                                                                class="col-3 col-md-7 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                    style="margin-bottom: 0;">
                                                                                </p>
                                                                                <p class="bluetext"
                                                                                    style="font-weight: normal;">
                                                                                    Packaged</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row row-cols-2 d-md-flex justify-content-xxl-start align-items-xxl-center"
                                                                            style="padding: 5px;">
                                                                            <div
                                                                                class="col-1 col-sm-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="font-weight: normal;">
                                                                                </p>

                                                                            </div>
                                                                            <div
                                                                                class="col-3 col-md-7 col-xxl-9 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                    style="margin-bottom: 0;">
                                                                                </p>
                                                                                <p class="bluetext"
                                                                                    style="font-weight: normal;">
                                                                                    Vendor
                                                                                    Number
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer"><a class="btn text-gray-500" role="button"
                            data-bs-dismiss="modal">Close</a>
                    </div>
                </div>
            </div>
        </div>


        <!-- MODAL SUCCESS -->
        <div role="dialog" tabindex="-1" class="modal fade" id="movedarchive">
            <div class=" modal-dialog modal-dialog-centered" role="document" style="width:1000px !important;">
                <div class="modal-content">
                    <div class="modal-header" style="background: var(--lightblue);">
                        <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">
                            Quote Completed</h1><button (click)="getCompletedQuotes(selectedRFQ.quoteDetails.quoteId)"
                            type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body d-flex flex-column align-items-xxl-center"
                        style="padding-top: 20px; padding-bottom: 20px;">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="d-flex d-xl-flex justify-content-center justify-content-xl-center align-items-xl-center"
                                        style="margin-bottom: 5px;"><i
                                            class="fa fa-check-circle d-lg-flex align-items-lg-center"
                                            style="font-size: 18px;color: var(--lightblue);margin-right: 5px;"></i>
                                        <p class="text-nowrap d-lg-flex justify-content-xxl-center align-items-xxl-center bluetextthin"
                                            style="margin-bottom: 0px;">Quote For</p>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div
                                        class="d-flex d-xl-flex justify-content-center justify-content-xl-center align-items-xl-center">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <p class="d-flex d-sm-flex d-xxl-flex justify-content-center justify-content-sm-center justify-content-xxl-center blueheadsmall"
                                        style="margin-bottom: 5px;">{{selectedRFQ.rfqName}}<br /></p>
                                </div>
                                <div class="col-md-12">
                                    <p class="d-flex justify-content-center text-nowrap d-lg-flex justify-content-xxl-center align-items-xxl-center bluetextthin"
                                        style="margin-bottom: 5px;">with Payment Reference</p>
                                </div>
                                <div class="col-md-12">
                                    <p class="d-flex d-sm-flex d-xxl-flex justify-content-center justify-content-sm-center justify-content-xxl-center blueheadsmall"
                                        style="margin-bottom: 5px;">{{selectedQuote.paymentReference}}<br /></p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div *ngIf="(selectedRFQ.productCertificationRequirements && selectedRFQ.quoteDetails.productCertificationQuoteFileId != null) || !selectedRFQ.productCertificationRequirements"
                                class="col d-flex justify-content-center">
                                <p class="d-flex justify-content-center -lg-flex justify-content-xxl-center align-items-xxl-center bluetextthin"
                                    style="margin-bottom: 5px;">Has been moved to Archived </p>
                            </div>
                            <div *ngIf="selectedRFQ.productCertificationRequirements && selectedRFQ.quoteDetails.productCertificationQuoteFileId == null"
                                class="col d-flex justify-content-center">
                                <p class="d-flex justify-content-center -lg-flex justify-content-xxl-center align-items-xxl-center bluetextthin"
                                    style="margin-bottom: 5px;">Will remain in 'Awaiting Payment' until Test
                                    Certification has been uploaded </p>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer d-lg-flex align-items-lg-center"><a class="btn text-gray-500" role="button"
                            data-bs-dismiss="modal"
                            (click)="getCompletedQuotes(selectedRFQ.quoteDetails.quoteId)">Close</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- This is where the Q&A modal starts -->
        <section>
            <div class="modal fade" role="dialog" tabindex="-1" [attr.id]="'bwe' + selectedRFQ.rfqId"
                aria-labelledby="exampleModalLabel">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content ">
                        <div class="modal-body  " style="padding: 0px; border: 0px; ">
                            <div *ngIf="selectedRFQ">
                                <app-questions-and-answers [isBuyer]="isBuyer" [isSupplier]="isSupplier" [getQA]="getQA"
                                    (publicChatToggle)=" togglePubChat($event)"
                                    (periodicUpdateCond)="togglePeriodicReload($event)" [modalheader]="modalheader"
                                    [supplierId]="supplierId" [rfqId]="selectedRFQ.rfqId" [rfqStatus]="rfqStatusPC">
                                </app-questions-and-answers>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- QUOTE CHECKLIST FINAL -->

        <section>

            <div role="dialog" tabindex="-1" class="modal fade" data-bs-backdrop="static" id="quotechecklistfinal">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header" style="background: var(--lightblue);">
                            <h1 class="d-xl-flex align-items-xl-center whitehead"
                                style="font-size: 16px;margin-bottom: 0;">
                                Quote
                                Checklist</h1><button (click)="togglePeriodicReload(false)" type="button"
                                class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <!-- ONLY IF RFQ REVIEW AND QUOTE REVIEW DATA HAS BEEN FETCHED -->
                        <div *ngIf="this.quoteReview && this.rfqReview" class="modal-body">
                            <div>
                                <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                    <div class="col" style="padding-left: 0;">
                                        <p class="d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-center justify-content-md-center justify-content-lg-center blueheadsmall"
                                            style="margin-bottom: 0;padding-left: 32px;">Review
                                            Quote
                                            for {{selectedRFQ.rfqName}}
                                        </p>
                                    </div>
                                </div>

                                <div class="row rfqdetailrow" style="padding-bottom: 5px;padding-top: 5px;">
                                    <div class="col">
                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                            style="margin-left:26px; margin-bottom: 0;padding-left: 0px;">Correct
                                            material grade?

                                        </p>


                                    </div>
                                    <!-- BUYER AND SUPPLIER ANSWERS -->
                                    <div>
                                        <!-- Buyer answer start 1 -->
                                        <ng-container *ngIf="this.quoteReview ">
                                            <div *ngIf="quoteReview.correctMaterialGrade">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                    style="margin-bottom: 10px;padding-left: 26px;">Buyer answered:
                                                    <span class="bluetext">Yes</span>
                                                </p>
                                            </div>

                                            <div *ngIf="!quoteReview.correctMaterialGrade">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                    style="margin-bottom: 0;padding-left: 26px;padding-bottom: 5px;">
                                                    Buyer answered:
                                                    <span class="bluetext" style="margin-left: 5px;">No &nbsp; </span>
                                                    <!-- <a  class="badge rounded-pill bluetextthin" href="#" style="margin-bottom: 0px;margin-left: 5px;background: var(--darkblue);color: var(--shadowgrey);font-size: 10px;line-height: 11px;" data-bs-toggle="collapse" data-bs-target="#suppliersaid1">Read More...</a> -->

                                                </p>
                                            </div>

                                            <div *ngIf="quoteReview.correctMaterialGradeReason" id="buyersaid1"
                                                style="background: var(--shadowgrey);border-radius: 5px;" class="">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                    style="margin-bottom: 0;padding: 10px;padding-left: 26px;">
                                                    {{quoteReview.correctMaterialGradeReason}}</p>
                                            </div>
                                        </ng-container>

                                        <!-- SUPPLIER ANSWER 1 -->
                                        <!-- MATERIAL GRADE YES -->
                                        <div *ngIf="rfqReview.quoteDetails[0].correctMaterialGrade == true">
                                            <div class="col" style="padding-left: 0;">
                                                <div style="margin-left: 2.5rem;margin-top: 1rem;width: 437px;">
                                                    <div class="row d-xl-flex justify-content-xl-start"
                                                        style="padding-top: 0;width: 553px;min-width: 498px;">
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group"><input
                                                                    ngModel disabled type="checkbox" id="MatYES"
                                                                    class="btn-check form-check-input"
                                                                    name="mg" /><label
                                                                    class="form-label form-check-label btn btn-outline-info"
                                                                    style="color:white; background-color: var(--darkblue); border-radius: 40px;font-size: 14px;"
                                                                    name="flexRadioDefault">Yes</label></div>
                                                        </div>
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 offset-xl-0 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group"><input
                                                                    ngModel disabled type="checkbox" id="MatNo"
                                                                    class="btn-check form-check-input"
                                                                    name="mg" /><label
                                                                    class="form-label form-check-label btn btn-outline-info"
                                                                    style="border-radius: 40px;font-size: 14px;">No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <!-- MATERIAL GRADE NO -->
                                        <div *ngIf="rfqReview.quoteDetails[0].correctMaterialGrade == false">
                                            <div class="col" style="padding-left: 0;">
                                                <div style="margin-left: 2.5rem;margin-top: 1rem;width: 437px;">
                                                    <div class="row d-xl-flex justify-content-xl-start"
                                                        style="padding-top: 0;width: 553px;min-width: 498px;">
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group"><input
                                                                    ngModel disabled type="checkbox" id="MatYES"
                                                                    class="btn-check form-check-input"
                                                                    name="mg" /><label
                                                                    class="form-label form-check-label btn btn-outline-info"
                                                                    style=" border-radius: 40px;font-size: 14px;"
                                                                    name="flexRadioDefault">Yes</label></div>
                                                        </div>
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 offset-xl-0 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group"><input
                                                                    ngModel disabled type="checkbox" id="MatNo"
                                                                    class="btn-check form-check-input"
                                                                    name="mg" /><label
                                                                    class="form-label form-check-label btn btn-outline-info"
                                                                    style="color:white; background-color: var(--darkblue); border-radius: 40px;font-size: 14px;">No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div *ngIf="rfqReview.quoteDetails[0].correctMaterialGradeReason"
                                                style="background: var(--shadowgrey);border-radius: 5px;" class="">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                    style="margin-bottom: 0;padding: 10px;padding-left: 26px;">
                                                    {{rfqReview.quoteDetails[0].correctMaterialGradeReason}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- BUYER AND SUPPLIER ANSWERS 2-->
                                    <div>

                                        <div class="col">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-left:26px; margin-bottom: 0;padding-left: 0px;">Correct
                                                quantity of parts?

                                            </p>


                                        </div>
                                        <!-- Buyer answer start 2 -->
                                        <ng-container *ngIf=" this.quoteReview ">
                                            <div *ngIf="quoteReview.correctQuantity">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                    style="margin-bottom: 10px;padding-left: 26px;">Buyer answered:
                                                    <span class="bluetext">Yes</span>
                                                </p>
                                            </div>

                                            <div *ngIf="!quoteReview.correctQuantity ">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                    style="margin-bottom: 0;padding-left: 26px;padding-bottom: 5px;">
                                                    Buyer answered:
                                                    <span class="bluetext" style="margin-left: 5px;">No &nbsp; </span>
                                                    <!-- <a  class="badge rounded-pill bluetextthin" href="#" style="margin-bottom: 0px;margin-left: 5px;background: var(--darkblue);color: var(--shadowgrey);font-size: 10px;line-height: 11px;" data-bs-toggle="collapse" data-bs-target="#suppliersaid1">Read More...</a> -->

                                                </p>
                                            </div>

                                            <div *ngIf="quoteReview.correctQuantityReason" id="buyersaid2"
                                                style="background: var(--shadowgrey);border-radius: 5px;" class="">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                    style="margin-bottom: 0;padding: 10px;padding-left: 26px;">
                                                    {{quoteReview.correctQuantityReason}}</p>
                                            </div>
                                        </ng-container>



                                        <!-- SUPPLIER ANSWER 2 -->
                                        <!-- CORRECT QUANTITY YES -->
                                        <div *ngIf="rfqReview.quoteDetails[0].correctQuantity == true">
                                            <div class="col" style="padding-left: 0;">
                                                <div style="margin-left: 2.5rem;margin-top: 1rem;width: 437px;">
                                                    <div class="row d-xl-flex justify-content-xl-start"
                                                        style="padding-top: 0;width: 553px;min-width: 498px;">
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group">
                                                                <input ngModel disabled type="checkbox"
                                                                    class="btn-check form-check-input"
                                                                    name="mg" /><label
                                                                    class="form-label form-check-label btn btn-outline-info"
                                                                    style="color:white; background-color: var(--darkblue); border-radius: 40px;font-size: 14px;"
                                                                    name="flexRadioDefault">Yes</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 offset-xl-0 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group">
                                                                <input ngModel disabled type="checkbox"
                                                                    class="btn-check form-check-input"
                                                                    name="mg" /><label
                                                                    class="form-label form-check-label btn btn-outline-info"
                                                                    style="border-radius: 40px;font-size: 14px;">No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <!-- CORRECT QUANTITY  NO -->
                                        <div *ngIf="rfqReview.quoteDetails[0].correctQuantity == false">
                                            <div class="col" style="padding-left: 0;">
                                                <div style="margin-left: 2.5rem;margin-top: 1rem;width: 437px;">
                                                    <div class="row d-xl-flex justify-content-xl-start"
                                                        style="padding-top: 0;width: 553px;min-width: 498px;">
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group">
                                                                <input ngModel disabled type="checkbox"
                                                                    class="btn-check form-check-input"
                                                                    name="mg" /><label
                                                                    class="form-label form-check-label btn btn-outline-info"
                                                                    style=" border-radius: 40px;font-size: 14px;"
                                                                    name="flexRadioDefault">Yes</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 offset-xl-0 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group">
                                                                <input ngModel disabled type="checkbox"
                                                                    class="btn-check form-check-input"
                                                                    name="mg" /><label
                                                                    class="form-label form-check-label btn btn-outline-info"
                                                                    style="color:white; background-color: var(--darkblue); border-radius: 40px;font-size: 14px;">No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div *ngIf="rfqReview.quoteDetails[0].correctQuantityReason"
                                                style="background: var(--shadowgrey);border-radius: 5px;" class="">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                    style="margin-bottom: 0;padding: 10px;padding-left: 26px;">
                                                    {{rfqReview.quoteDetails[0].correctQuantityReason}}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- BUYER AND SUPPLIER ANSWERS 3-->
                                    <div>

                                        <div class="col">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-left:26px; margin-bottom: 0;padding-left: 0px;">Buyer
                                                Collected On Time?

                                            </p>


                                        </div>
                                        <!-- Buyer answer start 3 -->

                                        <ng-container *ngIf=" this.quoteReview ">
                                            <div *ngIf="quoteReview.onTimeDelivery">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                    style="margin-bottom: 10px;padding-left: 26px;">Buyer answered:
                                                    <span class="bluetext">Yes</span>
                                                </p>
                                            </div>

                                            <div *ngIf="!quoteReview.onTimeDelivery ">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                    style="margin-bottom: 0;padding-left: 26px;padding-bottom: 5px;">
                                                    Buyer answered:
                                                    <span class="bluetext" style="margin-left: 5px;">No &nbsp; </span>
                                                    <!-- <a  class="badge rounded-pill bluetextthin" href="#" style="margin-bottom: 0px;margin-left: 5px;background: var(--darkblue);color: var(--shadowgrey);font-size: 10px;line-height: 11px;" data-bs-toggle="collapse" data-bs-target="#suppliersaid1">Read More...</a> -->

                                                </p>
                                            </div>




                                            <div *ngIf="quoteReview.onTimeDeliveryReason" id="buyersaid3"
                                                style="background: var(--shadowgrey);border-radius: 5px;" class="">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                    style="margin-bottom: 0;padding: 10px;padding-left: 26px;">
                                                    {{quoteReview.onTimeDeliveryReason}}</p>
                                            </div>
                                        </ng-container>



                                        <!-- SUPPLIER ANSWER 3 -->
                                        <!-- COLLECTED ON TIME YES -->
                                        <div *ngIf="rfqReview.onTimeCollection == true">
                                            <div class="col" style="padding-left: 0;">
                                                <div style="margin-left: 2.5rem;margin-top: 1rem;width: 437px;">
                                                    <div class="row d-xl-flex justify-content-xl-start"
                                                        style="padding-top: 0;width: 553px;min-width: 498px;">
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group">
                                                                <input ngModel disabled type="checkbox"
                                                                    class="btn-check form-check-input"
                                                                    name="mg" /><label
                                                                    class="form-label form-check-label btn btn-outline-info"
                                                                    style="color:white; background-color: var(--darkblue); border-radius: 40px;font-size: 14px;"
                                                                    name="flexRadioDefault">Yes</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 offset-xl-0 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group">
                                                                <input ngModel disabled type="checkbox"
                                                                    class="btn-check form-check-input"
                                                                    name="mg" /><label
                                                                    class="form-label form-check-label btn btn-outline-info"
                                                                    style="border-radius: 40px;font-size: 14px;">No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <!-- COLLECTED ON TIME NO -->
                                        <div *ngIf="rfqReview.onTimeCollection == false">
                                            <div class="col" style="padding-left: 0;">
                                                <div style="margin-left: 2.5rem;margin-top: 1rem;width: 437px;">
                                                    <div class="row d-xl-flex justify-content-xl-start"
                                                        style="padding-top: 0;width: 553px;min-width: 498px;">
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group">
                                                                <input ngModel disabled type="checkbox"
                                                                    class="btn-check form-check-input"
                                                                    name="mg" /><label
                                                                    class="form-label form-check-label btn btn-outline-info"
                                                                    style=" border-radius: 40px;font-size: 14px;"
                                                                    name="flexRadioDefault">Yes</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 offset-xl-0 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group">
                                                                <input ngModel disabled type="checkbox"
                                                                    class="btn-check form-check-input"
                                                                    name="mg" /><label
                                                                    class="form-label form-check-label btn btn-outline-info"
                                                                    style="color:white; background-color: var(--darkblue); border-radius: 40px;font-size: 14px;">No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div *ngIf="rfqReview.onTimeCollectionReason"
                                                style="background: var(--shadowgrey);border-radius: 5px;" class="">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                    style="margin-bottom: 0;padding: 10px;padding-left: 26px;">
                                                    {{rfqReview.onTimeCollectionReason}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- BUYER AND SUPPLIER ANSWERS 4-->
                                    <div>

                                        <div class="col">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-left:26px; margin-bottom: 0;padding-left: 0px;">Buyer
                                                communicated timeously?

                                            </p>


                                        </div>

                                        <!-- Buyer answer start 4 -->
                                        <ng-container *ngIf=" this.quoteReview ">
                                            <div *ngIf="quoteReview.goodCommunication">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                    style="margin-bottom: 10px;padding-left: 26px;">Buyer answered:
                                                    <span class="bluetext">Yes</span>
                                                </p>
                                            </div>

                                            <div *ngIf="!quoteReview.goodCommunication ">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                    style="margin-bottom: 0;padding-left: 26px;padding-bottom: 5px;">
                                                    Buyer answered:
                                                    <span class="bluetext" style="margin-left: 5px;">No &nbsp; </span>
                                                    <!-- <a  class="badge rounded-pill bluetextthin" href="#" style="margin-bottom: 0px;margin-left: 5px;background: var(--darkblue);color: var(--shadowgrey);font-size: 10px;line-height: 11px;" data-bs-toggle="collapse" data-bs-target="#suppliersaid1">Read More...</a> -->

                                                </p>
                                            </div>

                                            <div *ngIf="quoteReview.goodCommunicationReason" id="buyersaid66"
                                                style="background: var(--shadowgrey);border-radius: 5px;" class="">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                    style="margin-bottom: 0;padding: 10px;padding-left: 26px;">
                                                    {{quoteReview.goodCommunicationReason}}</p>
                                            </div>


                                        </ng-container>


                                        <!-- SUPPLIER ANSWER 4 -->
                                        <!-- BUYER COMMUNICATED ON TIME YES -->
                                        <div *ngIf="rfqReview.goodCommunication == true">
                                            <div class="col" style="padding-left: 0;">
                                                <div style="margin-left: 2.5rem;margin-top: 1rem;width: 437px;">
                                                    <div class="row d-xl-flex justify-content-xl-start"
                                                        style="padding-top: 0;width: 553px;min-width: 498px;">
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group">
                                                                <input ngModel disabled type="checkbox"
                                                                    class="btn-check form-check-input"
                                                                    name="mg" /><label
                                                                    class="form-label form-check-label btn btn-outline-info"
                                                                    style="color:white; background-color: var(--darkblue); border-radius: 40px;font-size: 14px;"
                                                                    name="flexRadioDefault">Yes</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 offset-xl-0 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group">
                                                                <input ngModel disabled type="checkbox"
                                                                    class="btn-check form-check-input"
                                                                    name="mg" /><label
                                                                    class="form-label form-check-label btn btn-outline-info"
                                                                    style="border-radius: 40px;font-size: 14px;">No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <!-- BUYER COMMUNICATED ON TIME NO -->
                                        <div *ngIf="rfqReview.goodCommunication == false">
                                            <div class="col" style="padding-left: 0;">
                                                <div style="margin-left: 2.5rem;margin-top: 1rem;width: 437px;">
                                                    <div class="row d-xl-flex justify-content-xl-start"
                                                        style="padding-top: 0;width: 553px;min-width: 498px;">
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group">
                                                                <input ngModel disabled type="checkbox"
                                                                    class="btn-check form-check-input"
                                                                    name="mg" /><label
                                                                    class="form-label form-check-label btn btn-outline-info"
                                                                    style=" border-radius: 40px;font-size: 14px;"
                                                                    name="flexRadioDefault">Yes</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 offset-xl-0 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group">
                                                                <input ngModel disabled type="checkbox"
                                                                    class="btn-check form-check-input"
                                                                    name="mg" /><label
                                                                    class="form-label form-check-label btn btn-outline-info"
                                                                    style="color:white; background-color: var(--darkblue); border-radius: 40px;font-size: 14px;">No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div *ngIf="rfqReview.goodCommunicationReason"
                                                style="background: var(--shadowgrey);border-radius: 5px;" class="">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                    style="margin-bottom: 0;padding: 10px;padding-left: 26px;">
                                                    {{rfqReview.goodCommunicationReason}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- EXPECTED DELIVERY DATE -->
                                    <div style="padding-bottom: 5px; padding-left: 38px">

                                        <p *ngIf="selectedRFQ.deliveryRequirements == 'Collection'"
                                            class="text-nowrap bluetext" style="color: #0e0e82;">
                                            Expected Collection
                                            Date:
                                        </p>
                                        <p *ngIf="selectedRFQ.deliveryRequirements == 'Delivery' || selectedRFQ.deliveryRequirements == null "
                                            class="text-nowrap bluetext" style="color: #0e0e82;">Expected Delivery
                                            Date:
                                        </p>
                                        <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                            style="margin-bottom: 0;">
                                            {{selectedRFQ.maxLeadTime | date:'mediumDate'}}
                                        </p>

                                    </div>



                                    <!-- BUYER AND SUPPLIER ANSWERS 5-->

                                    <div class="col" style="margin-left: 5px">

                                        <div *ngIf="selectedRFQ.deliveryRequirements == 'Delivery'"
                                            class="col-lg-6 col-xxl-5"
                                            style="padding-left: 0; padding-bottom: 20px; width: 50%;">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="padding-bottom: 0px; margin-bottom: 0;padding-left: 22px;">
                                                Actual Delivery Date:
                                            </p>
                                            <ng-container>
                                                <div>
                                                    <p *ngIf="this.rfqReview "
                                                        class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin text-nowrap"
                                                        style="margin-bottom: 0px;padding-left: 22px;">
                                                        Supplier Delivery Date:
                                                        <span style="margin-left:3px;"
                                                            class="bluetext">{{rfqReview.quoteDetails[0].actualDeliveryDate
                                                            |
                                                            date :"mediumDate"}}</span>
                                                    </p>

                                                    <!-- IF SUPPLIER DELIVERY CALC -->
                                                    <ng-container>
                                                        <div class="d-flex"
                                                            style="padding-left: 22px; padding-top:0px ;">

                                                            <p class="text-nowrap bluetextthin" style="color: #0e0e82;">
                                                                Supplier Delivery
                                                                is:
                                                            </p>

                                                            <p class="text-nowrap bluetext"
                                                                style="color: #0e0e82; padding-left: 3px;">

                                                                <span *ngIf="dueDate == 1 || dueDate > 1 "
                                                                    style="color: var(--yellow) !important;">
                                                                    {{roundDate}} Days
                                                                    Late</span>

                                                                <span *ngIf="dueDate < 0  "
                                                                    style="color: var(--yellow) !important;">
                                                                    {{roundDate}} Days Early </span>

                                                                <span *ngIf="roundDate == 0"
                                                                    style="color: var(--maingreen) !important;"> On
                                                                    Time</span>

                                                            </p>

                                                        </div>



                                                    </ng-container>




                                                </div>
                                            </ng-container>
                                            <ng-container>
                                                <div>
                                                    <p *ngIf="this.rfqReview "
                                                        class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin text-nowrap"
                                                        style="margin-bottom: 0px;padding-left: 22px;">
                                                        Buyer Delivery Date:
                                                        <span style="margin-left:3px;"
                                                            class="bluetext">{{quoteReview.actualDeliveryDate |
                                                            date :"mediumDate"}}</span>
                                                    </p>

                                                    <!-- IF BUYER DELIVERY CALC -->
                                                    <ng-container>
                                                        <div class="d-flex"
                                                            style="padding-left: 22px; padding-top:0px ;">

                                                            <p class="text-nowrap bluetextthin" style="color: #0e0e82;">
                                                                Buyer Delivery
                                                                is:
                                                            </p>

                                                            <p class="text-nowrap bluetext"
                                                                style="color: #0e0e82; padding-left: 3px;">

                                                                <span *ngIf="dueDateb == 1 || dueDateb > 1 "
                                                                    style="color: var(--yellow) !important;">
                                                                    {{roundDateb}}
                                                                    Days
                                                                    Late</span>

                                                                <span *ngIf="dueDateb < 0  "
                                                                    style="color: var(--yellow) !important;">
                                                                    {{roundDateb}} Days Early </span>

                                                                <span *ngIf="roundDateb == 0"
                                                                    style="color: var(--maingreen) !important;"> On
                                                                    Time</span>

                                                            </p>

                                                        </div>



                                                    </ng-container>


                                                </div>
                                            </ng-container>

                                        </div>

                                        <div *ngIf="selectedRFQ.deliveryRequirements == 'Collection'"
                                            class="col-lg-6 col-xxl-5"
                                            style="padding-left: 0; padding-bottom: 20px; width: 50%;">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 22px;">
                                                Actual Collection Date
                                            </p>
                                            <ng-container>
                                                <div>
                                                    <p *ngIf="this.rfqReview "
                                                        class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin text-nowrap"
                                                        style="margin-bottom: 0px;padding-left: 22px;">
                                                        Supplier Collection Date:
                                                        <span style="margin-left:3px;"
                                                            class="bluetext">{{rfqReview.quoteDetails[0].actualDeliveryDate
                                                            |
                                                            date :"mediumDate"}}</span>
                                                    </p>

                                                    <!-- IF SUPPLIER COLLECTION CALC -->
                                                    <ng-container>
                                                        <div class="d-flex"
                                                            style="padding-left: 22px; padding-top:0px ;">

                                                            <p class="text-nowrap bluetextthin" style="color: #0e0e82;">
                                                                Supplier Collection Date
                                                                is:
                                                            </p>

                                                            <p class="text-nowrap bluetext"
                                                                style="color: #0e0e82; padding-left: 3px;">

                                                                <span *ngIf="dueDate == 1 || dueDate > 1 "
                                                                    style="color: var(--yellow) !important;">
                                                                    {{roundDate}} Days
                                                                    Late</span>

                                                                <span *ngIf="dueDate < 0  "
                                                                    style="color: var(--yellow) !important;">
                                                                    {{roundDate}} Days Early </span>

                                                                <span *ngIf="roundDate == 0"
                                                                    style="color: var(--maingreen) !important;"> On
                                                                    Time</span>

                                                            </p>

                                                        </div>



                                                    </ng-container>


                                                </div>
                                            </ng-container>
                                            <ng-container>
                                                <div>
                                                    <p *ngIf="this.quoteReview "
                                                        class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin text-nowrap"
                                                        style="margin-bottom: 0px;padding-left: 22px;">
                                                        Buyer Collection Date:
                                                        <span style="margin-left:3px;"
                                                            class="bluetext">{{quoteReview.actualDeliveryDate |
                                                            date :"mediumDate"}}</span>
                                                    </p>


                                                    <!-- IF BUYER COLLECTION CALC -->
                                                    <ng-container>
                                                        <div class="d-flex"
                                                            style="padding-left: 22px; padding-top:0px ;">

                                                            <p class="text-nowrap bluetextthin" style="color: #0e0e82;">
                                                                Buyer Collection
                                                                is:
                                                            </p>

                                                            <p class="text-nowrap bluetext"
                                                                style="color: #0e0e82; padding-left: 3px;">

                                                                <span *ngIf="dueDateb == 1 || dueDateb > 1 "
                                                                    style="color: var(--yellow) !important;">
                                                                    {{roundDateb}}
                                                                    Days
                                                                    Late</span>

                                                                <span *ngIf="dueDateb < 0  "
                                                                    style="color: var(--yellow) !important;">
                                                                    {{roundDateb}} Days Early </span>

                                                                <span *ngIf="roundDateb == 0"
                                                                    style="color: var(--maingreen) !important;"> On
                                                                    Time</span>

                                                            </p>

                                                        </div>



                                                    </ng-container>
                                                </div>
                                            </ng-container>

                                        </div>

                                    </div>


                                </div>


                            </div>
                            <div class="modal-footer d-lg-flex align-items-lg-center"><a class="btn text-gray-500"
                                    role="button" data-bs-dismiss="modal">Close</a>

                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </section>

        <!-- RFQ FILE DOWNLOADS MODAL -->
        <div role="dialog" tabindex="-1" class="modal fade" data-bs-backdrop="static" id="rfqFileDownloadsClosed">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header" style="background: var(--lightblue);">
                        <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">
                            RFQ
                            File
                            Downloads
                        </h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <!-- <div
                               class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                               <div class="col"
                                   style="padding-left: 0;">
                                   <p class="text-center d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-center justify-content-md-center justify-content-lg-center blueheadsmall"
                                       style="margin-bottom: 0;padding-left: 32px;">
                                       Download
                                       the
                                       following
                                       files
                                   </p>
                               </div>
                           </div> -->

                            <div class="row">
                                <div class="col d-lg-flex justify-content-lg-start" style="padding-bottom: 12px;">
                                    <div class="col-xxl-12" style="margin-top: 10px;">
                                        <section>
                                            <div class="table-responsive">
                                                <table class="table table-sm my-0 mydatatable">
                                                    <thead class="buyerimage">
                                                        <tr class="text-start">
                                                            <th class="blueheadsmall" style="text-align: center;">
                                                                FILE
                                                                TYPE
                                                            </th>
                                                            <th class="blueheadsmall" style="text-align: center;">
                                                                FILE
                                                                NAME
                                                            </th>
                                                            <th class="blueheadsmall" style="text-align: center;">
                                                                DATE
                                                                UPLOADED
                                                            </th>
                                                            <!-- <th class="blueheadsmall"
                                                               style="text-align: center;">
                                                               FILE TYPE</th>
                                                           <th class="blueheadsmall"
                                                               style="text-align: center;">
                                                               EDIT</th> -->
                                                            <th class="blueheadsmall" style="text-align: center;">
                                                                DOWNLOAD
                                                            </th>
                                                            <th class="blueheadsmall">
                                                            </th>
                                                            <th class="blueheadsmall">
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <ng-container>
                                                            <tr class="tablerow">
                                                                <td class="text-center bluetextthin">
                                                                    Purchase Order
                                                                </td>
                                                                <td class="text-center bluetextthin">
                                                                    <ng-container
                                                                        *ngIf="selectedRFQ.purchaseOrderFileName == null">
                                                                        Awaiting
                                                                        Upload
                                                                    </ng-container>
                                                                    {{selectedRFQ.purchaseOrderFileName}}
                                                                </td>

                                                                <td class="text-center bluetextthin">
                                                                    {{selectedRFQ.purchaseOrderCreatedAt
                                                                    |
                                                                    date:'mediumDate'}}

                                                                </td>


                                                                <td class="text-nowrap" style="text-align: center;">
                                                                    <button class="btn btn-secondary invoicebtn"
                                                                        role="button"
                                                                        [disabled]="selectedRFQ.purchaseOrderFileId == null"
                                                                        (click)="downloadPurchaseOrderFile(selectedRFQ.purchaseOrderFileId)"
                                                                        style="padding:0px;color:var(--darkblue);font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey); "><i
                                                                            class="fa fa-download"
                                                                            style="font-size: 14px;color: var(--lightblue);"></i>&nbsp;Download

                                                                        <!-- <div
                                                                           *ngIf="selectedRFQ.purchaseOrderFileId != null && selectedRFQ.quoteDetails.invoiceQuoteFileId == null && selectedRFQ.purchaseOrderDownloaded == false">
                                                                           <span
                                                                               class="d-flex justify-content-center align-items-center">
                                                                               <i style="margin-left:10px; padding:0px; font-size: 13px; color:red"
                                                                                   class="fas fa-circle"></i>
                                                                           </span>
                                                                       </div> -->

                                                                    </button>

                                                            </tr>
                                                            <tr class="tablerow">
                                                                <td class="text-center bluetextthin">
                                                                    Proof Of Payment
                                                                </td>
                                                                <td class="text-center bluetextthin">
                                                                    <ng-container
                                                                        *ngIf="selectedRFQ.proofOfPaymentFileName == null">
                                                                        Awaiting
                                                                        Upload
                                                                    </ng-container>
                                                                    {{selectedRFQ.proofOfPaymentFileName}}
                                                                </td>
                                                                <td class="text-center bluetextthin">
                                                                    {{selectedRFQ.proofOfPaymentCreatedAt
                                                                    |
                                                                    date:'mediumDate'}}

                                                                </td>


                                                                <td class="text-nowrap" style="text-align: center;">
                                                                    <button class="btn btn-secondary invoicebtn"
                                                                        role="button"
                                                                        [disabled]="selectedRFQ.proofOfPaymentFileId == null "
                                                                        (click)="downloadDrawingFile(selectedRFQ.proofOfPaymentFileId)"
                                                                        style="padding:0px;color:var(--darkblue);font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color:
                                                                                           var(--shadowgrey);"><i
                                                                            class="fa fa-download"
                                                                            style="font-size: 14px;color: var(--lightblue);"></i>&nbsp;Download</button>
                                                                </td>
                                                            </tr>
                                                            <tr class="tablerow">
                                                                <td class="text-center bluetextthin">
                                                                    Delivery Note
                                                                </td>
                                                                <td class="text-center bluetextthin">
                                                                    <ng-container
                                                                        *ngIf="selectedRFQ.deliveryNoteFileName == null">
                                                                        Awaiting
                                                                        Upload
                                                                    </ng-container>
                                                                    {{selectedRFQ.deliveryNoteFileName}}
                                                                </td>

                                                                <td class="text-center bluetextthin">
                                                                    {{selectedRFQ.deliveryNoteCreatedAt
                                                                    |
                                                                    date:'mediumDate'}}
                                                                </td>


                                                                <td class="text-nowrap" style="text-align: center;">
                                                                    <button class="btn btn-secondary invoicebtn"
                                                                        role="button"
                                                                        [disabled]="selectedRFQ.deliveryNoteFileId == null"
                                                                        (click)="downloadDrawingFile(selectedRFQ.deliveryNoteFileId)"
                                                                        style="padding:0px;color:var(--darkblue);font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color:
                                                                                           var(--shadowgrey);"><i
                                                                            class="fa fa-download"
                                                                            style="font-size: 14px;color: var(--lightblue);"></i>&nbsp;Download</button>

                                                                </td>

                                                            </tr>



                                                        </ng-container>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow"
                               style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;">
                               <div
                                   class="col">
                                   <p class="text-nowrap"
                                       style="font-weight: normal;font-size: 12px;">
                                       2/4
                                       files
                                       selected
                                   </p>
                               </div>
                           </div> -->
                        </div>
                    </div>
                    <div class="modal-footer d-lg-flex align-items-lg-center">
                        <!-- <a class="btn text-gray-500"
                           role="button" 
                           data-bs-dismiss="modal">Close</a> -->

                        <!-- <a
                           
                           class="btn d-flex d-lg-flex justify-content-center align-items-center justify-content-lg-center align-items-lg-center yellowbtn"
                           role="button"
                           style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Download</a> -->
                    </div>
                </div>
            </div>
        </div>



    </div>
</ng-container>

<ng-container #nodata *ngIf="viewCompletedQuotes.length == 0 && !dataLoading">
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel" id="tab-1">
            <div class="container-fluid bluecont">
                <h1 class="text-center" style="font-size: 20px;">No quotes to display
                </h1>


            </div>
        </div>
    </div>

    <div style="height: 100vh;">
        <div class="d-flex flex-column justify-content-center align-items-center">
            <img src="assets/nodata.png" style="margin-top: 8rem;">

            <h1 class="text-center text-center bluehead" style="font-size: 20px;margin-top: 1rem; ">No data
            </h1>

        </div>
    </div>
</ng-container>

<ng-container #loading *ngIf="dataLoading">
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel" id="tab-1">
            <div class="container-fluid bluecont">
                <h1 class="text-center" style="font-size: 20px;">Fetching Quotes...
                </h1>


            </div>
        </div>
    </div>


    <div class="container-fluid d-flex justify-content-center align-items-center"
        style="margin-top:-10rem;min-height: 100vh; ">
        <div class="text-center text-center bluehead"><span class="spinner-border spinner-border-sm mr-1"></span>
            Loading...
        </div>

    </div>

</ng-container>