import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const account = this.authService.accountValue;
    if (account) {
        // check if route is restricted by role
        if (route.data.roles) {
          for (let useraccount of account.account.roles){
            if(!route.data.roles.includes(useraccount)){

              // role not authorized so redirect to home page
              this.router.navigate(['/']);
              return false;
            }
          }
        }

        // authorized so return true
        return true;
    }

    // not logged in so redirect to login page with the return url 
    this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url }});
    return false;
}

  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): true|UrlTree {
  //   const url: string = state.url;

  //   return this.checkLogin(url);
  // }

  // checkLogin(url: string): true|UrlTree {
  //   if (this.authService.isLoggedIn) { return true; }

  //   // Store the attempted URL for redirecting
  //   this.authService.redirectUrl = url;

  //   // Redirect to the login page
  //   return this.router.parseUrl('/login');
  // }
}
