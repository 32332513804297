import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BuyerCompany } from '../models/buyerCompany';
import { ContactDetailsEdit } from '../models/contactDetailsEdit';
import { DefaultSearchParameters } from '../models/deafaultSearchParameters';
import { AddPreferedBuyer, PreferedBuyerFinal, preferedBuyerInitial } from '../models/preferredBuyer';
import { UploadRfqFile } from '../models/rfqCreate';
import { RfqFileDownload } from '../models/rfqFileDownload';
import { CreditApplicationAction, GetAllTradelySpecificDetails, SupplierCompany, TradleySpecificDetailsEdit } from '../models/supplierCompany';
import { MessageService } from './message.service';
import { ToastrNotificationService } from './toastr-notification.service';

const baseUrl = `${environment.apiUrl}/SupplierCompany`;

@Injectable({
  providedIn: 'root'
})
export class SupplierCompanyService {

  constructor(
    private messageService: MessageService,
    private http: HttpClient,
    private toastrNotification: ToastrNotificationService
  ) { }

  /** GET supplier company data from server */
  getSupplierCompanyData(supplierId: number): Observable<SupplierCompany> {
    const url = `${baseUrl}/SupplierDetails?supplierId=${supplierId}`
    return this.http.get<any>(url)
      .pipe(
        map(supplierCompanyData => { return supplierCompanyData.results }),
        tap(_ => this.log('fetched supplier company data')),
        catchError(this.handleError<SupplierCompany>('getSupplierCompanyData'))
      );
  }

  getSupplierConfiguration(supplierId: number): Observable<DefaultSearchParameters> {
    const url = `${baseUrl}/SupplierConfiguration?supplierId=${supplierId}`
    return this.http.get<any>(url)
      .pipe(
        map(supplierConfigurationData => { return supplierConfigurationData.results }),
        tap(_ => this.log('fetched supplier configuration data')),
        catchError(this.handleError<DefaultSearchParameters>('getSupplierConfiguration'))
      );
  }

  /** GET Tradely Specific data from server */
  getAllTradelySpecificData(): Observable<GetAllTradelySpecificDetails> {
    const url = `${baseUrl}/GetTradelyAllDetails`


    return this.http.get<any>(url)
      .pipe(
        map(AllCompanyData => { return AllCompanyData.results }),
        tap(_ => this.log('fetched All supplier company data')),
        catchError(this.handleError<GetAllTradelySpecificDetails>('getSupplierCompanyData'))
      );
  }


  editSupplierDetails(details: ContactDetailsEdit): Observable<ContactDetailsEdit> {
    const url = `${baseUrl}/EditSupplierDetails`;
    return this.http.post<any>(url, details, this.httpOptions)
      .pipe(
        map(editResult => {  return editResult.message }),
        tap((editResult: ContactDetailsEdit) => this.log(`supplier details succesfully`)),
        catchError(this.handleError<ContactDetailsEdit>('supplier details edit'))
      );
  }

  editTradleyDetails(details: TradleySpecificDetailsEdit): Observable<TradleySpecificDetailsEdit> {
    const url = `${baseUrl}/EditTradleyDetails`;
    return this.http.post<any>(url, details, this.httpOptions)
      .pipe(
        map(editResult => {  return editResult.message }),
        tap((editResult: TradleySpecificDetailsEdit) => this.log(`supplier details succesfully`)),
        catchError(this.handleError<TradleySpecificDetailsEdit>('supplier details edit'))
      );
  }

  /** GET All Preferred Buyers  data from server */
  getAllBuyers(userId: string): Observable<preferedBuyerInitial> {
    const url = `${baseUrl}/AllPreferredBuyers?userId=${userId}`
    return this.http.get<any>(url)
      .pipe(
        map(buyers => { return buyers.results }),
        tap(_ => this.log('fetched possible preffered buyer data')),
        catchError(this.handleError<preferedBuyerInitial>('getPreferedSupplierInitial'))
      );
  }

  /** GET preferred buyers data from server */
  getPreferedBuyersFinal(supplierId: number, buyerId: number): Observable<PreferedBuyerFinal> {
    const url = `${baseUrl}/SelectedPreferedBuyer?supplierId=${supplierId}&buyerId=${buyerId}`
    return this.http.get<any>(url)
      .pipe(
        map(buyers => { return buyers.results }),
        tap(_ => this.log('fetched  preffered supplier data')),
        catchError(this.handleError<PreferedBuyerFinal>('getPreferedBuyerFinal'))
      );
  }



  /** POST:add preferred buyer data to server */
  addPreferedBuyer(details: AddPreferedBuyer): Observable<AddPreferedBuyer> {
    const url = `${baseUrl}/AddPreferedBuyer`;
    return this.http.post<any>(url, details, this.httpOptions)
      .pipe(
        map(editResult => { return editResult.message }),
        tap((editResult: AddPreferedBuyer) => this.log(`prefered buyer added succesfully`)),
        catchError(this.handleError<AddPreferedBuyer>('prefered buyer add'))
      );
  }


  /** POST:remove preferred buyer data from server */
  removePreferedBuyer(details: AddPreferedBuyer): Observable<AddPreferedBuyer> {
    const url = `${baseUrl}/DeletePreferedBuyer`;
    return this.http.post<any>(url, details, this.httpOptions)
      .pipe(
        map(editResult => { return editResult.message }),
        tap((editResult: AddPreferedBuyer) => this.log(`prefered buyer removed succesfully`)),
        catchError(this.handleError<AddPreferedBuyer>('prefered buyer removed'))
      );
  }

  uploadCompanyLogo(rfqFile: UploadRfqFile) {
    const url = `${baseUrl}/Upload/CompanyLogoFile`;
    return this.http.post<any>(url, rfqFile, this.httpOptions)
      .pipe(
        map(fileName => { return fileName.message }),
        tap((buyerFile: UploadRfqFile) => this.log(`Company Logo uploaded succesfully`)),
        catchError(this.handleError<UploadRfqFile>('uploadCompanyLogo'))
      );
  }

  deleteCompanyLogoFile(supplierId: number) {
    const url = `${baseUrl}/Delete/CompanyLogoFile/${supplierId}`;
    return this.http.delete<any>(url)
      .pipe(
        tap(_ => this.log(`delete Company Log oFile`)),
        catchError(this.handleError<RfqFileDownload>(`deleteCompanyLogoFile}`))
      );
  }

  getCompanyLogoFile(supplierId: number): Observable<RfqFileDownload> {
    const url = `${baseUrl}/Download/CompanyLogo/${supplierId}`;
    return this.http.get<any>(url)
      .pipe(
        map(rfqDrawingFile => { return rfqDrawingFile.file }),
        tap(_ => this.log(`fetched Company Logo File`)),
        catchError(this.handleError<RfqFileDownload>(`getCompanyLogoFile}`))
      );
  }

  uploadCreditApplicationTemplateFile(rfqFile: UploadRfqFile) {
    const url = `${baseUrl}/Upload/CreditApplicationTemplate`;
    return this.http.post<any>(url, rfqFile, this.httpOptions)
      .pipe(
        map(fileName => { return fileName.message }),
        tap((creditAppTemplateFile: UploadRfqFile) => this.log(`Credit Application Template uploaded succesfully`)),
        catchError(this.handleError<UploadRfqFile>('uploadCreditApplicationTemplate'))
      );
  }

  getCreditApplicationTemplateFile(supplierId: number): Observable<RfqFileDownload> {
    const url = `${baseUrl}/Download/CreditApplicationTemplate/${supplierId}`;
    return this.http.get<any>(url)
      .pipe(
        map(creditApplicationTemplateFile => { return creditApplicationTemplateFile.file }),
        tap(_ => this.log(`fetched Credit Application Template File`)),
        catchError(this.handleError<RfqFileDownload>(`getCreditApplicationTemplateFile}`))
      );
  }

  takeCreditApplicationAction(details: CreditApplicationAction): Observable<CreditApplicationAction> {
    const url = `${baseUrl}/ChangeCreditApplicationState`;
    return this.http.post<any>(url, details, this.httpOptions)
      .pipe(
        map(Results => { return Results.message }),
        tap((Results: CreditApplicationAction) => this.log(`Credit App action taken`)),
        catchError(this.handleError<CreditApplicationAction>('Credit App action failed'))
      );
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`SupplierCompanyService: ${message}`);
  }

  private rfqUrl = 'api/rfqs';  // URL to web api

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.toastrNotification.error(`${operation} failed: ${error.message}`);

      throw error;
    };
  }
}
