import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// used to create fake backend
import { fakeBackendProvider } from './helpers/fake-backend';

import { AuthGuard } from './auth/auth.guard';
import { CanDeactivateGuard } from './can-deactivate.guard';


import { FormsModule } from '@angular/forms'; // <-- NgModel lives here
import { AppRoutingModule } from './app-routing.module';

//helpers
import { JwtInterceptor,  } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
//import { appInitializer } from './helpers/app.initializer';

//services
import { AuthService } from './auth/auth.service';
//search module
import { Ng2SearchPipeModule } from 'ng2-search-filter';


//auto log-out
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup


//componenets
import { AppComponent } from './app.component';
import { BuyerHomeComponent } from './buyer-home/buyer-home.component';
import { SupplierHomeComponent } from './supplier-home/supplier-home.component';
import { MessagesComponent } from './messages/messages.component';
import { ViewRfqComponent } from './view-rfq/view-rfq.component';
import { ViewQuoteComponent } from './view-quote/view-quote.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AlertComponent } from './alert/alert.component';
import { DndDirective } from './directive/dnd.directive';
import { NotificationsComponent } from './notifications/notifications.component';
import { ToastrNotificationComponent} from './toastr-notification/toastr-notification.component';  
import {ToastrNotificationService} from './services/toastr-notification.service';  
import { SearchRfqComponent } from './search-rfq/search-rfq.component';
import { SortDirective } from './directive/sort.directive';
import {NgxPaginationModule} from 'ngx-pagination';
import { PaginationComponent } from './pagination/pagination.component';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ChatComponent } from './chat/chat.component';
import { QuestionsAndAnswersComponent } from './questions-and-answers/questions-and-answers.component';
import { SubmitQuoteComponent } from './submit-quote/submit-quote.component';
import { BrowseRfqComponent } from './browse-rfq/browse-rfq.component';
import { QaReplyComponent } from './qa-reply/qa-reply.component';
import { AutofocusDirective } from './directive/autofocus.directive';
import { InlineEditComponent } from './inline-edit/inline-edit.component';
import { CreateRfqComponent } from './create-rfq/create-rfq.component';
import { InProgressRfqComponent } from './in-progress-rfq/in-progress-rfq.component';
import { OpenRfqComponent } from './open-rfq/open-rfq.component';
import { ClosedRfqComponent } from './closed-rfq/closed-rfq.component';
import { OpenQuotesComponent } from './open-quotes/open-quotes.component';
import { InProgressQuotesComponent } from './in-progress-quotes/in-progress-quotes.component';
import { CompletedQuotesComponent } from './completed-quotes/completed-quotes.component';
import { RfqService } from './services/rfq.service';
import { EditRfqComponent } from './edit-rfq/edit-rfq.component';
import { EditQuoteComponent } from './edit-quote/edit-quote.component';
import { SortAscDscPipe } from './pipes/sort-asc-dsc.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { BuyerProfileComponent } from './buyer-profile/buyer-profile.component';
import { SupplierProfileComponent } from './supplier-profile/supplier-profile.component';
import { BuyerReportingComponent } from './buyer-reporting/buyer-reporting.component';
import { SupplierReportingComponent } from './supplier-reporting/supplier-reporting.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { ExpiredQuotesComponent } from './expired-quotes/expired-quotes.component';
import { AllowDecimalNumberDirective } from './directive/allow-decimal.directive';
import { CancelledRfqComponent } from './cancelled-rfq/cancelled-rfq.component';
import { ReOrderRfqComponent } from './re-order-rfq/re-order-rfq.component';


@NgModule({
  declarations: [
    AppComponent,
    BuyerHomeComponent,
    SupplierHomeComponent,
    MessagesComponent,
    ViewRfqComponent,
    ViewQuoteComponent,
    PageNotFoundComponent,
    AlertComponent,
    DndDirective,
    NotificationsComponent,
    ToastrNotificationComponent, 
    SearchRfqComponent, 
    SortDirective, 
    PaginationComponent, 
    ChatComponent, 
    QuestionsAndAnswersComponent, 
    SubmitQuoteComponent, 
    BrowseRfqComponent, 
    QaReplyComponent, 
    AutofocusDirective, 
    InlineEditComponent, 
    CreateRfqComponent, 
    InProgressRfqComponent, 
    OpenRfqComponent,
     ClosedRfqComponent,
      OpenQuotesComponent,
       InProgressQuotesComponent, 
       CompletedQuotesComponent,
       EditRfqComponent, 
       EditQuoteComponent, 
       SortAscDscPipe, 
       FilterPipe,
       BuyerProfileComponent, 
       SupplierProfileComponent, 
       BuyerReportingComponent, 
       SupplierReportingComponent, 
       EditUserComponent, ExpiredQuotesComponent,
      AllowDecimalNumberDirective, 
      CancelledRfqComponent, 
      ReOrderRfqComponent,
  
  ],

  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    Ng2SearchPipeModule, 
    NgxPaginationModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    AuthGuard, 
    CanDeactivateGuard, 
    //{ provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // provider used to create fake backend
    //fakeBackendProvider,
    ToastrNotificationService,
    CurrencyPipe
  ],  
 
  bootstrap: [AppComponent]
})
export class AppModule { }
