import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
//import 'rxjs/add/observable/combineLatest'
import { ClosedRfqComponent } from '../closed-rfq/closed-rfq.component';
import { InProgressRfqComponent } from '../in-progress-rfq/in-progress-rfq.component';
import { RFQ } from '../models/rfq';
import { rfqState } from '../models/rfqState';
import { OpenRfqComponent } from '../open-rfq/open-rfq.component';
import { RfqService } from '../services/rfq.service';



@Component({
  selector: 'app-view-rfq',
  templateUrl: './view-rfq.component.html',
  styleUrls: ['./view-rfq.component.css'],
  animations: [
    // animation triggers go here
  ]
})
export class ViewRfqComponent implements OnInit {

  @ViewChild('tab2IP') goToTab2: ElementRef;
  @ViewChild('tab3CL') goToTab3: ElementRef;
  @ViewChild('tab1') goToTab1: ElementRef;

  @ViewChild('OpenTab') initiateOpenRfqs: OpenRfqComponent;

  @ViewChild('IPTab') initiateIPRfqs: InProgressRfqComponent;

  @ViewChild('ClosedTab') initiateClosedRfqs: ClosedRfqComponent;

  rfqs: RFQ[] = [];
  openRfqs: RFQ[] = [];
  inProgreeRfq: RFQ[] = [];
  closedRfq: RFQ[] = [];

  sentQID: number;

  openTab = "";
  inProgressTab = "";
  closedTab = "";
  cancelledTab = "";

  reloadOpenRfqs: boolean = false;
  reloadInProgressRfqs: boolean = false;
  reloadClosedRfqs: boolean = false;
  reloadCancelledRfqs: boolean = false;


  openChat: boolean = false;

  rfqstatus: boolean;

  // VARIABLES SENT FOR CHAT COMPONENT TO KNOW IF A BUYER OR SUPPLIER HAS OPENED THE CHAT
  Buyer: boolean = true;
  Supplier: boolean = false;

  // ROLE STRING USED FOR THE GET CHAT HISTORY METHOD
  Role: string;

  rfqStatus: boolean;

  state: string;
  rfqId: number;
  openRfqId: number = 0;
  inProgressRfqId: number = 0;
  closedRfqId: number = 0;
  cancelledRfqId: number = 0;
  closedRfqArchived: string;
  closedRfqArchivedState: string = "";

  openRfqNav: string = "";
  inProgressRfqNav: string = "";
  closedRfqNav: string = "";
  cancelledRfqNav: string = "";


  constructor(private rfqService: RfqService, private route: ActivatedRoute, private router: Router,) { }

  ngOnInit(): void {

    this.SetRoleStringValue();

    this.route.paramMap.subscribe(params => {
      this.state = params.get('state');
      this.rfqId = +params.get('rfqId');

      if (this.state == 'Open' || this.state == 'InProgress' || this.state == 'Closed'|| this.state == 'Cancelled') {
        if (params.get('state') == 'Open') {

          this.openRfqId = +params.get('rfqId');
          this.openRfqNav = params.get('nav');
          this.triggerOpenRfqReloading();

        } else if (params.get('state') == 'InProgress') {

          this.inProgressRfqId = +params.get('rfqId');
          this.inProgressRfqNav = params.get('nav');
          this.triggerInProgressRfqReloading();

        } else if (params.get('state') == 'Closed') {

          this.closedRfqId = +params.get('rfqId');
          this.closedRfqNav = params.get('nav');
          this.triggerClosedRfqReloading();

        } else if (params.get('state') == 'Cancelled') {

          this.cancelledRfqId = +params.get('rfqId');
          this.cancelledRfqNav = params.get('nav');
          this.triggerCancelledRfqReloading();

        }
      } else {
        this.router.navigate(['404']);
      }

    });

    this.route.queryParamMap.subscribe(params => {

      this.closedRfqArchived = params.get('archived');

      // if (this.state == 'Closed' && (params.get('archived') != 'true' && params.get('archived') != 'false') ) {
      //   this.router.navigate(['404']);
      // } 

    });

  }

  setRfqArchivedState(state) {
    this.closedRfqArchivedState = state;
  }

  triggerOpenRfqReloading() {

    this.reloadOpenRfqs = true;
    this.reloadInProgressRfqs = false;
    this.reloadClosedRfqs = false;
    this.reloadCancelledRfqs = false;

    // this.initiateOpenRfqs.getOpenRFQS(rfqId);

    this.openTab = "active";
    this.inProgressTab = "";
    this.closedTab = "";
    this.cancelledTab = "";

    //CLOSES PRIVATE CHAT WHEN SWITCHING TABS
    this.openChat = false;

  }

  triggerInProgressRfqReloading() {

    this.reloadOpenRfqs = false;
    this.reloadInProgressRfqs = true;
    this.reloadClosedRfqs = false;
    this.reloadCancelledRfqs = false;

    // this.initiateIPRfqs.getInProgressRFQS();

    this.openTab = "";
    this.inProgressTab = "active";
    this.closedTab = "";
    this.cancelledTab = "";

    //CLOSES PRIVATE CHAT WHEN SWITCHING TABS
    this.openChat = false;

    // TOGGLES RFQ STATUS BOOL TO NOT ARCHIVED WHEN LOADING THE IN PROGRESS TAB
    this.rfqStatus = false;

  }

  triggerClosedRfqReloading() {

    this.reloadOpenRfqs = false;
    this.reloadInProgressRfqs = false;
    this.reloadClosedRfqs = true;
    this.reloadCancelledRfqs = false;

    //this.initiateClosedRfqs.getClosedRFQS(0);

    this.openTab = "";
    this.inProgressTab = "";
    this.closedTab = "active";
    this.cancelledTab = "";

    //CLOSES PRIVATE CHAT WHEN SWITCHING TABS
    this.openChat = false;

  }


  triggerCancelledRfqReloading() {

    this.reloadOpenRfqs = false;
    this.reloadInProgressRfqs = false;
    this.reloadClosedRfqs = false;
    this.reloadCancelledRfqs = true;

    // this.initiateOpenRfqs.getOpenRFQS(rfqId);

    this.openTab = "";
    this.inProgressTab = "";
    this.closedTab = "";
    this.cancelledTab = "active";

    //CLOSES PRIVATE CHAT WHEN SWITCHING TABS
    this.openChat = false;
  }


  changeOpenTab(cond: boolean) {
    // setTimeout(() => {
    //   this.goToTab1.nativeElement.click();
    // }, 200);

    this.reloadOpenRfqs = true;
    this.reloadInProgressRfqs = false;
    this.reloadClosedRfqs = false;

    this.openTab = "active";
    this.inProgressTab = "";
    this.closedTab = "";


  }

  changeInProgressTab(cond: boolean) {
    // setTimeout(() => {
    //   this.goToTab2.nativeElement.click();
    // }, 200);

    this.reloadOpenRfqs = false;
    this.reloadInProgressRfqs = true;
    this.reloadClosedRfqs = false;

    this.openTab = "";
    this.inProgressTab = "active";
    this.closedTab = "";


  }

  changeClosedTab(cond: boolean) {
    // setTimeout(() => {
    //   this.goToTab3.nativeElement.click();
    // }, 200);

    this.reloadOpenRfqs = false;
    this.reloadInProgressRfqs = false;
    this.reloadClosedRfqs = true;

    this.openTab = "";
    this.inProgressTab = "";
    this.closedTab = "active";

  }


  triggerChatOpen(value: boolean) {
    this.openChat = value;
  }

  triggerQid(quoteid: number) {
    this.sentQID = quoteid;
  }

  rfqStatusSend(status: boolean) {
    this.rfqStatus = status;

  }


  SetRoleStringValue() {
    if (this.Buyer == true && this.Supplier == false) {
      this.Role = "Buyer";

    }
    if (this.Buyer == false && this.Supplier == true) {
      this.Role = "Supplier";
    }
  }

}


