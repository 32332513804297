import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef, OnDestroy, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { Account } from '../models/account';
import { QuoteHeaddisplay } from '../models/header';
import { ReloadViewRfqs } from '../models/reloadViewRfqs';
import { RFQ } from '../models/rfq';
import { RecievePaymentRequest } from '../models/rfqPaymentConfirmed';
import { ChatDetails, View_Chat } from '../models/viewChat';
import { View_Quotes, View_QuotesRFQs } from '../models/view_quote';
import { QuoteService } from '../services/quote.service';

import { SortCriteria } from '../pipes/sort-asc-dsc.pipe';
import { ViewQuoteServiceService } from '../services/view-quote-service.service';
import { ChatService } from 'src/app/services/chat.service';
import { RfqService } from '../services/rfq.service';
import { Observable, of, Subscription, interval, fromEventPattern, pipe } from 'rxjs';
import { switchMap, finalize, first } from 'rxjs/operators';
import { QuoteFileUpload } from '../models/quoteFile';
import { ToastrNotificationService } from '../services/toastr-notification.service';
import { QuoteHeadings } from '../models/quoteHeadings';
import { FileDownloadedService } from '../services/file-downloaded.service';
import { ActivatedRoute, Router } from '@angular/router';
import { quoteReview } from '../models/quoteReview';
import { FullRfqReview } from '../models/completeRfq';

@Component({
  selector: 'app-completed-quotes',
  templateUrl: './completed-quotes.component.html',
  styleUrls: ['./completed-quotes.component.css']
})
export class CompletedQuotesComponent implements OnInit, OnDestroy, OnChanges {

  @Input() reloadClosedQuoteTab: boolean;

  @ViewChild('paymentReceivedBtn') paymentReceivedBtn: ElementRef;
  @ViewChild('paymentReceivedFinal') paymentReceivedFinal: ElementRef;
  @ViewChild('paymentReceivedClose') paymentReceivedClose: ElementRef;

  @Output() quoteStatus = new EventEmitter<boolean>();
  @Output() rfqState = new EventEmitter<string>();
  @Output() quoteState = new EventEmitter<string>();
  @Output() rfqPaymentRequirement = new EventEmitter<string>();

  @Input() closedQuoteId: number;
  @Input() state: string;
  @Input() closedQuoteNav: string;
  @Input() closedQuoteArchivedState: string;

  @Output() quoteArchivedState = new EventEmitter<string>();

  sendQuoteArchivedState(value: string) {
    this.quoteArchivedState.emit(value);
  }

  sendRfqStatus(value: boolean) {
    this.quoteStatus.emit(value);
  }

  sendRfqState(value: string) {
    this.rfqState.emit(value);
  }

  sendquoteState(value: string) {
    this.quoteState.emit(value);
  }

  sendrfqPaymentRequirement(value: string) {
    this.rfqPaymentRequirement.emit(value);
  }

  //NEEDED FOR GET QUESTIONS AND ANSWERS
  getQA: boolean = false;

  //NEEDED FOR CREATING Q&A QUESTIONS AND REPLIES
  isBuyer: boolean = false;
  isSupplier: boolean = true;
  supplierId: number;
  rfqStatusPC: string = "Closed";


  //USED IN TEMPLATE TO DISPLAY  MESSAGES RED BLIP 
  unreadMessages: boolean;

  //NEEDED TO CHECK UNREAD MESSAGES ARRAY
  UnreadMsgsCount: number;

  //NEEDED FOR SELECTED RFQ ON SIDEBAR 
  SideSelectedRFQ: View_QuotesRFQs;

  //GETS ROLE VALUES FROM VIEW QUOTE COMPONENT
  @Input() Role: string;

  //NEEDED FOR CHAT
  @Output() quoteEvent = new EventEmitter<number>();
  @Output() openChatCond = new EventEmitter<boolean>();
  @Input() openChat: boolean;

  @Output() changeClosedTab = new EventEmitter<boolean>();

  tabToInClosed(value: boolean) {
    this.changeClosedTab.emit(value);
  }

  modalheader: string;

  private subscriptionCQ: Subscription;

  // NEEDED FOR CHAT TO DISPLAY QUOTE ID AND CHAT DETAILS
  sentQID: number;
  chatHistory: View_Chat;
  details: ChatDetails;

  public criteria: SortCriteria;

  selectedRFQ: View_QuotesRFQs;
  selectedQuote: View_Quotes;

  RFQHeadings: string[] = [];
  sortbyHeadings: QuoteHeaddisplay[] = [];
  sortbyHeadingsUpdate: QuoteHeaddisplay[] = [];
  singleSortHeadings: QuoteHeaddisplay = { rfqHeadName: null, rfq: null, id: null };
  headingId: number;
  check: boolean = false;
  updatedClosed: ReloadViewRfqs;
  searchedKeyword: string;
  searchrfq: string;
  selectedRfqId: number;

  paymentConfirmRequest: RecievePaymentRequest = { QuoteId: null, RfqId: null };

  viewCompletedQuotes: QuoteHeadings[] = [];
  viewCompletedQuotesAll: QuoteHeadings[] = [];
  viewCompletedQuotesUpdate: QuoteHeadings[] = [];

  awaitPaymentQuotes: QuoteHeadings[] = [];
  archivedQuotes: QuoteHeadings[] = [];
  awaitPaymentQuotesUpdate: QuoteHeadings[] = [];
  archivedQuotesUpdate: QuoteHeadings[] = [];

  rfqUsed: boolean = false;

  quoteStatusForm: FormGroup;
  account: Account;
  dataLoading: boolean = false;

  reloadingSelectedRfq: boolean;
  loadingRfq: boolean = false;

  quoteReviewForm: FormGroup;



  constructor(private rfqService: RfqService,
    private formBuilder: FormBuilder, private quoteService: QuoteService,
    private reloader: ViewQuoteServiceService,
    public authService: AuthService,
    public cdr: ChangeDetectorRef,
    private chatService: ChatService,
    private toastrNotification: ToastrNotificationService,
    private router: Router,
    private fileDownloadedService: FileDownloadedService,
    private route: ActivatedRoute) {
    this.criteria = {
      property: 'supplierServices',
      descending: true
    };
    this.authService.account.subscribe(x => {
      this.account = x
      this.supplierId = this.account.account.supplierId;
    });
  }

  ngOnChanges() {

    if (this.state == "Closed" && ((this.viewCompletedQuotesAll.length == 0 || this.closedQuoteId == 0) || this.closedQuoteNav == 'true')) {
      this.getCompletedQuotes(this.closedQuoteId);

    } else if (this.state == "Closed" && this.viewCompletedQuotesAll.length > 0 && this.closedQuoteNav == 'false') {
      this.onSelectRFQ(this.closedQuoteId);
    }
  }

  ngOnInit(): void {

    this.reloadingSelectedRfq = false;

    this.reloaded = false;

    this.modalheader = 'blue';
    this.cdr.detectChanges();

    this.clearSortHeadings();

    this.quoteStatusForm = this.formBuilder.group({

      archived: [false,],
      awaitingPayment: [false,]

    });

    this.reloader.currentIPQuoteStay.subscribe(closedCond => {
      if (closedCond.reloadCondition) {
        this.reloadQuotesAfterComplete(closedCond.reloadRfqId);
      }
    });

    this.reloader.currentClosedQuotes.subscribe(closedCond => {
      if (closedCond.reloadCondition) {
        this.reloadQuotesAfterComplete(closedCond.reloadRfqId);
        this.tabToInClosed(true);
      } else {

      }
    });

    this.subscriptionCQ = interval(300000)
      .subscribe(x => { this.periodicReload(); });

    // this.route.queryParamMap.subscribe(params => {

    //   this.closedQuoteNav = params.get('nav');
  
    // });

    //SETS RFQ SEARCH FORM VALUES TO FALSE
    this.RFQsearch = this.formBuilder.group({

      complete: [false],
      rawmat: [false],
      cod: [false],
      cbd: [false],
      thirtyDays: [false],
      sixtyDays: [false],
      paymentOther: [false],
      delivery: [false],
      collection: [false],
      boxed: [false],
      pallets: [false],
      strapped: [false],
      loose: [false],
      packagingOther: [false],
      testCertificationYes: [false],
      testCertificationNo: [false]

    });


    this.quoteReviewForm = this.formBuilder.group({

      correctMaterialGradeYes: [false, Validators.required],
      correctMaterialGradeNo: [false, Validators.required],
      correctMaterialGradeReason: ["", Validators.required],

      correctQuantityYes: [false, Validators.required],
      correctQuantityNo: [false, Validators.required],
      correctQuantityReason: ["", Validators.required],

      buyerCommunicateYes: [null, Validators.required],
      buyerCommunicateNo: [null, Validators.required],
      buyerCommunicateReason: ["", Validators.required],

      buyerCollectionOnTimeYes: [null, Validators.required],
      buyerCollectionOnTimeNo: [null, Validators.required],
      buyerCollectionOnTimeReason: ["", Validators.required],

      actualDeliveryDate: [null, Validators.required],

    });

  }




  ngOnDestroy() {
    this.subscriptionCQ.unsubscribe();
  }



  clearSortHeadings() {

    this.headingId = 1;
    this.singleSortHeadings.rfqHeadName = "Multiple Services";
    this.singleSortHeadings.rfq = [];
    this.sortbyHeadings.push(this.singleSortHeadings);
    this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
    this.headingId++;
  }

  togglePeriodicReload(cond) {
    if (cond) {
      this.rfqUsed = true;

    } else if (!cond) {
      this.rfqUsed = false;

    }
  }

  togglePubChat(cond) {
    if (cond) {
      // this.rfqUsed = true;
      this.getQA = true;
    } else if (!cond) {
      //  this.rfqUsed = false;
      this.getQA = false;
    }
  }

  reloaded: boolean = false;

  periodicReload() {

    if (this.rfqUsed == false && this.reloadClosedQuoteTab) {
      this.sortbyHeadingsUpdate = [];
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

      this.headingId = 1;
      this.singleSortHeadings.rfqHeadName = "Multiple Services";
      this.singleSortHeadings.rfq = [];
      this.singleSortHeadings.id = this.headingId;
      this.sortbyHeadingsUpdate.push(this.singleSortHeadings);
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
      this.headingId++;

      this.getClosedQuotesAfterUpdate();
    }
  }

  getClosedQuotesAfterUpdate() {

    this.reloadingSelectedRfq = true;

    this.awaitPaymentQuotesUpdate = [];
    this.archivedQuotesUpdate = [];
    var archiveRfqIds: number[] = [];
    var archiveRfqIdsOld: number[] = [];
    var awaitPaymentRfqIds: number[] = [];
    var awaitPaymentRfqIdsOld: number[] = [];
    var rfqSelect: QuoteHeadings;

    this.quoteService.getQuoteHeadings(this.account.account.supplierId, 'Closed')
      .subscribe(rfqs => {

        if (rfqs.length > 0) {

          this.viewCompletedQuotesUpdate = rfqs
          this.reloaded = true;

          for (let rfq of rfqs) {
            rfq.SideSelectedRFQ = false;

            if (this.viewCompletedQuotesAll.length > 0) {
              if (rfq.rfqId == this.selectedRFQ.rfqId) {
                rfqSelect = rfq;
              }
            }

            if (rfq.quoteDetails.archived == true) {
              this.archivedQuotesUpdate.push(rfq);
              archiveRfqIds.push(rfq.rfqId);
            } else {
              this.awaitPaymentQuotesUpdate.push(rfq);
              awaitPaymentRfqIds.push(rfq.rfqId)
            }
          }

          if (this.viewCompletedQuotesAll.length > 0) {

            var oldRfqIds: number[] = [];
            for (let oldRfq of this.viewCompletedQuotesAll) {
              oldRfqIds.push(oldRfq.rfqId)
            }

            var newRfqIds: number[] = [];
            for (let newRfq of this.viewCompletedQuotesUpdate) {
              newRfqIds.push(newRfq.rfqId)
            }


            for (let rfq of this.viewCompletedQuotesUpdate) {
              if (oldRfqIds.indexOf(rfq.rfqId) == -1) {
                this.viewCompletedQuotesAll.push(rfq);
              }
            }

            for (const { index, value } of this.viewCompletedQuotesAll.map((value, index) => ({ index, value }))) {
              if (newRfqIds.indexOf(value.rfqId) == -1) {
                this.viewCompletedQuotesAll.splice(index, 1);
              }
            }




            if (this.archivedQuotes.length > 0 && this.archivedQuotesUpdate.length > 0) {
              for (let oldArchivedRfq of this.archivedQuotes) {
                archiveRfqIdsOld.push(oldArchivedRfq.rfqId);
              }

              for (let archiveRfq of this.archivedQuotesUpdate) {
                if (archiveRfqIdsOld.indexOf(archiveRfq.rfqId) == -1) {
                  this.archivedQuotes.push(archiveRfq);
                }
              }
            } else if (this.archivedQuotes.length == 0 && this.archivedQuotesUpdate.length > 0) {
              for (let archiveRfq of this.archivedQuotesUpdate) {
                this.archivedQuotes.push(archiveRfq);
              }
            } else if (this.archivedQuotesUpdate.length == 0) {
              this.archivedQuotes = [];
              this.g.archived.disable();
            }


            if (this.awaitPaymentQuotes.length > 0 && this.awaitPaymentQuotesUpdate.length > 0) {

              for (let oldAwaitPaymentRfq of this.awaitPaymentQuotes) {
                awaitPaymentRfqIdsOld.push(oldAwaitPaymentRfq.rfqId);
              }

              for (let rfq of this.awaitPaymentQuotesUpdate) {
                if (awaitPaymentRfqIdsOld.indexOf(rfq.rfqId) == -1) {
                  this.awaitPaymentQuotes.push(rfq);
                }
              }

              for (const { index, value } of this.awaitPaymentQuotes.map((value, index) => ({ index, value }))) {
                if (awaitPaymentRfqIds.indexOf(value.rfqId) == -1) {
                  this.awaitPaymentQuotes.splice(index, 1);
                }
              }

            } else if (this.awaitPaymentQuotes.length == 0 && this.awaitPaymentQuotesUpdate.length > 0) {

              for (let rfq of this.awaitPaymentQuotesUpdate) {
                this.awaitPaymentQuotes.push(rfq);
              }

            } else {
              this.awaitPaymentQuotes = [];
              this.g.awaitingPayment.disable();
            }



            if (!rfqSelect.quoteDetails.archived) {
              if (rfqSelect.quoteDetails.archived == this.selectedRFQ.quoteDetails.archived) {
                this.sortRFQHeadArrayUpdate(this.awaitPaymentQuotesUpdate);
              } else {
                this.awaitOrArchivedRfqs("await", rfqSelect.quoteDetails.quoteId);
                this.sortRFQHeadArrayUpdate(this.awaitPaymentQuotesUpdate);
              }
            } else {
              if (rfqSelect.quoteDetails.archived == this.selectedRFQ.quoteDetails.archived) {
                this.sortRFQHeadArrayUpdate(this.archivedQuotesUpdate);
              } else {
                this.awaitOrArchivedRfqs("archived", rfqSelect.quoteDetails.quoteId);
                this.sortRFQHeadArrayUpdate(this.archivedQuotesUpdate);
              }
            }


            this.replaceRfqsOnUpdate();
            this.onSelectRFQ(rfqSelect.quoteDetails.quoteId);

            //IF FILTERS ARE USED , RFQ SIDENAV UPDATE 
            if (this.filtersUsed == true) {
              this.filterUpdateRfqs(this.viewCompletedQuotesAll);
            }


          } else {

            this.viewCompletedQuotesAll = [];
            for (let rfq of this.viewCompletedQuotesUpdate) {
              this.viewCompletedQuotesAll.push(rfq);
            }

            this.archivedQuotes = [];
            this.awaitPaymentQuotes = [];
            this.viewCompletedQuotes = [];

            for (let rfq of this.viewCompletedQuotesAll) {

              //Sets selected rfq on sidebar to false
              rfq.SideSelectedRFQ = false;
              if (rfq.quoteDetails.archived == true) {
                this.archivedQuotes.push(rfq);
              } else {
                this.awaitPaymentQuotes.push(rfq);
              }
            }

            if (this.awaitPaymentQuotes.length > 0 && this.archivedQuotes.length > 0) {
              this.awaitOrArchivedRfqs('await', 0);
            } else if (this.awaitPaymentQuotes.length == 0 && this.archivedQuotes.length > 0) {
              this.awaitOrArchivedRfqs('archived', 0);
              this.g.awaitingPayment.disable();
            } else if (this.archivedQuotes.length == 0 && this.awaitPaymentQuotes.length > 0) {
              this.awaitOrArchivedRfqs('await', 0);
              this.g.archived.disable();
            }


            //NEEDED FOR CHAT
            this.sentQID = this.selectedQuote.quoteId;
            this.cdr.detectChanges();

          }

          if (this.awaitPaymentQuotesUpdate.length == 0 && this.archivedQuotesUpdate.length > 0) {
            this.g.awaitingPayment.disable();
          } else if (this.archivedQuotesUpdate.length == 0 && this.awaitPaymentQuotesUpdate.length > 0) {
            this.g.archived.disable();
          }
        } else {
          this.viewCompletedQuotesAll = [];
          this.viewCompletedQuotes = [];
          this.selectedQuote = null;
          this.selectedRFQ = null;
        }
      });

  }

  replaceRfqsOnUpdate() {

    var newHeadings: string[] = [];
    var oldHeadings: string[] = [];

    for (let heading of this.sortbyHeadings) {
      oldHeadings.push(heading.rfqHeadName)
      for (let newHead of this.sortbyHeadingsUpdate) {
        if (heading.rfqHeadName == newHead.rfqHeadName) {
          heading.rfq = newHead.rfq;
          heading.openQuotesCounter = newHead.openQuotesCounter;;
        }
      }
    }

    for (let newHeading of this.sortbyHeadingsUpdate) {
      newHeadings.push(newHeading.rfqHeadName);
    }

    //if (this.sortbyHeadings.length != this.sortbyHeadingsUpdate.length) {
    //if (this.sortbyHeadings.length < this.sortbyHeadingsUpdate.length) {

    for (let newHead of this.sortbyHeadingsUpdate) {
      if (oldHeadings.indexOf(newHead.rfqHeadName) == -1) {
        this.sortbyHeadings.push(newHead);
      }
    }

    //} else {
    for (const { index, value } of this.sortbyHeadings.map((value, index) => ({ index, value }))) {
      if (newHeadings.indexOf(value.rfqHeadName) == -1) {
        this.sortbyHeadings.splice(index, 1);
      }
    }

    //  }

    //  }

  }

  //Used for periodic updating of RFQS
  sortRFQHeadArrayUpdate(rfqs) {

    for (let indexrfq of rfqs) {
      for (let service of indexrfq.rfqServicesRequired) {
        this.check = false;
        if (indexrfq.rfqServicesRequired.length == 1) {
          for (let name of this.sortbyHeadingsUpdate) {
            if (service == name.rfqHeadName) {
              this.check = true;
            }
          }
          if (this.check == false) {

            this.singleSortHeadings.rfqHeadName = service;
            this.singleSortHeadings.rfq = [];
            this.singleSortHeadings.id = this.headingId;
            this.sortbyHeadingsUpdate.push(this.singleSortHeadings);
            this.headingId++;
            this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
          }
        }
      }
      if (indexrfq.rfqServicesRequired.length > 1) {
        this.sortbyHeadingsUpdate[0].rfq.push(indexrfq);

      }

    }

    this.populateRFQHeadArrayUpdate(rfqs);

    this.getClosingTime(Date);
  }


  populateRFQHeadArrayUpdate(rfqs): void {
    for (let indexrfq of rfqs) {
      for (let service of indexrfq.rfqServicesRequired) {
        if (indexrfq.rfqServicesRequired.length == 1) {
          for (let val of this.sortbyHeadingsUpdate) {
            if (val.rfqHeadName == service) {
              val.rfq.push(indexrfq)

            }
          }
        }

      }
    }
    if (this.sortbyHeadingsUpdate[0].rfq.length == 0) {
      this.sortbyHeadingsUpdate.splice(0, 1);
    }
    // LOGIC FOR SUPPLIER USER ACTIONS 
    for (let heading of this.sortbyHeadingsUpdate) {
      for (let rfq of heading.rfq) {
        heading.openQuotesCounter = 0;

        if ((rfq.quoteDetails.invoiceQuoteFileId == null) ||
          (rfq.quoteDetails.invoiceQuoteFileId != null && rfq.quoteDetails.paymentRefecence != null && rfq.quoteDetails.paymentReceived == false) ||
          (rfq.productCertificationRequirements == true && rfq.quoteDetails.paymentReceived && rfq.quoteDetails.testCertificationFileId == null)) {
          heading.openQuotesCounter = 1;
          break;
        }
      }
    }
    this.cdr.detectChanges();
  }

  reloadQuotesAfterComplete(rfqId) {

    this.viewCompletedQuotes = [];
    this.archivedQuotes = [];
    this.awaitPaymentQuotes = [];
    this.sortbyHeadings = [];
    this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

    this.clearSortHeadings();

    this.reloadGetCompletedQuotes(rfqId);


  }
  reloadGetCompletedQuotes(rfqId) {

    this.dataLoading = true;

    this.quoteService.getQuoteHeadings(this.account.account.supplierId, 'Closed').pipe(
      finalize(() => this.dataLoading = false)
    ).subscribe(viewquotes => {

      this.finalInvoiceUploading = false;

      this.testCertirficateUploading = false;

      this.viewCompletedQuotesAll = viewquotes;

      for (const { index, value } of viewquotes.map((value, index) => ({ index, value }))) {
        if (value.rfqId == rfqId) {
          if (value.quoteDetails.archived == false) {
            this.g.awaitingPayment.setValue(true);
            this.g.archived.setValue(false);
            for (let rfq of viewquotes) {
              if (rfq.quoteDetails.archived == true) {
                this.archivedQuotes.push(rfq);
              } else {
                this.awaitPaymentQuotes.push(rfq);
                this.viewCompletedQuotes.push(rfq);
              }
            }
            this.onSelectRFQ(viewquotes[index].quoteDetails.quoteId);
          } else {
            this.g.awaitingPayment.setValue(false);
            this.g.archived.setValue(true);
            for (let rfq of viewquotes) {
              if (rfq.quoteDetails.archived == true) {
                this.archivedQuotes.push(rfq);
                this.viewCompletedQuotes.push(rfq);
              } else {
                this.awaitPaymentQuotes.push(rfq);
              }
            }
            this.onSelectRFQ(viewquotes[index].quoteDetails.quoteId);
          }
        }
      }
      this.sortByHeadings();


      if (this.awaitPaymentQuotes.length == 0 && this.archivedQuotes.length > 0) {
        this.g.awaitingPayment.disable();
      } else if (this.archivedQuotes.length == 0 && this.awaitPaymentQuotes.length > 0) {
        this.g.archived.disable();
      }

    })
  }

  reloadQuotesAfterPaymentConfirmed() {

    this.viewCompletedQuotes = [];
    this.archivedQuotes = [];
    this.awaitPaymentQuotes = [];
    this.sortbyHeadings = [];
    this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

    this.clearSortHeadings();

    this.reloadGetCompletedQuotesAfterArchived(this.selectedRfqId);
  }

  reloadGetCompletedQuotesAfterArchived(rfqId) {

    this.dataLoading = true;

    this.quoteService.getQuoteHeadings(this.account.account.supplierId, 'Closed').pipe(
      finalize(() => this.dataLoading = false)
    ).subscribe(viewquotes => {

      var quote: QuoteHeadings;

      for (const { index, value } of viewquotes.map((value, index) => ({ index, value }))) {
        if (value.rfqId == rfqId) {
          quote = viewquotes[index];
          this.onSelectRFQ(viewquotes[index].quoteDetails.quoteId);
          // this.router.navigate(['supplier-home/view-quote', 'Closed', this.sortbyHeadings[0].rfq[0].quoteDetails.quoteId], {
          //   queryParams: { archived: this.sortbyHeadings[0].rfq[0].quoteDetails.archived + "" }
          // });
        }
      }

      for (let rfq of viewquotes) {
        if (rfq.quoteDetails.archived == true) {
          this.archivedQuotes.push(rfq);
          this.viewCompletedQuotes.push(rfq);
        } else {
          this.awaitPaymentQuotes.push(rfq);
        }
      }



      if (this.awaitPaymentQuotes.length == 0 && this.archivedQuotes.length > 0) {
        this.g.awaitingPayment.disable();
        this.g.awaitingPayment.setValue(false);
        this.g.archived.enable();
        this.g.archived.setValue(true);
      } else if (this.archivedQuotes.length == 0 && this.awaitPaymentQuotes.length > 0) {
        this.g.archived.disable();
        this.g.archived.setValue(false);
        this.g.awaitingPayment.enable();
        this.g.awaitingPayment.setValue(true);
      }

      this.g.awaitingPayment.setValue(false);
      this.g.archived.setValue(true);
      this.sortByHeadings();
    })


  }

  getCompletedQuotes(selectedQuoteId) {

    this.archivedQuotes = [];
    this.awaitPaymentQuotes = [];
    this.viewCompletedQuotesAll = [];
    this.sortbyHeadings = [];
    this.selectedRFQ = null;
    this.selectedQuote = null;

    this.dataLoading = true;
    this.quoteService.getQuoteHeadings(this.account.account.supplierId, 'Closed').pipe(
      finalize(() => this.dataLoading = false)
    ).subscribe(viewquotes => {

      if (viewquotes.length > 0) {

        this.viewCompletedQuotesAll = viewquotes;

        for (let rfq of viewquotes) {

          //Sets selected rfq on sidebar to false
          rfq.SideSelectedRFQ = false;
          if (rfq.quoteDetails.archived == true) {
            this.archivedQuotes.push(rfq);
          } else {
            this.awaitPaymentQuotes.push(rfq);
          }
        }

        if (selectedQuoteId == 0) {

          if (this.closedQuoteArchivedState != 'false' && this.closedQuoteArchivedState != 'true' && this.closedQuoteArchivedState != '') {
            this.router.navigate(['404']);
          } else {

            if (this.awaitPaymentQuotes.length > 0 && this.archivedQuotes.length > 0) {
              this.awaitOrArchivedRfqs('await', selectedQuoteId);
              this.g.awaitingPayment.enable();
              this.g.archived.enable();
            } else if (this.awaitPaymentQuotes.length == 0 && this.archivedQuotes.length > 0) {
              this.awaitOrArchivedRfqs('archived', selectedQuoteId);
              this.g.awaitingPayment.disable();
              this.g.archived.enable();
            } else if (this.archivedQuotes.length == 0 && this.awaitPaymentQuotes.length > 0) {
              this.awaitOrArchivedRfqs('await', selectedQuoteId);
              this.g.archived.disable();
              this.g.awaitingPayment.enable();
            }
          }

        } else {

          if (this.closedQuoteArchivedState != 'false' && this.closedQuoteArchivedState != 'true') {
            this.router.navigate(['404']);
          } else {

            if (this.closedQuoteArchivedState == 'true') {
              this.awaitOrArchivedRfqs('archived', selectedQuoteId);
              if (this.awaitPaymentQuotes.length == 0) {
                this.g.awaitingPayment.disable();
              } else {
                this.g.awaitingPayment.enable();
              }
            } else if (this.closedQuoteArchivedState == 'false') {
              this.awaitOrArchivedRfqs('await', selectedQuoteId);
              if (this.archivedQuotes.length == 0) {
                this.g.archived.disable();
              } else {
                this.g.archived.enable();
              }
            }
          }

        }

        //NEEDED FOR CHAT
        // this.sentQID = this.selectedQuote.quoteId;
        // this.cdr.detectChanges();
      }
    });
  }

  closedrfqstate: string = '';

  awaitOrArchivedRfqs(type, quoteId) {
    this.viewCompletedQuotes = [];
    if (type == 'await') {
      this.closedrfqstate = 'await'
      this.g.awaitingPayment.setValue(true);
      this.g.archived.setValue(false);
      for (let rfq of this.awaitPaymentQuotes) {
        this.viewCompletedQuotes.push(rfq);
      }
    } else if (type == 'archived') {
      this.closedrfqstate = 'archived'
      this.g.awaitingPayment.setValue(false);
      this.g.archived.setValue(true);

      for (let rfq of this.archivedQuotes) {
        this.viewCompletedQuotes.push(rfq);
      }
    }

    this.sortbyHeadings = [];
    this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
    this.clearSortHeadings();

    this.sortByHeadings();

    if (quoteId == 0) {
      this.router.navigate(['supplier-home/view-quote', 'Closed', this.sortbyHeadings[0].rfq[0].quoteDetails.quoteId, 'false'], {
        queryParams: { archived: this.sortbyHeadings[0].rfq[0].quoteDetails.archived + "" }
      });
    } else {
      this.onSelectRFQ(quoteId);
    }
  }

  switchAwaitArchive(type) {
    this.viewCompletedQuotes = [];
    if (type == 'await') {
      this.closedrfqstate = 'await'
      this.g.awaitingPayment.setValue(true);
      this.g.archived.setValue(false);
      if (this.archivedQuotes.length == 0) {
        this.g.archived.disable();
      } else {
        this.g.archived.enable();
      }
      for (let rfq of this.awaitPaymentQuotes) {
        this.viewCompletedQuotes.push(rfq);
      }
    } else if (type == 'archived') {
      this.closedrfqstate = 'archived'
      this.g.awaitingPayment.setValue(false);
      this.g.archived.setValue(true);
      if (this.awaitPaymentQuotes.length == 0) {
        this.g.awaitingPayment.disable();
      } else {
        this.g.awaitingPayment.enable();
      }
      for (let rfq of this.archivedQuotes) {
        this.viewCompletedQuotes.push(rfq);
      }
    }

    this.sortbyHeadings = [];
    this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
    this.clearSortHeadings();

    this.sortByHeadings();
  }

  sortByHeadings(): void {

    for (let indexrfq of this.viewCompletedQuotes) {
      for (let service of indexrfq.rfqServicesRequired) {
        this.check = false;
        if (indexrfq.rfqServicesRequired.length == 1) {
          for (let name of this.sortbyHeadings) {
            if (service == name.rfqHeadName) {
              this.check = true;
            }
          }
          if (this.check == false) {

            this.singleSortHeadings.rfqHeadName = service;
            this.singleSortHeadings.rfq = [];
            this.singleSortHeadings.id = this.headingId;
            this.sortbyHeadings.push(this.singleSortHeadings);
            this.headingId++;
            this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
          }
        }
      }
      if (indexrfq.rfqServicesRequired.length > 1) {
        this.sortbyHeadings[0].rfq.push(indexrfq);

      }
    }

    this.populateRFQHeadArray();

  }
  populateRFQHeadArray(): void {
    for (let indexrfq of this.viewCompletedQuotes) {
      for (let service of indexrfq.rfqServicesRequired) {
        if (indexrfq.rfqServicesRequired.length == 1) {
          for (let val of this.sortbyHeadings) {
            if (val.rfqHeadName == service) {
              val.rfq.push(indexrfq)

            }
          }
        }

      }
    }
    if (this.sortbyHeadings[0].rfq.length == 0) {
      this.sortbyHeadings.splice(0, 1);
    }

    // LOGIC FOR SUPPLIER USER ACTIONS 
    for (let heading of this.sortbyHeadings) {
      for (let rfq of heading.rfq) {
        heading.openQuotesCounter = 0;

        if ((rfq.quoteDetails.invoiceQuoteFileId == null) ||
          (rfq.quoteDetails.invoiceQuoteFileId != null && rfq.quoteDetails.paymentRefecence != null && rfq.quoteDetails.paymentReceived == false) ||
          (rfq.productCertificationRequirements == true && rfq.quoteDetails.paymentReceived && rfq.quoteDetails.testCertificationFileId == null)) {
          heading.openQuotesCounter = 1;
          break;
        }
      }
    }

     // sort Rfq Heading in Asc order using String.prototype.localCompare()
     this.sortbyHeadings.sort((a, b) =>
     a.rfqHeadName.localeCompare(b.rfqHeadName)
   );

   // Re-inserts Multiple Services heading to first position in array
   var index = this.sortbyHeadings.findIndex(x => x.rfqHeadName == "Multiple Services");
   if (index != -1) {
     var head = this.sortbyHeadings.filter(x => x.rfqHeadName == "Multiple Services")[0];
     this.sortbyHeadings.splice(index, 1);
     this.sortbyHeadings.splice(0, 0, head);
   }

  }

  triggerSelectRfqLoading() {
    this.reloadingSelectedRfq = false;
  }

  selectedRfqHeading: QuoteHeadings;

  loadingRfqSpinnerId: number;

  onSelectRFQNav(quote: QuoteHeadings): void {
    this.loadingRfqSpinnerId = quote.rfqId;
    this.router.navigate(['supplier-home/view-quote', 'Closed', quote.quoteDetails.quoteId, 'false'], {
      queryParams: { archived: quote.quoteDetails.archived }
    });
  }

  onSelectRFQ(quoteId: number): void {

    if (!this.reloadingSelectedRfq) {
      this.loadingRfq = true;
    }

    if (this.viewCompletedQuotesAll.length > 0) {
      this.quoteService.getQuoteById(quoteId).pipe(
        finalize(() => this.loadingRfq = false)
      ).subscribe({
        next: (response: any) => {
          if (response.quoteDetails.state == "Closed") {

            if ((response.quoteDetails.archived && this.closedQuoteArchivedState == "false")) {
              this.switchAwaitArchive('archived');
              this.router.navigate(['supplier-home/view-quote', 'Closed', response.quoteDetails.quoteId, 'false'], {
                queryParams: { archived: response.quoteDetails.archived + "" }
              });
            } else if ((!response.quoteDetails.archived && this.closedQuoteArchivedState == "true")) {
              this.switchAwaitArchive('await');
              this.router.navigate(['supplier-home/view-quote', 'Closed', response.quoteDetails.quoteId, 'false'], {
                queryParams: { archived: response.quoteDetails.archived + "" }
              });
            } else if (this.closedQuoteNav == 'true') {
              this.router.navigate(['supplier-home/view-quote', 'Closed', response.quoteDetails.quoteId, 'false'], {
                queryParams: { archived: response.quoteDetails.archived + "" }
              });
            }

            if (response.purchaseOrderFileId != null) {
              this.fileDownloadedService.getFileDownloadedCheck(response.purchaseOrderFileId, this.account.account.userId).subscribe(check => {
                this.selectedRFQ.purchaseOrderDownloaded = check;
              });
            }

            this.selectedRfqHeading = this.viewCompletedQuotesAll.filter(x => x.quoteDetails.quoteId == quoteId)[0];
            this.selectedRfqId = this.selectedRfqHeading.rfqId;

            // GETS RFQ ID
            this.selectedRFQ = response;
            this.selectedQuote = this.selectedRFQ.quoteDetails;
            this.sentQID = this.selectedQuote.quoteId;
            this.loadingRfq = false;

            this.sendQuoteArchivedState(this.selectedRFQ.quoteDetails.archived + "");

            //NEEDED FOR CHAT
            // this.getChatHistory(this.selectedRfqHeading.quoteDetails.quoteId, this.Role);

            for (let rfq of this.viewCompletedQuotes) {
              if (rfq.rfqId == this.selectedRfqId) {
                rfq.SideSelectedRFQ = true;
              }
              else {
                rfq.SideSelectedRFQ = false;
              }

            }

            for (let rfq of this.viewCompletedQuotesUpdate) {
              if (rfq.rfqId == this.selectedRfqId) {
                rfq.SideSelectedRFQ = true;
              }
              else {
                rfq.SideSelectedRFQ = false;
              }

            }

            //SETS COUNT FOR UNREAD MESSAGES ARRAY
            //CHECKS IF CHAT HISTORY ARRAY EXISTS

            if (this.selectedRfqHeading.quoteDetails.unreadPrivateChatHistory) {
              this.UnreadMsgsCount = this.selectedRfqHeading.quoteDetails.unreadPrivateChatHistory.length;
            }


            //CHECKS FOR UNREAD MESSAGES NEEDED FOR RED BLIP
            if (this.selectedRfqHeading.quoteDetails.unreadPrivateMessages == true) {
              this.unreadMessages = true;

            }
            if (this.selectedRfqHeading.quoteDetails.unreadPrivateMessages == false) {
              this.unreadMessages = false;
            }


          } else {
            this.router.navigate(['supplier-home/view-quote', 'Closed', 0, 'false'], {
              queryParams: { archived: null }
            });
            this.toastrNotification.error("Invalid Selected Quote")
          }

        },
        error: error => {

          this.router.navigate(['supplier-home/view-quote', 'Closed', this.sortbyHeadings[0].rfq[0].quoteDetails.quoteId, 'false'], {
            queryParams: { archived: this.sortbyHeadings[0].rfq[0].quoteDetails.archived + "" }
          });
          this.toastrNotification.error("Invalid Selected Quote")
          this.loadingRfq = false
        }

      });
    }

  }

  paymentMadeCheck() {
    if (this.selectedRFQ.quoteDetails.paymentReference != null) {
      return false;
    } else {
      return true;
    }
  }

  confirmingPayment: boolean = false;

  confirmPayment() {

    // this.paymentReceivedBtn.nativeElement.click();

    this.confirmingPayment = true;

    this.paymentConfirmRequest = { QuoteId: null, RfqId: null };

    this.paymentConfirmRequest.QuoteId = this.selectedRFQ.quoteDetails.quoteId;
    this.paymentConfirmRequest.RfqId = this.selectedRFQ.rfqId;

    this.quoteService.paymentConfirmed(this.paymentConfirmRequest).pipe(first())
      .subscribe({
        next: (response: any) => {
          this.paymentReceivedBtn.nativeElement.click();
          this.confirmingPayment = false;
        },
        error: error => {
          this.toastrNotification.error(" Payment Received Failed");
          this.confirmingPayment = false;
        }

      });
  }

  confirmPaymentCheck(rfqId, quoteId, cond) {

    this.togglePeriodicReload(true);

    this.quoteService.PaymentReceivedCheck(quoteId).subscribe(x => {
      if (!x) {

        if (cond == "Initial") {
          this.confirmPayment();
        } else if (cond == "Final") {
          this.paymentReceivedFinal.nativeElement.click();
        }

      } else {

        this.toastrNotification.info("Payment for Rfq " + this.selectedRFQ.rfqName + " has already been received");

        this.togglePeriodicReload(false);

        if (cond == "Initial") {
          this.reloadQuotesAfterPaymentConfirmed();
        } else if (cond == "Final") {
          this.paymentReceivedClose.nativeElement.click();
          this.reloadQuotesAfterPaymentConfirmed();
        }

      }
    });

  }


  //Date Calcs
  public dateNow = new Date();
  public dDay?: Date;
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  private getTimeDifference() {
    this.timeDifference = this.dDay.getTime() - new Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
  }

  paymentDay: Date

  getPayDay(date) {

    this.paymentDay = new Date(date);

    if (this.selectedRFQ.paymentPreference == "30 Days") {
      this.paymentDay.setDate(this.paymentDay.getDate() + 30);
    } else if (this.selectedRFQ.paymentPreference == "60 Days") {
      this.paymentDay.setDate(this.paymentDay.getDate() + 60);
    }

    return this.paymentDay;

  }

  public getClosingTime(date) {

    this.dDay = new Date(date);

    if (this.selectedRFQ.paymentPreference == "30 Days") {
      this.dDay.setDate(this.dDay.getDate() + 30);
    } else if (this.selectedRFQ.paymentPreference == "60 Days") {
      this.dDay.setDate(this.dDay.getDate() + 60);
    }


    this.getTimeDifference();
    if (this.daysToDday < 0) {
      return 'Closed';
    } else {
      return this.daysToDday + ' Days ' + this.hoursToDday + ' Hrs ' + this.minutesToDday + ' Mins';
    }

  }

  private allocateTimeUnits(timeDifference) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }

  get g() { return this.quoteStatusForm.controls; }

  downloadQuoteFiles(quoteId) {
    this.rfqService.getQuoteFile(quoteId, this.account.account.userId).subscribe(x => {
      var FileContent = x[0].fileContent;
      var FileName = x[0].fileName;
      this.downloadFile(FileContent, FileName);
    });

  }
  downloadDrawingFile(rfqFileId) {
    this.rfqService.getRfqDrawingFile(rfqFileId).subscribe(x => {
      this.downloadFile(x.fileContent, x.fileName)
    })
  }

  downloadPurchaseOrderFile(rfqFileId) {
    this.rfqService.getPurchaseOrderFile(rfqFileId, this.account.account.userId, "Supplier").subscribe(x => {

      this.selectedRFQ.purchaseOrderDownloaded = true;
      this.downloadFile(x.fileContent, x.fileName)
    })
  }

  @ViewChild('fileUpload412') fileUpload412: ElementRef;

  downloadedPurchaseOrderCheck(purchaseOrderFileId) {
    this.fileDownloadedService.getFileDownloadedCheck(purchaseOrderFileId, this.account.account.userId).subscribe(check => {
      if (check) {
        this.fileUpload412.nativeElement.click();
        this.selectedRFQ.purchaseOrderDownloaded = check;
      } else {
        this.toastrNotification.info("Download And Confirm Purchase Order File Before Uploading Final Invoice File");
      }

    });
  }

  downloadSalesOrderFile(quoteFileId) {
    this.quoteService.getSalesOrderFile(quoteFileId).subscribe(file => {
      this.downloadFile(file.fileContent, file.fileName);
    })
  }

  downloadInvoiceFile(quoteFileId, quoteId) {
    this.quoteService.getInvoiceFile(quoteFileId).subscribe({
      next: (response: any) => {
        this.downloadFile(response.fileContent, response.fileName);
        // this.downloadingQuoteFile = false;
      },
      error: error => {
        this.toastrNotification.info("Final Invoice File out of date, new Sales Order File loaded. Please retry download")
        this.quoteService.getQuoteFileDetails(quoteId, "Invoices").subscribe(x => {
          this.selectedRFQ.quoteDetails.invoiceQuoteFileId = x.quoteFileId;
          this.selectedRFQ.quoteDetails.invoiceOrderFileName = x.quoteFileName;
          // this.downloadingQuoteFile = false;
        });
      }
    })
  }

  triggerReloadRfqs(value: boolean) {
    if (value) {

      this.viewCompletedQuotes = [];
      this.archivedQuotes = [];
      this.awaitPaymentQuotes = [];
      this.sortbyHeadings = [];
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

      this.headingId = 1;
      this.singleSortHeadings.rfqHeadName = "Multiple Services";
      this.singleSortHeadings.rfq = [];
      this.sortbyHeadings.push(this.singleSortHeadings);
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
      this.headingId++;

      this.reloadGetCompletedQuotes(this.selectedRFQ.rfqId);
    }

  }

  requiredFileType: string = ".pdf";
  InvoiceFile: File;
  productCertificationFile: File;
  invoiceFileName = '';
  productCertificationFileName = '';
  InvoiceUpload: QuoteFileUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
  productCertificationUpload: QuoteFileUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
  finalInvoiceUploading: boolean = false;
  finalInvoiceDeleting: boolean = false;
  testCertirficateUploading: boolean = false;
  testCetificateDeleting: boolean = false;
  quoteFileUploadComplete: boolean;

  @ViewChild('clearInputInv', { static: false }) clearInvInputVar: ElementRef;

  @ViewChild('clearTCInput', { static: false }) clearTCInputVar: ElementRef;

  resetInvInput() {
    this.clearInvInputVar.nativeElement.value = "";
  }

  InvoiceFileSelected(event) {

    this.InvoiceUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };

    //this.quoteFileUploadComplete = false;
    this.InvoiceFile = event.target.files[0];
    const reader = new FileReader();

    if (this.InvoiceFile) {

      this.invoiceFileName = this.InvoiceFile.name;

      if (this.InvoiceFile.name != this.selectedRFQ.quoteDetails.salesOrderFileName
        && this.InvoiceFile.name != this.selectedRFQ.quoteDetails.quoteFileName
        && this.InvoiceFile.name != this.selectedRFQ.quoteDetails.productCertificationFileName) {

        this.finalInvoiceUploading = true;

        reader.readAsDataURL(this.InvoiceFile);
        reader.onload = () => {
          this.InvoiceUpload.file = (reader.result + "").substring((reader.result + "").indexOf(",") + 1, (reader.result + "").length);
          this.InvoiceUpload.quoteId = this.selectedQuote.quoteId;
          this.InvoiceUpload.rfqId = this.selectedRFQ.rfqId;
          this.InvoiceUpload.buyerId = null;
          this.InvoiceUpload.supplierId = this.account.account.supplierId;
          this.InvoiceUpload.userId = this.account.account.userId;
          this.InvoiceUpload.fileName = this.InvoiceFile.name;
          this.quoteService.uploadInvoiceFile(this.InvoiceUpload).pipe(
            finalize(() => this.finalInvoiceUploading = true)
          ).subscribe(x => {
            if (this.selectedRFQ.quoteDetails.invoiceQuoteFileId != null) {
              this.finalInvoiceDeleting = true;
              this.quoteService.deleteInvoiceFile(this.selectedQuote.invoiceQuoteFileId).pipe(
                finalize(() => this.finalInvoiceDeleting = true)
              ).subscribe(y => {
                this.quoteService.getQuoteFileDetails(this.selectedRFQ.quoteDetails.quoteId, "Invoices").pipe(first())
                  .subscribe({
                    next: (response: any) => {
                      this.selectedRFQ.quoteDetails.invoiceQuoteFileId = response.quoteFileId;
                      this.selectedRFQ.quoteDetails.invoiceOrderFileName = response.quoteFileName;

                      for (let rfqHeading of this.sortbyHeadings) {
                        for (let rfq of rfqHeading.rfq) {
                          if (rfq.rfqId == this.selectedRFQ.rfqId) {
                            rfq.quoteDetails.invoiceQuoteFileId = response.quoteFileId;
                            rfq.quoteDetails.invoiceOrderFileName = response.quoteFileName;
                          }
                        }
                      }
                      this.finalInvoiceUploading = false;
                      this.finalInvoiceDeleting = false;

                      // LOGIC FOR SUPPLIER USER ACTIONS 
                      for (let heading of this.sortbyHeadings) {
                        for (let rfq of heading.rfq) {
                          heading.openQuotesCounter = 0;

                          if ((rfq.quoteDetails.invoiceQuoteFileId == null) ||
                            (rfq.quoteDetails.invoiceQuoteFileId != null && rfq.quoteDetails.paymentRefecence != null && rfq.quoteDetails.paymentReceived == false) ||
                            (rfq.productCertificationRequirements == true && rfq.quoteDetails.paymentReceived && rfq.quoteDetails.testCertificationFileId == null)) {
                            heading.openQuotesCounter = 1;
                            break;
                          }
                        }
                      }
                    },
                    error: error => {
                      this.toastrNotification.error("Get Quote Details Failed");
                    }
                  });
              });
            } else {
              this.quoteService.getQuoteFileDetails(this.selectedRFQ.quoteDetails.quoteId, "Invoices").pipe(first())
                .subscribe({
                  next: (response: any) => {
                    this.selectedRFQ.quoteDetails.invoiceQuoteFileId = response.quoteFileId;
                    this.selectedRFQ.quoteDetails.invoiceOrderFileName = response.quoteFileName;

                    for (let rfqHeading of this.sortbyHeadings) {
                      for (let rfq of rfqHeading.rfq) {
                        if (rfq.rfqId == this.selectedRFQ.rfqId) {
                          rfq.quoteDetails.invoiceQuoteFileId = response.quoteFileId;
                          rfq.quoteDetails.invoiceOrderFileName = response.quoteFileName;
                        }
                      }
                    }
                    this.finalInvoiceUploading = false;
                    this.finalInvoiceDeleting = false;

                    // LOGIC FOR SUPPLIER USER ACTIONS 
                    for (let heading of this.sortbyHeadings) {
                      for (let rfq of heading.rfq) {
                        heading.openQuotesCounter = 0;

                        if ((rfq.quoteDetails.invoiceQuoteFileId == null) ||
                          (rfq.quoteDetails.invoiceQuoteFileId != null && rfq.quoteDetails.paymentRefecence != null && rfq.quoteDetails.paymentReceived == false) ||
                          (rfq.productCertificationRequirements == true && rfq.quoteDetails.paymentReceived && rfq.quoteDetails.testCertificationFileId == null)) {
                          heading.openQuotesCounter = 1;
                          break;
                        }
                      }
                    }
                  },
                  error: error => {
                    this.toastrNotification.error("Get Quote Details Failed");
                  }
                });
            }
            this.clearInvInputVar.nativeElement.value = ""

          });
        };

      } else {
        if (this.InvoiceFile.name == this.selectedRFQ.quoteDetails.salesOrderFileName) {
          this.toastrNotification.error("File has previously been uploaded as Sales Order");
        } else if (this.InvoiceFile.name == this.selectedRFQ.quoteDetails.quoteFileName) {
          this.toastrNotification.error("File has previously been uploaded as Quote ");
        } else if (this.InvoiceFile.name == this.selectedRFQ.quoteDetails.productCertificationFileName) {
          this.toastrNotification.error("File has previously been uploaded as Test Certification ");
        }

        this.clearInvInputVar.nativeElement.value = ""
      }

    }

  }


  downloadProductCertificationFile(quoteFileId, quoteId) {
 
    this.quoteService.getProductCertificationFile(quoteFileId).subscribe({
      next: (response: any) => {
        this.downloadFile(response.fileContent, response.fileName);
        // this.downloadingQuoteFile = false;
      },
      error: error => {
        this.toastrNotification.info("Test Certification File out of date, new Test Certification File loaded. Please retry download")
        this.quoteService.getQuoteFileDetails(quoteId, "ProductCertification").subscribe(x => {
          this.selectedRFQ.quoteDetails.productCertificationQuoteFileId = x.quoteFileId;
          this.selectedRFQ.quoteDetails.productCertificationFileName = x.quoteFileName;
          // this.downloadingQuoteFile = false;
        });
      }
    })
  }

  TestCertificateSelected(event) {



    this.productCertificationUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };

    this.quoteFileUploadComplete = false;
    this.productCertificationFile = event.target.files[0];
    const reader = new FileReader();

    if (this.productCertificationFile) {

      this.productCertificationFileName = this.productCertificationFile.name;

      if (this.productCertificationFile.name != this.selectedRFQ.quoteDetails.salesOrderFileName
        && this.productCertificationFile.name != this.selectedRFQ.quoteDetails.quoteFileName
        && this.productCertificationFile.name != this.selectedRFQ.quoteDetails.invoiceOrderFileName) {

        this.testCertirficateUploading = true;

        reader.readAsDataURL(this.productCertificationFile);
        reader.onload = () => {
         
          this.productCertificationUpload.file = (reader.result + "").substring((reader.result + "").indexOf(",") + 1, (reader.result + "").length);
         
          this.productCertificationUpload.quoteId = this.selectedQuote.quoteId;
          this.productCertificationUpload.rfqId = this.selectedRFQ.rfqId;
          this.productCertificationUpload.buyerId = null;
          this.productCertificationUpload.supplierId = this.account.account.supplierId;
          this.productCertificationUpload.userId = this.account.account.userId;
          this.productCertificationUpload.fileName = this.productCertificationFile.name;
          this.quoteService.uploadTestCertificateFile(this.productCertificationUpload).pipe(
            finalize(() => this.testCertirficateUploading = true)).subscribe(x => {
             
              if (this.selectedRFQ.quoteDetails.productCertificationQuoteFileId != null) {
                this.testCetificateDeleting = true;
                this.quoteService.deleteProductCertificationFile(this.selectedQuote.productCertificationQuoteFileId).pipe(
                  finalize(() => this.testCetificateDeleting = true)).subscribe(y => {
                    this.quoteService.getQuoteFileDetails(this.selectedRFQ.quoteDetails.quoteId, "ProductCertification").pipe(first())
                      .subscribe({
                        next: (response: any) => {
                          this.selectedRFQ.quoteDetails.productCertificationQuoteFileId = response.quoteFileId;
                          this.selectedRFQ.quoteDetails.productCertificationFileName = response.quoteFileName;

                          // for (let rfqHeading of this.sortbyHeadings) {
                          //   for (let rfq of rfqHeading.rfq) {
                          //     if (rfq.rfqId == this.selectedRFQ.rfqId) {
                          //       rfq.quoteDetails.productCertificationQuoteFileId = response.quoteFileId;
                          //       rfq.quoteDetails.productCertificationFileName = response.quoteFileName;
                          //     }
                          //   }
                          // }
                          this.testCertirficateUploading = false;
                          this.testCetificateDeleting = false;

                          // LOGIC FOR SUPPLIER USER ACTIONS 
                          for (let heading of this.sortbyHeadings) {
                            for (let rfq of heading.rfq) {
                              heading.openQuotesCounter = 0;

                              if ((rfq.quoteDetails.invoiceQuoteFileId == null) ||
                                (rfq.quoteDetails.invoiceQuoteFileId != null && rfq.quoteDetails.paymentRefecence != null && rfq.quoteDetails.paymentReceived == false) ||
                                (rfq.productCertificationRequirements == true && rfq.quoteDetails.paymentReceived && rfq.quoteDetails.testCertificationFileId == null)) {
                                heading.openQuotesCounter = 1;
                                break;
                              }
                            }
                          }
                        },
                        error: error => {
                          this.toastrNotification.error("Get Quote Details Failed");
                        }
                      });

                  });
              } else {
                this.quoteService.getQuoteFileDetails(this.selectedRFQ.quoteDetails.quoteId, "ProductCertification").pipe(first())
                  .subscribe({
                    next: (response: any) => {
                      this.selectedRFQ.quoteDetails.productCertificationQuoteFileId = response.quoteFileId;
                      this.selectedRFQ.quoteDetails.productCertificationFileName = response.quoteFileName;

                      // for (let rfqHeading of this.sortbyHeadings) {
                      //   for (let rfq of rfqHeading.rfq) {
                      //     if (rfq.rfqId == this.selectedRFQ.rfqId) {
                      //       rfq.quoteDetails.productCertificationQuoteFileId = response.quoteFileId;
                      //       rfq.quoteDetails.productCertificationFileName = response.quoteFileName;
                      //     }
                      //   }
                      // }
                      this.testCertirficateUploading = false;
                      this.testCetificateDeleting = false;

                      // LOGIC FOR SUPPLIER USER ACTIONS 
                      for (let heading of this.sortbyHeadings) {
                        for (let rfq of heading.rfq) {
                          heading.openQuotesCounter = 0;

                          if ((rfq.quoteDetails.invoiceQuoteFileId == null) ||
                            (rfq.quoteDetails.invoiceQuoteFileId != null && rfq.quoteDetails.paymentRefecence != null && rfq.quoteDetails.paymentReceived == false) ||
                            (rfq.productCertificationRequirements == true && rfq.quoteDetails.paymentReceived && rfq.quoteDetails.testCertificationFileId == null)) {
                            heading.openQuotesCounter = 1;
                            break;
                          }
                        }
                      }
                    },
                    error: error => {
                      this.toastrNotification.error("Get Quote Details Failed");
                    }
                  });

              }
              this.clearTCInputVar.nativeElement.value = ""

            });
        };

      } else {
        if (this.productCertificationFile.name == this.selectedRFQ.quoteDetails.salesOrderFileName) {
          this.toastrNotification.error("File has already been uploaded as Sales Order");
        } else if (this.productCertificationFile.name == this.selectedRFQ.quoteDetails.quoteFileName) {
          this.toastrNotification.error("File has already been uploaded as Quote ");
        } else if (this.productCertificationFile.name == this.selectedRFQ.quoteDetails.invoiceOrderFileName) {
          this.toastrNotification.error("File has already been uploaded as Final Invoice ");
        }

        this.clearTCInputVar.nativeElement.value = ""
      }
    }
  }

  downloadFile(base64String, fileName) {

    // if(window.navigator && window.navigator.msSaveOrOpenBlob){ 
    //   // download PDF in IE
    //   let byteChar = atob(base64String);
    //   let byteArray = new Array(byteChar.length);
    //   for(let i = 0; i < byteChar.length; i++){
    //     byteArray[i] = byteChar.charCodeAt(i);
    //   }
    //   let uIntArray = new Uint8Array(byteArray);
    //   let blob = new Blob([uIntArray], {type : 'application/pdf'});
    //   window.navigator.msSaveOrOpenBlob(blob, `${fileName}.pdf`);
    // } 

    // Download PDF in Chrome etc.
    const source = `data:application/;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}`
    link.click();
  }

  Sendquotedata(value: number) {
    this.quoteEvent.emit(value);
    this.sentQID = value;

  }

  triggerChatOpen(value: boolean) {
    this.openChatCond.emit(value);
    this.openChat = value;

    //CHECKS IF SIDE NAVS RFQ ID = THE SELECTED RFQ AND IF CHAT IS OPENED ON SELECTED RFQ, THE BADGES DISSAPEARS
    if (value == true) {
      for (let siderfq of this.viewCompletedQuotes) {
        if (this.selectedRfqId == siderfq.rfqId) {
          this.selectedRFQ.quoteDetails.unreadPrivateMessages = false;

        }
      }

    }

  }

  getChatHistory(sentQID, Role) {
    this.chatService.getchatHistory(sentQID, Role)
      .subscribe(chatHistory => {
        this.chatHistory = chatHistory;
        //loads messages instantly
        this.cdr.detectChanges();

      });
  }

  // TOGGLES PRIVATE CHAT BADGE TO FALSE WHEN PUBLIC CHAT IS OPENED
  togglePrivateChatbadge() {
    this.selectedRfqHeading.quoteDetails.unreadPrivateMessages = false;
  }

  // RFQ NAV SEARCH FORM
  RFQsearch: FormGroup;
  // RFQ SEARCH FORM
  get rs() { return this.RFQsearch.controls; }

  //  CLEAR RFQ SEARCH FILTER VALUES
  filtersUsed: boolean = false;
  filteredNavRfqs: QuoteHeadings[] = [];
  filteredNavRfqsUpdate: QuoteHeadings[] = [];
  filterSortbyHeadings: QuoteHeaddisplay[] = [];
  filterSortbyHeadingsUpdate: QuoteHeaddisplay[] = [];


  filterRfqType: string[] = [];
  filterPaymentOptions: string[] = [];
  filterPackagingOptions: string[] = [];
  filterCollectorDelivery: string[] = [];
  filtertestCertification: boolean[] = [];


  //  FILTER RFQ NAV
  RFQsearchFilter() {

    this.filterRfqType = [];
    this.filterPaymentOptions = [];
    this.filterCollectorDelivery = [];
    this.filterPackagingOptions = [];
    this.filtertestCertification = [];


    this.filteredNavRfqs = [];


    // POPULATES THE FILTER RFQ ARRAY ACCORDING TO IF ARCHIVED OR AWAITING PAYMENT HAS BEEN SELECTED

    if (this.g.awaitingPayment.value == true) {
      for (let rfq of this.awaitPaymentQuotes) {
        this.filteredNavRfqs.push(rfq);
      }

    }

    if (this.g.archived.value == true) {
      for (let rfq of this.archivedQuotes) {
        this.filteredNavRfqs.push(rfq);
      }

    }


    // IF ANY OF THE FILTER VALUES ARE SET TO TRUE THEN THE FILTER IS USED AND THE HTML DISPLAYS THE filteredNavRfqs ARRAY
    if (this.rs.complete.value || this.rs.rawmat.value || this.rs.cod.value || this.rs.cbd.value || this.rs.thirtyDays.value || this.rs.sixtyDays.value ||
      this.rs.paymentOther.value || this.rs.delivery.value || this.rs.collection.value || this.rs.boxed.value || this.rs.pallets.value ||
      this.rs.strapped.value || this.rs.loose.value || this.rs.packagingOther.value || this.rs.testCertificationYes.value || this.rs.testCertificationNo.value) {

      this.filtersUsed = true;
    }
    else {
      this.filtersUsed = false;

    }

    // BUILD UP TYPE OF SUPPLY ARRAY
    if (this.rs.complete.value == true) {
      this.filterRfqType.push("Complete");
    }

    if (this.rs.rawmat.value == true) {
      this.filterRfqType.push("Raw Material");
    }


    // BUILD UP COLLECT OR DELIVERY ARRAY
    if (this.rs.delivery.value == true) {
      this.filterCollectorDelivery.push("Delivery");

    }

    if (this.rs.collection.value == true) {
      this.filterCollectorDelivery.push("Collection");

    }
    // BUILD UP PAYMENT ARRAY
    if (this.rs.cod.value == true) {
      this.filterPaymentOptions.push("COD");

    }

    if (this.rs.cbd.value == true) {
      this.filterPaymentOptions.push("CBD");

    }

    if (this.rs.thirtyDays.value == true) {
      this.filterPaymentOptions.push("30 Days");

    }


    if (this.rs.sixtyDays.value == true) {
      this.filterPaymentOptions.push("60 Days");

    }


    if (this.rs.paymentOther.value == true) {
      this.filterPaymentOptions.push("Other");

    }

    // BUILD UP PACKAGING OPTION ARRAY
    if (this.rs.boxed.value == true) {
      this.filterPackagingOptions.push("Boxed");

    }

    if (this.rs.pallets.value == true) {
      this.filterPackagingOptions.push("Pallet");

    }

    if (this.rs.strapped.value == true) {
      this.filterPackagingOptions.push("Strapped");

    }

    if (this.rs.loose.value == true) {
      this.filterPackagingOptions.push("Loose");

    }

    if (this.rs.packagingOther.value == true) {
      this.filterPackagingOptions.push("Other");

    }

    // TEST CERTIFICATION ARRAY POPULATE
    if (this.rs.testCertificationYes.value == true) {

      this.filtertestCertification.push(true);
    }

    // TEST CERTIFICATION ARRAY POPULATE
    if (this.rs.testCertificationNo.value == true) {

      this.filtertestCertification.push(true);
    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE TYPE OP SUPPLY OPTIONS
    if (this.filterRfqType.length > 0) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => this.filterRfqType.some(r => rfq.typeOfSupply.includes(r)));

    }


    // FILTERS filterOpenRfq ARRAY FOR ALL THE PAYMENT REQUIREMENT OPTIONS
    if (this.filterPaymentOptions.length > 0) {
      if (this.filteredNavRfqs != null) {
        this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => this.filterPaymentOptions.some(r => rfq.paymentRequirement?.includes(r)));
      }
    }


    // FILTERS filterOpenRfq ARRAY FOR ALL THE DELIVERY REQUIREMENT OPTIONS
    if (this.filterCollectorDelivery.length > 0) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => this.filterCollectorDelivery.some(r => rfq.deliveryRequirements.includes(r)));
    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE PACKAGING REQUIREMENT OPTIONS FIRST CHECKS IF THE PACKAGING REQUIREMENT OPTIONS IS NOT NULL
    if (this.filterPackagingOptions.length > 0) {

      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => rfq.packagingRequirements != null).filter(rfq => this.filterPackagingOptions.some(r => rfq.packagingRequirements.includes(r)));
    }


    // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE OR FALSE
    if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == true) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => rfq.productCertificationRequirements == true || rfq.productCertificationRequirements == false);

    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE
    if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == false) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => rfq.productCertificationRequirements == true);

    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS FALSE
    if (this.rs.testCertificationNo.value == true && this.rs.testCertificationYes.value == false) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => rfq.productCertificationRequirements == false);

    }



    // FILTERS filterOpenRfq ARRAY IF NOT NULL
    if (this.filteredNavRfqs.length > 0) {

      this.filterSortbyHeadings = [];
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

      this.headingId = 1;
      this.singleSortHeadings.rfqHeadName = "Multiple Services";
      this.singleSortHeadings.rfq = [];
      this.filterSortbyHeadings.push(this.singleSortHeadings);
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
      this.headingId++;



      this.sortFilterRFQHeadArray();
    }
    else {
      // MAKE MAKE THE ARRAY THAT BUILDS UP THE SIDE NAV NULL
      this.filterSortbyHeadings = [];
    }


  }

  // BUILDS RFQ SIDENAV AFTER A FILTER IS SELECTED
  sortFilterRFQHeadArray() {

    // PUSHES ALL VALUES FROM OPEN RFQS ARRAY INTO THE FILTER RFQS ARRAY

    for (let indexrfq of this.filteredNavRfqs) {
      for (let service of indexrfq.rfqServicesRequired) {
        this.check = false;
        if (indexrfq.rfqServicesRequired.length == 1) {
          for (let name of this.filterSortbyHeadings) {
            if (service == name.rfqHeadName) {
              this.check = true;
            }
          }
          if (this.check == false) {

            this.singleSortHeadings.rfqHeadName = service;
            this.singleSortHeadings.rfq = [];
            this.singleSortHeadings.id = this.headingId;
            this.filterSortbyHeadings.push(this.singleSortHeadings);
            this.headingId++;
            this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
          }
        }
      }

      if (indexrfq.rfqServicesRequired.length > 1) {
        this.filterSortbyHeadings[0].rfq.push(indexrfq);
      }

    }
    this.populateSearchRFQHeadArray();
    this.getClosingTime(Date);

  }

  populateSearchRFQHeadArray(): void {
    for (let rfq of this.filteredNavRfqs) {
      for (let service of rfq.rfqServicesRequired) {
        if (rfq.rfqServicesRequired.length == 1) {
          for (let val of this.filterSortbyHeadings) {
            if (val.rfqHeadName == service) {
              val.rfq.push(rfq)

            }
          }
        }

      }
    }
    if (this.filterSortbyHeadings[0].rfq.length == 0) {
      this.filterSortbyHeadings.splice(0, 1);
    }
    // LOGIC FOR SUPPLIER USER ACTIONS 
    for (let heading of this.filterSortbyHeadingsUpdate) {
      for (let rfq of heading.rfq) {
        heading.openQuotesCounter = 0;

        if ((rfq.quoteDetails.invoiceQuoteFileId == null) ||
          (rfq.quoteDetails.invoiceQuoteFileId != null && rfq.quoteDetails.paymentRefecence != null && rfq.quoteDetails.paymentReceived == false) ||
          (rfq.productCertificationRequirements == true && rfq.quoteDetails.paymentReceived && rfq.quoteDetails.testCertificationFileId == null)) {
          heading.openQuotesCounter = 1;
          break;
        }
      }
    }
  }

  // FILTER NAV ON PERIODIC RELOAD
  filterUpdateRfqs(array: QuoteHeadings[]) {

    this.filteredNavRfqsUpdate = [];

    if (array.length > 0) {


      // POPULATES THE FILTER RFQ ARRAY ACCORDING TO IF ARCHIVED OR AWAITING PAYMENT HAS BEEN SELECTED
      if (this.g.awaitingPayment.value == true) {
        for (let rfq of array) {

          if (!rfq.quoteDetails.archived) {
            this.filteredNavRfqsUpdate.push(rfq);
          }
        }
      }

      else if (this.g.archived.value == true) {
        for (let rfq of array) {
          if (rfq.quoteDetails.archived) {
            this.filteredNavRfqsUpdate.push(rfq);
          }
        }
      }


      // FILTERS filterOpenRfq ARRAY FOR ALL THE TYPE OP SUPPLY OPTIONS
      if (this.filterRfqType.length > 0) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => this.filterRfqType.some(r => rfq.typeOfSupply.includes(r)));

      }
      // FILTERS filterOpenRfq ARRAY FOR ALL THE PAYMENT REQUIREMENT OPTIONS
      if (this.filterPaymentOptions.length > 0) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => this.filterPaymentOptions.some(r => rfq.paymentRequirement.includes(r)));
      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE DELIVERY REQUIREMENT OPTIONS
      if (this.filterCollectorDelivery.length > 0) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => this.filterCollectorDelivery.some(r => rfq.deliveryRequirements.includes(r)));
      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE PACKAGING REQUIREMENT OPTIONS FIRST CHECKS IF THE PACKAGING REQUIREMENT OPTIONS IS NOT NULL
      if (this.filterPackagingOptions.length > 0) {

        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => rfq.packagingRequirements != null).filter(rfq => this.filterPackagingOptions.some(r => rfq.packagingRequirements.includes(r)));
      }


      // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE OR FALSE
      if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == true) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => rfq.productCertificationRequirements == true || rfq.productCertificationRequirements == false);

      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE
      if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == false) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => rfq.productCertificationRequirements == true);

      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS FALSE
      if (this.rs.testCertificationNo.value == true && this.rs.testCertificationYes.value == false) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => rfq.productCertificationRequirements == false);

      }

      // FILTERS filterOpenRfq ARRAY IF NOT NULL
      if (this.filteredNavRfqsUpdate.length > 0) {

        this.filterSortbyHeadingsUpdate = [];
        this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

        this.headingId = 1;
        this.singleSortHeadings.rfqHeadName = "Multiple Services";
        this.singleSortHeadings.rfq = [];
        this.filterSortbyHeadingsUpdate.push(this.singleSortHeadings);
        this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
        this.headingId++;

        for (let indexrfq of this.filteredNavRfqsUpdate) {
          for (let service of indexrfq.rfqServicesRequired) {
            this.check = false;
            if (indexrfq.rfqServicesRequired.length == 1) {
              for (let name of this.filterSortbyHeadingsUpdate) {
                if (service == name.rfqHeadName) {
                  this.check = true;
                }
              }
              if (this.check == false) {

                this.singleSortHeadings.rfqHeadName = service;
                this.singleSortHeadings.rfq = [];
                this.singleSortHeadings.id = this.headingId;
                this.filterSortbyHeadingsUpdate.push(this.singleSortHeadings);
                this.headingId++;
                this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
              }
            }
          }

          if (indexrfq.rfqServicesRequired.length > 1) {
            this.filterSortbyHeadingsUpdate[0].rfq.push(indexrfq);
          }

        }

        this.getClosingTime(Date);



        for (let rfq of this.filteredNavRfqsUpdate) {
          for (let service of rfq.rfqServicesRequired) {
            if (rfq.rfqServicesRequired.length == 1) {
              for (let val of this.filterSortbyHeadingsUpdate) {
                if (val.rfqHeadName == service) {
                  val.rfq.push(rfq)

                }
              }
            }

          }
        }
        if (this.filterSortbyHeadingsUpdate[0].rfq.length == 0) {
          this.filterSortbyHeadingsUpdate.splice(0, 1);
        }
        // LOGIC FOR SUPPLIER USER ACTIONS 
        for (let heading of this.filterSortbyHeadingsUpdate) {
          for (let rfq of heading.rfq) {
            heading.openQuotesCounter = 0;

            if ((rfq.quoteDetails.invoiceQuoteFileId == null) ||
              (rfq.quoteDetails.invoiceQuoteFileId != null && rfq.quoteDetails.paymentRefecence != null && rfq.quoteDetails.paymentReceived == false) ||
              (rfq.productCertificationRequirements == true && rfq.quoteDetails.paymentReceived && rfq.quoteDetails.testCertificationFileId == null)) {
              heading.openQuotesCounter = 1;
              break;
            }
          }
        }


      }
      else {
        // MAKE MAKE THE ARRAY THAT BUILDS UP THE SIDE NAV NULL
        this.filterSortbyHeadingsUpdate = [];
      }



      this.replaceFilteredRfqsOnUpdate();

    }

  }

  //PUSH OR SPLICE RFQS IF THEY HAVE LEFT THE ARRAY OR IF NEW ONES HAVE COME IN
  replaceFilteredRfqsOnUpdate() {

    var newHeadings: string[] = [];
    var oldHeadings: string[] = [];

    for (let heading of this.filterSortbyHeadings) {
      oldHeadings.push(heading.rfqHeadName)
      for (let newHead of this.filterSortbyHeadingsUpdate) {
        if (heading.rfqHeadName == newHead.rfqHeadName) {
          heading.rfq = newHead.rfq;
          heading.openQuotesCounter = newHead.openQuotesCounter;
        }
      }
    }

    for (let newHeading of this.filterSortbyHeadingsUpdate) {
      newHeadings.push(newHeading.rfqHeadName);
    }

    for (let newHead of this.filterSortbyHeadingsUpdate) {
      if (oldHeadings.indexOf(newHead.rfqHeadName) == -1) {
        this.filterSortbyHeadings.push(newHead);
      }

    }


    for (const { index, value } of this.filterSortbyHeadings.map((value, index) => ({ index, value }))) {
      if (newHeadings.indexOf(value.rfqHeadName) == -1) {
        this.filterSortbyHeadings.splice(index, 1);
      }
    }



  }

  clearRFQFilter() {

    this.filtersUsed = false;

    this.rs.complete.setValue(false);
    this.rs.rawmat.setValue(false);
    this.rs.cod.setValue(false);
    this.rs.cbd.setValue(false);
    this.rs.thirtyDays.setValue(false);
    this.rs.sixtyDays.setValue(false);
    this.rs.paymentOther.setValue(false);
    this.rs.delivery.setValue(false);
    this.rs.collection.setValue(false);
    this.rs.boxed.setValue(false);
    this.rs.pallets.setValue(false);
    this.rs.strapped.setValue(false);
    this.rs.loose.setValue(false);
    this.rs.packagingOther.setValue(false);
    this.rs.testCertificationYes.setValue(false);
    this.rs.testCertificationNo.setValue(false);
  }

  filterOpen: boolean = false;
  filterIconToggle() {

    this.filterOpen = !this.filterOpen;

  }

  // QUOTE REVIEW OBJECT
  quoteReview: quoteReview;

  loadingQuoteReview: boolean = false;

  getQuoteReview(quoteId) {

    //  NEEDED FOR SPINNER
    this.loadingQuoteReview = true;

    this.quoteService.getQuoteReview(quoteId).pipe(first())
      .subscribe({
        next: (response: any) => {

          this.quoteReview = response;
          this.loadingQuoteReview = false;
  

          // FOR BUYER DELIVERY DATE CALC
          this.getDaydiffdeliveryBuyer(this.quoteReview.actualDeliveryDate);

        },
        error: error => {
          this.toastrNotification.error("Quote Review Get Failed");
        }
      });
  }

  rfqReview: FullRfqReview;

  loadingRfqReview: boolean = false;

  getRfqReview() {

    this.loadingRfqReview = true;

    this.rfqService.getRfqReview(this.selectedRFQ.rfqId).pipe(first())
      .subscribe({
        next: (response: any) => {
          this.rfqReview = response;
          // NEEDED FOR SPINNER
          this.loadingRfqReview = false;

          // FOR SUPPLIER DELIVERY DATE CALC
          this.getDaydiffdeliverySupplier(this.rfqReview.actualDeliveryDate);

        },
        error: error => {
          this.toastrNotification.error("Rfq Review Get Failed");

        }



      });
  }

  getQuoteandRFQreviews() {

    this.getQuoteReview(this.selectedRFQ.quoteDetails.quoteId);
    this.getRfqReview();



  }



  //  DATE CALCULATIONS



  //needed for date calculations

  dueDate: any;
  dueHours: any;
  date1: any;
  date2: any;
  date3: any;
  roundDate: any;


  getDaydiffdeliverySupplier(actualDeliveryDate) {

    this.date1 = new Date(this.selectedRFQ.maxLeadTime);
    this.date2 = new Date(actualDeliveryDate);

    let diff = this.date2.getTime() - this.date1.getTime();
    let msInDay = 1000 * 3600 * 24;

    this.dueDate = diff / msInDay;

    this.roundDate = Math.floor(diff / msInDay);

    this.roundDate = Math.abs(this.roundDate);

  }



  //needed for date calculations

  dueDateb: any;
  dueHoursb: any;
  date1b: any;
  date2b: any;
  date3b: any;
  roundDateb: any;



  getDaydiffdeliveryBuyer(actualDeliveryDate) {

    this.date1b = new Date(this.selectedRFQ.maxLeadTime);
    this.date2b = new Date(actualDeliveryDate);

    let diff = this.date2b.getTime() - this.date1b.getTime();
    let msInDay = 1000 * 3600 * 24;

    this.dueDateb = diff / msInDay;

    this.roundDateb = Math.floor(diff / msInDay);

    this.roundDateb = Math.abs(this.roundDateb);

  }

}
