import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import { first } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Account } from '../models/account';
import { BuyerUsers } from '../models/buyerCompany';
import { EditUser } from '../models/editUser';
import { ResendWelcomeEmail } from '../models/resendWelcomeEmail';
import { ToastrNotificationService } from '../services/toastr-notification.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  @Input() currentUser: BuyerUsers;

  @Input() profileType: string;

  @Input() editorUsername: string;

  @Output() closeEditUserModalCond = new EventEmitter<boolean>();

  @Output() openDeleteUserModal = new EventEmitter<boolean>();

  closeEditUserModal(value: boolean) {
    this.closeEditUserModalCond.emit(value);
  }

  welcomeEmail: ResendWelcomeEmail = { Email: null, BuyerId: null, SupplierId: null }

  //NEEDED FOR MODAL HEADER COLOUR
  @Input() greenformheader: boolean;
  @Input() blueformheader: boolean;

  account: Account;
  editUserForm: FormGroup;

  editUser: EditUser = { RequestingUserName: null, UserName: null, FirstName: null, LastName: null, Role: null, Team: null, SMSPreference: null, EmailPreference: null };

  constructor(private formBuilder: FormBuilder, private authService: AuthService, public toastrNotification: ToastrNotificationService) {
    this.authService.account.subscribe(x => this.account = x);
  }

  editUserErrorDisplay: boolean = false;

  editingUser: boolean = false;

  ngOnInit(): void {

    this.editUserForm = this.formBuilder.group({

      userNameE: [this.currentUser.userName],
      firstNameE: [this.currentUser.firstName],
      lastNameE: [this.currentUser.lastName],
      roleE: [this.currentUser.role],
      teamE: [this.currentUser.team],
      sMSPreferenceE: [this.currentUser.smsPreference],
      emailPreferenceE: [this.currentUser.emailPreference],

    });

   // if (this.currentUser.role == "Admin" || !(this.account.account.roles[0] == "Admin")) {
      this.eu.roleE.disable();
    //}
  }

  contactDetailsValid() {
    if ((this.eu.firstNameE.value.replace(/ /g, "") != "" && this.eu.firstNameE.value.length < 50
      && this.eu.lastNameE.value.replace(/ /g, "") != "" && this.eu.lastNameE.value.length < 50
      //&& this.eu.roleE.value.replace(/ /g, "") != ""
      && this.eu.teamE.value.replace(/ /g, "") != "" && this.eu.teamE.value.length < 50)) {
      return true;
    } else {
      return false;
    }
  }

  contactDetailsValidCond() {
    if (this.eu.firstNameE.value.replace(/ /g, "") != "" && this.eu.firstNameE.value.length < 50
      && this.eu.lastNameE.value.replace(/ /g, "") != "" && this.eu.lastNameE.value.length < 50
     // && this.eu.roleE.value.replace(/ /g, "") != ""
      && this.eu.teamE.value.value.replace(/ /g, "") != "" && this.eu.teamE.value.length < 50) {
      this.editUserErrorDisplay = false;
    } else {
      this.editUserErrorDisplay = true;
    }
  }

  stringValidation(value) {
    if (value.replace(/ /g, "") == "") {
      return true
    } else {
      return false;
    }
  }

  editUserSubmit() {

    this.editUser = { RequestingUserName: null, UserName: null, FirstName: null, LastName: null, Role: null, Team: null, SMSPreference: null, EmailPreference: null };

    this.editUser.RequestingUserName = this.account.account.userName;
    this.editUser.UserName = this.eu.userNameE.value;
    this.editUser.FirstName = this.eu.firstNameE.value;
    this.editUser.LastName = this.eu.lastNameE.value;
    this.editUser.Role = this.currentUser.role;
    this.editUser.Team = this.eu.teamE.value;
    this.editUser.SMSPreference = this.eu.sMSPreferenceE.value;
    this.editUser.EmailPreference = this.eu.emailPreferenceE.value;

    if (this.editUser.FirstName != this.currentUser.firstName
      || this.editUser.LastName != this.currentUser.lastName
      || this.editUser.Role != this.currentUser.role
      || this.editUser.Team != this.currentUser.team
      || this.editUser.SMSPreference != this.currentUser.smsPreference
      || this.editUser.EmailPreference != this.currentUser.emailPreference) {

      this.editingUser = true;

      this.authService.editUserDetails(this.editUser).pipe(first())
        .subscribe({
          next: (response: any) => {
            this.closeEditUserModal(true);
            this.editingUser = false;
            this.toastrNotification.success("Edit User Details Succeeded");
          },
          error: error => {
            this.toastrNotification.error("Edit User Details Failed");
            this.editingUser = false;
          }
        });
    } else {
      this.toastrNotification.info("No Changes Present");
    }

  }

  cancelEdit() {

    this.eu.userNameE.setValue(this.currentUser.userName);
    this.eu.firstNameE.setValue(this.currentUser.firstName);
    this.eu.lastNameE.setValue(this.currentUser.lastName);
    this.eu.roleE.setValue(this.currentUser.role);
    this.eu.teamE.setValue(this.currentUser.team);
    this.eu.sMSPreferenceE.setValue(this.currentUser.smsPreference);
    this.eu.emailPreferenceE.setValue(this.currentUser.emailPreference);

  }

  

  deleteUser(){

    this.openDeleteUserModal.emit(true);

  }

  sendingWelcomeEmail: boolean = false;

  resendWelcomeEmail() {

    this.sendingWelcomeEmail = true;

    this.welcomeEmail = { Email: null, BuyerId: null, SupplierId: null }

    this.welcomeEmail.Email = this.eu.userNameE.value;

    if (this.profileType == "Buyer") {
      this.welcomeEmail.BuyerId = this.account.account.buyerId;
      this.welcomeEmail.SupplierId = null;
    }
    else if (this.profileType == "Supplier") {
      this.welcomeEmail.BuyerId = null;
      this.welcomeEmail.SupplierId = this.account.account.supplierId;
    }
    this.authService.resendWelcomeEmail(this.welcomeEmail).pipe(first()).subscribe({
      next: (response: any) => {

        this.toastrNotification.success("Welcome Email Resent");
        this.sendingWelcomeEmail = false;

      },
      error: error => {

        this.toastrNotification.error("Welcome Email Resent Failed");
        this.sendingWelcomeEmail = false;

      }
    });

  }

  get eu() { return this.editUserForm.controls; }

}
