import { Component, Input, OnInit, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Question, Reply } from '../models/question-thread';
import { RFQ } from '../models/rfq';
import { QuestionAndAnswersService } from '../services/question-and-answers.service';
import *  as moment from 'moment';
import { interval, Subscription } from 'rxjs';
import { ToastrNotificationService } from '../services/toastr-notification.service';
import { ThisReceiver } from '@angular/compiler';
import { NotificationsService } from '../services/notifications.service';
import { ViewQuestions } from '../models/viewQuestionThread';
import { CreateQuestion, EditQuestion } from '../models/questionModels';
import { EditReply } from '../models/replyModels';
import { Account } from '../models/account';
import { AuthService } from '../auth/auth.service';
import { getQA } from '../models/getQ&A';
import { finalize, first } from 'rxjs/operators';
import { newRepliesFlag } from '../models/newRepliesFlag';
import { ChatMessage } from '../models/chatMessage';

@Component({
  selector: 'app-questions-and-answers',
  templateUrl: './questions-and-answers.component.html',
  styleUrls: ['./questions-and-answers.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionsAndAnswersComponent implements OnChanges, OnInit, OnDestroy {

  // clickEventsubscription:Subscription;

  private subscriptionQA: Subscription;

  name = 'Hello angular inline input';
  cost: string = 'Question 1';

  saveCost(value) {
    this.cost = value;
  }

  getQuestions: getQA = { rfqId: null, userId: null, supplierId: null, isBuyer: null, isSupplier: null }

  //NEEDED FOR CREATING Q&A QUESTIONS AND REPLIES
  @Input() isBuyer: boolean;
  @Input() isSupplier: boolean;
  @Input() rfqStatus: string;

  //NEEDED TO TRIGGER GET QUESTIONS AND ANSWERS
  @Input() getQA: boolean;

  @Input() rfqId: number;
  @Input() supplierId: number;
  @Input() rfq: RFQ;
  @Input() searchedKeyWord: any;

  @Output() periodicUpdateCond = new EventEmitter<boolean>();
  @Output() periodicUpdateCondIPR = new EventEmitter<boolean>();
  @Output() periodicUpdateCondCR = new EventEmitter<boolean>();

  @Output() publicChatToggle = new EventEmitter<boolean>();

  triggerperiodicUpdate(value: boolean) {
    this.periodicUpdateCond.emit(value);
    this.periodicUpdateCondIPR.emit(value);
    this.periodicUpdateCondCR.emit(value);

    //SETS GET QUESTIONS AND ANSWERS TO FALSE SO THAT RELOAD DOES NOT HAPPEN WHEN MODAL IS CLOSED
    //INPUT CHECK FROM ALL COMPONENTS
    this.getQA = false;
  }

  triggerChatToggle(value: boolean) {
    this.publicChatToggle.emit(value)
  }

  searchedKeyword: string;
  form: FormGroup;

  questCount: number = 9;

  loading: boolean = false;

  showQAreplies: boolean = false;
  questionsAndReplies: ViewQuestions[] = [];
  questionsAndRepliesUpdate: ViewQuestions[] = [];
  questionIds: number[] = [];
  replyIds: number[] = [];
  questionIdsUpdate: number[] = [];
  replyIdsUpdate: number[] = [];

  questionSending: boolean = false;
  questionDeleting: boolean = false;
  selectedQuestionId: number;
  replyDeleting: boolean = false;
  selectedReplyId: number;

  //NEW REPLIES MODAL FLAG FOR NEW REPLIES FOR BUYERS AND SUPPLIERS
  newReplies: newRepliesFlag = { questionid: 0, buyerNewReplies: false, supplierNewReplies: false };


  newQuestion: CreateQuestion = { QuestionContent: null, RfqId: null, SupplierId: null, CreatedBy: null, isBuyer: null, isSupplier: null };
  deleteQuestion: EditQuestion = { QuestionId: null, QuestionContent: null, EditedBy: null };
  deleteReply: EditReply = { ReplyId: null, ReplyContent: null, EditedBy: null };

  replies: Reply[] = [];
  newReply: Reply = { replyId: null, questionId: null, supplierId: null, reply: null, createDate: null };
  selectedQuestions: Question[] = [];
  selectedReplies: Reply[] = [];
  showReply: string = "";
  inlineEdit: boolean = false;
  data: string = 'inline edit test';

  @Input() modalheader: string;

  account: Account;

  constructor(
    public authService: AuthService,
    private formBuilder: FormBuilder,
    private questionAndAnswersService: QuestionAndAnswersService,
    public toastrNotification: ToastrNotificationService,
    public cdr: ChangeDetectorRef,
    public notificationService: NotificationsService) {
    // this.clickEventsubscription = this.questionAndAnswersService.getClickEvent().subscribe(()=>{
   

    //   })
    this.authService.account.subscribe(x => this.account = x);
  }

  ngOnInit(): void {

    this.subscriptionQA = interval(60000)
      .subscribe(x => { this.periodicReloadQA() });

  }

  ngOnDestroy() {
    this.subscriptionQA.unsubscribe();
  }


  ngOnChanges() {

    this.setPeriodicRefresh(1);
 

    this.form = this.formBuilder.group({

      question: ['', Validators.required],

    });

    this.getQuestionsAndReplies(this.rfqId)

  }

  saveChanges(value) {
    this.data = value;
  }

  showReplies(value: string) {
    this.showReply = value;
  }


  toggleViewHideReplies() {
    this.showQAreplies = !this.showQAreplies;


  }

  triggerReloadQA(value) {
    for (let question of this.questionsAndReplies) {
      if (question.questionId == value.questionId) {
        question.replies.push(value);
      }
    }
  }


  // ONLY PERIODICALLY RELOADS IF GET QA IS TRUE
  periodicReloadQA() {
    if (this.getQA == true) {

      this.questionIds = [];
      this.replyIds = [];
      this.questionIdsUpdate = [];
      this.replyIdsUpdate = [];

      this.getQuestions = { rfqId: null, userId: null, supplierId: null, isBuyer: null, isSupplier: null }

      this.getQuestions.rfqId = this.rfqId;
      this.getQuestions.userId = this.account.account.userName;
      this.getQuestions.isSupplier = this.isSupplier;
      this.getQuestions.isBuyer = this.isBuyer;

      if (this.isSupplier) {
        this.getQuestions.supplierId = this.supplierId
      }

      this.questionAndAnswersService.getQuestionThread(this.getQuestions)
        .subscribe(questionsAndReplies => {
          this.questionsAndRepliesUpdate = questionsAndReplies;
          //this.cdr.detectChanges();

          for (let question of this.questionsAndReplies) {
            this.questionIds.push(question.questionId);
            for (let reply of question.replies) {
              this.replyIds.push(reply.replyId);
            }
          }

          for (let question of this.questionsAndRepliesUpdate) {
            this.questionIdsUpdate.push(question.questionId);
            for (let reply of question.replies) {
              this.replyIdsUpdate.push(reply.replyId);
            }
          }

          if (this.questionIds.length != this.questionIdsUpdate.length) {
            for (let question of this.questionsAndRepliesUpdate) {
              if (this.questionIds.indexOf(question.questionId) == -1) {
                this.questionsAndReplies.push(question);
              }
            }

            for (const { index, value } of this.questionsAndReplies.map((value, index) => ({ index, value }))) {
              if (this.questionIdsUpdate.indexOf(value.questionId) == -1) {
                this.questionsAndReplies.splice(index, 1);
              }
            }
          }

          for (const { index, value } of this.questionsAndReplies.map((value, index) => ({ index, value }))) {

            this.replyIds = [];
            this.replyIdsUpdate = [];

            for (let reply of value.replies) {
              this.replyIds.push(reply.replyId);
            }

            for (let question of this.questionsAndRepliesUpdate) {

              for (let reply of question.replies) {
                this.replyIdsUpdate.push(reply.replyId);
              }
              if (value.questionId == question.questionId) {

                if (value.replies.length != question.replies.length) {

                  value.replies = question.replies;

                  // for (let reply of question.replies) {
                  //   if (this.replyIds.indexOf(reply.replyId) == -1) {
                  //     value.replies.push(reply);
                  //   }
                  // }

                  // for (const { indexReply, reply } of value.replies.map((reply, indexReply) => ({ indexReply, reply }))) {
                  //   if (this.replyIdsUpdate.indexOf(reply.replyId) == -1) {
                  //     value.replies.splice(indexReply, 1);
                  //   }
                  // }
                }
              }

            }
          }

          this.cdr.detectChanges();
        });
    }
  }

  // ONLY GETS QUESTIONS AND REPLIES RELOADS IF GET QA IS TRUE
  getQuestionsAndReplies(rfqId) {



    this.loading = true;

    this.questionsAndReplies = [];

    this.getQuestions = { rfqId: null, userId: null, supplierId: null, isBuyer: null, isSupplier: null }

    this.getQuestions.rfqId = this.rfqId;
    this.getQuestions.userId = this.account.account.userId;
    this.getQuestions.isSupplier = this.isSupplier;
    this.getQuestions.isBuyer = this.isBuyer;

    if (this.isSupplier) {
      this.getQuestions.supplierId = this.supplierId
    }
   
    if (this.getQA == true) {
      this.questionAndAnswersService.getQuestionThread(this.getQuestions)
        .subscribe(questionsAndReplies => {
         
          this.questionsAndReplies = questionsAndReplies;
          this.loading = false;
          this.newRepliesCheck();
          this.cdr.detectChanges();
        });
    }

  }

  // getMockQuestions(): void {
  //   //async
  //   this.questionAndAnswersService.getMockQuestions()
  //     .subscribe(questions => this.questions = questions);


  // }



  selectedQA() {
    // this.selectedQuestions = [];

    // this.getMockQuestions();
    // for (let value of this.questions) {
    //   if (value.rfqId == this.rfqId) {
    //     this.selectedQuestions.push(value);
    //   }
    // }
   

    // this.form = this.formBuilder.group({

    //   question: ['', Validators.required],

    // });

  }
  get f() { return this.form.controls; }


  questionSubmit() {
 
    this.newQuestion = { QuestionContent: null, RfqId: null, SupplierId: null, CreatedBy: null, isBuyer: null, isSupplier: null };
    if (this.f.question.value != false && this.f.question.value.replace(/ /g, "") != "" && this.f.question.value != null && this.f.question.value.length < 100 ) {

      this.newQuestion.QuestionContent = this.f.question.value;
      this.newQuestion.RfqId = this.rfqId;
      this.newQuestion.SupplierId = this.account.account.supplierId;
      this.newQuestion.CreatedBy = this.account.account.userId;
      this.newQuestion.isBuyer = this.isBuyer
      this.newQuestion.isSupplier = this.isSupplier
      this.createQuestion(this.newQuestion)


      this.f.question.setValue(null);
      // this.notificationService.addNotification(this.rfq.rfqName, this.rfq.buyerId, 1, 'question','A question was asked on this thread');
    } else {
      if(this.f.question.value.replace(/ /g, "") == ""){
        this.toastrNotification.error('Cannot Send Blank Question');
      }else if(this.f.question.value.length > 100){
        this.toastrNotification.error('Question Exceeds 100 Characters');
      }
     
    }
    this.questCount++;

  }



  createQuestion(question) {
    //async
    var message;
    this.questionSending = true;
    this.f.question.disable();

    this.questionAndAnswersService.addQuestion(question).pipe(first())
      .subscribe({
        next: (response: any) => {
          message = response;

          this.getQuestionsAndReplies(this.rfqId);
          this.questionSending = false;
          this.f.question.enable();
        },
        error: error => {
          this.toastrNotification.error(" Question Submission Failed");
          this.questionSending = false;
          this.f.question.enable();
        }
      });
  }

  questionDelete(qId) {

    this.selectedQuestionId = qId;

    this.deleteQuestion = { QuestionId: null, QuestionContent: null, EditedBy: null };
    this.deleteQuestion.QuestionId = qId;
    this.deleteQuestion.QuestionContent = "";
    this.deleteQuestion.EditedBy = this.account.account.userName

    this.questionDeleting = true;

    var result;
    this.questionAndAnswersService.deleteQuestion(this.deleteQuestion).pipe(first())
      .subscribe({
        next: (response: any) => {
          result = response;
          this.getQuestionsAndReplies(this.rfqId);
          this.questionDeleting = false;
        },
        error: error => {
          this.toastrNotification.error(" Question Delete Failed");
          this.questionDeleting = false;
        }
      });

  }

  replyCollapse() {
    return "collapse";
  }


  replyDelete(rId, qId) {

    this.selectedReplyId = rId;

    this.deleteReply = { ReplyId: null, ReplyContent: null, EditedBy: null };
    this.deleteReply.ReplyId = rId;
    this.deleteReply.ReplyContent = "";
    this.deleteReply.EditedBy = this.account.account.userName;
    var result;

    this.replyDeleting = true;

    this.questionAndAnswersService.deleteReply(this.deleteReply).pipe(first())
      .subscribe({
        next: (response: any) => {
          result = response;
          for (let question of this.questionsAndReplies) {
            if (question.questionId == qId) {
              for (const { index, value } of question.replies.map((value, index) => ({ index, value }))) {
                if (value.replyId == rId) {
                  question.replies.splice(index, 1);
                }
              }
            }
          }
          this.replyDeleting = false;
          this.cdr.detectChanges();
        },
        error: error => {
          this.toastrNotification.error(" Reply Delete Failed");
          this.replyDeleting = false;
        }
      });


    // for (const { index, value } of this.selectedQuestions.map((value, index) => ({ index, value }))) {
    //   for (const { index2, value2 } of value.replies.map((value2, index2) => ({ index2, value2 }))) {
    //     if (value2.replyId == rId) {
    //       value.replies.splice(index2, 1)
    //     }


    //   }
    // }
  }

  questionEdit(newQuestion, qId) {

    this.deleteQuestion = { QuestionId: null, QuestionContent: null, EditedBy: null };
    this.deleteQuestion.QuestionId = qId;
    this.deleteQuestion.QuestionContent = newQuestion;
    this.deleteQuestion.EditedBy = this.account.account.userName


    var result;
    this.questionAndAnswersService.editQuestion(this.deleteQuestion)
      .subscribe(message => {
        result = message;

        this.getQuestionsAndReplies(this.rfqId);
      });



    // for (let question of this.selectedQuestions) {
    //   if (question.questionId == qId) {
    //     question.question = newQuestion;
    //   }
    // }
  }

  replyEdit(newReply, rId) {

    this.deleteReply = { ReplyId: null, ReplyContent: null, EditedBy: null };
    this.deleteReply.ReplyId = rId;
    this.deleteReply.ReplyContent = newReply;
    this.deleteReply.EditedBy = this.account.account.userName;

    var result;
    this.questionAndAnswersService.editReply(this.deleteReply)
      .subscribe(message => {
        result = message;
        //(message);
        this.getQuestionsAndReplies(this.rfqId);

      });


    // for (let question of this.selectedQuestions) {
    //  for (let reply of question.replies)
    //   if (reply.replyId == rId) {
    //     reply.reply = newReply;

    //   }
    // }

  }



  setPeriodicRefresh(minutes) {
    setInterval(() => {
      this.cdr.markForCheck();
    }, minutes * 10 * 6000);
  }



  getMinimalisticRelativeTime(dateTime) {


    var date = new Date(dateTime);
    date.setHours(date.getHours() + 2);

    if (!date) {
      return null;
    }

    const today = moment();

    const time = moment(date);

    const diff = today.diff(time);

    const duration = moment.duration(diff);

    if (duration.years() > 0) {
      return duration.years() + 'y';
    } else if (duration.weeks() > 0) {
      return duration.weeks() + 'w';
    } else if (duration.days() > 0) {
      return duration.days() + 'd';
    } else if (duration.hours() > 0) {
      return duration.hours() + 'h';
    } else if (duration.minutes() > 0) {
      return duration.minutes() + 'm';
    } else if (duration.minutes() < 1) {
      return 'few seconds ago';
    }
  }

  newRepliesCheck() {
    this.newReplies = { questionid: 0, buyerNewReplies: false, supplierNewReplies: false };
    for (let question of this.questionsAndReplies) {
      for (let reply of question.replies) {

        if (this.modalheader == 'blue' && reply.supplierSeen == false && reply.isBuyer == true && question.supplierId == this.account.account.supplierId) {
          this.newReplies.supplierNewReplies = true;
          this.newReplies.questionid = question.questionId;

        }
        if (this.modalheader == 'green' && reply.buyerSeen == false && reply.isSupplier == true) {
          this.newReplies.buyerNewReplies = true;
          this.newReplies.questionid = question.questionId;


        }

      }

    }
  }
}
