<ng-container *ngIf="inProgressRfqs.length > 0 && !dataLoading">
    <main class="main" style="padding-top: 0px;">
        <div>
            <div>
                <div class="tab-content">
                    <div class="tab-pane active" role="tabpanel" id="tab-1">
                        <div class="container-fluid greencont">
                            <h1 class="text-center" style="font-size: 20px;">In Progress Requests for
                                {{selectedRFQ.rfqName}}</h1>
                        </div>
                        <div id="wrapper">
                            <nav class="navbar navbar-dark align-items-start navmargin sidebar sidebar-dark accordion p-0"
                                style="padding: 0;margin-right: 24px;">
                                <div class="container d-flex flex-column p-0">
                                    <hr class="sidebar-divider my-0">
                                    <ul class="accordionSidebar navbar-nav text-light" style="min-width:249px;" id="">
                                        <li class="nav-item" data-bs-toggle="tooltip" data-bss-tooltip=""
                                            data-bs-placement="right" title="" style="padding-top: 10px;">
                                            <div class="input-group"><input class="bg-light form-control border-0 small"
                                                    type="text" placeholder="Search for ..."
                                                    [(ngModel)]="searchrfq"><button class="btn btn-primary py-0"
                                                    type="button"><i class="fas fa-search"></i></button></div>
                                        </li>

                                        <!-- FILTER SECTION STARTS HERE -->

                                        <li class="nav-item" data-bs-toggle="tooltip" data-bss-tooltip=""
                                            data-bs-placement="right" title="">




                                            <div class="accordion accordion-flush collapsed" role="tablist"
                                                id="filter1">
                                                <div class="accordion-item">
                                                    <h2 style="background-color: var(--shadowgrey);"
                                                        class="accordion-header" role="tab"><button
                                                            (click)="filterIconToggle()"
                                                            class="accordion-button collapsed sorthead"
                                                            data-bs-toggle="collapse" data-bs-target="#filter1 .item-1"
                                                            aria-expanded="false" aria-controls="filter .item-1"
                                                            style="color: #0e0e82;font-size: 14px;  height: 40px;   padding: 0px;
                                                                                                                                padding-left:15px; padding-right: 15px;">



                                                            <div
                                                                *ngIf="this.filterOpen == false && this.filtersUsed == false ">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                    height="16" fill="currentColor" class="bi bi-funnel"
                                                                    viewBox="0 0 16 16">
                                                                    <path
                                                                        d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z" />
                                                                </svg>
                                                            </div>

                                                            <div
                                                                *ngIf="this.filterOpen == true || this.filtersUsed == true">

                                                                <svg class="bi bi-funnel-fill"
                                                                    xmlns="http://www.w3.org/2000/svg" width="16"
                                                                    height="1em" fill="currentColor" viewBox="0 0 16 16"
                                                                    style="color: var(--darkblue);">
                                                                    <path
                                                                        d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z">
                                                                    </path>
                                                                </svg>


                                                            </div>

                                                            FIlter
                                                        </button></h2>
                                                    <div class="accordion-collapse collapse item-1 sortdropdown"
                                                        style="    border-radius: 0px; max-height:400px;    overflow-y: scroll; overflow-x: hidden; "
                                                        role="tabpanel" data-bs-parent="#filter1">

                                                        <div class="d-flex justify-content-start"
                                                            style="position: sticky;top: 0;background: var(--shadowgrey); padding-bottom: 15px;     padding-right: 15px; padding-left: 1rem; border-radius: 0px; padding-top: 10px;">

                                                            <button (click)="clearRFQFilter()"
                                                                class="btn btn-secondary applybtn"
                                                                style="width:100px; background-color: var(--darkblue); margin-left: 5px; font-size: 12px;"
                                                                type="button">Clear</button>
                                                        </div>
                                                        <div class="accordion-body">

                                                            <form [formGroup]="RFQsearch" #sheesh="ngForm">

                                                                <div style="  padding-bottom: 3px;"><a>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;font-size: 8px;">
                                                                        </p>
                                                                        <p class="text-nowrap d-sm-flex justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;font-size: 8px;">
                                                                            Type of RFQ</p>
                                                                    </a>
                                                                    <div class="form-check form-check-inline"><input
                                                                            (change)="RFQsearchFilter()"
                                                                            formControlName="complete"
                                                                            class="form-check-input" type="checkbox"
                                                                            id="complete"><label
                                                                            class="form-check-label radio bluetext thin"
                                                                            for="complete">Complete&nbsp; &nbsp;
                                                                            &nbsp;
                                                                            &nbsp;&nbsp;</label></div>
                                                                    <div class="form-check form-check-inline"><input
                                                                            formControlName="rawmat"
                                                                            (change)="RFQsearchFilter()"
                                                                            class="form-check-input" type="checkbox"
                                                                            id="rawmat"><label
                                                                            class="form-check-label radio bluetext thin"
                                                                            for="rawmat">Raw
                                                                            Material</label></div>
                                                                </div>

                                                                <div class="d-flex flex-column"
                                                                    style="padding-bottom: 3px;"><a>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;font-size: 8px;">
                                                                        </p>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;font-size: 8px;">
                                                                            Payment Options</p>
                                                                    </a>
                                                                    <div class="form-check form-check-inline"><input
                                                                            (change)="RFQsearchFilter()"
                                                                            formControlName="cod"
                                                                            class="form-check-input" type="checkbox"
                                                                            id="cod"><label
                                                                            class="form-check-label radio bluetext thin"
                                                                            for="cod">COD</label></div>

                                                                    <div class="form-check form-check-inline"><input
                                                                            (change)="RFQsearchFilter()"
                                                                            formControlName="cbd"
                                                                            class="form-check-input" type="checkbox"
                                                                            id="cbd"><label
                                                                            class="form-check-label radio bluetext thin"
                                                                            for="cbd">CBD</label></div>


                                                                    <div class="form-check form-check-inline"><input
                                                                            (change)="RFQsearchFilter()"
                                                                            formControlName="thirtyDays"
                                                                            class="form-check-input" type="checkbox"
                                                                            id="thirtyDays"><label
                                                                            class="form-check-label radio bluetext thin"
                                                                            for="thirtyDays">30 Days</label></div>
                                                                    <div class="form-check form-check-inline"><input
                                                                            (change)="RFQsearchFilter()"
                                                                            formControlName="sixtyDays"
                                                                            class="form-check-input" type="checkbox"
                                                                            id="sixtyDays"><label
                                                                            class="form-check-label radio bluetext thin"
                                                                            for="sixtyDays">60 Days</label></div>
                                                                    <div class="form-check form-check-inline"><input
                                                                            (change)="RFQsearchFilter()"
                                                                            formControlName="paymentOther"
                                                                            class="form-check-input" type="checkbox"
                                                                            id="paymentOther"><label
                                                                            class="form-check-label radio bluetext thin"
                                                                            for="paymentOther">Other</label></div>
                                                                </div>
                                                                <div class="d-flex flex-column"
                                                                    style="padding-bottom: 3px;"><a>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;font-size: 8px;">
                                                                        </p>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;font-size: 8px;">
                                                                            Delivery or Collection</p>
                                                                    </a>
                                                                    <div class="form-check form-check-inline"><input
                                                                            formControlName="delivery"
                                                                            (change)="RFQsearchFilter()"
                                                                            class="form-check-input" type="checkbox"
                                                                            id="delivery"><label
                                                                            class="form-check-label radio bluetext thin"
                                                                            for="delivery">Delivery</label></div>
                                                                    <div class="form-check form-check-inline"><input
                                                                            formControlName="collection"
                                                                            (change)="RFQsearchFilter()"
                                                                            class="form-check-input" type="checkbox"
                                                                            id="collection"><label
                                                                            class="form-check-label radio bluetext thin"
                                                                            for="collection">Collection</label></div>

                                                                </div>

                                                                <div class="d-flex flex-column"
                                                                    style="padding-bottom: 3px;"><a>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;font-size: 8px;">
                                                                        </p>
                                                                        <p class="text-nowrap d-sm-flex justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;font-size: 8px;">
                                                                            Packaging Options</p>
                                                                    </a>
                                                                    <div class="form-check form-check-inline"><input
                                                                            (change)="RFQsearchFilter()"
                                                                            formControlName="boxed"
                                                                            class="form-check-input" type="checkbox"
                                                                            id="boxed"><label
                                                                            class="form-check-label radio bluetext thin"
                                                                            for="boxed">Boxed</label></div>
                                                                    <div class="form-check form-check-inline"><input
                                                                            (change)="RFQsearchFilter()"
                                                                            formControlName="pallets"
                                                                            class="form-check-input" type="checkbox"
                                                                            id="pallets"><label
                                                                            class="form-check-label radio bluetext thin"
                                                                            for="pallets">Pallets</label></div>
                                                                    <div class="form-check form-check-inline"><input
                                                                            (change)="RFQsearchFilter()"
                                                                            formControlName="strapped"
                                                                            class="form-check-input" type="checkbox"
                                                                            id="strapped"><label
                                                                            class="form-check-label radio bluetext thin"
                                                                            for="strapped">Strapped</label></div>
                                                                    <div class="form-check form-check-inline"><input
                                                                            (change)="RFQsearchFilter()"
                                                                            formControlName="loose"
                                                                            class="form-check-input" type="checkbox"
                                                                            id="loose"><label
                                                                            class="form-check-label radio bluetext thin"
                                                                            for="loose">Loose</label></div>
                                                                    <div class="form-check form-check-inline"><input
                                                                            (change)="RFQsearchFilter()"
                                                                            formControlName="packagingOther"
                                                                            class="form-check-input" type="checkbox"
                                                                            id="packagingOther"><label
                                                                            class="form-check-label radio bluetext thin"
                                                                            for="packagingOther">Other</label></div>
                                                                </div>


                                                                <div class="d-flex flex-column"
                                                                    style="padding-bottom: 3px;"><a>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;font-size: 8px;">
                                                                        </p>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;font-size: 8px;">
                                                                            Test Certification</p>
                                                                    </a>
                                                                    <div class="form-check form-check-inline"><input
                                                                            (change)="RFQsearchFilter()"
                                                                            formControlName="testCertificationYes"
                                                                            class="form-check-input" type="checkbox"
                                                                            id="ftestCertificationYes"><label
                                                                            class="form-check-label radio bluetext thin"
                                                                            for="testCertificationYes">Yes </label>
                                                                    </div>
                                                                    <div class="form-check form-check-inline"><input
                                                                            (change)="RFQsearchFilter()"
                                                                            formControlName="testCertificationNo"
                                                                            class="form-check-input" type="checkbox"
                                                                            id="testCertificationNo"><label
                                                                            class="form-check-label radio bluetext thin"
                                                                            for="testCertificationNo">No</label></div>

                                                                </div>


                                                            </form>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <!-- <li class="nav-item" data-bs-toggle="tooltip" data-bss-tooltip="" data-bs-placement="right" title="">
                                        <div></div>
                                        <div class="accordion accordion-flush collapsed" role="tablist" id="accordion-1">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" role="tab"><button class="accordion-button collapsed sorthead" data-bs-toggle="collapse" data-bs-target="#accordion-1 .item-1" aria-expanded="false" aria-controls="accordion-1 .item-1" style="color: #0e0e82;font-size: 14px;height: 0px;">Sort By</button></h2>
                                                <div class="accordion-collapse collapse item-1 sortdropdown" role="tabpanel" data-bs-parent="#accordion-1">
                                                    <div class="accordion-body"><a href="#">
                                                            <p class="d-sm-flex justify-content-sm-start bluetext thin" style="margin-bottom: 0;font-size: 8px;">Name A-Z</p>
                                                            <p class="d-sm-flex justify-content-sm-start bluetext thin" style="margin-bottom: 0;">Name Z-A</p>
                                                        </a><a href="#"></a><a href="#">
                                                            <p class="text-nowrap d-sm-flex justify-content-sm-start bluetext thin" style="margin-bottom: 0;">Date Ascending</p>
                                                            <p class="text-nowrap d-sm-flex justify-content-sm-start bluetext thin" style="margin-bottom: 0;">Date Descending</p>
                                                        </a><a href="#">
                                                            <p class="d-sm-flex justify-content-sm-start bluetext thin" style="margin-bottom: 0;">Price Low-High</p>
                                                            <p class="d-sm-flex justify-content-sm-start bluetext thin" style="margin-bottom: 0;">Price High-Low</p>
                                                        </a><a href="#"></a></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion accordion-flush collapsed" role="tablist" id="filter">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" role="tab"><button class="accordion-button collapsed sorthead" data-bs-toggle="collapse" data-bs-target="#filter .item-1" aria-expanded="false" aria-controls="filter .item-1" style="color: #0e0e82;font-size: 14px;height: 0px;"><img src="assets/Filtericon.png"></button></h2>
                                                <div class="accordion-collapse collapse item-1 sortdropdown" role="tabpanel" data-bs-parent="#filter">
                                                    <div class="accordion-body">
                                                        <div style="padding-bottom: 3px;"><a href="#">
                                                                <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0;font-size: 8px;"></p>
                                                                <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0;font-size: 8px;">Material</p>
                                                            </a>
                                                            <div class="form-check form-check-inline"><input class="form-check-input" type="checkbox" id="formCheck-1"><label class="form-check-label radio bluetext thin" for="formCheck-1">Steel</label></div>
                                                            <div class="form-check form-check-inline"><input class="form-check-input" type="checkbox" id="formCheck-2"><label class="form-check-label radio bluetext thin" for="formCheck-1">Aluminium</label></div>
                                                            <div class="form-check form-check-inline"><input class="form-check-input" type="checkbox" id="formCheck-1"><label class="form-check-label radio bluetext thin" for="formCheck-1">Other</label></div>
                                                            <div class="form-check form-check-inline"><input class="form-check-input" type="checkbox" id="formCheck-3"><label class="form-check-label radio bluetext thin" for="formCheck-1">Other</label></div>
                                                        </div>
                                                        <div style="padding-bottom: 3px;"><a href="#">
                                                                <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0;font-size: 8px;"></p>
                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0;font-size: 8px;">Certification Required</p>
                                                            </a>
                                                            <div class="form-check form-check-inline"><input class="form-check-input" type="checkbox" id="formCheck-4"><label class="form-check-label radio bluetext thin" for="formCheck-1">Yes&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</label></div>
                                                            <div class="form-check form-check-inline"><input class="form-check-input" type="checkbox" id="formCheck-3"><label class="form-check-label radio bluetext thin" for="formCheck-1">No</label></div>
                                                        </div>
                                                        <div style="padding-bottom: 3px;"><a href="#">
                                                                <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0;font-size: 8px;"></p>
                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0;font-size: 8px;">Packaging Required</p>
                                                            </a>
                                                            <div class="form-check form-check-inline"><input class="form-check-input" type="checkbox" id="formCheck-4"><label class="form-check-label radio bluetext thin" for="formCheck-1">Yes&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</label></div>
                                                            <div class="form-check form-check-inline"><input class="form-check-input" type="checkbox" id="formCheck-5"><label class="form-check-label radio bluetext thin" for="formCheck-1">No</label></div>
                                                        </div>
                                                        <div style="padding-bottom: 3px;"><a href="#">
                                                                <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0;font-size: 8px;"></p>
                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0;font-size: 8px;">Complete Supply</p>
                                                            </a>
                                                            <div class="form-check form-check-inline"><input class="form-check-input" type="checkbox" id="formCheck-6"><label class="form-check-label radio bluetext thin" for="formCheck-1">Yes&nbsp; &nbsp; &nbsp; &nbsp;</label></div>
                                                            <div class="form-check form-check-inline"><input class="form-check-input" type="checkbox" id="formCheck-5"><label class="form-check-label radio bluetext thin" for="formCheck-1">No</label></div>
                                                        </div>
                                                        <div style="padding-bottom: 3px;"><a href="#">
                                                                <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0;font-size: 8px;"></p>
                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0;font-size: 8px;">Product Identification</p>
                                                            </a>
                                                            <div class="form-check form-check-inline"><input class="form-check-input" type="checkbox" id="formCheck-6"><label class="form-check-label radio bluetext thin" for="formCheck-1">Yes&nbsp; &nbsp; &nbsp; &nbsp;</label></div>
                                                            <div class="form-check form-check-inline"><input class="form-check-input" type="checkbox" id="formCheck-7"><label class="form-check-label radio bluetext thin" for="formCheck-1">No</label></div>
                                                        </div>
                                                        <div class="d-xxl-flex justify-content-xxl-end applybtndiv"><button class="btn btn-secondary applybtn" type="button">Apply</button></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li> -->
                                        <li class="nav-item" data-bs-toggle="tooltip" data-bss-tooltip=""
                                            data-bs-placement="right" title="">
                                            <div class="greyline"></div>
                                        </li>
                                        <!-- SIDE RFQ NAV DISPLAY IF NO FILTER IS SELECTED -->
                                        <ng-container *ngIf="this.filtersUsed == false">
                                            <li class="nav-item" data-bs-toggle="tooltip" data-bss-tooltip=""
                                                data-bs-placement="right" title=""
                                                *ngFor="let heading of sortbyHeadings | filterPipe:searchrfq">

                                                <div
                                                    class="text-left text-white sideitem flex-container space-between  ">

                                                    <div
                                                        class="d-flex justify-content-between align-items-center text-nowrap">
                                                        <h1 class="flex-container space-between navlabel"
                                                            style="font-size: 16px;">
                                                            {{heading.rfqHeadName}}
                                                        </h1>
                                                        <div><button class="accordion-button collapsed sorthead"
                                                                data-bs-toggle="collapse"
                                                                [attr.data-bs-target]="'#b' + heading.id"
                                                                aria-expanded="false"
                                                                style="color: #0e0e82;font-size: 14px;height: 0px; margin-left: 5px;">
                                                                <!--  QUOTE COUNT -->
                                                                <span *ngIf="heading.openQuotesCounter == 1">
                                                                    <i style="color:red" class="fas fa-circle"></i>
                                                                </span>


                                                            </button></div>


                                                    </div>


                                                    <div class="blueline"></div>
                                                    <div [attr.id]="'b' + heading.id" class="collapse"
                                                        style="height:100px;">
                                                        <div class="scrollbox">
                                                            <div *ngFor="let rfq of heading.rfq | filterPipe:searchrfq">
                                                                <div>
                                                                    <button [disabled]="loadingRfq"
                                                                        [class.rfqbuttonsselected]=" this.rfq.SideSelectedRFQ == true"
                                                                        [class.rfqbuttons]=" this.rfq.SideSelectedRFQ == false"                                                                        
                                                                        (click)="triggerSelectRfqLoading()"                                                      
                                                                        (click)="onSelectRFQNav(rfq)"
                                                                        (click)="triggerChatOpen(false)"
                                                                        class="rfqbuttonsselected  align-items-start sidebutton bluetext"
                                                                        type="button"
                                                                        style="margin-bottom: 10px;height:auto;font-size: 14px;padding: 5px;">
                                                                        {{rfq.rfqName}}<span
                                                                            *ngIf="loadingRfq && loadingRfqSpinnerId == rfq.rfqId "
                                                                            class="spinner-border spinner-border-sm mr-1"></span>
                                                                        <br /><span style="font-size: 12px;">({{
                                                                            rfq.rfqClosingDate | date:'mediumDate'
                                                                            }})</span>
                                                                        <br /><br />
                                                                        <div class="d-flex justify-content-evenly align-items-center"
                                                                            style="margin-top: -15px;border-top-width: 25px;border-top-color: var(--textgrey);">


                                                                            <!-- IF ACTION NEEDED FROM BUYER THEN BLIP -->
                                                                            <ng-container *ngIf="blipCondition(rfq)">
                                                                                <i message-badge-bell="ss"
                                                                                    [attr.data-badge]=""
                                                                                    class="fas fa-bell"
                                                                                    style="color: var(--darkblue);"></i>
                                                                            </ng-container>

                                                                            <!-- IF NO ACTION NEEDED FROM BUYER THEN GREY BELL -->

                                                                            <ng-container *ngIf="!blipCondition(rfq)">
                                                                                <i class=" fas fa-bell"
                                                                                    style="color: var(--textgrey);"></i>
                                                                            </ng-container>

                                                                            <span
                                                                                style="width: 15px;background: var(--yellow);border-radius: 995px;height: auto;font-size: 12px;">{{
                                                                                rfq.acceptedQuotes.length}}</span>


                                                                            <!-- Messages Indicators -->
                                                                            <div>
                                                                                <div *ngIf="rfq.acceptedQuotes[0].unreadPrivateMessages == true"
                                                                                    message-badge-small=""
                                                                                    [attr.data-badge]="rfq.acceptedQuotes[0].unreadPrivateChatHistory.length">

                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                        width="1em" height="1em"
                                                                                        fill="currentColor"
                                                                                        viewBox="0 0 16 16"
                                                                                        class="bi bi-chat-right-fill"
                                                                                        style="color: var(--darkblue);">
                                                                                        <path
                                                                                            d="M14 0a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z">
                                                                                        </path>

                                                                                    </svg>

                                                                                </div>
                                                                                <div
                                                                                    *ngIf="rfq.acceptedQuotes[0].unreadPrivateMessages == false">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                        width="1em" height="1em"
                                                                                        fill="currentColor"
                                                                                        viewBox="0 0 16 16"
                                                                                        class="bi bi-chat-right-fill"
                                                                                        style="color: var(--textgrey);">
                                                                                        <path
                                                                                            d="M14 0a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z">
                                                                                        </path>

                                                                                    </svg>

                                                                                </div>

                                                                            </div>
                                                                            <div *ngIf="(rfq.unreadPublicChatReplyHistory.length > 0 || rfq.unreadPublicChatQuestionHistory.length > 0)  && rfq.unreadPublicMessages == true"
                                                                                message-badge-smaller="ss"
                                                                                [attr.data-badge]="">
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    width="1em" height="2em"
                                                                                    viewBox="0 0 20 20" fill="none"
                                                                                    style="color: var(--darkblue);">
                                                                                    <path fill-rule="evenodd"
                                                                                        clip-rule="evenodd"
                                                                                        d="M18 3C18 2.65342 17.8205 2.33156 17.5257 2.14935C17.2309 1.96714 16.8628 1.95058 16.5528 2.10557L8.76393 6H5C3.34315 6 2 7.34315 2 9C2 10.6569 3.34315 12 5 12H5.27925L7.05132 17.3162C7.18744 17.7246 7.56958 18 8.00001 18H9.00001C9.55229 18 10 17.5523 10 17V12.618L16.5528 15.8944C16.8628 16.0494 17.2309 16.0329 17.5257 15.8507C17.8205 15.6684 18 15.3466 18 15V3Z"
                                                                                        fill="currentColor"></path>
                                                                                </svg>
                                                                            </div>

                                                                            <ng-container
                                                                                *ngIf="(rfq.unreadPublicChatReplyHistory.length > 0 || rfq.unreadPublicChatQuestionHistory.length > 0 )  && rfq.unreadPublicMessages == false">
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    width="1em" height="2em"
                                                                                    viewBox="0 0 20 20" fill="none"
                                                                                    style="color: var(--darkblue);">
                                                                                    <path fill-rule="evenodd"
                                                                                        clip-rule="evenodd"
                                                                                        d="M18 3C18 2.65342 17.8205 2.33156 17.5257 2.14935C17.2309 1.96714 16.8628 1.95058 16.5528 2.10557L8.76393 6H5C3.34315 6 2 7.34315 2 9C2 10.6569 3.34315 12 5 12H5.27925L7.05132 17.3162C7.18744 17.7246 7.56958 18 8.00001 18H9.00001C9.55229 18 10 17.5523 10 17V12.618L16.5528 15.8944C16.8628 16.0494 17.2309 16.0329 17.5257 15.8507C17.8205 15.6684 18 15.3466 18 15V3Z"
                                                                                        fill="currentColor"></path>
                                                                                </svg>
                                                                            </ng-container>


                                                                            <ng-container
                                                                                *ngIf="(rfq.unreadPublicChatReplyHistory.length == 0 && rfq.unreadPublicChatQuestionHistory.length == 0) && rfq.unreadPublicMessages == false">
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    width="1em" height="2em"
                                                                                    viewBox="0 0 20 20" fill="none"
                                                                                    style="color: var(--textgrey);">
                                                                                    <path fill-rule="evenodd"
                                                                                        clip-rule="evenodd"
                                                                                        d="M18 3C18 2.65342 17.8205 2.33156 17.5257 2.14935C17.2309 1.96714 16.8628 1.95058 16.5528 2.10557L8.76393 6H5C3.34315 6 2 7.34315 2 9C2 10.6569 3.34315 12 5 12H5.27925L7.05132 17.3162C7.18744 17.7246 7.56958 18 8.00001 18H9.00001C9.55229 18 10 17.5523 10 17V12.618L16.5528 15.8944C16.8628 16.0494 17.2309 16.0329 17.5257 15.8507C17.8205 15.6684 18 15.3466 18 15V3Z"
                                                                                        fill="currentColor"></path>
                                                                                </svg>
                                                                            </ng-container>

                                                                            <ng-container
                                                                                *ngIf="(rfq.unreadPublicChatReplyHistory.length == 0 && rfq.unreadPublicChatQuestionHistory.length == 0) && rfq.unreadPublicMessages == true"
                                                                                message-badge-smaller="ss"
                                                                                [attr.data-badge]="">
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    width="1em" height="2em"
                                                                                    viewBox="0 0 20 20" fill="none"
                                                                                    style="color: var(--darkblue);">
                                                                                    <path fill-rule="evenodd"
                                                                                        clip-rule="evenodd"
                                                                                        d="M18 3C18 2.65342 17.8205 2.33156 17.5257 2.14935C17.2309 1.96714 16.8628 1.95058 16.5528 2.10557L8.76393 6H5C3.34315 6 2 7.34315 2 9C2 10.6569 3.34315 12 5 12H5.27925L7.05132 17.3162C7.18744 17.7246 7.56958 18 8.00001 18H9.00001C9.55229 18 10 17.5523 10 17V12.618L16.5528 15.8944C16.8628 16.0494 17.2309 16.0329 17.5257 15.8507C17.8205 15.6684 18 15.3466 18 15V3Z"
                                                                                        fill="currentColor"></path>
                                                                                </svg>
                                                                            </ng-container>


                                                                        </div>
                                                                    </button>


                                                                </div>

                                                            </div>


                                                        </div>

                                                    </div>

                                                </div>
                                            </li>
                                        </ng-container>


                                        <!-- SIDE RFQ NAV DISPLAY IF A FILTER IS SELECTED -->
                                        <ng-container *ngIf="this.filtersUsed == true">
                                            <li class="nav-item" data-bs-toggle="tooltip" data-bss-tooltip=""
                                                data-bs-placement="right" title=""
                                                *ngFor="let heading of filterSortbyHeadings | filterPipe:searchrfq">

                                                <div
                                                    class="text-left text-white sideitem flex-container space-between  ">

                                                    <div
                                                        class="d-flex justify-content-between align-items-center text-nowrap">
                                                        <h1 class="flex-container space-between navlabel"
                                                            style="font-size: 16px;">
                                                            {{heading.rfqHeadName}}
                                                        </h1>
                                                        <div><button class="accordion-button collapsed sorthead"
                                                                data-bs-toggle="collapse"
                                                                [attr.data-bs-target]="'#b' + heading.id"
                                                                aria-expanded="false"
                                                                style="color: #0e0e82;font-size: 14px;height: 0px; margin-left: 5px;">
                                                                <!--  QUOTE COUNT -->
                                                                <span *ngIf="heading.openQuotesCounter == 1">
                                                                    <i style="color:red" class="fas fa-circle"></i>
                                                                </span>


                                                            </button></div>


                                                    </div>


                                                    <div class="blueline"></div>
                                                    <div [attr.id]="'b' + heading.id" class="collapse"
                                                        style="height:100px;">
                                                        <div class="scrollbox">
                                                            <div *ngFor="let rfq of heading.rfq | filterPipe:searchrfq">
                                                                <div>
                                                                    <button [disabled]="loadingRfq"
                                                                        [class.rfqbuttonsselected]=" this.rfq.SideSelectedRFQ == true"
                                                                        [class.rfqbuttons]=" this.rfq.SideSelectedRFQ == false"
                                                                        (click)="triggerSelectRfqLoading()"
                                                                     
                                                                        (click)="onSelectRFQNav(rfq)"
                                                                        (click)="triggerChatOpen(false)"
                                                                        class="rfqbuttonsselected  align-items-start sidebutton bluetext"
                                                                        type="button"
                                                                        style="margin-bottom: 10px;height:auto;font-size: 14px;padding: 5px;">
                                                                        {{rfq.rfqName}}<span
                                                                            *ngIf="loadingRfq && loadingRfqSpinnerId == rfq.rfqId "
                                                                            class="spinner-border spinner-border-sm mr-1"></span>
                                                                        <br /><span style="font-size: 12px;">({{
                                                                            rfq.rfqClosingDate | date:'mediumDate'
                                                                            }})</span>
                                                                        <br /><br />
                                                                        <div class="d-flex justify-content-evenly align-items-center"
                                                                            style="margin-top: -15px;border-top-width: 25px;border-top-color: var(--textgrey);">

                                                                            <!-- IF ACTION NEEDED FROM BUYER THEN BLIP -->
                                                                            <ng-container *ngIf="blipCondition(rfq)">
                                                                                <i message-badge-bell="ss"
                                                                                    [attr.data-badge]=""
                                                                                    class="fas fa-bell"
                                                                                    style="color: var(--darkblue);"></i>
                                                                            </ng-container>

                                                                            <!-- IF NO ACTION NEEDED FROM BUYER THEN GREY BELL -->

                                                                            <ng-container *ngIf="!blipCondition(rfq)">
                                                                                <i class=" fas fa-bell"
                                                                                    style="color: var(--textgrey);"></i>
                                                                            </ng-container>

                                                                            <span
                                                                                style="width: 15px;background: var(--yellow);border-radius: 995px;height: auto;font-size: 12px;">{{
                                                                                rfq.acceptedQuotes.length}}</span>


                                                                            <!-- Messages Indicators -->
                                                                            <div>
                                                                                <div *ngIf="rfq.acceptedQuotes[0].unreadPrivateMessages == true"
                                                                                    message-badge-small=""
                                                                                    [attr.data-badge]="rfq.acceptedQuotes[0].unreadPrivateChatHistory.length">

                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                        width="1em" height="1em"
                                                                                        fill="currentColor"
                                                                                        viewBox="0 0 16 16"
                                                                                        class="bi bi-chat-right-fill"
                                                                                        style="color: var(--darkblue);">
                                                                                        <path
                                                                                            d="M14 0a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z">
                                                                                        </path>

                                                                                    </svg>

                                                                                </div>
                                                                                <div
                                                                                    *ngIf="rfq.acceptedQuotes[0].unreadPrivateMessages == false">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                        width="1em" height="1em"
                                                                                        fill="currentColor"
                                                                                        viewBox="0 0 16 16"
                                                                                        class="bi bi-chat-right-fill"
                                                                                        style="color: var(--textgrey);">
                                                                                        <path
                                                                                            d="M14 0a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z">
                                                                                        </path>

                                                                                    </svg>

                                                                                </div>

                                                                            </div>
                                                                            <div *ngIf="(rfq.unreadPublicChatReplyHistory.length > 0 || rfq.unreadPublicChatQuestionHistory.length > 0)  && rfq.unreadPublicMessages == true"
                                                                                message-badge-smaller="ss"
                                                                                [attr.data-badge]="">
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    width="1em" height="2em"
                                                                                    viewBox="0 0 20 20" fill="none"
                                                                                    style="color: var(--darkblue);">
                                                                                    <path fill-rule="evenodd"
                                                                                        clip-rule="evenodd"
                                                                                        d="M18 3C18 2.65342 17.8205 2.33156 17.5257 2.14935C17.2309 1.96714 16.8628 1.95058 16.5528 2.10557L8.76393 6H5C3.34315 6 2 7.34315 2 9C2 10.6569 3.34315 12 5 12H5.27925L7.05132 17.3162C7.18744 17.7246 7.56958 18 8.00001 18H9.00001C9.55229 18 10 17.5523 10 17V12.618L16.5528 15.8944C16.8628 16.0494 17.2309 16.0329 17.5257 15.8507C17.8205 15.6684 18 15.3466 18 15V3Z"
                                                                                        fill="currentColor"></path>
                                                                                </svg>
                                                                            </div>

                                                                            <ng-container
                                                                                *ngIf="(rfq.unreadPublicChatReplyHistory.length > 0 || rfq.unreadPublicChatQuestionHistory.length > 0 )  && rfq.unreadPublicMessages == false">
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    width="1em" height="2em"
                                                                                    viewBox="0 0 20 20" fill="none"
                                                                                    style="color: var(--darkblue);">
                                                                                    <path fill-rule="evenodd"
                                                                                        clip-rule="evenodd"
                                                                                        d="M18 3C18 2.65342 17.8205 2.33156 17.5257 2.14935C17.2309 1.96714 16.8628 1.95058 16.5528 2.10557L8.76393 6H5C3.34315 6 2 7.34315 2 9C2 10.6569 3.34315 12 5 12H5.27925L7.05132 17.3162C7.18744 17.7246 7.56958 18 8.00001 18H9.00001C9.55229 18 10 17.5523 10 17V12.618L16.5528 15.8944C16.8628 16.0494 17.2309 16.0329 17.5257 15.8507C17.8205 15.6684 18 15.3466 18 15V3Z"
                                                                                        fill="currentColor"></path>
                                                                                </svg>
                                                                            </ng-container>


                                                                            <ng-container
                                                                                *ngIf="(rfq.unreadPublicChatReplyHistory.length == 0 && rfq.unreadPublicChatQuestionHistory.length == 0) && rfq.unreadPublicMessages == false">
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    width="1em" height="2em"
                                                                                    viewBox="0 0 20 20" fill="none"
                                                                                    style="color: var(--textgrey);">
                                                                                    <path fill-rule="evenodd"
                                                                                        clip-rule="evenodd"
                                                                                        d="M18 3C18 2.65342 17.8205 2.33156 17.5257 2.14935C17.2309 1.96714 16.8628 1.95058 16.5528 2.10557L8.76393 6H5C3.34315 6 2 7.34315 2 9C2 10.6569 3.34315 12 5 12H5.27925L7.05132 17.3162C7.18744 17.7246 7.56958 18 8.00001 18H9.00001C9.55229 18 10 17.5523 10 17V12.618L16.5528 15.8944C16.8628 16.0494 17.2309 16.0329 17.5257 15.8507C17.8205 15.6684 18 15.3466 18 15V3Z"
                                                                                        fill="currentColor"></path>
                                                                                </svg>
                                                                            </ng-container>

                                                                            <ng-container
                                                                                *ngIf="(rfq.unreadPublicChatReplyHistory.length == 0 && rfq.unreadPublicChatQuestionHistory.length == 0) && rfq.unreadPublicMessages == true"
                                                                                message-badge-smaller="ss"
                                                                                [attr.data-badge]="">
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    width="1em" height="2em"
                                                                                    viewBox="0 0 20 20" fill="none"
                                                                                    style="color: var(--darkblue);">
                                                                                    <path fill-rule="evenodd"
                                                                                        clip-rule="evenodd"
                                                                                        d="M18 3C18 2.65342 17.8205 2.33156 17.5257 2.14935C17.2309 1.96714 16.8628 1.95058 16.5528 2.10557L8.76393 6H5C3.34315 6 2 7.34315 2 9C2 10.6569 3.34315 12 5 12H5.27925L7.05132 17.3162C7.18744 17.7246 7.56958 18 8.00001 18H9.00001C9.55229 18 10 17.5523 10 17V12.618L16.5528 15.8944C16.8628 16.0494 17.2309 16.0329 17.5257 15.8507C17.8205 15.6684 18 15.3466 18 15V3Z"
                                                                                        fill="currentColor"></path>
                                                                                </svg>
                                                                            </ng-container>


                                                                        </div>
                                                                    </button>


                                                                </div>

                                                            </div>


                                                        </div>

                                                    </div>

                                                </div>
                                            </li>
                                        </ng-container>

                                    </ul>
                                    <div class="text-center d-none d-md-inline"><button
                                            class="btn rounded-circle border-0" id="sidebarToggle"
                                            type="button"></button></div>
                                </div>
                            </nav>
                            <div *ngIf="!loadingRfq" class="d-flex flex-column" id="content-wrapper">
                                <div class="d-xl-flex d-xxl-flex justify-content-xl-center justify-content-xxl-center"
                                    id="content">
                                    <div class="row row-cols-1 d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                        style="margin-left: 0;margin-right: 0;width: 100%;">
                                        <div class="col-xxl-8" style="width: 100%;">
                                            <div class="row" style="margin-right: 1px;">
                                                <div class="col-lg-12 col-xl-12 col-xxl-11"
                                                    style="color: var(--darkblue);width: 100%;padding-left: 0;">
                                                    <div class="uirow bg-white rounded shadow-sm">
                                                        <div class="row g-0" style="margin-bottom: 0.5rem;">
                                                            <div class="col d-flex d-xxl-flex align-items-center align-items-xxl-center"
                                                                style="padding: 0.5rem;padding-bottom: 0px;background: var(--shadowgrey);">
                                                                <img src="assets/Group%2028.png" style="width: 42px;">
                                                                <h1 class="text-nowrap text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel"
                                                                    style="font-size: 18px;margin-bottom: 0;">&nbsp;View
                                                                    RFQ</h1>


                                                                <div div *ngIf=" paymentCheck()" class=" d-flex
                                                                d-xxl-flex justify-content-center
                                                                justify-content-xxl-center"
                                                                    style="background: var(--white);padding: 7px;border-radius: 40px;">
                                                                    <i class="fa fa-check-circle d-flex d-xl-flex d-xxl-flex align-items-center align-items-xl-center align-items-xxl-center"
                                                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 0;"></i>
                                                                    <p
                                                                        style="margin-left:5px;font-size: 12px;color: var(--darkblue);margin-bottom: -1px;font-weight: 600;">
                                                                        Paid:</p>
                                                                    <p
                                                                        style="margin-left:5px; font-size: 12px;color: var(--darkblue);margin-bottom: -1px;/*font-weight: 600;*/">
                                                                        {{selectedRFQ.acceptedQuotes[0].paymentReference}}
                                                                    </p>

                                                                </div>

                                                                <!-- <a
                                                                    class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                                    role="button"
                                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                                        class="fa fa-edit"
                                                                        style="font-size: 14px;color: var(--maingreen);"></i>&nbsp;Edit</a> -->
                                                                <button class="btn accordion-button collapsed"
                                                                    data-bs-toggle="collapse" data-bs-target=".rfqinner"
                                                                    type="button" aria-controls=""></button>
                                                            </div>
                                                        </div>
                                                        <div class="row g-0 rfqinner collapse show"
                                                            style="padding: 0.5rem;padding-top: 0;">
                                                            <div class="col-xxl-12 uidetailblock">
                                                                <div>
                                                                    <div class="row row-cols-2 rfqdetailrow"
                                                                        style="max-width: 1099px;">
                                                                        <div class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                            style="padding-right: 2rem;">
                                                                            <p class="text-nowrap bluetext"
                                                                                style="color: #0e0e82;">RFQ Name:</p>
                                                                            <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                style="margin-bottom: 0;">
                                                                                {{selectedRFQ.rfqName}}</p>
                                                                        </div>
                                                                        <div class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                            style="padding-right: 2rem;">
                                                                            <p *ngIf="selectedRFQ.deliveryRequirements == 'Collection'"
                                                                                class="text-nowrap bluetext"
                                                                                style="color: #0e0e82;">Collection
                                                                                Date:
                                                                            </p>
                                                                            <p *ngIf="selectedRFQ.deliveryRequirements == 'Delivery' || selectedRFQ.deliveryRequirements == null "
                                                                                class="text-nowrap bluetext"
                                                                                style="color: #0e0e82;">Delivery
                                                                                Date:
                                                                            </p>
                                                                            <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                style="margin-bottom: 0;">
                                                                                {{selectedRFQ.maxLeadTime|
                                                                                date:'mediumDate'}}</p>
                                                                        </div>
                                                                        <!-- <div class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                            style="padding-right: 2rem;">
                                                                            <p class="text-nowrap bluetext"
                                                                                style="color: #0e0e82;">Closes in</p>
                                                                            <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                style="margin-bottom: 0;">{{
                                                                                getClosingTime(selectedRFQ.rfqClosingDate)}}
                                                                            </p>
                                                                        </div> -->
                                                                        <div class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                            style="padding-right: 2rem;">
                                                                            <p class="text-nowrap bluetext"
                                                                                style="color: #0e0e82;">Type Of Supply:
                                                                            </p>
                                                                            <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                style="margin-bottom: 0;">
                                                                                {{selectedRFQ.typeOfSupply}}</p>
                                                                        </div>
                                                                        <div class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                            style="padding-right: 2rem;">
                                                                            <p class="text-nowrap bluetext"
                                                                                style="color: #0e0e82;">Created On:
                                                                            </p>
                                                                            <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                style="margin-bottom: 0;">
                                                                                {{selectedRFQ.createdAt |
                                                                                date:'mediumDate'}}</p>
                                                                        </div>
                                                                        <div *ngIf=" selectedRFQ.paymentRequirement != '' &&  selectedRFQ.paymentRequirement != null"
                                                                            class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                            style="padding-right: 2rem;">
                                                                            <p class="text-nowrap bluetext"
                                                                                style="color: #0e0e82;">Payment
                                                                                Requirement:</p>
                                                                            <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                style="margin-bottom: 0;">
                                                                                {{selectedRFQ.paymentRequirement}} </p>
                                                                        </div>
                                                                        <div *ngIf=" selectedRFQ.productCertificationRequirements != null"
                                                                            class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                            style="padding-right: 2rem;">
                                                                            <p class="text-nowrap bluetext"
                                                                                style="color: #0e0e82;">Test
                                                                                Certification: </p>
                                                                            <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                style="margin-bottom: 0;">
                                                                                <ng-container
                                                                                    *ngIf="selectedRFQ.productCertificationRequirements">
                                                                                    Required
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="!selectedRFQ.productCertificationRequirements">
                                                                                    Not Required
                                                                                </ng-container>
                                                                            </p>
                                                                        </div>
                                                                        <!-- <div *ngIf=" selectedRFQ.verifiedFactoryRequirements != null"
                                                                            class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                            style="padding-right: 2rem;">
                                                                            <p class="text-nowrap bluetext"
                                                                                style="color: #0e0e82;">Verified Factory
                                                                                Requirements</p>
                                                                            <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                style="margin-bottom: 0;">
                                                                                {{selectedRFQ.verifiedFactoryRequirements}}
                                                                            </p>
                                                                        </div> -->
                                                                        <div *ngIf=" selectedRFQ.isoRequirement"
                                                                            class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                            style="padding-right: 2rem;">
                                                                            <p class="text-nowrap bluetext"
                                                                                style="color: #0e0e82;">ISO Requirement:
                                                                            </p>
                                                                            <p *ngIf="selectedRFQ.isoRequirement"
                                                                                class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                style="margin-bottom: 0;">
                                                                                Required </p>
                                                                            <!-- <p *ngIf="!selectedRFQ.isoRequirement"
                                                                                class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                style="margin-bottom: 0;">
                                                                                {{selectedRFQ.isoRequirement}} </p> -->
                                                                        </div>
                                                                        <div *ngIf=" selectedRFQ.beeRequirement != '' &&  selectedRFQ.beeRequirement != null"
                                                                            class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                            style="padding-right: 2rem;">
                                                                            <p class="text-nowrap bluetext"
                                                                                style="color: #0e0e82;">B-BBEE
                                                                                Requirement:
                                                                            </p>
                                                                            <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                style="margin-bottom: 0;">
                                                                                {{selectedRFQ.beeRequirement}}</p>
                                                                        </div>
                                                                        <div *ngIf=" selectedRFQ.partnerRequirement != '' &&  selectedRFQ.partnerRequirement != null"
                                                                            class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                            style="padding-right: 2rem;">
                                                                            <p class="text-nowrap bluetext"
                                                                                style="color: #0e0e82;">Partnership
                                                                                Requirement:</p>
                                                                            <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                style="margin-bottom: 0;">
                                                                                {{selectedRFQ.partnerRequirement}}</p>
                                                                        </div>
                                                                        <div *ngIf=" selectedRFQ.packagingRequirements != ''  &&  selectedRFQ.packagingRequirements != null"
                                                                            class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                            style="padding-right: 2rem;">
                                                                            <p class="text-nowrap bluetext"
                                                                                style="color: #0e0e82;">Packaging
                                                                                Requirement:</p>
                                                                            <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                style="margin-bottom: 0;">
                                                                                {{selectedRFQ.packagingRequirements}}
                                                                            </p>
                                                                        </div>
                                                                        <div *ngIf=" selectedRFQ.supplierLocationRequirements != '' &&  selectedRFQ.supplierLocationRequirements != null"
                                                                            class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                            style="padding-right: 2rem;">
                                                                            <p class="text-nowrap bluetext"
                                                                                style="color: #0e0e82;">Location
                                                                                Requirement:</p>
                                                                            <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                style="margin-bottom: 0;">
                                                                                {{selectedRFQ.supplierLocationRequirements}}
                                                                            </p>
                                                                        </div>

                                                                        <div *ngIf="selectedRFQ.deliveryRequirements == 'Delivery' && selectedRFQ.deliveryStreetAddress != null"
                                                                            class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                            style="padding-right: 2rem;">
                                                                            <p class="text-nowrap bluetext"
                                                                                style="color: #0e0e82;"> Alternate
                                                                                Delivery
                                                                                Address 1:
                                                                            </p>
                                                                            <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                style="margin-bottom: 0;">
                                                                                {{selectedRFQ.deliveryStreetAddress}},
                                                                                {{selectedRFQ.deliveryCity}}
                                                                            </p>
                                                                        </div>

                                                                        <div *ngIf=" selectedRFQ.deliveryRequirements != '' &&  selectedRFQ.deliveryRequirements != null"
                                                                            class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                            style="padding-right: 2rem;">
                                                                            <p class="text-nowrap bluetext"
                                                                                style="color: #0e0e82;">Delivery
                                                                                Requirement:</p>
                                                                            <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                style="margin-bottom: 0;">
                                                                                {{selectedRFQ.deliveryRequirements}}</p>
                                                                        </div>


                                                                        <div *ngIf="selectedRFQ.deliveryRequirements == 'Delivery' && selectedRFQ.deliveryStreetAddress != null"
                                                                            class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                            style="padding-right: 2rem;">
                                                                            <p class="text-nowrap bluetext"
                                                                                style="color: #0e0e82;">Alternate
                                                                                Delivery
                                                                                Address 2:
                                                                            </p>
                                                                            <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                style="margin-bottom: 0;">
                                                                                {{selectedRFQ.deliveryProvince}},
                                                                                {{selectedRFQ.deliveryPostalCode}}

                                                                            </p>
                                                                        </div>

                                                                    </div>
                                                                    <div *ngIf=" selectedRFQ.otherPaymentSpecification != '' &&  selectedRFQ.otherPaymentSpecification != null"
                                                                        style="color: var(--shadowgrey);background: var(--shadowgrey);border-radius: 14px;padding: 1rem;padding-bottom: 0;margin: 1rem;margin-left: 0;max-width: 600px;">
                                                                        <div
                                                                            class="row d-flex d-xxl-flex justify-content-center justify-content-xxl-center">
                                                                            <div
                                                                                class="col d-flex d-xxl-flex justify-content-start align-items-xxl-center">
                                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                                                                    style="color: #0e0e82;">Other
                                                                                    Payment Specifications:</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row rfqdetailrow">
                                                                            <div class="col">
                                                                                <p
                                                                                    class="d-sm-flex justify-content-sm-start bluetextthin">
                                                                                    {{selectedRFQ.otherPaymentSpecification}}
                                                                                </p>
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="margin-bottom: 0;"></p>
                                                                            </div>
                                                                        </div>
                                                                        <!-- <div class="row rfqdetailrow"
                                                                        style="padding: 0px;">
                                                                        <div class="col"><a href="/">
                                                                                <p class="d-flex d-sm-flex justify-content-end justify-content-sm-start justify-content-xxl-end"
                                                                                    style="font-size: 12px;color: var(--darkblue);">
                                                                                    ... Read More</p>
                                                                            </a>
                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                style="margin-bottom: 0;"></p>
                                                                        </div>
                                                                    </div> -->
                                                                    </div>
                                                                    <div *ngIf=" selectedRFQ.otherPackagingSpecification != '' &&  selectedRFQ.otherPackagingSpecification != null"
                                                                        style="color: var(--shadowgrey);background: var(--shadowgrey);border-radius: 14px;padding: 1rem;padding-bottom: 0;margin: 1rem;margin-left: 0;max-width: 600px;">
                                                                        <div
                                                                            class="row d-flex d-xxl-flex justify-content-center justify-content-xxl-center">
                                                                            <div
                                                                                class="col d-flex d-xxl-flex justify-content-start align-items-xxl-center">
                                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                                                                    style="color: #0e0e82;">Other
                                                                                    Packaging Specificayions:</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row rfqdetailrow">
                                                                            <div class="col">
                                                                                <p
                                                                                    class="d-sm-flex justify-content-sm-start bluetextthin">
                                                                                    {{selectedRFQ.otherPackagingSpecification}}
                                                                                </p>
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="margin-bottom: 0;"></p>
                                                                            </div>
                                                                        </div>
                                                                        <!-- <div class="row rfqdetailrow"
                                                                    style="padding: 0px;">
                                                                    <div class="col"><a href="/">
                                                                            <p class="d-flex d-sm-flex justify-content-end justify-content-sm-start justify-content-xxl-end"
                                                                                style="font-size: 12px;color: var(--darkblue);">
                                                                                ... Read More</p>
                                                                        </a>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;"></p>
                                                                    </div>
                                                                </div> -->
                                                                    </div>
                                                                    <div *ngIf=" selectedRFQ.productIdentification != '' &&  selectedRFQ.productIdentification != null"
                                                                        style="color: var(--shadowgrey);background: var(--shadowgrey);border-radius: 14px;padding: 1rem;padding-bottom: 0;margin: 1rem;margin-left: 0;max-width: 600px;">
                                                                        <div
                                                                            class="row d-flex d-xxl-flex justify-content-center justify-content-xxl-center">
                                                                            <div
                                                                                class="col d-flex d-xxl-flex justify-content-start align-items-xxl-center">
                                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                                                                    style="color: #0e0e82;">Product
                                                                                    Identification Requirements:</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row rfqdetailrow">
                                                                            <div class="col">
                                                                                <p
                                                                                    class="d-sm-flex justify-content-sm-start bluetextthin">
                                                                                    {{selectedRFQ.productIdentification}}
                                                                                </p>
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="margin-bottom: 0;"></p>
                                                                            </div>
                                                                        </div>
                                                                        <!-- <div class="row rfqdetailrow"
                                                                            style="padding: 0px;">
                                                                            <div class="col"><a href="/">
                                                                                    <p class="d-flex d-sm-flex justify-content-end justify-content-sm-start justify-content-xxl-end"
                                                                                        style="font-size: 12px;color: var(--darkblue);">
                                                                                        ... Read More</p>
                                                                                </a>
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="margin-bottom: 0;"></p>
                                                                            </div>
                                                                        </div> -->
                                                                    </div>
                                                                    <div *ngIf=" selectedRFQ.additionalComments != '' &&  selectedRFQ.additionalComments != null"
                                                                        style="color: var(--shadowgrey);background: var(--shadowgrey);border-radius: 14px;padding: 1rem;padding-bottom: 0;margin: 1rem;margin-left: 0;max-width: 600px;">
                                                                        <div
                                                                            class="row d-flex d-xxl-flex justify-content-center justify-content-xxl-center">
                                                                            <div
                                                                                class="col d-flex d-xxl-flex justify-content-start align-items-xxl-center">
                                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                                                                    style="color: #0e0e82;">Additional
                                                                                    Comments:</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row rfqdetailrow">
                                                                            <div class="col">
                                                                                <p
                                                                                    class="d-sm-flex justify-content-sm-start bluetextthin">
                                                                                    {{selectedRFQ.additionalComments}}
                                                                                </p>
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="margin-bottom: 0;"></p>
                                                                            </div>
                                                                        </div>
                                                                        <!-- <div class="row rfqdetailrow"
                                                                            style="padding: 0px;">
                                                                            <div class="col"><a href="/">
                                                                                    <p class="d-flex d-sm-flex justify-content-end justify-content-sm-start justify-content-xxl-end"
                                                                                        style="font-size: 12px;color: var(--darkblue);">
                                                                                        ... Read More</p>
                                                                                </a>
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="margin-bottom: 0;"></p>
                                                                            </div>
                                                                        </div> -->
                                                                    </div>
                                                                    <div class="row d-flex d-sm-flex flex-row">
                                                                        <div
                                                                            class="col-xxl-2 text-nowrap d-flex d-xxl-flex align-items-center align-items-xxl-center">
                                                                            <p class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                                                                style="color: #0e0e82;padding-right: 18px;">
                                                                                Services Required:</p>
                                                                        </div>
                                                                        <div
                                                                            class="col-xxl-9 d-sm-flex d-lg-flex d-xxl-flex flex-row justify-content-sm-start align-items-sm-center justify-content-lg-start justify-content-xxl-start align-items-xxl-center">
                                                                            <div class="col"><button
                                                                                    *ngFor=" let service of selectedRFQ.servicesRequired | sortAscDsc: criteria "
                                                                                    class="btn text-nowrap"
                                                                                    type="button"
                                                                                    style="font-size: 12px;background: var(--mainblue);color: var(--shadowgrey);border-radius: 166px;margin: 2px;">{{service}}
                                                                                    <a class="closeicon" href="#"
                                                                                        style="color: rgb(255,255,255);"></a></button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div>
                                                                        <div *ngIf="selectedHeadingRfq.unreadPublicMessages == false"
                                                                            class="col d-flex align-items-center">
                                                                            <button data-bs-toggle="modal"
                                                                                [attr.data-bs-target]="'#bwe' + selectedRFQ.rfqId"
                                                                                class="btn btn-secondary invoicebtn"
                                                                                type="button"
                                                                                (click)="togglePeriodicReload(true)"
                                                                                (click)="togglePubChat(true)"
                                                                                style="font-size: 12px;background: var(--maingreen);border-style: none;height: 36px;">Open
                                                                                Public Chat</button>
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <div *ngIf="selectedHeadingRfq.unreadPublicMessages == true"
                                                                            class="col d-flex align-items-center">
                                                                            <button message-badge-smaller="ss"
                                                                                [attr.data-badge]=""
                                                                                data-bs-toggle="modal"
                                                                                [attr.data-bs-target]="'#bwe' + selectedRFQ.rfqId"
                                                                                class="btn btn-secondary invoicebtn"
                                                                                type="button"
                                                                                (click)="togglePeriodicReload(true)"
                                                                                (click)="togglePubChat(true)"
                                                                                style="font-size: 12px;background: var(--maingreen);border-style: none;height: 36px;">Open
                                                                                Public Chat</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="selectedRFQ.rfqFiles.length > 0 && selectedRFQ.dimensions.length == 0 "
                                                        class="uirow bg-white rounded shadow-sm"
                                                        style="margin-bottom: 0.5rem;">
                                                        <div class="row g-0" id="quoteblock">
                                                            <div class="col d-flex d-xxl-flex align-items-center align-items-xxl-center"
                                                                style="padding: 0.5rem;padding-bottom: 0px;background: var(--shadowgrey);">
                                                                <h1 class="text-nowrap text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel"
                                                                    style="font-size: 18px;margin-bottom: 0;padding: 0;">
                                                                    <img src="assets/6543.png"
                                                                        style="padding-left: 6p;width: 42px;">
                                                                </h1>
                                                                <h1 class="text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel"
                                                                    style="font-size: 18px;margin-bottom: 0;">
                                                                    &nbsp;Drawings</h1>
                                                                <!-- <a
                                                                    class="btn btn-secondary d-flex d-xxl-flex align-items-center invoicebtn"
                                                                    role="button"
                                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                                        class="fas fa-upload"
                                                                        style="font-size: 14px;color: var(--maingreen);"></i>&nbsp;Upload</a> -->
                                                                <div class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                                                    style="background: var(--white);padding: 7px;border-radius: 40px;">
                                                                    <i class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 0;"></i>
                                                                    <p
                                                                        style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;">
                                                                        &nbsp;Uploaded</p>
                                                                </div>
                                                                <!-- <p class="text-nowrap d-sm-flex justify-content-sm-start align-items-xxl-center"
                                                                    style="color: #0e0e82;margin-left: 1rem;font-size: 12px;">
                                                                    Last updated: 5 Jun ‘21 (12:34pm)</p> -->

                                                                <button class="btn accordion-button collapsed"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target=".drawingInner" type="button"
                                                                    aria-controls=""></button>
                                                            </div>
                                                        </div>
                                                        <div class="row g-0 drawingInner collapse">
                                                            <div class="col-xxl-11 uidetailblock"
                                                                style="padding: -2px;padding-top: 0px;width: 100%;">
                                                                <div>
                                                                    <div *ngIf="selectedRFQ.drawingsChangesComment != null"
                                                                        style="color: var(--shadowgrey);background: var(--shadowgrey);border-radius: 14px;padding: 1rem;padding-bottom: 0;margin: 1rem;margin-left: 0;max-width: 600px;">
                                                                        <div
                                                                            class="row d-flex d-xxl-flex justify-content-center justify-content-xxl-center">
                                                                            <div
                                                                                class="col d-flex d-xxl-flex justify-content-start align-items-xxl-center">
                                                                                <p class="text-nowrap d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                                                                    style="color: #0e0e82;">All Changes
                                                                                    to Drawings Comments</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row rfqdetailrow">
                                                                            <div class="col">
                                                                                <p
                                                                                    class="d-sm-flex justify-content-sm-start bluetextthin">
                                                                                    {{selectedRFQ.drawingsChangesComment}}
                                                                                </p>
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="margin-bottom: 0;"></p>
                                                                            </div>
                                                                        </div>
                                                                        <!-- <div class="row rfqdetailrow"
                                                                            style="padding: 0px;">
                                                                            <div class="col"><a href="/">
                                                                                    <p class="d-flex d-sm-flex justify-content-end justify-content-sm-start justify-content-xxl-end"
                                                                                        style="font-size: 12px;color: var(--darkblue);">
                                                                                        ... Read More</p>
                                                                                </a>
                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                    style="margin-bottom: 0;"></p>
                                                                            </div>
                                                                        </div> -->
                                                                    </div>
                                                                    <div
                                                                        class="d-flex d-sm-flex d-md-flex d-xxl-flex align-items-center justify-content-sm-start align-items-sm-center justify-content-md-start justify-content-xxl-start align-items-xxl-center searchdiv">
                                                                        <div class="input-group tablesearch"><input
                                                                                [(ngModel)]="searchdrawings"
                                                                                class="bg-light form-control border-0 small"
                                                                                type="text"
                                                                                placeholder="Search for ..."><button
                                                                                class="btn btn-primary py-0"
                                                                                type="button"><i
                                                                                    class="fas fa-search"></i></button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-xxl-12"
                                                                            style="margin-top: 10px;">
                                                                            <section>
                                                                                <div class="table-responsive">
                                                                                    <table
                                                                                        class="table table-sm my-0 mydatatable">
                                                                                        <thead class="buyerimage">
                                                                                            <tr class="text-start">
                                                                                                <th class="blueheadsmall"
                                                                                                    style="text-align: center;">
                                                                                                    FILE NAME</th>
                                                                                                <th class="blueheadsmall"
                                                                                                    style="text-align: center;">
                                                                                                    DATE UPLOADED</th>
                                                                                                <!-- <th class="blueheadsmall"
                                                                                                    style="text-align: center;">
                                                                                                    FILE TYPE</th>
                                                                                                <th class="blueheadsmall"
                                                                                                    style="text-align: center;">
                                                                                                    EDIT</th> -->
                                                                                                <th class="blueheadsmall"
                                                                                                    style="text-align: center;">
                                                                                                    DOWNLOAD</th>
                                                                                                <th
                                                                                                    class="blueheadsmall">
                                                                                                </th>
                                                                                                <th
                                                                                                    class="blueheadsmall">
                                                                                                </th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <ng-container
                                                                                                *ngFor="let drawFile of selectedRFQ.rfqFiles | filterPipe:searchdrawings">
                                                                                                <ng-container
                                                                                                    *ngIf="drawFile.fileType == 'Drawings'">
                                                                                                    <tr
                                                                                                        class="tablerow">
                                                                                                        <td
                                                                                                            class="text-center bluetextthin">
                                                                                                            {{drawFile.fileName}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            class="text-center bluetextthin">
                                                                                                            {{drawFile.createdAt
                                                                                                            |
                                                                                                            date:'mediumDate'}}
                                                                                                        </td>
                                                                                                        <!-- <td
                                                                                                        class="text-center bluetextthin">
                                                                                                        {{drawFile.fileType}}
                                                                                                    </td> -->
                                                                                                        <!-- <td
                                                                                                        class="text-center">
                                                                                                        <a class="btn btn-secondary invoicebtn"
                                                                                                            role="button"
                                                                                                            style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                                                                class="icon ion-edit"
                                                                                                                style="font-size: 14px;color: var(--maingreen);"></i></a>
                                                                                                    </td> -->
                                                                                                        <td class="text-nowrap"
                                                                                                            style="text-align: center;">
                                                                                                            <a class="btn btn-secondary invoicebtn"
                                                                                                                role="button"
                                                                                                                (click)="downloadDrawingFile(drawFile.rfqFileId)"
                                                                                                                style="padding:0px;color:var(--darkblue);font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                                                                    class="fa fa-download"
                                                                                                                    style="font-size: 14px;color: var(--maingreen);"></i>&nbsp;Download</a>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-container>
                                                                                            </ng-container>

                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </section>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="selectedRFQ.dimensions.length > 0 "
                                                        class="uirow bg-white rounded shadow-sm"
                                                        style="margin-bottom: 0.5rem;">
                                                        <div class="row g-0" id="quoteblock">
                                                            <div class="col d-flex d-xxl-flex align-items-center align-items-xxl-center"
                                                                style="padding: 0.5rem;padding-bottom: 0px;background: var(--shadowgrey);">
                                                                <h1 class="text-nowrap text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel"
                                                                    style="font-size: 18px;margin-bottom: 0;padding: 0;">
                                                                    <img src="assets/6543.png"
                                                                        style="padding-left: 6p;width: 42px;">
                                                                </h1>
                                                                <h1 class="text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel"
                                                                    style="font-size: 18px;margin-bottom: 0;">
                                                                    &nbsp;Dimensions</h1><button
                                                                    class="btn accordion-button collapsed"
                                                                    data-bs-toggle="collapse" data-bs-target=".dimInner"
                                                                    type="button" aria-controls=""></button>
                                                            </div>
                                                        </div>
                                                        <div class="row g-0 dimInner collapse"
                                                            style="padding: 0.5rem;padding-top: 0;">
                                                            <div class="col">
                                                                <div class="input-group tablesearch"><input
                                                                        class="bg-light form-control border-0 small"
                                                                        type="text" placeholder="Search for ..."
                                                                        [(ngModel)]="searchdimensions"><button
                                                                        class="btn btn-primary py-0" type="button"><i
                                                                            class="fas fa-search"></i></button></div>
                                                                <div class="table-responsive" style="padding: 0.5rem;">
                                                                    <table class="table table-sm my-0 mydatatable">
                                                                        <thead class="buyerimage">
                                                                            <tr class="text-start">
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">PART
                                                                                    NUMBER</th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">TYPE
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">MATERIAL
                                                                                    GRADE</th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">
                                                                                    DIMENSIONS</th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">QUANTITY
                                                                                </th>
                                                                                <th class="blueheadsmall"></th>
                                                                                <th class="blueheadsmall"></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <ng-container
                                                                                *ngFor="let dimensions of selectedRFQ.dimensions | filterPipe: searchdimensions">
                                                                                <tr class="tablerow">
                                                                                    <td
                                                                                        class="text-center bluetextthin">
                                                                                        {{dimensions.partNo}}</td>
                                                                                    <td
                                                                                        class="text-center bluetextthin">
                                                                                        {{dimensions.type}}</td>
                                                                                    <td
                                                                                        class="text-center bluetextthin">
                                                                                        {{dimensions.materialGrade}}
                                                                                    </td>
                                                                                    <td class="text-nowrap"
                                                                                        style="text-align: center;">
                                                                                        <p
                                                                                            class="d-flex justify-content-center bluetextthin">
                                                                                            {{dimensions.dimensions}}
                                                                                        </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p
                                                                                            class="d-flex justify-content-center bluetextthin">
                                                                                            {{dimensions.quantity}}</p>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-container>

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="uirow bg-white rounded shadow-sm"
                                                        style="margin-bottom: 0.5rem;">
                                                        <div class="row g-0" id="quoteblock">
                                                            <div class="col d-flex d-xxl-flex align-items-center align-items-xxl-center"
                                                                style="padding: 0.5rem;padding-bottom: 0px;background: var(--shadowgrey);">
                                                                <h1 class="text-nowrap text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel"
                                                                    style="font-size: 18px;margin-bottom: 0;padding: 0;">
                                                                    <img src="assets/232.png"
                                                                        style="padding-left: 6p;width: 42px;">
                                                                </h1>
                                                                <h1 class="text-nowrap text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel"
                                                                    style="font-size: 18px;margin-bottom: 0;">&nbsp;
                                                                    In Progress Quotes<span
                                                                        class="d-xxl-flex align-items-xxl-center bluetext"
                                                                        style="font-size: 15px !IMPORTANT;padding-left: 10PX;color: VAR(--darkblue) !IMPORTANT;">({{selectedRFQ.acceptedQuotes.length}})</span>
                                                                </h1>

                                                                <!-- <div *ngIf="selectedRFQ.acceptedQuotes[0].salesQuoteFileId != null"
                                                                    class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                                                    style="background: var(--white);padding: 7px;border-radius: 40px; margin-right: 5px;">
                                                                    <i class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 0;"></i>
                                                                    <p class="text-nowrap"
                                                                        style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;">
                                                                        &nbsp;Sales Order</p>
                                                                </div>


                                                                <div *ngIf="selectedRFQ.acceptedQuotes[0].invoiceQuoteFileId != null"
                                                                    class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                                                    style="background: var(--white);padding: 7px;border-radius: 40px; margin-right: 5px;">
                                                                    <i class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 0;"></i>
                                                                    <p class="text-nowrap"
                                                                        style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;">
                                                                        &nbsp;Final Invoice</p>
                                                                </div>

                                                                <div *ngIf="selectedRFQ.acceptedQuotes[0].salesQuoteFileId != null && selectedRFQ.purchaseOrderFileId == null "
                                                                    class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                                                    style="background: var(--white);padding: 7px;border-radius: 40px; margin-right: 5px;">
                                                                    <i class="fas fa-exclamation-circle d-xxl-flex align-items-xxl-center"
                                                                        style="border-color: var(--maingreen);color: var(--bs-orange);font-size: 15px;padding-left: 0;"></i>
                                                                    <p class="text-nowrap"
                                                                        style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;">
                                                                        &nbsp;Purchase Order Required</p>
                                                                </div> -->



                                                                <div *ngIf="selectedRFQ.acceptedQuotes[0].supplierDeliveryMade"
                                                                    style="background: var(--shadowgrey);padding-top: 1rem;padding-bottom: 1rem; margin-right: 5px;">
                                                                    <div class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                                                        style="background: var(--white);padding: 7px;border-radius: 40px;height: 31px;">
                                                                        <i class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 0;"></i>
                                                                        <p class="text-nowrap"
                                                                            style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;">
                                                                            &nbsp;Delivery Complete</p>
                                                                    </div>
                                                                </div>

                                                                <button class="btn accordion-button collapsed"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target=".quotesInner2" type="button"
                                                                    aria-controls=""></button>

                                                            </div>
                                                        </div>
                                                        <div class="row g-0 quotesInner2 collapse show">
                                                            <div class="col-xxl-11 uidetailblock"
                                                                style="padding: -2px;padding-top: 0px;width: 100%;">
                                                                <div>
                                                                    <div
                                                                        class="d-flex d-sm-flex d-md-flex d-xxl-flex align-items-center justify-content-sm-start align-items-sm-center justify-content-md-start justify-content-xxl-start align-items-xxl-center searchdiv">
                                                                        <div class="input-group tablesearch"><input
                                                                                [(ngModel)]="searchprogressquotes"
                                                                                class="bg-light form-control border-0 small"
                                                                                type="text"
                                                                                placeholder="Search for ..."><button
                                                                                class="btn btn-primary py-0"
                                                                                type="button"><i
                                                                                    class="fas fa-search"></i></button>
                                                                        </div>

                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-xxl-12">
                                                                            <section>
                                                                                <div class="table-responsive">
                                                                                    <table
                                                                                        class="table table-sm my-0 mydatatable">
                                                                                        <thead class="buyerimage">
                                                                                            <tr class="text-start">
                                                                                                <th [appSort]="selectedRFQ.acceptedQuotes"
                                                                                                    data-order="desc"
                                                                                                    data-name="quoteId"
                                                                                                    scope="col"
                                                                                                    class="blueheadsmall"
                                                                                                    style="text-align: center;">
                                                                                                    SUPPLIER<span
                                                                                                        class="fa-sort fas p-1"></span>
                                                                                                </th>

                                                                                                <th [appSort]="selectedRFQ.acceptedQuotes"
                                                                                                    data-order="desc"
                                                                                                    data-name="amount"
                                                                                                    scope="col"
                                                                                                    class="blueheadsmall"
                                                                                                    style="text-align: center;">
                                                                                                    AMOUNT(EXCL. VAT)<span
                                                                                                        class="fa-sort fas p-1"></span>
                                                                                                </th>
                                                                                                <th [appSort]="selectedRFQ.acceptedQuotes"
                                                                                                    data-order="desc"
                                                                                                    data-name="leadTime"
                                                                                                    class="blueheadsmall"
                                                                                                    style="text-align: center;">
                                                                                                    LEAD
                                                                                                    TIME<span
                                                                                                        class="fa-sort fas p-1"></span>
                                                                                                </th>
                                                                                                <th class="blueheadsmall"
                                                                                                    style="text-align: center;">
                                                                                                    CREDIT
                                                                                                </th>



                                                                                                <!-- <th class="blueheadsmall"
                                                                                                    style="text-align: center;">
                                                                                                    VERIFIED</th> -->
                                                                                                <th class="blueheadsmall"
                                                                                                    style="text-align: center;">
                                                                                                    PURCHASE ORDER
                                                                                                    <span
                                                                                                        *ngIf="selectedRFQ.acceptedQuotes[0].salesQuoteFileId != null && selectedRFQ.purchaseOrderFileId == null && selectedRFQ.acceptedQuotes[0].salesOrderDownloaded == true">
                                                                                                        <i style=" font-size: 12px; color:red"
                                                                                                            class="fas fa-circle"></i>
                                                                                                    </span>
                                                                                                </th>

                                                                                                <th class="blueheadsmall"
                                                                                                    style="text-align: center;">
                                                                                                    SERVICES QUOTED</th>
                                                                                                <th
                                                                                                    class="text-center blueheadsmall">
                                                                                                    FILES
                                                                                                    <span
                                                                                                        *ngIf="(selectedRFQ.acceptedQuotes[0].invoiceQuoteFileId != null &&  selectedRFQ.acceptedQuotes[0].invoiceOrderDownloaded == false)
                                                                                                               || (selectedRFQ.acceptedQuotes[0].salesQuoteFileId != null &&  selectedRFQ.acceptedQuotes[0].salesOrderDownloaded == false )">
                                                                                                        <i style=" font-size: 12px; color:red"
                                                                                                            class="fas fa-circle"></i>
                                                                                                    </span>


                                                                                                </th>
                                                                                                <th
                                                                                                    class="blueheadsmall text-center">
                                                                                                </th>
                                                                                                <th
                                                                                                    class="blueheadsmall">
                                                                                                </th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody
                                                                                            style="border-style: none;">
                                                                                            <ng-container
                                                                                                *ngFor="let quote of acceptedQuotes | filterPipe:searchprogressquotes">
                                                                                                <tr class="tablerow">
                                                                                                    <td
                                                                                                        class="text-center bluetextthin">
                                                                                                        {{quote.supplierDetails.name}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        class="text-center bluetextthin">
                                                                                                        {{quote.amount |
                                                                                                        currency:
                                                                                                        'R '}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        class="text-center bluetextthin">
                                                                                                        {{quote.leadTime}}
                                                                                                        days</td>

                                                                                                    <!-- IF CREDIT NOT NULL -->
                                                                                                    <ng-container
                                                                                                        *ngIf="quote.supplierDetails.creditApplication != null">


                                                                                                        <!-- IF CREDIT HAS BEEN ACCEPTED -->
                                                                                                        <ng-container
                                                                                                            *ngIf="quote.supplierDetails.creditApplication.status == 'Accepted'">
                                                                                                            <td
                                                                                                                class="text-center bluetextthin">

                                                                                                                {{quote.supplierDetails.creditApplication.period}}

                                                                                                            </td>
                                                                                                        </ng-container>

                                                                                                        <!-- IF CREDIT STATUS IS PENDING-->
                                                                                                        <ng-container
                                                                                                            *ngIf="quote.supplierDetails.creditApplication.status == 'Pending' ">

                                                                                                            <td
                                                                                                                class="text-center bluetextthin">

                                                                                                                Pending

                                                                                                            </td>

                                                                                                        </ng-container>

                                                                                                        <!-- IF CREDIT STATUS IS REJECTED-->
                                                                                                        <ng-container
                                                                                                            *ngIf="quote.supplierDetails.creditApplication.status == 'Rejected' ">

                                                                                                            <td
                                                                                                                class="text-center bluetextthin">

                                                                                                                Rejected

                                                                                                            </td>

                                                                                                        </ng-container>


                                                                                                    </ng-container>


                                                                                                    <!-- IF CREDIT STATUS IS NULL-->
                                                                                                    <ng-container
                                                                                                        *ngIf="quote.supplierDetails.creditApplication == null">
                                                                                                        <td
                                                                                                            class="text-center bluetextthin">
                                                                                                            None

                                                                                                        </td>
                                                                                                    </ng-container>



                                                                                                    <td
                                                                                                        class="text-center bluetextthin">

                                                                                                        <input
                                                                                                            #clearPOInput
                                                                                                            type="file"
                                                                                                            class="file-input"
                                                                                                            (change)="PurchaseorderSelected($event)"
                                                                                                            #PurchaseOrderUploadInput
                                                                                                            accept="{{requiredFileType}}">


                                                                                                        <button
                                                                                                            *ngIf="selectedRFQ.purchaseOrderFileId == null "
                                                                                                            (click)="salesOrderDownloadedCheck(selectedRFQ.acceptedQuotes[0].salesQuoteFileId)"
                                                                                                            [disabled]="selectedRFQ.acceptedQuotes[0].salesQuoteFileId == null || purchaseOrderUploading"
                                                                                                            class="btn btn-secondary invoicebtn"
                                                                                                            role="button"
                                                                                                            style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);padding-bottom: 3px;">
                                                                                                            <i class="fas fa-upload"
                                                                                                                style="font-size: 14px;color: var(--maingreen);"></i>
                                                                                                            Upload
                                                                                                        </button>

                                                                                                        <button
                                                                                                            *ngIf="selectedRFQ.purchaseOrderFileId != null"
                                                                                                            [disabled]="purchaseOrderDeleting || purchaseOrderUploading"
                                                                                                            (click)="downloadPurchaseOrderFile(selectedRFQ.purchaseOrderFileId)"
                                                                                                            class="btn btn-secondary invoicebtn"
                                                                                                            role="button"
                                                                                                            style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);padding-bottom: 3px;"><i
                                                                                                                class="fa fa-download"
                                                                                                                style="font-size: 14px;color: var(--maingreen);"></i>&nbsp;Download</button>

                                                                                                        <button
                                                                                                            *ngIf="selectedRFQ.purchaseOrderFileId != null"
                                                                                                            (click)="PurchaseOrderUploadInput.click()"
                                                                                                            [disabled]="purchaseOrderDeleting || purchaseOrderUploading"
                                                                                                            class="btn btn-secondary invoicebtn"
                                                                                                            role="button"
                                                                                                            style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);padding-bottom: 3px;"><i
                                                                                                                class="fa fa-edit"
                                                                                                                style="font-size: 14px;color: var(--maingreen);"></i>
                                                                                                            Edit</button>

                                                                                                        <p *ngIf="selectedRFQ.purchaseOrderFileId != null"
                                                                                                            class="d-md-flex d-lg-flex align-items-md-center justify-content-lg-center align-items-lg-center"
                                                                                                            style="font-weight: normal;font-size: 12px;color: var(--darkblue);margin-bottom: 0px;padding-left: 10px;">
                                                                                                            <i
                                                                                                                class="fa fa-file-pdf-o"></i>{{selectedRFQ.purchaseOrderFileName}}
                                                                                                        </p>

                                                                                                    </td>

                                                                                                    <td
                                                                                                        class="text-center bluetextthin">
                                                                                                        <div
                                                                                                            class="text-start d-xxl-flex justify-content-xxl-center">
                                                                                                            <div class="row row-cols-2 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-2"
                                                                                                                style="max-width: 230px;">
                                                                                                                <ng-container
                                                                                                                    *ngFor="let service of quote.servicesQuoted | sortAscDsc: criteria">
                                                                                                                    <div
                                                                                                                        class="col d-flex justify-content-center align-items-center">
                                                                                                                        <p class="text-nowrap text-start"
                                                                                                                            style="color: var(--darkblue);font-size: 12px;">
                                                                                                                            {{service}}
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </ng-container>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td
                                                                                                        class="text-center bluetextthin">
                                                                                                        <a class="btn btn-secondary invoicebtn"
                                                                                                            role="button"
                                                                                                            (click)="togglePeriodicReload(true)"
                                                                                                            data-bs-toggle="modal"
                                                                                                            [attr.data-bs-target]="'#filedownloadIP' + quote.quoteId "
                                                                                                            style="padding:0px;color:var(--darkblue);font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                                                                class="fa fa-download"
                                                                                                                style="font-size: 14px;color: var(--maingreen);"></i>&nbsp;Download</a>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <button
                                                                                                            class="btn accordion-button multi collapsed nopad"
                                                                                                            type="button"
                                                                                                            [attr.data-bs-target]="'#a' + quote.quoteId"
                                                                                                            data-bs-toggle="collapse"
                                                                                                            aria-controls="tbc2"></button>
                                                                                                    </td>
                                                                                                </tr>

                                                                                                <tr [attr.id]="'a' +  quote.quoteId"
                                                                                                    class="collapse show">
                                                                                                    <td class="blueheadsmall"
                                                                                                        colspan="9"
                                                                                                        style="width: 886px;">
                                                                                                        <div
                                                                                                            class="d-md-flex d-xl-flex align-items-xxl-center">
                                                                                                            <div class="col-5 col-xxl-6 shift"
                                                                                                                style="padding-left: 1rem;">
                                                                                                                <div>

                                                                                                                    <div
                                                                                                                        class="row">
                                                                                                                        <div
                                                                                                                            class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Company:
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-sm-6">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                {{quote.supplierDetails.name}}
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        class="row">
                                                                                                                        <div
                                                                                                                            class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Rating:
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div *ngIf="quote.supplierDetails.rating != null"
                                                                                                                            class="col">
                                                                                                                            <p class="bluetextthin "
                                                                                                                                style=" font-weight: normal; margin-bottom: 0;">

                                                                                                                                {{quote.supplierDetails.rating
                                                                                                                                |
                                                                                                                                number
                                                                                                                                :'1.0-1'}}
                                                                                                                                <i class="fa fa-star"
                                                                                                                                    style="margin-bottom: 0px; color: var(--yellow);"></i>

                                                                                                                            </p>
                                                                                                                            <!-- <p class="uiinfo"
                                                                                                                            style="font-weight: normal;">
                                                                                                                            <i class="fa fa-star"
                                                                                                                                style="color: var(--yellow);"></i><i
                                                                                                                                class="fa fa-star"
                                                                                                                                style="color: var(--yellow);"></i><i
                                                                                                                                class="fa fa-star"
                                                                                                                                style="color: var(--yellow);"></i><i
                                                                                                                                class="fa fa-star-o"
                                                                                                                                style="color: var(--yellow);"></i>
                                                                                                                        </p> -->
                                                                                                                        </div>
                                                                                                                        <div *ngIf="quote.supplierDetails.rating == null"
                                                                                                                            class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0; ">


                                                                                                                                <i class="fa fa-star"
                                                                                                                                    style="color: var(--textgrey);"></i>
                                                                                                                            </p>
                                                                                                                            <p class="uiinfo"
                                                                                                                                style="font-weight: normal;">

                                                                                                                                <!-- <ng-container *ngFor = "let i of createNumberIterator(quote.supplierDetails.rating)">
                                                                                                                        <i class="fa fa-star"
                                                                                                                            style="color: var(--yellow);"></i>
                                                                                                                        </ng-container>

                                                                                                                        <ng-container>
                                                                                                                        <i
                                                                                                                            class="fa fa-star-o"
                                                                                                                            style="color: var(--yellow);"></i>
                                                                                                                        </ng-container>
                                                                                                                             -->
                                                                                                                                <!-- <i
                                                                                                                            class="fa fa-star"
                                                                                                                            style="color: var(--yellow);"></i><i
                                                                                                                            class="fa fa-star-o"
                                                                                                                            style="color: var(--yellow);"></i><i
                                                                                                                            class="fa fa-star-o"
                                                                                                                            style="color: var(--yellow);"></i><i
                                                                                                                            class="fa fa-star-o"
                                                                                                                            style="color: var(--yellow);"></i> -->
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <ng-container>
                                                                                                                        <div
                                                                                                                            class="row">
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    Street
                                                                                                                                    Address:
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                    style="margin-bottom: 0;">
                                                                                                                                </p>
                                                                                                                                <p class="bluetextthin"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    {{quote.supplierDetails.streetAddress}}
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="row">
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    City:
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                    style="margin-bottom: 0;">
                                                                                                                                </p>
                                                                                                                                <p class="bluetextthin"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    {{quote.supplierDetails.city}}
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div
                                                                                                                            class="row">
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    Postal
                                                                                                                                    Code:
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                    style="margin-bottom: 0;">
                                                                                                                                </p>
                                                                                                                                <p class="bluetextthin"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    {{quote.supplierDetails.postalCode}}
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="row">
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    Province:
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                    style="margin-bottom: 0;">
                                                                                                                                </p>
                                                                                                                                <p class="bluetextthin"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    {{quote.supplierDetails.province}}
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </ng-container>

                                                                                                                    <ng-container
                                                                                                                        *ngIf="quote.collectionStreetAddress != null">
                                                                                                                        <div
                                                                                                                            class="row">
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    Collection
                                                                                                                                    Street
                                                                                                                                    Address:
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                    style="margin-bottom: 0;">
                                                                                                                                </p>
                                                                                                                                <p class="bluetextthin"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    {{quote.collectionStreetAddress}}
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="row">
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    Collection
                                                                                                                                    City:
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                    style="margin-bottom: 0;">
                                                                                                                                </p>
                                                                                                                                <p class="bluetextthin"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    {{quote.collectionCity}}
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div
                                                                                                                            class="row">
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    Collection
                                                                                                                                    Postal
                                                                                                                                    Code:
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                    style="margin-bottom: 0;">
                                                                                                                                </p>
                                                                                                                                <p class="bluetextthin"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    {{quote.collectionPostalCode}}
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="row">
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    Collection
                                                                                                                                    Province:
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                    style="margin-bottom: 0;">
                                                                                                                                </p>
                                                                                                                                <p class="bluetextthin"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    {{quote.collectionProvince}}
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </ng-container>

                                                                                                                </div>
                                                                                                                <div>

                                                                                                                    <!-- <div *ngIf="quote.supplierDetails.beeCertificate"
                                                                                                                        class="row">
                                                                                                                        <div
                                                                                                                            class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                B-BBEE
                                                                                                                                Requirement:
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                {{quote.supplierDetails.beeRequirements}}
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div> -->


                                                                                                                    <div *ngIf=" quote.supplierDetails.isoCertificate"
                                                                                                                        class="row">
                                                                                                                        <div
                                                                                                                            class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                ISO
                                                                                                                                Requirement:
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Yes
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!-- <div
                                                                                                                    class="row">
                                                                                                                    <div
                                                                                                                        class="col">
                                                                                                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                            style="font-weight: normal;">
                                                                                                                            BEE
                                                                                                                            level:
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        class="col">
                                                                                                                        <p class="d-sm-flex justify-content-sm-start"
                                                                                                                            style="margin-bottom: 0;">
                                                                                                                        </p>
                                                                                                                        <p class="bluetextthin"
                                                                                                                            style="font-weight: normal;">
                                                                                                                            5
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </div> -->

                                                                                                                    <div
                                                                                                                        class="row d-flex d-lg-flex align-items-center align-items-lg-center">

                                                                                                                        <!-- <div class="col d-flex d-sm-flex d-md-flex d-lg-flex justify-content-between align-items-center align-items-sm-center align-items-md-center align-items-xxl-center"
                                                                                                                            style="background: var(--shadowgrey);border-radius: 7px;width:510px;max-width: 510px;min-width: 400px;padding: 10px;">
                                                                                                                            <p class="text-nowrap d-flex d-sm-flex align-items-center justify-content-sm-start align-items-sm-center align-items-md-center align-items-lg-center align-items-xxl-center bluetext"
                                                                                                                                style="font-weight: normal;margin-bottom: 0px;">
                                                                                                                                Purchase
                                                                                                                                Order

                                                                                                                                <input
                                                                                                                                    #clearInput
                                                                                                                                    type="file"
                                                                                                                                    class="file-input"
                                                                                                                                    (change)="PurchaseorderSelected($event)"
                                                                                                                                    #PurchaseOrderUpload
                                                                                                                                    accept="{{requiredFileType}}">
                                                                                                                                <button *ngIf="selectedRFQ.purchaseOrderFileId == null"
                                                                                                                                    (click)="PurchaseOrderUpload.click()" [disabled] = "selectedRFQ.acceptedQuotes[0].salesQuoteFileId == null"
                                                                                                                                    class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                                                                                                    role="button"
                                                                                                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);padding-bottom: 3px;"><i
                                                                                                                                        class="fas fa-upload"
                                                                                                                                        style="font-size: 14px;color: var(--maingreen);"></i>
                                                                                                                                    Upload</button>



                                                                                                                                <button *ngIf="selectedRFQ.purchaseOrderFileId != null"
                                                                                                                                    (click)="PurchaseOrderUpload.click()" [disabled] = "selectedRFQ.acceptedQuotes[0].invoiceQuoteFileId != null"
                                                                                                                                    class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                                                                                                    role="button"
                                                                                                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);padding-bottom: 3px;"><i
                                                                                                                                        class="fa fa-edit"
                                                                                                                                        style="font-size: 14px;color: var(--maingreen);"></i>
                                                                                                                                    Edit</button>
                                                                                                                            </p>

                                                                                                                            
                                                                                                                            <div *ngIf = "selectedRFQ.acceptedQuotes[0].salesQuoteFileId != null && selectedRFQ.purchaseOrderFileId == null " class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                                                                                                            style="background: var(--white);padding: 7px;border-radius: 40px;">
                                                                                                                            <i class="fas fa-exclamation-circle d-xxl-flex align-items-xxl-center"
                                                                                                                                style="border-color: var(--maingreen);color: var(--bs-orange);font-size: 15px;padding-left: 0;"></i>
                                                                                                                            
                                                                                                                        </div>

                                                                                                                            <div  *ngIf="selectedRFQ.purchaseOrderFileId != null"
                                                                                                                                class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center">
                                                                                                                                <i class="fa fa-check-circle d-md-flex d-lg-flex d-xxl-flex align-items-md-center align-items-lg-center align-items-xxl-center"
                                                                                                                                    style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 10px;"></i>
                                                                                                                                <p class="d-md-flex d-xl-flex align-items-md-center align-items-xl-center"
                                                                                                                                    style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;">
                                                                                                                                     Uploaded
                                                                                                                                </p>
                                                                                                                                <p class="d-md-flex d-lg-flex align-items-md-center justify-content-lg-center align-items-lg-center"
                                                                                                                                    style="font-weight: normal;font-size: 12px;color: var(--darkblue);margin-bottom: 0px;padding-left: 10px;">
                                                                                                                                    <i
                                                                                                                                        class="fa fa-file-pdf-o"></i>{{selectedRFQ.purchaseOrderFileName}}
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div> -->


                                                                                                                    </div>


                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="d-flex d-md-flex d-xl-flex d-xxl-flex justify-content-around align-items-center align-items-lg-center align-items-xl-center align-items-xxl-start"
                                                                                                                style="width: 100%;">
                                                                                                                <!-- <div class="col-auto"
                                                                                                                    style="background: var(--shadowgrey);border-radius: 10px;padding: 0.5rem;width: 182.812px;">
                                                                                                                    <div class="row row-cols-2 d-md-flex align-items-md-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-md-3 col-lg-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                            </p>
                                                                                                                            <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Verified
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row row-cols-2 d-md-flex align-items-md-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-md-3 col-lg-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                    style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 col-xxl-8 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="text-nowrap bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Gold
                                                                                                                                Member
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row row-cols-2 d-md-flex align-items-md-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                    style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 col-xxl-9 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="text-nowrap bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Credit
                                                                                                                                Approved
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row row-cols-2 d-md-flex justify-content-xxl-start align-items-xxl-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                <i class="fa fa-times-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                    style="border-color: var(--maingreen);color: var(--mainblue);font-size: 20px;padding-left: 0;"></i>
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Manufacturer
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row row-cols-2 d-md-flex justify-content-xxl-start align-items-xxl-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <i class="fa fa-times-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                style="border-color: var(--maingreen);color: var(--mainblue);font-size: 20px;padding-left: 0;"></i>
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Packaged
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row row-cols-2 d-md-flex justify-content-xxl-start align-items-xxl-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <i class="fa fa-times-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                style="border-color: var(--maingreen);color: var(--mainblue);font-size: 20px;padding-left: 0;"></i>
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 col-xxl-9 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="text-nowrap bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Vendor
                                                                                                                                Number
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> -->
                                                                                                            </div>
                                                                                                            <div class="row g-0 d-xxl-flex"
                                                                                                                style="padding: 0;padding-right: 1rem;padding-left: 1rem;">
                                                                                                                <div
                                                                                                                    class="col d-flex flex-column justify-content-center align-items-center justify-content-sm-center justify-content-md-center justify-content-lg-center justify-content-xl-center justify-content-xxl-end">
                                                                                                                    <ng-container
                                                                                                                        *ngIf="this.unreadMessages == true">

                                                                                                                        <button
                                                                                                                            [attr.data-badge]="this.selectedHeadingRfq.acceptedQuotes[0].unreadPrivateChatHistory.length"
                                                                                                                            message-badge=""
                                                                                                                            *ngIf="!openChat"
                                                                                                                            (click)="togglePrivateChatbadge()"
                                                                                                                            (click)="Sendquotedata(sentQID)"
                                                                                                                            (click)="triggerChatOpen(true)"
                                                                                                                            (click)="this.unreadMessages = false;"
                                                                                                                            class="btn btn-secondary invoicebtn"
                                                                                                                            type="button"
                                                                                                                            style="font-size: 12px;background: var(--maingreen);border-style: none;width: 127.344px;height: 36px ; margin-bottom: 1rem;">
                                                                                                                            Open
                                                                                                                            Chat
                                                                                                                        </button>


                                                                                                                    </ng-container>


                                                                                                                    <ng-container
                                                                                                                        *ngIf="this.unreadMessages == false">

                                                                                                                        <button
                                                                                                                            *ngIf="!openChat"
                                                                                                                            (click)="Sendquotedata(sentQID)"
                                                                                                                            (click)="triggerChatOpen(true)"
                                                                                                                            (click)="this.unreadMessages = false;"
                                                                                                                            class="btn btn-secondary invoicebtn"
                                                                                                                            type="button"
                                                                                                                            style="font-size: 12px;background: var(--maingreen);border-style: none;width: 127.344px;height: 36px ; margin-bottom: 1rem;">
                                                                                                                            Open
                                                                                                                            Chat
                                                                                                                        </button>


                                                                                                                    </ng-container>

                                                                                                                    <button
                                                                                                                        *ngIf="openChat"
                                                                                                                        (click)="triggerChatOpen(false)"
                                                                                                                        class="btn btn-secondary invoicebtn"
                                                                                                                        type="button"
                                                                                                                        style="font-size: 12px;background: var(--maingreen);border-style: none;width: 127.344px;height: 36px; margin-bottom: 1rem;">
                                                                                                                        Close
                                                                                                                        Chat

                                                                                                                    </button>

                                                                                                                    <button
                                                                                                                        #markAsCompleteBtn
                                                                                                                        class="btn btn-secondary invoicebtn"
                                                                                                                        type="button"
                                                                                                                        (click)="togglePeriodicReload(true)"
                                                                                                                        style=" display: none; font-size: 12px;background: var(--maingreen);border-style: none;width: 127.344px;height: 36px;"
                                                                                                                        data-bs-toggle="modal"
                                                                                                                        data-bs-target="#rfqchecklist1">Mark
                                                                                                                        as
                                                                                                                        Complete
                                                                                                                        Hidden


                                                                                                                    </button>

                                                                                                                    <!-- if statements to prevent delivery or colection being made before supplier -->
                                                                                                                    <!-- (!selectedRFQ.acceptedQuotes[0].supplierDeliveryMade && selectedRFQ.deliveryRequirements == 'Delivery' ) -->

                                                                                                                    <ng-container
                                                                                                                        *ngIf=" (selectedRFQ.paymentRequirement != 'CBD' && (selectedRFQ.purchaseOrderFileId == null || selectedRFQ.acceptedQuotes[0].salesQuoteFileId == null))
                                                                                                                    || (selectedRFQ.paymentRequirement == 'CBD' && !selectedRFQ.acceptedQuotes[0].paymentReceived ) || selectedRFQ.acceptedQuotes[0].buyerDeliveryMade == true
                                                                                                                    || selectedRFQ.acceptedQuotes[0].buyerCollectionMade == true ">

                                                                                                                        <button
                                                                                                                            disabled="true"
                                                                                                                            class="btn btn-secondary invoicebtn"
                                                                                                                            type="button"
                                                                                                                            style="font-size: 12px;background: var(--maingreen);border-style: none;width: 127.344px;height: 36px;">

                                                                                                                            <ng-container
                                                                                                                                *ngIf="selectedRFQ.deliveryRequirements == 'Delivery'">
                                                                                                                                Mark
                                                                                                                                As
                                                                                                                                Delivered
                                                                                                                            </ng-container>

                                                                                                                            <ng-container
                                                                                                                                *ngIf="selectedRFQ.deliveryRequirements == 'Collection'">
                                                                                                                                Mark
                                                                                                                                As
                                                                                                                                Collected
                                                                                                                            </ng-container>

                                                                                                                            <!-- <span
                                                                                                                                *ngIf="checkingRfq"
                                                                                                                                class="spinner-border spinner-border-sm mr-1"></span> -->

                                                                                                                        </button>

                                                                                                                    </ng-container>

                                                                                                                    <ng-container
                                                                                                                        *ngIf="
                                                                                                                    ((selectedRFQ.paymentRequirement != 'CBD' && selectedRFQ.purchaseOrderFileId != null && selectedRFQ.acceptedQuotes[0].salesQuoteFileId != null)
                                                                                                                     || (selectedRFQ.paymentRequirement == 'CBD' && selectedRFQ.acceptedQuotes[0].paymentReceived )) 
                                                                                                                     && ((selectedRFQ.deliveryRequirements == 'Delivery' && selectedRFQ.acceptedQuotes[0].buyerDeliveryMade == false ) 
                                                                                                                    || (selectedRFQ.deliveryRequirements == 'Collection' && selectedRFQ.acceptedQuotes[0].buyerCollectionMade == false ) ) ">

                                                                                                                        <button
                                                                                                                            message-badge-small="ss"
                                                                                                                            [attr.data-badge]=""
                                                                                                                            (click)="completeRfqCheck(selectedRFQ.rfqId,'Initial')"
                                                                                                                            [disabled]="  checkingRfq"
                                                                                                                            class="btn btn-secondary invoicebtn"
                                                                                                                            type="button"
                                                                                                                            style="font-size: 12px;background: var(--maingreen);border-style: none;width: 127.344px;height: 36px;">

                                                                                                                            <ng-container
                                                                                                                                *ngIf="selectedRFQ.deliveryRequirements == 'Delivery'">
                                                                                                                                Mark
                                                                                                                                As
                                                                                                                                Delivered
                                                                                                                            </ng-container>

                                                                                                                            <ng-container
                                                                                                                                *ngIf="selectedRFQ.deliveryRequirements == 'Collection'">
                                                                                                                                Mark
                                                                                                                                As
                                                                                                                                Collected
                                                                                                                            </ng-container>

                                                                                                                            <span
                                                                                                                                *ngIf="checkingRfq"
                                                                                                                                class="spinner-border spinner-border-sm mr-1"></span>

                                                                                                                        </button>

                                                                                                                    </ng-container>


                                                                                                                    <button
                                                                                                                        #makePaymentBtnIP
                                                                                                                        data-bs-toggle="modal"
                                                                                                                        data-bs-target="#completepaymentIP"
                                                                                                                        (click)=" togglePeriodicReload(true)"
                                                                                                                        class="btn btn-secondary invoicebtn"
                                                                                                                        type="button"
                                                                                                                        style=" display: none;font-size: 12px;background: var(--maingreen);border-style: none;width: 127.344px;height: 36px;">Make
                                                                                                                        Payment
                                                                                                                        Hidden</button>


                                                                                                                    <ng-container
                                                                                                                        *ngIf=" (selectedRFQ.acceptedQuotes[0].invoiceQuoteFileId == null) ||
                                                                                                                        (selectedRFQ.acceptedQuotes[0].paymentReference != null && selectedRFQ.acceptedQuotes[0].invoiceQuoteFileId != null) 
                                                                                                                        || selectedRFQ.acceptedQuotes[0].invoiceOrderDownloaded == false 
                                                                                                                        ">
                                                                                                                        <button
                                                                                                                            disabled="true"
                                                                                                                            *ngIf="selectedRFQ.paymentRequirement == 'CBD'"
                                                                                                                            class="btn btn-secondary invoicebtn"
                                                                                                                            type="button"
                                                                                                                            style="font-size: 12px;background: var(--maingreen);border-style: none;width: 127.344px;height: 36px; margin-top: 1rem;">Make
                                                                                                                            Payment</button>
                                                                                                                    </ng-container>

                                                                                                                    <ng-container
                                                                                                                        *ngIf="selectedRFQ.acceptedQuotes[0].paymentReference == null && selectedRFQ.acceptedQuotes[0].invoiceQuoteFileId != null && selectedRFQ.acceptedQuotes[0].invoiceOrderDownloaded == true ">
                                                                                                                        <button
                                                                                                                            message-badge-small="ss"
                                                                                                                            [attr.data-badge]=""
                                                                                                                            *ngIf="selectedRFQ.paymentRequirement == 'CBD'"
                                                                                                                            class="btn btn-secondary invoicebtn"
                                                                                                                            type="button"
                                                                                                                            (click)="makePaymentCheck(selectedRFQ.rfqId, 'Initial')"
                                                                                                                            style="font-size: 12px;background: var(--maingreen);border-style: none;width: 127.344px;height: 36px; margin-top: 1rem;">Make
                                                                                                                            Payment<span
                                                                                                                                *ngIf="paymentAvailable"
                                                                                                                                class="spinner-border spinner-border-sm mr-1"></span></button>

                                                                                                                    </ng-container>

                                                                                                                    <div *ngIf="supplierAcceptPaymentCheck()"
                                                                                                                        class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                                                                                                        style="background: var(--white);padding: 7px;border-radius: 40px;height: 31px;">
                                                                                                                        <i class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                                                                                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 0;"></i>
                                                                                                                        <p
                                                                                                                            style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;width: 110.3594px;">
                                                                                                                            &nbsp;Supplier
                                                                                                                            Confirmed
                                                                                                                        </p>
                                                                                                                    </div>



                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <!-- IN PROGRESS FILE DOWNLOADS MODAL -->
                                                                                                <div role="dialog"
                                                                                                    tabindex="-1"
                                                                                                    class="modal fade"
                                                                                                    data-bs-backdrop="static"
                                                                                                    [attr.id]="'filedownloadIP' + quote.quoteId ">
                                                                                                    <div class="modal-dialog modal-lg modal-dialog-centered"
                                                                                                        role="document">
                                                                                                        <div
                                                                                                            class="modal-content">
                                                                                                            <div class="modal-header"
                                                                                                                style="background: var(--maingreen);">
                                                                                                                <h1 class="d-xl-flex align-items-xl-center whitehead"
                                                                                                                    style="font-size: 16px;margin-bottom: 0;">
                                                                                                                    Quote
                                                                                                                    File
                                                                                                                    Downloads
                                                                                                                </h1>
                                                                                                                <button
                                                                                                                    (click)="togglePeriodicReload(false)"
                                                                                                                    type="button"
                                                                                                                    class="btn-close"
                                                                                                                    data-bs-dismiss="modal"
                                                                                                                    aria-label="Close"></button>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                class="modal-body">
                                                                                                                <div>
                                                                                                                    <!-- <div
                                                                                                                        class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                                                                                                        <div class="col"
                                                                                                                            style="padding-left: 0;">
                                                                                                                            <p class="text-center d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-center justify-content-md-center justify-content-lg-center blueheadsmall"
                                                                                                                                style="margin-bottom: 0;padding-left: 32px;">
                                                                                                                                Download
                                                                                                                                the
                                                                                                                                following
                                                                                                                                files
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div> -->

                                                                                                                    <div
                                                                                                                        class="row">
                                                                                                                        <div class="col d-lg-flex justify-content-lg-start"
                                                                                                                            style="padding-bottom: 12px;">
                                                                                                                            <div class="col-xxl-12"
                                                                                                                                style="margin-top: 10px;">
                                                                                                                                <section>
                                                                                                                                    <div
                                                                                                                                        class="table-responsive">
                                                                                                                                        <table
                                                                                                                                            class="table table-sm my-0 mydatatable">
                                                                                                                                            <thead
                                                                                                                                                class="buyerimage">
                                                                                                                                                <tr
                                                                                                                                                    class="text-start">
                                                                                                                                                    <th class="blueheadsmall"
                                                                                                                                                        style="text-align: center;">
                                                                                                                                                        FILE
                                                                                                                                                        TYPE
                                                                                                                                                    </th>
                                                                                                                                                    <th class="blueheadsmall"
                                                                                                                                                        style="text-align: center;">
                                                                                                                                                        FILE
                                                                                                                                                        NAME
                                                                                                                                                    </th>
                                                                                                                                                    <th class="blueheadsmall"
                                                                                                                                                        style="text-align: center;">
                                                                                                                                                        DATE
                                                                                                                                                        UPLOADED
                                                                                                                                                    </th>
                                                                                                                                                    <!-- <th class="blueheadsmall"
                                                                                                                                                        style="text-align: center;">
                                                                                                                                                        FILE TYPE</th>
                                                                                                                                                    <th class="blueheadsmall"
                                                                                                                                                        style="text-align: center;">
                                                                                                                                                        EDIT</th> -->
                                                                                                                                                    <th class="blueheadsmall"
                                                                                                                                                        style="text-align: center;">
                                                                                                                                                        DOWNLOAD
                                                                                                                                                    </th>
                                                                                                                                                    <th
                                                                                                                                                        class="blueheadsmall">
                                                                                                                                                    </th>
                                                                                                                                                    <th
                                                                                                                                                        class="blueheadsmall">
                                                                                                                                                    </th>
                                                                                                                                                </tr>
                                                                                                                                            </thead>
                                                                                                                                            <tbody>
                                                                                                                                                <ng-container>
                                                                                                                                                    <tr
                                                                                                                                                        class="tablerow">
                                                                                                                                                        <td
                                                                                                                                                            class="text-center bluetextthin">
                                                                                                                                                            Quote
                                                                                                                                                        </td>
                                                                                                                                                        <td
                                                                                                                                                            class="text-center bluetextthin">
                                                                                                                                                            {{quote.quoteFileName}}
                                                                                                                                                        </td>

                                                                                                                                                        <td
                                                                                                                                                            class="text-center bluetextthin">
                                                                                                                                                            {{quote.quoteCreatedAt
                                                                                                                                                            |
                                                                                                                                                            date:'mediumDate'}}

                                                                                                                                                        </td>


                                                                                                                                                        <td class="text-nowrap"
                                                                                                                                                            style="text-align: center;">
                                                                                                                                                            <button
                                                                                                                                                                class="btn btn-secondary invoicebtn"
                                                                                                                                                                role="button"
                                                                                                                                                                [disabled]="downloadingQuoteFile"
                                                                                                                                                                (click)="downloadIPQuoteFiles(quote.quoteFileId,quote.quoteId,'Quotes')"
                                                                                                                                                                style="padding:0px;color:var(--darkblue);font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                                                                                                                    class="fa fa-download"
                                                                                                                                                                    style="font-size: 14px;color: var(--maingreen);"></i>&nbsp;Download</button>
                                                                                                                                                        </td>
                                                                                                                                                    </tr>
                                                                                                                                                    <tr
                                                                                                                                                        class="tablerow">
                                                                                                                                                        <td
                                                                                                                                                            class="text-center bluetextthin">
                                                                                                                                                            Sales
                                                                                                                                                            Order
                                                                                                                                                        </td>
                                                                                                                                                        <td
                                                                                                                                                            class="text-center bluetextthin">
                                                                                                                                                            <ng-container
                                                                                                                                                                *ngIf="quote.salesQuoteFileId == null">
                                                                                                                                                                Awaiting
                                                                                                                                                                Upload
                                                                                                                                                            </ng-container>
                                                                                                                                                            {{quote.salesOrderFileName}}
                                                                                                                                                        </td>

                                                                                                                                                        <td
                                                                                                                                                            class="text-center bluetextthin">
                                                                                                                                                            {{quote.salesOrderCreatedAt
                                                                                                                                                            |
                                                                                                                                                            date:'mediumDate'}}
                                                                                                                                                        </td>


                                                                                                                                                        <td class="text-nowrap"
                                                                                                                                                            style="text-align: center;">
                                                                                                                                                            <button
                                                                                                                                                                class="btn btn-secondary invoicebtn"
                                                                                                                                                                role="button"
                                                                                                                                                                [disabled]="quote.salesQuoteFileId == null"
                                                                                                                                                                (click)="downloadSalesOrderFile(quote.salesQuoteFileId,quote.quoteId)"
                                                                                                                                                                style="padding:0px;color:var(--darkblue);font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color:
                                                                                                                                                                                    var(--shadowgrey);"><i
                                                                                                                                                                    class="fa fa-download"
                                                                                                                                                                    style="font-size: 14px;color: var(--maingreen);"></i>&nbsp;Download</button>

                                                                                                                                                        </td>

                                                                                                                                                    </tr>
                                                                                                                                                    <tr
                                                                                                                                                        class="tablerow">
                                                                                                                                                        <td
                                                                                                                                                            class="text-center bluetextthin">
                                                                                                                                                            Invoice
                                                                                                                                                        </td>
                                                                                                                                                        <td
                                                                                                                                                            class="text-center bluetextthin">
                                                                                                                                                            <ng-container
                                                                                                                                                                *ngIf="quote.invoiceQuoteFileId == null">
                                                                                                                                                                Awaiting
                                                                                                                                                                Upload
                                                                                                                                                            </ng-container>
                                                                                                                                                            {{quote.invoiceOrderFileName}}
                                                                                                                                                        </td>
                                                                                                                                                        <td
                                                                                                                                                            class="text-center bluetextthin">
                                                                                                                                                            {{quote.invoiceCreatedAt
                                                                                                                                                            |
                                                                                                                                                            date:'mediumDate'}}

                                                                                                                                                        </td>


                                                                                                                                                        <td class="text-nowrap"
                                                                                                                                                            style="text-align: center;">
                                                                                                                                                            <button
                                                                                                                                                                class="btn btn-secondary invoicebtn"
                                                                                                                                                                role="button"
                                                                                                                                                                [disabled]="quote.invoiceQuoteFileId == null || downloadingInvoiceFile"
                                                                                                                                                                (click)="downloadIPQuoteFiles(quote.invoiceQuoteFileId,quote.quoteId,'Invoices')"
                                                                                                                                                                style="padding:0px;color:var(--darkblue);font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color:
                                                                                                                                                                                    var(--shadowgrey);"><i
                                                                                                                                                                    class="fa fa-download"
                                                                                                                                                                    style="font-size: 14px;color: var(--maingreen);"></i>&nbsp;Download</button>
                                                                                                                                                        </td>
                                                                                                                                                    </tr>
                                                                                                                                                    <ng-container
                                                                                                                                                        *ngIf="selectedRFQ.productCertificationRequirements">
                                                                                                                                                        <tr
                                                                                                                                                            class="tablerow">
                                                                                                                                                            <td
                                                                                                                                                                class="text-center bluetextthin">
                                                                                                                                                                Test
                                                                                                                                                                Certification
                                                                                                                                                            </td>
                                                                                                                                                            <td
                                                                                                                                                                class="text-center bluetextthin">
                                                                                                                                                                <ng-container
                                                                                                                                                                    *ngIf="quote.productCertificationQuoteFileId == null">
                                                                                                                                                                    Awaiting
                                                                                                                                                                    Upload
                                                                                                                                                                </ng-container>
                                                                                                                                                                {{quote.productCertificationFileName}}
                                                                                                                                                            </td>
                                                                                                                                                            <td
                                                                                                                                                                class="text-center bluetextthin">

                                                                                                                                                                {{quote.productCertCreatedAt
                                                                                                                                                                |
                                                                                                                                                                date:'mediumDate'}}
                                                                                                                                                            </td>


                                                                                                                                                            <td class="text-nowrap"
                                                                                                                                                                style="text-align: center;">
                                                                                                                                                                <button
                                                                                                                                                                    class="btn btn-secondary invoicebtn"
                                                                                                                                                                    (click)="downloadProductCertificationFile(quote.productCertificationQuoteFileId,quote.quoteId)"
                                                                                                                                                                    [disabled]="quote.productCertificationQuoteFileId == null"
                                                                                                                                                                    role="button"
                                                                                                                                                                    style="padding:0px;color:var(--darkblue);font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color:
                                                                                                                                                                            var(--shadowgrey);"><i
                                                                                                                                                                        class="fa fa-download"
                                                                                                                                                                        style="font-size: 14px;color: var(--maingreen);"></i>&nbsp;Download</button>
                                                                                                                                                            </td>
                                                                                                                                                        </tr>
                                                                                                                                                    </ng-container>

                                                                                                                                                </ng-container>

                                                                                                                                            </tbody>
                                                                                                                                        </table>
                                                                                                                                    </div>
                                                                                                                                </section>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                    <!-- <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow"
                                                                                                                        style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;">
                                                                                                                        <div
                                                                                                                            class="col">
                                                                                                                            <p class="text-nowrap"
                                                                                                                                style="font-weight: normal;font-size: 12px;">
                                                                                                                                2/4
                                                                                                                                files
                                                                                                                                selected
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div> -->
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                class="modal-footer d-lg-flex align-items-lg-center">
                                                                                                                <!-- <a class="btn text-gray-500"
                                                                                                                    role="button" 
                                                                                                                    data-bs-dismiss="modal">Close</a> -->

                                                                                                                <!-- <a
                                                                                                                    
                                                                                                                    class="btn d-flex d-lg-flex justify-content-center align-items-center justify-content-lg-center align-items-lg-center yellowbtn"
                                                                                                                    role="button"
                                                                                                                    style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Download</a> -->
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </ng-container>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </section>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="uirow bg-white rounded shadow-sm">
                                                        <div class="row g-0" id="quoteblock">
                                                            <div class="col d-flex d-xxl-flex align-items-center align-items-xxl-center"
                                                                style="padding: 0.5rem;padding-bottom: 0px;background: var(--shadowgrey);color: var(--darkblue);">
                                                                <h1 class="text-nowrap text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel"
                                                                    style="font-size: 18px;margin-bottom: 0;padding: 0;">
                                                                    <img src="assets/33333.png"
                                                                        style="padding-left: 6p;width: 42px;">
                                                                </h1>
                                                                <h1 class="text-nowrap text-start d-flex d-sm-flex d-md-flex d-lg-flex d-xxl-flex align-items-center justify-content-sm-start justify-content-md-start justify-content-lg-start align-items-xl-center justify-content-xxl-start uiblocklabel"
                                                                    style="font-size: 18px;margin-bottom: 0;">
                                                                    &nbsp;Other Quotes<span
                                                                        class="d-xxl-flex align-items-xxl-center bluetext"
                                                                        style="font-size: 15px !IMPORTANT;padding-left: 10PX;color: VAR(--darkblue) !IMPORTANT;">({{selectedRFQ.openQuotes.length}})</span>
                                                                </h1><button class="btn accordion-button collapsed"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target=".PquotesInner2" type="button"
                                                                    aria-controls=""></button>
                                                            </div>
                                                        </div>
                                                        <div class="row g-0 PquotesInner2 collapse">
                                                            <div class="col-xxl-11 uidetailblock"
                                                                style="padding: -2px;padding-top: 0px;width: 100%;">
                                                                <div>
                                                                    <div
                                                                        class="d-flex d-sm-flex d-md-flex d-xxl-flex align-items-center justify-content-sm-start align-items-sm-center justify-content-md-start justify-content-xxl-start align-items-xxl-center searchdiv">
                                                                        <div class="input-group tablesearch"><input
                                                                                [(ngModel)]="searchpendingquotes"
                                                                                class="bg-light form-control border-0 small"
                                                                                type="text"
                                                                                placeholder="Search for ..."><button
                                                                                class="btn btn-primary py-0"
                                                                                type="button"><i
                                                                                    class="fas fa-search"></i></button>
                                                                        </div>

                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-xxl-12">
                                                                            <section>
                                                                                <div class="table-responsive">
                                                                                    <table
                                                                                        class="table table-sm my-0 mydatatable">
                                                                                        <thead class="buyerimage">
                                                                                            <tr class="text-start">
                                                                                                <th [appSort]="selectedRFQ.acceptedQuotes"
                                                                                                    data-order="desc"
                                                                                                    data-name="quoteId"
                                                                                                    scope="col"
                                                                                                    class="blueheadsmall"
                                                                                                    style="text-align: center;">
                                                                                                    SUPPLIER<span
                                                                                                        class="fa-sort fas p-1"></span>
                                                                                                </th>

                                                                                                <th [appSort]="selectedRFQ.acceptedQuotes"
                                                                                                    data-order="desc"
                                                                                                    data-name="amount"
                                                                                                    scope="col"
                                                                                                    class="blueheadsmall"
                                                                                                    style="text-align: center;">
                                                                                                    AMOUNT(EXCL. VAT)<span
                                                                                                        class="fa-sort fas p-1"></span>
                                                                                                </th>
                                                                                                <th [appSort]="selectedRFQ.acceptedQuotes"
                                                                                                    data-order="desc"
                                                                                                    data-name="leadTime"
                                                                                                    class="blueheadsmall"
                                                                                                    style="text-align: center;">
                                                                                                    LEAD
                                                                                                    TIME<span
                                                                                                        class="fa-sort fas p-1"></span>
                                                                                                </th>

                                                                                                <th class="blueheadsmall"
                                                                                                    style="text-align: center;">
                                                                                                    CREDIT
                                                                                                </th>



                                                                                                <!-- <th class="blueheadsmall"
                                                                                                    style="text-align: center;">
                                                                                                    VERIFIED</th> -->
                                                                                                <!-- <th class="blueheadsmall"
                                                                                                    style="text-align: center;">
                                                                                                    PO</th> -->
                                                                                                <th class="blueheadsmall"
                                                                                                    style="text-align: center;">
                                                                                                    SERVICES QUOTED</th>
                                                                                                <th
                                                                                                    class="text-center blueheadsmall">
                                                                                                    FILES</th>
                                                                                                <th
                                                                                                    class="blueheadsmall text-center">
                                                                                                </th>
                                                                                                <th
                                                                                                    class="blueheadsmall">
                                                                                                </th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody
                                                                                            style="border-style: none;">
                                                                                            <ng-container
                                                                                                *ngFor="let quote of openQuotes | filterPipe: searchpendingquotes">
                                                                                                <tr class="tablerow">
                                                                                                    <td
                                                                                                        class="text-center bluetextthin">
                                                                                                        {{quote.supplierDetails.name}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        class="text-center bluetextthin">
                                                                                                        {{quote.amount |
                                                                                                        currency:
                                                                                                        'R '}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        class="text-center bluetextthin">
                                                                                                        {{quote.leadTime}}
                                                                                                        days</td>
                                                                                                    <!-- <td
                                                                                                        class="text-center">
                                                                                                        <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                                                    </td> -->

                                                                                                    <!-- <td
                                                                                                        class="text-center">
                                                                                                        <i class="fa fa-circle d-xxl-flex justify-content-xxl-center"
                                                                                                            style="border-color: var(--maingreen);color: var(--textgrey);font-size: 20px;padding-left: 0;"></i>

                                                                                                    </td> -->


                                                                                                    <!-- IF CREDIT NOT NULL -->
                                                                                                    <ng-container
                                                                                                        *ngIf="quote.supplierDetails.creditApplication != null">


                                                                                                        <!-- IF CREDIT HAS BEEN ACCEPTED -->
                                                                                                        <ng-container
                                                                                                            *ngIf="quote.supplierDetails.creditApplication.status == 'Accepted'">
                                                                                                            <td
                                                                                                                class="text-center bluetextthin">

                                                                                                                {{quote.supplierDetails.creditApplication.period}}

                                                                                                            </td>
                                                                                                        </ng-container>

                                                                                                        <!-- IF CREDIT STATUS IS PENDING-->
                                                                                                        <ng-container
                                                                                                            *ngIf="quote.supplierDetails.creditApplication.status == 'Pending' ">

                                                                                                            <td
                                                                                                                class="text-center bluetextthin">

                                                                                                                Pending

                                                                                                            </td>

                                                                                                        </ng-container>

                                                                                                        <!-- IF CREDIT STATUS IS REJECTED-->
                                                                                                        <ng-container
                                                                                                            *ngIf="quote.supplierDetails.creditApplication.status == 'Rejected' ">

                                                                                                            <td
                                                                                                                class="text-center bluetextthin">

                                                                                                                Rejected

                                                                                                            </td>

                                                                                                        </ng-container>


                                                                                                    </ng-container>


                                                                                                    <!-- IF CREDIT STATUS IS NULL-->
                                                                                                    <ng-container
                                                                                                        *ngIf="quote.supplierDetails.creditApplication == null">
                                                                                                        <td
                                                                                                            class="text-center bluetextthin">
                                                                                                            None

                                                                                                        </td>
                                                                                                    </ng-container>





                                                                                                    <td
                                                                                                        class="text-center bluetextthin">
                                                                                                        <div
                                                                                                            class="text-start d-xxl-flex justify-content-xxl-center">
                                                                                                            <div class="row row-cols-2 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-2"
                                                                                                                style="max-width: 230px;">
                                                                                                                <ng-container
                                                                                                                    *ngFor="let service of quote.servicesQuoted | sortAscDsc: criteria">
                                                                                                                    <div
                                                                                                                        class="col d-flex justify-content-center align-items-center">
                                                                                                                        <p class="text-nowrap text-start"
                                                                                                                            style="color: var(--darkblue);font-size: 12px;">
                                                                                                                            {{service}}
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </ng-container>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td
                                                                                                        class="text-center bluetextthin">
                                                                                                        <a class="btn btn-secondary invoicebtn"
                                                                                                            role="button"
                                                                                                            (click)="togglePeriodicReload(true)"
                                                                                                            data-bs-toggle="modal"
                                                                                                            [attr.data-bs-target]="'#pendingfiledownload' + quote.quoteId "
                                                                                                            style="padding:0px;color:var(--darkblue);font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color:
                                                                                                                                                                                    var(--shadowgrey);"><i
                                                                                                                class="fa fa-download"
                                                                                                                style="font-size: 14px;color: var(--maingreen);"></i>&nbsp;Download</a>
                                                                                                    </td>
                                                                                                    <td><button
                                                                                                            class="btn accordion-button multi collapsed nopad"
                                                                                                            type="button"
                                                                                                            [attr.data-bs-target]="'#a' + quote.quoteId"
                                                                                                            data-bs-toggle="collapse"
                                                                                                            aria-controls="tbc2"></button>
                                                                                                    </td>
                                                                                                </tr>

                                                                                                <tr [attr.id]="'a' +  quote.quoteId"
                                                                                                    class="collapse">
                                                                                                    <td class="blueheadsmall"
                                                                                                        colspan="9"
                                                                                                        style="width: 886px;">
                                                                                                        <div
                                                                                                            class="d-md-flex d-xl-flex align-items-xxl-center">
                                                                                                            <div class="col-5 col-xxl-6 shift"
                                                                                                                style="padding-left: 1rem;">
                                                                                                                <div>

                                                                                                                    <div
                                                                                                                        class="row">
                                                                                                                        <div
                                                                                                                            class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Company:
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-sm-6">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                {{quote.supplierDetails.name}}
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div *ngIf="quote.supplierDetails.rating != null"
                                                                                                                        class="row">
                                                                                                                        <div
                                                                                                                            class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Rating:
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col">
                                                                                                                            <p class="bluetextthin "
                                                                                                                                style=" font-weight: normal; margin-bottom: 0;">

                                                                                                                                {{quote.supplierDetails.rating
                                                                                                                                |
                                                                                                                                number
                                                                                                                                :'1.0-1'}}
                                                                                                                                <i class="fa fa-star"
                                                                                                                                    style=" color: var(--yellow);"></i>

                                                                                                                            </p>
                                                                                                                            <!-- <p class="uiinfo"
                                                                                                                            style="font-weight: normal;">
                                                                                                                            <i class="fa fa-star"
                                                                                                                                style="color: var(--yellow);"></i><i
                                                                                                                                class="fa fa-star"
                                                                                                                                style="color: var(--yellow);"></i><i
                                                                                                                                class="fa fa-star"
                                                                                                                                style="color: var(--yellow);"></i><i
                                                                                                                                class="fa fa-star-o"
                                                                                                                                style="color: var(--yellow);"></i>
                                                                                                                        </p> -->
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div *ngIf="quote.supplierDetails.rating == null"
                                                                                                                        class="col">
                                                                                                                        <p class="d-sm-flex justify-content-sm-start"
                                                                                                                            style="margin-bottom: 0; ">


                                                                                                                            <i class="fa fa-star"
                                                                                                                                style="color: var(--textgrey);"></i>
                                                                                                                        </p>
                                                                                                                        <p class="uiinfo"
                                                                                                                            style="font-weight: normal;">

                                                                                                                            <!-- <ng-container *ngFor = "let i of createNumberIterator(quote.supplierDetails.rating)">
                                                                                                                    <i class="fa fa-star"
                                                                                                                        style="color: var(--yellow);"></i>
                                                                                                                    </ng-container>

                                                                                                                    <ng-container>
                                                                                                                    <i
                                                                                                                        class="fa fa-star-o"
                                                                                                                        style="color: var(--yellow);"></i>
                                                                                                                    </ng-container>
                                                                                                                         -->
                                                                                                                            <!-- <i
                                                                                                                        class="fa fa-star"
                                                                                                                        style="color: var(--yellow);"></i><i
                                                                                                                        class="fa fa-star-o"
                                                                                                                        style="color: var(--yellow);"></i><i
                                                                                                                        class="fa fa-star-o"
                                                                                                                        style="color: var(--yellow);"></i><i
                                                                                                                        class="fa fa-star-o"
                                                                                                                        style="color: var(--yellow);"></i> -->
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    <ng-container>
                                                                                                                        <div
                                                                                                                            class="row">
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    Street
                                                                                                                                    Address:
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                    style="margin-bottom: 0;">
                                                                                                                                </p>
                                                                                                                                <p class="bluetextthin"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    {{quote.supplierDetails.streetAddress}}
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="row">
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    City:
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                    style="margin-bottom: 0;">
                                                                                                                                </p>
                                                                                                                                <p class="bluetextthin"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    {{quote.supplierDetails.city}}
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div
                                                                                                                            class="row">
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    Postal
                                                                                                                                    Code:
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                    style="margin-bottom: 0;">
                                                                                                                                </p>
                                                                                                                                <p class="bluetextthin"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    {{quote.supplierDetails.postalCode}}
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="row">
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    Province:
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                    style="margin-bottom: 0;">
                                                                                                                                </p>
                                                                                                                                <p class="bluetextthin"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    {{quote.supplierDetails.province}}
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </ng-container>

                                                                                                                    <ng-container
                                                                                                                        *ngIf="quote.collectionStreetAddress != null">
                                                                                                                        <div
                                                                                                                            class="row">
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    Collection
                                                                                                                                    Street
                                                                                                                                    Address:
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                    style="margin-bottom: 0;">
                                                                                                                                </p>
                                                                                                                                <p class="bluetextthin"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    {{quote.collectionStreetAddress}}
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="row">
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    Collection
                                                                                                                                    City:
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                    style="margin-bottom: 0;">
                                                                                                                                </p>
                                                                                                                                <p class="bluetextthin"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    {{quote.collectionCity}}
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div
                                                                                                                            class="row">
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    Collection
                                                                                                                                    Postal
                                                                                                                                    Code:
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                    style="margin-bottom: 0;">
                                                                                                                                </p>
                                                                                                                                <p class="bluetextthin"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    {{quote.collectionPostalCode}}
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="row">
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    Collection
                                                                                                                                    Province:
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                class="col">
                                                                                                                                <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                    style="margin-bottom: 0;">
                                                                                                                                </p>
                                                                                                                                <p class="bluetextthin"
                                                                                                                                    style="font-weight: normal;">
                                                                                                                                    {{quote.collectionProvince}}
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </ng-container>

                                                                                                                </div>
                                                                                                                <div>

                                                                                                                    <!-- <div *ngIf="quote.supplierDetails.beeCertificate"
                                                                                                                        class="row">
                                                                                                                        <div
                                                                                                                            class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                B-BBEE
                                                                                                                                Requirement:
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                {{quote.supplierDetails.beeRequirements}}
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div> -->

                                                                                                                    <div *ngIf=" quote.supplierDetails.isoCertificate"
                                                                                                                        class="row">
                                                                                                                        <div
                                                                                                                            class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                ISO
                                                                                                                                Requirement:
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Yes
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!-- <div
                                                                                                                        class="row">
                                                                                                                        <div
                                                                                                                            class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                BEE
                                                                                                                                level:
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                5
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div> -->

                                                                                                                </div>





                                                                                                            </div>
                                                                                                            <div class="d-flex d-md-flex d-xl-flex d-xxl-flex justify-content-around align-items-center align-items-lg-center align-items-xl-center align-items-xxl-start"
                                                                                                                style="width: 100%;">
                                                                                                                <!-- <div class="col-auto"
                                                                                                                    style="background: var(--shadowgrey);border-radius: 10px;padding: 0.5rem;width: 182.812px;">
                                                                                                                    <div class="row row-cols-2 d-md-flex align-items-md-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-md-3 col-lg-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                            </p>
                                                                                                                            <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Verified
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row row-cols-2 d-md-flex align-items-md-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-md-3 col-lg-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                    style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 col-xxl-8 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="text-nowrap bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Gold
                                                                                                                                Member
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row row-cols-2 d-md-flex align-items-md-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                    style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 col-xxl-9 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="text-nowrap bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Credit
                                                                                                                                Approved
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row row-cols-2 d-md-flex justify-content-xxl-start align-items-xxl-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                <i class="fa fa-times-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                    style="border-color: var(--maingreen);color: var(--mainblue);font-size: 20px;padding-left: 0;"></i>
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Manufacturer
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row row-cols-2 d-md-flex justify-content-xxl-start align-items-xxl-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <i class="fa fa-times-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                style="border-color: var(--maingreen);color: var(--mainblue);font-size: 20px;padding-left: 0;"></i>
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Packaged
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="row row-cols-2 d-md-flex justify-content-xxl-start align-items-xxl-center"
                                                                                                                        style="padding: 5px;margin-left: 0;padding-bottom: 0px;padding-top: 5px;">
                                                                                                                        <div
                                                                                                                            class="col-1 col-sm-3 col-xxl-2 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                                                                                                            <i class="fa fa-times-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                                style="border-color: var(--maingreen);color: var(--mainblue);font-size: 20px;padding-left: 0;"></i>
                                                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                                                style="font-weight: normal;">
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            class="col-3 col-md-7 col-xxl-9 d-xxl-flex justify-content-xxl-start align-items-xxl-center">
                                                                                                                            <p class="d-sm-flex justify-content-sm-start"
                                                                                                                                style="margin-bottom: 0;">
                                                                                                                            </p>
                                                                                                                            <p class="text-nowrap bluetextthin"
                                                                                                                                style="font-weight: normal;">
                                                                                                                                Vendor
                                                                                                                                Number
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> -->
                                                                                                                <!-- <button
                                                                                                                    class="btn btn-secondary invoicebtn"
                                                                                                                    type="button"
                                                                                                                    style="font-size: 12px;background: var(--maingreen);border-style: none;width: 163px;height: 36px;">Accept
                                                                                                                    Quote</button> -->
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <!-- Other quotes FILE DOWNLOADS MODAL -->
                                                                                                <div role="dialog"
                                                                                                    tabindex="-1"
                                                                                                    class="modal fade"
                                                                                                    data-bs-backdrop="static"
                                                                                                    [attr.id]="'pendingfiledownload' + quote.quoteId ">
                                                                                                    <div class="modal-dialog modal-lg modal-dialog-centered"
                                                                                                        role="document">
                                                                                                        <div
                                                                                                            class="modal-content">
                                                                                                            <div class="modal-header"
                                                                                                                style="background: var(--maingreen);">
                                                                                                                <h1 class="d-xl-flex align-items-xl-center whitehead"
                                                                                                                    style="font-size: 16px;margin-bottom: 0;">
                                                                                                                    Quote
                                                                                                                    File
                                                                                                                    Downloads
                                                                                                                </h1>
                                                                                                                <button
                                                                                                                    (click)="togglePeriodicReload(false)"
                                                                                                                    type="button"
                                                                                                                    class="btn-close"
                                                                                                                    data-bs-dismiss="modal"
                                                                                                                    aria-label="Close"></button>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                class="modal-body">
                                                                                                                <div>
                                                                                                                    <!-- <div
                                                                                                                        class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                                                                                                        <div class="col"
                                                                                                                            style="padding-left: 0;">
                                                                                                                            <p class="text-center d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-center justify-content-md-center justify-content-lg-center blueheadsmall"
                                                                                                                                style="margin-bottom: 0;padding-left: 32px;">
                                                                                                                                Download
                                                                                                                                the
                                                                                                                                following
                                                                                                                                files
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div> -->
                                                                                                                    <form
                                                                                                                        [formGroup]="otherQuoteform">

                                                                                                                        <div
                                                                                                                            class="row">
                                                                                                                            <div class="col d-lg-flex justify-content-lg-start"
                                                                                                                                style="padding-left: 0;padding-bottom: 12px;">
                                                                                                                                <div class="col-xxl-12"
                                                                                                                                    style="margin-top: 10px;">
                                                                                                                                    <section>
                                                                                                                                        <div
                                                                                                                                            class="table-responsive">
                                                                                                                                            <table
                                                                                                                                                class="table table-sm my-0 mydatatable">
                                                                                                                                                <thead
                                                                                                                                                    class="buyerimage">
                                                                                                                                                    <tr
                                                                                                                                                        class="text-start">
                                                                                                                                                        <th class="blueheadsmall"
                                                                                                                                                            style="text-align: center;">
                                                                                                                                                            FILE
                                                                                                                                                            TYPE
                                                                                                                                                        </th>
                                                                                                                                                        <th class="blueheadsmall"
                                                                                                                                                            style="text-align: center;">
                                                                                                                                                            FILE
                                                                                                                                                            NAME
                                                                                                                                                        </th>
                                                                                                                                                        <th class="blueheadsmall"
                                                                                                                                                            style="text-align: center;">
                                                                                                                                                            DATE
                                                                                                                                                            UPLOADED
                                                                                                                                                        </th>
                                                                                                                                                        <!-- <th class="blueheadsmall"
                                                                                                                                                        style="text-align: center;">
                                                                                                                                                        FILE TYPE</th>
                                                                                                                                                    <th class="blueheadsmall"
                                                                                                                                                        style="text-align: center;">
                                                                                                                                                        EDIT</th> -->

                                                                                                                                                        <!-- CHECK IF IT IS CORRECT -->
                                                                                                                                                        <th class="blueheadsmall"
                                                                                                                                                            style="text-align: center;">
                                                                                                                                                            <!-- <a class="btn btn-secondary invoicebtn"
                                                                                                                                                                role="button" 
                                                                                                                                                                (click)="downloadIPQuoteFiles(quote.quoteId)"
                                                                                                                                                                style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                                                                                                                    class="fa fa-external-link"
                                                                                                                                                                    style="font-size: 14px;color: var(--maingreen);"></i></a> -->

                                                                                                                                                            DOWNLOAD
                                                                                                                                                        </th>
                                                                                                                                                        <th
                                                                                                                                                            class="blueheadsmall">
                                                                                                                                                        </th>
                                                                                                                                                        <th
                                                                                                                                                            class="blueheadsmall">
                                                                                                                                                        </th>
                                                                                                                                                    </tr>
                                                                                                                                                </thead>
                                                                                                                                                <tbody>
                                                                                                                                                    <ng-container>
                                                                                                                                                        <tr
                                                                                                                                                            class="tablerow">
                                                                                                                                                            <td
                                                                                                                                                                class="text-center bluetextthin">
                                                                                                                                                                Quote
                                                                                                                                                            </td>
                                                                                                                                                            <td
                                                                                                                                                                class="text-center bluetextthin">
                                                                                                                                                                {{quote.quoteFileName}}
                                                                                                                                                            </td>
                                                                                                                                                            <td
                                                                                                                                                                class="text-center bluetextthin">
                                                                                                                                                                {{quote.quoteCreatedAt
                                                                                                                                                                |
                                                                                                                                                                date:'mediumDate'}}

                                                                                                                                                            </td>


                                                                                                                                                            <td class="text-nowrap"
                                                                                                                                                                style="text-align: center;">
                                                                                                                                                                <button
                                                                                                                                                                    class="btn btn-secondary invoicebtn"
                                                                                                                                                                    role="button"
                                                                                                                                                                    [disabled]="downloadingQuoteFile"
                                                                                                                                                                    (click)="downloadIPQuoteFiles(quote.quoteFileId,quote.quoteId,'Quotes')"
                                                                                                                                                                    style="padding:0px;color:var(--darkblue);font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color:
                                                                                                                                                                                    var(--shadowgrey);"><i
                                                                                                                                                                        class="fa fa-download"
                                                                                                                                                                        style="font-size: 14px;color: var(--maingreen);"></i>&nbsp;Download</button>
                                                                                                                                                            </td>
                                                                                                                                                        </tr>
                                                                                                                                                        <!-- <tr class="tablerow">
                                                                                                                                                        <td
                                                                                                                                                            class="text-center bluetextthin">
                                                                                                                                                            Sales Order
                                                                                                                                                        </td>
                                                                                                                                                        <td
                                                                                                                                                            class="text-center bluetextthin">
                                                                                                                                                            {{quote.salesOrderFileName}}
                                                                                                                                                        </td>
                                                                                                                                                        <td
                                                                                                                                                            class="text-center bluetextthin">
                                                                                                                                                            {{quote.salesOrderCreatedAt
                                                                                                                                                                |
                                                                                                                                                                date:'mediumDate'}}
                                                                                                                                                        </td>
                                                                                                                                                        
                                                                                                                                                      
                                                                                                                                                        <td class="text-nowrap"
                                                                                                                                                            style="text-align: center;">
                                                                                                                                                            <a class="btn btn-secondary invoicebtn"
                                                                                                                                                                role="button"
                                                                                                                                                               
                                                                                                                                                                style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                                                                                                                    class="fa fa-external-link"
                                                                                                                                                                    style="font-size: 14px;color: var(--maingreen);"></i></a>
                                                                                                                                                        </td>
                                                                                                                                                    </tr> -->
                                                                                                                                                        <!-- <tr class="tablerow">
                                                                                                                                                        <td
                                                                                                                                                            class="text-center bluetextthin">
                                                                                                                                                           Invoice
                                                                                                                                                        </td>
                                                                                                                                                        <td
                                                                                                                                                            class="text-center bluetextthin">
                                                                                                                                                            {{quote.invoiceOrderFileName}}
                                                                                                                                                        </td>
                                                                                                                                                        <td
                                                                                                                                                            class="text-center bluetextthin">
                                                                                                                                                            {{quote.invoiceCreatedAt
                                                                                                                                                            |
                                                                                                                                                            date:'mediumDate'}}
                                                                                                                                                            
                                                                                                                                                        </td>
                                                                                                                                                        
                                                                                                                                                      
                                                                                                                                                        <td class="text-nowrap"
                                                                                                                                                            style="text-align: center;">
                                                                                                                                                            <a class="btn btn-secondary invoicebtn"
                                                                                                                                                                role="button"
                                                                                                                                                               
                                                                                                                                                                style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                                                                                                                    class="fa fa-external-link"
                                                                                                                                                                    style="font-size: 14px;color: var(--maingreen);"></i></a>
                                                                                                                                                        </td>
                                                                                                                                                    </tr> -->
                                                                                                                                                        <!-- <tr
                                                                                                                                                            class="tablerow">
                                                                                                                                                            <td
                                                                                                                                                                class="text-center bluetextthin">
                                                                                                                                                                T&C's
                                                                                                                                                            </td>
                                                                                                                                                            <td
                                                                                                                                                                class="text-center bluetextthin">
                                                                                                                                                                T&C
                                                                                                                                                                file
                                                                                                                                                            </td>
                                                                                                                                                            <td
                                                                                                                                                                class="text-center bluetextthin">
                                                                                                                                                        
                                                                                                                                                                date
                                                                                                                                                            </td>


                                                                                                                                                            <td class="text-nowrap"
                                                                                                                                                                style="text-align: center;">
                                                                                                                                                                <a class="btn btn-secondary invoicebtn"
                                                                                                                                                                    role="button"
                                                                                                                                                                    style="padding:0px;color:var(--darkblue);font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color:
                                                                                                                                                                                    var(--shadowgrey);"><i
                                                                                                                                                                        class="fa fa-download"
                                                                                                                                                                        style="font-size: 14px;color: var(--maingreen);"></i>&nbsp;Download</a>
                                                                                                                                                            </td>
                                                                                                                                                        </tr> -->
                                                                                                                                                    </ng-container>

                                                                                                                                                </tbody>
                                                                                                                                            </table>
                                                                                                                                        </div>
                                                                                                                                    </section>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </form>
                                                                                                                    <!-- <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow"
                                                                                                                        style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;">
                                                                                                                        <div
                                                                                                                            class="col">
                                                                                                                            <p class="text-nowrap"
                                                                                                                                style="font-weight: normal;font-size: 12px;">
                                                                                                                                2/4
                                                                                                                                files
                                                                                                                                selected
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div> -->
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                class="modal-footer d-lg-flex align-items-lg-center">
                                                                                                                <!-- <a class="btn text-gray-500"
                                                                                                                    role="button"
                                                                                                                    data-bs-dismiss="modal">Close</a> -->
                                                                                                                <!-- <a
                                                                                                                    (click)="downloadOtherQuoteFiles(quote.quoteId)"
                                                                                                                    class="btn d-flex d-lg-flex justify-content-center align-items-center justify-content-lg-center align-items-lg-center yellowbtn"
                                                                                                                    role="button"
                                                                                                                    style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Download</a> -->
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </ng-container>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </section>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="uiblock" style="padding-bottom: 1rem;">
                                                        <!-- <button
                                                            class="btn btn-secondary active greybutton" type="button"
                                                            style="background: #84848429;border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;color: #C0C0C0;">Back</button> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-start d-xxl-flex justify-content-xxl-center">
                                        <div class="container-fluid content-container" style="padding-left: 0;">
                                            <div class="row g-0 d-lg-flex flex-column justify-content-lg-center align-items-lg-center align-items-xl-center"
                                                style="width: 100%;">
                                                <div class="col offset-lg-0 d-xxl-flex justify-content-xxl-center">
                                                    <div class="row g-0 rfqdetailrow"
                                                        style="width: 315px;padding-top: 0;">
                                                        <div class="col shadow-sm rounded mb-2"
                                                            style="background: var(--shadowgrey);padding: 1rem;">
                                                            <p
                                                                class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluehead">
                                                                <img src="assets/353535.png"
                                                                    style="width: 30px;margin: 10px;">Status
                                                            </p>
                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                style="margin-bottom: 0;margin-left: 51px;">RFQ IN
                                                                PROGRESS
                                                            </p>
                                                            <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                style="width: 223.391px;margin-left: 50px;">
                                                                <p *ngIf="selectedRFQ.deliveryRequirements == 'Delivery'"
                                                                    class="d-sm-flex justify-content-sm-start bluetextthin shift"
                                                                    style="margin-bottom: 0;">
                                                                    Deliver On</p>
                                                                <p *ngIf="selectedRFQ.deliveryRequirements == 'Collection'"
                                                                    class="d-sm-flex justify-content-sm-start bluetextthin shift"
                                                                    style="margin-bottom: 0;">
                                                                    Collect On</p>
                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                    style="margin-bottom: 0;">
                                                                    {{selectedRFQ.maxLeadTime | date:'mediumDate'}}
                                                                </p>
                                                            </div>
                                                            <div class="d-flex d-xxl-flex justify-content-between align-items-center align-items-xxl-center"
                                                                style="width: 223.391px;margin-left: 50px;">
                                                                <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                    style="margin-bottom: 0;">Time
                                                                    Left</p>
                                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                    style="margin-bottom: 0;">{{
                                                                    getClosingTime(selectedRFQ.maxLeadTime)}}<br>
                                                                </p>
                                                            </div>
                                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                                style="width: 223.391px;"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col d-xxl-flex justify-content-xxl-center">
                                                    <div class="row g-0 rfqdetailrow" style="width: 315px;">
                                                        <div class="col shadow-sm rounded mb-2"
                                                            style="padding: 1rem;background: var(--shadowgrey);">
                                                            <p
                                                                class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-sm-center align-items-xxl-center bluehead">
                                                                <img src="assets/wsetywe5e.png"
                                                                    style="width: 30px;margin: 10px;">Quotes Accepted
                                                            </p>
                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                style="margin-bottom: 0;margin-left: 51px;"></p>
                                                            <ng-container
                                                                *ngFor="let service of selectedRFQ.acceptedQuotes[0].servicesQuoted | sortAscDsc: criteria ">
                                                                <div class="d-flex d-xxl-flex justify-content-between align-items-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;padding: 1px;padding-bottom: 0.5rem;">
                                                                    <div class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                                                        style="background: rgb(14,14,130);padding: 7px;border-radius: 40px;height: 31px;">
                                                                        <i class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 0;"></i>
                                                                        <p
                                                                            style="font-size: 12px;color: var(--white);margin-bottom: 0px;/*font-weight: 600;*/">
                                                                            &nbsp;{{service}}</p>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                            <div class="d-flex d-xxl-flex justify-content-between align-items-center align-items-xxl-center"
                                                                style="width: 223.391px;margin-left: 50px;"></div>
                                                            <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                                style="width: 223.391px;"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col offset-lg-0 d-xxl-flex justify-content-xxl-center">
                                                    <div class="row g-0 rfqdetailrow"
                                                        style="width: 315px;padding-top: 0;">
                                                        <div class="col shadow-sm rounded mb-2"
                                                            style="background: var(--shadowgrey);padding: 1rem;">

                                                            <p
                                                                class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluehead">
                                                                <img src="assets/353535.png"
                                                                    style="width: 30px;margin: 10px;">Checklist
                                                            </p>



                                                            <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                style="width: 223.391px;margin-left: 50px;">
                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                    style="width: 223.391px;"><i
                                                                        class="fa fa-check-circle"
                                                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                        style="margin-bottom: 0;padding-left: 15px;">
                                                                        RFQ Submitted</p>
                                                                </div>
                                                            </div>

                                                            <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                style="width: 223.391px;margin-left: 50px;">

                                                            </div>
                                                            <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                style="width: 223.391px;margin-left: 50px;">
                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                    style="width: 223.391px;"><i
                                                                        class="fa fa-check-circle"
                                                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                        style="margin-bottom: 0;padding-left: 15px;">
                                                                        Quote
                                                                        Accepted</p>
                                                                    <!-- <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                        style="margin-bottom: 0;padding-left: 15px;">
                                                                        (0/3)
                                                                    </p> -->
                                                                </div>
                                                            </div>
                                                            <!-- 
                                                            SALES ORDER HISTORY -->
                                                            <div>
                                                                <div *ngIf="selectedRFQ.acceptedQuotes[0].salesQuoteFileId != null && selectedRFQ.acceptedQuotes[0].salesOrderDownloaded == true "
                                                                    class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;">
                                                                    <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                        style="width: 223.391px;"><i
                                                                            class="fa fa-check-circle"
                                                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                            style="margin-bottom: 0;padding-left: 15px;">

                                                                            Received Sales Order</p>
                                                                    </div>
                                                                </div>

                                                                <div *ngIf="selectedRFQ.acceptedQuotes[0].salesQuoteFileId == null"
                                                                    class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;">
                                                                    <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                        style="width: 223.391px;"><i
                                                                            class="fa fa-circle"
                                                                            style="color: var(--textgrey);font-size: 20px;padding-right:15px; padding-left: 0;"></i>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                            style="margin-bottom: 0;padding-left: 0px;text-shadow: 0px 0px var(--lightblue);margin-left: 50p;border-bottom: 2px solid var(--lightblue) ;">
                                                                            Awaiting
                                                                            Sales Order</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <!-- SALES ORDER DOWNLOAD -->
                                                            <div>
                                                                <div *ngIf="selectedRFQ.acceptedQuotes[0].salesQuoteFileId != null && selectedRFQ.acceptedQuotes[0].salesOrderDownloaded != true "
                                                                    class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;">
                                                                    <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                        style="width: 223.391px;"><i
                                                                            class="fa fa-circle"
                                                                            style="color: var(--textgrey);font-size: 20px;padding-right:15px; padding-left: 0;"></i>
                                                                        <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                            style="margin-bottom: 0;padding-left: 0px;text-shadow: 0px 0px var(--lightblue);margin-left: 50p;border-bottom: 2px solid var(--maingreen) ;">
                                                                            Download Sales Order</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <!-- PURCHASE ORDER HISTORY -->
                                                            <div>
                                                                <div *ngIf="selectedRFQ.purchaseOrderFileId != null"
                                                                    class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;">
                                                                    <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                        style="width: 223.391px;"><i
                                                                            class="fa fa-check-circle"
                                                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                            style="margin-bottom: 0;padding-left: 15px;">
                                                                            Purchase Order Uploaded</p>
                                                                    </div>
                                                                </div>

                                                                <div *ngIf="selectedRFQ.purchaseOrderFileId == null"
                                                                    class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;">
                                                                    <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                        style="width: 223.391px;"><i
                                                                            class="fa fa-circle"
                                                                            style="color: var(--textgrey);font-size: 20px;padding-right:15px; padding-left: 0;"></i>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                            style="margin-bottom: 0;padding-left: 0px;text-shadow: 0px 0px var(--lightblue);margin-left: 50p;border-bottom: 2px solid var(--maingreen) ;">
                                                                            Upload
                                                                            Purchase Order</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- FINAL INVOICE  HISTORY -->
                                                            <div *ngIf="selectedRFQ.paymentRequirement == 'CBD'">
                                                                <div *ngIf="selectedRFQ.acceptedQuotes[0].invoiceQuoteFileId != null && selectedRFQ.acceptedQuotes[0].invoiceOrderDownloaded == true"
                                                                    class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;">
                                                                    <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                        style="width: 223.391px;"><i
                                                                            class="fa fa-check-circle"
                                                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                            style="margin-bottom: 0;padding-left: 15px;">
                                                                            Received Final Invoice</p>
                                                                    </div>
                                                                </div>

                                                                <div *ngIf="selectedRFQ.acceptedQuotes[0].invoiceQuoteFileId == null"
                                                                    class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;">
                                                                    <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                        style="width: 223.391px;"><i
                                                                            class="fa fa-circle"
                                                                            style="color: var(--textgrey);font-size: 20px;padding-right: 15px; padding-left: 0;"></i>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                            style="margin-bottom: 0;padding-left: 0px;text-shadow: 0px 0px var(--lightblue);margin-left: 50p;border-bottom: 2px solid var(--lightblue) ;">
                                                                            Awaiting Final Invoice</p>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <!-- FINAL INVOICE  HISTORY -->
                                                            <div *ngIf="selectedRFQ.paymentRequirement == 'CBD'">
                                                                <div *ngIf="selectedRFQ.acceptedQuotes[0].invoiceQuoteFileId != null && selectedRFQ.acceptedQuotes[0].invoiceOrderDownloaded == false "
                                                                    class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;">
                                                                    <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                        style="width: 223.391px;"><i
                                                                            class="fa fa-circle"
                                                                            style="color: var(--textgrey);font-size: 20px;padding-right: 15px; padding-left: 0;"></i>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                            style="margin-bottom: 0;padding-left: 0px;text-shadow: 0px 0px var(--lightblue);margin-left: 50p;border-bottom: 2px solid var(--maingreen) ;">
                                                                            Download Final Invoice</p>
                                                                    </div>
                                                                </div>


                                                            </div>






                                                            <!-- PAYMENT STUFF ON IN PROGRESS SCREEN ONLY IF CBD SELECTED -->
                                                            <!-- MAKE PAYMENT -->
                                                            <div>
                                                                <div *ngIf="selectedRFQ.paymentRequirement == 'CBD' && selectedRFQ.acceptedQuotes[0].paymentReference == null"
                                                                    class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;">
                                                                    <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                        style="width: 223.391px;"><i
                                                                            class="fa fa-circle"
                                                                            style="color: var(--textgrey);font-size: 20px;padding-right:15px; padding-left: 0;"></i>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                            style="margin-bottom: 0;padding-left: 0px;text-shadow: 0px 0px var(--lightblue);margin-left: 50p;border-bottom: 2px solid var(--maingreen) ;">
                                                                            Make Payment</p>

                                                                    </div>
                                                                </div>



                                                                <div *ngIf="selectedRFQ.paymentRequirement == 'CBD' && selectedRFQ.acceptedQuotes[0].paymentReference != null"
                                                                    class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;">
                                                                    <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                        style="width: 223.391px;"><i
                                                                            class="fa fa-check-circle"
                                                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                            style="margin-bottom: 0;padding-left: 15px;">
                                                                            Payment Made</p>
                                                                    </div>
                                                                </div>



                                                                <!-- Awaiting Payment Confirmations    -->



                                                                <div>
                                                                    <div *ngIf="selectedRFQ.paymentRequirement == 'CBD' && selectedRFQ.acceptedQuotes[0].paymentReceived == true"
                                                                        class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                        style="width: 223.391px;margin-left: 50px;">
                                                                        <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                            style="width: 223.391px;"><i
                                                                                class="fa fa-check-circle"
                                                                                style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                            <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                                style="margin-bottom: 0;padding-left: 15px;">
                                                                                Payment Received </p>
                                                                        </div>
                                                                    </div>

                                                                    <div *ngIf="selectedRFQ.paymentRequirement == 'CBD' && selectedRFQ.acceptedQuotes[0].paymentReceived == false "
                                                                        class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                        style="width: 223.391px;margin-left: 50px;">
                                                                        <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                            style="width: 223.391px;"><i
                                                                                class="fa fa-circle"
                                                                                style="color: var(--textgrey);font-size: 20px;padding-right: 15px; padding-left: 0;"></i>
                                                                            <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                style="margin-bottom: 0;padding-left: 0px;text-shadow: 0px 0px var(--lightblue);margin-left: 50p;border-bottom: 2px solid var(--lightblue) ;">
                                                                                Awaiting
                                                                                Payment confirmation</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>




                                                            <!-- COLLECTION     -->
                                                            <div
                                                                *ngIf=" selectedRFQ.deliveryRequirements == 'Collection'">
                                                                <div *ngIf=" selectedRFQ.acceptedQuotes[0].buyerCollectionMade == false"
                                                                    class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;">
                                                                    <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                        style="width: 223.391px;"><i
                                                                            class="fa fa-circle"
                                                                            style="color: var(--textgrey);font-size: 20px;padding-right:15px; padding-left: 0;"></i>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                            style="margin-bottom: 0;padding-left: 0px;text-shadow: 0px 0px var(--lightblue);margin-left: 50p;border-bottom: 2px solid var(--maingreen) ;">
                                                                            Mark As Collected</p>
                                                                    </div>
                                                                </div>

                                                                <div *ngIf=" selectedRFQ.acceptedQuotes[0].buyerCollectionMade == true"
                                                                    class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;">
                                                                    <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                        style="width: 223.391px;"><i
                                                                            class="fa fa-check-circle"
                                                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                            style="margin-bottom: 0;padding-left: 15px;">
                                                                            Marked As Collected </p>
                                                                    </div>
                                                                </div>

                                                                <div *ngIf=" selectedRFQ.acceptedQuotes[0].buyerCollectionMade == true && selectedRFQ.acceptedQuotes[0].supplierCollectionMade == false"
                                                                    class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;">
                                                                    <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                        style="width: 223.391px;"><i
                                                                            class="fa fa-circle"
                                                                            style="color: var(--textgrey);font-size: 20px;padding-right:15px; padding-left: 0;"></i>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                            style="margin-bottom: 0;padding-left: 0px;text-shadow: 0px 0px var(--lightblue);margin-left: 50p;border-bottom: 2px solid var(--lightblue) ;">
                                                                            Awaiting Supplier Collection</p>
                                                                    </div>
                                                                </div>

                                                            </div>


                                                            <!-- DELIVERY    -->
                                                            <div
                                                                *ngIf=" selectedRFQ.deliveryRequirements == 'Delivery' ">
                                                                <div *ngIf=" selectedRFQ.acceptedQuotes[0].buyerDeliveryMade == false"
                                                                    class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;">
                                                                    <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                        style="width: 223.391px;"><i
                                                                            class="fa fa-circle"
                                                                            style="color: var(--textgrey);font-size: 20px;padding-right: 15px; padding-left: 0;"></i>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                            style="margin-bottom: 0;padding-left: 0px;text-shadow: 0px 0px var(--lightblue);margin-left: 50p;border-bottom: 2px solid var(--maingreen) ;">
                                                                            Mark As Delivered</p>
                                                                    </div>
                                                                </div>

                                                                <div *ngIf=" selectedRFQ.acceptedQuotes[0].buyerDeliveryMade == true"
                                                                    class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;">
                                                                    <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                        style="width: 223.391px;"><i
                                                                            class="fa fa-check-circle"
                                                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                            style="margin-bottom: 0;padding-left: 15px;">
                                                                            Marked As Delivered </p>
                                                                    </div>
                                                                </div>

                                                                <div *ngIf=" selectedRFQ.acceptedQuotes[0].buyerDeliveryMade == true && selectedRFQ.acceptedQuotes[0].supplierDeliveryMade == false"
                                                                    class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;">
                                                                    <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                        style="width: 223.391px;"><i
                                                                            class="fa fa-circle"
                                                                            style="color: var(--textgrey);font-size: 20px;padding-right:15px; padding-left: 0;"></i>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                            style="margin-bottom: 0;padding-left: 0px;text-shadow: 0px 0px var(--lightblue);margin-left: 50p;border-bottom: 2px solid var(--lightblue) ;">
                                                                            Awaiting Supplier Delivery</p>
                                                                    </div>
                                                                </div>

                                                            </div>



                                                            <!-- TEST CERTIFICATE     -->
                                                            <!-- IF TEST CERTIFICATE REQUIRED -->
                                                            <div *ngIf=" selectedRFQ.productCertificationRequirements">

                                                                <!-- FILE ID IS NULL -->

                                                                <div *ngIf="  selectedRFQ.paymentRequirement == 'CBD' && selectedRFQ.acceptedQuotes[0].paymentReceived &&  selectedRFQ.acceptedQuotes[0].productCertificationQuoteFileId == null"
                                                                    class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;">
                                                                    <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                        style="width: 223.391px;"><i
                                                                            class="fa fa-circle"
                                                                            style="color: var(--textgrey);font-size: 20px;padding-right:15px; padding-left: 0;"></i>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                            style="margin-bottom: 0;padding-left: 0px;text-shadow: 0px 0px var(--lightblue);margin-left: 50p;border-bottom: 2px solid var(--lightblue) ;">
                                                                            Awaiting Test Certification</p>
                                                                    </div>
                                                                </div>
                                                                <!-- FILE ID NOT NULL -->
                                                                <div *ngIf=" selectedRFQ.acceptedQuotes[0].productCertificationQuoteFileId != null"
                                                                    class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;">
                                                                    <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                        style="width: 223.391px;"><i
                                                                            class="fa fa-check-circle"
                                                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                            style="margin-bottom: 0;padding-left: 15px;">
                                                                            Test Certification Received </p>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                        </div>
                                                    </div>


                                                </div>

                                                <!-- <div class="col d-xxl-flex justify-content-xxl-center">
                                                    <div class="row g-0 rfqdetailrow" style="width: 315px;">
                                                        <div class="col shadow-sm rounded mb-2"
                                                            style="padding: 1rem;background: var(--shadowgrey);">
                                                            <p
                                                                class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-sm-center align-items-xxl-center bluehead">
                                                                <img src="assets/353535.png"
                                                                    style="width: 30px;margin: 10px;">History
                                                            </p>
                                                            <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                style="margin-bottom: 0;margin-left: 51px;">Last
                                                                updated: 5 Jun ‘21 (12:34pm)<br></p>
                                                            <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                style="width: 223.391px;margin-left: 50px;">
                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                    style="width: 223.391px;"><i
                                                                        class="fa fa-check-circle"
                                                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                        style="margin-bottom: 0;padding-left: 15px;">
                                                                        Submitted</p>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                style="width: 223.391px;margin-left: 50px;">
                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                    style="width: 223.391px;"><i
                                                                        class="fa fa-check-circle"
                                                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;"></i>
                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                        style="margin-bottom: 0;padding-left: 15px;">
                                                                        Accepted</p>
                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                        style="margin-bottom: 0;padding-left: 15px;">
                                                                        (0/3)</p>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                style="width: 223.391px;margin-left: 50px;">
                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                    style="width: 223.391px;"><i class="fa fa-circle"
                                                                        style="border-color: var(--maingreen);color: var(--textgrey);font-size: 20px;padding-left: 0;"></i>
                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                        style="margin-bottom: 0;padding-left: 15px;">
                                                                        Awaiting Confirmation</p>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                style="width: 223.391px;margin-left: 50px;">
                                                                <div class="d-flex d-md-flex d-xxl-flex justify-content-start justify-content-md-start justify-content-xxl-start"
                                                                    style="width: 223.391px;"><i class="fa fa-circle"
                                                                        style="border-color: var(--maingreen);color: var(--textgrey);font-size: 20px;padding-left: 0;"></i>
                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                        style="margin-bottom: 0;padding-left: 15px;">
                                                                        Completed</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="loadingRfq" class="d-flex flex-column" id="content-wrapper">
                                <div class="d-flex justify-content-center"
                                    style="min-height:100vh; margin-top:1rem; margin-right:1rem;  background-color: var(--shadowgrey);"
                                    id="content">

                                    <div class="text-center text-center bluehead" style="padding-top:15rem;"><span
                                            class="spinner-border spinner-border-sm mr-1"></span>
                                        Loading...
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>



    <!-- MODALS START HERE -->



    <!-- CHECKLIST1 -->
    <section>
        <form [formGroup]="form" #test="ngForm">
            <div role="dialog" tabindex="-1" class="modal fade" data-bs-backdrop="static" id="rfqchecklist1">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header" style="background: var(--maingreen);">
                            <h1 class="d-xl-flex align-items-xl-center whitehead"
                                style="font-size: 16px;margin-bottom: 0;">
                                RFQ
                                Checklist</h1><button (click)="resetCompleteRfq()" type="button" class="btn-close"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <!-- <p>{{test.value | json}}</p>    -->
                        <div class="modal-body">
                            <div>
                                <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                    <div class="col" style="padding-left: 0;">
                                        <p class="d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-center justify-content-md-center justify-content-lg-center blueheadsmall"
                                            style="margin-bottom: 0;padding-left: 32px;">You are about to complete
                                            {{selectedRFQ.rfqName}}
                                        </p>
                                    </div>
                                </div>
                                <div class="row rfqdetailrow" style="padding-bottom: 5px;padding-top: 5px;">

                                    <div *ngIf="selectedRFQ.deliveryRequirements == 'Delivery' || selectedRFQ.deliveryRequirements == null "
                                        class="col">
                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;padding-left: 22px;">On time delivery?
                                            <ng-container
                                                *ngIf=" onTimeDeliveryCond == null  && checklistValidCond == false">
                                                <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                                            </ng-container>
                                        </p>
                                    </div>

                                    <div *ngIf="selectedRFQ.deliveryRequirements == 'Collection' " class="col">
                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;padding-left: 22px;">Collected On Time?
                                            <ng-container
                                                *ngIf=" onTimeDeliveryCond == null  && checklistValidCond == false">
                                                <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                                            </ng-container>
                                        </p>
                                    </div>

                                    <!-- supplier answer start 1 -->
                                    <ng-container *ngIf=" this.rfqReview ">
                                        <div *ngIf="this.rfqReview.onTimeCollection">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                style="margin-bottom: 10px;padding-left: 22px;">Supplier answered:
                                                <span class="bluetext">Yes</span>
                                            </p>
                                        </div>

                                        <div *ngIf="!this.rfqReview.onTimeCollection">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                style="margin-bottom: 0;padding-left: 22px;padding-bottom: 5px;">
                                                Supplier answered:
                                                <span class="bluetext" style="margin-left: 5px;">No &nbsp; </span>
                                                <!-- <a  class="badge rounded-pill bluetextthin" href="#" style="margin-bottom: 0px;margin-left: 5px;background: var(--darkblue);color: var(--shadowgrey);font-size: 10px;line-height: 11px;" data-bs-toggle="collapse" data-bs-target="#suppliersaid1">Read More...</a> -->
                                                <a class="bluetextthin" href="#" data-bs-toggle="collapse"
                                                    data-bs-target="#suppliersaid1"><u>Read More...</u>
                                                </a>
                                            </p>
                                        </div>

                                        <div *ngIf="rfqReview.onTimeCollectionReason" id="suppliersaid1"
                                            style="background: var(--shadowgrey);border-radius: 5px;" class="collapse">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                style="margin-bottom: 0;padding: 10px;padding-left: 22px;">
                                                {{rfqReview.onTimeCollectionReason}}</p>
                                        </div>
                                    </ng-container>
                                    <!-- supplier answer end 1-->

                                    <div class="col" style="padding-left: 0;">
                                        <div style="margin-left: 2.5rem;margin-top: 1rem;width: 437px;">
                                            <div class="row d-xl-flex justify-content-xl-start"
                                                style="padding-top: 0;width: 553px;min-width: 498px;">
                                                <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-md-flex justify-content-md-center align-items-md-center"
                                                    style="padding: 0;">
                                                    <div class="btn-group bluebtn" role="group"
                                                        aria-label="Basic checkbox toggle button group"><input
                                                            type="checkbox" formControlName="onTimeDeliveryYes"
                                                            (click)="onTimeDeliveryCheck(true)" id="timeYES"
                                                            class="btn-check form-check-input"
                                                            name="timedelivery" /><label
                                                            class="form-label form-check-label btn btn-outline-primary"
                                                            for="timeYES" style="border-radius: 40px;font-size: 14px;"
                                                            name="flexRadioDefault">Yes</label></div>
                                                </div>

                                                <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 offset-xl-0 d-md-flex justify-content-md-center align-items-md-center"
                                                    style="padding: 0;">
                                                    <div class="btn-group bluebtn" role="group"
                                                        aria-label="Basic checkbox toggle button group"><input
                                                            type="checkbox" formControlName="onTimeDeliveryNo"
                                                            (click)="onTimeDeliveryCheck(false)" id="timeNO"
                                                            class="btn-check form-check-input"
                                                            name="timedelivery" /><label
                                                            class="form-label form-check-label btn btn-outline-primary"
                                                            for="timeNO"
                                                            style="border-radius: 40px;font-size: 14px;">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="onTimeDeliveryCond == false"
                                        class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                        <div class="col" style="padding-left: 0;">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 33px;">Plead Provide More Detail
                                                <ng-container
                                                    *ngIf=" stringValidation(f.onTimeDeliveryReason.value)  && checklistValidCond == false">
                                                    <span class="p-1 bluetext thin"
                                                        style="color:#a51d29;">(Required)</span>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf=" f.onTimeDeliveryReason.value.length > 250  && checklistValidCond == false">
                                                    <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds Max
                                                        Length)</span>
                                                </ng-container>
                                            </p>
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;"></p>
                                            <div style="margin-top: 1rem;width: 100%;">
                                                <div
                                                    style="height: auto;margin-left: 2rem;margin-top: 1rem;width: 25rem;">
                                                    <textarea formControlName="onTimeDeliveryReason"
                                                        class="form-control"
                                                        style="min-height: 70px; height: 70px; max-height: 70px;"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row rfqdetailrow" style="padding-bottom: 5px;padding-top: 5px;">
                                    <div class="col">
                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;padding-left: 22px;">Correct material grade?
                                            <ng-container
                                                *ngIf=" correctMaterialGradeCond == null  && checklistValidCond == false">
                                                <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                                            </ng-container>
                                        </p>
                                    </div>

                                    <!-- supplier answer start 2 -->
                                    <ng-container *ngIf=" this.rfqReview ">
                                        <div *ngIf="rfqReview.quoteDetails[0].correctMaterialGrade">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                style="margin-bottom: 10px;padding-left: 22px;">Supplier answered:
                                                <span class="bluetext">Yes</span>
                                            </p>
                                        </div>

                                        <div *ngIf="!rfqReview.quoteDetails[0].correctMaterialGrade">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                style="margin-bottom: 0;padding-left: 22px;padding-bottom: 5px;">
                                                Supplier answered:
                                                <span class="bluetext" style="margin-left: 5px;">No &nbsp; </span>
                                                <!-- <a class="badge rounded-pill bluetextthin" href="#" style="margin-bottom: 0px;margin-left: 5px;background: var(--darkblue);color: var(--shadowgrey);font-size: 10px;line-height: 11px;" data-bs-toggle="collapse" data-bs-target="#suppliersaid2">Read More...</a> -->
                                                <a class="bluetextthin" href="#" data-bs-toggle="collapse"
                                                    data-bs-target="#suppliersaid2"><u>Read More...</u>
                                                </a>
                                            </p>
                                        </div>

                                        <div *ngIf="rfqReview.quoteDetails[0].correctMaterialGradeReason"
                                            id="suppliersaid2" style="background: var(--shadowgrey);border-radius: 5px;"
                                            class="collapse">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                style="margin-bottom: 0;padding: 10px;padding-left: 22px;">
                                                {{rfqReview.quoteDetails[0].correctMaterialGradeReason}} </p>
                                        </div>
                                    </ng-container>
                                    <!-- supplier answer end 2-->

                                    <div class="col" style="padding-left: 0;">
                                        <div style="margin-left: 2.5rem;margin-top: 1rem;width: 437px;">
                                            <div class="row d-xl-flex justify-content-xl-start"
                                                style="padding-top: 0;width: 553px;min-width: 498px;">
                                                <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-md-flex justify-content-md-center align-items-md-center"
                                                    style="padding: 0;">
                                                    <div class="btn-group bluebtn" role="group"
                                                        aria-label="Basic checkbox toggle button group"><input
                                                            type="checkbox" formControlName="correctMaterialGradeYes"
                                                            (click)="correctMaterialGradeCheck(true)" id="MatYES"
                                                            class="btn-check form-check-input" name="mg" /><label
                                                            class="form-label form-check-label btn btn-outline-primary"
                                                            for="MatYES" style="border-radius: 40px;font-size: 14px;"
                                                            name="flexRadioDefault">Yes</label></div>
                                                </div>
                                                <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 offset-xl-0 d-md-flex justify-content-md-center align-items-md-center"
                                                    style="padding: 0;">
                                                    <div class="btn-group bluebtn" role="group"
                                                        aria-label="Basic checkbox toggle button group"><input
                                                            type="checkbox" formControlName="correctMaterialGradeNo"
                                                            (click)="correctMaterialGradeCheck(false)" id="MatNo"
                                                            class="btn-check form-check-input" name="mg" /><label
                                                            class="form-label form-check-label btn btn-outline-primary"
                                                            for="MatNo"
                                                            style="border-radius: 40px;font-size: 14px;">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="correctMaterialGradeCond == false"
                                        class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                        <div class="col" style="padding-left: 0;">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 33px;">Plead Provide More Detail
                                                <ng-container
                                                    *ngIf=" stringValidation(f.correctMaterialGradeReason.value)  && checklistValidCond == false">
                                                    <span class="p-1 bluetext thin"
                                                        style="color:#a51d29;">(Required)</span>
                                                </ng-container>

                                                <ng-container
                                                    *ngIf=" f.correctMaterialGradeReason.value.length > 250  && checklistValidCond == false">
                                                    <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds Max
                                                        Length)</span>
                                                </ng-container>

                                            </p>
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;"></p>
                                            <div style="margin-top: 1rem;width: 100%;">
                                                <div
                                                    style="height: auto;margin-left: 2rem;margin-top: 1rem;width: 25rem;">
                                                    <textarea formControlName="correctMaterialGradeReason"
                                                        class="form-control"
                                                        style="min-height: 70px; height: 70px; max-height: 70px;"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row rfqdetailrow" style="padding-bottom: 5px;padding-top: 5px;">
                                    <div class="col">
                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;padding-left: 22px;">Correct quantity of parts?
                                            <ng-container
                                                *ngIf=" correctQuantityOfPartsCond == null  && checklistValidCond == false">
                                                <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                                            </ng-container>
                                        </p>
                                    </div>

                                    <!-- supplier answer start 3 -->
                                    <ng-container *ngIf=" this.rfqReview ">
                                        <div *ngIf="rfqReview.quoteDetails[0].correctQuantity">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                style="margin-bottom: 10px;padding-left: 22px;">Supplier answered:
                                                <span class="bluetext">Yes</span>
                                            </p>
                                        </div>

                                        <div *ngIf="!rfqReview.quoteDetails[0].correctQuantity">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                style="margin-bottom: 0;padding-left: 22px;padding-bottom: 5px;">
                                                Supplier answered:
                                                <span class="bluetext" style="margin-left: 5px;">No &nbsp; </span>
                                                <!-- <a class="badge rounded-pill bluetextthin" href="#" style="margin-bottom: 0px;margin-left: 5px;background: var(--darkblue);color: var(--shadowgrey);font-size: 10px;line-height: 11px;" data-bs-toggle="collapse" data-bs-target="#suppliersaid3">Read More...</a> -->
                                                <a class="bluetextthin" href="#" data-bs-toggle="collapse"
                                                    data-bs-target="#suppliersaid3"><u>Read More...</u>
                                                </a>
                                            </p>

                                        </div>

                                        <div *ngIf="rfqReview.quoteDetails[0].correctQuantityReason" id="suppliersaid3"
                                            style="background: var(--shadowgrey);border-radius: 5px;" class="collapse">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                style="margin-bottom: 0;padding: 10px;padding-left: 22px;">
                                                {{rfqReview.quoteDetails[0].correctQuantityReason}} </p>
                                        </div>
                                    </ng-container>
                                    <!-- supplier answer end 3-->

                                    <div class="col" style="padding-left: 0;">
                                        <div style="margin-left: 2.5rem;margin-top: 1rem;width: 437px;">
                                            <div class="row d-xl-flex justify-content-xl-start"
                                                style="padding-top: 0;width: 553px;min-width: 498px;">
                                                <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-md-flex justify-content-md-center align-items-md-center"
                                                    style="padding: 0;">
                                                    <div class="btn-group bluebtn" role="group"
                                                        aria-label="Basic checkbox toggle button group"><input
                                                            type="checkbox" formControlName="correctQuantityYes"
                                                            (click)="correctQuantityOfPartsCheck(true)" id="QYES"
                                                            class="btn-check form-check-input" name="q" /><label
                                                            class="form-label form-check-label btn btn-outline-primary"
                                                            for="QYES" style="border-radius: 40px;font-size: 14px;"
                                                            name="q">Yes</label></div>
                                                </div>
                                                <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 offset-xl-0 d-md-flex justify-content-md-center align-items-md-center"
                                                    style="padding: 0;">
                                                    <div class="btn-group bluebtn" role="group"
                                                        aria-label="Basic checkbox toggle button group"><input
                                                            type="checkbox" formControlName="correctQuantityNo"
                                                            (click)="correctQuantityOfPartsCheck(false)" id="QNO"
                                                            class="btn-check form-check-input" name="q" /><label
                                                            class="form-label form-check-label btn btn-outline-primary"
                                                            for="QNO"
                                                            style="border-radius: 40px;font-size: 14px;">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="correctQuantityOfPartsCond == false"
                                        class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                        <div class="col" style="padding-left: 0;">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 33px;">Plead Provide More Detail
                                                <ng-container
                                                    *ngIf=" stringValidation(f.correctQuantityReason.value)  && checklistValidCond == false">
                                                    <span class="p-1 bluetext thin"
                                                        style="color:#a51d29;">(Required)</span>
                                                </ng-container>

                                                <ng-container
                                                    *ngIf="f.correctQuantityReason.value.length > 250  && checklistValidCond == false">
                                                    <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds Max
                                                        Length)</span>
                                                </ng-container>

                                            </p>
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;"></p>
                                            <div style="margin-top: 1rem;width: 100%;">
                                                <div
                                                    style="height: auto;margin-left: 2rem;margin-top: 1rem;width: 25rem;">
                                                    <textarea formControlName="correctQuantityReason"
                                                        class="form-control"
                                                        style="min-height: 70px; height: 70px; max-height: 70px;"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="selectedRFQ.productCertificationRequirements" class="row rfqdetailrow"
                                    style="padding-bottom: 5px;padding-top: 5px;">
                                    <div class="col">
                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;padding-left: 22px;">Received test certification?
                                            <ng-container
                                                *ngIf=" receivedTestCertificationCond == null  && checklistValidCond == false">
                                                <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                                            </ng-container>
                                        </p>
                                    </div>

                                    <!-- supplier answer start 4 -->
                                    <!-- <ng-container *ngIf = "selectedRFQ.deliveryRequirements == 'Delivery' && this.rfqReview ">
                                     <div >
                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin" style="margin-bottom: 10px;padding-left: 22px;">Supplier answered: 
                                         <i class="fa fa-check-circle" style="color: var(--maingreen);"></i><span class="bluetext">Yes</span></p>
                                    </div>

                                    <div>
                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin" style="margin-bottom: 0;padding-left: 22px;padding-bottom: 5px;">Supplier answered: 
                                             <i class="fa fa-times-circle" style="color: var(--bs-primary);"></i><span class="bluetext" style="margin-left: 5px;">No</span>
                                             <a class="badge rounded-pill bluetextthin" href="#" style="margin-bottom: 0px;margin-left: 5px;background: var(--darkblue);color: var(--shadowgrey);font-size: 10px;line-height: 11px;" data-bs-toggle="collapse" data-bs-target="#suppliersaid4">Read More...</a></p>
                                    </div>

                                    <div id="suppliersaid4" style="background: var(--shadowgrey);border-radius: 5px;" class = "collapse">
                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin" style="margin-bottom: 0;padding: 10px;padding-left: 22px;">Info about no </p>
                                    </div>
                                    </ng-container> -->
                                    <!-- supplier answer end 4-->

                                    <div class="col" style="padding-left: 0;">
                                        <div style="margin-left: 2.5rem;margin-top: 1rem;width: 437px;">
                                            <div class="row d-xl-flex justify-content-xl-start"
                                                style="padding-top: 0;width: 553px;min-width: 498px;">
                                                <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-md-flex justify-content-md-center align-items-md-center"
                                                    style="padding: 0;">
                                                    <div class="btn-group bluebtn" role="group"
                                                        aria-label="Basic checkbox toggle button group"><input
                                                            type="checkbox" formControlName="receivedTestCertYes"
                                                            (click)=" receivedTestCertificationCheck(true)" id="tcYES"
                                                            class="btn-check form-check-input" name="testc" /><label
                                                            class="form-label form-check-label btn btn-outline-primary"
                                                            for="tcYES" style="border-radius: 40px;font-size: 14px;"
                                                            name="testc">Yes</label>
                                                    </div>
                                                </div>
                                                <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 offset-xl-0 d-md-flex justify-content-md-center align-items-md-center"
                                                    style="padding: 0;">
                                                    <div class="btn-group bluebtn" role="group"
                                                        aria-label="Basic checkbox toggle button group"><input
                                                            type="checkbox" formControlName="receivedTestCertNo"
                                                            (click)=" receivedTestCertificationCheck(false)" id="tcNO"
                                                            class="btn-check form-check-input" name="testc" /><label
                                                            class="form-label form-check-label btn btn-outline-primary"
                                                            for="tcNO"
                                                            style="border-radius: 40px;font-size: 14px;">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="receivedTestCertificationCond == false"
                                        class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                        <div class="col" style="padding-left: 0;">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 33px;">Plead Provide More Detail
                                                <ng-container
                                                    *ngIf=" stringValidation(f.receivedTestCertReason.value)  && checklistValidCond == false">
                                                    <span class="p-1 bluetext thin"
                                                        style="color:#a51d29;">(Required)</span>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="f.receivedTestCertReason.value.length > 250 && checklistValidCond == false">
                                                    <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds Max
                                                        Length)</span>
                                                </ng-container>
                                            </p>
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;"></p>
                                            <div style="margin-top: 1rem;width: 100%;">
                                                <div
                                                    style="height: auto;margin-left: 2rem;margin-top: 1rem;width: 25rem;">
                                                    <textarea formControlName="receivedTestCertReason"
                                                        class="form-control"
                                                        style="min-height: 70px; height: 70px; max-height: 70px;"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row rfqdetailrow" style="padding-bottom: 5px;padding-top: 5px;">
                                    <div class="col">
                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;padding-left: 22px;">Supplier
                                            communicated timeously?
                                        </p>
                                    </div>

                                    <!-- supplier answer start 5 -->
                                    <ng-container *ngIf="this.rfqReview ">
                                        <div *ngIf="rfqReview.goodCommunication">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                style="margin-bottom: 10px;padding-left: 22px;">Supplier answered:
                                                <span class="bluetext">Yes</span>
                                            </p>
                                        </div>

                                        <div
                                            *ngIf="!rfqReview.goodCommunication && rfqReview.goodCommunication != null">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                style="margin-bottom: 0;padding-left: 22px;padding-bottom: 5px;">
                                                Supplier answered:
                                                <span class="bluetext" style="margin-left: 5px;">No &nbsp; </span>
                                                <!-- <a class="badge rounded-pill bluetextthin" href="#" style="margin-bottom: 0px;margin-left: 5px;background: var(--darkblue);color: var(--shadowgrey);font-size: 10px;line-height: 11px;" data-bs-toggle="collapse" data-bs-target="#suppliersaid5">Read More...</a> -->
                                                <a class="bluetextthin" href="#" data-bs-toggle="collapse"
                                                    data-bs-target="#suppliersaid5"><u>Read More...</u>
                                                </a>
                                            </p>
                                        </div>

                                        <div *ngIf="rfqReview.goodCommunicationReason && rfqReview.goodCommunication != null"
                                            id="suppliersaid5" style="background: var(--shadowgrey);border-radius: 5px;"
                                            class="collapse">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                style="margin-bottom: 0;padding: 10px;padding-left: 22px;">
                                                {{rfqReview.goodCommunicationReason}} </p>
                                        </div>
                                    </ng-container>
                                    <!-- supplier answer end 5-->

                                    <div class="col" style="padding-left: 0;">
                                        <div style="margin-left: 2.5rem;margin-top: 1rem;width: 437px;">
                                            <div class="row d-xl-flex justify-content-xl-start"
                                                style="padding-top: 0;width: 553px;min-width: 498px;">
                                                <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-md-flex justify-content-md-center align-items-md-center"
                                                    style="padding: 0;">
                                                    <div class="btn-group bluebtn" role="group"
                                                        aria-label="Basic checkbox toggle button group"><input
                                                            type="checkbox" formControlName="supplierCommunicateYes"
                                                            (click)="supplierCommunicationCheck(true)" id="tYES"
                                                            class="btn-check form-check-input" name="time" /><label
                                                            class="form-label form-check-label btn btn-outline-primary"
                                                            for="tYES" style="border-radius: 40px;font-size: 14px;"
                                                            name="time">Yes</label>
                                                    </div>
                                                </div>
                                                <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 offset-xl-0 d-md-flex justify-content-md-center align-items-md-center"
                                                    style="padding: 0;">
                                                    <div class="btn-group bluebtn" role="group"
                                                        aria-label="Basic checkbox toggle button group"><input
                                                            type="checkbox" formControlName="supplierCommunicateNo"
                                                            (click)="supplierCommunicationCheck(false)" id="tNO"
                                                            class="btn-check form-check-input" name="time" /><label
                                                            class="form-label form-check-label btn btn-outline-primary"
                                                            for="tNO"
                                                            style="border-radius: 40px;font-size: 14px;">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="supplierCommunicationCond == false"
                                        class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                        <div class="col" style="padding-left: 0;">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 33px;">Plead Provide More Detail
                                                <ng-container
                                                    *ngIf=" stringValidation(f.supplierCommunicateReason.value)  && checklistValidCond == false">
                                                    <span class="p-1 bluetext thin"
                                                        style="color:#a51d29;">(Required)</span>
                                                </ng-container>

                                                <ng-container
                                                    *ngIf="f.supplierCommunicateReason.value.length > 250  && checklistValidCond == false">
                                                    <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds Max
                                                        Length)</span>
                                                </ng-container>

                                            </p>
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;"></p>
                                            <div style="margin-top: 1rem;width: 100%;">
                                                <div
                                                    style="height: auto;margin-left: 2rem;margin-top: 1rem;width: 25rem;">
                                                    <textarea formControlName="supplierCommunicateReason"
                                                        class="form-control"
                                                        style="min-height: 70px; height: 70px; max-height: 70px;"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- EXPECTED DELIVERY DATE -->
                                <div class="col-lg-6 col-xxl-5" style="padding-bottom: 5px; padding-left: 26px">

                                    <p *ngIf="selectedRFQ.deliveryRequirements == 'Collection'"
                                        class="text-nowrap bluetext" style="color: #0e0e82;">
                                        Expected Collection
                                        Date:
                                    </p>
                                    <p *ngIf="selectedRFQ.deliveryRequirements == 'Delivery' || selectedRFQ.deliveryRequirements == null "
                                        class="text-nowrap bluetext" style="color: #0e0e82;">Expected Delivery
                                        Date:
                                    </p>
                                    <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                        style="margin-bottom: 0;">
                                        {{selectedRFQ.maxLeadTime | date:'mediumDate'}}
                                    </p>







                                </div>


                                <div class="col-lg-6 col-xxl-5"
                                    style="padding-left: 0; padding-bottom: 20px; width: 50%;">
                                    <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                        style="margin-bottom: 0;padding-left: 0px;"><i class="fa fa-asterisk uiicon"
                                            style="font-size: 6px;color: #ff0000;"></i>Actual Delivery Date
                                        <ng-container
                                            *ngIf=" f.actualDeliveryDate.value == null  && checklistValidCond == false">
                                            <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                                        </ng-container>
                                    </p>
                                    <ng-container>
                                        <div>
                                            <p *ngIf="this.rfqReview "
                                                class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin text-nowrap"
                                                style="margin-bottom: 10px;padding-left: 26px;">Supplier Delivery Date:
                                                <span class="bluetext">{{rfqReview.quoteDetails[0].actualDeliveryDate |
                                                    date :"mediumDate"}}</span>
                                            </p>
                                        </div>
                                    </ng-container>
                                    <div style="margin-top: 1rem;height: 2rem;width: auto;margin-left: 1rem;">

                                        <input formControlName="actualDeliveryDate" (ngModelChange)="getDaydiff($event)"
                                            type="date" class="form-select form-control" style="width: 189px;"
                                            style="width: 100%;">
                                    </div>
                                </div>




                                <div class="col-lg-6 col-xxl-5" style="padding-bottom: 5px; padding-left: 26px">
                                    <ng-container *ngIf="this.f.actualDeliveryDate.value != null">

                                        <!-- IF COLLECTION CALC -->

                                        <ng-container *ngIf="selectedRFQ.deliveryRequirements == 'Collection'">

                                            <div class="d-flex" style="padding-top:5px ;">

                                                <p class="text-nowrap bluetextthin" style="color: #0e0e82;"> Collection
                                                    is:
                                                </p>
                                                <p class="text-nowrap bluetext"
                                                    style="color: #0e0e82; padding-left: 3px;">

                                                    <span *ngIf="dueDate == 1 || dueDate > 1 "
                                                        style="color: var(--yellow) !important;"> {{roundDate}} Days
                                                        Late</span>

                                                    <span *ngIf="dueDate < 0  "
                                                        style="color: var(--yellow) !important;">
                                                        {{roundDate}} Days Early </span>

                                                    <span *ngIf="roundDate == 0"
                                                        style="color: var(--maingreen) !important;"> On Time</span>

                                                </p>

                                            </div>

                                        </ng-container>

                                        <!-- IF DELIVERY CALC -->
                                        <ng-container
                                            *ngIf="selectedRFQ.deliveryRequirements == 'Delivery' || selectedRFQ.deliveryRequirements == null ">
                                            <div class="d-flex" style="padding-top:5px ;">

                                                <p class="text-nowrap bluetextthin" style="color: #0e0e82;"> Delivery
                                                    is:
                                                </p>

                                                <p class="text-nowrap bluetext"
                                                    style="color: #0e0e82; padding-left: 3px;">

                                                    <span *ngIf="dueDate == 1 || dueDate > 1 "
                                                        style="color: var(--yellow) !important;"> {{roundDate}} Days
                                                        Late</span>

                                                    <span *ngIf="dueDate < 0  "
                                                        style="color: var(--yellow) !important;">
                                                        {{roundDate}} Days Early </span>

                                                    <span *ngIf="roundDate == 0"
                                                        style="color: var(--maingreen) !important;"> On Time</span>

                                                </p>

                                            </div>



                                        </ng-container>
                                    </ng-container>
                                </div>







                                <div class="row d-flex d-xxl-flex justify-content-center align-items-center rfqdetailrow"
                                    style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;">
                                    <div class="col">
                                        <p class="text-nowrap bluetextthin" style="font-weight: normal;">Problem with
                                            delivery</p>
                                    </div>
                                    <div class="col">
                                        <p class="bluetextthin" style="font-weight: normal;">
                                            <a class="text-nowrap bluetextthin" (click)="Sendquotedata(sentQID)"
                                                (click)="triggerChatOpen(true)" (click)="this.unreadMessages = false;"
                                                (click)="resetCompleteRfq()" (click)="resetCompleteRfq()"
                                                data-bs-dismiss="modal"
                                                style="text-decoration:  underline;  cursor: pointer;">Chat with
                                                Supplier </a>
                                        </p>
                                    </div>
                                    <div class="col">
                                        <p class="text-nowrap d-flex d-lg-flex justify-content-center justify-content-lg-center bluetextthin"
                                            style="font-weight: normal;">or</p>
                                    </div>
                                    <div class="col">
                                        <p class="bluetextthin" style="font-weight: normal;"><a
                                                class="text-nowrap bluetextthin"
                                                style="text-decoration:  underline;  cursor: pointer;">Tradely
                                                Support</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer d-lg-flex align-items-lg-center">
                            <a class="btn text-gray-500" (click)="resetCompleteRfq()" role="button"
                                data-bs-dismiss="modal">Close</a>

                            <a *ngIf="checklistCond()" (click)="onNext()" data-bs-toggle="modal"
                                data-bs-target="#rfqchecklist2" data-bs-dismiss="modal"
                                class="btn d-flex justify-content-center align-items-center yellowbtn" role="button"
                                style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Continue</a>

                            <a *ngIf="!checklistCond()" (click)="checklistCheck()"
                                class="btn d-flex justify-content-center align-items-center yellowbtn" role="button"
                                style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Continue</a>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </section>

    <!-- CHECKLIST2 -->

    <div role="dialog" tabindex="-1" class="modal fade" data-bs-backdrop="static" id="rfqchecklist2">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header" style="background: var(--maingreen);">
                    <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">RFQ
                        Checklist</h1><button (click)="resetCompleteRfq()" type="button" class="btn-close"
                        data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <!-- <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                            <div class="col" style="padding-left: 0;">
                                <p class="text-center d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-center justify-content-md-center justify-content-lg-center blueheadsmall"
                                    style="margin-bottom: 0;padding-left: 32px;">Upload  Delivery Note</p>
                            </div>
                        </div> -->

                        <div class="row d-flex d-xxl-flex align-items-center justify-content-xxl-center rfqdetailrow">
                            <div class="col" style="margin-bottom: 1rem;">
                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                    style="margin-bottom: 0;">Delivery Note Upload (Optional)
                                    <!-- <ng-container
                                        *ngIf="deliverNoteValidCond && selectedRFQ.deliveryNoteFileId == null">
                                        <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                                    </ng-container> -->
                                </p>
                                <span *ngIf="selectedRFQ.deliveryNoteFileId != null"
                                    class="text-nowrap d-xl-flex d-xxl-flex align-items-xl-end"
                                    style="font-weight: normal;font-size: 12px;"> <i
                                        class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center"
                                        style="font-size: 16px;margin-right: 5px;"></i>
                                    {{selectedRFQ.deliveryNoteFileName}}</span>

                                <div *ngIf="deliveryNoteUploading || deliveryNoteDeleting"
                                    class="text-center text-center bluehead thin" style="font-size: 14px;"><span
                                        class="spinner-border spinner-border-sm mr-1"></span>
                                    Uploading...
                                </div>
                            </div>
                        </div>

                        <div class="mt-1 row d-flex d-xxl-flex align-items-center rfqdetailrow"
                            style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;">
                            <div class="col">
                                <p class="text-nowrap bluetextthin" style="font-weight: normal;">Delivery Note</p>

                            </div>
                            <div class="col">
                                <p class="bluetextthin" style="font-weight: normal;"></p>
                            </div>



                            <div *ngIf="selectedRFQ.deliveryNoteFileId == null" class="col" style="padding-left: 0;">
                                <input #clearDInput type="file" class="file-input"
                                    (change)="deliveryNoteSelected($event)" #DfileUpload accept="{{requiredFileType}}">

                                <button class="btn btn-secondary d-flex d-xxl-flex align-items-center invoicebtn"
                                    role="button" (click)="DfileUpload.click()"
                                    [disabled]="deliveryNoteUploading || deliveryNoteDeleting"
                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);">
                                    <i class="fas fa-upload"
                                        style="font-size: 14px;color: var(--maingreen);"></i>&nbsp;Upload</button>

                            </div>
                            <div *ngIf="selectedRFQ.deliveryNoteFileId != null" class="col" style="padding-left: 0;">
                                <div class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                    style="background: var(--white);padding: 7px;border-radius: 40px;">
                                    <i class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 0;"></i>
                                    <p
                                        style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;">
                                        &nbsp;Uploaded</p>
                                </div>
                            </div>
                            <!-- <div
                                    class="col d-flex d-xxl-flex justify-content-center align-items-center align-items-lg-center justify-content-xl-center justify-content-xxl-center">
                                    <i class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center"
                                        style="font-size: 16px;margin-right: 5px;"></i>
                                    <p class="text-nowrap d-xl-flex d-xxl-flex align-items-xl-end"
                                        style="font-weight: normal;font-size: 12px;">
                                        DeliveryNote.pdf</p>
                                </div> -->
                            <div class="col d-flex d-xxl-flex justify-content-center justify-content-xxl-center">
                                <input #clearDInput type="file" class="file-input"
                                    (change)="deliveryNoteSelected($event)" #DEfileUpload accept="{{requiredFileType}}">
                                <button
                                    [disabled]="selectedRFQ.deliveryNoteFileId == null || deliveryNoteUploading || deliveryNoteDeleting"
                                    class="btn btn-secondary invoicebtn" role="button" (click)="DEfileUpload.click()"
                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                        class="fa fa-edit"
                                        style="font-size: 14px;color: var(--maingreen);"></i></button>
                            </div>

                            <div class="col d-flex d-xxl-flex justify-content-center justify-content-xxl-center">

                                <button (click)="downloadDrawingFile(selectedRFQ.deliveryNoteFileId)"
                                    class="btn btn-secondary invoicebtn" role="button"
                                    [disabled]="selectedRFQ.deliveryNoteFileId == null || deliveryNoteUploading || deliveryNoteDeleting"
                                    style="padding:0px;color:var(--darkblue);font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color:
                                                                                                                                                                                    var(--shadowgrey);"><i
                                        class="fa fa-download"
                                        style="font-size: 14px;color: var(--maingreen);"></i>&nbsp;Download</button>
                            </div>
                        </div>

                        <!-- <ng-container *ngIf="selectedRFQ.productCertificationRequirements">
                            <div
                                class="row d-flex d-xxl-flex align-items-center justify-content-xxl-center rfqdetailrow">
                                <div class="col" style="margin-bottom: 1rem;">
                                    <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                        style="margin-bottom: 0;">Supplier test certificate Download</p>
                                </div>
                            </div>
                            <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow"
                                style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;">
                                <div class="col">
                                    <p class="text-nowrap bluetextthin" style="font-weight: normal;">Test certificate
                                    </p>
                                </div>
                                <div class="col">
                                    <p class="bluetextthin" style="font-weight: normal;"></p>
                                </div>
                                <div *ngIf="selectedRFQ.acceptedQuotes[0].productCertificationQuoteFileId != null"
                                    class="col d-flex d-xxl-flex justify-content-center align-items-center align-items-lg-center justify-content-xl-center justify-content-xxl-center">
                                    <i class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center"
                                        style="font-size: 16px;margin-right: 5px;"></i>
                                    <p class="text-nowrap d-xl-flex d-xxl-flex align-items-xl-end"
                                        style="font-weight: normal;font-size: 12px;">
                                        {{selectedRFQ.acceptedQuotes[0].productCertificationFileName}}</p>
                                </div>
                                <div *ngIf="selectedRFQ.acceptedQuotes[0].productCertificationQuoteFileId == null"
                                    class="col d-flex d-xxl-flex justify-content-center align-items-center align-items-lg-center justify-content-xl-center justify-content-xxl-center">

                                    <p class="text-nowrap d-xl-flex d-xxl-flex align-items-xl-end"
                                        style="font-weight: normal;font-size: 12px;">Awaiting Upload</p>
                                </div>

                                <div class="col d-flex d-xxl-flex justify-content-center justify-content-xxl-center">
                                    <button
                                        (click)="downloadProductCertificationFile(selectedRFQ.acceptedQuotes[0].productCertificationQuoteFileId)"
                                        class="btn btn-secondary invoicebtn" role="button"
                                        [disabled]="selectedRFQ.acceptedQuotes[0].productCertificationQuoteFileId == null"
                                        style="padding:0px;color:var(--darkblue);font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color:
                                                                                                                                                                                    var(--shadowgrey);"><i
                                            class="fa fa-download"
                                            style="font-size: 14px;color: var(--maingreen);"></i>&nbsp;Download</button>
                                </div>
                            </div>
                        </ng-container> -->

                    </div>
                </div>
                <div class="modal-footer d-lg-flex align-items-lg-center"><a (click)="resetCompleteRfq()"
                        class="btn text-gray-500" role="button" data-bs-dismiss="modal">Cancel</a>

                    <a (click)="onNext()" data-bs-toggle="modal" data-bs-target="#rating" data-bs-dismiss="modal"
                        class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn" role="button"
                        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Continue</a>

                    <!-- <a *ngIf="selectedRFQ.deliveryNoteFileId == null" (click)="deliveryNoteValid()"
                        class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn" role="button"
                        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Continue</a> -->
                </div>
            </div>
        </div>
    </div>

    <!-- RATING -->
    <form [formGroup]="ratingForm" #testRating="ngForm">
        <div role="dialog" tabindex="-1" class="modal fade" id="rating">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header" style="background: var(--maingreen);">
                        <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">
                            Rate
                            the
                            supplier</h1><button #markAsCompleteClose (click)="resetCompleteRfq()" type="button"
                            class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                <div class="col" style="padding-left: 0;">
                                    <p class="text-center d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-center justify-content-md-center justify-content-lg-center blueheadsmall"
                                        style="margin-bottom: 0;padding-left: 32px;">How would you rate this supplier
                                    </p>
                                </div>
                            </div>
                            <!-- <p>{{testRating.value | json}}</p>    -->
                            <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow"
                                style="padding-left: 22px;background: white;border-radius: 10px;">
                                <div class="col">
                                    <p class="text-nowrap bluetextthin" style="font-weight: normal;">Bad</p>
                                </div>
                                <div
                                    class="col-auto col-sm-8 col-md-8 d-flex d-xl-flex justify-content-center align-items-center justify-content-xl-center align-items-xl-center">
                                    <fieldset class="rating">

                                        <input formControlName="supplierRating5" [(ngModel)]="resetRatingForm"
                                            (click)=" rateSupplier(5)" type="radio" id="star5" value="5" /><label
                                            class="full" for="star5" title="Awesome - 5 stars"></label>

                                        <input formControlName="supplierRating4" [(ngModel)]="resetRatingForm"
                                            (click)=" rateSupplier(4)" type="radio" id="star4" value="4" /><label
                                            class="full" for="star4" title="Pretty good - 4 stars"></label>

                                        <input formControlName="supplierRating3" [(ngModel)]="resetRatingForm"
                                            (click)=" rateSupplier(3)" type="radio" id="star3" value="3" /><label
                                            class="full" for="star3" title="Meh - 3 stars"></label>

                                        <input formControlName="supplierRating2" [(ngModel)]="resetRatingForm"
                                            (click)=" rateSupplier(2)" type="radio" id="star2" value="2" /><label
                                            class="full" for="star2" title="Kinda bad - 2 stars"></label>

                                        <input formControlName="supplierRating1" [(ngModel)]="resetRatingForm"
                                            (click)=" rateSupplier(1)" type="radio" id="star1" value="1" /><label
                                            class="full" for="star1" title="Sucks big time - 1 star"></label>
                                    </fieldset>
                                </div>
                                <div
                                    class="col d-flex d-xxl-flex justify-content-center align-items-center align-items-lg-center justify-content-xxl-center">
                                    <p class="text-nowrap d-xxl-flex bluetextthin" style="font-weight: normal;">
                                        Excellent
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col d-flex d-lg-flex justify-content-center align-items-center justify-content-lg-center"
                                    style="padding-top: 10px;padding-bottom: 10px;"><a (click)=" clearSupplierRating()"
                                        class="btn d-flex d-lg-flex justify-content-center align-items-center justify-content-lg-center align-items-lg-center yellowbtn"
                                        role="button"
                                        style="font-size:12px; border-style: none;border-radius: 20px;width: 129.0312px;height: 28px;">Clear
                                        Rating</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer d-lg-flex align-items-lg-center"><a (click)="resetCompleteRfq()"
                            class="btn text-gray-500" role="button" data-bs-dismiss="modal">Close</a>

                        <button [disabled]="loading" *ngIf="this.supplierRating == 0"
                            class="btn d-sm-flex d-lg-flex justify-content-sm-center align-items-sm-center justify-content-lg-center align-items-lg-center yellowbtn"
                            role="button" (click)="completeRfqCheck(selectedRFQ.rfqId,'Final')"
                            style="font-size:12px; border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Skip
                            Rating
                            & Confirm<span *ngIf="loading"
                                class="spinner-border spinner-border-sm mr-1"></span></button>


                        <button [disabled]="loading" *ngIf="this.supplierRating > 0"
                            class="btn d-sm-flex d-lg-flex justify-content-sm-center align-items-sm-center justify-content-lg-center align-items-lg-center yellowbtn"
                            role="button" (click)="completeRfqCheck(selectedRFQ.rfqId,'Final')"
                            style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Confirm<span
                                *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span></button>

                        <button data-bs-toggle="modal" data-bs-target="#movedcomplete" data-bs-dismiss="modal"
                            #markAsCompleteFinal
                            class="btn d-sm-flex d-lg-flex justify-content-sm-center align-items-sm-center justify-content-lg-center align-items-lg-center yellowbtn"
                            role="button"
                            style=" display: none !important; border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Confirm
                            Hidden<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span></button>

                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- MOVED TO COMPLETED -->

    <!-- MODAL SUCCESS -->
    <div role="dialog" data-bs-backdrop="static" tabindex="-1" class="modal fade" id="movedcomplete">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header" style="background: var(--maingreen);">
                    <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">RFQ
                        Completed</h1>
                    <!-- <button type="button" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button> -->
                </div>
                <div class="modal-body">
                    <div>
                        <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                            <div class="col" style="padding-left: 0;">
                                <p class="d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-center justify-content-md-center justify-content-lg-center blueheadsmall"
                                    style="margin-bottom: 0;padding-left: 32px;"><i class="fa fa-check-circle"
                                        style="color: var(--maingreen);width: 29.7188px;font-size: 18px;"></i>{{selectedRFQ.rfqName}}
                                    Succesfully moved to Completed</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-lg-flex align-items-lg-center">
                    <a (click)=" reloadAfterQuoteCompleteStay(selectedRFQ.rfqId)" class="btn text-gray-500"
                        role="button" data-bs-dismiss="modal">Close</a>

                    <a *ngIf="quoteStateCheck == 'Closed'" data-bs-dismiss="modal"
                        class="btn d-sm-flex d-lg-flex justify-content-sm-center align-items-sm-center justify-content-lg-center align-items-lg-center yellowbtn"
                        role="button" (click)="reloadAfterQuoteComplete()"
                        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Go To Rfq</a>
                </div>
            </div>
        </div>
    </div>


    <!-- FILE DOWNLOADS MODAL -->
    <div role="dialog" tabindex="-1" class="modal fade" id="filedownload">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header" style="background: var(--maingreen);">
                    <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">
                        Complete
                        Payments</h1><button type="button" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                            <div class="col" style="padding-left: 0;">
                                <p class="text-center d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-center justify-content-md-center justify-content-lg-center blueheadsmall"
                                    style="margin-bottom: 0;padding-left: 32px;">Download the following files</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col d-lg-flex justify-content-lg-start"
                                style="padding-left: 0;padding-bottom: 12px;">
                                <div style="margin-left: 2.5rem;margin-top: 1rem;width: 437px;">
                                    <div class="row d-xl-flex" style="padding-top: 0;min-width: 498px;width: 183.25px;">
                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-4 col-xxl-4 d-md-flex justify-content-md-center align-items-md-center"
                                            style="padding: 0;width: 183.25px;">
                                            <div class="btn-group bluebtn" role="group"
                                                aria-label="Basic checkbox toggle button group"><input type="checkbox"
                                                    id="q" class="btn-check form-check-input" name="q" /><label
                                                    class="form-label form-check-label btn btn-outline-primary" for="q"
                                                    style="border-radius: 40px;font-size: 14px;" name="q">Quote</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row d-xl-flex" style="padding-top: 0;min-width: 498px;width: 553px;">
                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-4 col-xxl-4 d-md-flex justify-content-md-center align-items-md-center"
                                            style="padding: 0;width: 183.25px;">
                                            <div class="btn-group bluebtn" role="group"
                                                aria-label="Basic checkbox toggle button group"><input type="checkbox"
                                                    id="so" class="btn-check form-check-input" name="so" /><label
                                                    class="form-label form-check-label btn btn-outline-primary" for="so"
                                                    style="border-radius: 40px;font-size: 14px;"
                                                    name="flexRadioDefault">Sales Order</label></div>
                                        </div>
                                    </div>
                                    <div class="row d-xl-flex" style="padding-top: 0;min-width: 498px;width: 553px;">
                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-4 col-xxl-4 d-md-flex justify-content-md-center align-items-md-center"
                                            style="padding: 0;width: 183.25px;">
                                            <div class="btn-group bluebtn" role="group"
                                                aria-label="Basic checkbox toggle button group"><input type="checkbox"
                                                    id="inv" class="btn-check form-check-input" name="inv" /><label
                                                    class="form-label form-check-label btn btn-outline-primary"
                                                    for="inv" style="border-radius: 40px;font-size: 14px;"
                                                    name="inv">Invoice</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row d-xl-flex" style="padding-top: 0;min-width: 498px;width: 553px;">
                                        <div class="col-8 col-sm-3 col-md-3 col-lg-3 col-xl-4 col-xxl-4 d-md-flex justify-content-md-center align-items-md-center"
                                            style="padding: 0;width: 183.25px;">
                                            <div class="btn-group bluebtn" role="group"
                                                aria-label="Basic checkbox toggle button group"><input type="checkbox"
                                                    id="tc" class="btn-check form-check-input" name="tc" /><label
                                                    class="form-label form-check-label btn btn-outline-primary" for="tc"
                                                    style="border-radius: 40px;font-size: 14px;" name="tc">Terms &amp;
                                                    Conditions</label></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow"
                            style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;">
                            <div class="col">
                                <p class="text-nowrap" style="font-weight: normal;font-size: 12px;">2/4 files selected
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-lg-flex align-items-lg-center"><a class="btn text-gray-500" role="button"
                        data-bs-dismiss="modal">Close</a><a
                        class="btn d-flex d-lg-flex justify-content-center align-items-center justify-content-lg-center align-items-lg-center yellowbtn"
                        role="button"
                        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Download</a>
                </div>
            </div>
        </div>
    </div>

    <!-- Payment Reference Modal-->
    <div role="dialog" tabindex="-1" class="modal fade" id="completepaymentIP">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header" style="background: var(--maingreen);">
                    <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">
                        Complete
                        Payment - {{selectedRFQ.rfqName}}</h1><button type="button" class="btn-close"
                        (click)=" togglePeriodicReload(false)" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form [formGroup]="payForm">
                    <div class="modal-body">
                        <div>
                            <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                <div class="col" style="padding-left: 0;">
                                    <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                        style="margin-bottom: 0;padding-left: 20px;"><i class="fa fa-asterisk uiicon"
                                            style="font-size: 6px;color: #ff0000;"></i><strong>Provide the payment
                                            reference
                                            below</strong><br />

                                        <ng-container
                                            *ngIf=" stringValidation(pf.paymentReference.value)  && paymentValidCond == false">
                                            <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                                        </ng-container>

                                        <ng-container
                                            *ngIf="pf.paymentReference.value.length > 50  && paymentValidCond == false">
                                            <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds Max
                                                Length)</span>
                                        </ng-container>

                                    </p>
                                    <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0;">
                                    </p>
                                    <div style="margin-top: 1rem;width: 100%;">
                                        <div style="height: auto;margin-left: 2rem;margin-top: 1rem;width: 25rem;">
                                            <input formControlName="paymentReference" type="text" class="form-control"
                                                autocomplete="off" style="width: 420px;"
                                                placeholder="Payment Reference" />
                                        </div>
                                    </div>

                                </div>


                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                    style="margin-bottom: 0;padding-left: 32px; padding-top: 20px;">Proof Of Payment
                                    Upload (Optional)
                                </p>
                                <span *ngIf="selectedRFQ.proofOfPaymentFileId != null"
                                    class="text-nowrap d-xl-flex d-xxl-flex align-items-xl-end"
                                    style="font-weight: normal;font-size: 12px;"> <i
                                        class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center"
                                        style="font-size: 16px; padding-left: 22px;"></i>
                                    {{selectedRFQ.proofOfPaymentFileName}}</span>

                                <div *ngIf="proofOfPaymentUploading || proofOfPaymentDeleting"
                                    class="text-center text-center bluehead thin" style="font-size: 14px;"><span
                                        class="spinner-border spinner-border-sm mr-1"></span>
                                    Uploading...
                                </div>
                            </div>

                            <div class="mt-1 row d-flex d-xxl-flex align-items-center rfqdetailrow"
                                style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;">
                                <div class="col">
                                    <p class="text-nowrap bluetextthin" style="font-weight: normal;">Proof of Payment
                                    </p>

                                </div>
                                <div class="col">
                                    <p class="bluetextthin" style="font-weight: normal;"></p>
                                </div>



                                <div *ngIf="selectedRFQ.proofOfPaymentFileId == null" class="col"
                                    style="padding-left: 0;">
                                    <input #clearCompanyLogoInput type="file" class="file-input"
                                        (change)="proofOfPaymentSelected($event)" #PPfileUploadIP
                                        accept="{{requiredFileType}}">

                                    <button class="btn btn-secondary d-flex d-xxl-flex align-items-center invoicebtn"
                                        role="button" (click)="PPfileUploadIP.click()"
                                        [disabled]="proofOfPaymentUploading || proofOfPaymentDeleting"
                                        style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);">
                                        <i class="fas fa-upload"
                                            style="font-size: 14px;color: var(--maingreen);"></i>&nbsp;Upload</button>

                                </div>
                                <div *ngIf="selectedRFQ.proofOfPaymentFileId != null" class="col"
                                    style="padding-left: 0;">
                                    <div class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                        style="background: var(--white);padding: 7px;border-radius: 40px;">
                                        <i class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 0;"></i>
                                        <p
                                            style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;">
                                            &nbsp;Uploaded</p>
                                    </div>
                                </div>

                                <div class="col d-flex d-xxl-flex justify-content-center justify-content-xxl-center">
                                    <input #clearPPInput type="file" class="file-input"
                                        (change)="proofOfPaymentSelected($event)" #PPEfileUploadIP
                                        accept="{{requiredFileType}}">
                                    <button
                                        [disabled]="selectedRFQ.proofOfPaymentFileId == null || proofOfPaymentUploading || proofOfPaymentDeleting"
                                        class="btn btn-secondary invoicebtn" role="button"
                                        (click)="PPEfileUploadIP.click()"
                                        style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                            class="fa fa-edit"
                                            style="font-size: 14px;color: var(--maingreen);"></i></button>
                                </div>

                                <div class="col d-flex d-xxl-flex justify-content-center justify-content-xxl-center">

                                    <button (click)="downloadDrawingFile(selectedRFQ.proofOfPaymentFileId)"
                                        class="btn btn-secondary invoicebtn" role="button"
                                        [disabled]="selectedRFQ.proofOfPaymentFileId == null || proofOfPaymentUploading || proofOfPaymentDeleting"
                                        style="padding:0px;color:var(--darkblue);font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color:
                                                                                                                                                                                    var(--shadowgrey);"><i
                                            class="fa fa-download"
                                            style="font-size: 14px;color: var(--maingreen);"></i>&nbsp;Download</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>

                <div class="modal-footer d-lg-flex align-items-lg-center">
                    <a #makePaymentCloseIP class="btn text-gray-500" role="button"
                        (click)=" togglePeriodicReload(false)" data-bs-dismiss="modal">Cancel</a>

                    <button #makePaymentFinalIP (click)="onNext()" data-bs-toggle="modal"
                        data-bs-target="#movedarchiveIP" data-bs-dismiss="modal"
                        class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn" role="button"
                        style="display: none !important;border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Confirm
                        Hidden</button>

                    <button *ngIf="paymentValid()" [disabled]="makingPayment"
                        (click)="makePaymentCheck(selectedRFQ.rfqId,'Final')"
                        class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn" role="button"
                        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Confirm<span
                            *ngIf="makingPayment" class="spinner-border spinner-border-sm mr-1"></span></button>

                    <button *ngIf="!paymentValid()" (click)="paymentValidCheck()"
                        class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn" role="button"
                        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Confirm<span
                            *ngIf="makingPayment" class="spinner-border spinner-border-sm mr-1"></span></button>

                </div>

            </div>
        </div>
    </div>




    <!-- Payment Success Modal -->
    <div role="dialog" tabindex="-1" class="modal fade" id="movedarchiveIP">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header" style="background: var(--maingreen);">
                    <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">RFQ
                        Completed</h1><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        (click)=" togglePeriodicReload(false)" (click)="triggerReloadRfqs(true)"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                            <div class="col" style="padding-left: 0;">
                                <p class="d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-center justify-content-md-center justify-content-lg-center blueheadsmall"
                                    style="margin-bottom: 0;padding-left: 32px;"><i class="fa fa-check-circle"
                                        style="color: var(--maingreen);width: 29.7188px;font-size: 18px;"></i>{{selectedRFQ.rfqName}}
                                    Has Succesfully Been Paid </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-lg-flex align-items-lg-center"><a class="btn text-gray-500" role="button"
                        data-bs-dismiss="modal" (click)=" togglePeriodicReload(false)"
                        (click)="triggerReloadRfqs(true)">Close</a>
                </div>
            </div>
        </div>
    </div>

    <!-- This is where the Q&A modal starts -->
    <section>
        <div class="modal fade" data-bs-backdrop="static" role="dialog" tabindex="-1"
            [attr.id]="'bwe' + selectedRFQ.rfqId" aria-labelledby="exampleModalLabel">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content ">
                    <div class="modal-body  " style="padding: 0px; border: 0px; ">
                        <div *ngIf="selectedRFQ">
                            <app-questions-and-answers [getQA]="getQA" (publicChatToggle)=" togglePubChat($event)"
                                (periodicUpdateCondIPR)="togglePeriodicReload($event)" [isBuyer]="isBuyer"
                                [isSupplier]="isSupplier" [modalheader]="modalheader" [rfq]="selectedRFQ"
                                [rfqId]="selectedRFQ.rfqId" [rfqStatus]="rfqStatusPC">
                            </app-questions-and-answers>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


</ng-container>

<ng-container #nodata *ngIf="inProgressRfqs.length == 0 && !dataLoading">
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel" id="tab-1">
            <div class="container-fluid greencont">
                <h1 class="text-center" style="font-size: 20px;">No RFQs to display
                </h1>


            </div>
        </div>
    </div>

    <div style="height: 100vh;">
        <div class="d-flex flex-column justify-content-center align-items-center">
            <img src="assets/nodatagreen.png" style="margin-top: 8rem;">
            <h1 class="text-center text-center bluehead" style="font-size: 20px;margin-top: 1rem; ">No data
            </h1>

        </div>
    </div>
</ng-container>

<ng-container #loading *ngIf="dataLoading">
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel" id="tab-1">
            <div class="container-fluid greencont">
                <h1 class="text-center" style="font-size: 20px;">Fetching RFQS...
                </h1>


            </div>
        </div>
    </div>


    <div class="container-fluid d-flex justify-content-center align-items-center"
        style="margin-top:-10rem;min-height: 100vh; ">
        <div class="text-center text-center bluehead"><span class="spinner-border spinner-border-sm mr-1"></span>
            Loading...
        </div>

    </div>

</ng-container>