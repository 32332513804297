import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError, map, tap, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Account } from '../models/account';
import { ForgotPassword } from '../models/forgot-password';
import { ResetPassword } from '../models/resetPassword';
import { PasswordResetToken } from '../models/passwordResetToken';
import { ToastrNotificationService } from '../services/toastr-notification.service';
import { EditUser } from '../models/editUser';
import { MessageService } from '../services/message.service';
import { CreateUser } from '../models/createUser';
import { ResendWelcomeEmail } from '../models/resendWelcomeEmail';

//Real backend
const authUrl = `${environment.apiUrl}/Auth`;
const baseUrl = `${environment.apiUrl}/Users`;

//Fake backend
// const baseUrl = `${environment.apiUrl}/accounts`;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // private accountSubject: BehaviorSubject<Account | null>;/
  private accountSubject: BehaviorSubject<Account>;
  public account: Observable<Account>;
  private userLoggedIn = new Subject<boolean>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private toastrNotification: ToastrNotificationService,
    private messageService: MessageService,
  ) {
    const accString = localStorage.getItem('Account');
    let initialAccount: Account = null;
    if (accString !== null) initialAccount = JSON.parse(accString);

    this.accountSubject = new BehaviorSubject<Account>(initialAccount);
    this.account = this.accountSubject.asObservable();

    if (accString !== null) { this.userLoggedIn.next(true); }
    else { this.userLoggedIn.next(false); }
  }

  public get accountValue(): Account {
    return this.accountSubject.value;
  }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  //Real Backend
  login(email: string, password: string) {
    return this.http.post<any>(`${authUrl}/get-token`, { email, password }, { withCredentials: true })
      .pipe(map(account => {
        this.accountSubject.next(account);
        localStorage.setItem('Account', JSON.stringify(account));
        this.userLoggedIn.next(true);
        //this.startRefreshTokenTimer();
        return account;
      }));
  }

  //Fake backend
  //  login(email: string, password: string) {
  //   return this.http.post<any>(`${baseUrl}/authenticate`, { email, password }, { withCredentials: true })
  //      .pipe(map(account => {
  //        this.accountSubject.next(account);
  //       //this.startRefreshTokenTimer();
  //        return account;
  //      }));
  // }

  logout() {
    this.http.post<any>(`${baseUrl}/revoke-token`, {}, { withCredentials: true }).subscribe();
    //this.stopRefreshTokenTimer();
    localStorage.removeItem('Account');
    this.accountSubject.next(null);
    this.userLoggedIn.next(false);
    this.router.navigate(['/account/login']);
  }

  refreshToken() {
    return this.http.post<any>(`${baseUrl}/refresh-token`, {}, { withCredentials: true })
      .pipe(map((account) => {
        this.accountSubject.next(account);
        //this.startRefreshTokenTimer();
        return account;
      }));
  }

  register(account: Account) {
    return this.http.post(`${baseUrl}/register`, account);
  }

  verifyEmail(token: string) {
    return this.http.post(`${baseUrl}/verify-email`, { token });
  }

  // forgotPassword(forgotPasswordEmail: ForgotPassword) {
  //   return this.http.post(`${baseUrl}/forgot-password`, { forgotPasswordEmail });
  // }

  /** POST: Forgot Email*/
  forgotPassword(forgotPasswordEmail: ForgotPassword): Observable<ForgotPassword> {
    const url = `${baseUrl}/forgot-password`;
    return this.http.post<any>(url, forgotPasswordEmail, this.httpOptions)
      .pipe(
        map(forgotPasswordEmailMessage => { console.log(forgotPasswordEmailMessage.message); return forgotPasswordEmailMessage.message }),
        tap((forogtPassword: ForgotPassword) => console.log(`Forgot Password Email Sent`)),
        catchError(this.handleError<ForgotPassword>('Forgot Password Email Sent'))
      );
  }

  /** POST: edit user details */
  editUserDetails(details: EditUser): Observable<EditUser> {
    const url = `${baseUrl}/EditUser`;
    return this.http.post<any>(url, details, this.httpOptions)
      .pipe(
        map(editResult => {  return editResult.message }),
        tap((editResult: EditUser) => this.log(`buyer details succesfully`)),
        catchError(this.handleError<EditUser>('buyer details edit'))
      );
  }

  /** POST: edit user details */
  resendWelcomeEmail(details: ResendWelcomeEmail): Observable<ResendWelcomeEmail> {
    const url = `${baseUrl}/ResendWelcomeEmail`;
    return this.http.post<any>(url, details, this.httpOptions)
      .pipe(
        map(emailResult => {return emailResult.message }),
        tap((editResult: ResendWelcomeEmail) => this.log(`buyer details succesfully`)),
        catchError(this.handleError<ResendWelcomeEmail>('buyer details edit'))
      );
  }

  /** POST: edit user details */
  createUser(details: CreateUser): Observable<CreateUser> {
    const url = `${baseUrl}/Register`;
    return this.http.post<any>(url, details, this.httpOptions)
      .pipe(
        map(editResult => { console.log(editResult.message); return editResult.message }),
        tap((editResult: CreateUser) => this.log(`User Added  succesfully`)),
        catchError(this.handleError<CreateUser>('User details edit'))
      );
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`BuyerCompanyService: ${message}`);
  }
  // validateResetToken(token: string) {
  //   return this.http.post(`${baseUrl}/validate-reset-token`, { token });
  // }

  /** POST: Send Reset Email*/
  // validateResetToken(passwordResetToken: PasswordResetToken): Observable<PasswordResetToken> {
  //   const url = `${baseUrl}/forgot-password`;
  //   return this.http.post<any>(url, passwordResetToken, this.httpOptions)
  //     .pipe(
  //       map(passwordResetToken => { console.log(passwordResetToken.message); return passwordResetToken.message }),
  //       tap((passwordResetToken: PasswordResetToken) => console.log(`Token Verified`)),
  //       catchError(this.handleError<PasswordResetToken>('Error'))
  //     );
  // }

  // resetPassword(email: string, token: string, password: string, confirmPassword: string) {
  //   return this.http.post(`${baseUrl}/reset-password`, {email, token, password, confirmPassword });
  // }

  /** POST: Password Reset*/
  resetPassword(resetPasswordDto: ResetPassword): Observable<ResetPassword> {
    const url = `${baseUrl}/reset-password`;
    return this.http.post<any>(url, resetPasswordDto, this.httpOptions)
      .pipe(
        //map(resetPasswordDtoMessage => { console.log(resetPasswordDtoMessage.message); return resetPasswordDtoMessage.message }),
        //tap((forogtPassword: ResetPassword) => console.log(`Password Reset`)),
        catchError(this.handleError<ResetPassword>('Password Reset'))
      );
  }

  deleteUser(userName: string): Observable<string> {
    const url = `${baseUrl}/deleteUser/${userName}`;
    return this.http.get<any>(url)
      .pipe(
        map(deletedMessage => { return deletedMessage.message }),
        tap(_ => this.log(`fetched Rfq`)),
        catchError(this.handleError<string>(`deleteUser}`))
      );
  }
  

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  getAll() {
    return this.http.get<Account[]>(baseUrl);
  }

  getById(id: string) {
    return this.http.get<Account>(`${baseUrl}/${id}`);
  }

  create(params: any) {
    return this.http.post(baseUrl, params);
  }

  update(id: string, params: any) {
    return this.http.put(`${baseUrl}/${id}`, params)
      .pipe(map((account: any) => {
        // update the current account if it was updated
        if (account.id === this.accountValue.id) {
          // publish updated account to subscribers
          account = { ...this.accountValue, ...account };
          this.accountSubject.next(account);
        }
        return account;
      }));
  }

  delete(id: string) {
    return this.http.delete(`${baseUrl}/${id}`)
      .pipe(finalize(() => {
        // auto logout if the logged in account was deleted
        if (id === this.accountValue.id)
          this.logout();
      }));
  }

  // helper methods

  private refreshTokenTimeout: any;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.accountValue.token!.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  /**
* Handle Http operation that failed.
* Let the app continue.
* @param operation - name of the operation that failed
* @param result - optional value to return as the observable result
*/
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.toastrNotification.error(`${operation} failed: ${error.message}`);

      throw error;
    };
  }

  //Old angular tut methods
  //isLoggedIn = false;

  // store the URL so we can redirect after logging in
  //redirectUrl: string | null = null;

  // login(): Observable<boolean> {
  //   return of(true).pipe(
  //     delay(1000),
  //     tap(() => this.isLoggedIn = true)
  //   );
  // }

  // logout(): void {
  //   this.isLoggedIn = false;
  // }
}
