import { Component, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { FilterPipe } from './app/pipes/filter.pipe';


if (environment.production) {
  enableProdMode();
}

// export class maints {

//   constructor(){

//   };

//   displaySomething(){
//     console.log("it works")
//   }

// }

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

 
