import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, of, Subscription, interval, fromEventPattern, pipe } from 'rxjs';
import { switchMap, finalize, first, take, last, single, distinctUntilChanged, withLatestFrom, takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

//Services
import { RfqService } from '../services/rfq.service';
import { RFQ } from '../models/rfq';
import { Quote } from '../models/quote';
import { RFQS } from '../models/mock-data';
import { MessageService } from '../services/message.service';
import { DialogService } from '../services/dialog.service';
// import { Console } from 'console';
import { LiteralArray, ThrowStmt } from '@angular/compiler';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { RFQHeaddisplay } from '../models/header';
import { ViewRfq, ViewRFQs_Quotes } from '../models/viewRfq';
import { ViewRfqServiceService } from '../services/view-rfq-service.service';
import { ReloadViewRfqs } from '../models/reloadViewRfqs';
import { SortCriteria } from '../pipes/sort-asc-dsc.pipe';
import { AuthService } from '../auth/auth.service';
import { Account } from '../models/account';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrNotificationService } from '../services/toastr-notification.service';
import { FindValueOperator } from 'rxjs/internal/operators/find';
import { RfqHeadings } from '../models/rfqHeadings';
import { QuoteService } from '../services/quote.service';
import { FileDownloadedService } from '../services/file-downloaded.service';
import { SupplierCompanyService } from '../services/supplier-company.service';
import { UploadRfqFile } from '../models/rfqCreate';
import { CreditApplicationCreate } from '../models/buyerCompany';
import { BuyerCompanyService } from '../services/buyer-company.service';


@Component({
  selector: 'app-open-rfq',
  templateUrl: './open-rfq.component.html',
  styleUrls: ['./open-rfq.component.css']
})
export class OpenRfqComponent implements OnInit, OnDestroy, OnChanges {

  public criteriaView: SortCriteria;

  @Input() reloadOpenRfqsFromTab: boolean;

  @Input() openRfqId: number;

  @Input() state: string;

  @Input() openRfqNav: string;

  @Output() changeTab = new EventEmitter<boolean>();

  tabToInProgress(value: boolean) {
    this.changeTab.emit(value);
  }


  @Output() changeToOpenTab = new EventEmitter<boolean>();

  tabToOpenRfqs(value: boolean) {
    this.changeToOpenTab.emit(value);
  }

  private subscription: Subscription;

  //NEEDED FOR CREATING Q&A QUESTIONS AND REPLIES
  isBuyer: boolean = true;
  isSupplier: boolean = false;
  rfqStatusPC: string = "Open";
  acceptQuoteBtnDisplay: string = "";

  openQuotesCounter: number = 0;

  //NEEDED FOR GET QUESTIONS AND ANSWERS
  getQA: boolean = false;

  SideSelectedRFQ: ViewRfq;
  dataLoading: boolean = false;
  openRfqs: RfqHeadings[] = [];
  openRfqsUpdate: RfqHeadings[] = [];
  reloadOpenRfqs: ViewRfq[] = [];
  form: FormGroup;
  creditApplication: FormGroup;

  rfq: ViewRfq;
  rfqIndex: number;
  selectedRfqId: number;
  quote: Quote;
  editName = '';
  quoteResult: string = '';
  rfqUsed: boolean = false;
  rfqEditInProgress: boolean = false;
  selectedQuoteId: number;


  selectedRFQ: ViewRfq;
  openQuotes: ViewRFQs_Quotes[];
  RFQHeadings: string[] = [];
  selectedQuote?: ViewRFQs_Quotes = null;
  sortbyHeadings: RFQHeaddisplay[] = [];
  sortbyHeadingsUpdate: RFQHeaddisplay[] = [];
  singleSortHeadings: RFQHeaddisplay = { rfqHeadName: null, rfq: null, id: null };
  headingId: number;
  updatedInProgress: ReloadViewRfqs = { reloadCondition: null, reloadRfqId: null };
  check: boolean = false;
  reloadInProgressRfqCond: boolean;
  //for rfq table search 
  title = 'custom-search-filter-example';
  searchedKeyword: string;
  searchrfq: string = '';
  searchdimensions: string;
  searchdrawings: string;
  searchquotes: string;

  acceptQuoteHide: boolean = false;
  accpetingQuote: boolean = false;
  loadingRfq: boolean = false;
  reloadingSelectedRfq: boolean;

  RFQsearch: FormGroup;

  //  openRfqNav: string;

  searchRFQs: string = "";
  searchRFQsCheck: string = "";

  // USED TO CHECK IF EDITED QUOTES HAS BEEN SEEN AND SETS THEM TO SEEN ON CLICK METHOD
  setEditedQuotetoSeen: string;
  seenEditedQuote: string;
  quoteIdList: number[];



  @ViewChild('acceptQuoteBtn') acceptQuoteAllowed: ElementRef;

  @ViewChild('closeQuoteMdl') acceptQuoteModalClose: ElementRef;

  @ViewChild('navFromOpenMdl') acceptQuoteModalNav: ElementRef;

  @ViewChild('acceptQuoteFinal') acceptQuoteFinal: ElementRef;

  //used for green modals Like the public chat
  modalheader: string = 'green';

  quoteFileSelected: boolean = false;

  removespaces() {
    this.searchrfq
  }

  //toggle accept buttons thing
  showMe: boolean = true;

  //Date Calcs
  public dateNow = new Date();
  public dDay?: Date;
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  private getTimeDifference() {
    this.timeDifference = this.dDay.getTime() - new Date().getTime();

    this.allocateTimeUnits(this.timeDifference);
  }

  public getClosingTime(date) {
    this.dDay = new Date(date);
    this.getTimeDifference();

    if (this.daysToDday < 0) {

      return 'Closed';
    } else {

      return this.daysToDday + ' Days ' + this.hoursToDday + ' Hrs ' + this.minutesToDday + ' Mins';
    }

  }

  private allocateTimeUnits(timeDifference) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }

  account: Account;

  constructor(
    public cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private rfqService: RfqService,
    private quoteService: QuoteService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private location: Location,
    private router: Router,
    private reloader: ViewRfqServiceService,
    public authService: AuthService,
    private toastrNotification: ToastrNotificationService,
    private fileDownloadedService: FileDownloadedService,
    private route: ActivatedRoute,
    private supplierCompanyService: SupplierCompanyService,
    private buyerCompanyService: BuyerCompanyService
  ) {
    this.criteriaView = {
      property: 'supplierServicesView',
      descending: true
    };

    this.authService.account.subscribe(x => this.account = x);
  }

  ngOnChanges() {
    if (this.state == "Open" && ((this.openRfqs.length == 0 || this.openRfqId == 0) || this.openRfqNav == 'true')) {
      this.getOpenRFQS(this.openRfqId);
    } else if (this.state == "Open" && this.openRfqs.length > 0 && this.openRfqNav == 'false') {
      this.onSelectRFQ(this.openRfqId);
    }
  }

  ngOnInit() {

    this.reloadingSelectedRfq = false;

    this.form = this.formBuilder.group({

      quoteFile: [false]

    });

    this.creditApplication = this.formBuilder.group({

      dayRange: [""],

    });

    this.RFQsearch = this.formBuilder.group({

      complete: [false],
      rawmat: [false],
      cod: [false],
      cbd: [false],
      thirtyDays: [false],
      sixtyDays: [false],
      paymentOther: [false],
      delivery: [false],
      collection: [false],
      boxed: [false],
      pallets: [false],
      strapped: [false],
      loose: [false],
      packagingOther: [false],
      testCertificationYes: [false],
      testCertificationNo: [false]

    });

    this.modalheader = 'green';
    // this.headingId = 1;
    // this.singleSortHeadings.rfqHeadName = "Multiple Services";
    // this.singleSortHeadings.rfq = [];
    // this.sortbyHeadings.push(this.singleSortHeadings);
    // this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
    // this.headingId++;


    this.subscription = interval(300000)
      .subscribe(x => { this.periodicReload(); });

    // this.route.queryParamMap.subscribe(params => {

    //   this.openRfqNav = params.get('nav');

    //   this.cdr.detectChanges();
    // });

    // if(this.editRfqInProgress == false){
    //     this.subscription = interval(5000)
    //     .subscribe(x => {this.periodicReload();} );
    // }

    this.showMe = true;

    // this.reloader.currentNavNewRfq.subscribe(reloadCond => {

    //   if (reloadCond.reloadCondition) {

    //     this.navToViewRfq(reloadCond.reloadRfqId);
    //     this.tabToOpenRfqs(true);
    //   } else if (!reloadCond.reloadCondition) {
    //     if (this.openRfqs.length == 0) {
    //       this.getOpenRFQS(0);

    //     }
    //   }
    //   return;
    // });


  }
  removeWhiteSpace(value) {
    var newValue = value.trim();
    return newValue;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();

  }


  roundOffRating(value) {
    return Math.round(value);
  }

  createNumberIterator(value) {
    var roundValue = Math.round(value)
    var numArray = Array(roundValue).fill(1).map((x, i) => i); // [0,1,2,3,4]

    return numArray;
  }


  togglePeriodicReload(cond) {
    if (cond) {
      this.rfqUsed = true;
      // this.getQA = true;
    } else if (!cond) {
      this.rfqUsed = false;
      //  this.getQA = false;
    }


  }

  togglePubChat(cond) {
    if (cond) {
      // this.rfqUsed = true;
      this.getQA = true;
    } else if (!cond) {
      //  this.rfqUsed = false;
      this.getQA = false;
    }
  }

  periodicReload() {


    if (this.rfqUsed == false && this.rfqEditInProgress == false && this.reloadOpenRfqsFromTab) {

      this.sortbyHeadingsUpdate = [];
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

      this.headingId = 1;
      this.singleSortHeadings.rfqHeadName = "Multiple Services";
      this.singleSortHeadings.rfq = [];
      this.singleSortHeadings.id = this.headingId;
      this.sortbyHeadingsUpdate.push(this.singleSortHeadings);
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
      this.headingId++;

      this.getOpenRFQSAfterUpdate();
    }
  }

  getOpenRFQSAfterUpdate() {

    this.reloadingSelectedRfq = true;

    this.rfqService.getRFQHeadings(this.account.account.buyerId, "Open").subscribe(rfqs => {

      if (rfqs.length > 0) {
        this.openRfqsUpdate = rfqs;

        if (this.filtersUsed) {
          this.filterUpdateRfqs(rfqs);
        }

        if (this.openRfqs.length > 0) {

          var newRfqIds: number[] = [];
          for (let newRfq of this.openRfqsUpdate) {
            newRfqIds.push(newRfq.rfqId)
          }

          if (newRfqIds.indexOf(this.selectedRFQ.rfqId) != -1) {
            for (const { index, value } of this.openRfqsUpdate.map((value, index) => ({ index, value }))) {
              if (value.rfqId == this.selectedRfqId) {
                this.rfqIndex = index;
                this.onSelectRFQ(rfqs[index].rfqId);
              }
            }

            this.sortRFQHeadArrayUpdate();
            this.replaceRfqsOnUpdate();

          } else {

            this.sortRFQHeadArrayUpdate();
            this.replaceRfqsOnUpdate();
            this.router.navigate(['buyer-home/view-rfq', 'Open', this.sortbyHeadings[0].rfq[0].rfqId, 'false']);
            // this.onSelectRFQ(this.sortbyHeadings[0].rfq[0]);
          }

          var oldRfqIds: number[] = [];
          for (let oldRfq of this.openRfqs) {
            oldRfqIds.push(oldRfq.rfqId)
          }


          for (let rfq of this.openRfqsUpdate) {
            if (oldRfqIds.indexOf(rfq.rfqId) == -1) {
              this.openRfqs.push(rfq);
            }
          }

          for (const { index, value } of this.openRfqs.map((value, index) => ({ index, value }))) {
            if (newRfqIds.indexOf(value.rfqId) == -1) {
              this.openRfqs.splice(index, 1);
            }
          }

        } else {

          for (let rfq of this.openRfqsUpdate) {
            this.openRfqs.push(rfq);
          }

          this.sortbyHeadings = [];
          this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

          this.headingId = 1;
          this.singleSortHeadings.rfqHeadName = "Multiple Services";
          this.singleSortHeadings.rfq = [];
          this.sortbyHeadings.push(this.singleSortHeadings);
          this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
          this.headingId++;

          for (let rfq of rfqs) {

            rfq.SideSelectedRFQ = false;
          }
          //

          this.sortRFQHeadArray();
          this.router.navigate(['buyer-home/view-rfq', 'Open', this.sortbyHeadings[0].rfq[0].rfqId, 'false']);
          //this.onSelectRFQ(this.sortbyHeadings[0].rfq[0]);

        }

      } else {
        this.openRfqs = []
        this.selectedRFQ = null;
      }



    });
  }

  replaceRfqsOnUpdate() {

    var newHeadings: string[] = [];
    var oldHeadings: string[] = [];

    for (let heading of this.sortbyHeadings) {
      oldHeadings.push(heading.rfqHeadName)
      for (let newHead of this.sortbyHeadingsUpdate) {
        if (heading.rfqHeadName == newHead.rfqHeadName) {
          heading.rfq = newHead.rfq;
          heading.openQuotesCounter = newHead.openQuotesCounter;
        }
      }
    }

    for (let newHeading of this.sortbyHeadingsUpdate) {
      newHeadings.push(newHeading.rfqHeadName);
    }

    // if (this.sortbyHeadings.length != this.sortbyHeadingsUpdate.length) {
    //  if (this.sortbyHeadings.length < this.sortbyHeadingsUpdate.length) {

    for (let newHead of this.sortbyHeadingsUpdate) {
      if (oldHeadings.indexOf(newHead.rfqHeadName) == -1) {
        this.sortbyHeadings.push(newHead);
      }
    }

    // } else {
    for (const { index, value } of this.sortbyHeadings.map((value, index) => ({ index, value }))) {
      if (newHeadings.indexOf(value.rfqHeadName) == -1) {
        this.sortbyHeadings.splice(index, 1);
      }
    }

    //  }

    // }

  }


  navToViewRfq(rfqId) {

    this.sortbyHeadings = [];
    this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

    this.headingId = 1;
    this.singleSortHeadings.rfqHeadName = "Multiple Services";
    this.singleSortHeadings.rfq = [];
    this.sortbyHeadings.push(this.singleSortHeadings);
    this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
    this.headingId++;

    this.getOpenRFQSAfterEdit(rfqId);
  }

  triggerReloadRfqs(value: boolean) {
    if (value) {

      this.sortbyHeadings = [];
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

      this.headingId = 1;
      this.singleSortHeadings.rfqHeadName = "Multiple Services";
      this.singleSortHeadings.rfq = [];
      this.sortbyHeadings.push(this.singleSortHeadings);
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
      this.headingId++;

      this.getOpenRFQSAfterEdit(this.selectedRfqId);
    }

  }

  toggleTag() {
    this.showMe = !this.showMe;
  }

  getOpenRFQS(selectedRfqId) {

    this.acceptQuoteBtnDisplay = "";

    this.dataLoading = true;

    this.sortbyHeadings = [];
    this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
    this.selectedRFQ = null;
    this.openRfqs = [];

    this.rfqService.getRFQHeadings(this.account.account.buyerId, "Open").pipe(
      finalize(() => this.dataLoading = false)
    ).subscribe(rfqs => {

      if (rfqs.length > 0) {

        this.headingId = 1;
        this.singleSortHeadings.rfqHeadName = "Multiple Services";
        this.singleSortHeadings.rfq = [];
        this.sortbyHeadings.push(this.singleSortHeadings);
        this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
        this.headingId++;

        this.openRfqs = rfqs;


        // SETS INITIAL VALUE OF SIDE NAV RFQ TO FALSE
        for (let rfq of rfqs) {

          rfq.SideSelectedRFQ = false;
        }
        //

        this.sortRFQHeadArray();

        if (selectedRfqId == 0) {
          this.router.navigate(['buyer-home/view-rfq', 'Open', this.sortbyHeadings[0].rfq[0].rfqId, 'false']);
        } else {
          this.onSelectRFQ(selectedRfqId);
        }

      }

    });

  }

  toggleDisplay() {
    this.acceptQuoteBtnDisplay = "noDisplay";
  }



  getOpenRFQSAfterEdit(rfqId) {

    this.dataLoading = true;

    this.rfqService.getRFQHeadings(this.account.account.buyerId, "Open").pipe(
      finalize(() => this.dataLoading = false)
    ).subscribe(rfqs => {
      this.openRfqs = rfqs;
      for (const { index, value } of this.openRfqs.map((value, index) => ({ index, value }))) {
        if (value.rfqId == rfqId) {
          this.rfqIndex = index;
          this.onSelectRFQ(rfqs[index].rfqId);
        }
      }
      this.sortRFQHeadArray();
    });
  }

  selectQuoteId(quoteId) {
    this.selectedQuoteId = quoteId;
  }

  selectQuote(quote) {
    this.selectedQuote = quote;
  }

  acceptQuote() {

    this.accpetingQuote = true;

    this.rfqService.acceptQuote(this.selectedQuoteId).pipe(first())
      .subscribe({
        next: (response: any) => {
          this.accpetingQuote = false;
          this.acceptQuoteModalClose.nativeElement.click();
          this.acceptQuoteFinal.nativeElement.click();

        },
        error: error => {
          this.toastrNotification.error("Accept Quote Failed");
          //this.submittingRfq = false;
        }

      });

  }

  acceptQuoteCheck(rfqId: number, cond: string, quoteId: number, quoteFileId: number) {

    this.selectedQuoteId = quoteId;

    this.togglePeriodicReload(true);

    this.quoteService.getQuoteAvailability(quoteId).pipe(first())
      .subscribe({
        next: (response: any) => {
          if (!response.quoteWithdrawn && response.quoteAvailable) {

            this.fileDownloadedService.getFileDownloadedCheck(quoteFileId, this.account.account.userId).subscribe(check => {

              if (check) {
                if (cond == "Initial") {
                  this.acceptQuoteAllowed.nativeElement.click();
                } else if (cond == "Final") {
                  this.acceptQuote();
                }
              } else {
                this.toastrNotification.info("Download And Confirm Quote File Before Accpeting Quote");
                this.togglePeriodicReload(false);
                this.acceptQuoteModalClose.nativeElement.click();
              }
            });
          } else {
            if (response.quoteWithdrawn) {
              this.toastrNotification.info("Quote Has Been Withdrawn for this Rfq");
            } else if (!response.quoteAvailable) {
              this.toastrNotification.info("A Quote Has Been Accepted for this Rfq");
            }

            this.togglePeriodicReload(false);

            if (cond == "Initial") {
              this.reloadAfterQuoteAcceptStay(rfqId)
            } else if (cond == "Final") {
              this.acceptQuoteModalClose.nativeElement.click();
              this.reloadAfterQuoteAcceptStay(rfqId)
            }

          }

        },
        error: error => {
          this.toastrNotification.error("Quote Accept Check Failed")
        }
      });
  }

  reloadAfterQuoteAcceptStay(rfqId) {

    this.togglePeriodicReload(false);

    for (const { index, value } of this.openRfqs.map((value, index) => ({ index, value }))) {
      if (value.rfqId == rfqId) {
        this.openRfqs.splice(index, 1);
      }
    }

    for (let heading of this.sortbyHeadings) {
      for (const { index, value } of heading.rfq.map((value, index) => ({ index, value }))) {
        if (value.rfqId == rfqId) {
          heading.rfq.splice(index, 1);
        }
      }
    }

    if (this.sortbyHeadings[0].rfq.length == 0) {
      this.sortbyHeadings.splice(0, 1);
    }
    if (this.openRfqs.length > 0) {
      this.router.navigate(['buyer-home/view-rfq', 'Open', this.sortbyHeadings[0].rfq[0].rfqId, 'false']);
    }
  }

  reloadAfterQuoteAccept() {

    this.togglePeriodicReload(false);

    this.router.navigate(['buyer-home/view-rfq', 'InProgress', this.selectedRFQ.rfqId, 'true']);

  }

  //Used for normal loading and navigating populating RFQS
  sortRFQHeadArray() {

    for (let indexrfq of this.openRfqs) {
      for (let service of indexrfq.servicesRequired) {
        this.check = false;
        if (indexrfq.servicesRequired.length == 1) {
          for (let name of this.sortbyHeadings) {
            if (service == name.rfqHeadName) {
              this.check = true;
            }
          }
          if (this.check == false) {

            this.singleSortHeadings.rfqHeadName = service;
            this.singleSortHeadings.rfq = [];
            this.singleSortHeadings.id = this.headingId;
            this.sortbyHeadings.push(this.singleSortHeadings);
            this.headingId++;
            this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
          }
        }
      }
      if (indexrfq.servicesRequired.length > 1) {
        this.sortbyHeadings[0].rfq.push(indexrfq);
      }
    }

    this.populateRFQHeadArray();
    this.getClosingTime(Date);

  }


  populateRFQHeadArray(): void {
    for (let indexrfq of this.openRfqs) {
      for (let service of indexrfq.servicesRequired) {
        if (indexrfq.servicesRequired.length == 1) {
          for (let val of this.sortbyHeadings) {
            if (val.rfqHeadName == service) {
              val.rfq.push(indexrfq)

            }
          }
        }

      }
    }
    if (this.sortbyHeadings[0].rfq.length == 0) {
      this.sortbyHeadings.splice(0, 1);
    }

    // LOGIC FOR OPEN QUOTES COUNTER ON NAV
    for (let heading of this.sortbyHeadings) {
      heading.openQuotesCounter = 0;
      for (let rfq of heading.rfq) {
        heading.openQuotesCounter = heading.openQuotesCounter + rfq.openQuotes.length;
      }

    }

    // sort Rfq Heading in Asc order using String.prototype.localCompare()
    this.sortbyHeadings.sort((a, b) =>
      a.rfqHeadName.localeCompare(b.rfqHeadName)
    );

   // Re-inserts Multiple Services heading to first position in array
    var index = this.sortbyHeadings.findIndex(x => x.rfqHeadName == "Multiple Services");
    if(index != -1){
      var head = this.sortbyHeadings.filter(x => x.rfqHeadName == "Multiple Services")[0];
      this.sortbyHeadings.splice(index, 1);
      this.sortbyHeadings.splice(0, 0, head);
    }
  

  }

  //Used for periodic updating of RFQS
  sortRFQHeadArrayUpdate() {

    for (let indexrfq of this.openRfqsUpdate) {
      for (let service of indexrfq.servicesRequired) {
        this.check = false;
        if (indexrfq.servicesRequired.length == 1) {
          for (let name of this.sortbyHeadingsUpdate) {
            if (service == name.rfqHeadName) {
              this.check = true;
            }
          }
          if (this.check == false) {

            this.singleSortHeadings.rfqHeadName = service;
            this.singleSortHeadings.rfq = [];
            this.singleSortHeadings.id = this.headingId;
            this.sortbyHeadingsUpdate.push(this.singleSortHeadings);
            this.headingId++;
            this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
          }
        }
      }
      if (indexrfq.servicesRequired.length > 1) {
        this.sortbyHeadingsUpdate[0].rfq.push(indexrfq);

      }

      // LOGIC FOR OPEN QUOTES COUNTER ON NAV
      for (let heading of this.sortbyHeadingsUpdate) {
        heading.openQuotesCounter = 0;
        for (let rfq of heading.rfq) {
          heading.openQuotesCounter = heading.openQuotesCounter + rfq.openQuotes.length;
        }

      }

    }

    this.populateRFQHeadArrayUpdate();
    this.getClosingTime(Date);




  }

  populateRFQHeadArrayUpdate(): void {
    for (let indexrfq of this.openRfqsUpdate) {
      for (let service of indexrfq.servicesRequired) {
        if (indexrfq.servicesRequired.length == 1) {
          for (let val of this.sortbyHeadingsUpdate) {
            if (val.rfqHeadName == service) {
              val.rfq.push(indexrfq)

            }
          }
        }

      }
    }
    if (this.sortbyHeadingsUpdate[0].rfq.length == 0) {
      this.sortbyHeadingsUpdate.splice(0, 1);
    }

    // LOGIC FOR OPEN QUOTES COUNTER ON NAV
    for (let heading of this.sortbyHeadingsUpdate) {
      heading.openQuotesCounter = 0;
      for (let rfq of heading.rfq) {
        heading.openQuotesCounter = heading.openQuotesCounter + rfq.openQuotes.length;
      }

    }

  }

  selectedHeadingRfq: RfqHeadings;

  loadingtest: boolean = true;

  triggerSelectRfqLoading() {
    this.reloadingSelectedRfq = false;
  }

  loadingRfqSpinnerId: number;
  newQuotes: boolean = true;

  onSelectRFQNav(rfq: RfqHeadings): void {
    this.loadingRfqSpinnerId = rfq.rfqId;
    this.newQuotes = true;
    this.router.navigate(['buyer-home/view-rfq', 'Open', rfq.rfqId, 'false']);
  }

  loadingSelectedRfq: boolean

  onSelectRFQ(rfqId: number): void {

    this.selectedHeadingRfq = this.openRfqs.filter(x => x.rfqId == rfqId)[0];
    this.selectedRfqId = rfqId;

    if (!this.reloadingSelectedRfq) {
      this.loadingRfq = true;
    }

    this.rfqService.getRFQById(rfqId).pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.state == "Open") {

            if (this.newQuotes == true) {
              this.openQuotes = response.openQuotes;
              this.newQuotes = false;
            } else {
              if (response.openQuotes.length > 0) {
                this.reloadOpenQuotes(response.openQuotes)
              }
            }

            this.selectedRFQ = response;

            this.loadingRfq = false

            //INDICATES WHICH THING RFQ BUTTON IS SELECTED FOR THE OPEN RFQs OBJECT
            for (let siderfq of this.openRfqs) {
              if (this.selectedRfqId == siderfq.rfqId) {
                siderfq.SideSelectedRFQ = true;
              }
              else {
                siderfq.SideSelectedRFQ = false;
              }

            }

            //INDICATES WHICH THING RFQ BUTTON IS SELECTED FOR THE OPEN RFQs AFTER RELOAD OBJECT
            if (this.openRfqsUpdate.length > 0) {
              for (let siderfq of this.openRfqsUpdate) {
                if (this.selectedRfqId == siderfq.rfqId) {
                  siderfq.SideSelectedRFQ = true;
                }
                else {
                  siderfq.SideSelectedRFQ = false;
                }

              }
            }

            //NEEDED FOR QUOTES EDITE SEEN CHECK
            this.quoteIdList = [];
            if (this.selectedRFQ.openQuotes.length > 0) {
              for (let quote of this.selectedRFQ.openQuotes) {
                this.quoteIdList.push(quote.quoteId);

              }
              this.getEditedQuoteCheck(this.quoteIdList);
            }

            if (this.openRfqNav == 'true') {
              this.router.navigate(['buyer-home/view-rfq', 'Open', response.rfqId, 'false']);
            }

          } else {

            this.router.navigate(['buyer-home/view-rfq', 'Open', 0, 'false'], {
              queryParams: { nav: 'false' }
            });
            this.toastrNotification.error("Invalid Selected Rfq")
          }

        },
        error: error => {

          this.router.navigate(['buyer-home/view-rfq', 'Open', this.sortbyHeadings[0].rfq[0].rfqId, 'false']);
          this.toastrNotification.error("Invalid Selected Rfq")
          this.loadingRfq = false
        }

      });


    //var date =  new Date(this.selectedRFQ.rfqClosingDate).getTime();

    //this.getClosingTime(new Date (this.selectedRFQ.rfqClosingDate));

    //CHECKS FOR UNREAD MESSAGES NEEDED FOR RED BLIP

  }

  togglePublicChatMessages() {
    this.selectedHeadingRfq.unreadPublicMessages = false;
  }

  reloadOpenQuotes(newQuotes: ViewRFQs_Quotes[]) {

    if (this.openQuotes.length == 0 && newQuotes.length > 0) {
      this.openQuotes = newQuotes;
    } else if (newQuotes.length == 0) {
      this.openQuotes = [];
    } else if (this.openQuotes.length > 0 && newQuotes.length > 0) {

      var oldQuotesIds: number[] = [];
      var newQuotesIds: number[] = [];

      for (let currentQuote of this.openQuotes) {
        for (let replaceQuote of newQuotes) {
          if (replaceQuote.quoteId == currentQuote.quoteId) {
            currentQuote.state = replaceQuote.state;
            currentQuote.servicesQuoted = replaceQuote.servicesQuoted;
            currentQuote.accepted = replaceQuote.accepted;
            currentQuote.amount = replaceQuote.amount;
            currentQuote.leadTime = replaceQuote.leadTime;
            currentQuote.paymentReference = replaceQuote.paymentReference;
            currentQuote.paymentReceived = replaceQuote.paymentReceived;
            currentQuote.quoteFileName = replaceQuote.quoteFileName;
            currentQuote.quoteFileId = replaceQuote.quoteFileId;
            currentQuote.salesOrderFileName = replaceQuote.salesOrderFileName;
            currentQuote.salesQuoteFileId = replaceQuote.salesQuoteFileId;
            currentQuote.productCertificationFileName = replaceQuote.productCertificationFileName;
            currentQuote.productCertificationQuoteFileId = replaceQuote.productCertificationQuoteFileId;
            currentQuote.invoiceOrderFileName = replaceQuote.invoiceOrderFileName;
            currentQuote.invoiceQuoteFileId = replaceQuote.invoiceQuoteFileId;
            currentQuote.quoteCreatedAt = replaceQuote.quoteCreatedAt;
            currentQuote.salesOrderCreatedAt = replaceQuote.salesOrderCreatedAt;
            currentQuote.invoiceCreatedAt = replaceQuote.invoiceCreatedAt;
            currentQuote.productCertCreatedAt = replaceQuote.productCertCreatedAt;
            currentQuote.supplierDeliveryMade = replaceQuote.supplierDeliveryMade;
            currentQuote.buyerCollectionMade = replaceQuote.buyerCollectionMade;
            currentQuote.buyerDeliveryMade = replaceQuote.buyerDeliveryMade;
            currentQuote.supplierCollectionMade = replaceQuote.supplierCollectionMade;
            currentQuote.supplierDetails = replaceQuote.supplierDetails;
            currentQuote.collectionStreetAddress = replaceQuote.collectionStreetAddress;
            currentQuote.collectionCity = replaceQuote.collectionCity;
            currentQuote.collectionProvince = replaceQuote.collectionProvince;
            currentQuote.collectionPostalCode = replaceQuote.collectionPostalCode;

          }
        }
      }

      for (let oldquote of this.openQuotes) {
        oldQuotesIds.push(oldquote.quoteId);
      }

      for (let newquote of newQuotes) {
        newQuotesIds.push(newquote.quoteId);
      }

      for (let quote of newQuotes) {
        if (oldQuotesIds.indexOf(quote.quoteId) == -1) {
          this.openQuotes.push(quote);
        }
      }

      for (const { index, value } of this.openQuotes.map((value, index) => ({ index, value }))) {
        if (newQuotesIds.indexOf(value.quoteId) == -1) {
          this.openQuotes.splice(index, 1);
        }
      };

    }

  }

  // CHECKS IF EDITED QUOTE HAS BEEN SEEN
  getEditedQuoteCheck(quoteIds: number[]) {
    this.rfqService.getQuoteseenCheck(quoteIds, this.account.account.userId).pipe(first())
      .subscribe({
        next: (response: any) => {

          if (response.length > 0) {
            for (let editedSeen of response) {

              if (this.selectedRFQ.openQuotes.length > 0) {
                for (let quote of this.selectedRFQ.openQuotes) {

                  if (editedSeen.quoteId == quote.quoteId) {
                    quote.editedSeen = false;
                  }
                }
              }

              if (this.openQuotes.length > 0) {
                for (let quote2 of this.openQuotes) {

                  if (editedSeen.quoteId == quote2.quoteId) {
                    quote2.editedSeen = false;
                  }
                }
              }

            }
          }
        },
        error: error => {
          this.toastrNotification.error(" Edit Quote Check Failed");

        }
      });

  }

  // SETS EDITED QUOTE TO SEEN
  EditedQuoteSetSeen(quoteId) {

    this.rfqService.getQuotesetSeen(quoteId, this.account.account.userId).pipe(
      finalize(() => this.dataLoading = false)
    ).subscribe(response => {
      for (let quote of this.selectedRFQ.openQuotes) {
        if (quoteId == quote.quoteId) {
          quote.editedSeen = true;
        }

      }
      for (let quote of this.openQuotes) {
        if (quoteId == quote.quoteId) {
          quote.editedSeen = true;
        }

      }

    })
  }

  newCreditApplication: CreditApplicationCreate = { BuyerId: null, SupplierId: null, Period: null, CreatedBy: null };
  creatingCreditApplication: boolean = false;
  @ViewChild('CreditAppSuccessMdl') CreditAppSuccessMdl: ElementRef;
  creditState: string = "";


  createCreditApplication(state: string) {

    this.newCreditApplication = { BuyerId: null, SupplierId: null, Period: null, CreatedBy: null };

    this.newCreditApplication.BuyerId = this.account.account.buyerId;
    this.newCreditApplication.SupplierId = this.selectedQuote.supplierDetails.supplierId;
    this.newCreditApplication.Period = this.ca.dayRange.value;
    this.newCreditApplication.CreatedBy = this.account.account.userId;

    this.creatingCreditApplication = true;

    if (state == "Apply") {
      this.buyerCompanyService.createCreditApplication(this.newCreditApplication).pipe(first())
        .subscribe({
          next: (response: any) => {
            this.buyerCompanyService.uploadCreditApplicationFile(this.creditApplicationUpload).pipe(first())
              .subscribe({
                next: (response: any) => {
                  this.creatingCreditApplication = false;
                  this.clearCreditApplicationForm();
                  this.getNewCreditApplication(this.selectedRFQ.rfqId, this.selectedQuote.quoteId);
                  this.CreditAppSuccessMdl.nativeElement.click();
                },
                error: error => {
                  this.toastrNotification.error("Upload Credit Application File Failed");
                  this.creatingCreditApplication = false;
                  this.buyerCompanyService.deleteCreditApplication(this.newCreditApplication.BuyerId, this.newCreditApplication.SupplierId).pipe(first())
                    .subscribe({
                      next: (response: any) => {

                      },
                      error: error => {
                        this.toastrNotification.error("Removed Credit Application Failed");
                      }
                    });
                }
              });
          },
          error: error => {
            this.toastrNotification.error("Create Credit Application Failed");
            this.creatingCreditApplication = false;
          }
        });
    } else if (state == "Reapply") {
      this.buyerCompanyService.editCreditApplication(this.newCreditApplication).pipe(first())
        .subscribe({
          next: (response: any) => {
            this.buyerCompanyService.uploadCreditApplicationFile(this.creditApplicationUpload).pipe(first())
              .subscribe({
                next: (response: any) => {
                  this.creatingCreditApplication = false;
                  this.clearCreditApplicationForm();
                  this.getNewCreditApplication(this.selectedRFQ.rfqId, this.selectedQuote.quoteId);
                  this.CreditAppSuccessMdl.nativeElement.click();
                },
                error: error => {
                  this.toastrNotification.error("Upload Credit Application File Failed");
                  this.creatingCreditApplication = false;
                }
              });
          },
          error: error => {
            this.toastrNotification.error("Edit Credit Application Failed");
            this.creatingCreditApplication = false;
          }
        });
    }

  }

  getNewCreditApplication(rfqId: number, quoteId: number) {
    this.buyerCompanyService.getCreditApplication(this.account.account.buyerId, this.selectedQuote.supplierDetails.supplierId).pipe(first())
      .subscribe({
        next: (response: any) => {
          this.selectedRFQ.openQuotes.filter(x => x.quoteId == quoteId)[0].supplierDetails.creditApplication = response;
        },
        error: error => {
          this.toastrNotification.error("Get Credit Application Failed");
        }
      });
  }

  get f() { return this.form.controls; }

  downloadingQuoteFile: boolean = false;

  downloadQuoteFiles(quoteFileId, quoteId) {

    this.downloadingQuoteFile = true;

    this.quoteService.getQuoteFile(quoteFileId, this.account.account.userId, "Buyer").subscribe({
      next: (response: any) => {

        var FileContent = response.fileContent;
        var FileName = response.fileName;
        this.downloadFile(FileContent, FileName);
        this.downloadingQuoteFile = false;

      },
      error: error => {
        this.toastrNotification.info("Quote File out of date, new Quote File loaded. Please retry download")
        this.quoteService.getQuoteFileDetails(quoteId, "Quotes").subscribe(y => {
          this.selectedRFQ.openQuotes.filter(x => x.quoteId == quoteId)[0].quoteFileId = y.quoteFileId;
          this.selectedRFQ.openQuotes.filter(x => x.quoteId == quoteId)[0].quoteFileName = y.quoteFileName;
          this.downloadingQuoteFile = false;
        });
      }
    });

  }

  downloadingTemplate: boolean = false;

  downloadCreditApplicationTemplate() {

    this.downloadingTemplate = true;

    this.supplierCompanyService.getCreditApplicationTemplateFile(this.selectedQuote.supplierDetails.supplierId).pipe(first())
      .subscribe({
        next: (response: any) => {
          this.downloadingTemplate = false;

          this.downloadFile(response.fileContent, this.selectedQuote.supplierDetails.creditAppTemplateFileName);

        },
        error: error => {
          this.toastrNotification.error("Download Credit Application Template failed");
          this.downloadingTemplate = false;
        }
      });
  }

  clearCreditApplicationForm() {
    this.ca.dayRange.setValue("");
    this.creditApplicationUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
    this.CreditApplicationFileNamePreview = "";
  }

  downloadDrawingFile(rfqFileId) {
    this.rfqService.getRfqDrawingFile(rfqFileId).subscribe({

      next: (response: any) => {
        this.downloadFile(response.fileContent, response.fileName)
      },
      error: error => {
        this.onSelectRFQ(this.selectedRFQ.rfqId);
        this.toastrNotification.info("Rfq File is out dated, updated Rfq is being loaded")
      }
    })
  }

  creditApplicationUpload: UploadRfqFile = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
  CreditApplicationUploading: boolean = false;
  CreditApplicationFileNamePreview: string = "";
  creditApplicationFile: File;
  requiredCRFileType: string = ".pdf";

  @ViewChild('clearCreditAppInput', { static: false }) clearCreditAppInput: ElementRef;

  uploadCreditApplicationFile(event) {

    this.creditApplicationUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };

    this.creditApplicationFile = event.target.files[0];
    const reader = new FileReader();

    if (this.creditApplicationFile) {

      reader.readAsDataURL(this.creditApplicationFile);
      reader.onload = (e: any) => {

        this.CreditApplicationFileNamePreview = this.creditApplicationFile.name;
        this.creditApplicationUpload.file = (reader.result + "").substring((reader.result + "").indexOf(",") + 1, (reader.result + "").length);
        this.creditApplicationUpload.buyerId = this.account.account.buyerId;
        this.creditApplicationUpload.userId = this.account.account.userId;;
        this.creditApplicationUpload.supplierId = this.selectedQuote.supplierDetails.supplierId;
        this.creditApplicationUpload.fileName = this.creditApplicationFile.name;

        this.clearCreditAppInput.nativeElement.value = "";

      };
    }

  }

  downloadFile(base64String, fileName) {

    // if(window.navigator && window.navigator.msSaveOrOpenBlob){ 
    //   // download PDF in IE
    //   let byteChar = atob(base64String);
    //   let byteArray = new Array(byteChar.length);
    //   for(let i = 0; i < byteChar.length; i++){
    //     byteArray[i] = byteChar.charCodeAt(i);
    //   }
    //   let uIntArray = new Uint8Array(byteArray);
    //   let blob = new Blob([uIntArray], {type : 'application/pdf'});
    //   window.navigator.msSaveOrOpenBlob(blob, `${fileName}.pdf`);
    // } 

    // Download PDF in Chrome etc.
    const source = `data:application/;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}`
    link.click();
  }


  // add(name: string): void {
  //   name = name.trim();
  //   if (!name) { return; }
  //   this.rfqService.addRFQ({ rfq } as RFQ)
  //     .subscribe(rfq => {
  //       this.rfqs.push(rfq);
  //     });
  // }

  // delete(rfq: RFQ): void {
  //   this.rfqs = this.rfqs.filter(h => h !== rfq);
  //   this.rfqService.deleteHero(rfq.rfqId).subscribe();
  // }

  cancel() {
    this.gotoRFQS();
  }

  save() {
    this.rfq.rfqName = this.editName;
    this.gotoRFQS();
  }

  canDeactivate(): Observable<boolean> | boolean {
    // Allow synchronous navigation (`true`) if no crisis or the crisis is unchanged
    if (!this.rfq || this.rfq.rfqName === this.editName) {
      return true;
    }
    // Otherwise ask the user with the dialog service and return its
    // observable which resolves to true or false when the user decides
    return this.dialogService.confirm('Discard changes?');
  }

  //EXAMPLE
  gotoRFQS() {
    //const crisisId = this.crisis ? this.crisis.id : null;
    // Pass along the crisis id if available
    // so that the CrisisListComponent can select that crisis.
    // Add a totally useless `foo` parameter for kicks.
    // Relative navigation back to the crises
    //this.router.navigate(['../', { id: crisisId, foo: 'foo' }], { relativeTo: this.route });
  }




  // RFQ SEARCH FORM
  get rs() { return this.RFQsearch.controls; }

  //  CLEAR RFQ SEARCH FILTER VALUES
  filtersUsed: boolean = false;
  filterOpenRfqs: RfqHeadings[] = [];
  filterOpenRfqsUpdate: RfqHeadings[] = [];
  filterSortbyHeadings: RFQHeaddisplay[] = [];
  filterSortbyHeadingsUpdate: RFQHeaddisplay[] = [];


  filterRfqType: string[] = [];
  filterPaymentOptions: string[] = [];
  filterPackagingOptions: string[] = [];
  filterCollectorDelivery: string[] = [];
  filtertestCertification: boolean[] = [];



  //  FILTER RFQ NAV
  RFQsearchFilter() {

    this.filterRfqType = [];
    this.filterPaymentOptions = [];
    this.filterCollectorDelivery = [];
    this.filterPackagingOptions = [];
    this.filtertestCertification = [];


    this.filterOpenRfqs = [];

    // POPULATES THE FILTER RFQ ARRAY EVERY VALUE IN THE OPEN RFQS ARRAY
    for (let value of this.openRfqs) {
      this.filterOpenRfqs.push(value);
    }

    // IF ANY OF THE FILTER VALUES ARE SET TO TRUE THEN THE FILTER IS USED AND THE HTML DISPLAYS THE filterOpenRfqs ARRAY
    if (this.rs.complete.value || this.rs.rawmat.value || this.rs.cod.value || this.rs.cbd.value || this.rs.thirtyDays.value || this.rs.sixtyDays.value ||
      this.rs.paymentOther.value || this.rs.delivery.value || this.rs.collection.value || this.rs.boxed.value || this.rs.pallets.value ||
      this.rs.strapped.value || this.rs.loose.value || this.rs.packagingOther.value || this.rs.testCertificationYes.value || this.rs.testCertificationNo.value) {

      this.filtersUsed = true;
    }
    else {
      this.filtersUsed = false;

    }

    // BUILD UP TYPE OF SUPPLY ARRAY
    if (this.rs.complete.value == true) {
      this.filterRfqType.push("Complete");
    }

    if (this.rs.rawmat.value == true) {
      this.filterRfqType.push("Raw Material");
    }


    // BUILD UP COLLECT OR DELIVERY ARRAY
    if (this.rs.delivery.value == true) {
      this.filterCollectorDelivery.push("Delivery");

    }

    if (this.rs.collection.value == true) {
      this.filterCollectorDelivery.push("Collection");

    }
    // BUILD UP PAYMENT ARRAY
    if (this.rs.cod.value == true) {
      this.filterPaymentOptions.push("COD");

    }

    if (this.rs.cbd.value == true) {
      this.filterPaymentOptions.push("CBD");

    }

    if (this.rs.thirtyDays.value == true) {
      this.filterPaymentOptions.push("30 Days");

    }


    if (this.rs.sixtyDays.value == true) {
      this.filterPaymentOptions.push("60 Days");

    }


    if (this.rs.paymentOther.value == true) {
      this.filterPaymentOptions.push("Other");

    }

    // BUILD UP PACKAGING OPTION ARRAY
    if (this.rs.boxed.value == true) {
      this.filterPackagingOptions.push("Boxed");

    }

    if (this.rs.pallets.value == true) {
      this.filterPackagingOptions.push("Pallet");

    }

    if (this.rs.strapped.value == true) {
      this.filterPackagingOptions.push("Strapped");

    }

    if (this.rs.loose.value == true) {
      this.filterPackagingOptions.push("Loose");

    }

    if (this.rs.packagingOther.value == true) {
      this.filterPackagingOptions.push("Other");

    }

    // TEST CERTIFICATION ARRAY POPULATE
    if (this.rs.testCertificationYes.value == true) {

      this.filtertestCertification.push(true);
    }

    // TEST CERTIFICATION ARRAY POPULATE
    if (this.rs.testCertificationNo.value == true) {

      this.filtertestCertification.push(true);
    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE TYPE OP SUPPLY OPTIONS
    if (this.filterRfqType.length > 0) {
      this.filterOpenRfqs = this.filterOpenRfqs.filter(rfq => this.filterRfqType.some(r => rfq.typeOfSupply.includes(r)));

    }
    // FILTERS filterOpenRfq ARRAY FOR ALL THE PAYMENT REQUIREMENT OPTIONS
    if (this.filterPaymentOptions.length > 0) {
      this.filterOpenRfqs = this.filterOpenRfqs.filter(rfq => this.filterPaymentOptions.some(r => rfq.paymentRequirement.includes(r)));
    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE DELIVERY REQUIREMENT OPTIONS
    if (this.filterCollectorDelivery.length > 0) {
      this.filterOpenRfqs = this.filterOpenRfqs.filter(rfq => this.filterCollectorDelivery.some(r => rfq.deliveryRequirements.includes(r)));
    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE PACKAGING REQUIREMENT OPTIONS FIRST CHECKS IF THE PACKAGING REQUIREMENT OPTIONS IS NOT NULL
    if (this.filterPackagingOptions.length > 0) {

      this.filterOpenRfqs = this.filterOpenRfqs.filter(rfq => rfq.packagingRequirements != null).filter(rfq => this.filterPackagingOptions.some(r => rfq.packagingRequirements.includes(r)));
    }


    // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE OR FALSE
    if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == true) {
      this.filterOpenRfqs = this.filterOpenRfqs.filter(rfq => rfq.productCertificationRequirements == true || rfq.productCertificationRequirements == false);

    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE
    if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == false) {
      this.filterOpenRfqs = this.filterOpenRfqs.filter(rfq => rfq.productCertificationRequirements == true);

    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS FALSE
    if (this.rs.testCertificationNo.value == true && this.rs.testCertificationYes.value == false) {
      this.filterOpenRfqs = this.filterOpenRfqs.filter(rfq => rfq.productCertificationRequirements == false);

    }

    // FILTERS filterOpenRfq ARRAY IF NOT NULL
    if (this.filterOpenRfqs.length > 0) {

      this.filterSortbyHeadings = [];
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

      this.headingId = 1;
      this.singleSortHeadings.rfqHeadName = "Multiple Services";
      this.singleSortHeadings.rfq = [];
      this.filterSortbyHeadings.push(this.singleSortHeadings);
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
      this.headingId++;

      this.sortFilterRFQHeadArray();
    }
    else {
      // MAKE MAKE THE ARRAY THAT BUILDS UP THE SIDE NAV NULL
      this.filterSortbyHeadings = [];
    }

  }

  // FILTER NAV ON PERIODIC RELOAD
  filterUpdateRfqs(array: RfqHeadings[]) {

    this.filterOpenRfqsUpdate = [];

    if (array.length > 0) {

      for (let rfq of array) {
        this.filterOpenRfqsUpdate.push(rfq);
      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE TYPE OP SUPPLY OPTIONS
      if (this.filterRfqType.length > 0) {
        this.filterOpenRfqsUpdate = this.filterOpenRfqsUpdate.filter(rfq => this.filterRfqType.some(r => rfq.typeOfSupply.includes(r)));

      }
      // FILTERS filterOpenRfq ARRAY FOR ALL THE PAYMENT REQUIREMENT OPTIONS
      if (this.filterPaymentOptions.length > 0) {
        this.filterOpenRfqsUpdate = this.filterOpenRfqsUpdate.filter(rfq => this.filterPaymentOptions.some(r => rfq.paymentRequirement.includes(r)));
      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE DELIVERY REQUIREMENT OPTIONS
      if (this.filterCollectorDelivery.length > 0) {
        this.filterOpenRfqsUpdate = this.filterOpenRfqsUpdate.filter(rfq => this.filterCollectorDelivery.some(r => rfq.deliveryRequirements.includes(r)));
      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE PACKAGING REQUIREMENT OPTIONS FIRST CHECKS IF THE PACKAGING REQUIREMENT OPTIONS IS NOT NULL
      if (this.filterPackagingOptions.length > 0) {

        this.filterOpenRfqsUpdate = this.filterOpenRfqsUpdate.filter(rfq => rfq.packagingRequirements != null).filter(rfq => this.filterPackagingOptions.some(r => rfq.packagingRequirements.includes(r)));
      }


      // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE OR FALSE
      if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == true) {
        this.filterOpenRfqsUpdate = this.filterOpenRfqsUpdate.filter(rfq => rfq.productCertificationRequirements == true || rfq.productCertificationRequirements == false);

      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE
      if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == false) {
        this.filterOpenRfqsUpdate = this.filterOpenRfqsUpdate.filter(rfq => rfq.productCertificationRequirements == true);

      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS FALSE
      if (this.rs.testCertificationNo.value == true && this.rs.testCertificationYes.value == false) {
        this.filterOpenRfqsUpdate = this.filterOpenRfqsUpdate.filter(rfq => rfq.productCertificationRequirements == false);

      }


      // FILTERS filterOpenRfq ARRAY IF NOT NULL
      if (this.filterOpenRfqsUpdate.length > 0) {

        this.filterSortbyHeadingsUpdate = [];
        this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

        this.headingId = 1;
        this.singleSortHeadings.rfqHeadName = "Multiple Services";
        this.singleSortHeadings.rfq = [];
        this.filterSortbyHeadingsUpdate.push(this.singleSortHeadings);
        this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
        this.headingId++;

        for (let indexrfq of this.filterOpenRfqsUpdate) {
          for (let service of indexrfq.servicesRequired) {
            this.check = false;
            if (indexrfq.servicesRequired.length == 1) {
              for (let name of this.filterSortbyHeadingsUpdate) {
                if (service == name.rfqHeadName) {
                  this.check = true;
                }
              }
              if (this.check == false) {

                this.singleSortHeadings.rfqHeadName = service;
                this.singleSortHeadings.rfq = [];
                this.singleSortHeadings.id = this.headingId;
                this.filterSortbyHeadingsUpdate.push(this.singleSortHeadings);
                this.headingId++;
                this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
              }
            }
          }

          if (indexrfq.servicesRequired.length > 1) {
            this.filterSortbyHeadingsUpdate[0].rfq.push(indexrfq);
          }

        }

        this.getClosingTime(Date);



        for (let rfq of this.filterOpenRfqsUpdate) {
          for (let service of rfq.servicesRequired) {
            if (rfq.servicesRequired.length == 1) {
              for (let val of this.filterSortbyHeadingsUpdate) {
                if (val.rfqHeadName == service) {
                  val.rfq.push(rfq)

                }
              }
            }

          }
        }
        if (this.filterSortbyHeadingsUpdate[0].rfq.length == 0) {
          this.filterSortbyHeadingsUpdate.splice(0, 1);
        }

        // LOGIC FOR OPEN QUOTES COUNTER ON NAV
        for (let heading of this.filterSortbyHeadingsUpdate) {
          heading.openQuotesCounter = 0;
          for (let rfq of heading.rfq) {
            heading.openQuotesCounter = heading.openQuotesCounter + rfq.openQuotes.length;
          }

        }

      }
      else {
        // MAKE MAKE THE ARRAY THAT BUILDS UP THE SIDE NAV NULL
        this.filterSortbyHeadingsUpdate = [];
      }

      this.replaceFilteredRfqsOnUpdate();

    }

  }

  replaceFilteredRfqsOnUpdate() {

    var newHeadings: string[] = [];
    var oldHeadings: string[] = [];

    for (let heading of this.filterSortbyHeadings) {
      oldHeadings.push(heading.rfqHeadName)
      for (let newHead of this.filterSortbyHeadingsUpdate) {
        if (heading.rfqHeadName == newHead.rfqHeadName) {
          heading.rfq = newHead.rfq;
          heading.openQuotesCounter = newHead.openQuotesCounter;;
        }
      }
    }

    for (let newHeading of this.filterSortbyHeadingsUpdate) {
      newHeadings.push(newHeading.rfqHeadName);
    }

    // if (this.sortbyHeadings.length != this.sortbyHeadingsUpdate.length) {
    //  if (this.sortbyHeadings.length < this.sortbyHeadingsUpdate.length) {

    for (let newHead of this.filterSortbyHeadingsUpdate) {
      if (oldHeadings.indexOf(newHead.rfqHeadName) == -1) {
        this.filterSortbyHeadings.push(newHead);
      }
    }

    // } else {
    for (const { index, value } of this.filterSortbyHeadings.map((value, index) => ({ index, value }))) {
      if (newHeadings.indexOf(value.rfqHeadName) == -1) {
        this.filterSortbyHeadings.splice(index, 1);
      }
    }

    //  }

    // }

  }


  // BUILDS RFQ SIDENAV AFTER A FILTER IS SELECTED
  sortFilterRFQHeadArray() {

    // PUSHES ALL VALUES FROM OPEN RFQS ARRAY INTO THE FILTER RFQS ARRAY

    for (let indexrfq of this.filterOpenRfqs) {
      for (let service of indexrfq.servicesRequired) {
        this.check = false;
        if (indexrfq.servicesRequired.length == 1) {
          for (let name of this.filterSortbyHeadings) {
            if (service == name.rfqHeadName) {
              this.check = true;
            }
          }
          if (this.check == false) {

            this.singleSortHeadings.rfqHeadName = service;
            this.singleSortHeadings.rfq = [];
            this.singleSortHeadings.id = this.headingId;
            this.filterSortbyHeadings.push(this.singleSortHeadings);
            this.headingId++;
            this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
          }
        }
      }

      if (indexrfq.servicesRequired.length > 1) {
        this.filterSortbyHeadings[0].rfq.push(indexrfq);
      }

    }
    this.populateSearchRFQHeadArray();
    this.getClosingTime(Date);



  }


  populateSearchRFQHeadArray(): void {
    for (let rfq of this.filterOpenRfqs) {
      for (let service of rfq.servicesRequired) {
        if (rfq.servicesRequired.length == 1) {
          for (let val of this.filterSortbyHeadings) {
            if (val.rfqHeadName == service) {
              val.rfq.push(rfq)

            }
          }
        }

      }
    }
    if (this.filterSortbyHeadings[0].rfq.length == 0) {
      this.filterSortbyHeadings.splice(0, 1);
    }

    // LOGIC FOR OPEN QUOTES COUNTER ON NAV
    for (let heading of this.filterSortbyHeadings) {
      heading.openQuotesCounter = 0;
      for (let rfq of heading.rfq) {
        heading.openQuotesCounter = heading.openQuotesCounter + rfq.openQuotes.length;
      }

    }
  }

  clearRFQFilter() {

    this.filtersUsed = false;

    this.rs.complete.setValue(false);
    this.rs.rawmat.setValue(false);
    this.rs.cod.setValue(false);
    this.rs.cbd.setValue(false);
    this.rs.thirtyDays.setValue(false);
    this.rs.sixtyDays.setValue(false);
    this.rs.paymentOther.setValue(false);
    this.rs.delivery.setValue(false);
    this.rs.collection.setValue(false);
    this.rs.boxed.setValue(false);
    this.rs.pallets.setValue(false);
    this.rs.strapped.setValue(false);
    this.rs.loose.setValue(false);
    this.rs.packagingOther.setValue(false);
    this.rs.testCertificationYes.setValue(false);
    this.rs.testCertificationNo.setValue(false);
  }


  filterOpen: boolean = false;
  filterIconToggle() {

    this.filterOpen = !this.filterOpen;

  }

  @ViewChild('cancelRfqBtn') cancelRfqBtn: ElementRef;

  @ViewChild('cancelRfqDismissBtn') cancelRfqDismissBtn: ElementRef;

  cancellingRfq: boolean = false;

  //@ViewChild('cancelRfqSuccessBtn') cancelRfqSuccessBtn: ElementRef;

  cancelRfq(rfqId: number) {

    this.rfqService.cancelRfq(rfqId).subscribe({
      next: (response: any) => {
        this.cancelRfqBtn.nativeElement.click();
        this.cancellingRfq = false;
      },
      error: error => {
        this.toastrNotification.error("Rfq Cancel Failed");
        this.cancelRfqDismissBtn.nativeElement.click();
        this.cancellingRfq = false;
      }
    });
  }

  cancelRfqCheck(rfqId) {

    this.cancellingRfq = true;

    this.rfqService.getRfqState(rfqId).subscribe({
      next: (response: any) => {

        if (response == "Open") {
          this.cancelRfq(rfqId)
        } else {
          this.toastrNotification.info("Rfq has already been cancelled")
          this.cancelRfqDismissBtn.nativeElement.click();
          this.cancellingRfq = false;
        }

      },
      error: error => {
        this.toastrNotification.error("Rfq Check Failed")
        this.cancelRfqDismissBtn.nativeElement.click();
        this.cancellingRfq = false;
      }
    });
  }

  get ca() { return this.creditApplication.controls; }


}
