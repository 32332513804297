import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { NotificationsService } from '../services/notifications.service';
import { interval, Observable, of, Subscription } from 'rxjs';
import { Notification } from '../models/notification';
import *  as moment from 'moment';
import { ReloadViewRfqs } from '../models/reloadViewRfqs';
import { ViewRfqServiceService } from '../services/view-rfq-service.service';
import { Router } from '@angular/router';
import { RfqService } from '../services/rfq.service';
import { ToastrNotificationService } from '../services/toastr-notification.service';
import { ViewQuoteServiceService } from '../services/view-quote-service.service';
import { QuoteService } from '../services/quote.service';
import { AuthService } from '../auth/auth.service';
import { Account } from '../models/account';
import { first } from 'rxjs/operators';
import { QuestionAndAnswersService } from '../services/question-and-answers.service';



@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent implements OnInit {

  // @Input() notifications: Notification[];
  //@Input() notifCount: number;

  private subscription: Subscription;

  notifCount: number = 0;
  notifications: Notification[];
  notifs: Notification[] = [];
  selectedNotif: Notification;
  routerLink: string

  updatedInProgress: ReloadViewRfqs = { reloadCondition: null, reloadRfqId: null };

  updateQuotesTab: ReloadViewRfqs = { reloadCondition: null, reloadRfqId: null };
  updateRfqTab: ReloadViewRfqs = { reloadCondition: null, reloadRfqId: null };

  //currentTime: Date =  new Date('June 20 2021');;

  newRfqEvent: ReloadViewRfqs = { reloadCondition: null, reloadRfqId: null };

  newQuoteEvent: ReloadViewRfqs = { reloadCondition: null, reloadRfqId: null };
  account: Account;
  constructor(
    private notificationsService: NotificationsService,
    public cdr: ChangeDetectorRef,
    private reloader: ViewRfqServiceService,
    private router: Router,
    private rfqService: RfqService,
    private toastrNotification: ToastrNotificationService,
    private quoteReloader: ViewQuoteServiceService,
    private quoteService: QuoteService,
    public authService: AuthService,
    private questionAndAnswersService: QuestionAndAnswersService) {
    //this.currentTime = moment().format();
    this.authService.account.subscribe(x => this.account = x);
  }

  notifBeingRemoved: boolean = false;

  selectedNotifId: number;

  removeNotification(notifId: number) {
    this.selectedNotifId = notifId;
    this.notifBeingRemoved = true;
    this.notificationsService.setNotificationReturn(notifId).pipe(first()).subscribe({
      next: (response: any) => {
        for (const { index, value } of this.notifications.map((value, index) => ({ index, value }))) {
          if (value.notificationId == notifId) {
            this.notifBeingRemoved = false;
            
            this.notifications.splice(index, 1);
          }
        };
      },
      error: error => {
        this.toastrNotification.error("Remove Notification  Failed");
        //this.submittingRfq = false;
      }
    });

  }

  navFromNotification(notif: Notification,) {

    //this.loadRfqsNormal();

   // this.loadQuotesNormal();

    if (notif.seen == false) {
      this.notificationsService.setNotificationAsSeen(notif.notificationId).subscribe(x => { });

      this.notifCount--;

      for (const { index, value } of this.notifications.map((value, index) => ({ index, value }))) {
        if (value.notificationId == notif.notificationId) {
          value.seen = true;
        }
      }
    }



    if (notif.type == "NewQuote") {
      this.rfqService.getRfqState(notif.rfqId).subscribe(x => {
        if (x == "Open") {
          this.router.navigate(['buyer-home/view-rfq', 'Open', notif.rfqId,'true']);
        } else {
          this.toastrNotification.info("Rfq Has already moved out of Open Rfqs");
          this.notificationsService.setNotificationReturn(notif.notificationId).subscribe(x => {
          });
          for (const { index, value } of this.notifications.map((value, index) => ({ index, value }))) {
            if (value.notificationId == notif.notificationId) {
            
              this.notifications.splice(index, 1);
            }
          };
        }
      });

    } else if (notif.type == "QuoteAccepted") {
      this.quoteService.QuoteStateCheck(notif.quoteId).subscribe(x => {
        if (x == "In Progress") {
         
          this.router.navigate(['supplier-home/view-quote', 'InProgress', notif.quoteId,'true']);
        } else {
          this.toastrNotification.info("Quote Has already moved out of In Progress Quotes");
          this.notificationsService.setNotificationReturn(notif.notificationId).subscribe(x => {
          });
          for (const { index, value } of this.notifications.map((value, index) => ({ index, value }))) {
            if (value.notificationId == notif.notificationId) {
            
              this.notifications.splice(index, 1);
            }
          };
        }
      });
    } else if (notif.type == "PaymentMade") {
      this.quoteService.QuoteStateCheck(notif.quoteId).subscribe(x => {
        if (x == "Closed") {
          this.quoteService.QuoteArchivedCheck(notif.quoteId).subscribe(x => {
            this.router.navigate(['supplier-home/view-quote', 'Closed', notif.quoteId,'true'], {
              queryParams: { archived: x + "" }
            });
          });

        } else if (x == "In Progress") {
          this.router.navigate(['supplier-home/view-quote', 'InProgress', notif.quoteId,'true']);
        }
      });

    } else if (notif.type == "PaymentReceived" || notif.type == "PaymentRecieved") {
      this.rfqService.getRfqState(notif.rfqId).subscribe(x => {
        if (x == "Closed") {
          this.rfqService.getRfqArchiveState(notif.rfqId).subscribe(x => {
            this.router.navigate(['buyer-home/view-rfq', 'Closed', notif.rfqId,'true'], {
              queryParams: { archived: x + "" }
            });
          });

        } else if (x == "In Progress") {
          this.router.navigate(['buyer-home/view-rfq', 'InProgress', notif.rfqId,'true']);
        }
      });

    } else if (notif.type == "RfqCompleted") {

      this.quoteService.QuoteStateCheck(notif.quoteId).subscribe(x => {
        if (x == "Closed") {
          this.quoteService.QuoteArchivedCheck(notif.quoteId).subscribe(x => {
            this.router.navigate(['supplier-home/view-quote', 'Closed', notif.quoteId,'true'], {
              queryParams: { archived: x + "" }
            });
          });

        } else if (x == "In Progress") {
          this.router.navigate(['supplier-home/view-quote', 'InProgress', notif.quoteId,'true']);
        }
      });

    } else if (notif.type == "DeliveryComplete") {
      this.rfqService.getRfqState(notif.rfqId).subscribe(x => {
        if (x == "Closed") {
          this.rfqService.getRfqArchiveState(notif.rfqId).subscribe(x => {
            this.router.navigate(['buyer-home/view-rfq', 'Closed', notif.rfqId,'true'], {
              queryParams: { archived: x + "" }
            });
          });

        } else if (x == "In Progress") {
          this.router.navigate(['buyer-home/view-rfq', 'InProgress', notif.rfqId,'true']);
        }
      });
    } else if (notif.type == "SalesOrderUpload") {

      this.rfqService.getRfqState(notif.rfqId).subscribe(x => {
        if (x == "In Progress") {
          this.router.navigate(['buyer-home/view-rfq', 'InProgress', notif.rfqId,'true']);
        } else {
          this.toastrNotification.info("Rfq Has already moved out of In Progress Rfqs");
          this.notificationsService.setNotificationReturn(notif.notificationId).subscribe(x => {
          });
          for (const { index, value } of this.notifications.map((value, index) => ({ index, value }))) {
            if (value.notificationId == notif.notificationId) {
            
              this.notifications.splice(index, 1);
            }
          };
        }
      });
    } else if (notif.type == "PurchaseOrderUpload") {
      this.quoteService.QuoteStateCheck(notif.quoteId).subscribe(x => {
        if (x == "In Progress") {
          this.router.navigate(['supplier-home/view-quote', 'InProgress', notif.quoteId,'true']);
        } else {
          this.toastrNotification.info("Quote Has already moved out of In Progress Quotes");
          this.notificationsService.setNotificationReturn(notif.notificationId).subscribe(x => {
          });
          for (const { index, value } of this.notifications.map((value, index) => ({ index, value }))) {
            if (value.notificationId == notif.notificationId) {
              this.notifications.splice(index, 1);
            }
          };
        }
      });
    } else if (notif.type == "FinalInvoiceUpload") {
      this.rfqService.getRfqState(notif.rfqId).subscribe(x => {
        if (x == "Closed") {
          this.rfqService.getRfqArchiveState(notif.rfqId).subscribe(x => {
            this.router.navigate(['buyer-home/view-rfq', 'Closed', notif.rfqId,'true'], {
              queryParams: { archived: x + "" }
            });
          });

        } else if (x == "In Progress") {
          this.router.navigate(['buyer-home/view-rfq', 'InProgress', notif.rfqId,'true']);
        }
      });
    } else if (notif.type == "ReplySubmitted") {
  
      this.questionAndAnswersService.getQuestionOrReplyDeleted(notif.questionId, notif.replyId).subscribe(x => {
        if (!x) {
      
          this.quoteService.QuoteExistCheck(notif.rfqId,notif.supplierId).subscribe(check =>{
            if(check != 0){
              this.router.navigate(['supplier-home/view-quote', 'Open', check,'true']);
            }else if (check == 0){
             //nav to favourites or open quotes
            //  this.router.navigate(['supplier-home/search-rfq']);
            this.rfqService.getRfqStarredCheck(notif.rfqId, this.account.account.userId).pipe(first())
            .subscribe({
              next: (response: any) => {
               if(response){
                this.router.navigate(['supplier-home/search-rfq'], {
                  queryParams: {
                    SRCH: "Favourites"
                  },
                });
               }else{
                this.router.navigate(['supplier-home/search-rfq'], {
                  queryParams: {
                    SRCH: "PubChatNav",
                    RWPC: notif.rfqId
                  },
                });
               }
              },
              error: error => {
                this.toastrNotification.error("Nav To Search Rfq Failed");
              }
            })
            }
            for (const { index, value } of this.notifications.map((value, index) => ({ index, value }))) {
              if (value.notificationId == notif.notificationId) {
             
                this.notifications.splice(index, 1);
              }
            };
            this.notificationsService.setNotificationReturn(notif.notificationId).subscribe(x => { });
          })
        } else {
          this.toastrNotification.info("Question or Reply has been deleted");
          this.notificationsService.setNotificationReturn(notif.notificationId).subscribe(x => {
          });
          for (const { index, value } of this.notifications.map((value, index) => ({ index, value }))) {
            if (value.notificationId == notif.notificationId) {
              
              this.notifications.splice(index, 1);
            }
          };
        }
      })
    } else if(notif.type == "Unread Public Message"){
      this.router.navigate(['buyer-home/view-rfq', 'Open', 0,'true']);
      for (const { index, value } of this.notifications.map((value, index) => ({ index, value }))) {
        if (value.notificationId == notif.notificationId) {
       
          this.notifications.splice(index, 1);
        }
      };
      this.notificationsService.setNotificationReturn(notif.notificationId).subscribe(x => { });
    }
    
    else if (notif.type == "QuoteExpired") {
      this.router.navigate(['supplier-home/view-quote', 'Expired', notif.quoteId,'true']);

    } else if (notif.type == "BuyerPrivateChat") {
      this.rfqService.getRfqState(notif.rfqId).subscribe(x => {
        if (x == "In Progress") {
          this.router.navigate(['buyer-home/view-rfq', 'InProgress', notif.rfqId,'true']);
        } else if (x == "Closed") {
          this.rfqService.getRfqArchiveState(notif.rfqId).subscribe(x => {
            this.router.navigate(['buyer-home/view-rfq', 'Closed', notif.rfqId,'true'], {
              queryParams: { archived: x + "" }
            });
          });
        }
        for (const { index, value } of this.notifications.map((value, index) => ({ index, value }))) {
          if (value.notificationId == notif.notificationId) {
           
            this.notifications.splice(index, 1);
          }
        };
        this.notificationsService.setNotificationReturn(notif.notificationId).subscribe(x => {
        });
      });

    } else if (notif.type == "SupplierPrivateChat") {
      this.quoteService.QuoteStateCheck(notif.quoteId).subscribe(x => {
        if (x == "In Progress") {
          this.router.navigate(['supplier-home/view-quote', 'InProgress', notif.quoteId,'true']);
        } else if (x == "Closed") {
          this.quoteService.QuoteArchivedCheck(notif.quoteId).subscribe(x => {
            this.router.navigate(['supplier-home/view-quote', 'Closed', notif.quoteId,'true'], {
              queryParams: { archived: x + "" }
            });
          });
        }
        for (const { index, value } of this.notifications.map((value, index) => ({ index, value }))) {
          if (value.notificationId == notif.notificationId) {
          
            this.notifications.splice(index, 1);
          }
        };
        this.notificationsService.setNotificationReturn(notif.notificationId).subscribe(x => {

        });
      });
    }
    else if (notif.type == "RFQClosingDate") {
      this.router.navigate(['buyer-home/view-rfq', 'Open', 0,'true']);
    } else if (notif.type == "RfqCancelled") {
      this.router.navigate(['supplier-home/view-quote', 'Expired', notif.quoteId,'true']);
    }else if (notif.type == "CreditApplicationSubmitted") {
      this.router.navigate(['supplier-home/supplier-profile'], {
        queryParams: { Tab: 4 }
      });
    }else if (notif.type == "CreditApplicationRejected") {
      this.router.navigate(['buyer-home/buyer-profile'], {
        queryParams: { Tab: 4 }
      });
    }else if (notif.type == "CreditApplicationAccepted") {
      this.router.navigate(['buyer-home/buyer-profile'], {
        queryParams: { Tab: 4 }
      });
    }
  }

  viewRfqNormalLoad: ReloadViewRfqs = { reloadCondition: null, reloadRfqId: null };

  // loadRfqsNormal() {

  //   this.viewRfqNormalLoad = { reloadCondition: null, reloadRfqId: null };
  //   this.viewRfqNormalLoad.reloadCondition = false;
  //   this.viewRfqNormalLoad.reloadRfqId = null;
  //   this.reloader.changeNavNewRfq(this.viewRfqNormalLoad);
  //   this.reloader.changeReloadInProgressRfqs(this.viewRfqNormalLoad);
  //   this.reloader.changeReloadClosedRfqs(this.viewRfqNormalLoad);
  // }

  // loadQuotesNormal() {
  //   this.newQuoteEvent = { reloadCondition: false, reloadRfqId: null };
  //   this.newQuoteEvent.reloadCondition = false;
  //   this.newQuoteEvent.reloadRfqId = null
  //   this.quoteReloader.changeNotifNavQuoteAccepted(this.newQuoteEvent);
  //   this.quoteReloader.changeReloadClosedQuotes(this.newQuoteEvent);
  //   this.quoteReloader.changeNavToExpired(this.newQuoteEvent);
  //   this.quoteReloader.changeNavToNewQuote(this.newQuoteEvent);
  // }

  ngOnInit(): void {

    this.getNotifications(this.account.account.userName);

    this.setPeriodicRefresh(0.05);

    this.subscription = interval(60000)
      .subscribe(x => { this.getNotifications(this.account.account.userName); });

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  getNotifications(username) {

    this.notificationsService.getNotifications(username).subscribe(notifs => {
      this.notifications = [];
      this.notifCount = 0;
      this.notifications = notifs;
      for (let notif of notifs) {
        if (notif.seen == false) {
          this.notifCount++;
        }
      }
    });
  }

  getMockNotifs(): void {
    //async
    // this.notificationsService.getMockNotifs()
    //   .subscribe(notifs => this.notifs = notifs);

    // this.selectedNotif = this.notifs[0];

  }

  setPeriodicRefresh(minutes) {
    setInterval(() => {
      this.cdr.markForCheck();
    }, minutes * 10 * 6000);
  }



  getMinimalisticRelativeTime(dateTime) {

    var date = new Date(dateTime);
    date.setHours(date.getHours() + 2);

    if (!date) {
      return null;
    }


    const today = moment();

    const time = moment(date);

    const diff = today.diff(time);

    const duration = moment.duration(diff);

    if (duration.years() > 0) {
      return duration.years() + 'y';
    } else if (duration.weeks() > 0) {
      return duration.weeks() + 'w';
    } else if (duration.days() > 0) {
      return duration.days() + 'd';
    } else if (duration.hours() > 0) {
      return duration.hours() + 'h';
    } else if (duration.minutes() > 0) {
      return duration.minutes() + 'm';
    } else if (duration.minutes() < 1) {
      return '1s';
    }
  }

}
function ngOnDestroy() {
  throw new Error('Function not implemented.');
}

