import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, first } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { BuyerCompanyService } from '../services/buyer-company.service';
import { Account } from '../models/account';
import { AddUser, BuyerCompany, CreditApplicationCreate, CreditApplicationList, CreditApplicationSuppliersRequest, PreferredSuppliersList } from '../models/buyerCompany';
import { ContactDetailsEdit } from '../models/contactDetailsEdit';
import { CreateUser } from '../models/createUser';
import { EditRfqDetails } from '../models/generalRfqDetailsEdit';
import { AddPreferedSupplier, PreferedSupplierFinal, preferedSupplierInitial } from '../models/preferedSupplier';
import { UploadRfqFile } from '../models/rfqCreate';
import { Province } from '../models/service';
import { SupplierCompanyService } from '../services/supplier-company.service';
import { ToastrNotificationService } from '../services/toastr-notification.service';

@Component({
  selector: 'app-buyer-profile',
  templateUrl: './buyer-profile.component.html',
  styleUrls: ['./buyer-profile.component.css']
})
export class BuyerProfileComponent implements OnInit {

  // NEEDED FOR DATA LOADING SPINNER
  dataLoading: boolean = false;


  //NEEDED FOR COLOUR OF EDIT USER MODAL
  greenformheader: boolean = true;
  blueformheader: boolean = false;

  buyerCompanyDetais: BuyerCompany;

  account: Account;

  provinces: Province[] = [Province.WesternCape, Province.NorthernCape, Province.NorthWest, Province.Mpumalanga, Province.Limpopo, Province.KwaZuluNatal, Province.Gauteng, Province.FreeState, Province.EasternCape]

  updatedContactDetails: ContactDetailsEdit = { BuyerId: null, StreetAddress: null, City: null, Province: null, PostalCode: null, CompanyEmail: null, CompanyPhoneNumber: null }
  newUser: CreateUser = { BuyerId: null, Username: null, FirstName: null, Email: null, LastName: null, Role: null, Team: null, SMSPreference: null, EmailPreference: null }
  updatedGeneralRfqRequirements: EditRfqDetails = { BuyerId: null, TUVRequirements: null, BEECertificate: null, BEERequirements: null, ISORequirements: null };
  initialSuppliers: preferedSupplierInitial[] = [];
  selectedSupplier: PreferedSupplierFinal = { supplierId: null, name: null, supplierServices: null, companyEmail: null, companyPhoneNumber: null, teams: null };
  preferredSupplier: AddPreferedSupplier = { BuyerId: null, SupplierId: null, Team: null };
  removePreferredSupplier: AddPreferedSupplier = { BuyerId: null, SupplierId: null, Team: null };
  availableCreditSuppliers: CreditApplicationSuppliersRequest[] = [];

  @ViewChild("editContactDetailsBtn") editContactDetailsBtn: ElementRef;
  @ViewChild('CloseEditUserModal') CloseEditUserModal: ElementRef;
  @ViewChild('CloseAddUserModal') CloseAddUserModal: ElementRef;
  @ViewChild('GeneralRfqRequirementBtn') GeneralRfqRequirementBtn: ElementRef;
  @ViewChild('addSupplierOpenBtn') addSupplierOpenBtn: ElementRef;
  @ViewChild('addPreferredSupplierBtn') addPreferredSupplierBtn: ElementRef;
  @ViewChild('DeleteUserModal') DeleteUserModal: ElementRef;
  @ViewChild('tabToCreditApplication') tabToCreditApplication: ElementRef;
  @ViewChild('creditReApplicationMdl') creditReApplicationMdl: ElementRef;

  @ViewChild('CreditAppMdl') CreditAppMdl: ElementRef;
  @ViewChild('CreditAppSuccessMdl') CreditAppSuccessMdl: ElementRef;

  @ViewChild('BTab1') BTab1: ElementRef;
  @ViewChild('BTab2') BTab2: ElementRef;
  @ViewChild('BTab3') BTab3: ElementRef;
  @ViewChild('BTab4') BTab4: ElementRef;

  userModalPos: number;

  editingContactDetails: boolean = false;
  addingNewUser: boolean = false;
  contactDetailsErrorDisplay: boolean = false;
  editGeneralDetails: boolean = false;
  openSupplierModal: boolean = false;
  gettingSupplierDetails: boolean = false;
  addingPreferredSupplier: boolean = false;
  removingPreferredSupplier: boolean = false;
  gettingAvailableSuppliers: boolean = false;
  creatingCreditApplication: boolean = false;
  gettingReApplySupplier: boolean = false;

  contactForm: FormGroup;
  addNewUserForm: FormGroup;
  generalRfqRequirementsForm: FormGroup;
  beePopulateForm: FormGroup;
  addPreferedSupplierForm: FormGroup;
  creditApplication: FormGroup;

  selectedBeeLevel: number;

  pageOfItems: Array<any>;
  pageOfItemsPS: Array<any>;
  pageOfItemsCA: Array<any>;
  maxUserListSize: number = 10;

  searchUsers: string = "";
  searchUserCheck: string = "";
  searchSuppliers: string = "";
  searchCreditSuppliers: string = "";
  searchPreferredSuppliers: string = "";
  searchCreditApplications: string = "";
  selectedPreferredSupplierId: number;
  selectedCreditSupplier: CreditApplicationSuppliersRequest = null;
  newCreditApplication: CreditApplicationCreate = { BuyerId: null, SupplierId: null, Period: null, CreatedBy: null };
  selectedCreditApplication: CreditApplicationList;
  tabParam: number;
  userTab: string = "";
  requirementsTab: string = "";
  preferredSupplierTab: string = "";
  creditApplicationTab: string = "";


  postalCodeValidator: RegExp = /^\s*?\d{1,10}\s*?$/i;

  constructor(
    private buyerCompanyService: BuyerCompanyService,
    private supplierCompanyService: SupplierCompanyService,
    public authService: AuthService,
    private formBuilder: FormBuilder,
    public toastrNotification: ToastrNotificationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.authService.account.subscribe(x => {
      this.account = x
      // console.log(x);
    });

  }

  ngOnInit(): void {

    this.addPreferedSupplierForm = this.formBuilder.group({

      supplierName: [""],
      supplierEmail: [""],
      supplierContactNumber: [""],
      team: [""]

    });

    this.creditApplication = this.formBuilder.group({

      dayRange: [""],

    });


    this.ps.supplierName.disable();
    this.ps.supplierEmail.disable();;
    this.ps.supplierEmail.disable();
    this.ps.supplierContactNumber.disable();
    this.ps.team.disable();


    this.addNewUserForm = this.formBuilder.group({

      userName: ["", Validators.email],
      firstName: [""],
      lastName: [""],
      role: [""],
      team: [""],
      sMSPreference: [false],
      emailPreference: [false],

    });

    this.beePopulateForm = this.formBuilder.group({

      beeLevel1P: [false],
      beeLevel2P: [false],
      beeLevel3P: [false],
      beeLevel4P: [false],
      beeLevel5P: [false],

    });

    this.nu.role.disable();

    this.getBuyerCompanyData();

    this.route.queryParamMap.subscribe(params => {

      this.tabParam = +params.get('Tab');
    
      if (this.tabParam == 1) {
        this.changeToUserTab();
      } else if (this.tabParam == 2) {
        this.changeToRequirementsTab();
      } else if ((this.tabParam == 3)) {
        this.changeToPreferredSupplierTab();
      } else if ((this.tabParam == 4)) {
        this.changeTocreditApplicationTab();
      } else {
        this.router.navigate(['buyer-home/buyer-profile'], {
          queryParams: {
            Tab: 1
          },
          queryParamsHandling: 'merge'
        });
      }

    });

  }

  changeToUserTab(){
    this.userTab = "active";
    this.requirementsTab = "";
    this.preferredSupplierTab = "";
    this.creditApplicationTab = "";
  }

  changeToRequirementsTab(){
    this.userTab = "";
    this.requirementsTab = "active";
    this.preferredSupplierTab = "";
    this.creditApplicationTab = "";
  }

  changeToPreferredSupplierTab(){
    this.userTab = "";
    this.requirementsTab = "";
    this.preferredSupplierTab = "active";
    this.creditApplicationTab = "";
  }

  changeTocreditApplicationTab(){
    this.userTab = "";
    this.requirementsTab = "";
    this.preferredSupplierTab = "";
    this.creditApplicationTab = "active";
  }

  changeTab(tabNumber){
    this.router.navigate(['buyer-home/buyer-profile'], {
      queryParams: {
        Tab: tabNumber
      },
      queryParamsHandling: 'merge'
    });
  }

  preferredSupplierCond: boolean;

  addPreferredSupplierValid() {

    if (this.ps.team.value == "") {
      this.preferredSupplierCond = true;
    } else {
      this.preferredSupplierCond = false;
    }
  }

  removePreferedSupplier(supplierId, team) {

    this.selectedPreferredSupplierId = supplierId;

    this.removingPreferredSupplier = true;

    this.removePreferredSupplier = { BuyerId: null, SupplierId: null, Team: null };

    this.removePreferredSupplier.BuyerId = this.account.account.buyerId;
    this.removePreferredSupplier.SupplierId = supplierId;
    this.removePreferredSupplier.Team = team;

    this.buyerCompanyService.removePreferedSupplier(this.removePreferredSupplier).pipe(first())
      .subscribe({

        next: (response: any) => {
          this.getBuyerCompanyData();
          this.removingPreferredSupplier = false;
        },
        error: error => {
          this.toastrNotification.error("Remove Preferred Supplier Failed");
          this.removingPreferredSupplier = false;
        }
      });

  }

  addPreferredSupplier() {

    this.addingPreferredSupplier = true;

    this.preferredSupplier = { BuyerId: null, SupplierId: null, Team: null };

    this.preferredSupplier.BuyerId = this.account.account.buyerId;
    this.preferredSupplier.SupplierId = this.selectedSupplier.supplierId;
    this.preferredSupplier.Team = this.ps.team.value;

    this.buyerCompanyService.addPreferedSupplier(this.preferredSupplier).pipe(first())
      .subscribe({
        next: (response: any) => {
          this.ps.team.setValue("");
          this.addPreferredSupplierBtn.nativeElement.click();
          this.addingPreferredSupplier = false;
          this.getBuyerCompanyData();
        },
        error: error => {
          this.toastrNotification.error("Add Preferred Supplier Failed");
          this.addingPreferredSupplier = false;
        }
      });

  }

  getPreferredSupplierSelect(supplierId: number) {

    this.gettingSupplierDetails = true;

    this.buyerCompanyService.getPreferedSupplierFinal(supplierId, this.account.account.buyerId).pipe(first())
      .subscribe({
        next: (response: any) => {
          this.selectedSupplier = response;
          this.ps.team.enable();
          this.ps.supplierName.setValue(response.name);
          this.ps.supplierEmail.setValue(response.companyEmail);
          this.ps.supplierEmail.setValue(response.companyEmail);
          this.ps.supplierContactNumber.setValue(response.companyPhoneNumber);
          this.gettingSupplierDetails = false;
        },
        error: error => {
          this.toastrNotification.error("Get Preferred Supplier Failed");
          this.gettingSupplierDetails = false;
        }
      });

  }



  getInitialSupplierList() {

    this.openSupplierModal = true;
    this.buyerCompanyService.getPreferedSupplierInitial(this.account.account.userId).pipe(first())
      .subscribe({
        next: (response: any) => {
          this.initialSuppliers = response;
          this.addSupplierOpenBtn.nativeElement.click();
          this.openSupplierModal = false;
        },
        error: error => {
          this.toastrNotification.error("Get Supplier List Failed");
          this.openSupplierModal = false;
        }
      });

  }

  searchUserChange() {
    this.searchUserCheck = this.searchUsers
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;

    // console.log(this.pageOfItems);
  }

  onChangePagePS(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItemsPS = pageOfItems;
  }

  onChangePageCA(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItemsCA = pageOfItems;
  }

  userDetailsValid() {

    if (this.nu.firstName.value.replace(/ /g, "") != "" && this.nu.firstName.value.length < 50
      && this.nu.lastName.value.replace(/ /g, "") != "" && this.nu.lastName.value.length < 50
      // && this.nu.role.value.replace(/ /g, "") != ""
      && this.nu.team.value.replace(/ /g, "") != "" && this.nu.team.value.length < 50
      && this.nu.userName.value.replace(/ /g, "") != "" && this.nu.userName.value.length < 50) {
      return true;
    } else {
      return false;
    }
  }

  addUserErrorDisplay: boolean;

  userDetailsValidCond() {
    if (this.nu.firstName.value.replace(/ /g, "") != "" && this.nu.firstName.value.length < 50
      && this.nu.lastName.value.replace(/ /g, "") != "" && this.nu.lastName.value.length < 50
      // && this.nu.role.value.replace(/ /g, "") != ""
      && this.nu.team.value.replace(/ /g, "") != "" && this.nu.team.value.length < 50
      && this.nu.userName.value.replace(/ /g, "") != "" && this.nu.userName.value.length < 50) {
      this.addUserErrorDisplay = false;
    } else {
      this.addUserErrorDisplay = true;
    }
  }

  addNewUser() {

    this.addingNewUser = true;

    this.newUser = { BuyerId: null, Username: null, FirstName: null, Email: null, LastName: null, Role: null, Team: null, SMSPreference: null, EmailPreference: null }

    this.newUser.BuyerId = this.account.account.buyerId;
    this.newUser.FirstName = this.nu.firstName.value;
    this.newUser.LastName = this.nu.lastName.value;
    this.newUser.Role = "Buyer";
    this.newUser.Team = this.nu.team.value;
    this.newUser.Username = this.nu.userName.value;
    this.newUser.Email = this.nu.userName.value;
    this.newUser.SMSPreference = this.nu.sMSPreference.value;
    this.newUser.EmailPreference = this.nu.emailPreference.value;

    this.authService.createUser(this.newUser).pipe(first())
      .subscribe({
        next: (response: any) => {
          this.CloseAddUserModal.nativeElement.click();
          this.addingNewUser = false;
          this.getBuyerCompanyData();
          this.clearAddUser();
          this.toastrNotification.success("User Added Successfully");
        },
        error: error => {
          this.toastrNotification.error("Add User Failed");
          this.addingNewUser = false;
        }
      });
  }

  clearAddUser() {
    this.nu.firstName.setValue("");
    this.nu.lastName.setValue("");
    this.nu.role.setValue("");
    this.nu.team.setValue("");
    this.nu.userName.setValue("");
    this.nu.sMSPreference.setValue(false);
    this.nu.emailPreference.setValue(false);
  }

  companyLogoFileName: string = "";

  getBuyerCompanyData() {
    // FLAG FOR DATA LOADING SETS VALUE TO TRUE WHILE DATA IS BEING FETCHED

    this.dataLoading = true;

    var beeReqCond: boolean;
    var beeVal: number;

    // FLAG FOR DATA LOADING SETS VALUE TO FALSE WHEN DATA IS FETCHED
    this.buyerCompanyService.getBuyerCompanyData(this.account.account.buyerId).pipe(
      finalize(() => this.dataLoading = false)
    ).subscribe(x => {
      this.buyerCompanyDetais = x;
      this.companyLogoFileName = x.profilePictureFileName;
      this.getCompanyLogoFile();
      // console.log(x);

      for (let user of this.buyerCompanyDetais.buyerUsers) {
        user.fullname = user.firstName + " " + user.lastName;

        if (user.emailConfirmed) {
          user.status = "Active";
        } else {
          user.status = "Pending";
        }
      }

      this.contactForm = this.formBuilder.group({

        streetAddress: [this.buyerCompanyDetais.streetAddress,],
        city: [this.buyerCompanyDetais.city],
        province: [""],
        postalCode: [this.buyerCompanyDetais.postalCode],
        companyEmail: [this.buyerCompanyDetais.companyEmail, Validators.email],
        companyPhoneNumber: [this.buyerCompanyDetais.companyPhoneNumber]

      });

      this.setDefaultProvince(this.buyerCompanyDetais.province);

      this.generalRfqRequirementsForm = this.formBuilder.group({

        tuvRequirements: [this.buyerCompanyDetais.tuvRequirements],
        isoRequirements: [this.buyerCompanyDetais.isoRequirements],
        beeRequired: [this.buyerCompanyDetais.beeCertificate],

        beeLevel1: [false],
        beeLevel2: [false],
        beeLevel3: [false],
        beeLevel4: [false],
        beeLevel5: [false],

      });

      if (this.buyerCompanyDetais.beeRequirements != null) {
        this.beeLevelSelect(this.buyerCompanyDetais.beeRequirements);
        this.selectedBeeLevel = this.buyerCompanyDetais.beeRequirements
      }

      this.beeLevelSelectDisplay(this.buyerCompanyDetais.beeRequirements)

      

    });
  }

  setDefaultProvince(province) {
    // console.log(this.buyerCompanyDetais.province)
    if (province == "Gauteng") {
      this.cf.province.setValue(Province.Gauteng);
    } else if (province == "Western Cape") {
      this.cf.province.setValue(Province.WesternCape);
    } else if (province == "Eastern Cape") {
      this.cf.province.setValue(Province.EasternCape);
    } else if (province == "Free State") {
      this.cf.province.setValue(Province.FreeState);
    } else if (province == "KwaZulu-Natal") {
      this.cf.province.setValue(Province.KwaZuluNatal);
    } else if (province == "Limpopo") {
      this.cf.province.setValue(Province.Limpopo);
    } else if (province == "Mpumalanga") {
      this.cf.province.setValue(Province.Mpumalanga);
    } else if (province == "Northern Cape") {
      this.cf.province.setValue(Province.NorthernCape);
    } else if (province == "North West") {
      this.cf.province.setValue(Province.NorthWest);
    }
  }

  beeLevelSelect(level) {

    this.selectedBeeLevel = level;
    if (level == 1) {
      this.gr.beeLevel1.setValue(true);
      this.gr.beeLevel2.setValue(false);
      this.gr.beeLevel3.setValue(false);
      this.gr.beeLevel4.setValue(false);
      this.gr.beeLevel5.setValue(false);
    } else if (level == 2) {
      this.gr.beeLevel1.setValue(false);
      this.gr.beeLevel2.setValue(true);
      this.gr.beeLevel3.setValue(false);
      this.gr.beeLevel4.setValue(false);
      this.gr.beeLevel5.setValue(false);
    } else if (level == 3) {
      this.gr.beeLevel1.setValue(false);
      this.gr.beeLevel2.setValue(false);
      this.gr.beeLevel3.setValue(true);
      this.gr.beeLevel4.setValue(false);
      this.gr.beeLevel5.setValue(false);
    } else if (level == 4) {
      this.gr.beeLevel1.setValue(false);
      this.gr.beeLevel2.setValue(false);
      this.gr.beeLevel3.setValue(false);
      this.gr.beeLevel4.setValue(true);
      this.gr.beeLevel5.setValue(false);
    } else if (level == 5) {
      this.gr.beeLevel1.setValue(false);
      this.gr.beeLevel2.setValue(false);
      this.gr.beeLevel3.setValue(false);
      this.gr.beeLevel4.setValue(false);
      this.gr.beeLevel5.setValue(true);
    }
  }

  beeLevelSelectDisplay(level) {

    if (level == 1) {
      this.bp.beeLevel1P.setValue(true);
      this.bp.beeLevel2P.setValue(false);
      this.bp.beeLevel3P.setValue(false);
      this.bp.beeLevel4P.setValue(false);
      this.bp.beeLevel5P.setValue(false);
    } else if (level == 2) {
      this.bp.beeLevel1P.setValue(false);
      this.bp.beeLevel2P.setValue(true);
      this.bp.beeLevel3P.setValue(false);
      this.bp.beeLevel4P.setValue(false);
      this.bp.beeLevel5P.setValue(false);
    } else if (level == 3) {
      this.bp.beeLevel1P.setValue(false);
      this.bp.beeLevel2P.setValue(false);
      this.bp.beeLevel3P.setValue(true);
      this.bp.beeLevel4P.setValue(false);
      this.bp.beeLevel5P.setValue(false);
    } else if (level == 4) {
      this.bp.beeLevel1P.setValue(false);
      this.bp.beeLevel2P.setValue(false);
      this.bp.beeLevel3P.setValue(false);
      this.bp.beeLevel4P.setValue(true);
      this.bp.beeLevel5P.setValue(false);
    } else if (level == 5) {
      this.bp.beeLevel1P.setValue(false);
      this.bp.beeLevel2P.setValue(false);
      this.bp.beeLevel3P.setValue(false);
      this.bp.beeLevel4P.setValue(false);
      this.bp.beeLevel5P.setValue(true);
    }

    this.bp.beeLevel1P.disable();
    this.bp.beeLevel2P.disable();
    this.bp.beeLevel3P.disable();
    this.bp.beeLevel4P.disable();
    this.bp.beeLevel5P.disable();
  }

  contactDetailsValid() {
    if (this.cf.streetAddress.value.replace(/ /g, "") != "" && this.cf.streetAddress.value.length < 100
      && this.cf.city.value.replace(/ /g, "") != "" && this.cf.city.value.length < 50
      && this.cf.province.value.replace(/ /g, "") != "" && this.cf.province.value.length < 50
      && this.cf.postalCode.value.replace(/ /g, "") != "" && this.cf.postalCode.value.length < 10
      && this.postalCodeValid(this.cf.postalCode.value)
      && this.cf.companyEmail.value.replace(/ /g, "") != "" && this.cf.companyEmail.value.length < 50
      && this.cf.companyPhoneNumber.value.replace(/ /g, "") != "" && this.cf.companyPhoneNumber.value.length < 15) {
      return true;
    } else {
      return false;
    }
  }

  contactDetailsValidCond() {
    if (this.cf.streetAddress.value.replace(/ /g, "") != "" && this.cf.streetAddress.value.length < 100
      && this.cf.city.value.replace(/ /g, "") != "" && this.cf.city.value.length < 50
      && this.cf.province.value.replace(/ /g, "") != "" && this.cf.province.value.length < 50
      && this.cf.postalCode.value.replace(/ /g, "") != "" && this.cf.postalCode.value.length < 10
      && this.postalCodeValid(this.cf.postalCode.value)
      && this.cf.companyEmail.value.replace(/ /g, "") != "" && this.cf.companyEmail.value.length < 50
      && this.cf.companyPhoneNumber.value.replace(/ /g, "") != "" && this.cf.companyPhoneNumber.value.length < 15) {
      this.contactDetailsErrorDisplay = false;
    } else {
      this.contactDetailsErrorDisplay = true;
    }
  }

  postalCodeValid(value) {
    return this.postalCodeValidator.test(value);
    // console.log(this.postalCodeValidator.test(value));
  }


  stringValidation(value) {
    if (value.replace(/ /g, "") == "") {
      return true
    } else {
      return false;
    }
  }

  removeSFWhiteSpace(value) {
    var newValue = value.trim();
    return newValue;
  }

  contactDetailsEdit() {

    this.updatedContactDetails = { BuyerId: null, StreetAddress: null, City: null, Province: null, PostalCode: null, CompanyEmail: null, CompanyPhoneNumber: null }

    this.updatedContactDetails.BuyerId = this.account.account.buyerId;
    this.updatedContactDetails.StreetAddress = this.cf.streetAddress.value;
    this.updatedContactDetails.City = this.cf.city.value;
    this.updatedContactDetails.Province = this.cf.province.value;
    this.updatedContactDetails.PostalCode = this.cf.postalCode.value;
    this.updatedContactDetails.CompanyEmail = this.cf.companyEmail.value;
    this.updatedContactDetails.CompanyPhoneNumber = this.cf.companyPhoneNumber.value;

    if (this.updatedContactDetails.StreetAddress != this.buyerCompanyDetais.streetAddress
      || this.updatedContactDetails.City != this.buyerCompanyDetais.city
      || this.updatedContactDetails.Province != this.buyerCompanyDetais.province
      || this.updatedContactDetails.PostalCode != this.buyerCompanyDetais.postalCode
      || this.updatedContactDetails.CompanyEmail != this.buyerCompanyDetais.companyEmail
      || this.updatedContactDetails.CompanyPhoneNumber != this.buyerCompanyDetais.companyPhoneNumber) {

      this.editingContactDetails = true;

      this.buyerCompanyService.editBuyerDetails(this.updatedContactDetails).pipe(first())
        .subscribe({
          next: (response: any) => {
            this.editContactDetailsBtn.nativeElement.click();
            this.editingContactDetails = false;
            this.getBuyerCompanyData();
            this.toastrNotification.success("Edit Contact Details Successful");
          },
          error: error => {
            this.toastrNotification.error("Edit Contact Details Failed");
            this.editingContactDetails = false;
          }
        });
    } else {
      this.toastrNotification.info("No Changes Present");
    }
  }

  cancelContactDetailsEdit() {
    this.cf.streetAddress.setValue(this.buyerCompanyDetais.streetAddress);
    this.cf.city.setValue(this.buyerCompanyDetais.city);
    this.cf.province.setValue(this.buyerCompanyDetais.province);
    this.cf.postalCode.setValue(this.buyerCompanyDetais.postalCode);
    this.cf.companyEmail.setValue(this.buyerCompanyDetais.companyEmail);
    this.cf.companyPhoneNumber.setValue(this.buyerCompanyDetais.companyPhoneNumber);
    this.contactDetailsErrorDisplay = false;
  }

  EditUserModalTrigger(cond) {
    this.CloseEditUserModal.nativeElement.click();
    this.getBuyerCompanyData();
  }

  openDeleteUserModal(value) {

    this.CloseEditUserModal.nativeElement.click();
    this.DeleteUserModal.nativeElement.click();
  }

  deletingUser: boolean = false;

  deleteUser(user) {

    this.deletingUser = true;

    this.authService.deleteUser(user.userName).pipe(first()).subscribe({
      next: (response: any) => {

        this.DeleteUserModal.nativeElement.click();
        this.toastrNotification.success("User Deleted Successfully");
        this.deletingUser = false;
        this.getBuyerCompanyData();

      },
      error: error => {

        this.toastrNotification.error("User Delete Failed");
        this.deletingUser = false;

      }
    });
  }

  userModalPosition(pos) {
    this.userModalPos = pos;
  }

  editGeneralRfqRequirements() {

    this.updatedGeneralRfqRequirements = { BuyerId: null, TUVRequirements: null, BEECertificate: null, BEERequirements: null, ISORequirements: null };

    this.updatedGeneralRfqRequirements.BuyerId = this.account.account.buyerId;
    this.updatedGeneralRfqRequirements.TUVRequirements = this.gr.tuvRequirements.value;
    this.updatedGeneralRfqRequirements.BEECertificate = this.gr.beeRequired.value;
    this.updatedGeneralRfqRequirements.BEERequirements = this.selectedBeeLevel;
    this.updatedGeneralRfqRequirements.ISORequirements = this.gr.isoRequirements.value;
    if (this.updatedGeneralRfqRequirements.TUVRequirements != this.buyerCompanyDetais.tuvRequirements
      || this.updatedGeneralRfqRequirements.BEECertificate != this.buyerCompanyDetais.beeCertificate
      || this.updatedGeneralRfqRequirements.BEERequirements != this.buyerCompanyDetais.beeRequirements
      || this.updatedGeneralRfqRequirements.ISORequirements != this.buyerCompanyDetais.isoRequirements) {

      this.editGeneralDetails = true;

      this.buyerCompanyService.editBuyerGeneralRfqDetails(this.updatedGeneralRfqRequirements).pipe(first()).subscribe({

        next: (response: any) => {

          this.GeneralRfqRequirementBtn.nativeElement.click();
          this.getBuyerCompanyData();
          this.editGeneralDetails = false;
          this.toastrNotification.success("Edit General RFQ  Requirements Succeeded");
        },
        error: error => {
          this.toastrNotification.error("Edit General RFQ  Requirements Failed");
          this.editGeneralDetails = false;
        }
      });
    } else {
      this.toastrNotification.info("No Changes Present");
    }

  }

  cancelGeneralRfqDetails() {

    this.gr.tuvRequirements.setValue(this.buyerCompanyDetais.tuvRequirements);
    this.gr.beeRequired.setValue(this.buyerCompanyDetais.beeRequirements);
    this.gr.isoRequirements.setValue(this.buyerCompanyDetais.isoRequirements);

    if (this.buyerCompanyDetais.beeRequirements != null) {
      this.beeLevelSelect(this.buyerCompanyDetais.beeRequirements);
      this.selectedBeeLevel = this.buyerCompanyDetais.beeRequirements
    }

  }

  companyLogo: string = "";

  differentCompanyLogoReload() {
    if (this.companyLogoFileName != this.buyerCompanyDetais.profilePictureFileName) {
      this.getCompanyLogoFile();
      this.companyLogoFileName = this.buyerCompanyDetais.profilePictureFileName;
    }
  }

  getCompanyLogoFile() {
    this.buyerCompanyService.getCompanyLogoFile(this.account.account.buyerId).pipe(first())
      .subscribe({
        next: (response: any) => {
          var fileType = this.buyerCompanyDetais.profilePictureFileName.substring(this.buyerCompanyDetais.profilePictureFileName.indexOf(".") + 1, this.buyerCompanyDetais.profilePictureFileName.length);
          this.companyLogo = `data:image/${fileType};base64,${response.fileContent}`;
          this.companyLogoPreview = `data:image/${fileType};base64,${response.fileContent}`;
          this.buyerCompanyDetais.profilePictureFileName = response.fileName;
          this.companyLogoFileNamePreview = response.fileName;
          this.companyLogoUploading = false;
        },
        error: error => {
          this.toastrNotification.error("Buyer Company Logo Download Failed");

        }
      }
      );
  }

  creditState: string = "";

  getAvailableCreditSuppliers() {

    this.gettingAvailableSuppliers = true;

    this.buyerCompanyService.getAvailableCreditSuppliers(this.account.account.userId).pipe(first())
      .subscribe({
        next: (response: any) => {
          this.availableCreditSuppliers = response;
          this.gettingAvailableSuppliers = false;
          this.creditState = "Apply";
          this.CreditAppMdl.nativeElement.click();
        },
        error: error => {
          this.toastrNotification.error("Available credit suppliers get failed");
          this.gettingAvailableSuppliers = false;
        }
      });
  }

  createCreditApplication(state: string) {

    this.newCreditApplication = { BuyerId: null, SupplierId: null, Period: null, CreatedBy: null };

    this.newCreditApplication.BuyerId = this.account.account.buyerId;
    this.newCreditApplication.SupplierId = this.selectedCreditSupplier.supplierId;
    this.newCreditApplication.Period = this.ca.dayRange.value;
    this.newCreditApplication.CreatedBy = this.account.account.userId;

    this.creatingCreditApplication = true;

    if (state == "Apply") {
      this.buyerCompanyService.createCreditApplication(this.newCreditApplication).pipe(first())
        .subscribe({
          next: (response: any) => {
            this.buyerCompanyService.uploadCreditApplicationFile(this.creditApplicationUpload).pipe(first())
              .subscribe({
                next: (response: any) => {
                  this.creatingCreditApplication = false;
                  this.clearCreditApplicationForm();
                  this.CreditAppSuccessMdl.nativeElement.click();
                },
                error: error => {
                  this.toastrNotification.error("Upload Credit Application File Failed");
                  this.creatingCreditApplication = false;
                  this.buyerCompanyService.deleteCreditApplication(this.newCreditApplication.BuyerId, this.newCreditApplication.SupplierId).pipe(first())
                  .subscribe({
                    next: (response: any) => {

                    },
                    error: error => {
                      this.toastrNotification.error("Removed Credit Application Failed");
                    }
                  });
                }
              });
          },
          error: error => {
            this.toastrNotification.error("Create Credit Application Failed");
            this.creatingCreditApplication = false;
          }
        });
    } else if (state == "Reapply") {
      this.buyerCompanyService.editCreditApplication(this.newCreditApplication).pipe(first())
        .subscribe({
          next: (response: any) => {
            this.buyerCompanyService.uploadCreditApplicationFile(this.creditApplicationUpload).pipe(first())
              .subscribe({
                next: (response: any) => {
                  this.creatingCreditApplication = false;
                  this.clearCreditApplicationForm();
                  this.CreditAppSuccessMdl.nativeElement.click();
                },
                error: error => {
                  this.toastrNotification.error("Upload Credit Application File Failed");
                  this.creatingCreditApplication = false;
                }
              });
          },
          error: error => {
            this.toastrNotification.error("Edit Credit Application Failed");
            this.creatingCreditApplication = false;
          }
        });
    }

  }

  clearCreditApplicationForm() {
    this.ca.dayRange.setValue("");
    this.creditApplicationUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
    this.CreditApplicationFileNamePreview = "";
    this.selectedCreditSupplier = null;

  }

  closeCreditAppSuccessModal() {

    this.getBuyerCompanyData();
    //this.CreditAppSuccessMdl.nativeElement.click();    
  }

  selectCreditSupplier(supplier) {
    this.selectedCreditSupplier = supplier;
  }

  setSelectedCreditApplication(application: CreditApplicationList) {
    this.selectedCreditApplication = application;
    //console.log(application);
  }

  getReapplyCreditSupplier(supplierId) {

    this.applicationSupplierId = supplierId;
    this.gettingReApplySupplier = true;

    this.buyerCompanyService.getReapplyCreditSuppliers(this.account.account.buyerId, supplierId).pipe(first())
      .subscribe({
        next: (response: any) => {

          if (response != null) {
            this.selectedCreditSupplier = response;
            this.creditState = "Reapply";
            this.creditReApplicationMdl.nativeElement.click();
          }

          this.gettingReApplySupplier = false;

        },
        error: error => {
          this.toastrNotification.error("Re-apply credit supplier get failed");
          this.gettingReApplySupplier = false;
        }
      });
  }

  companyLogoFile: File;
  creditApplicationFile: File;
  companyLogoUpload: UploadRfqFile = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
  creditApplicationUpload: UploadRfqFile = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
  requiredFileType: string = ".jpg,.png,.jpeg";
  requiredCRFileType: string = ".pdf";
  allowedFileCheck: string[] = ["jpg", "png", "jpeg"];
  companyLogoUploading: boolean = false;
  companyLogoPreview: string = "";
  companyLogoFileNamePreview: string = "";

  CreditApplicationUploading: boolean = false;
  CreditApplicationFileNamePreview: string = "";

  @ViewChild('clearCompanyLogoInput', { static: false }) clearCompanyLogoInput: ElementRef;
  @ViewChild('closeBuyerDetailsMdl') closeBuyerDetailsMdl: ElementRef;
  @ViewChild('clearCreditAppInput', { static: false }) clearCreditAppInput: ElementRef;


  resetComapnyLogoPreview() {
    this.companyLogoPreview = this.companyLogo;
    this.companyLogoFileNamePreview = this.buyerCompanyDetais.profilePictureFileName;
  }

  uploadCompanyLogo() {

    if (this.companyLogoUpload.file != null) {

      this.companyLogoUploading = true;

      if (this.buyerCompanyDetais.profilePictureFileName != null) {

        this.buyerCompanyService.deleteCompanyLogoFile(this.account.account.buyerId).pipe(first())
          .subscribe({
            next: (response: any) => {

              this.buyerCompanyService.uploadCompanyLogo(this.companyLogoUpload).pipe(first())
                .subscribe({
                  next: (response: any) => {
                    // this.getBuyerCompanyData();
                    this.closeBuyerDetailsMdl.nativeElement.click();
                    this.buyerCompanyDetais.profilePictureFileName = response;
                    this.getCompanyLogoFile();
                    this.clearCompanyLogoInput.nativeElement.value = "";
                    this.companyLogoUploading = false;

                  },
                  error: error => {
                    this.toastrNotification.error("Buyer Company Logo Upload Failed");
                    this.clearCompanyLogoInput.nativeElement.value = "";
                    this.companyLogoUploading = false;
                  }
                }
                );
            },
            error: error => {
              this.toastrNotification.error("Buyer Company Logo Edit Failed");
              this.clearCompanyLogoInput.nativeElement.value = "";
              this.companyLogoUploading = false;
            }
          }
          );
      } else {

        this.buyerCompanyService.uploadCompanyLogo(this.companyLogoUpload).pipe(first())
          .subscribe({
            next: (response: any) => {
              // this.getBuyerCompanyData();
              this.closeBuyerDetailsMdl.nativeElement.click();
              this.buyerCompanyDetais.profilePictureFileName = response;
              this.getCompanyLogoFile();
              this.clearCompanyLogoInput.nativeElement.value = "";
              this.companyLogoUploading = false;

            },
            error: error => {
              this.toastrNotification.error("Buyer Company Logo Upload Failed");
              this.clearCompanyLogoInput.nativeElement.value = "";
              this.companyLogoUploading = false;
            }
          }
          );
      }

    } else {
      this.closeBuyerDetailsMdl.nativeElement.click();
    }

  }

  companyLogoSelected(event) {

    this.companyLogoUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };

    this.companyLogoFile = event.target.files[0];
    const reader = new FileReader();

    //this.companyLogoUploading = true;

    if (this.companyLogoFile) {


      const max_height = 5000;
      const max_width = 5000;

      reader.readAsDataURL(this.companyLogoFile);
      reader.onload = (e: any) => {

        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          //  console.log(img_height, img_width);

          if (img_height > max_height && img_width > max_width) {
            this.toastrNotification.error("Image Exceeds width and height of 5000px");
            this.clearCompanyLogoInput.nativeElement.value = "";
          } else if (img_height != img_width) {
            this.toastrNotification.error("Image Width Must Be Equal To Image Height");
            this.clearCompanyLogoInput.nativeElement.value = "";
          } else {

            this.companyLogoPreview = reader.result + "";
            this.companyLogoFileNamePreview = this.companyLogoFile.name;
            this.companyLogoUpload.file = (reader.result + "").substring((reader.result + "").indexOf(",") + 1, (reader.result + "").length);
            // this.companyLogoUpload.quoteId = this.selectedRFQ.acceptedQuotes[0].quoteId;
            // this.companyLogoUpload.rfqId = this.selectedRFQ.rfqId;
            this.companyLogoUpload.buyerId = this.account.account.buyerId;
            this.companyLogoUpload.userId = this.account.account.userId;;
            this.companyLogoUpload.supplierId = null;
            this.companyLogoUpload.fileName = this.companyLogoFile.name;

            this.clearCompanyLogoInput.nativeElement.value = "";

            // console.log(this.companyLogoUpload);

          }
        };
      };
    }
  }

  uploadCreditApplicationFile(event) {

    this.creditApplicationUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };

    this.creditApplicationFile = event.target.files[0];
    const reader = new FileReader();

    if (this.creditApplicationFile) {

      reader.readAsDataURL(this.creditApplicationFile);
      reader.onload = (e: any) => {

        this.CreditApplicationFileNamePreview = this.creditApplicationFile.name;
        this.creditApplicationUpload.file = (reader.result + "").substring((reader.result + "").indexOf(",") + 1, (reader.result + "").length);
        this.creditApplicationUpload.buyerId = this.account.account.buyerId;
        this.creditApplicationUpload.userId = this.account.account.userId;;
        this.creditApplicationUpload.supplierId = this.selectedCreditSupplier.supplierId;
        this.creditApplicationUpload.fileName = this.creditApplicationFile.name;

        this.clearCreditAppInput.nativeElement.value = "";

      };
    }

  }

  downloadingTemplate: boolean = false;
  downloadingApplication: boolean = false;

  downloadCreditApplicationTemplate() {

    this.downloadingTemplate = true;

    this.supplierCompanyService.getCreditApplicationTemplateFile(this.selectedCreditSupplier.supplierId).pipe(first())
      .subscribe({
        next: (response: any) => {
          this.downloadingTemplate = false;

          this.downloadFile(response.fileContent, this.selectedCreditSupplier.creditAppTemplateFileName);

        },
        error: error => {
          this.toastrNotification.error("Download Credit Application Template failed");
          this.downloadingTemplate = false;
        }
      });
  }

  applicationSupplierId: number;

  downloadCreditApplication(supplierId) {
    this.applicationSupplierId = supplierId;
    this.downloadingApplication = true;

    this.buyerCompanyService.getCreditApplicationFile(this.account.account.buyerId, supplierId).pipe(first())
      .subscribe({
        next: (response: any) => {
          this.downloadingApplication = false;
          this.downloadFile(response.fileContent, response.fileName);
        },
        error: error => {
          this.toastrNotification.error("Download Credit Application Template failed");
          this.downloadingApplication = false;
        }
      });
  }

  downloadFile(base64String, fileName) {

    const source = `data:application/;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}`
    link.click();
  }




  //Form control gets
  get cf() { return this.contactForm.controls; }
  get nu() { return this.addNewUserForm.controls; }
  get gr() { return this.generalRfqRequirementsForm.controls; }
  get bp() { return this.beePopulateForm.controls; }
  get ps() { return this.addPreferedSupplierForm.controls; }
  get ca() { return this.creditApplication.controls; }





}

