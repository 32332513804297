export enum Service {
    Bending = 'Bending',
    CNCMachining = 'CNC Machining',
    Chamfer = 'Chamfer',
    DecoilingAndSlitting = 'De-Coiling & Slitting',
    Drilling = 'Drilling',
    Fabrication = 'Fabrication',
    LaserCutting = 'Laser Cutting',
    PlasmaCutting = 'Plasma Cutting',
    ProfileCutting = 'Profile Cutting',
    Rolling = 'Rolling',
    Welding = 'Welding',
}

export enum BuyerPreference {
    BEEComplient = 'BEE Complient',
    CreditGaruntee = 'Credit Garuntee',
    GoldVerified = 'Gold Verified'
}

export enum PaymentPreference {
    COD = 'COD',
    CBD = 'CBD',
    Days30 = '30 Days',
    Days60 = '60 Days',
    Other = 'Other',
    NotSpecified = 'Not Specified'

}

export enum TypeOfSupply {
    Complete = 'Complete',
    RawMaterial = 'Raw Material',
}

export enum PaymentRequirement {

}

export enum BEERequirements {
    Level1 = 'Level 1',
    Level2 = 'Level 2 and lower',
    Level3 = 'Level 3 and lower',
    Level4 = 'Level 4 and lower',
    Level5 = 'Level 5 and lower',
    All = "All",
    None = 'Not Required'
}

export enum ISORequirements {
    R9001 = '9001',
    R9002 = '9002',
    R9003 = '9003'

}

export enum PartnershipRequirements {
    // Gold = 'Gold',
    // Silver = 'Silver',
    // Bronze = 'Bronze',
    All = 'All',
    PreferredSuppliers = 'Preferred Suppliers'

}

export enum LocationRequirements {
    GP = 'Gauteng',



}

export enum PackagingRequirements {
    Boxed = 'Boxed',
    Pallet = 'Pallet',
    Strapped = 'Strapped',
    Loose = 'Loose',
    Other = 'Other'

}

export enum DeliveryRequirements {
    Collection = "Collection",
    Delivery = "Delivery",
    None = "None"

}


export enum RfqType {
    Individual = "Individual",
    CompleteSupply = "Complete Supply"
}

export enum Province {
    WesternCape = "Western Cape",
    EasternCape = "Eastern Cape",
    FreeState = "Free State",
    Gauteng = "Gauteng",
    KwaZuluNatal = "KwaZulu-Natal",
    Limpopo = "Limpopo",
    Mpumalanga = "Mpumalanga",
    NorthernCape = "Northern Cape",
    NorthWest = "North West"

}
