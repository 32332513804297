import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ReloadViewRfqs } from '../models/reloadViewRfqs';

@Injectable({
  providedIn: 'root'
})
export class ViewRfqServiceService {

  inProgressCond: ReloadViewRfqs = { reloadCondition: false, reloadRfqId: null };

  closedCond: ReloadViewRfqs = { reloadCondition: false, reloadRfqId: null };

  newRfqCond: ReloadViewRfqs = { reloadCondition: false, reloadRfqId: null };

  private reloadInProgressRfqs = new BehaviorSubject<ReloadViewRfqs>(this.inProgressCond);

  private reloadInProgressRfqsStay = new BehaviorSubject<ReloadViewRfqs>(this.inProgressCond);

  private reloadClosedRfqs = new BehaviorSubject<ReloadViewRfqs>(this.closedCond);

  private reloadClosedRfqsStay = new BehaviorSubject<ReloadViewRfqs>(this.closedCond);

  private navNewRfq = new BehaviorSubject<ReloadViewRfqs>(this.newRfqCond);

  currentReloadInProgressRfqs = this.reloadInProgressRfqs.asObservable();

  currentReloadInProgressRfqsStay = this.reloadInProgressRfqsStay.asObservable();

  currentClosedRfq = this.reloadClosedRfqs.asObservable();

  currentClosedRfqStay = this.reloadClosedRfqsStay.asObservable();

  currentNavNewRfq = this.navNewRfq.asObservable();

  changeReloadInProgressRfqs(cond: ReloadViewRfqs) {
    this.reloadInProgressRfqs.next(cond);
  }
  changeReloadInProgressRfqsStay(cond: ReloadViewRfqs) {
    this.reloadInProgressRfqsStay.next(cond);
  }
  changeReloadClosedRfqs(cond: ReloadViewRfqs) {
    this.reloadClosedRfqs.next(cond);
  }

  changeReloadClosedRfqsStay(cond: ReloadViewRfqs) {
    this.reloadClosedRfqsStay.next(cond);
  }

  changeNavNewRfq(cond: ReloadViewRfqs) {
    this.navNewRfq.next(cond);
  }

}
