
<form [formGroup]='form' #test="ngForm">
    <!-- <p>{{test.value | json}}</p>  -->
<div class="row d-xxl-flex rfqdetailrow" style="padding-bottom: 0px;">
    <div class="col-1 col-sm-1" style="padding-left: 0;"></div>
    <div class="col d-flex d-sm-flex justify-content-start align-items-start justify-content-sm-start align-items-sm-start justify-content-md-start align-items-xxl-start"
        style="padding: 0px;padding-left: 1rem;margin: 0;margin-right: 1rem;margin-bottom: 0.5rem;margin-left: 1rem;border-radius: 20px;">
        <a class=" bluetextthin clickable" data-bs-toggle="collapse"   [attr.data-bs-target] ="'.b' + question.questionId">Reply</a>
        <p class="text-start d-sm-flex bluetext shift" style="margin-bottom: 0;padding-left: 0;"></p>
    </div>
</div>

<div [attr.class]="'b' + question.questionId + ' row collapse'"style="padding-bottom: 0px;">
    <div class="col-1 col-sm-1" style="padding-left: 0;"></div>
    <div class="col-sm-1 d-flex d-sm-flex justify-content-start align-items-start justify-content-sm-start align-items-sm-start justify-content-md-start align-items-xxl-start"
        style="padding: 0px;padding-left: 1rem;margin: 0;margin-right: 1rem;margin-bottom: 0.5rem;margin-left: 1rem;border-radius: 20px;">
        <p
            class="d-flex d-sm-flex align-items-center justify-content-sm-start align-items-sm-center bluetext">
            <i class="fa fa-user-circle uiicon" style="font-size: 30px;color: #4272F740;"></i>
        </p>
    </div>
    <div class="col-sm-8 d-flex d-sm-flex justify-content-start align-items-start justify-content-sm-start align-items-sm-start justify-content-md-start align-items-xxl-start"
        style="padding: 0px;padding-left: 0rem;margin: 0;margin-right: 1rem;margin-bottom: 0.5rem;margin-left: 1rem;border-radius: 20px;">
        <textarea matInput formControlName = "reply" class=" text-start form-control" role="textbox" contenteditable=""
        (keyup.enter) = "replySubmit(question.questionId)" style="border-radius: 16px;background: #4272F71A;width: 363px;  min-height: 70px; height: 70px; max-height: 70px;" placeholder="Write..."></textarea>
        <button  *ngIf="!replySending" (click) = "replySubmit(question.questionId)" class="btn clickable" role="button"><i class="fa fa-send" style="color: var(--supplierblue);"></i>
           </button>
           <i *ngIf="replySending" class="spinner-border spinner-border-sm mr-1"></i>
    </div>
</div>
</form>