import { Component, OnInit, AfterViewChecked, ViewChild, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrNotificationService } from '../services/toastr-notification.service';

import { BrowseRfqService } from '../services/browse-rfq.service';
import { RFQ } from '../models/rfq';
import { Observable, of, Subscription, interval, fromEventPattern, pipe } from 'rxjs';
import { switchMap, finalize, first } from 'rxjs/operators';


import { Pipe, PipeTransform } from '@angular/core';
import { FilterPipe } from '../pipes/filter.pipe';
import { PaginationInstance } from 'ngx-pagination';

import { QuestionAndAnswersService } from '../services/question-and-answers.service';
import { RfqService } from '../services/rfq.service';
import { RfqCountSearch, SupplierServiceCount, SupplierServiceRfqs } from '../models/rfqCountSearch';
import { ViewQuoteServiceService } from '../services/view-quote-service.service';
import { ReloadViewRfqs } from '../models/reloadViewRfqs';
import { Account } from '../models/account';
import { AuthService } from '../auth/auth.service';
import { SupplierServices } from '../models/supplierServices';
import { ActivatedRoute, Router } from '@angular/router';
import { SupplierCompanyService } from '../services/supplier-company.service';
import { DefaultSearchParameters } from '../models/deafaultSearchParameters';




@Component({
  selector: 'app-search-rfq',
  templateUrl: './search-rfq.component.html',
  styleUrls: ['./search-rfq.component.css']
})
export class SearchRfqComponent implements OnInit, AfterViewChecked {

  @ViewChild('collapseSearch') collapseSearch: ElementRef;

  private subscription: Subscription;
  rfqs: RFQ[] = [];
  selectedServiceRFQs: RFQ[] = [];
  selectedBuyerRFQs: RFQ[] = [];
  selectedDrawingRFQs: RFQ[] = [];
  selectedPaymentRFQs: RFQ[] = [];
  selectedTypeRFQs: RFQ[] = [];
  finalRFQs: RFQ[] = [];
  rfq: RFQ;
  selectedRFQ: RFQ;
  selectedCount: number;
  isSearched: boolean = false;
  lastService: boolean = false;
  collapseTrigger: string = "";
  dataLoading: boolean = false;

  rfqCount: SupplierServiceCount[] = [];
  rfqCountUpdate: SupplierServiceCount[] = [];

  searchRfqCounts: SupplierServiceRfqs[] = [];
  searchRfqCountsUpdate: SupplierServiceRfqs[] = [];

  drawingsRfqsCount: number;
  dimensionsRfqsCount: number;
  allRfqSpecifcationsCount: number;

  CBDCount: number;
  CODCount: number;
  days30Count: number;
  days60Count: number;
  otherCount: number;
  notSpecifiedCount: number;
  allPaymentPreferenceCount: number;

  BoxedCount: number;
  LooseCount: number;
  PalletCount: number;
  StrappedCount: number;
  otherPackageCount: number;
  allPackagingPreferenceCount: number;

  EcCount: number;
  FsCount: number;
  GaCount: number;
  KznCount: number;
  LimCount: number;
  MpCount: number;
  NcCount: number;
  NwCount: number;
  WcCount: number;
  allLocationPreferenceCount: number;

  completeSupplyCount: number;

  updatedClosed: ReloadViewRfqs;

  form: FormGroup;
  services: number[] = [];
  buyerPreference: string[] = [];
  containDrawings: string = null;
  containDrawCond: boolean;
  paymentPreference: string = null;
  paymentPreferenceCond: boolean;
  rfqType: string = null;
  rfqTypeCond: boolean;
  collapse: string;
  pageOfItems: Array<any>;
  rawMaterialServiceId: number;
  supplierServices: SupplierServices[] = [];

  maxRfqListSize: number = 10;

  //Date Calcs
  public dateNow = new Date();
  public dDay?: Date;
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  paymentTypes: string[] = ['COD', '30 Days', '60 Days', 'CBD', 'Other', 'any']

  serviceNamesCheck: string[] = [];
  serviceNamesCheckUpdate: string[] = [];

  serviceParams: number[] = [];
  serviceParamString: string;
  paymentParam: string;
  drawingParam: string;
  rfqTypeParam: string;
  buyerPreferredParam: string;

  paymentTypeList: string[] = [];
  packagingTypeList: string[] = [];

  currentPage: number = null;
  defaultPage: number = null;
  paged: string = 'F';

  loadDefaultSearchParams: boolean = true;

  supplierConfig: DefaultSearchParameters;

  account: Account;

  constructor(
    private formBuilder: FormBuilder,
    public toastrNotification: ToastrNotificationService,
    private browseRfq: BrowseRfqService,
    private questionAndAnswersService: QuestionAndAnswersService,
    private rfqService: RfqService,
    private reloader: ViewQuoteServiceService,
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private supplierCompanyService: SupplierCompanyService
  ) {

    this.authService.account.subscribe(x => this.account = x);
  }

  ngOnInit() {

    this.form = this.formBuilder.group({

      bending: [false],
      cncMachining: [false],
      deCoilSlit: [false],
      chamfer: [false],
      drilling: [false],
      fabrication: [false],
      profileCutting: [false],
      laserCutting: [false],
      plasmaCutting: [false],
      rolling: [false],
      welding: [false],
      rawMaterials: [false],
      guillotining: [false],

      beeComplient: [false],
      creditGaruntee: [false],
      goldVerified: [false],


      drawYes: [false],
      drawNo: [false],
      drawAny: [false],

      preferredBuyerNo: [false],
      preferredBuyerYes: [false],

      paymentCOD: [false],
      payment30Days: [false],
      payment60Days: [false],
      paymentOther: [false],
      paymentAny: [false],
      paymentCBD: [false],

      packageBoxed: [false],
      packageLoose: [false],
      packagePallet: [false],
      packageStrapped: [false],
      packageOther: [false],
      packageAny: [false],

      provinceEasternCape: [false],
      provinceFreeState: [false],
      provinceGuateng: [false],
      provinceKwaZuluNatal: [false],
      provinceLimpopo: [false],
      provinceMpumalanga: [false],
      provinceNorthenCape: [false],
      provinceNorthWest: [false],
      provinceWesternCape: [false],
      provinceAny: [false],

      payPrefInd: [false],
      payPrefCS: [false],

    });

    this.disableAllServices()

    this.getSupplierServices();

    this.reloader.currentQuoteSubmitted.subscribe(quoteCond => {
      if (quoteCond.reloadCondition) {

        for (let rfq of this.finalRFQs) {
          if (rfq.rfqId == quoteCond.reloadRfqId) {
            rfq.quotedOn = true;
          }
        }
        this.updatedClosed = { reloadCondition: null, reloadRfqId: null };
        this.updatedClosed.reloadCondition = false;
        this.updatedClosed.reloadRfqId = null;
        this.reloader.markQuoteAsSubmitted(this.updatedClosed);
      }
    });

    this.subscription = interval(300000)
      .subscribe(x => { this.reloadSearchButtons(); });

    // this.subscription = interval(1000)
    //        .subscribe(x => { this.getTimeDifference(); });

    this.router.navigate(['supplier-home/search-rfq'], {
      queryParams: {
        PGD: 'F',
      },
      queryParamsHandling: 'merge'
    });

    this.route.queryParamMap.subscribe(params => {

      this.serviceParams = [];

      this.drawingParam = params.get('DD');
      this.rfqTypeParam = params.get('SPLY');
      this.buyerPreferredParam = params.get('PB');

      if (+params.get('Page') >= 1) {
        this.defaultPage = +params.get('Page');
      }

      if (params.get('SV') != null && params.get('PGD') == 'F' && params.get('SRCH') == "Search") {

        var ss = params.get('SV').match(/\d+/g);

        for (let s of ss) {
          this.serviceParams.push(Number(s));
        }

        if (params.get('PT') != null) {

          this.paymentTypeList = [];

          var decodePaymentStr = decodeURIComponent(params.get('PT'));

          for (let paytype of JSON.parse(decodePaymentStr)) {
            this.paymentList(true, paytype);
          }
        }

        if (params.get('PKG') != null) {

          this.packagingTypeList = [];

          var decodePackageStr = decodeURIComponent(params.get('PKG'));

          for (let packageType of JSON.parse(decodePackageStr)) {
            this.packagingList(true, packageType);
          }
        }

        if (params.get('LOC') != null) {

          this.locationTypeList = [];

          var decodelocationStr = decodeURIComponent(params.get('LOC'));

          for (let locationType of JSON.parse(decodelocationStr)) {
            this.locationList(true, locationType);
          }
        }

        // single select for payment preference
        // this.paymentPreferenceCheck(params.get('PT'))

        // if (this.paymentTypes.indexOf(params.get('PT')) >= 0) {

        //   if (params.get('PT') == 'COD') {
        //     this.f.paymentCOD.setValue(true);

        //   } else if (params.get('PT') == '30 Days') {
        //     this.f.payment30Days.setValue(true);

        //   } else if (params.get('PT') == '60 Days') {
        //     this.f.payment60Days.setValue(true);

        //   } else if (params.get('PT') == 'CBD') {
        //     this.f.paymentCBD.setValue(true);

        //   } else if (params.get('PT') == 'Other') {
        //     this.f.paymentOther.setValue(true);

        //   } else if (params.get('PT') == 'any') {
        //     this.f.paymentAny.setValue(true);

        //   }

        // }

        if (this.drawingParam == "Yes" || this.drawingParam == "No" || this.drawingParam == "any") {

          if (this.drawingParam == 'No') {
            this.f.drawNo.setValue(true);
          } else if (this.drawingParam == 'Yes') {
            this.f.drawYes.setValue(true);
          } else if (this.drawingParam == "any") {
            this.f.drawAny.setValue(true);
          }

          this.containDrawingsCheck(this.drawingParam)
        }

        if (this.buyerPreferredParam == "Yes" || this.buyerPreferredParam == "No") {

          if (this.buyerPreferredParam == 'No') {
            this.f.preferredBuyerNo.setValue(true);
          } else if (this.buyerPreferredParam == 'Yes') {
            this.f.preferredBuyerYes.setValue(true);
          }

          this.buyerPreferenceCheck(this.buyerPreferredParam)
        }

        this.isSearched = true;

        this.getSearchRFQS(this.serviceParams, this.account.account.supplierId, this.buyerPreferredParam)

      } else if (params.get('SRCH') == "Favourites") {

        this.isSearched = true;

        this.getStarredRfqs();

      } else if (params.get('SRCH') == "PubChatNav") {

        this.isSearched = true;

        this.dataLoading = true

        this.rfqService.getSingleSearchRfq(+params.get('RWPC'), this.account.account.userId, this.account.account.supplierId).pipe(first())
          .subscribe({
            next: (response: any) => {
              this.finalRFQs = [];
              this.finalRFQs.push(response);
              this.dataLoading = false;
            },
            error: error => {
              this.toastrNotification.error("Get Search Rfq Failed");
              this.dataLoading = false;
            }
          })
      } else if (params.get('SRCH') == "Default") {
        this.getDefaultSearchParams();
        this.loadDefaultSearchParams = false;
      }
    });

  }

  getDefaultSearchParams() {

    if (this.loadDefaultSearchParams) {
      this.supplierCompanyService.getSupplierConfiguration(this.account.account.supplierId).pipe(first())
        .subscribe({
          next: (response: any) => {
            this.paymentTypeList = [];
            this.packagingTypeList = [];
            this.locationTypeList = [];
            console.log(response);
            this.supplierConfig = response;
            this.loadDefaultSearchParams = false;

            if (this.supplierConfig.deliveryRequirements.length > 0) {
              for (let delivery of this.supplierConfig.deliveryRequirements) {
                this.locationList(true, delivery)
              }
            }

            if (this.supplierConfig.paymentRequirements.length > 0) {
              for (let payment of this.supplierConfig.paymentRequirements) {
                this.paymentList(true, payment)
              }
            }

            if (this.supplierConfig.packagingRequirements.length > 0) {
              for (let packaging of this.supplierConfig.packagingRequirements) {
                this.packagingList(true, packaging)
              }
            }

            if (this.supplierConfig.partnershipRequirements.length > 0) {
              for (let partnership of this.supplierConfig.partnershipRequirements) {
                if (partnership == "Preferred Buyers") {
                  this.f.preferredBuyerYes.setValue(true);
                  this.buyerPreferenceCheck("Yes");
                }

              }
            }


          },
          error: error => {
            this.toastrNotification.error("Get default search parameters failed");

          }
        });
    }
  }

  setCurrentPage(value: number) {

    this.currentPage = value;

    this.router.navigate(['supplier-home/search-rfq'], {
      queryParams: {
        Page: this.currentPage
      },
      queryParamsHandling: 'merge'
    });


  }

  navToFavourites() {

    this.router.navigate(['supplier-home/search-rfq'], {
      queryParams: {
        SRCH: "Favourites"
      },
    });
  }

  setPagingActive(value: string) {

    this.router.navigate(['supplier-home/search-rfq'], {
      queryParams: {
        PGD: value,
      },
      queryParamsHandling: 'merge'
    });

  }

  removeSelectedRfq(rfqId: number) {

    for (const { index, value } of this.pageOfItems.map((value, index) => ({ index, value }))) {
      if (value.rfqId == rfqId) {
        this.pageOfItems.splice(index, 1);
      }
    }

    for (const { index, value } of this.finalRFQs.map((value, index) => ({ index, value }))) {
      if (value.rfqId == rfqId) {
        this.finalRFQs.splice(index, 1);
      }
    }

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  reloadSearchButtons() {
    this.getRfqCountUpdate(this.account.account.supplierId);
  }

  supplierServicesValid: boolean = true;

  getSupplierServices() {
    this.supplierServicesValid = true;
    this.rfqService.getSupplierServices().pipe(first())
      .subscribe({
        next: (response: any) => {
          this.supplierServices = response;
          for (let service of response) {
            if (service.serviceName == "Raw Materials") {
              this.rawMaterialServiceId = service.supplierServiceId;
            }
          }
          this.getRfqCount(this.account.account.supplierId);

        },
        error: error => {
          this.toastrNotification.error("Get Supplier Services Failed");
          this.supplierServicesValid = false;
        }
      });
  }

  disableAllServices() {
    this.f.rolling.disable();
    this.f.laserCutting.disable();
    this.f.deCoilSlit.disable();
    this.f.drilling.disable();
    this.f.chamfer.disable();
    this.f.cncMachining.disable();
    this.f.fabrication.disable();
    this.f.profileCutting.disable();
    this.f.welding.disable();
    this.f.bending.disable();
    this.f.plasmaCutting.disable();
    this.f.payPrefInd.disable();
    this.f.guillotining.disable();
  }

  ngAfterViewChecked() {

  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  private getTimeDifference() {
    this.timeDifference = this.dDay.getTime() - new Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
  }

  public getClosingTime(date) {
    this.dDay = new Date(date);
    this.getTimeDifference();
    if (this.daysToDday < 0) {
      return 'Closed';
    } else {
      return this.daysToDday + ' d ' + this.hoursToDday + ' hrs';
    }

  }

  private allocateTimeUnits(timeDifference) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }

  rfqCountValid: boolean = true;

  getRfqCount(supplierId) {

    this.rfqService.getRfqSupplierServiceCount(supplierId).pipe(first())
      .subscribe({
        next: (response: any) => {
          this.rfqCountValid = true;
          this.rfqCount = response.serviceCountRfqs;
          this.searchRfqCounts = response.rfqs;
          // console.log(response)

          this.completeSupplyCount = this.searchRfqCounts.filter(rfq => !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;

          this.dimensionsRfqsCount = this.searchRfqCounts.filter(rfq => rfq.drawingsOrDimension === "Dimensions").length;
          this.drawingsRfqsCount = this.searchRfqCounts.filter(rfq => rfq.drawingsOrDimension === "Drawings").length;
          this.CBDCount = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "CBD").length;
          this.CODCount = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "COD").length;
          this.days30Count = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "30 Days").length;
          this.days60Count = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "60 Days").length;
          this.otherCount = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "Other").length;
          this.notSpecifiedCount = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "Not Specified").length;

          this.BoxedCount = this.searchRfqCounts.filter(rfq => rfq.packagingRequirement === "Boxed").length;
          this.LooseCount = this.searchRfqCounts.filter(rfq => rfq.packagingRequirement === "Loose").length;
          this.PalletCount = this.searchRfqCounts.filter(rfq => rfq.packagingRequirement === "Pallet").length;
          this.StrappedCount = this.searchRfqCounts.filter(rfq => rfq.packagingRequirement === "Strapped").length;
          this.otherPackageCount = this.searchRfqCounts.filter(rfq => rfq.packagingRequirement === "Other").length;

          this.EcCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Eastern Cape").length;
          this.FsCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Free State").length;
          this.GaCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Gauteng").length;
          this.KznCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "KwaZulu-Natal").length;
          this.LimCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Limpopo").length;
          this.MpCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Mpumalanga").length;
          this.NcCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Northern Cape").length;
          this.NwCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "North West").length;
          this.WcCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Western Cape").length;

          this.allPaymentPreferenceCount = this.CBDCount + this.CODCount + this.days30Count + this.days60Count + this.otherCount + this.notSpecifiedCount;
          this.allRfqSpecifcationsCount = this.dimensionsRfqsCount + this.drawingsRfqsCount;

          //CHECKS IF THERE ARE RFQS FOR THESE SERVICES AND DISABLES BUTTONS IF NOT
          for (let count of this.rfqCount) {

            this.serviceNamesCheck.push(count.serviceName);
          }

          this.serviceEnableToggle();

          if (this.rfqTypeParam == "Complete" || this.rfqTypeParam == "Raw Material") {

            if (this.rfqTypeParam == "Complete") {
              this.f.payPrefCS.setValue(true);
            } else if (this.rfqTypeParam == "Raw Material") {
              this.f.payPrefInd.setValue(true);
            }

            this.rfqTypeList(this.rfqTypeParam);
          }

          for (let serviceId of this.serviceParams) {
            var service = this.supplierServices.filter(x => x.supplierServiceId == serviceId)
            this.serviceList(true, service[0].serviceName)
          }

        },
        error: error => {
          this.toastrNotification.error("Get Rfq Counts Failed");
          this.rfqCountValid = false;
        }
      });

  }

  updatePayAndDrawButtonsCount() {

    if (this.services.length > 0) {

      this.dimensionsRfqsCount = this.searchRfqCounts.filter(rfq => rfq.drawingsOrDimension === "Dimensions" && this.services.some(r => rfq.rfqServiceIds.includes(r))).length;
      this.drawingsRfqsCount = this.searchRfqCounts.filter(rfq => rfq.drawingsOrDimension === "Drawings" && this.services.some(r => rfq.rfqServiceIds.includes(r))).length;
      this.CBDCount = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "CBD" && this.services.some(r => rfq.rfqServiceIds.includes(r))).length;
      this.CODCount = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "COD" && this.services.some(r => rfq.rfqServiceIds.includes(r))).length;
      this.days30Count = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "30 Days" && this.services.some(r => rfq.rfqServiceIds.includes(r))).length;
      this.days60Count = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "60 Days" && this.services.some(r => rfq.rfqServiceIds.includes(r))).length;
      this.otherCount = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "Other" && this.services.some(r => rfq.rfqServiceIds.includes(r))).length;
      this.notSpecifiedCount = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "Not Specified" && this.services.some(r => rfq.rfqServiceIds.includes(r))).length;

      this.BoxedCount = this.searchRfqCounts.filter(rfq => rfq.packagingRequirement === "Boxed" && this.services.some(r => rfq.rfqServiceIds.includes(r))).length;
      this.LooseCount = this.searchRfqCounts.filter(rfq => rfq.packagingRequirement === "Loose" && this.services.some(r => rfq.rfqServiceIds.includes(r))).length;
      this.PalletCount = this.searchRfqCounts.filter(rfq => rfq.packagingRequirement === "Pallet" && this.services.some(r => rfq.rfqServiceIds.includes(r))).length;
      this.StrappedCount = this.searchRfqCounts.filter(rfq => rfq.packagingRequirement === "Strapped" && this.services.some(r => rfq.rfqServiceIds.includes(r))).length;
      this.otherPackageCount = this.searchRfqCounts.filter(rfq => rfq.packagingRequirement === "Other" && this.services.some(r => rfq.rfqServiceIds.includes(r))).length;

      this.EcCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Eastern Cape" && this.services.some(r => rfq.rfqServiceIds.includes(r))).length;
      this.FsCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Free State" && this.services.some(r => rfq.rfqServiceIds.includes(r))).length;
      this.GaCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Gauteng" && this.services.some(r => rfq.rfqServiceIds.includes(r))).length;
      this.KznCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "KwaZulu-Natal" && this.services.some(r => rfq.rfqServiceIds.includes(r))).length;
      this.LimCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Limpopo" && this.services.some(r => rfq.rfqServiceIds.includes(r))).length;
      this.MpCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Mpumalanga" && this.services.some(r => rfq.rfqServiceIds.includes(r))).length;
      this.NcCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Northern Cape" && this.services.some(r => rfq.rfqServiceIds.includes(r))).length;
      this.NwCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "North West" && this.services.some(r => rfq.rfqServiceIds.includes(r))).length;
      this.WcCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Western Cape" && this.services.some(r => rfq.rfqServiceIds.includes(r))).length;

    } else if (this.services.length == 0 && this.rfqType == null) {

      this.dimensionsRfqsCount = this.searchRfqCounts.filter(rfq => rfq.drawingsOrDimension === "Dimensions").length;
      this.drawingsRfqsCount = this.searchRfqCounts.filter(rfq => rfq.drawingsOrDimension === "Drawings").length;
      this.CBDCount = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "CBD").length;
      this.CODCount = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "COD").length;
      this.days30Count = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "30 Days").length;
      this.days60Count = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "60 Days").length;
      this.otherCount = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "Other").length;
      this.notSpecifiedCount = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "Not Specified").length;

      this.BoxedCount = this.searchRfqCounts.filter(rfq => rfq.packagingRequirement === "Boxed").length;
      this.LooseCount = this.searchRfqCounts.filter(rfq => rfq.packagingRequirement === "Loose").length;
      this.PalletCount = this.searchRfqCounts.filter(rfq => rfq.packagingRequirement === "Pallet").length;
      this.StrappedCount = this.searchRfqCounts.filter(rfq => rfq.packagingRequirement === "Strapped").length;
      this.otherPackageCount = this.searchRfqCounts.filter(rfq => rfq.packagingRequirement === "Other").length;

      this.EcCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Eastern Cape").length;
      this.FsCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Free State").length;
      this.GaCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Gauteng").length;
      this.KznCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "KwaZulu-Natal").length;
      this.LimCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Limpopo").length;
      this.MpCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Mpumalanga").length;
      this.NcCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Northern Cape").length;
      this.NwCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "North West").length;
      this.WcCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Western Cape").length;

    } else if (this.services.length == 0 && this.rfqType == "Complete") {

      this.dimensionsRfqsCount = this.searchRfqCounts.filter(rfq => rfq.drawingsOrDimension === "Dimensions" && !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;
      this.drawingsRfqsCount = this.searchRfqCounts.filter(rfq => rfq.drawingsOrDimension === "Drawings" && !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;
      this.CBDCount = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "CBD" && !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;
      this.CODCount = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "COD" && !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;
      this.days30Count = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "30 Days" && !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;
      this.days60Count = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "60 Days" && !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;
      this.otherCount = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "Other" && !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;
      this.notSpecifiedCount = this.searchRfqCounts.filter(rfq => rfq.paymentRequirement === "Not Specified" && !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;

      this.BoxedCount = this.searchRfqCounts.filter(rfq => rfq.packagingRequirement === "Boxed" && !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;
      this.LooseCount = this.searchRfqCounts.filter(rfq => rfq.packagingRequirement === "Loose" && !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;
      this.PalletCount = this.searchRfqCounts.filter(rfq => rfq.packagingRequirement === "Pallet" && !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;
      this.StrappedCount = this.searchRfqCounts.filter(rfq => rfq.packagingRequirement === "Strapped" && !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;
      this.otherPackageCount = this.searchRfqCounts.filter(rfq => rfq.packagingRequirement === "Other" && !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;

      this.EcCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Eastern Cape" && !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;
      this.FsCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Free State" && !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;
      this.GaCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Gauteng" && !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;
      this.KznCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "KwaZulu-Natal" && !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;
      this.LimCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Limpopo" && !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;
      this.MpCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Mpumalanga" && !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;
      this.NcCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Northern Cape" && !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;
      this.NwCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "North West" && !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;
      this.WcCount = this.searchRfqCounts.filter(rfq => rfq.supplierLocationRequirement === "Western Cape" && !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;

    }

    this.allPaymentPreferenceCount = this.CBDCount + this.CODCount + this.days30Count + this.days60Count + this.otherCount + this.notSpecifiedCount;
    this.allRfqSpecifcationsCount = this.dimensionsRfqsCount + this.drawingsRfqsCount;
    this.completeSupplyCount = this.searchRfqCounts.filter(rfq => !rfq.rfqServiceIds.includes(this.rawMaterialServiceId)).length;
  }

  rfqCountUpdateValid: boolean = true;

  getRfqCountUpdate(supplierId) {

    this.serviceNamesCheckUpdate = [];

    var oldRfqIds = [];
    var newRfqIds = [];

    this.rfqService.getRfqSupplierServiceCount(supplierId).pipe(first())
      .subscribe({

        next: (response: any) => {
          this.rfqCountUpdateValid = true;
          this.supplierServicesValid = true;
          this.rfqCountValid = true;

          this.rfqCountUpdate = response.serviceCountRfqs;
          this.rfqCount = response.serviceCountRfqs;
          this.searchRfqCountsUpdate = response.rfqs;

          // this.serviceEnableToggle();

          if (this.searchRfqCountsUpdate.length > 0) {

            if (this.searchRfqCounts.length > 0) {
              for (let rfq of this.searchRfqCounts) {
                oldRfqIds.push(rfq.rfqId);
              }

              for (let rfq of this.searchRfqCountsUpdate) {
                newRfqIds.push(rfq.rfqId);
              }

              for (let rfq of this.searchRfqCountsUpdate) {
                if (oldRfqIds.indexOf(rfq.rfqId) == -1) {
                  this.searchRfqCounts.push(rfq);
                }
              }

              for (const { index, value } of this.searchRfqCounts.map((value, index) => ({ index, value }))) {
                if (newRfqIds.indexOf(value.rfqId) == -1) {
                  this.searchRfqCounts.splice(index, 1);
                }
              }

              for (const { index, value } of this.searchRfqCounts.map((value, index) => ({ index, value }))) {
                for (let rfq of this.searchRfqCountsUpdate) {
                  if (value.rfqId == rfq.rfqId) {
                    this.searchRfqCounts[index] = rfq;
                  }
                }
              }
            } else {
              for (let rfq of this.searchRfqCountsUpdate) {
                this.searchRfqCounts.push(rfq);
              }
            }

          } else {
            this.searchRfqCounts = [];
          }

          this.updatePayAndDrawButtonsCount();

          if (this.rfqCount.length > 0) {
            for (let count of this.rfqCount) {

              this.serviceNamesCheckUpdate.push(count.serviceName);
            }

            for (let service of this.serviceNamesCheckUpdate) {
              if (this.serviceNamesCheck.indexOf(service) == -1) {
                this.serviceNamesCheck.push(service);
              }
            }

            for (const { index, value } of this.serviceNamesCheck.map((value, index) => ({ index, value }))) {
              if (this.serviceNamesCheckUpdate.indexOf(value) == -1) {
                this.serviceNamesCheck.splice(index, 1);
              }
            }
          } else {
            this.serviceNamesCheck = [];
          }

          this.serviceEnableToggle();
        },
        error: error => {
          this.toastrNotification.error("Update Rfq Count Failed");
          this.rfqCountUpdateValid = false;
        }
      })

  }

  serviceEnableToggleUpdate() {

  }

  serviceEnableToggle() {

    if (this.serviceNamesCheck.includes("Rolling")) {

      this.f.rolling.enable();
    } else {
      this.f.rolling.disable();
    }
    if (this.serviceNamesCheck.includes("Laser Cutting")) {

      this.f.laserCutting.enable();
    } else {
      this.f.laserCutting.disable();
    }

    if (this.serviceNamesCheck.includes("Decoiling And Slitting")) {

      this.f.deCoilSlit.enable();
    } else {
      this.f.deCoilSlit.disable();
    }

    if (this.serviceNamesCheck.includes("Drilling")) {

      this.f.drilling.enable();
    } else {
      this.f.drilling.disable();
    }

    if (this.serviceNamesCheck.includes("Chamfer")) {

      this.f.chamfer.enable();
    } else {
      this.f.chamfer.disable();
    }

    if (this.serviceNamesCheck.includes("CNC Machining")) {

      this.f.cncMachining.enable();
    } else {
      this.f.cncMachining.disable();
    }

    if (this.serviceNamesCheck.includes("Fabrication")) {

      this.f.fabrication.enable();
    } else {
      this.f.fabrication.disable();
    }

    if (this.serviceNamesCheck.includes("Profile Cutting")) {

      this.f.profileCutting.enable();
    } else {
      this.f.profileCutting.disable();
    }


    if (this.serviceNamesCheck.includes("Welding")) {

      this.f.welding.enable();
    } else {
      this.f.welding.disable();
    }


    if (this.serviceNamesCheck.includes("Bending")) {

      this.f.bending.enable();
    } else {
      this.f.bending.disable();
    }


    if (this.serviceNamesCheck.includes("Plasma Cutting")) {

      this.f.plasmaCutting.enable();
    } else {
      this.f.plasmaCutting.disable();
    }

    if (this.serviceNamesCheck.includes("Raw Materials")) {

      this.f.payPrefInd.enable();
    } else {
      this.f.payPrefInd.disable();
    }

    if (this.serviceNamesCheck.includes("Guillotining")) {

      this.f.guillotining.enable();
    } else {
      this.f.guillotining.disable();
    }

  }

  onSelectRFQ(rfq: RFQ): void {
    this.selectedRFQ = rfq;

  }

  searchCollapse() {
    if (this.isSearched == true) {
      return "search collapse  ";
    } else {
      return " collapse show";
    }

  }

  onSearch() {

    this.isSearched = true;

    if (this.services.length == 0) {
      this.toastrNotification.error('At least 1 Supplier Service must be selected');
      this.isSearched = false;
    }
    else {

      this.serviceParamString = "";

      for (let service of this.services) {
        this.serviceParamString += "S-" + service + " ";
      }


      var paymentStr = "";
      if (this.paymentTypeList.length > 0) {
        paymentStr = encodeURIComponent(JSON.stringify(this.paymentTypeList));
      } else {
        paymentStr = null;
      }

      var packageStr = "";
      if (this.packagingTypeList.length > 0) {
        packageStr = encodeURIComponent(JSON.stringify(this.packagingTypeList));
      } else {
        packageStr = null;
      }

      var locationeStr = "";
      if (this.locationTypeList.length > 0) {
        locationeStr = encodeURIComponent(JSON.stringify(this.locationTypeList));
      } else {
        locationeStr = null;
      }

      this.defaultPage = 1;

      this.router.navigate(['supplier-home/search-rfq'], {
        queryParams: {
          SRCH: 'Search',
          SV: this.serviceParamString,
          PT: paymentStr,
          PKG: packageStr,
          LOC: locationeStr,
          SPLY: this.rfqType,
          DD: this.containDrawings,
          PB: this.preferredBuyerCheck,
          PGD: 'F',
          Page: 1
        },
        queryParamsHandling: 'merge'
      });

    }
  }

  SearchResultsFilter(arrayToSearch) {

    this.finalRFQs = [];

    for (let rfq of arrayToSearch) {
      this.finalRFQs.push(rfq)
    }

    if (this.rfqType == "Complete" || this.rfqType == "Raw Material") {
      this.finalRFQs = this.finalRFQs.filter(rfq => rfq.typeOfSupply == this.rfqType)
    }

    if (this.containDrawings == 'Yes') {
      this.finalRFQs = this.finalRFQs.filter(rfq => rfq.rfqFiles.length > 0)
    }

    if (this.containDrawings == 'No') {
      this.finalRFQs = this.finalRFQs.filter(rfq => rfq.rfqFiles.length == 0)
    }

    if (this.paymentTypeList.length > 0 && !this.paymentTypeList.includes("any")) {
      this.finalRFQs = this.finalRFQs.filter(rfq => this.paymentTypeList.includes(rfq.paymentRequirement))
    }

    if (this.packagingTypeList.length > 0 && !this.packagingTypeList.includes("any")) {
      this.finalRFQs = this.finalRFQs.filter(rfq => rfq.packagingRequirements != null).filter(rfq => this.packagingTypeList.includes(rfq.packagingRequirements))
    }

    if (this.locationTypeList.length > 0 && !this.locationTypeList.includes("any")) {
      this.finalRFQs = this.finalRFQs.filter(rfq => rfq.supplierLocationRequirements != null).filter(rfq => this.locationTypeList.includes(rfq.supplierLocationRequirements))
    }

  }

  clearSearch() {
    this.finalRFQs = [];
    this.selectedServiceRFQs = [];
    this.selectedBuyerRFQs = [];
    this.selectedDrawingRFQs = [];
    this.selectedPaymentRFQs = [];
    this.selectedTypeRFQs = [];
  }

  clearAll() {

    this.isSearched = false;

    Object.keys(this.form.controls).forEach(key => {
      this.form.controls[key].setValue(false);
    });

    this.services.splice(0, this.services.length);
    this.buyerPreference.splice(0, this.buyerPreference.length);
    this.paymentTypeList = [];
    this.packagingTypeList = [];
    this.locationTypeList = [];
    this.containDrawings = null;
    this.containDrawCond = false;
    this.paymentPreference = null;
    this.paymentPreferenceCond = false;
    this.rfqType = null;
    this.rfqTypeCond = false;
    this.serviceEnableToggle()
    this.clearSearch();

    this.updatePayAndDrawButtonsCount();

    this.router.navigate(['supplier-home/search-rfq']);

  }

  getSearchRFQS(services, supplierId, buyerPreferrence) {

    this.dataLoading = true

    this.rfqService.getSearchedRfqs(services, supplierId, this.account.account.userId, buyerPreferrence).pipe(
      finalize(() => this.dataLoading = false)
    ).subscribe(rfqs => {
      this.selectedServiceRFQs = rfqs;
      this.selectedServiceRFQs = this.selectedServiceRFQs.filter(rfq => rfq.quotedOn === false);
      this.SearchResultsFilter(this.selectedServiceRFQs);
    });

  }

  getStarredRfqs() {

    this.isSearched = true;

    Object.keys(this.form.controls).forEach(key => {
      this.form.controls[key].setValue(false);
    });

    this.services.splice(0, this.services.length);
    this.buyerPreference.splice(0, this.buyerPreference.length);
    this.paymentTypeList = [];
    this.packagingTypeList = [];
    this.locationTypeList = [];
    this.containDrawings = null;
    this.containDrawCond = false;
    this.paymentPreference = null;
    this.paymentPreferenceCond = false;
    this.rfqType = null;
    this.rfqTypeCond = false;
    this.serviceEnableToggle()
    this.clearSearch();

    this.dataLoading = true

    this.rfqService.getStarredRfqs(this.account.account.supplierId, this.account.account.userId).pipe(
      finalize(() => this.dataLoading = false)
    ).subscribe(rfqs => {
      this.finalRFQs = rfqs;
      this.finalRFQs = this.finalRFQs.filter(rfq => rfq.quotedOn === false);

    });
  }

  get f() { return this.form.controls; }

  public showErrorToastrNotification(message) {
    this.toastrNotification.error(message);
  }

  serviceList(cond, serviceName) {

    for (let service of this.supplierServices) {
      if (service.serviceName == serviceName) {
        if (cond && this.services.indexOf(service.supplierServiceId) == -1) {
          this.services.push(service.supplierServiceId);
          this.serviceButtonsToggle(serviceName, cond)
        }
        else if (!cond) {
          this.services.splice(this.services.indexOf(service.supplierServiceId), 1);
          this.serviceButtonsToggle(serviceName, cond)
        };
      }
    }
    this.updatePayAndDrawButtonsCount();
  }

  serviceButtonsToggle(serviceName, cond) {

    if (serviceName == 'Bending') {
      this.f.bending.setValue(cond);
    } else if (serviceName == 'Decoiling And Slitting') {
      this.f.deCoilSlit.setValue(cond);
    } else if (serviceName == 'Chamfer') {
      this.f.chamfer.setValue(cond);
    } else if (serviceName == 'CNC Machining') {
      this.f.cncMachining.setValue(cond);
    } else if (serviceName == 'Drilling') {
      this.f.drilling.setValue(cond);
    } else if (serviceName == 'Fabrication') {
      this.f.fabrication.setValue(cond);
    } else if (serviceName == 'Laser Cutting') {
      this.f.laserCutting.setValue(cond);
    } else if (serviceName == 'Profile Cutting') {
      this.f.profileCutting.setValue(cond);
    } else if (serviceName == 'Plasma Cutting') {
      this.f.plasmaCutting.setValue(cond);
    } else if (serviceName == 'Rolling') {
      this.f.rolling.setValue(cond);
    } else if (serviceName == 'Welding') {
      this.f.welding.setValue(cond);
    } else if (serviceName == 'Guillotining') {
      this.f.guillotining.setValue(cond);
    }
  }


  serviceListDisplay(service) {

    if (this.services.length > 1) {
      this.lastService = false;
      this.services.splice(this.services.indexOf(service), 1);
      if (this.isSearched == true) {
        this.onSearch();
      }

      for (let serviceName of this.supplierServices) {
        if (serviceName.supplierServiceId == service) {
          if (serviceName.serviceName == 'Bending') {
            this.f.bending.setValue(false);
          } else if (serviceName.serviceName == 'Decoiling And Slitting') {
            this.f.deCoilSlit.setValue(false);
          } else if (serviceName.serviceName == 'Chamfer') {
            this.f.chamfer.setValue(false);
          } else if (serviceName.serviceName == 'CNC Machining') {
            this.f.cncMachining.setValue(false);
          } else if (serviceName.serviceName == 'Drilling') {
            this.f.drilling.setValue(false);
          } else if (serviceName.serviceName == 'Fabrication') {
            this.f.fabrication.setValue(false);
          } else if (serviceName.serviceName == 'Laser Cutting') {
            this.f.laserCutting.setValue(false);
          } else if (serviceName.serviceName == 'Profile Cutting') {
            this.f.profileCutting.setValue(false);
          } else if (serviceName.serviceName == 'Plasma Cutting') {
            this.f.plasmaCutting.setValue(false);
          } else if (serviceName.serviceName == 'Rolling') {
            this.f.rolling.setValue(false);
          } else if (serviceName.serviceName == 'Welding') {
            this.f.welding.setValue(false);
          } else if (serviceName.serviceName == 'Guillotining') {
            this.f.guillotining.setValue(false);
          }
        }
      }

    }

    this.updatePayAndDrawButtonsCount();

  }

  paymentList(cond, paymentType) {

    if (this.paymentTypeList.indexOf(paymentType) == -1) {
      this.paymentTypeList.push(paymentType);
      this.paymentButtonsToggle(cond, paymentType);
    } else if (this.paymentTypeList.indexOf(paymentType) != -1) {
      this.paymentTypeList.splice(this.paymentTypeList.indexOf(paymentType), 1);
      this.paymentButtonsToggle(cond, paymentType);
    }

  }

  paymentListDisplay(paymentType) {

    this.paymentTypeList.splice(this.paymentTypeList.indexOf(paymentType), 1);
    this.paymentButtonsToggle(false, paymentType);

    if (this.isSearched == true) {
      this.onSearch();
    }

  }

  paymentButtonsToggle(cond, paymentType) {

    if (paymentType == 'COD') {
      this.f.paymentCOD.setValue(cond);
    } else if (paymentType == '30 Days') {
      this.f.payment30Days.setValue(cond);
    } else if (paymentType == '60 Days') {
      this.f.payment60Days.setValue(cond);
    } else if (paymentType == 'CBD') {
      this.f.paymentCBD.setValue(cond);
    } else if (paymentType == 'Other') {
      this.f.paymentOther.setValue(cond);
    } else if (paymentType == 'any') {
      this.f.paymentAny.setValue(cond);
    }
  }



  packagingList(cond, packageType) {

    if (this.packagingTypeList.indexOf(packageType) == -1) {
      this.packagingTypeList.push(packageType);
      this.packagingButtonsToggle(cond, packageType);
    } else if (this.packagingTypeList.indexOf(packageType) != -1) {
      this.packagingTypeList.splice(this.packagingTypeList.indexOf(packageType), 1);
      this.packagingButtonsToggle(cond, packageType);
    }

  }

  packageListDisplay(packageType) {

    this.packagingTypeList.splice(this.packagingTypeList.indexOf(packageType), 1);
    this.packagingButtonsToggle(false, packageType);

    if (this.isSearched == true) {
      this.onSearch();
    }

  }

  packagingButtonsToggle(cond, packageType) {

    if (packageType == 'Boxed') {
      this.f.packageBoxed.setValue(cond);
    } else if (packageType == 'Loose') {
      this.f.packageLoose.setValue(cond);
    } else if (packageType == 'Pallet') {
      this.f.packagePallet.setValue(cond);
    } else if (packageType == 'Strapped') {
      this.f.packageStrapped.setValue(cond);
    } else if (packageType == 'Other') {
      this.f.packageOther.setValue(cond);
    } else if (packageType == 'any') {
      this.f.packageAny.setValue(cond);
    }
  }

  locationTypeList: string[] = [];

  locationList(cond, location) {

    if (this.locationTypeList.indexOf(location) == -1) {
      this.locationTypeList.push(location);
      this.locationButtonsToggle(cond, location);
    } else if (this.locationTypeList.indexOf(location) != -1) {
      this.locationTypeList.splice(this.locationTypeList.indexOf(location), 1);
      this.locationButtonsToggle(cond, location);
    }

  }

  locationListDisplay(locationType) {

    this.locationTypeList.splice(this.locationTypeList.indexOf(locationType), 1);
    this.locationButtonsToggle(false, locationType);

    if (this.isSearched == true) {
      this.onSearch();
    }

  }

  locationButtonsToggle(cond, location) {

    if (location == 'Eastern Cape') {
      this.f.provinceEasternCape.setValue(cond);
    } else if (location == 'Free State') {
      this.f.provinceFreeState.setValue(cond);
    } else if (location == 'Gauteng') {
      this.f.provinceGuateng.setValue(cond);
    } else if (location == 'KwaZulu-Natal') {
      this.f.provinceKwaZuluNatal.setValue(cond);
    } else if (location == 'Limpopo') {
      this.f.provinceLimpopo.setValue(cond);
    } else if (location == 'Mpumalanga') {
      this.f.provinceMpumalanga.setValue(cond);
    } else if (location == 'Northern Cape') {
      this.f.provinceNorthenCape.setValue(cond);
    } else if (location == 'North West') {
      this.f.provinceNorthWest.setValue(cond);
    } else if (location == 'Western Cape') {
      this.f.provinceWesternCape.setValue(cond);
    } else if (location == 'any') {
      this.f.provinceAny.setValue(cond);
    }
  }

  // single select payment preference
  paymentPreferenceCheck(answer) {
    this.paymentPreference = answer;
    if (!this.f.paymentCOD.value && !this.f.payment30Days.value && !this.f.payment60Days.value && !this.f.paymentOther.value && !this.f.paymentAny.value && !this.f.paymentCBD.value) {
      this.paymentPreference = null;
      this.paymentPreferenceCond = false;
    } else if (this.paymentPreference == 'COD') {
      this.f.paymentCOD.setValue(true);
      this.f.payment60Days.setValue(false);
      this.f.payment30Days.setValue(false);
      this.f.paymentOther.setValue(false);
      this.f.paymentAny.setValue(false);
      this.f.paymentCBD.setValue(false);
      this.paymentPreferenceCond = true;
    } else if (this.paymentPreference == '30 Days') {
      this.f.payment30Days.setValue(true);
      this.f.payment60Days.setValue(false);
      this.f.paymentCOD.setValue(false);
      this.f.paymentOther.setValue(false);
      this.f.paymentAny.setValue(false);
      this.f.paymentCBD.setValue(false);
      this.paymentPreferenceCond = true;
    } else if (this.paymentPreference == '60 Days') {
      this.f.payment60Days.setValue(true);
      this.f.payment30Days.setValue(false);
      this.f.paymentCOD.setValue(false);
      this.f.paymentOther.setValue(false);
      this.f.paymentAny.setValue(false);
      this.f.paymentCBD.setValue(false);
      this.paymentPreferenceCond = true;
    } else if (this.paymentPreference == 'CBD') {
      this.f.paymentCBD.setValue(true);
      this.f.payment30Days.setValue(false);
      this.f.paymentCOD.setValue(false);
      this.f.payment60Days.setValue(false);
      this.f.paymentAny.setValue(false);
      this.f.paymentOther.setValue(false);
      this.paymentPreferenceCond = true;
    } else if (this.paymentPreference == 'Other') {
      this.f.paymentOther.setValue(true);
      this.f.payment30Days.setValue(false);
      this.f.paymentCOD.setValue(false);
      this.f.payment60Days.setValue(false);
      this.f.paymentAny.setValue(false);
      this.f.paymentCBD.setValue(false);
      this.paymentPreferenceCond = true;
    } else if (this.paymentPreference == 'any') {
      this.f.paymentAny.setValue(true);
      this.f.payment30Days.setValue(false);
      this.f.paymentCOD.setValue(false);
      this.f.payment60Days.setValue(false);
      this.f.paymentOther.setValue(false);
      this.f.paymentCBD.setValue(false);
      this.paymentPreferenceCond = true;

    } else if (this.paymentPreference == 'null') {
      this.f.payment30Days.setValue(false);
      this.f.paymentCOD.setValue(false);
      this.f.payment60Days.setValue(false);
      this.f.paymentOther.setValue(false);
      this.f.paymentAny.setValue(false);
      this.f.paymentCBD.setValue(false);
      this.paymentPreferenceCond = false;
    }

  }

  // single select payment preference
  paymentPreferenceDisplay() {
    this.paymentPreference = null;
    this.f.payment60Days.setValue(false);
    this.f.payment30Days.setValue(false);
    this.f.paymentOther.setValue(false);
    this.f.paymentCOD.setValue(false);
    this.f.paymentAny.setValue(false);
    this.paymentPreferenceCond = false;
    if (this.isSearched == true) {
      this.onSearch();
    }
  }

  clearServiceSelectForm() {
    this.f.bending.setValue(false);

    this.f.deCoilSlit.setValue(false);

    this.f.chamfer.setValue(false);

    this.f.cncMachining.setValue(false);

    this.f.drilling.setValue(false);

    this.f.fabrication.setValue(false);

    this.f.laserCutting.setValue(false);

    this.f.profileCutting.setValue(false);

    this.f.plasmaCutting.setValue(false);

    this.f.rolling.setValue(false);

    this.f.welding.setValue(false);

    this.f.guillotining.setValue(false);

  }

  preferredBuyerCheck: string = null;
  preferredBuyerCond: boolean;

  buyerPreferenceCheck(answer) {

    this.preferredBuyerCheck = answer;

    if (!this.f.preferredBuyerYes.value && !this.f.preferredBuyerNo.value) {
      this.preferredBuyerCheck = null;
      this.preferredBuyerCond = false;
    } else if (this.preferredBuyerCheck == 'No') {
      this.f.preferredBuyerNo.setValue(true);
      this.f.preferredBuyerYes.setValue(false);
      this.preferredBuyerCond = true;
    } else if (this.preferredBuyerCheck == 'Yes') {
      this.f.preferredBuyerYes.setValue(true);
      this.f.preferredBuyerNo.setValue(false);
      this.preferredBuyerCond = true;
    }
  }

  buyerPreferenceListDisplay() {

    this.preferredBuyerCheck = null;
    this.f.preferredBuyerNo.setValue(false);
    this.f.preferredBuyerYes.setValue(false);
    this.preferredBuyerCond = false;
    if (this.isSearched == true) {
      this.onSearch();
    }

  }

  containDrawingsCheck(answer) {

    this.containDrawings = answer;

    if (!this.f.drawYes.value && !this.f.drawNo.value && !this.f.drawAny.value) {
      this.containDrawings = null;
      this.containDrawCond = false;
    } else if (this.containDrawings == 'No') {
      this.f.drawNo.setValue(true);
      this.f.drawYes.setValue(false);
      this.f.drawAny.setValue(false);
      this.containDrawCond = true;
    } else if (this.containDrawings == 'Yes') {
      this.f.drawYes.setValue(true);
      this.f.drawNo.setValue(false);
      this.f.drawAny.setValue(false);
      this.containDrawCond = true;
    } else if (this.containDrawings == 'null') {
      this.f.drawNo.setValue(false);
      this.f.drawYes.setValue(false);
      this.f.drawAny.setValue(false);
      this.containDrawCond = false;
    } else if (this.containDrawings == "any") {
      this.f.drawAny.setValue(true);
      this.f.drawNo.setValue(false);
      this.f.drawYes.setValue(false);
      this.containDrawCond = true;
    }

  }

  containDrawingsDisplay() {
    this.containDrawings = null;
    this.f.drawNo.setValue(false);
    this.f.drawYes.setValue(false);
    this.f.drawAny.setValue(false);
    this.containDrawCond = false;
    if (this.isSearched == true) {
      this.onSearch();
    }
  }

  rfqTypeList(answer) {

    this.rfqType = answer;
    if (!this.f.payPrefCS.value && !this.f.payPrefInd.value) {
      this.serviceEnableToggle()
      if (this.services.indexOf(this.rawMaterialServiceId) != -1) {
        this.services.splice(this.services.indexOf(this.rawMaterialServiceId), 1);
      }
      // this.clearServiceSelectForm()
      this.rfqType = null;
      this.rfqTypeCond = false
    } else if (answer == 'Complete') {
      this.serviceEnableToggle();
      if (this.services.indexOf(this.rawMaterialServiceId) != -1) {
        this.services.splice(this.services.indexOf(this.rawMaterialServiceId), 1);
      }
      // this.clearServiceSelectForm()
      this.f.payPrefCS.setValue(true);
      this.f.payPrefInd.setValue(false);
      this.rfqTypeCond = true;
    } else if (answer == 'Raw Material') {
      this.disableAllServices();
      this.f.payPrefInd.enable();
      this.services.splice(0, this.services.length);
      this.clearServiceSelectForm()
      this.services.push(this.rawMaterialServiceId);
      this.f.payPrefCS.setValue(false);
      this.f.payPrefInd.setValue(true);
      this.rfqTypeCond = true;
    }

    this.updatePayAndDrawButtonsCount();

    // if (cond && this.rfqType.indexOf(answer) == -1) {
    //   this.rfqType.push(answer);

    // }
    // else if (!cond) {
    //   this.rfqType.splice(this.rfqType.indexOf(answer), 1);
    // };

  }

  rfqListDisplay() {

    this.rfqType = null;
    this.f.payPrefCS.setValue(false);
    this.f.payPrefInd.setValue(false);
    this.rfqTypeCond = false;
    if (this.isSearched == true) {
      this.onSearch();
    }

    this.updatePayAndDrawButtonsCount();

    // this.rfqType.splice(this.rfqType.indexOf(answer), 1);

    // if (answer == 'Complete Supply') {
    //   this.f.payPrefCS.setValue(false);
    // } else if (answer == 'Raw Material') {
    //   this.f.payPrefInd.setValue(false);
    // }
    // if (this.isSearched == true) {
    //   this.onSearch();
    // }

  }

  //   ngOnDestroy() {
  //     this.subscription.unsubscribe();
  //  }


  getBuyerPreferenceRFQs(toSearchArray) {
    for (let value of toSearchArray) {
      for (let requiredBuyerPreference of value.buyerPreference) {
        for (let buyerPreference of this.buyerPreference) {
          if (requiredBuyerPreference == buyerPreference) {
            const test = this.selectedBuyerRFQs.indexOf(value);
            if (test == -1) {
              this.selectedBuyerRFQs.push(value);
            }

          }

        }
      }
    }
  }

  getCointainsDrawings(toSearchArray) {
    for (let value of toSearchArray) {
      if (this.containDrawings == 'Yes' && value.rfqFiles.length > 0) {
        this.selectedDrawingRFQs.push(value);
      } else if (this.containDrawings == 'No' && value.rfqFiles.length == 0) {
        this.selectedDrawingRFQs.push(value);
      } else if (this.containDrawings == 'any' || this.containDrawings == null) {
        this.selectedDrawingRFQs.push(value);
      }


    }

  }

  getPaymentPreference(toSearchArray) {

    for (let value of toSearchArray) {
      if (this.paymentPreference != null && this.paymentPreference == value.paymentRequirement) {
        this.selectedPaymentRFQs.push(value);
      } else if (this.paymentPreference == "any" || this.paymentPreference == null) {
        this.selectedPaymentRFQs.push(value);
      }




    }

  }

  getRfqType(toSearchArray) {

    for (let value of toSearchArray) {

      if (this.rfqType != null && this.rfqType == value.typeOfSupply) {
        this.selectedTypeRFQs.push(value);
      }

    }
  }

  nonSequencialSearch() {



    if (this.buyerPreference.length != 0) {
      this.getBuyerPreferenceRFQs(this.selectedServiceRFQs);
      if (this.containDrawings != null) {
        this.getCointainsDrawings(this.selectedBuyerRFQs);
        if (this.paymentPreference != null) {
          this.getPaymentPreference(this.selectedDrawingRFQs);
          if (this.rfqType != null) {
            this.getRfqType(this.selectedPaymentRFQs);
            this.finalRFQs = this.selectedTypeRFQs;
          } else {
            this.finalRFQs = this.selectedPaymentRFQs;
          }
        } else {
          if (this.rfqType != null) {
            this.getRfqType(this.selectedDrawingRFQs);
            this.finalRFQs = this.selectedTypeRFQs;
          } else {
            this.finalRFQs = this.selectedDrawingRFQs;
          }
        }
      } else {
        if (this.paymentPreference != null) {
          this.getPaymentPreference(this.selectedBuyerRFQs);
          if (this.rfqType != null) {
            this.getRfqType(this.selectedPaymentRFQs);
            this.finalRFQs = this.selectedTypeRFQs;
          } else {
            this.finalRFQs = this.selectedPaymentRFQs;
          }
        } else {
          if (this.rfqType != null) {
            this.getRfqType(this.selectedBuyerRFQs);
            this.finalRFQs = this.selectedTypeRFQs;
          } else {
            this.finalRFQs = this.selectedBuyerRFQs;
          }
        }
      }
    } else {
      if (this.containDrawings != null) {
        this.getCointainsDrawings(this.selectedServiceRFQs);
        if (this.paymentPreference != null) {
          this.getPaymentPreference(this.selectedDrawingRFQs);
          if (this.rfqType != null) {
            this.getRfqType(this.selectedPaymentRFQs);
            this.finalRFQs = this.selectedTypeRFQs;
          } else {
            this.finalRFQs = this.selectedPaymentRFQs;
          }
        } else {
          if (this.rfqType != null) {
            this.getRfqType(this.selectedDrawingRFQs);
            this.finalRFQs = this.selectedTypeRFQs;
          } else {
            this.finalRFQs = this.selectedDrawingRFQs;
          }
        }
      } else {
        if (this.paymentPreference != null) {
          this.getPaymentPreference(this.selectedServiceRFQs);
          if (this.rfqType != null) {
            this.getRfqType(this.selectedPaymentRFQs);
            this.finalRFQs = this.selectedTypeRFQs;
          } else {
            this.finalRFQs = this.selectedPaymentRFQs;
          }
        } else {
          if (this.rfqType != null) {
            this.getRfqType(this.selectedServiceRFQs);
            this.finalRFQs = this.selectedTypeRFQs;
          } else {
            this.finalRFQs = this.selectedServiceRFQs;
          }
        }
      }

    }
    //  } else if(this.buyerPreference.length == 0 && this.containDrawings != null){
    //    this.getCointainsDrawings(this.selectedServiceRFQs);
    //  }

  }



}
