<section class="searchsection" style="padding-bottom: 2rem; background: var(--supplierbg)">
    <main class="main" style="padding: 0; padding-top: 2rem; padding-bottom: 2rem">

        <div>
            <h1 class="text-center bigheading" style="color: #0e0e82">Browse RFQs</h1>
        </div>

    </main>
    <div class="container-sm RFQFORM">
        <toastr-notification></toastr-notification>
        <div class="row formrow">
            <div class="col-lg-10 col-xxl-6 offset-lg-1 offset-xxl-3" style="padding-bottom: 0.5rem">
                <div class="row" style="border-radius: 0px; border-bottom: 1px solid var(--mainblue)">

                    <div class="
              col
              d-flex d-md-flex d-lg-flex d-xl-flex
              align-items-center align-items-md-center align-items-lg-center
              justify-content-xl-start
              align-items-xl-center
            ">
                        <h1 class="text-nowrap blueheadsmall">Refine search criteria</h1>
                    </div>
                    <div class="col d-xl-flex align-items-xl-center">
                        <button class="btn accordion-button collapsed" data-bs-toggle="collapse"
                            data-bs-target="#searchcriteria" type="button" aria-controls=""></button>
                    </div>
                </div>
            </div>
        </div>
        <form [formGroup]="form" #test="ngForm">
            <!-- <p>{{test.value | json}}</p>  -->



            <div id="searchcriteria" class=" {{searchCollapse()}}">
                <div class="row formrow">
                    <div class="col-lg-10 col-xxl-6 offset-lg-1 offset-xxl-3">
                        <div class="row">
                            <div class="col">
                                <h1 class="text-nowrap blueheadsmall"
                                    style="padding-bottom: 0.5rem; padding-top: 0.5rem">
                                    Type of RFQ required?
                                </h1>
                            </div>
                            <div class="col d-xl-flex align-items-xl-center">
                                <button class="btn accordion-button collapsed" data-bs-toggle="collapse"
                                    data-bs-target="#rfqtype" type="button"
                                    style="border-style: none; /*padding: 0px;*/" aria-controls=""></button>
                            </div>
                        </div>
                        <div id="rfqtype" class=" collapse show">
                            <div class="row d-xl-flex justify-content-xl-start" style="padding-top: 0">
                                <div class="
                    col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" formControlName="payPrefCS" (change)="
                        rfqTypeList( 'Complete')
                      " id="flexRadioDefault1" class="btn-check form-check-input" /><label
                                            class="form-label form-check-label btn btn-outline-info"
                                            for="flexRadioDefault1" style="border-radius: 40px; font-size: 14px"
                                            name="flexRadioDefault">Complete Supply

                                            <ng-container *ngIf="completeSupplyCount > 0"><span
                                                    class="amount">({{completeSupplyCount}})</span></ng-container>

                                        </label>
                                    </div>
                                </div>
                                <div class="
                    col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" formControlName="payPrefInd"
                                            (change)="rfqTypeList('Raw Material')" id="flexRadioDefault2"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info" for="flexRadioDefault2"
                                            style="border-radius: 40px; font-size: 14px">Raw Material
                                            <ng-container *ngFor="let count of rfqCount"><span
                                                    *ngIf="count.serviceName == 'Raw Materials'"
                                                    class="amount">({{count.rfqIdCount}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row formrow">
                    <div class="col-lg-10 col-xxl-6 offset-lg-1 offset-xxl-3">
                        <div class="row">
                            <div class="col">
                                <h1 class="text-nowrap blueheadsmall"
                                    style="padding-bottom: 0.5rem; padding-top: 0.5rem">
                                    What service are you looking for
                                </h1>
                            </div>
                            <div class="col d-xl-flex align-items-xl-center">
                                <button class="btn accordion-button collapsed" data-bs-toggle="collapse"
                                    data-bs-target="#servicescollapse" type="button"
                                    style="border-style: none; /*padding: 0px;*/" aria-controls=""></button>
                            </div>
                        </div>
                        <div></div>

                        <div id="servicescollapse" class="collapse show">
                            <div class="row d-xl-flex justify-content-xl-start" style="padding-top: 0">
                                <div class="
                    col
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input [disabled]="true" type="checkbox" id="check1" formControlName="bending"
                                            (change)="serviceList(f.bending.value, 'Bending')"
                                            class="btn-check" /><label class="form-label btn btn-outline-info"
                                            for="check1" style="border-radius: 40px; font-size: 14px">Bending&nbsp;
                                            <ng-container *ngFor="let count of rfqCount"><span
                                                    *ngIf="count.serviceName == 'Bending'"
                                                    class="amount">({{count.rfqIdCount}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                                col
                                d-md-flex
                                justify-content-md-center
                                align-items-md-center
                              " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="check3" formControlName="chamfer"
                                            (change)="serviceList(f.chamfer.value, 'Chamfer')"
                                            class="btn-check" /><label class="form-label btn btn-outline-info"
                                            for="check3" style="border-radius: 40px; font-size: 14px">Chamfer

                                            <ng-container *ngFor="let count of rfqCount"><span
                                                    *ngIf="count.serviceName == 'Chamfer'"
                                                    class="amount">({{count.rfqIdCount}})</span></ng-container>

                                        </label>
                                    </div>
                                </div>
                                <div class="
                                            col
                                            d-md-flex
                                            justify-content-md-center
                                            align-items-md-center
                                          " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="check4" formControlName="cncMachining" (change)="
                                                serviceList(f.cncMachining.value, 'CNC Machining')
                                              " class="btn-check" /><label class="form-label btn btn-outline-info"
                                            for="check4" style="border-radius: 40px; font-size: 14px">CNC
                                            Machining&nbsp;
                                            <ng-container *ngFor="let count of rfqCount"><span
                                                    *ngIf="count.serviceName == 'CNC Machining'"
                                                    class="amount">({{count.rfqIdCount}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>

                            </div>
                            <div class="row d-xl-flex justify-content-xl-start" style="padding-top: 0">
                                <div class="
                                col
                                d-md-flex
                                justify-content-md-center
                                align-items-md-center
                              " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="check2" formControlName="deCoilSlit" (change)="
                                    serviceList(f.deCoilSlit.value, 'Decoiling And Slitting')
                                  " class="btn-check" /><label class="form-label text-nowrap btn btn-outline-info"
                                            for="check2" style="border-radius: 40px; font-size: 14px">De-Coiling &amp;
                                            Slitting&nbsp;<ng-container *ngFor="let count of rfqCount"><span
                                                    *ngIf="count.serviceName == 'Decoiling And Slitting'"
                                                    class="amount">({{count.rfqIdCount}})</span></ng-container></label>
                                    </div>
                                </div>

                                <div class="
                    col
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="check5" formControlName="drilling"
                                            (change)="serviceList(f.drilling.value, 'Drilling')"
                                            class="btn-check" /><label class="form-label btn btn-outline-info"
                                            for="check5" style="border-radius: 40px; font-size: 14px">Drilling&nbsp;
                                            <ng-container *ngFor="let count of rfqCount"><span
                                                    *ngIf="count.serviceName == 'Drilling'"
                                                    class="amount">({{count.rfqIdCount}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                    col
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0; font-size: 14px">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="check6" formControlName="fabrication"
                                            (change)="serviceList(f.fabrication.value, 'Fabrication')"
                                            class="btn-check" /><label class="form-label btn btn-outline-info"
                                            for="check6" style="border-radius: 40px; font-size: 14px">Fabrication&nbsp;
                                            <ng-container *ngFor="let count of rfqCount"><span
                                                    *ngIf="count.serviceName == 'Fabrication'"
                                                    class="amount">({{count.rfqIdCount}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-xl-flex justify-content-xl-start" style="padding-top: 0">

                                <div class="
                                col
                                d-md-flex
                                justify-content-md-center
                                align-items-md-center
                              " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="check1133" formControlName="guillotining"
                                            class="btn-check"
                                            (change)="serviceList(f.guillotining.value, 'Guillotining')"><label
                                            class="form-label btn btn-outline-info" for="check1133"
                                            style="border-radius: 40px; font-size: 14px">&nbsp;Guillotining&nbsp;
                                            <ng-container *ngFor="let count of rfqCount"><span
                                                    *ngIf="count.serviceName == 'Guillotining'"
                                                    class="amount">({{count.rfqIdCount}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>

                                <div class="
                    col
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="check7" formControlName="laserCutting" (change)="
                        serviceList(f.laserCutting.value, 'Laser Cutting') 
                      " class="btn-check" /><label class="form-label btn btn-outline-info" for="check7"
                                            style="border-radius: 40px; font-size: 14px">Laser Cutting
                                            <ng-container *ngFor="let count of rfqCount"><span
                                                    *ngIf="count.serviceName == 'Laser Cutting'"
                                                    class="amount">({{count.rfqIdCount}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                                col
                                d-md-flex
                                justify-content-md-center
                                align-items-md-center
                              " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="check9" formControlName="plasmaCutting" (change)="
                                    serviceList(f.plasmaCutting.value,'Plasma Cutting' )
                                  " class="btn-check" /><label class="form-label btn btn-outline-info" for="check9"
                                            style="border-radius: 40px; font-size: 14px">Plasma Cutting<ng-container
                                                *ngFor="let count of rfqCount"><span
                                                    *ngIf="count.serviceName == 'Plasma Cutting'"
                                                    class="amount">({{count.rfqIdCount}})</span></ng-container></label>
                                    </div>
                                </div>



                            </div>
                            <div class="row d-xl-flex justify-content-xl-start" style="padding-top: 0">
                                <div class="
                                col
                                d-md-flex
                                justify-content-md-center
                                align-items-md-center
                              " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="check8" formControlName="profileCutting" (change)="
                                    serviceList(f.profileCutting.value, 'Profile Cutting')
                                  " class="btn-check" /><label class="form-label btn btn-outline-info" for="check8"
                                            style="border-radius: 40px; font-size: 14px">Profile Cutting&nbsp;
                                            <ng-container *ngFor="let count of rfqCount"><span
                                                    *ngIf="count.serviceName == 'Profile Cutting'"
                                                    class="amount">({{count.rfqIdCount}})</span></ng-container>
                                        </label>
                                    </div>

                                </div>
                                <div class="
                    col
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="check10" formControlName="rolling"
                                            (change)="serviceList(f.rolling.value, 'Rolling')"
                                            class="btn-check" /><label class="form-label btn btn-outline-info"
                                            for="check10" style="border-radius: 40px; font-size: 14px">Rolling&nbsp;
                                            <ng-container *ngFor="let count of rfqCount"><span
                                                    *ngIf="count.serviceName == 'Rolling'"
                                                    class="amount">({{count.rfqIdCount}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                    col
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="check11" formControlName="welding"
                                            (change)="serviceList(f.welding.value, 'Welding')"
                                            class="btn-check" /><label class="form-label btn btn-outline-info"
                                            for="check11"
                                            style="border-radius: 40px; font-size: 14px">&nbsp;Welding&nbsp;
                                            <ng-container *ngFor="let count of rfqCount"><span
                                                    *ngIf="count.serviceName == 'Welding'"
                                                    class="amount">({{count.rfqIdCount}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>


                                <!-- <div class="
                                            col
                                            d-md-flex
                                            justify-content-md-center
                                            align-items-md-center
                                          " style="padding: 0">

                                </div> -->


                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="row formrow">                  
                    <div class="col-lg-10 col-xxl-6 offset-lg-1 offset-xxl-3">
                        <div class="row">
                            <div class="col">
                                <h1 class="text-nowrap blueheadsmall"
                                    style="padding-bottom: 0.5rem; padding-top: 0.5rem">
                                    Do you have a buyer preferance?
                                </h1>
                            </div>
                            <div class="col d-xl-flex align-items-xl-center">
                                <button class="btn accordion-button collapsed" data-bs-toggle="collapse"
                                    data-bs-target="#bpreferance" type="button"
                                    style="border-style: none; /*padding: 0px;*/" aria-controls=""></button>
                            </div>
                        </div>
                        <div id="bpreferance" class="collapse show">
                            <div class="row">
                                <div class="
                    col-sm-1
                    d-sm-flex d-xl-flex
                    align-items-sm-center align-items-xl-center
                  ">
                                    <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                        <input formControlName="beeComplient" (change)="
                        buyerPreferenceList(
                          f.beeComplient.value,
                          'BEE Complient'
                        )
                      " type="checkbox" id="checkbee" class="btn-check" /><label class="
                        form-label
                        d-flex d-sm-flex d-lg-flex d-xl-flex
                        align-items-center
                        align-items-sm-center
                        align-items-lg-center
                        justify-content-xl-center
                        align-items-xl-center
                        btn btn-outline-info
                        smallcheckbtn
                      " for="checkbee" style="border-radius: 300px; height: 24px"><i class="fa fa-check"
                                                style="font-size: 12px"></i></label>
                                    </div>
                                </div>
                                <div class="
                    col
                    d-sm-flex d-xl-flex
                    align-items-sm-center align-items-xl-center
                  ">
                                    <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                        <p class="bluetext">BEE Complient</p>
                                    </div>
                                </div>
                                <div class="
                    col-sm-1
                    d-sm-flex d-xl-flex
                    align-items-sm-center align-items-xl-center
                  ">
                                    <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                        <input formControlName="creditGaruntee" (change)="
                        buyerPreferenceList(
                          f.creditGaruntee.value,
                          'Credit Garuntee'
                        )
                      " type="checkbox" id="checkcredit" class="btn-check" /><label class="
                        form-label
                        d-flex d-xl-flex
                        align-items-center
                        justify-content-xl-center
                        align-items-xl-center
                        btn btn-outline-info
                        smallcheckbtn
                      " for="checkcredit" style="border-radius: 300px; height: 24px"><i class="fa fa-check"
                                                style="font-size: 12px"></i></label>
                                    </div>
                                </div>
                                <div class="
                    col
                    d-sm-flex d-xl-flex
                    align-items-sm-center align-items-xl-center
                  ">
                                    <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                        <p class="bluetext">Credit garuntee</p>
                                    </div>
                                </div>
                                <div class="
                    col-sm-1
                    d-sm-flex d-xl-flex
                    align-items-sm-center align-items-xl-center
                  ">
                                    <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                        <input formControlName="goldVerified" (change)="
                        buyerPreferenceList(
                          f.goldVerified.value,
                          'Gold Verified'
                        )
                      " type="checkbox" id="checkgold" class="btn-check" /><label class="
                        form-label
                        d-flex d-xl-flex
                        align-items-center
                        justify-content-xl-center
                        align-items-xl-center
                        btn btn-outline-info
                        smallcheckbtn
                      " for="checkgold" style="border-radius: 300px; height: 24px"><i class="fa fa-check"
                                                style="font-size: 12px"></i></label>
                                    </div>
                                </div>
                                <div class="
                    col
                    d-sm-flex d-xl-flex
                    align-items-sm-center align-items-xl-center
                  ">
                                    <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                        <p class="bluetext">Gold verified</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="row formrow">
                    <div class="col-lg-10 col-xxl-6 offset-lg-1 offset-xxl-3">
                        <div class="row">
                            <div class="col">
                                <h1 class="text-nowrap blueheadsmall"
                                    style="padding-bottom: 0.5rem; padding-top: 0.5rem">
                                    Do you have a buyer preferance?
                                </h1>
                            </div>
                            <div class="col d-xl-flex align-items-xl-center">
                                <button class="btn accordion-button collapsed" data-bs-toggle="collapse"
                                    data-bs-target="#rfqbuyeroption" type="button"
                                    style="border-style: none; /*padding: 0px;*/" aria-controls=""></button>
                            </div>
                        </div>
                        <div id="rfqbuyeroption" class=" collapse show">
                            <div class="row d-xl-flex justify-content-xl-start" style="padding-top: 0">
                                <div class="
                    col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="PrefferedBuyer" formControlName="preferredBuyerYes"
                                            (change)="buyerPreferenceCheck('Yes')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label form-check-label btn btn-outline-info"
                                            for="PrefferedBuyer" style="border-radius: 40px; font-size: 14px"
                                            name="flexRadioDefault">Preferred Buyer
                                            <ng-container *ngIf="dimensionsRfqsCount > 0">

                                                <!-- <span class="amount">({{dimensionsRfqsCount}})</span> -->

                                            </ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                    col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    offset-xl-0
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="NoPrefferedBuyer" formControlName="preferredBuyerNo"
                                            (change)="buyerPreferenceCheck('No')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info" for="NoPrefferedBuyer"
                                            style="border-radius: 40px; font-size: 14px">No Preference
                                            <ng-container *ngIf="drawingsRfqsCount > 0">

                                                <!-- <span class="amount">({{drawingsRfqsCount}})</span> -->

                                            </ng-container>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row formrow">
                    <div class="col-lg-10 col-xxl-6 offset-lg-1 offset-xxl-3">
                        <div class="row">
                            <div class="col">
                                <h1 class="text-nowrap blueheadsmall"
                                    style="padding-bottom: 0.5rem; padding-top: 0.5rem">
                                    Must the RFQs contain drawings?
                                </h1>
                            </div>
                            <div class="col d-xl-flex align-items-xl-center">
                                <button class="btn accordion-button collapsed" data-bs-toggle="collapse"
                                    data-bs-target="#rfqdrawingoption" type="button"
                                    style="border-style: none; /*padding: 0px;*/" aria-controls=""></button>
                            </div>
                        </div>
                        <div id="rfqdrawingoption" class=" collapse show">
                            <div class="row d-xl-flex justify-content-xl-start" style="padding-top: 0">
                                <div class="
                    col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="RFQNO" formControlName="drawNo"
                                            (change)="containDrawingsCheck('No')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label form-check-label btn btn-outline-info" for="RFQNO"
                                            style="border-radius: 40px; font-size: 14px" name="flexRadioDefault">No
                                            <ng-container *ngIf="dimensionsRfqsCount > 0"><span
                                                    class="amount">({{dimensionsRfqsCount}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                    col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    offset-xl-0
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="RFQYES" formControlName="drawYes"
                                            (change)="containDrawingsCheck('Yes')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info" for="RFQYES"
                                            style="border-radius: 40px; font-size: 14px">Yes
                                            <ng-container *ngIf="drawingsRfqsCount > 0"><span
                                                    class="amount">({{drawingsRfqsCount}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                                col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                                offset-xl-0
                                d-md-flex
                                justify-content-md-center
                                align-items-md-center
                              " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="RFQANY" formControlName="drawAny"
                                            (change)="containDrawingsCheck('any')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info" for="RFQANY"
                                            style="border-radius: 40px; font-size: 14px">No Preference
                                            <ng-container *ngIf="allRfqSpecifcationsCount > 0"><span
                                                    class="amount">({{allRfqSpecifcationsCount}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row formrow">
                    <div class="col-lg-10 col-xxl-6 offset-lg-1 offset-xxl-3">
                        <div class="row">
                            <div class="col">
                                <h1 class="text-nowrap blueheadsmall"
                                    style="padding-bottom: 0.5rem; padding-top: 0.5rem">
                                    What is your payment preference?
                                </h1>
                            </div>
                            <div class="col d-xl-flex align-items-xl-center">
                                <button class="btn accordion-button collapsed" data-bs-toggle="collapse"
                                    data-bs-target="#paymentpreferance" type="button"
                                    style="border-style: none; /*padding: 0px;*/" aria-controls=""></button>
                            </div>
                        </div>
                        <div class="collapse show" id="paymentpreferance">
                            <div class="row d-xl-flex justify-content-xl-start" style="padding-top: 0">
                                <div class="
                    col-1 col-sm-2 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="checkcod" formControlName="paymentCOD"
                                            (change)="paymentList(f.paymentCOD.value,'COD')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info form-check-label" for="checkcod"
                                            style="border-radius: 40px; font-size: 14px">COD
                                            <ng-container *ngIf="CODCount > 0"><span
                                                    class="amount">({{CODCount}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                    col-2 col-sm-2 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="check30" formControlName="payment30Days"
                                            (change)="paymentList(f.payment30Days.value,'30 Days')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info form-check-label" for="check30"
                                            style="border-radius: 40px; font-size: 14px">30 days
                                            <ng-container *ngIf="days30Count > 0"><span
                                                    class="amount">({{days30Count}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                    col-2 col-sm-2 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="check60" formControlName="payment60Days"
                                            (change)="paymentList(f.payment60Days.value,'60 Days')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info form-check-label" for="check60"
                                            style="border-radius: 40px; font-size: 14px">60 days
                                            <ng-container *ngIf="days60Count > 0"><span
                                                    class="amount">({{days60Count}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                    col-1 col-sm-2 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="checkCBD" formControlName="paymentCBD"
                                            (change)="paymentList(f.paymentCBD.value,'CBD')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info form-check-label" for="checkCBD"
                                            style="border-radius: 40px; font-size: 14px">CBD
                                            <ng-container *ngIf="CBDCount > 0"><span
                                                    class="amount">({{CBDCount}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                                col-1 col-sm-2 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                                d-md-flex
                                justify-content-md-center
                                align-items-md-center
                              " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="checkother" formControlName="paymentOther"
                                            (change)="paymentList(f.paymentOther.value,'Other')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info form-check-label" for="checkother"
                                            style="border-radius: 40px; font-size: 14px">Other
                                            <ng-container *ngIf="otherCount > 0"><span
                                                    class="amount">({{otherCount}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                    col-1 col-sm-2 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="checkany1" formControlName="paymentAny"
                                            (change)=" paymentList(f.paymentAny.value,'any')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info form-check-label" for="checkany1"
                                            style="border-radius: 40px; font-size: 14px">No Preference
                                            <ng-container *ngIf="allPaymentPreferenceCount > 0"><span
                                                    class="amount">({{allPaymentPreferenceCount}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row formrow">
                    <div class="col-lg-10 col-xxl-6 offset-lg-1 offset-xxl-3">
                        <div class="row">
                            <div class="col">
                                <h1 class="text-nowrap blueheadsmall"
                                    style="padding-bottom: 0.5rem; padding-top: 0.5rem">
                                    What is your packaging preference?
                                </h1>
                            </div>
                            <div class="col d-xl-flex align-items-xl-center">
                                <button class="btn accordion-button collapsed" data-bs-toggle="collapse"
                                    data-bs-target="#packagingpreferance" type="button"
                                    style="border-style: none; /*padding: 0px;*/" aria-controls=""></button>
                            </div>
                        </div>
                        <div class="collapse show" id="packagingpreferance">
                            <div class="row d-xl-flex justify-content-xl-start" style="padding-top: 0">
                                <div class="
                    col-1 col-sm-2 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="checkBoxed" formControlName="packageBoxed"
                                            (change)="packagingList(f.packageBoxed.value,'Boxed')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info form-check-label" for="checkBoxed"
                                            style="border-radius: 40px; font-size: 14px">Boxed
                                            <ng-container *ngIf="BoxedCount > 0"><span
                                                    class="amount">({{BoxedCount}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                    col-2 col-sm-2 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="checkLoose" formControlName="packageLoose"
                                            (change)="packagingList(f.packageLoose.value,'Loose')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info form-check-label" for="checkLoose"
                                            style="border-radius: 40px; font-size: 14px">Loose
                                            <ng-container *ngIf="LooseCount > 0"><span
                                                    class="amount">({{LooseCount}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                    col-2 col-sm-2 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="checkPallet" formControlName="packagePallet"
                                            (change)="packagingList(f.packagePallet.value,'Pallet')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info form-check-label" for="checkPallet"
                                            style="border-radius: 40px; font-size: 14px">Pallet
                                            <ng-container *ngIf="PalletCount > 0"><span
                                                    class="amount">({{PalletCount}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                    col-1 col-sm-2 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="checkStrapped" formControlName="packageStrapped"
                                            (change)="packagingList(f.packageStrapped.value,'Strapped')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info form-check-label" for="checkStrapped"
                                            style="border-radius: 40px; font-size: 14px">Strapped
                                            <ng-container *ngIf="StrappedCount > 0"><span
                                                    class="amount">({{StrappedCount}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                                col-1 col-sm-2 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                                d-md-flex
                                justify-content-md-center
                                align-items-md-center
                              " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="checkotherpackage" formControlName="packageOther"
                                            (change)="packagingList(f.packageOther.value,'Other')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info form-check-label"
                                            for="checkotherpackage" style="border-radius: 40px; font-size: 14px">Other
                                            <ng-container *ngIf="otherPackageCount > 0"><span
                                                    class="amount">({{otherPackageCount}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                    col-1 col-sm-2 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="checkanypackage" formControlName="packageAny"
                                            (change)=" packagingList(f.packageAny.value,'any')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info form-check-label"
                                            for="checkanypackage" style="border-radius: 40px; font-size: 14px">No
                                            Preference
                                            <!-- <ng-container *ngIf="allPaymentPreferenceCount > 0"><span
                                                    class="amount">({{allPaymentPreferenceCount}})</span></ng-container> -->
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row formrow">
                    <div class="col-lg-10 col-xxl-6 offset-lg-1 offset-xxl-3">
                        <div class="row">
                            <div class="col">
                                <h1 class="text-nowrap blueheadsmall"
                                    style="padding-bottom: 0.5rem; padding-top: 0.5rem">
                                    What is your location preference?
                                </h1>
                            </div>
                            <div class="col d-xl-flex align-items-xl-center">
                                <button class="btn accordion-button collapsed" data-bs-toggle="collapse"
                                    data-bs-target="#locationpreferance" type="button"
                                    style="border-style: none; /*padding: 0px;*/" aria-controls=""></button>
                            </div>
                        </div>
                        <div class="collapse show" id="locationpreferance">
                            <div class="row d-xl-flex justify-content-xl-start" style="padding-top: 0">
                                <div class="
                    col-1 col-sm-2 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="checkEC" formControlName="provinceEasternCape"
                                            (change)="locationList(f.provinceEasternCape.value,'Eastern Cape')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info form-check-label" for="checkEC"
                                            style="border-radius: 40px; font-size: 14px">Eastern Cape
                                            <ng-container *ngIf="EcCount > 0"><span class="amount">({{EcCount}})</span>
                                            </ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                    col-2 col-sm-2 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="checkFS" formControlName="provinceFreeState"
                                            (change)="locationList(f.provinceFreeState.value,'Free State')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info form-check-label" for="checkFS"
                                            style="border-radius: 40px; font-size: 14px">Free State
                                            <ng-container *ngIf="FsCount > 0"><span class="amount">({{FsCount}})</span>
                                            </ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                    col-2 col-sm-2 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="checkGau" formControlName="provinceGuateng"
                                            (change)="locationList(f.provinceGuateng.value,'Gauteng')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info form-check-label" for="checkGau"
                                            style="border-radius: 40px; font-size: 14px">Gauteng
                                            <ng-container *ngIf="GaCount > 0"><span class="amount">({{GaCount}})</span>
                                            </ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                    col-1 col-sm-2 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="checkKZN" formControlName="provinceKwaZuluNatal"
                                            (change)="locationList(f.provinceKwaZuluNatal.value,'KwaZulu-Natal')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info form-check-label" for="checkKZN"
                                            style="border-radius: 40px; font-size: 14px">KwaZulu-Natal
                                            <ng-container *ngIf="KznCount > 0"><span
                                                    class="amount">({{KznCount}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                                col-1 col-sm-2 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                                d-md-flex
                                justify-content-md-center
                                align-items-md-center
                              " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="checkLim" formControlName="provinceLimpopo"
                                            (change)="locationList(f.provinceLimpopo.value,'Limpopo')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info form-check-label" for="checkLim"
                                            style="border-radius: 40px; font-size: 14px">Limpopo
                                            <ng-container *ngIf="LimCount > 0"><span
                                                    class="amount">({{LimCount}})</span></ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                    col-1 col-sm-2 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="checkMp" formControlName="provinceMpumalanga"
                                            (change)=" locationList(f.provinceMpumalanga.value,'Mpumalanga')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info form-check-label" for="checkMp"
                                            style="border-radius: 40px; font-size: 14px">Mpumalanga
                                            <ng-container *ngIf="MpCount > 0"><span class="amount">({{MpCount}})</span>
                                            </ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                    col-1 col-sm-2 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="checkNC" formControlName="provinceNorthenCape"
                                            (change)=" locationList(f.provinceNorthenCape.value,'Northern Cape')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info form-check-label" for="checkNC"
                                            style="border-radius: 40px; font-size: 14px">Northern Cape
                                            <ng-container *ngIf="NcCount > 0"><span class="amount">({{NcCount}})</span>
                                            </ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                    col-1 col-sm-2 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="checkNW" formControlName="provinceNorthWest"
                                            (change)=" locationList(f.provinceNorthWest.value,'North West')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info form-check-label" for="checkNW"
                                            style="border-radius: 40px; font-size: 14px">North West
                                            <ng-container *ngIf="NwCount > 0"><span class="amount">({{NwCount}})</span>
                                            </ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                    col-1 col-sm-2 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                    d-md-flex
                    justify-content-md-center
                    align-items-md-center
                  " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="checkWC" formControlName="provinceWesternCape"
                                            (change)=" locationList(f.provinceWesternCape.value,'Western Cape')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info form-check-label" for="checkWC"
                                            style="border-radius: 40px; font-size: 14px">Western Cape
                                            <ng-container *ngIf="WcCount > 0"><span class="amount">({{WcCount}})</span>
                                            </ng-container>
                                        </label>
                                    </div>
                                </div>
                                <div class="
                                col-1 col-sm-2 col-md-3 col-lg-3 col-xl-3 col-xxl-3
                                d-md-flex
                                justify-content-md-center
                                align-items-md-center
                              " style="padding: 0">
                                    <div class="btn-group bluebtn" role="group"
                                        aria-label="Basic checkbox toggle button group">
                                        <input type="checkbox" id="checkprovinceany" formControlName="provinceAny"
                                            (change)=" locationList(f.provinceAny.value,'any')"
                                            class="btn-check form-check-input" /><label
                                            class="form-label btn btn-outline-info form-check-label"
                                            for="checkprovinceany" style="border-radius: 40px; font-size: 14px">No
                                            Preference
                                            <!-- <ng-container *ngIf="allPaymentPreferenceCount > 0"><span
                                                                class="amount">({{allPaymentPreferenceCount}})</span></ng-container> -->
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row formrow">
                    <div class="col-lg-10 col-xxl-6 offset-lg-1 offset-xxl-3">
                        <div class="row" style="padding-top: 1rem; padding-bottom: 1rem">
                            <div class="col">
                                <h1 class="text-nowrap bluehead" style="padding-bottom: 0.5rem; padding-top: 0.5rem">
                                </h1>
                            </div>
                            <div class="
                  col
                  d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex
                  justify-content-sm-start
                  justify-content-md-end
                  justify-content-lg-end
                  justify-content-xl-end
                  align-items-xl-center
                  justify-content-xxl-end
                ">

                                <button [disabled]="!supplierServicesValid || !rfqCountValid || !rfqCountUpdateValid"
                                    (click)="navToFavourites()" class="btn yellowbtn" type="button"
                                    data-bs-target=".search" data-bs-toggle="collapse" style=" 
                                                                    border-style: none;
                                                                    border-radius: 20px;
                                                                    width: 150.0312px;
                                                                    height: 42px;
                                                                    font-size: 14px;
                                                                    margin-right:10px;
                                                                    background-color:var(--darkblue) !important;
                                                                  ">
                                    Favourites
                                </button>
                                <button [disabled]="!supplierServicesValid || !rfqCountValid || !rfqCountUpdateValid"
                                    (click)="onSearch()" class="btn yellowbtn" type="button" data-bs-target=".search"
                                    data-bs-toggle="collapse" style="
                    border-style: none;
                    border-radius: 20px;
                    width: 150.0312px;
                    height: 42px;
                    font-size: 14px;
                  ">
                                    Search
                                </button>

                                <button #collapseSearch style="display: none;"></button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="container d-flex align-items-center flex-column justify-content-center "
            style="padding-bottom: 5rem;">
            <!-- search filters displays -->
            <div class="   

        d-flex
        align-items-center
        justify-content-center
        justify-content-sm-center
        justify-content-md-center
        justify-content-lg-center
        justify-content-xl-center
        justify-content-xxl-center
        searchbtns 
      " style="  flex-wrap: wrap; max-width: 800px;">
                <div
                    *ngIf="services.length > 0 || buyerPreference.length > 0 || containDrawings != null || paymentPreference != null || rfqType != null  ">
                    <button class="
      btn
      text-nowrap
      d-md-flex d-xl-flex
      justify-content-md-center
      align-items-md-center align-items-xl-center
      yellowsearchbtn
    " data-bs-toggle="modal" data-bs-target="#closePrompt" type="button" style="height: 30px; font-size: 12px ;">
                        Clear All &nbsp;<a class="closeicon" style="color: rgb(255, 255, 255)"><svg
                                xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"
                                fill="none">
                                <path
                                    d="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
                                    fill="currentColor"></path>
                            </svg></a>
                    </button>
                </div>

                <div *ngFor="let service of services">
                    <ng-container>
                        <button *ngIf="services.length > 1" (click)="serviceListDisplay(service)" class="
            btn
            text-nowrap
            d-md-flex d-xl-flex
            justify-content-md-center
            align-items-md-center align-items-xl-center
            bluesearchbtn
          " type="button" style="height: 30px; font-size: 12px">
                            <ng-container *ngFor="let sn of rfqCount">
                                <ng-container *ngIf="sn.serviceId == service">


                                    <ng-container *ngIf="sn.serviceName != 'Raw Materials'">
                                        {{ sn.serviceName }}&nbsp;
                                    </ng-container>

                                    <ng-container *ngIf="sn.serviceName == 'Raw Materials'">
                                        RFQ Type: Raw Material&nbsp;
                                    </ng-container>


                                </ng-container>
                            </ng-container><a class="closeicon" style="color: rgb(255, 255, 255)"><svg
                                    xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"
                                    fill="none">
                                    <path
                                        d="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
                                        fill="currentColor"></path>
                                </svg></a>
                        </button>
                    </ng-container>
                </div>

                <button *ngIf="services.length == 1  " (click)="serviceListDisplay(services[0])" class="
                btn
                text-nowrap
                d-md-flex d-xl-flex
                justify-content-md-center
                align-items-md-center align-items-xl-center
                bluesearchbtn
              " type="button" style="height: 30px; font-size: 12px" data-bs-toggle="modal"
                    data-bs-target="#closePrompt">
                    <ng-container *ngFor="let sn of rfqCount">
                        <ng-container *ngIf="sn.serviceId == services[0]">

                            <ng-container *ngIf="sn.serviceName != 'Raw Materials'">
                                {{ sn.serviceName }}&nbsp;
                            </ng-container>

                            <ng-container *ngIf="sn.serviceName == 'Raw Materials'">
                                RFQ Type: Raw Material&nbsp;
                            </ng-container>


                        </ng-container>
                    </ng-container><a class="closeicon" style="color: rgb(255, 255, 255)"><svg
                            xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
                                fill="currentColor"></path>
                        </svg></a>
                </button>

                <div *ngIf="preferredBuyerCond">
                    <button (click)="buyerPreferenceListDisplay()" class="
            btn
            text-nowrap
            d-md-flex d-xl-flex
            justify-content-md-center
            align-items-md-center align-items-xl-center
            bluesearchbtn
          " type="button" style="height: 30px; font-size: 12px">
                        Buyer Preference: {{ preferredBuyerCheck }}&nbsp;<a class="closeicon"
                            style="color: rgb(255, 255, 255)"><svg xmlns="http://www.w3.org/2000/svg" width="1em"
                                height="1em" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
                                    fill="currentColor"></path>
                            </svg></a>
                    </button>
                </div>

                <div *ngIf="containDrawCond">
                    <button (click)="containDrawingsDisplay()" class="
            btn
            text-nowrap
            d-md-flex d-xl-flex
            justify-content-md-center
            align-items-md-center align-items-xl-center
            bluesearchbtn
          " type="button" style="height: 30px; font-size: 12px">
                        Include Drawings: {{ containDrawings }}&nbsp;<a class="closeicon"
                            style="color: rgb(255, 255, 255)"><svg xmlns="http://www.w3.org/2000/svg" width="1em"
                                height="1em" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
                                    fill="currentColor"></path>
                            </svg></a>
                    </button>
                </div>

                <div *ngFor="let payment of paymentTypeList">
                    <button (click)="paymentListDisplay(payment)" class="
            btn
            text-nowrap
            d-md-flex d-xl-flex
            justify-content-md-center
            align-items-md-center align-items-xl-center
            bluesearchbtn
          " type="button" style="height: 30px; font-size: 12px">
                        Paymeny Preference: {{ payment }}&nbsp;<a class="closeicon"
                            style="color: rgb(255, 255, 255)"><svg xmlns="http://www.w3.org/2000/svg" width="1em"
                                height="1em" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
                                    fill="currentColor"></path>
                            </svg></a>
                    </button>
                </div>

                <div *ngFor="let packaging of packagingTypeList">
                    <button (click)="packageListDisplay(packaging)" class="
            btn
            text-nowrap
            d-md-flex d-xl-flex
            justify-content-md-center
            align-items-md-center align-items-xl-center
            bluesearchbtn
          " type="button" style="height: 30px; font-size: 12px">
                        Packaging Preference: {{ packaging }}&nbsp;<a class="closeicon"
                            style="color: rgb(255, 255, 255)"><svg xmlns="http://www.w3.org/2000/svg" width="1em"
                                height="1em" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
                                    fill="currentColor"></path>
                            </svg></a>
                    </button>
                </div>

                <div *ngFor="let location of locationTypeList">
                    <button (click)="locationListDisplay(location)" class="
            btn
            text-nowrap
            d-md-flex d-xl-flex
            justify-content-md-center
            align-items-md-center align-items-xl-center
            bluesearchbtn
          " type="button" style="height: 30px; font-size: 12px">
                        Location Preference: {{ location }}&nbsp;<a class="closeicon"
                            style="color: rgb(255, 255, 255)"><svg xmlns="http://www.w3.org/2000/svg" width="1em"
                                height="1em" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
                                    fill="currentColor"></path>
                            </svg></a>
                    </button>
                </div>

                <div *ngIf="rfqTypeCond">
                    <ng-container *ngIf="rfqType != 'Raw Material'">
                        <button (click)="rfqListDisplay()" class="
            btn
            text-nowrap
            d-md-flex d-xl-flex
            justify-content-md-center
            align-items-md-center align-items-xl-center
            bluesearchbtn
          " type="button" style="height: 30px; font-size: 12px">
                            RFQ Type: {{rfqType }}&nbsp;<a class="closeicon" style="color: rgb(255, 255, 255)"><svg
                                    xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"
                                    fill="none">
                                    <path
                                        d="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
                                        fill="currentColor"></path>
                                </svg></a>
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>

        <!-- No Search Results Display -->
        <!-- <div *ngIf="finalRFQs.length == 0 && isSearched && !dataLoading" style="padding-top: 20px;">
            <h1 class="text-nowrap blueheadsmall" style="text-align: center; color: #3e3e48; font-size: x-large;">No
                search results</h1>
            </div> -->
        <div *ngIf="finalRFQs.length == 0 && isSearched && !dataLoading" style="height: 100vh;">
            <div class="d-flex flex-column justify-content-center align-items-center">
                <img src="assets/nodata.png" style="margin-top: 8rem;">

                <h1 class="text-center text-center bluehead" style="font-size: 20px;margin-top: 1rem; ">No search
                    results
                </h1>

            </div>
        </div>


        <!-- Loading -->
        <ng-container #loading *ngIf="dataLoading">
            <!-- <div class="tab-content">
                <div class="tab-pane active" role="tabpanel" id="tab-1">
                    <div class="container-fluid bluecont">
                        <h1 class="text-center" style="font-size: 20px;">Searching for RFQs...
                        </h1>
        
        
                    </div>
                </div>
            </div> -->


            <div class="container-fluid d-flex justify-content-center align-items-center"
                style="margin-top:-25rem;min-height: 100vh; ">
                <div class="text-center text-center bluehead"><span
                        class="spinner-border spinner-border-sm mr-1"></span>
                    Searching for RFQs...
                </div>

            </div>

        </ng-container>


        <ng-container *ngIf="finalRFQs.length > 0 && !dataLoading">
            <!-- RFQ display section -->
            <div style="padding-top: 15px;">
                <div *ngFor="let rfq of pageOfItems">
                    <app-browse-rfq [rfq]="rfq" (RfqExpiredEvent)="removeSelectedRfq($event)"></app-browse-rfq>
                </div>
            </div>



            <!-- pagination  -->
            <pagination [items]="finalRFQs" (changePage)="onChangePage($event)" [initialPage]="defaultPage"
                [pageSize]="maxRfqListSize" (currentPage)="setCurrentPage($event)" (paging)="setPagingActive($event)">
            </pagination>

        </ng-container>

    </div>
</section>


<!-- This is where the clear search modal modal starts -->
<section>
    <div role="dialog" tabindex="5" class="modal fade" id="closePrompt" aria-labelledby="exampleModalLabel"
        style="font-family: Roboto, sans-serif;border-radius: 20px !important;">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body d-lg-flex align-items-lg-center"
                    style="background: rgba(255,255,255,0);padding: 0px;height: 0px !important;">
                    <div class="container shadow-sm"
                        style="width: 400px;margin-top: 1rem;font-family: Roboto, sans-serif;background-color:#f2f2f5; padding: 1rem;border-radius: 20px;">
                        <div class="row row-cols-1">
                            <div class="col d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                                <h1 class="text-center d-xxl-flex bluetext">Are you sure you want to clear all search
                                    fields(&amp; results)</h1>
                            </div>
                        </div>
                        <div class="row row-cols-2 d-flex d-xxl-flex justify-content-end justify-content-xxl-end"
                            style="padding-top: 1rem;">
                            <div class="col d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex justify-content-end justify-content-sm-end justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end"
                                style="padding-left: 0;padding-right: 19px;"><a data-bs-toggle="modal"
                                    data-bs-target="#closePrompt"
                                    class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex align-items-center align-items-sm-center align-items-md-center align-items-lg-center align-items-xl-center align-items-xxl-center text-gray-500"
                                    href="#" style="padding-right: 1rem;">No</a>
                                <p class="d-sm-flex justify-content-sm-start align-items-sm-center align-items-md-center align-items-xl-center align-items-xxl-center bluetext"
                                    style="margin-bottom: 0;"><a data-bs-toggle="modal" data-bs-target="#closePrompt"
                                        href="#" (click)="clearAll()"
                                        class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn"
                                        role="button"
                                        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Yes</a>
                                </p>
                                <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0;"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>