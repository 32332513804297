import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-supplier-reporting',
  templateUrl: './supplier-reporting.component.html',
  styleUrls: ['./supplier-reporting.component.css']
})
export class SupplierReportingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
