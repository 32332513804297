import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, NgZone, ViewChild, Output, EventEmitter, Input, ElementRef, AfterViewChecked, OnChanges, SimpleChanges, assertPlatform, OnDestroy, } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import *  as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Role } from '../models/role';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { first, take } from 'rxjs/operators';
import { Quote } from '../models/quote';
import { ChatDetails, View_Chat } from '../models/viewChat';
import { AuthService } from '../auth/auth.service';
import { Account } from '../models/account';
import { CreateChatMessage } from '../models/createchatmessage';
import { interval, Subscription } from 'rxjs';
import { ThrowStmt } from '@angular/compiler';
import { ToastrNotificationService } from '../services/toastr-notification.service';



@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatComponent implements OnInit, AfterViewChecked, OnChanges, OnDestroy {


  @Output() openChatCond = new EventEmitter<boolean>();
  @Input() openChat: boolean;
  @Input() quoteId: number;
  @Input() sentQID: number;

  @Input() rfqStatus: boolean;

  //GETS THESE VALUES FROM VIEW QUOTES / VIEW RFQ COMPONENT
  @Input() Buyer: boolean;
  @Input() Supplier: boolean;
  @Input() Role: string;

  //needed for scrolling chat to bottom on view
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  // USED FOR PERIODIC CHAT RELOAD
  private subscriptionChat: Subscription;

  // EMITS MESSAGE TEXT TO DISPLAY IN QUOTES COMPONENT
  chatmessageids: number[] = [];

  messageseen: boolean;
  seenMessages: number[] = [];
  chatMessage: CreateChatMessage;




  chatHistory: View_Chat;

  chatHistoryAfterReload: View_Chat;

  // NEEDED TO CHECK IF NEW MESSAGES HAVE COME IN
  chatHistoryNewMessageCheck: boolean = false;

  details: ChatDetails;
  // ChatMessageDetails: ChatDetails[] = [];
  showchat: boolean = true;

  triggerChatOpen(value: boolean) {
    this.openChatCond.emit(value);
    this.openChat = value;
    this.details.seen = true;
  }

  // CHECKS TO SEE IF A CHAT HISTORY AFTER RELOAD OBJECT IS BIGGER THAN OLD CHAT HISTORY OBJECT
  PeriodicReloadChat() {
    this.chatmessageids = [];
    this.chatService.getchatHistory(this.sentQID, this.Role)
      .subscribe(chatHistory => {

        this.chatHistory.active = chatHistory.active;
        this.chatHistoryAfterReload = chatHistory;
        if (this.chatHistoryAfterReload.chatMessages.length > this.chatHistory.chatMessages.length)
          //    BOOL FOR NEW MESSAGES BADGE
          this.chatHistoryNewMessageCheck = true;
        {
          for (let chatmsgid of this.chatHistory.chatMessages) {
            this.chatmessageids.push(chatmsgid.chatMessageId);
          }
          // PUSHES NEW CHAT MESSAGES INTO CHAT HISTORY
          for (let newmsgs of this.chatHistoryAfterReload.chatMessages) {
            if (this.chatmessageids.indexOf(newmsgs.chatMessageId) == -1) {
              this.chatHistory.chatMessages.push(newmsgs);

            }
          }
        }
        //loads messages instantly
        this.cdr.detectChanges();
        //MAKES MESSAGES SEEN WHEN CHAT IS OPEN
      });
  }

  //JAMIE CODE

  // viewchatHistory: View_Chat = { chatId: null, quoteId: null, active: null, chatMessages: null }
  // viewChatDetails: ChatDetails = { chatMessageId: null, chatId: null, userId: null, messageContent: null, delivered: null, seen: null, isBuyer: null, isSupplier: null, createdAt: null }

  form: FormGroup;

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  account: Account;

  //EXAMPLES OF ACCOUNT CONTENTS
  //this.account.account.userName
  //this.account.account.buyerId
  //this.account.account.supplierId

  constructor(
    public authService: AuthService,
    private formBuilder: FormBuilder,
    public cdr: ChangeDetectorRef,
    private chatService: ChatService,
    public toastrNotification: ToastrNotificationService,
    private _ngZone: NgZone) {

    this.authService.account.subscribe(x => this.account = x);
  }
  ngOnDestroy() {
    this.subscriptionChat.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form = this.formBuilder.group({
      message: ["", Validators.required],
    });

    this.getChatHistory(this.sentQID, this.Role);
    // this.scrollToBottom();
  }

  ngOnInit(): void {



    this.subscriptionChat = interval(30000)
      .subscribe(x => { this.PeriodicReloadChat() });

    //scrollschat to bottom
  }


  ngAfterViewChecked() {
    //scrollschat to bottom
    // this.scrollToBottom()

  }

  submitMessage() {

  }

  get f() { return this.form.controls; }

  // setPeriodicRefresh(minutes) {
  //   setInterval(() => {
  //     this.cdr.markForCheck();
  //   }, minutes * 10 * 6000);
  // }

  // triggerResize() {
  //   // Wait for changes to be applied, then trigger textarea resize.
  //   this._ngZone.onStable.pipe(take(1))
  //     .subscribe(() => this.autosize.resizeToFitContent(true));
  // }

  // getMinimalisticRelativeTime(dateTime) {
  //   if (!dateTime) {
  //     return null;
  //   }


  //   const today = moment();

  //   const time = moment(dateTime);

  //   const diff = today.diff(time);

  //   const duration = moment.duration(diff);

  //   if (duration.years() > 0) {
  //     return duration.years() + 'y';
  //   } else if (duration.weeks() > 0) {
  //     return duration.weeks() + 'w';
  //   } else if (duration.days() > 0) {
  //     return duration.days() + 'd';
  //   } else if (duration.hours() > 0) {
  //     return duration.hours() + 'h';
  //   } else if (duration.minutes() > 0) {
  //     return duration.minutes() + 'm';
  //   } else if (duration.minutes() < 1) {
  //     return 'few seconds ago';
  //   }
  // }

  getChatHistory(sentQID, Role) {
    this.chatService.getchatHistory(sentQID, Role)
      .subscribe(viewchatHistory => {
        this.chatHistory = viewchatHistory;
        console.log(viewchatHistory);
        //loads messages instantly
        this.cdr.detectChanges();

        //MAKES MESSAGES SEEN WHEN CHAT IS OPEN

        //SCROLLS TO BOTTOM WHEN GET CHAT HISTORY HAPPENS
        this.scrollToBottom();

      });
  }






  stringValidation(value) {
    if (value.replace(/ /g, "") == "") {
      return true
    } else {
      return false;
    }
  }

  sendingMessage: boolean = false;

  createChatMessage() {

    if (this.f.message.value != false && this.f.message.value.replace(/ /g, "") != "" && this.f.message.value != null && this.f.message.value.length < 100 ) {
      this.chatMessage = { chatId: null, userId: null, messageContent: null, isBuyer: null, isSupplier: null }
      this.chatMessage.chatId = this.chatHistory.chatId;
      this.chatMessage.userId = this.account.account.userId;
      this.chatMessage.messageContent = this.f.message.value;
      this.chatMessage.isBuyer = this.Buyer;
      this.chatMessage.isSupplier = this.Supplier;

      // IF THE CHAT MESSAGES ARRAY IS NOT EMPTY
      if (this.chatHistory.chatMessages != null) {

        for (let message of this.chatHistory.chatMessages) {
          if (message.seen = false) {
            this.seenMessages.push(message.chatMessageId);
          }

        }
      }

      this.sendingMessage = true;
      this.f.message.disable();

      this.chatService.CreateChatMessage(this.chatMessage, this.seenMessages).pipe(first())
        .subscribe({
          next: (response: any) => {
            this.f.message.setValue("");
            this.chatHistory = null;
            this.getChatHistory(this.sentQID, this.Role);
            this.sendingMessage = false;
            this.f.message.enable();
            this.cdr.detectChanges();
          },
          error: error => {
            this.toastrNotification.error(" Message Submission Failed");
            this.sendingMessage = false;
            this.f.message.enable();
          }
        });
    }else{
      if(this.f.message.value.replace(/ /g, "") == ""  ){
        this.toastrNotification.error('Cannot Send Blank Message');
      }else if(this.f.message.value.length > 100){
        this.toastrNotification.error('Message Exceeds 100 Characters');
      }
    }

  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }


  newMessages(el): void {
    this.myScrollContainer.nativeElement.scroll({
      top: this.myScrollContainer.nativeElement.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });


    // HIDES NEW MESSAGES BADGE WHEN CLICKED
    this.chatHistoryNewMessageCheck = false;

  }


  //Adds 2 hours to the time when a message is created (server delay)
  correctDateTime(dateTime) {

    var date = new Date(dateTime);
    date.setHours(date.getHours() + 2);
    return date;

  }

}


function ngOnDestroy() {
  throw new Error('Function not implemented.');
}

