<div class="supplierbg">
    <div class="container d-md-flex justify-content-md-center">
        <main class="main" style="padding: 0;padding-top: 2rem;padding-bottom: 2rem;">
            <div>
                <h1 class="text-center bigheading" style="color: #0e0e82;">Supplier Home</h1>
            </div>
            <!-- <p class="shortpar">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam.</p> -->
        </main>
    </div>
    <div class="container portalcont">
        <div class="row g-0 row-cols-2">
            <div
                class="col-xxl-4 offset-xxl-2 text-center d-sm-flex d-md-flex justify-content-sm-center justify-content-md-center">
                <a [routerLink] = "['/supplier-home/search-rfq']" [queryParams] = "{SRCH: 'Default'}" >
                    <div class="bluehov" style="width: auto;max-width: 400px;"><img
                            class="img-fluid d-md-flex d-xxl-flex m-auto justify-content-md-center justify-content-xxl-center buyerimage"
                            src="assets/Group1310.png"></div>
                    <h1 class="text-center bluehead">Browse RFQs</h1>
                </a>
            </div>
            <div
                class="col-xxl-4 offset-xxl-0 text-center d-sm-flex d-md-flex justify-content-sm-center justify-content-md-center">
                <a [routerLink] = "['/supplier-home/view-quote','Open',0,'false']" >
                    <div class="bluehov" style="width: auto;max-width: 400px;"><img
                            class="img-fluid d-md-flex d-xxl-flex m-auto justify-content-md-center justify-content-xxl-center buyerimage"
                            src="assets/Group1311.png"></div>
                    <h1 class="text-center bluehead">View Quotes</h1>
                </a>
            </div>
        </div>
        <div class="row g-0 row-cols-2">
            <div
                class="col-xxl-4 offset-xxl-2 text-center d-sm-flex d-md-flex justify-content-sm-center justify-content-md-center">
                <a>
                    <div class="bluehov hovereffect" style="width: auto;max-width: 400px;"><img
                            class="img-fluid d-md-flex d-xxl-flex m-auto justify-content-md-center justify-content-xxl-center buyerimage"
                            src="assets/Group1312.png"></div>
                    <h1 class="text-center bluehead">Reporting</h1>
                </a>
            </div>
            <div
                class="col-xxl-4 offset-xxl-0 text-center d-sm-flex d-md-flex justify-content-sm-center justify-content-md-center">
                <a routerLink="/supplier-home/supplier-profile">
                    <div class="bluehov" style="width: auto;max-width: 400px;"><img
                            class="img-fluid d-md-flex d-xxl-flex m-auto justify-content-md-center justify-content-xxl-center buyerimage"
                            src="assets/Group1313.png"></div>
                    <h1 class="text-center bluehead">Supplier Profile</h1>
                </a>
            </div>
        </div>
    </div>
    <footer class="footer-basic d-flex justify-content-center align-items-center flex-column">
        <p class="copyright">Tradely © {{currentYear}}</p>
    </footer>
</div>