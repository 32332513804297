import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MessageService } from './message.service';
import { ToastrNotificationService } from './toastr-notification.service';


const baseUrl = `${environment.apiUrl}/FileDownload`;

@Injectable({
  providedIn: 'root'
})
export class FileDownloadedService {

  constructor( private messageService: MessageService,
    private http: HttpClient,
    private toastrNotification: ToastrNotificationService) { }

    getFileDownloadedCheck(fileId: number, userId: string): Observable<boolean> {
      const url = `${baseUrl}/FileDownloaded?fileId=${fileId}&userId=${userId}`
      return this.http.get<any>(url)
        .pipe(
          map(rfqs => { return rfqs.results }),
          tap(_ => this.log('fetched file downloaded checkl')),
          catchError(this.handleError<boolean>('getRFQS'))
        );
    }

     /** Log a HeroService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`RFQService: ${message}`);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.toastrNotification.error(`${operation} failed: ${error.message}`);

      throw error;
    };
  }
}
