<!-- RFQ MODAL 1-->

<form [formGroup]="form" #test="ngForm">

    <section>
        <div class="modal fade" data-bs-backdrop="static" role="dialog" tabindex="-1" id="editrfqmodal"
            aria-labelledby="exampleModalLabel">

            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body" style="padding: 0px; border: 0px">
                        <div class="shadow p" style="width: 500px">
                            <div
                                class="row text-center d-xl-flex justify-content-xl-center align-items-xl-center greenformheader">
                                <div class="col d-flex d-xl-flex justify-content-xl-start align-items-xl-center">
                                    <h1 class="d-xl-flex align-items-xl-center whitehead"
                                        style="font-size: 16px;margin-bottom: 0;">Edit RFQ</h1>
                                </div>
                                <div
                                    class="col d-flex d-xl-flex justify-content-end justify-content-xl-end align-items-xl-center justify-content-xxl-end">
                                    <a data-bs-dismiss="modal" data-bs-target="editrfqmodal" (click)="resetEdit()"
                                        (click)="triggerperiodicUpdate(false)"><svg xmlns="http://www.w3.org/2000/svg"
                                            width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                            style="color: var(--shadowgrey);">
                                            <path
                                                d="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
                                                fill="currentColor"></path>
                                        </svg></a>
                                </div>
                            </div>
                            <div style="width: 500px;">
                                <div class="row RFQmodal shadow p">
                                    <div class="col-xl-12 col-xxl-12 rfqsearchblock"
                                        style="border-radius: 0px 0px 20px 20px;padding: 2rem;">
                                        <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                            <div class="col" style="padding-left: 0;">
                                                <!-- <p>{{test.value | json}}</p>   -->
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;"><i class="fa fa-asterisk uiicon"
                                                        style="font-size: 6px;color: #ff0000;"></i> Project Name
                                                    <ng-container
                                                        *ngIf="(f.projectName.touched && stringValidation(f.projectName.value)) || (rfqValidCond == false  && stringValidation(f.projectName.value) )">
                                                        <span class="ms-1 bluetext thin"
                                                            style="color:#a51d29;">(Required)</span>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="(f.projectName.touched &&  f.projectName.value.length > 50)  || (rfqValidCond == false  && f.projectName.value.length > 50 )">
                                                        <span class="ms-1 bluetext thin" style="color:#a51d29;">(Exceeds
                                                            Max Length)</span>
                                                    </ng-container>

                                                    <ng-container *ngIf="!projectNameUnique">
                                                        <span class="ms-1 bluetext thin" style="color:#a51d29;">(Not
                                                            Unique)</span>
                                                    </ng-container>
                                                </p>
                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;"></p>
                                                <div
                                                    style="height: 2rem;margin-left: 2rem;margin-top: 1rem;width: 18rem;">
                                                    <input formControlName="projectName"
                                                        (ngModelChange)="projectNameUniqueCheck(f.projectName.value)"
                                                        class="form-control form-control"
                                                        style="width: 100%;height: 2rem;">
                                                    <!-- <div *ngIf="f.projectName.touched && f.projectName.errors" class=" ms-1 alert-danger" style = "width:100%; height: 30px; font-size: 14px;" >
                                                            <div *ngIf="f.projectName.errors.required" style="margin-left: 10px;">Project Name is Required</div>
                                                        </div>  -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                            <div class="col" style="padding-left: 0;">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;"><i class="fa fa-asterisk uiicon"
                                                        style="font-size: 6px;color: #ff0000;"></i> Type of Supply
                                                    <ng-container
                                                        *ngIf=" typeOfSupply == null  && rfqValidCond == false">
                                                        <span class="ms-1 bluetext thin"
                                                            style="color:#a51d29;">(Required)</span>
                                                    </ng-container>
                                                </p>
                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;"></p>
                                                <div style="margin-left: 2.5rem;margin-top: 1rem;width: 437px;">
                                                    <div class="row d-xl-flex justify-content-xl-start"
                                                        style="padding-top: 0;width: 553px;min-width: 498px;">
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group"><input
                                                                    formControlName="complete" type="checkbox"
                                                                    (change)="typeOfSupplycheck('Complete')"
                                                                    id="completesply" class="btn-check form-check-input"
                                                                    name="flexRadioDefaultRFQ" /><label
                                                                    class="form-label form-check-label btn btn-outline-primary"
                                                                    for="completesply"
                                                                    style="border-radius: 40px;font-size: 14px;"
                                                                    name="flexRadioDefault">Complete</label></div>
                                                        </div>
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 offset-xl-0 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group"><input
                                                                    formControlName="rawMaterial" type="checkbox"
                                                                    (change)="typeOfSupplycheck('Raw Material')"
                                                                    id="ndivsply" class="btn-check form-check-input "
                                                                    name="flexRadioDefaultRFQ" /><label
                                                                    class="form-label btn btn-outline-primary"
                                                                    for="ndivsply"
                                                                    style="border-radius: 40px;font-size: 14px;">Raw
                                                                    Materials</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <!-- <div *ngIf="displayServices.length > 0"
                                            class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                            <div class="col" style="padding-left: 0;">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;"><i class="fa fa-asterisk uiicon"
                                                        style="font-size: 6px;color: #ff0000;"></i> Services Required
                                                    <ng-container
                                                        *ngIf="(f.supplierServiceSelect.touched && selectedservice.length == 0) || (rfqValidCond == false  && selectedservice.length == 0)">
                                                        <span class="ms-1 bluetext thin"
                                                            style="color:#a51d29;">(Required)</span>
                                                    </ng-container>
                                                </p>


                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;"></p>
                                                <div class="d-flex align-items-center"
                                                    style="height: 2rem;margin-left: 2rem;margin-top: 1rem;width: 18rem;">

                                                    <select formControlName="supplierServiceSelect" class="form-select">
                                                        <option [disabled]="true" value=null selected="">Select a
                                                            service</option>
                                                        <option
                                                            *ngFor="let service of allservices | sortAscDsc: criteria"
                                                            [value]="service.supplierServiceId">{{service.serviceName}}
                                                        </option>
                                                    </select>

                                                    <button [disabled]="f.supplierServiceSelect.value == null"
                                                        (click)="selectService(f.supplierServiceSelect.value)"
                                                        class="a d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                        role="button"
                                                        style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                            class="fa fa-plus-circle"
                                                            style="font-size: 20px;color: var(--maingreen);"></i> Add</button>

                                                </div>
                                            </div>
                                        </div> -->


                                        <!-- <div>
                                            <div *ngFor="let selectedvalue of allServiceDisplay"
                                                class="row d-flex d-xxl-flex align-items-center">
                                                <div class="col" style="padding-left: 0;">
                                                    <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                        style="margin-bottom: 0;"><i class=" uiicon"
                                                            style="font-size: 6px;color: #ff0000;"></i> {{selectedvalue.serviceName}}
                                                    </p>
                                                </div>
                                                <div class="col" style="padding-left: 0;">
                                                    <p class="d-flex d-sm-flex align-items-center justify-content-sm-start justify-content-xl-start bluetext"
                                                        style="margin-bottom: 0;"> <button
                                                            (click)="deleteService(selectedvalue.supplierServiceId)"
                                                            class="btn btn-link" role="button"><i
                                                                class="fa fa-minus-circle"
                                                                style="font-size: 20px;color: var(--maingreen);"></i></button>
                                                    </p>
                                                </div>

                                            </div>

                                        </div>
                                         -->

                                        <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                            <div class="col" style="padding-left: 0;">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;"><i class="fa fa-asterisk uiicon"
                                                        style="font-size: 6px;color: #ff0000;"></i>Submit drawings or
                                                    Dimensions

                                                    <ng-container *ngIf=" drawOrDim == null  && rfqValidCond == false">
                                                        <span class="ms-1 bluetext thin"
                                                            style="color:#a51d29;">(Required)</span>
                                                    </ng-container>

                                                </p>
                                            </div>
                                        </div>

                                        <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                            <div class="col" style="padding-left: 0;">

                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;"></p>
                                                <div style="margin-left: 2.5rem;margin-top: 0rem;width: 437px;">
                                                    <div class="row d-xl-flex justify-content-xl-start"
                                                        style="padding-top: 0;width: 553px;min-width: 498px;">
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group"><input
                                                                    formControlName="drawings" type="checkbox"
                                                                    (change)="draw_or_dim_check('Drawings')"
                                                                    id="drawingsbtn" class="btn-check form-check-input"
                                                                    name="flexRadioDefaultRFQ"><label
                                                                    class="form-label form-check-label btn btn-outline-primary"
                                                                    for="drawingsbtn"
                                                                    style="border-radius: 40px;font-size: 14px;"
                                                                    name="flexRadioDefault">Drawings</label></div>
                                                        </div>
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 offset-xl-0 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group"><input
                                                                    formControlName="dimensions" type="checkbox"
                                                                    (change)="draw_or_dim_check('Dimensions')"
                                                                    id="dimensionsbtn"
                                                                    class="btn-check form-check-input "
                                                                    name="flexRadioDefaultRFQ" /><label
                                                                    class="form-label btn btn-outline-primary"
                                                                    for="dimensionsbtn"
                                                                    style="border-radius: 40px;font-size: 14px;">Dimensions</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row d-xxl-flex rfqdetailrow">
                                            <div class="col" style="width: 100%;padding: 0px;">
                                                <div>
                                                    <ul role="tablist"
                                                        class="nav nav-tabs d-flex d-sm-flex d-xxl-flex justify-content-start justify-content-sm-start justify-content-xl-start justify-content-xxl-start"
                                                        style="border-width: 5px;border-color: var(--maingreen);">

                                                    </ul>
                                                    <div class="tab-content" style="height: auto;">
                                                        <!-- DRAWINGS TAB START-->
                                                        <div *ngIf="f.drawings.value" role="tabpanel"
                                                            class="tab-pane active" id="tab-1">
                                                            <div class="container-fluid rfqformcontainer">
                                                                <div
                                                                    class="row d-flex d-xxl-flex align-items-center justify-content-xxl-center rfqdetailrow">
                                                                    <div class="col">
                                                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 1rem;">File(s)
                                                                            <span
                                                                                *ngIf=" rfqValidCond == false && drawingFileArray.length == 0 && viewRfqFiles.length == 0"
                                                                                class="ms-1 bluetext thin"
                                                                                style="color:#a51d29;">(Required)</span>
                                                                        </p>


                                                                        <!-- 
                                                                        <div class="d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center dragdropblock"
                                                                            style="height: 2rem;margin-top: 1rem;width: 100%;border-width: 2px;border-style: dashed;">
                                                                            <p class="d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-start justify-content-xxl-center align-items-xxl-center bluetextthin"
                                                                                style="margin-bottom: 0;padding: 1rem;">
                                                                                Drag
                                                                                &amp; Drop or <a
                                                                                    class="btn btn-link btn-sm d-xxl-flex align-items-xxl-start"
                                                                                    role="button"
                                                                                    style="padding: 0px;">Browse</a></p>
                                                                        </div>
                                                                         -->



                                                                        <ng-container>

                                                                            <div class="container" appDnd
                                                                                (fileDropped)="onFileDropped($event)">
                                                                                <input accept="{{requiredFileType}}"
                                                                                    type="file" #fileDropRef
                                                                                    id="fileDropRef" multiple
                                                                                    (change)="fileBrowseHandler($any($event.target).files)" />
                                                                                <h3>Drag and drop file here</h3>
                                                                                <h3>or</h3>
                                                                                <label for="fileDropRef">Browse for
                                                                                    file</label>
                                                                            </div>

                                                                            <!-- <div *ngIf="drawFileUploading "
                                                                                class="text-center text-center bluehead thin"
                                                                                style="font-size: 14px;"><span
                                                                                    class="spinner-border spinner-border-sm mr-1"></span>
                                                                                Uploading...
                                                                            </div>

                                                                            <div *ngIf="drawFileDeleting "
                                                                                class="text-center text-center bluehead thin"
                                                                                style="font-size: 14px;"><span
                                                                                    class="spinner-border spinner-border-sm mr-1"></span>
                                                                                Deleting...
                                                                            </div> -->

                                                                            <div class="files-list">
                                                                                <div class="single-file"
                                                                                    *ngFor="let file of viewRfqFiles; let i = index">
                                                                                    <img src="assets/img/dnd/ic-file.svg"
                                                                                        width="45px" alt="file">
                                                                                    <div class="info">
                                                                                        <h4 class="name">
                                                                                            {{ file.fileName }}
                                                                                        </h4>
                                                                                    </div>
                                                                                    <img src="assets/img/dnd/ic-delete-file.svg"
                                                                                        class="delete" width="20px"
                                                                                        alt="file"
                                                                                        (click)="deleteExistingFile(file.rfqFileId)">
                                                                                </div>
                                                                            </div>
                                                                            <div class="files-list">
                                                                                <div class="single-file"
                                                                                    *ngFor="let file of drawingFileArray; let i = index">
                                                                                    <img src="assets/img/dnd/ic-file.svg"
                                                                                        width="45px" alt="file">
                                                                                    <div class="info">
                                                                                        <h4 class="name">
                                                                                            {{ file.fileName }}
                                                                                        </h4>
                                                                                    </div>
                                                                                    <img src="assets/img/dnd/ic-delete-file.svg"
                                                                                        class="delete" width="20px"
                                                                                        alt="file"
                                                                                        (click)="deleteNewFile(i,file)">
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <!-- <div class="files-list">
                                                                            <div class="single-file" *ngFor="let file of files; let i = index">
                                                                                <img src="assets/img/dnd/ic-file.svg" width="45px" alt="file">
                                                                                <div class="info">
                                                                                    <h4 class="name">
                                                                                        {{ file?.name }}
                                                                                    </h4>
                                                                                    <p class="size">
                                                                                        {{ formatBytes(file?.size) }}
                                                                                    </p>
                                                                                </div>
                                                                                <img src="assets/img/dnd/ic-delete-file.svg" class="delete" width="20px" alt="file"
                                                                                    (click)="deleteFile(i,file)">
                                                                            </div>
                                                                        </div> -->
                                                                    </div>
                                                                </div>

                                                                <!-- <div
                                                                    class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                                                    <div class="col" style="padding-left: 0;">
                                                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;"><i
                                                                                class="fa fa-asterisk uiicon"
                                                                                style="font-size: 6px;color: #ff0000;"></i>Difference
                                                                            on
                                                                            specific parts?</p>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;"></p>
                                                                        <div
                                                                            style="margin-left: 2.5rem;margin-top: 1rem;width: 437px;">
                                                                            <div class="row d-xl-flex justify-content-xl-start"
                                                                                style="padding-top: 0;width: 553px;min-width: 498px;">
                                                                                <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-md-flex justify-content-md-center align-items-md-center"
                                                                                    style="padding: 0;">
                                                                                    <div class="btn-group bluebtn"
                                                                                        role="group"
                                                                                        aria-label="Basic checkbox toggle button group">
                                                                                        <input type="checkbox"
                                                                                            id="yesdiff"
                                                                                            formControlName="diffpartsYes"
                                                                                            (change)="containdiffpartcheck('Yes')"
                                                                                            class="btn-check form-check-input"
                                                                                            name="diffparts" /><label
                                                                                            class="form-label form-check-label btn btn-outline-primary"
                                                                                            for="yesdiff"
                                                                                            style="border-radius: 40px;font-size: 14px;"
                                                                                            name="flexRadioDefault2">Yes</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 offset-xl-0 d-md-flex justify-content-md-center align-items-md-center"
                                                                                    style="padding: 0;">
                                                                                    <div class="btn-group bluebtn"
                                                                                        role="group"
                                                                                        aria-label="Basic checkbox toggle button group">
                                                                                        <input type="checkbox"
                                                                                            id="nodiff"
                                                                                            formControlName="diffpartsNo"
                                                                                            (change)="containdiffpartcheck('No')"
                                                                                            class="btn-check form-check-input"
                                                                                            name="diffparts" /><label
                                                                                            class="form-label btn btn-outline-primary"
                                                                                            for="nodiff"
                                                                                            style="border-radius: 40px;font-size: 14px;">No</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> -->
                                                                <div *ngIf="allservices.length > 0 "
                                                                    class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                                                    <div class="col" style="padding-left: 0;">
                                                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;"><i
                                                                                class="fa fa-asterisk uiicon"
                                                                                style="font-size: 6px;color: #ff0000;"></i> Services
                                                                            Required
                                                                            <ng-container
                                                                                *ngIf="typeOfSupply == 'Complete' && ((f.supplierServiceSelect.touched && selectedservice.length == 0) || (rfqValidCond == false  && selectedservice.length == 0))">
                                                                                <span class="ms-1 bluetext thin"
                                                                                    style="color:#a51d29;">(Required)</span>
                                                                            </ng-container>
                                                                        </p>


                                                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;"></p>
                                                                        <div *ngIf=" typeOfSupply == 'Complete'"
                                                                            class="d-flex align-items-center"
                                                                            style="height: 2rem;margin-left: 2rem;margin-top: 1rem;width: 18rem;">

                                                                            <select
                                                                                formControlName="supplierServiceSelect"
                                                                                class="form-select">

                                                                                <option [disabled]="true" value=null
                                                                                    selected="">Select a
                                                                                    service</option>
                                                                                <option
                                                                                    *ngFor="let service of allservices | sortAscDsc: criteria"
                                                                                    [value]="service.supplierServiceId">
                                                                                    {{service.serviceName}}
                                                                                </option>

                                                                            </select>
                                                                            <div></div>
                                                                            <!-- <button [disabled]="f.supplierServiceSelect.value == null"
                                                                                (click)="selectService()" class="btn btn-link" role="button"
                                                                                style="margin: 0px;padding: 0px;"><i
                                                                                    class="fa fa-plus-circle uiicon"
                                                                                    style="font-size: 20px;color: var(--maingreen);"></i> Add
                                                                            </button> -->

                                                                            <button
                                                                                [disabled]="f.supplierServiceSelect.value == null"
                                                                                (click)="selectService(f.supplierServiceSelect.value)"
                                                                                class="a d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                                                role="button"
                                                                                style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                                                    class="fa fa-plus-circle"
                                                                                    style="font-size: 20px;color: var(--maingreen);"></i> Add</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div *ngFor="let selectedvalue of allServiceDisplay"
                                                                        class="row d-flex d-xxl-flex align-items-center">
                                                                        <div class="col" style="padding-left: 0;">
                                                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                                                style="margin-bottom: 0;"><i
                                                                                    class=" uiicon"
                                                                                    style="font-size: 6px;color: #ff0000;"></i> {{selectedvalue.serviceName}}
                                                                            </p>
                                                                        </div>
                                                                        <div class="col" style="padding-left: 0;">
                                                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start justify-content-xl-start bluetext"
                                                                                style="margin-bottom: 0;"> <button
                                                                                    (click)="deleteService(selectedvalue.supplierServiceId)"
                                                                                    class="btn btn-link"
                                                                                    role="button"><i
                                                                                        class="fa fa-minus-circle"
                                                                                        style="font-size: 20px;color: var(--maingreen);"></i></button>

                                                                            </p>
                                                                        </div>

                                                                    </div>
                                                                    <ng-container
                                                                        *ngIf="typeOfSupply == 'Raw Material'">
                                                                        <div *ngFor="let selectedvalue of RawMaterialServices"
                                                                            class="row d-flex d-xxl-flex align-items-center">
                                                                            <div class="col" style="padding-left: 0;">
                                                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                                                    style="margin-bottom: 0;"><i
                                                                                        class=" uiicon"
                                                                                        style="font-size: 6px;color: #ff0000;"></i> {{selectedvalue.serviceName}}
                                                                                </p>
                                                                            </div>


                                                                        </div>
                                                                    </ng-container>

                                                                </div>

                                                                <div
                                                                    class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                                                    <div class="col" style="padding-left: 0;">
                                                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;"><i
                                                                                class="fa fa-asterisk uiicon"
                                                                                style="font-size: 6px;color: #ff0000;"></i>Changes
                                                                            to be made on
                                                                            all drawings
                                                                            <span
                                                                                *ngIf="(f.dchanges.touched && f.dchanges.value.length > 500) || (rfqValidCond == false  && f.dchanges.value.length > 500)"
                                                                                class="ms-1 bluetext thin"
                                                                                style="color:#a51d29;">(Exceeds Max
                                                                                Length)</span>
                                                                        </p>
                                                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;"></p>
                                                                        <div style="margin-top: 1rem;width: 100%;">
                                                                            <div
                                                                                style="height: auto;margin-left: 2rem;margin-top: 1rem;width: 25rem;">
                                                                                <textarea formControlName="dchanges"
                                                                                    class="form-control"
                                                                                    style="min-height: 70px; height: 70px; max-height: 70px;"></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="row rfqdetailrow">
                                                                    <div class="col"
                                                                        style="padding-left: 0;width: 30%;">
                                                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;width: auto;"><i
                                                                                class="fa fa-asterisk uiicon"
                                                                                style="font-size: 6px;color: #ff0000;"></i> Part
                                                                            No.</p>
                                                                        <div
                                                                            style="margin-left: 2rem;margin-top: 1rem;height: 2rem;width: auto;">
                                                                            <input type="text"
                                                                                class="form-control form-control"
                                                                                inputmode="numeric" autocomplete="off"
                                                                                placeholder="0" style="width: 180px;" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-xxl-5"
                                                                        style="padding-left: 0;width: 50%;">
                                                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;width: auto;margin-left: -1rem;">
                                                                            <i class="fa fa-asterisk uiicon"
                                                                                style="font-size: 6px;color: #ff0000;"></i> Number
                                                                            of parts
                                                                        </p>
                                                                        <div
                                                                            style="margin-top: 1rem;height: 2rem;width: auto;margin-left: 1rem;">
                                                                            <input type="text"
                                                                                class="form-control form-control"
                                                                                inputmode="numeric" autocomplete="off"
                                                                                placeholder="0" style="width: 189px;" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row rfqdetailrow">
                                                                    <div class="col"
                                                                        style="padding-left: 0;width: 30%;">
                                                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;width: auto;"><i
                                                                                class="fa fa-asterisk uiicon"
                                                                                style="font-size: 6px;color: #ff0000;"></i> Dimensions
                                                                        </p>
                                                                        <div
                                                                            style="margin-left: 2rem;margin-top: 1rem;height: 2rem;width: auto;">
                                                                            <input type="text"
                                                                                class="form-control form-control"
                                                                                inputmode="numeric" autocomplete="off"
                                                                                placeholder="0" style="width: 180px;" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-xxl-5"
                                                                        style="padding-left: 0;width: 50%;">
                                                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;width: auto;margin-left: -1rem;">
                                                                            <i class="fa fa-asterisk uiicon"
                                                                                style="font-size: 6px;color: #ff0000;"></i> Material
                                                                            Grade
                                                                        </p>
                                                                        <div
                                                                            style="margin-top: 1rem;height: 2rem;width: auto;margin-left: 1rem;">
                                                                            <input type="text"
                                                                                class="form-control form-control"
                                                                                inputmode="numeric" autocomplete="off"
                                                                                placeholder="0" style="width: 189px;" />
                                                                        </div>
                                                                    </div>
                                                                </div> -->
                                                                <div class="d-flex d-md-flex justify-content-end justify-content-sm-end justify-content-md-end"
                                                                    style="height: 2rem;width: 100%;">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- DRAWINGS TAB END-->

                                                        <!-- DIMENSIONS TAB START-->
                                                        <div *ngIf="f.dimensions.value" role="tabpanel"
                                                            class="tab-pane active " id="tab-2">
                                                            <div class="container-fluid rfqformcontainer">
                                                                <div
                                                                    class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                                                    <div class="col" style="padding-left: 0;">
                                                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;"><i
                                                                                class="fa fa-asterisk uiicon"
                                                                                style="font-size: 7px;color: #ff0000;"></i>
                                                                            Dimensions:

                                                                            <ng-container
                                                                                *ngIf=" (dimensionNotSubmittedCheck() && rfqValidCond == false) && dimensionsUI.length > 0">
                                                                                <span class="ms-1 bluetext thin"
                                                                                    style="color:#a51d29;">(Clear
                                                                                    Dimension Fields If Not Applicable,
                                                                                    Before Continuing)</span>
                                                                            </ng-container>
                                                                            <span
                                                                                *ngIf=" dimensionsUI.length == 0  && rfqValidCond == false"
                                                                                class="ms-1 bluetext thin"
                                                                                style="color:#a51d29;">(Required)</span>



                                                                        </p>

                                                                        <button
                                                                            class="btn yellowbtn d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                                            style="border-radius: 50px; padding-top: 5px; margin-left:25px; padding-bottom: 5px; margin-bottom:5px;  margin-top:5px;"
                                                                            (click)="clearDimensions()">Clear
                                                                            All</button>
                                                                    </div>
                                                                </div>

                                                                <div class="row rfqdetailrow">
                                                                    <div class="col"
                                                                        style="padding-left: 0;width: 30%; padding-bottom: 10px;">
                                                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;width: auto;">
                                                                            <i class="fa fa-asterisk uiicon"
                                                                                style="font-size: 6px;color: #ff0000;"></i>
                                                                            Part Number

                                                                            <ng-container
                                                                                *ngIf="(f.partNo.touched && stringValidation(f.partNo.value)) || (stringValidation(f.partNo.value) && rfqValidCond == false && dimensionsUI.length == 0) || ( stringValidation(f.partNo.value) && dimensionsValidCond == false)">
                                                                                <span class="ms-1 bluetext thin"
                                                                                    style="color:#a51d29;">(Required)</span>
                                                                            </ng-container>

                                                                            <ng-container
                                                                                *ngIf="(f.partNo.touched && f.partNo.value.length > 50 ) || (f.partNo.value.length > 50 && rfqValidCond == false && dimensionsUI.length == 0) || ( f.partNo.value.length > 50 && dimensionsValidCond == false)">
                                                                                <span class="ms-1 bluetext thin"
                                                                                    style="color:#a51d29;">(Exceeds Max
                                                                                    Length)</span>
                                                                            </ng-container>

                                                                            <ng-container
                                                                                *ngIf="uniquePartNoCheckRealTime(f.partNo.value)">
                                                                                <span class="ms-1 bluetext thin"
                                                                                    style="color:#a51d29;">(Not
                                                                                    Unique)</span>
                                                                            </ng-container>
                                                                        </p>

                                                                        <!-- <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                                        style="margin-bottom: 0; color:#000000; font-size: 13px!Important;">(Format: LENGTH x WIDTH x HEIGHT)
                                                                    </p>

                                                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                                        style="margin-bottom: 0; color:#000000; font-size: 13px!Important;">(Example: 123x123x123 Or 123.456x123.456x123.456)
                                                                    </p> -->

                                                                        <div
                                                                            style="margin-left: 2rem;margin-top: 1rem;height: 2rem;width: auto;">
                                                                            <input formControlName="partNo"
                                                                                placeholder="" type="text"
                                                                                class="form-control form-control"
                                                                                inputmode="numeric" autocomplete="off"
                                                                                style="font-size: 18px;" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="row rfqdetailrow">
                                                                    <div class="col"
                                                                        style="padding-left: 0;width: 30%;">
                                                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;width: auto;"><i
                                                                                class="fa fa-asterisk uiicon"
                                                                                style="font-size: 6px;color: #ff0000;"></i> 
                                                                            Type

                                                                            <!-- TOOLTIP -->
                                                                            <span
                                                                                data-tooltip="General steel product range to suit your requirements.">
                                                                                <i class="fa fa-question-circle"
                                                                                    style="margin-left:5px;color: var(--maingreen);font-size: 12px;"></i></span>

                                                                            <ng-container
                                                                                *ngIf="(f.dimensionType.touched &&  stringValidation(f.dimensionType.value)) || (stringValidation(f.dimensionType.value) && rfqValidCond == false && dimensionsUI.length == 0) || ( stringValidation(f.dimensionType.value) && dimensionsValidCond == false)">
                                                                                <span class="ms-1 bluetext thin"
                                                                                    style="color:#a51d29;">(Required)</span>
                                                                            </ng-container>

                                                                            <ng-container
                                                                                *ngIf="(f.dimensionType.touched && f.dimensionType.value.length > 25 ) || (f.dimensionType.value.length > 25 && rfqValidCond == false && dimensionsUI.length == 0) || ( f.dimensionType.value.length > 25 && dimensionsValidCond == false)">
                                                                                <span class="ms-1 bluetext thin"
                                                                                    style="color:#a51d29;">(Exceeds Max
                                                                                    Length)</span>
                                                                            </ng-container>


                                                                        </p>
                                                                        <div
                                                                            style="margin-left: 2rem;margin-top: 1rem;height: 2rem;width: auto;">
                                                                            <input type="text"
                                                                                formControlName="dimensionType"
                                                                                class="form-control form-control"
                                                                                inputmode="numeric" autocomplete="off"
                                                                                placeholder="Ex. Angle , Beams etc."
                                                                                style="width: 189px;" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-xxl-5"
                                                                        style="padding-left: 0;width: 50%;">
                                                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;width: auto;margin-left: -1rem;">
                                                                            <i class="fa fa-asterisk uiicon"
                                                                                style="font-size: 6px;color: #ff0000;"></i> Quantity
                                                                            (Units)
                                                                            <ng-container
                                                                                *ngIf="(f.numberOfParts.touched && f.numberOfParts.errors) || (f.numberOfParts.errors && rfqValidCond == false && dimensionsUI.length == 0) || ( f.numberOfParts.errors && dimensionsValidCond == false)">
                                                                                <span
                                                                                    *ngIf="f.numberOfParts.errors.required"
                                                                                    class="ms-1 bluetext thin"
                                                                                    style="color:#a51d29;">(Required)</span>
                                                                            </ng-container>

                                                                            <ng-container
                                                                                *ngIf=" quantityValidation(f.numberOfParts.value) ">
                                                                                <span class="ms-1 bluetext thin"
                                                                                    style="color:#a51d29;">(Invalid)</span>
                                                                            </ng-container>

                                                                            <ng-container
                                                                                *ngIf=" f.numberOfParts.value > 1000000 ">
                                                                                <span class="ms-1 bluetext thin"
                                                                                    style="color:#a51d29;">(Exceeds Max
                                                                                    Quantity)</span>
                                                                            </ng-container>

                                                                        </p>
                                                                        <div
                                                                            style="margin-top: 1rem;height: 2rem;width: auto;margin-left: 1rem;">
                                                                            <input formControlName="numberOfParts"
                                                                                type="number" min=1
                                                                                class="form-control form-control"
                                                                                inputmode="numeric" autocomplete="off"
                                                                                placeholder="" style="width: 189px;" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="row rfqdetailrow">
                                                                    <!-- <div class="col"
                                                                        style="padding-left: 0;width: 30%;">
                                                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;width: auto;"><i
                                                                                class="fa fa-asterisk uiicon"
                                                                                style="font-size: 6px;color: #ff0000;"></i> Dimensions
                                                                            (mm)
                                                                            <ng-container
                                                                                *ngIf="(f.dimesions_d.touched && stringValidation(f.dimesions_d.value)) || (stringValidation(f.dimesions_d.value) && rfqValidCond == false && dimensionsUI.length == 0) || ( stringValidation(f.dimesions_d.value) && dimensionsValidCond == false)">
                                                                                <span
                                                                                 
                                                                                    class="ms-1 bluetext thin"
                                                                                    style="color:#a51d29;">(Required)</span>
                                                                            </ng-container>

                                                                            <ng-container
                                                                                *ngIf="f.dimesions_d.touched && !dimValidCond && !stringValidation(f.dimesions_d.value) ">
                                                                                <span
                                                                                 
                                                                                    class="ms-1 bluetext thin"
                                                                                    style="color:#a51d29;">(Invalid)</span>
                                                                            </ng-container>

                                                                        </p>
                                                                        <div
                                                                            style="margin-left: 2rem;margin-top: 1rem;height: 2rem;width: auto;">
                                                                            <input formControlName="dimesions_d"
                                                                                type="text" (ngModelChange) = "dimensionsRectangularCheck(f.dimesions_d.value)"
                                                                                class="form-control form-control"
                                                                                inputmode="numeric" autocomplete="off"
                                                                                placeholder="LENGTH X WIDTH X HEIGHT"
                                                                                style=" height: 37.53px; width: 189px; font-size: 12px;" />
                                                                        </div>
                                                                    </div> -->

                                                                    <div class="col-lg-6 col-xxl-5"
                                                                        style="padding-left: 50;width: 50%;">
                                                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;width: auto;margin-left: -1rem;">
                                                                            <i class="fa fa-asterisk uiicon"
                                                                                style="font-size: 6px;color: #ff0000;"></i>
                                                                            Service Required

                                                                            <ng-container
                                                                                *ngIf="((f.dimensionSupplierService.touched &&  f.dimensionSupplierService.value == null) || ( f.dimensionSupplierService.value == null && rfqValidCond == false && dimensionsUI.length == 0) || ( f.dimensionSupplierService.value == null && dimensionsValidCond == false) )&& typeOfSupply != 'Raw Material' ">
                                                                                <span class="ms-1 bluetext thin"
                                                                                    style="color:#a51d29;">(Required)</span>
                                                                            </ng-container>
                                                                        </p>
                                                                        <div *ngIf=" typeOfSupply == 'Complete'"
                                                                            style="margin-top: 1rem;height: 2rem;width: auto;margin-left: 1rem;">

                                                                            <select
                                                                                formControlName="dimensionSupplierService"
                                                                                style="width: 189px;"
                                                                                class="form-select">
                                                                                <option [disabled]="true" value=null
                                                                                    selected="">Select a
                                                                                    service</option>
                                                                                <option
                                                                                    *ngFor="let service of dimensionServices | sortAscDsc: criteria"
                                                                                    [value]="service.supplierServiceId">
                                                                                    {{service.serviceName}}
                                                                                </option>
                                                                            </select>

                                                                            <!-- <input formControlName="dimensionSupplierService"
                                                                            type="text"
                                                                            class="form-control form-control"
                                                                            inputmode="numeric" autocomplete="off"
                                                                            style="width: 189px;" /> -->

                                                                        </div>
                                                                        <ng-container
                                                                            *ngIf="typeOfSupply == 'Raw Material'">
                                                                            <div *ngFor="let selectedvalue of RawMaterialServices"
                                                                                class="row d-flex d-xxl-flex align-items-start">
                                                                                <div class="col"
                                                                                    style="padding-left: 0;">


                                                                                    <span
                                                                                        class="badge rounded-pill bluetextthin"
                                                                                        style="margin-left:25px ; background-color: var(--darkblue);">
                                                                                         {{selectedvalue.serviceName}}
                                                                                    </span>



                                                                                </div>

                                                                            </div>
                                                                        </ng-container>
                                                                    </div>

                                                                    <div class="col-lg-6 col-xxl-5"
                                                                        style="padding-left: 0;width: 50%;">
                                                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;width: auto;margin-left: -1rem;">
                                                                            <i class="fa fa-asterisk uiicon"
                                                                                style="font-size: 6px;color: #ff0000;"></i> Material
                                                                            Grade
                                                                            <ng-container
                                                                                *ngIf="(f.materialGrade.touched &&  stringValidation(f.materialGrade.value)) || (stringValidation(f.materialGrade.value) && rfqValidCond == false && dimensionsUI.length == 0) || ( stringValidation(f.materialGrade.value) && dimensionsValidCond == false)">
                                                                                <span class="ms-1 bluetext thin"
                                                                                    style="color:#a51d29;">(Required)</span>
                                                                            </ng-container>

                                                                            <ng-container
                                                                                *ngIf="(f.materialGrade.touched && f.materialGrade.value.length > 25 ) || (f.materialGrade.value.length > 25 && rfqValidCond == false && dimensionsUI.length == 0) || ( f.materialGrade.value.length > 25 && dimensionsValidCond == false)">
                                                                                <span class="ms-1 bluetext thin"
                                                                                    style="color:#a51d29;">(Exceeds Max
                                                                                    Length)</span>
                                                                            </ng-container>

                                                                        </p>
                                                                        <div
                                                                            style="margin-top: 1rem;height: 2rem;width: auto;margin-left: 1rem;">
                                                                            <input formControlName="materialGrade"
                                                                                type="text"
                                                                                class="form-control form-control"
                                                                                inputmode="numeric" autocomplete="off"
                                                                                style="width: 189px;" />
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <div class="row rfqdetailrow">

                                                                </div>

                                                                <div class="row rfqdetailrow">
                                                                    <div class="col"
                                                                        style="padding-left: 0;width: 30%; padding-bottom: 10px;">
                                                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                                            style="margin-bottom: 0;width: auto;">
                                                                            <i class="fa fa-asterisk uiicon"
                                                                                style="font-size: 6px;color: #ff0000;"></i>
                                                                            Dimensions (mm)

                                                                            <ng-container
                                                                                *ngIf="(f.dimesions_d.touched && f.dimesions_d.value.length > 25) || (f.dimesions_d.value.length > 25 && rfqValidCond == false && dimensionsUI.length == 0) || ( f.dimesions_d.value.length > 25 && dimensionsValidCond == false)">
                                                                                <span class="ms-1 bluetext thin"
                                                                                    style="color:#a51d29;">(Exceeds Max
                                                                                    Length)</span>
                                                                            </ng-container>

                                                                            <ng-container
                                                                                *ngIf="(f.dimesions_d.touched && stringValidation(f.dimesions_d.value)) || (stringValidation(f.dimesions_d.value) && rfqValidCond == false && dimensionsUI.length == 0) || ( stringValidation(f.dimesions_d.value) && dimensionsValidCond == false)">
                                                                                <span class="ms-1 bluetext thin"
                                                                                    style="color:#a51d29;">(Required)</span>
                                                                            </ng-container>

                                                                            <!-- <ng-container
                                                                                *ngIf="f.dimesions_d.touched && !dimValidCond && !stringValidation(f.dimesions_d.value) ">
                                                                                <span
                                                                                 
                                                                                    class="ms-1 bluetext thin"
                                                                                    style="color:#a51d29;">(Invalid)</span>
                                                                            </ng-container> -->
                                                                        </p>
                                                                        <!-- 
                                                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                                        style="margin-bottom: 0; color:#000000; font-size: 13px!Important;">(Format: LENGTH x WIDTH x HEIGHT)
                                                                    </p>

                                                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                                        style="margin-bottom: 0; color:#000000; font-size: 13px!Important;">(Example: 123x123x123 Or 123.456x123.456x123.456)
                                                                    </p> -->

                                                                        <div
                                                                            style="margin-left: 2rem;margin-top: 1rem;height: 2rem;width: auto;">
                                                                            <input formControlName="dimesions_d"
                                                                                placeholder="LENGTH x WIDTH x HEIGHT"
                                                                                type="text"
                                                                                class="form-control form-control"
                                                                                inputmode="numeric" autocomplete="off"
                                                                                style="font-size: 18px;" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="d-flex d-md-flex justify-content-end justify-content-sm-end justify-content-md-end"
                                                                    style="height: 2rem;width: 100%;">


                                                                    <button (click)="clearDimensionInputs()"
                                                                        class="a d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                                        role="button"
                                                                        style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                                            class="fa fa-minus-circle"
                                                                            style="font-size: 20px;color: var(--maingreen);"></i>Clear</button>

                                                                    <button
                                                                        (click)="dimensions_block_add(f.partNo.value,this.f.dimensionType.value,f.numberOfParts.value,f.dimesions_d.value,f.materialGrade.value, f.dimensionSupplierService.value)"
                                                                        class="a d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                                        role="button" style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color:
                                                                        var(--darkblue);"><i class="fa fa-plus-circle"
                                                                            style="font-size: 20px;color: var(--maingreen);"></i> Add</button>
                                                                </div>

                                                                <div id="partsblockrender">
                                                                    <div *ngFor="let d of dimensionsUI"
                                                                        class="row d-flex d-xxl-flex align-items-center"
                                                                        style="padding-bottom:1rem ;">



                                                                        <div class="col" style="padding-left: 0;">
                                                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext "
                                                                                style="margin-bottom: 0;">
                                                                                <i class=" uiicon bluetext"
                                                                                    style="font-size: 6px;color: #ff0000;"></i>
                                                                                Part No:
                                                                                <span class="bluetext thin"
                                                                                    style="padding-left: 2px;">
                                                                                    {{d.PartNo}}</span>
                                                                            </p>

                                                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext "
                                                                                style="margin-bottom: 0;">
                                                                                <i class=" uiicon bluetext"
                                                                                    style="font-size: 6px;color: #ff0000;"></i>
                                                                                Type:













                                                                                <span class="bluetext thin"
                                                                                    style="margin-left: 3px;">
                                                                                    {{d.Type}}</span>
                                                                            </p>



                                                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext "
                                                                                style="margin-bottom: 0;">
                                                                                <i class=" uiicon bluetext"
                                                                                    style="font-size: 6px;color: #ff0000;"></i>
                                                                                Quantity:
                                                                                <span class="bluetext thin"
                                                                                    style="padding-left: 2px;">
                                                                                    {{d.Quantity}}</span>
                                                                            </p>

                                                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext "
                                                                                style="margin-bottom: 0;">
                                                                                <i class=" uiicon bluetext"
                                                                                    style="font-size: 6px;color: #ff0000;"></i>
                                                                                Dimensions:
                                                                                <span class="bluetext thin"
                                                                                    style="padding-left: 2px;">
                                                                                    {{d.Dimensions}}</span>
                                                                            </p>

                                                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext "
                                                                                style="margin-bottom: 0;">
                                                                                <i class=" uiicon bluetext"
                                                                                    style="font-size: 6px;color: #ff0000;"></i>
                                                                                Material Grade:
                                                                                <span class="bluetext thin"
                                                                                    style="padding-left: 2px;">
                                                                                    {{d.MaterialGrade}}</span>
                                                                            </p>

                                                                            <p class="text-nowrap d-flex d-sm-flex align-items-center justify-content-sm-start bluetext "
                                                                                style="margin-bottom: 0;">
                                                                                <i class=" uiicon bluetext"
                                                                                    style="font-size: 6px;color: #ff0000;"></i>
                                                                                Service Required:
                                                                                <span *ngIf="typeOfSupply == 'Complete'"
                                                                                    class="bluetext thin"
                                                                                    style="padding-left: 2px;">
                                                                                    <ng-container
                                                                                        *ngFor="let service of dimensionServices">
                                                                                        <ng-container
                                                                                            *ngIf="d.DimensionSupplierServiceId == service.supplierServiceId">
                                                                                            <span
                                                                                                class="badge rounded-pill bluetextthin"
                                                                                                style="background-color: var(--darkblue);">
                                                                                                {{service.serviceName}}
                                                                                            </span>
                                                                                        </ng-container>
                                                                                    </ng-container>

                                                                                </span>
                                                                                <span
                                                                                    *ngIf="typeOfSupply == 'Raw Material'"
                                                                                    class="bluetext thin"
                                                                                    style="padding-left: 2px;">
                                                                                    <ng-container
                                                                                        *ngFor="let service of RawMaterialServices">
                                                                                        <ng-container
                                                                                            *ngIf="d.DimensionSupplierServiceId == service.supplierServiceId">
                                                                                            <span
                                                                                                class="badge rounded-pill bluetextthin"
                                                                                                style="margin-left:3px ; background-color: var(--darkblue);">
                                                                                                {{service.serviceName}}
                                                                                            </span>
                                                                                        </ng-container>
                                                                                    </ng-container>

                                                                                </span>
                                                                            </p>

                                                                        </div>
                                                                        <div class="col" style="padding-left: 0;">
                                                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start justify-content-xl-start bluetext"
                                                                                style="margin-bottom: 0;"> 
                                                                                <button
                                                                                    (click)="editpartgroup(d.PartNo)"
                                                                                    class="btn btn-link"
                                                                                    role="button"><i
                                                                                        class="fa fa-pencil"
                                                                                        style="font-size: 20px;color: var(--maingreen);"></i></button>




                                                                                <button
                                                                                    (click)="Parts_group_delete(d.PartNo)"
                                                                                    class="btn btn-link"
                                                                                    role="button"><i
                                                                                        class="fa fa-minus-circle"
                                                                                        style="font-size: 20px;color: var(--maingreen);"></i></button>


                                                                            </p>


                                                                        </div>

                                                                        <ul role="tablist"
                                                                            class="nav nav-tabs d-flex d-flex d-flex justify-content-start justify-content-start justify-content-start justify-content-start"
                                                                            style="padding-top:0.5rem;border-width: 3px;border-color: var(--maingreen);">

                                                                        </ul>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <!-- DIMENSIONS TAB END-->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                            <div class="col" style="padding-left: 0;">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;"><i class="fa fa-asterisk uiicon"
                                                        style="font-size: 6px;color: #ff0000;"></i>Delivery Requirement
                                                    <ng-container
                                                        *ngIf=" deliverOrCollection == null  && rfqValidCond == false">
                                                        <span class="ms-1 bluetext thin"
                                                            style="color:#a51d29;">(Required)</span>
                                                    </ng-container>
                                                </p>
                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;"></p>
                                                <div style="margin-left: 2.5rem;margin-top: 1rem;width: 437px;">
                                                    <div class="row d-xl-flex justify-content-xl-start"
                                                        style="padding-top: 0;width: 553px;min-width: 498px;">
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group">
                                                                <input formControlName="delivery" type="checkbox"
                                                                    (change)="deliveryOrCollection('Delivery')"
                                                                    id="completesply12"
                                                                    class="btn-check form-check-input"
                                                                    name="flexRadioDefaultRFQ" /><label
                                                                    class="form-label form-check-label btn btn-outline-primary"
                                                                    for="completesply12"
                                                                    style="border-radius: 40px;font-size: 14px;"
                                                                    name="flexRadioDefault">Delivery</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 offset-xl-0 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group"><input
                                                                    formControlName="collection" type="checkbox"
                                                                    (change)="deliveryOrCollection('Collection')"
                                                                    id="ndivsply12" class="btn-check form-check-input "
                                                                    name="flexRadioDefaultRFQ" /><label
                                                                    class="form-label btn btn-outline-primary"
                                                                    for="ndivsply12"
                                                                    style="border-radius: 40px;font-size: 14px;">Collection</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="deliverOrCollection == 'Delivery'"
                                            class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                            <div class="col" style="padding-left: 0;">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;"><i class="fa fa-asterisk uiicon"
                                                        style="font-size: 6px;color: #ff0000;"></i>Is The Delivery
                                                    Address
                                                    The Same The Company Address?
                                                    <ng-container
                                                        *ngIf=" differentDeliveryAddressCond == null  && rfqValidCond == false">
                                                        <span class="ms-1 bluetext thin"
                                                            style="color:#a51d29;">(Required)</span>
                                                    </ng-container>
                                                </p>
                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;"></p>
                                                <div style="margin-left: 2.5rem;margin-top: 1rem;width: 437px;">
                                                    <div class="row d-xl-flex justify-content-xl-start"
                                                        style="padding-top: 0;width: 553px;min-width: 498px;">
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group">
                                                                <input formControlName="deliveryAddressDifYes"
                                                                    type="checkbox"
                                                                    (change)="deliveryAddressDifferent('Yes')"
                                                                    id="completesply12yes"
                                                                    class="btn-check form-check-input"
                                                                    name="flexRadioDefaultRFQ" /><label
                                                                    class="form-label form-check-label btn btn-outline-primary"
                                                                    for="completesply12yes"
                                                                    style="border-radius: 40px;font-size: 14px;"
                                                                    name="flexRadioDefault">Yes</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 offset-xl-0 d-md-flex justify-content-md-center align-items-md-center"
                                                            style="padding: 0;">
                                                            <div class="btn-group bluebtn" role="group"
                                                                aria-label="Basic checkbox toggle button group"><input
                                                                    formControlName="deliveryAddressDifNo"
                                                                    type="checkbox"
                                                                    (change)="deliveryAddressDifferent('No')"
                                                                    id="ndivsply12no"
                                                                    class="btn-check form-check-input "
                                                                    name="flexRadioDefaultRFQ" /><label
                                                                    class="form-label btn btn-outline-primary"
                                                                    for="ndivsply12no"
                                                                    style="border-radius: 40px;font-size: 14px;">No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="differentDeliveryAddressCond == 'No' && f.delivery.value"
                                            class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                            <div class="col" style="padding-left: 0;">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;"><i class="fa fa-asterisk uiicon"
                                                        style="font-size: 6px;color: #ff0000;"></i>Delivery Address</p>
                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;">
                                                </p>
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;padding-left: 30px; padding-top: 15px;"><i
                                                        class="fa fa-asterisk uiicon"
                                                        style="font-size: 6px;color: #ff0000;"></i>Street Address
                                                    <ng-container
                                                        *ngIf="(differentDeliveryAddressCond == 'No' && f.deliveryStreetAddress.touched && stringValidation(f.deliveryStreetAddress.value)) || ( differentDeliveryAddressCond == 'No' && rfqValidCond == false  && stringValidation(f.deliveryStreetAddress.value) )">
                                                        <span class="ms-1 bluetext thin"
                                                            style="color:#a51d29;">(Required)</span>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="(differentDeliveryAddressCond == 'No' && f.deliveryStreetAddress.touched && f.deliveryStreetAddress.value.length > 30 ) || ( differentDeliveryAddressCond == 'No' && rfqValidCond == false  && f.deliveryStreetAddress.value.length > 30)">
                                                        <span class="ms-1 bluetext thin" style="color:#a51d29;">(Exceeds
                                                            Max Length)</span>
                                                    </ng-container>

                                                </p>
                                                <div
                                                    style="height: 2rem;margin-left: 2rem;margin-top: 0.5rem;width: 18rem;">
                                                    <input formControlName="deliveryStreetAddress"
                                                        placeholder="Street Address" type="text" class="form-control"
                                                        autocomplete="off" style="width: 315px;" />
                                                </div>
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;padding-left: 30px; padding-top: 10px;"><i
                                                        class="fa fa-asterisk uiicon"
                                                        style="font-size: 6px;color: #ff0000;"></i>City

                                                    <ng-container
                                                        *ngIf="(differentDeliveryAddressCond == 'No' && f.deliveryCity.touched && stringValidation(f.deliveryCity.value)) || ( differentDeliveryAddressCond == 'No' && rfqValidCond == false  && stringValidation(f.deliveryCity.value) )">
                                                        <span class="ms-1 bluetext thin"
                                                            style="color:#a51d29;">(Required)</span>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="(differentDeliveryAddressCond == 'No' && f.deliveryCity.touched && f.deliveryCity.value.length > 30) || ( differentDeliveryAddressCond == 'No' && rfqValidCond == false  && f.deliveryCity.value.length > 30)">
                                                        <span class="ms-1 bluetext thin" style="color:#a51d29;">(Exceeds
                                                            Max Length)</span>
                                                    </ng-container>

                                                </p>
                                                <div
                                                    style="height: 2rem;margin-left: 2rem;margin-top: 0.5rem;width: 18rem;">
                                                    <input formControlName="deliveryCity" placeholder="City" type="text"
                                                        class="form-control" autocomplete="off"
                                                        style="width: 315px;height: 2rem;" />
                                                </div>
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;padding-left: 30px; padding-top: 10px;"><i
                                                        class="fa fa-asterisk uiicon"
                                                        style="font-size: 6px;color: #ff0000;"></i>Postal Code
                                                    <ng-container
                                                        *ngIf="(differentDeliveryAddressCond == 'No' && f.deliveryPostalCode.touched && stringValidation(f.deliveryPostalCode.value)) || ( differentDeliveryAddressCond == 'No' && rfqValidCond == false  && stringValidation(f.deliveryPostalCode.value) )">
                                                        <span class="ms-1 bluetext thin"
                                                            style="color:#a51d29;">(Required)</span>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="(differentDeliveryAddressCond == 'No' && f.deliveryPostalCode.touched && f.deliveryPostalCode.value.length > 10) || ( differentDeliveryAddressCond == 'No' && rfqValidCond == false  && f.deliveryPostalCode.value.length > 10)">
                                                        <span class="ms-1 bluetext thin" style="color:#a51d29;">(Exceeds
                                                            Max Length)</span>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="(differentDeliveryAddressCond == 'No' && f.deliveryPostalCode.touched && !postalCodeValid(f.deliveryPostalCode.value) && !stringValidation(f.deliveryPostalCode.value)) || ( differentDeliveryAddressCond == 'No' && rfqValidCond == false && !postalCodeValid(f.deliveryPostalCode.value) && !stringValidation(f.deliveryPostalCode.value))">
                                                        <span class="ms-1 bluetext thin"
                                                            style="color:#a51d29;">(Invalid)</span>
                                                    </ng-container>

                                                </p>
                                                <div
                                                    style="height: 2rem;margin-left: 2rem;margin-top: 0.5rem;width: 18rem;">
                                                    <input formControlName="deliveryPostalCode"
                                                        placeholder="Postal Code" type="text" class="form-control"
                                                        autocomplete="off" style="width: 315px;height: 2rem;" />
                                                </div>
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;padding-left: 30px; padding-top: 10px;"><i
                                                        class="fa fa-asterisk uiicon"
                                                        style="font-size: 6px;color: #ff0000;"></i>Province

                                                    <ng-container
                                                        *ngIf="(differentDeliveryAddressCond == 'No' && f.deliveryProvince.touched && stringValidation(f.deliveryProvince.value)) || ( differentDeliveryAddressCond == 'No' && rfqValidCond == false  && stringValidation(f.deliveryProvince.value) )">
                                                        <span class="ms-1 bluetext thin"
                                                            style="color:#a51d29;">(Required)</span>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="(differentDeliveryAddressCond == 'No' && f.deliveryProvince.touched && f.deliveryProvince.value.length > 30) || ( differentDeliveryAddressCond == 'No' && rfqValidCond == false  && f.deliveryProvince.value.length > 30 )">
                                                        <span class="ms-1 bluetext thin" style="color:#a51d29;">(Exceeds
                                                            Max Length)</span>
                                                    </ng-container>

                                                </p>
                                                <div
                                                    style="height: 2rem;margin-left: 2rem;margin-top: 0.5rem;width: 18rem;">
                                                    <input formControlName="deliveryProvince" placeholder="Province"
                                                        type="text" class="form-control" autocomplete="off"
                                                        style="width: 315px;height: 2rem;" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                            <div class="col" style="padding-left: 0;">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;"><i class="fa fa-asterisk uiicon"
                                                        style="font-size: 6px;color: #ff0000;"></i> Payment Requirement
                                                    <ng-container
                                                        *ngIf=" f.paymentoptions.value == null  && rfqValidCond == false">
                                                        <span class="ms-1 bluetext thin"
                                                            style="color:#a51d29;">(Required)</span>

                                                    </ng-container>

                                                </p>


                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;"></p>
                                                <div
                                                    style="height: 2rem;margin-left: 2rem;margin-top: 1rem;width: 18rem; margin-bottom: 10px;">
                                                    <select formControlName="paymentoptions"
                                                        class="form-select d-flex align-items-center form-select"
                                                        style="height: auto;width: 418PX;">
                                                        <option [disabled]="true" [ngValue]="null" selected="">Select
                                                            Payment Requirements</option>
                                                        <option *ngFor="let p_req of paymentoptions" [value]="p_req">
                                                            {{p_req}}</option>

                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="f.paymentoptions.value == 'Other'"
                                            class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                            <div class="col" style="padding-left: 0;">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;padding-left: 30px;"> Specify Other Payment
                                                    Requirement
                                                    <ng-container
                                                        *ngIf="(f.otherPaymentRequirement.touched && stringValidation(f.otherPaymentRequirement.value)) || (paymentValidCond == false  && stringValidation(f.otherPaymentRequirement.value) )">
                                                        <span class="ms-1 bluetext thin"
                                                            style="color:#a51d29;">(Required)</span>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="(f.otherPaymentRequirement.touched && f.otherPaymentRequirement.value.length > 250) || (paymentValidCond == false  && f.otherPaymentRequirement.value.length > 250 )">
                                                        <span class="ms-1 bluetext thin" style="color:#a51d29;">(Exceeds
                                                            Max
                                                            Length)</span>
                                                    </ng-container>
                                                </p>
                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;"></p>
                                                <div style="margin-top: 1rem;width: 100%;">
                                                    <div
                                                        style="height: auto;margin-left: 2rem;margin-top: 1rem;width: 25rem;">
                                                        <textarea formControlName="otherPaymentRequirement"
                                                            class="form-control"
                                                            style="width: 417px; min-height: 70px; height: 70px; max-height: 70px;"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row rfqdetailrow">
                                            <div class="col" style="padding-left: 0;width: 30%;">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;width: auto;"><i
                                                        class="fa fa-asterisk uiicon"
                                                        style="font-size: 6px;color: #ff0000;"></i>
                                                    <ng-container *ngIf="deliverOrCollection == 'Delivery'"> Delivery
                                                        Date </ng-container>
                                                    <ng-container *ngIf="deliverOrCollection == 'Collection'">
                                                        Collection
                                                        Date </ng-container>
                                                    <ng-container *ngIf=" deliverOrCollection == null ">
                                                        Delivery/Collection
                                                        Date </ng-container>
                                                    <ng-container
                                                        *ngIf="(f.deliveryDate.touched && f.deliveryDate.errors) || (f.deliveryDate.errors && rfqValidCond == false)">
                                                        <span *ngIf="f.deliveryDate.errors.required"
                                                            class="ms-1 bluetext thin"
                                                            style="color:#a51d29;">(Required)</span>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf=" DeliverDay == 'Saturday' || DeliverDay == 'Sunday' ">
                                                        <span class="ms-1 bluetext thin"
                                                            style="color: var(--yellow);">({{DeliverDay}})</span>
                                                    </ng-container>

                                                    <ng-container *ngIf="f.deliveryDate.value < minDate">
                                                        <span class="ms-1 bluetext thin"
                                                            style="color:#a51d29;">(Expired)</span>
                                                    </ng-container>
                                                </p>

                                                <div
                                                    style="margin-left: 2rem;margin-top: 1rem;height: 2rem;width: auto;">
                                                    <input type="date" class="form-select form-control"
                                                        autocomplete="off" placeholder="0" style="width: 180px;"
                                                        (change)="disableCloseDateForm()"
                                                        (ngModelChange)="getDeliveryHours($event)"
                                                        (ngModelChange)="getDeliveryDays($event)"
                                                        (ngModelChange)="getMaxDate($event)"
                                                        (ngModelChange)="getMaxLeadTime(f.deliveryDate.value,f.RFQcloseDate.value)"
                                                        (change)="getDeliveryDay()" formControlName="deliveryDate"
                                                        min="{{minDate}}" type="date" style="width: 100%;" />

                                                    <div *ngIf="f.deliveryDate.value!= null" class=" "
                                                        style="padding-top: 1rem;">
                                                        <p class="bluetext thin" style="font-size: 22px;"> Deliver in
                                                            <b>{{dueDate}}</b> Days and
                                                            <b>{{dueHours}}</b> Hours
                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-xxl-5" style="padding-left: 0;width: 50%;">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;width: auto;margin-left: -1rem;"><i
                                                        class="fa fa-asterisk uiicon"
                                                        style="font-size: 6px;color: #ff0000;"></i> RFQ Closing Date
                                                    <ng-container
                                                        *ngIf="(f.RFQcloseDate.touched && f.RFQcloseDate.errors)  || (f.RFQcloseDate.errors && rfqValidCond == false)">
                                                        <span *ngIf="f.RFQcloseDate.errors.required"
                                                            class="ms-1 bluetext thin"
                                                            style="color:#a51d29;">(Required)</span>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf=" ClosingDay == 'Saturday' || ClosingDay == 'Sunday' ">
                                                        <span class="ms-1 bluetext thin"
                                                            style="color: var(--yellow);">({{ClosingDay}})</span>
                                                    </ng-container>
                                                    <ng-container *ngIf="f.RFQcloseDate.value < minDate">
                                                        <span class="ms-1 bluetext thin"
                                                            style="color:#a51d29;">(Expired)</span>
                                                    </ng-container>
                                                    <!-- TOOLTIP -->
                                                    <span
                                                        data-tooltip="RFQ Closing date is the final date that the RFQ can be quotted on."
                                                        style="z-index: 9999999;">
                                                        <i class="fa fa-question-circle"
                                                            style="  position: relative; margin-left:5px;color: var(--maingreen);font-size: 12px;"></i></span>

                                                </p>
                                                <div
                                                    style="margin-top: 1rem;height: 2rem;width: auto;margin-left: 1rem;">

                                                    <input formControlName="RFQcloseDate" [disabled]="true" type="date"
                                                        class="form-select form-control" style="width: 189px;"
                                                        (ngModelChange)="getExpireHours($event)"
                                                        (ngModelChange)="getExpireDays($event)" min="{{minDate}}"
                                                        (ngModelChange)="getMaxLeadTime(f.deliveryDate.value,f.RFQcloseDate.value)"
                                                        (change)="getClosingDay()" max="{{maxDate}}"
                                                        style="width: 100%;">

                                                    <div *ngIf="f.RFQcloseDate.value!= null" class=" "
                                                        style="padding-top: 1rem;">
                                                        <p class="bluetext thin" style="font-size: 22px;">Closes in
                                                            <b>{{expireDate}}</b> Days and
                                                            <b>{{expHours}}</b> Hours
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row rfqdetailrow">
                                            <div class="col" style="padding-left: 0;width: 30%;">

                                                <div
                                                    style="margin-left: 2rem;margin-top: 1rem;height: 2rem;width: auto;">

                                                    <div *ngIf="maxLeadTime!= null" style="padding-top: 2.25rem;">
                                                        <p class="bluetext thin" style="font-size: 22px;">
                                                            Max Lead Time: <b>{{maxLeadTime}}</b> Days
                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="row d-xxl-flex" style="padding-top: 2rem;">
                                            <div class="col d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex justify-content-end justify-content-sm-end justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end"
                                                style="padding-top:1rem;padding-left: 0;padding-right: 34px;"><a
                                                    class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex align-items-center align-items-sm-center align-items-md-center align-items-lg-center align-items-xl-center align-items-xxl-center text-gray-500"
                                                    href="/" style="padding-right: 1rem;"></a>
                                                <p class="d-sm-flex justify-content-sm-start align-items-sm-center align-items-md-center align-items-xl-center align-items-xxl-center bluetext"
                                                    style="margin-bottom: 0;">

                                                    <button *ngIf="rfqValid()" (click)="onNext()" data-bs-toggle="modal"
                                                        data-bs-target="#editrfqmodal2" data-bs-dismiss="modal"
                                                        class="btn d-flex justify-content-center align-items-center yellowbtn"
                                                        role="button"
                                                        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Next</button>

                                                    <!-- Fake next button for error checking  -->
                                                    <button *ngIf="!rfqValid()" (click)="rfqValidCheck()"
                                                        (click)="paymentValidCheck()"
                                                        class="btn d-flex justify-content-center align-items-center yellowbtn"
                                                        role="button"
                                                        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Next</button>

                                                </p>
                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <!-- RFQ MODAL 2-->

    <section>
        <div class="modal fade" data-bs-backdrop="static" role="dialog" tabindex="-1" id="editrfqmodal2"
            aria-labelledby="exampleModalLabel">

            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body" style="padding: 0px; border: 0px">
                        <div style="width: 500px;">
                            <div
                                class="row text-center d-xl-flex justify-content-xl-center align-items-xl-center greenformheader">
                                <div class="col d-flex d-xl-flex justify-content-xl-start align-items-xl-center">
                                    <h1 class="d-xl-flex align-items-xl-center whitehead"
                                        style="font-size: 16px;margin-bottom: 0;">Edit RFQ</h1><span
                                        class="d-xl-flex justify-content-xl-center align-items-xl-center"
                                        style="color: var(--white);padding-left: 5px;">part 2</span>

                                </div>
                                <div
                                    class="col d-flex d-xl-flex justify-content-end justify-content-xl-end align-items-xl-center justify-content-xxl-end">
                                    <a a data-bs-dismiss="modal" data-bs-target="createfqmodal2" (click)="resetEdit()"
                                        href="/" (click)="triggerperiodicUpdate(false)"><svg
                                            xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                            viewBox="0 0 24 24" fill="none" style="color: var(--shadowgrey);">
                                            <path
                                                d="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
                                                fill="currentColor"></path>
                                        </svg></a>
                                </div>
                            </div>
                            <!-- <p>{{test.value | json}}</p>  -->
                            <div class="row RFQmodal2 shadow p">
                                <div class="col-xl-12 col-xxl-12 rfqsearchblock"
                                    style="border-radius: 0px 0px 20px 20px;padding: 2rem;">


                                    <div class="row row-cols-2 d-flex d-xxl-flex align-items-center rfqdetailrow">
                                        <div class="col" style="padding-left: 0;">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 30px;"> Packaging Requirements</p>
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;"></p>
                                            <div style="height: 2rem;margin-left: 2rem;margin-top: 1rem;width: 18rem;">
                                                <select formControlName="packaging"
                                                    class="form-select d-flex align-items-center form-select"
                                                    style="height: auto;width: 418PX;">
                                                    <option [disabled]="true" [ngValue]="null" selected="">
                                                        Packaging Requirement </option>
                                                    <option *ngFor="let d of packagingList" [value]="d">
                                                        {{d}}</option>
                                                    <option [ngValue]="null">Not Required</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="f.packaging.value == 'Other'"
                                        class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                        <div class="col" style="padding-left: 0;">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 30px;"> Specify Other Packaging
                                                Requirement
                                                <ng-container
                                                    *ngIf="(f.otherPackagingSpecification.touched && stringValidation(f.otherPackagingSpecification.value)) || (packagingValidCond == false  && stringValidation(f.otherPackagingSpecification.value) )">
                                                    <span class="ms-1 bluetext thin"
                                                        style="color:#a51d29;">(Required)</span>
                                                </ng-container>

                                                <ng-container
                                                    *ngIf="(f.otherPackagingSpecification.touched && f.otherPackagingSpecification.value.length > 250) || (packagingValidCond == false  && f.otherPackagingSpecification.value.length > 250 )">
                                                    <span class="ms-1 bluetext thin" style="color:#a51d29;">(Exceeds Max
                                                        Length)</span>
                                                </ng-container>

                                            </p>
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;"></p>
                                            <div style="margin-top: 1rem;width: 100%;">
                                                <div
                                                    style="height: auto;margin-left: 2rem;margin-top: 1rem;width: 25rem;">
                                                    <textarea formControlName="otherPackagingSpecification"
                                                        class="form-control"
                                                        style="width: 417px; min-height: 70px; height: 70px; max-height: 70px;"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row row-cols-2 d-flex d-xxl-flex align-items-center rfqdetailrow">
                                        <div class="col" style="padding-left: 0;">
                                            <div class="d-flex d-md-flex d-lg-flex justify-content-start justify-content-md-start justify-content-lg-start"
                                                style="margin-left: 30px;">
                                                <p
                                                    class="d-flex d-sm-flex align-items-center justify-content-sm-start justify-content-lg-end bluetext">
                                                    Test
                                                    Certification Required?</p><span
                                                    class="d-md-flex justify-content-md-center align-items-md-center"
                                                    style="padding-bottom: 3px;"
                                                    data-tooltip-wider="A Supplier provides traceability and assurance to the buyer about the quality of the steel used and the process used to produce it in the form of a certified document."><i
                                                        class="fa fa-question-circle"
                                                        style="margin-left: 5px;font-size: 12px;color: var(--maingreen);"></i></span>
                                            </div>


                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;"></p>
                                            <div class="KNOPPE"
                                                style="height: 2rem;margin-left: 2rem;margin-top: 1rem;width: 18rem;">

                                                <div class="row d-xl-flex justify-content-xl-start"
                                                    style="padding-left: 8px; padding-top: 0;width: 553px;min-width: 498px;">
                                                    <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-md-flex justify-content-md-center align-items-md-center"
                                                        style="padding-left: 0;">
                                                        <div class="btn-group bluebtn" role="group"
                                                            aria-label="Basic checkbox toggle button group">
                                                            <input type="checkbox" id="yespid"
                                                                formControlName="productidYes"
                                                                (change)="productidcheck(true)"
                                                                class="btn-check form-check-input" name="" /><label
                                                                class="form-label form-check-label btn btn-outline-primary"
                                                                for="yespid"
                                                                style="border-radius: 40px;font-size: 14px;"
                                                                name="flexRadioDefault2">Yes</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 offset-xl-0 d-md-flex justify-content-md-center align-items-md-center"
                                                        style="padding: 0;">
                                                        <div class="btn-group bluebtn" role="group"
                                                            aria-label="Basic checkbox toggle button group">
                                                            <input type="checkbox" id="nopid"
                                                                formControlName="productidNo"
                                                                (change)="productidcheck(false)"
                                                                class="btn-check form-check-input" name="" /><label
                                                                class="form-label btn btn-outline-primary" for="nopid"
                                                                style="border-radius: 40px;font-size: 14px;">No</label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="row row-cols-2 d-flex d-xxl-flex align-items-center rfqdetailrow">
                                        <div class="col" style="padding-left: 0;">
                                            <p class="text-nowrap d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 30px;padding-top: 0px;"> ISO
                                                Required?</p>
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;"></p>
                                            <div class="KNOPPE"
                                                style="height: 2rem;margin-left: 2rem;margin-top: 1rem;width: 18rem;">

                                                <div class="row d-xl-flex justify-content-xl-start"
                                                    style="padding-left: 8px; padding-top: 0;width: 553px;min-width: 498px;">
                                                    <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-md-flex justify-content-md-center align-items-md-center"
                                                        style="padding-left: 0;">
                                                        <div class="btn-group bluebtn" role="group"
                                                            aria-label="Basic checkbox toggle button group">
                                                            <input type="checkbox" formControlName="isoYes" id="vfrYes"
                                                                (click)=" isoRequirementsCheck(true)"
                                                                class="btn-check form-check-input" name="" /><label
                                                                for="vfrYes"
                                                                class="form-label form-check-label btn btn-outline-primary"
                                                                style="border-radius: 40px;font-size: 14px;"
                                                                name="flexRadioDefault2">Yes</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 offset-xl-0 d-md-flex justify-content-md-center align-items-md-center"
                                                        style="padding: 0;">
                                                        <div class="btn-group bluebtn" role="group"
                                                            aria-label="Basic checkbox toggle button group">
                                                            <input type="checkbox" formControlName="isoNo" id="vfrNo"
                                                                (click)="isoRequirementsCheck(false)"
                                                                class="btn-check form-check-input" name="" /><label
                                                                class="form-label btn btn-outline-primary" for="vfrNo"
                                                                style="border-radius: 40px;font-size: 14px;">No</label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                        <div class="col" style="padding-left: 0;">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 30px;"> Product Identification
                                                Requirements

                                                <span class="d-md-flex justify-content-md-center align-items-md-center"
                                                    style="padding-bottom: 3px;"
                                                    data-tooltip="Is a labeling category that provides product traceability and identification."><i
                                                        class="fa fa-question-circle"
                                                        style="margin-left: 5px;font-size: 12px;color: var(--maingreen);"></i></span>


                                            </p>

                                            <ng-container
                                                *ngIf="(f.P_Cert_Req.touched && f.P_Cert_Req.value.length > 250 ) || (productIdentificationCond == false && f.P_Cert_Req.value.length > 250 ) ">
                                                <span class="ms-1 bluetext thin" style="color:#a51d29;">(Exceeds Max
                                                    Length)</span>
                                            </ng-container>

                                            <p class=""
                                                style="font-size:12px;margin-bottom: 0;padding-left: 30px;color: var(--darkblue);">
                                                * Note: These requests are subject the suppliers ability to execute
                                                them.
                                            </p>



                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;"></p>
                                            <div style="margin-top: 1rem;width: 100%;">
                                                <div
                                                    style="height: auto;margin-left: 2rem;margin-top: 1rem;width: 25rem;">
                                                    <textarea formControlName="P_Cert_Req" class="form-control"
                                                        style="width: 417px; min-height: 70px; height: 70px; max-height: 70px;"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row row-cols-2 d-flex d-xxl-flex align-items-center rfqdetailrow">
                                        <div class="col" style="padding-left: 0;">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 30px;">B-BBEE Requirements</p>
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;"></p>
                                            <div style="height: 2rem;margin-left: 2rem;margin-top: 1rem;width: 18rem;">
                                                <select formControlName="BEE"
                                                    class="form-select d-flex align-items-center form-select"
                                                    style="height: auto;width: 180px;">
                                                    <option [disabled]="true" [ngValue]="null" selected="">
                                                        B-BBEE Level</option>
                                                    <option *ngFor="let level of BEE" [value]=" level">
                                                        {{level}}</option>
                                                    <option [ngValue]="null">Not Required</option>
                                                </select>
                                            </div>
                                        </div>

                                        <!-- <div class="col" style="padding-left: 0;">
                                            <p class="text-nowrap d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 30px;"> ISO Requirements</p>
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;"></p>
                                            <div style="height: 2rem;margin-left: 2rem;margin-top: 1rem;width: 18rem;">
                                                <select formControlName="ISO"
                                                    class="form-select d-flex align-items-center form-select"
                                                    style="height: auto;width: 180px;">
                                                    <option [disabled]="true" value="" selected="">
                                                        ISO Requirement</option>
                                                    <option *ngFor="let ISO of ISO Requirements" [value]=" ISO">
                                                        {{ISO}}</option>
                                                </select>
                                            </div>
                                        </div> -->
                                        <!-- <div class="col" style="padding-left: 0;">
                                            <p class="text-nowrap d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 30px;"> Delivery Requiremnt</p>
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;"></p>
                                            <div style="height: 2rem;margin-left: 2rem;margin-top: 1rem;width: 18rem;">
                                                <select formControlName="delivery"
                                                    class="form-select d-flex align-items-center form-select"
                                                    style="height: auto;width: 180px;">
                                                    <option [disabled]="true"  [ngValue]="null"  selected="">
                                                        Delivery Requirement </option>
                                                    <option *ngFor="let d of deliveryList" [value]=" d">
                                                        {{d}}</option>
                                                        <option  [ngValue]="null" >Not Required</option>
                                                </select>
                                            </div>
                                        </div> -->

                                    </div>
                                    <div class="row row-cols-2 d-flex d-xxl-flex align-items-center rfqdetailrow">
                                        <div class="col" style="padding-left: 0;">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 30px;"> Partnership Requirements
                                            </p>
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;"></p>
                                            <div style="height: 2rem;margin-left: 2rem;margin-top: 1rem;width: 18rem;">
                                                <select formControlName="Partners"
                                                    class="form-select d-flex align-items-center form-select"
                                                    style="height: auto;width: 180px;">
                                                    <option [disabled]="true" [ngValue]="null" selected="">
                                                        Partner Requirement</option>
                                                    <option *ngFor="let Partner of Partners" [value]=" Partner">
                                                        {{Partner}}</option>
                                                    <!-- <option [ngValue]="null">Not Required</option> -->
                                                </select>
                                            </div>
                                        </div>

                                        <!-- <div class="col" style="padding-left: 0;">
                                            <p class="text-nowrap d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 30px;"> Packaging Requirement</p>
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;"></p>
                                            <div style="height: 2rem;margin-left: 2rem;margin-top: 1rem;width: 18rem;">
                                                <select formControlName="packaging"
                                                    class="form-select d-flex align-items-center form-select"
                                                    style="height: auto;width: 180px;">
                                                    <option [disabled]="true" value="" selected="">
                                                        Packaging Requirement </option>
                                                    <option *ngFor="let d of packagingList" [value]="d">
                                                        {{d}}</option>
                                                </select>
                                            </div>
                                        </div> -->

                                        <div class="col" style="padding-left: 0;">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 30px;"> Location Requirements</p>
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;"></p>
                                            <div style="height: 2rem;margin-left: 2rem;margin-top: 1rem;width: 18rem;">
                                                <select formControlName="Location"
                                                    class="form-select d-flex align-items-center form-select"
                                                    style="height: auto;width: 180px;">
                                                    <option [disabled]="true" [ngValue]="null" selected="">
                                                        Location </option>
                                                    <option *ngFor="let selectedlocation of Location"
                                                        [value]=" selectedlocation">
                                                        {{selectedlocation}}</option>
                                                    <option [ngValue]="null">Not Required</option>
                                                </select>
                                            </div>
                                        </div>


                                    </div>
                                    <div class="row row-cols-2 d-flex d-xxl-flex align-items-center rfqdetailrow">


                                    </div>
                                    <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                        <div class="col" style="padding-left: 0;">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 30px;"> Any Additional Comments
                                                <ng-container
                                                    *ngIf="(f.Comments.touched && f.Comments.value.length > 500 ) || (additionCommentsCond == false && f.Comments.value.length > 500 ) ">
                                                    <span class="ms-1 bluetext thin" style="color:#a51d29;">(Exceeds Max
                                                        Length)</span>
                                                </ng-container>
                                            </p>
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;"></p>
                                            <div style="margin-top: 1rem;width: 100%;">
                                                <div
                                                    style="height: auto;margin-left: 2rem;margin-top: 1rem;width: 25rem;">
                                                    <textarea formControlName="Comments" class="form-control"
                                                        style="width: 417px; min-height: 70px; height: 70px; max-height: 70px;"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row d-xxl-flex" style="padding-top: 2rem;">
                                        <div class="col d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex justify-content-end justify-content-sm-end justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end"
                                            style="padding-left: 0;padding-right: 34px;"><a (click)="onNext()"
                                                data-bs-toggle="modal" data-bs-target="#editrfqmodal"
                                                data-bs-dismiss="modal"
                                                class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex align-items-center align-items-sm-center align-items-md-center align-items-lg-center align-items-xl-center align-items-xxl-center text-gray-500"
                                                href="/" style="padding-right: 1rem;">Back</a>
                                            <p *ngIf=" packagingSpecificationsValid() && additionalCommentsValid() && productIdentificationValid()"
                                                class="d-sm-flex justify-content-sm-start align-items-sm-center align-items-md-center align-items-xl-center align-items-xxl-center bluetext"
                                                style="margin-bottom: 0;"><a (click)="onNext()" data-bs-toggle="modal"
                                                    data-bs-target="#editrfqmodalsummary" data-bs-dismiss="modal"
                                                    class="btn d-flex justify-content-center align-items-center yellowbtn"
                                                    role="button"
                                                    style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">
                                                    RFQ Summary</a>
                                            </p>
                                            <p *ngIf=" !packagingSpecificationsValid() || !additionalCommentsValid() || !productIdentificationValid()"
                                                class="d-sm-flex justify-content-sm-start align-items-sm-center align-items-md-center align-items-xl-center align-items-xxl-center bluetext"
                                                style="margin-bottom: 0;"><a (click)="paymentValidCheck()"
                                                    (click)="additionalCommentsValidCheck()"
                                                    (click)="productIdentificationValidCheck()"
                                                    class="btn d-flex justify-content-center align-items-center yellowbtn"
                                                    role="button"
                                                    style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">
                                                    RFQ Summary </a>
                                            </p>
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</form>

<!-- RFQ MODAL 3 SUMMARY-->

<section>
    <div class="modal fade" data-bs-backdrop="static" role="dialog" tabindex="-1" id="editrfqmodalsummary"
        aria-labelledby="exampleModalLabel">

        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body" style="padding: 0px; border: 0px">
                    <div style="width: 500px;">
                        <div
                            class="row text-center d-xl-flex justify-content-xl-center align-items-xl-center greenformheader">
                            <div class="col d-flex d-xl-flex justify-content-xl-start align-items-xl-center">
                                <h1 class="d-xl-flex align-items-xl-center whitehead"
                                    style="font-size: 16px;margin-bottom: 0;">RFQ Summary
                                </h1>
                            </div>
                            <div
                                class="col d-flex d-xl-flex justify-content-end justify-content-xl-end align-items-xl-center justify-content-xxl-end">
                                <a a data-bs-dismiss="modal" data-bs-target="#editrfqmodalsummary"
                                    (click)="triggerperiodicUpdate(false)"><svg xmlns="http://www.w3.org/2000/svg"
                                        width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                        style="color: var(--shadowgrey);">
                                        <path
                                            d="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
                                            fill="currentColor"></path>
                                    </svg></a>
                            </div>
                        </div>
                        <div class="row RFQmodal shadow p">
                            <div class="col-xl-12 col-xxl-12 rfqsearchblock"
                                style="border-radius: 0px 0px 20px 20px;padding: 2rem;">
                                <div class="row rfqdetailrow">
                                    <div class="col">
                                        <h1 style="font-size: 16px;color:#0e0e82;">Project Name</h1>
                                    </div>
                                    <div class="col">
                                        <h1 class="text-end blueheadsmall">{{f.projectName.value}}</h1>
                                    </div>
                                </div>
                                <div class="row rfqdetailrow">
                                    <div class="col">
                                        <h1 style="font-size: 16px;color:#0e0e82;">Type of Supply:</h1>
                                    </div>
                                    <div class="col">
                                        <h1 class="text-end blueheadsmall">{{typeOfSupply}}</h1>
                                    </div>
                                </div>
                                <div class="row rfqdetailrow">
                                    <div class="col">
                                        <h1 style="font-size: 16px;color:#0e0e82;">Services Required:</h1>
                                    </div>
                                    <div class="col">
                                        <ng-container *ngIf="typeOfSupply == 'Complete' && drawOrDim == 'Drawings'">
                                            <div *ngFor="let s of allServiceDisplay">
                                                <h1 class="text-end blueheadsmall">{{s.serviceName}}</h1>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="typeOfSupply == 'Complete' && drawOrDim == 'Dimensions'">
                                            <div *ngFor="let s of dimensionSelectedService">
                                                <ng-container *ngFor="let service of dimensionServices">
                                                    <ng-container *ngIf="s == service.supplierServiceId">
                                                        <h1 class="text-end blueheadsmall">{{service.serviceName}}</h1>
                                                    </ng-container>
                                                </ng-container>

                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="typeOfSupply == 'Raw Material'">
                                            <h1 class="text-end blueheadsmall">Raw Material</h1>
                                        </ng-container>


                                    </div>
                                </div>
                                <!-- <div class="row rfqdetailrow">
                                    <div class="col">
                                        <h1 style="font-size: 16px;color:#0e0e82;">No. of parts (total):</h1>
                                    </div>
                                    <div class="col">
                                        <h1 class="text-end blueheadsmall">{{f.numberOfParts.value}}</h1>
                                    </div>
                                </div> -->
                                <div class="row rfqdetailrow">
                                    <div class="col">
                                        <h1 style="font-size: 16px;color:#0e0e82;">Drawings or dimensions:</h1>
                                    </div>
                                    <div class="col">
                                        <h1 class="text-end blueheadsmall">{{drawOrDim}}</h1>
                                    </div>
                                </div>
                                <!-- checks of dimensions or drawings are selected for the summary -->

                                <div *ngIf="f.dimensions.value">
                                    <div class="row rfqdetailrow">
                                        <div class="col">
                                            <h1 style="font-size: 16px;color:#0e0e82;">Dimensions Details:</h1>
                                        </div>
                                        <div class="col">
                                            <div *ngFor="let d of dimensionsUI">

                                                <p class="d-flex d-sm-flex align-items-end justify-content-end bluetext "
                                                    style="margin-bottom: 0;">
                                                    <i class=" uiicon bluetext"
                                                        style="font-size: 6px;color: #ff0000;"></i>
                                                    Part No:
                                                    <span class="bluetext thin" style="padding-left: 2px;">
                                                        {{d.PartNo}}</span>
                                                </p>

                                                <p class="d-flex d-sm-flex align-items-end justify-content-end bluetext "
                                                    style="margin-bottom: 0;">
                                                    <i class=" uiicon bluetext"
                                                        style="font-size: 6px;color: #ff0000;"></i>
                                                    Type:
                                                    <span class="bluetext thin" style="padding-left: 2px;">
                                                        {{d.Type}}</span>
                                                </p>

                                                <p class="d-flex d-sm-flex align-items-end justify-content-end bluetext "
                                                    style="margin-bottom: 0;">
                                                    <i class=" uiicon bluetext"
                                                        style="font-size: 6px;color: #ff0000;"></i>
                                                    Quantity:
                                                    <span class="bluetext thin" style="padding-left: 2px;">
                                                        {{d.Quantity}}</span>
                                                </p>

                                                <p class="d-flex d-sm-flex align-items-end justify-content-end bluetext "
                                                    style="margin-bottom: 0;">
                                                    <i class=" uiicon bluetext"
                                                        style="font-size: 6px;color: #ff0000;"></i>
                                                    Dimensions:
                                                    <span class="bluetext thin" style="padding-left: 2px;">
                                                        {{d.Dimensions}}</span>
                                                </p>

                                                <p class="d-flex d-sm-flex align-items-end justify-content-end bluetext "
                                                    style="margin-bottom: 0;">
                                                    <i class=" uiicon bluetext"
                                                        style="font-size: 6px;color: #ff0000;"></i>
                                                    Material Grade:
                                                    <span class="bluetext thin" style="padding-left: 2px;">
                                                        {{d.MaterialGrade}}</span>
                                                </p>
                                                <p class="d-flex d-sm-flex align-items-end justify-content-end bluetext "
                                                    style="margin-bottom: 0;">
                                                    <i class="text-nowrap uiicon bluetext"
                                                        style="font-size: 6px;color: #ff0000;"></i>
                                                    Service Required:
                                                    <span *ngIf="typeOfSupply == 'Complete'" class="bluetext thin"
                                                        style="padding-left: 2px;">
                                                        <ng-container *ngFor="let service of dimensionServices">
                                                            <ng-container
                                                                *ngIf="d.DimensionSupplierServiceId == service.supplierServiceId">
                                                                <span class="badge rounded-pill bluetextthin"
                                                                    style="background-color: var(--darkblue);">
                                                                    {{service.serviceName}}
                                                                </span>
                                                            </ng-container>
                                                        </ng-container>

                                                    </span>
                                                    <span *ngIf="typeOfSupply == 'Raw Material'" class="bluetext thin"
                                                        style="padding-left: 2px;">
                                                        <ng-container *ngFor="let service of RawMaterialServices">
                                                            <ng-container
                                                                *ngIf="d.DimensionSupplierServiceId == service.supplierServiceId">
                                                                <span class="badge rounded-pill bluetextthin"
                                                                    style="background-color: var(--darkblue);">
                                                                    {{service.serviceName}}
                                                                </span>
                                                            </ng-container>
                                                        </ng-container>

                                                    </span>
                                                </p>
                                                <ul role="tablist"
                                                    class="nav nav-tabs d-flex d-flex d-flex justify-content-start justify-content-start justify-content-start justify-content-start"
                                                    style="padding-top:0.5rem;border-width: 3px;border-color: var(--maingreen);">

                                                </ul>

                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <!-- checks of dimensions or drawings are selected for the summary -->
                                <div *ngIf="f.drawings.value">
                                    <div class="row rfqdetailrow">
                                        <div class="col">
                                            <h1 style="font-size: 16px;color:#0e0e82;">Files:
                                            </h1>
                                        </div>
                                        <div class="col">
                                            <ng-container *ngFor="let file of drawingFileArray">
                                                <h1 class="text-end blueheadsmall">{{file.fileName}}</h1>
                                            </ng-container>
                                            <ng-container *ngFor="let file of viewRfqFiles">
                                                <h1 class="text-end blueheadsmall">{{file.fileName}}</h1>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <!-- <div class="row rfqdetailrow">
                                        <div class="col">
                                            <h1 style="font-size: 16px;color:#0e0e82;">Difference on specific parts:
                                            </h1>
                                        </div>
                                        <div class="col">
                                            <h1 class="text-end blueheadsmall">{{this.diffOnparts}}</h1>
                                        </div>
                                    </div> -->
                                    <div class="row rfqdetailrow">
                                        <div class="col">
                                            <h1 class="text-nowrap" style="color: #0e0e82;font-size: 16px;">Changes to
                                                be made on all drawings:
                                            </h1>
                                        </div>

                                        <div class="col">
                                            <h1 class="text-end blueheadsmall">{{this.f.dchanges.value}}</h1>
                                        </div>
                                    </div>
                                </div>

                                <div class="row rfqdetailrow">
                                    <div class="col">
                                        <h1 style="font-size: 16px;color:#0e0e82;">
                                            Delivery Requirements:
                                        </h1>
                                    </div>
                                    <div class="col">
                                        <h1 class="text-end blueheadsmall">{{deliverOrCollection}}</h1>
                                    </div>
                                </div>

                                <div class="row rfqdetailrow">
                                    <div class="col">
                                        <h1 style="color: #0e0e82;font-size: 16px;">
                                            <ng-container *ngIf="deliverOrCollection == 'Delivery'"> Delivery
                                                Date:</ng-container>
                                            <ng-container *ngIf="deliverOrCollection == 'Collection'"> Collection
                                                Date: </ng-container>
                                        </h1>
                                    </div>
                                    <div class="col">
                                        <h1 class="text-end blueheadsmall">{{this.f.deliveryDate.value}}</h1>
                                    </div>
                                </div>
                                <div class="row rfqdetailrow">
                                    <div class="col">
                                        <h1 style="font-size: 16px;color:#0e0e82;">RFQ Closing Date</h1>
                                    </div>
                                    <div class="col">
                                        <h1 class="text-end blueheadsmall">{{this.f.RFQcloseDate.value}}</h1>
                                    </div>
                                </div>

                                <div *ngIf="f.paymentoptions.value != null && f.paymentoptions.value != ''"
                                    class="row rfqdetailrow">
                                    <div class="col">
                                        <h1 style="font-size: 16px;color:#0e0e82;">Payment Requirements:</h1>
                                    </div>
                                    <div class="col">
                                        <h1 class="text-end blueheadsmall">{{f.paymentoptions.value}}</h1>
                                    </div>
                                </div>

                                <div *ngIf="f.otherPaymentRequirement.value != null && f.otherPaymentRequirement.value != ''"
                                    class="row rfqdetailrow">
                                    <div class="col">
                                        <h1 style="font-size: 16px;color:#0e0e82;">Other Payment Specification:</h1>
                                    </div>
                                    <div class="col">
                                        <h1 class="text-end blueheadsmall">{{f.otherPaymentRequirement.value}}</h1>
                                    </div>
                                </div>

                                <div *ngIf="pid != null" class="row rfqdetailrow">
                                    <div class="col">
                                        <h1 class="text-nowrap" style="color: #0e0e82;font-size: 16px;">Test
                                            Certification Requirements:
                                        </h1>
                                    </div>
                                    <div *ngIf="pid" class="col">
                                        <h1 class="text-end blueheadsmall">Required</h1>
                                    </div>
                                    <div *ngIf="!pid" class="col">
                                        <h1 class="text-end blueheadsmall">Not Required</h1>
                                    </div>
                                </div>

                                <div *ngIf="f.P_Cert_Req.value != null && f.P_Cert_Req.value != ''"
                                    class="row rfqdetailrow">
                                    <div class="col">
                                        <h1 class="text-nowrap" style="color: #0e0e82;font-size: 16px;">Product
                                            Identification Requirements:
                                        </h1>
                                    </div>
                                    <div class="col">
                                        <h1 class="text-end blueheadsmall">{{f.P_Cert_Req.value}}</h1>
                                    </div>
                                </div>

                                <div *ngIf="f.BEE.value != null && f.BEE.value != ''" class="row rfqdetailrow">
                                    <div class="col">
                                        <h1 style="color: #0e0e82;font-size: 16px;">B-BBEE requirements:</h1>
                                    </div>
                                    <div class="col">
                                        <h1 class="text-end blueheadsmall">{{f.BEE.value}}</h1>
                                    </div>
                                </div>

                                <div *ngIf="isoRequirements != null" class="row rfqdetailrow">
                                    <div class="col">
                                        <h1 style="font-size: 16px;color:#0e0e82;">ISO requirements:</h1>
                                    </div>
                                    <div *ngIf="isoRequirements" class="col">
                                        <h1 class="text-end blueheadsmall">Required</h1>
                                    </div>
                                    <div *ngIf="!isoRequirements" class="col">
                                        <h1 class="text-end blueheadsmall">Not Required</h1>
                                    </div>
                                </div>

                                <div *ngIf="f.Partners.value != null && f.Partners.value != ''"
                                    class="row rfqdetailrow">
                                    <div class="col">
                                        <h1 style="font-size: 16px;color:#0e0e82;">Partnership requirements:</h1>
                                    </div>
                                    <div class="col">
                                        <h1 class="text-end blueheadsmall">{{f.Partners.value}}</h1>
                                    </div>
                                </div>
                                <!-- <div class="row rfqdetailrow">
                                    <div class="col">
                                        <h1 class="text-nowrap" style="font-size: 16px;color:#0e0e82;">Verified
                                            Factory Requirements:</h1>
                                    </div>
                                    <div class="col">
                                        <h1 class="text-end blueheadsmall">{{verifiedFactoryRequirements}}</h1>
                                    </div>
                                </div> -->

                                <div *ngIf="f.Location.value != null && f.Location.value != ''"
                                    class="row rfqdetailrow">
                                    <div class="col">
                                        <h1 style="font-size: 16px;color:#0e0e82;">Location Requirements:</h1>
                                    </div>
                                    <div class="col">
                                        <h1 class="text-end blueheadsmall">{{this.f.Location.value}}</h1>
                                    </div>
                                </div>



                                <div *ngIf="f.packaging.value != null && f.packaging.value != ''"
                                    class="row rfqdetailrow">
                                    <div class="col">
                                        <h1 style="font-size: 16px;color:#0e0e82;">Packaging Requirements:</h1>
                                    </div>
                                    <div class="col">
                                        <h1 class="text-end blueheadsmall">{{f.packaging.value}}</h1>
                                    </div>
                                </div>

                                <div *ngIf="f.otherPackagingSpecification.value != null && f.otherPackagingSpecification.value != ''"
                                    class="row rfqdetailrow">
                                    <div class="col">
                                        <h1 style="font-size: 16px;color:#0e0e82;">Other Packaging Specifications:</h1>
                                    </div>
                                    <div class="col">
                                        <h1 class="text-end blueheadsmall">{{f.otherPackagingSpecification.value}}</h1>
                                    </div>
                                </div>

                                <div *ngIf="f.Comments.value != null && f.Comments.value != ''"
                                    class="row rfqdetailrow">
                                    <div class="col">
                                        <h1 style="font-size: 16px;color:#0e0e82;">Additional comments:</h1>
                                    </div>
                                    <div class="col">
                                        <h1 class="text-end blueheadsmall">{{f.Comments.value}}</h1>
                                    </div>
                                </div>
                                <div class="row d-xxl-flex" style="padding-top: 2rem;">
                                    <div class="col d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex justify-content-end justify-content-sm-end justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end"
                                        style="padding-left: 0;padding-right: 34px;"><a (click)="onNext()"
                                            data-bs-toggle="modal" data-bs-target="#editrfqmodal2"
                                            data-bs-dismiss="modal"
                                            class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex align-items-center align-items-sm-center align-items-md-center align-items-lg-center align-items-xl-center align-items-xxl-center text-gray-500"
                                            href="/" style="padding-right: 1rem;">Back</a>
                                        <p class="d-sm-flex justify-content-sm-start align-items-sm-center align-items-md-center align-items-xl-center align-items-xxl-center bluetext"
                                            style="margin-bottom: 0;">

                                            <a #editRfqBtn data-bs-toggle="modal" data-bs-target="#editrfqsuccess"
                                                data-bs-dismiss="modal"
                                                class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn"
                                                role="button"
                                                style="display: none !important;border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Edit
                                                RFQ</a>

                                            <button [disabled]="editingRfq || drawFileDeleting || drawFileUploading"
                                                (click)="rfqSubmit()"
                                                class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn"
                                                role="button"
                                                style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Edit
                                                RFQ <span *ngIf="editingRfq || drawFileDeleting || drawFileUploading"
                                                    class="spinner-border spinner-border-sm mr-1"></span></button>


                                        </p>
                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- RFQ MODAL SUCCESS-->

<section>
    <div class="modal fade" data-bs-backdrop="static" role="dialog" tabindex="-1" id="editrfqsuccess"
        aria-labelledby="exampleModalLabel">

        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body" style="padding: 0px; border: 0px">
                    <div style="width: 500px;">
                        <div
                            class="row text-center d-xl-flex justify-content-xl-center align-items-xl-center greenformheader">
                            <div class="col d-flex d-xl-flex justify-content-xl-start align-items-xl-center">
                                <h1 class="d-xl-flex align-items-xl-center whitehead"
                                    style="font-size: 16px;margin-bottom: 0;">Edit RFQ</h1>
                            </div>
                            <div
                                class="col d-flex d-xl-flex justify-content-end justify-content-xl-end align-items-xl-center justify-content-xxl-end">
                                <a (click)="onNext()" data-bs-toggle="modal" data-bs-target="#editrfqsuccess"
                                    (click)="triggerReloadRfqs(true)" data-bs-dismiss="modal"><svg
                                        xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"
                                        fill="none" style="color: var(--shadowgrey);">
                                        <path
                                            d="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
                                            fill="currentColor"></path>
                                    </svg></a>
                            </div>
                        </div>
                        <div class="row shadow p" style="height: 0px;">
                            <div class="col-xl-12 col-xxl-12 rfqsearchblock"
                                style="border-radius: 0px 0px 0px 0px;padding: 1rem;">
                                <div class="row rfqdetailrow">
                                    <div class="col d-flex justify-content-center align-items-center"><span></span><i
                                            class="fa fa-check-circle"
                                            style="font-size: 32px;margin-right: 10px;color: var(--maingreen);"></i>
                                        <h1 class="text-end bluehead" style="margin-bottom: 0;">RFQ Edited Successfully
                                        </h1>
                                    </div>
                                </div>
                                <!-- <div class="row rfqdetailrow">
                                    <div
                                        class="col d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center">
                                        <a class="btn" role="button"><i class="fa fa-plus-circle"
                                                style="font-size: 20px;color: var(--maingreen);"></i></a><a
                                            (click)="onNext()" data-bs-toggle="modal" data-bs-target="#editrfqmodal"
                                            data-bs-dismiss="modal"
                                            class="btn btn-link btn-sm d-xxl-flex justify-content-xxl-center align-items-xxl-start"
                                            role="button" style="padding: 0px;font-size: 16px;">Create another</a>
                                    </div>
                                </div> -->
                                <div class="row d-xxl-flex" style="padding-top: 2rem;">
                                    <div class="col d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex justify-content-end justify-content-sm-end justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end"
                                        style="padding-left: 0;padding-right: 34px;"><a data-bs-dismiss="modal"
                                            data-bs-target="#editrfqsuccess" (click)="triggerReloadRfqs(true)"
                                            class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex align-items-center align-items-sm-center align-items-md-center align-items-lg-center align-items-xl-center align-items-xxl-center text-gray-500"
                                            href="/" style="padding-right: 1rem;">Close</a>
                                        <p class="d-sm-flex justify-content-sm-start align-items-sm-center align-items-md-center align-items-xl-center align-items-xxl-center bluetext"
                                            style="margin-bottom: 0;">
                                            <!-- <a
                                                class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn"
                                                role="button"
                                                style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Go
                                                to RFQ</a> -->
                                        </p>
                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>