import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { RFQ } from '../models/rfq';
import { QuoteRFQ, RFQS } from '../models/mock-data';
import { MessageService } from './message.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Account } from '../models/account';
import { DefaultCreateRfqDetails, RFQCreate, UploadRfqFile } from '../models/rfqCreate';
import { SupplierServices } from '../models/supplierServices';
import { RfqCountSearch } from '../models/rfqCountSearch';
import { ToastrNotificationService } from './toastr-notification.service';
import { ViewRfq } from '../models/viewRfq';
import { RFQEdit } from '../models/rfqEdit';
import { CompleteRfq, FullRfqReview } from '../models/completeRfq';
import { MakePaymentRequest } from '../models/rfqPaymentRequest';
import { RfqFileDownload } from '../models/rfqFileDownload';
import { QuoteFileDownload } from '../models/quoteFileDownload';
import { RfqHeadings } from '../models/rfqHeadings';
import { RfqFileDetails } from '../models/fileDetails';

const baseUrl = `${environment.apiUrl}/Rfq`;

// USED FOR EDITED QUOTE SEEN FLAG
const baseUrl2 = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class RfqService {

  constructor(
    private messageService: MessageService,
    private http: HttpClient,
    private toastrNotification: ToastrNotificationService
  ) { }

  getMockRFQs(): Observable<RFQ[]> {
    const rfqs = of(RFQS);
    //this.messageService.add('HeroService: fetched heroes');
    return rfqs;
  }

  //mock single quote RFQs
  getMockQuoteRFQs(): Observable<RFQ[]> {
    const rfqs = of(QuoteRFQ);
    //this.messageService.add('HeroService: fetched heroes');
    return rfqs;
  }
  //test method for getting Rfqs
  testGetRfqs(buyerId: number, rfqState: string) {
    const url = `${baseUrl}/ViewRFQs?buyerId=${buyerId}&rfqState=${rfqState}`
    return this.http.get<RFQ[]>(url);

  }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  /** POST: add a new rfq to the server */
  addRFQ(rfq: RFQCreate): Observable<number> {
    const url = `${baseUrl}/CreateRFQ`;
    return this.http.post<any>(url, rfq, this.httpOptions)
      .pipe(
        map(rfq => {  return rfq.results }),
        tap((newRFQ: number) => this.log(`added rfq succesfully`)),
        catchError(this.handleError<number>('addRFQ'))
      );
  }

  /** POST: accept quote */
  acceptQuote(quoteId: number): Observable<number> {
    const url = `${baseUrl}/RFQAcceptQuote`;
    return this.http.post<any>(url, quoteId, this.httpOptions)
      .pipe(
        map(rfq => {  return rfq.message }),
        tap((acceptQuote: number) => this.log(`Accepted Quote succesfully`)),
        catchError(this.handleError<number>('Accept Quote'))
      );
  }

  /** POST: accept quote */
  completeRfq(supplierEvaluation: CompleteRfq): Observable<CompleteRfq> {
    const url = `${baseUrl}/CompleteRFQ`;
    return this.http.post<any>(url, supplierEvaluation, this.httpOptions)
      .pipe(
        map(rfq => {  return rfq.message }),
        tap((supplierEvaluation: CompleteRfq) => this.log(`Rfq completed succesfully`)),
        catchError(this.handleError<CompleteRfq>('Complete Rfq'))
      );
  }

  editRFQ(rfq: RFQEdit): Observable<RFQEdit> {
    const url = `${baseUrl}/EditRFQ`;
    return this.http.post<any>(url, rfq, this.httpOptions)
      .pipe(
        map(rfq => { return rfq }),
        tap((newRFQ: RFQEdit) => this.log(`rfq edited succesfully`)),
        catchError(this.handleError<RFQEdit>('editRFQ'))
      );
  }

  getSearchedRfqs(supplierServices: number[], supplierId, userId: string,BuyerPreference: string): Observable<RFQ[]> {
    const url = `${baseUrl}/SearchedRFQs?SupplierId=${supplierId}&UserId=${userId}&BuyerPreference=${BuyerPreference}`;
    return this.http.post<any>(url, supplierServices, this.httpOptions)
      .pipe(
        map(rfqs => { return rfqs.results }),
        tap((serchedRfqs: RFQ[]) => this.log(`Rfqs searched succesfully`)),
        catchError(this.handleError<RFQ[]>('addRFQ'))
      );
  }

  getStarredRfqs(supplierId, userId: string): Observable<RFQ[]> {
    const url = `${baseUrl}/StarredRFQs?UserId=${userId}`;
    return this.http.post<any>(url, supplierId, this.httpOptions)
      .pipe(
        map(rfqs => { return rfqs.results }),
        tap((serchedRfqs: RFQ[]) => this.log(`Rfqs searched succesfully`)),
        catchError(this.handleError<RFQ[]>('addRFQ'))
      );
  }

  starRfqs(rfqId, userId: string): Observable<string> {
    const url = `${baseUrl}/StarRfq?UserId=${userId}`;
    return this.http.post<any>(url, rfqId, this.httpOptions)
      .pipe(
        map(rfqs => { return rfqs.succeeded }),
        tap((serchedRfqs: string) => this.log(`Rfqs searched succesfully`)),
        catchError(this.handleError<string>('addRFQ'))
      );
  }

  //Delete after optimisation
  /** GET rfqs from the server */
  //ViewRFQs?buyerId=1&rfqState=Open
  getRFQS(buyerId: number, rfqState: string): Observable<ViewRfq[]> {
    const url = `${baseUrl}/ViewRFQs?buyerId=${buyerId}&rfqState=${rfqState}`
    return this.http.get<any>(url)
      .pipe(
        map(rfqs => { return rfqs.results }),
        tap(_ => this.log('fetched rfqs')),
        catchError(this.handleError<ViewRfq[]>('getRFQS', []))
      );
  }

  getDefaultCreateRfqDetails(buyerId: number): Observable<DefaultCreateRfqDetails> {
    const url = `${baseUrl}/GetDefaultRfqBuyerDetails?buyerId=${buyerId}`
    return this.http.get<any>(url)
      .pipe(
        map(details => { return details.results }),
        tap(_ => this.log('fetched rfqs')),
        catchError(this.handleError<DefaultCreateRfqDetails>('getRFQS'))
      );
  }

  getRFQHeadings(buyerId: number, rfqState: string): Observable<RfqHeadings[]> {
    const url = `${baseUrl}/ViewRFQHeadings?buyerId=${buyerId}&rfqState=${rfqState}`
    return this.http.get<any>(url)
      .pipe(
        map(rfqs => { return rfqs.results }),
        tap(_ => this.log('fetched rfqs')),
        catchError(this.handleError<RfqHeadings[]>('getRFQS', []))
      );
  }

  getRFQById(rfqId: number): Observable<ViewRfq> {
    const url = `${baseUrl}/ViewRFQById?rfqId=${rfqId}`
    return this.http.get<any>(url)
      .pipe(
        map(rfqs => { return rfqs.results }),
        tap(_ => this.log('fetched rfqs')),
        catchError(this.handleError<ViewRfq>('getRFQS'))
      );
  }

  /** GET all supplier services for CreateRfq  */
  getSupplierServices(): Observable<SupplierServices[]> {
    const url = `${baseUrl}/RFQAllServices`;
    return this.http.get<any>(url)
      .pipe(
        map(supplierServices => { return supplierServices.results }),
        tap(_ => this.log(`fetched Supplier Services`)),
        catchError(this.handleError<SupplierServices[]>(`getSupplierServices}`))
      );
  }

  /** GET number of Rfqs containing specific service  */
  getRfqSupplierServiceCount(supplierId): Observable<RfqCountSearch> {
    const url = `${baseUrl}/RfqsInSupplierServiceCount?supplierId=${supplierId}`;
    return this.http.get<any>(url)
      .pipe(
        map(rfqCount => { return rfqCount.results }),
        tap(_ => this.log(`fetched Rfq Count`)),
        catchError(this.handleError<RfqCountSearch>(`getRfqCountSearch}`))
      );
  }

  /** POST: make payment for quote*/
  makePayment(payRef: MakePaymentRequest): Observable<MakePaymentRequest> {
    const url = `${baseUrl}/MakePaymentRFQ`;
    return this.http.post<any>(url, payRef, this.httpOptions)
      .pipe(
        map(rfqpayRefMessage => {  return rfqpayRefMessage.message }),
        tap((newRFQ: MakePaymentRequest) => this.log(`added rfq succesfully`)),
        catchError(this.handleError<MakePaymentRequest>('addRFQ'))
      );
  }


  uploadDrawingFiles(rfqFiles: UploadRfqFile[]) {
    const url = `${baseUrl}/Upload/DrawingFiles`;
    return this.http.post<any>(url, rfqFiles, this.httpOptions)
      .pipe(
        // map(rfqpayRefMessage => {  return rfqpayRefMessage.message }),
        tap((drawingFiles: UploadRfqFile) => this.log(`drawings uploaded succesfully`)),
        catchError(this.handleError<UploadRfqFile>('ulpoadDrawingFiles'))
      );
  }

  uploadDeliveryNoteFile(rfqFile: UploadRfqFile) {
    const url = `${baseUrl}/Upload/DeliveryNoteFile`;
    return this.http.post<any>(url, rfqFile, this.httpOptions)
      .pipe(
        // map(rfqpayRefMessage => {  return rfqpayRefMessage.message }),
        tap((drawingFiles: UploadRfqFile) => this.log(`delivery note uploaded succesfully`)),
        catchError(this.handleError<UploadRfqFile>('uploadDeliveryNoteFiles'))
      );
  }

  uploadProofOfPaymentFile(rfqFile: UploadRfqFile) {
    const url = `${baseUrl}/Upload/ProofOfPayment`;
    return this.http.post<any>(url, rfqFile, this.httpOptions)
      .pipe(
        // map(rfqpayRefMessage => {  return rfqpayRefMessage.message }),
        tap((drawingFiles: UploadRfqFile) => this.log(`proof of payment uploaded succesfully`)),
        catchError(this.handleError<UploadRfqFile>('uploadProofOfPaymentFile'))
      );
  }

  uploadPurchaseOrderFile(rfqFile: UploadRfqFile) {
    const url = `${baseUrl}/Upload/PurchaseOrderFile`;
    return this.http.post<any>(url, rfqFile, this.httpOptions)
      .pipe(
        // map(rfqpayRefMessage => {  return rfqpayRefMessage.message }),
        tap((drawingFiles: UploadRfqFile) => this.log(`purchase order uploaded succesfully`)),
        catchError(this.handleError<UploadRfqFile>('uploadPurchaseOrderFile'))
      );
  }

 

  /** GET, check if project name is unique */
  //RfqNameUnique?buyerId=1&projectName=ABC
  getProjectNameCheck(buyerId: number, projectName: string): Observable<boolean> {
    const url = `${baseUrl}/RfqNameUnique?buyerId=${buyerId}&projectName=${projectName}`
    return this.http.get<any>(url)
      .pipe(
        map(projectNameUnique => { return projectNameUnique.results }),
        tap(_ => this.log('project name unique check')),
        catchError(this.handleError<boolean>('getProjectNameCheck'))
      );
  }

  /** GET, rfq state */
  //GetRfqState?rfqId=1
  getRfqState(rfqId: number): Observable<string> {
    const url = `${baseUrl}/GetRfqState?rfqId=${rfqId}`
    return this.http.get<any>(url)
      .pipe(
        map(rfqState => { return rfqState.results }),
        tap(_ => this.log('rfq State Check')),
        catchError(this.handleError<string>('getRfqCheck'))
      );
  }

  getRfqArchiveState(rfqId: number): Observable<boolean> {
    const url = `${baseUrl}/GetRfqArchivedState?rfqId=${rfqId}`
    return this.http.get<any>(url)
      .pipe(
        map(rfqState => { return rfqState.results }),
        tap(_ => this.log('rfq State Check')),
        catchError(this.handleError<boolean>('getRfqCheck'))
      );
  }

  //Get payment made check
  getPaymentMadeCheck(quoteId: number): Observable<boolean> {
    const url = `${baseUrl}/PaymentMadeCheck?quoteId=${quoteId}`
    return this.http.get<any>(url)
      .pipe(
        map(rfqState => { return rfqState.results }),
        tap(_ => this.log('rfq State Check')),
        catchError(this.handleError<boolean>('getRfqCheck'))
      );
  }

  /** GET rfqDrawing file  */
  getRfqDrawingFile(rfqFileId: number): Observable<RfqFileDownload> {
    const url = `${baseUrl}/Download/DrawingFile/${rfqFileId}`;
    return this.http.get<any>(url)
      .pipe(
        map(rfqDrawingFile => { return rfqDrawingFile.file }),
        tap(_ => this.log(`fetched Rfq Drawing File`)),
        catchError(this.handleError<RfqFileDownload>(`getRfqDrawingFile}`))
      );
  }

  /** GET rfqDrawing file  */
  getPurchaseOrderFile(rfqFileId: number, userId: string, role: string): Observable<RfqFileDownload> {
    const url = `${baseUrl}/Download/PurchaseOrderFile/${rfqFileId}/${userId}/${role}`;
    return this.http.get<any>(url)
      .pipe(
        map(rfqDrawingFile => { return rfqDrawingFile.file }),
        tap(_ => this.log(`fetched Rfq Purchase Order File`)),
        catchError(this.handleError<RfqFileDownload>(`getPurchaseOrderFile}`))
      );
  }

  /** DELETE rfqDrawing file  */
  deleteRfqDrawingFile(rfqFileId: number) {
    const url = `${baseUrl}/Delete/DrawingFile/${rfqFileId}`;
    return this.http.delete<any>(url)
      .pipe(
        tap(_ => this.log(`Deleted Rfq Drawing File`)),
        catchError(this.handleError<RfqFileDownload>(`deleteRfqDrawingFile}`))
      );
  }

  deleteMultipleRfqDrawingFiles(rfqFileIds: number[]) {
    const url = `${baseUrl}/Delete/SelectedDrawingFiles`;
    return this.http.post<any>(url, rfqFileIds, this.httpOptions)
      .pipe(
        tap(_ => this.log(`Deleted Rfq Drawing Files`)),
        catchError(this.handleError<RfqFileDownload>(`deleteRfqDrawingFiles}`))
      );
  }

  getSingleSearchRfq(rfqId: number, userId: string,supplierId: number): Observable<RFQ> {
    const url = `${baseUrl}/getSearchRfqByRfqId/${rfqId}/${userId}/${supplierId}`;
    return this.http.get<any>(url)
      .pipe(
        map(rfq => { return rfq.results }),
        tap(_ => this.log(`fetched Rfq`)),
        catchError(this.handleError<RFQ>(`getSingleSearchRfq}`))
      );
  }

  getRfqExportZip(rfqId: number): Observable<boolean> {
    const url = `${baseUrl}/exportRfqZip/${rfqId}`;
    return this.http.get<any>(url)
      .pipe(
        map(rfqExport => { return rfqExport.results }),
        tap(_ => this.log(`fetched Rfq`)),
        catchError(this.handleError<boolean>(`getRfqExportZip}`))
      );
  }

  getRfqStarredCheck(rfqId: number, userId: string): Observable<boolean> {
    const url = `${baseUrl}/getRfqStarredCheck/${rfqId}/${userId}`;
    return this.http.get<any>(url)
      .pipe(
        map(rfq => { return rfq.results }),
        tap(_ => this.log(`fetched Rfq`)),
        catchError(this.handleError<boolean>(`getRfqStarredCheck}`))
      ); 
  }

  getRfqPdf(rfqId: number): Observable<string> {
    const url = `${baseUrl}/exportRFQ/${rfqId}`;
    return this.http.get<any>(url)
      .pipe(
        map(rfq => { return rfq.results }),
        tap(_ => this.log(`fetched Rfq`)),
        catchError(this.handleError<string>(`getRfqPdf}`))
      );
  }

  getPreferredSupplierCount(buyerId: number): Observable<number> {
    const url = `${baseUrl}/GetPreferredSupplierCount?buyerId=${buyerId}`;
    return this.http.get<any>(url)
      .pipe(
        map(PreferredSupplierCount => { return PreferredSupplierCount.results }),
        tap(_ => this.log(`fetched Preferred Supplier Count`)),
        catchError(this.handleError<number>(`getPreferredSupplierCount}`))
      );
  }

  /** GET rfqDrawing file  */
  getQuoteFile(quoteId: number, userId: string): Observable<QuoteFileDownload[]> {
    const url = `${baseUrl}/Download/QuoteFile/${quoteId}/${userId}`;
    return this.http.get<any>(url)
      .pipe(
        map(rfqDrawingFile => { return rfqDrawingFile.files }),
        tap(_ => this.log(`fetched Rfq Drawing File`)),
        catchError(this.handleError<QuoteFileDownload[]>(`getRfqDrawingFile}`))
      );
  }

  getRfqFileDetails(rfqId: number, fileType: string): Observable<RfqFileDetails> {
    const url = `${baseUrl}/RfqFileDetails/${rfqId}/${fileType}`;
    return this.http.get<any>(url)
      .pipe(
        map(rfqFileDetails => { return rfqFileDetails.results }),
        tap(_ => this.log(`fetched Rfq Drawing File`)),
        catchError(this.handleError<RfqFileDetails>(`getRfqFileDetails`))
      );
  }

  getRfqReview(rfqId: number): Observable<FullRfqReview> {
    const url = `${baseUrl}/ViewRfqReview?RfqId=${rfqId}`;
    return this.http.get<any>(url)
      .pipe(
        map(quoteReviewDetails => { return quoteReviewDetails.results }),
        tap(_ => this.log(`fetched quote review details`)),
        catchError(this.handleError<FullRfqReview>(`getQuoteReview`))
      );
  }

  /** GET hero by id. Will 404 if id not found */
  getRFQ(id: number): Observable<RFQ> {
    const url = `${this.rfqUrl}/${id}`;
    return this.http.get<RFQ>(url).pipe(
      tap(_ => this.log(`fetched rfq id=${id}`)),
      catchError(this.handleError<RFQ>(`getRFQ id=${id}`))
    );
  }



  /* GET heroes whose name contains search term */
  searchRFQS(term: string): Observable<RFQ[]> {
    if (!term.trim()) {
      // if not search term, return empty hero array.
      return of([]);
    }
    return this.http.get<RFQ[]>(`${this.rfqUrl}/?name=${term}`).pipe(
      tap(x => x.length ?
        this.log(`found rfqs matching "${term}"`) :
        this.log(`no rfqs matching "${term}"`)),
      catchError(this.handleError<RFQ[]>('searchRFQs', []))
    );
  }


  /** PUT: update the hero on the server */
  updateRFQ(rfq: RFQ): Observable<any> {
    return this.http.put(this.rfqUrl, rfq, this.httpOptions).pipe(
      tap(_ => this.log(`updated rfq id=${rfq.rfqId}`)),
      catchError(this.handleError<any>('updateRFQ'))
    );
  }

  // QUOTE EDITED IF THE BUYER HAS SEEN CHECK

  getQuoteseenCheck(quoteIds: number[], userId: string): Observable<string> {
    const url = `${baseUrl2}/EditedSeen/QuoteEditedSeenCheck?UserId=${userId}`;
    return this.http.post<any>(url, quoteIds, this.httpOptions)
      .pipe(
        map(seenQuotes => { return seenQuotes.results }),
        tap((serchedRfqs: string) => this.log(`Edited not seen Quotes returned succesfully`)),
        catchError(this.handleError<string>('failed'))
      );
  }

  cancelRfq( rfqId: number): Observable<boolean> {
    const url = `${baseUrl}/CancelRfq`;
    return this.http.post<any>(url, rfqId, this.httpOptions)
      .pipe(
        map(results => { return results.results }),
        tap((cancelRfq: boolean) => this.log(`Rfq Cancelled`)),
        catchError(this.handleError<boolean>('failed'))
      );
  }


  // SET QUOTE EDITED CHECK TO SEEN
  getQuotesetSeen(quoteId: number, userId: string): Observable<string> {
    const url = `${baseUrl2}/EditedSeen/SetEditedSeenCheck?QuoteId=${quoteId}&userId=${userId}`
    return this.http.get<any>(url)
      .pipe(
        map(setseen => { return setseen.results }),
        tap(_ => this.log('Set Edited Quote to seen')),
        catchError(this.handleError<string>('getQuotesetSeen'))
      );
  }



  /** DELETE: delete the hero from the server */
  deleteHero(id: number): Observable<RFQ> {
    const url = `${this.rfqUrl}/${id}`;

    return this.http.delete<RFQ>(url, this.httpOptions).pipe(
      tap(_ => this.log(`deleted rfq id=${id}`)),
      catchError(this.handleError<RFQ>('deleteRFQ'))
    );
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`RFQService: ${message}`);
  }

  private rfqUrl = 'api/rfqs';  // URL to web api



  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.toastrNotification.error(`${operation} failed: ${error.message}`);

      throw error;
    };
  }


}
