import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { finalize, first } from 'rxjs/operators';
import { RFQ } from '../models/rfq';
import { rfqState } from '../models/rfqState';
import { QuoteSubmitted } from '../models/quote';
import { ThisReceiver } from '@angular/compiler';
import { ToastrNotificationService } from '../services/toastr-notification.service';
import { RfqService } from '../services/rfq.service';
import { SupplierServices } from '../models/supplierServices';
import { QuoteService } from '../services/quote.service';
import { ReloadViewRfqs } from '../models/reloadViewRfqs';
import { ViewQuoteServiceService } from '../services/view-quote-service.service';
import { Account } from '../models/account';
import { AuthService } from '../auth/auth.service';
import { QuoteFileUpload } from '../models/quoteFile';
import { Router } from '@angular/router';

@Component({
  selector: 'app-submit-quote',
  templateUrl: './submit-quote.component.html',
  styleUrls: ['./submit-quote.component.css']
})
export class SubmitQuoteComponent implements OnInit {

  @ViewChild('clearInput', { static: false }) clearInputVar: ElementRef;

  @Output() quoteId = new EventEmitter<number>();

  resetQuoteFileInput() {
    this.clearInputVar.nativeElement.value = "";
  }

  @Output() closeSubmitModalCond = new EventEmitter<boolean>();

  closeSubmitModal(value: boolean) {
    this.closeSubmitModalCond.emit(value);
  }

  @Input() rfq: RFQ;
  // @Output() quoteAccepted = new EventEmitter<boolean>();

  // quoteSubmittedCond(value){
  //  this.quoteAccepted.emit(value);
  // }
  termsAndConditionsMock: string = "Terms and conditions will be displayed here"
  requiredFileType: string = ".pdf";
  form: FormGroup;
  quoteFileName = '';
  termsCondFileName = '';
  uploadProgress: number;
  quoteFileUploadComplete: boolean;
  termsCondUploadComplete: boolean;
  uploadSub: Subscription;
  quoteFile: File;
  termsCondFile: File;
  allservices: SupplierServices[];
  quoteValidCond: boolean;
  alreadyQuoted: boolean;
  editTCCond: boolean = false;

  setAsQuottedOn: ReloadViewRfqs;

  serviceListSelect: any[] = [];
  serviceList: any[] = [];

  //currency: RegExp = /^(?![0,.]+$)(?:0|[1-9]\d{0,2}(?:,\d{3})*|[1-9]\d*)(?:\.\d{1,2})?$/;

  quote: QuoteSubmitted = {
    UserName: null, RfqId: null, Amount: null, LeadTime: null, ServiceIds: [], SupplierId: null,
    CollectionCity: null, CollectionPostalCode: null, CollectionProvince: null, CollectionStreetAddress: null
  };

  quotes: QuoteSubmitted[] = []
  isQuoted: boolean = false;

  quoteFileUpload: QuoteFileUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };

  // needed for date calcs
  public dateNow = new Date();
  public dDay?: Date;
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  public mtimeDifference;
  public msecondsToDday;
  public mminutesToDday;
  public mhoursToDday;
  public mdaysToDday;

  account: Account;

  defaultTotal: any;

  constructor(
    public authService: AuthService,
    private formBuilder: FormBuilder,
    private currencyPipe: CurrencyPipe,
    private http: HttpClient,
    private toastrNotification: ToastrNotificationService,
    private rfqService: RfqService,
    private quoteService: QuoteService,
    private reloader: ViewQuoteServiceService,
    private router: Router,) {

    this.authService.account.subscribe(x => this.account = x);
  }

  ngOnInit() {

    this.defaultTotal = 12.12;

    this.form = this.formBuilder.group({

      totalAmount: [null, Validators.required],
      leadTime: [null, Validators.required],
      services: [null],

      collectionAddressDifYes: [null, Validators.required],
      collectionAddressDifNo: [null, Validators.required],

      collectionStreetAddress: ["", Validators.required],
      collectionCity: ["", Validators.required],
      collectionProvince: ["", Validators.required],
      collectionPostalCode: ["", Validators.required],

      termsAndConditionsEdit: [this.termsAndConditionsMock]

    });

    // this.form.valueChanges.subscribe(form => {
    //   if (form.totalAmount) {
    //     this.form.patchValue({
    //       totalAmount: this.currencyPipe.transform(form.totalAmount.replace(/\D/g, '').replace(/^0+/, ''), 'R', 'symbol', '1.0-0')         
    //     }, { emitEvent: false });
    //   }
    // });

    for (let index of this.rfq.servicesRequired) {
      this.serviceListSelect.push(index);
    }

    this.getSupplierServices();


  }

  totalValidator(value) {
    if (value) {      
        if (value.match(/[.]/g).length <= 1) {
          return true;
        } else {
          return false;
        }
      }
    
  }

  transformTotal() {

    const value = this.form.controls.totalAmount.value;
    this.form.controls.totalAmount.setValue(
      this.formatMoney(value.replace(/\,/g, "")),
      { emitEvent: false }
    );
  }

  formatMoney(value) {
    const temp = `${value}`.replace(/\,/g, "");
    return this.currencyPipe.transform(temp).replace("$", "");
  }

  collectionDeliveryAddressCond: string = null;

  collectionAddressDifferent(answer) {

    if (answer == 'Yes') {
      this.f.collectionAddressDifYes.setValue(true);
      if (this.collectionDeliveryAddressCond == null) {
        this.collectionDeliveryAddressCond = "Yes";
      } else if (this.collectionDeliveryAddressCond == "No") {
        this.f.collectionAddressDifNo.setValue(false);
        this.collectionDeliveryAddressCond = "Yes";
      }
    } else if (answer == 'No') {
      this.f.collectionAddressDifNo.setValue(true);
      if (this.collectionDeliveryAddressCond == null) {
        this.collectionDeliveryAddressCond = "No";
      } else if (this.collectionDeliveryAddressCond == "Yes") {
        this.f.collectionAddressDifYes.setValue(false);
        this.collectionDeliveryAddressCond = "No";
      }
    }

  }

  stringValidation(value) {
    if (value.replace(/ /g, "") == "") {
      return true
    } else {
      return false;
    }
  }

  quoteExistCheck() {
    this.quoteService.QuoteExistCheck(this.rfq.rfqId, this.account.account.supplierId)
      .subscribe(check => {
        if(check != 0){
          this.alreadyQuoted = true;
        }else{
          this.alreadyQuoted = false;
        }
       

      })
  }

  quoteValid() {
    if (this.f.totalAmount.value == null || this.f.leadTime.value == null || this.quoteFileName == ''
      || this.f.leadTime.value < 0 || this.f.totalAmount.value.match(/[.]/g).length > 1
      || (this.rfq.deliveryRequirements == "Collection" && this.collectionDeliveryAddressCond == "No"
        && (this.f.collectionStreetAddress.value.replace(/ /g, "") == ""
          || this.f.collectionCity.value.replace(/ /g, "") == ""
          || this.f.collectionPostalCode.value.replace(/ /g, "") == ""
          || this.f.collectionProvince.value.replace(/ /g, "") == ""))) {
      return false;
    } else {
      this.quoteValidCond = true;
      return true;
    }

  }


  quoteValidCheck() {

    if (this.quoteValid() == false) {
      this.quoteValidCond = false
    } else {
      this.quoteValidCond = true
    }
  }

  leadTimeValidCheck() {
    if (this.f.leadTime.value > this.getMaxLeadTime(this.rfq.maxLeadTime, this.rfq.rfqClosingDate)) {
      return true;
    } else {
      return false;
    }
  }

  leadTimeNegativeCheck() {
    if (this.f.leadTime.value < 0) {
      return true;
    } else {
      return false;
    }
  }

  getSupplierServices() {
    this.rfqService.getSupplierServices()
      .subscribe(supplierServices => this.allservices = supplierServices)
  }

  submitQuote() {

    // this.toastrNotification.success('Quote successfully edited');

    this.quoteSubmitting = true;

    this.quote = {
      UserName: null, RfqId: null, Amount: null, LeadTime: null, ServiceIds: [], SupplierId: null,
      CollectionCity: null, CollectionPostalCode: null, CollectionProvince: null, CollectionStreetAddress: null
    };

    if (this.f.totalAmount.value != null && this.f.leadTime.value != null &&
      this.quoteFileName != "" && this.f.leadTime.value >= 0) {

      this.quoteService.QuoteExistCheck(this.rfq.rfqId, this.account.account.supplierId).pipe(first())
        .subscribe({
          next: (response: any) => {
            if (response == false) {
              this.quote = { UserName: null, RfqId: null, Amount: null, LeadTime: null, ServiceIds: [], SupplierId: null };
              this.quote.UserName = this.account.account.userName
              this.quote.RfqId = this.rfq.rfqId;
              this.quote.SupplierId = this.account.account.supplierId;
              // this.quote.Amount = Number(this.f.totalAmount.value.replace(/R/, '').replace(/,/g, ''));
              
              this.quote.Amount = Number(this.f.totalAmount.value.replace(/,/g, ''));
            
              this.quote.LeadTime = this.f.leadTime.value;

              if (this.rfq.deliveryRequirements == "Collection" && this.collectionDeliveryAddressCond == "No") {
                this.quote.CollectionStreetAddress = this.f.collectionStreetAddress.value;
                this.quote.CollectionCity = this.f.collectionCity.value;
                this.quote.CollectionPostalCode = this.f.collectionPostalCode.value;
                this.quote.CollectionProvince = this.f.collectionProvince.value;
              } else {
                this.quote.CollectionStreetAddress = null;
                this.quote.CollectionCity = null;
                this.quote.CollectionPostalCode = null;
                this.quote.CollectionProvince = null;
              }

              for (let service of this.serviceListSelect) {
                for (let supplierService of this.allservices) {
                  if (service == supplierService.serviceName) {
                    this.quote.ServiceIds.push(supplierService.supplierServiceId);
                  }
                }
              }

              // this.quote.QuoteTCName = "upload test";
              // this.quote.QuoteFileName = "upload test";
              this.isQuoted = true;


              this.createQuote();

            } else {
              this.quoteSubmitting = false;
              this.toastrNotification.error('Rfq already quoted on by supplier');
            }
          },
          error: error => {
            this.toastrNotification.error("Quote Check Failed");
            this.quoteSubmitting = false;
          }
        });


    } else {

      this.toastrNotification.error('error in submit quote form');
    }

  }

  @ViewChild('submitQuoteBtn') submitQuoteBtn: ElementRef;

  @ViewChild('SuccessMdl') SuccessMdl: ElementRef;


  quoteSubmitting: boolean = false;

  quoteFileUploading: boolean = false;

  modeltest() {
    this.submitQuoteBtn.nativeElement.click();
    this.SuccessMdl.nativeElement.click();
  }

  clearQuote(){

    this.form.reset();

    this.f.collectionStreetAddress.setValue("");
    this.f.collectionCity.setValue("");
    this.f.collectionPostalCode.setValue("");
    this.f.collectionProvince.setValue("");

    this.f.collectionAddressDifYes.setValue(null);
    this.f.collectionAddressDifNo.setValue(null);
    this.f.totalAmount.setValue(null);
    this.f.leadTime.setValue(null);
    this.f.services.setValue(null);

    this.collectionDeliveryAddressCond = null;

    this.quoteDeleteFile();
  }

  createQuote() {

    this.quoteFileUploading = true;
    //async
    var result;
    this.quoteService.addQuote(this.quote).pipe(first())
      .subscribe({
        next: (response: any) => {
          result = response;
          this.quoteFileUpload.quoteId = null;
          this.quoteFileUpload.quoteId = response;

          this.quoteSubmitting = false;
          this.quoteService.ulpoadQuoteFile(this.quoteFileUpload).pipe(first())
            .subscribe({
              next: (response: any) => {
                this.quoteId.emit(result);
                this.submitQuoteBtn.nativeElement.click();
                this.quoteFileUploading = false;
                // this.toastrNotification.success('Quote successfully created');  
                this.setAsQuottedOn = { reloadCondition: null, reloadRfqId: null };
                this.setAsQuottedOn.reloadCondition = true;
                this.setAsQuottedOn.reloadRfqId = this.rfq.rfqId;

                this.reloader.markQuoteAsSubmitted(this.setAsQuottedOn);

                this.form.reset();

                this.f.collectionStreetAddress.setValue("");
                this.f.collectionCity.setValue("");
                this.f.collectionPostalCode.setValue("");
                this.f.collectionProvince.setValue("");

                this.f.collectionAddressDifYes.setValue(null);
                this.f.collectionAddressDifNo.setValue(null);
                this.f.totalAmount.setValue(null);
                this.f.leadTime.setValue(null);
                this.f.services.setValue(null);

                this.collectionDeliveryAddressCond = null;

                this.quoteDeleteFile();
                // this.termsCondDeleteFile();    
              },
              error: error => {
                this.toastrNotification.error(" Quote File Upload Failed");
                this.quoteFileUploading = false;
              }
            });
        },
        error: error => {
          this.toastrNotification.error(" Quote Submission Failed");
          this.quoteSubmitting = false;
        }

      })
  }

  editTC(cond) {
    this.editTCCond = cond;
  }

  selectService() {
    this.serviceList.push(this.f.services.value);
    this.serviceListSelect.splice(this.serviceListSelect.indexOf(this.f.services.value), 1);
    this.f.services.setValue(null);
  }

  deleteService(service) {
    this.serviceList.splice(this.serviceList.indexOf(service), 1);
    this.serviceListSelect.push(service);
    this.f.services.setValue(null);
  }

  quoteFileSelected(event) {

    this.quoteFileUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };

    this.quoteFileUploadComplete = false;
    this.quoteFile = event.target.files[0];
    const reader = new FileReader();

    if (this.quoteFile) {

      this.quoteFileName = this.quoteFile.name;

      reader.readAsDataURL(this.quoteFile);
      reader.onload = () => {
      
        this.quoteFileUpload.file = (reader.result + "").substring((reader.result + "").indexOf(",") + 1, (reader.result + "").length);
        
        this.quoteFileUpload.rfqId = this.rfq.rfqId;
        this.quoteFileUpload.buyerId = null;
        this.quoteFileUpload.supplierId = this.account.account.supplierId;
        this.quoteFileUpload.userId = this.account.account.userId;
        this.quoteFileUpload.fileName = this.quoteFile.name;
      };

    }
    
    if (this.quoteFileName != "") {
      this.quoteFileUploadComplete = true;
    } else {
      this.quoteFileUploadComplete = false;
    }
  }
  quoteDeleteFile() {
    this.quoteFileName = "";
    this.quoteFile = null;
    this.resetQuoteFileInput();
   
    this.quoteFileUploadComplete = false;
  }

  get f() { return this.form.controls; }


  getMaxLeadTime(deliveryDate, closingDate) {
    this.getDateDifference(new Date(deliveryDate), new Date(closingDate));
    return this.mdaysToDday;

  }

  getDateDifference(deliveryDate, closingDate) {
    var maxLeadDif = deliveryDate.getTime() - closingDate.getTime();
    this.allocateMaxLeadTimeUnits(maxLeadDif);
  }

  private allocateMaxLeadTimeUnits(timeDifference) {
    this.msecondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.mminutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.mhoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.mdaysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }
}
