import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { Account } from '../models/account';
import { RFQ } from '../models/rfq';
import { RFQCreate, CreateRFQs_Dimensions, UploadRfqFile } from '../models/rfqCreate';
import { EditRFQs_Dimensions, RFQEdit } from '../models/rfqEdit';
import { RfqFileDownload } from '../models/rfqFileDownload';
import { BEERequirements, DeliveryRequirements, ISORequirements, LocationRequirements, PackagingRequirements, PartnershipRequirements, PaymentPreference, Service, TypeOfSupply } from '../models/service';
import { SupplierServices } from '../models/supplierServices';
import { ViewRfq } from '../models/viewRfq';
import { QuoteService } from '../services/quote.service';
import { RfqService } from '../services/rfq.service';
import { SortCriteria } from '../pipes/sort-asc-dsc.pipe';
import { ToastrNotificationService } from '../services/toastr-notification.service';
import { switchMap, finalize, first } from 'rxjs/operators';
import { Router } from '@angular/router';


@Component({
  selector: 'app-re-order-rfq',
  templateUrl: './re-order-rfq.component.html',
  styleUrls: ['./re-order-rfq.component.css']
})
export class ReOrderRfqComponent implements OnInit, OnChanges {

  public criteria: SortCriteria;

  @Output() reloadRfqs = new EventEmitter<boolean>();

  @Output() periodicUpdateCond = new EventEmitter<boolean>();

  triggerReloadRfqs(value: boolean) {
    this.reloadRfqs.emit(value);
  }

  triggerperiodicUpdate(value: boolean) {
    this.periodicUpdateCond.emit(value);
  }

  @Input() rfq: ViewRfq;

  form: FormGroup;
  nextmodal: boolean = false;
  uniquePartNo: boolean = true;
  rfqId: number;
  rfqValidCond: boolean;
  paymentValidCond: boolean;
  packagingValidCond: boolean;
  dimensionsValidCond: boolean;
  partNoUniqueCond: boolean;
  projectNameUnique: boolean = true;
  viewRfqFiles: RfqFileDownload[] = [];
  viewRfqFile: RfqFileDownload = { rfqFileId: null, fileContent: null, fileName: null, fileType: null };


  otherPayValue: string;
  otherpackageValue: string;
  additionaComments: string;
  drawingChanges: string;
  productIdentification: string;

  deliveryStreetAddress: string;
  deliveryCity: string;
  deliveryPostal: string;
  deliveryProvince: string;


  //new Rfq object 
  reOrderRfq: RFQCreate = {
    RfqName: null, TypeOfSupply: null, ServicesRequired: [], Dimensions: [], AllDrawingChanges: null, MaxLeadTime: null,
    RfqClosingDate: null, PaymentRequirement: null, OtherPaymentSpecification: null, ProductIdentification: null, ProductCertification: null, BeeRequirement: null, IsoRequirement: null,
    PartnershipRequirement: null, PackagingRequirements: null, OtherPackagingSpecification: null, LocationRequirements: null, DeliveryRequirements: null, AdditionalComments: null, BuyerId: null, CreatedBy: null
    , DeliveryCity: null, DeliveryPostalCode: null, DeliveryProvince: null, DeliveryStreetAddress: null
  };



  // variables needed for Check of individual or supply selected
  typeOfSupply: TypeOfSupply = null;


  // variables needed for difference on parts selected
  diffOnparts: string = null;
  diffOnpartsCond: boolean;

  // variables needed for Check if Drawings or Dimensions is selected
  drawOrDim: string = null;





  //product id check
  pid: boolean = null;

  //ISO requirements check
  isoRequirements: boolean = null;

  // Array for adding dimensions blocks to UI
  dimensionsUI: EditRFQs_Dimensions[] = [];
  dimensionsgroup: EditRFQs_Dimensions = { PartNo: null, Type: null, Quantity: null, Dimensions: null, MaterialGrade: null, DimensionSupplierServiceId: null }

  deliverOrCollection: string = null;
  account: Account;

  constructor(private formBuilder: FormBuilder,
    private rfqService: RfqService,
    public toastrNotification: ToastrNotificationService,
    public authService: AuthService,
    private quoteService: QuoteService,
    private router: Router) {
    this.criteria = {
      property: 'supplierServices',
      descending: true
    };

    this.authService.account.subscribe(x => this.account = x);
  }

  //REGEX for Dimensions   
  dimValidCheck: RegExp = /^\s*?\d{0,6}[.]?\d{1,6}[x]\d{0,6}[.]?\d{1,6}[x]\d{0,6}[.]?\d{1,6}\s*?$/i;
  dimValidCond: boolean;


  postalCodeValidator: RegExp = /^\s*?\d{1,10}\s*?$/i;

  // All Services
  selectedservice: number[] = [];
  dimensionSelectedService: number[] = [];
  dimensionSelectedServiceDuplicate: number[] = [];
  allservices: SupplierServices[] = [];
  dimensionServices: SupplierServices[] = [];
  RawMaterialServices: SupplierServices[] = [];
  dimensionServicesSelect: string[] = ["Profile Cutting", "Plasma Cutting", "Guillotining", "Laser Cutting"];
  displayServices: SupplierServices[] = [];
  supplierServaceAdd: SupplierServices = { supplierServiceId: null, serviceName: null };
  allServiceDisplay: SupplierServices[] = [];

  // All Payment options
  paymentoptions: PaymentPreference[] = [PaymentPreference.COD, PaymentPreference.CBD, PaymentPreference.Days30, PaymentPreference.Days60, PaymentPreference.Other, PaymentPreference.NotSpecified];

  // BEE Requirements
  BEE: BEERequirements[] = [BEERequirements.Level1, BEERequirements.Level2, BEERequirements.Level3, BEERequirements.Level4, BEERequirements.Level5, BEERequirements.All];

  // ISO Requirements
  ISO: ISORequirements[] = [ISORequirements.R9001, ISORequirements.R9002, ISORequirements.R9003];

  Partners: PartnershipRequirements[] = [PartnershipRequirements.PreferredSuppliers, PartnershipRequirements.All,];

  Location: LocationRequirements[] = [LocationRequirements.GP];

  packagingList: PackagingRequirements[] = [PackagingRequirements.Boxed, PackagingRequirements.Loose, PackagingRequirements.Pallet, PackagingRequirements.Strapped, PackagingRequirements.Other];

  deliveryList: DeliveryRequirements[] = [DeliveryRequirements.Collection, DeliveryRequirements.Delivery];

  differentDeliveryAddressCond: string = null;

  //needed for date calculations
  valDate = true;
  minDate: any;
  maxDate: any;
  dueDate: any;
  dueHours: any;
  expHours: any;
  date1: any;
  date2: any;
  expireDate: any;
  //needed for date calculations

  //check form submission 
  submitted = false;

  ngOnInit() {

  }

  ngOnChanges(): void {

    // var closingDate = this.convertDate(this.rfq.rfqClosingDate);
    // var deliveryDate = this.convertDate(this.rfq.maxLeadTime);
    // this.getExpireDaysInitial(closingDate);
    // this.getExpireHoursInitial(closingDate);
    // this.getDeliveryDaysInitial(deliveryDate);
    // this.getDeliveryHoursInitial(deliveryDate);

    // this.getMaxLeadTime(this.rfq.maxLeadTime, this.rfq.rfqClosingDate);

    if (this.rfq.otherPaymentSpecification == null) {
      this.otherPayValue = "";
    } else {
      this.otherPayValue = this.rfq.otherPaymentSpecification;
    }

    if (this.rfq.otherPackagingSpecification == null) {
      this.otherpackageValue = "";
    } else {
      this.otherpackageValue = this.rfq.otherPackagingSpecification;
    }

    if (this.rfq.productIdentification == null) {
      this.productIdentification = "";
    } else {
      this.productIdentification = this.rfq.productIdentification;
    }

    if (this.rfq.additionalComments == null) {
      this.additionaComments = "";
    } else {
      this.additionaComments = this.rfq.additionalComments;
    }

    if (this.rfq.drawingsChangesComment == null) {
      this.drawingChanges = "";
    } else {
      this.drawingChanges = this.rfq.drawingsChangesComment;
    }

    if (this.rfq.deliveryStreetAddress == null) {
      this.deliveryStreetAddress = "";
    } else {
      this.deliveryStreetAddress = this.rfq.deliveryStreetAddress;
    }

    if (this.rfq.deliveryCity == null) {
      this.deliveryCity = "";
    } else {
      this.deliveryCity = this.rfq.deliveryCity;
    }

    if (this.rfq.deliveryProvince == null) {
      this.deliveryProvince = "";
    } else {
      this.deliveryProvince = this.rfq.deliveryProvince;
    }

    if (this.rfq.deliveryPostalCode == null) {
      this.deliveryPostal = "";
    } else {
      this.deliveryPostal = this.rfq.deliveryPostalCode;
    }

    this.form = this.formBuilder.group({

      projectName: ["", Validators.required],
      completeSupply: ['', Validators.required],

      complete: [false],
      rawMaterial: [false],

      completeF: [false],
      rawMaterialF: [false],

      supplierServiceSelect: [null, Validators.required],

      dchanges: [this.drawingChanges, Validators.required],


      isoYes: [false],
      isoNo: [false],

      productidYes: [false],
      productidNo: [false],

      VerifiedFactoryYes: [false],
      VerifiedFactoryNo: [false],

      drawings: [false],
      dimensions: [false],

      deliveryDate: [null, Validators.required],
      RFQcloseDate: [null, Validators.required],

      delivery: [null, Validators.required],
      collection: [null, Validators.required],

      deliveryAddressDifYes: [null, Validators.required],
      deliveryAddressDifNo: [null, Validators.required],

      deliveryStreetAddress: [this.deliveryStreetAddress, Validators.required],
      deliveryCity: [this.deliveryCity, Validators.required],
      deliveryProvince: [this.deliveryProvince, Validators.required],
      deliveryPostalCode: [this.deliveryPostal, Validators.required],

      // values for the dimensions array
      partNo: ['', Validators.required],
      numberOfParts: [null, Validators.required],
      dimesions_d: ['', Validators.required],
      materialGrade: ['', Validators.required],
      dimensionSupplierService: [null, Validators.required],
      dimensionType: ["", Validators.required],

      paymentoptions: [this.rfq.paymentRequirement, Validators.required],
      otherPaymentRequirement: [this.otherPayValue, Validators.required],

      P_Cert_Req: [this.productIdentification, Validators.required],

      BEE: [this.rfq.beeRequirement, Validators.required],

      ISO: [this.rfq.isoRequirement, Validators.required],

      Partners: [this.rfq.partnerRequirement, Validators.required],

      Location: [this.rfq.supplierLocationRequirements, Validators.required],

      // Delivery: [this.rfq.deliveryRequirements, Validators.required],

      packaging: [this.rfq.packagingRequirements, Validators.required],
      otherPackagingSpecification: [this.otherpackageValue, Validators.required],


      Comments: [this.additionaComments, Validators.required],

    });
    //gets current date


    //Enable and disable Closing date form

    this.getMinDate();

    this.disableCloseDateForm();

    this.dimensionSelectedService = [];

    this.dimensionSelectedServiceDuplicate = [];

    this.dimensionServices = [];

    this.RawMaterialServices = [];

    this.selectedservice = [];

    this.allServiceDisplay = [];

    this.allservices = [];

    this.dimensionsUI = [];

    this.viewRfqFiles = [];

    this.drawingFileArray = [];

    this.getSupplierServices();

    // this.f.drawings.disable();
    // this.f.dimensions.disable();

    // this.f.complete.disable();
    // this.f.rawMaterial.disable();

    this.typeOfSupplycheck(this.rfq.typeOfSupply);

    this.isoRequirementsCheck(this.rfq.isoRequirement);

    this.productidcheck(this.rfq.productCertificationRequirements);

    this.deliveryOrCollection(this.rfq.deliveryRequirements);

    if (this.rfq.deliveryRequirements == "Delivery") {
      if (this.rfq.deliveryStreetAddress != null) {
        this.deliveryAddressDifferent("No");
      } else {
        this.deliveryAddressDifferent("Yes");
      }
    }


  }

  resetReOrder() {

    // this.form.reset();

    this.f.projectName.setValue("");
    this.f.projectName.markAsUntouched();
    this.f.deliveryDate.setValue(null);
    this.f.deliveryDate.markAsUntouched();
    this.f.RFQcloseDate.setValue(null);
    this.f.RFQcloseDate.markAsUntouched();

    this.f.paymentoptions.setValue(this.rfq.paymentRequirement);
    this.f.BEE.setValue(this.rfq.beeRequirement);
    this.f.ISO.setValue(this.rfq.isoRequirement);
    this.f.Partners.setValue(this.rfq.partnerRequirement);
    this.f.Location.setValue(this.rfq.supplierLocationRequirements);
    this.f.packaging.setValue(this.rfq.packagingRequirements);

    if (this.rfq.otherPaymentSpecification == null) {
      this.f.otherPaymentRequirement.setValue("");
    } else {
      this.f.otherPaymentRequirement.setValue(this.rfq.otherPaymentSpecification);
    }

    if (this.rfq.otherPackagingSpecification == null) {
      this.f.otherPackagingSpecification.setValue("");
    } else {
      this.f.otherPackagingSpecification.setValue(this.rfq.otherPackagingSpecification);
    }

    if (this.rfq.productIdentification == null) {
      this.f.P_Cert_Req.setValue("");
    } else {
      this.f.P_Cert_Req.setValue(this.rfq.productIdentification);
    }

    if (this.rfq.additionalComments == null) {
      this.f.Comments.setValue("");
    } else {
      this.f.Comments.setValue(this.rfq.additionalComments);
    }

    if (this.rfq.drawingsChangesComment == null) {
      this.f.dchanges.setValue("");
    } else {
      this.f.dchanges.setValue(this.rfq.drawingsChangesComment);
    }

    if (this.rfq.deliveryStreetAddress == null) {
      this.f.deliveryStreetAddress.setValue("");
    } else {
      this.f.deliveryStreetAddress.setValue(this.rfq.deliveryStreetAddress);
    }

    if (this.rfq.deliveryCity == null) {
      this.f.deliveryCity.setValue("");
    } else {
      this.f.deliveryCity.setValue(this.rfq.deliveryCity);
    }

    if (this.rfq.deliveryProvince == null) {
      this.f.deliveryProvince.setValue("");
    } else {
      this.f.deliveryProvince.setValue(this.rfq.deliveryProvince);
    }

    if (this.rfq.deliveryPostalCode == null) {
      this.f.deliveryPostalCode.setValue("");
    } else {
      this.f.deliveryPostalCode.setValue(this.rfq.deliveryPostalCode);
    }

    this.getMinDate();

    this.disableCloseDateForm();

    this.dimensionSelectedService = [];

    this.dimensionSelectedServiceDuplicate = [];

    this.dimensionServices = [];

    this.RawMaterialServices = [];

    this.selectedservice = [];

    this.allServiceDisplay = [];

    this.allservices = [];

    this.dimensionsUI = [];

    this.viewRfqFiles = [];

    this.drawingFileArray = [];

    this.getSupplierServices();

    // this.f.drawings.disable();
    // this.f.dimensions.disable();

    // this.f.complete.disable();
    // this.f.rawMaterial.disable();

    this.typeOfSupplycheck(this.rfq.typeOfSupply);

    this.isoRequirementsCheck(this.rfq.isoRequirement);

    this.productidcheck(this.rfq.productCertificationRequirements);

    this.deliveryOrCollection(this.rfq.deliveryRequirements);

    if (this.rfq.deliveryRequirements == "Delivery") {
      if (this.rfq.deliveryStreetAddress != null) {
        this.deliveryAddressDifferent("No");
      } else {
        this.deliveryAddressDifferent("Yes");
      }
    }

    this.triggerperiodicUpdate(false);
  }

  goToRfq() {
    this.router.navigate(['buyer-home/view-rfq', 'Open', this.rfqId, 'true']);
  }


  rfqSubmit() {

    this.reOrderRfq = {
      RfqName: null, TypeOfSupply: null, ServicesRequired: [], Dimensions: [], AllDrawingChanges: null, MaxLeadTime: null,
      RfqClosingDate: null, PaymentRequirement: null, OtherPaymentSpecification: null, ProductIdentification: null, ProductCertification: null, BeeRequirement: null, IsoRequirement: null,
      PartnershipRequirement: null, PackagingRequirements: null, OtherPackagingSpecification: null, LocationRequirements: null, DeliveryRequirements: null, AdditionalComments: null, BuyerId: null, CreatedBy: null
      , DeliveryCity: null, DeliveryPostalCode: null, DeliveryProvince: null, DeliveryStreetAddress: null
    };

    this.reOrderRfq.RfqName = this.f.projectName.value;
    this.reOrderRfq.TypeOfSupply = this.typeOfSupply;

    if (this.typeOfSupply == "Complete") {
      if (this.drawOrDim == "Drawings") {
        for (let service of this.selectedservice) {
          this.reOrderRfq.ServicesRequired.push(service);
        }
      } else if (this.drawOrDim == "Dimensions") {
        for (let service of this.dimensionSelectedService) {
          this.reOrderRfq.ServicesRequired.push(service);
        }
      }

    } else if (this.typeOfSupply == "Raw Material") {
      this.reOrderRfq.ServicesRequired.push(this.RawMaterialServices[0].supplierServiceId);
    }


    if (this.drawOrDim == "Dimensions") {
      for (let dimensions of this.dimensionsUI) {
        this.reOrderRfq.Dimensions.push(dimensions);
      }
    }

    this.reOrderRfq.MaxLeadTime = this.f.deliveryDate.value;
    this.reOrderRfq.RfqClosingDate = this.f.RFQcloseDate.value;
    this.reOrderRfq.ProductCertification = this.pid;
    this.reOrderRfq.IsoRequirement = this.isoRequirements;

    if (this.f.dchanges.value.replace(/ /g, "") == '') {
      this.reOrderRfq.AllDrawingChanges = null;
    } else if (this.drawOrDim == "Drawings" && this.f.dchanges.value.replace(/ /g, "") == '') {
      this.reOrderRfq.AllDrawingChanges = this.f.dchanges.value;
    }

    if (this.f.paymentoptions.value == '') {
      this.reOrderRfq.PaymentRequirement = null;
    } else {
      this.reOrderRfq.PaymentRequirement = this.f.paymentoptions.value;
    }

    if (this.f.paymentoptions.value == "Other") {
      this.reOrderRfq.OtherPaymentSpecification = this.f.otherPaymentRequirement.value;
    } else {
      this.reOrderRfq.OtherPaymentSpecification = null;
    }

    if (this.f.P_Cert_Req.value.replace(/ /g, "") == '') {
      this.reOrderRfq.ProductIdentification = null;
    } else {
      this.reOrderRfq.ProductIdentification = this.f.P_Cert_Req.value;
    }

    if (this.f.BEE.value == '' || this.f.BEE.value == BEERequirements.None) {
      this.reOrderRfq.BeeRequirement = null;
    } else {
      this.reOrderRfq.BeeRequirement = this.f.BEE.value;
    }

    if (this.f.Partners.value == '' || this.f.Partners.value == null) {
      this.reOrderRfq.PartnershipRequirement = null;
    } else {
      this.reOrderRfq.PartnershipRequirement = this.f.Partners.value;
    }


    if (this.f.Location.value == '' || this.f.Location.value == null) {
      this.reOrderRfq.LocationRequirements = null;
    } else {
      this.reOrderRfq.LocationRequirements = this.f.Location.value;
    }

    this.reOrderRfq.DeliveryRequirements = this.deliverOrCollection;

    if (this.deliverOrCollection == "Delivery" && this.differentDeliveryAddressCond == "No") {

      this.reOrderRfq.DeliveryStreetAddress = this.f.deliveryStreetAddress.value;
      this.reOrderRfq.DeliveryCity = this.f.deliveryCity.value;
      this.reOrderRfq.DeliveryPostalCode = this.f.deliveryPostalCode.value;
      this.reOrderRfq.DeliveryProvince = this.f.deliveryProvince.value;
    } else {
      this.reOrderRfq.DeliveryStreetAddress = null;
      this.reOrderRfq.DeliveryCity = null;
      this.reOrderRfq.DeliveryPostalCode = null;
      this.reOrderRfq.DeliveryProvince = null;
    }

    if (this.f.packaging.value == '' || this.f.packaging.value == null) {
      this.reOrderRfq.PackagingRequirements = null;
    } else {
      this.reOrderRfq.PackagingRequirements = this.f.packaging.value;
    }

    if (this.f.packaging.value == "Other") {
      this.reOrderRfq.OtherPackagingSpecification = this.f.otherPackagingSpecification.value;
    } else {
      this.reOrderRfq.OtherPackagingSpecification = null;
    }

    if (this.f.Comments.value.replace(/ /g, "") == '') {
      this.reOrderRfq.AdditionalComments = null;
    } else {
      this.reOrderRfq.AdditionalComments = this.f.Comments.value;
    }

    this.reOrderRfq.BuyerId = this.account.account.buyerId;
    this.reOrderRfq.CreatedBy = this.account.account.userName;

    this.createRFQ();
  }

  submittingRfq: boolean = false;
  submittingRfqFiles: boolean = false;

  @ViewChild('RfqSuccessBtn') RfqSuccessBtn: ElementRef;

  createRFQ() {
    this.submittingRfq = true;
    //async
    this.rfqService.addRFQ(this.reOrderRfq).pipe(first())
      .subscribe({
        next: (response: any) => {
          this.submittingRfq = false;
          this.rfqId = Number(response);
          if (this.drawOrDim == "Drawings") {
            this.submittingRfqFiles = true;
            for (let file of this.drawingFileArray) {
              file.rfqId = Number(response);
            }
            this.rfqService.uploadDrawingFiles(this.drawingFileArray).pipe(first())
              .subscribe({
                next: (fileResponse: any) => {
                  this.submittingRfqFiles = false;
                  this.RfqSuccessBtn.nativeElement.click();
                  this.files = [];
                  this.drawingFileArray = [];
                  // this.onSuccess();
                },
                error: error => {
                  this.toastrNotification.error("Re Order Rfq File Uploads Failed");
                  this.submittingRfqFiles = false;
                }
              });
          } else if (this.drawOrDim == "Dimensions") {
            this.RfqSuccessBtn.nativeElement.click();
            //  this.onSuccess();
          }
        },
        error: error => {
          this.toastrNotification.error("Re Order Rfq Failed");
          this.submittingRfq = false;
        }

      });
  }

  rfqValid() {

    if (this.f.projectName.value == null || this.f.projectName.value.length > 50 || this.f.projectName.value.replace(/ /g, "") == "" || this.typeOfSupply == null || this.f.deliveryDate.value == null
      || this.f.RFQcloseDate.value == null || this.drawOrDim == null || this.deliverOrCollection == null || this.projectNameUnique == false
      || this.dimensionNotSubmittedCheck()
      || !this.paymentSpecificationsValid() || this.f.paymentoptions.value == null
      || (this.deliverOrCollection == "Delivery" && this.differentDeliveryAddressCond == null)
      || (this.deliverOrCollection == "Delivery" && this.differentDeliveryAddressCond == "No" && (
        this.f.deliveryStreetAddress.value.replace(/ /g, "") == ""
        || this.f.deliveryCity.value.replace(/ /g, "") == ""
        || this.f.deliveryPostalCode.value.replace(/ /g, "") == ""
        || this.f.deliveryProvince.value.replace(/ /g, "") == ""
        || this.f.deliveryStreetAddress.value.length > 30
        || this.f.deliveryCity.value.length > 30
        || this.f.deliveryProvince.value.length > 30
        || this.f.deliveryPostalCode.value.length > 10
        || !this.postalCodeValid(this.f.deliveryPostalCode.value)
      ))) {
      return false;
    } else if ((this.drawOrDim == "Dimensions" && this.dimensionsUI.length == 0 && this.typeOfSupply == "Complete")
      || (this.drawOrDim == "Drawings" && this.drawingFileArray.length == 0 && this.typeOfSupply == "Complete")
      || (this.drawOrDim == "Drawings" && this.selectedservice.length == 0 && this.typeOfSupply == "Complete")
      || (this.drawOrDim == "Dimensions" && this.dimensionsUI.length == 0 && this.typeOfSupply == "Raw Material")
      || (this.drawOrDim == "Drawings" && this.drawingFileArray.length == 0 && this.typeOfSupply == "Raw Material")
      || (this.drawOrDim == "Drawings" && this.f.dchanges.value.length > 500)) {
      return false;
    } else {
      this.rfqValidCond = true;
      return true;
    }

  }



  dimensionsRectangularCheck(dimensions) {

    if (this.dimValidCheck.test(dimensions)) {

      this.dimValidCond = true;
    } else {

      this.dimValidCond = false;
    }
  }

  postalCodeValid(value) {
    return this.postalCodeValidator.test(value);

  }

  dimensionNotSubmittedCheck() {
    if (this.f.partNo.value.replace(/ /g, "") != "" || this.f.numberOfParts.value != null
      || this.f.dimesions_d.value.replace(/ /g, "") != "" || this.f.materialGrade.value.replace(/ /g, "") != "") {
      return true;
    } else {
      return false;
    }
  }

  rfqValidCheck() {

    if (this.rfqValid() == false) {
      this.rfqValidCond = false
    } else {
      this.rfqValidCond = true
    }

  }

  stringValidation(value) {
    if (value.replace(/ /g, "") == "") {
      return true
    } else {
      return false;
    }
  }

  paymentSpecificationsValid() {

    if (this.f.paymentoptions.value == "Other" && (this.f.otherPaymentRequirement.value == null || this.f.otherPaymentRequirement.value.replace(/ /g, "") == "" || this.f.otherPaymentRequirement.value.length > 250)) {
      return false;
    } else {
      return true;
    }

  }

  paymentValidCheck() {

    if (this.paymentSpecificationsValid() == false) {
      this.paymentValidCond = false
    } else {
      this.paymentValidCond = true
    }

  }

  packagingSpecificationsValid() {

    if (this.f.packaging.value == "Other" && (this.f.otherPackagingSpecification.value == null || this.f.otherPackagingSpecification.value.replace(/ /g, "") == "" || this.f.otherPackagingSpecification.value.length > 250)) {
      return false;
    } else {
      return true;
    }

  }

  additionalCommentsValid() {
    if (this.f.Comments.value.length > 500) {
      return false;
    } else {
      return true;
    }
  }

  additionCommentsCond: boolean;

  additionalCommentsValidCheck() {
    if (this.additionalCommentsValid() == false) {
      this.additionCommentsCond = false;
    } else {
      this.additionCommentsCond = true;
    }
  }

  productIdentificationValid() {
    if (this.f.P_Cert_Req.value.length > 250) {
      return false;
    } else {
      return true;
    }
  }

  productIdentificationCond: boolean;

  productIdentificationValidCheck() {
    if (this.additionalCommentsValid() == false) {
      this.productIdentificationCond = false;
    } else {
      this.productIdentificationCond = true;
    }
  }



  packagingValidCheck() {

    if (this.packagingSpecificationsValid() == false) {
      this.packagingValidCond = false
    } else {
      this.packagingValidCond = true
    }

  }

  @ViewChild('RfqProjectNameValid') RfqProjectNameValid: ElementRef;

  projectNameUniqueCheck(projectName) {
    if (projectName.replace(/ /g, "") != "" && projectName != null) {
      this.rfqService.getProjectNameCheck(this.account.account.buyerId, projectName).subscribe(
        projectNameUniqueCond => {
          this.projectNameUnique = projectNameUniqueCond;
          if (projectNameUniqueCond) {
            this.RfqProjectNameValid.nativeElement.click();
          }
        }
      );
    }
  }


  dimensionsValidCheck() {
    if (this.f.partNo.value.replace(/ /g, "") == "" || this.f.partNo.value == null || this.f.numberOfParts.value == null ||
      this.f.dimesions_d.value.replace(/ /g, "") == "" || this.f.dimesions_d.value == null || this.f.materialGrade.value.replace(/ /g, "") == "" || this.f.materialGrade.value == null) {
      this.dimensionsValidCond = false;
    } else {
      this.dimensionsValidCond = true;
    }

  }

  getSupplierServices() {
    this.rfqService.getSupplierServices()
      .subscribe(supplierServices => {
        for (let drawService of supplierServices) {
          if (drawService.serviceName != "Raw Materials") {

            this.allservices.push(drawService);
            this.displayServices.push(drawService);
          } else {
            this.RawMaterialServices.push(drawService);

          }
        }

        for (let service of supplierServices) {
          for (let serviceName of this.dimensionServicesSelect) {
            this.supplierServaceAdd = { supplierServiceId: null, serviceName: null };
            if (serviceName == (service.serviceName)) {
              this.supplierServaceAdd.serviceName = service.serviceName;
              this.supplierServaceAdd.supplierServiceId = service.supplierServiceId;
              this.dimensionServices.push(this.supplierServaceAdd);
            }
          }
        }

        this.supplierServaceAdd = { supplierServiceId: null, serviceName: null };

        if (this.rfq.rfqFiles.length > 0) {
          for (let service of this.rfq.servicesRequired) {
            for (let serviceId of this.allservices) {
              if (service == serviceId.serviceName) {
                this.selectService(serviceId.supplierServiceId);
              }
            }
          }
        }

        if (this.rfq.dimensions.length > 0) {
          this.draw_or_dim_check('Dimensions');
          for (let dimension of this.rfq.dimensions) {
            this.dimensions_block_addInitial(dimension.partNo, dimension.type, dimension.quantity, dimension.dimensions, dimension.materialGrade, dimension.supplierServiceId);

          }
        } else if (this.rfq.rfqFiles.length > 0) {
          this.draw_or_dim_check('Drawings');
          this.quoteService.getRfqFiles(this.rfq.rfqId).subscribe(rfqFiles => {

            for (let file of rfqFiles) {

              if (file.fileType == "Drawings") {
                this.viewRfqFiles.push(file);

                this.populateDrawingFilesArrayInitial(file)
              }

            }
          })


        }


      });
  }



  // Checks Raw Material or supply buttons
  typeOfSupplycheck(answer) {

    if (answer == 'Raw Material') {
      this.f.rawMaterial.setValue(true);
      this.f.rawMaterialF.setValue(true);
      if (this.typeOfSupply == null) {
        this.typeOfSupply = TypeOfSupply.RawMaterial;
      } else if (this.typeOfSupply == TypeOfSupply.Complete) {
        this.f.complete.setValue(false);
        this.f.completeF.setValue(false);
        this.typeOfSupply = TypeOfSupply.RawMaterial;
      }
    } else if (answer == 'Complete') {
      this.f.complete.setValue(true);
      this.f.completeF.setValue(true);
      if (this.typeOfSupply == null) {
        this.typeOfSupply = TypeOfSupply.Complete;
      } else if (this.typeOfSupply == TypeOfSupply.RawMaterial) {
        this.f.rawMaterial.setValue(false);
        this.f.rawMaterialF.setValue(false);
        this.typeOfSupply = TypeOfSupply.Complete;
      }
    }
  }

  disabledTypeOfSupply() {
    if (this.typeOfSupply == 'Raw Material') {
      this.f.rawMaterialF.setValue(true);
      this.f.completeF.setValue(false);
    } else if (this.typeOfSupply == 'Complete') {
      this.f.rawMaterialF.setValue(false);
      this.f.completeF.setValue(true);
    }
  }

  typeOfSupplyChangeCheck() {
    if (this.files.length == 0 && this.selectedservice.length == 0 && this.dimensionsUI.length == 0) {
      return true;
    } else {
      return false;
    }
  }

  clearDrawings() {
    var selectedServiceDelete = [];
    this.drawingFileArray = [];
    this.files = [];
    for (let s of this.selectedservice) {
      selectedServiceDelete.push(s);
    }

    for (let id of selectedServiceDelete) {
      this.deleteService(id)
    }
    this.f.supplierServiceSelect.markAsUntouched();
    this.f.dchanges.setValue("");
    this.f.dchanges.markAsUntouched();
  }


  deliveryOrCollection(answer) {
    if (answer == 'Delivery') {
      this.f.delivery.setValue(true);
      if (this.deliverOrCollection == null) {
        this.deliverOrCollection = "Delivery";
      } else if (this.deliverOrCollection == "Collection") {
        this.f.collection.setValue(false);
        this.deliverOrCollection = "Delivery";
      }
    } else if (answer == 'Collection') {
      this.f.collection.setValue(true);
      if (this.deliverOrCollection == null) {
        this.deliverOrCollection = "Collection";
      } else if (this.deliverOrCollection == "Delivery") {
        this.f.delivery.setValue(false);
        this.deliverOrCollection = "Collection";
      }
    }

  }

  deliveryAddressDifferent(answer) {
    if (answer == 'Yes') {
      this.f.deliveryAddressDifYes.setValue(true);
      if (this.differentDeliveryAddressCond == null) {
        this.differentDeliveryAddressCond = "Yes";
      } else if (this.differentDeliveryAddressCond == "No") {
        this.f.deliveryAddressDifNo.setValue(false);
        this.differentDeliveryAddressCond = "Yes";
      }
    } else if (answer == 'No') {
      this.f.deliveryAddressDifNo.setValue(true);
      if (this.differentDeliveryAddressCond == null) {
        this.differentDeliveryAddressCond = "No";
      } else if (this.differentDeliveryAddressCond == "Yes") {
        this.f.deliveryAddressDifYes.setValue(false);
        this.differentDeliveryAddressCond = "No";
      }
    }

  }

  // Check difference on parts buttons
  containdiffpartcheck(answer) {
    this.diffOnparts = answer;
    if (!this.f.diffpartsYes.value && !this.f.diffpartsNo.value) {
      this.diffOnparts = null;
      this.diffOnpartsCond = false;
    } else if (this.diffOnparts == 'No') {
      this.f.diffpartsYes.setValue(false);
      this.diffOnpartsCond = true;
    } else if (this.diffOnparts == 'Yes') {
      this.f.diffpartsNo.setValue(false);
      this.diffOnpartsCond = true;
    } else if (this.diffOnparts == 'null') {
      this.f.diffpartsNo.setValue(false);
      this.f.diffpartsYes.setValue(false);
      this.diffOnpartsCond = false;
    }
  }

  productidcheck(answer) {
    if (answer == false) {
      this.f.productidNo.setValue(true);
      if (this.pid == null) {
        this.pid = answer;
      } else if (this.pid == true) {
        this.f.productidYes.setValue(false);
        this.pid = answer;

      }
    } else if (answer == true) {
      this.f.productidYes.setValue(true);
      if (this.pid == null) {
        this.pid = answer;
      } else if (this.pid == false) {
        this.f.productidNo.setValue(false);
        this.pid = answer;

      }
    }
  }

  isoRequirementsCheck(answer) {
    if (answer == true) {
      this.f.isoYes.setValue(true);
      if (this.isoRequirements == null) {
        this.isoRequirements = true;
      } else if (this.isoRequirements == false) {
        this.f.isoNo.setValue(false);
        this.isoRequirements = true;
      }
    } else if (answer == false) {
      this.f.isoNo.setValue(true);
      if (this.isoRequirements == null) {
        this.isoRequirements = false;
      } else if (this.isoRequirements == true) {
        this.f.isoYes.setValue(false);
        this.isoRequirements = false;
      }
    }
  }

  // Check difference on parts buttons
  draw_or_dim_check(answer) {

    if (answer == 'Drawings') {
      this.f.drawings.setValue(true);
      if (this.drawOrDim == null) {
        this.drawOrDim = answer;
      } else if (this.drawOrDim == 'Dimensions') {
        this.f.dimensions.setValue(false);
        this.drawOrDim = answer;
      }
    } else if (answer == 'Dimensions') {
      this.f.dimensions.setValue(true);
      if (this.drawOrDim == null) {
        this.drawOrDim = answer;
      } else if (this.drawOrDim == 'Drawings') {
        this.f.drawings.setValue(false);
        this.drawOrDim = answer;
      }
    }
  }

  // draw_or_dim_check(answer) {

  //   if (answer == 'Drawings') {

  //     this.dimensionsUI = [];
  //     this.f.partNo.setValue("");
  //     this.f.partNo.markAsUntouched();
  //     this.f.numberOfParts.setValue(null);
  //     this.f.numberOfParts.markAsUntouched();
  //     this.f.dimesions_d.setValue("");
  //     this.f.dimesions_d.markAsUntouched();
  //     this.f.materialGrade.setValue("");
  //     this.f.materialGrade.markAsUntouched();

  //     if (this.drawOrDim == null) {
  //       this.drawOrDim = answer;
  //     } else if (this.drawOrDim == 'Dimensions') {
  //       this.f.dimensions.setValue(false);
  //       this.drawOrDim = answer;
  //     }
  //   } else if (answer == 'Dimensions') {
  //     this.drawingFileArray = [];
  //     this.files = [];
  //    
  //     this.f.dimensions.setValue(true);
  //     if (this.drawOrDim == null) {
  //       this.drawOrDim = answer;
  //     } else if (this.drawOrDim == 'Drawings') {
  //       this.f.drawings.setValue(false);
  //       this.drawOrDim = answer;
  //     }
  //   }
  // }


  // Push the selected service to a new array 
  selectService(service) {
    this.selectedservice.push(Number(service));
    this.supplierServaceAdd = { supplierServiceId: null, serviceName: null };
    for (const { index, value } of this.allservices.map((value, index) => ({ index, value }))) {
      if (value.supplierServiceId == service) {
        this.supplierServaceAdd.supplierServiceId = this.allservices[index].supplierServiceId;
        this.supplierServaceAdd.serviceName = this.allservices[index].serviceName;
        this.allServiceDisplay.push(this.supplierServaceAdd);
        this.allservices.splice(index, 1);
      }
    }

    this.f.supplierServiceSelect.setValue(null);

  }


  // deletes the selected service and pushes it back to the all services array
  deleteService(serviceId) {
    this.selectedservice.splice(this.selectedservice.indexOf(serviceId), 1);
    this.supplierServaceAdd = { supplierServiceId: null, serviceName: null };
    for (const { index, value } of this.allServiceDisplay.map((value, index) => ({ index, value }))) {
      if (value.supplierServiceId == serviceId) {
        this.supplierServaceAdd.supplierServiceId = this.allServiceDisplay[index].supplierServiceId;
        this.supplierServaceAdd.serviceName = this.allServiceDisplay[index].serviceName;
        this.allservices.push(this.supplierServaceAdd);
        this.allServiceDisplay.splice(index, 1);
      }
    }
    //this.allservices.push(service);
    this.f.supplierServiceSelect.setValue(null);

    // sort services in Asc order using String.prototype.localCompare()
    this.allservices.sort((a, b) =>
      a.serviceName.localeCompare(b.serviceName)
    );

  }

  dimensions_block_addInitial(partNo, type, numParts, dimensions, materialGrade, dimensionService) {
    this.dimensionsgroup = { PartNo: null, Type: null, Quantity: null, Dimensions: null, MaterialGrade: null, DimensionSupplierServiceId: null }
    this.dimensionsgroup.PartNo = partNo;
    this.dimensionsgroup.Quantity = numParts;
    this.dimensionsgroup.Dimensions = dimensions;
    this.dimensionsgroup.MaterialGrade = materialGrade;
    this.dimensionsgroup.Type = type;
    if (this.typeOfSupply == "Complete") {
      this.dimensionsgroup.DimensionSupplierServiceId = Number(dimensionService);
      if (this.dimensionSelectedService.indexOf(Number(dimensionService)) == -1) {
        this.dimensionSelectedService.push(Number(dimensionService));
      }
      this.dimensionSelectedServiceDuplicate.push(Number(dimensionService));
    } else if (this.typeOfSupply == "Raw Material") {
      this.dimensionsgroup.DimensionSupplierServiceId = this.RawMaterialServices[0].supplierServiceId;
    }
    this.dimensionsUI.push(this.dimensionsgroup);
  }

  // Push to the dimensions array
  dimensions_block_add(partNo, type, numParts, dimensions, materialGrade, dimensionService) {

    if (this.f.partNo.value.replace(/ /g, "") != "" && this.f.partNo.value.length <= 50
      && this.f.partNo.value != null && this.f.numberOfParts.value != null && this.f.numberOfParts.value >= 1 && this.f.numberOfParts.value < 1000000
      && this.f.dimesions_d.value.replace(/ /g, "") != "" && this.f.dimesions_d.value != null
      && this.f.dimesions_d.value.length <= 25 && this.f.dimensionType.value.replace(/ /g, "") != ""
      && this.f.dimensionType.value != null && this.f.dimensionType.value.length <= 25
      && this.f.materialGrade.value.replace(/ /g, "") != "" && this.f.materialGrade.value != null && this.f.materialGrade.value.length <= 25
      && ((this.f.dimensionSupplierService.value != null && this.typeOfSupply == "Complete") || (this.typeOfSupply == "Raw Material"))
    ) {
      this.uniquePartNoCheck(this.f.partNo.value);
      if (this.uniquePartNo == true) {

        this.dimensionsgroup = { PartNo: null, Type: null, Quantity: null, Dimensions: null, MaterialGrade: null, DimensionSupplierServiceId: null }
        this.dimensionsgroup.PartNo = partNo;
        this.dimensionsgroup.Quantity = numParts;
        this.dimensionsgroup.Dimensions = dimensions;
        this.dimensionsgroup.MaterialGrade = materialGrade;
        this.dimensionsgroup.Type = type;

        if (this.typeOfSupply == "Complete") {
          this.dimensionsgroup.DimensionSupplierServiceId = Number(dimensionService);
          if (this.dimensionSelectedService.indexOf(Number(dimensionService)) == -1) {
            this.dimensionSelectedService.push(Number(dimensionService));
          }
          this.dimensionSelectedServiceDuplicate.push(Number(dimensionService));
        } else if (this.typeOfSupply == "Raw Material") {
          this.dimensionsgroup.DimensionSupplierServiceId = this.RawMaterialServices[0].supplierServiceId;
        }

        this.dimensionsUI.push(this.dimensionsgroup);
        this.f.partNo.setValue("");
        this.f.partNo.markAsUntouched();
        this.f.dimensionType.setValue("");
        this.f.dimensionType.markAsUntouched();
        this.f.numberOfParts.setValue(null);
        this.f.numberOfParts.markAsUntouched();
        this.f.dimesions_d.setValue("");
        this.f.dimesions_d.markAsUntouched();
        this.f.materialGrade.setValue("");
        this.f.materialGrade.markAsUntouched();
        this.f.dimensionSupplierService.setValue(null);
        this.f.dimensionSupplierService.markAsUntouched();
        this.dimensionsValidCond = true;
      } else {

        this.uniquePartNo = true;
      }


    } else {

      this.dimensionsValidCond = false;

    }

  }

  clearDimensionInputs() {

    this.f.partNo.setValue("");
    this.f.partNo.markAsUntouched();
    this.f.dimensionType.setValue("");
    this.f.dimensionType.markAsUntouched();
    this.f.numberOfParts.setValue(null);
    this.f.numberOfParts.markAsUntouched();
    this.f.dimesions_d.setValue("");
    this.f.dimesions_d.markAsUntouched();
    this.f.materialGrade.setValue("");
    this.f.materialGrade.markAsUntouched();
    this.f.dimensionSupplierService.setValue(null);
    this.f.dimensionSupplierService.markAsUntouched();
    this.dimensionsValidCond = true;
  }

  uniquePartNoCheck(partNo) {

    for (let dimensions of this.dimensionsUI) {
      if (dimensions.PartNo.replace(/ /g, "") == partNo.replace(/ /g, "")) {
        this.uniquePartNo = false;
      }
    }
  }

  uniquePartNoCheckRealTime(partNo) {
    for (let dimensions of this.dimensionsUI) {
      if (dimensions.PartNo.replace(/ /g, "") == partNo.replace(/ /g, "")) {
        return true;
      }
    }
  }

  quantityValidation(number) {
    if (number < 1 && number != null) {
      return true;
    }
  }



  Parts_group_delete(partNo) {

    var serviceCount = 0;
    for (const { index, value } of this.dimensionsUI.map((value, index) => ({ index, value }))) {
      if (value.PartNo == partNo) {
        this.dimensionsUI.splice(index, 1);
        for (let s of this.dimensionSelectedServiceDuplicate) {
          if (s == value.DimensionSupplierServiceId) {
            serviceCount++;
          }
        }

        for (const { serviceIndex, serviceValue } of this.dimensionSelectedService.map((serviceValue, serviceIndex) => ({ serviceIndex, serviceValue }))) {
          if (serviceValue == value.DimensionSupplierServiceId) {
            this.dimensionSelectedServiceDuplicate.splice(serviceIndex, 1);
            if (serviceCount == 1) {
              this.dimensionSelectedService.splice(serviceIndex, 1);
            }

          }
        }
      }
    }


  }

  //edit parts group

  editpartgroup(partNo) {
    for (let input of this.dimensionsUI) {
      if (input.PartNo == partNo) {
        this.f.partNo.setValue(input.PartNo);
        this.f.numberOfParts.setValue(input.Quantity);
        this.f.dimesions_d.setValue(input.Dimensions);
        this.f.materialGrade.setValue(input.MaterialGrade);
        this.f.dimensionSupplierService.setValue(input.DimensionSupplierServiceId);
        this.Parts_group_delete(partNo);
      }


    }
  }


  //DATE CALCULATIONS

  convertDate(deliveryDate) {

    var date = new Date(deliveryDate);
    var toDate: any = date.getDate();
    if (toDate < 10) {
      toDate = "0" + toDate;
    }
    var month: any = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    var year = date.getFullYear();
    return (year + "-" + month + "-" + toDate);

  }


  getMinDate() {
    var date = new Date();
    date.setDate(date.getDate() + 1);
    var toDate: any = date.getDate();
    if (toDate < 10) {
      toDate = "0" + toDate;
    }
    var month: any = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    var year = date.getFullYear();
    this.minDate = year + "-" + month + "-" + toDate;
  }

  getMaxDate(deliveryDate) {


    var date = new Date(this.f.deliveryDate.value);
    var toDate: any = date.getDate();
    if (toDate < 10) {
      toDate = "0" + toDate;
    }
    var month: any = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    var year = date.getFullYear();
    this.maxDate = year + "-" + month + "-" + toDate;

  }



  getDeliveryDays(deliveryDate) {

    this.date1 = new Date(this.minDate);
    this.date2 = new Date(this.f.deliveryDate.value);

    let diff = this.date2.getTime() - this.date1.getTime();
    let msInDay = 1000 * 3600 * 24;

    this.dueDate = diff / msInDay;

  }

  getDeliveryDaysInitial(deliveryDate) {

    this.date1 = new Date(this.minDate);
    this.date2 = new Date(deliveryDate);

    let diff = this.date2.getTime() - this.date1.getTime();
    let msInDay = 1000 * 3600 * 24;

    this.dueDate = diff / msInDay;

  }

  getDeliveryHours(deliveryDate) {


    this.date1 = new Date();
    this.date2 = new Date(this.f.deliveryDate.value);

    let diff = this.date2.getTime() - this.date1.getTime();

    this.dueHours = Math.floor(((diff % 86400000) / 3600000) - 2);

    //this.dueMinutes = Math.floor(((diff % 86400000)/3600000) /60000);


  }

  getDeliveryHoursInitial(deliveryDate) {


    this.date1 = new Date();
    this.date2 = new Date(deliveryDate);

    let diff = this.date2.getTime() - this.date1.getTime();

    this.dueHours = Math.floor(((diff % 86400000) / 3600000) - 2);

    //this.dueMinutes = Math.floor(((diff % 86400000)/3600000) /60000);


  }

  getExpireDays(RFQcloseDate) {

    this.date1 = new Date(this.minDate);
    this.date2 = new Date(this.f.RFQcloseDate.value);

    let diff = this.date2.getTime() - this.date1.getTime();
    let msInDay = 1000 * 3600 * 24;

    this.expireDate = diff / msInDay;


  }

  getExpireDaysInitial(RFQcloseDate) {

    this.date1 = new Date(this.minDate);
    this.date2 = new Date(RFQcloseDate);

    let diff = this.date2.getTime() - this.date1.getTime();
    let msInDay = 1000 * 3600 * 24;

    this.expireDate = diff / msInDay;


  }

  getExpireHours(RFQcloseDate) {


    this.date1 = new Date();
    this.date2 = new Date(this.f.RFQcloseDate.value);

    let diff = this.date2.getTime() - this.date1.getTime();

    this.expHours = Math.floor(((diff % 86400000) / 3600000) - 2);




  }

  getExpireHoursInitial(RFQcloseDate) {


    this.date1 = new Date();
    this.date2 = new Date(RFQcloseDate);

    let diff = this.date2.getTime() - this.date1.getTime();

    this.expHours = Math.floor(((diff % 86400000) / 3600000) - 2);




  }
  //DATE CALCULATIONS


  //Enable and disable Closing date form
  disableCloseDateForm() {
    if (this.f.deliveryDate.value == "" || this.f.deliveryDate.value == null) {
      this.f.RFQcloseDate.disable();
    }
    else (this.f.RFQcloseDate.enable());
  }



  onNext() {
    this.nextmodal = true;
  }

  get f() { return this.form.controls; }



  //FILE UPLOAD 

  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;

  files: any[] = [];
  drawingFile: UploadRfqFile = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
  drawingFileArray: UploadRfqFile[] = [];
  drawingFileArraySubmit: UploadRfqFile[] = [];
  requiredFileType: string = ".pdf,.dwg,.idw,.dxf,.iam,.png,.xls,.jpg,.jpeg,.ipt,.xlsx";
  allowedFileCheck: string[] = ["pdf", "dwg", "idw", "dxf", "iam", "png", "xls", "jpg", "jpeg", "ipt", "xlsx"];
  uniqueFile: boolean = true;
  drawFileUploading: boolean = false;
  drawFileDeleting: boolean = false;
  drawFileLoading: boolean = false;

  //file upload handling 
  /**
    * on file drop handler
    */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }
  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }
  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number, fileDeleted) {

    this.files.splice(index, 1);
    for (const { fileIndex, value } of this.drawingFileArray.map((value, fileIndex) => ({ fileIndex, value }))) {
      if (value.fileName == fileDeleted.fileName) {
        this.drawingFileArray.splice(fileIndex, 1)
      }
    }

    // for (const { index, value } of this.viewRfqFiles.map((value, index) => ({ index, value }))) {
    //   if (value.fileName == fileDeleted.fileName) {
    //     this.viewRfqFiles.splice(index, 1);
    //   }
    // }

  }

  deleteExistingFile(rfqFileId) {

    //  this.drawFileDeleting = true;
    //  this.rfqService.deleteRfqDrawingFile(rfqFileId).pipe(
    //  finalize(() => this.drawFileDeleting = false)
    // ).subscribe(x => {

    for (const { index, value } of this.viewRfqFiles.map((value, index) => ({ index, value }))) {
      if (value.rfqFileId == rfqFileId) {
        this.viewRfqFiles.splice(index, 1);
      }
    }
    //})
  }


  /**
    * Convert Files list to normal array list
    * @param files (Files List)
    */
  prepareFilesList(files: Array<any>) {

    for (let item of files) {
      ;
      this.fileDuplicateCheck(item)
      if (this.uniqueFile) {
        if (this.allowedFileCheck.indexOf((item.name + "").substring((item.name + "").indexOf(".") + 1, (item.name + "").length)) != -1) {
          this.files.push(item);
          this.populateDrawingFilesArray(item)
        } else {
          this.toastrNotification.error("File type not allowed");
        }
      } else {
        this.uniqueFile = true;
        this.toastrNotification.error("File of the same name has already been uploaded");
      }
    }
    this.fileDropEl.nativeElement.value = "";

  }

  fileDuplicateCheck(file) {
    for (let drawFile of this.drawingFileArray) {
      if (drawFile.fileName == file.name) {
        this.uniqueFile = false;
      }
    }

    // for (let rfqFile of this.rfq.rfqFiles) {
    //   if (rfqFile.fileName == file.name) {
    //     this.uniqueFile = false;
    //   }
    // }

  }

  // uploadRfqDrawings(files) {

  //   this.drawFileUploading = true;
  //   this.drawFileLoading = true;

  //   this.rfqService.uploadDrawingFiles(files).pipe(
  //     finalize(() => this.drawFileUploading = false)
  //   ).subscribe(x => {
  //     
  //     this.quoteService.getRfqFiles(this.rfq.rfqId).pipe(
  //       finalize(() => this.drawFileLoading = false)
  //     ).subscribe(rfqFiles => {
  //       
  //       this.viewRfqFiles = [];
  //       for (let file of rfqFiles) {
  //         this.viewRfqFiles.push(file);
  //       }
  //     })
  //   }
  //   );
  // }

  populateDrawingFilesArray(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.drawingFile = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
      this.drawingFile.file = (reader.result + "").substring((reader.result + "").indexOf(",") + 1, (reader.result + "").length);
      this.drawingFile.buyerId = this.account.account.buyerId;
      this.drawingFile.userId = this.account.account.userId;
      this.drawingFile.rfqId = null;
      this.drawingFile.supplierId = null;
      this.drawingFile.quoteId = null;
      this.drawingFile.fileName = file.name;
      this.drawingFileArray.push(this.drawingFile);
    };
  }


  populateDrawingFilesArrayInitial(file: RfqFileDownload) {

    this.drawingFile = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
    this.drawingFile.file = file.fileContent;
    this.drawingFile.buyerId = this.account.account.buyerId;
    this.drawingFile.userId = this.account.account.userId;
    this.drawingFile.rfqId = null;
    this.drawingFile.supplierId = null;
    this.drawingFile.quoteId = null;
    this.drawingFile.fileName = file.fileName;
    this.drawingFileArray.push(this.drawingFile);
  }


  populateFileDisplay(file: RfqFileDownload) {
    this.files.push(file);
  }

  // ulpoadFiles() {
  //   this.rfqService.uploadDrawingFiles(this.drawingFileArray).subscribe();
  // }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  //day of the week calculation
  days: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  DeliverDay: string;
  ClosingDay: string;

  getDeliveryDay() {

    this.DeliverDay = this.days[new Date(this.f.deliveryDate.value).getDay()];

  }
  getClosingDay() {

    this.ClosingDay = this.days[new Date(this.f.RFQcloseDate.value).getDay()];

  }

  maxLeadTime: string = null;

  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  public mtimeDifference;
  public msecondsToDday;
  public mminutesToDday;
  public mhoursToDday;
  public mdaysToDday;

  getMaxLeadTime(date) {
    this.getDateDifference(new Date(this.f.deliveryDate.value), new Date(this.f.RFQcloseDate.value));

    if (this.mdaysToDday < 0) {
      this.maxLeadTime = 'Closed';
    } else {
      this.maxLeadTime = this.mdaysToDday;
    }
  }

  getDateDifference(deliveryDate, closingDate) {
    var maxLeadDif = deliveryDate.getTime() - closingDate.getTime();
    this.allocateMaxLeadTimeUnits(maxLeadDif);
  }

  private allocateMaxLeadTimeUnits(timeDifference) {
    this.msecondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.mminutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.mhoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.mdaysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }

}
