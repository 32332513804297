import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { getQA } from '../models/getQ&A';
import { Questions } from '../models/mock-data';
import { Question, Reply } from '../models/question-thread';
import { CreateQuestion, EditQuestion } from '../models/questionModels';
import { CreateReply, EditReply } from '../models/replyModels';
import { ViewQuestions, ViewReplies } from '../models/viewQuestionThread';
import { View_QuotesRFQs } from '../models/view_quote';
import { MessageService } from './message.service';
import { ToastrNotificationService } from './toastr-notification.service';

const baseUrl = `${environment.apiUrl}/Question`;

@Injectable({
  providedIn: 'root'
})
export class QuestionAndAnswersService {

  private subject = new Subject<any>();

  constructor(private messageService: MessageService, private http: HttpClient, private toastrNotification: ToastrNotificationService) { }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  /** GET Questions and Replies from the server */
  //ViewQuotes?SupplierId=1&QuoteState=Open
  // getQuestionThread(rfqId: number, userId: string, supplierId?: number, isBuyer?: boolean, isSupplier?: boolean): Observable<ViewQuestions[]> {
  //   const url = `${baseUrl}/ViewQuestions?rfqId=${rfqId}&userId=${userId}&supplierId=${supplierId}&isBuyer=${isBuyer}&isSupplier=${isSupplier}`
  //   return this.http.get<any>(url)
  //     .pipe(
  //       map(questions => { return questions.results }),
  //       tap(_ => this.log('fetched quotes')),
  //       catchError(this.handleError<ViewQuestions[]>('getQuotes', []))
  //     );
  // }

  /** POST: add a new question to the server */
  getQuestionThread(request: getQA): Observable<ViewQuestions[]> {
    const url = `${baseUrl}/ViewQuestions`;
    return this.http.post<any>(url, request, this.httpOptions)
      .pipe(
        map(question => { return question.results }),
        tap((questions: ViewQuestions[]) => this.log(`question thread retrieved succesfully`)),
        catchError(this.handleError<ViewQuestions[]>('get Q&A thread'))
      );
  }

  /** POST: add a new question to the server */
  addQuestion(question: CreateQuestion): Observable<CreateQuestion> {
    const url = `${baseUrl}/CreateQuestion`;
    return this.http.post<any>(url, question, this.httpOptions)
      .pipe(
        map(question => {  return question.message }),
        tap((newRFQ: CreateQuestion) => this.log(`question submitted succesfully`)),
        catchError(this.handleError<CreateQuestion>('submit question'))
      );
  }

  /** POST: add a new reply to the server */
  addReply(reply: CreateReply): Observable<ViewReplies> {
    const url = `${baseUrl}/CreateReply`;
    return this.http.post<any>(url, reply, this.httpOptions)
      .pipe(
        map(question => { return question.results }),
        tap((newReply: ViewReplies) => this.log(`reply submitted succesfully`)),
        catchError(this.handleError<ViewReplies>('submit reply'))
      );
  }

  /** POST: edit question on the server */
  editQuestion(question: EditQuestion): Observable<EditQuestion> {
    const url = `${baseUrl}/EditQuestion`;
    return this.http.post<any>(url, question, this.httpOptions)
      .pipe(
        map(question => {  return question.message }),
        tap((newRFQ: EditQuestion) => this.log(`question edited succesfully`)),
        catchError(this.handleError<EditQuestion>('submit question'))
      );
  }

  /** POST: edit reply on the server */
  editReply(reply: EditReply): Observable<EditReply> {
    const url = `${baseUrl}/EditReply`;
    return this.http.post<any>(url, reply, this.httpOptions)
      .pipe(
        map(reply => {  return reply.message }),
        tap((newRFQ: EditReply) => this.log(`reply edited succesfully`)),
        catchError(this.handleError<EditReply>('submit reply'))
      );
  }

  /** POST: delete question on the server */
  deleteQuestion(question: EditQuestion): Observable<EditQuestion> {
    const url = `${baseUrl}/DeleteQuestion`;
    return this.http.post<any>(url, question, this.httpOptions)
      .pipe(
        map(question => {  return question.message }),
        tap((newRFQ: EditQuestion) => this.log(`question deleted succesfully`)),
        catchError(this.handleError<EditQuestion>('delete question'))
      );
  }

  /** POST: delete reply on the server */
  deleteReply(reply: EditReply): Observable<EditReply> {
    const url = `${baseUrl}/DeleteReply`;
    return this.http.post<any>(url, reply, this.httpOptions)
      .pipe(
        map(reply => {  return reply.message }),
        tap((newRFQ: EditReply) => this.log(`reply deleted succesfully`)),
        catchError(this.handleError<EditReply>('deleted reply'))
      );
  }

  getQuestionOrReplyDeleted( questionId: number,replyId: number): Observable<boolean> {
    const url = `${baseUrl}/QuestionOrReplyDeletedCheck?questionId=${questionId}&replyId=${replyId}`
    return this.http.get<any>(url)
      .pipe(
        map(condition => {return condition.succeeded; }),
        tap(_ => this.log('fetched Check')),
        catchError(this.handleError<boolean>('getQuestionOrReplyDeleted'))
      );
  }

  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      this.toastrNotification.error(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    this.messageService.add(`RFQService: ${message}`);
  }
  // getMockQuestions(): Observable<Question[]> {
  //   const questions = of(Questions);
  //   return questions;

  // }


}
