import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ReloadViewRfqs } from '../models/reloadViewRfqs';

@Injectable({
  providedIn: 'root'
})
export class ViewQuoteServiceService {

  closedCond: ReloadViewRfqs = {reloadCondition: false, reloadRfqId: null};

  private reloadClosedQuotes = new BehaviorSubject<ReloadViewRfqs>(this.closedCond);

  private quoteSubmitted = new BehaviorSubject<ReloadViewRfqs>(this.closedCond);

  private notifNavQuoteAcceptedBe = new BehaviorSubject<ReloadViewRfqs>(this.closedCond);

  private IPQuoteStay = new BehaviorSubject<ReloadViewRfqs>(this.closedCond);

  private navToNewQuote = new BehaviorSubject<ReloadViewRfqs>(this.closedCond);

  private navExpiredQuote = new BehaviorSubject<ReloadViewRfqs>(this.closedCond);

  currentClosedQuotes = this.reloadClosedQuotes.asObservable();

  currentQuoteSubmitted = this.quoteSubmitted.asObservable();

  notifNavQuoteAccepted = this.notifNavQuoteAcceptedBe.asObservable();

  currentIPQuoteStay = this.IPQuoteStay.asObservable();

  currentNavToNewQuote = this.navToNewQuote.asObservable();

  currentNavToExpiredQuote = this.navExpiredQuote.asObservable();

  changeReloadClosedQuotes(cond: ReloadViewRfqs) {
    this.reloadClosedQuotes.next(cond);
  }
  
  markQuoteAsSubmitted(cond: ReloadViewRfqs) {
    this.quoteSubmitted.next(cond);
  }

  changeNotifNavQuoteAccepted(cond: ReloadViewRfqs){
    this.notifNavQuoteAcceptedBe.next(cond);
  }

  changeIPQuoteStay(cond: ReloadViewRfqs){
    this.IPQuoteStay.next(cond);
  }

  changeNavToNewQuote(cond: ReloadViewRfqs){
    this.navToNewQuote.next(cond);
  }

  changeNavToExpired(cond: ReloadViewRfqs){
    this.navExpiredQuote.next(cond);
  }

  

  
}
