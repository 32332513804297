import { Injectable } from '@angular/core';  
  import { Router, NavigationStart } from '@angular/router';  
  import { Observable, Subject } from 'rxjs';  

  import { ToastrNotifications, ToastrNotificationType } from '../models/toastr-notification';  

@Injectable({
  providedIn: 'root'
})
  export class ToastrNotificationService {  
      public subject = new Subject<ToastrNotifications>();  
      public keepAfterRouteChange = true;  
    
      constructor(public router: Router) {  
          // clear alert messages on route change unless 'keepAfterRouteChange' flag is true  
          router.events.subscribe(event => {  
              if (event instanceof NavigationStart) {  
                  if (this.keepAfterRouteChange) {  
                      // only keep for a single route change  
                      this.keepAfterRouteChange = false;  
                  } else {  
                      // clear alert messages  
                      this.clear();  
                  }  
              }  
          });  
      }  
    
      getAlert(): Observable<any> {  
          return this.subject.asObservable();  
      }  
    
      success(message: string, keepAfterRouteChange = false) {  
          this.showNotification(ToastrNotificationType.Success, message, keepAfterRouteChange);  
      }  
    
      error(message: string, keepAfterRouteChange = false) {  
          this.showNotification(ToastrNotificationType.Error, message, keepAfterRouteChange);  
      }  
    
      info(message: string, keepAfterRouteChange = false) {  
          this.showNotification(ToastrNotificationType.Info, message, keepAfterRouteChange);  
      }  
    
      warn(message: string, keepAfterRouteChange = false) {  
          this.showNotification(ToastrNotificationType.Warning, message, keepAfterRouteChange);  
      }  
    
      showNotification(type: ToastrNotificationType, message: string, keepAfterRouteChange = false) {  
          this.keepAfterRouteChange = keepAfterRouteChange;  
          this.subject.next(<ToastrNotifications>{ type: type, message: message });  
      }  
    
      clear() {  
          this.subject.next();  
      }  
  }  
