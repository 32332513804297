<div class="modal-header" [class.greenformheader]="greenformheader==true"
    [class.lightblueformheader]="blueformheader== true">
    <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">Edit User
    </h1><button (click)="cancelEdit()" type="button" class="btn-close" data-bs-dismiss="modal"
        aria-label="Close"></button>
</div>
<div class="modal-body">
    <div>
        <div class="row text-end">
            <div class="col" style="padding-left: 0;">
                <p class="text-center blueheadsmall">Edit the fields below and click the 'Save' button when
                    done.</p>
            </div>
        </div>
        <form [formGroup]="editUserForm" #test="ngForm">
            <div class="row rfqdetailrow">
                <div class="col d-flex align-items-center">
                    <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">First Name

                        <ng-container
                            *ngIf="(eu.firstNameE.touched && editUserErrorDisplay &&  stringValidation(eu.firstNameE.value) )">
                            <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                        </ng-container>

                        <ng-container
                        *ngIf="(eu.firstNameE.touched && editUserErrorDisplay &&  eu.firstNameE.value.length > 50 )">
                        <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds Max Length)</span>
                    </ng-container>

                    </p>
                </div>
                <div class="col"><input formControlName="firstNameE" type="text" class="form-control" autocomplete="off"
                        placeholder="" style="height: 2rem;width: 315px;" /></div>
            </div>
            <div class="row rfqdetailrow">
                <div class="col d-flex align-items-center">
                    <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">Last Name
                        <ng-container
                            *ngIf="(eu.lastNameE.touched && editUserErrorDisplay &&  stringValidation(eu.lastNameE.value) )">
                            <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                        </ng-container>

                        <ng-container
                            *ngIf="(eu.lastNameE.touched && editUserErrorDisplay &&  eu.lastNameE.value.length > 50 )">
                            <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds Max Length)</span>
                        </ng-container>

                    </p>
                </div>
                <div class="col"><input formControlName="lastNameE" type="text" class="form-control" autocomplete="off"
                        placeholder="" style="height: 2rem;width: 315px;" /></div>
            </div>
            <!-- <div class="row rfqdetailrow">
            <div class="col d-flex align-items-center">
                <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">Email
                    Adress</p> 
            </div>
            <div class="col"><input formControlName = "userNameE" type="text" class="form-control" autocomplete="off"
                    placeholder="" style="height: 2rem;width: 315px;" /></div>
        </div> -->
            <div class="row rfqdetailrow">
                <div class="col d-flex align-items-center">
                    <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">Role</p>
                </div>
                <div class="col">

                    <input formControlName="roleE" type="text" class="form-control" autocomplete="off"
                    placeholder="currentUser.role" style="height: 2rem;width: 315px;" />
                    
                    <!-- <select class="form-select" style="width: 315px;height: 2rem;" formControlName="roleE">

                        <option disabled value=null selected>Role</option>
                        <option value="Buyer">Buyer</option>
                        <option value="Supplier">Supplier</option>
                        <option value="Merchant">Merchant</option>

                    </select> -->
                
                </div>
            </div>
            <div class="row rfqdetailrow">
                <div class="col d-flex align-items-center">
                    <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">Team
                        <ng-container
                            *ngIf="(eu.teamE.touched && editUserErrorDisplay &&  stringValidation(eu.teamE.value) )">
                            <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                        </ng-container>

                        <ng-container
                        *ngIf="(eu.teamE.touched && editUserErrorDisplay &&  eu.teamE.value.length > 50 )">
                        <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds Max Length)</span>
                    </ng-container>

                    </p>
                </div>
                <div class="col"><input formControlName="teamE" type="text" class="form-control" autocomplete="off"
                        placeholder="" style="height: 2rem;width: 315px;" /></div>
            </div>

            <div class="row rfqdetailrow">
                <div class="col d-flex align-items-center">
                    <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">Notification
                        Type(s)</p>
                </div>
                <div class="col">
                    <div
                        class="d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center">
                        <div class="form-check form-check-inline">
                            <input type="checkbox" formControlName="emailPreferenceE" [class.form-check-input]="profileType=='Buyer'"
                            [class.Blueform-check-input]="profileType=='Supplier'"
                                id="formCheck-50" /><label class="form-check-label radio bluetext thin"
                                for="formCheck-1">Email</label>
                        </div>
                        <!-- <div class="form-check form-check-inline">
                            <input type="checkbox" formControlName="sMSPreferenceE" [class.form-check-input]="profileType=='Buyer'"
                            [class.Blueform-check-input]="profileType=='Supplier'"
                                id="formCheck-12" /><label class="form-check-label radio bluetext thin"
                                for="formCheck-1">SMS</label>
                        </div> -->
                    </div>
                </div>
            </div>
        </form>

        <div class="row rfqdetailrow">
            <div class="col d-flex align-items-center" >
                <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                    Re-send Welcome Email                  
                </p>
            </div>
            <div class="col">
            <button (click) = "resendWelcomeEmail()" [disabled] = "sendingWelcomeEmail || editorUsername == currentUser.userName"                                  
            class="btn btn-secondary invoicebtn" type="button" [class.greenButton]="profileType=='Buyer'"
            [class.blueButton]="profileType=='Supplier'"
            style="font-size: 12px;border-style: none;width: 127.344px;height: 36px;">Send Email
            <span
             *ngIf="sendingWelcomeEmail" class="spinner-border spinner-border-sm mr-1"></span>
           </button>
        </div>
        </div>

        <div class="row rfqdetailrow">
            <div class="col d-flex align-items-center" >
                <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                    Delete User                  
                </p>
            </div>
            <div class="col">
            <button  (click) = "deleteUser()"                                 
            class="btn btn-secondary invoicebtn" type="button" [class.greenButton]="profileType=='Buyer'"
            [class.blueButton]="profileType=='Supplier'"
            style="font-size: 12px;border-style: none;width: 127.344px;height: 36px;">Delete         
           </button>
        </div>
        </div>

       
        <!-- <div class="row d-flex d-xxl-flex justify-content-center align-items-center rfqdetailrow"
            style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;">
            <div class="col d-flex d-xl-flex justify-content-center justify-content-xl-center">
                <p class="text-nowrap bluetextthin" style="font-weight: normal;">All fields are required</p>
            </div>
        </div> -->
    </div>
</div>
<div class="modal-footer d-lg-flex align-items-lg-center"><a (click)="cancelEdit()" class="btn text-gray-500"
        role="button" data-bs-dismiss="modal">Cancel</a>

    <button *ngIf="contactDetailsValid()" (click)="editUserSubmit()" [disabled]=editingUser
        class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn" role="button"
        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Save
        <span *ngIf="editingUser" class="spinner-border spinner-border-sm mr-1"></span></button>

    <a *ngIf="!contactDetailsValid()" (click)="contactDetailsValidCond()"
        class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn" role="button"
        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Save</a>

</div>