<!-- heading and closing x -->
<div class="row text-center d-xl-flex justify-content-xl-center align-items-xl-center"
    [class.greenformheader]="modalheader== 'green'" [class.lightblueformheader]="modalheader== 'blue'">
    <div
        class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 offset-0 offset-sm-0 offset-md-0 offset-lg-0 offset-xl-0 d-flex justify-content-center align-items-center justify-content-center align-items-center">
        <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">Question &amp;
            Answer Thread </h1><a></a>
    </div>
    <div
        class="col text-end d-flex d-xl-flex d-xxl-flex justify-content-end justify-content-sm-end align-items-md-center align-items-xl-center justify-content-xxl-end align-items-xxl-center">
        <span class="d-xxl-flex justify-content-xxl-center align-items-xxl-center"><a data-bs-toggle="modal"
                (click)="triggerperiodicUpdate(false)" (click)="triggerChatToggle(false)"
                [attr.data-bs-target]="'#bwe' + rfqId"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                    viewBox="0 0 24 24" fill="none" style="color: var(--shadowgrey);">
                    <path
                        d="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
                        fill="currentColor"></path>
                </svg></a></span>
    </div>
</div>

<!-- search  -->
<div class="row searchqa" style="padding-bottom: 0px;background: #ffffff;height: 74px;">
    <div class="col justify-content-end align-items-start justify-content-sm-end align-items-sm-end justify-content-xxl-end align-items-xxl-end"
        style="padding: 1rem;padding-left: 0;margin: 0;margin-right: 1rem;margin-bottom: 0.5rem;margin-left: 1rem;border-radius: 20px;padding-right: 00;">
        <div class="input-group"><input [(ngModel)]="searchedKeyword" type="text"
                class="bg-light form-control border-0 small" placeholder="Search for ..." /><button
                class="btn btn-primary py-0" [class.greenformheader]="modalheader== 'green'"
                [class.lightblueformheader]="modalheader== 'blue'" type="button"><i class="fas fa-search"></i></button>
        </div>
    </div>
</div>

<div>
    <div class="row">
        <div id="md" class="  col-xl-12 col-xxl-12 rfqsearchblock"
            style="padding: 2rem;padding-top: 0px !important;border-radius: 0px 0px 0px 0px;padding-bottom: 0px;">

            <ng-container *ngIf="questionsAndReplies.length > 0">
                <div>
                    <!-- Question and reply Display (with edit and delete) -->
                    <div *ngFor="let question of questionsAndReplies | filter:searchedKeyword ">
                        <div class="row rfqdetailrow" style="padding-bottom: 0px;">
                            <!-- IF MODAL GREEN AND IS BUYER THEN GREEN BACKGROUND -->
                            <ng-container *ngIf="modalheader == 'green' && question.isBuyer == true ">

                                <div class="col-1 col-sm-1" style="padding-left: 0;">
                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                        style="margin-bottom: 0;padding-bottom: 1rem;"><i
                                            class="fa fa-user-circle uiicon"
                                            style="font-size: 30px;color: #4272F740;"></i></p>
                                </div>

                                <div class=" col justify-content-end align-items-start justify-content-sm-end
                            align-items-sm-end justify-content-xxl-end align-items-xxl-end"
                                    style="padding: 1rem;padding-left: 1rem;margin: 0;margin-right: 1rem;margin-bottom: 0.5rem;margin-left: 1rem;background: #d1f3f0;border-radius: 20px;">
                                    <p class="text-start d-xl-flex bluetext shift"
                                        style="margin-bottom: 0;padding-left: 0;">{{question.questionContent}} </p>
                                    <!-- <app-inline-edit [data]='question.questionContent'
                                        (focusOut)='questionEdit($event,question.questionId)'></app-inline-edit> -->
                                    <div class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex justify-content-between align-items-center align-items-sm-center align-items-md-center align-items-lg-center align-items-xl-center align-items-xxl-center"
                                        style="padding-top: 0.5rem;">
                                        <div
                                            class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex align-items-sm-center align-items-md-center align-items-lg-center justify-content-xl-start align-items-xl-center justify-content-xxl-start">

                                            <p class="text-start d-flex d-sm-flex d-xl-flex justify-content-end justify-content-sm-end shift"
                                                style="margin-bottom: 0;padding-left: 0; font-size: 11px !important;">
                                                {{getMinimalisticRelativeTime(question.createdAt)}}<br /></p>


                                        </div>
                                        <ng-container
                                            *ngIf="question.canDeleteQuestion && (rfqStatus != 'In Progress' && rfqStatus != 'Closed')">
                                            <a *ngIf="!questionDeleting " class="bluetextthin clickable"
                                                (click)="questionDelete(question.questionId)"><i
                                                    class="fa fa-trash"></i></a>
                                            <span *ngIf="questionDeleting && question.questionId == selectedQuestionId"
                                                class="spinner-border spinner-border-sm mr-1"></span>
                                        </ng-container>
                                    </div>

                                </div>
                            </ng-container>

                            <!-- IF MODAL GREEN AND IS SUPPLIER THEN BLUE BACKGROUND -->
                            <ng-container *ngIf="modalheader == 'green' && question.isSupplier == true ">

                                <!-- USER ICON -->
                                <div *ngIf="question.buyerSeen == true" class="col-1 col-sm-1" style="padding-left: 0;">
                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                        style="margin-bottom: 0;padding-bottom: 1rem;"><i
                                            class="fa fa-user-circle uiicon"
                                            style="font-size: 30px;color: #4272F740;"></i></p>
                                </div>

                                <div *ngIf="question.buyerSeen == false  && question.isSupplier == true"
                                    class="col-1 col-sm-1" style="padding-left: 0;">
                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                        style="margin-bottom: 0;padding-bottom: 1rem;"><i message-badge-smallest=""
                                            class="fa fa-user-circle uiicon"
                                            style="font-size: 30px;color: #4272F740;"></i>
                                    </p>
                                </div>

                                <div class=" col justify-content-end align-items-start justify-content-sm-end
                                                                        align-items-sm-end justify-content-xxl-end align-items-xxl-end"
                                    style="padding: 1rem;padding-left: 1rem;margin: 0;margin-right: 1rem;margin-bottom: 0.5rem;margin-left: 1rem;background:var(--shadowgrey);border-radius: 20px;">
                                    <p class="text-start d-xl-flex bluetext shift"
                                        style="margin-bottom: 0;padding-left: 0;">
                                        {{question.questionContent}} </p>
                                    <!-- <app-inline-edit [data]='question.questionContent'
                                                                                    (focusOut)='questionEdit($event,question.questionId)'></app-inline-edit> -->
                                    <div class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex justify-content-between align-items-center align-items-sm-center align-items-md-center align-items-lg-center align-items-xl-center align-items-xxl-center"
                                        style="padding-top: 0.5rem;">
                                        <div
                                            class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex align-items-sm-center align-items-md-center align-items-lg-center justify-content-xl-start align-items-xl-center justify-content-xxl-start">

                                            <p class="text-start d-flex d-sm-flex d-xl-flex justify-content-end justify-content-sm-end shift"
                                                style="margin-bottom: 0;padding-left: 0; font-size: 11px !important;">
                                                {{getMinimalisticRelativeTime(question.createdAt)}}<br /></p>
                                        </div>
                                        <ng-container
                                            *ngIf="question.canDeleteQuestion && (rfqStatus != 'In Progress' && rfqStatus != 'Closed')">
                                            <a *ngIf="!questionDeleting " class="bluetextthin clickable"
                                                (click)="questionDelete(question.questionId)"><i
                                                    class="fa fa-trash"></i></a>
                                            <span *ngIf="questionDeleting && question.questionId == selectedQuestionId"
                                                class="spinner-border spinner-border-sm mr-1"></span>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                            <!-- IF MODAL BLUE AND IS BUYER THEN GREEN BACKGROUND -->
                            <ng-container *ngIf="modalheader == 'blue' && question.isBuyer == true">
                                <!-- USER ICON -->
                                <div class="col-1 col-sm-1" style="padding-left: 0;">
                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                        style="margin-bottom: 0;padding-bottom: 1rem;"><i
                                            class="fa fa-user-circle uiicon"
                                            style="font-size: 30px;color: #4272F740;"></i></p>
                                </div>

                                <div class=" col justify-content-end align-items-start justify-content-sm-end
                                                    align-items-sm-end justify-content-xxl-end align-items-xxl-end"
                                    style="padding: 1rem;padding-left: 1rem;margin: 0;margin-right: 1rem;margin-bottom: 0.5rem;margin-left: 1rem;background: #d1f3f0;border-radius: 20px;">
                                    <p class="text-start d-xl-flex bluetext shift"
                                        style="margin-bottom: 0;padding-left: 0;">{{question.questionContent}} </p>
                                    <!-- <app-inline-edit [data]='question.questionContent'
                                        (focusOut)='questionEdit($event,question.questionId)'>
                                    </app-inline-edit> -->
                                    <div class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex justify-content-between align-items-center align-items-sm-center align-items-md-center align-items-lg-center align-items-xl-center align-items-xxl-center"
                                        style="padding-top: 0.5rem;">
                                        <div
                                            class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex align-items-sm-center align-items-md-center align-items-lg-center justify-content-xl-start align-items-xl-center justify-content-xxl-start">

                                            <p class="text-start d-flex d-sm-flex d-xl-flex justify-content-end justify-content-sm-end shift"
                                                style="margin-bottom: 0;padding-left: 0; font-size: 11px !important;">
                                                {{getMinimalisticRelativeTime(question.createdAt)}}<br /></p>

                                            <p class="text-start d-flex d-sm-flex d-xl-flex justify-content-end justify-content-sm-end shift"
                                                style=" margin-bottom: 0;padding-left: 3px; font-size: 11px !important;">
                                                - From Buyer</p>




                                        </div>
                                        <ng-container
                                            *ngIf="question.canDeleteQuestion && (rfqStatus != 'In Progress' && rfqStatus != 'Closed')">
                                            <a *ngIf="!questionDeleting " class="bluetextthin clickable"
                                                (click)="questionDelete(question.questionId)"><i
                                                    class="fa fa-trash"></i></a>
                                            <span *ngIf="questionDeleting && question.questionId == selectedQuestionId"
                                                class="spinner-border spinner-border-sm mr-1"></span>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                            <!-- IF MODAL BLUE AND IS SUPPLIER AND CAN EDIT OR DELETE QUESTION THEN BLUE BACKGROUND -->
                            <ng-container
                                *ngIf="modalheader == 'blue' && question.isSupplier == true && question.canDeleteQuestion == true">

                                <!-- USER ICON -->
                                <div class="col-1 col-sm-1" style="padding-left: 0;">
                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                        style="margin-bottom: 0;padding-bottom: 1rem;"><i
                                            class="fa fa-user-circle uiicon"
                                            style="font-size: 30px;color: #4272F740;"></i></p>
                                </div>

                                <div class=" col justify-content-end align-items-start justify-content-sm-end
                                                                                        align-items-sm-end justify-content-xxl-end align-items-xxl-end"
                                    style="padding: 1rem;padding-left: 1rem;margin: 0;margin-right: 1rem;margin-bottom: 0.5rem;margin-left: 1rem;background:    #d5e0fc;border-radius: 20px;">
                                    <p class="text-start d-xl-flex bluetext shift"
                                        style="margin-bottom: 0;padding-left: 0;">
                                        {{question.questionContent}} </p>
                                    <!-- <app-inline-edit [data]='question.questionContent'
                                                                            (focusOut)='questionEdit($event,question.questionId)'>
                                                                        </app-inline-edit> -->
                                    <div class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex justify-content-between align-items-center align-items-sm-center align-items-md-center align-items-lg-center align-items-xl-center align-items-xxl-center"
                                        style="padding-top: 0.5rem;">
                                        <div
                                            class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex align-items-sm-center align-items-md-center align-items-lg-center justify-content-xl-start align-items-xl-center justify-content-xxl-start">

                                            <p class="text-start d-flex d-sm-flex d-xl-flex justify-content-end justify-content-sm-end shift"
                                                style="margin-bottom: 0;padding-left: 0; font-size: 11px !important;">
                                                {{getMinimalisticRelativeTime(question.createdAt)}}<br /></p>
                                        </div>
                                        <ng-container
                                            *ngIf="question.canDeleteQuestion && (rfqStatus != 'In Progress' && rfqStatus != 'Closed')">
                                            <a *ngIf="!questionDeleting " class="bluetextthin clickable"
                                                (click)="questionDelete(question.questionId)"><i
                                                    class="fa fa-trash"></i></a>
                                            <span *ngIf="questionDeleting && question.questionId == selectedQuestionId"
                                                class="spinner-border spinner-border-sm mr-1"></span>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- IF MODAL BLUE AND IS SUPPLIER AND CAN NOT EDIT OR DELETE QUESTION THEN GREY BACKGROUND -->
                            <ng-container
                                *ngIf="modalheader == 'blue' && question.isSupplier == true && question.canDeleteQuestion == false">

                                <!-- USER ICON -->
                                <div class="col-1 col-sm-1" style="padding-left: 0;">
                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                        style="margin-bottom: 0;padding-bottom: 1rem;"><i
                                            class="fa fa-user-circle uiicon"
                                            style="font-size: 30px;color: #4272F740;"></i></p>
                                </div>

                                <div class=" col justify-content-end align-items-start justify-content-sm-end
                                                                                                                                align-items-sm-end justify-content-xxl-end align-items-xxl-end"
                                    style="padding: 1rem;padding-left: 1rem;margin: 0;margin-right: 1rem;margin-bottom: 0.5rem;margin-left: 1rem;background: var(--shadowgrey);border-radius: 20px;">
                                    <p class="text-start d-xl-flex bluetext shift"
                                        style="margin-bottom: 0;padding-left: 0;">
                                        {{question.questionContent}} </p>
                                    <!-- <app-inline-edit [data]='question.questionContent'
                                                                                                                    (focusOut)='questionEdit($event,question.questionId)'>
                                                                                                                </app-inline-edit> -->
                                    <div class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex justify-content-between align-items-center align-items-sm-center align-items-md-center align-items-lg-center align-items-xl-center align-items-xxl-center"
                                        style="padding-top: 0.5rem;">
                                        <div
                                            class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex align-items-sm-center align-items-md-center align-items-lg-center justify-content-xl-start align-items-xl-center justify-content-xxl-start">

                                            <p class="text-start d-flex d-sm-flex d-xl-flex justify-content-end justify-content-sm-end shift"
                                                style="margin-bottom: 0;padding-left: 0; font-size: 11px !important;">
                                                {{getMinimalisticRelativeTime(question.createdAt)}}<br /></p>
                                        </div>
                                        <ng-container
                                            *ngIf="question.canDeleteQuestion && (rfqStatus != 'In Progress' && rfqStatus != 'Closed')">
                                            <a *ngIf="!questionDeleting " class="bluetextthin clickable"
                                                (click)="questionDelete(question.questionId)"><i
                                                    class="fa fa-trash"></i></a>
                                            <span *ngIf="questionDeleting && question.questionId == selectedQuestionId"
                                                class="spinner-border spinner-border-sm mr-1"></span>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>

                        </div>

                        <!-- reply collapse -->
                        <div *ngIf="question.replies.length > 0" class="row d-xxl-flex rfqdetailrow"
                            style="padding-bottom: 0px;">
                            <div class="col-1 col-sm-1" style="padding-left: 0;"></div>
                            <div class="col d-flex d-sm-flex justify-content-start align-items-start justify-content-sm-start align-items-sm-start justify-content-md-start align-items-xxl-start"
                                style="padding: 0px;padding-left: 1rem;margin: 0;margin-right: 1rem;margin-bottom: 0.5rem;margin-left: 1rem;border-radius: 20px;">
                                <p class="text-start d-sm-flex bluetext shift"
                                    style="margin-bottom: 0;padding-left: 0;">
                                </p>


                                <!-- VIEW REPLIES TEXT LINK -->
                                <a class="bluetextthin" data-bs-toggle="collapse"
                                    [attr.data-bs-target]="'#a' + question.questionId">View
                                    {{question.replies.length}}
                                    Replies</a>


                                <!-- NEW REPLIES BADGES FOR BUYER AND SUPPLIER -->
                                <ng-container>
                                    <div
                                        *ngIf="this.newReplies.buyerNewReplies == true && this.newReplies.questionid == question.questionId">
                                        <span style="margin-left:10px; font-size: 10px !important;"
                                            class="badge rounded-pill bg-warning bluetextthin">New Replies</span>
                                    </div>
                                </ng-container>
                                <ng-container>
                                    <div
                                        *ngIf="newReplies.supplierNewReplies == true && newReplies.questionid == question.questionId">
                                        <span style="margin-left:10px; font-size: 10px !important;"
                                            class="badge rounded-pill bg-warning bluetextthin">New Replies</span>
                                    </div>
                                </ng-container>
                                <!-- <a (click)=" toggleViewHideReplies()" *ngIf="showQAreplies == true" class="bluetextthin"
                                    data-bs-toggle="collapse" [attr.data-bs-target]="'#a' + question.questionId">Hide
                                    {{question.replies.length}}
                                    Replies</a> -->
                            </div>
                        </div>

                        <!-- reply display -->
                        <div *ngIf="question.replies.length > 0" class="{{replyCollapse()}}"
                            [attr.id]="'a' + question.questionId ">
                            <div *ngFor="let reply of question.replies" class="row replies1"
                                style="padding-bottom: 0px;">
                                <div class="col-1 col-sm-1" style="padding-left: 0;"></div>
                                <!-- USER ICON
                                <div class="col-sm-1 d-flex d-sm-flex justify-content-start align-items-start justify-content-sm-start align-items-sm-start justify-content-md-start align-items-xxl-start"
                                    style="padding: 0px;padding-left: 1rem;margin: 0;margin-right: 1rem;margin-bottom: 0.5rem;margin-left: 1rem;border-radius: 20px;">
                                    <p
                                        class="d-flex d-sm-flex align-items-center justify-content-sm-start align-items-sm-center bluetext">
                                        <i class="fa fa-user-circle uiicon"
                                            style="font-size: 30px;color: #4272F740;"></i>
                                    </p>
                                </div> -->

                                <!-- IF MODAL GREEN AND IS BUYER THEN GREEN BACKGROUND -->
                                <ng-container *ngIf="modalheader == 'green' && reply.isBuyer == true">
                                    <!-- REPLY USER ICON -->

                                    <div class="col-1 col-sm-1" style="padding-left: 0;">
                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;padding-bottom: 1rem;"><i
                                                class="fa fa-user-circle uiicon"
                                                style="font-size: 30px;color: #4272F740;"></i></p>
                                    </div>

                                    <div class="col justify-content-end align-items-start justify-content-sm-end align-items-sm-end justify-content-xxl-end align-items-xxl-end"
                                        style="padding: 1rem;padding-left: 1rem;margin: 0;margin-right: 1rem;margin-bottom: 0.5rem;margin-left: 1rem;background: #d1f3f0;border-radius: 20px;">
                                        <p class="text-start d-xl-flex bluetext shift"
                                            style="margin-bottom: 0;padding-left: 0;">{{reply.replyContent}} </p>
                                        <!-- <app-inline-edit [data]='reply.replyContent'
                                        (focusOut)='replyEdit($event,reply.replyId)'>
                                    </app-inline-edit> -->
                                        <div class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex justify-content-between align-items-center align-items-sm-center align-items-md-center align-items-lg-center align-items-xl-center align-items-xxl-center"
                                            style="padding-top: 0.5rem;">
                                            <div
                                                class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex align-items-sm-center align-items-md-center align-items-lg-center justify-content-xl-start align-items-xl-center justify-content-xxl-start">
                                                <p class="text-start d-flex d-sm-flex d-xl-flex justify-content-end justify-content-sm-end  shift"
                                                    style="margin-bottom: 0;padding-left: 0; font-size: 11px !important;">
                                                    {{getMinimalisticRelativeTime(reply.createdAt)}}<br /></p>

                                            </div>
                                            <ng-container
                                                *ngIf="reply.canDeleteReply && (rfqStatus != 'In Progress' && rfqStatus != 'Closed')">
                                                <a *ngIf="!replyDeleting" class="bluetextthin clickable"
                                                    (click)="replyDelete(reply.replyId, reply.questionId)"><i
                                                        class="fa fa-trash "></i></a><span
                                                    *ngIf="replyDeleting && reply.replyId == selectedReplyId"
                                                    class="spinner-border spinner-border-sm mr-1"></span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>

                                <!-- IF MODAL GREEN AND IS SUPPLIER THEN BLUE BACKGROUND -->
                                <ng-container *ngIf="modalheader == 'green' && reply.isSupplier == true">

                                    <!-- REPLY USER ICON -->

                                    <div *ngIf="reply.buyerSeen == true && reply.isSupplier == true "
                                        class="col-1 col-sm-1" style="padding-left: 0;">
                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;padding-bottom: 1rem;"><i
                                                class="fa fa-user-circle uiicon"
                                                style="font-size: 30px;color: #4272F740;"></i></p>
                                    </div>

                                    <div *ngIf="reply.buyerSeen == false  && reply.isSupplier == true"
                                        class="col-1 col-sm-1" style="padding-left: 0;">
                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;padding-bottom: 1rem;"><i message-badge-smallest=""
                                                class="fa fa-user-circle uiicon"
                                                style="font-size: 30px;color: #4272F740;"></i>
                                        </p>
                                    </div>

                                    <div class="col justify-content-end align-items-start justify-content-sm-end align-items-sm-end justify-content-xxl-end align-items-xxl-end"
                                        style="padding: 1rem;padding-left: 1rem;margin: 0;margin-right: 1rem;margin-bottom: 0.5rem;margin-left: 1rem;background: var(--shadowgrey);border-radius: 20px;">
                                        <p class="text-start d-xl-flex bluetext shift"
                                            style="margin-bottom: 0;padding-left: 0;">{{reply.replyContent}}
                                        </p>
                                        <!-- <app-inline-edit [data]='reply.replyContent'
                                                                                    (focusOut)='replyEdit($event,reply.replyId)'>
                                                                                </app-inline-edit> -->
                                        <div class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex justify-content-between align-items-center align-items-sm-center align-items-md-center align-items-lg-center align-items-xl-center align-items-xxl-center"
                                            style="padding-top: 0.5rem;">
                                            <div
                                                class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex align-items-sm-center align-items-md-center align-items-lg-center justify-content-xl-start align-items-xl-center justify-content-xxl-start">
                                                <p class="text-start d-flex d-sm-flex d-xl-flex justify-content-end justify-content-sm-end  shift"
                                                    style="margin-bottom: 0;padding-left: 0; font-size: 11px !important;">
                                                    {{getMinimalisticRelativeTime(reply.createdAt)}}<br /></p>

                                            </div>
                                            <ng-container
                                                *ngIf="reply.canDeleteReply && (rfqStatus != 'In Progress' && rfqStatus != 'Closed')">
                                                <a *ngIf="!replyDeleting" class="bluetextthin clickable"
                                                    (click)="replyDelete(reply.replyId, reply.questionId)"><i
                                                        class="fa fa-trash "></i></a><span
                                                    *ngIf="replyDeleting && reply.replyId == selectedReplyId"
                                                    class="spinner-border spinner-border-sm mr-1"></span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>

                                <!-- IF MODAL BLUE AND IS BUYER THEN GREEN BACKGROUND -->
                                <ng-container *ngIf="modalheader == 'blue' && reply.isBuyer == true ">

                                    <!-- REPLY USER ICON -->

                                    <div *ngIf="reply.supplierSeen == true && reply.isBuyer == true "
                                        class="col-1 col-sm-1" style="padding-left: 0;">
                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;padding-bottom: 1rem;"><i
                                                class="fa fa-user-circle uiicon"
                                                style="font-size: 30px;color: #4272F740;"></i></p>
                                    </div>

                                    <div *ngIf="reply.supplierSeen == false  && reply.isBuyer == true "
                                        class="col-1 col-sm-1" style="padding-left: 0;">
                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;padding-bottom: 1rem;"><i message-badge-smallest=""
                                                class="fa fa-user-circle uiicon"
                                                style="font-size: 30px;color: #4272F740;"></i>
                                        </p>
                                    </div>








                                    <div class="col justify-content-end align-items-start justify-content-sm-end align-items-sm-end justify-content-xxl-end align-items-xxl-end"
                                        style="padding: 1rem;padding-left: 1rem;margin: 0;margin-right: 1rem;margin-bottom: 0.5rem;margin-left: 1rem;background: #d1f3f0;border-radius: 20px;">
                                        <p class="text-start d-xl-flex bluetext shift"
                                            style="margin-bottom: 0;padding-left: 0;">{{reply.replyContent}}
                                        </p>
                                        <!-- <app-inline-edit [data]='reply.replyContent'
                                                                                                                    (focusOut)='replyEdit($event,reply.replyId)'>
                                                                                                                </app-inline-edit> -->
                                        <div class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex justify-content-between align-items-center align-items-sm-center align-items-md-center align-items-lg-center align-items-xl-center align-items-xxl-center"
                                            style="padding-top: 0.5rem;">
                                            <div
                                                class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex align-items-sm-center align-items-md-center align-items-lg-center justify-content-xl-start align-items-xl-center justify-content-xxl-start">
                                                <p class="text-start d-flex d-sm-flex d-xl-flex justify-content-end justify-content-sm-end  shift"
                                                    style="margin-bottom: 0;padding-left: 0; font-size: 11px !important;">
                                                    {{getMinimalisticRelativeTime(reply.createdAt)}}<br /></p>

                                                <p class="text-start d-flex d-sm-flex d-xl-flex justify-content-end justify-content-sm-end shift"
                                                    style=" margin-bottom: 0;padding-left: 3px; font-size: 11px !important;">
                                                    - From Buyer</p>

                                            </div>
                                            <ng-container
                                                *ngIf="reply.canDeleteReply && (rfqStatus != 'In Progress' && rfqStatus != 'Closed')">
                                                <a *ngIf="!replyDeleting" class="bluetextthin clickable"
                                                    (click)="replyDelete(reply.replyId, reply.questionId)"><i
                                                        class="fa fa-trash "></i></a><span
                                                    *ngIf="replyDeleting && reply.replyId == selectedReplyId"
                                                    class="spinner-border spinner-border-sm mr-1"></span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>

                                <!-- IF MODAL BLUE AND IS SUPPLIER AND CAN EDIT OR DELETE QUESTION THEN BLUE BACKGROUND -->
                                <ng-container
                                    *ngIf="modalheader == 'blue' && reply.isSupplier == true && reply.canDeleteReply">


                                    <!-- REPLY USER ICON -->

                                    <div class="col-1 col-sm-1" style="padding-left: 0;">
                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;padding-bottom: 1rem;"><i
                                                class="fa fa-user-circle uiicon"
                                                style="font-size: 30px;color: #4272F740;"></i></p>
                                    </div>
                                    <div class="col justify-content-end align-items-start justify-content-sm-end align-items-sm-end justify-content-xxl-end align-items-xxl-end"
                                        style="padding: 1rem;padding-left: 1rem;margin: 0;margin-right: 1rem;margin-bottom: 0.5rem;margin-left: 1rem;background:    #d5e0fc;border-radius: 20px;">
                                        <p class="text-start d-xl-flex bluetext shift"
                                            style="margin-bottom: 0;padding-left: 0;">{{reply.replyContent}}
                                        </p>
                                        <!-- <app-inline-edit [data]='reply.replyContent'
                                                                                                                                                                (focusOut)='replyEdit($event,reply.replyId)'>
                                                                                                                                                            </app-inline-edit> -->
                                        <div class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex justify-content-between align-items-center align-items-sm-center align-items-md-center align-items-lg-center align-items-xl-center align-items-xxl-center"
                                            style="padding-top: 0.5rem;">
                                            <div
                                                class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex align-items-sm-center align-items-md-center align-items-lg-center justify-content-xl-start align-items-xl-center justify-content-xxl-start">
                                                <p class="text-start d-flex d-sm-flex d-xl-flex justify-content-end justify-content-sm-end  shift"
                                                    style="margin-bottom: 0;padding-left: 0; font-size: 11px !important;">
                                                    {{getMinimalisticRelativeTime(reply.createdAt)}}<br /></p>

                                            </div>
                                            <ng-container
                                                *ngIf="reply.canDeleteReply && (rfqStatus != 'In Progress' && rfqStatus != 'Closed')">
                                                <a *ngIf="!replyDeleting" class="bluetextthin clickable"
                                                    (click)="replyDelete(reply.replyId, reply.questionId)"><i
                                                        class="fa fa-trash "></i></a><span
                                                    *ngIf="replyDeleting && reply.replyId == selectedReplyId"
                                                    class="spinner-border spinner-border-sm mr-1"></span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>

                                <!-- IF MODAL BLUE AND IS SUPPLIER AND CAN NOT EDIT OR DELETE QUESTION THEN GREY BACKGROUND -->
                                <ng-container
                                    *ngIf="modalheader == 'blue' && reply.isSupplier == true && reply.canDeleteReply == false">


                                    <!-- REPLY USER ICON -->

                                    <div class="col-1 col-sm-1" style="padding-left: 0;">
                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;padding-bottom: 1rem;"><i
                                                class="fa fa-user-circle uiicon"
                                                style="font-size: 30px;color: #4272F740;"></i></p>
                                    </div>






                                    <div class="col justify-content-end align-items-start justify-content-sm-end align-items-sm-end justify-content-xxl-end align-items-xxl-end"
                                        style="padding: 1rem;padding-left: 1rem;margin: 0;margin-right: 1rem;margin-bottom: 0.5rem;margin-left: 1rem;background: var(--shadowgrey);border-radius: 20px;">
                                        <p class="text-start d-xl-flex bluetext shift"
                                            style="margin-bottom: 0;padding-left: 0;">{{reply.replyContent}}
                                        </p>
                                        <!-- <app-inline-edit [data]='reply.replyContent'
                                                                                                                                                                                                                    (focusOut)='replyEdit($event,reply.replyId)'>
                                                                                                                                                                                                                </app-inline-edit> -->
                                        <div class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex justify-content-between align-items-center align-items-sm-center align-items-md-center align-items-lg-center align-items-xl-center align-items-xxl-center"
                                            style="padding-top: 0.5rem;">
                                            <div
                                                class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex align-items-sm-center align-items-md-center align-items-lg-center justify-content-xl-start align-items-xl-center justify-content-xxl-start">
                                                <p class="text-start d-flex d-sm-flex d-xl-flex justify-content-end justify-content-sm-end  shift"
                                                    style="margin-bottom: 0;padding-left: 0; font-size: 11px !important;">
                                                    {{getMinimalisticRelativeTime(reply.createdAt)}}<br /></p>

                                            </div>
                                            <ng-container
                                                *ngIf="reply.canDeleteReply && (rfqStatus != 'In Progress' && rfqStatus != 'Closed')">
                                                <a *ngIf="!replyDeleting" class="bluetextthin clickable"
                                                    (click)="replyDelete(reply.replyId, reply.questionId)"><i
                                                        class="fa fa-trash "></i></a><span
                                                    *ngIf="replyDeleting && reply.replyId == selectedReplyId"
                                                    class="spinner-border spinner-border-sm mr-1"></span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>

                            </div>
                            <div *ngIf=" (rfqStatus != 'In Progress' && rfqStatus != 'Closed')">
                                <app-qa-reply [isBuyer]="isBuyer" [isSupplier]="isSupplier" [rfq]="rfq"
                                    [question]="question" (reloadQA)="triggerReloadQA($event)">
                                </app-qa-reply>
                            </div>
                        </div>


                        <div
                            *ngIf="question.replies.length == 0 && (rfqStatus != 'In Progress' && rfqStatus != 'Closed')">
                            <div>
                                <app-qa-reply [isBuyer]="isBuyer" [isSupplier]="isSupplier" [rfq]="rfq"
                                    [question]="question" (reloadQA)="triggerReloadQA($event)">
                                </app-qa-reply>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div *ngIf="loading" class="container-fluid d-flex justify-content-center align-items-center"
                style="margin-top:-10rem;min-height: 50vh; ">
                <div class="text-center text-center bluehead"><span
                        class="spinner-border spinner-border-sm mr-1"></span>
                    Loading...
                </div>

            </div>

        </div>

        <!-- question input -->
        <form [formGroup]='form' #test="ngForm">
            <div class="row d-xxl-flex rfqdetailrow reply" style="padding-bottom: 0px;background: #ffffff;">
                <div *ngIf="rfqStatus != 'In Progress' && rfqStatus != 'Closed' && rfqStatus != 'Cancelled'" class="col"
                    style="padding-left: 2rem;padding-right: 2rem;">
                    <div class="d-flex d-md-flex align-items-center align-items-md-center" id="reply1">
                        <div class="col-1 col-sm-1 col-md-1 d-flex d-xl-flex justify-content-center align-items-center align-items-xl-center"
                            style>
                            <p
                                class="d-flex d-sm-flex align-items-center justify-content-sm-start align-items-sm-center bluetext">
                                <i class="fa fa-user-circle uiicon" style="font-size: 30px;color: #4272F740;"></i>
                            </p>
                        </div>

                        <div class="col-9 col-sm-11 col-md-11 col-xxl-11 d-flex d-sm-flex align-items-center justify-content-sm-center align-items-sm-center justify-content-xxl-end align-items-xxl-center"
                            style="padding: 1rem;padding-left: 1rem;margin: 0;margin-right: 1rem;margin-bottom: 0px;margin-left: 1rem;border-radius: 20px;">

                            <textarea matInput formControlName="question" class=" text-start form-control"
                                role="textbox" contenteditable="" (keyup.enter)="questionSubmit()"
                                style="border-radius: 16px;background: #4272F71A;width: 363px;  min-height: 70px; height: 70px; max-height: 70px;"
                                placeholder="Write..."></textarea>
                            <button *ngIf="!questionSending" (click)="questionSubmit()" class="btn" role="button"><i
                                    class="fa fa-send" style="color: var(--supplierblue);"></i></button>
                            <i *ngIf="questionSending" class="spinner-border spinner-border-sm mr-1"></i>
                        </div>

                    </div>

                </div>

                <div *ngIf="rfqStatus == 'In Progress' || rfqStatus == 'Closed' || rfqStatus == 'Cancelled'" class="col"
                    style="padding-left: 0.75rem;padding-right: 0.75rem;">
                    <div class="row d-xxl-flex reply" style="background: #ffffff; ">
                        <div class="d-flex d-md-flex align-items-center justify-content-md-center align-items-md-center"
                            style="padding-top: 1rem;padding-bottom: 1rem;background: #ffffff;">
                            <p class="bluehead" style="color: rgb(14,14,130);font-size: 18px;"><i class="fa fa-lock"
                                    style="padding-right: 10px;"></i>Public Chat Closed</p>
                        </div>
                    </div>

                </div>


            </div>
        </form>
    </div>
</div>