<!-- edit quote modal -->
<section>
    <div role="dialog" tabindex="-1" class="modal fade" data-bs-backdrop="static" id="editQuote"
        aria-labelledby="exampleModalLabel">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body" style="padding: 0px; border: 0px">
                    <div class="shadow p" style="width: 500px">
                        <div class="
                  row
                  text-center
                  d-xl-flex
                  justify-content-xl-center
                  align-items-xl-center
                  blueformheader
                ">
                            <div class="
                    col
                    offset-1 offset-sm-1 offset-md-1 offset-lg-1 offset-xl-2
                    d-flex d-xl-flex
                    justify-content-center
                    align-items-center
                    justify-content-xl-center
                    align-items-xl-center
                  ">
                                <h1 class="d-xl-flex align-items-xl-center whitehead"
                                    style="font-size: 16px; margin-bottom: 0; color: #ffffff">
                                    Edit Quote
                                </h1>
                                <a></a>
                            </div>
                            <div class="
                    col-1 col-sm-1 col-md-1 col-lg-1 col-xl-2
                    d-xl-flex
                    justify-content-xl-end
                    align-items-xl-center
                  ">
                                <a data-bs-target="#editQuote" data-bs-toggle="modal"
                                    (click)="triggerperiodicUpdate(false)"><i class="fa fa-close"
                                        style="color: #db2e53; padding-left: 5px"></i></a>
                            </div>
                        </div>

                        <form [formGroup]='form' #test="ngForm" class="shadow p" style="width: 500px;">
                            <!-- {{test.value | json}}    -->
                            <div class="row">
                                <div class="col-xl-12 col-xxl-12 rfqsearchblock"
                                    style="padding: 2rem;border-radius: 5px 5px 5x 5px;padding-top: 0px;">
                                    <h1 class="text-center d-flex d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start blueheadsmallLB"
                                        style="font-size: 18px;padding-top: 1rem;padding-left: 0;">{{quoteRfq.rfqName}}
                                    </h1>
                                    <div class="row row-cols-2 d-xxl-flex rfqdetailrow">
                                        <div class="col d-sm-flex d-md-flex d-xl-flex align-items-sm-center align-items-md-center align-items-xl-center"
                                            style="padding-left: 0;width: 230px;">
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="padding-bottom: 0;margin-bottom: 0;"><img
                                                    class="img-fluid d-xxl-flex flex-shrink-1 align-items-xxl-center uiicon"
                                                    style="font-size: 9px;width: 40px;height: 20px;"
                                                    src="assets/Path859.png" />Total:</p>
                                            <ng-container
                                                *ngIf="(f.totalAmount.touched && f.totalAmount.errors) || (quoteValidCond == false  && f.totalAmount.errors )">
                                                <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                                            </ng-container>

                                            <ng-container
                                            *ngIf="(f.totalAmount.touched && !totalValidator(f.totalAmount.value) && !f.totalAmount.errors) || (quoteValidCond == false  && !totalValidator(f.totalAmount.value) && !f.totalAmount.errors)">
                                            <span class="p-1 bluetext thin" style="color:#a51d29;">(Invalid)</span>
                                        </ng-container>
                        
                                            <p *ngIf="!f.totalAmount.errors && totalValidator(f.totalAmount.value)"
                                                class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext thin"
                                                style="margin-bottom: 0; color:#000000; font-size: 13px!Important;">(Must Match Quote)
                                            </p>

                                        </div>
                                        <div class="col">
                                            <div class="d-flex align-items-center justify-content-center" style="margin-right: 50px;">
                                                <span class="d-sm-flex justify-content-sm-start bluetext" style="padding-right: 4px;padding-bottom: 0;margin-bottom: 0;">
                                                    R 
                                                </span>
                                            <input appAllowDecimalNumber  (change)="transformTotal()" formControlName="totalAmount"
                                                class="form-control d-xl-flex bluetextthin" placeholder="Amount"
                                                style="width: 180px;" />
                                                </div>
                                            <!-- <div *ngIf="f.totalAmount.touched && f.totalAmount.errors" class=" p-1 alert-danger" style = "width: 180px; height: 30px; font-size: 14px;" >
                   <div *ngIf="f.totalAmount.errors.required" style="margin-left: 10px;">Amount is Required</div>
               </div> -->
                                        </div>
                                    </div>
                                    <div class="row row-cols-2 d-xxl-flex rfqdetailrow">
                                        <div class="col d-flex d-md-flex d-xl-flex align-items-center align-items-md-center align-items-xl-center"
                                            style="padding-left: 0;width: 230px;"><img
                                                class="img-fluid flex-shrink-1 uiicon"
                                                style="font-size: 9px;width: 40px;height: 20px;"
                                                src="assets/Group742.png" />
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-bottom: 0;">Lead Time (days):</p>
                                            <ng-container
                                                *ngIf="(f.leadTime.touched && f.leadTime.errors) || (quoteValidCond == false  && f.leadTime.errors )">
                                                <span class=" bluetext thin" style="color:#a51d29;">(Required)</span>
                                            </ng-container>
                                            <ng-container *ngIf="(leadTimeValidCheck())">
                                                <span class=" bluetext thin"
                                                    style="font-size:12px !important; color: var(--yellow);">(Max Lead
                                                    Time is {{getMaxLeadTime(quoteRfq.maxLeadTime,
                                                    quoteRfq.quoteSubmissionClosingDate)}} days )</span>
                                            </ng-container>
                                            <ng-container *ngIf="(leadTimeNegativeCheck())">
                                                <span class=" bluetext thin" style="color:#a51d29;">(Invalid)</span>
                                            </ng-container>
                                        </div>
                                        <div class="col" style="width: 242px;"><input formControlName="leadTime"
                                                type="number" class="form-control d-xl-flex bluetextthin" id="leadtime"
                                                data-type placeholder="Lead Time Days" style="width: 180px;" />
                                            <!-- <div *ngIf="f.leadTime.touched && f.leadTime.errors" class=" p-1 alert-danger" style = "width: 180px; height: 30px; font-size: 14px;" >
                       <div *ngIf="f.leadTime.errors.required" style="margin-left: 10px;">Lead Time is Required</div>
                   </div> -->
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col d-flex d-md-flex d-xl-flex align-items-center align-items-md-center align-items-xl-center"
                                            style="width: 30%;padding:0px;">
                                            <img class="img-fluid flex-shrink-1 uiicon"
                                                style="font-size: 9px;width: 40px;height: 20px;"
                                                src="assets/Group745.png" />
                                            <p class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                                style="color: #0e0e82;padding-right: 18px;margin-left: 0px;">Services
                                            </p>
                                        </div>
                                        <div class="col">
                                            <ng-container *ngFor="let service of serviceListSelect let i = index">
                                                <button class="btn text-nowrap" type="button"
                                                    style="font-size: 12px;background: var(--mainblue);color: var(--shadowgrey);border-radius: 166px;margin: 2px;">{{service}}<a
                                                        class="closeicon" href="#"
                                                        style="color: rgb(255,255,255);"></a></button>
                                            </ng-container>
                                        </div>
                                    </div>


                                    <div *ngIf="quoteRfq.deliveryRequirements == 'Collection'"
                                        class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                        <div class="col" style="padding-left: 0;">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;"><i class="fa fa-asterisk uiicon"
                                                    style="font-size: 6px;color: #ff0000;"></i>Is The Collection Address
                                                The Same As The Company Address?

                                                <ng-container
                                                    *ngIf=" collectionDeliveryAddressCond == null  && quoteValidCond == false">
                                                    <span class="p-1 bluetext thin"
                                                        style="color:#a51d29;">(Required)</span>
                                                </ng-container>
                                            </p>
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;"></p>
                                            <div style="margin-left: 2.5rem;margin-top: 1rem;width: 437px;">
                                                <div class="row d-xl-flex justify-content-xl-start"
                                                    style="padding-top: 0;width: 553px;min-width: 498px;">
                                                    <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-md-flex justify-content-md-center align-items-md-center"
                                                        style="padding: 0;">
                                                        <div class="btn-group bluebtn" role="group"
                                                            aria-label="Basic checkbox toggle button group">
                                                            <input formControlName="collectionAddressDifYes"
                                                                type="checkbox"
                                                                (change)="collectionAddressDifferent('Yes')"
                                                                id="completesply12qyes"
                                                                class="btn-check form-check-input"
                                                                name="flexRadioDefaultRFQ" /><label
                                                                class="form-label form-check-label btn btn-outline-info"
                                                                for="completesply12qyes"
                                                                style="border-radius: 40px;font-size: 14px;"
                                                                name="flexRadioDefault">Yes</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 offset-xl-0 d-md-flex justify-content-md-center align-items-md-center"
                                                        style="padding: 0;">
                                                        <div class="btn-group bluebtn" role="group"
                                                            aria-label="Basic checkbox toggle button group"><input
                                                                formControlName="collectionAddressDifNo" type="checkbox"
                                                                (change)="collectionAddressDifferent('No')"
                                                                id="ndivsply12qno" class="btn-check form-check-input "
                                                                name="flexRadioDefaultRFQ" /><label
                                                                class="form-label form-check-label btn btn-outline-info"
                                                                for="ndivsply12qno"
                                                                style="border-radius: 40px;font-size: 14px;">No</label>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="collectionDeliveryAddressCond == 'No'"
                                        class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                        <div class="col" style="padding-left: 0;">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;"><i class="fa fa-asterisk uiicon"
                                                    style="font-size: 6px;color: #ff0000;"></i>Collection Address</p>
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;">
                                            </p>
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 30px; padding-top: 15px;"><i
                                                    class="fa fa-asterisk uiicon"
                                                    style="font-size: 6px;color: #ff0000;"></i>Street Address
                                                <ng-container
                                                    *ngIf="(collectionDeliveryAddressCond == 'No' && f.collectionStreetAddress.touched && stringValidation(f.collectionStreetAddress.value)) || ( collectionDeliveryAddressCond == 'No' && quoteValidCond == false  && stringValidation(f.collectionStreetAddress.value) )">
                                                    <span class="p-1 bluetext thin"
                                                        style="color:#a51d29;">(Required)</span>
                                                </ng-container>
                                            </p>
                                            <div
                                                style="height: 2rem;margin-left: 2rem;margin-top: 0.5rem;width: 18rem;">
                                                <input formControlName="collectionStreetAddress"
                                                    placeholder="Street Address" type="text" class="form-control"
                                                    autocomplete="off" style="width: 315px;" />
                                            </div>
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 30px; padding-top: 10px;"><i
                                                    class="fa fa-asterisk uiicon"
                                                    style="font-size: 6px;color: #ff0000;"></i>City

                                                <ng-container
                                                    *ngIf="(collectionDeliveryAddressCond == 'No' && f.collectionCity.touched && stringValidation(f.collectionCity.value)) || ( collectionDeliveryAddressCond == 'No' && quoteValidCond == false  && stringValidation(f.collectionCity.value) )">
                                                    <span class="p-1 bluetext thin"
                                                        style="color:#a51d29;">(Required)</span>
                                                </ng-container>

                                            </p>
                                            <div
                                                style="height: 2rem;margin-left: 2rem;margin-top: 0.5rem;width: 18rem;">
                                                <input formControlName="collectionCity" placeholder="City" type="text"
                                                    class="form-control" autocomplete="off"
                                                    style="width: 315px;height: 2rem;" />
                                            </div>
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 30px; padding-top: 10px;"><i
                                                    class="fa fa-asterisk uiicon"
                                                    style="font-size: 6px;color: #ff0000;"></i>Postal Code
                                                <ng-container
                                                    *ngIf="(collectionDeliveryAddressCond == 'No' && f.collectionPostalCode.touched && stringValidation(f.collectionPostalCode.value)) || ( collectionDeliveryAddressCond == 'No' && quoteValidCond == false  && stringValidation(f.collectionPostalCode.value) )">
                                                    <span class="p-1 bluetext thin"
                                                        style="color:#a51d29;">(Required)</span>
                                                </ng-container>

                                            </p>
                                            <div
                                                style="height: 2rem;margin-left: 2rem;margin-top: 0.5rem;width: 18rem;">
                                                <input formControlName="collectionPostalCode" placeholder="Postal Code"
                                                    type="text" class="form-control" autocomplete="off"
                                                    style="width: 315px;height: 2rem;" />
                                            </div>
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 30px; padding-top: 10px;"><i
                                                    class="fa fa-asterisk uiicon"
                                                    style="font-size: 6px;color: #ff0000;"></i>Province

                                                <ng-container
                                                    *ngIf="(collectionDeliveryAddressCond == 'No' && f.collectionProvince.touched && stringValidation(f.collectionProvince.value)) || ( collectionDeliveryAddressCond == 'No' && quoteValidCond == false  && stringValidation(f.collectionProvince.value) )">
                                                    <span class="p-1 bluetext thin"
                                                        style="color:#a51d29;">(Required)</span>
                                                </ng-container>

                                            </p>
                                            <div
                                                style="height: 2rem;margin-left: 2rem;margin-top: 0.5rem;width: 18rem;">
                                                <input formControlName="collectionProvince" placeholder="Province"
                                                    type="text" class="form-control" autocomplete="off"
                                                    style="width: 315px;height: 2rem;" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" style="padding-top: 15px;">
                                        <div class="col d-flex d-md-flex d-xl-flex align-items-center align-items-md-center align-items-xl-center"
                                            style="width: 30%;padding:0px;">
                                            <img
                                            src="assets/Group1299.png"
                                            style="text-decoration: underline; margin-right:10px; padding-left: 10px;width: 28px;" />
                                            <p class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                                                style="color: #0e0e82;padding-right: 18px;margin-left: 0px;">Edit Quote File
                                            </p>
                                        </div>
                                        <div class="col">
                                            
                                            <input #clearInput type="file"
                                            class="file-input"
                                            (change)="quoteFileSelected($event)"
                                            #fileUpload2
                                            accept="{{requiredFileType}}">
                                        <p class="d-sm-flex justify-content-sm-start align-items-sm-center align-items-md-center align-items-xl-center align-items-xxl-center bluetext"
                                            style="margin-bottom: 0;"><button [disabled] = "quoteFileEditing"
                                                (click)="fileUpload2.click()"
                                                class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                                type="button"
                                                style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                    class="fa fa-edit"
                                                    style="font-size: 14px;color: var(--lightblue);"></i>Edit<span *ngIf="quoteFileEditing"
                                                    class="spinner-border spinner-border-sm mr-1"></span>
                                            </button></p>

                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;"></p>

                                            <span class="text-nowrap d-xxl-flex"
                                            style="font-weight: normal;margin-right: 2rem;font-size: 12px; margin-left: 10px;">
                                            <i class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center"
                                                style="margin-right: 3px;"></i>{{quoteFileNameChange}}
                                        </span>
                                        </div>
                                    </div>

                                    <!-- <div class="row row-cols-2 d-xxl-flex rfqdetailrow">
               <div class="col d-flex d-sm-flex d-md-flex d-xl-flex align-items-center align-items-sm-center align-items-md-center align-items-xl-center" style="padding-left: 0;width: 230px;">
                   <p class="d-sm-flex justify-content-sm-start bluetext" style="padding-bottom: 0;margin-bottom: 0;"><img class="img-fluid d-xxl-flex flex-shrink-1 align-items-xxl-center uiicon" style="font-size: 9px;height: 20px;width: 40px;" src="assets/Group1299.png" />Terms and Conditions:</p>
               </div>
               <div class="col"></div>
           </div>
           <div class="row d-xxl-flex rfqdetailrow">
               <div class="col" style="padding-left: 0;">
                   <input type="file" class="file-input" (change)="termsCondFileSelected($event)" #fileUpload1 accept = "{{requiredFileType}}" >
                   <p class="d-sm-flex justify-content-sm-start align-items-sm-center align-items-md-center align-items-xl-center align-items-xxl-center bluetext" style="margin-bottom: 0;"><img class="img-fluid d-xxl-flex flex-shrink-1 align-items-xxl-center uiicon" style="font-size: 9px;" src="assets/PDFicon.png"><button (click)="fileUpload1.click()" class="btn bluebutton" type="button" style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Upload T's & C's</button></p>
                   <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0;"></p>
               </div>
               <div class="col d-flex d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-center align-items-center justify-content-sm-center align-items-sm-center justify-content-md-center align-items-md-center justify-content-lg-center align-items-lg-center align-items-xl-center align-items-xxl-center" style="padding-left: 0;">
                   <p class="d-sm-flex justify-content-sm-start align-items-sm-center align-items-md-center align-items-xl-center align-items-xxl-center bluetext" style="margin-bottom: 0;"></p>
                   <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0;"></p>
                   <p class="d-sm-flex justify-content-sm-start bluetext shift" style="margin-bottom: 0;padding-left: 0;">{{termsCondFileName || "No file uploaded yet."}}</p><span *ngIf="uploadProgress">{{ uploadProgress}} %</span><span><a ><i (click)="termsCondDeleteFile()" *ngIf="termsCondUploadComplete"class="fa fa-close" style="color: #DB2E53;padding-left: 5px;"></i></a></span>
               </div>
           </div>
           <div class="row d-xxl-flex rfqdetailrow">
               <div class="col d-flex d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-center align-items-center justify-content-sm-center align-items-sm-center align-items-md-center align-items-lg-center" style="padding-left: 0;height: 0px;">
                   <div style="background: var(--darkblue);height: 1px;margin-left: 40px;margin-top: 0;width: 410px;"></div>
               </div>
           </div>
           <div class="row d-xxl-flex rfqdetailrow">
               <div class="col" style="padding-left: 0;">
                   <input type="file" class="file-input" (change)="quoteFileSelected($event)" #fileUpload2 accept = "{{requiredFileType}}" >
                   <p class="d-sm-flex justify-content-sm-start align-items-sm-center align-items-md-center align-items-xl-center align-items-xxl-center bluetext" style="margin-bottom: 0;"><img class="img-fluid d-xxl-flex flex-shrink-1 align-items-xxl-center uiicon" style="font-size: 9px;" src="assets/PDFicon.png"><button (click)="fileUpload2.click()" class="btn bluebutton" type="button" style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Upload Quote</button></p>
                   <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0;"></p>
               </div>
               <div class="col d-flex d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-center align-items-center justify-content-sm-center align-items-sm-center justify-content-md-center align-items-md-center justify-content-lg-center align-items-lg-center align-items-xl-center align-items-xxl-center" style="padding-left: 0;">
                   <p class="d-sm-flex justify-content-sm-start align-items-sm-center align-items-md-center align-items-xl-center align-items-xxl-center bluetext" style="margin-bottom: 0;"></p>
                   <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0;"></p>
                   <p class="d-sm-flex justify-content-sm-start bluetext shift" style="margin-bottom: 0;padding-left: 0;">{{quoteFileName || "No file uploaded yet."}}</p><span *ngIf="uploadProgress">{{ uploadProgress}} %</span><span><a ><i (click)="quoteDeleteFile()" *ngIf="quoteFileUploadComplete"class="fa fa-close" style="color: #DB2E53;padding-left: 5px;"></i></a></span>
               </div>
           </div> -->

                                    <div class="row d-xxl-flex" style="padding-top: 1rem;">
                                        <div class="col d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex justify-content-end justify-content-sm-end justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-start"
                                            style="padding-left: 40px;padding-right: 0;">
                                            <p class="d-sm-flex justify-content-sm-start align-items-sm-center align-items-md-center align-items-xl-center align-items-xxl-center bluetext"
                                                style="margin-bottom: 0;"></p>
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;"></p>
                                        </div>
                                        <div class="col d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex justify-content-end justify-content-sm-end justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end"
                                            style="padding-left: 0;padding-right: 34px;"><a
                                                (click)="triggerperiodicUpdate(false)"
                                                class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex align-items-center align-items-sm-center align-items-md-center align-items-lg-center align-items-xl-center align-items-xxl-center text-gray-500"
                                                style="padding-right: 1rem;" data-bs-toggle="modal"
                                                data-bs-target="#editQuote">Cancel</a>
                                            <p class="d-sm-flex justify-content-sm-start align-items-sm-center align-items-md-center align-items-xl-center align-items-xxl-center bluetext"
                                                style="margin-bottom: 0;">
                                                <button [disabled]="editingQuote" *ngIf="quoteValid()"
                                                    (click)="submitQuote()" class="bluebutton" type="button"
                                                    style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Confirm<span
                                                        *ngIf="editingQuote"
                                                        class="spinner-border spinner-border-sm mr-1"></span></button>
                                                <button *ngIf="!quoteValid()" (click)="quoteValidCheck()"
                                                    class="bluebutton" type="button"
                                                    style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Confirm</button>
                                                <button #EditQuoteBtn data-bs-toggle="modal" data-bs-target="#editQuote"
                                                    class="bluebutton" type="button"
                                                    style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px; display: none !important;">Confirm</button>
                                            </p>
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>