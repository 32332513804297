import { Quote } from './quote';
import { RFQ } from './rfq';
import { rfqState } from './rfqState';
import { BuyerPreference, PaymentPreference, RfqType, Service } from './service';
import { Notification, NotifType } from './notification';
import { Question, Reply } from './question-thread';
import { ChatMessage } from './chatMessage';
import { Role } from './role';
import { quoteState } from './quoteState';




export const Date1: Date = new Date('July 1 2021');
export const Date2: Date = new Date('July 2 2021');
export const Date3: Date = new Date('July 3 2021');
export const Date4: Date = new Date('July 4 2021');
export const Date5: Date = new Date('June 10 2021');
export const Date6: Date = new Date('June 2 2021');
export const Date7: Date = new Date('June 16 2021');
export const Date8: Date = new Date('August 27 2021');
export const Date9: Date = new Date('August 18 2021');
export const Date10: Date = new Date('August 12 2021');
export const Date11: Date = new Date('September 19 2021');
export const Date12: Date = new Date('September 20 2021');
export const Date13: Date = new Date('July 25 2021');
export const Date14: Date = new Date('July 24 2021');
export const Date15: Date = new Date('July 21 2021');
export const Date16: Date = new Date('July 10 2021');
export const Date17: Date = new Date('June 18 2021');
export const Date18: Date = new Date('June 21 2021');
export const Date19: Date = new Date('June 18 2021');
export const Date20: Date = new Date('July 25 2021');
export const Date21: Date = new Date('August 19 2021');
export const Date22: Date = new Date('August 17 2021');
export const Date23: Date = new Date('September 20 2021');
export const Date24: Date = new Date('September 21 2021');

//Mock Chat

export const ChatMessages: ChatMessage[] = [
  { chatID: 1, rfqId: 1, quoteId: 1, role: Role.Buyer, messageContent: 'hey how are you doing?', createdAt: Date1, createdBy: 'geoff' },
  { chatID: 2, rfqId: 1, quoteId: 1, role: Role.Supplier, messageContent: 'im fine and you?', createdAt: Date1, createdBy: 'carlos' },
  { chatID: 3, rfqId: 1, quoteId: 1, role: Role.Buyer, messageContent: 'im good, any news on the delivery?', createdAt: Date1, createdBy: 'geoff' },
  { chatID: 4, rfqId: 1, quoteId: 1, role: Role.Supplier, messageContent: 'it will be dispatched at 3pm today', createdAt: Date1, createdBy: 'carlos' }
]





//Mock Question thread
export const Reply1: Reply = { replyId: 1, questionId: 1, supplierId: 1, reply: 'This is Reply 1', createDate: Date1 };
export const Reply2: Reply = { replyId: 2, questionId: 1, supplierId: 2, reply: 'This is Reply 2', createDate: Date2 };
export const Reply3: Reply = { replyId: 3, questionId: 2, supplierId: 2, reply: 'This is Reply 1', createDate: Date3 };
export const Reply4: Reply = { replyId: 4, questionId: 2, supplierId: null, reply: 'This is Reply 2', createDate: Date4 };


export const Questions: Question[] = [
  { questionId: 1, rfqId: 1, supplierId: 1, question: 'This is Question 1', replies: [Reply1, Reply2], createDate: Date1 },
  { questionId: 2, rfqId: 1, supplierId: 2, question: 'This is Question 2', replies: [Reply3, Reply4], createDate: Date2 },
  { questionId: 3, rfqId: 1, supplierId: 2, question: 'This is Question 3', replies: [], createDate: Date3 },
  { questionId: 4, rfqId: 1, supplierId: null, question: 'This is Question 4', replies: [], createDate: Date4 },
  { questionId: 5, rfqId: 2, supplierId: 1, question: 'This is Question 1', replies: [], createDate: Date5 },
  { questionId: 6, rfqId: 2, supplierId: 2, question: 'This is Question 2', replies: [], createDate: Date6 },
  { questionId: 7, rfqId: 2, supplierId: 2, question: 'This is Question 3', replies: [], createDate: Date7 },
  { questionId: 8, rfqId: 2, supplierId: null, question: 'This is Question 4', replies: [], createDate: Date8 }
]


//Mock notification data
// export const Notifs: Notification[] = [
//   { notifId: 1, notifRfqName: 'oros blow mould', buyerId: 1, supplierId: 1, notifType: NotifType.rfqSuccess, notification: 'rfq was ulpoaded successfully', notifDate: Date1 },
//   { notifId: 1, notifRfqName: 'vicks injection mould', buyerId: 1, supplierId: 1, notifType: NotifType.rfqFail, notification: 'rfq was unsuccesful', notifDate: Date2 },
//   { notifId: 1, notifRfqName: 'powerade blow mould', buyerId: 1, supplierId: 1, notifType: NotifType.rfqSuccess, notification: 'rfq was ulpoaded successfully', notifDate: Date3 },
//   { notifId: 1, notifRfqName: 'energade injection mould', buyerId: 1, supplierId: 1, notifType: NotifType.rfqFail, notification: 'rfq was unsuccesful', notifDate: Date4 },
//   { notifId: 1, notifRfqName: 'bang blow mould', buyerId: 1, supplierId: 1, notifType: NotifType.rfqSuccess, notification: 'rfq was ulpoaded successfully', notifDate: Date5 },
//   { notifId: 1, notifRfqName: 'red bull injection mould', buyerId: 1, supplierId: 1, notifType: NotifType.rfqFail, notification: 'rfq was unsuccesful', notifDate: Date6 }
// ]

export const Quote1: Quote = { quoteId: 1, amount: 1110000.00, leadTime: '9 days', servicesQuoted: [Service.LaserCutting], quoteFile: null, quoteAccepted: null, termsAndConditions: 'A sales order will only be deemed as completed once you have completed the purchase cycle, an approved payment/order confirmation is received and delivery has taken place. We will confirm your purchase order with you via your specified email. By placing a product in your shopping cart or adding a product to a wish-list does not make up a sale nor are such products, its price nor its quantities reserved. If you complete the purchase cycle for such products at a later stage Incredible Connection cannot be held liable to honour any price changes or if such products are not available.', supplierID: 1, supplierName: 'Supplier 1' };
export const Quote2: Quote = { quoteId: 2, amount: 1500000.00, leadTime: '11 days', servicesQuoted: [Service.LaserCutting, Service.Bending], quoteFile: null, quoteAccepted: null, termsAndConditions: 'None', supplierID: 2, supplierName: 'Supplier 2' };
export const Quote3: Quote = { quoteId: 3, amount: 1700000.00, leadTime: '5 days', servicesQuoted: [Service.Bending], quoteFile: null, quoteAccepted: null, termsAndConditions: 'None', supplierID: 3, supplierName: 'Supplier 3' };
export const Quote4: Quote = { quoteId: 4, amount: 900000.00, leadTime: '14 days', servicesQuoted: [Service.LaserCutting, Service.CNCMachining], quoteFile: null, quoteAccepted: null, termsAndConditions: 'None', supplierID: 4, supplierName: 'Supplier 4' };
export const Quote5: Quote = { quoteId: 5, amount: 2000000.00, leadTime: '22 days', servicesQuoted: [Service.Fabrication], quoteFile: null, quoteAccepted: null, termsAndConditions: 'None', supplierID: 5, supplierName: 'Supplier 5' };
export const Quote6: Quote = { quoteId: 6, amount: 750000.00, leadTime: '15 days', servicesQuoted: [Service.Fabrication], quoteFile: null, quoteAccepted: null, termsAndConditions: 'None', supplierID: 6, supplierName: 'Supplier 6' };
export const Quote7: Quote = { quoteId: 7, amount: 1300000.00, leadTime: '4 days', servicesQuoted: [Service.Fabrication], quoteFile: null, quoteAccepted: null, termsAndConditions: 'None', supplierID: 7, supplierName: 'Supplier 7' };
export const Quote8: Quote = { quoteId: 8, amount: 1300000.00, leadTime: '4 days', servicesQuoted: [Service.DecoilingAndSlitting], quoteFile: null, quoteAccepted: true, termsAndConditions: 'None', supplierID: 8, supplierName: 'Supplier 8' };
export const Quote9: Quote = { quoteId: 9, amount: 1300000.00, leadTime: '2 days', servicesQuoted: [Service.DecoilingAndSlitting], quoteFile: null, quoteAccepted: true, termsAndConditions: 'None', supplierID: 9, supplierName: 'GrootSeun Suppliers' };
export const Quote10: Quote = { quoteId: 10, amount: 1330000.00, leadTime: '8 days', servicesQuoted: [Service.DecoilingAndSlitting], quoteFile: null, quoteAccepted: true, termsAndConditions: 'None', supplierID: 10, supplierName: 'GrootSeun Suppliers' };
export const Quote11: Quote = { quoteId: 11, amount: 1550000.00, leadTime: '5 days', servicesQuoted: [Service.DecoilingAndSlitting], quoteFile: null, quoteAccepted: true, termsAndConditions: 'None', supplierID: 10, supplierName: 'GrootSeun Suppliers' };

export const Quotes: Quote[] = [
  { quoteId: 1, amount: 1100000.00, leadTime: '9 days', servicesQuoted: [Service.LaserCutting], quoteFile: null, quoteAccepted: null, termsAndConditions: 'None', supplierID: 1, supplierName: 'Supplier 1' },
  { quoteId: 2, amount: 1500000.00, leadTime: '11 days', servicesQuoted: [Service.LaserCutting, Service.Bending], quoteFile: null, quoteAccepted: null, termsAndConditions: 'None', supplierID: 2, supplierName: 'Supplier 1' },
  { quoteId: 3, amount: 1700000.00, leadTime: '5 days', servicesQuoted: [Service.Bending], quoteFile: null, quoteAccepted: null, termsAndConditions: 'None', supplierID: 3, supplierName: 'Supplier 1' },
  { quoteId: 4, amount: 900000.00, leadTime: '14 days', servicesQuoted: [Service.LaserCutting, Service.CNCMachining], quoteFile: null, quoteAccepted: null, termsAndConditions: 'None', supplierID: 4, supplierName: 'Supplier 1' },
  { quoteId: 5, amount: 2000000.00, leadTime: '22 days', servicesQuoted: [Service.Fabrication], quoteFile: null, quoteAccepted: null, termsAndConditions: 'None', supplierID: 5, supplierName: 'Supplier 1' },
  { quoteId: 6, amount: 750000.00, leadTime: '15 days', servicesQuoted: [Service.Fabrication], quoteFile: null, quoteAccepted: null, termsAndConditions: 'None', supplierID: 6, supplierName: 'Supplier 1' },
  { quoteId: 7, amount: 1300000.00, leadTime: '4 days', servicesQuoted: [Service.Fabrication], quoteFile: null, quoteAccepted: null, termsAndConditions: 'None', supplierID: 7, supplierName: 'Supplier 1' },
  { quoteId: 8, amount: 1300000.00, leadTime: '4 days', servicesQuoted: [Service.DecoilingAndSlitting], quoteFile: null, quoteAccepted: true, termsAndConditions: 'None', supplierID: 8, supplierName: 'Supplier 1' },
  { quoteId: 9, amount: 1300000.00, leadTime: '2 days', servicesQuoted: [Service.DecoilingAndSlitting], quoteFile: null, quoteAccepted: true, termsAndConditions: 'None', supplierID: 9, supplierName: 'Supplier 1' },
  { quoteId: 10, amount: 1330000.00, leadTime: '8 days', servicesQuoted: [Service.DecoilingAndSlitting], quoteFile: null, quoteAccepted: true, termsAndConditions: 'None', supplierID: 10, supplierName: 'Supplier 1' },
  { quoteId: 11, amount: 1550000.00, leadTime: '5 days', servicesQuoted: [Service.DecoilingAndSlitting], quoteFile: null, quoteAccepted: true, termsAndConditions: 'None', supplierID: 10, supplierName: 'Supplier 1' }
]

 export const QuoteRFQ: RFQ[] = [
//   { rfqId: 1, rfqState: rfqState.Open, rfqName: 'RFQ 001', rfqType: [RfqType.CompleteSupply, RfqType.Individual], maxLeadTime: '9 days', quoteSubmissionClosingDate: Date20, servicesRequired: [Service.LaserCutting, Service.Bending, Service.Chamfer], buyerPreference: [BuyerPreference.BEEComplient], paymentPreference: PaymentPreference.COD, numberOfParts: 105, materialGrade: "Cobolt-60", testCertification: 'Not Required', drawings: [], quotes: [Quote1], acceptedQuotes: null, additionalComments: 'No shortcuts please', qAndAId: 1, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 2, rfqState: rfqState.Open, rfqName: 'RFQ 002', rfqType: [RfqType.Individual], maxLeadTime: '12 days', quoteSubmissionClosingDate: Date21, servicesRequired: [Service.LaserCutting, Service.CNCMachining], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.Days30, numberOfParts: 50, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A2.pdf'], quotes: [Quote2], acceptedQuotes: null, additionalComments: 'None', qAndAId: 2, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 3, rfqState: rfqState.Open, rfqName: 'RFQ 003', rfqType: [RfqType.CompleteSupply], maxLeadTime: '22 days', quoteSubmissionClosingDate: Date22, servicesRequired: [Service.Fabrication], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.GoldVerified], paymentPreference: PaymentPreference.Days60, numberOfParts: 540, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: [], quotes: [Quote3], acceptedQuotes: null, additionalComments: 'None', qAndAId: 3, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 4, rfqState: rfqState.inProgress, rfqName: 'RFQ 004', rfqType: [RfqType.Individual], maxLeadTime: '50 days', quoteSubmissionClosingDate: Date24, servicesRequired: [Service.DecoilingAndSlitting], buyerPreference: [BuyerPreference.GoldVerified], paymentPreference: PaymentPreference.Other, numberOfParts: 24, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A1.pdf'], quotes: [Quote4], acceptedQuotes: [Quote8], additionalComments: 'None', qAndAId: 4, chatId: 1, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 5, rfqState: rfqState.Open, rfqName: 'RFQ 005', rfqType: [RfqType.CompleteSupply, RfqType.Individual], maxLeadTime: '35 days', quoteSubmissionClosingDate: Date23, servicesRequired: [Service.LaserCutting, Service.Rolling, Service.Fabrication], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.COD, numberOfParts: 105, materialGrade: 'Various (as per drawings)', testCertification: 'Not Required', drawings: [], quotes: [Quote5], acceptedQuotes: null, additionalComments: 'No shortcuts please', qAndAId: 1, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 6, rfqState: rfqState.Open, rfqName: 'RFQ 006', rfqType: [RfqType.Individual], maxLeadTime: '17 days', quoteSubmissionClosingDate: Date21, servicesRequired: [Service.Welding, Service.CNCMachining], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.Days30, numberOfParts: 50, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A3.pdf'], quotes: [Quote6], acceptedQuotes: null, additionalComments: 'None', qAndAId: 2, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 7, rfqState: rfqState.Open, rfqName: 'RFQ 007', rfqType: [RfqType.CompleteSupply], maxLeadTime: '69 days', quoteSubmissionClosingDate: Date22, servicesRequired: [Service.Fabrication, Service.ProfileCutting], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.GoldVerified], paymentPreference: PaymentPreference.Days60, numberOfParts: 540, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: [], quotes: [Quote7], acceptedQuotes: null, additionalComments: 'None', qAndAId: 3, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 8, rfqState: rfqState.inProgress, rfqName: 'RFQ 008', rfqType: [RfqType.Individual], maxLeadTime: '86 days', quoteSubmissionClosingDate: Date23, servicesRequired: [Service.DecoilingAndSlitting, Service.PlasmaCutting, Service.ProfileCutting], buyerPreference: [BuyerPreference.GoldVerified, BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.Other, numberOfParts: 24, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A4.pdf'], quotes: [Quote8], acceptedQuotes: [Quote8], additionalComments: 'None', qAndAId: 4, chatId: 1, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 9, rfqState: rfqState.Open, rfqName: 'RFQ 009', rfqType: [RfqType.CompleteSupply, RfqType.Individual], maxLeadTime: '58 days', quoteSubmissionClosingDate: Date24, servicesRequired: [Service.LaserCutting, Service.Bending, Service.Chamfer], buyerPreference: [BuyerPreference.BEEComplient], paymentPreference: PaymentPreference.COD, numberOfParts: 195, materialGrade: 'Various (as per drawings)', testCertification: 'Not Required', drawings: [], quotes: [Quote9], acceptedQuotes: null, additionalComments: 'No shortcuts please', qAndAId: 1, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 10, rfqState: rfqState.Open, rfqName: 'RFQ 010', rfqType: [RfqType.Individual], maxLeadTime: '64 days', quoteSubmissionClosingDate: Date20, servicesRequired: [Service.LaserCutting, Service.CNCMachining], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.Days30, numberOfParts: 58, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A2.pdf'], quotes: [Quote10], acceptedQuotes: null, additionalComments: 'None', qAndAId: 2, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
 ];



//Mock View RFQ Data
 export const RFQS: RFQ[] = [
//   { rfqId: 1, rfqState: rfqState.Open, rfqName: 'RFQ 001', rfqType: [RfqType.CompleteSupply, RfqType.Individual], maxLeadTime: '9 days', quoteSubmissionClosingDate: Date1, servicesRequired: [Service.LaserCutting, Service.Bending, Service.Chamfer], buyerPreference: [BuyerPreference.BEEComplient], paymentPreference: PaymentPreference.COD, numberOfParts: 105, materialGrade: 'Various (as per drawings)', testCertification: 'Not Required', drawings: [], quotes: [Quote1, Quote2, Quote3], acceptedQuotes: null, additionalComments: 'No shortcuts please', qAndAId: 1, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 2, rfqState: rfqState.Open, rfqName: 'RFQ 002', rfqType: [RfqType.Individual], maxLeadTime: '12 days', quoteSubmissionClosingDate: Date2, servicesRequired: [Service.LaserCutting, Service.CNCMachining], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.Days30, numberOfParts: 50, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A2.pdf'], quotes: [Quote4], acceptedQuotes: null, additionalComments: 'None', qAndAId: 2, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 3, rfqState: rfqState.Open, rfqName: 'RFQ 003', rfqType: [RfqType.CompleteSupply], maxLeadTime: '22 days', quoteSubmissionClosingDate: Date3, servicesRequired: [Service.Fabrication], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.GoldVerified], paymentPreference: PaymentPreference.Days60, numberOfParts: 540, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: [], quotes: [Quote5, Quote6, Quote7, Quote9, Quote10, Quote11], acceptedQuotes: null, additionalComments: 'None', qAndAId: 3, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 4, rfqState: rfqState.inProgress, rfqName: 'RFQ 004', rfqType: [RfqType.Individual], maxLeadTime: '50 days', quoteSubmissionClosingDate: Date4, servicesRequired: [Service.DecoilingAndSlitting], buyerPreference: [BuyerPreference.GoldVerified], paymentPreference: PaymentPreference.Other, numberOfParts: 24, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A1.pdf'], quotes: [Quote8], acceptedQuotes: [Quote8], additionalComments: 'None', qAndAId: 4, chatId: 1, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 5, rfqState: rfqState.Open, rfqName: 'RFQ 005', rfqType: [RfqType.CompleteSupply, RfqType.Individual], maxLeadTime: '35 days', quoteSubmissionClosingDate: Date5, servicesRequired: [Service.LaserCutting, Service.Rolling, Service.Fabrication], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.COD, numberOfParts: 105, materialGrade: 'Various (as per drawings)', testCertification: 'Not Required', drawings: [], quotes: [Quote1, Quote2, Quote3], acceptedQuotes: null, additionalComments: 'No shortcuts please', qAndAId: 1, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 6, rfqState: rfqState.Closed, rfqName: 'RFQ 006', rfqType: [RfqType.Individual], maxLeadTime: '17 days', quoteSubmissionClosingDate: Date6, servicesRequired: [Service.Welding, Service.CNCMachining], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.Days30, numberOfParts: 50, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A3.pdf'], quotes: [Quote4], acceptedQuotes: null, additionalComments: 'None', qAndAId: 2, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 7, rfqState: rfqState.Open, rfqName: 'RFQ 007', rfqType: [RfqType.CompleteSupply], maxLeadTime: '69 days', quoteSubmissionClosingDate: Date4, servicesRequired: [Service.Fabrication, Service.ProfileCutting], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.GoldVerified], paymentPreference: PaymentPreference.Days60, numberOfParts: 540, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: [], quotes: [Quote5, Quote6, Quote7, Quote9, Quote10, Quote11], acceptedQuotes: null, additionalComments: 'None', qAndAId: 3, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 8, rfqState: rfqState.inProgress, rfqName: 'RFQ 008', rfqType: [RfqType.Individual], maxLeadTime: '86 days', quoteSubmissionClosingDate: Date6, servicesRequired: [Service.DecoilingAndSlitting, Service.PlasmaCutting, Service.ProfileCutting], buyerPreference: [BuyerPreference.GoldVerified, BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.Other, numberOfParts: 24, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A4.pdf'], quotes: [Quote8], acceptedQuotes: [Quote8], additionalComments: 'None', qAndAId: 4, chatId: 1, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 9, rfqState: rfqState.Open, rfqName: 'RFQ 009', rfqType: [RfqType.CompleteSupply, RfqType.Individual], maxLeadTime: '58 days', quoteSubmissionClosingDate: Date7, servicesRequired: [Service.LaserCutting, Service.Bending, Service.Chamfer], buyerPreference: [BuyerPreference.BEEComplient], paymentPreference: PaymentPreference.COD, numberOfParts: 195, materialGrade: 'Various (as per drawings)', testCertification: 'Not Required', drawings: [], quotes: [Quote1, Quote2, Quote3], acceptedQuotes: null, additionalComments: 'No shortcuts please', qAndAId: 1, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 10, rfqState: rfqState.Open, rfqName: 'RFQ 010', rfqType: [RfqType.Individual], maxLeadTime: '64 days', quoteSubmissionClosingDate: Date8, servicesRequired: [Service.LaserCutting, Service.CNCMachining], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.Days30, numberOfParts: 58, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A2.pdf'], quotes: [Quote4], acceptedQuotes: null, additionalComments: 'None', qAndAId: 2, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 11, rfqState: rfqState.Open, rfqName: 'RFQ 011', rfqType: [RfqType.CompleteSupply], maxLeadTime: '72 days', quoteSubmissionClosingDate: Date9, servicesRequired: [Service.Fabrication, Service.Rolling], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.GoldVerified], paymentPreference: PaymentPreference.Days60, numberOfParts: 565, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: [], quotes: [Quote5, Quote6, Quote7, Quote9, Quote10, Quote11], acceptedQuotes: null, additionalComments: 'None', qAndAId: 3, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 12, rfqState: rfqState.inProgress, rfqName: 'RFQ 012', rfqType: [RfqType.Individual], maxLeadTime: '19 days', quoteSubmissionClosingDate: Date10, servicesRequired: [Service.DecoilingAndSlitting], buyerPreference: [BuyerPreference.GoldVerified], paymentPreference: PaymentPreference.Other, numberOfParts: 35, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A1.pdf'], quotes: [Quote8], acceptedQuotes: [Quote8], additionalComments: 'None', qAndAId: 4, chatId: 1, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 13, rfqState: rfqState.Open, rfqName: 'RFQ 013', rfqType: [RfqType.CompleteSupply, RfqType.Individual], maxLeadTime: '5 days', quoteSubmissionClosingDate: Date11, servicesRequired: [Service.LaserCutting, Service.Rolling, Service.Fabrication], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.COD, numberOfParts: 185, materialGrade: 'Various (as per drawings)', testCertification: 'Not Required', drawings: [], quotes: [Quote1, Quote2, Quote3], acceptedQuotes: null, additionalComments: 'No shortcuts please', qAndAId: 1, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 14, rfqState: rfqState.Closed, rfqName: 'RFQ 014', rfqType: [RfqType.Individual], maxLeadTime: '96 days', quoteSubmissionClosingDate: Date12, servicesRequired: [Service.Welding, Service.CNCMachining, Service.Drilling], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.Days30, numberOfParts: 50, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A3.pdf'], quotes: [Quote4], acceptedQuotes: null, additionalComments: 'None', qAndAId: 2, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 15, rfqState: rfqState.Open, rfqName: 'RFQ 015', rfqType: [RfqType.CompleteSupply, RfqType.Individual], maxLeadTime: '70 days', quoteSubmissionClosingDate: Date3, servicesRequired: [Service.Fabrication, Service.ProfileCutting, Service.Rolling], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.GoldVerified], paymentPreference: PaymentPreference.Days60, numberOfParts: 472, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: [], quotes: [Quote5, Quote6, Quote7, Quote9, Quote10, Quote11], acceptedQuotes: null, additionalComments: 'None', qAndAId: 3, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 16, rfqState: rfqState.inProgress, rfqName: 'RFQ 016', rfqType: [RfqType.Individual], maxLeadTime: '23 days', quoteSubmissionClosingDate: Date6, servicesRequired: [Service.DecoilingAndSlitting, Service.PlasmaCutting, Service.ProfileCutting], buyerPreference: [BuyerPreference.GoldVerified, BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.Other, numberOfParts: 244, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A4.pdf'], quotes: [Quote8], acceptedQuotes: [Quote8], additionalComments: 'None', qAndAId: 4, chatId: 1, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 17, rfqState: rfqState.Open, rfqName: 'RFQ 017', rfqType: [RfqType.CompleteSupply, RfqType.Individual], maxLeadTime: '9 days', quoteSubmissionClosingDate: Date1, servicesRequired: [Service.LaserCutting, Service.Bending, Service.Chamfer], buyerPreference: [BuyerPreference.BEEComplient], paymentPreference: PaymentPreference.COD, numberOfParts: 155, materialGrade: 'Various (as per drawings)', testCertification: 'Not Required', drawings: [], quotes: [Quote1, Quote2, Quote3], acceptedQuotes: null, additionalComments: 'No shortcuts please', qAndAId: 1, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 18, rfqState: rfqState.Open, rfqName: 'RFQ 018', rfqType: [RfqType.Individual], maxLeadTime: '12 days', quoteSubmissionClosingDate: Date2, servicesRequired: [Service.LaserCutting, Service.CNCMachining], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.Days30, numberOfParts: 1005, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A2.pdf'], quotes: [Quote4], acceptedQuotes: null, additionalComments: 'None', qAndAId: 2, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 19, rfqState: rfqState.Open, rfqName: 'RFQ 019', rfqType: [RfqType.CompleteSupply], maxLeadTime: '22 days', quoteSubmissionClosingDate: Date18, servicesRequired: [Service.Fabrication], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.GoldVerified], paymentPreference: PaymentPreference.Days60, numberOfParts: 255, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: [], quotes: [Quote5, Quote6, Quote7, Quote9, Quote10, Quote11], acceptedQuotes: null, additionalComments: 'None', qAndAId: 3, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 20, rfqState: rfqState.inProgress, rfqName: 'RFQ 020', rfqType: [RfqType.Individual], maxLeadTime: '50 days', quoteSubmissionClosingDate: Date19, servicesRequired: [Service.DecoilingAndSlitting], buyerPreference: [BuyerPreference.GoldVerified], paymentPreference: PaymentPreference.Other, numberOfParts: 64, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A1.pdf'], quotes: [Quote8], acceptedQuotes: [Quote8], additionalComments: 'None', qAndAId: 4, chatId: 1, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 21, rfqState: rfqState.Open, rfqName: 'RFQ 021', rfqType: [RfqType.CompleteSupply, RfqType.Individual], maxLeadTime: '35 days', quoteSubmissionClosingDate: Date24, servicesRequired: [Service.LaserCutting, Service.Rolling, Service.Fabrication], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.COD, numberOfParts: 1545, materialGrade: 'Various (as per drawings)', testCertification: 'Not Required', drawings: [], quotes: [Quote1, Quote2, Quote3], acceptedQuotes: null, additionalComments: 'No shortcuts please', qAndAId: 1, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 22, rfqState: rfqState.Open, rfqName: 'RFQ 022', rfqType: [RfqType.Individual], maxLeadTime: '17 days', quoteSubmissionClosingDate: Date16, servicesRequired: [Service.Welding, Service.CNCMachining], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.Days30, numberOfParts: 85, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A3.pdf'], quotes: [Quote4], acceptedQuotes: null, additionalComments: 'None', qAndAId: 2, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 23, rfqState: rfqState.Open, rfqName: 'RFQ 023', rfqType: [RfqType.CompleteSupply], maxLeadTime: '69 days', quoteSubmissionClosingDate: Date17, servicesRequired: [Service.Fabrication, Service.ProfileCutting], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.GoldVerified], paymentPreference: PaymentPreference.Days60, numberOfParts: 854, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: [], quotes: [Quote5, Quote6, Quote7, Quote9, Quote10, Quote11], acceptedQuotes: null, additionalComments: 'None', qAndAId: 3, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 24, rfqState: rfqState.inProgress, rfqName: 'RFQ 024', rfqType: [RfqType.Individual], maxLeadTime: '86 days', quoteSubmissionClosingDate: Date19, servicesRequired: [Service.DecoilingAndSlitting, Service.PlasmaCutting, Service.ProfileCutting], buyerPreference: [BuyerPreference.GoldVerified, BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.Other, numberOfParts: 314, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A4.pdf'], quotes: [Quote8], acceptedQuotes: [Quote8], additionalComments: 'None', qAndAId: 4, chatId: 1, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 25, rfqState: rfqState.Open, rfqName: 'RFQ 025', rfqType: [RfqType.CompleteSupply, RfqType.Individual], maxLeadTime: '58 days', quoteSubmissionClosingDate: Date20, servicesRequired: [Service.LaserCutting, Service.Bending, Service.Chamfer], buyerPreference: [BuyerPreference.BEEComplient], paymentPreference: PaymentPreference.COD, numberOfParts: 1055, materialGrade: 'Various (as per drawings)', testCertification: 'Not Required', drawings: [], quotes: [Quote1, Quote2, Quote3], acceptedQuotes: null, additionalComments: 'No shortcuts please', qAndAId: 1, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 26, rfqState: rfqState.Closed, rfqName: 'RFQ 026', rfqType: [RfqType.Individual], maxLeadTime: '64 days', quoteSubmissionClosingDate: Date18, servicesRequired: [Service.LaserCutting, Service.CNCMachining], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.Days30, numberOfParts: 845, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A2.pdf'], quotes: [Quote4], acceptedQuotes: null, additionalComments: 'None', qAndAId: 2, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 27, rfqState: rfqState.Open, rfqName: 'RFQ 027', rfqType: [RfqType.CompleteSupply], maxLeadTime: '72 days', quoteSubmissionClosingDate: Date13, servicesRequired: [Service.Fabrication, Service.Rolling], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.GoldVerified], paymentPreference: PaymentPreference.Days60, numberOfParts: 218, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: [], quotes: [Quote5, Quote6, Quote7, Quote9, Quote10, Quote11], acceptedQuotes: null, additionalComments: 'None', qAndAId: 3, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 28, rfqState: rfqState.inProgress, rfqName: 'RFQ 028', rfqType: [RfqType.Individual], maxLeadTime: '19 days', quoteSubmissionClosingDate: Date12, servicesRequired: [Service.DecoilingAndSlitting], buyerPreference: [BuyerPreference.GoldVerified], paymentPreference: PaymentPreference.Other, numberOfParts: 298, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A1.pdf'], quotes: [Quote8], acceptedQuotes: [Quote8], additionalComments: 'None', qAndAId: 4, chatId: 1, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 29, rfqState: rfqState.Open, rfqName: 'RFQ 029', rfqType: [RfqType.CompleteSupply, RfqType.Individual], maxLeadTime: '5 days', quoteSubmissionClosingDate: Date11, servicesRequired: [Service.LaserCutting, Service.Rolling, Service.Fabrication], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.COD, numberOfParts: 115, materialGrade: 'Various (as per drawings)', testCertification: 'Not Required', drawings: [], quotes: [Quote1, Quote2, Quote3], acceptedQuotes: null, additionalComments: 'No shortcuts please', qAndAId: 1, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 30, rfqState: rfqState.Open, rfqName: 'RFQ 030', rfqType: [RfqType.Individual], maxLeadTime: '96 days', quoteSubmissionClosingDate: Date9, servicesRequired: [Service.Welding, Service.CNCMachining, Service.Drilling], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.Days30, numberOfParts: 50, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A3.pdf'], quotes: [Quote4], acceptedQuotes: null, additionalComments: 'None', qAndAId: 2, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 31, rfqState: rfqState.Open, rfqName: 'RFQ 031', rfqType: [RfqType.CompleteSupply, RfqType.Individual], maxLeadTime: '70 days', quoteSubmissionClosingDate: Date4, servicesRequired: [Service.Fabrication, Service.ProfileCutting, Service.Rolling], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.GoldVerified], paymentPreference: PaymentPreference.Days60, numberOfParts: 540, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: [], quotes: [Quote5, Quote6, Quote7, Quote9, Quote10, Quote11], acceptedQuotes: null, additionalComments: 'None', qAndAId: 3, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 32, rfqState: rfqState.inProgress, rfqName: 'RFQ 032', rfqType: [RfqType.Individual], maxLeadTime: '23 days', quoteSubmissionClosingDate: Date16, servicesRequired: [Service.DecoilingAndSlitting, Service.PlasmaCutting, Service.ProfileCutting], buyerPreference: [BuyerPreference.GoldVerified, BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.Other, numberOfParts: 24, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A4.pdf'], quotes: [Quote8], acceptedQuotes: [Quote8], additionalComments: 'None', qAndAId: 4, chatId: 1, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 33, rfqState: rfqState.Open, rfqName: 'RFQ 033', rfqType: [RfqType.CompleteSupply, RfqType.Individual], maxLeadTime: '9 days', quoteSubmissionClosingDate: Date1, servicesRequired: [Service.LaserCutting, Service.Bending, Service.Chamfer], buyerPreference: [BuyerPreference.BEEComplient], paymentPreference: PaymentPreference.COD, numberOfParts: 105, materialGrade: 'Various (as per drawings)', testCertification: 'Not Required', drawings: [], quotes: [Quote1, Quote2, Quote3], acceptedQuotes: null, additionalComments: 'No shortcuts please', qAndAId: 1, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 34, rfqState: rfqState.Open, rfqName: 'RFQ 034', rfqType: [RfqType.Individual], maxLeadTime: '12 days', quoteSubmissionClosingDate: Date2, servicesRequired: [Service.LaserCutting, Service.CNCMachining], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.Days30, numberOfParts: 50, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A2.pdf'], quotes: [Quote4], acceptedQuotes: null, additionalComments: 'None', qAndAId: 2, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 35, rfqState: rfqState.Open, rfqName: 'RFQ 035', rfqType: [RfqType.CompleteSupply], maxLeadTime: '22 days', quoteSubmissionClosingDate: Date3, servicesRequired: [Service.Fabrication], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.GoldVerified], paymentPreference: PaymentPreference.Days60, numberOfParts: 540, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: [], quotes: [Quote5, Quote6, Quote7, Quote9, Quote10, Quote11], acceptedQuotes: null, additionalComments: 'None', qAndAId: 3, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 36, rfqState: rfqState.inProgress, rfqName: 'RFQ 036', rfqType: [RfqType.Individual], maxLeadTime: '50 days', quoteSubmissionClosingDate: Date4, servicesRequired: [Service.DecoilingAndSlitting], buyerPreference: [BuyerPreference.GoldVerified], paymentPreference: PaymentPreference.Other, numberOfParts: 24, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A1.pdf'], quotes: [Quote8], acceptedQuotes: [Quote8], additionalComments: 'None', qAndAId: 4, chatId: 1, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 37, rfqState: rfqState.Open, rfqName: 'RFQ 037', rfqType: [RfqType.CompleteSupply, RfqType.Individual], maxLeadTime: '35 days', quoteSubmissionClosingDate: Date5, servicesRequired: [Service.LaserCutting, Service.Rolling, Service.Fabrication], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.COD, numberOfParts: 105, materialGrade: 'Various (as per drawings)', testCertification: 'Not Required', drawings: [], quotes: [Quote1, Quote2, Quote3], acceptedQuotes: null, additionalComments: 'No shortcuts please', qAndAId: 1, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 38, rfqState: rfqState.Closed, rfqName: 'RFQ 038', rfqType: [RfqType.Individual], maxLeadTime: '17 days', quoteSubmissionClosingDate: Date6, servicesRequired: [Service.Welding, Service.CNCMachining], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.Days30, numberOfParts: 50, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A3.pdf'], quotes: [Quote4], acceptedQuotes: null, additionalComments: 'None', qAndAId: 2, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 39, rfqState: rfqState.Open, rfqName: 'RFQ 039', rfqType: [RfqType.CompleteSupply], maxLeadTime: '69 days', quoteSubmissionClosingDate: Date4, servicesRequired: [Service.Fabrication, Service.ProfileCutting], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.GoldVerified], paymentPreference: PaymentPreference.Days60, numberOfParts: 540, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: [], quotes: [Quote5, Quote6, Quote7, Quote9, Quote10, Quote11], acceptedQuotes: null, additionalComments: 'None', qAndAId: 3, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 40, rfqState: rfqState.inProgress, rfqName: 'RFQ 040', rfqType: [RfqType.Individual], maxLeadTime: '86 days', quoteSubmissionClosingDate: Date6, servicesRequired: [Service.DecoilingAndSlitting, Service.PlasmaCutting, Service.ProfileCutting], buyerPreference: [BuyerPreference.GoldVerified, BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.Other, numberOfParts: 24, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A4.pdf'], quotes: [Quote8], acceptedQuotes: [Quote8], additionalComments: 'None', qAndAId: 4, chatId: 1, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 41, rfqState: rfqState.Open, rfqName: 'RFQ 041', rfqType: [RfqType.CompleteSupply, RfqType.Individual], maxLeadTime: '58 days', quoteSubmissionClosingDate: Date7, servicesRequired: [Service.LaserCutting, Service.Bending, Service.Chamfer], buyerPreference: [BuyerPreference.BEEComplient], paymentPreference: PaymentPreference.COD, numberOfParts: 105, materialGrade: 'Various (as per drawings)', testCertification: 'Not Required', drawings: [], quotes: [Quote1, Quote2, Quote3], acceptedQuotes: null, additionalComments: 'No shortcuts please', qAndAId: 1, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 42, rfqState: rfqState.Closed, rfqName: 'RFQ 042', rfqType: [RfqType.Individual], maxLeadTime: '64 days', quoteSubmissionClosingDate: Date8, servicesRequired: [Service.LaserCutting, Service.CNCMachining], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.Days30, numberOfParts: 50, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A2.pdf'], quotes: [Quote4], acceptedQuotes: null, additionalComments: 'None', qAndAId: 2, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 43, rfqState: rfqState.Open, rfqName: 'RFQ 043', rfqType: [RfqType.CompleteSupply], maxLeadTime: '72 days', quoteSubmissionClosingDate: Date9, servicesRequired: [Service.Fabrication, Service.Rolling], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.GoldVerified], paymentPreference: PaymentPreference.Days60, numberOfParts: 540, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: [], quotes: [Quote5, Quote6, Quote7, Quote9, Quote10, Quote11], acceptedQuotes: null, additionalComments: 'None', qAndAId: 3, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 44, rfqState: rfqState.Open, rfqName: 'RFQ 044', rfqType: [RfqType.Individual], maxLeadTime: '19 days', quoteSubmissionClosingDate: Date10, servicesRequired: [Service.DecoilingAndSlitting], buyerPreference: [BuyerPreference.GoldVerified], paymentPreference: PaymentPreference.Other, numberOfParts: 24, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A1.pdf'], quotes: [Quote8], acceptedQuotes: [Quote8], additionalComments: 'None', qAndAId: 4, chatId: 1, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 45, rfqState: rfqState.Closed, rfqName: 'RFQ 045', rfqType: [RfqType.CompleteSupply, RfqType.Individual], maxLeadTime: '5 days', quoteSubmissionClosingDate: Date11, servicesRequired: [Service.LaserCutting, Service.Rolling, Service.Fabrication], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.COD, numberOfParts: 105, materialGrade: 'Various (as per drawings)', testCertification: 'Not Required', drawings: [], quotes: [Quote1, Quote2, Quote3], acceptedQuotes: null, additionalComments: 'No shortcuts please', qAndAId: 1, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 46, rfqState: rfqState.Open, rfqName: 'RFQ 046', rfqType: [RfqType.Individual], maxLeadTime: '96 days', quoteSubmissionClosingDate: Date12, servicesRequired: [Service.Welding, Service.CNCMachining, Service.Drilling], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.Days30, numberOfParts: 50, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A3.pdf'], quotes: [Quote4], acceptedQuotes: null, additionalComments: 'None', qAndAId: 2, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 47, rfqState: rfqState.Open, rfqName: 'RFQ 047', rfqType: [RfqType.CompleteSupply, RfqType.Individual], maxLeadTime: '70 days', quoteSubmissionClosingDate: Date3, servicesRequired: [Service.Fabrication, Service.ProfileCutting, Service.Rolling], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.GoldVerified], paymentPreference: PaymentPreference.Days60, numberOfParts: 540, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: [], quotes: [Quote5, Quote6, Quote7, Quote9, Quote10, Quote11], acceptedQuotes: null, additionalComments: 'None', qAndAId: 3, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 48, rfqState: rfqState.inProgress, rfqName: 'RFQ 048', rfqType: [RfqType.Individual], maxLeadTime: '23 days', quoteSubmissionClosingDate: Date6, servicesRequired: [Service.DecoilingAndSlitting, Service.PlasmaCutting, Service.ProfileCutting], buyerPreference: [BuyerPreference.GoldVerified, BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.Other, numberOfParts: 24, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A4.pdf'], quotes: [Quote8], acceptedQuotes: [Quote8], additionalComments: 'None', qAndAId: 4, chatId: 1, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 49, rfqState: rfqState.Open, rfqName: 'RFQ 049', rfqType: [RfqType.CompleteSupply, RfqType.Individual], maxLeadTime: '9 days', quoteSubmissionClosingDate: Date1, servicesRequired: [Service.LaserCutting, Service.Bending, Service.Chamfer], buyerPreference: [BuyerPreference.BEEComplient], paymentPreference: PaymentPreference.COD, numberOfParts: 105, materialGrade: 'Various (as per drawings)', testCertification: 'Not Required', drawings: [], quotes: [Quote1, Quote2, Quote3], acceptedQuotes: null, additionalComments: 'No shortcuts please', qAndAId: 1, chatId: null, buyerId: 1, buyerName: 'Buyer1' },
//   { rfqId: 50, rfqState: rfqState.Closed, rfqName: 'RFQ 050', rfqType: [RfqType.Individual], maxLeadTime: '12 days', quoteSubmissionClosingDate: Date2, servicesRequired: [Service.LaserCutting], buyerPreference: [BuyerPreference.BEEComplient, BuyerPreference.CreditGaruntee], paymentPreference: PaymentPreference.Days30, numberOfParts: 50, materialGrade: 'Various (as per drawings)', testCertification: 'Required', drawings: ['A2.pdf'], quotes: [Quote4], acceptedQuotes: null, additionalComments: 'None', qAndAId: 2, chatId: null, buyerId: 1, buyerName: 'Buyer1' },

 ];


export interface rfqForm {
  userId: number;
  supplierService: String[];
  //all other form inputs and some more meta data
}

export const RFQFORM: rfqForm[] = [

  { userId: 1, supplierService: [] },


]

export interface Buyer {
  userId: number;
  buyerId: number;
  supplierService: String;
}

//export const buyers: Buyer[] = [
//{ userId: 11, supplierService: [],  }
//];



