
 <div class="container d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex justify-content-end justify-content-sm-end justify-content-md-end justify-content-lg-end justify-content-xl-end align-items-xl-center" style="width: 700px;padding-right: 0px;">
<ul *ngIf="pager.pages && pager.pages.length" class="pagination">
    <!-- <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item">
        <a (click)="setPage(1)" class="page-link" aria-label="Next"><span aria-hidden="true">First</span></a>
    </li> -->
    <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item previous-item">
        <a (click) = "pagingActive()" (click)="setPage(pager.currentPage - 1)"  class="page-link" aria-label="Next"><span aria-hidden="true"><</span></a>
    </li>
    <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}" class="page-item">
        <a (click) = "pagingActive()" (click)="setPage(page)"  class="page-link">{{page}}</a>
    </li>
    <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item">
        <a (click) = "pagingActive()" (click)="setPage(pager.currentPage + 1)"  class="page-link" aria-label="Next" ><span aria-hidden="true">></span></a>
    </li>
    <!-- <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item">
        <a (click)="setPage(pager.totalPages)" class="page-link" aria-label="Next"><span aria-hidden="true">Last</span></a>
    </li> -->

</ul>
</div>  
