import { Quote, QuoteSubmitted } from '../models/quote';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Quotes } from '../models/mock-data';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { ToastrNotificationService } from './toastr-notification.service';
import { MessageService } from './message.service';
import { View_Quotes, View_QuotesRFQs } from '../models/view_quote';
import { QuoteEdit } from '../models/quoteEdit';
import { CompleteQuote } from '../models/completeQuote';
import { RecievePaymentRequest } from '../models/rfqPaymentConfirmed';
import { QuoteFileUpload } from '../models/quoteFile';
import { RfqFileDownload } from '../models/rfqFileDownload';
import { QuoteFileDownload } from '../models/quoteFileDownload';
import { QuoteHeadings } from '../models/quoteHeadings';
import { quoteReview } from '../models/quoteReview';
import { QuoteFileDetails } from '../models/fileDetails';
import { QuoteAvailability } from '../models/quoteAvailability';


const baseUrl = `${environment.apiUrl}/Quote`;

// USED FOR EDITED RFQs SEEN FLAG
const baseUrl2 = `${environment.apiUrl}`;




@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  constructor(private messageService: MessageService, private http: HttpClient, private toastrNotification: ToastrNotificationService) { }

  getMockQuotes(): Observable<Quote[]> {
    const quotes = of(Quotes);
    return quotes;
  }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  // REMOVE WHEN DONE

  /** GET Quotes from the server */
  //ViewQuotes?SupplierId=1&QuoteState=Open
  getQuotes(supplierId: number, quoteState: string): Observable<View_QuotesRFQs[]> {
    const url = `${baseUrl}/ViewQuotes?SupplierId=${supplierId}&QuoteState=${quoteState}`
    return this.http.get<any>(url)
      .pipe(
        map(quotes => { return quotes.results }),
        tap(_ => this.log('fetched quotes')),
        catchError(this.handleError<View_QuotesRFQs[]>('getQuotes', []))
      );
  }


  /** GET Quotes from the server */
  //ViewQuotes?SupplierId=1&QuoteState=Open

  // NEW QUOTE HEADINGS
  getQuoteHeadings(supplierId: number, quoteState: string): Observable<QuoteHeadings[]> {
    const url = `${baseUrl}/ViewQuoteHeadings?SupplierId=${supplierId}&QuoteState=${quoteState}`
    return this.http.get<any>(url)
      .pipe(
        map(quotes => { return quotes.results }),
        tap(_ => this.log('fetched quotes')),
        catchError(this.handleError<QuoteHeadings[]>('getQuotes', []))
      );
  }

  // NEW GET QUOTES
  getQuoteById(quoteId: number): Observable<View_QuotesRFQs> {
    const url = `${baseUrl}/ViewQuoteById?quoteId=${quoteId}`
    return this.http.get<any>(url)
      .pipe(
        map(quotes => { return quotes.results }),
        tap(_ => this.log('fetched quotes')),
        catchError(this.handleError<View_QuotesRFQs>('getQuotes'))
      );
  }


  /** POST: add a new quote to the server */
  addQuote(quote: QuoteSubmitted): Observable<number> {
    const url = `${baseUrl}/SubmitQuote`;
    return this.http.post<any>(url, quote, this.httpOptions)
      .pipe(
        map(quoteResult => {  return quoteResult.results }),
        tap((newRFQ: number) => this.log(`quote submitted succesfully`)),
        catchError(this.handleError<number>('submit Quote'))
      );
  }

  editQuote(editQuote: QuoteEdit): Observable<QuoteEdit> {
    const url = `${baseUrl}/EditQuote`;
    return this.http.post<any>(url, editQuote, this.httpOptions)
      .pipe(
        map(quote => {  return quote }),
        tap((newRFQ: QuoteEdit) => this.log(`rfq edited succesfully`)),
        catchError(this.handleError<QuoteEdit>('editRFQ'))
      );
  }

  /** POST: COMPLETE QUOTE add a new quote to the server */
  completeQuote(BuyerEvaluation: CompleteQuote): Observable<CompleteQuote> {
    const url = `${baseUrl}/CompleteQuote`;
    return this.http.post<any>(url, BuyerEvaluation, this.httpOptions)
      .pipe(
        map(quote => {  return quote }),
        tap((BuyerEvaluation: CompleteQuote) => this.log(`Quote completed succesfully`)),
        catchError(this.handleError<CompleteQuote>('Complete Quote'))
      );
  }


  /** GET Quotes from the server */
  //QuoteExist?rfqId=1&SupplierId=Open
  QuoteExistCheck(rfqId: number, supplierId: number): Observable<number> {
    const url = `${baseUrl}/QuoteExist?rfqId=${rfqId}&SupplierId=${supplierId}`
    return this.http.get<any>(url)
      .pipe(
        map(check => {  return check.results }),
        tap(_ => this.log('fetched quotes')),
        catchError(this.handleError<number>('getQuoteCheck'))
      );
  }



  /** POST: confirm payment on quote */
  paymentConfirmed(payConfirm: RecievePaymentRequest): Observable<RecievePaymentRequest> {
    const url = `${baseUrl}/ConfirmPaymentQuote`;
    return this.http.post<any>(url, payConfirm, this.httpOptions)
      .pipe(
        map(quoteResult => {  return quoteResult.message }),
        tap((confirmPayment: RecievePaymentRequest) => this.log(`payment confirmed succesfully`)),
        catchError(this.handleError<RecievePaymentRequest>('confirm payment'))
      );
  }

  /** GET Quote Sate the server */
  //QuoteStateCheck?quoteId=1
  QuoteStateCheck(quoteId: number): Observable<string> {
    const url = `${baseUrl}/QuoteStateCheck?quoteId=${quoteId}`
    return this.http.get<any>(url)
      .pipe(
        map(check => { return check.results }),
        tap(_ => this.log('fetched quote state')),
        catchError(this.handleError<string>('QuoteStateCheck'))
      );
  }

  QuoteArchivedCheck(quoteId: number): Observable<boolean> {
    const url = `${baseUrl}/QuoteArchivedCheck?quoteId=${quoteId}`
    return this.http.get<any>(url)
      .pipe(
        map(check => { return check.results }),
        tap(_ => this.log('fetched quote state')),
        catchError(this.handleError<boolean>('QuoteStateCheck'))
      );
  }


  //PaymentReceivedCheck?quoteId=1
  PaymentReceivedCheck(quoteId: number): Observable<boolean> {
    const url = `${baseUrl}/PaymentReceivedCheck?quoteId=${quoteId}`
    return this.http.get<any>(url)
      .pipe(
        map(check => { return check.results }),
        tap(_ => this.log('fetched quote state')),
        catchError(this.handleError<boolean>('QuoteStateCheck'))
      );
  }

  /** POST: upload quote file */
  ulpoadQuoteFile(quoteFile: QuoteFileUpload) {
    const url = `${baseUrl}/Upload/QuoteFile`;
    return this.http.post<any>(url, quoteFile, this.httpOptions)
      .pipe(
        // map(quoteResult => {  return quoteResult.message }),
        tap((confirmPayment: QuoteFileUpload) => this.log(`file uploaded succesfully`)),
        catchError(this.handleError<QuoteFileUpload>('upload quote file'))
      );
  }

  uploadSalesOrderFile(salesOrderFile: QuoteFileUpload) {
    const url = `${baseUrl}/Upload/SalesOrderFile`;
    return this.http.post<any>(url, salesOrderFile, this.httpOptions)
      .pipe(
        // map(quoteResult => {  return quoteResult.message }),
        tap((confirmPayment: QuoteFileUpload) => this.log(`file uploaded succesfully`)),
        catchError(this.handleError<QuoteFileUpload>('upload sales order file'))
      );
  }

  uploadInvoiceFile(InvoiceFile: QuoteFileUpload) {
    const url = `${baseUrl}/Upload/InvoiceFile`;
    return this.http.post<any>(url, InvoiceFile, this.httpOptions)
      .pipe(
        // map(quoteResult => {  return quoteResult.message }),
        tap((confirmPayment: QuoteFileUpload) => this.log(`file uploaded succesfully`)),
        catchError(this.handleError<QuoteFileUpload>('upload invoice order file'))
      );
  }

  uploadTestCertificateFile(productCertification: QuoteFileUpload) {
    const url = `${baseUrl}/Upload/ProductCertificationFile`;
    return this.http.post<any>(url, productCertification, this.httpOptions)
      .pipe(
        // map(quoteResult => {  return quoteResult.message }),
        tap((confirmPayment: QuoteFileUpload) => this.log(`file uploaded succesfully`)),
        catchError(this.handleError<QuoteFileUpload>('upload product certification  file'))
      );
  }


  getSalesOrderFile(quotefileId: number): Observable<QuoteFileDownload> {
    const url = `${baseUrl}/Download/SalesOrderFile/${quotefileId}`
    return this.http.get<any>(url)
      .pipe(
        map(check => { return check.file }),
        tap(_ => this.log('fetched sales order File')),
        catchError(this.handleError<QuoteFileDownload>('getSalesOrderFile'))
      );
  }

  getQuoteFile(quotefileId: number, userId: string, role: string): Observable<QuoteFileDownload> {
    const url = `${baseUrl}/Download/QuoteFile/${quotefileId}/${userId}/${role}`
    return this.http.get<any>(url)
      .pipe(
        map(check => { return check.file }),
        tap(_ => this.log('fetched quote File')),
        catchError(this.handleError<QuoteFileDownload>('getQuoteFile'))
      );
  }

  getInvoiceFile(quotefileId: number): Observable<QuoteFileDownload> {
    const url = `${baseUrl}/Download/InvoiceFile/${quotefileId}`
    return this.http.get<any>(url)
      .pipe(
        map(check => { return check.file }),
        tap(_ => this.log('fetched Invoice File')),
        catchError(this.handleError<QuoteFileDownload>('getInvoiceFile'))
      );
  }

  getProductCertificationFile(quotefileId: number): Observable<QuoteFileDownload> {
    const url = `${baseUrl}/Download/ProductCertificationFile/${quotefileId}`
    return this.http.get<any>(url)
      .pipe(
        map(check => { return check.file }),
        tap(_ => this.log('fetched Invoice File')),
        catchError(this.handleError<QuoteFileDownload>('getInvoiceFile'))
      );
  }

  getRfqFiles(rfqId: number): Observable<RfqFileDownload[]> {
    const url = `${baseUrl}/Download/RfqFile/${rfqId}`
    return this.http.get<any>(url)
      .pipe(
        map(check => { return check.files }),
        tap(_ => this.log('fetched rfq Files')),
        catchError(this.handleError<RfqFileDownload[]>('getRfqFiles'))
      );
  }

  deleteQuoteFile(quoteFileId: number) {
    const url = `${baseUrl}/Delete/QuoteFile/${quoteFileId}`
    return this.http.delete<any>(url)
      .pipe(
        tap(_ => this.log('deleted quotew file')),
        catchError(this.handleError('deleteQuoteFile'))
      );
  }

  deleteSalesOrderFile(quoteFileId: number) {
    const url = `${baseUrl}/Delete/SalesOrderFile/${quoteFileId}`
    return this.http.delete<any>(url)
      .pipe(
        tap(_ => this.log('deleted sales order file')),
        catchError(this.handleError('deleteSalesOrderFile'))
      );

  }

  deleteInvoiceFile(quoteFileId: number) {
    const url = `${baseUrl}/Delete/InvoiceFile/${quoteFileId}`
    return this.http.delete<any>(url)
      .pipe(
        tap(_ => this.log('deleted Invoice file')),
        catchError(this.handleError('deleteInvoiceFile'))
      );
  }

  deleteProductCertificationFile(quoteFileId: number) {
    const url = `${baseUrl}/Delete/ProductCertificationFile/${quoteFileId}`
    return this.http.delete<any>(url)
      .pipe(
        tap(_ => this.log('deleted product certification file')),
        catchError(this.handleError('deleteProductCertificationFile'))
      );
  }

  getQuoteFileDetails(quoteId: number, fileType: string): Observable<QuoteFileDetails> {
    const url = `${baseUrl}/QuoteFileDetails/${quoteId}/${fileType}`;
    return this.http.get<any>(url)
      .pipe(
        map(quoteFileDetails => { return quoteFileDetails.results }),
        tap(_ => this.log(`fetched Quote File Details`)),
        catchError(this.handleError<QuoteFileDetails>(`getQuoteFileDetails`))
      );
  }


  // RFQ EDITED IF THE SUPPLIER HAS SEEN CHECK
  getRFQseenCheck(rfqId: number, userId: string): Observable<string> {
    const url = `${baseUrl2}/EditedSeen/RfqEditedSeenCheck?RfqId=${rfqId}&userId=${userId}`


    return this.http.get<any>(url)
      .pipe(
        map(seencheck => { return seencheck.results }),
        tap(_ => this.log('fetched RFQ seen check')),
        catchError(this.handleError<string>('getRFQseenCheck'))
      );
  }



  // SET RFQ EDITED TO SEEN
  getRFQsetSeen(rfqId: number, userId: string): Observable<string> {
    const url = `${baseUrl2}/EditedSeen/SetEditedSeenCheck?RfqId=${rfqId}&userId=${userId}`
    return this.http.get<any>(url)
      .pipe(
        map(setseen => { return setseen.results }),
        tap(_ => this.log('Set Edited RFQ to seen')),
        catchError(this.handleError<string>('getRFQsetSeen'))
      );
  }


  /** GET QUOTE REVIEWS  */

  getQuoteReview(quoteId: number): Observable<quoteReview> {
    const url = `${baseUrl}/ViewQuoteReview?quoteId=${quoteId}`
    return this.http.get<any>(url)
      .pipe(
        map(quotereview => { return quotereview.results }),
        tap(_ => this.log('fetched Quote Review')),
        catchError(this.handleError<quoteReview>('GetQuoteReview'))
      );
  }

  getQuoteAvailability(quoteId: number): Observable<QuoteAvailability> {
    const url = `${baseUrl}/QuoteAvailabilityCheck/${quoteId}`
    return this.http.get<any>(url)
      .pipe(
        map(check => { return check.results }),
        tap(_ => this.log('fetched quote availability')),
        catchError(this.handleError<QuoteAvailability>('getQuoteAvailability'))
      );
  }

  withdrawQuote(quoteId: number): Observable<boolean> {
    const url = `${baseUrl}/WithdrawQuote/${quoteId}`
    return this.http.delete<any>(url)
      .pipe(
        map(check => { return check.succeeded }),
        tap(_ => this.log('Withdraw Quote')),
        catchError(this.handleError<boolean>('withdrawQuote'))
      );
  }

  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      this.toastrNotification.error(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      throw error;
    };
  }



  private log(message: string) {
    this.messageService.add(`RFQService: ${message}`);
  }
}
