<ng-container  *ngFor="let item of notifications">
    <div style = "z-index: 7000;" class = " toast-container position-fixed bottom-0 end-0 p-3" >  
        <div  class = "{{cssClass(item)}}"  >  
            <div class="toast-header {{cssClass1(item) }} "><i class="{{notifIcon(item)}}"></i><strong class="me-auto"> {{notifHead(item)}}</strong>
                <button (click)="removeNotification(item)" class="btn-close ms-2 mb-1 close" ></button></div>
            <div class="toast-body" role="alert">
                <div class="toast-message">  
                    {{item.message}}   
                </div> 
            </div>
        </div>    
    </div>  
</ng-container>
