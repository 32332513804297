import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, AfterViewChecked } from '@angular/core';
import { RouterOutlet, Router } from '@angular/router';
import { slideInAnimation } from './animations';
import { AuthService } from './auth/auth.service';
import { Account } from './models/account';
import { Role } from './models/role';
import { Notification, NotifType } from './models/notification';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { NotificationsService } from './services/notifications.service';
import { NavbarService } from './services/navbar.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [slideInAnimation]
})
export class AppComponent implements AfterViewChecked {


  // VARIABL NEED FOR THE COLOUR OF THE SELECTED NAV ITEM
  NavRole: string = '';
  buyerSelected: boolean;
  supplierSelected: boolean;
  title = 'Tradely';
  Role = Role;
  account: Account;
  showNotification: boolean;
  notifCount: number = 0;
  notifications: Notification[];
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;



  @ViewChild("autoLogoutModal") autoLogoutModal: ElementRef;

  constructor(public navbarService: NavbarService, public authService: AuthService, private router: Router, private notificationsService: NotificationsService, private idle: Idle, private keepalive: Keepalive, public cdr: ChangeDetectorRef,
  ) {
    this.authService.account.subscribe(x =>
      this.account = x);

    console.log(this.account);

    /// sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(900);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(30);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.watch();

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      //this.childModal.hide();
      this.autoLogoutModal.nativeElement.click();
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
      this.autoLogout();
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      console.log(this.idleState);
      //this.childModal.show();
      this.autoLogoutModal.nativeElement.click();
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will be logged out in ' + countdown + ' seconds.'
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.authService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch()
        this.timedOut = false;
      } else {
        idle.stop();
      }
    })

  }

  // getNotifications(username){
  //   this.notificationsService.getNotifications(username).subscribe( notifs =>{
  //   this.notifications = notifs;
  //   for (let notif of notifs) {
  //     if (notif.seen == false) {
  //       this.notifCount++;
  //     }
  //   }
  //   });

  //     }

  reset() {
    //xthis.idleState = 'Started.';
    this.timedOut = false;
  }

  hideChildModal(): void {
    this.autoLogoutModal.nativeElement.click();
  }

  stay() {
    this.autoLogoutModal.nativeElement.click();
    this.reset();
  }

  openNotification(state: boolean) {
    this.showNotification = state;
  }

  getAnimationData(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  logout() {
    this.authService.logout();
  }

  autoLogout() {
    this.authService.logout();
    this.autoLogoutModal.nativeElement.click();
    location.reload();
  }

  unseenNotifCounter() {

  }

  ngOnInit(): void {

    // this.navbarService.currentloggedInCheck.pipe(first()).subscribe(l => {
    //   if (l) {
    //     this.authService.account.subscribe(x =>
    //       this.account = x);
    //     this.CheckAccountRole();
    //   }

    // })





    // NEED FOR CHECKING INITIAL ROLE


  }

  //CHECK ACCOUNT ROLE AFTER LOGIN
  ngAfterViewChecked(): void {









  }


  ngAfterViewInit(): void {




  }

  navToggleBuyer() {
    this.NavRole = 'Buyer';
    this.buyerSelected = true;
    this.supplierSelected = false;



  }

  navToggleSupplier() {

    this.NavRole = 'Supplier';
    this.supplierSelected = true;
    this.buyerSelected = false;


  }

//   CheckAccountRole() {

//     if (this.account.account.buyerId != null && this.account.account.supplierId != null && this.account.account.roles.includes(Role.Admin)) {
//       this.NavRole = 'Buyer';
//       this.buyerSelected = true;
//       this.supplierSelected = false;
//     }else if (this.account.account.buyerId == null && this.account.account.supplierId != null && this.account.account.roles.includes(Role.Supplier)) {
//       this.NavRole = 'Supplier';
//       this.supplierSelected = true;
//       this.buyerSelected = false;
//     }else if (this.account.account.buyerId != null && this.account.account.supplierId == null && this.account.account.roles.includes(Role.Buyer)) {
//       this.NavRole = 'Buyer';
//       this.buyerSelected = true;
//       this.supplierSelected = false;
//     }
//     console.log(this.supplierSelected);
//    }

}


