import { Component, OnInit } from '@angular/core';  
  
import { ToastrNotifications, ToastrNotificationType } from '../models/toastr-notification';  
import { ToastrNotificationService } from '../services/toastr-notification.service';  
  
@Component({  
    selector: 'toastr-notification',  
    templateUrl: 'toastr-notification.component.html',  
    styleUrls: ['./toastr-notification.component.css']  
})  
  
export class ToastrNotificationComponent {  
    notifications: ToastrNotifications[] = [];  
  
    constructor(public _notificationService: ToastrNotificationService) { }  
  
    ngOnInit() {   
        this._notificationService.getAlert().subscribe((alert: ToastrNotifications) => {  
            this.notifications = [];  
            if (!alert) {  
                this.notifications = [];  
                return;  
            }  

            this.notifications.push(alert); 

            setTimeout(() => {  
                this.notifications = this.notifications.filter(x => x !== alert);  
            }, 4000);  
        });  

        
    }  
  
    removeNotification(notification: ToastrNotifications) {  
        this.notifications = this.notifications.filter(x => x !== notification);  
    }  
  
    /**Set css class for Alert -- Called from alert component**/      
    cssClass(notification: ToastrNotifications) {  
        if (!notification) {  
            return 'no notification';  
        }  
        switch (notification.type) {  
            case ToastrNotificationType.Success:  
                return 'toast fade sucesstoast show';  
            case ToastrNotificationType.Error:  
                return 'toast fade errortoast show';  
            case ToastrNotificationType.Info:  
                return 'toast fade infotoast show';  
            case ToastrNotificationType.Warning:  
                return 'toast fade warningtoast show';  
        }  
    }  
     cssClass1(notification: ToastrNotifications) {  
        if (!notification) {  
            return 'no notification';  
       }  
        switch (notification.type) {  
            case ToastrNotificationType.Success:  
             return 'suctoastheader';  
            case ToastrNotificationType.Error:  
                return 'errortoasthead';  
            case ToastrNotificationType.Info:  
                return 'infotoasthead';  
             case ToastrNotificationType.Warning:  
                 return 'warningtoasthead';  
        }  
    }  

    notifHead(notification: ToastrNotifications) {  
        if (!notification) {  
            return 'no notification';  
       }  
        switch (notification.type) {  
            case ToastrNotificationType.Success:  
             return 'Success';  
            case ToastrNotificationType.Error:  
                return 'Error';  
            case ToastrNotificationType.Info:  
                return 'Information';  
             case ToastrNotificationType.Warning:  
                 return 'Warning';  
        }  
    }  

    notifIcon(notification: ToastrNotifications) {  
        if (!notification) {  
            return 'no notification';  
       }  
        switch (notification.type) {  
            case ToastrNotificationType.Success:  
             return 'fa fa-check p-1 ';  
            case ToastrNotificationType.Error:  
                return 'fa fa-remove p-1 ';  
            case ToastrNotificationType.Info:  
                return 'Information';  
             case ToastrNotificationType.Warning:  
                 return 'fa fa-warning p-1 ';  
        }  
    }  
}  