import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef, AfterViewChecked, ViewChild, ElementRef, OnDestroy, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { CompletedQuotesComponent } from '../completed-quotes/completed-quotes.component';
import { Account } from '../models/account';
import { CompleteQuote } from '../models/completeQuote';
import { QuoteHeaddisplay } from '../models/header';
import { ReloadViewRfqs } from '../models/reloadViewRfqs';

import { RFQ } from '../models/rfq';
import { View_Quotes, View_QuotesRFQs } from '../models/view_quote';
import { QuoteService } from '../services/quote.service';
import { SortCriteria } from '../pipes/sort-asc-dsc.pipe';
import { ViewQuoteServiceService } from '../services/view-quote-service.service';
import { ChatDetails, View_Chat } from '../models/viewChat';
import { ChatService } from 'src/app/services/chat.service';
import { Alert } from '../models/alert';
import { RfqService } from '../services/rfq.service';
import { QuoteFileUpload } from '../models/quoteFile';
import { Observable, of, Subscription, interval, fromEventPattern, pipe } from 'rxjs';
import { switchMap, finalize, first } from 'rxjs/operators';
import { ToastrNotificationService } from '../services/toastr-notification.service';
import { QuoteHeadings } from '../models/quoteHeadings';
import { FileDownloadedService } from '../services/file-downloaded.service';
import { BuyerProfileComponent } from '../buyer-profile/buyer-profile.component';
import { RecievePaymentRequest } from '../models/rfqPaymentConfirmed';
import { quoteReview } from '../models/quoteReview';
import { ThrowStmt } from '@angular/compiler';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-in-progress-quotes',
  templateUrl: './in-progress-quotes.component.html',
  styleUrls: ['./in-progress-quotes.component.css']
})
export class InProgressQuotesComponent implements OnInit, OnDestroy, OnChanges {

  public criteria: SortCriteria;

  @Input() reloadInProgressQuoteTab: boolean;

  @Input() inProgressQuoteId: number;

  @Input() state: string;

  @Input() inProgressQuoteNav: string;

  //NEEDED FOR CREATING Q&A QUESTIONS AND REPLIES
  isBuyer: boolean = false;
  isSupplier: boolean = true;
  supplierId: number;
  rfqStatusPC: string = "In Progress";

  @Output() quoteEvent = new EventEmitter<number>();
  @Output() openChatCond = new EventEmitter<boolean>();
  @Input() openChat: boolean;



  @Output() changeClosedTab = new EventEmitter<boolean>();

  @Output() changeInProgressTab = new EventEmitter<boolean>();

  @Output() quoteStatus = new EventEmitter<boolean>();

  sendRfqStatus(value: boolean) {
    this.quoteStatus.emit(value);
  }

  tabToInClosed(value: boolean) {
    this.changeClosedTab.emit(value);
  }

  tabToInProgress(value: boolean) {
    this.changeInProgressTab.emit(value);
  }


  //needed for date calculations

  //NEEDED FOR GET QUESTIONS AND ANSWERS
  getQA: boolean = false;

  //NEEDED FOR CLEAR BUYER RATING FORM BUTTON 
  resetRatingForm: number = null;

  //USED IN TEMPLATE TO DISPLAY  MESSAGES RED BLIP 
  unreadMessages: boolean;

  //NEEDED TO CHECK UNREAD MESSAGES ARRAY
  UnreadMsgsCount: number;


  //NEEDED FOR SELECTED RFQ ON SIDEBAR 
  SideSelectedRFQ: View_QuotesRFQs;
  selectedRfqId: number;

  // NEEDED FOR MODAL HEADER COLOR
  modalheader: string;

  //GETS ROLE VALUES FROM VIEW QUOTE COMPONENT
  @Input() Role: string;

  // NEEDED FOR CHAT TO DISPLAY QUOTE ID AND CHAT DETAILS

  sentQID: number;
  chatHistory: View_Chat;
  details: ChatDetails;

  rfqIndex: number;
  form: FormGroup;
  ratingForm: FormGroup;

  updatedInProgress: ReloadViewRfqs = { reloadCondition: null, reloadRfqId: null };

  nextmodal: boolean = false;
  selectedRFQ: View_QuotesRFQs;
  selectedQuote: View_Quotes;

  RFQHeadings: string[] = [];

  sortbyHeadings: QuoteHeaddisplay[] = [];
  sortbyHeadingsUpdate: QuoteHeaddisplay[] = [];
  singleSortHeadings: QuoteHeaddisplay = { rfqHeadName: null, rfq: null, id: null };

  headingId: number;

  check: boolean = false;

  searchedKeyword: string;
  searchrfq: string;

  //needed for Complete Quotes form
  correctMaterialGradeCond: boolean = null;
  correctQuantityOfPartsCond: boolean = null;
  BuyerCommunicationCond: boolean = null;
  buyerCollectedOnTime: boolean = null;
  BuyerRating: number = null;
  checklistValidCond: boolean;
  dataLoading: boolean = false;

  reloadingSelectedRfq: boolean;
  loadingRfq: boolean = false;


  BuyerSurvey: CompleteQuote = {
    rfqId: null, quoteId: null, correctMaterialGrade: null, correctMaterialGradeReason: null,
    correctQuantity: null, correctQuantityReason: null, goodCommunication: null, goodCommunicationReason: null,
    onTimeCollection: null, onTimeCollectionReason: null, actualDeliveryDate: null, BuyerRating: null, deliveryRequirements: null
  };

  rfqUsed: boolean = false;

  private subscriptionIPQ: Subscription;


  // QUOTE HEADINGS OBJECT FOR THE SIDENAV
  viewInProgressQuotes: QuoteHeadings[] = [];
  viewInProgressQuotesUpdate: QuoteHeadings[] = [];

  selectedQuoteHeading: QuoteHeadings;

  account: Account;

  constructor(private formBuilder: FormBuilder,
    private quoteService: QuoteService,
    private reloader: ViewQuoteServiceService,
    public authService: AuthService,
    public cdr: ChangeDetectorRef,
    private rfqService: RfqService,
    private chatService: ChatService,
    private toastrNotification: ToastrNotificationService,
    private router: Router,
    private fileDownloadedService: FileDownloadedService,
    private route: ActivatedRoute) {
    this.criteria = {
      property: 'supplierServices',
      descending: true
    };
    this.authService.account.subscribe(x => {
      this.account = x
      this.supplierId = this.account.account.supplierId;
    });
  }

  minDate: Date;

  ngOnChanges() {

    if (this.state == "InProgress" && ((this.viewInProgressQuotes.length == 0 || this.inProgressQuoteId == 0) || this.inProgressQuoteNav == 'true')) {
      this.getInProgressQuotes(this.inProgressQuoteId);

    } else if (this.state == "InProgress" && this.viewInProgressQuotes.length > 0 && this.inProgressQuoteNav == 'false') {
      this.onSelectRFQ(this.inProgressQuoteId);
    }
  }

  ngOnInit(): void {

    this.reloadingSelectedRfq = false;

    //SETS THE INITIAL VALUE OF BUYER RATING TO 0
    this.BuyerRating = 0;


    this.minDate = new Date()

    this.modalheader = 'blue';

    this.cdr.detectChanges();

    this.form = this.formBuilder.group({

      correctMaterialGradeYes: [false, Validators.required],
      correctMaterialGradeNo: [false, Validators.required],
      correctMaterialGradeReason: ["", Validators.required],

      correctQuantityYes: [false, Validators.required],
      correctQuantityNo: [false, Validators.required],
      correctQuantityReason: ["", Validators.required],

      buyerCommunicateYes: [null, Validators.required],
      buyerCommunicateNo: [null, Validators.required],
      buyerCommunicateReason: ["", Validators.required],

      buyerCollectionOnTimeYes: [null, Validators.required],
      buyerCollectionOnTimeNo: [null, Validators.required],
      buyerCollectionOnTimeReason: ["", Validators.required],

      actualDeliveryDate: [null, Validators.required],

    });

    this.ratingForm = this.formBuilder.group({

      buyerRating1: [false,],
      buyerRating2: [false,],
      buyerRating3: [false,],
      buyerRating4: [false,],
      buyerRating5: [false,],

    });

    // this.headingId = 1;
    // this.singleSortHeadings.rfqHeadName = "Multiple Services";
    // this.singleSortHeadings.rfq = [];
    // this.sortbyHeadings.push(this.singleSortHeadings);
    // this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
    // this.headingId++;

    // this.reloader.notifNavQuoteAccepted.subscribe(notifNavQuoteAcceptedCond => {
    //   if (notifNavQuoteAcceptedCond.reloadCondition) {
  
    //     this.NavFromNotification(notifNavQuoteAcceptedCond.reloadRfqId);
    //     this.tabToInProgress(true);
    //   } else {
  

    //   }
    // });

    this.subscriptionIPQ = interval(300000)
      .subscribe(x => { this.periodicReload(); });

    // this.route.queryParamMap.subscribe(params => {

    //   this.inProgressQuoteNav = params.get('nav');

    // });

    //SETS RFQ SEARCH FORM VALUES TO FALSE
    this.RFQsearch = this.formBuilder.group({

      complete: [false],
      rawmat: [false],
      cod: [false],
      cbd: [false],
      thirtyDays: [false],
      sixtyDays: [false],
      paymentOther: [false],
      delivery: [false],
      collection: [false],
      boxed: [false],
      pallets: [false],
      strapped: [false],
      loose: [false],
      packagingOther: [false],
      testCertificationYes: [false],
      testCertificationNo: [false]

    });
  }

  ngOnDestroy() {
    this.subscriptionIPQ.unsubscribe();
  }

  togglePeriodicReload(cond) {
    if (cond) {
      this.rfqUsed = true;

    } else if (!cond) {
      this.rfqUsed = false;

    }
  }

  togglePubChat(cond) {
    if (cond) {
      // this.rfqUsed = true;
      this.getQA = true;
    } else if (!cond) {
      //  this.rfqUsed = false;
      this.getQA = false;
    }
  }

  periodicReload() {
    if (this.rfqUsed == false && this.reloadInProgressQuoteTab) {
 
      this.sortbyHeadingsUpdate = [];
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

      this.headingId = 1;
      this.singleSortHeadings.rfqHeadName = "Multiple Services";
      this.singleSortHeadings.rfq = [];
      this.singleSortHeadings.id = this.headingId;
      this.sortbyHeadingsUpdate.push(this.singleSortHeadings);
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
      this.headingId++;

      this.getInProgresRFQSAfterUpdate();
    }
  }

  getInProgresRFQSAfterUpdate() {

    this.reloadingSelectedRfq = true;

    this.quoteService.getQuoteHeadings(this.account.account.supplierId, 'In Progress')
      .subscribe(quotes => {

        if (quotes.length > 0) {
          
          this.viewInProgressQuotesUpdate = quotes;

          //IF THE FILTERS ARE USED ON PERIODIC RELAOD , THE FILTERED RFQ ARRAY MUST BE UPDATED
          if (this.filtersUsed) {
            this.filterUpdateRfqs(quotes);
          }

          //SETS SELECTED RFQs ON SIDEBAR TO FALSE
          for (let rfq of quotes) {
            rfq.SideSelectedRFQ = false;
          }

          if (this.viewInProgressQuotes.length > 0) {
            var newRfqIds: number[] = [];
            for (let newRfq of this.viewInProgressQuotesUpdate) {
              newRfqIds.push(newRfq.rfqId)
            }

            if (newRfqIds.indexOf(this.selectedRFQ.rfqId) != -1) {
              for (const { index, value } of this.viewInProgressQuotesUpdate.map((value, index) => ({ index, value }))) {
                if (value.rfqId == this.selectedRFQ.rfqId) {
                  this.rfqIndex = index;
                  this.onSelectRFQ(quotes[index].quoteDetails.quoteId);
                }
              }
              this.sortRFQHeadArrayUpdate();
              this.replaceRfqsOnUpdate();
            } else {
              this.sortRFQHeadArrayUpdate();
              this.replaceRfqsOnUpdate();
              this.onSelectRFQ(this.sortbyHeadings[0].rfq[0].quoteDetails.quoteId);
            }

            var oldRfqIds: number[] = [];
            for (let oldRfq of this.viewInProgressQuotes) {
              oldRfqIds.push(oldRfq.rfqId)
            }



            for (let rfq of this.viewInProgressQuotesUpdate) {
              if (oldRfqIds.indexOf(rfq.rfqId) == -1) {
                this.viewInProgressQuotes.push(rfq);
              }
            }

            for (const { index, value } of this.viewInProgressQuotes.map((value, index) => ({ index, value }))) {
              if (newRfqIds.indexOf(value.rfqId) == -1) {
                this.viewInProgressQuotes.splice(index, 1);
              }
            }
          } else {

            for (let rfq of this.viewInProgressQuotesUpdate) {
              this.viewInProgressQuotes.push(rfq);
            }
            this.sortbyHeadings = [];
            this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

            this.headingId = 1;
            this.singleSortHeadings.rfqHeadName = "Multiple Services";
            this.singleSortHeadings.rfq = [];
            this.sortbyHeadings.push(this.singleSortHeadings);
            this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
            this.headingId++;

            for (let rfq of this.viewInProgressQuotes) {

              rfq.SideSelectedRFQ = false;
            }
            //

            this.sortByHeadings();
            this.onSelectRFQ(this.sortbyHeadings[0].rfq[0].quoteDetails.quoteId);

          }

        } else {

          this.viewInProgressQuotes = [];
          this.selectedRFQ = null;
          this.selectedQuote = null;
        }
      });
  }

  replaceRfqsOnUpdate() {

    var newHeadings: string[] = [];
    var oldHeadings: string[] = [];

    for (let heading of this.sortbyHeadings) {
      oldHeadings.push(heading.rfqHeadName)
      for (let newHead of this.sortbyHeadingsUpdate) {
        if (heading.rfqHeadName == newHead.rfqHeadName) {
          heading.rfq = newHead.rfq;
          heading.openQuotesCounter = newHead.openQuotesCounter;
        }
      }
    }

    for (let newHeading of this.sortbyHeadingsUpdate) {
      newHeadings.push(newHeading.rfqHeadName);
    }

    //if (this.sortbyHeadings.length != this.sortbyHeadingsUpdate.length) {
    // if (this.sortbyHeadings.length < this.sortbyHeadingsUpdate.length) {

    for (let newHead of this.sortbyHeadingsUpdate) {
      if (oldHeadings.indexOf(newHead.rfqHeadName) == -1) {
        this.sortbyHeadings.push(newHead);
      }
    }

    // } else {
    for (const { index, value } of this.sortbyHeadings.map((value, index) => ({ index, value }))) {
      if (newHeadings.indexOf(value.rfqHeadName) == -1) {
        this.sortbyHeadings.splice(index, 1);
      }
      // }
      //  }
    }
  }

  //Used for periodic updating of RFQS
  sortRFQHeadArrayUpdate() {

    for (let indexrfq of this.viewInProgressQuotesUpdate) {
      for (let service of indexrfq.rfqServicesRequired) {
        this.check = false;
        if (indexrfq.rfqServicesRequired.length == 1) {
          for (let name of this.sortbyHeadingsUpdate) {
            if (service == name.rfqHeadName) {
              this.check = true;
            }
          }
          if (this.check == false) {

            this.singleSortHeadings.rfqHeadName = service;
            this.singleSortHeadings.rfq = [];
            this.singleSortHeadings.id = this.headingId;
            this.sortbyHeadingsUpdate.push(this.singleSortHeadings);
            this.headingId++;
            this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
          }
        }
      }
      if (indexrfq.rfqServicesRequired.length > 1) {
        this.sortbyHeadingsUpdate[0].rfq.push(indexrfq);

      }

    }



    this.populateRFQHeadArrayUpdate();
    this.getClosingTime(Date);

  }


  populateRFQHeadArrayUpdate(): void {
    for (let indexrfq of this.viewInProgressQuotesUpdate) {
      for (let service of indexrfq.rfqServicesRequired) {
        if (indexrfq.rfqServicesRequired.length == 1) {
          for (let val of this.sortbyHeadingsUpdate) {
            if (val.rfqHeadName == service) {
              val.rfq.push(indexrfq)

            }
          }
        }

      }
    }
    if (this.sortbyHeadingsUpdate[0].rfq.length == 0) {
      this.sortbyHeadingsUpdate.splice(0, 1);
    }

    // LOGIC FOR SUPPLIER USER ACTIONS 
    for (let heading of this.sortbyHeadingsUpdate) {
      for (let rfq of heading.rfq) {
        heading.openQuotesCounter = 0;

        if (this.blipCondition(rfq)) {
          heading.openQuotesCounter = 1;
          break;
        }
      }
    }

  }

  getInProgressQuotesNav(rfqId) {

    this.dataLoading = true;

    this.quoteService.getQuoteHeadings(this.account.account.supplierId, 'In Progress').pipe(
      finalize(() => this.dataLoading = false)
    ).subscribe(viewquotes => {
      this.viewInProgressQuotes = viewquotes;
      for (const { index, value } of viewquotes.map((value, index) => ({ index, value }))) {
        if (value.rfqId == rfqId) {
          this.onSelectRFQ(viewquotes[index].quoteDetails.quoteId);
        }
      }
      this.sortByHeadings();

    })
  }

  NavFromNotification(rfqId) {

    this.viewInProgressQuotes = [];
    this.sortbyHeadings = [];
    this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

    this.headingId = 1;
    this.singleSortHeadings.rfqHeadName = "Multiple Services";
    this.singleSortHeadings.rfq = [];
    this.sortbyHeadings.push(this.singleSortHeadings);
    this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
    this.headingId++;

    this.getInProgressQuotesNav(rfqId);
  }

  getInProgressQuotes(selectedQuoteId) {

    this.sortbyHeadings = [];
    this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
    this.selectedQuote = null;
    this.selectedRFQ = null;
    this.viewInProgressQuotes = [];

    this.dataLoading = true;

    this.quoteService.getQuoteHeadings(this.account.account.supplierId, 'In Progress').pipe(
      finalize(() => this.dataLoading = false)
    ).subscribe(viewquotes => {

      if (viewquotes.length > 0) {

        this.headingId = 1;
        this.singleSortHeadings.rfqHeadName = "Multiple Services";
        this.singleSortHeadings.rfq = [];
        this.sortbyHeadings.push(this.singleSortHeadings);
        this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
        this.headingId++;

        this.viewInProgressQuotes = viewquotes;

        //SETS SELECTED RFQs ON SIDEBAR TO FALSE
        for (let rfq of viewquotes) {
          rfq.SideSelectedRFQ = false;
        }

        this.sortByHeadings();

        if (selectedQuoteId == 0) {
          this.router.navigate(['supplier-home/view-quote', 'InProgress', this.sortbyHeadings[0].rfq[0].quoteDetails.quoteId, 'false']);
        } else {
          this.onSelectRFQ(selectedQuoteId);
        }

        //NEEDED FOR CHAT
        //this.sentQID = this.selectedQuote.quoteId;

        this.cdr.detectChanges();
      }
    });
  }

  reloadQuatesAfterCompleteStay(rfqId) {

    this.togglePeriodicReload(false);

    this.quoteService.QuoteStateCheck(this.selectedRFQ.quoteDetails.quoteId).subscribe(x => {

      if (x == "Closed") {

        for (const { index, value } of this.viewInProgressQuotes.map((value, index) => ({ index, value }))) {
          if (value.rfqId == rfqId) {
            this.viewInProgressQuotes.splice(index, 1);
          }
        }

        for (let heading of this.sortbyHeadings) {
          for (const { index, value } of heading.rfq.map((value, index) => ({ index, value }))) {
            if (value.rfqId == rfqId) {
              heading.rfq.splice(index, 1);
            }
          }
        }

        if (this.sortbyHeadings[0].rfq.length == 0) {
          this.sortbyHeadings.splice(0, 1);
        }

        if (this.viewInProgressQuotes.length > 0) {
          this.router.navigate(['supplier-home/view-quote', 'InProgress', this.sortbyHeadings[0].rfq[0].quoteDetails.quoteId, 'false']);
        }
      } else if (x == "In Progress") {
        this.router.navigate(['supplier-home/view-quote', 'InProgress', this.selectedRFQ.quoteDetails.quoteId, 'true']);
      }

    });
  }



  reloadQuotesAfterComplete() {

    this.togglePeriodicReload(false);

    this.quoteService.QuoteStateCheck(this.selectedRFQ.quoteDetails.quoteId).subscribe(x => {
      if (x == "Closed") {
        this.router.navigate(['supplier-home/view-quote', 'Closed', this.selectedRFQ.quoteDetails.quoteId, 'true'], {
          queryParams: { archived: this.selectedRFQ.quoteDetails.archived }
        });
      } else if (x == "In Progress") {
        this.router.navigate(['supplier-home/view-quote', 'InProgress', this.selectedRFQ.quoteDetails.quoteId, 'true']);
      }

    });


  }

  sortByHeadings(): void {

    for (let indexrfq of this.viewInProgressQuotes) {
      for (let service of indexrfq.rfqServicesRequired) {
        this.check = false;
        if (indexrfq.rfqServicesRequired.length == 1) {
          for (let name of this.sortbyHeadings) {
            if (service == name.rfqHeadName) {
              this.check = true;
            }
          }
          if (this.check == false) {

            this.singleSortHeadings.rfqHeadName = service;
            this.singleSortHeadings.rfq = [];
            this.singleSortHeadings.id = this.headingId;
            this.sortbyHeadings.push(this.singleSortHeadings);
            this.headingId++;
            this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
          }
        }
      }
      if (indexrfq.rfqServicesRequired.length > 1) {
        this.sortbyHeadings[0].rfq.push(indexrfq);

      }
    }

    this.populateRFQHeadArray();
   
  }

  blipCondition(rfq: QuoteHeadings) {

    if (rfq.quoteDetails.salesQuoteFileId == null) {
      return true;
    } else if (rfq.paymentRequirement == 'CBD') {

      if (rfq.purchaseOrderFileId != null && rfq.quoteDetails.invoiceQuoteFileId == null) {
        return true;
      } else if (rfq.quoteDetails.paymentRefecence != null && rfq.quoteDetails.paymentReceived == false) {
        return true;
      } else if ((rfq.quoteDetails.supplierCollectionMade == false && rfq.quoteDetails.supplierDeliveryMade == false) && rfq.quoteDetails.paymentReceived == true) {
        return true;
      } else {
        return false;
      }
    } else if (rfq.paymentRequirement != 'CBD') {
      if ((rfq.quoteDetails.supplierDeliveryMade == false && rfq.quoteDetails.supplierCollectionMade == false) && rfq.purchaseOrderFileId != null) {
        return true;
      } else {
        return false;
      }
    }
  }

  populateRFQHeadArray(): void {
    for (let indexrfq of this.viewInProgressQuotes) {
      for (let service of indexrfq.rfqServicesRequired) {
        if (indexrfq.rfqServicesRequired.length == 1) {
          for (let val of this.sortbyHeadings) {
            if (val.rfqHeadName == service) {
              val.rfq.push(indexrfq)

            }
          }
        }

      }
    }
    if (this.sortbyHeadings[0].rfq.length == 0) {
      this.sortbyHeadings.splice(0, 1);
    }
    // LOGIC FOR SUPPLIER USER ACTIONS 
    for (let heading of this.sortbyHeadings) {
      for (let rfq of heading.rfq) {
        heading.openQuotesCounter = 0;

        if (this.blipCondition(rfq)) {
          heading.openQuotesCounter = 1;
          break;
        }
      }
    }

     // sort Rfq Heading in Asc order using String.prototype.localCompare()
     this.sortbyHeadings.sort((a, b) =>
     a.rfqHeadName.localeCompare(b.rfqHeadName)
   );

   // Re-inserts Multiple Services heading to first position in array
   var index = this.sortbyHeadings.findIndex(x => x.rfqHeadName == "Multiple Services");
   if (index != -1) {
     var head = this.sortbyHeadings.filter(x => x.rfqHeadName == "Multiple Services")[0];
     this.sortbyHeadings.splice(index, 1);
     this.sortbyHeadings.splice(0, 0, head);
   }

  }

  triggerSelectRfqLoading() {
    this.reloadingSelectedRfq = false;
  }

  loadingRfqSpinnerId: number;

  onSelectRFQNav(quote: QuoteHeadings): void {
    this.loadingRfqSpinnerId = quote.rfqId;
    this.router.navigate(['supplier-home/view-quote', 'InProgress', quote.quoteDetails.quoteId, 'false']);
  }

  onSelectRFQ(quoteId: number): void {

    if (!this.reloadingSelectedRfq) {
      this.loadingRfq = true;
    }


    if (this.viewInProgressQuotes.length > 0) {
      this.quoteService.getQuoteById(quoteId).pipe(first())
        .subscribe({
          // GETS RFQ ID        
          next: (response: any) => {
            if (response.quoteDetails.state == "In Progress") {

        
              this.router.navigate(['supplier-home/view-quote', 'InProgress', response.quoteDetails.quoteId, 'false']);

              this.selectedQuoteHeading = this.viewInProgressQuotes.filter(x => x.quoteDetails.quoteId == quoteId)[0];
              this.selectedRfqId = this.selectedQuoteHeading.rfqId;

              this.selectedRFQ = response;
              this.selectedQuote = this.selectedRFQ.quoteDetails;
              this.sentQID = this.selectedQuote.quoteId;
              this.loadingRfq = false

              if (response.purchaseOrderFileId != null) {
                this.fileDownloadedService.getFileDownloadedCheck(response.purchaseOrderFileId, this.account.account.userId).subscribe(check => {
                  this.selectedRFQ.purchaseOrderDownloaded = check;
                });
              }

              //INDICATES WHICH THING RFQ BUTTON IS SELECTED FOR THE viewInProgressQuotes OBJECT
              for (let siderfq of this.viewInProgressQuotes) {
                if (this.selectedRfqId == siderfq.rfqId) {
                  siderfq.SideSelectedRFQ = true;
                }
                else {
                  siderfq.SideSelectedRFQ = false;
                }
              }

              //INDICATES WHICH THING RFQ BUTTON IS SELECTED FOR THE viewInProgressQuotesUpdate OBJECT
              for (let siderfq of this.viewInProgressQuotesUpdate) {
                if (this.selectedRfqId == siderfq.rfqId) {
                  siderfq.SideSelectedRFQ = true;
                }
                else {
                  siderfq.SideSelectedRFQ = false;
                }
              }


              //SETS COUNT FOR UNREAD MESSAGES ARRAY
              //CHECK IF UNDREAD MESSAGE ARRAY EXISTS
              if (this.selectedQuoteHeading.quoteDetails.unreadPrivateChatHistory) {
                this.UnreadMsgsCount = this.selectedQuoteHeading.quoteDetails.unreadPrivateChatHistory.length;
              }


              //CHECKS FOR UNREAD MESSAGES NEEDED FOR RED BLIP
              if (this.selectedQuoteHeading.quoteDetails.unreadPrivateMessages == true) {
                this.unreadMessages = true;

              }
              if (this.selectedQuoteHeading.quoteDetails.unreadPrivateMessages == false) {
                this.unreadMessages = false;
              }



            } else {
              this.router.navigate(['supplier-home/view-quote', 'InProgress', 0, 'false']);
              this.toastrNotification.error("Invalid Selected Quote")
            }

          },
          error: error => {
         
            this.router.navigate(['supplier-home/view-quote', 'InProgress', this.sortbyHeadings[0].rfq[0].quoteDetails.quoteId, 'false']);
            this.toastrNotification.error("Invalid Selected Quote")
            this.loadingRfq = false
          }

        });
    }

  }

  triggerChatOpen(value: boolean) {
    this.openChatCond.emit(value);
    this.openChat = value;

    //CHECKS IF SIDE NAVS RFQ ID = THE SELECTED RFQ AND IF CHAT IS OPENED ON SELECTED RFQ, THE BADGES DISSAPEARS
    if (value == true) {
      for (let siderfq of this.viewInProgressQuotes) {
        if (this.selectedRfqId == siderfq.rfqId) {
          this.selectedRFQ.quoteDetails.unreadPrivateMessages = false;

        }
      }

    }
    //CHECKS IF SIDE NAVS RFQ ID = THE SELECTED RFQ AND IF CHAT IS OPENED ON SELECTED RFQ, THE BADGES DISSAPEARS
    if (value == true) {
      for (let siderfq of this.viewInProgressQuotesUpdate) {
        if (this.selectedRfqId == siderfq.rfqId) {
          this.selectedRFQ.quoteDetails.unreadPrivateMessages = false;

        }
      }

    }

  }

  //Date Calcs
  public dateNow = new Date();
  public dDay?: Date;
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  private getTimeDifference() {
    this.timeDifference = this.dDay.getTime() - new Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
  }

  public getClosingTime(date) {
    this.dDay = new Date(date);
    this.getTimeDifference();
    if (this.daysToDday < 0) {
      return 'Closed';
    } else {
      return this.daysToDday + ' Days ' + this.hoursToDday + ' Hrs ' + this.minutesToDday + ' Mins';
    }

  }

  private allocateTimeUnits(timeDifference) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }

  onNext() {
    this.nextmodal = true;
  }


  //needed for complete Quote form
  correctMaterialGradeCheck(answer) {
    this.correctMaterialGradeCond = answer;
    if (answer) {
      this.f.correctMaterialGradeNo.setValue(false);
      this.f.correctMaterialGradeYes.setValue(true);
    } else {
      this.f.correctMaterialGradeYes.setValue(false);
      this.f.correctMaterialGradeNo.setValue(true);
    }
    
  }

  correctQuantityOfPartsCheck(answer) {
    this.correctQuantityOfPartsCond = answer;
    if (answer) {
      this.f.correctQuantityNo.setValue(false);
      this.f.correctQuantityYes.setValue(true);
    } else {
      this.f.correctQuantityYes.setValue(false);
      this.f.correctQuantityNo.setValue(true);
    }
    
  }

  onTimeCollection(answer) {
    this.buyerCollectedOnTime = answer;
    if (answer) {
      this.f.buyerCollectionOnTimeNo.setValue(false);
      this.f.buyerCollectionOnTimeYes.setValue(true);
    } else {
      this.f.buyerCollectionOnTimeYes.setValue(false);
      this.f.buyerCollectionOnTimeNo.setValue(true);
    }
 
  }

  BuyerCommunicationCheck(answer) {
    if (answer) {
      if (this.BuyerCommunicationCond != answer) {
        this.BuyerCommunicationCond = answer;
        this.f.buyerCommunicateNo.setValue(false);
      } else {
        this.BuyerCommunicationCond = null;
        this.f.buyerCommunicateYes.setValue(false);
      }
    } else if (!answer) {
      if (this.BuyerCommunicationCond != answer) {
        this.BuyerCommunicationCond = answer;
        this.f.buyerCommunicateYes.setValue(false);
      } else {
        this.BuyerCommunicationCond = null;
        this.f.buyerCommunicateNo.setValue(false);
      }
    }

  }




  rateBuyer(answer) {

    if (this.BuyerRating == answer) {
      this.BuyerRating = answer;
    } else if (this.BuyerRating != answer) {
      this.BuyerRating = answer;
    } else if (this.BuyerRating == null) {
      this.BuyerRating = answer;
      this.ratingForm.reset();

    }

  }

  completingQuote: boolean = false;
  rfqStateCheck: string;

  completeQuote(buyersurvey) {

    this.completingQuote = true;
    var resultMessage;
    this.quoteService.completeQuote(buyersurvey).pipe(first())
      .subscribe({
        next: (response: any) => {
          this.rfqService.getRfqState(this.selectedRFQ.rfqId).pipe(first())
            .subscribe({
              next: (response: any) => {
                this.rfqStateCheck = response;
                resultMessage = response;
                this.deliveryCompleteFinal.nativeElement.click();
                this.resetChecklist();
                this.completingQuote = false;
              },
              error: error => {
                this.toastrNotification.error("Rfq State Check Failed");
                this.completingQuote = false;
              }

            }
            );

        },
        error: error => {
          this.toastrNotification.error("Delivery Complete Failed");
          this.completingQuote = false;
        }

      });
  }

  checklistCheck() {
    if ((this.correctMaterialGradeCond == null || this.correctQuantityOfPartsCond == null || this.f.actualDeliveryDate.value == null
      || this.buyerCollectedOnTime == null
      || (this.correctMaterialGradeCond == false && this.f.correctMaterialGradeReason.value.replace(/ /g, "") == "")
      || (this.correctQuantityOfPartsCond == false && this.f.correctQuantityReason.value.replace(/ /g, "") == "")
      || (this.BuyerCommunicationCond == false && this.f.buyerCommunicateReason.value.replace(/ /g, "") == "")
      || (this.buyerCollectedOnTime == false && this.f.buyerCollectionOnTimeReason.value.replace(/ /g, "") == "")
      || (this.correctMaterialGradeCond == false && this.f.correctMaterialGradeReason.value.length > 250)
      || (this.correctQuantityOfPartsCond == false && this.f.correctQuantityReason.value.length > 250)
      || (this.BuyerCommunicationCond == false && this.f.buyerCommunicateReason.value.length > 250)
      || (this.buyerCollectedOnTime == false && this.f.buyerCollectionOnTimeReason.value.length > 250))) {
      this.checklistValidCond = false;
    } else {
      this.checklistValidCond = true;
    }
  }

  checklistCond() {
    if ((this.correctMaterialGradeCond == null || this.correctQuantityOfPartsCond == null || this.f.actualDeliveryDate.value == null
      || this.buyerCollectedOnTime == null
      || (this.correctMaterialGradeCond == false && this.f.correctMaterialGradeReason.value.replace(/ /g, "") == "")
      || (this.correctQuantityOfPartsCond == false && this.f.correctQuantityReason.value.replace(/ /g, "") == "")
      || (this.BuyerCommunicationCond == false && this.f.buyerCommunicateReason.value.replace(/ /g, "") == "")
      || (this.buyerCollectedOnTime == false && this.f.buyerCollectionOnTimeReason.value.replace(/ /g, "") == "")
      || (this.correctMaterialGradeCond == false && this.f.correctMaterialGradeReason.value.length > 250)
      || (this.correctQuantityOfPartsCond == false && this.f.correctQuantityReason.value.length > 250)
      || (this.BuyerCommunicationCond == false && this.f.buyerCommunicateReason.value.length > 250)
      || (this.buyerCollectedOnTime == false && this.f.buyerCollectionOnTimeReason.value.length > 250))) {
      return false;
    } else {
      return true;
    }
  }

  testCertValidCond: boolean;

  testCertValid() {
    if (this.selectedRFQ.quoteDetails.productCertificationQuoteFileId == null) {
      this.testCertValidCond = true;
    } else {
      this.testCertValidCond = false;
    }
  }

  stringValidation(value) {
    if (value.replace(/ /g, "") == "") {
      return true
    } else {
      return false;
    }
  }

  completeQuoteSubmit() {

    this.BuyerSurvey = {
      rfqId: null, quoteId: null, correctMaterialGrade: null, correctMaterialGradeReason: null,
      correctQuantity: null, correctQuantityReason: null, goodCommunication: null, goodCommunicationReason: null,
      onTimeCollection: null, onTimeCollectionReason: null, actualDeliveryDate: null, BuyerRating: null, deliveryRequirements: null
    };


    if (this.correctMaterialGradeCond != null && this.correctQuantityOfPartsCond != null) {
      this.BuyerSurvey.rfqId = this.selectedRFQ.rfqId;
      this.BuyerSurvey.quoteId = this.selectedRFQ.quoteDetails.quoteId;
      this.BuyerSurvey.correctMaterialGrade = this.correctMaterialGradeCond;
      if (this.correctMaterialGradeCond == true) {
        this.BuyerSurvey.correctMaterialGradeReason = null;
      } else {
        this.BuyerSurvey.correctMaterialGradeReason = this.f.correctMaterialGradeReason.value;
      }
      this.BuyerSurvey.correctQuantity = this.correctQuantityOfPartsCond;
      if (this.correctQuantityOfPartsCond == true) {
        this.BuyerSurvey.correctQuantityReason = null;
      } else {
        this.BuyerSurvey.correctQuantityReason = this.f.correctQuantityReason.value;
      }
      this.BuyerSurvey.onTimeCollection = this.buyerCollectedOnTime;
      if (this.buyerCollectedOnTime == true) {
        this.BuyerSurvey.onTimeCollectionReason = null;
      } else {
        this.BuyerSurvey.onTimeCollectionReason = this.f.buyerCollectionOnTimeReason.value;
      }
      this.BuyerSurvey.goodCommunication = this.BuyerCommunicationCond;
      if (this.BuyerCommunicationCond == true) {
        this.BuyerSurvey.goodCommunicationReason = null;
      } else {
        this.BuyerSurvey.goodCommunicationReason = this.f.buyerCommunicateReason.value;
      }
      this.BuyerSurvey.actualDeliveryDate = this.f.actualDeliveryDate.value;
      this.BuyerSurvey.BuyerRating = this.BuyerRating;

      if (this.selectedRFQ.deliveryRequirements == "Collection") {
        this.BuyerSurvey.deliveryRequirements = "Collection";
      } else if (this.selectedRFQ.deliveryRequirements == "Delivery") {
        this.BuyerSurvey.deliveryRequirements = "Delivery";
      }

      this.completeQuote(this.BuyerSurvey);

    } else {
      this.toastrNotification.error("error with form");
    }
  }

  clearSupplierRating() {

    this.BuyerRating = 0;
    this.resetRatingForm = null;

  }

  resetChecklist() {

    this.form.reset();
    this.ratingForm.reset();
    this.correctMaterialGradeCond = null;
    this.correctQuantityOfPartsCond = null;
    this.BuyerCommunicationCond = null;
    this.BuyerRating = 0;
    this.resetRatingForm = null;
    this.buyerCollectedOnTime = null;
    this.f.correctMaterialGradeReason.setValue("");
    this.f.correctQuantityReason.setValue("");
    this.f.buyerCollectionOnTimeReason.setValue("");
    this.f.buyerCommunicateReason.setValue("");
    this.checklistValidCond = true;

  }

  @ViewChild('deliveryCompleteBtn') deliveryCompleteBtn: ElementRef;

  @ViewChild('deliveryCompleteFinal') deliveryCompleteFinal: ElementRef;

  @ViewChild('deliveryCompleteClose') deliveryCompleteClose: ElementRef;

  deliveryCheck: boolean = false;

  deliveryCompleteCheck(rfqId, quoteId, cond) {

    this.togglePeriodicReload(true);

    this.deliveryCheck = true;

    this.quoteService.QuoteStateCheck(quoteId).pipe(
      finalize(() => this.deliveryCheck = false)).subscribe(x => {

        if (x == "In Progress") {
          if (cond == "Initial") {
            // if (this.selectedRFQ.deliveryRequirements == "Collection") {
            this.getQuoteReview(this.selectedRFQ.quoteDetails.quoteId);
            // }
            // else {
            //   this.deliveryCompleteBtn.nativeElement.click();
            // }

          } else if (cond == "Final") {
            this.completeQuoteSubmit();

          }

        } else {

          if (this.selectedRFQ.deliveryRequirements = "Delivery") {
            this.toastrNotification.info("Delivery on Rfq " + this.selectedRFQ.rfqName + " has already been made");
          } else {
            this.toastrNotification.info("Collection of Rfq " + this.selectedRFQ.rfqName + " has already been completed");
          }

          this.togglePeriodicReload(false);

          if (cond == "Initial") {
            this.reloadQuatesAfterCompleteStay(rfqId)
          } else if (cond == "Final") {
            this.deliveryCompleteClose.nativeElement.click();
            this.reloadQuatesAfterCompleteStay(rfqId)
          }

        }




      });



  }


  // QUOTE REVIEW OBJECT
  quoteReview: quoteReview;
  reviewdataloading: boolean;

  getQuoteReview(quoteId) {
    this.reviewdataloading = true;

    this.quoteService.getQuoteReview(quoteId).pipe(first())
      .subscribe({
        next: (response: any) => {

          this.quoteReview = response;
          this.reviewdataloading = false;
          this.deliveryCompleteBtn.nativeElement.click();

        },
        error: error => {
          this.toastrNotification.error("Quote Review Get Failed");
        }
      });
  }




  Sendquotedata(value: number) {
    this.quoteEvent.emit(value);
    this.sentQID = value;

  }


  get f() { return this.form.controls; }





  triggerReloadRfqs(value: boolean) {
    if (value) {

      this.sortbyHeadings = [];
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

      this.headingId = 1;
      this.singleSortHeadings.rfqHeadName = "Multiple Services";
      this.singleSortHeadings.rfq = [];
      this.sortbyHeadings.push(this.singleSortHeadings);
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
      this.headingId++;

      this.getIPQuotesAfterFileUpload(this.selectedRFQ.rfqId);
    }

  }

  getIPQuotesAfterFileUpload(rfqId) {
    this.quoteService.getQuoteHeadings(this.account.account.supplierId, 'In Progress').pipe(first())
      .subscribe({
        next: (response: any) => {
          this.viewInProgressQuotes = response;
          for (const { index, value } of this.viewInProgressQuotes.map((value, index) => ({ index, value }))) {
            if (value.rfqId == rfqId) {
              this.onSelectRFQ(response[index].quoteDetails.quoteId);
            }
          }
          this.salesOrderUploading = false;
          this.salesOrderDeleting = false;
          this.finalInvoiceUploading = false;
          this.finalInvoiceDeleting = false;
          this.testCertirficateUploading = false;
          this.testCetificateDeleting = false;
          this.sortByHeadings();
        }
      })
  }

  paymentMadeCheck() {
    if (this.selectedRFQ.quoteDetails.paymentReference != null) {
      return false;
    } else {
      return true;
    }
  }

  confirmingPayment: boolean = false;
  paymentConfirmRequest: RecievePaymentRequest = { QuoteId: null, RfqId: null };

  @ViewChild('paymentReceivedBtnIP') paymentReceivedBtnIP: ElementRef;
  @ViewChild('paymentReceivedFinalIP') paymentReceivedFinalIP: ElementRef;
  @ViewChild('paymentReceivedCloseIP') paymentReceivedCloseIP: ElementRef;

  confirmPayment() {

    // this.paymentReceivedBtn.nativeElement.click();

    this.confirmingPayment = true;

    this.paymentConfirmRequest = { QuoteId: null, RfqId: null };

    this.paymentConfirmRequest.QuoteId = this.selectedRFQ.quoteDetails.quoteId;
    this.paymentConfirmRequest.RfqId = this.selectedRFQ.rfqId;

    this.quoteService.paymentConfirmed(this.paymentConfirmRequest).pipe(first())
      .subscribe({
        next: (response: any) => {
          this.paymentReceivedBtnIP.nativeElement.click();
          this.confirmingPayment = false;
        },
        error: error => {
          this.toastrNotification.error("Payment Received Failed");
          this.confirmingPayment = false;
        }

      });
  }

  confirmPaymentAvailable: boolean = false;

  confirmPaymentCheck(rfqId, quoteId, cond) {

    this.togglePeriodicReload(true);

    this.confirmPaymentAvailable = true;

    this.quoteService.PaymentReceivedCheck(quoteId).subscribe(x => {
      if (!x) {

        if (cond == "Initial") {
          this.confirmPayment();
        } else if (cond == "Final") {
          this.paymentReceivedFinalIP.nativeElement.click();
        }

      } else {

        this.toastrNotification.info("Payment for Rfq " + this.selectedRFQ.rfqName + " has already been received");

        this.togglePeriodicReload(false);

        if (cond == "Initial") {
          // this.reloadQuotesAfterPaymentConfirmed()
        } else if (cond == "Final") {
          this.paymentReceivedCloseIP.nativeElement.click();
          //  this.reloadQuotesAfterPaymentConfirmed()
        }

      }
      this.confirmPaymentAvailable = false;
    });

  }


  //Files 
  PurchaseOrderFile: File;
  salesOrderFile: File;
  InvoiceFile: File;
  productCertificationFile: File;
  quoteFileUploadComplete: boolean;
  saleOderFileName = '';
  invoiceFileName = '';
  productCertificationFileName = '';
  salesOrderUploading: boolean = false;
  salesOrderDeleting: boolean = false;
  finalInvoiceUploading: boolean = false;
  finalInvoiceDeleting: boolean = false;
  testCertirficateUploading: boolean = false;
  testCetificateDeleting: boolean = false;
  salesOrderUpload: QuoteFileUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
  InvoiceUpload: QuoteFileUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
  productCertificationUpload: QuoteFileUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };

  requiredFileType: string = ".pdf";

  @ViewChild('clearInput', { static: false }) clearInputVar: ElementRef;

  resetQuoteFileInput() {
    this.clearInputVar.nativeElement.value = "";
  }

  @ViewChild('clearInputInv', { static: false }) clearInvInputVar: ElementRef;

  resetInvInput() {
    this.clearInvInputVar.nativeElement.value = "";
  }

  @ViewChild('clearTCInput', { static: false }) clearTCInputVar: ElementRef;

  @ViewChild('fileUpload4') fileUpload4: ElementRef;

  resetTCInput() {
    this.clearTCInputVar.nativeElement.value = "";
  }

  downloadQuoteFiles(quoteId) {
    this.rfqService.getQuoteFile(quoteId, this.account.account.userId).subscribe(x => {
      var FileContent = x[0].fileContent;
      var FileName = x[0].fileName;
      this.downloadFile(FileContent, FileName);
    });

  }

  downloadSalesOrderFile(quoteFileId, quoteId) {
    this.quoteService.getSalesOrderFile(quoteFileId).subscribe({
      next: (response: any) => {
        this.downloadFile(response.fileContent, response.fileName);
        // this.downloadingQuoteFile = false;
      },
      error: error => {
        this.toastrNotification.info("Sales Order File out of date, new Sales Order File loaded. Please retry download")
        this.quoteService.getQuoteFileDetails(quoteId, "SalesOrders").subscribe(x => {
          this.selectedRFQ.quoteDetails.salesQuoteFileId = x.quoteFileId;
          this.selectedRFQ.quoteDetails.salesOrderFileName = x.quoteFileName;
          // this.downloadingQuoteFile = false;
        });
      }
    })
  }

  downloadInvoiceFile(quoteFileId, quoteId) {
    this.quoteService.getInvoiceFile(quoteFileId).subscribe({
      next: (response: any) => {
        this.downloadFile(response.fileContent, response.fileName);
        // this.downloadingQuoteFile = false;
      },
      error: error => {
        this.toastrNotification.info("Final Invoice File out of date, new Sales Order File loaded. Please retry download")
        this.quoteService.getQuoteFileDetails(quoteId, "Invoices").subscribe(x => {
          this.selectedRFQ.quoteDetails.invoiceQuoteFileId = x.quoteFileId;
          this.selectedRFQ.quoteDetails.invoiceOrderFileName = x.quoteFileName;
          // this.downloadingQuoteFile = false;
        });
      }
    })
  }

  downloadProductCertificationFile(quoteFileId, quoteId) {
    this.quoteService.getProductCertificationFile(quoteFileId).subscribe({
      next: (response: any) => {
        this.downloadFile(response.fileContent, response.fileName);
        // this.downloadingQuoteFile = false;
      },
      error: error => {
        this.toastrNotification.info("Test Certification File out of date, new Test Certification File loaded. Please retry download")
        this.quoteService.getQuoteFileDetails(quoteId, "ProductCertification").subscribe(x => {
          this.selectedRFQ.quoteDetails.productCertificationQuoteFileId = x.quoteFileId;
          this.selectedRFQ.quoteDetails.productCertificationFileName = x.quoteFileName;
          // this.downloadingQuoteFile = false;
        });
      }
    })
  }


  downloadDrawingFile(rfqFileId) {
    this.rfqService.getRfqDrawingFile(rfqFileId).subscribe(x => {
      this.downloadFile(x.fileContent, x.fileName)
    })
  }



  downloadPurchaseOrderFile(rfqFileId) {
    this.rfqService.getPurchaseOrderFile(rfqFileId, this.account.account.userId, "Supplier").subscribe({
      next: (response: any) => {
        this.selectedRFQ.purchaseOrderDownloaded = true;
        this.downloadFile(response.fileContent, response.fileName)
      },
      error: error => {
        this.rfqService.getRfqFileDetails(this.selectedRFQ.rfqId, "PurchaseOrders").subscribe(x => {
          this.selectedRFQ.purchaseOrderFileId = x.rfqFileId;
          this.selectedRFQ.purchaseOrderFileName = x.rfqFileName;
        });
        this.toastrNotification.info("Purchase Order File out of date, new Purchase Order File loaded. Please retry download")

      }


    })
  }




  downloadedPurchaseOrderCheck(purchaseOrderFileId) {
    this.fileDownloadedService.getFileDownloadedCheck(purchaseOrderFileId, this.account.account.userId).subscribe(check => {
      if (check) {
        this.fileUpload4.nativeElement.click();
        this.selectedRFQ.purchaseOrderDownloaded = check;
      } else {
        this.toastrNotification.info("Download And Confirm Purchase Order File Before Uploading Final Invoice File");
      }

    });
  }



  downloadFile(base64String, fileName) {

    // if(window.navigator && window.navigator.msSaveOrOpenBlob){ 
    //   // download PDF in IE
    //   let byteChar = atob(base64String);
    //   let byteArray = new Array(byteChar.length);
    //   for(let i = 0; i < byteChar.length; i++){
    //     byteArray[i] = byteChar.charCodeAt(i);
    //   }
    //   let uIntArray = new Uint8Array(byteArray);
    //   let blob = new Blob([uIntArray], {type : 'application/pdf'});
    //   window.navigator.msSaveOrOpenBlob(blob, `${fileName}.pdf`);
    // } 

    // Download PDF in Chrome etc.

    const source = `data:application/;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}`
    link.click();

  }

  salesOrderFileSelected(event) {

    this.salesOrderUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };

    this.quoteFileUploadComplete = false;
    this.salesOrderFile = event.target.files[0];
    const reader = new FileReader();

    if (this.salesOrderFile) {

      this.saleOderFileName = this.salesOrderFile.name;

      if (this.salesOrderFile.name != this.selectedRFQ.quoteDetails.invoiceOrderFileName
        && this.salesOrderFile.name != this.selectedRFQ.quoteDetails.quoteFileName
        && this.salesOrderFile.name != this.selectedRFQ.quoteDetails.productCertificationFileName) {

        this.salesOrderUploading = true;

        reader.readAsDataURL(this.salesOrderFile);
        reader.onload = () => {
       
          this.salesOrderUpload.file = (reader.result + "").substring((reader.result + "").indexOf(",") + 1, (reader.result + "").length);
         
          this.salesOrderUpload.quoteId = this.selectedQuote.quoteId;
          this.salesOrderUpload.rfqId = this.selectedRFQ.rfqId;
          this.salesOrderUpload.buyerId = null;
          this.salesOrderUpload.supplierId = this.account.account.supplierId;
          this.salesOrderUpload.userId = this.account.account.userId;
          this.salesOrderUpload.fileName = this.salesOrderFile.name;
          this.quoteService.uploadSalesOrderFile(this.salesOrderUpload).pipe(
            finalize(() => this.salesOrderUploading = true)
          ).subscribe(x => {
           
            if (this.selectedRFQ.quoteDetails.salesQuoteFileId != null) {
              this.salesOrderDeleting = true;
              this.quoteService.deleteSalesOrderFile(this.selectedQuote.salesQuoteFileId).pipe(
                finalize(() => this.salesOrderDeleting = true)
              ).subscribe(y => {
                this.quoteService.getQuoteFileDetails(this.selectedRFQ.quoteDetails.quoteId, "SalesOrders").pipe(first())
                  .subscribe({
                    next: (response: any) => {
                      this.selectedRFQ.quoteDetails.salesQuoteFileId = response.quoteFileId;
                      this.selectedRFQ.quoteDetails.salesOrderFileName = response.quoteFileName;

                      for (let rfqHeading of this.sortbyHeadings) {
                        for (let rfq of rfqHeading.rfq) {
                          if (rfq.rfqId == this.selectedRFQ.rfqId) {
                            rfq.quoteDetails.salesQuoteFileId = response.quoteFileId;
                            rfq.quoteDetails.salesOrderFileName = response.quoteFileName;
                          }
                        }
                      }
                      this.salesOrderUploading = false;
                      this.salesOrderDeleting = false;

                      // LOGIC FOR SUPPLIER USER ACTIONS 
                      for (let heading of this.sortbyHeadings) {
                        for (let rfq of heading.rfq) {
                          heading.openQuotesCounter = 0;

                          if (this.blipCondition(rfq)) {
                            heading.openQuotesCounter = 1;
                            break;
                          }
                        }
                      }
                    },
                    error: error => {
                      this.toastrNotification.error("Get Quote Details Failed");
                    }
                  });
              });
            } else {
              this.quoteService.getQuoteFileDetails(this.selectedRFQ.quoteDetails.quoteId, "SalesOrders").pipe(first())
                .subscribe({
                  next: (response: any) => {
                    this.selectedRFQ.quoteDetails.salesQuoteFileId = response.quoteFileId;
                    this.selectedRFQ.quoteDetails.salesOrderFileName = response.quoteFileName;

                    for (let rfqHeading of this.sortbyHeadings) {
                      for (let rfq of rfqHeading.rfq) {
                        if (rfq.rfqId == this.selectedRFQ.rfqId) {
                          rfq.quoteDetails.salesQuoteFileId = response.quoteFileId;
                          rfq.quoteDetails.salesOrderFileName = response.quoteFileName;
                        }
                      }
                    }
                    this.salesOrderUploading = false;
                    this.salesOrderDeleting = false;

                    // LOGIC FOR SUPPLIER USER ACTIONS 
                    for (let heading of this.sortbyHeadings) {
                      for (let rfq of heading.rfq) {
                        heading.openQuotesCounter = 0;

                        if (this.blipCondition(rfq)) {
                          heading.openQuotesCounter = 1;
                          break;
                        }
                      }
                    }
                  },
                  error: error => {
                    this.toastrNotification.error("Get Quote Details Failed");
                  }
                });
            }
            this.clearInputVar.nativeElement.value = "";
          });
        };
     

      } else {

        if (this.salesOrderFile.name == this.selectedRFQ.quoteDetails.invoiceOrderFileName) {
          this.toastrNotification.error("File has previously been uploaded as Final Invoice");
        } else if (this.salesOrderFile.name == this.selectedRFQ.quoteDetails.quoteFileName) {
          this.toastrNotification.error("File has previously been uploaded as Quote");
        } else if (this.salesOrderFile.name == this.selectedRFQ.quoteDetails.productCertificationFileName) {
          this.toastrNotification.error("File has previously been uploaded as Test Certification");
        }

        this.clearInputVar.nativeElement.value = "";
      }
    }
  }

  InvoiceFileSelected(event) {

  

    this.InvoiceUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };

    this.quoteFileUploadComplete = false;
    this.InvoiceFile = event.target.files[0];
    const reader = new FileReader();

    if (this.InvoiceFile) {

      this.invoiceFileName = this.InvoiceFile.name;

      if (this.InvoiceFile.name != this.selectedRFQ.quoteDetails.salesOrderFileName
        && this.InvoiceFile.name != this.selectedRFQ.quoteDetails.quoteFileName
        && this.InvoiceFile.name != this.selectedRFQ.quoteDetails.productCertificationFileName) {

        this.finalInvoiceUploading = true;

        reader.readAsDataURL(this.InvoiceFile);
        reader.onload = () => {
          
          this.InvoiceUpload.file = (reader.result + "").substring((reader.result + "").indexOf(",") + 1, (reader.result + "").length);
          
          this.InvoiceUpload.quoteId = this.selectedQuote.quoteId;
          this.InvoiceUpload.rfqId = this.selectedRFQ.rfqId;
          this.InvoiceUpload.buyerId = null;
          this.InvoiceUpload.supplierId = this.account.account.supplierId;
          this.InvoiceUpload.userId = this.account.account.userId;
          this.InvoiceUpload.fileName = this.InvoiceFile.name;
          this.quoteService.uploadInvoiceFile(this.InvoiceUpload).pipe(
            finalize(() => this.finalInvoiceUploading = true)
          ).subscribe(x => {
            
            if (this.selectedRFQ.quoteDetails.invoiceQuoteFileId != null) {
              this.finalInvoiceDeleting = true;
              this.quoteService.deleteInvoiceFile(this.selectedQuote.invoiceQuoteFileId).pipe(
                finalize(() => this.finalInvoiceDeleting = true)
              ).subscribe(y => {
                this.quoteService.getQuoteFileDetails(this.selectedRFQ.quoteDetails.quoteId, "Invoices").pipe(first())
                  .subscribe({
                    next: (response: any) => {
                      this.selectedRFQ.quoteDetails.invoiceQuoteFileId = response.quoteFileId;
                      this.selectedRFQ.quoteDetails.invoiceOrderFileName = response.quoteFileName;

                      for (let rfqHeading of this.sortbyHeadings) {
                        for (let rfq of rfqHeading.rfq) {
                          if (rfq.rfqId == this.selectedRFQ.rfqId) {
                            rfq.quoteDetails.invoiceQuoteFileId = response.quoteFileId;
                            rfq.quoteDetails.invoiceOrderFileName = response.quoteFileName;
                          }
                        }
                      }
                      this.finalInvoiceUploading = false;
                      this.finalInvoiceDeleting = false;

                      // LOGIC FOR SUPPLIER USER ACTIONS 
                      for (let heading of this.sortbyHeadings) {
                        for (let rfq of heading.rfq) {
                          heading.openQuotesCounter = 0;

                          if (this.blipCondition(rfq)) {
                            heading.openQuotesCounter = 1;
                            break;
                          }
                        }
                      }
                    },
                    error: error => {
                      this.toastrNotification.error("Get Quote Details Failed");
                    }
                  });
              });
            } else {
              this.quoteService.getQuoteFileDetails(this.selectedRFQ.quoteDetails.quoteId, "Invoices").pipe(first())
                .subscribe({
                  next: (response: any) => {
                    this.selectedRFQ.quoteDetails.invoiceQuoteFileId = response.quoteFileId;
                    this.selectedRFQ.quoteDetails.invoiceOrderFileName = response.quoteFileName;

                    for (let rfqHeading of this.sortbyHeadings) {
                      for (let rfq of rfqHeading.rfq) {
                        if (rfq.rfqId == this.selectedRFQ.rfqId) {
                          rfq.quoteDetails.invoiceQuoteFileId = response.quoteFileId;
                          rfq.quoteDetails.invoiceOrderFileName = response.quoteFileName;
                        }
                      }
                    }
                    this.finalInvoiceUploading = false;
                    this.finalInvoiceDeleting = false;

                    // LOGIC FOR SUPPLIER USER ACTIONS 
                    for (let heading of this.sortbyHeadings) {
                      for (let rfq of heading.rfq) {
                        heading.openQuotesCounter = 0;

                        if (this.blipCondition(rfq)) {
                          heading.openQuotesCounter = 1;
                          break;
                        }
                      }
                    }
                  },
                  error: error => {
                    this.toastrNotification.error("Get Quote Details Failed");
                  }
                });
            }
            this.clearInvInputVar.nativeElement.value = ""

          });
        };
        

      } else {
        if (this.InvoiceFile.name == this.selectedRFQ.quoteDetails.salesOrderFileName) {
          this.toastrNotification.error("File has previously been uploaded as Sales Order");
        } else if (this.InvoiceFile.name == this.selectedRFQ.quoteDetails.quoteFileName) {
          this.toastrNotification.error("File has previously been uploaded as Quote ");
        } else if (this.InvoiceFile.name == this.selectedRFQ.quoteDetails.productCertificationFileName) {
          this.toastrNotification.error("File has previously been uploaded as Test Certification ");
        }

        this.clearInvInputVar.nativeElement.value = ""
      }
    }
  }

  TestCertificateSelected(event) {

    this.productCertificationUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };

    this.quoteFileUploadComplete = false;
    this.productCertificationFile = event.target.files[0];
    const reader = new FileReader();

    if (this.productCertificationFile) {

      this.productCertificationFileName = this.productCertificationFile.name;

      if (this.productCertificationFile.name != this.selectedRFQ.quoteDetails.salesOrderFileName
        && this.productCertificationFile.name != this.selectedRFQ.quoteDetails.quoteFileName
        && this.productCertificationFile.name != this.selectedRFQ.quoteDetails.invoiceOrderFileName) {

        this.testCertirficateUploading = true;

        reader.readAsDataURL(this.productCertificationFile);
        reader.onload = () => {

          this.productCertificationUpload.file = (reader.result + "").substring((reader.result + "").indexOf(",") + 1, (reader.result + "").length);

          this.productCertificationUpload.quoteId = this.selectedQuote.quoteId;
          this.productCertificationUpload.rfqId = this.selectedRFQ.rfqId;
          this.productCertificationUpload.buyerId = null;
          this.productCertificationUpload.supplierId = this.account.account.supplierId;
          this.productCertificationUpload.userId = this.account.account.userId;
          this.productCertificationUpload.fileName = this.productCertificationFile.name;
          this.quoteService.uploadTestCertificateFile(this.productCertificationUpload).pipe(
            finalize(() => this.testCertirficateUploading = true)).subscribe(x => {
             
              if (this.selectedRFQ.quoteDetails.productCertificationQuoteFileId != null) {
                this.testCetificateDeleting = true;
                this.quoteService.deleteProductCertificationFile(this.selectedQuote.productCertificationQuoteFileId).pipe(
                  finalize(() => this.testCetificateDeleting = true)).subscribe(y => {
                    this.quoteService.getQuoteFileDetails(this.selectedRFQ.quoteDetails.quoteId, "ProductCertification").pipe(first())
                      .subscribe({
                        next: (response: any) => {
                          this.selectedRFQ.quoteDetails.productCertificationQuoteFileId = response.quoteFileId;
                          this.selectedRFQ.quoteDetails.productCertificationFileName = response.quoteFileName;

                          // for (let rfqHeading of this.sortbyHeadings) {
                          //   for (let rfq of rfqHeading.rfq) {
                          //     if (rfq.rfqId == this.selectedRFQ.rfqId) {
                          //       rfq.quoteDetails.productCertificationQuoteFileId = response.quoteFileId;
                          //       rfq.quoteDetails.productCertificationFileName = response.quoteFileName;
                          //     }
                          //   }
                          // }
                          this.testCertirficateUploading = false;
                          this.testCetificateDeleting = false;

                          // LOGIC FOR SUPPLIER USER ACTIONS 
                          for (let heading of this.sortbyHeadings) {
                            for (let rfq of heading.rfq) {
                              heading.openQuotesCounter = 0;

                              if (this.blipCondition(rfq)) {
                                heading.openQuotesCounter = 1;
                                break;
                              }
                            }
                          }
                        },
                        error: error => {
                          this.toastrNotification.error("Get Quote Details Failed");
                        }
                      });

                  });
              } else {
                this.quoteService.getQuoteFileDetails(this.selectedRFQ.quoteDetails.quoteId, "ProductCertification").pipe(first())
                .subscribe({
                  next: (response: any) => {
                    this.selectedRFQ.quoteDetails.productCertificationQuoteFileId = response.quoteFileId;
                    this.selectedRFQ.quoteDetails.productCertificationFileName = response.quoteFileName;

                    // for (let rfqHeading of this.sortbyHeadings) {
                    //   for (let rfq of rfqHeading.rfq) {
                    //     if (rfq.rfqId == this.selectedRFQ.rfqId) {
                    //       rfq.quoteDetails.productCertificationQuoteFileId = response.quoteFileId;
                    //       rfq.quoteDetails.productCertificationFileName = response.quoteFileName;
                    //     }
                    //   }
                    // }
                    this.testCertirficateUploading = false;
                    this.testCetificateDeleting = false;

                    // LOGIC FOR SUPPLIER USER ACTIONS 
                    for (let heading of this.sortbyHeadings) {
                      for (let rfq of heading.rfq) {
                        heading.openQuotesCounter = 0;

                        if (this.blipCondition(rfq)) {
                          heading.openQuotesCounter = 1;
                          break;
                        }
                      }
                    }
                  },
                  error: error => {
                    this.toastrNotification.error("Get Quote Details Failed");
                  }
                });

              }
              this.clearTCInputVar.nativeElement.value = ""

            });
        };
        

      } else {
        if (this.productCertificationFile.name == this.selectedRFQ.quoteDetails.salesOrderFileName) {
          this.toastrNotification.error("File has already been uploaded as Sales Order");
        } else if (this.productCertificationFile.name == this.selectedRFQ.quoteDetails.quoteFileName) {
          this.toastrNotification.error("File has already been uploaded as Quote ");
        } else if (this.productCertificationFile.name == this.selectedRFQ.quoteDetails.invoiceOrderFileName) {
          this.toastrNotification.error("File has already been uploaded as Final Invoice ");
        }

        this.clearTCInputVar.nativeElement.value = ""
      }
    }
  }


  // RFQ NAV SEARCH FORM
  RFQsearch: FormGroup;
  // RFQ SEARCH FORM
  get rs() { return this.RFQsearch.controls; }

  //  CLEAR RFQ SEARCH FILTER VALUES
  filtersUsed: boolean = false;
  filteredNavRfqs: QuoteHeadings[] = [];
  filteredNavRfqsUpdate: QuoteHeadings[] = [];
  filterSortbyHeadings: QuoteHeaddisplay[] = [];
  filterSortbyHeadingsUpdate: QuoteHeaddisplay[] = [];


  filterRfqType: string[] = [];
  filterPaymentOptions: string[] = [];
  filterPackagingOptions: string[] = [];
  filterCollectorDelivery: string[] = [];
  filtertestCertification: boolean[] = [];





  //  FILTER RFQ NAV
  RFQsearchFilter() {

    this.filterRfqType = [];
    this.filterPaymentOptions = [];
    this.filterCollectorDelivery = [];
    this.filterPackagingOptions = [];
    this.filtertestCertification = [];


    this.filteredNavRfqs = [];

    // POPULATES THE FILTER RFQ ARRAY EVERY VALUE IN THE IN PROGRESS QUOTES ARRAY
    for (let value of this.viewInProgressQuotes) {
      this.filteredNavRfqs.push(value);

    }

    // IF ANY OF THE FILTER VALUES ARE SET TO TRUE THEN THE FILTER IS USED AND THE HTML DISPLAYS THE filteredNavRfqs ARRAY
    if (this.rs.complete.value || this.rs.rawmat.value || this.rs.cod.value || this.rs.cbd.value || this.rs.thirtyDays.value || this.rs.sixtyDays.value ||
      this.rs.paymentOther.value || this.rs.delivery.value || this.rs.collection.value || this.rs.boxed.value || this.rs.pallets.value ||
      this.rs.strapped.value || this.rs.loose.value || this.rs.packagingOther.value || this.rs.testCertificationYes.value || this.rs.testCertificationNo.value) {

      this.filtersUsed = true;
    }
    else {
      this.filtersUsed = false;

    }

    // BUILD UP TYPE OF SUPPLY ARRAY
    if (this.rs.complete.value == true) {
      this.filterRfqType.push("Complete");
    }

    if (this.rs.rawmat.value == true) {
      this.filterRfqType.push("Raw Material");
    }


    // BUILD UP COLLECT OR DELIVERY ARRAY
    if (this.rs.delivery.value == true) {
      this.filterCollectorDelivery.push("Delivery");

    }

    if (this.rs.collection.value == true) {
      this.filterCollectorDelivery.push("Collection");

    }
    // BUILD UP PAYMENT ARRAY
    if (this.rs.cod.value == true) {
      this.filterPaymentOptions.push("COD");

    }

    if (this.rs.cbd.value == true) {
      this.filterPaymentOptions.push("CBD");

    }

    if (this.rs.thirtyDays.value == true) {
      this.filterPaymentOptions.push("30 Days");

    }


    if (this.rs.sixtyDays.value == true) {
      this.filterPaymentOptions.push("60 Days");

    }


    if (this.rs.paymentOther.value == true) {
      this.filterPaymentOptions.push("Other");

    }

    // BUILD UP PACKAGING OPTION ARRAY
    if (this.rs.boxed.value == true) {
      this.filterPackagingOptions.push("Boxed");

    }

    if (this.rs.pallets.value == true) {
      this.filterPackagingOptions.push("Pallet");

    }

    if (this.rs.strapped.value == true) {
      this.filterPackagingOptions.push("Strapped");

    }

    if (this.rs.loose.value == true) {
      this.filterPackagingOptions.push("Loose");

    }

    if (this.rs.packagingOther.value == true) {
      this.filterPackagingOptions.push("Other");

    }

    // TEST CERTIFICATION ARRAY POPULATE
    if (this.rs.testCertificationYes.value == true) {

      this.filtertestCertification.push(true);
    }

    // TEST CERTIFICATION ARRAY POPULATE
    if (this.rs.testCertificationNo.value == true) {

      this.filtertestCertification.push(true);
    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE TYPE OP SUPPLY OPTIONS
    if (this.filterRfqType.length > 0) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => this.filterRfqType.some(r => rfq.typeOfSupply.includes(r)));

    }


    // FILTERS filterOpenRfq ARRAY FOR ALL THE PAYMENT REQUIREMENT OPTIONS
    if (this.filterPaymentOptions.length > 0) {
      if (this.filteredNavRfqs != null) {
        this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => this.filterPaymentOptions.some(r => rfq.paymentRequirement?.includes(r)));
      }
    }


    // FILTERS filterOpenRfq ARRAY FOR ALL THE DELIVERY REQUIREMENT OPTIONS
    if (this.filterCollectorDelivery.length > 0) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => this.filterCollectorDelivery.some(r => rfq.deliveryRequirements.includes(r)));
    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE PACKAGING REQUIREMENT OPTIONS FIRST CHECKS IF THE PACKAGING REQUIREMENT OPTIONS IS NOT NULL
    if (this.filterPackagingOptions.length > 0) {

      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => rfq.packagingRequirements != null).filter(rfq => this.filterPackagingOptions.some(r => rfq.packagingRequirements.includes(r)));
    }


    // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE OR FALSE
    if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == true) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => rfq.productCertificationRequirements == true || rfq.productCertificationRequirements == false);

    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE
    if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == false) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => rfq.productCertificationRequirements == true);

    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS FALSE
    if (this.rs.testCertificationNo.value == true && this.rs.testCertificationYes.value == false) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => rfq.productCertificationRequirements == false);

    }



    // FILTERS filterOpenRfq ARRAY IF NOT NULL
    if (this.filteredNavRfqs.length > 0) {

      this.filterSortbyHeadings = [];
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

      this.headingId = 1;
      this.singleSortHeadings.rfqHeadName = "Multiple Services";
      this.singleSortHeadings.rfq = [];
      this.filterSortbyHeadings.push(this.singleSortHeadings);
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
      this.headingId++;



      this.sortFilterRFQHeadArray();
    }
    else {
      // MAKE MAKE THE ARRAY THAT BUILDS UP THE SIDE NAV NULL
      this.filterSortbyHeadings = [];
    }


  }


  // BUILDS RFQ SIDENAV AFTER A FILTER IS SELECTED
  sortFilterRFQHeadArray() {

    // PUSHES ALL VALUES FROM OPEN RFQS ARRAY INTO THE FILTER RFQS ARRAY

    for (let indexrfq of this.filteredNavRfqs) {
      for (let service of indexrfq.rfqServicesRequired) {
        this.check = false;
        if (indexrfq.rfqServicesRequired.length == 1) {
          for (let name of this.filterSortbyHeadings) {
            if (service == name.rfqHeadName) {
              this.check = true;
            }
          }
          if (this.check == false) {

            this.singleSortHeadings.rfqHeadName = service;
            this.singleSortHeadings.rfq = [];
            this.singleSortHeadings.id = this.headingId;
            this.filterSortbyHeadings.push(this.singleSortHeadings);
            this.headingId++;
            this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
          }
        }
      }

      if (indexrfq.rfqServicesRequired.length > 1) {
        this.filterSortbyHeadings[0].rfq.push(indexrfq);
      }

    }
    this.populateSearchRFQHeadArray();
    this.getClosingTime(Date);
  }


  populateSearchRFQHeadArray(): void {
    for (let rfq of this.filteredNavRfqs) {
      for (let service of rfq.rfqServicesRequired) {
        if (rfq.rfqServicesRequired.length == 1) {
          for (let val of this.filterSortbyHeadings) {
            if (val.rfqHeadName == service) {
              val.rfq.push(rfq)

            }
          }
        }

      }
    }
    if (this.filterSortbyHeadings[0].rfq.length == 0) {
      this.filterSortbyHeadings.splice(0, 1);
    }
    // LOGIC FOR SUPPLIER USER ACTIONS 
    for (let heading of this.filterSortbyHeadings) {
      for (let rfq of heading.rfq) {
        heading.openQuotesCounter = 0;

        if (this.blipCondition(rfq)) {
          heading.openQuotesCounter = 1;
          break;
        }
      }
    }
  }

  // FILTER NAV ON PERIODIC RELOAD
  filterUpdateRfqs(array: QuoteHeadings[]) {

    this.filteredNavRfqsUpdate = [];

    if (array.length > 0) {

      for (let rfq of array) {
        this.filteredNavRfqsUpdate.push(rfq);
      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE TYPE OP SUPPLY OPTIONS
      if (this.filterRfqType.length > 0) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => this.filterRfqType.some(r => rfq.typeOfSupply.includes(r)));

      }
      // FILTERS filterOpenRfq ARRAY FOR ALL THE PAYMENT REQUIREMENT OPTIONS
      if (this.filterPaymentOptions.length > 0) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => this.filterPaymentOptions.some(r => rfq.paymentRequirement.includes(r)));
      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE DELIVERY REQUIREMENT OPTIONS
      if (this.filterCollectorDelivery.length > 0) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => this.filterCollectorDelivery.some(r => rfq.deliveryRequirements.includes(r)));
      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE PACKAGING REQUIREMENT OPTIONS FIRST CHECKS IF THE PACKAGING REQUIREMENT OPTIONS IS NOT NULL
      if (this.filterPackagingOptions.length > 0) {

        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => rfq.packagingRequirements != null).filter(rfq => this.filterPackagingOptions.some(r => rfq.packagingRequirements.includes(r)));
      }


      // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE OR FALSE
      if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == true) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => rfq.productCertificationRequirements == true || rfq.productCertificationRequirements == false);

      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE
      if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == false) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => rfq.productCertificationRequirements == true);

      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS FALSE
      if (this.rs.testCertificationNo.value == true && this.rs.testCertificationYes.value == false) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => rfq.productCertificationRequirements == false);

      }
      

      // FILTERS filterOpenRfq ARRAY IF NOT NULL
      if (this.filteredNavRfqsUpdate.length > 0) {

        this.filterSortbyHeadingsUpdate = [];
        this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

        this.headingId = 1;
        this.singleSortHeadings.rfqHeadName = "Multiple Services";
        this.singleSortHeadings.rfq = [];
        this.filterSortbyHeadingsUpdate.push(this.singleSortHeadings);
        this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
        this.headingId++;

        for (let indexrfq of this.filteredNavRfqsUpdate) {
          for (let service of indexrfq.rfqServicesRequired) {
            this.check = false;
            if (indexrfq.rfqServicesRequired.length == 1) {
              for (let name of this.filterSortbyHeadingsUpdate) {
                if (service == name.rfqHeadName) {
                  this.check = true;
                }
              }
              if (this.check == false) {

                this.singleSortHeadings.rfqHeadName = service;
                this.singleSortHeadings.rfq = [];
                this.singleSortHeadings.id = this.headingId;
                this.filterSortbyHeadingsUpdate.push(this.singleSortHeadings);
                this.headingId++;
                this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
              }
            }
          }

          if (indexrfq.rfqServicesRequired.length > 1) {
            this.filterSortbyHeadingsUpdate[0].rfq.push(indexrfq);
          }

        }

        this.getClosingTime(Date);



        for (let rfq of this.filteredNavRfqsUpdate) {
          for (let service of rfq.rfqServicesRequired) {
            if (rfq.rfqServicesRequired.length == 1) {
              for (let val of this.filterSortbyHeadingsUpdate) {
                if (val.rfqHeadName == service) {
                  val.rfq.push(rfq)

                }
              }
            }

          }
        }
        if (this.filterSortbyHeadingsUpdate[0].rfq.length == 0) {
          this.filterSortbyHeadingsUpdate.splice(0, 1);
        }

        // LOGIC FOR SUPPLIER USER ACTIONS 
        for (let heading of this.filterSortbyHeadingsUpdate) {
          for (let rfq of heading.rfq) {
            heading.openQuotesCounter = 0;

            if (this.blipCondition(rfq)) {
              heading.openQuotesCounter = 1;
              break;
            }
          }
        }


      }
      else {
        // MAKE MAKE THE ARRAY THAT BUILDS UP THE SIDE NAV NULL
        this.filterSortbyHeadingsUpdate = [];
      }
      
      this.replaceFilteredRfqsOnUpdate();

    }

  }

  //PUSH OR SPLICE RFQS IF THEY HAVE LEFT THE ARRAY OR IF NEW ONES HAVE COME IN
  replaceFilteredRfqsOnUpdate() {

    var newHeadings: string[] = [];
    var oldHeadings: string[] = [];

    for (let heading of this.filterSortbyHeadings) {
      oldHeadings.push(heading.rfqHeadName)
      for (let newHead of this.filterSortbyHeadingsUpdate) {
        if (heading.rfqHeadName == newHead.rfqHeadName) {
          heading.rfq = newHead.rfq;
          heading.openQuotesCounter = newHead.openQuotesCounter;
        }
      }
    }

    for (let newHeading of this.filterSortbyHeadingsUpdate) {
      newHeadings.push(newHeading.rfqHeadName);
    }

    for (let newHead of this.filterSortbyHeadingsUpdate) {
      if (oldHeadings.indexOf(newHead.rfqHeadName) == -1) {
        this.filterSortbyHeadings.push(newHead);
      }
    }


    for (const { index, value } of this.filterSortbyHeadings.map((value, index) => ({ index, value }))) {
      if (newHeadings.indexOf(value.rfqHeadName) == -1) {
        this.filterSortbyHeadings.splice(index, 1);
      }
    }



  }


  clearRFQFilter() {

    this.filtersUsed = false;

    this.rs.complete.setValue(false);
    this.rs.rawmat.setValue(false);
    this.rs.cod.setValue(false);
    this.rs.cbd.setValue(false);
    this.rs.thirtyDays.setValue(false);
    this.rs.sixtyDays.setValue(false);
    this.rs.paymentOther.setValue(false);
    this.rs.delivery.setValue(false);
    this.rs.collection.setValue(false);
    this.rs.boxed.setValue(false);
    this.rs.pallets.setValue(false);
    this.rs.strapped.setValue(false);
    this.rs.loose.setValue(false);
    this.rs.packagingOther.setValue(false);
    this.rs.testCertificationYes.setValue(false);
    this.rs.testCertificationNo.setValue(false);
  }

  filterOpen: boolean = false;
  filterIconToggle() {

    this.filterOpen = !this.filterOpen;

  }


  // TOGGLES PUBLIC CHAT BADGE TO FALSE WHEN PUBLIC CHAT IS OPENED
  togglePublicChatMessages() {
    this.selectedQuoteHeading.unreadPublicMessages = false;

  }
  // TOGGLES PRIVATE CHAT BADGE TO FALSE WHEN PUBLIC CHAT IS OPENED
  togglePrivateChatbadge() {
    this.selectedQuoteHeading.quoteDetails.unreadPrivateMessages = false;
  }

  //  DATE CALCULATIONS



  //needed for date calculations

  dueDate: any;
  dueHours: any;
  date1: any;
  date2: any;
  roundDate: any;


  getDaydiff(deliveryDate) {

    this.date1 = new Date(this.selectedRFQ.maxLeadTime);
    this.date2 = new Date(this.f.actualDeliveryDate.value);

    let diff = this.date2.getTime() - this.date1.getTime();
    let msInDay = 1000 * 3600 * 24;

    this.dueDate = diff / msInDay;

    this.roundDate = Math.floor(diff / msInDay);

    this.roundDate = Math.abs(this.roundDate);
  }


}


