import { Quote } from '@angular/compiler';
import { Component, Input, OnInit, AfterViewInit, ElementRef, ViewChild, Output, EventEmitter, OnDestroy, OnChanges, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Console } from 'console';
import { Observable, of, Subscription, interval, fromEventPattern, pipe } from 'rxjs';
import { switchMap, finalize, first } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { FileDownloadedService } from '../services/file-downloaded.service';
import { Account } from '../models/account';
import { FullRfqReview } from '../models/completeRfq';
import { RFQHeaddisplay } from '../models/header';
import { quoteReview } from '../models/quoteReview';
import { ReloadViewRfqs } from '../models/reloadViewRfqs';
import { RFQ } from '../models/rfq';
import { UploadRfqFile } from '../models/rfqCreate';
import { RfqHeadings } from '../models/rfqHeadings';
import { MakePaymentRequest } from '../models/rfqPaymentRequest';
import { ViewRfq, ViewRFQs_Quotes } from '../models/viewRfq';
import { QuoteService } from '../services/quote.service';
import { RfqService } from '../services/rfq.service';
import { SortCriteria } from '../pipes/sort-asc-dsc.pipe';
import { ToastrNotificationService } from '../services/toastr-notification.service';
import { ViewRfqServiceService } from '../services/view-rfq-service.service';

@Component({
  selector: 'app-closed-rfq',
  templateUrl: './closed-rfq.component.html',
  styleUrls: ['./closed-rfq.component.css']
})
export class ClosedRfqComponent implements OnInit, OnDestroy, OnChanges {

  @Input() reloadClosedRfqsFromTab: boolean;
  @Output() quoteEvent = new EventEmitter<number>();
  @Output() rfqStatus = new EventEmitter<boolean>();
  @Output() openChatCond = new EventEmitter<boolean>();
  @Input() openChat: boolean;
  @Input() quote: Quote;
  @Output() changeClosedTab = new EventEmitter<boolean>();

  @Output() rfqArchivedState = new EventEmitter<string>();

  sendRfqArchivedState(value: string) {
    this.rfqArchivedState.emit(value);
  }

  @Input() closedRfqId: number;
  @Input() state: string;
  @Input() closedRfqArchived: string;
  @Input() closedRfqNav: string;

  @ViewChild('makePaymentBtn') makePaymentBtn: ElementRef;
  @ViewChild('makePaymentFinal') makePaymentFinal: ElementRef;
  @ViewChild('makePaymentClose') makePaymentClose: ElementRef;

  tabToInClosed(value: boolean) {
    this.changeClosedTab.emit(value);
  }
  //NEEDED FOR CREATING Q&A QUESTIONS AND REPLIES
  isBuyer: boolean = true;
  isSupplier: boolean = false;
  rfqStatusPC: string = "Closed";

  //NEEDED FOR SELECTED RFQ ON SIDEBAR 
  SideSelectedRFQ: ViewRfq;

  //NEEDED TO CHECK UNREAD MESSAGES ARRAY
  UnreadMsgsCount: number;

  //USED IN TEMPLATE TO DISPLAY  MESSAGES RED BLIP 
  unreadMessages: boolean;

  //NEEDED FOR GET QUESTIONS AND ANSWERS
  getQA: boolean = false;

  public criteria: SortCriteria;
  private subscriptionC: Subscription;

  sentQID: number;
  modalheader: string = 'green';
  nextmodal: boolean = false;
  dataLoading = false;
  account: Account;

  form: FormGroup;
  reviewForm: FormGroup;
  rfqStatusForm: FormGroup;
  inProgressQuoteform: FormGroup;
  otherQuoteform: FormGroup;

  closedRfqs: RfqHeadings[] = [];
  closedRfqsAll: RfqHeadings[] = [];
  closedRfqsUpdate: RfqHeadings[] = [];
  awaitPaymentRfqs: RfqHeadings[] = [];
  archivedRfqs: RfqHeadings[] = [];
  awaitPaymentRfqsUpdate: RfqHeadings[] = [];
  archivedRfqsUpdate: RfqHeadings[] = [];
  rfq: ViewRfq;
  rfqIndex: number;
  selectedRfqId: number;
  openQuotes: ViewRFQs_Quotes[];
  acceptedQuotes: ViewRFQs_Quotes[];
  updatedClosed: ReloadViewRfqs;
  selectedRFQ?: ViewRfq;
  RFQHeadings: string[] = [];
  selectedQuote?: Quote;
  sortbyHeadings: RFQHeaddisplay[] = [];
  sortbyHeadingsUpdate: RFQHeaddisplay[] = [];
  singleSortHeadings: RFQHeaddisplay = { rfqHeadName: null, rfq: null, id: null };
  headingId: number;
  check: boolean = false;
  paymentcond: boolean;
  rfqUsed: boolean = false;
  paymentDay: Date;
  reloadingSelectedRfq: boolean;

  //for rfq table search 
  title = 'custom-search-filter-example';
  searchedKeyword: string;
  searchrfq: string;
  searchdimensions: string;
  searchdrawings: string;
  searchpendingquotes: string;
  completedquotes: string;

  //Date Calcs
  public dateNow = new Date();
  public dDay?: Date;
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  reloadCond: boolean;

  onTimeDeliveryCond: boolean = null;
  correctMaterialGradeCond: boolean = null;
  correctQuantityOfPartsCond: boolean = null;
  supplierCommunicationCond: boolean = null;
  receivedTestCertificationCond: boolean = null;



  payDetails: MakePaymentRequest = { QuoteId: null, PaymentReference: null }
  //NEEDED FOR CHAT
  triggerChatOpen(value: boolean) {
    this.openChatCond.emit(value);
    this.openChat = value;

    //CHECKS IF SIDE NAVS RFQ ID = THE SELECTED RFQ AND IF CHAT IS OPENED ON SELECTED RFQ, THE BADGES DISSAPEARS
    if (value == true) {
      for (let siderfq of this.closedRfqs) {
        if (this.selectedRfqId == siderfq.rfqId) {
          this.selectedRFQ.acceptedQuotes[0].unreadPrivateMessages = false;
          this.acceptedQuotes[0].unreadPrivateMessages = false;
        }
      }

      for (let siderfq of this.closedRfqsUpdate) {
        if (this.selectedRfqId == siderfq.rfqId) {
          this.selectedRFQ.acceptedQuotes[0].unreadPrivateMessages = false;
          this.acceptedQuotes[0].unreadPrivateMessages = false;
        }
      }

    }

  }
  //NEEDED FOR CHAT
  Sendquotedata(value: number) {
    this.quoteEvent.emit(value);
    this.sentQID = value;
  }

  sendRfqStatus(value: boolean) {
    this.rfqStatus.emit(value);
  }

  private getTimeDifference() {
    this.timeDifference = this.dDay.getTime() - new Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
  }

  getPayDay(date) {

    this.paymentDay = new Date(date);
    if (this.selectedRFQ.paymentRequirement == "30 Days") {
      this.paymentDay.setDate(this.paymentDay.getDate() + 30);
    } else if (this.selectedRFQ.paymentRequirement == "60 Days") {
      this.paymentDay.setDate(this.paymentDay.getDate() + 60);
    }
    return this.paymentDay;
  }

  public getClosingTime(date) {

    this.dDay = new Date(date);
    if (this.selectedRFQ.paymentRequirement == "30 Days") {
      this.dDay.setDate(this.dDay.getDate() + 30);
    } else if (this.selectedRFQ.paymentRequirement == "60 Days") {
      this.dDay.setDate(this.dDay.getDate() + 60);
    }

    this.getTimeDifference();
    if (this.daysToDday < 0) {
      return 'Closed';
    } else {
      return this.daysToDday + ' Days ' + this.hoursToDday + ' Hrs ' + this.minutesToDday + ' Mins';
    }
  }

  private allocateTimeUnits(timeDifference) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }

  constructor(private quoteService: QuoteService, private rfqService: RfqService,
    private formBuilder: FormBuilder, public cdr: ChangeDetectorRef,
    private reloader: ViewRfqServiceService,
    public authService: AuthService, private router: Router,
    private toastrNotification: ToastrNotificationService, private fileDownloadedService: FileDownloadedService,
    private route: ActivatedRoute) {
    this.criteria = {
      property: 'supplierServices',
      descending: true
    };
    this.authService.account.subscribe(x => this.account = x);
  }

  test: string;

  ngOnChanges() {
    if (this.state == "Closed" && ((this.closedRfqsAll.length == 0 || this.closedRfqId == 0) || this.closedRfqNav == 'true')) {
      this.getClosedRFQS(this.closedRfqId);
    } else if (this.state == "Closed" && this.closedRfqsAll.length > 0 && this.closedRfqNav == 'false') {
      this.onSelectRFQ(this.closedRfqId);
    }
  }

  ngOnInit(): void {



    this.reloadingSelectedRfq = false;

    this.reloaded = false;
    this.modalheader = 'green';

    this.clearSortHeadings();

    // this.reloader.currentClosedRfqStay.subscribe(closedCond => {
    //   if (closedCond.reloadCondition) {
    //     this.reloadAfterComplete(closedCond.reloadRfqId);

    //   }
    // });

    // this.reloader.currentClosedRfq.subscribe(closedCond => {

    //   if (closedCond.reloadCondition) {
    //     this.reloadAfterComplete(closedCond.reloadRfqId);
    //     this.tabToInClosed(true);
    //   }
    // });

    this.form = this.formBuilder.group({
      paymentReference: ['', Validators.required],
    });

    this.rfqStatusForm = this.formBuilder.group({
      archived: [false,],
      awaitingPayment: [false,]
    });

    this.inProgressQuoteform = this.formBuilder.group({
      quoteFile: [false]
    });

    this.otherQuoteform = this.formBuilder.group({
      otherQuoteFile: [false]
    });

    this.reviewForm = this.formBuilder.group({

      onTimeDeliveryYes: [false, Validators.required],
      onTimeDeliveryNo: [false, Validators.required],
      onTimeDeliveryReason: ["", Validators.required],

      correctMaterialGradeYes: [false, Validators.required],
      correctMaterialGradeNo: [false, Validators.required],
      correctMaterialGradeReason: ["", Validators.required],


      correctQuantityYes: [false, Validators.required],
      correctQuantityNo: [false, Validators.required],
      correctQuantityReason: ["", Validators.required],


      receivedTestCertYes: [false, Validators.required],
      receivedTestCertNo: [false, Validators.required],
      receivedTestCertReason: ["", Validators.required],


      supplierCommunicateYes: [null, Validators.required],
      supplierCommunicateNo: [null, Validators.required],
      supplierCommunicateReason: ["", Validators.required],

      actualDeliveryDate: [null, Validators.required],

    });

    this.subscriptionC = interval(300000)
      .subscribe(x => { this.periodicReload(); });

    // this.route.queryParamMap.subscribe(params => {

    //   this.closedRfqNav = params.get('nav');

    // });

    //SETS RFQ SEARCH FORM VALUES TO FALSE
    this.RFQsearch = this.formBuilder.group({

      complete: [false],
      rawmat: [false],
      cod: [false],
      cbd: [false],
      thirtyDays: [false],
      sixtyDays: [false],
      paymentOther: [false],
      delivery: [false],
      collection: [false],
      boxed: [false],
      pallets: [false],
      strapped: [false],
      loose: [false],
      packagingOther: [false],
      testCertificationYes: [false],
      testCertificationNo: [false]

    });


  }

  ngOnDestroy() {
    this.subscriptionC.unsubscribe();
  }

  clearSortHeadings() {
    this.headingId = 1;
    this.singleSortHeadings.rfqHeadName = "Multiple Services";
    this.singleSortHeadings.rfq = [];
    this.sortbyHeadings.push(this.singleSortHeadings);
    this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
    this.headingId++;
  }

  togglePeriodicReload(cond) {
    if (cond) {
      this.rfqUsed = true;

    } else if (!cond) {
      this.rfqUsed = false;

    }
  }

  togglePubChat(cond) {
    if (cond) {
      // this.rfqUsed = true;
      this.getQA = true;
    } else if (!cond) {
      //  this.rfqUsed = false;
      this.getQA = false;
    }
  }


  reloaded: boolean = false;

  periodicReload() {

    if (this.rfqUsed == false && this.reloadClosedRfqsFromTab) {

      this.sortbyHeadingsUpdate = [];
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

      this.headingId = 1;
      this.singleSortHeadings.rfqHeadName = "Multiple Services";
      this.singleSortHeadings.rfq = [];
      this.singleSortHeadings.id = this.headingId;
      this.sortbyHeadingsUpdate.push(this.singleSortHeadings);
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
      this.headingId++;

      this.getClosedRFQSAfterUpdate();

    }
  }

  getClosedRFQSAfterUpdate() {


    this.reloadingSelectedRfq = true;

    this.awaitPaymentRfqsUpdate = [];
    this.archivedRfqsUpdate = [];
    var archiveRfqIds: number[] = [];
    var archiveRfqIdsOld: number[] = [];
    var awaitPaymentRfqIds: number[] = [];
    var awaitPaymentRfqIdsOld: number[] = [];
    var rfqSelect: RfqHeadings;

    this.rfqService.getRFQHeadings(this.account.account.buyerId, "Closed").subscribe(rfqs => {

      if (rfqs.length > 0) {

        this.closedRfqsUpdate = rfqs;
        this.reloaded = true;



        for (let rfq of rfqs) {
          rfq.SideSelectedRFQ = false;

          if (this.closedRfqsAll.length > 0) {
            if (rfq.rfqId == this.selectedRFQ.rfqId) {
              rfqSelect = rfq;
            }
          }

          if (rfq.archived == true) {
            this.archivedRfqsUpdate.push(rfq);


            archiveRfqIds.push(rfq.rfqId);
          } else {
            this.awaitPaymentRfqsUpdate.push(rfq);

            awaitPaymentRfqIds.push(rfq.rfqId)
          }


        }


        if (this.closedRfqsAll.length > 0) {

          var oldRfqIds: number[] = [];
          for (let oldRfq of this.closedRfqsAll) {
            oldRfqIds.push(oldRfq.rfqId)
          }

          var newRfqIds: number[] = [];
          for (let newRfq of this.closedRfqsUpdate) {
            newRfqIds.push(newRfq.rfqId)
          }


          for (let rfq of this.closedRfqsUpdate) {
            if (oldRfqIds.indexOf(rfq.rfqId) == -1) {
              this.closedRfqsAll.push(rfq);
            }
          }

          for (const { index, value } of this.closedRfqsAll.map((value, index) => ({ index, value }))) {
            if (newRfqIds.indexOf(value.rfqId) == -1) {
              this.closedRfqsAll.splice(index, 1);
            }
          }


          //IF FILTERS ARE USED , RFQ SIDENAV UPDATE
          if (this.filtersUsed == true) {
            this.filterUpdateRfqs(this.closedRfqsAll);
          }

          if (this.archivedRfqs.length > 0 && this.archivedRfqsUpdate.length > 0) {
            for (let oldArchivedRfq of this.archivedRfqs) {
              archiveRfqIdsOld.push(oldArchivedRfq.rfqId);
            }

            for (let archiveRfq of this.archivedRfqsUpdate) {
              if (archiveRfqIdsOld.indexOf(archiveRfq.rfqId) == -1) {
                this.archivedRfqs.push(archiveRfq);
              }
            }
          } else if (this.archivedRfqsUpdate.length > 0 && this.archivedRfqs.length == 0) {
            for (let archiveRfq of this.archivedRfqsUpdate) {
              this.archivedRfqs.push(archiveRfq);
            }
          } else if (this.archivedRfqsUpdate.length == 0) {
            this.archivedRfqs = [];
            this.g.archived.disable();
          }


          if (this.awaitPaymentRfqs.length > 0 && this.awaitPaymentRfqsUpdate.length > 0) {

            for (let oldAwaitPaymentRfq of this.awaitPaymentRfqs) {
              awaitPaymentRfqIdsOld.push(oldAwaitPaymentRfq.rfqId);
            }


            for (let rfq of this.awaitPaymentRfqsUpdate) {
              if (awaitPaymentRfqIdsOld.indexOf(rfq.rfqId) == -1) {
                this.awaitPaymentRfqs.push(rfq);
              }
            }

            for (const { index, value } of this.awaitPaymentRfqs.map((value, index) => ({ index, value }))) {
              if (awaitPaymentRfqIds.indexOf(value.rfqId) == -1) {
                this.awaitPaymentRfqs.splice(index, 1);
              }

            }
          } else if (this.awaitPaymentRfqs.length == 0 && this.awaitPaymentRfqsUpdate.length > 0) {
            for (let rfq of this.awaitPaymentRfqsUpdate) {
              this.awaitPaymentRfqs.push(rfq);
            }
          } else if (this.awaitPaymentRfqsUpdate.length == 0) {
            this.awaitPaymentRfqs = [];
            this.g.awaitingPayment.disable();
          }

          if (!rfqSelect.archived) {
            if (rfqSelect.archived == this.selectedRFQ.archived) {
              this.sortRFQHeadArrayUpdate(this.awaitPaymentRfqsUpdate);
            } else {
              // this.selectedRFQ = rfqSelect;
              this.awaitOrArchivedRfqs("await", rfqSelect.rfqId);
              this.sortRFQHeadArrayUpdate(this.awaitPaymentRfqsUpdate);
            }
          } else {
            if (rfqSelect.archived == this.selectedRFQ.archived) {
              this.sortRFQHeadArrayUpdate(this.archivedRfqsUpdate);
            } else {
              // this.selectedRFQ = rfqSelect;
              this.awaitOrArchivedRfqs("archived", rfqSelect.rfqId);
              this.sortRFQHeadArrayUpdate(this.archivedRfqsUpdate);
            }
          }

          this.replaceRfqsOnUpdate();
          this.onSelectRFQ(rfqSelect.rfqId);


        }

        else {

          this.closedRfqsAll = [];
          for (let rfq of this.closedRfqsUpdate) {
            this.closedRfqsAll.push(rfq);
          }

          this.clearSortHeadings();
          this.closedRfqs = [];
          this.archivedRfqs = [];
          this.awaitPaymentRfqs = [];

          for (let rfq of this.closedRfqsAll) {

            if (rfq.archived == true) {
              this.archivedRfqs.push(rfq);
            } else {
              this.awaitPaymentRfqs.push(rfq);
              this.closedRfqs.push(rfq);
            }
          }



          if (this.awaitPaymentRfqs.length > 0 && this.archivedRfqs.length > 0) {
            this.awaitOrArchivedRfqs('await', 0);
          } else if (this.awaitPaymentRfqs.length == 0 && this.archivedRfqs.length > 0) {
            this.awaitOrArchivedRfqs('archived', 0);
            this.g.awaitingPayment.disable();
          } else if (this.archivedRfqs.length == 0 && this.awaitPaymentRfqs.length > 0) {
            this.awaitOrArchivedRfqs('await', 0);
            this.g.archived.disable();
          }

        }


        // if (this.awaitPaymentRfqsUpdate.length == 0 && this.archivedRfqsUpdate.length > 0) {
        //   this.g.awaitingPayment.disable();
        // } else if (this.archivedRfqsUpdate.length == 0 && this.awaitPaymentRfqsUpdate.length > 0) {
        //   this.g.archived.disable();
        // }


      } else {
        this.closedRfqs = [];
        this.selectedRFQ = null;
      }

    });



  }

  replaceRfqsOnUpdate() {

    var newHeadings: string[] = [];
    var oldHeadings: string[] = [];

    for (let heading of this.sortbyHeadings) {
      oldHeadings.push(heading.rfqHeadName)
      for (let newHead of this.sortbyHeadingsUpdate) {
        if (heading.rfqHeadName == newHead.rfqHeadName) {
          heading.rfq = newHead.rfq;
          heading.openQuotesCounter = newHead.openQuotesCounter;

        }
      }
    }

    for (let newHeading of this.sortbyHeadingsUpdate) {
      newHeadings.push(newHeading.rfqHeadName);
    }

    //if (this.sortbyHeadings.length != this.sortbyHeadingsUpdate.length) {
    // if (this.sortbyHeadings.length < this.sortbyHeadingsUpdate.length) {

    for (let newHead of this.sortbyHeadingsUpdate) {
      if (oldHeadings.indexOf(newHead.rfqHeadName) == -1) {
        this.sortbyHeadings.push(newHead);
      }
    }

    // } else {
    for (const { index, value } of this.sortbyHeadings.map((value, index) => ({ index, value }))) {
      if (newHeadings.indexOf(value.rfqHeadName) == -1) {
        this.sortbyHeadings.splice(index, 1);
      }
      // }

      //}

    }

  }

  //Used for periodic updating of RFQS
  sortRFQHeadArrayUpdate(rfqs) {

    for (let indexrfq of rfqs) {
      for (let service of indexrfq.servicesRequired) {
        this.check = false;
        if (indexrfq.servicesRequired.length == 1) {
          for (let name of this.sortbyHeadingsUpdate) {
            if (service == name.rfqHeadName) {
              this.check = true;
            }
          }
          if (this.check == false) {

            this.singleSortHeadings.rfqHeadName = service;
            this.singleSortHeadings.rfq = [];
            this.singleSortHeadings.id = this.headingId;
            this.sortbyHeadingsUpdate.push(this.singleSortHeadings);
            this.headingId++;
            this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
          }
        }
      }
      if (indexrfq.servicesRequired.length > 1) {
        this.sortbyHeadingsUpdate[0].rfq.push(indexrfq);

      }


    }

    this.populateRFQHeadArrayUpdate(rfqs);



    this.cdr.detectChanges();

    this.getClosingTime(Date);
  }


  populateRFQHeadArrayUpdate(rfqs): void {
    for (let indexrfq of rfqs) {
      for (let service of indexrfq.servicesRequired) {
        if (indexrfq.servicesRequired.length == 1) {
          for (let val of this.sortbyHeadingsUpdate) {
            if (val.rfqHeadName == service) {
              val.rfq.push(indexrfq)

            }
          }
        }

      }
    }
    if (this.sortbyHeadingsUpdate[0].rfq.length == 0) {
      this.sortbyHeadingsUpdate.splice(0, 1);
    }

    // LOGIC FOR SUPPLIER USER ACTIONS
    for (let heading of this.sortbyHeadingsUpdate) {
      for (let rfq of heading.rfq) {
        heading.openQuotesCounter = 0;
        if (rfq.acceptedQuotes[0].paymentReference == null && rfq.acceptedQuotes[0].invoiceQuoteFileId != null) {

          heading.openQuotesCounter = 1;
          break;
        }

      }


    }

  }


  reloadAfterComplete(rfqId) {

    this.closedRfqsAll = [];
    this.closedRfqs = [];
    this.archivedRfqs = [];
    this.awaitPaymentRfqs = [];
    this.sortbyHeadings = [];
    this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

    this.clearSortHeadings();

    this.getClosedRFQSAfterComplete(rfqId);

  }


  getClosedRFQSAfterComplete(rfqId) {


    this.reloaded = false;

    this.dataLoading = true;

    this.rfqService.getRFQHeadings(this.account.account.buyerId, "Closed").pipe(
      finalize(() => this.dataLoading = false)
    ).subscribe(rfqs => {

      this.closedRfqsAll = rfqs;

      for (const { index, value } of rfqs.map((value, index) => ({ index, value }))) {

        if (value.rfqId == rfqId) {
          if (value.archived == false) {
            this.g.awaitingPayment.setValue(true);
            this.g.archived.setValue(false);
            for (let rfq of rfqs) {

              if (rfq.archived == true) {
                this.archivedRfqs.push(rfq);
              } else {
                this.awaitPaymentRfqs.push(rfq);
                this.closedRfqs.push(rfq);
              }

            }

            this.onSelectRFQ(rfqs[index].rfqId);

          } else {
            this.g.archived.setValue(true);
            this.g.awaitingPayment.setValue(false);
            for (let rfq of rfqs) {

              if (rfq.archived == true) {
                this.archivedRfqs.push(rfq);
                this.closedRfqs.push(rfq);
              } else {
                this.awaitPaymentRfqs.push(rfq);
              }
            }
            this.onSelectRFQ(rfqs[index].rfqId);
          }
        }
      }

      if (this.archivedRfqs.length == 0) {
        this.g.archived.disable();
      } else {
        this.g.archived.enable();
      }

      if (this.awaitPaymentRfqs.length == 0) {
        this.g.awaitingPayment.disable();
      } else {
        this.g.awaitingPayment.enable();
      }

      this.sortRFQHeadArray();

    });

  }

  getClosedRFQS(selectedRfqId) {

    this.closedRfqsAll = [];
    this.closedRfqs = [];
    this.archivedRfqs = [];
    this.awaitPaymentRfqs = [];
    this.sortbyHeadings = [];
    this.selectedRFQ = null;

    this.dataLoading = true;

    this.rfqService.getRFQHeadings(this.account.account.buyerId, "Closed").pipe(
      finalize(() => this.dataLoading = false)
    ).subscribe(rfqs => {

      if (rfqs.length > 0) {

        this.closedRfqsAll = rfqs;

        for (let rfq of rfqs) {
          if (rfq.archived == true) {
            this.archivedRfqs.push(rfq);
          } else {
            this.awaitPaymentRfqs.push(rfq);
            // this.closedRfqs.push(rfq);
          }
        }

        if (selectedRfqId == 0) {

          if (this.closedRfqArchived != 'false' && this.closedRfqArchived != 'true' && this.closedRfqArchived != '') {
            this.router.navigate(['404']);
          } else {

            if (this.awaitPaymentRfqs.length > 0 && this.archivedRfqs.length > 0) {
              this.awaitOrArchivedRfqs('await', selectedRfqId);
            } else if (this.awaitPaymentRfqs.length == 0 && this.archivedRfqs.length > 0) {
              this.awaitOrArchivedRfqs('archived', selectedRfqId);
              this.g.awaitingPayment.disable();
            } else if (this.archivedRfqs.length == 0 && this.awaitPaymentRfqs.length > 0) {
              this.awaitOrArchivedRfqs('await', selectedRfqId);
              this.g.archived.disable();
            }
          }

        } else {

          if (this.closedRfqArchived != 'false' && this.closedRfqArchived != 'true') {
            this.router.navigate(['404']);
          } else {
            if (this.closedRfqArchived == 'true') {
              this.awaitOrArchivedRfqs('archived', selectedRfqId);
              if (this.awaitPaymentRfqs.length == 0) {
                this.g.awaitingPayment.disable();
              } else {
                this.g.awaitingPayment.enable();
              }
              if (this.archivedRfqs.length > 0) {
                this.g.archived.enable();
              } else {
                this.g.archived.disable();
              }
            } else if (this.closedRfqArchived == 'false') {
              this.awaitOrArchivedRfqs('await', selectedRfqId);
              if (this.archivedRfqs.length == 0) {
                this.g.archived.disable();
              } else {
                this.g.archived.enable();
              }
              if (this.awaitPaymentRfqs.length > 0) {
                this.g.awaitingPayment.enable();
              } else {
                this.g.awaitingPayment.disable();
              }
            }
          }
        }

      }

    });

  }

  awaitOrArchivedRfqs(type, rfqId) {

    this.closedRfqs = [];
    if (type == 'await') {

      this.g.awaitingPayment.setValue(true);
      this.g.archived.setValue(false);

      for (let rfq of this.awaitPaymentRfqs) {
        this.closedRfqs.push(rfq);
      }

    } else if (type == 'archived') {

      this.g.awaitingPayment.setValue(false);
      this.g.archived.setValue(true);

      for (let rfq of this.archivedRfqs) {
        this.closedRfqs.push(rfq);
      }



    }

    this.sortbyHeadings = [];
    this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
    this.clearSortHeadings();
    this.sortRFQHeadArray();

    if (rfqId == 0) {
      this.router.navigate(['buyer-home/view-rfq', 'Closed', this.sortbyHeadings[0].rfq[0].rfqId, 'false'], {
        queryParams: { archived: this.sortbyHeadings[0].rfq[0].archived + "" }
      });
    } else {
      this.onSelectRFQ(rfqId);
    }


  }

  switchAwaitArchive(type) {
    this.closedRfqs = [];
    if (type == 'await') {

      this.g.awaitingPayment.setValue(true);
      this.g.archived.setValue(false);

      for (let rfq of this.awaitPaymentRfqs) {
        this.closedRfqs.push(rfq);
      }

    } else if (type == 'archived') {

      this.g.awaitingPayment.setValue(false);
      this.g.archived.setValue(true);

      for (let rfq of this.archivedRfqs) {
        this.closedRfqs.push(rfq);
      }

    }

    this.sortbyHeadings = [];
    this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
    this.clearSortHeadings();
    this.sortRFQHeadArray();
  }

  sortRFQHeadArray() {
    for (let indexrfq of this.closedRfqs) {
      for (let service of indexrfq.servicesRequired) {
        this.check = false;
        if (indexrfq.servicesRequired.length == 1) {
          for (let name of this.sortbyHeadings) {
            if (service == name.rfqHeadName) {
              this.check = true;
            }
          }
          if (this.check == false) {

            this.singleSortHeadings.rfqHeadName = service;
            this.singleSortHeadings.rfq = [];
            this.singleSortHeadings.id = this.headingId;
            this.sortbyHeadings.push(this.singleSortHeadings);
            this.headingId++;
            this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
          }
        }
      }
      if (indexrfq.servicesRequired.length > 1) {
        this.sortbyHeadings[0].rfq.push(indexrfq);

      }

    }

    this.populateRFQHeadArray();


  }



  populateRFQHeadArray(): void {
    for (let indexrfq of this.closedRfqs) {
      for (let service of indexrfq.servicesRequired) {
        if (indexrfq.servicesRequired.length == 1) {
          for (let val of this.sortbyHeadings) {
            if (val.rfqHeadName == service) {
              val.rfq.push(indexrfq)

            }
          }
        }

      }
    }
    if (this.sortbyHeadings[0].rfq.length == 0) {
      this.sortbyHeadings.splice(0, 1);
    }

    // LOGIC FOR SUPPLIER USER ACTIONS
    for (let heading of this.sortbyHeadings) {
      for (let rfq of heading.rfq) {
        heading.openQuotesCounter = 0;
        if (rfq.acceptedQuotes[0].paymentReference == null && rfq.acceptedQuotes[0].invoiceQuoteFileId != null) {
          heading.openQuotesCounter = 1;
          break;
        }

      }
    }

    // sort Rfq Heading in Asc order using String.prototype.localCompare()
    this.sortbyHeadings.sort((a, b) =>
      a.rfqHeadName.localeCompare(b.rfqHeadName)
    );

      // Re-inserts Multiple Services heading to first position in array
    var index = this.sortbyHeadings.findIndex(x => x.rfqHeadName == "Multiple Services");
    if(index != -1){
    var head = this.sortbyHeadings.filter(x => x.rfqHeadName == "Multiple Services")[0];
    this.sortbyHeadings.splice(index, 1);
    this.sortbyHeadings.splice(0, 0, head);
    }

  }

  selectedHeadingRfq: RfqHeadings;
  loadingRfq: boolean = false;

  triggerSelectRfqLoading() {
    this.reloadingSelectedRfq = false;
  }

  loadingRfqSpinnerId: number;
  newQuotes: boolean = true;

  onSelectRFQNav(rfq: RfqHeadings): void {
    this.loadingRfqSpinnerId = rfq.rfqId;
    this.newQuotes = true;
    this.router.navigate(['buyer-home/view-rfq', 'Closed', rfq.rfqId, 'false'], {
      queryParams: { archived: rfq.archived }
    });
  }

  onSelectRFQ(rfqId: number): void {

    this.selectedHeadingRfq = this.closedRfqsAll.filter(x => x.rfqId == rfqId)[0];
    this.selectedRfqId = rfqId;

    if (!this.reloadingSelectedRfq) {
      this.loadingRfq = true;
    }


    this.rfqService.getRFQById(rfqId).pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.state == "Closed") {

            if (this.newQuotes == true) {
              this.openQuotes = response.openQuotes;
              this.acceptedQuotes = response.acceptedQuotes;
              this.newQuotes = false;
            } else {
              if (response.acceptedQuotes.length > 0) {
                this.reloadAcceptedQuotes(response.acceptedQuotes)
              }
            }

            if ((response.archived && this.closedRfqArchived == "false")) {
              this.switchAwaitArchive('archived');
              this.router.navigate(['buyer-home/view-rfq', 'Closed', response.rfqId, 'false'], {
                queryParams: { archived: response.archived + "" }
              });

            } else if ((!response.archived && this.closedRfqArchived == "true")) {
              this.switchAwaitArchive('await');
              this.router.navigate(['buyer-home/view-rfq', 'Closed', response.rfqId, 'false'], {
                queryParams: { archived: response.archived + "" }
              });
            } else if (this.closedRfqNav == 'true') {
              this.router.navigate(['buyer-home/view-rfq', 'Closed', response.rfqId, 'false'], {
                queryParams: { archived: response.archived + "" }
              });
            }

            //INDICATES WHICH THING RFQ BUTTON IS SELECTED FOR THE CLOSED RFQs OBJECT
            if (this.closedRfqsAll.length > 0) {
              for (let siderfq of this.closedRfqsAll) {
                if (this.selectedRfqId == siderfq.rfqId) {
                  siderfq.SideSelectedRFQ = true;
                }
                else {
                  siderfq.SideSelectedRFQ = false;
                }

              }
            }

            if (this.closedRfqsUpdate.length > 0) {
              for (let siderfq of this.closedRfqsUpdate) {
                if (this.selectedRfqId == siderfq.rfqId) {
                  siderfq.SideSelectedRFQ = true;
                }
                else {
                  siderfq.SideSelectedRFQ = false;
                }

              }
            }


            //SETS COUNT FOR UNREAD MESSAGES ARRAY
            //CHECKS IF CHAT HISTORY ARRAY EXISTS
            if (this.selectedHeadingRfq.acceptedQuotes[0].unreadPrivateChatHistory) {
              this.UnreadMsgsCount = this.selectedHeadingRfq.acceptedQuotes[0].unreadPrivateChatHistory.length;

            }


            //CHECKS FOR UNREAD MESSAGES NEEDED FOR RED BLIP

            for (let quote of this.selectedHeadingRfq.acceptedQuotes) {

              if (quote.unreadPrivateMessages == true) {
                this.unreadMessages = true;

              }
              if (quote.unreadPrivateMessages == false) {
                this.unreadMessages = false;
              }


            }


            this.selectedRFQ = response;
            this.dDay = this.selectedRFQ.rfqClosingDate;
            this.sentQID = this.selectedRFQ.acceptedQuotes[0].quoteId;
            for (const { index, value } of this.closedRfqs.map((value, index) => ({ index, value }))) {
              if (value.rfqId == this.selectedRFQ.rfqId) {
                this.rfqIndex = index;
              }
            }
            this.loadingRfq = false
            this.sendRfqArchivedState(this.selectedRFQ.archived + "");

            // CHECKS FOR INVOCIE ORDER DOWLOADED
            if (this.selectedRFQ.acceptedQuotes[0].invoiceQuoteFileId) {
              this.fileDownloadedService.getFileDownloadedCheck(this.selectedRFQ.acceptedQuotes[0].invoiceQuoteFileId, this.account.account.userId).subscribe(check => {
                this.selectedRFQ.acceptedQuotes[0].invoiceOrderDownloaded = check;
                this.acceptedQuotes[0].invoiceOrderDownloaded = check;

              });
            }

          } else {
            this.router.navigate(['buyer-home/view-rfq', 'Closed', 0, 'false'], {
              queryParams: { archived: null }
            });
            this.toastrNotification.error("Invalid Selected Quote")
          }

        },
        error: error => {
          this.router.navigate(['buyer-home/view-rfq', 'Closed', this.sortbyHeadings[0].rfq[0].rfqId, 'false'], {
            queryParams: { archived: this.sortbyHeadings[0].rfq[0].archived + "" }
          });
          this.toastrNotification.error("Invalid Selected Rfq");
          this.loadingRfq = false
        }

      });

  }


  reloadAcceptedQuotes(newQuotes: ViewRFQs_Quotes[]) {

    for (let currentQuote of this.acceptedQuotes) {
      for (let replaceQuote of newQuotes) {
        if (replaceQuote.quoteId == currentQuote.quoteId) {
          currentQuote.state = replaceQuote.state;
          currentQuote.paymentReference = replaceQuote.paymentReference;
          currentQuote.paymentReceived = replaceQuote.paymentReceived;
          currentQuote.salesOrderFileName = replaceQuote.salesOrderFileName;
          currentQuote.salesQuoteFileId = replaceQuote.salesQuoteFileId;
          currentQuote.productCertificationFileName = replaceQuote.productCertificationFileName;
          currentQuote.productCertificationQuoteFileId = replaceQuote.productCertificationQuoteFileId;
          currentQuote.invoiceOrderFileName = replaceQuote.invoiceOrderFileName;
          currentQuote.invoiceQuoteFileId = replaceQuote.invoiceQuoteFileId;
          currentQuote.salesOrderCreatedAt = replaceQuote.salesOrderCreatedAt;
          currentQuote.invoiceCreatedAt = replaceQuote.invoiceCreatedAt;
          currentQuote.productCertCreatedAt = replaceQuote.productCertCreatedAt;
          currentQuote.supplierDeliveryMade = replaceQuote.supplierDeliveryMade;
          currentQuote.buyerCollectionMade = replaceQuote.buyerCollectionMade;
          currentQuote.buyerDeliveryMade = replaceQuote.buyerDeliveryMade;
          currentQuote.supplierCollectionMade = replaceQuote.supplierCollectionMade;
          currentQuote.supplierDetails = replaceQuote.supplierDetails;
        }
      }
    }
  }

  onNext() {
    this.nextmodal = true;
  }

  supplierAcceptPaymentCheck() {
    if (this.selectedRFQ.acceptedQuotes[0].paymentReceived) {
      return true;
    } else {
      return false;
    }
  }

  stringValidation(value) {
    if (value.replace(/ /g, "") == "") {
      return true
    } else {
      return false;
    }
  }

  makePayment() {

    if (this.f.paymentReference.value.replace(/ /g, "") != '' && this.f.paymentReference.value.length < 50) {

      this.makingPayment = true;

      this.payDetails = { QuoteId: null, PaymentReference: null }

      this.payDetails.PaymentReference = this.f.paymentReference.value;
      this.payDetails.QuoteId = this.selectedRFQ.acceptedQuotes[0].quoteId;

      this.rfqService.makePayment(this.payDetails).pipe(first())
        .subscribe({
          next: (response: any) => {
            this.makePaymentFinal.nativeElement.click();
            this.makingPayment = false;
            this.f.paymentReference.setValue('');
          },
          error: error => {
            this.toastrNotification.error("Rfq Payment Failed");
            this.makingPayment = false;
          }

        });

    } else {
      this.toastrNotification.error("Invalid Payment Reference");
    }
  }

  paymentValid() {

    if (this.f.paymentReference.value.replace(/ /g, "") == '' || this.f.paymentReference.value.length > 50) {
      return false;
    } else {
      return true;
    }

  }

  paymentValidCond: boolean;

  paymentValidCheck() {
    if (!this.paymentValid()) {
      this.paymentValidCond = false;
    } else {
      this.paymentValidCond = true;
    }
  }

  makingPayment: boolean = false;
  paymentAvailable: boolean = false;

  makePaymentCheck(rfqId, cond) {
    this.togglePeriodicReload(true);

    this.paymentAvailable = true;

    this.rfqService.getPaymentMadeCheck(this.selectedRFQ.acceptedQuotes[0].quoteId).subscribe(x => {
      if (!x) {

        if (cond == "Initial") {
          this.fileDownloadedService.getFileDownloadedCheck(this.selectedRFQ.acceptedQuotes[0].invoiceQuoteFileId, this.account.account.userId).subscribe(check => {

            if (check) {
              this.makePaymentBtn.nativeElement.click();
            } else {
              this.toastrNotification.info("Download And Confirm Final Invoice File Before Making Payment");
            }
            this.paymentAvailable = false;
          });

        } else if (cond == "Final") {
          this.makePayment();
          this.paymentAvailable = false;
        }
      } else {
        this.toastrNotification.info("Payment has already been made for Rfq " + this.selectedRFQ.rfqName);
        this.togglePeriodicReload(false);

        if (cond == "Initial") {
          this.reloadAfterComplete(rfqId)
        } else if (cond == "Final") {
          this.makePaymentClose.nativeElement.click();
          this.reloadAfterComplete(rfqId)
        }
        this.paymentAvailable = false;
      }

    });
  }



  paymentCheck() {
    if (this.selectedRFQ.acceptedQuotes[0].paymentReference == null) {
      return false;
    } else {
      return true;
    }
  }

  triggerReloadRfqs(value: boolean) {
    if (value) {
      this.sortbyHeadings = [];
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
      this.clearSortHeadings();
      this.getClosedRFQSAfterEdit(this.selectedRFQ.rfqId);
    }

  }

  getClosedRFQSAfterEdit(rfqId) {

    this.dataLoading = true;

    this.rfqService.getRFQHeadings(this.account.account.buyerId, "In Progress").pipe(
      finalize(() => this.dataLoading = false)
    ).subscribe(rfqs => {
      this.closedRfqs = rfqs;
      for (const { index, value } of this.closedRfqs.map((value, index) => ({ index, value }))) {
        if (value.rfqId == rfqId) {
          this.onSelectRFQ(rfqs[index].rfqId);
        }
      }
      this.sortRFQHeadArray();
    });
  }

  OpenRFQModal() {
    const rfqModal = document.getElementById("createfqmodal");
    rfqModal.setAttribute('style', "display:block;");
  }

  @ViewChild('RFQReviewBtn') RFQReviewBtn: ElementRef;
  quoteReview: quoteReview;
  rfqReview: FullRfqReview;
  loadingQuoteReview: boolean = false;
  loadingRfqReview: boolean = false;


  getQuoteReview(quoteId) {

    this.loadingQuoteReview = true;

    this.quoteService.getQuoteReview(quoteId).pipe(first())
      .subscribe({
        next: (response: any) => {

          this.quoteReview = response;
          this.getRfqReview();
          this.loadingQuoteReview = false;

          // FOR BUYER DELIVERY DATE CALC
          this.getDaydiffdeliveryBuyer(this.quoteReview.actualDeliveryDate);


        },


        error: error => {
          this.toastrNotification.error("Quote Review Get Failed");
          this.loadingQuoteReview = false;
        }
      });


  }

  getRfqReview() {

    this.loadingRfqReview = true;

    this.rfqService.getRfqReview(this.selectedRFQ.rfqId).pipe(first())
      .subscribe({
        next: (response: any) => {
          this.rfqReview = response;

          this.onTimeDeliveryCheck(this.quoteReview.onTimeDelivery);
          this.correctMaterialGradeCheck(this.quoteReview.correctMaterialGrade);
          this.correctQuantityOfPartsCheck(this.quoteReview.correctQuantity);

          if (this.quoteReview.goodCommunication != null) {
            this.supplierCommunicationCheck(this.quoteReview.goodCommunication);
          }

          if (this.selectedRFQ.productCertificationRequirements) {
            this.receivedTestCertificationCheck(this.quoteReview.correctTestCertification);
          }

          this.reviewForm.disable();

          this.RFQReviewBtn.nativeElement.click();

          this.loadingRfqReview = false;

          // FOR SUPPLIER DELIVERY DATE CALC
          this.getDaydiffdeliverySupplier(this.rfqReview.actualDeliveryDate);
        },



        error: error => {
          this.toastrNotification.error("Rfq Review Get Failed");
          this.loadingRfqReview = false;
        }

      });



  }

  onTimeDeliveryCheck(answer) {
    this.onTimeDeliveryCond = answer;
    if (answer) {
      this.revf.onTimeDeliveryNo.setValue(false);
      this.revf.onTimeDeliveryYes.setValue(true);
    } else {
      this.revf.onTimeDeliveryYes.setValue(false);
      this.revf.onTimeDeliveryNo.setValue(true);
      this.revf.onTimeDeliveryReason.setValue(this.quoteReview.onTimeDeliveryReason);
    }

  }

  correctMaterialGradeCheck(answer) {
    this.correctMaterialGradeCond = answer;
    if (answer) {
      this.revf.correctMaterialGradeNo.setValue(false);
      this.revf.correctMaterialGradeYes.setValue(true);
    } else {
      this.revf.correctMaterialGradeYes.setValue(false);
      this.revf.correctMaterialGradeNo.setValue(true);
      this.revf.correctMaterialGradeReason.setValue(this.quoteReview.correctMaterialGradeReason);
    }

  }

  correctQuantityOfPartsCheck(answer) {
    this.correctQuantityOfPartsCond = answer;
    if (answer) {
      this.revf.correctQuantityNo.setValue(false);
      this.revf.correctQuantityYes.setValue(true);
    } else {
      this.revf.correctQuantityYes.setValue(false);
      this.revf.correctQuantityNo.setValue(true);
      this.revf.correctQuantityReason.setValue(this.quoteReview.correctQuantityReason);
    }

  }

  supplierCommunicationCheck(answer) {

    this.supplierCommunicationCond = answer;
    if (answer) {
      this.revf.supplierCommunicateNo.setValue(false);
      this.revf.supplierCommunicateYes.setValue(true);
    } else {
      this.revf.supplierCommunicateYes.setValue(false);
      this.revf.supplierCommunicateNo.setValue(true);
      this.revf.supplierCommunicateReason.setValue(this.quoteReview.goodCommunicationReason);
    }


  }

  receivedTestCertificationCheck(answer) {
    this.receivedTestCertificationCond = answer;
    if (answer) {
      this.revf.receivedTestCertNo.setValue(false);
      this.revf.receivedTestCertYes.setValue(true);
    } else {
      this.revf.receivedTestCertYes.setValue(false);
      this.revf.receivedTestCertNo.setValue(true);
      this.revf.receivedTestCertReason.setValue(this.quoteReview.correctTestCertificationReason);
    }

  }

  exportingRfq: boolean = false;

  exportRfq() {

    this.exportingRfq = true;

    this.rfqService.getRfqExportZip(this.selectedRFQ.rfqId).pipe(first())
      .subscribe({
        next: (response: any) => {

          var zipFileName = this.selectedRFQ.rfqName + " Export.zip"
          this.downloadFile(response, zipFileName);
          this.exportingRfq = false;
        },
        error: error => {
          this.toastrNotification.error("Export Rfq Failed");
          this.exportingRfq = false;
        }
      });

  }

  //FILES
  @ViewChild('clearPOInput', { static: false }) clearPOInputVar: ElementRef;

  @ViewChild('clearPPInput', { static: false }) clearPPInput: ElementRef;

  proofOfPaymentUploading: boolean = false;
  proofOfPaymentDeleting: boolean = false;

  resetTCInput() {
    this.clearPOInputVar.nativeElement.value = "";
  }

  purchaseOrderFile: File;
  proofOfPaymentFile: File;
  purchaseOrderUpload: UploadRfqFile = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
  proofOfPaymentUpload: UploadRfqFile = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
  requiredFileType: string = ".pdf";
  deliveryNoteUploading: boolean = false;
  deliveryNoteDeleting: boolean = false;

  deliveryNoteSelected(event) {

    this.purchaseOrderUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };

    this.purchaseOrderFile = event.target.files[0];
    const reader = new FileReader();

    if (this.purchaseOrderFile) {

      reader.readAsDataURL(this.purchaseOrderFile);
      reader.onload = () => {
        this.purchaseOrderUpload.file = (reader.result + "").substring((reader.result + "").indexOf(",") + 1, (reader.result + "").length);
        this.purchaseOrderUpload.quoteId = this.selectedRFQ.acceptedQuotes[0].quoteId;
        this.purchaseOrderUpload.rfqId = this.selectedRFQ.rfqId;
        this.purchaseOrderUpload.buyerId = this.account.account.buyerId;
        this.purchaseOrderUpload.userId = this.account.account.userId;;
        this.purchaseOrderUpload.supplierId = null;
        this.purchaseOrderUpload.fileName = this.purchaseOrderFile.name;
        this.rfqService.uploadPurchaseOrderFile(this.purchaseOrderUpload).subscribe(x => {

          if (this.selectedRFQ.purchaseOrderFileId != null) {
            this.rfqService.deleteRfqDrawingFile(this.selectedRFQ.purchaseOrderFileId).subscribe(y => {
              this.triggerReloadRfqs(true);
            });
          } else {
            this.triggerReloadRfqs(true);
          }
          this.clearPOInputVar.nativeElement.value = "";
        });
      };
    }
  }

  proofOfPaymentSelected(event) {

    this.proofOfPaymentUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };

    this.proofOfPaymentFile = event.target.files[0];
    const reader = new FileReader();

    this.proofOfPaymentUploading = true;


    if (this.proofOfPaymentFile) {

      reader.readAsDataURL(this.proofOfPaymentFile);
      reader.onload = () => {
        this.proofOfPaymentUpload.file = (reader.result + "").substring((reader.result + "").indexOf(",") + 1, (reader.result + "").length);
        this.proofOfPaymentUpload.quoteId = this.selectedRFQ.acceptedQuotes[0].quoteId;
        this.proofOfPaymentUpload.rfqId = this.selectedRFQ.rfqId;
        this.proofOfPaymentUpload.buyerId = this.account.account.buyerId;
        this.proofOfPaymentUpload.userId = this.account.account.userId;;
        this.proofOfPaymentUpload.supplierId = null;
        this.proofOfPaymentUpload.fileName = this.proofOfPaymentFile.name;
        this.rfqService.uploadProofOfPaymentFile(this.proofOfPaymentUpload).pipe(
          finalize(() => this.proofOfPaymentUploading = false)).subscribe(x => {

            if (this.selectedRFQ.proofOfPaymentFileId != null) {
              this.proofOfPaymentDeleting = true;
              this.rfqService.deleteRfqDrawingFile(this.selectedRFQ.proofOfPaymentFileId).pipe(
                finalize(() => this.proofOfPaymentDeleting = false)).subscribe(y => {
                  // this.triggerReloadRfqs(true);
                  this.rfqService.getRfqFileDetails(this.selectedRFQ.rfqId, "ProofOfPayment").subscribe(x => {
                    this.selectedRFQ.proofOfPaymentFileId = x.rfqFileId;
                    this.selectedRFQ.proofOfPaymentFileName = x.rfqFileName;
                  });
                });
            } else {
              // this.triggerReloadRfqs(true);
              this.rfqService.getRfqFileDetails(this.selectedRFQ.rfqId, "ProofOfPayment").subscribe(x => {
                this.selectedRFQ.proofOfPaymentFileId = x.rfqFileId;
                this.selectedRFQ.proofOfPaymentFileName = x.rfqFileName;
              });
            }
            this.clearPPInput.nativeElement.value = "";
          });
      };
    }
  }


  downloadSalesOrderFile(quoteFileId, quoteId) {
    this.quoteService.getQuoteFile(quoteFileId, this.account.account.userId, "Buyer").subscribe({
      next: (response: any) => {
        this.downloadFile(response.fileContent, response.fileName);

      },
      error: error => {
        this.toastrNotification.info("Sales Order File out of date, new Sales Order File loaded. Please retry download")
        this.quoteService.getQuoteFileDetails(quoteId, "SalesOrders").subscribe(x => {
          this.selectedRFQ.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].salesQuoteFileId = x.quoteFileId;
          this.selectedRFQ.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].salesOrderFileName = x.quoteFileName;
          this.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].salesQuoteFileId = x.quoteFileId;
          this.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].salesOrderFileName = x.quoteFileName;
        });
      }
    });
  }

  downloadInvoiceFile(quoteFileId) {
    this.quoteService.getInvoiceFile(quoteFileId).subscribe(file => {
      this.downloadFile(file.fileContent, file.fileName);
    })
  }

  downloadProductCertificationFile(quoteFileId, quoteId) {
    this.quoteService.getProductCertificationFile(quoteFileId).subscribe(file => {
      this.quoteService.getQuoteFile(quoteFileId, this.account.account.userId, "Buyer").subscribe({
        next: (response: any) => {
          this.downloadFile(response.fileContent, response.fileName);

        },
        error: error => {
          this.toastrNotification.info("Test Certification File out of date, new Test Certification File loaded. Please retry download")
          this.quoteService.getQuoteFileDetails(quoteId, "ProductCertification").subscribe(x => {
            this.selectedRFQ.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].productCertificationQuoteFileId = x.quoteFileId;
            this.selectedRFQ.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].productCertificationFileName = x.quoteFileName;
            this.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].productCertificationQuoteFileId = x.quoteFileId;
            this.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].productCertificationFileName = x.quoteFileName;
          });
        }
      });
    })
  }

  downloadingQuoteFile: boolean = false;
  downloadingInvoiceFile: boolean = false;

  downloadIPQuoteFiles(quoteFileId, quoteId, type) {

    if (type == "Invoices") {
      this.downloadingInvoiceFile = true;
    } else {
      this.downloadingQuoteFile = true;
    }

    this.quoteService.getQuoteFile(quoteFileId, this.account.account.userId, "Buyer").subscribe({
      next: (response: any) => {

        var FileContent = response.fileContent;
        var FileName = response.fileName;
        this.downloadFile(FileContent, FileName);
        this.downloadingQuoteFile = false;
        this.downloadingInvoiceFile = false;

        if (type == "Invoices") {
          //  SETS INVOICE DOWNLOAD BOOL TO TRUE
          this.selectedRFQ.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].invoiceOrderDownloaded = true;
          this.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].invoiceOrderDownloaded = true;

        }

      },
      error: error => {

        if (type == "Invoices") {
          this.toastrNotification.info("Final Invoice File out of date, new Final Invoice File loaded. Please retry download")
          this.quoteService.getQuoteFileDetails(quoteId, "Invoices").subscribe(x => {
            this.selectedRFQ.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].invoiceQuoteFileId = x.quoteFileId;
            this.selectedRFQ.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].invoiceOrderFileName = x.quoteFileName;
            this.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].invoiceQuoteFileId = x.quoteFileId;
            this.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].invoiceOrderFileName = x.quoteFileName;
            this.downloadingQuoteFile = false;
            this.downloadingInvoiceFile = false;
          });
        } else if (type == "Quotes") {
          this.downloadingQuoteFile = false;
          this.downloadingInvoiceFile = false;
        }

      }
    });

  }


  downloadOtherQuoteFiles(quoteId) {
    if (this.oqf.otherQuoteFile.value) {
      this.rfqService.getQuoteFile(quoteId, this.account.account.userId).subscribe(x => {
        var FileContent = x[0].fileContent;
        var FileName = x[0].fileName;
        this.downloadFile(FileContent, FileName);
      });
      this.oqf.otherQuoteFile.setValue(false);
    }
  }

  downloadDrawingFile(rfqFileId) {
    this.rfqService.getRfqDrawingFile(rfqFileId).subscribe(x => {
      this.downloadFile(x.fileContent, x.fileName)
    })
  }


  downloadFile(base64String, fileName) {
    // Download PDF in Chrome etc.
    const source = `data:application/;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}`
    link.click();

  }

  //FORM CONTROL GETS
  get f() { return this.form.controls; }
  get g() { return this.rfqStatusForm.controls; }
  get ipqf() { return this.inProgressQuoteform.controls; }
  get oqf() { return this.otherQuoteform.controls; }

  get revf() { return this.reviewForm.controls; }



  // TOGGLES PRIVATE CHAT BADGE TO FALSE WHEN PUBLIC CHAT IS OPENED
  togglePrivateChatbadge() {
    this.selectedHeadingRfq.acceptedQuotes[0].unreadPrivateMessages = false;
  }






  // RFQ NAV SEARCH FORM
  RFQsearch: FormGroup;
  // RFQ SEARCH FORM
  get rs() { return this.RFQsearch.controls; }

  //  CLEAR RFQ SEARCH FILTER VALUES
  filtersUsed: boolean = false;
  filteredNavRfqs: RfqHeadings[] = [];
  filteredNavRfqsUpdate: RfqHeadings[] = [];
  filterSortbyHeadings: RFQHeaddisplay[] = [];
  filterSortbyHeadingsUpdate: RFQHeaddisplay[] = [];


  filterRfqType: string[] = [];
  filterPaymentOptions: string[] = [];
  filterPackagingOptions: string[] = [];
  filterCollectorDelivery: string[] = [];
  filtertestCertification: boolean[] = [];



  //  FILTER RFQ NAV
  RFQsearchFilter() {

    this.filterRfqType = [];
    this.filterPaymentOptions = [];
    this.filterCollectorDelivery = [];
    this.filterPackagingOptions = [];
    this.filtertestCertification = [];


    this.filteredNavRfqs = [];

    // POPULATES THE FILTER RFQ ARRAY ACCORDING TO IF ARCHIVED OR AWAITING PAYMENT HAS BEEN SELECTED

    if (this.g.awaitingPayment.value == true) {
      for (let rfq of this.awaitPaymentRfqs) {
        this.filteredNavRfqs.push(rfq);
      }

    }

    if (this.g.archived.value == true) {
      for (let rfq of this.archivedRfqs) {
        this.filteredNavRfqs.push(rfq);
      }

    }



    // IF ANY OF THE FILTER VALUES ARE SET TO TRUE THEN THE FILTER IS USED AND THE HTML DISPLAYS THE filteredNavRfqs ARRAY
    if (this.rs.complete.value || this.rs.rawmat.value || this.rs.cod.value || this.rs.cbd.value || this.rs.thirtyDays.value || this.rs.sixtyDays.value ||
      this.rs.paymentOther.value || this.rs.delivery.value || this.rs.collection.value || this.rs.boxed.value || this.rs.pallets.value ||
      this.rs.strapped.value || this.rs.loose.value || this.rs.packagingOther.value || this.rs.testCertificationYes.value || this.rs.testCertificationNo.value) {

      this.filtersUsed = true;
    }
    else {
      this.filtersUsed = false;

    }

    // BUILD UP TYPE OF SUPPLY ARRAY
    if (this.rs.complete.value == true) {
      this.filterRfqType.push("Complete");
    }

    if (this.rs.rawmat.value == true) {
      this.filterRfqType.push("Raw Material");
    }


    // BUILD UP COLLECT OR DELIVERY ARRAY
    if (this.rs.delivery.value == true) {
      this.filterCollectorDelivery.push("Delivery");

    }

    if (this.rs.collection.value == true) {
      this.filterCollectorDelivery.push("Collection");

    }
    // BUILD UP PAYMENT ARRAY
    if (this.rs.cod.value == true) {
      this.filterPaymentOptions.push("COD");

    }

    if (this.rs.cbd.value == true) {
      this.filterPaymentOptions.push("CBD");

    }

    if (this.rs.thirtyDays.value == true) {
      this.filterPaymentOptions.push("30 Days");

    }


    if (this.rs.sixtyDays.value == true) {
      this.filterPaymentOptions.push("60 Days");

    }


    if (this.rs.paymentOther.value == true) {
      this.filterPaymentOptions.push("Other");

    }

    // BUILD UP PACKAGING OPTION ARRAY
    if (this.rs.boxed.value == true) {
      this.filterPackagingOptions.push("Boxed");

    }

    if (this.rs.pallets.value == true) {
      this.filterPackagingOptions.push("Pallet");

    }

    if (this.rs.strapped.value == true) {
      this.filterPackagingOptions.push("Strapped");

    }

    if (this.rs.loose.value == true) {
      this.filterPackagingOptions.push("Loose");

    }

    if (this.rs.packagingOther.value == true) {
      this.filterPackagingOptions.push("Other");

    }

    // TEST CERTIFICATION ARRAY POPULATE
    if (this.rs.testCertificationYes.value == true) {

      this.filtertestCertification.push(true);
    }

    // TEST CERTIFICATION ARRAY POPULATE
    if (this.rs.testCertificationNo.value == true) {

      this.filtertestCertification.push(true);
    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE TYPE OP SUPPLY OPTIONS
    if (this.filterRfqType.length > 0) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => this.filterRfqType.some(r => rfq.typeOfSupply.includes(r)));

    }


    // FILTERS filterOpenRfq ARRAY FOR ALL THE PAYMENT REQUIREMENT OPTIONS
    if (this.filterPaymentOptions.length > 0) {
      if (this.filteredNavRfqs != null) {
        this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => this.filterPaymentOptions.some(r => rfq.paymentRequirement?.includes(r)));
      }
    }


    // FILTERS filterOpenRfq ARRAY FOR ALL THE DELIVERY REQUIREMENT OPTIONS
    if (this.filterCollectorDelivery.length > 0) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => this.filterCollectorDelivery.some(r => rfq.deliveryRequirements.includes(r)));
    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE PACKAGING REQUIREMENT OPTIONS FIRST CHECKS IF THE PACKAGING REQUIREMENT OPTIONS IS NOT NULL
    if (this.filterPackagingOptions.length > 0) {

      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => rfq.packagingRequirements != null).filter(rfq => this.filterPackagingOptions.some(r => rfq.packagingRequirements.includes(r)));
    }


    // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE OR FALSE
    if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == true) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => rfq.productCertificationRequirements == true || rfq.productCertificationRequirements == false);

    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE
    if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == false) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => rfq.productCertificationRequirements == true);

    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS FALSE
    if (this.rs.testCertificationNo.value == true && this.rs.testCertificationYes.value == false) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => rfq.productCertificationRequirements == false);

    }

    // FILTERS filterOpenRfq ARRAY IF NOT NULL
    if (this.filteredNavRfqs.length > 0) {

      this.filterSortbyHeadings = [];
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

      this.headingId = 1;
      this.singleSortHeadings.rfqHeadName = "Multiple Services";
      this.singleSortHeadings.rfq = [];
      this.filterSortbyHeadings.push(this.singleSortHeadings);
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
      this.headingId++;

      this.sortFilterRFQHeadArray();
    }
    else {
      // MAKE MAKE THE ARRAY THAT BUILDS UP THE SIDE NAV NULL
      this.filterSortbyHeadings = [];
    }

  }

  // BUILDS RFQ SIDENAV AFTER A FILTER IS SELECTED
  sortFilterRFQHeadArray() {

    // PUSHES ALL VALUES FROM OPEN RFQS ARRAY INTO THE FILTER RFQS ARRAY

    for (let indexrfq of this.filteredNavRfqs) {
      for (let service of indexrfq.servicesRequired) {
        this.check = false;
        if (indexrfq.servicesRequired.length == 1) {
          for (let name of this.filterSortbyHeadings) {
            if (service == name.rfqHeadName) {
              this.check = true;
            }
          }
          if (this.check == false) {

            this.singleSortHeadings.rfqHeadName = service;
            this.singleSortHeadings.rfq = [];
            this.singleSortHeadings.id = this.headingId;
            this.filterSortbyHeadings.push(this.singleSortHeadings);
            this.headingId++;
            this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
          }
        }
      }

      if (indexrfq.servicesRequired.length > 1) {
        this.filterSortbyHeadings[0].rfq.push(indexrfq);
      }

    }
    this.populateSearchRFQHeadArray();
    this.getClosingTime(Date);


    // PUT BLIP LOGIC HERE

  }


  populateSearchRFQHeadArray(): void {
    for (let rfq of this.filteredNavRfqs) {
      for (let service of rfq.servicesRequired) {
        if (rfq.servicesRequired.length == 1) {
          for (let val of this.filterSortbyHeadings) {
            if (val.rfqHeadName == service) {
              val.rfq.push(rfq)

            }
          }
        }

      }
    }
    if (this.filterSortbyHeadings[0].rfq.length == 0) {
      this.filterSortbyHeadings.splice(0, 1);
    }

    // LOGIC FOR SUPPLIER USER ACTIONS
    for (let heading of this.filterSortbyHeadings) {
      for (let rfq of heading.rfq) {
        heading.openQuotesCounter = 0;
        if (rfq.acceptedQuotes[0].paymentReference == null && rfq.acceptedQuotes[0].invoiceQuoteFileId != null) {

          heading.openQuotesCounter = 1;
          break;
        }

      }
    }
  }

  // FILTER NAV ON PERIODIC RELOAD
  filterUpdateRfqs(array: RfqHeadings[]) {

    this.filteredNavRfqsUpdate = [];


    if (array.length > 0) {


      // POPULATES THE FILTER RFQ ARRAY ACCORDING TO IF ARCHIVED OR AWAITING PAYMENT HAS BEEN SELECTED
      if (this.g.awaitingPayment.value == true) {
        for (let rfq of array) {

          if (!rfq.archived) {
            this.filteredNavRfqsUpdate.push(rfq);
          }
        }
      }

      else if (this.g.archived.value == true) {
        for (let rfq of array) {
          if (rfq.archived) {
            this.filteredNavRfqsUpdate.push(rfq);
          }
        }
      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE TYPE OP SUPPLY OPTIONS
      if (this.filterRfqType.length > 0) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => this.filterRfqType.some(r => rfq.typeOfSupply.includes(r)));

      }
      // FILTERS filterOpenRfq ARRAY FOR ALL THE PAYMENT REQUIREMENT OPTIONS
      if (this.filterPaymentOptions.length > 0) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => this.filterPaymentOptions.some(r => rfq.paymentRequirement.includes(r)));
      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE DELIVERY REQUIREMENT OPTIONS
      if (this.filterCollectorDelivery.length > 0) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => this.filterCollectorDelivery.some(r => rfq.deliveryRequirements.includes(r)));
      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE PACKAGING REQUIREMENT OPTIONS FIRST CHECKS IF THE PACKAGING REQUIREMENT OPTIONS IS NOT NULL
      if (this.filterPackagingOptions.length > 0) {

        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => rfq.packagingRequirements != null).filter(rfq => this.filterPackagingOptions.some(r => rfq.packagingRequirements.includes(r)));
      }


      // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE OR FALSE
      if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == true) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => rfq.productCertificationRequirements == true || rfq.productCertificationRequirements == false);

      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE
      if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == false) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => rfq.productCertificationRequirements == true);

      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS FALSE
      if (this.rs.testCertificationNo.value == true && this.rs.testCertificationYes.value == false) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => rfq.productCertificationRequirements == false);

      }


      // FILTERS filterOpenRfq ARRAY IF NOT NULL
      if (this.filteredNavRfqsUpdate.length > 0) {

        this.filterSortbyHeadingsUpdate = [];
        this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

        this.headingId = 1;
        this.singleSortHeadings.rfqHeadName = "Multiple Services";
        this.singleSortHeadings.rfq = [];
        this.filterSortbyHeadingsUpdate.push(this.singleSortHeadings);
        this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
        this.headingId++;

        for (let indexrfq of this.filteredNavRfqsUpdate) {
          for (let service of indexrfq.servicesRequired) {
            this.check = false;
            if (indexrfq.servicesRequired.length == 1) {
              for (let name of this.filterSortbyHeadingsUpdate) {
                if (service == name.rfqHeadName) {
                  this.check = true;
                }
              }
              if (this.check == false) {

                this.singleSortHeadings.rfqHeadName = service;
                this.singleSortHeadings.rfq = [];
                this.singleSortHeadings.id = this.headingId;
                this.filterSortbyHeadingsUpdate.push(this.singleSortHeadings);
                this.headingId++;
                this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
              }
            }
          }

          if (indexrfq.servicesRequired.length > 1) {
            this.filterSortbyHeadingsUpdate[0].rfq.push(indexrfq);
          }

        }

        this.getClosingTime(Date);

        this.cdr.detectChanges();

        for (let rfq of this.filteredNavRfqsUpdate) {
          for (let service of rfq.servicesRequired) {
            if (rfq.servicesRequired.length == 1) {
              for (let val of this.filterSortbyHeadingsUpdate) {
                if (val.rfqHeadName == service) {
                  val.rfq.push(rfq)

                }
              }
            }

          }
        }
        if (this.filterSortbyHeadingsUpdate[0].rfq.length == 0) {
          this.filterSortbyHeadingsUpdate.splice(0, 1);
        }

        // LOGIC FOR SUPPLIER USER ACTIONS
        for (let heading of this.filterSortbyHeadingsUpdate) {
          for (let rfq of heading.rfq) {
            heading.openQuotesCounter = 0;
            if (rfq.acceptedQuotes[0].paymentReference == null && rfq.acceptedQuotes[0].invoiceQuoteFileId != null) {

              heading.openQuotesCounter = 1;
              break;
            }

          }
        }

      }
      else {
        // MAKE MAKE THE ARRAY THAT BUILDS UP THE SIDE NAV NULL
        this.filterSortbyHeadingsUpdate = [];
      }

      this.replaceFilteredRfqsOnUpdate();

    }

  }

  //PUSH OR SPLICE RFQS IF THEY HAVE LEFT THE ARRAY OR IF NEW ONES HAVE COME IN
  replaceFilteredRfqsOnUpdate() {

    var newHeadings: string[] = [];
    var oldHeadings: string[] = [];

    for (let heading of this.filterSortbyHeadings) {
      oldHeadings.push(heading.rfqHeadName)
      for (let newHead of this.filterSortbyHeadingsUpdate) {
        if (heading.rfqHeadName == newHead.rfqHeadName) {
          heading.rfq = newHead.rfq;
          heading.openQuotesCounter = newHead.openQuotesCounter;
        }
      }
    }

    for (let newHeading of this.filterSortbyHeadingsUpdate) {
      newHeadings.push(newHeading.rfqHeadName);
    }

    // if (this.sortbyHeadings.length != this.sortbyHeadingsUpdate.length) {
    //  if (this.sortbyHeadings.length < this.sortbyHeadingsUpdate.length) {

    for (let newHead of this.filterSortbyHeadingsUpdate) {
      if (oldHeadings.indexOf(newHead.rfqHeadName) == -1) {
        this.filterSortbyHeadings.push(newHead);
      }
    }

    // } else {
    for (const { index, value } of this.filterSortbyHeadings.map((value, index) => ({ index, value }))) {
      if (newHeadings.indexOf(value.rfqHeadName) == -1) {
        this.filterSortbyHeadings.splice(index, 1);
      }
    }

    //  }

    // }

  }


  clearRFQFilter() {

    this.filtersUsed = false;

    this.rs.complete.setValue(false);
    this.rs.rawmat.setValue(false);
    this.rs.cod.setValue(false);
    this.rs.cbd.setValue(false);
    this.rs.thirtyDays.setValue(false);
    this.rs.sixtyDays.setValue(false);
    this.rs.paymentOther.setValue(false);
    this.rs.delivery.setValue(false);
    this.rs.collection.setValue(false);
    this.rs.boxed.setValue(false);
    this.rs.pallets.setValue(false);
    this.rs.strapped.setValue(false);
    this.rs.loose.setValue(false);
    this.rs.packagingOther.setValue(false);
    this.rs.testCertificationYes.setValue(false);
    this.rs.testCertificationNo.setValue(false);
  }



  filterOpen: boolean = false;
  filterIconToggle() {

    this.filterOpen = !this.filterOpen;

  }

  //  DATE CALCULATIONS



  //needed for date calculations

  dueDate: any;
  dueHours: any;
  date1: any;
  date2: any;
  date3: any;
  roundDate: any;


  getDaydiffdeliverySupplier(actualDeliveryDate) {

    this.date1 = new Date(this.selectedRFQ.maxLeadTime);
    this.date2 = new Date(actualDeliveryDate);

    let diff = this.date2.getTime() - this.date1.getTime();
    let msInDay = 1000 * 3600 * 24;

    this.dueDate = diff / msInDay;

    this.roundDate = Math.floor(diff / msInDay);

    this.roundDate = Math.abs(this.roundDate);


  }

  //needed for date calculations

  dueDateb: any;
  dueHoursb: any;
  date1b: any;
  date2b: any;
  date3b: any;
  roundDateb: any;



  getDaydiffdeliveryBuyer(actualDeliveryDate) {

    this.date1b = new Date(this.selectedRFQ.maxLeadTime);
    this.date2b = new Date(actualDeliveryDate);

    let diff = this.date2b.getTime() - this.date1b.getTime();
    let msInDay = 1000 * 3600 * 24;

    this.dueDateb = diff / msInDay;

    this.roundDateb = Math.floor(diff / msInDay);

    this.roundDateb = Math.abs(this.roundDateb);


  }


}
