<div class="main" style="padding-top: 20px;">
    <div>
        <ul class="nav nav-tabs d-flex d-sm-flex d-xxl-flex justify-content-center justify-content-sm-center justify-content-xxl-center"
            role="tablist">
            <li class="nav-item" role="presentation"><a class="nav-link  {{openTab}} greentab" role="tab"
                    data-bs-toggle="tab"  #tab1 [routerLink] = "['/buyer-home/view-rfq','Open',openRfqId,'false']" >Open RFQs</a></li>
            <li class="nav-item" role="presentation"><a class="nav-link {{inProgressTab}}  greentab" role="tab"
                    data-bs-toggle="tab" #tab2IP [routerLink] = "['/buyer-home/view-rfq','InProgress',inProgressRfqId,'false']" >In Progress
                    RFQs</a></li>
            <li class="nav-item" role="presentation"><a class="nav-link {{closedTab}} greentab" role="tab"
                    data-bs-toggle="tab" #tab3CL [routerLink] = "['/buyer-home/view-rfq','Closed',closedRfqId,'false']" [queryParams] = "{archived: closedRfqArchivedState}">Completed RFQs</a>
            </li>
            <li class="nav-item" role="presentation"><a class="nav-link {{cancelledTab}}  greentab" role="tab"
                data-bs-toggle="tab" #tab4 [routerLink] = "['/buyer-home/view-rfq','Cancelled',cancelledRfqId,'false']" >Cancelled
                RFQs</a></li>
        </ul>
    </div> 

    <div class="tab-content">

        <div class="tab-pane {{openTab}}" role="tabpanel" id="tab-1">

            <app-open-rfq #OpenTab (changeTab)="changeInProgressTab($event)" (changeToOpenTab)="changeOpenTab($event)"
                [reloadOpenRfqsFromTab]="reloadOpenRfqs" [openRfqId] = "openRfqId " [state] = "state"  [openRfqNav] = "openRfqNav">
            </app-open-rfq>

        </div>

        <div class="tab-pane {{inProgressTab}}" role="tabpanel" id="tab-2IP">
            <app-in-progress-rfq #IPTab [Role]="Role" [openChat]="openChat" (openChatCond)="triggerChatOpen($event)"
                (quoteEvent)="triggerQid($event)" (changeClosedTab)="changeClosedTab($event)"
                (rfqStatus)="rfqStatusSend($event)" (changeTab)="changeInProgressTab($event)"
                [reloadIPRfqsFromTab]="reloadInProgressRfqs" [inProgressRfqId] = "inProgressRfqId" [state] = "state"  [inProgressRfqNav] = "inProgressRfqNav" >
            </app-in-progress-rfq>
        </div>

        <div class="tab-pane {{closedTab}}" role="tabpanel" id="tab-3">
            <app-closed-rfq #ClosedTab [openChat]="openChat" (openChatCond)="triggerChatOpen($event)"
                (quoteEvent)="triggerQid($event)" (rfqStatus)="rfqStatusSend($event)"
                (changeClosedTab)="changeClosedTab($event)" [reloadClosedRfqsFromTab]="reloadClosedRfqs" [state] = "state" [closedRfqId] = "closedRfqId"
                [closedRfqArchived] = "closedRfqArchived" (rfqArchivedState)="setRfqArchivedState($event)"  [closedRfqNav] = "closedRfqNav">
            </app-closed-rfq>
        </div>

        <div class="tab-pane {{cancelledTab}}" role="tabpanel" id="tab-4">

             <app-cancelled-rfq [cancelledRfqId] = "cancelledRfqId " [state] = "state"   [cancelledRfqNav] = "cancelledRfqNav"
            
             [reloadOpenRfqsFromTab]="reloadCancelledRfqs"></app-cancelled-rfq>
            
        </div>

    </div>

</div>

<div *ngIf="openChat">
    <app-chat [Buyer]="Buyer" [Role]="Role" [Supplier]="Supplier" [sentQID]="sentQID" [openChat]="openChat"
        [rfqStatus]="rfqStatus" (openChatCond)="triggerChatOpen($event)">
    </app-chat>
</div>