<!-- RFQ display section -->
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">


<!-- ORIGINAL RFQ BLOCKS

<section class="rfqsearchblocks">
  <div class="container-fluid RFQFORM">
    <div class="
      row
      d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex
      justify-content-sm-center
      justify-content-md-center
      justify-content-lg-center
      justify-content-xl-center
      justify-content-xxl-center
    " style="padding-bottom: 1rem">
      <div class="col-xxl-6 d-sm-flex justify-content-sm-center" style="width: 100%">
        <div class="row g-0 uirow bg-white rounded shadow-sm" style="width: 700px; min-width: 700px">
          <div class="col-xxl-2 text-nowrap uigreyblock" style="color: #f2f2f5; width: 100px">
            <div
              class="d-flex d-lg-flex d-xl-flex justify-content-between align-items-center align-items-lg-center align-items-xl-center"
              style="margin-top: 0.5rem;"><i
                class="fas fa-clock d-flex d-xxl-flex align-items-between align-items-xxl-center"
                style="color: var(--mainblue);font-size: 16px;"></i>
              <p class="text-center" style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;">
                {{getClosingTime(rfq.rfqClosingDate)}}</p>
            </div>
            <div
              class="d-flex d-lg-flex d-xl-flex justify-content-between align-items-center align-items-lg-center align-items-xl-center"
              style="margin-top: 0.5rem;"><i
                class="material-icons d-flex d-xxl-flex align-items-center align-items-xxl-center"
                style="color: var(--mainblue);font-size: 18px;">verified_user</i>
              <p class="text-center"
                style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px; padding-right: 10px;">  Verified</p>
            </div>




            <div *ngIf="rfq.quotedOn" class="justify-content-center">
              <h1 class="
                      text-center
                      d-lg-flex d-xxl-flex
                      justify-content-lg-start justify-content-xxl-center
                      blueheadsmallLB
                    " style="font-size: 18px; padding-top: 1rem; font-size: small;">
                Quoted On
              </h1>
              <div class="fa fa-check-circle d-flex justify-content-center"
                style="border-color: var(--maingreen); color: var(--maingreen); font-size: 20px; padding-left: 0;"
                aria-hidden="true">
              </div>
            </div>
          </div>

          <div class="col-xxl-5 rfqsearchblock" style="width: 218.344px">
            <div class="row ">
              <div class="col">

                <p class="d-sm-flex justify-content-sm-start blueheadsmallshift"
                  style="margin-bottom: 0; padding-left: 0px;">
                  <img src="assets/Group%2028.png" style="width: 42px; padding-right: 10px;">
                  {{ rfq.rfqName }}
                </p>


              </div>


            </div>
            <div class="row rfqdetailrow">
              <div class="col">
                <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0">
                  <img class="
                    img-fluid
                    d-xxl-flex
                    flex-shrink-1
                    align-items-xxl-center
                    uiicon
                  " style="font-size: 9px; width: 40px; height: 20px" src="assets/Group745.png" />Services Required
                </p>
                <ng-container *ngFor=" let service of rfq.servicesRequired let i = index">
                  <p class="d-sm-flex justify-content-sm-start bluetext shift" style="margin-bottom: 0">
                    <b>{{i+1}}.</b>&nbsp;{{service}}
                  </p>
                </ng-container>
              </div>
            </div>

            <div class="row rfqdetailrow">
              <div class="col">
                <p class="
                  d-sm-flex
                  justify-content-sm-start
                  align-items-xxl-center
                  bluetext
                " style="margin-bottom: 0; color: #0e0e82">
                  <img class="img-fluid flex-shrink-1 uiicon" style="font-size: 9px; width: 40px; height: 20px"
                    src="assets/Group742.png" />Requested Delivery Date:
                </p>
                <p class="d-sm-flex justify-content-sm-start bluetext shift" style="margin-bottom: 0">
                  {{rfq.maxLeadTime | date:'mediumDate'}}
                </p>
                <p class="d-sm-flex justify-content-sm-start bluetext shift" style="margin-bottom: 0">
                  ({{getMaxLeadTime(rfq.maxLeadTime,rfq.rfqClosingDate)}})
                </p>
                <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0"></p>
              </div>
            </div>
            <div [attr.class]="'a' + rfq.rfqId + ' collapse'">
              <div class="row rfqdetailrow">
                <div class="col">
                  <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0">
                    <img class="
                      img-fluid
                      d-xxl-flex
                      flex-shrink-1
                      align-items-xxl-center
                      uiicon
                    " style="font-size: 9px; width: 40px; height: 20px" src="assets/Group746.png" />Material grade:
                  </p>
                  <p class="
                    text-nowrap
                    d-sm-flex
                    justify-content-sm-start
                    bluetext
                    shift
                  " style="margin-bottom: 0">
                    material grade
                  </p>
                  <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0"></p>
                </div>
              </div>
              <div class="row rfqdetailrow">
                <div class="col">
                  <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0">
                    <img class="
                      img-fluid
                      d-xxl-flex
                      flex-shrink-1
                      align-items-xxl-center
                      uiicon
                    " style="font-size: 9px; width: 40px; height: 20px" src="assets/Group747.png" />Test
                    certifiaction
                  </p>
                  <p class="d-sm-flex justify-content-sm-start bluetext shift" style="margin-bottom: 0">
                    test cert
                  </p>
                  <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="
            col
            text-center
            d-inline-flex d-xxl-flex
            flex-column
            justify-content-center
            justify-content-sm-center
            justify-content-md-center
            justify-content-lg-center
            justify-content-xl-center
            justify-content-xxl-center
            align-items-xxl-center
          " style="
            padding: 1rem;
            padding-right: 1rem;
            padding-left: 1rem;
            width: 218.328px;
          ">
            <div [attr.class]="'a' + rfq.rfqId + ' collapse'" class="collapse">
              <div class="row" style="
                margin-bottom: 0;
                padding-top: 10px;
                padding-bottom: 10px;
              ">
                <div class="col">
                  <button data-bs-toggle="modal" [attr.data-bs-target]="'#bwe' + rfq.rfqId" (click)="onSelectRFQ(rfq)"
                    class="btn btn-secondary invoicebtn" type="button"
                    style="font-size: 12px;background: var(--lightblue);border-style: none;width: 127.344px; height: 36px;">
                    Open Public Chat
                  </button>

                </div>
              </div>


              <ng-container *ngIf="rfq.dimensions.length > 0">
                <div class="row">
                  <div class="col">

                    <button data-bs-toggle="modal" [attr.data-bs-target]="'#g' + rfq.rfqId" (click)="onSelectRFQ(rfq)"
                      class="btn btn-secondary invoicebtn" type="button"
                      style="font-size: 12px;background: var(--lightblue);border-style: none;width: 127.344px; height: 36px;">
                      Dimensions
                    </button>
                  </div>

                </div>
              </ng-container>



              <ng-container *ngIf="rfq.rfqFiles.length > 0">
                <div class="row" style="
                margin-bottom: 0;
                padding-top: 10px;
                padding-bottom: 10px;
              ">
                  <div class="col">
                    <button data-bs-toggle="modal" [attr.data-bs-target]="'#e' + rfq.rfqId" (click)="onSelectRFQ(rfq)"
                      class="btn btn-secondary invoicebtn" type="button"
                      style="font-size: 12px;background: var(--lightblue);border-style: none;width: 127.344px; height: 36px;">
                      Drawings
                    </button>
                  </div>

                </div>
              </ng-container>

            </div>
          </div>
          <div class="
            col
            offset-sm-0
            d-flex
            flex-column
            justify-content-between
            align-items-xl-center align-items-xxl-center
          " style="
            padding: 1rem;
            padding-right: 1rem;
            padding-left: 1rem;
            width: 218.328px;
          ">
            <div id="hideviewdetails">
              <button [attr.id]="'h' + rfq.rfqId" class="
                btn
                d-xxl-flex
                justify-content-xxl-center
                align-items-xxl-center
                yellowbtn
              " id="viewdetails" type="button" style="
                border-style: none;
                border-radius: 20px;
                width: 150.0312px;
                height: 42px;
                font-size: 14px;
                text-align: center;
              " data-bs-toggle="collapse" [attr.data-bs-target]="'.a' + rfq.rfqId" aria-expanded="true"
                (click)="toggleCollapse()">
                {{ isCollapsed ? "+ View Details" : "- Hide Details" }}
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
                  class="bi bi-plus"></svg>
              </button>
            </div>
            <div [attr.class]="'a' + rfq.rfqId + ' collapse'">
              <button *ngIf="!rfq.quotedOn" data-bs-toggle="modal" [attr.data-bs-target]="'.c' + rfq.rfqId"
                (click)="onSelectRFQ(rfq)" class="
                btn
                d-xxl-flex
                justify-content-xxl-center
                align-items-xxl-center
              " type="button" style=" 
                border-style: none;
                border-radius: 20px;
                width: 150.0312px;
                height: 42px;
                font-size: 14px;
                text-align: center;
                color: var(--white);
                background: var(--mainblue);
              ">
                Submit Quote
              </button>

              <button *ngIf="rfq.quotedOn" (click)="onSelectRFQ(rfq)" class="
            btn
            d-xxl-flex
            justify-content-xxl-center
            align-items-xxl-center
          " type="button" style=" 
            border-style: none;
            border-radius: 20px;
            width: 150.0312px;
            height: 42px;
            font-size: 14px;
            text-align: center;
            color: var(--white);
            background: var(--lightblue);
          ">
                Quoted On
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->



<!-- NEW BROWSE RFQ BLOCKS -->

<section class="rfqsearchblocks">
  <div class="container-fluid RFQFORM">
    <div class="
      row
      d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex
      justify-content-sm-center
      justify-content-md-center
      justify-content-lg-center
      justify-content-xl-center
      justify-content-xxl-center
    " style="padding-bottom: 1rem">
      <div class="col-xxl-6 d-sm-flex justify-content-sm-center" style="width: 100%">

        <div class="row g-0 uirow bg-white rounded shadow-sm" style="width: 800px; min-width: 800px">
          <div>
            <div class="row g-0" style="margin-bottom: 0.5rem;">

              <!-- <div class="col d-flex d-xxl-flex justify-content-between align-items-xxl-center"
                style="padding: 0.5rem;padding-bottom: 0.5rem;background: var(--shadowgrey);padding-top: 0.5rem;">
                <p class="text-nowrap d-flex d-xxl-flex align-items-center align-items-xxl-center bluehead"
                  style="color: #0e0e82;padding-left: 0.5rem;"><img src="assets/Group%2028.png" style="width: 42px;" /> 
                  {{rfq.rfqName}}</p>
                <p *ngIf="rfq.quotedOn"
                  class="text-nowrap d-flex d-xxl-flex align-items-center align-items-xxl-center bluetext"
                  style="color: #0e0e82;padding-left: 2rem;"><i class="fa fa-check-circle"
                    style="font-size: 16px;"></i> Quote
                  Sumbitted</p>
                <p class="text-nowrap d-flex d-xxl-flex align-items-center align-items-xxl-center bluetextthin"
                  style="color: #0e0e82;padding-left: 2rem;"><i class="fas fa-clock"
                    style="font-size: 16px;"></i> {{getClosingTime(rfq.rfqClosingDate)}}</p>

                    <p class="text-nowrap d-flex d-xxl-flex align-items-center align-items-xxl-center bluetextthin"
                    style="color: #0e0e82;padding-left: 2rem;"><i class="fa fa-star"
                    style="font-size: 15px;color: var(--yellow);"></i>Rating: {{rfq.buyerDetails.rating | number :'1.0-1'}}</p>

                <p class="text-nowrap d-flex d-xxl-flex align-items-center align-items-xxl-center bluetextthin"
                  style="color: #0e0e82;padding-left: 2rem;"><i class="material-icons"
                    style="font-size: 19px;">verified_user</i> Verified</p>

                <button *ngIf="!rfq.quotedOn" 
                  (click)="onSelectRFQ(rfq)" data-bs-toggle="modal" [attr.data-bs-target]="'#c' + rfq.rfqId"
                  class="btn d-xxl-flex justify-content-xxl-center align-items-xxl-center yellowbtn" type="button"
                  style="border-style: none;border-radius: 20px;margin-left: 4rem;height: 42px;width: 150.0312px;text-align: center;font-size: 14px;color: var(--white);min-width: 150.0312px;"
                  >Submit Quote</button>

                  <button #openSubmitQuoteModal data-bs-toggle="modal" [attr.data-bs-target]="'#c' + rfq.rfqId" style="display:none !important;"></button>


                <button *ngIf="rfq.quotedOn" (click)="onSelectRFQ(rfq)"
                  class="btn d-xxl-flex justify-content-xxl-center align-items-xxl-center yellowbtn" type="button"
                  style="background:var(--shadowgrey);color: var(--yellow);border-color: var(--bs-yellow);border-radius: 108px;;border-radius: 20px;height: 42px;width: 150.0312px;text-align: center;font-size: 14px;min-width: 150.0312px;"
                  data-bs-toggle="modal" data-bs-target="#submitquotemodal">Quoted On</button>

              </div> -->

              <!-- new one -->
              <div class="row g-0 row-cols-1" style="margin-bottom: 0.5rem;">
                <div class="col d-flex d-xxl-flex justify-content-between align-items-xxl-center"
                  style="padding: 0.5rem;background: var(--shadowgrey);padding-top: 0.5rem;">
                  <div class="d-flex d-lg-flex justify-content-center justify-content-lg-center"><img
                      src="assets/Group%2028.png" style="width: 42px;" />
                    <p class="text-nowrap d-flex d-xxl-flex align-items-center align-items-xxl-center bluehead"
                      style="color: #0e0e82;padding-left: 0.5rem;">{{rfq.rfqName}}</p>
                  </div>

                  <div class=" d-flex d-lg-flex justify-content-center justify-content-lg-center">
                    <button *ngIf="!rfq.quotedOn" (click)="onSelectRFQ(rfq)" data-bs-toggle="modal"
                      [attr.data-bs-target]="'#c' + rfq.rfqId"
                      class="btn d-xxl-flex justify-content-xxl-center align-items-xxl-center yellowbtn" type="button"
                      style="border-style: none;border-radius: 20px;margin-left: 4rem;height: 42px;width: 150.0312px;text-align: center;font-size: 14px;color: var(--white);min-width: 150.0312px;">Submit
                      Quote</button>


                    <!-- NON SELECTED STAR -->
                    <div *ngIf="!rfq.quotedOn">
                      <button [disabled]="starringRfq" *ngIf="!rfq.starred " (click)="starRfq(rfq.rfqId)"
                        class="btn btn-link" role="button"><i class="fa fa-star-o"
                          style="font-size: 20px;color: var(--darkblue);padding-top:4px;padding-left: 0;"></i>
                        <span *ngIf="starringRfq" style="padding-top:4px;padding-left: 0;"
                          class="spinner-border spinner-border-sm mr-1"></span></button>
                    </div>

                    <!-- SELECTED STAR -->
                    <div *ngIf="!rfq.quotedOn">
                      <button [disabled]="starringRfq" *ngIf="rfq.starred " (click)="starRfq(rfq.rfqId)"
                        class="btn btn-link" role="button"><i class="fa fa-star"
                          style="font-size: 20px;color: var(--darkblue);padding-top:4px;padding-left: 0;"></i>
                        <span *ngIf="starringRfq" style="padding-top:4px;padding-left: 0;"
                          class="spinner-border spinner-border-sm mr-1"></span></button>

                    </div>



                    <button #openSubmitQuoteModal data-bs-toggle="modal" [attr.data-bs-target]="'#c' + rfq.rfqId"
                      style="display:none !important;"></button>
                  </div>


                  <button *ngIf="rfq.quotedOn" (click)="onSelectRFQ(rfq)"
                    class="btn d-xxl-flex justify-content-xxl-center align-items-xxl-center yellowbtn" type="button"
                    style="background:var(--shadowgrey);color: var(--yellow);border-color: var(--bs-yellow);border-radius: 108px;;border-radius: 20px;height: 42px;width: 150.0312px;text-align: center;font-size: 14px;min-width: 150.0312px;"
                    data-bs-toggle="modal" [attr.data-bs-target]="'#success' + rfq.rfqId">Go To Quote</button>
                </div>
                <div class="col d-flex d-xxl-flex align-items-xxl-center"
                  style="padding: 0.5rem;background: var(--shadowgrey);padding-top: 0px;">
                  <div class="d-flex d-lg-flex justify-content-center justify-content-lg-center"
                    style="padding-left: 3rem;">

                    <p class="text-nowrap d-flex d-xxl-flex align-items-center align-items-xxl-center bluetextthin"
                      style="color: #0e0e82;padding-left: 0rem;"><i class="fas fa-clock"
                        style="padding-right:  5px;font-size: 16px;"></i>{{getClosingTime(rfq.rfqClosingDate)}}</p>
                    <p class="text-nowrap d-flex d-xxl-flex align-items-center align-items-xxl-center bluetextthin"
                      style="color: #0e0e82;padding-left: 2rem;"><i class="fa fa-star"
                        style="padding-right: 5px; font-size: 15px;color: var(--yellow);"></i>Rating:


                      <ng-container *ngIf="rfq.buyerDetails.rating  != null">
                        {{rfq.buyerDetails.rating | number :'1.0-1'}}
                      </ng-container>
                      <ng-container *ngIf="rfq.buyerDetails.rating  == null">
                        --
                      </ng-container>
                    </p>
                    <p *ngIf="rfq.preferredBuyer == true"
                      class="text-nowrap d-flex d-xxl-flex align-items-center align-items-xxl-center bluetextthin"
                      style="color: #0e0e82;padding-left: 2rem;"><i class="material-icons"
                        style="padding-right:5px; font-size: 19px;">verified_user</i> Preferred Buyer</p>

                    <ng-container *ngIf="rfq.CreditApplication != null">

                      <ng-container>

                        <p class="text-nowrap d-flex d-xxl-flex align-items-center align-items-xxl-center bluetextthin"
                          style="color: #0e0e82;padding-left: 2rem;"> <i class="fas fa-donate"
                            style="padding-right:5px; font-size: 19px;"></i>

                          {{rfq.CreditApplication.Period}}</p>

                      </ng-container>


                    </ng-container>

                    <p *ngIf="rfq.quotedOn"
                      class="text-nowrap d-flex d-xxl-flex align-items-center align-items-xxl-center bluetext"
                      style="color: #0e0e82;padding-left: 2rem;"><i class="fa fa-check-circle"
                        style="font-size: 16px;"></i> Quote Sumbitted</p>


                  </div>
                </div>
              </div>

            </div>
            <div class="row g-0 rfqinner" style="padding: 0.5rem;padding-top: 0;">
              <div class="col-xxl-12 uidetailblock">
                <div>
                  <div>
                    <div class="row row-cols-2 rfqdetailrow" style="max-width: 1099px;">

                      <div class="col d-flex d-md-flex d-xxl-flex justify-content-between" style="padding-right: 2rem;">
                        <p *ngIf="rfq.deliveryRequirements == 'Collection'" class="text-nowrap bluetext"
                          style="color: #0e0e82;">Collection
                          Date
                        </p>
                        <p *ngIf="rfq.deliveryRequirements == 'Delivery' || rfq.deliveryRequirements == null "
                          class="text-nowrap bluetext" style="color: #0e0e82;">Delivery
                          Date
                        </p>
                        <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                          style="margin-bottom: 0;">
                          {{rfq.maxLeadTime|
                          date:'mediumDate'}} &nbsp;({{getMaxLeadTime(rfq.maxLeadTime,rfq.rfqClosingDate)}})</p>
                      </div>
                      <!-- <div class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                                        style="padding-right: 2rem;">
                                                                                        <p class="text-nowrap bluetext"
                                                                                            style="color: #0e0e82;">Closes in</p>
                                                                                        <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                            style="margin-bottom: 0;">{{
                                                                                            getClosingTime(rfq.rfqClosingDate)}}
                                                                                        </p>
                                                                                    </div> -->
                      <div class="col d-flex d-md-flex d-xxl-flex justify-content-between" style="padding-right: 2rem;">
                        <p class="text-nowrap bluetext" style="color: #0e0e82;">Type Of Supply
                        </p>
                        <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                          style="margin-bottom: 0;">
                          {{rfq.typeOfSupply}}</p>
                      </div>

                      <div class="col d-flex d-md-flex d-xxl-flex justify-content-between" style="padding-right: 2rem;">
                        <p class="text-nowrap bluetext" style="color: #0e0e82;">Created On
                        </p>
                        <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                          style="margin-bottom: 0;">
                          {{rfq.createdAt |
                          date:'mediumDate'}}</p>
                      </div>

                      <div *ngIf=" rfq.paymentRequirement != '' &&  rfq.paymentRequirement != null"
                        class="col d-flex d-md-flex d-xxl-flex justify-content-between" style="padding-right: 2rem;">
                        <p class="text-nowrap bluetext" style="color: #0e0e82;">Payment
                          Requirement</p>
                        <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                          style="margin-bottom: 0;">
                          {{rfq.paymentRequirement}} </p>
                      </div>
                      <div *ngIf=" rfq.productCertificationRequirements != null"
                        class="col d-flex d-md-flex d-xxl-flex justify-content-between" style="padding-right: 2rem;">
                        <p class="text-nowrap bluetext" style="color: #0e0e82;">Test
                          Certification </p>
                        <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                          style="margin-bottom: 0;">
                          <ng-container *ngIf="rfq.productCertificationRequirements">
                            Required
                          </ng-container>
                          <ng-container *ngIf="!rfq.productCertificationRequirements">
                            Not Required
                          </ng-container>
                        </p>
                      </div>
                      <!-- <div *ngIf=" rfq.verifiedFactoryRequirements != null"
                                                                                        class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                                                                                        style="padding-right: 2rem;">
                                                                                        <p class="text-nowrap bluetext"
                                                                                            style="color: #0e0e82;">Verified Factory
                                                                                            Requirements</p>
                                                                                        <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                                                                            style="margin-bottom: 0;">
                                                                                            {{rfq.verifiedFactoryRequirements}}
                                                                                        </p>
                                                                                    </div> -->
                      <div *ngIf="rfq.isoRequirement" class="col d-flex d-md-flex d-xxl-flex justify-content-between"
                        style="padding-right: 2rem;">
                        <p class="text-nowrap bluetext" style="color: #0e0e82;">ISO Requirement
                        </p>
                        <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                          style="margin-bottom: 0;">
                          Required </p>

                      </div>
                      <div *ngIf=" rfq.beeRequirement != '' &&  rfq.beeRequirement != null"
                        class="col d-flex d-md-flex d-xxl-flex justify-content-between" style="padding-right: 2rem;">
                        <p class="text-nowrap bluetext" style="color: #0e0e82;">B-BBEE Requirement
                        </p>
                        <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                          style="margin-bottom: 0;">
                          {{rfq.beeRequirement}}</p>
                      </div>
                      <div *ngIf=" rfq.partnerRequirement != '' &&  rfq.partnerRequirement != null"
                        class="col d-flex d-md-flex d-xxl-flex justify-content-between" style="padding-right: 2rem;">
                        <p class="text-nowrap bluetext" style="color: #0e0e82;">Partnership
                          Requirement</p>
                        <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                          style="margin-bottom: 0;">
                          {{rfq.partnerRequirement}}</p>
                      </div>
                      <div *ngIf=" rfq.packagingRequirements != ''  &&  rfq.packagingRequirements != null"
                        class="col d-flex d-md-flex d-xxl-flex justify-content-between" style="padding-right: 2rem;">
                        <p class="text-nowrap bluetext" style="color: #0e0e82;">Packaging
                          Requirement</p>
                        <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                          style="margin-bottom: 0;">
                          {{rfq.packagingRequirements}}
                        </p>
                      </div>
                      <div *ngIf=" rfq.supplierLocationRequirements != '' &&  rfq.supplierLocationRequirements != null"
                        class="col d-flex d-md-flex d-xxl-flex justify-content-between" style="padding-right: 2rem;">
                        <p class="text-nowrap bluetext" style="color: #0e0e82;">Location
                          Requirement</p>
                        <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                          style="margin-bottom: 0;">
                          {{rfq.supplierLocationRequirements}}
                        </p>
                      </div>
                      <div *ngIf=" rfq.deliveryRequirements != '' &&  rfq.deliveryRequirements != null"
                        class="col d-flex d-md-flex d-xxl-flex justify-content-between" style="padding-right: 2rem;">
                        <p class="text-nowrap bluetext" style="color: #0e0e82;">Delivery
                          Requirement</p>
                        <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                          style="margin-bottom: 0;">
                          {{rfq.deliveryRequirements}}</p>
                      </div>
                    </div>

                    <div *ngIf=" rfq.otherPaymentSpecification != '' &&  rfq.otherPaymentSpecification != null"
                      style="color: var(--shadowgrey);background: var(--shadowgrey);border-radius: 14px;padding: 1rem;padding-bottom: 0;margin: 1rem;margin-left: 0;max-width: 600px;">
                      <div class="row d-flex d-xxl-flex justify-content-center justify-content-xxl-center">
                        <div class="col d-flex d-xxl-flex justify-content-start align-items-xxl-center">
                          <p class="text-nowrap d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                            style="color: #0e0e82;">Other
                            Payment Specifications</p>
                        </div>
                      </div>
                      <div class="row rfqdetailrow">
                        <div class="col">
                          <p class="d-sm-flex justify-content-sm-start bluetextthin">
                            {{rfq.otherPaymentSpecification}}
                          </p>
                          <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0;"></p>
                        </div>
                      </div>
                      <!-- <div class="row rfqdetailrow"
                                                                                    style="padding: 0px;">
                                                                                    <div class="col"><a href="/">
                                                                                            <p class="d-flex d-sm-flex justify-content-end justify-content-sm-start justify-content-xxl-end"
                                                                                                style="font-size: 12px;color: var(--darkblue);">
                                                                                                ... Read More</p>
                                                                                        </a>
                                                                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                            style="margin-bottom: 0;"></p>
                                                                                    </div>
                                                                                </div> -->
                    </div>
                    <div *ngIf=" rfq.otherPackagingSpecification != '' &&  rfq.otherPackagingSpecification != null"
                      style="color: var(--shadowgrey);background: var(--shadowgrey);border-radius: 14px;padding: 1rem;padding-bottom: 0;margin: 1rem;margin-left: 0;max-width: 600px;">
                      <div class="row d-flex d-xxl-flex justify-content-center justify-content-xxl-center">
                        <div class="col d-flex d-xxl-flex justify-content-start align-items-xxl-center">
                          <p class="text-nowrap d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                            style="color: #0e0e82;">Other
                            Packaging Specificayions</p>
                        </div>
                      </div>
                      <div class="row rfqdetailrow">
                        <div class="col">
                          <p class="d-sm-flex justify-content-sm-start bluetextthin">
                            {{rfq.otherPackagingSpecification}}
                          </p>
                          <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0;"></p>
                        </div>
                      </div>
                      <!-- <div class="row rfqdetailrow"
                                                                                style="padding: 0px;">
                                                                                <div class="col"><a href="/">
                                                                                        <p class="d-flex d-sm-flex justify-content-end justify-content-sm-start justify-content-xxl-end"
                                                                                            style="font-size: 12px;color: var(--darkblue);">
                                                                                            ... Read More</p>
                                                                                    </a>
                                                                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                        style="margin-bottom: 0;"></p>
                                                                                </div>
                                                                            </div> -->
                    </div>
                    <div *ngIf=" rfq.productIdentification != '' &&  rfq.productIdentification != null"
                      style="color: var(--shadowgrey);background: var(--shadowgrey);border-radius: 14px;padding: 1rem;padding-bottom: 0;margin: 1rem;margin-left: 0;max-width: 600px;">
                      <div class="row d-flex d-xxl-flex justify-content-center justify-content-xxl-center">
                        <div class="col d-flex d-xxl-flex justify-content-start align-items-xxl-center">
                          <p class="text-nowrap d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                            style="color: #0e0e82;">Product
                            Identification requirements</p>
                        </div>
                      </div>
                      <div class="row rfqdetailrow">
                        <div class="col">
                          <p class="d-sm-flex justify-content-sm-start bluetextthin">
                            {{rfq.productIdentification}}
                          </p>
                          <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0;"></p>
                        </div>
                      </div>
                      <!-- <div class="row rfqdetailrow"
                                                                                        style="padding: 0px;">
                                                                                        <div class="col"><a href="/">
                                                                                                <p class="d-flex d-sm-flex justify-content-end justify-content-sm-start justify-content-xxl-end"
                                                                                                    style="font-size: 12px;color: var(--darkblue);">
                                                                                                    ... Read More</p>
                                                                                            </a>
                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                style="margin-bottom: 0;"></p>
                                                                                        </div>
                                                                                    </div> -->
                    </div>
                    <div *ngIf=" rfq.additionalComments != '' &&  rfq.additionalComments != null"
                      style="color: var(--shadowgrey);background: var(--shadowgrey);border-radius: 14px;padding: 1rem;padding-bottom: 0;margin: 1rem;margin-left: 0;max-width: 600px;">
                      <div class="row d-flex d-xxl-flex justify-content-center justify-content-xxl-center">
                        <div class="col d-flex d-xxl-flex justify-content-start align-items-xxl-center">
                          <p class="text-nowrap d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                            style="color: #0e0e82;">Additional
                            Comments</p>
                        </div>
                      </div>
                      <div class="row rfqdetailrow">
                        <div class="col">
                          <p class="d-sm-flex justify-content-sm-start bluetextthin">
                            {{rfq.additionalComments}}
                          </p>
                          <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0;"></p>
                        </div>
                      </div>
                      <!-- <div class="row rfqdetailrow"
                                                                                        style="padding: 0px;">
                                                                                        <div class="col"><a href="/">
                                                                                                <p class="d-flex d-sm-flex justify-content-end justify-content-sm-start justify-content-xxl-end"
                                                                                                    style="font-size: 12px;color: var(--darkblue);">
                                                                                                    ... Read More</p>
                                                                                            </a>
                                                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                                                style="margin-bottom: 0;"></p>
                                                                                        </div>
                                                                                    </div> -->
                    </div>

                  </div>

                </div>



                <ng-container *ngIf="rfq.dimensions.length > 0">

                  <div class="row rfqdetailrow" style="padding-top: -6px;">
                    <div class="col d-flex align-items-center"><a data-bs-toggle="modal"
                        [attr.data-bs-target]="'#g' + rfq.rfqId" (click)="onSelectRFQ(rfq)"
                        class="btn btn-link d-flex d-xxl-flex align-items-center invoicebtn" role="button"
                        style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);padding-left: 0px;"><img
                          src="assets/6543.png" style="padding-left: 6p;width: 28px;" /> View Dimensions</a></div>
                  </div>


                </ng-container>

                <ng-container *ngIf="rfq.rfqFiles.length > 0">

                  <div class="row rfqdetailrow" style="padding-top: -6px;">
                    <div class="col d-flex align-items-center"><a data-bs-toggle="modal"
                        [attr.data-bs-target]="'#e' + rfq.rfqId"
                        class="btn btn-link d-flex d-xxl-flex align-items-center invoicebtn" role="button"
                        style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);padding-left: 0px;"><img
                          src="assets/6543.png" style="padding-left: 6p;width: 28px;" /> View Drawings</a></div>
                  </div>


                </ng-container>


                <div>
                  <div class="row">
                    <div class="col-xxl-3 d-xxl-flex align-items-xxl-center" style="width: 30%;">
                      <p class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                        style="color: #0e0e82;padding-right: 18px;">Services Required</p>
                    </div>
                    <div class="col"><button *ngFor=" let service of rfq.servicesRequired" class="btn text-nowrap"
                        type="button"
                        style="font-size: 12px;background: var(--mainblue);color: var(--shadowgrey);border-radius: 166px;margin: 2px;">{{service}}<a
                          class="closeicon" href="#" style="color: rgb(255,255,255);"></a></button></div>
                  </div>
                </div>
                <div class="row rfqdetailrow">
                  <div class="col d-flex align-items-center"><button data-bs-toggle="modal"
                      [attr.data-bs-target]="'#bwe' + rfq.rfqId" (click)="onSelectRFQ(rfq)"
                      (click)="togglePeriodicReload(true)" class="btn btn-secondary invoicebtn" type="button"
                      style="font-size: 12px;background: var(--lightblue);border-style: none;height: 36px;">Open Public
                      Chat</button></div>
                </div>
              </div>
            </div>
          </div>
        </div>




      </div>
    </div>
  </div>
</section>



<!-- This is where the Q&A modal starts -->
<section>
  <div class="modal fade" role="dialog" tabindex="-1" [attr.id]="'bwe' + rfq.rfqId" aria-labelledby="exampleModalLabel">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content ">
        <div class="modal-body  " style="padding: 0px; border: 0px; ">
          <div *ngIf="selectedRFQ">
            <app-questions-and-answers (periodicUpdateCond)="togglePeriodicReload($event)" [getQA]="getQA"
              [isBuyer]="isBuyer" [isSupplier]="isSupplier" [modalheader]="modalheader" [rfqId]="selectedRFQ.rfqId"
              [supplierId]="supplierId">
            </app-questions-and-answers>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!-- This is where the quote submission modal starts -->
<section>
  <div role="dialog" tabindex="-1" class="modal fade" [attr.id]="'c' + rfq.rfqId" aria-labelledby="exampleModalLabel">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body" style="padding: 0px; border: 0px">
          <div class="shadow p" style="width: 500px">

            <div *ngIf="selectedRFQ">
              <app-submit-quote (closeSubmitModalCond)="submitQuoteModalTrigger($event)" (quoteId)="setQuoteId($event)"
                [rfq]="selectedRFQ">
              </app-submit-quote>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- QUOTE MODAL SUCCESS-->

<section>
  <div class="modal fade" data-bs-backdrop="static" role="dialog" tabindex="-1" [attr.id]="'success' + rfq.rfqId"
    aria-labelledby="exampleModalLabel">

    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body" style="padding: 0px; border: 0px">
          <div style="width: 500px;">
            <div class="row text-center d-xl-flex justify-content-xl-center align-items-xl-center  blueformheader">
              <div class="col d-flex d-xl-flex justify-content-xl-start align-items-xl-center">
                <h1 class="d-xl-flex align-items-xl-center whitehead"
                  style="font-size: 16px;margin-bottom: 0; color: #ffffff;">Quote Submitted</h1>
              </div>
              <div
                class="col d-flex d-xl-flex justify-content-end justify-content-xl-end align-items-xl-center justify-content-xxl-end">
                <a data-bs-toggle="modal" [attr.data-bs-target]="'#success' + rfq.rfqId" data-bs-dismiss="modal"><i
                    class="fa fa-close" style="color: #db2e53; padding-left: 5px"></i></a>
              </div>
            </div>
            <div class="row shadow p" style="height: 100px;">
              <div class="col-xl-12 col-xxl-12 rfqsearchblock" style="border-radius: 0px 0px 0px 0px;padding: 2rem;">
                <div class="row rfqdetailrow">
                  <div class="col d-flex justify-content-center align-items-center"><span></span><i
                      class="fa fa-check-circle"
                      style="font-size: 32px;margin-right: 10px;color: var(--maingreen);"></i>
                    <p class="text-end bluetextthin" style="margin-bottom: 0;">Quote Submitted Successfully For

                    </p>

                  </div>

                  <div class=" d-flex justify-content-center align-items-center"><span></span>
                    <p class="text-end bluetext" style="margin-bottom: 0;">
                      {{rfq.rfqName}}

                    </p>

                  </div>




                </div>
                <!-- <div class="row rfqdetailrow">
                                  <div
                                      class="col d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center">
                                      <a class="btn" role="button"><i class="fa fa-plus-circle"
                                              style="font-size: 20px;color: var(--maingreen);"></i></a><a
                                          data-bs-dismiss="modal"
                                          class="btn btn-link btn-sm d-xxl-flex justify-content-xxl-center align-items-xxl-start"
                                          role="button" style="padding: 0px;font-size: 16px;">Stay On Browse Rfqs</a>
                                  </div>
                              </div> -->
                <div class="row d-xxl-flex" style="padding-top: 2rem;">
                  <div
                    class="col d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex justify-content-end justify-content-sm-end justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end"
                    style="padding-left: 0;padding-right: 34px;"><a data-bs-dismiss="modal"
                      [attr.data-bs-target]="'#success' + rfq.rfqId"
                      class="d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex d-xxl-flex align-items-center align-items-sm-center align-items-md-center align-items-lg-center align-items-xl-center align-items-xxl-center text-gray-500"
                      href="/" style="padding-right: 1rem;">Close</a>
                    <p class="d-sm-flex justify-content-sm-start align-items-sm-center align-items-md-center align-items-xl-center align-items-xxl-center bluetext"
                      style="margin-bottom: 0;"><a data-bs-dismiss="modal"
                        class="btn d-lg-flex justify-content-lg-center align-items-lg-center bluebutton" role="button"
                        (click)="navToNewQuote()"
                        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Go
                        to Quote</a>
                    </p>
                    <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0;"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<!-- Quote edit modal -->
<!-- <ng-container *ngIf = "selectedRFQ.quotedOn">
<app-edit-quote [quoteRfq] = "selectedRFQ"></app-edit-quote>
</ng-container> -->

<!-- DIMENSIONS MODAL -->

<div class="modal fade" role="dialog" tabindex="-1" [attr.id]="'g' + rfq.rfqId" aria-labelledby="exampleModalLabel">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header" style="background: var(--lightblue);">
        <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">
          Dimensions</h1><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="table-responsive" style="padding: 0.5rem;">
          <table class="table table-sm my-0 mydatatable">
            <thead class="buyerimage">
              <tr class="text-start">
                <th class="blueheadsmall" style="text-align: center;"> PART NUMBER</th>
                <th class="blueheadsmall" style="text-align: center;">TYPE</th>
                <th class="blueheadsmall" style="text-align: center;">MATERIAL GRADE
                </th>
                <th class="blueheadsmall" style="text-align: center;">DIMENSIONS</th>
                <th class="blueheadsmall" style="text-align: center;">QUANTITY</th>


              </tr>
            </thead>
            <tbody>
              <tr class="tablerow" *ngFor="let dimensions of rfq.dimensions">
                <td class="text-center bluetextthin">
                  {{dimensions.partNo}}</td>
                <td class="text-center bluetextthin">{{dimensions.type}}</td>
                <td class="text-center bluetextthin">{{dimensions.materialGrade}}</td>
                <td class="text-center bluetextthin">{{dimensions.dimensions}}</td>
                <td class="text-center bluetextthin">{{dimensions.quantity}}</td>


              </tr>


            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer"><a class="btn text-gray-500" role="button" data-bs-dismiss="modal">Close</a></div>
    </div>
  </div>
</div>




<!-- DRAWINGS MODAL -->
<div class="modal fade" role="dialog" tabindex="-1" [attr.id]="'e' + rfq.rfqId" aria-labelledby="exampleModalLabel">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header" style="background: var(--lightblue);">
        <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">
          Drawings
        </h1><button #closeDrawingsMdl type="button" class="btn-close" data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div>

          <div *ngIf="rfq.drawingsChangesComment != null"
            style="color: var(--shadowgrey);background: var(--shadowgrey);border-radius: 14px;padding: 1rem;padding-bottom: 0;margin: 1rem;margin-left: 0;">
            <div class="row d-flex d-xxl-flex justify-content-center justify-content-xxl-center">
              <div class="col d-flex d-xxl-flex justify-content-start align-items-xxl-center">
                <p class="text-nowrap d-sm-flex justify-content-sm-start align-items-xxl-center bluetext"
                  style="color: #0e0e82;">All Drawing Changes Comments</p>
              </div>
            </div>
            <div class="row rfqdetailrow">
              <div class="col">
                <p class="d-sm-flex justify-content-sm-start bluetextthin">{{rfq.drawingsChangesComment}}</p>
                <p class="d-sm-flex justify-content-sm-start bluetext" style="margin-bottom: 0;"></p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xxl-12" style="margin-top: 10px;">
              <section>
                <div class="table-responsive">
                  <table class="table table-sm my-0 mydatatable">
                    <thead class="buyerimage">
                      <tr class="text-start">
                        <th class="blueheadsmall" style="text-align: center;">
                          FILE NAME
                        </th>
                        <th class="blueheadsmall" style="text-align: center;">
                          DATE UPLOADED</th>
                        <th class="blueheadsmall" style="text-align: center;">
                          DOWNLOAD
                        </th>
                        <th class="blueheadsmall"></th>
                        <th class="blueheadsmall"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let file of rfq.rfqFiles">
                        <tr class="tablerow">
                          <td class="text-center bluetextthin">
                            {{file.fileName}}
                          </td>
                          <td class="text-center bluetextthin"> {{file.createdAt | date:'mediumDate'}}
                          </td>
                          <td class="text-nowrap" style="text-align: center;">
                            <a class="btn btn-secondary invoicebtn" role="button" (click)="fileDownload(file.rfqFileId)"
                              style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                class="fa fa-external-link" style="font-size: 14px;color: var(--lightblue);"></i></a>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer"><a class="btn text-gray-500" role="button" data-bs-dismiss="modal">Close</a></div>
    </div>
  </div>
</div>