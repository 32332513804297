import { Injectable, OnInit } from '@angular/core';
import { ChatMessage } from '../models/chatMessage';
import { Observable, of, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ChatMessages } from '../models/mock-data';
import { View_Chat } from '../models/viewChat';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { ToastrNotificationService } from './toastr-notification.service';
import { MessageService } from './message.service';
import { CreateChatMessage } from '../models/createchatmessage';

const baseUrl = `${environment.apiUrl}/Chat`;

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(
    private messageService: MessageService,
    private http: HttpClient,
    private toastrNotification: ToastrNotificationService
  ) { }



  getMockMessages(): Observable<ChatMessage[]> {
    const messages = of(ChatMessages);
    return messages;
  }


  /** GET Chat History from the server */
  //ViewRFQs?buyerId=1&rfqState=Open
  getchatHistory(quoteId: number, Role: string): Observable<View_Chat> {
    const url = `${baseUrl}/GetHistory?quoteId=${quoteId}&Role=${Role}`;

    return this.http.get<any>(url)
      .pipe(
        map(chatHistory => { return chatHistory.results }),
        tap(_ => this.log('fetched Chat History')),
        catchError(this.handleError<View_Chat>('GetChatHistoryList'))
      );
  }


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  /** POST: add a new rfq to the server */
  CreateChatMessage(newchatmessage: CreateChatMessage, seenMessages: number[]): Observable<CreateChatMessage> {
    const url = `${baseUrl}/SendMessage`;
    return this.http.post<any>(url, newchatmessage, this.httpOptions)
      .pipe(
        map(newchatmessage => {  return newchatmessage.message }),
        tap((newchatmessage: CreateChatMessage) => this.log(`Chat message saved successfully`)),
        catchError(this.handleError<CreateChatMessage>('Chat message saved successfully'))
      );
  }


  private log(message: string) {
    this.messageService.add(`ChatService: ${message}`);
  }

  private rfqUrl = 'api/Chat';  // URL to web api



  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.toastrNotification.error(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  //    private  connection: any = new signalR.HubConnectionBuilder().withUrl(`${baseUrl}/chatHub`)   // mapping to the chathub as in startup.cs
  //                                          .configureLogging(signalR.LogLevel.Information)
  //                                          .build();
  //    readonly POST_URL = `${baseUrl}/chat/send`

  //   private receivedMessageObject: chatMessage = new chatMessage();
  //   private sharedObj = new Subject<chatMessage>();

  //   constructor(private http: HttpClient) { 
  //     this.connection.onclose(async () => {
  //       await this.start();
  //     });
  //    this.connection.on("ReceiveMessage", (userId, messageContent) => { this.mapReceivedMessage(userId, messageContent); });
  //    this.start();                 
  //   }


  //   // Strart the connection
  //   public async start() {
  //     try {
  //       await this.connection.start();
  //       console.log("connected");
  //     } catch (err) {
  //       console.log(err);
  //       setTimeout(() => this.start(), 5000);
  //     } 
  //   }

  //   private mapReceivedMessage(userId: number, messageContent: string): void {
  //     this.receivedMessageObject.userId = userId;
  //     this.receivedMessageObject.messageContent = messageContent;
  //     this.sharedObj.next(this.receivedMessageObject);
  //  }

  //   /* ****************************** Public Mehods **************************************** */

  //   // Calls the controller method
  //   public broadcastMessage(chatMessage: any) {
  //     this.http.post(this.POST_URL, chatMessage).subscribe(data => console.log(data));
  //     // this.connection.invoke("SendMessage1", msgDto.user, msgDto.msgText).catch(err => console.error(err));    // This can invoke the server method named as "SendMethod1" directly.
  //   }

  //   public retrieveMappedObject(): Observable<chatMessage> {
  //     return this.sharedObj.asObservable();
  //   }


}