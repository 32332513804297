import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  loggedIn: boolean = false;

  private loggedInCheck = new BehaviorSubject<boolean>(this.loggedIn);

  currentloggedInCheck = this.loggedInCheck.asObservable();

  changeLoggedInStatus(cond: boolean) {
    this.loggedInCheck.next(cond);
  }

  constructor() { }
}
