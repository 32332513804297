import { Injectable } from '@angular/core';
import { Notification, NotifType } from '../models/notification';

import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MessageService } from './message.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { ToastrNotificationService } from './toastr-notification.service';

const baseUrl = `${environment.apiUrl}/Notification`;

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {


  /** GET notification from the server */
  //GetNotifications?username=1
  getNotifications( username: string): Observable<Notification[]> {
    const url = `${baseUrl}/GetNotifications?username=${username}`
    return this.http.get<any>(url)
      .pipe(
        map(notifications => {return notifications.results; }),
        tap(_ => this.log('fetched notifications')),
        catchError(this.handleError<Notification[]>('getNotifications', []))
      );
  }

  /** Mark Notification As seen */
  //MarkNotificationAsSeen?notificationId=1
  public setNotificationAsSeen( notifId: number): Observable<string> {
    const url = `${baseUrl}/MarkNotificationAsSeen?notificationId=${notifId}`
    return this.http.get<any>(url)
      .pipe(
        map(notifications => { return notifications.message; }),
        tap(_ => this.log('notification mark as seen')),
        catchError(this.handleError<string>('setNotificationAsSeen',))
      );
  }

   /** Mark Notification As seen */
  //MarkNotificationAsSeen?notificationId=1
  public setNotificationReturn( notifId: number): Observable<string> {
    const url = `${baseUrl}/MarkReturn?notificationId=${notifId}`
    return this.http.get<any>(url)
      .pipe(
        map(notifications => { return notifications.message; }),
        tap(_ => this.log('notification mark as seen')),
        catchError(this.handleError<string>('setNotificationAsSeen',))
      );
  }

   /** Log a HeroService message with the MessageService */
   private log(message: string) {
    this.messageService.add(`RFQService: ${message}`);
  }

  private rfqUrl = 'api/rfqs';  // URL to web api


  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.toastrNotification.error(`${operation} failed: ${error.message}`);

      throw error;
    };
  }


// idCount: number = 1; 
//   Date1: Date = new Date('July 1 2021');
 
//   notifications: Notification[] = [ { notifId: 1, notifRfqName: 'oros blow mould', buyerId: 1, supplierId: 1, notifType: NotifType.rfqSuccess, notification: 'The rfq was ulpoaded successfully', notifDate: this.Date1 }];
  
//   singleNotification: Notification = { 
//     notifId: null,
//     notifRfqName:  null,
//     buyerId:  null,
//     supplierId:  null,
//     notifType:  null,
// //     notification:  null,
//     notifDate:  null };


  // getMockNotifs(): Observable<Notification[]> {
  //   const notifs = of(this.notifications);
  //   return notifs;
  // }


  addNotification(notifRfqName,buyerId, supplierId,notifType,notification) {

    // this.singleNotification = { 
    //   notifId: null,
    //   notifRfqName:  null,
    //   buyerId:  null,
    //   supplierId:  null,
    //   notifType:  null,
    //   notification:  null,
    //   notifDate:  null };
  

    // this.singleNotification.notifId = this.idCount;
    // this.singleNotification.notifRfqName = notifRfqName;
    // this.singleNotification.buyerId = buyerId;
    // this.singleNotification.supplierId = supplierId;
    // this.singleNotification.notifType = notifType;
    // this.singleNotification.notification = notification;
    // const now = new Date();
    // this.singleNotification.notifDate = now;
    // this.notifications.push(this.singleNotification);
    
    // this.idCount++; 
  }

  clearNotification(nId) {
    // for (const { index, value } of this.notifications.map((value, index) => ({ index, value }))) {
    //   if (value.notifId == nId) {
    //     this.notifications.splice(index, 1);
    //   }
    // }
  }


  constructor(  
    private messageService: MessageService,
    private http: HttpClient,
    private toastrNotification: ToastrNotificationService) { }
}
