import { Component, OnInit } from '@angular/core';
import { ReloadViewRfqs } from '../models/reloadViewRfqs';
import { ViewQuoteServiceService } from '../services/view-quote-service.service';

@Component({
  selector: 'app-supplier-home',
  templateUrl: './supplier-home.component.html',
  styleUrls: ['./supplier-home.component.css']
})
export class SupplierHomeComponent implements OnInit {

  newQuoteEvent: ReloadViewRfqs = { reloadCondition: null, reloadRfqId: null };

  currentYear: number;


  constructor(private quoteReloader: ViewQuoteServiceService) {

    this.currentYear = new Date().getFullYear();
  }

  ngOnInit(): void {

  }

  loadQuotesNormal() {
    this.newQuoteEvent = { reloadCondition: false, reloadRfqId: null };
    this.newQuoteEvent.reloadCondition = false;
    this.newQuoteEvent.reloadRfqId = null
    this.quoteReloader.changeNotifNavQuoteAccepted(this.newQuoteEvent);
    this.quoteReloader.changeReloadClosedQuotes(this.newQuoteEvent);
    this.quoteReloader.changeNavToExpired(this.newQuoteEvent);
    this.quoteReloader.changeNavToNewQuote(this.newQuoteEvent);  
  }
}
