
export class ToastrNotifications { 

    type: ToastrNotificationType;  
    message: string;
    keepAfterRouteChange?: boolean;   
}

export enum ToastrNotificationType {  
    Success,  
    Error,  
    Info,  
    Warning   
}  