import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, AfterViewChecked, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import { format } from 'path';
import { finalize, first } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Account } from '../models/account';
import { ContactDetailsEdit } from '../models/contactDetailsEdit';
import { CreateUser } from '../models/createUser';
import { AddPreferedBuyer, PreferedBuyerFinal, preferedBuyerInitial } from '../models/preferredBuyer';
import { QuoteFileUpload } from '../models/quoteFile';
import { UploadRfqFile } from '../models/rfqCreate';
import { Province } from '../models/service';
import { CreditApplicationAction, GetAllTradelySpecificDetails, SupplierCompany, SupplierCreditApplicationList, TradleySpecificDetailsEdit } from '../models/supplierCompany';
import { SupplierCompanyService } from '../services/supplier-company.service';
import { ToastrNotificationService } from '../services/toastr-notification.service';

@Component({
  selector: 'app-supplier-profile',
  templateUrl: './supplier-profile.component.html',
  styleUrls: ['./supplier-profile.component.css']
})
export class SupplierProfileComponent implements OnInit {

  // NEEDED FOR DATA LOADING SPINNER
  dataLoading: boolean = false;

  //NEEDED FOR COLOUR OF EDIT USER MODAL
  greenformheader: boolean = false;
  blueformheader: boolean = true;

  // FOR CLOSING CONTACT DETAILS AFTER SUCCESFULL EDIT
  @ViewChild("editContactDetailsBtn") editContactDetailsBtn: ElementRef;

  // FOR CLOSING ADD USER MODAL AFTER SUCCESFULL EDIT
  @ViewChild('CloseAddUserModal') CloseAddUserModal: ElementRef;

  @ViewChild('editTradelyDetailsBtn') editTradelyDetailsBtn: ElementRef;
  //FOR CLOSING EDIT USER MODAL
  @ViewChild('CloseEditUserModal') CloseEditUserModal: ElementRef;

  //FOR CLOSING Add Preferred Buyer MODAL
  @ViewChild('addBuyerOpenBtn') addBuyerOpenBtn: ElementRef;

  @ViewChild('addPreferredBuyerBtn') addPreferredBuyerBtn: ElementRef;

  @ViewChild('DeleteUserModal') DeleteUserModal: ElementRef;

  @ViewChild('clearCreditAppInput', { static: false }) clearCreditAppInput: ElementRef;

  @ViewChild(' clearEditCreditAppInput', { static: false }) clearEditCreditAppInput: ElementRef;

  @ViewChild(' creditAppActionClose', { static: false }) creditAppActionClose: ElementRef;

  @ViewChild('BTab1') BTab1: ElementRef;
  //@ViewChild('BTab2') BTab2: ElementRef;
  @ViewChild('BTab3') BTab3: ElementRef;
  @ViewChild('BTab4') BTab4: ElementRef;

  //ROUTING
  tabParam: number;
  userTab: string = "";
  preferredBuyerTab: string = "";
  creditApplicationTab: string = "";

  //NEEDED FOR PAGINATION OF USERS BLOCK
  pageOfItems: Array<any>;
  pageOfItemsPS: Array<any>;
  pageOfItemsCA: Array<any>;
  maxUserListSize: number = 10;

  //NEEDED FOR EDIT USER MODALS
  userModalPos: number;

  //NEEDED FOR FILTER PIPE
  searchUsers: string = "";
  searchUserCheck: string = "";
  searchCreditApplications: string = "";

  // ARRAYS FOR EDIT TRADELY DETAILS IDs
  selectedLocationIDs: number[] = [];
  selectedPackagingIDs: number[] = [];
  selectedPaymentIDs: number[] = [];
  SelectedPartnershipIDs: number[] = [];

  provinces: Province[] = [Province.WesternCape, Province.NorthernCape, Province.NorthWest, Province.Mpumalanga, Province.Limpopo, Province.KwaZuluNatal, Province.Gauteng, Province.FreeState, Province.EasternCape]

  allTradelyDetails: GetAllTradelySpecificDetails;
  supplierCompanyDetails: SupplierCompany;
  SupplierCompanyDetailsEdit: TradleySpecificDetailsEdit = { SupplierId: null, PartnershipRequirements: null, PaymentRequirements: null, DeliveryCababilities: null, PackagingCababilities: null };
  account: Account;
  suppliercontactForm: FormGroup;
  editSupplierSpecificDetails: FormGroup;
  addNewUserForm: FormGroup;
  addPreferedBuyerForm: FormGroup;


  packagingNameList: string[] = [];
  PaymentOptionNameList: string[] = [];
  PartnershipOptionsNameList: string[] = [];
  deliveryLocationsNameList: string[] = [];

  packagingNameIdList: number[] = [];
  PaymentOptionNameIdList: number[] = [];
  PartnershipOptionsNameIdList: number[] = [];
  deliveryLocationsNameIdList: number[] = [];

  editTradelyDetails: boolean = false;
  editingContactDetails: boolean = false;
  contactDetailsErrorDisplay: boolean = false;

  //NEEDED VALIDATION
  addingNewUser: boolean = false;
  updatedContactDetails: ContactDetailsEdit = { SupplierId: null, StreetAddress: null, City: null, Province: null, PostalCode: null, CompanyEmail: null, CompanyPhoneNumber: null }

  // ADD NEW USER
  newUser: CreateUser = { SupplierId: null, Username: null, FirstName: null, Email: null, LastName: null, Role: null, Team: null, SMSPreference: null, EmailPreference: null }

  // NEEDED FOR PREFERRED BUYERS
  initialBuyers: preferedBuyerInitial[] = [];
  selectedBuyer: PreferedBuyerFinal = { buyerId: null, name: null, companyEmail: null, companyPhoneNumber: null, teams: null };
  preferredBuyer: AddPreferedBuyer = { BuyerId: null, SupplierId: null, Team: null };
  removePreferredBuyer: AddPreferedBuyer = { BuyerId: null, SupplierId: null, Team: null };

  // NEEDED FOR BUYER SEARCH
  searchBuyers: string = "";
  searchPreferredBuyers: string = "";

  // NEEDED TO ADD AND REMOVE PREFERRED BUYER
  selectedPreferredBuyerId: number;
  addingPreferredBuyerId: boolean = false;

  addingPreferredBuyer: boolean = false;
  removingPreferredBuyer: boolean = false;

  //NEEDED FOR CREDIT APPLICATIONS
  selectedCreditApplicant: SupplierCreditApplicationList = null;
  creditAppAction: CreditApplicationAction = {supplierId: null, buyerId: null, status: null};
  CreditAppActionLoading: boolean = false;
  creditAppActionForm: FormGroup;
  creditAppActionCheckbox: string;

  // CHECK IF PREFERRED BUYER MODAL OPEN
  openBuyerModal: boolean = false;

  //CHECK IF GETTING PREFFERED BUYER DETAILS
  gettingBuyerDetails: boolean = false;

  postalCodeValidator: RegExp = /^\s*?\d{1,10}\s*?$/i;

  constructor(
    public authService: AuthService,
    private supplierCompanyService: SupplierCompanyService,
    private formBuilder: FormBuilder,
    public toastrNotification: ToastrNotificationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.authService.account.subscribe(x => this.account = x);
  }



  ngOnInit(): void {

    this.addNewUserForm = this.formBuilder.group({

      userName: ["", Validators.email],
      firstName: [""],
      lastName: [""],
      role: [""],
      team: [""],
      sMSPreference: [false],
      emailPreference: [false],

    });


    this.addPreferedBuyerForm = this.formBuilder.group({

      buyerName: [""],
      buyerEmail: [""],
      buyerContactNumber: [""],
      team: [""]

    });

    this.pb.buyerName.disable();
    this.pb.buyerEmail.disable();;
    this.pb.buyerContactNumber.disable();
    this.pb.team.disable();
    this.nu.role.disable();

    this.getSupplierCompanyData();
    this.getAllTradelyData();

    this.creditAppActionForm = this.formBuilder.group({

      Accepted: [false],
      Rejected: [false]
    });

    this.route.queryParamMap.subscribe(params => {

      this.tabParam = +params.get('Tab');

      console.log(this.tabParam);
    
      if (this.tabParam == 1) {
        this.changeToUserTab();
      } else if ((this.tabParam == 3)) {
        this.changeToPreferredBuyerTab();
      } else if ((this.tabParam == 4)) {
        this.changeTocreditApplicationTab();
      } else {
        this.router.navigate(['supplier-home/supplier-profile'], {
          queryParams: {
            Tab: 1
          },
          queryParamsHandling: 'merge'
        });
      }

    });
  }

  changeToUserTab(){
    this.userTab = "active";
    this.preferredBuyerTab = "";
    this.creditApplicationTab = "";
  }


  changeToPreferredBuyerTab(){
    this.userTab = "";
    this.preferredBuyerTab = "active";
    this.creditApplicationTab = "";
  }

  changeTocreditApplicationTab(){
    this.userTab = "";
    this.preferredBuyerTab = "";
    this.creditApplicationTab = "active";
  }

  changeTab(tabNumber){
    this.router.navigate(['supplier-home/supplier-profile'], {
      queryParams: {
        Tab: tabNumber
      },
      queryParamsHandling: 'merge'
    });
  }

  onChangePageCA(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItemsCA = pageOfItems;
  }

  getAllTradelyData() {
    this.supplierCompanyService.getAllTradelySpecificData().subscribe(y => {
      this.allTradelyDetails = y;


      //PUSHES IDs INTO INITIAL ARRAYS WHEN MODAL IS OPENED
      for (let location of this.supplierCompanyDetails.deliveryCapabilities) {
        this.LocationCheck(true, location.locationName);
      }

      for (let packaging of this.supplierCompanyDetails.packagingCapabilities) {
        this.PackagingCheck(true, packaging.packagingName);
      }

      for (let paymentoption of this.supplierCompanyDetails.paymentRequirements) {
        this.PaymentOptionCheck(true, paymentoption.paymentOptionName);
      }

      for (let partnership of this.supplierCompanyDetails.partnershipRequirements) {
        this.PartnershipOptionCheck(true, partnership.partnershipOptionName);
      }


    });

  }

  getSupplierCompanyData() {

    this.packagingNameIdList = [];
    this.PaymentOptionNameIdList = [];
    this.PartnershipOptionsNameIdList = [];
    this.deliveryLocationsNameIdList = [];

    // FLAG FOR DATA LOADING SETS VALUE TO TRUE WHILE DATA IS BEING FETCHED
    this.dataLoading = true;

    // FLAG FOR DATA LOADING SETS VALUE TO FALSE WHEN DATA IS FETCHED
    this.supplierCompanyService.getSupplierCompanyData(this.account.account.supplierId).pipe(
      finalize(() => this.dataLoading = false)
    ).subscribe(x => {
      this.supplierCompanyDetails = x;
      this.companyLogoFileName = x.profilePictureFileName;
      this.getCompanyLogoFile();


      //SETTING STATUS BADGE FOR SUPPLIER USER
      for (let user of this.supplierCompanyDetails.supplierUsers) {
        user.fullname = user.firstName + " " + user.lastName;

        if (user.emailConfirmed) {
          user.status = "Active";
        } else {
          user.status = "Pending";
        }
      }

      this.packagingNameList = [];
      this.PaymentOptionNameList = [];
      this.PartnershipOptionsNameList = [];
      this.deliveryLocationsNameList = [];


      //ADDS PACKAGES NAMES INTO A ARRAY
      for (let packageName of this.supplierCompanyDetails.packagingCapabilities) {

        this.packagingNameList.push(packageName.packagingName);
        this.packagingNameIdList.push(packageName.packagingId);

      }

      //ADDS PAYMENT OPTIONS NAMES INTO A ARRAY
      for (let payment of this.supplierCompanyDetails.paymentRequirements) {

        this.PaymentOptionNameList.push(payment.paymentOptionName);
        this.PaymentOptionNameIdList.push(payment.paymentOptionId);

      }


      //ADDS PARTNERSHIP REQUIREMENT OPTIONS NAMES INTO A ARRAY

      for (let PR of this.supplierCompanyDetails.partnershipRequirements) {

        this.PartnershipOptionsNameList.push(PR.partnershipOptionName);
        this.PartnershipOptionsNameIdList.push(PR.partnershipOptionId);


      }

      //ADDS DELIVERY CAPABILITIES LOCATION  NAMES INTO A ARRAY

      for (let deliverylocation of this.supplierCompanyDetails.deliveryCapabilities) {

        this.deliveryLocationsNameList.push(deliverylocation.locationName);
        this.deliveryLocationsNameIdList.push(deliverylocation.locationId);

      }


      this.suppliercontactForm = this.formBuilder.group({

        streetAddress: [this.supplierCompanyDetails.streetAddress],
        city: [this.supplierCompanyDetails.city],
        province: [""],
        postalCode: [this.supplierCompanyDetails.postalCode],
        companyEmail: [this.supplierCompanyDetails.companyEmail, Validators.email],
        companyPhoneNumber: [this.supplierCompanyDetails.companyPhoneNumber]

      });

      this.setDefaultProvince(this.supplierCompanyDetails.province);

      this.editSupplierSpecificDetails = this.formBuilder.group({

        boxed: [this.packagingNameList.includes("Boxed")],
        pallets: [this.packagingNameList.includes("Pallet")],
        strapped: [this.packagingNameList.includes("Strapped")],
        loose: [this.packagingNameList.includes("Loose")],
        cod: [this.PaymentOptionNameList.includes("COD")],
        cbd: [this.PaymentOptionNameList.includes("CBD")],
        thirty_day: [this.PaymentOptionNameList.includes("30 Days")],
        sixty_day: [this.PaymentOptionNameList.includes("60 Days")],
        no_preference: [this.PaymentOptionNameList != null],
        verified: [this.PartnershipOptionsNameList.includes("Verified")],
        preferredBuyers: [this.PartnershipOptionsNameList.includes("Preferred Buyers")],
        vendor: [this.PartnershipOptionsNameList.includes("Vendor")],
        easternCape: [this.deliveryLocationsNameList.includes("Eastern Cape")],
        freeState: [this.deliveryLocationsNameList.includes("Free State")],
        gauteng: [this.deliveryLocationsNameList.includes("Gauteng")],
        kzn: [this.deliveryLocationsNameList.includes("KwaZulu-Natal")],
        limpopo: [this.deliveryLocationsNameList.includes("Limpopo")],
        mpumalanga: [this.deliveryLocationsNameList.includes("Mpumalanga")],
        northernCape: [this.deliveryLocationsNameList.includes("Northern Cape")],
        northWest: [this.deliveryLocationsNameList.includes("North West")],
        westernCape: [this.deliveryLocationsNameList.includes("Western Cape")],
      });
    });

  }

  setDefaultProvince(province) {

    if (province == "Gauteng") {
      this.cf.province.setValue(Province.Gauteng);
    } else if (province == "Western Cape") {
      this.cf.province.setValue(Province.WesternCape);
    } else if (province == "Eastern Cape") {
      this.cf.province.setValue(Province.EasternCape);
    } else if (province == "Free State") {
      this.cf.province.setValue(Province.FreeState);
    } else if (province == "KwaZulu-Natal") {
      this.cf.province.setValue(Province.KwaZuluNatal);
    } else if (province == "Limpopo") {
      this.cf.province.setValue(Province.Limpopo);
    } else if (province == "Mpumalanga") {
      this.cf.province.setValue(Province.Mpumalanga);
    } else if (province == "Northern Cape") {
      this.cf.province.setValue(Province.NorthernCape);
    } else if (province == "North West") {
      this.cf.province.setValue(Province.NorthWest);
    }
  }

  arrayEquals(a, b) {
    var array1 = a.sort((n1, n2) => n1 - n2);
    var array2 = b.sort((n1, n2) => n1 - n2);
    return Array.isArray(array1) &&
      Array.isArray(array2) &&
      array1.length === array2.length &&
      array1.every((val, index) => val === array2[index]);
  }

  //METHOD FOR PUSHING CHECKBOX VALUES CORRESPONDING IDs TO ARRAYS
  LocationCheck(cond, locname) {
    for (let location of this.allTradelyDetails.location) {
      if (location.locationName == locname) {
        if (cond && this.selectedLocationIDs.indexOf(location.locationId) == -1) {
          this.selectedLocationIDs.push(location.locationId);

        }
        else if (!cond) {
          this.selectedLocationIDs.splice(this.selectedLocationIDs.indexOf(location.locationId), 1);
        };
      }
    }
  }


  PackagingCheck(cond, packagename) {
    for (let pack of this.allTradelyDetails.packaging) {
      if (pack.packagingName == packagename) {
        if (cond && this.selectedPackagingIDs.indexOf(pack.packagingId) == -1) {
          this.selectedPackagingIDs.push(pack.packagingId);

        }
        else if (!cond) {
          this.selectedPackagingIDs.splice(this.selectedPackagingIDs.indexOf(pack.packagingId), 1);
        };
      }
    }


  }

  PaymentOptionCheck(cond, paymentname) {
    for (let payoption of this.allTradelyDetails.paymentOption) {
      if (payoption.paymentOptionName == paymentname) {
        if (cond && this.selectedPaymentIDs.indexOf(payoption.paymentOptionId) == -1) {
          this.selectedPaymentIDs.push(payoption.paymentOptionId);

        }
        else if (!cond) {
          this.selectedPaymentIDs.splice(this.selectedPaymentIDs.indexOf(payoption.paymentOptionId), 1);
        };
      }
    }
  }

  PartnershipOptionCheck(cond, partnershipname) {
    for (let partner of this.allTradelyDetails.partnershipOption) {
      if (partner.partnershipOptionName == partnershipname) {
        if (cond && this.SelectedPartnershipIDs.indexOf(partner.partnershipOptionId) == -1) {
          this.SelectedPartnershipIDs.push(partner.partnershipOptionId);

        }
        else if (!cond) {
          this.SelectedPartnershipIDs.splice(this.SelectedPartnershipIDs.indexOf(partner.partnershipOptionId), 1);
        };
      }
    }
  }


  SupplierDetailsEdit() {


    this.SupplierCompanyDetailsEdit = { SupplierId: null, PartnershipRequirements: null, PaymentRequirements: null, DeliveryCababilities: null, PackagingCababilities: null }
    this.SupplierCompanyDetailsEdit.SupplierId = this.account.account.supplierId;
    this.SupplierCompanyDetailsEdit.DeliveryCababilities = this.selectedLocationIDs;
    this.SupplierCompanyDetailsEdit.PaymentRequirements = this.selectedPaymentIDs;
    this.SupplierCompanyDetailsEdit.PartnershipRequirements = this.SelectedPartnershipIDs;
    this.SupplierCompanyDetailsEdit.PackagingCababilities = this.selectedPackagingIDs;



    if (!this.arrayEquals(this.packagingNameIdList, this.selectedPackagingIDs)
      || !this.arrayEquals(this.PartnershipOptionsNameIdList, this.SelectedPartnershipIDs)
      || !this.arrayEquals(this.PaymentOptionNameIdList, this.selectedPaymentIDs)
      || !this.arrayEquals(this.deliveryLocationsNameIdList, this.selectedLocationIDs)) {

      //Boolean for while editing
      this.editTradelyDetails = true;

      //POST THE RESPONSE TO BACKEND (TRADELY DETAILS)
      this.supplierCompanyService.editTradleyDetails(this.SupplierCompanyDetailsEdit).pipe(first())
        .subscribe({
          next: (response: any) => {

            this.getSupplierCompanyData();
            this.editTradelyDetailsBtn.nativeElement.click();
            this.editTradelyDetails = false;

            this.toastrNotification.success(" Edit Tradely Details Successfull");
          },
          error: error => {
            this.toastrNotification.error(" Edit Tradely Details Failed");
            this.editTradelyDetails = false;
          }
        });

    } else {
      this.toastrNotification.info("No Changes Present");
    }
  }

  // POSTS THE EDITED CONTACT DETAILS
  contactDetailsEdit() {



    this.updatedContactDetails = { SupplierId: null, StreetAddress: null, City: null, Province: null, PostalCode: null, CompanyEmail: null, CompanyPhoneNumber: null }

    this.updatedContactDetails.SupplierId = this.account.account.supplierId;
    this.updatedContactDetails.StreetAddress = this.cf.streetAddress.value;
    this.updatedContactDetails.City = this.cf.city.value;
    this.updatedContactDetails.Province = this.cf.province.value;
    this.updatedContactDetails.PostalCode = this.cf.postalCode.value;
    this.updatedContactDetails.CompanyEmail = this.cf.companyEmail.value;
    this.updatedContactDetails.CompanyPhoneNumber = this.cf.companyPhoneNumber.value;


    if (this.updatedContactDetails.StreetAddress != this.supplierCompanyDetails.streetAddress
      || this.updatedContactDetails.City != this.supplierCompanyDetails.city
      || this.updatedContactDetails.Province != this.supplierCompanyDetails.province
      || this.updatedContactDetails.PostalCode != this.supplierCompanyDetails.postalCode
      || this.updatedContactDetails.CompanyEmail != this.supplierCompanyDetails.companyEmail
      || this.updatedContactDetails.CompanyPhoneNumber != this.supplierCompanyDetails.companyPhoneNumber) {

      //Boolean for while editing
      this.editingContactDetails = true;

      //POST THE RESPONSE TO BACKEND
      this.supplierCompanyService.editSupplierDetails(this.updatedContactDetails).pipe(first())
        .subscribe({
          next: (response: any) => {


            this.editContactDetailsBtn.nativeElement.click();
            this.editingContactDetails = false;
            this.getSupplierCompanyData();
            this.toastrNotification.success(" Edit Contact Details Successfully");
          },
          error: error => {
            this.toastrNotification.error(" Edit Contact Details Failed");
            this.editingContactDetails = false;
          }
        });
    } else {
      this.toastrNotification.info("No Changes Present");
    }

  }


  contactDetailsValid() {
    if (this.cf.streetAddress.value.replace(/ /g, "") != "" && this.cf.streetAddress.value.length < 100
      && this.cf.city.value.replace(/ /g, "") != "" && this.cf.city.value.length < 50
      && this.cf.province.value.replace(/ /g, "") != "" && this.cf.province.value.length < 50
      && this.cf.postalCode.value.replace(/ /g, "") != "" && this.cf.postalCode.value.length < 10
      && this.postalCodeValid(this.cf.postalCode.value)
      && this.cf.companyEmail.value.replace(/ /g, "") != "" && this.cf.companyEmail.value.length < 50
      && this.cf.companyPhoneNumber.value.replace(/ /g, "") != "" && this.cf.companyPhoneNumber.value.length < 15) {
      return true;
    } else {
      return false;
    }
  }


  contactDetailsValidCond() {
    if (this.cf.streetAddress.value.replace(/ /g, "") != "" && this.cf.streetAddress.value.length < 100
      && this.cf.city.value.replace(/ /g, "") != "" && this.cf.city.value.length < 50
      && this.cf.province.value.replace(/ /g, "") != "" && this.cf.province.value.length < 50
      && this.cf.postalCode.value.replace(/ /g, "") != "" && this.cf.postalCode.value.length < 10
      && this.postalCodeValid(this.cf.postalCode.value)
      && this.cf.companyEmail.value.replace(/ /g, "") != "" && this.cf.companyEmail.value.length < 50
      && this.cf.companyPhoneNumber.value.replace(/ /g, "") != "" && this.cf.companyPhoneNumber.value.length < 15) {
      this.contactDetailsErrorDisplay = false;
    } else {
      this.contactDetailsErrorDisplay = true;
    }
  }

  postalCodeValid(value) {
    return this.postalCodeValidator.test(value);

  }

  preferredBuyerCond: boolean;

  //  VALIDATION FOR BUYER TEAMS
  addPreferredBuyerValid() {

    if (this.pb.team.value == "") {
      this.preferredBuyerCond = true;
    } else {
      this.preferredBuyerCond = false;
    }
  }

  //EDITS NOTHING WHEN DETAILS ARE NOT SUBMITTED AND CLICKED OUT OF THE MODAL
  cancelContactDetailsEdit() {
    this.cf.streetAddress.setValue(this.supplierCompanyDetails.streetAddress);
    this.cf.city.setValue(this.supplierCompanyDetails.city);
    this.cf.province.setValue(this.supplierCompanyDetails.province);
    this.cf.postalCode.setValue(this.supplierCompanyDetails.postalCode);
    this.cf.companyEmail.setValue(this.supplierCompanyDetails.companyEmail);
    this.cf.companyPhoneNumber.setValue(this.supplierCompanyDetails.companyPhoneNumber);
  }


  cancelTradelyDetailsEdit() {
    this.esd.boxed.setValue([this.packagingNameList.includes("Boxed")]);

  }


  stringValidation(value) {
    if (value.replace(/ /g, "") == "") {
      return true
    } else {
      return false;
    }

  }
  addNewUser() {

    this.addingNewUser = true;

    this.newUser = { SupplierId: null, Username: null, FirstName: null, Email: null, LastName: null, Role: null, Team: null, SMSPreference: null, EmailPreference: null }
    this.newUser.SupplierId = this.account.account.supplierId;
    this.newUser.FirstName = this.nu.firstName.value;
    this.newUser.LastName = this.nu.lastName.value;
    this.newUser.Role = "Supplier";
    this.newUser.Team = this.nu.team.value;
    this.newUser.Username = this.nu.userName.value;
    this.newUser.Email = this.nu.userName.value;
    this.newUser.SMSPreference = this.nu.sMSPreference.value;
    this.newUser.EmailPreference = this.nu.emailPreference.value;


    this.authService.createUser(this.newUser).pipe(first())
      .subscribe({

        next: (response: any) => {
          this.CloseAddUserModal.nativeElement.click();
          this.addingNewUser = false;
          this.getSupplierCompanyData();
          this.clearAddUser();
          this.toastrNotification.success("User Added Successfully");
        },
        error: error => {
          this.toastrNotification.error("Add User Failed");
          this.addingNewUser = false;
        }
      });
  }


  EditUserModalTrigger(cond) {
    this.CloseEditUserModal.nativeElement.click();
    this.getSupplierCompanyData();
  }

  openDeleteUserModal(value) {

    this.CloseEditUserModal.nativeElement.click();
    this.DeleteUserModal.nativeElement.click();
  }

  deletingUser: boolean = false;

  deleteUser(user) {

    this.deletingUser = true;

    this.authService.deleteUser(user.userName).pipe(first()).subscribe({
      next: (response: any) => {

        this.DeleteUserModal.nativeElement.click();
        this.toastrNotification.success("User Deleted Successfully");
        this.deletingUser = false;
        this.getSupplierCompanyData();

      },
      error: error => {

        this.toastrNotification.error("User Delete Failed");
        this.deletingUser = false;

      }
    });
  }


  // CLEARS ADD USER FIELDS
  clearAddUser() {

    this.nu.firstName.setValue("");
    this.nu.lastName.setValue("");
    this.nu.role.setValue("");
    this.nu.team.setValue("");
    this.nu.userName.setValue("");
    this.nu.sMSPreference.setValue(false);
    this.nu.emailPreference.setValue(false);

  }

  //NEEDED FOR VALDATION OF ADD USER FIELDS
  userDetailsValid() {
    if (this.nu.firstName.value.replace(/ /g, "") != "" && this.nu.firstName.value.length < 50
      && this.nu.lastName.value.replace(/ /g, "") != "" && this.nu.lastName.value.length < 50
      // && this.nu.role.value.replace(/ /g, "") != ""
      && this.nu.team.value.replace(/ /g, "") != "" && this.nu.team.value.length < 50
      && this.nu.userName.value.replace(/ /g, "") != "" && this.nu.userName.value.length < 50) {
      return true;
    } else {
      return false;
    }
  }

  addUserErrorDisplay: boolean;

  userDetailsValidCond() {
    if (this.nu.firstName.value.replace(/ /g, "") != "" && this.nu.firstName.value.length < 50
      && this.nu.lastName.value.replace(/ /g, "") != "" && this.nu.lastName.value.length < 50
      // && this.nu.role.value.replace(/ /g, "") != ""
      && this.nu.team.value.replace(/ /g, "") != "" && this.nu.team.value.length < 50
      && this.nu.userName.value.replace(/ /g, "") != "" && this.nu.userName.value.length < 50) {
      this.addUserErrorDisplay = false;
    } else {
      this.addUserErrorDisplay = true;
    }
  }

  getInitialBuyerList() {

    this.openBuyerModal = true;
    this.supplierCompanyService.getAllBuyers(this.account.account.userId).pipe(first())
      .subscribe({

        next: (response: any) => {
          this.initialBuyers = response;

          this.addBuyerOpenBtn.nativeElement.click();
          this.openBuyerModal = false;
        },
        error: error => {
          this.toastrNotification.error("Get Buyer List Failed");
          this.openBuyerModal = false;


        }
      });



  }

  getPreferredBuyerSelect(buyerId: number) {

    this.gettingBuyerDetails = true;

    this.supplierCompanyService.getPreferedBuyersFinal(this.account.account.supplierId, buyerId).pipe(first())
      .subscribe({

        next: (response: any) => {

          this.selectedBuyer = response;

          this.pb.team.enable();

          this.pb.buyerName.setValue(response.name);
          this.pb.buyerEmail.setValue(response.companyEmail);
          this.pb.buyerContactNumber.setValue(response.companyPhoneNumber);
          this.pb.team.setValue(response.team);


          // this.CloseAddUserModal.nativeElement.click();
          this.gettingBuyerDetails = false;
        },
        error: error => {
          this.toastrNotification.error("Get Preferred Buyer Failed");
          this.gettingBuyerDetails = false;
        }
      });



  }


  addPreferredBuyer() {

    this.addingPreferredBuyer = true;

    this.preferredBuyer = { BuyerId: null, SupplierId: null, Team: null };
    this.preferredBuyer.SupplierId = this.account.account.supplierId;
    this.preferredBuyer.BuyerId = this.selectedBuyer.buyerId;
    this.preferredBuyer.Team = this.pb.team.value;



    this.supplierCompanyService.addPreferedBuyer(this.preferredBuyer).pipe(first())
      .subscribe({

        next: (response: any) => {

          this.pb.team.setValue("");
          this.addPreferredBuyerBtn.nativeElement.click();
          this.addingPreferredBuyer = false;
          this.getSupplierCompanyData();

        },
        error: error => {
          this.toastrNotification.error("Add Preferred Buyer Failed");
          this.addingPreferredBuyer = false;
        }
      });

  }



  removePreferedBuyer(BuyerId, team) {

    this.selectedPreferredBuyerId = BuyerId;


    this.removingPreferredBuyer = true;

    this.removePreferredBuyer = { BuyerId: null, SupplierId: null, Team: null };

    this.removePreferredBuyer.BuyerId = BuyerId;
    this.removePreferredBuyer.SupplierId = this.account.account.supplierId;
    this.removePreferredBuyer.Team = team;

    this.supplierCompanyService.removePreferedBuyer(this.removePreferredBuyer).pipe(first())
      .subscribe({

        next: (response: any) => {

          this.getSupplierCompanyData();
          this.removingPreferredBuyer = false;
          this.toastrNotification.success("Preferred Buyer Removed");
        },
        error: error => {
          this.toastrNotification.error("Remove Preferred Buyer Failed");
          this.removingPreferredBuyer = false;
        }
      });

  }



  // REMOVE WHITE SPACES FROM SEARCH
  removeSFWhiteSpace(value) {
    var newValue = value.trim();
    return newValue;
  }

  userModalPosition(pos) {
    this.userModalPos = pos;
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  onChangePagePS(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItemsPS = pageOfItems;
  }

  searchUserChange() {
    this.searchUserCheck = this.searchUsers
  }

  setSelectedCreditApplicant(creditApplicant: SupplierCreditApplicationList) {
    this.selectedCreditApplicant = creditApplicant;
  }

  takeCreditAppAction() {
    console.log(this.selectedCreditApplicant);
    this.creditAppAction.buyerId = this.selectedCreditApplicant.buyerId;
    this.creditAppAction.supplierId = this.account.account.supplierId;
    this.creditAppAction.status = this.creditAppActionCheckbox;

    if (this.creditAppAction.status != null) {
      this.CreatingCreditApplication = true;

      this.supplierCompanyService.takeCreditApplicationAction(this.creditAppAction).pipe(first())
        .subscribe({
          next: (response: any) => {
            this.CreatingCreditApplication = false;
            this.creditAppActionClose.nativeElement.click();
            this.selectedCreditApplicant.status = this.creditAppAction.status;
          },
          error: error => {
            this.toastrNotification.error("Credit App Action Failed");
          }
        }
        );
    }
  }


  companyLogo: string = "";
  companyLogoFileName: string = "";

  differentCompanyLogoReload() {
    if (this.companyLogoFileName != this.supplierCompanyDetails.profilePictureFileName) {
      this.getCompanyLogoFile();
      this.companyLogoFileName = this.supplierCompanyDetails.profilePictureFileName;
    }
  }

  getCompanyLogoFile() {
    this.supplierCompanyService.getCompanyLogoFile(this.account.account.supplierId).pipe(first())
      .subscribe({
        next: (response: any) => {
          var fileType = this.supplierCompanyDetails.profilePictureFileName.substring(this.supplierCompanyDetails.profilePictureFileName.indexOf(".") + 1, this.supplierCompanyDetails.profilePictureFileName.length);
          this.companyLogo = `data:image/${fileType};base64,${response.fileContent}`;
          this.companyLogoPreview = `data:image/${fileType};base64,${response.fileContent}`;
          this.supplierCompanyDetails.profilePictureFileName = response.fileName;
          this.companyLogoFileNamePreview = response.fileName;
          this.companyLogoUploading = false;
        },
        error: error => {
          this.toastrNotification.error("Supplier Company Logo Download Failed");
          this.companyLogoUploading = false;

        }
      }
      );
  }

  creditAppDownload: boolean = false;

  getCreditApplicationTemplateFile() {
    this.creditAppDownload = true;
    this.supplierCompanyService.getCreditApplicationTemplateFile(this.account.account.supplierId).pipe(first())
      .subscribe({
        next: (response: any) => {

          this.creditAppDownload = false;
          this.downloadFile(response.fileContent, response.fileName);
        },
        error: error => {
          this.toastrNotification.error("Supplier Credit Application Template Download Failed");
          this.creditAppDownload = false;

        }
      }
      );
  }

  downloadFile(base64String, fileName) {

    const source = `data:application/;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}`
    link.click();
  }



  companyLogoFile: File;
  companyLogoUpload: UploadRfqFile = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
  requiredFileType: string = ".jpg,.png,.jpeg";
  requiredCRFileType: string = ".pdf";
  allowedFileCheck: string[] = ["jpg", "png", "jpeg"];
  companyLogoUploading: boolean = false;
  companyLogoPreview: string = "";
  companyLogoFileNamePreview: string = "";

  @ViewChild('clearCompanyLogoInput', { static: false }) clearCompanyLogoInput: ElementRef;
  @ViewChild('closeBuyerDetailsMdl') closeBuyerDetailsMdl: ElementRef;
  @ViewChild('closeCreditAppTemplateUploadMdl') closeCreditAppTemplateUploadMdl: ElementRef;


  resetComapnyLogoPreview() {
    this.companyLogoPreview = this.companyLogo;
    this.companyLogoFileNamePreview = this.supplierCompanyDetails.profilePictureFileName;
  }

  uploadCompanyLogo() {

    if (this.companyLogoUpload.file != null) {

      this.companyLogoUploading = true;

      if (this.supplierCompanyDetails.profilePictureFileName != null) {

        this.supplierCompanyService.deleteCompanyLogoFile(this.account.account.supplierId).pipe(first())
          .subscribe({
            next: (response: any) => {

              this.supplierCompanyService.uploadCompanyLogo(this.companyLogoUpload).pipe(first())
                .subscribe({
                  next: (response: any) => {
                    // this.getBuyerCompanyData();
                    this.closeBuyerDetailsMdl.nativeElement.click();
                    this.supplierCompanyDetails.profilePictureFileName = response;
                    this.getCompanyLogoFile();
                    this.clearCompanyLogoInput.nativeElement.value = "";
                    this.companyLogoUploading = false;

                  },
                  error: error => {
                    this.toastrNotification.error("Buyer Company Logo Upload Failed");
                    this.clearCompanyLogoInput.nativeElement.value = "";
                    this.companyLogoUploading = false;
                  }
                }
                );
            },
            error: error => {
              this.toastrNotification.error("Buyer Company Logo Edit Failed");
              this.clearCompanyLogoInput.nativeElement.value = "";
              this.companyLogoUploading = false;
            }
          }
          );
      } else {

        this.supplierCompanyService.uploadCompanyLogo(this.companyLogoUpload).pipe(first())
          .subscribe({
            next: (response: any) => {
              // this.getBuyerCompanyData();
              this.closeBuyerDetailsMdl.nativeElement.click();
              this.supplierCompanyDetails.profilePictureFileName = response;
              this.getCompanyLogoFile();
              this.clearCompanyLogoInput.nativeElement.value = "";
              this.companyLogoUploading = false;

            },
            error: error => {
              this.toastrNotification.error("Buyer Company Logo Upload Failed");
              this.clearCompanyLogoInput.nativeElement.value = "";
              this.companyLogoUploading = false;
            }
          }
          );
      }

    } else {
      this.closeBuyerDetailsMdl.nativeElement.click();
    }

  }

  companyLogoSelected(event) {

    this.companyLogoUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };

    this.companyLogoFile = event.target.files[0];
    const reader = new FileReader();

    //this.companyLogoUploading = true;

    if (this.companyLogoFile) {


      const max_height = 5000;
      const max_width = 5000;

      reader.readAsDataURL(this.companyLogoFile);
      reader.onload = (e: any) => {

        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];


          if (img_height > max_height && img_width > max_width) {
            this.toastrNotification.error("Image Exceeds width and height of 5000px");
            this.clearCompanyLogoInput.nativeElement.value = "";
          } else if (img_height != img_width) {
            this.toastrNotification.error("Image Width Must Be Equal To Image Height");
            this.clearCompanyLogoInput.nativeElement.value = "";
          } else {

            this.companyLogoPreview = reader.result + "";
            this.companyLogoFileNamePreview = this.companyLogoFile.name;
            this.companyLogoUpload.file = (reader.result + "").substring((reader.result + "").indexOf(",") + 1, (reader.result + "").length);
            // this.companyLogoUpload.quoteId = this.selectedRFQ.acceptedQuotes[0].quoteId;
            // this.companyLogoUpload.rfqId = this.selectedRFQ.rfqId;
            this.companyLogoUpload.supplierId = this.account.account.supplierId;
            this.companyLogoUpload.userId = this.account.account.userId;;
            this.companyLogoUpload.buyerId = null;
            this.companyLogoUpload.fileName = this.companyLogoFile.name;

            this.clearCompanyLogoInput.nativeElement.value = "";


          }
        };
      };
    }
  }

  // SUPPLIER CONTACT DETAILS FORM
  get cf() { return this.suppliercontactForm.controls; }
  // NEW USER FORM
  get nu() { return this.addNewUserForm.controls; }
  //EDIT TRADELY DETAILS FORM
  get esd() { return this.editSupplierSpecificDetails.controls; }
  // ADD PREFERRED BUYER FORM
  get pb() { return this.addPreferedBuyerForm.controls; }
  // TAKE CREDIT APP
  get caa() { return this.creditAppActionForm.controls; }



  creditApplicationTemplateUpload: QuoteFileUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
  creditApplicationTemplateFile: File;
  CreditApplicationUploading: boolean = false;
  CreditApplicationFileNamePreview: string = "";
  CreatingCreditApplication: boolean = false;

  creditAppCheckboxToggle(status) {

    if (status == 'Accepted') {
      this.caa.Rejected.setValue(false);

    }
    if (status == 'Rejected') {
      this.caa.Accepted.setValue(false);
    }

    this.creditAppActionCheckbox = status;

    console.log(this.creditAppActionCheckbox);
  }



  uploadCreditApplicationTemplateFileInitial(event) {

    this.creditApplicationTemplateUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };

    this.creditApplicationTemplateFile = event.target.files[0];
    const reader = new FileReader();

    if (this.creditApplicationTemplateFile) {

      reader.readAsDataURL(this.creditApplicationTemplateFile);
      reader.onload = (e: any) => {

        this.CreditApplicationFileNamePreview = this.creditApplicationTemplateFile.name;
        this.creditApplicationTemplateUpload.file = (reader.result + "").substring((reader.result + "").indexOf(",") + 1, (reader.result + "").length);
        this.creditApplicationTemplateUpload.userId = this.account.account.userId;;
        this.creditApplicationTemplateUpload.supplierId = this.account.account.supplierId;
        this.creditApplicationTemplateUpload.fileName = this.creditApplicationTemplateFile.name;

        this.clearCreditAppInput.nativeElement.value = "";


      };
    }

  }

  uploadCreditApplicationTemplateFileFinal() {

    if (this.creditApplicationTemplateUpload.file != null) {
      this.CreatingCreditApplication = true;

      this.supplierCompanyService.uploadCreditApplicationTemplateFile(this.creditApplicationTemplateUpload).pipe(first())
        .subscribe({
          next: (response: any) => {
            this.CreatingCreditApplication = false;
            this.supplierCompanyDetails.creditAppTemplateFileName = this.creditApplicationTemplateUpload.fileName;
            this.creditApplicationTemplateUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
            this.CreditApplicationFileNamePreview = "";
            this.closeCreditAppTemplateUploadMdl.nativeElement.click();
          },
          error: error => {
            this.toastrNotification.error("Upload Credit Application Template File Failed");
            this.CreatingCreditApplication = false;
          }
        });
    }
    else {
      //else what hmmm?

    }

  }

  EditingCreditApplication: boolean = false;

  editApplicationTemplate(event) {
    this.EditingCreditApplication = true;

    this.creditApplicationTemplateUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };

    this.creditApplicationTemplateFile = event.target.files[0];
    const reader = new FileReader();

    if (this.creditApplicationTemplateFile) {

      reader.readAsDataURL(this.creditApplicationTemplateFile);
      reader.onload = (e: any) => {

        this.CreditApplicationFileNamePreview = this.creditApplicationTemplateFile.name;
        this.creditApplicationTemplateUpload.file = (reader.result + "").substring((reader.result + "").indexOf(",") + 1, (reader.result + "").length);
        this.creditApplicationTemplateUpload.userId = this.account.account.userId;;
        this.creditApplicationTemplateUpload.supplierId = this.account.account.supplierId;
        this.creditApplicationTemplateUpload.fileName = this.creditApplicationTemplateFile.name;

        this.supplierCompanyService.uploadCreditApplicationTemplateFile(this.creditApplicationTemplateUpload).pipe(first())
          .subscribe({
            next: (response: any) => {
              this.EditingCreditApplication = false;
              this.supplierCompanyDetails.creditAppTemplateFileName =  this.creditApplicationTemplateFile.name;

            },
            error: error => {
              this.toastrNotification.error("Edit Credit Application Template File Failed");
              this.EditingCreditApplication = false;
            }
          });
        this.clearEditCreditAppInput.nativeElement.value = "";

      };
    }


  }


}

