import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, MaxLengthValidator, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { Account } from '../models/account';
import { ReloadViewRfqs } from '../models/reloadViewRfqs';
import { RFQ } from '../models/rfq';
import { RFQCreate, CreateRFQs_Dimensions, UploadRfqFile, DefaultCreateRfqDetails } from '../models/rfqCreate';
import { BEERequirements, DeliveryRequirements, ISORequirements, LocationRequirements, PackagingRequirements, PartnershipRequirements, PaymentPreference, Service, TypeOfSupply } from '../models/service';
import { SupplierServices } from '../models/supplierServices';
import { RfqService } from '../services/rfq.service';
import { SortCriteria } from '../pipes/sort-asc-dsc.pipe';
import { ToastrNotificationService } from '../services/toastr-notification.service';
import { ViewRfqServiceService } from '../services/view-rfq-service.service';
import { first } from 'rxjs/operators';
import { Popover } from 'node_modules/bootstrap/dist/js/bootstrap.esm.min.js'
import { ViewRfq } from '../models/viewRfq';


@Component({
  selector: 'app-create-rfq',
  templateUrl: './create-rfq.component.html',
  styleUrls: ['./create-rfq.component.css']
})
export class CreateRfqComponent implements OnInit, OnChanges {

  @ViewChild('RfqSuccessBtn') RfqSuccessBtn: ElementRef;

  @ViewChild('RfqProjectNameValid') RfqProjectNameValid: ElementRef;

  public criteria: SortCriteria;
  form: FormGroup;
  nextmodal: boolean = false;
  displayModal: string = 'form';
  displaySubModal: string = "form1";
  uniquePartNo: boolean = true;
  rfqId: number;
  rfqValidCond: boolean;
  paymentValidCond: boolean;
  packagingValidCond: boolean;
  dimensionsValidCond: boolean;
  partNoUniqueCond: boolean;
  projectNameUnique: boolean = true;
  maxLeadTime: string = null;
  newRfqEvent: ReloadViewRfqs = { reloadCondition: null, reloadRfqId: null };

  //new Rfq object 
  newRfq: RFQCreate = {
    RfqName: null, TypeOfSupply: null, ServicesRequired: [], Dimensions: [], AllDrawingChanges: null, MaxLeadTime: null,
    RfqClosingDate: null, PaymentRequirement: null, OtherPaymentSpecification: null, ProductIdentification: null, ProductCertification: null, BeeRequirement: null, IsoRequirement: null,
    PartnershipRequirement: null, PackagingRequirements: null, OtherPackagingSpecification: null, LocationRequirements: null, DeliveryRequirements: null, AdditionalComments: null, BuyerId: null, CreatedBy: null
    , DeliveryCity: null, DeliveryPostalCode: null, DeliveryProvince: null, DeliveryStreetAddress: null
  };

  // variables needed for Check of individual or supply selected
  typeOfSupply: TypeOfSupply = null;

  // variables needed for difference on parts selected
  diffOnparts: string = null;
  diffOnpartsCond: boolean;

  // variables needed for Check if Drawings or Dimensions is selected
  drawOrDim: string = null;

  selectedservice: number[] = [];
  dimensionSelectedService: number[] = [];
  dimensionSelectedServiceDuplicate: number[] = [];

  //product id check
  pid: boolean = null;

  //ISO requirements check
  isoRequirements: boolean = null;

  // Array for adding dimensions blocks to UI
  dimensionsUI: CreateRFQs_Dimensions[] = [];
  dimensionsgroup: CreateRFQs_Dimensions = { PartNo: null, Type: null, Quantity: null, Dimensions: null, MaterialGrade: null, DimensionSupplierServiceId: null }

  deliverOrCollection: string = null;
  account: Account;

  submittingRfq: boolean = false;
  submittingRfqFiles: boolean = false;

  //REGEX for Dimensions   
  dimValidCheck: RegExp = /^\s*?\d{0,6}[.]?\d{1,6}[x]\d{0,6}[.]?\d{1,6}[x]\d{0,6}[.]?\d{1,6}\s*?$/i;
  dimValidCond: boolean;

  postalCodeValidator: RegExp = /^\s*?\d{1,10}\s*?$/i;

  // All Services
  allservices: SupplierServices[] = [];
  allservicesDefault: SupplierServices[] = [];
  dimensionServices: SupplierServices[] = [];
  RawMaterialServices: SupplierServices[] = [];
  dimensionServicesSelect: string[] = ["Profile Cutting", "Plasma Cutting", "Guillotining", "Laser Cutting"];
  supplierServaceAdd: SupplierServices = { supplierServiceId: null, serviceName: null };
  allServiceDisplay: SupplierServices[] = [];

  // All Payment options
  paymentoptions: PaymentPreference[] = [PaymentPreference.COD, PaymentPreference.CBD, PaymentPreference.Days30, PaymentPreference.Days60, PaymentPreference.Other, PaymentPreference.NotSpecified];

  // BEE Requirements
  BEE: BEERequirements[] = [BEERequirements.Level1, BEERequirements.Level2, BEERequirements.Level3, BEERequirements.Level4, BEERequirements.Level5, BEERequirements.All, BEERequirements.None];

  // ISO Requirements
  ISO: ISORequirements[] = [ISORequirements.R9001, ISORequirements.R9002, ISORequirements.R9003];

  Partners: PartnershipRequirements[] = [PartnershipRequirements.All, PartnershipRequirements.PreferredSuppliers];

  Location: LocationRequirements[] = [LocationRequirements.GP];

  packagingList: PackagingRequirements[] = [PackagingRequirements.Boxed, PackagingRequirements.Loose, PackagingRequirements.Pallet, PackagingRequirements.Strapped, PackagingRequirements.Other];

  deliveryList: DeliveryRequirements[] = [DeliveryRequirements.Collection, DeliveryRequirements.Delivery];

  differentDeliveryAddressCond: string = null;

  defaultRFqDetails: DefaultCreateRfqDetails;

  //needed for date calculations
  valDate = true;
  minDate: any;
  maxDate: any;
  dueDate: any;
  dueHours: any;
  expHours: any;
  date1: any;
  date2: any;
  expireDate: any;
  //needed for date calculations

  //check form submission 
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private rfqService: RfqService,
    private reloader: ViewRfqServiceService,
    public authService: AuthService,
    public toastrNotification: ToastrNotificationService,
    private router: Router,) {
    this.criteria = {
      property: 'supplierServices',
      descending: true
    };

    this.authService.account.subscribe(x => this.account = x);
  }

  ngOnChanges() {

  }

  ngOnInit(): void {

    // ENABLES BOOTSTRAP POPOVERS FOR TOOLTIPS

    // Array.from(document.querySelectorAll('[data-bs-toggle="popover"]'))
    //   .forEach(popoverNode => new Popover(popoverNode))

    this.form = this.formBuilder.group({

      projectName: ["", Validators.required],
      completeSupply: [null, Validators.required],

      complete: [false],
      rawMaterial: [false],

      completeF: [false],
      rawMaterialF: [false],

      supplierServiceSelect: [null, Validators.required],

      dchanges: ["", Validators.required],

      diffpartsYes: [false],
      diffpartsNo: [false],

      productidYes: [false],
      productidNo: [false],

      isoYes: [false],
      isoNo: [false],

      drawings: [false],
      dimensions: [false],

      deliveryDate: [null, Validators.required],
      RFQcloseDate: [null, Validators.required],

      delivery: [null, Validators.required],
      collection: [null, Validators.required],

      deliveryAddressDifYes: [null, Validators.required],
      deliveryAddressDifNo: [null, Validators.required],

      deliveryStreetAddress: ["", Validators.required],
      deliveryCity: ["", Validators.required],
      deliveryProvince: ["", Validators.required],
      deliveryPostalCode: ["", Validators.required],

      // values for the dimensions array
      partNo: ["", Validators.required],
      numberOfParts: [null, Validators.required],
      dimesions_d: ["", Validators.required],
      materialGrade: ["", Validators.required],
      dimensionSupplierService: [null, Validators.required],
      dimensionType: ["", Validators.required],

      paymentoptions: [null, Validators.required],
      otherPaymentRequirement: ["", Validators.required],

      P_Cert_Req: ["", Validators.required],


      BEE: [null, Validators.required],

      ISO: [null, Validators.required],

      Partners: [null, Validators.required],

      Location: [null, Validators.required],

      packaging: [null, Validators.required],
      otherPackagingSpecification: ["", Validators.required],

      Comments: ["", Validators.required],

    });


    this.f.drawings.disable();
    this.f.dimensions.disable();

    //gets current date
    this.getMinDate();

    //Enable and disable Closing date form
    this.disableCloseDateForm();

    this.getSupplierServices();

    this.getDefaultRfqDetails();

  }

  rfqSubmit() {

    this.newRfq = {
      RfqName: null, TypeOfSupply: null, ServicesRequired: [], Dimensions: [], AllDrawingChanges: null, MaxLeadTime: null,
      RfqClosingDate: null, PaymentRequirement: null, OtherPaymentSpecification: null, ProductIdentification: null, ProductCertification: null, BeeRequirement: null, IsoRequirement: null,
      PartnershipRequirement: null, PackagingRequirements: null, OtherPackagingSpecification: null, LocationRequirements: null, DeliveryRequirements: null, AdditionalComments: null, BuyerId: null, CreatedBy: null
      , DeliveryCity: null, DeliveryPostalCode: null, DeliveryProvince: null, DeliveryStreetAddress: null
    };

    this.newRfq.RfqName = this.f.projectName.value;
    this.newRfq.TypeOfSupply = this.typeOfSupply;

    if (this.typeOfSupply == "Complete") {
      if (this.drawOrDim == "Drawings") {
        for (let service of this.selectedservice) {
          this.newRfq.ServicesRequired.push(service);
        }
      } else if (this.drawOrDim == "Dimensions") {
        for (let service of this.dimensionSelectedService) {
          this.newRfq.ServicesRequired.push(service);
        }
      }

    } else if (this.typeOfSupply == "Raw Material") {
      this.newRfq.ServicesRequired.push(this.RawMaterialServices[0].supplierServiceId);
    }


    if (this.drawOrDim == "Dimensions") {
      for (let dimensions of this.dimensionsUI) {
        this.newRfq.Dimensions.push(dimensions);
      }
    }

    this.newRfq.MaxLeadTime = this.f.deliveryDate.value;
    this.newRfq.RfqClosingDate = this.f.RFQcloseDate.value;
    this.newRfq.ProductCertification = this.pid;
    this.newRfq.IsoRequirement = this.isoRequirements;

    if (this.f.dchanges.value.replace(/ /g, "") == '') {
      this.newRfq.AllDrawingChanges = null;
    } else if (this.drawOrDim == "Drawings" && this.f.dchanges.value.replace(/ /g, "") == '') {
      this.newRfq.AllDrawingChanges = this.f.dchanges.value;
    }

    if (this.f.paymentoptions.value == '') {
      this.newRfq.PaymentRequirement = null;
    } else {
      this.newRfq.PaymentRequirement = this.f.paymentoptions.value;
    }

    if (this.f.paymentoptions.value == "Other") {
      this.newRfq.OtherPaymentSpecification = this.f.otherPaymentRequirement.value;
    } else {
      this.newRfq.OtherPaymentSpecification = null;
    }

    if (this.f.P_Cert_Req.value.replace(/ /g, "") == '') {
      this.newRfq.ProductIdentification = null;
    } else {
      this.newRfq.ProductIdentification = this.f.P_Cert_Req.value;
    }

    if (this.f.BEE.value == '' || this.f.BEE.value == BEERequirements.None) {
      this.newRfq.BeeRequirement = null;
    } else {
      this.newRfq.BeeRequirement = this.f.BEE.value;
    }

    if (this.f.Partners.value == '') {
      this.newRfq.PartnershipRequirement = null;
    } else {
      this.newRfq.PartnershipRequirement = this.f.Partners.value;
    }


    if (this.f.Location.value == '') {
      this.newRfq.LocationRequirements = null;
    } else {
      this.newRfq.LocationRequirements = this.f.Location.value;
    }

    this.newRfq.DeliveryRequirements = this.deliverOrCollection;

    if (this.deliverOrCollection == "Delivery" && this.differentDeliveryAddressCond == "No") {

      this.newRfq.DeliveryStreetAddress = this.f.deliveryStreetAddress.value;
      this.newRfq.DeliveryCity = this.f.deliveryCity.value;
      this.newRfq.DeliveryPostalCode = this.f.deliveryPostalCode.value;
      this.newRfq.DeliveryProvince = this.f.deliveryProvince.value;
    } else {
      this.newRfq.DeliveryStreetAddress = null;
      this.newRfq.DeliveryCity = null;
      this.newRfq.DeliveryPostalCode = null;
      this.newRfq.DeliveryProvince = null;
    }

    if (this.f.packaging.value == '') {
      this.newRfq.PackagingRequirements = null;
    } else {
      this.newRfq.PackagingRequirements = this.f.packaging.value;
    }

    if (this.f.packaging.value == "Other") {
      this.newRfq.OtherPackagingSpecification = this.f.otherPackagingSpecification.value;
    } else {
      this.newRfq.OtherPackagingSpecification = null;
    }

    if (this.f.Comments.value.replace(/ /g, "") == '') {
      this.newRfq.AdditionalComments = null;
    } else {
      this.newRfq.AdditionalComments = this.f.Comments.value;
    }

    this.newRfq.BuyerId = this.account.account.buyerId;
    this.newRfq.CreatedBy = this.account.account.userName;

    
    this.createRFQ();
  }



  onSuccess(cond) {


    if (cond == "clear") {

      this.files = [];
      this.drawingFileArray = [];
      this.selectedservice = [];
      this.allServiceDisplay = [];
      this.allservices = [];
      this.dimensionsUI = [];

      this.dimensionSelectedService = [];
      this.dimensionSelectedServiceDuplicate = [];
      

      for (let service of this.allservicesDefault) {
        this.allservices.push(service);
      }
    }

    if (cond == "create") {
      if (this.typeOfSupply == "Complete" && this.drawOrDim == "Drawings") {
        for (let service of this.newRfq.ServicesRequired) {
          this.deleteService(service);
        }

      }
    
    if (this.drawOrDim == "Dimensions") {
      for (let dimension of this.newRfq.Dimensions) {
        this.Parts_group_delete(dimension.PartNo);
      }
     
    }
  }

    this.form.reset();

    this.f.projectName.setValue("");
    this.f.partNo.setValue("");
    this.f.dimesions_d.setValue("");
    this.f.materialGrade.setValue("");
    this.f.dimensionType.setValue("");
    this.f.otherPaymentRequirement.setValue("");
    this.f.otherPackagingSpecification.setValue("");
    this.f.P_Cert_Req.setValue("");
    this.f.Comments.setValue("");
    this.f.dchanges.setValue("");
    this.f.deliveryStreetAddress.setValue("");
    this.f.deliveryCity.setValue("");
    this.f.deliveryPostalCode.setValue("");
    this.f.deliveryProvince.setValue("");

    this.typeOfSupply = null;
    this.pid = null;
    this.isoRequirements = null;
    this.deliverOrCollection = null
    this.maxLeadTime = null;
    this.DeliverDay = null;
    this.ClosingDay = null;
    this.drawOrDim = null;


    this.disableCloseDateForm();

    this.f.drawings.disable();
    this.f.dimensions.disable();

    this.getDefaultRfqDetails();

    this.newRfq = {
      RfqName: null, TypeOfSupply: null, ServicesRequired: [], Dimensions: [], AllDrawingChanges: null, MaxLeadTime: null,
      RfqClosingDate: null, PaymentRequirement: null, OtherPaymentSpecification: null, ProductIdentification: null, ProductCertification: null, BeeRequirement: null, IsoRequirement: null,
      PartnershipRequirement: null, PackagingRequirements: null, OtherPackagingSpecification: null, LocationRequirements: null, DeliveryRequirements: null, AdditionalComments: null, BuyerId: null, CreatedBy: null
      , DeliveryCity: null, DeliveryPostalCode: null, DeliveryProvince: null, DeliveryStreetAddress: null
    };



    this.onNext();
  }

  getDefaultRfqDetails() {
    this.rfqService.getDefaultCreateRfqDetails(this.account.account.buyerId).pipe(first())
      .subscribe({
        next: (response: any) => {
          this.defaultRFqDetails = response;

          if (this.defaultRFqDetails.beeCertificate != null && this.defaultRFqDetails.beeRequirements != null) {

            if (this.defaultRFqDetails.beeCertificate) {
              if (this.defaultRFqDetails.beeRequirements == 1) {
                this.f.BEE.setValue(BEERequirements.Level1);
              } else if (this.defaultRFqDetails.beeRequirements == 2) {
                this.f.BEE.setValue(BEERequirements.Level2);
              } else if (this.defaultRFqDetails.beeRequirements == 3) {
                this.f.BEE.setValue(BEERequirements.Level3);
              } else if (this.defaultRFqDetails.beeRequirements == 4) {
                this.f.BEE.setValue(BEERequirements.Level4);
              } else if (this.defaultRFqDetails.beeRequirements == 5) {
                this.f.BEE.setValue(BEERequirements.Level5);
              }
            } else {
              this.f.BEE.setValue("Not Required");
            }
          }

          if (this.defaultRFqDetails.isoRequirements != null) {
            if (this.defaultRFqDetails.isoRequirements) {
              this.isoRequirementsCheck(true);
            } else {
              this.isoRequirementsCheck(false);
            }

          }


        },
        error: error => {
          this.toastrNotification.error("Get Default Rfq Details Failed");
          this.submittingRfq = false;
        }

      });
  }

  createRFQ() {
    this.submittingRfq = true;
    //async
    this.rfqService.addRFQ(this.newRfq).pipe(first())
      .subscribe({
        next: (response: any) => {
          this.submittingRfq = false;
          this.rfqId = Number(response);
          if (this.drawOrDim == "Drawings") {
            this.submittingRfqFiles = true;
            for (let file of this.drawingFileArray) {
              file.rfqId = Number(response);
            }

            this.rfqService.uploadDrawingFiles(this.drawingFileArray).pipe(first())
              .subscribe({
                next: (fileResponse: any) => {
                  this.submittingRfqFiles = false;
                  this.RfqSuccessBtn.nativeElement.click();
                  this.files = [];
                  this.drawingFileArray = [];
                  this.onSuccess("create");
                },
                error: error => {
                  this.toastrNotification.error("Create Rfq File Uploads Failed");
                  this.submittingRfqFiles = false;
                }
              });
          } else if (this.drawOrDim == "Dimensions") {
            this.RfqSuccessBtn.nativeElement.click();
            this.onSuccess("create");
          }
        },
        error: error => {
          this.toastrNotification.error("Create Rfq Failed");
          this.submittingRfq = false;
        }

      });
  }

  getLocalstorage() {
    var account = localStorage.getItem('Account');
  }

  goToRfq() {
    // this.newRfqEvent = { reloadCondition: null, reloadRfqId: null };
    // this.newRfqEvent.reloadCondition = true;
    // this.newRfqEvent.reloadRfqId = this.rfqId;
    // this.reloader.changeNavNewRfq(this.newRfqEvent);
    this.router.navigate(['buyer-home/view-rfq', 'Open', this.rfqId, 'true']);
  }


  rfqValid() {

    if (this.f.projectName.value == null || this.f.projectName.value.length > 50 || this.f.projectName.value.replace(/ /g, "") == "" || this.typeOfSupply == null || this.f.deliveryDate.value == null
      || this.f.RFQcloseDate.value == null || this.drawOrDim == null || this.deliverOrCollection == null || this.projectNameUnique == false
      || this.dimensionNotSubmittedCheck()
      || !this.paymentSpecificationsValid() || this.f.paymentoptions.value == null
      || (this.deliverOrCollection == "Delivery" && this.differentDeliveryAddressCond == null)
      || (this.deliverOrCollection == "Delivery" && this.differentDeliveryAddressCond == "No" && (
        this.f.deliveryStreetAddress.value.replace(/ /g, "") == ""
        || this.f.deliveryCity.value.replace(/ /g, "") == ""
        || this.f.deliveryPostalCode.value.replace(/ /g, "") == ""
        || this.f.deliveryProvince.value.replace(/ /g, "") == ""
        || this.f.deliveryStreetAddress.value.length > 30
        || this.f.deliveryCity.value.length > 30
        || this.f.deliveryProvince.value.length > 30
        || this.f.deliveryPostalCode.value.length > 10
        || !this.postalCodeValid(this.f.deliveryPostalCode.value)
      ))) {
      return false;
    } else if ((this.drawOrDim == "Dimensions" && this.dimensionsUI.length == 0 && this.typeOfSupply == "Complete")
      || (this.drawOrDim == "Drawings" && this.drawingFileArray.length == 0 && this.typeOfSupply == "Complete")
      || (this.drawOrDim == "Drawings" && this.selectedservice.length == 0 && this.typeOfSupply == "Complete")
      || (this.drawOrDim == "Dimensions" && this.dimensionsUI.length == 0 && this.typeOfSupply == "Raw Material")
      || (this.drawOrDim == "Drawings" && this.drawingFileArray.length == 0 && this.typeOfSupply == "Raw Material")
      || (this.drawOrDim == "Drawings" && this.f.dchanges.value.length > 500)) {
      return false;
    } else {
      this.rfqValidCond = true;
      return true;
    }


  }

  dimensionsRectangularCheck(dimensions) {

    if (this.dimValidCheck.test(dimensions)) {
      this.dimValidCond = true;
    } else {
      this.dimValidCond = false;
    }
  }

  dimensionNotSubmittedCheck() {
    if (this.f.partNo.value.replace(/ /g, "") != "" || this.f.numberOfParts.value != null || this.f.dimensionSupplierService.value != null
      || this.f.dimesions_d.value.replace(/ /g, "") != "" || this.f.materialGrade.value.replace(/ /g, "") != "") {
      return true;
    } else {
      return false;
    }
  }

  rfqValidCheck() {

    if (this.rfqValid() == false) {
      this.rfqValidCond = false
    } else {
      this.rfqValidCond = true
    }

  }

  stringValidation(value) {
    if (value.replace(/ /g, "") == "") {
      return true
    } else {
      return false;
    }
  }

  postalCodeValid(value) {
    return this.postalCodeValidator.test(value);

  }

  paymentSpecificationsValid() {

    if (this.f.paymentoptions.value == "Other" && (this.f.otherPaymentRequirement.value == null || this.f.otherPaymentRequirement.value.replace(/ /g, "") == "" || this.f.otherPaymentRequirement.value.length > 250)) {
      return false;
    } else {
      return true;
    }

  }

  paymentValidCheck() {
    if (this.paymentSpecificationsValid() == false) {
      this.paymentValidCond = false
    } else {
      this.paymentValidCond = true
    }
  }

  packagingSpecificationsValid() {
    if (this.f.packaging.value == "Other" && (this.f.otherPackagingSpecification.value == null || this.f.otherPackagingSpecification.value.replace(/ /g, "") == "" || this.f.otherPackagingSpecification.value.length > 250)) {
      return false;
    } else {
      return true;
    }
  }



  additionalCommentsValid() {
    if (this.f.Comments.value.length > 500) {
      return false;
    } else {
      return true;
    }
  }

  additionCommentsCond: boolean;

  additionalCommentsValidCheck() {
    if (this.additionalCommentsValid() == false) {
      this.additionCommentsCond = false;
    } else {
      this.additionCommentsCond = true;
    }
  }

  productIdentificationValid() {
    if (this.f.P_Cert_Req.value.length > 250) {
      return false;
    } else {
      return true;
    }
  }

  productIdentificationCond: boolean;

  productIdentificationValidCheck() {
    if (this.additionalCommentsValid() == false) {
      this.productIdentificationCond = false;
    } else {
      this.productIdentificationCond = true;
    }
  }


  packagingValidCheck() {
    if (this.packagingSpecificationsValid() == false) {
      this.packagingValidCond = false
    } else {
      this.packagingValidCond = true
    }
  }

  projectNameUniqueLoading: boolean = false;

  projectNameUniqueCheck(projectName) {

    this.projectNameUniqueLoading = true;

    this.Partners = [PartnershipRequirements.All, PartnershipRequirements.PreferredSuppliers];

    if (projectName.replace(/ /g, "") != "" && projectName != null) {
      this.rfqService.getProjectNameCheck(this.account.account.buyerId, projectName).pipe(first()).subscribe({
        next: (response: any) => {

          this.projectNameUnique = response;
          if (response) {
            this.rfqService.getPreferredSupplierCount(this.account.account.buyerId).pipe(first()).subscribe({
              next: (preferredSupplierCount: any) => {

                if (preferredSupplierCount == 0) {
                  this.Partners.splice(this.Partners.indexOf(PartnershipRequirements.PreferredSuppliers), 1);
                }

                this.RfqProjectNameValid.nativeElement.click();
                this.projectNameUniqueLoading = false;

              },
              error: error => {
                this.toastrNotification.info("Preferred Supplier check fail")
                this.projectNameUniqueLoading = false;
              }
            });
          }

        },
        error: error => {
          this.toastrNotification.info("Project Name Unique check fail")
          this.projectNameUniqueLoading = false;
        }
      });
    }
  }

  dimensionsValidCheck() {
    if (this.drawOrDim == "Dimensions" && (this.f.partNo.value.replace(/ /g, "") == "" || this.f.partNo.value == null || this.f.numberOfParts.value == null ||
      this.f.dimesions_d.value.replace(/ /g, "") == "" || this.f.dimesions_d.value == null || this.f.materialGrade.value.replace(/ /g, "") == "" || this.f.materialGrade.value == null)
    ) {
      this.dimensionsValidCond = false;
    } else {
      this.dimensionsValidCond = true;
    }
  }

  getSupplierServices() {

    this.rfqService.getSupplierServices()
      .subscribe(supplierServices => {
        for (let drawService of supplierServices) {
          if (drawService.serviceName != "Raw Materials") {
            this.allservices.push(drawService);
            this.allservicesDefault.push(drawService);
          } else {
            this.RawMaterialServices.push(drawService);

          }
        }
        for (let service of supplierServices) {
          for (let serviceName of this.dimensionServicesSelect) {
            this.supplierServaceAdd = { supplierServiceId: null, serviceName: null };
            if (serviceName == (service.serviceName)) {
              this.supplierServaceAdd.serviceName = service.serviceName;
              this.supplierServaceAdd.supplierServiceId = service.supplierServiceId;
              this.dimensionServices.push(this.supplierServaceAdd);
            }
          }
        }
        this.supplierServaceAdd = { supplierServiceId: null, serviceName: null };

      });
  }

  // Checks Raw Material or supply buttons
  typeOfSupplycheck(answer) {

    this.f.drawings.enable();
    this.f.dimensions.enable();

    if (answer == 'Raw Material') {
      this.f.rawMaterial.setValue(true);
      this.f.rawMaterialF.setValue(true);
      if (this.typeOfSupply == null) {
        this.typeOfSupply = TypeOfSupply.RawMaterial;
      } else if (this.typeOfSupply == TypeOfSupply.Complete) {
        this.f.complete.setValue(false);
        this.f.completeF.setValue(false);
        this.typeOfSupply = TypeOfSupply.RawMaterial;
      }
    } else if (answer == 'Complete') {
      this.f.complete.setValue(true);
      this.f.completeF.setValue(true);
      if (this.typeOfSupply == null) {
        this.typeOfSupply = TypeOfSupply.Complete;
      } else if (this.typeOfSupply == TypeOfSupply.RawMaterial) {
        this.f.rawMaterial.setValue(false);
        this.f.rawMaterialF.setValue(false);
        this.typeOfSupply = TypeOfSupply.Complete;
      }
    }
  }

  disabledTypeOfSupply() {
    if (this.typeOfSupply == 'Raw Material') {
      this.f.rawMaterialF.setValue(true);
      this.f.completeF.setValue(false);
    } else if (this.typeOfSupply == 'Complete') {
      this.f.rawMaterialF.setValue(false);
      this.f.completeF.setValue(true);
    }
  }

  typeOfSupplyChangeCheck() {
    if (this.files.length == 0 && this.selectedservice.length == 0 && this.dimensionsUI.length == 0) {
      return true;
    } else {
      return false;
    }
  }


  deliveryOrCollection(answer) {
    if (answer == 'Delivery') {
      this.f.delivery.setValue(true);
      if (this.deliverOrCollection == null) {
        this.deliverOrCollection = "Delivery";
      } else if (this.deliverOrCollection == "Collection") {
        this.f.collection.setValue(false);
        this.deliverOrCollection = "Delivery";
      }
    } else if (answer == 'Collection') {
      this.f.collection.setValue(true);
      if (this.deliverOrCollection == null) {
        this.deliverOrCollection = "Collection";
      } else if (this.deliverOrCollection == "Delivery") {
        this.f.delivery.setValue(false);
        this.deliverOrCollection = "Collection";
      }
    }
  }

  deliveryAddressDifferent(answer) {
    if (answer == 'Yes') {
      this.f.deliveryAddressDifYes.setValue(true);
      if (this.differentDeliveryAddressCond == null) {
        this.differentDeliveryAddressCond = "Yes";
      } else if (this.differentDeliveryAddressCond == "No") {
        this.f.deliveryAddressDifNo.setValue(false);
        this.differentDeliveryAddressCond = "Yes";
      }
    } else if (answer == 'No') {
      this.f.deliveryAddressDifNo.setValue(true);
      if (this.differentDeliveryAddressCond == null) {
        this.differentDeliveryAddressCond = "No";
      } else if (this.differentDeliveryAddressCond == "Yes") {
        this.f.deliveryAddressDifYes.setValue(false);
        this.differentDeliveryAddressCond = "No";
      }
    }
  }

  // Check difference on parts buttons
  containdiffpartcheck(answer) {
    this.diffOnparts = answer;
    if (!this.f.diffpartsYes.value && !this.f.diffpartsNo.value) {
      this.diffOnparts = null;
      this.diffOnpartsCond = false;
    } else if (this.diffOnparts == 'No') {
      this.f.diffpartsYes.setValue(false);
      this.diffOnpartsCond = true;
    } else if (this.diffOnparts == 'Yes') {
      this.f.diffpartsNo.setValue(false);
      this.diffOnpartsCond = true;
    } else if (this.diffOnparts == 'null') {
      this.f.diffpartsNo.setValue(false);
      this.f.diffpartsYes.setValue(false);
      this.diffOnpartsCond = false;
    }
  }

  productidcheck(answer) {
    if (answer == false) {
      this.f.productidNo.setValue(true);
      if (this.pid == null) {
        this.pid = answer;
      } else if (this.pid == true) {
        this.f.productidYes.setValue(false);
        this.pid = answer;

      }
    } else if (answer == true) {
      this.f.productidYes.setValue(true);
      if (this.pid == null) {
        this.pid = answer;
      } else if (this.pid == false) {
        this.f.productidNo.setValue(false);
        this.pid = answer;

      }
    }
  }

  isoRequirementsCheck(answer) {
    if (answer == true) {
      this.f.isoYes.setValue(true);
      if (this.isoRequirements == null) {
        this.isoRequirements = true;
      } else if (this.isoRequirements == false) {
        this.f.isoNo.setValue(false);
        this.isoRequirements = true;
      }
    } else if (answer == false) {
      this.f.isoNo.setValue(true);
      if (this.isoRequirements == null) {
        this.isoRequirements = false;
      } else if (this.isoRequirements == true) {
        this.f.isoYes.setValue(false);
        this.isoRequirements = false;
      }
    }
  }

  // Check difference on parts buttons
  draw_or_dim_check(answer) {

    if (answer == 'Drawings') {

      this.dimensionsUI = [];
      this.f.partNo.setValue("");
      this.f.partNo.markAsUntouched();
      this.f.dimensionType.setValue("");
      this.f.dimensionType.markAsUntouched();
      this.f.numberOfParts.setValue(null);
      this.f.numberOfParts.markAsUntouched();
      this.f.dimesions_d.setValue("");
      this.f.dimesions_d.markAsUntouched();
      this.f.materialGrade.setValue("");
      this.f.materialGrade.markAsUntouched();
      this.f.dimensionSupplierService.setValue(null);
      this.f.dimensionSupplierService.markAsUntouched();

      this.f.drawings.setValue(true);
      if (this.drawOrDim == null) {
        this.drawOrDim = answer;
      } else if (this.drawOrDim == 'Dimensions') {
        this.f.dimensions.setValue(false);
        this.drawOrDim = answer;
      }
    } else if (answer == 'Dimensions') {
      this.drawingFileArray = [];
      this.files = [];
      this.f.dimensions.setValue(true);
      if (this.drawOrDim == null) {
        this.drawOrDim = answer;
      } else if (this.drawOrDim == 'Drawings') {
        this.f.drawings.setValue(false);
        this.drawOrDim = answer;
      }
    }
  }

  clearDrawings() {
    var selectedServiceDelete = [];
    this.drawingFileArray = [];
    this.files = [];
    for (let s of this.selectedservice) {
      selectedServiceDelete.push(s);
    }

    for (let id of selectedServiceDelete) {
      this.deleteService(id)
    }
    this.f.supplierServiceSelect.markAsUntouched();
    this.f.dchanges.setValue("");
    this.f.dchanges.markAsUntouched();
  }

  clearDimensions() {


    this.dimensionsUI = [];
    this.dimensionSelectedService = [];
    this.dimensionSelectedServiceDuplicate = [];

    this.f.partNo.setValue("");
    this.f.partNo.markAsUntouched();
    this.f.dimensionType.setValue("");
    this.f.dimensionType.markAsUntouched();
    this.f.numberOfParts.setValue(null);
    this.f.numberOfParts.markAsUntouched();
    this.f.dimesions_d.setValue("");
    this.f.dimesions_d.markAsUntouched();
    this.f.materialGrade.setValue("");
    this.f.materialGrade.markAsUntouched();
    this.f.dimensionSupplierService.setValue(null);
    this.f.dimensionSupplierService.markAsUntouched();


  }

  // Push the selected service to a new array 
  selectService() {
    this.selectedservice.push(Number(this.f.supplierServiceSelect.value));
    this.supplierServaceAdd = { supplierServiceId: null, serviceName: null };
    for (const { index, value } of this.allservices.map((value, index) => ({ index, value }))) {
      if (value.supplierServiceId == this.f.supplierServiceSelect.value) {
        this.supplierServaceAdd.supplierServiceId = this.allservices[index].supplierServiceId;
        this.supplierServaceAdd.serviceName = this.allservices[index].serviceName;
        this.allServiceDisplay.push(this.supplierServaceAdd);
        this.allservices.splice(index, 1);
      }
    }

    this.f.supplierServiceSelect.setValue(null);
  }



  // deletes the selected service and pushes it back to the all services array
  deleteService(serviceId) {
    this.selectedservice.splice(this.selectedservice.indexOf(serviceId), 1);
    this.supplierServaceAdd = { supplierServiceId: null, serviceName: null };
    for (const { index, value } of this.allServiceDisplay.map((value, index) => ({ index, value }))) {
      if (value.supplierServiceId == serviceId) {
        this.supplierServaceAdd.supplierServiceId = this.allServiceDisplay[index].supplierServiceId;
        this.supplierServaceAdd.serviceName = this.allServiceDisplay[index].serviceName;
        this.allservices.push(this.supplierServaceAdd);
        this.allServiceDisplay.splice(index, 1);
      }
    }
    //this.allservices.push(service);
    this.f.supplierServiceSelect.setValue(null);

     // sort services in Asc order using String.prototype.localCompare()
    this.allservices.sort((a, b) =>
    a.serviceName.localeCompare(b.serviceName)
 );
  }

  // Push to the dimensions array
  dimensions_block_add() {
    if (this.f.partNo.value.replace(/ /g, "") != "" && this.f.partNo.value.length <= 50 && this.f.partNo.value != null
      && this.f.numberOfParts.value != null && this.f.numberOfParts.value >= 1 && this.f.numberOfParts.value < 1000000 && this.f.dimesions_d.value.replace(/ /g, "") != ""
      && this.f.dimesions_d.value != null && this.f.dimesions_d.value.length <= 25
      && this.f.dimensionType.value.replace(/ /g, "") != ""
      && this.f.dimensionType.value != null && this.f.dimensionType.value.length <= 25
      && this.f.materialGrade.value.replace(/ /g, "") != "" && this.f.materialGrade.value != null && this.f.materialGrade.value.length <= 25
      && ((this.f.dimensionSupplierService.value != null && this.typeOfSupply == "Complete") || (this.typeOfSupply == "Raw Material"))
    ) {
      this.uniquePartNoCheck(this.f.partNo.value);
      if (this.uniquePartNo == true) {

        this.dimensionsgroup = { PartNo: null, Type: null, Quantity: null, Dimensions: null, MaterialGrade: null, DimensionSupplierServiceId: null }
        this.dimensionsgroup.PartNo = this.f.partNo.value;
        this.dimensionsgroup.Type = this.f.dimensionType.value;
        this.dimensionsgroup.Quantity = this.f.numberOfParts.value;
        this.dimensionsgroup.Dimensions = this.f.dimesions_d.value;
        this.dimensionsgroup.MaterialGrade = this.f.materialGrade.value;

        if (this.typeOfSupply == "Complete") {
          this.dimensionsgroup.DimensionSupplierServiceId = Number(this.f.dimensionSupplierService.value);
          if (this.dimensionSelectedService.indexOf(Number(this.f.dimensionSupplierService.value)) == -1) {
            this.dimensionSelectedService.push(Number(this.f.dimensionSupplierService.value));
          }
          this.dimensionSelectedServiceDuplicate.push(Number(this.f.dimensionSupplierService.value));
        } else if (this.typeOfSupply == "Raw Material") {
          this.dimensionsgroup.DimensionSupplierServiceId = this.RawMaterialServices[0].supplierServiceId;
        }
        this.dimensionsUI.push(this.dimensionsgroup);
        this.f.partNo.setValue("");
        this.f.partNo.markAsUntouched();
        this.f.dimensionType.setValue("");
        this.f.dimensionType.markAsUntouched();
        this.f.numberOfParts.setValue(null);
        this.f.numberOfParts.markAsUntouched();
        this.f.dimesions_d.setValue("");
        this.f.dimesions_d.markAsUntouched();
        this.f.materialGrade.setValue("");
        this.f.materialGrade.markAsUntouched();
        this.f.dimensionSupplierService.setValue(null);
        this.f.dimensionSupplierService.markAsUntouched();
        this.dimensionsValidCond = true;
      }
      else {
        this.uniquePartNo = true;
      }

    }
    else {
      this.dimensionsValidCond = false;
    }
  }

  clearDimensionInputs() {

    this.f.partNo.setValue("");
    this.f.partNo.markAsUntouched();
    this.f.dimensionType.setValue("");
    this.f.dimensionType.markAsUntouched();
    this.f.numberOfParts.setValue(null);
    this.f.numberOfParts.markAsUntouched();
    this.f.dimesions_d.setValue("");
    this.f.dimesions_d.markAsUntouched();
    this.f.materialGrade.setValue("");
    this.f.materialGrade.markAsUntouched();
    this.f.dimensionSupplierService.setValue(null);
    this.f.dimensionSupplierService.markAsUntouched();
    this.dimensionsValidCond = true;
  }

  uniquePartNoCheck(partNo) {

    for (let dimensions of this.dimensionsUI) {
      if (dimensions.PartNo.replace(/ /g, "") == partNo.replace(/ /g, "")) {
        this.uniquePartNo = false;
      }
    }
  }

  uniquePartNoCheckRealTime(partNo) {
    for (let dimensions of this.dimensionsUI) {
      if (dimensions.PartNo.replace(/ /g, "") == partNo.replace(/ /g, "")) {
        return true;
      }
    }
  }

  quantityValidation(number) {
    if (number < 1 && number != null) {
      return true;
    }
  }

  Parts_group_delete(partNo) {

    var serviceCount = 0;
    for (const { index, value } of this.dimensionsUI.map((value, index) => ({ index, value }))) {
      if (value.PartNo == partNo) {
        this.dimensionsUI.splice(index, 1);
        for (let s of this.dimensionSelectedServiceDuplicate) {
          if (s == value.DimensionSupplierServiceId) {
            serviceCount++;
          }
        }

        for (const { serviceIndex, serviceValue } of this.dimensionSelectedService.map((serviceValue, serviceIndex) => ({ serviceIndex, serviceValue }))) {
          if (serviceValue == value.DimensionSupplierServiceId) {
            this.dimensionSelectedServiceDuplicate.splice(serviceIndex, 1);
            if (serviceCount == 1) {
              this.dimensionSelectedService.splice(serviceIndex, 1);
            }

          }
        }
      }
    }
  }

  //edit parts group

  editpartgroup(partNo) {
    for (let input of this.dimensionsUI) {
      if (input.PartNo == partNo) {
        this.f.partNo.setValue(input.PartNo);
        this.f.dimensionType.setValue(input.Type);
        this.f.numberOfParts.setValue(input.Quantity);
        this.f.dimesions_d.setValue(input.Dimensions);
        this.f.materialGrade.setValue(input.MaterialGrade);
        this.f.dimensionSupplierService.setValue(input.DimensionSupplierServiceId);
        this.Parts_group_delete(partNo);
      }
    }
  }


  //DATE CALCULATIONS
  getMinDate() {
    var date = new Date();
    date.setDate(date.getDate() + 1);
    var toDate: any = date.getDate();
    if (toDate < 10) {
      toDate = "0" + toDate;
    }
    var month: any = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    var year = date.getFullYear();
    this.minDate = year + "-" + month + "-" + toDate;
  }

  getMaxDate(deliveryDate) {


    var date = new Date(this.f.deliveryDate.value);
    var toDate: any = date.getDate();
    if (toDate < 10) {
      toDate = "0" + toDate;
    }
    var month: any = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    var year = date.getFullYear();
    this.maxDate = year + "-" + month + "-" + toDate;

  }

  getDeliveryDays(deliveryDate) {

    this.date1 = new Date(this.minDate);
    this.date2 = new Date(this.f.deliveryDate.value);

    let diff = this.date2.getTime() - this.date1.getTime();
    let msInDay = 1000 * 3600 * 24;

    this.dueDate = diff / msInDay;

  }

  getDeliveryHours(deliveryDate) {

    this.date1 = new Date();
    this.date2 = new Date(this.f.deliveryDate.value);

    let diff = this.date2.getTime() - this.date1.getTime();

    this.dueHours = Math.floor(((diff % 86400000) / 3600000) - 2);

    //this.dueMinutes = Math.floor(((diff % 86400000)/3600000) /60000);
  }

  getExpireDays(RFQcloseDate) {

    this.date1 = new Date(this.minDate);
    this.date2 = new Date(this.f.RFQcloseDate.value);

    let diff = this.date2.getTime() - this.date1.getTime();
    let msInDay = 1000 * 3600 * 24;

    this.expireDate = diff / msInDay;
  }

  getExpireHours(RFQcloseDate) {


    this.date1 = new Date();
    this.date2 = new Date(this.f.RFQcloseDate.value);

    let diff = this.date2.getTime() - this.date1.getTime();

    this.expHours = Math.floor(((diff % 86400000) / 3600000) - 2);

  }

  //Enable and disable Closing date form
  disableCloseDateForm() {
    if (this.f.deliveryDate.value == "" || this.f.deliveryDate.value == null) {
      this.f.RFQcloseDate.disable();
    }
    else (this.f.RFQcloseDate.enable());
  }


  onBack() {
    // if (this.displayModal === 'summary') this.displayModal = 'form';
    // else if (this.displayModal === 'form') {
    //   const rfqModal = document.getElementById("createfqmodal");
    //   rfqModal.setAttribute('style', "display:block;");
    //   this.displaySubModal = 'form1';
    // }
  }


  onNext() {
    this.nextmodal = true;
    // if (this.displayModal === 'form') {
    //   if (this.displaySubModal === 'form1') this.displaySubModal = 'form2';
    //   else this.displayModal = 'summary';
    // } else if (this.displayModal === 'summary') this.displayModal = 'success';
  }

  get f() { return this.form.controls; }

  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  public mtimeDifference;
  public msecondsToDday;
  public mminutesToDday;
  public mhoursToDday;
  public mdaysToDday;

  getMaxLeadTime(date) {
    this.getDateDifference(new Date(this.f.deliveryDate.value), new Date(this.f.RFQcloseDate.value));

    if (this.mdaysToDday < 0) {
      this.maxLeadTime = 'Closed';
    } else {
      this.maxLeadTime = this.mdaysToDday;
    }
  }

  getDateDifference(deliveryDate, closingDate) {
    var maxLeadDif = deliveryDate.getTime() - closingDate.getTime();
    this.allocateMaxLeadTimeUnits(maxLeadDif);
  }

  private allocateMaxLeadTimeUnits(timeDifference) {
    this.msecondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.mminutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.mhoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.mdaysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }


  //FILE UPLOAD 

  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;

  files: any[] = [];
  drawingFile: UploadRfqFile = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
  drawingFileArray: UploadRfqFile[] = [];
  requiredFileType: string = ".pdf,.dwg,.idw,.dxf,.iam,.png,.xls,.jpg,.jpeg,.ipt,.xlsx";
  allowedFileCheck: string[] = ["pdf", "dwg", "idw", "dxf", "iam", "png", "xls", "jpg", "jpeg", "ipt", "xlsx"];
  uniqueFile: boolean = true;

  //file upload handling 
  /**
    * on file drop handler
    */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }
  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }
  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number, fileDeleted: File) {
    this.files.splice(index, 1);

    for (const { fileIndex, value } of this.drawingFileArray.map((value, fileIndex) => ({ fileIndex, value }))) {
      if (value.fileName == fileDeleted.name) {
        this.drawingFileArray.splice(fileIndex, 1)
      }
    }
  }


  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {

    for (let item of files) {
      ;
      this.fileDuplicateCheck(item)
      if (this.uniqueFile) {
        if (this.allowedFileCheck.indexOf((item.name + "").substring((item.name + "").indexOf(".") + 1, (item.name + "").length)) != -1) {
          this.files.push(item);
          this.populateDrawingFilesArray(item)
        } else {
          this.toastrNotification.error("File type not allowed");
        }
      } else {
        this.uniqueFile = true;
        this.toastrNotification.error("File of the same name has already been uploaded");
      }
    }
    this.fileDropEl.nativeElement.value = "";

  }

  fileDuplicateCheck(file) {
    for (let drawFile of this.drawingFileArray) {
      if (drawFile.fileName == file.name) {
        this.uniqueFile = false;
      }
    }
  }

  populateDrawingFilesArray(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.drawingFile = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
      this.drawingFile.file = (reader.result + "").substring((reader.result + "").indexOf(",") + 1, (reader.result + "").length);
      this.drawingFile.buyerId = this.account.account.buyerId;
      this.drawingFile.userId = this.account.account.userId;
      this.drawingFile.rfqId = null;
      this.drawingFile.supplierId = null;
      this.drawingFile.quoteId = null;
      this.drawingFile.fileName = file.name;
      this.drawingFileArray.push(this.drawingFile);
    };
  }

  // ulpoadFiles() {
  //   this.rfqService.uploadDrawingFiles(this.drawingFileArray).subscribe();
  // }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  //day of the week calculation
  days: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  DeliverDay: string;
  ClosingDay: string;

  getDeliveryDay() {
    this.DeliverDay = this.days[new Date(this.f.deliveryDate.value).getDay()];
  }
  getClosingDay() {
    this.ClosingDay = this.days[new Date(this.f.RFQcloseDate.value).getDay()];
  }
}


