<!-- <div  class="text-start d-sm-flex justify-content-sm-start bluetext shift"
style="margin-bottom: 0;padding-left: 0;"> -->

<div class="d-flex justify-content-between align-items-center bluetextthin">

<div  *ngIf='!editMode' ><span >{{data}}</span></div>

  <textarea  class=" text-start form-control" role="textbox" contenteditable=""  *ngIf='editMode' (focusout)="onFocusOut()" (keydown.up)= "onFocusOut()"  [(ngModel)]='data' 
  appAutofocus type='text' (focusout)="editMode=false" style="border-radius: 16px;background: #7276821a;width: 90%;  min-height: 70px; height: 70px; max-height: 70px;" placeholder="Write..."></textarea>

<a class="bluetextthin clickable" *ngIf='!editMode' (click)='editMode=true' ><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" fill="none">
  <path d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z" fill="currentColor"></path>
  <path d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z" fill="currentColor"></path>
</svg></a>

<a class="bluetextthin clickable"  *ngIf='editMode'  (focusout)="onFocusOut()" (click)='editMode=false' ><i class="fas fa-check"></i></a>

<!-- <a class= "clickable" *ngIf='editMode'  (focusout)="onFocusOut()" (click)='editMode=false' >Save</a> -->


</div>



<!-- </div> -->




 




