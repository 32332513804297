import { ThrowStmt } from '@angular/compiler';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, AfterViewChecked, ElementRef, OnDestroy, OnChanges, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subscription, interval, fromEventPattern, pipe } from 'rxjs';
import { switchMap, finalize, first } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Account } from '../models/account';
import { CompleteRfq, FullRfqReview } from '../models/completeRfq';
import { RFQHeaddisplay } from '../models/header';
import { Quote } from '../models/quote';
import { ReloadViewRfqs } from '../models/reloadViewRfqs';
import { RFQ } from '../models/rfq';
import { UploadRfqFile } from '../models/rfqCreate';
import { ChatDetails, View_Chat } from '../models/viewChat';
import { ChatService } from 'src/app/services/chat.service';
import { ViewRfq, ViewRFQs_Quotes } from '../models/viewRfq';
import { QuoteService } from '../services/quote.service';
import { RfqService } from '../services/rfq.service';
import { SortCriteria } from '../pipes/sort-asc-dsc.pipe';
import { ViewRfqServiceService } from '../services/view-rfq-service.service';
import { View_QuotesRFQs } from '../models/view_quote';
import { ToastrNotificationService } from '../services/toastr-notification.service';
import { RfqHeadings } from '../models/rfqHeadings';
import { FileDownloadedService } from '../services/file-downloaded.service';
import { MakePaymentRequest } from '../models/rfqPaymentRequest';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-in-progress-rfq',
  templateUrl: './in-progress-rfq.component.html',
  styleUrls: ['./in-progress-rfq.component.css']
})

export class InProgressRfqComponent implements OnInit, AfterViewChecked, OnDestroy, OnChanges {

  public criteria: SortCriteria;

  @Input() reloadIPRfqsFromTab: boolean;

  @Input() inProgressRfqId: number;

  @Input() state: string;

  @Input() inProgressRfqNav: string;

  //NEEDED FOR CREATING Q&A QUESTIONS AND REPLIES
  isBuyer: boolean = true;
  isSupplier: boolean = false;
  rfqStatusPC: string = "In Progress";

  //NEEDED FOR GET QUESTIONS AND ANSWERS
  getQA: boolean = false;
  // EMITS RFQ STATUS
  @Output() rfqStatus = new EventEmitter<boolean>();

  @Output() quoteEvent = new EventEmitter<number>();
  @Output() openChatCond = new EventEmitter<boolean>();
  @Input() openChat: boolean;
  @Input() quote: Quote;
  @Output() changeClosedTab = new EventEmitter<boolean>();

  @Output() changeTab = new EventEmitter<boolean>();

  resetRatingForm: number = null;

  //GETS ROLE VALUES FROM VIEW RFQ COMPONENT
  @Input() Role: string;

  tabToInProgress(value: boolean) {
    this.changeTab.emit(value);
  }

  tabToInClosed(value: boolean) {
    this.changeClosedTab.emit(value);
  }

  //NEEDED TO CHECK UNREAD MESSAGES ARRAY
  UnreadMsgsCount: number;


  //NEEDED FOR SELECTED RFQ ON SIDEBAR 
  SideSelectedRFQ: ViewRfq;
  selectedRfqId: number;

  number: number = 1;
  //NEEDED FOR CHAT HISTORY
  chatHistory: View_Chat;
  details: ChatDetails;

  //USED IN TEMPLATE TO DISPLAY  MESSAGES RED BLIP 
  unreadMessages: boolean;

  //Needed to open the next modal
  nextmodal: boolean = false;

  loading = false;
  dataLoading = false;
  private subscriptionIP: Subscription;
  inProgressRfqs: RfqHeadings[] = [];
  inProgressRfqsUpdate: RfqHeadings[] = [];
  rfq: ViewRfq;
  form: FormGroup;
  payForm: FormGroup;
  ratingForm: FormGroup;
  inProgressQuoteform: FormGroup;
  otherQuoteform: FormGroup;
  sentQID: number;
  selectedRFQ?: ViewRfq;
  openQuotes: ViewRFQs_Quotes[];
  acceptedQuotes: ViewRFQs_Quotes[];
  RFQHeadings: string[] = [];
  selectedQuote?: Quote;
  sortbyHeadings: RFQHeaddisplay[] = [];
  sortbyHeadingsUpdate: RFQHeaddisplay[] = [];
  singleSortHeadings: RFQHeaddisplay = { rfqHeadName: null, rfq: null, id: null };
  headingId: number;
  check: boolean = false;
  showchat: boolean = false;
  //for rfq table search 
  title = 'custom-search-filter-example';
  searchedKeyword: string;
  searchrfq: string;
  onTimeDeliveryCond: boolean = null;
  correctMaterialGradeCond: boolean = null;
  correctQuantityOfPartsCond: boolean = null;
  supplierCommunicationCond: boolean = null;
  receivedTestCertificationCond: boolean = null;
  supplierRating: number = null;
  checklistValidCond: boolean;
  rfqUsed: boolean = false;
  searchdimensions: string;
  searchdrawings: string;
  searchpendingquotes: string;
  searchprogressquotes: string;
  modalheader: string = 'green';
  rfqIndex: number;
  reloadingSelectedRfq: boolean;



  @ViewChild('markAsCompleteBtn') markAsCompleteBtn: ElementRef;

  @ViewChild('markAsCompleteFinal') markAsCompleteFinal: ElementRef;

  @ViewChild('markAsCompleteClose') markAsCompleteClose: ElementRef;


  supplierSurvey: CompleteRfq = {
    rfqId: null, quoteId: null, onTimeDelivery: null, onTimeDeliveryReason: null,
    correctMaterialGrade: null, correctMaterialGradeReason: null, correctQuantity: null, correctQuantityReason: null,
    goodCommunication: null, goodCommunicationReason: null, correctTestCertification: null, correctTestCertificationReason: null,
    actualDeliveryDate: null, supplierRating: null, deliveryRequirements: null
  };

  updatedInProgress: ReloadViewRfqs = { reloadCondition: null, reloadRfqId: null };

  //Date Calcs
  public dateNow = new Date();
  public dDay?: Date;
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  reloadInProgressRfqCond: boolean;

  sendRfqStatus(value: boolean) {
    this.rfqStatus.emit(value);
  }


  private getTimeDifference() {
    this.timeDifference = this.dDay.getTime() - new Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
  }

  public getClosingTime(date) {
    this.dDay = new Date(date);
    this.getTimeDifference();
    if (this.daysToDday < 0) {
      return 'Closed';
    } else {
      return this.daysToDday + ' Days ' + this.hoursToDday + ' Hrs ' + this.minutesToDday + ' Mins';
    }

  }

  private allocateTimeUnits(timeDifference) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }

  account: Account;

  constructor(private quoteService: QuoteService, private formBuilder: FormBuilder, private rfqService: RfqService,
    private reloader: ViewRfqServiceService, public authService: AuthService, private chatService: ChatService,
    public cdr: ChangeDetectorRef,
    private toastrNotification: ToastrNotificationService, private fileDownloadedService: FileDownloadedService,
    private router: Router, private route: ActivatedRoute) {
    this.criteria = {
      property: 'supplierServices',
      descending: true
    };

    this.authService.account.subscribe(x => this.account = x);
  }

  ngAfterViewChecked() {
  }

  ngOnChanges() {
    if (this.state == "InProgress" && ((this.inProgressRfqs.length == 0 || this.inProgressRfqId == 0) || this.inProgressRfqNav == 'true')) {
      this.getInProgressRFQS(this.inProgressRfqId);
    } else if (this.state == "InProgress" && this.inProgressRfqs.length > 0 && this.inProgressRfqNav == 'false') {
      this.onSelectRFQ(this.inProgressRfqs.filter(x => x.rfqId == this.inProgressRfqId)[0].rfqId);
    }
  }

  ngOnInit() {





    this.reloadingSelectedRfq = false;

    //SETS THE INITIAL VALUE OF SUPPLIER RATING TO 0
    this.supplierRating = 0;

    this.modalheader = 'green';

    this.payForm = this.formBuilder.group({
      paymentReference: ['', Validators.required],
    });

    this.form = this.formBuilder.group({

      onTimeDeliveryYes: [false, Validators.required],
      onTimeDeliveryNo: [false, Validators.required],
      onTimeDeliveryReason: ["", Validators.required],

      correctMaterialGradeYes: [false, Validators.required],
      correctMaterialGradeNo: [false, Validators.required],
      correctMaterialGradeReason: ["", Validators.required],


      correctQuantityYes: [false, Validators.required],
      correctQuantityNo: [false, Validators.required],
      correctQuantityReason: ["", Validators.required],


      receivedTestCertYes: [false, Validators.required],
      receivedTestCertNo: [false, Validators.required],
      receivedTestCertReason: ["", Validators.required],


      supplierCommunicateYes: [null, Validators.required],
      supplierCommunicateNo: [null, Validators.required],
      supplierCommunicateReason: ["", Validators.required],

      actualDeliveryDate: [null, Validators.required],

    });

    this.ratingForm = this.formBuilder.group({

      supplierRating1: [false,],
      supplierRating2: [false,],
      supplierRating3: [false,],
      supplierRating4: [false,],
      supplierRating5: [false,],

    });

    this.inProgressQuoteform = this.formBuilder.group({

      quoteFile: [false],
      salesOrder: [false],
      invoice: [false]

    });

    this.otherQuoteform = this.formBuilder.group({

      otherQuoteFile: [false],
      otherSalesOrder: [false],
      otherinvoice: [false]

    });

    // this.headingId = 1;
    // this.singleSortHeadings.rfqHeadName = "Multiple Services";
    // this.singleSortHeadings.rfq = [];
    // this.sortbyHeadings.push(this.singleSortHeadings);
    // this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
    // this.headingId++;

    // this.reloader.currentReloadInProgressRfqsStay.subscribe(reloadCond => {
    //   if (reloadCond.reloadCondition) {

    //     this.updateInProgressRfqAfterQuoteAccept(reloadCond.reloadRfqId);
    //   }
    // });

    this.subscriptionIP = interval(300000)
      .subscribe(x => { this.periodicReload(); });

    // this.route.queryParamMap.subscribe(params => {

    //   this.inProgressRfqNav = params.get('nav');


    // });

    // this.reloader.currentReloadInProgressRfqs.subscribe(reloadCond => {

    //   if (reloadCond.reloadCondition) {

    //     this.updateInProgressRfqAfterQuoteAccept(reloadCond.reloadRfqId);
    //     this.tabToInProgress(true);


    //   } 
    // });

    //SETS RFQ SEARCH FORM VALUES TO FALSE
    this.RFQsearch = this.formBuilder.group({

      complete: [false],
      rawmat: [false],
      cod: [false],
      cbd: [false],
      thirtyDays: [false],
      sixtyDays: [false],
      paymentOther: [false],
      delivery: [false],
      collection: [false],
      boxed: [false],
      pallets: [false],
      strapped: [false],
      loose: [false],
      packagingOther: [false],
      testCertificationYes: [false],
      testCertificationNo: [false]

    });

  }

  ngOnDestroy() {
    this.subscriptionIP.unsubscribe();
  }

  togglePeriodicReload(cond) {
    if (cond) {
      this.rfqUsed = true;

    } else if (!cond) {
      this.rfqUsed = false;

    }

  }

  togglePubChat(cond) {
    if (cond) {
      // this.rfqUsed = true;
      this.getQA = true;
    } else if (!cond) {
      //  this.rfqUsed = false;
      this.getQA = false;
    }
  }

  periodicReload() {

    if (this.rfqUsed == false && this.reloadIPRfqsFromTab) {

      this.sortbyHeadingsUpdate = [];
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

      this.headingId = 1;
      this.singleSortHeadings.rfqHeadName = "Multiple Services";
      this.singleSortHeadings.rfq = [];
      this.singleSortHeadings.id = this.headingId;
      this.sortbyHeadingsUpdate.push(this.singleSortHeadings);
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
      this.headingId++;

      this.getInProgresRFQSAfterUpdate();
    }
  }

  getInProgresRFQSAfterUpdate() {

    this.reloadingSelectedRfq = true;

    this.rfqService.getRFQHeadings(this.account.account.buyerId, "In Progress").subscribe(rfqs => {


      if (rfqs.length > 0) {
        this.inProgressRfqsUpdate = rfqs;

        //IF THE FILTERS ARE USED ON PERIODIC RELAOD , THE FILTERED RFQ ARRAY MUST BE UPDATED
        if (this.filtersUsed) {
          this.filterUpdateRfqs(rfqs);
        }


        if (this.inProgressRfqs.length > 0) {


          var newRfqIds: number[] = [];
          for (let newRfq of this.inProgressRfqsUpdate) {
            newRfqIds.push(newRfq.rfqId)
          }

          if (newRfqIds.indexOf(this.selectedRFQ.rfqId) != -1) {
            for (const { index, value } of this.inProgressRfqsUpdate.map((value, index) => ({ index, value }))) {
              if (value.rfqId == this.selectedRFQ.rfqId) {
                this.rfqIndex = index;
                this.onSelectRFQ(rfqs[index].rfqId);
              }
            }

            this.sortRFQHeadArrayUpdate();
            this.replaceRfqsOnUpdate();

          } else {

            this.sortRFQHeadArrayUpdate();
            this.replaceRfqsOnUpdate();

            this.onSelectRFQ(this.sortbyHeadings[0].rfq[0].rfqId);
          }

          var oldRfqIds: number[] = [];
          for (let oldRfq of this.inProgressRfqs) {
            oldRfqIds.push(oldRfq.rfqId)
          }

          for (let rfq of this.inProgressRfqsUpdate) {
            if (oldRfqIds.indexOf(rfq.rfqId) == -1) {
              this.inProgressRfqs.push(rfq);
            }
          }

          for (const { index, value } of this.inProgressRfqs.map((value, index) => ({ index, value }))) {
            if (newRfqIds.indexOf(value.rfqId) == -1) {
              this.inProgressRfqs.splice(index, 1);
            }
          }


        } else {
          for (let rfq of this.inProgressRfqsUpdate) {
            this.inProgressRfqs.push(rfq);
          }

          this.sortbyHeadings = [];
          this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

          this.headingId = 1;
          this.singleSortHeadings.rfqHeadName = "Multiple Services";
          this.singleSortHeadings.rfq = [];
          this.sortbyHeadings.push(this.singleSortHeadings);
          this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
          this.headingId++;

          for (let rfq of this.inProgressRfqs) {
            rfq.SideSelectedRFQ = false;
          }

          this.sortRFQHeadArray();
          this.onSelectRFQ(this.sortbyHeadings[0].rfq[0].rfqId);
        }

      } else {
        this.inProgressRfqs = []
        this.selectedRFQ = null;
      }
    });

  }

  replaceRfqsOnUpdate() {

    var newHeadings: string[] = [];
    var oldHeadings: string[] = [];

    for (let heading of this.sortbyHeadings) {
      oldHeadings.push(heading.rfqHeadName)
      for (let newHead of this.sortbyHeadingsUpdate) {
        if (heading.rfqHeadName == newHead.rfqHeadName) {
          heading.rfq = newHead.rfq;
          heading.openQuotesCounter = newHead.openQuotesCounter;
        }
      }
    }

    for (let newHeading of this.sortbyHeadingsUpdate) {
      newHeadings.push(newHeading.rfqHeadName);
    }

    //  if (this.sortbyHeadings.length != this.sortbyHeadingsUpdate.length) {
    // if (this.sortbyHeadings.length < this.sortbyHeadingsUpdate.length) {

    for (let newHead of this.sortbyHeadingsUpdate) {
      if (oldHeadings.indexOf(newHead.rfqHeadName) == -1) {
        this.sortbyHeadings.push(newHead);
      }
    }

    // } 
    //else {
    for (const { index, value } of this.sortbyHeadings.map((value, index) => ({ index, value }))) {
      if (newHeadings.indexOf(value.rfqHeadName) == -1) {
        this.sortbyHeadings.splice(index, 1);
      }
    }
    //' }
    // }
  }


  getInProgressRfqsAfterQuoteAccept(rfqId) {

    this.dataLoading = true;

    this.rfqService.getRFQHeadings(this.account.account.buyerId, "In Progress").pipe(
      finalize(() => this.dataLoading = false)
    ).subscribe(rfqs => {
      this.inProgressRfqs = rfqs;
      for (const { index, value } of rfqs.map((value, index) => ({ index, value }))) {
        if (value.rfqId == rfqId) {
          this.onSelectRFQ(rfqs[index].rfqId);
        }
      }
      this.sortRFQHeadArray();
      // this.sentQID = this.selectedRFQ.acceptedQuotes[0].quoteId;
    });
  }

  updateInProgressRfqAfterQuoteAccept(rfqId) {

    this.inProgressRfqs = [];
    this.sortbyHeadings = [];
    this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

    this.headingId = 1;
    this.singleSortHeadings.rfqHeadName = "Multiple Services";
    this.singleSortHeadings.rfq = [];
    this.sortbyHeadings.push(this.singleSortHeadings);
    this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
    this.headingId++;


    this.getInProgressRfqsAfterQuoteAccept(rfqId);

  }

  getInProgressRFQS(selectedRfqId) {

    this.sortbyHeadings = [];
    this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
    this.selectedRFQ = null;
    this.inProgressRfqs = [];

    this.dataLoading = true;

    this.rfqService.getRFQHeadings(this.account.account.buyerId, "In Progress").pipe(
      finalize(() => this.dataLoading = false)
    ).subscribe(rfqs => {

      if (rfqs.length > 0) {

        this.headingId = 1;
        this.singleSortHeadings.rfqHeadName = "Multiple Services";
        this.singleSortHeadings.rfq = [];
        this.sortbyHeadings.push(this.singleSortHeadings);
        this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
        this.headingId++;

        this.inProgressRfqs = rfqs;
        // for (let rfq of rfqs){

        // }
        //Sets initial selected rfq on sidebar to false
        for (let rfq of rfqs) {
          rfq.SideSelectedRFQ = false;
        }


        this.sortRFQHeadArray();

        if (selectedRfqId == 0) {
          this.router.navigate(['buyer-home/view-rfq', 'InProgress', this.sortbyHeadings[0].rfq[0].rfqId, 'false']);
        } else {
          this.onSelectRFQ(selectedRfqId);
        }
      }

    });

  }

  updateInProgressRfq() {
    this.inProgressRfqs = [];
    this.sortbyHeadings = [];
    this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

    this.headingId = 1;
    this.singleSortHeadings.rfqHeadName = "Multiple Services";
    this.singleSortHeadings.rfq = [];
    this.sortbyHeadings.push(this.singleSortHeadings);
    this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
    this.headingId++;


    this.getInProgressRFQS(0);

  }

  reloadAfterQuoteCompleteStay(rfqId) {

    this.resetCompleteRfq()

    this.rfqService.getRfqState(this.selectedRFQ.rfqId).subscribe(x => {

      if (x == "Closed") {

        for (const { index, value } of this.inProgressRfqs.map((value, index) => ({ index, value }))) {
          if (value.rfqId == rfqId) {
            this.inProgressRfqs.splice(index, 1);
          }
        }

        for (let heading of this.sortbyHeadings) {
          for (const { index, value } of heading.rfq.map((value, index) => ({ index, value }))) {
            if (value.rfqId == rfqId) {
              heading.rfq.splice(index, 1);
            }
          }
        }

        for (const { index, value } of this.sortbyHeadings.map((value, index) => ({ index, value }))) {
          if (value.rfq.length == 0) {
            this.sortbyHeadings.splice(index, 1);
          }
        }

        this.router.navigate(['buyer-home/view-rfq', 'InProgress', 0, 'false']);
      } else if (x == "In Progress") {
        this.router.navigate(['buyer-home/view-rfq', 'InProgress', this.selectedRFQ.rfqId, 'true']);
      }
    });
  }

  reloadAfterQuoteComplete() {

    this.resetCompleteRfq();

    // this.inProgressRfqs = [];
    // this.sortbyHeadings = [];
    // this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

    // this.headingId = 1;
    // this.singleSortHeadings.rfqHeadName = "Multiple Services";
    // this.singleSortHeadings.rfq = [];
    // this.sortbyHeadings.push(this.singleSortHeadings);
    // this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
    // this.headingId++;

    this.rfqService.getRfqState(this.selectedRFQ.rfqId).subscribe(x => {

      if (x == "Closed") {
        this.router.navigate(['buyer-home/view-rfq', 'Closed', this.selectedRFQ.rfqId, 'true'], {
          queryParams: { archived: this.selectedRFQ.archived }
        });
      } else if (x == "In Progress") {
        this.router.navigate(['buyer-home/view-rfq', 'InProgress', this.selectedRFQ.rfqId, 'true']);
      }

    });
  }

  sortRFQHeadArray() {

    for (let indexrfq of this.inProgressRfqs) {
      for (let service of indexrfq.servicesRequired) {
        this.check = false;
        if (indexrfq.servicesRequired.length == 1) {
          for (let name of this.sortbyHeadings) {
            if (service == name.rfqHeadName) {
              this.check = true;
            }
          }
          if (this.check == false) {

            this.singleSortHeadings.rfqHeadName = service;
            this.singleSortHeadings.rfq = [];
            this.singleSortHeadings.id = this.headingId;
            this.sortbyHeadings.push(this.singleSortHeadings);
            this.headingId++;
            this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
          }
        }
      }
      if (indexrfq.servicesRequired.length > 1) {
        this.sortbyHeadings[0].rfq.push(indexrfq);

      }

    }

    this.populateRFQHeadArray();
    // this.cdr.detectChanges();

  }

  blipCondition(rfq: RfqHeadings) {

    if (rfq.purchaseOrderFileId == null && rfq.acceptedQuotes[0].salesQuoteFileId != null) {
      return true;
    } else if (rfq.paymentRequirement == 'CBD') {
      if (rfq.acceptedQuotes[0].invoiceQuoteFileId != null && rfq.acceptedQuotes[0].paymentReference == null) {
        return true;
      } else if ((rfq.acceptedQuotes[0].buyerCollectionMade == false && rfq.acceptedQuotes[0].buyerDeliveryMade == false) && rfq.acceptedQuotes[0].paymentReceived == true) {
        return true;
      } else {
        return false;
      }
    } else if (rfq.paymentRequirement != 'CBD') {
      if ((rfq.acceptedQuotes[0].buyerDeliveryMade == false && rfq.acceptedQuotes[0].buyerCollectionMade == false) && rfq.purchaseOrderFileId != null) {
        return true;
      } else {
        return false;
      }
    }
  }

  populateRFQHeadArray(): void {
    for (let indexrfq of this.inProgressRfqs) {
      for (let service of indexrfq.servicesRequired) {
        if (indexrfq.servicesRequired.length == 1) {
          for (let val of this.sortbyHeadings) {
            if (val.rfqHeadName == service) {
              val.rfq.push(indexrfq)

            }
          }
        }

      }
    }
    if (this.sortbyHeadings[0].rfq.length == 0) {
      this.sortbyHeadings.splice(0, 1);
    }

    // LOGIC FOR ACTIONS NEEDED FROM BUYER BLIPS ON NAV
    for (let heading of this.sortbyHeadings) {

      for (let rfq of heading.rfq) {
        heading.openQuotesCounter = 0;

        if (this.blipCondition(rfq)) {
          heading.openQuotesCounter = 1;
          break;
        }
      }

    }

    // sort Rfq Heading in Asc order using String.prototype.localCompare()
    this.sortbyHeadings.sort((a, b) =>
      a.rfqHeadName.localeCompare(b.rfqHeadName)
    );

    // Re-inserts Multiple Services heading to first position in array
    var index = this.sortbyHeadings.findIndex(x => x.rfqHeadName == "Multiple Services");
    if(index != -1){
    var head = this.sortbyHeadings.filter(x => x.rfqHeadName == "Multiple Services")[0];
    this.sortbyHeadings.splice(index, 1);
    this.sortbyHeadings.splice(0, 0, head);
    }
  }

  //Used for periodic updating of RFQS
  sortRFQHeadArrayUpdate() {

    for (let indexrfq of this.inProgressRfqsUpdate) {
      for (let service of indexrfq.servicesRequired) {
        this.check = false;
        if (indexrfq.servicesRequired.length == 1) {
          for (let name of this.sortbyHeadingsUpdate) {
            if (service == name.rfqHeadName) {
              this.check = true;
            }
          }
          if (this.check == false) {

            this.singleSortHeadings.rfqHeadName = service;
            this.singleSortHeadings.rfq = [];
            this.singleSortHeadings.id = this.headingId;
            this.sortbyHeadingsUpdate.push(this.singleSortHeadings);
            this.headingId++;
            this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
          }
        }
      }
      if (indexrfq.servicesRequired.length > 1) {
        this.sortbyHeadingsUpdate[0].rfq.push(indexrfq);

      }

    }

    this.populateRFQHeadArrayUpdate();
    this.getClosingTime(Date);

    // this.cdr.detectChanges();

  }


  populateRFQHeadArrayUpdate(): void {
    for (let indexrfq of this.inProgressRfqsUpdate) {
      for (let service of indexrfq.servicesRequired) {
        if (indexrfq.servicesRequired.length == 1) {
          for (let val of this.sortbyHeadingsUpdate) {
            if (val.rfqHeadName == service) {
              val.rfq.push(indexrfq)

            }
          }
        }

      }
    }
    if (this.sortbyHeadingsUpdate[0].rfq.length == 0) {
      this.sortbyHeadingsUpdate.splice(0, 1);
    }

    // LOGIC FOR ACTIONS NEEDED FROM BUYER BLIPS ON NAV
    for (let heading of this.sortbyHeadingsUpdate) {

      for (let rfq of heading.rfq) {
        heading.openQuotesCounter = 0;

        if (this.blipCondition(rfq)) {
          heading.openQuotesCounter = 1;
          break;
        }

      }

    }

  }

  selectedHeadingRfq: RfqHeadings;

  loadingRfq: boolean = false;

  triggerSelectRfqLoading() {
    this.reloadingSelectedRfq = false;
  }

  loadingRfqSpinnerId: number;
  newQuotes: boolean = true;

  onSelectRFQNav(rfq: RfqHeadings): void {
    this.loadingRfqSpinnerId = rfq.rfqId;
    this.newQuotes = true;
    this.router.navigate(['buyer-home/view-rfq', 'InProgress', rfq.rfqId, 'false']);
  }

  onSelectRFQ(rfqId: number): void {

    this.selectedHeadingRfq = this.inProgressRfqs.filter(x => x.rfqId == rfqId)[0];
    this.selectedRfqId = rfqId;

    if (!this.reloadingSelectedRfq) {
      this.loadingRfq = true;
    }

    this.rfqService.getRFQById(rfqId).pipe(first())
      .subscribe({
        next: (response: any) => {

          if (response.state == "In Progress") {

            if (this.newQuotes == true) {
              this.openQuotes = response.openQuotes;
              this.acceptedQuotes = response.acceptedQuotes;
              this.newQuotes = false;
            } else {
              if (response.acceptedQuotes.length > 0) {
                this.reloadAcceptedQuotes(response.acceptedQuotes)
              }
            }

            this.selectedRFQ = response;

            // CHECKS FOR SALES ORDER DOWLOADED
            if (this.selectedRFQ.acceptedQuotes[0].salesQuoteFileId) {
              this.fileDownloadedService.getFileDownloadedCheck(this.selectedRFQ.acceptedQuotes[0].salesQuoteFileId, this.account.account.userId).subscribe(check => {
                this.selectedRFQ.acceptedQuotes[0].salesOrderDownloaded = check;
                this.acceptedQuotes[0].salesOrderDownloaded = check;
              });
            }

            // CHECKS FOR INVOCIE ORDER DOWLOADED
            if (this.selectedRFQ.acceptedQuotes[0].invoiceQuoteFileId) {
              this.fileDownloadedService.getFileDownloadedCheck(this.selectedRFQ.acceptedQuotes[0].invoiceQuoteFileId, this.account.account.userId).subscribe(check => {
                this.selectedRFQ.acceptedQuotes[0].invoiceOrderDownloaded = check;
                this.acceptedQuotes[0].invoiceOrderDownloaded = check;

              });
            }



            //SETS COUNT FOR UNREAD MESSAGES ARRAY
            //CHECK IF UNDREAD MESSAGE ARRAY EXISTS
            if (this.selectedHeadingRfq.acceptedQuotes[0].unreadPrivateChatHistory) {
              this.UnreadMsgsCount = this.selectedHeadingRfq.acceptedQuotes[0].unreadPrivateChatHistory.length;

            }

            //CHECKS FOR UNREAD MESSAGES NEEDED FOR RED BLIP

            for (let quote of this.selectedHeadingRfq.acceptedQuotes) {

              if (quote.unreadPrivateMessages == true) {
                this.unreadMessages = true;

              }
              if (quote.unreadPrivateMessages == false) {
                this.unreadMessages = false;
              }
            }

            this.checklistValidCond = true;

            //INDICATES WHICH THING RFQ BUTTON IS SELECTED FOR THE IN PROGRESS RFQs OBJECT
            for (let siderfq of this.inProgressRfqs) {
              if (this.selectedRfqId == siderfq.rfqId) {
                siderfq.SideSelectedRFQ = true;
              }
              else {
                siderfq.SideSelectedRFQ = false;
              }

            }

            //INDICATES WHICH THING RFQ BUTTON IS SELECTED FOR THE IN PROGRESS RFQs AFTER RELOAD OBJECT
            for (let siderfq of this.inProgressRfqsUpdate) {
              if (this.selectedRfqId == siderfq.rfqId) {
                siderfq.SideSelectedRFQ = true;
              }
              else {
                siderfq.SideSelectedRFQ = false;
              }

            }
            this.loadingRfq = false


            this.dDay = this.selectedRFQ.rfqClosingDate;
            this.sentQID = this.selectedRFQ.acceptedQuotes[0].quoteId;

            this.router.navigate(['buyer-home/view-rfq', 'InProgress', response.rfqId, 'false']);

          } else {
            this.router.navigate(['buyer-home/view-rfq', 'InProgress', 0, 'false']);
            this.toastrNotification.error("Invalid Selected Rfq");
          }

        },
        error: error => {

          this.router.navigate(['buyer-home/view-rfq', 'InProgress', this.sortbyHeadings[0].rfq[0].rfqId, 'false']);
          this.toastrNotification.error("Invalid Selected Rfq");
          this.loadingRfq = false
        }
      });



  }

  reloadAcceptedQuotes(newQuotes: ViewRFQs_Quotes[]) {

    for (let currentQuote of this.acceptedQuotes) {
      for (let replaceQuote of newQuotes) {
        if (replaceQuote.quoteId == currentQuote.quoteId) {
          currentQuote.state = replaceQuote.state;
          currentQuote.paymentReference = replaceQuote.paymentReference;
          currentQuote.paymentReceived = replaceQuote.paymentReceived;
          currentQuote.salesOrderFileName = replaceQuote.salesOrderFileName;
          currentQuote.salesQuoteFileId = replaceQuote.salesQuoteFileId;
          currentQuote.productCertificationFileName = replaceQuote.productCertificationFileName;
          currentQuote.productCertificationQuoteFileId = replaceQuote.productCertificationQuoteFileId;
          currentQuote.invoiceOrderFileName = replaceQuote.invoiceOrderFileName;
          currentQuote.invoiceQuoteFileId = replaceQuote.invoiceQuoteFileId;
          currentQuote.salesOrderCreatedAt = replaceQuote.salesOrderCreatedAt;
          currentQuote.invoiceCreatedAt = replaceQuote.invoiceCreatedAt;
          currentQuote.productCertCreatedAt = replaceQuote.productCertCreatedAt;
          currentQuote.supplierDeliveryMade = replaceQuote.supplierDeliveryMade;
          currentQuote.buyerCollectionMade = replaceQuote.buyerCollectionMade;
          currentQuote.buyerDeliveryMade = replaceQuote.buyerDeliveryMade;
          currentQuote.supplierCollectionMade = replaceQuote.supplierCollectionMade;
          currentQuote.supplierDetails = replaceQuote.supplierDetails;
        }
      }
    }
  }

  triggerChatOpen(value: boolean) {
    this.openChatCond.emit(value);
    this.openChat = value;


    //CHECKS IF SIDE NAVS RFQ ID = THE SELECTED RFQ AND IF CHAT IS OPENED ON SELECTED RFQ, THE BADGES DISSAPEARS
    if (value == true) {
      for (let siderfq of this.inProgressRfqs) {
        if (this.selectedRfqId == siderfq.rfqId) {
          this.selectedRFQ.acceptedQuotes[0].unreadPrivateMessages = false;
          this.acceptedQuotes[0].unreadPrivateMessages = false;
        }
      }

    }

  }

  //needed for next modal
  onNext() {
    this.nextmodal = true;
  }

  toggleCollapse() {
    this.showchat = !this.showchat;

  }


  Sendquotedata(value: number) {
    this.quoteEvent.emit(value);
    this.sentQID = value;

  }

  onTimeDeliveryCheck(answer) {
    this.onTimeDeliveryCond = answer;
    if (answer) {
      this.f.onTimeDeliveryNo.setValue(false);
      this.f.onTimeDeliveryYes.setValue(true);
    } else {
      this.f.onTimeDeliveryYes.setValue(false);
      this.f.onTimeDeliveryNo.setValue(true);
    }

  }

  correctMaterialGradeCheck(answer) {
    this.correctMaterialGradeCond = answer;
    if (answer) {
      this.f.correctMaterialGradeNo.setValue(false);
      this.f.correctMaterialGradeYes.setValue(true);
    } else {
      this.f.correctMaterialGradeYes.setValue(false);
      this.f.correctMaterialGradeNo.setValue(true);
    }

  }

  correctQuantityOfPartsCheck(answer) {
    this.correctQuantityOfPartsCond = answer;
    if (answer) {
      this.f.correctQuantityNo.setValue(false);
      this.f.correctQuantityYes.setValue(true);
    } else {
      this.f.correctQuantityYes.setValue(false);
      this.f.correctQuantityNo.setValue(true);
    }

  }

  supplierCommunicationCheck(answer) {
    if (answer) {
      if (this.supplierCommunicationCond != answer) {
        this.supplierCommunicationCond = answer;
        this.f.supplierCommunicateNo.setValue(false);
      } else {
        this.supplierCommunicationCond = null;
        this.f.supplierCommunicateYes.setValue(false);
      }
    } else if (!answer) {
      if (this.supplierCommunicationCond != answer) {
        this.supplierCommunicationCond = answer;
        this.f.supplierCommunicateYes.setValue(false);
      } else {
        this.supplierCommunicationCond = null;
        this.f.supplierCommunicateNo.setValue(false);
      }
    }

  }

  receivedTestCertificationCheck(answer) {
    this.receivedTestCertificationCond = answer;
    if (answer) {
      this.f.receivedTestCertNo.setValue(false);
      this.f.receivedTestCertYes.setValue(true);
    } else {
      this.f.receivedTestCertYes.setValue(false);
      this.f.receivedTestCertNo.setValue(true);
    }

  }

  rateSupplier(answer) {

    if (this.supplierRating == answer) {
      this.supplierRating = answer;
    } else if (this.supplierRating != answer) {
      this.supplierRating = answer;
    } else if (this.supplierRating == null) {
      this.supplierRating = answer;
      this.ratingForm.reset();
    }

  }

  clearSupplierRating() {

    this.supplierRating = 0;
    this.resetRatingForm = null;

  }

  checklistCheck() {
    if (this.onTimeDeliveryCond == null || this.correctMaterialGradeCond == null || this.correctQuantityOfPartsCond == null
      || (this.selectedRFQ.productCertificationRequirements && this.receivedTestCertificationCond == null)
      || (this.onTimeDeliveryCond == false && this.f.onTimeDeliveryReason.value.replace(/ /g, "") == "")
      || (this.correctMaterialGradeCond == false && this.f.correctMaterialGradeReason.value.replace(/ /g, "") == "")
      || (this.correctQuantityOfPartsCond == false && this.f.correctQuantityReason.value.replace(/ /g, "") == "")
      || (this.supplierCommunicationCond == false && this.f.supplierCommunicateReason.value.replace(/ /g, "") == "")
      || (this.receivedTestCertificationCond == false && this.f.receivedTestCertReason.value.replace(/ /g, "") == "")
      || (this.onTimeDeliveryCond == false && this.f.onTimeDeliveryReason.value.length > 250)
      || (this.correctMaterialGradeCond == false && this.f.correctMaterialGradeReason.value.length > 250)
      || (this.correctQuantityOfPartsCond == false && this.f.correctQuantityReason.value.length > 250)
      || (this.supplierCommunicationCond == false && this.f.supplierCommunicateReason.value.length > 250)
      || (this.receivedTestCertificationCond == false && this.f.receivedTestCertReason.value.length > 250)

      || this.f.actualDeliveryDate.value == null) {
      this.checklistValidCond = false;
    } else {
      this.checklistValidCond = true;
    }
  }

  checklistCond() {
    if (this.onTimeDeliveryCond == null || this.correctMaterialGradeCond == null || this.correctQuantityOfPartsCond == null
      || (this.selectedRFQ.productCertificationRequirements && this.receivedTestCertificationCond == null)
      || (this.onTimeDeliveryCond == false && this.f.onTimeDeliveryReason.value.replace(/ /g, "") == "")
      || (this.correctMaterialGradeCond == false && this.f.correctMaterialGradeReason.value.replace(/ /g, "") == "")
      || (this.correctQuantityOfPartsCond == false && this.f.correctQuantityReason.value.replace(/ /g, "") == "")
      || (this.supplierCommunicationCond == false && this.f.supplierCommunicateReason.value.replace(/ /g, "") == "")
      || (this.receivedTestCertificationCond == false && this.f.receivedTestCertReason.value.replace(/ /g, "") == "")
      || (this.onTimeDeliveryCond == false && this.f.onTimeDeliveryReason.value.length > 250)
      || (this.correctMaterialGradeCond == false && this.f.correctMaterialGradeReason.value.length > 250)
      || (this.correctQuantityOfPartsCond == false && this.f.correctQuantityReason.value.length > 250)
      || (this.supplierCommunicationCond == false && this.f.supplierCommunicateReason.value.length > 250)
      || (this.receivedTestCertificationCond == false && this.f.receivedTestCertReason.value.length > 250)
      || this.f.actualDeliveryDate.value == null) {
      return false;
    } else {
      return true;
    }
  }

  stringValidation(value) {
    if (value.replace(/ /g, "") == "") {
      return true
    } else {
      return false;
    }
  }

  deliverNoteValidCond: boolean;

  deliveryNoteValid() {
    if (this.selectedRFQ.deliveryNoteFileId == null) {
      this.deliverNoteValidCond = true;
    } else {
      this.deliverNoteValidCond = false;
    }
  }

  completeRfqSubmit() {
    this.loading = true;

    this.supplierSurvey = {
      rfqId: null, quoteId: null, onTimeDelivery: null, onTimeDeliveryReason: null,
      correctMaterialGrade: null, correctMaterialGradeReason: null, correctQuantity: null, correctQuantityReason: null,
      goodCommunication: null, goodCommunicationReason: null, correctTestCertification: null, correctTestCertificationReason: null,
      actualDeliveryDate: null, supplierRating: null, deliveryRequirements: null
    };

    if ((this.onTimeDeliveryCond != null && this.correctMaterialGradeCond != null && this.correctQuantityOfPartsCond != null)) {

      this.supplierSurvey.rfqId = this.selectedRFQ.rfqId;
      this.supplierSurvey.quoteId = this.selectedRFQ.acceptedQuotes[0].quoteId;
      this.supplierSurvey.onTimeDelivery = this.onTimeDeliveryCond;
      if (this.onTimeDeliveryCond == true) {
        this.supplierSurvey.onTimeDeliveryReason = null;
      } else {
        this.supplierSurvey.onTimeDeliveryReason = this.f.onTimeDeliveryReason.value;
      }
      this.supplierSurvey.correctMaterialGrade = this.correctMaterialGradeCond;
      if (this.correctMaterialGradeCond == true) {
        this.supplierSurvey.correctMaterialGradeReason = null;
      } else {
        this.supplierSurvey.correctMaterialGradeReason = this.f.correctMaterialGradeReason.value;
      }
      this.supplierSurvey.correctQuantity = this.correctQuantityOfPartsCond;
      if (this.correctQuantityOfPartsCond == true) {
        this.supplierSurvey.correctQuantityReason = null;
      } else {
        this.supplierSurvey.correctQuantityReason = this.f.correctQuantityReason.value;
      }
      this.supplierSurvey.goodCommunication = this.supplierCommunicationCond;
      if (this.supplierCommunicationCond == true) {
        this.supplierSurvey.goodCommunicationReason = null;
      } else {
        this.supplierSurvey.goodCommunicationReason = this.f.supplierCommunicateReason.value;
      }
      this.supplierSurvey.correctTestCertification = this.receivedTestCertificationCond;
      if (this.receivedTestCertificationCond == true) {
        this.supplierSurvey.correctTestCertificationReason = null;
      } else {
        this.supplierSurvey.correctTestCertificationReason = this.f.receivedTestCertReason.value;
      }
      this.supplierSurvey.actualDeliveryDate = this.f.actualDeliveryDate.value;
      this.supplierSurvey.supplierRating = this.supplierRating;

      if (this.selectedRFQ.deliveryRequirements == "Collection") {
        this.supplierSurvey.deliveryRequirements = "Collection";
      } else if (this.selectedRFQ.deliveryRequirements == "Delivery") {
        this.supplierSurvey.deliveryRequirements = "Delivery";
      }


      this.completeRfq(this.supplierSurvey);


      this.form.reset();
      this.ratingForm.reset();

      this.onTimeDeliveryCond = null;
      this.correctMaterialGradeCond = null;
      this.correctQuantityOfPartsCond = null;
      this.supplierCommunicationCond = null;
      this.receivedTestCertificationCond = null;
      this.supplierRating = 0;
      this.f.onTimeDeliveryReason.setValue("");
      this.f.correctMaterialGradeReason.setValue("");
      this.f.correctQuantityReason.setValue("");
      this.f.supplierCommunicateReason.setValue("");
      this.f.receivedTestCertReason.setValue("");
      this.checklistValidCond = true;
    } else {
      this.toastrNotification.error("error with form");
    }

  }

  resetCompleteRfq() {
    this.form.reset();
    this.ratingForm.reset();

    this.onTimeDeliveryCond = null;
    this.correctMaterialGradeCond = null;
    this.correctQuantityOfPartsCond = null;
    this.supplierCommunicationCond = null;
    this.receivedTestCertificationCond = null;
    this.supplierRating = 0;

    this.f.onTimeDeliveryReason.setValue("");
    this.f.correctMaterialGradeReason.setValue("");
    this.f.correctQuantityReason.setValue("");
    this.f.supplierCommunicateReason.setValue("");
    this.f.receivedTestCertReason.setValue("");
    this.checklistValidCond = true;

    this.togglePeriodicReload(false);
  }

  quoteStateCheck: string;

  completeRfq(survey) {
    var resultMessage;
    this.rfqService.completeRfq(survey).pipe(first())
      .subscribe({
        next: (response: any) => {

          this.quoteService.QuoteStateCheck(this.selectedRFQ.acceptedQuotes[0].quoteId).pipe(first())
            .subscribe({
              next: (response: any) => {
                this.quoteStateCheck = response;
                resultMessage = response;
                this.loading = false;
                this.onNext();
                this.markAsCompleteFinal.nativeElement.click();
              },
              error: error => {
                this.toastrNotification.error("Rfq State Check Failed");
                this.loading = false;
              }

            }
            );
        },
        error: error => {
          this.toastrNotification.error("Rfq Completion Failed");
          this.loading = false;
        }

      }
      );
  }

  checkingRfq: boolean = false;

  completeRfqCheck(rfqId, cond) {

    this.checkingRfq = true;

    this.togglePeriodicReload(true);

    this.rfqService.getRfqState(rfqId).subscribe(x => {

      if (x == "In Progress") {
        if (cond == "Initial") {

          this.getRfqReview();
          //this.markAsCompleteBtn.nativeElement.click();
          this.checkingRfq = false;


        } else if (cond == "Final") {
          this.completeRfqSubmit();
          this.checkingRfq = false;
        }

      } else {
        this.toastrNotification.info("Rfq " + this.selectedRFQ.rfqName + " has already been completed");
        this.togglePeriodicReload(false);

        if (cond == "Initial") {
          this.reloadAfterQuoteCompleteStay(rfqId)
        } else if (cond == "Final") {
          this.markAsCompleteClose.nativeElement.click();
          this.reloadAfterQuoteCompleteStay(rfqId)
        }
        this.checkingRfq = false;
      }
    });
  }

  rfqReview: FullRfqReview;

  getRfqReview() {
    this.rfqService.getRfqReview(this.selectedRFQ.rfqId).pipe(first())
      .subscribe({
        next: (response: any) => {
          this.rfqReview = response;
          this.markAsCompleteBtn.nativeElement.click();

          this.checkingRfq = false;
        },
        error: error => {
          this.toastrNotification.error("Rfq Review Get Failed");
          this.makingPayment = false;
        }

      });
  }



  triggerReloadRfqs(value: boolean) {
    if (value) {

      this.sortbyHeadings = [];
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

      this.headingId = 1;
      this.singleSortHeadings.rfqHeadName = "Multiple Services";
      this.singleSortHeadings.rfq = [];
      this.sortbyHeadings.push(this.singleSortHeadings);
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
      this.headingId++;

      this.getInProgressRFQSAfterEdit(this.selectedRFQ.rfqId);
    }

  }

  @ViewChild('makePaymentBtnIP') makePaymentBtnIP: ElementRef;
  @ViewChild('makePaymentFinalIP') makePaymentFinalIP: ElementRef;
  @ViewChild('makePaymentCloseIP') makePaymentCloseIP: ElementRef;

  paymentValid() {

    if (this.pf.paymentReference.value.replace(/ /g, "") == '' || this.pf.paymentReference.value.length > 50) {
      return false;
    } else {
      return true;
    }

  }

  paymentValidCond: boolean;

  paymentValidCheck() {
    if (!this.paymentValid()) {
      this.paymentValidCond = false;
    } else {
      this.paymentValidCond = true;
    }
  }

  paymentAvailable: boolean = false;

  makePaymentCheck(rfqId, cond) {
    this.togglePeriodicReload(true);

    this.paymentAvailable = true;

    this.rfqService.getPaymentMadeCheck(this.selectedRFQ.acceptedQuotes[0].quoteId).subscribe(x => {

      if (!x) {

        if (cond == "Initial") {
          this.fileDownloadedService.getFileDownloadedCheck(this.selectedRFQ.acceptedQuotes[0].invoiceQuoteFileId, this.account.account.userId).subscribe(check => {

            if (check) {
              this.makePaymentBtnIP.nativeElement.click();
            } else {
              this.toastrNotification.info("Download And Confirm Final Invoice File Before Making Payment");
            }
            this.paymentAvailable = false;
          });
        } else if (cond == "Final") {
          this.makePayment();
          this.paymentAvailable = false;
        }
      } else {
        this.toastrNotification.info("Payment has already been made for Rfq " + this.selectedRFQ.rfqName);
        this.togglePeriodicReload(false);

        if (cond == "Initial") {
          // this.reloadAfterComplete(rfqId)
        } else if (cond == "Final") {
          this.makePaymentCloseIP.nativeElement.click();
          //  this.reloadAfterComplete(rfqId)
        }
        this.paymentAvailable = false;
      }

    });
  }

  makingPayment: boolean = false;
  payDetails: MakePaymentRequest = { QuoteId: null, PaymentReference: null }

  makePayment() {

    if (this.pf.paymentReference.value.replace(/ /g, "") != '' && this.pf.paymentReference.value.length < 50) {

      this.makingPayment = true;

      this.payDetails = { QuoteId: null, PaymentReference: null }

      this.payDetails.PaymentReference = this.pf.paymentReference.value;
      this.payDetails.QuoteId = this.selectedRFQ.acceptedQuotes[0].quoteId;

      this.rfqService.makePayment(this.payDetails).pipe(first())
        .subscribe({
          next: (response: any) => {
            this.makePaymentFinalIP.nativeElement.click();
            this.makingPayment = false;
            this.pf.paymentReference.setValue('');
          },
          error: error => {
            this.toastrNotification.error("Rfq Payment Failed");
            this.makingPayment = false;
          }

        });

    } else {
      this.toastrNotification.error("Invalid Payment Reference");
    }
  }

  paymentCheck() {
    if (this.selectedRFQ.acceptedQuotes[0].paymentReference == null) {
      return false;
    } else {
      return true;
    }
  }

  supplierAcceptPaymentCheck() {
    if (this.selectedRFQ.acceptedQuotes[0].paymentReceived) {
      return true;
    } else {
      return false;
    }
  }

  getInProgressRFQSAfterEdit(rfqId) {

    this.rfqService.getRFQHeadings(this.account.account.buyerId, "In Progress").subscribe(rfqs => {
      this.inProgressRfqs = rfqs;

      this.purchaseOrderUploading = false;
      this.purchaseOrderDeleting = false;
      this.deliveryNoteUploading = false;
      this.deliveryNoteDeleting = false;

      for (const { index, value } of this.inProgressRfqs.map((value, index) => ({ index, value }))) {
        if (value.rfqId == rfqId) {
          this.onSelectRFQ(rfqs[index].rfqId);
        }
      }
      this.sortRFQHeadArray();
    });
  }

  get f() { return this.form.controls; }

  get pf() { return this.payForm.controls; }

  get ipqf() { return this.inProgressQuoteform.controls; }

  get oqf() { return this.otherQuoteform.controls; }

  //Files 
  @ViewChild('clearDInput', { static: false }) clearDInputVar: ElementRef;
  @ViewChild('clearDEInput', { static: false }) clearDEInputVar: ElementRef;
  @ViewChild('clearPOInput', { static: false }) clearPOInput: ElementRef;

  @ViewChild('PurchaseOrderUploadInput') PurchaseOrderUploadInput: ElementRef;

  @ViewChild('clearPPIPInput', { static: false }) clearPPIPInput: ElementRef;

  proofOfPaymentUploading: boolean = false;
  proofOfPaymentDeleting: boolean = false;

  resetTCInput() {
    this.clearDInputVar.nativeElement.value = "";
  }

  deliveryNoteFile: File;
  purchaseOrderFile: File;
  proofOfPaymentFile: File;
  deliveryNoteUpload: UploadRfqFile = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
  purchaseOrderUpload: UploadRfqFile = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, file: null, userId: null, fileName: null };
  proofOfPaymentUpload: UploadRfqFile = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
  requiredFileType: string = ".pdf";
  purchaseOrderUploading: boolean = false;
  purchaseOrderDeleting: boolean = false;
  deliveryNoteUploading: boolean = false;
  deliveryNoteDeleting: boolean = false;

  downloadSalesOrderFile(quoteFileId, quoteId) {
    this.quoteService.getQuoteFile(quoteFileId, this.account.account.userId, "Buyer").subscribe({
      next: (response: any) => {
        this.downloadFile(response.fileContent, response.fileName);
        // SALES ORDER DOWNLOADED
        this.selectedRFQ.acceptedQuotes[0].salesOrderDownloaded = true;
        this.acceptedQuotes[0].salesOrderDownloaded = true;

      },
      error: error => {
        this.toastrNotification.info("Sales Order File out of date, new Sales Order File loaded. Please retry download")
        this.quoteService.getQuoteFileDetails(quoteId, "SalesOrders").subscribe(x => {
          this.selectedRFQ.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].salesQuoteFileId = x.quoteFileId;
          this.selectedRFQ.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].salesOrderFileName = x.quoteFileName;
          this.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].salesQuoteFileId = x.quoteFileId;
          this.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].salesOrderFileName = x.quoteFileName;
        });
      }
    });
  }

  downloadInvoiceFile(quoteFileId) {
    this.quoteService.getInvoiceFile(quoteFileId).subscribe(file => {
      this.downloadFile(file.fileContent, file.fileName);
    })
  }



  salesOrderDownloadedCheck(salesOrderFileId) {
    this.fileDownloadedService.getFileDownloadedCheck(salesOrderFileId, this.account.account.userId).subscribe(check => {

      if (check) {


        this.PurchaseOrderUploadInput.nativeElement.click();
      } else {
        this.toastrNotification.info("Download And Confirm Sales Order File Before Uploading Purchase Order");
      }

    });
  }




  downloadProductCertificationFile(quoteFileId, quoteId) {
    this.quoteService.getProductCertificationFile(quoteFileId).subscribe(file => {
      this.quoteService.getQuoteFile(quoteFileId, this.account.account.userId, "Buyer").subscribe({
        next: (response: any) => {
          this.downloadFile(response.fileContent, response.fileName);

        },
        error: error => {
          this.toastrNotification.info("Test Certification File out of date, new Test Certification File loaded. Please retry download")
          this.quoteService.getQuoteFileDetails(quoteId, "ProductCertification").subscribe(x => {
            this.selectedRFQ.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].productCertificationQuoteFileId = x.quoteFileId;
            this.selectedRFQ.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].productCertificationFileName = x.quoteFileName;
            this.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].productCertificationQuoteFileId = x.quoteFileId;
            this.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].productCertificationFileName = x.quoteFileName;
          });
        }
      });
    })
  }

  invoiceDownloadedCheck(invoiceFileId) {
    this.fileDownloadedService.getFileDownloadedCheck(invoiceFileId, this.account.account.userId).subscribe(check => {

      if (check) {
        this.PurchaseOrderUploadInput.nativeElement.click();
      } else {
        this.toastrNotification.info("Download And Confirm Final Invoice File Before Uploading Purchase Order");
      }

    });
  }

  downloadingQuoteFile: boolean = false;
  downloadingInvoiceFile: boolean = false;


  downloadIPQuoteFiles(quoteFileId, quoteId, type) {


    if (type == "Invoices") {
      this.downloadingInvoiceFile = true;
    } else {
      this.downloadingQuoteFile = true;
    }


    this.quoteService.getQuoteFile(quoteFileId, this.account.account.userId, "Buyer").subscribe({
      next: (response: any) => {

        if (type == "Invoices") {
          //  SETS INVOICE DOWNLOAD BOOL TO TRUE
          this.selectedRFQ.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].invoiceOrderDownloaded = true;
          this.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].invoiceOrderDownloaded = true;

        }

        var FileContent = response.fileContent;
        var FileName = response.fileName;
        this.downloadFile(FileContent, FileName);
        this.downloadingQuoteFile = false;
        this.downloadingInvoiceFile = false;

      },
      error: error => {

        if (type == "Invoices") {
          this.toastrNotification.info("Final Invoice File out of date, new Final Invoice File loaded. Please retry download")
          this.quoteService.getQuoteFileDetails(quoteId, "Invoices").subscribe(x => {
            this.selectedRFQ.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].invoiceQuoteFileId = x.quoteFileId;
            this.selectedRFQ.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].invoiceOrderFileName = x.quoteFileName;
            this.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].invoiceQuoteFileId = x.quoteFileId;
            this.acceptedQuotes.filter(x => x.quoteId == quoteId)[0].invoiceOrderFileName = x.quoteFileName;
            this.downloadingQuoteFile = false;
            this.downloadingInvoiceFile = false;
          });
        } else if (type == "Quotes") {
          this.downloadingQuoteFile = false;
          this.downloadingInvoiceFile = false;
        }

      }
    });

  }


  downloadOtherQuoteFiles(quoteId) {

    if (this.oqf.otherQuoteFile.value) {
      this.rfqService.getQuoteFile(quoteId, this.account.account.userId).subscribe(x => {

        var FileContent = x[0].fileContent;
        var FileName = x[0].fileName;
        this.downloadFile(FileContent, FileName);
      });
      this.oqf.otherQuoteFile.setValue(false);
    }
  }

  downloadDrawingFile(rfqFileId) {
    this.rfqService.getRfqDrawingFile(rfqFileId).subscribe(x => {

      this.downloadFile(x.fileContent, x.fileName)
    })
  }

  downloadPurchaseOrderFile(purcahseOrderFileId) {
    this.rfqService.getRfqDrawingFile(purcahseOrderFileId).subscribe({

      next: (response: any) => {

        this.downloadFile(response.fileContent, response.fileName)
      },
      error: error => {
        this.rfqService.getRfqFileDetails(this.selectedRFQ.rfqId, "PurchaseOrders").subscribe(x => {
          this.selectedRFQ.purchaseOrderFileId = x.rfqFileId;
          this.selectedRFQ.purchaseOrderFileName = x.rfqFileName;
        });
        this.toastrNotification.info("Purchase Order File out of date, new Purchase Order File loaded. Please retry download")

      }
    })
  }

  proofOfPaymentSelected(event) {

    this.proofOfPaymentUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };

    this.proofOfPaymentFile = event.target.files[0];
    const reader = new FileReader();

    this.proofOfPaymentUploading = true;


    if (this.proofOfPaymentFile) {

      reader.readAsDataURL(this.proofOfPaymentFile);
      reader.onload = () => {
        this.proofOfPaymentUpload.file = (reader.result + "").substring((reader.result + "").indexOf(",") + 1, (reader.result + "").length);
        this.proofOfPaymentUpload.quoteId = this.selectedRFQ.acceptedQuotes[0].quoteId;
        this.proofOfPaymentUpload.rfqId = this.selectedRFQ.rfqId;
        this.proofOfPaymentUpload.buyerId = this.account.account.buyerId;
        this.proofOfPaymentUpload.userId = this.account.account.userId;;
        this.proofOfPaymentUpload.supplierId = null;
        this.proofOfPaymentUpload.fileName = this.proofOfPaymentFile.name;
        this.rfqService.uploadProofOfPaymentFile(this.proofOfPaymentUpload).pipe(
          finalize(() => this.proofOfPaymentUploading = false)).subscribe(x => {

            if (this.selectedRFQ.proofOfPaymentFileId != null) {
              this.proofOfPaymentDeleting = true;
              this.rfqService.deleteRfqDrawingFile(this.selectedRFQ.proofOfPaymentFileId).pipe(
                finalize(() => this.proofOfPaymentDeleting = false)).subscribe(y => {
                  // this.triggerReloadRfqs(true);
                  this.rfqService.getRfqFileDetails(this.selectedRFQ.rfqId, "ProofOfPayment").subscribe(x => {
                    this.selectedRFQ.proofOfPaymentFileId = x.rfqFileId;
                    this.selectedRFQ.proofOfPaymentFileName = x.rfqFileName;
                  });
                });
            } else {
              // this.triggerReloadRfqs(true);
              this.rfqService.getRfqFileDetails(this.selectedRFQ.rfqId, "ProofOfPayment").subscribe(x => {
                this.selectedRFQ.proofOfPaymentFileId = x.rfqFileId;
                this.selectedRFQ.proofOfPaymentFileName = x.rfqFileName;
              });
            }
            this.clearPPIPInput.nativeElement.value = "";
          });
      };
    }
  }

  PurchaseorderSelected(event) {


    this.purchaseOrderUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };
    this.purchaseOrderFile = event.target.files[0];
    const reader = new FileReader();

    if (this.purchaseOrderFile) {

      if (this.purchaseOrderFile.name != this.selectedRFQ.deliveryNoteFileName) {

        this.purchaseOrderUploading = true;

        reader.readAsDataURL(this.purchaseOrderFile);
        reader.onload = () => {

          this.purchaseOrderUpload.file = (reader.result + "").substring((reader.result + "").indexOf(",") + 1, (reader.result + "").length);

          this.purchaseOrderUpload.quoteId = this.selectedRFQ.acceptedQuotes[0].quoteId;
          this.purchaseOrderUpload.rfqId = this.selectedRFQ.rfqId;
          this.purchaseOrderUpload.buyerId = this.account.account.buyerId;
          this.purchaseOrderUpload.userId = this.account.account.userId;
          this.purchaseOrderUpload.supplierId = null;
          this.purchaseOrderUpload.fileName = this.purchaseOrderFile.name;
          this.rfqService.uploadPurchaseOrderFile(this.purchaseOrderUpload).pipe(
            finalize(() => this.purchaseOrderUploading = true)
          ).subscribe(data => {

            if (this.selectedRFQ.purchaseOrderFileId != null) {
              this.purchaseOrderDeleting = true;
              this.rfqService.deleteRfqDrawingFile(this.selectedRFQ.purchaseOrderFileId).pipe(
                finalize(() => this.purchaseOrderDeleting = true)
              ).subscribe(data => {
                this.rfqService.getRfqFileDetails(this.selectedRFQ.rfqId, "PurchaseOrders").pipe(first())
                  .subscribe({
                    next: (response: any) => {
                      this.selectedRFQ.purchaseOrderFileId = response.rfqFileId;
                      this.selectedRFQ.purchaseOrderFileName = response.rfqFileName;

                      this.purchaseOrderUploading = false;
                      this.purchaseOrderDeleting = false;


                      for (let rfqHeading of this.sortbyHeadings) {
                        for (let rfq of rfqHeading.rfq) {
                          if (rfq.rfqId == this.selectedRFQ.rfqId) {
                            rfq.purchaseOrderFileId = response.rfqFileId;
                            // rfq.purchaseOrderFileName = response.rfqFileName;
                          }
                        }
                      }

                      // LOGIC FOR ACTIONS NEEDED FROM BUYER BLIPS ON NAV
                      for (let heading of this.sortbyHeadings) {

                        for (let rfq of heading.rfq) {
                          heading.openQuotesCounter = 0;

                          if (this.blipCondition(rfq)) {
                            heading.openQuotesCounter = 1;
                            break;
                          }
                        }

                      }
                    },
                    error: error => {
                      this.toastrNotification.error("Get Rfq File Details Failed");
                    }
                  });
                this.togglePeriodicReload(false)
              });
            } else {
              this.rfqService.getRfqFileDetails(this.selectedRFQ.rfqId, "PurchaseOrders").pipe(first())
                .subscribe({
                  next: (response: any) => {
                    this.selectedRFQ.purchaseOrderFileId = response.rfqFileId;
                    this.selectedRFQ.purchaseOrderFileName = response.rfqFileName;

                    this.purchaseOrderUploading = false;
                    this.purchaseOrderDeleting = false;


                    for (let rfqHeading of this.sortbyHeadings) {
                      for (let rfq of rfqHeading.rfq) {
                        if (rfq.rfqId == this.selectedRFQ.rfqId) {
                          rfq.purchaseOrderFileId = response.rfqFileId;
                          // rfq.purchaseOrderFileName = response.rfqFileName;
                        }
                      }
                    }

                    // LOGIC FOR ACTIONS NEEDED FROM BUYER BLIPS ON NAV
                    for (let heading of this.sortbyHeadings) {

                      for (let rfq of heading.rfq) {
                        heading.openQuotesCounter = 0;

                        if (this.blipCondition(rfq)) {
                          heading.openQuotesCounter = 1;
                          break;
                        }
                      }

                    }
                  },
                  error: error => {
                    this.toastrNotification.error("Get Rfq File Details Failed");
                  }
                });
              this.togglePeriodicReload(false)
            }

            this.clearPOInput.nativeElement.value = "";

          });
        };
      } else {
        this.toastrNotification.error("File has previously been uploaded as Delivery Note");
      }
    }

  }

  deliveryNoteSelected(event) {

    this.deliveryNoteUpload = { rfqId: null, quoteId: null, buyerId: null, supplierId: null, userId: null, file: null, fileName: null };

    this.deliveryNoteFile = event.target.files[0];
    const reader = new FileReader();

    if (this.deliveryNoteFile) {

      if (this.deliveryNoteFile.name != this.selectedRFQ.purchaseOrderFileName) {

        this.deliveryNoteUploading = true;

        reader.readAsDataURL(this.deliveryNoteFile);
        reader.onload = () => {

          this.deliveryNoteUpload.file = (reader.result + "").substring((reader.result + "").indexOf(",") + 1, (reader.result + "").length);

          this.deliveryNoteUpload.quoteId = this.selectedRFQ.acceptedQuotes[0].quoteId;
          this.deliveryNoteUpload.rfqId = this.selectedRFQ.rfqId;
          this.deliveryNoteUpload.buyerId = this.account.account.buyerId;
          this.deliveryNoteUpload.userId = this.account.account.userId;
          this.deliveryNoteUpload.supplierId = null;
          this.deliveryNoteUpload.fileName = this.deliveryNoteFile.name;
          this.rfqService.uploadDeliveryNoteFile(this.deliveryNoteUpload).pipe(
            finalize(() => this.deliveryNoteUploading = true)).subscribe(x => {

              if (this.selectedRFQ.deliveryNoteFileId != null) {
                this.deliveryNoteDeleting = true;
                this.rfqService.deleteRfqDrawingFile(this.selectedRFQ.deliveryNoteFileId).pipe(
                  finalize(() => this.deliveryNoteDeleting = true)).subscribe(y => {

                    this.rfqService.getRfqFileDetails(this.selectedRFQ.rfqId, "DeliveryNotes").pipe(first())
                      .subscribe({
                        next: (response: any) => {
                          this.selectedRFQ.deliveryNoteFileId = response.rfqFileId;
                          this.selectedRFQ.deliveryNoteFileName = response.rfqFileName;

                          this.deliveryNoteUploading = false;
                          this.deliveryNoteDeleting = false;

                          // LOGIC FOR ACTIONS NEEDED FROM BUYER BLIPS ON NAV
                          for (let heading of this.sortbyHeadings) {

                            for (let rfq of heading.rfq) {
                              heading.openQuotesCounter = 0;

                              if (this.blipCondition(rfq)) {
                                heading.openQuotesCounter = 1;
                                break;
                              }
                            }

                          }
                        },
                        error: error => {
                          this.toastrNotification.error("Get Rfq File Details Failed");
                        }
                      });

                  });
              } else {
                this.rfqService.getRfqFileDetails(this.selectedRFQ.rfqId, "DeliveryNotes").pipe(first())
                  .subscribe({
                    next: (response: any) => {
                      this.selectedRFQ.deliveryNoteFileId = response.rfqFileId;
                      this.selectedRFQ.deliveryNoteFileName = response.rfqFileName;

                      this.deliveryNoteUploading = false;
                      this.deliveryNoteDeleting = false;

                      // LOGIC FOR ACTIONS NEEDED FROM BUYER BLIPS ON NAV
                      for (let heading of this.sortbyHeadings) {

                        for (let rfq of heading.rfq) {
                          heading.openQuotesCounter = 0;

                          if (this.blipCondition(rfq)) {
                            heading.openQuotesCounter = 1;
                            break;
                          }
                        }

                      }
                    },
                    error: error => {
                      this.toastrNotification.error("Get Rfq File Details Failed");
                    }
                  });
              }
              this.clearDInputVar.nativeElement.value = "";
              this.clearDEInputVar.nativeElement.value = "";
            });
        };
      } else {
        this.toastrNotification.error("File has previously been uploaded as Purchase Order ");
      }
    }
  }
  downloadFile(base64String, fileName) {

    // if(window.navigator && window.navigator.msSaveOrOpenBlob){ 
    //   // download PDF in IE
    //   let byteChar = atob(base64String);
    //   let byteArray = new Array(byteChar.length);
    //   for(let i = 0; i < byteChar.length; i++){
    //     byteArray[i] = byteChar.charCodeAt(i);
    //   }
    //   let uIntArray = new Uint8Array(byteArray);
    //   let blob = new Blob([uIntArray], {type : 'application/pdf'});
    //   window.navigator.msSaveOrOpenBlob(blob, `${fileName}.pdf`);
    // } 

    // Download PDF in Chrome etc.
    const source = `data:application/;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}`
    link.click();
  }

  // RFQ NAV SEARCH FORM
  RFQsearch: FormGroup;
  // RFQ SEARCH FORM
  get rs() { return this.RFQsearch.controls; }

  //  CLEAR RFQ SEARCH FILTER VALUES
  filtersUsed: boolean = false;
  filteredNavRfqs: RfqHeadings[] = [];
  filteredNavRfqsUpdate: RfqHeadings[] = [];
  filterSortbyHeadings: RFQHeaddisplay[] = [];
  filterSortbyHeadingsUpdate: RFQHeaddisplay[] = [];


  filterRfqType: string[] = [];
  filterPaymentOptions: string[] = [];
  filterPackagingOptions: string[] = [];
  filterCollectorDelivery: string[] = [];
  filtertestCertification: boolean[] = [];



  //  FILTER RFQ NAV
  RFQsearchFilter() {

    this.filterRfqType = [];
    this.filterPaymentOptions = [];
    this.filterCollectorDelivery = [];
    this.filterPackagingOptions = [];
    this.filtertestCertification = [];


    this.filteredNavRfqs = [];

    // POPULATES THE FILTER RFQ ARRAY EVERY VALUE IN THE OPEN RFQS ARRAY
    for (let value of this.inProgressRfqs) {
      this.filteredNavRfqs.push(value);
    }

    // IF ANY OF THE FILTER VALUES ARE SET TO TRUE THEN THE FILTER IS USED AND THE HTML DISPLAYS THE filteredNavRfqs ARRAY
    if (this.rs.complete.value || this.rs.rawmat.value || this.rs.cod.value || this.rs.cbd.value || this.rs.thirtyDays.value || this.rs.sixtyDays.value ||
      this.rs.paymentOther.value || this.rs.delivery.value || this.rs.collection.value || this.rs.boxed.value || this.rs.pallets.value ||
      this.rs.strapped.value || this.rs.loose.value || this.rs.packagingOther.value || this.rs.testCertificationYes.value || this.rs.testCertificationNo.value) {

      this.filtersUsed = true;
    }
    else {
      this.filtersUsed = false;

    }

    // BUILD UP TYPE OF SUPPLY ARRAY
    if (this.rs.complete.value == true) {
      this.filterRfqType.push("Complete");
    }

    if (this.rs.rawmat.value == true) {
      this.filterRfqType.push("Raw Material");
    }


    // BUILD UP COLLECT OR DELIVERY ARRAY
    if (this.rs.delivery.value == true) {
      this.filterCollectorDelivery.push("Delivery");

    }

    if (this.rs.collection.value == true) {
      this.filterCollectorDelivery.push("Collection");

    }
    // BUILD UP PAYMENT ARRAY
    if (this.rs.cod.value == true) {
      this.filterPaymentOptions.push("COD");

    }

    if (this.rs.cbd.value == true) {
      this.filterPaymentOptions.push("CBD");

    }

    if (this.rs.thirtyDays.value == true) {
      this.filterPaymentOptions.push("30 Days");

    }


    if (this.rs.sixtyDays.value == true) {
      this.filterPaymentOptions.push("60 Days");

    }


    if (this.rs.paymentOther.value == true) {
      this.filterPaymentOptions.push("Other");

    }

    // BUILD UP PACKAGING OPTION ARRAY
    if (this.rs.boxed.value == true) {
      this.filterPackagingOptions.push("Boxed");

    }

    if (this.rs.pallets.value == true) {
      this.filterPackagingOptions.push("Pallet");

    }

    if (this.rs.strapped.value == true) {
      this.filterPackagingOptions.push("Strapped");

    }

    if (this.rs.loose.value == true) {
      this.filterPackagingOptions.push("Loose");

    }

    if (this.rs.packagingOther.value == true) {
      this.filterPackagingOptions.push("Other");

    }

    // TEST CERTIFICATION ARRAY POPULATE
    if (this.rs.testCertificationYes.value == true) {

      this.filtertestCertification.push(true);
    }

    // TEST CERTIFICATION ARRAY POPULATE
    if (this.rs.testCertificationNo.value == true) {

      this.filtertestCertification.push(true);
    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE TYPE OP SUPPLY OPTIONS
    if (this.filterRfqType.length > 0) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => this.filterRfqType.some(r => rfq.typeOfSupply.includes(r)));

    }


    // FILTERS filterOpenRfq ARRAY FOR ALL THE PAYMENT REQUIREMENT OPTIONS
    if (this.filterPaymentOptions.length > 0) {
      if (this.filteredNavRfqs != null) {
        this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => this.filterPaymentOptions.some(r => rfq.paymentRequirement?.includes(r)));
      }
    }


    // FILTERS filterOpenRfq ARRAY FOR ALL THE DELIVERY REQUIREMENT OPTIONS
    if (this.filterCollectorDelivery.length > 0) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => this.filterCollectorDelivery.some(r => rfq.deliveryRequirements.includes(r)));
    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE PACKAGING REQUIREMENT OPTIONS FIRST CHECKS IF THE PACKAGING REQUIREMENT OPTIONS IS NOT NULL
    if (this.filterPackagingOptions.length > 0) {

      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => rfq.packagingRequirements != null).filter(rfq => this.filterPackagingOptions.some(r => rfq.packagingRequirements.includes(r)));
    }


    // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE OR FALSE
    if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == true) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => rfq.productCertificationRequirements == true || rfq.productCertificationRequirements == false);

    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE
    if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == false) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => rfq.productCertificationRequirements == true);

    }

    // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS FALSE
    if (this.rs.testCertificationNo.value == true && this.rs.testCertificationYes.value == false) {
      this.filteredNavRfqs = this.filteredNavRfqs.filter(rfq => rfq.productCertificationRequirements == false);

    }

    // FILTERS filterOpenRfq ARRAY IF NOT NULL
    if (this.filteredNavRfqs.length > 0) {

      this.filterSortbyHeadings = [];
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

      this.headingId = 1;
      this.singleSortHeadings.rfqHeadName = "Multiple Services";
      this.singleSortHeadings.rfq = [];
      this.filterSortbyHeadings.push(this.singleSortHeadings);
      this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
      this.headingId++;

      this.sortFilterRFQHeadArray();
    }
    else {
      // MAKE MAKE THE ARRAY THAT BUILDS UP THE SIDE NAV NULL
      this.filterSortbyHeadings = [];
    }



  }


  // BUILDS RFQ SIDENAV AFTER A FILTER IS SELECTED
  sortFilterRFQHeadArray() {

    // PUSHES ALL VALUES FROM OPEN RFQS ARRAY INTO THE FILTER RFQS ARRAY

    for (let indexrfq of this.filteredNavRfqs) {
      for (let service of indexrfq.servicesRequired) {
        this.check = false;
        if (indexrfq.servicesRequired.length == 1) {
          for (let name of this.filterSortbyHeadings) {
            if (service == name.rfqHeadName) {
              this.check = true;
            }
          }
          if (this.check == false) {

            this.singleSortHeadings.rfqHeadName = service;
            this.singleSortHeadings.rfq = [];
            this.singleSortHeadings.id = this.headingId;
            this.filterSortbyHeadings.push(this.singleSortHeadings);
            this.headingId++;
            this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
          }
        }
      }

      if (indexrfq.servicesRequired.length > 1) {
        this.filterSortbyHeadings[0].rfq.push(indexrfq);
      }

    }
    this.populateSearchRFQHeadArray();
    this.getClosingTime(Date);


    // BLIIIIP LOGICCCC
    // LOGIC FOR ACTIONS NEEDED FROM BUYER BLIPS ON NAV


    //this.cdr.detectChanges();

  }


  populateSearchRFQHeadArray(): void {
    for (let rfq of this.filteredNavRfqs) {
      for (let service of rfq.servicesRequired) {
        if (rfq.servicesRequired.length == 1) {
          for (let val of this.filterSortbyHeadings) {
            if (val.rfqHeadName == service) {
              val.rfq.push(rfq)

            }
          }
        }

      }
    }
    if (this.filterSortbyHeadings[0].rfq.length == 0) {
      this.filterSortbyHeadings.splice(0, 1);
    }

    for (let heading of this.filterSortbyHeadings) {

      for (let rfq of heading.rfq) {
        heading.openQuotesCounter = 0;

        if (this.blipCondition(rfq)) {
          heading.openQuotesCounter = 1;
          break;
        }

      }

    }
  }

  clearRFQFilter() {

    this.filtersUsed = false;

    this.rs.complete.setValue(false);
    this.rs.rawmat.setValue(false);
    this.rs.cod.setValue(false);
    this.rs.cbd.setValue(false);
    this.rs.thirtyDays.setValue(false);
    this.rs.sixtyDays.setValue(false);
    this.rs.paymentOther.setValue(false);
    this.rs.delivery.setValue(false);
    this.rs.collection.setValue(false);
    this.rs.boxed.setValue(false);
    this.rs.pallets.setValue(false);
    this.rs.strapped.setValue(false);
    this.rs.loose.setValue(false);
    this.rs.packagingOther.setValue(false);
    this.rs.testCertificationYes.setValue(false);
    this.rs.testCertificationNo.setValue(false);
  }


  // FILTER NAV ON PERIODIC RELOAD
  filterUpdateRfqs(array: RfqHeadings[]) {

    this.filteredNavRfqsUpdate = [];

    if (array.length > 0) {

      for (let rfq of array) {
        this.filteredNavRfqsUpdate.push(rfq);
      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE TYPE OP SUPPLY OPTIONS
      if (this.filterRfqType.length > 0) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => this.filterRfqType.some(r => rfq.typeOfSupply.includes(r)));

      }
      // FILTERS filterOpenRfq ARRAY FOR ALL THE PAYMENT REQUIREMENT OPTIONS
      if (this.filterPaymentOptions.length > 0) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => this.filterPaymentOptions.some(r => rfq.paymentRequirement.includes(r)));
      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE DELIVERY REQUIREMENT OPTIONS
      if (this.filterCollectorDelivery.length > 0) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => this.filterCollectorDelivery.some(r => rfq.deliveryRequirements.includes(r)));
      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE PACKAGING REQUIREMENT OPTIONS FIRST CHECKS IF THE PACKAGING REQUIREMENT OPTIONS IS NOT NULL
      if (this.filterPackagingOptions.length > 0) {

        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => rfq.packagingRequirements != null).filter(rfq => this.filterPackagingOptions.some(r => rfq.packagingRequirements.includes(r)));
      }


      // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE OR FALSE
      if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == true) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => rfq.productCertificationRequirements == true || rfq.productCertificationRequirements == false);

      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS TRUE
      if (this.rs.testCertificationYes.value == true && this.rs.testCertificationNo.value == false) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => rfq.productCertificationRequirements == true);

      }

      // FILTERS filterOpenRfq ARRAY FOR ALL THE PRODUCT TEST CERTIFICATION IS FALSE
      if (this.rs.testCertificationNo.value == true && this.rs.testCertificationYes.value == false) {
        this.filteredNavRfqsUpdate = this.filteredNavRfqsUpdate.filter(rfq => rfq.productCertificationRequirements == false);

      }


      // FILTERS filterOpenRfq ARRAY IF NOT NULL
      if (this.filteredNavRfqsUpdate.length > 0) {

        this.filterSortbyHeadingsUpdate = [];
        this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };

        this.headingId = 1;
        this.singleSortHeadings.rfqHeadName = "Multiple Services";
        this.singleSortHeadings.rfq = [];
        this.filterSortbyHeadingsUpdate.push(this.singleSortHeadings);
        this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
        this.headingId++;

        for (let indexrfq of this.filteredNavRfqsUpdate) {
          for (let service of indexrfq.servicesRequired) {
            this.check = false;
            if (indexrfq.servicesRequired.length == 1) {
              for (let name of this.filterSortbyHeadingsUpdate) {
                if (service == name.rfqHeadName) {
                  this.check = true;
                }
              }
              if (this.check == false) {

                this.singleSortHeadings.rfqHeadName = service;
                this.singleSortHeadings.rfq = [];
                this.singleSortHeadings.id = this.headingId;
                this.filterSortbyHeadingsUpdate.push(this.singleSortHeadings);
                this.headingId++;
                this.singleSortHeadings = { rfqHeadName: null, rfq: null, id: null };
              }
            }
          }

          if (indexrfq.servicesRequired.length > 1) {
            this.filterSortbyHeadingsUpdate[0].rfq.push(indexrfq);
          }

        }

        this.getClosingTime(Date);

        // BLIIIIP LOGICCCC
        // LOGIC FOR ACTIONS NEEDED FROM BUYER BLIPS ON NAV


        //this.cdr.detectChanges();

        for (let rfq of this.filteredNavRfqsUpdate) {
          for (let service of rfq.servicesRequired) {
            if (rfq.servicesRequired.length == 1) {
              for (let val of this.filterSortbyHeadingsUpdate) {
                if (val.rfqHeadName == service) {
                  val.rfq.push(rfq)

                }
              }
            }

          }
        }
        if (this.filterSortbyHeadingsUpdate[0].rfq.length == 0) {
          this.filterSortbyHeadingsUpdate.splice(0, 1);
        }

        for (let heading of this.filterSortbyHeadingsUpdate) {

          for (let rfq of heading.rfq) {
            heading.openQuotesCounter = 0;

            if (this.blipCondition(rfq)) {
              heading.openQuotesCounter = 1;
              break;
            }

          }

        }


      }
      else {
        // MAKE MAKE THE ARRAY THAT BUILDS UP THE SIDE NAV NULL
        this.filterSortbyHeadingsUpdate = [];
      }

      this.replaceFilteredRfqsOnUpdate();

    }

  }

  //PUSH OR SPLICE RFQS IF THEY HAVE LEFT THE ARRAY OR IF NEW ONES HAVE COME IN
  replaceFilteredRfqsOnUpdate() {

    var newHeadings: string[] = [];
    var oldHeadings: string[] = [];

    for (let heading of this.filterSortbyHeadings) {
      oldHeadings.push(heading.rfqHeadName)
      for (let newHead of this.filterSortbyHeadingsUpdate) {
        if (heading.rfqHeadName == newHead.rfqHeadName) {
          heading.rfq = newHead.rfq;
          heading.openQuotesCounter = newHead.openQuotesCounter;
        }
      }
    }

    for (let newHeading of this.filterSortbyHeadingsUpdate) {
      newHeadings.push(newHeading.rfqHeadName);
    }

    // if (this.sortbyHeadings.length != this.sortbyHeadingsUpdate.length) {
    //  if (this.sortbyHeadings.length < this.sortbyHeadingsUpdate.length) {

    for (let newHead of this.filterSortbyHeadingsUpdate) {
      if (oldHeadings.indexOf(newHead.rfqHeadName) == -1) {
        this.filterSortbyHeadings.push(newHead);
      }
    }

    // } else {
    for (const { index, value } of this.filterSortbyHeadings.map((value, index) => ({ index, value }))) {
      if (newHeadings.indexOf(value.rfqHeadName) == -1) {
        this.filterSortbyHeadings.splice(index, 1);
      }
    }

    //  }

    // }

  }





  filterOpen: boolean = false;
  filterIconToggle() {

    this.filterOpen = !this.filterOpen;

  }

  togglePrivateChatbadge() {
    this.selectedHeadingRfq.acceptedQuotes[0].unreadPrivateMessages = false;
  }


  //  DATE CALCULATIONS



  //needed for date calculations

  dueDate: any;
  dueHours: any;
  date1: any;
  date2: any;
  roundDate: any;


  getDaydiff(deliveryDate) {

    this.date1 = new Date(this.selectedRFQ.maxLeadTime);
    this.date2 = new Date(this.f.actualDeliveryDate.value);

    let diff = this.date2.getTime() - this.date1.getTime();
    let msInDay = 1000 * 3600 * 24;

    this.dueDate = diff / msInDay;

    this.roundDate = Math.floor(diff / msInDay);

    this.roundDate = Math.abs(this.roundDate);

  }

}
