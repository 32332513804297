<ng-container *ngIf="account">
    <nav class="navbar navbar-light navbar-expand-md sticky-top navigation-clean supplierhovr"
        style="background: rgb(255,255,255);padding-top: 0px;padding-bottom: 0px;box-shadow: 0px 0px 1px;">
        <div class="container"><a class="navbar-brand"><img src="assets/TradelyLogo.png"
                    style="width: 177px;"></a><button data-bs-toggle="collapse" data-bs-target="#navcol-1"
                class="navbar-toggler"><span class="visually-hidden">Toggle navigation</span><span
                    class="navbar-toggler-icon"></span></button>
            <div class="collapse navbar-collapse" id="navcol-1">
                <ul class="navbar-nav me-auto">

                    <app-notifications></app-notifications>

                    <li (click)="navToggleBuyer()" class=" nav-item navitem"><a
                        routerLinkActive = " buyergreennav" 
                        
                            class="nav-link text-nowrap"
                            *ngIf="account.account.roles.includes(Role.Admin) || account.account.roles.includes(Role.Buyer)"
                            routerLink="/buyer-home"><i class="fa fa-shopping-cart" style="margin-right: 5px;"></i>Buyer
                            Home</a></li>


                    <li  (click)="navToggleSupplier()" class="nav-item navitem"><a
                        routerLinkActive = " supplierbluenav"
                            
                            class="nav-link text-nowrap blue"
                            *ngIf="account.account.roles.includes(Role.Admin) || account.account.roles.includes(Role.Supplier)"
                            routerLink="/supplier-home"><i class="fa fa-box-open"
                                style="margin-right: 5px;"></i>Supplier Home</a></li>
                    <!-- <li class="nav-item navitem supplierhovr"><a class="nav-link text-nowrap supplierhovr"
                            *ngIf="account.account.roles.includes(Role.Admin)" routerLink="/admin"
                            routerLinkActive="active"><i class="fa fa-lock" style="margin-right: 5px;"></i>Admin
                            Portal</a></li> -->
                </ul>
            </div>

            <!-- OLD USERNAME AND LOGOUT -->

            <div class="collapse navbar-collapse" id="navcol-1">
                <!-- <ul class="navbar-nav ms-auto">
                    <li class=""><a [class.blou]="this.NavRole == 'Buyer'" class="nav-link text-nowrap" href="/"><i
                                class="fa fa-user" style="margin-right: 5px;"></i>{{account.account.userName}}</a></li>
                    <li class="nav-item d-xxl-flex align-items-xxl-center navitem blue"
                        style="width: -2px;background: #C0C0C0;margin-top: 3px;margin-bottom: 3px;"></li>
                    <li (click)="logout()" class="nav-item navitem blue"><a class="nav-link text-nowrap blue"><svg
                                xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"
                                stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                                stroke-linejoin="round" class="icon icon-tabler icon-tabler-settings"
                                style="margin-right: 5px ;">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path
                                    d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z">
                                </path>
                                <circle cx="12" cy="12" r="3"></circle>
                            </svg>Logout</a></li>
                </ul> -->
                <!-- DROPDOWN USERNAME AND LOGOUT -->


                <div class="navbar ms-auto">
                    <div class="nav-item dropdown" data-bs-auto-close="outside"><a aria-expanded="false"
                            data-bs-toggle="dropdown" class="dropdown-toggle nav-link" href="#"
                            style="color: var(--textgrey);" data-bs-auto-close="outside"><i class="fa fa-user"
                                style="margin-right: 5px;"></i>{{account.account.userName}}</a>
                        <div class="dropdown-menu" style="padding: 0px;" data-bs-auto-close="outside">
                            <div class="row g-0 rfqdetailrow" style="width: 315px;padding-top: 0;height: 0px;">
                                <div class="col shadow-sm rounded mb-2"
                                    style="background: var(--shadowgrey);padding: 1rem;padding-bottom: 0px;padding-top: 16px;">
                                    <div
                                        class="d-flex d-sm-flex d-xxl-flex align-items-center justify-content-sm-start align-items-sm-center align-items-xxl-center">
                                        <div class="col d-flex">
                                            <i class="fas fa-building d-flex d-sm-flex d-xxl-flex align-items-center "
                                                style="padding-bottom: 3px;padding-right: 10px;border-bottom-style: none;color: var(--mainblue);"></i>
                                            <p class="d-xxl-flex align-items-xxl-center bluetext">
                                                {{account.account.company}}</p>
                                        </div>
                                        <div class="col d-xxl-flex justify-content-xxl-end">
                                            <span
                                                class="badge rounded-pill d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-center bluetextthin"
                                                style="max-width:300px; color: var(--white);background: var(--mainblue);border-radius: 150px;text-align: left;padding-left: 5px;padding-right: 5px;">
                                                {{account.account.team}}</span>
                                        </div>
                                    </div>
                                    <div class="d-xxl-flex align-items-xxl-center">
                                        <div class="col">

                                        </div>
                                    </div>
                                    <div class="d-xxl-flex align-items-xxl-center">
                                        <div class="col">
                                            <p class="d-xxl-flex align-items-xxl-center bluetext">
                                                {{account.account.userName}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="d-xxl-flex align-items-xxl-center">
                                        <div class="col d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                            style="padding-top: 5px;padding-bottom: 11px;"><button (click)="logout()"
                                                class="btn btn-secondary invoicebtn" type="button"
                                                style="font-size: 12px;background: var(--mainblue);border-style: none;height: 36px;"><i
                                                    class="fa fa-sign-out"></i>Log Out</button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <!-- subnav router outlet -->
    <!-- <router-outlet name="subnav"></router-outlet> -->

</ng-container>

<!-- global alert -->
<alert></alert>
<toastr-notification></toastr-notification>

<div [@routeAnimation]="getAnimationData(routerOutlet)">
    <router-outlet #routerOutlet="outlet"></router-outlet>
</div>
<!-- <footer class="footer-basic d-flex justify-content-center align-items-center flex-column" *ngIf="account">
    <p class="copyright">Tradely © 2021</p>
</footer> -->

<!-- Auto Logout Modal -->
<button #autoLogoutModal style="display: none;" data-bs-target="#AutoLogout" data-bs-toggle="modal"></button>

<div role="dialog" tabindex="-1" class="modal fade" id="AutoLogout">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header" style="background: var(--maingreen);">
                <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0; color: white">You have
                    been inactive</h1><button type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                {{idleState}}

            </div>
            <div class="modal-footer d-lg-flex align-items-lg-center"><a (click)="autoLogout()"
                    class="btn text-gray-500" role="button" data-bs-dismiss="modal">Logout</a><a (click)="stay()"
                    class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn" role="button"
                    style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Stay</a></div>
        </div>
    </div>
</div>