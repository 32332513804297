import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Account } from '../models/account';
import { Question, Reply } from '../models/question-thread';
import { CreateReply } from '../models/replyModels';
import { RFQ } from '../models/rfq';
import { ViewQuestions, ViewReplies } from '../models/viewQuestionThread';
import { NotificationsService } from '../services/notifications.service';
import { QuestionAndAnswersService } from '../services/question-and-answers.service';
import { ToastrNotificationService } from '../services/toastr-notification.service';

@Component({
  selector: 'app-qa-reply',
  templateUrl: './qa-reply.component.html',
  styleUrls: ['./qa-reply.component.css']
})
export class QaReplyComponent implements OnInit {

  //NEEDED FOR CREATING Q&A QUESTIONS AND REPLIES
  @Input() isBuyer: boolean;
  @Input() isSupplier: boolean;

  @Input() rfq: RFQ;
  @Input() question: ViewQuestions;
  form: FormGroup;

  newReply: CreateReply = { ReplyContent: null, QuestionId: null, CreatedBy: null, isBuyer: null, isSupplier: null };
  showReply: string = "";
  replyCount: number = 5;

  @Output() reloadQA = new EventEmitter<ViewReplies>();

  sendReplyTrigger(value: ViewReplies) {
    this.reloadQA.emit(value);
  }

  newReplyReturn: ViewReplies = {
    replyId: null, replyContent: null, questionId: null, createdAt: null, createdBy: null, canDeleteReply: null, isBuyer: null, isSupplier: null, buyerSeen: null, supplierSeen: null
  };

  account: Account;

  constructor(private formBuilder: FormBuilder,
    private questionAndAnswersService: QuestionAndAnswersService,
    public toastrNotification: ToastrNotificationService,
    public notificationService: NotificationsService,
    public authService: AuthService,) {

    this.authService.account.subscribe(x => this.account = x);
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({

      reply: ['', Validators.required]

    });
  }

  get f() { return this.form.controls; }

  replySubmit(qId) {
    if (this.f.reply.value != false && this.f.reply.value.replace(/ /g, "") != "" && this.f.reply.value != null && this.f.reply.value.length < 100) {

      this.newReply = { ReplyContent: null, QuestionId: null, CreatedBy: null, isBuyer: null, isSupplier: null };
      
      this.newReply.QuestionId = qId;
      this.newReply.ReplyContent = this.f.reply.value;
      this.newReply.CreatedBy = this.account.account.userId;
      this.newReply.isBuyer = this.isBuyer;
      this.newReply.isSupplier = this.isSupplier;


      this.createReply(this.newReply);


      this.f.reply.setValue(null);
      this.showReply = "show";

      // this.notificationService.addNotification(this.rfq.rfqName, this.rfq.buyerId, 1, 'question','A reply was asked on this thread');
    } else {
      if(this.f.reply.value.replace(/ /g, "") == ""){
        this.toastrNotification.error('Cannot Send Blank Reply');
      }else if(this.f.reply.value.length > 100){
        this.toastrNotification.error('Reply Exceeds 100 Characters');
      }
      
    }

    // this.sendReplyTrigger(this.showReply);
    this.replyCount++;
  }

  replySending: boolean = false;

  createReply(reply) {
    //async
    this.newReplyReturn = { replyId: null, replyContent: null, questionId: null, createdAt: null, createdBy: null, canDeleteReply: null, isBuyer: null, isSupplier: null, buyerSeen: null, supplierSeen: null };

    this.replySending = true;

    this.f.reply.disable();

    this.questionAndAnswersService.addReply(reply).pipe(first())
      .subscribe({
        next: (response: any) => {
          this.newReplyReturn = response;
          this.sendReplyTrigger(response);
          this.replySending = false;
          this.f.reply.enable();
        },
        error: error => {
          this.toastrNotification.error("Reply Submission Failed");
          this.replySending = false;
          this.f.reply.enable();
        }
      });
  }

  public showErrorToastrNotification(message) {
    this.toastrNotification.error(message);
  }
}
