import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, first } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Account } from '../models/account';
import { QuoteSubmitted } from '../models/quote';
import { ReloadViewRfqs } from '../models/reloadViewRfqs';
import { RFQ } from '../models/rfq';
import { RfqService } from '../services/rfq.service';
import { ToastrNotificationService } from '../services/toastr-notification.service';


import { ViewQuoteServiceService } from '../services/view-quote-service.service';

@Component({
  selector: 'app-browse-rfq',
  templateUrl: './browse-rfq.component.html',
  styleUrls: ['./browse-rfq.component.css']
})
export class BrowseRfqComponent implements OnInit {

  @Input() rfq: RFQ;
  @Output() RfqExpiredEvent = new EventEmitter<number>();

  @ViewChild('closeDrawingsMdl') closeDrawingsMdl: ElementRef;

  //NEEDED FOR CREATING Q&A QUESTIONS AND REPLIES ACTS AS SUPPLIER
  isBuyer: boolean = false;
  isSupplier: boolean = true;
  supplierId: number;
  newQuoteId: number;

  //NEEDED FOR GET QUESTIONS AND ANSWERS
  getQA: boolean = false;
  rfqUsed: boolean = false;

  // NEEDED FOR MODAL HEADER COLOR
  modalheader: string;

  isCollapsed: boolean = true;
  selectedRFQ?: RFQ;

  editQuoteShow = false;
  submitQuoteShow = true;

  // needed for date calcs
  public dateNow = new Date();
  public dDay?: Date;
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  public mtimeDifference;
  public msecondsToDday;
  public mminutesToDday;
  public mhoursToDday;
  public mdaysToDday;

  @ViewChild('openSubmitQuoteModal') openSubmitQuoteModal: ElementRef;

  account: Account;

  constructor(private rfqService: RfqService, private reloader: ViewQuoteServiceService, public toastrNotification: ToastrNotificationService,
    private router: Router, public authService: AuthService,) {
    this.authService.account.subscribe(x => {
      this.account = x
      this.supplierId = this.account.account.supplierId;
    });
  }

  ngOnInit() {

    this.modalheader = 'blue';
  }

  setQuoteId(value) {
    this.newQuoteId = value;
  }

  starringRfq: boolean = false;

  starRfq(rfqId: number) {

    this.starringRfq = true;
    this.rfqService.starRfqs(rfqId, this.account.account.userId).pipe(
      finalize(() => this.starringRfq = false)
    ).subscribe(rfqs => {
      if (this.rfq.starred) {
        this.toastrNotification.success("Rfq Removed From Favourites");
        this.rfq.starred = false;
      } else {
        this.toastrNotification.success("Rfq Added To Favourites");
        this.rfq.starred = true;
      }

    });
  }

  goToQuote: ReloadViewRfqs = { reloadCondition: null, reloadRfqId: null };

  navToNewQuote() {

    // this.goToQuote = { reloadCondition: null, reloadRfqId: null };
    // this.goToQuote.reloadCondition = true;
    // this.goToQuote.reloadRfqId = rfqId;
    // this.reloader.changeNavToNewQuote(this.goToQuote);

    this.router.navigate(['supplier-home/view-quote', 'Open', this.newQuoteId, 'true']);

  }

  submitQuoteModalTrigger(cond) {
    this.openSubmitQuoteModal.nativeElement.click();
  }

  // CONDITIONS FOR IF A RFQ IS BEING USED AND WHEN TO GET THE QA DATA METHOD IS BEING CALLED WHEN PUBLIC CHAT IS OPENED
  togglePeriodicReload(cond) {
    if (cond) {
      this.rfqUsed = true;
      this.getQA = true;
    } else if (!cond) {
      this.rfqUsed = false;
      this.getQA = false;
    }

  }

  toggleEditButton() {
    if (this.editQuoteShow == false) {
      return 'hidebutton';
    }
  }

  toggleSubmitButton() {
    if (this.submitQuoteShow == false) {
      return 'hidebutton';
    }
  }

  private getTimeDifference() {
    this.timeDifference = this.dDay.getTime() - new Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
  }

  public getClosingTime(date) {
    this.dDay = new Date(date);
    this.getTimeDifference();
    if (this.daysToDday < 0) {
      return 'Closed';
    } else {
      return this.daysToDday + ' d ' + this.hoursToDday + ' hrs';
    }

  }


  private allocateTimeUnits(timeDifference) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }



  getMaxLeadTime(deliveryDate, closingDate) {
    this.getDateDifference(new Date(deliveryDate), new Date(closingDate));
    if (this.mdaysToDday < 0) {
      return 'Closed';
    } else {
      return this.mdaysToDday + ' Days ';
    }
  }

  getDateDifference(deliveryDate, closingDate) {
    var maxLeadDif = deliveryDate.getTime() - closingDate.getTime();
    this.allocateMaxLeadTimeUnits(maxLeadDif);
  }

  private allocateMaxLeadTimeUnits(timeDifference) {
    this.msecondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.mminutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.mhoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.mdaysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }



  onSelectRFQ(rfq: RFQ): void {
    this.selectedRFQ = rfq;
  }

  // TOGGLE DETAILS BUTTON
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }


  fileDownload(rfqFileId) {

    // this.rfqService.getRfqDrawingFile(rfqFileId).subscribe(x => {
    //   this.downloadFile(x.fileContent, x.fileName)
    // })

    this.rfqService.getRfqDrawingFile(rfqFileId).subscribe({
      // console.log(x);
      next: (response: any) => {
        this.downloadFile(response.fileContent, response.fileName)
      },
      error: error => {
        this.rfqService.getSingleSearchRfq(this.rfq.rfqId, this.account.account.userId, this.account.account.supplierId).pipe(first())
          .subscribe({
            next: (response: any) => {
              if (response != null) {
                this.toastrNotification.info("Rfq File is out dated, updated Rfq is being loaded")
                this.rfq = response;
              } else {
                this.toastrNotification.info("Rfq Has Expired")
                this.closeDrawingsMdl.nativeElement.click();
                this.RfqExpiredEvent.emit(this.rfq.rfqId);
              }
            },
            error: error => {
              this.toastrNotification.error("Get Search Rfq Failed");
            }
          })

      }


    })

  }


  downloadFile(base64String, fileName) {
    // Download PDF in Chrome etc.
    const source = `data:application/;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}`
    link.click();
  }

}






