import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ReloadViewRfqs } from '../models/reloadViewRfqs';
import { ViewRfq } from '../models/viewRfq';
import { RfqService } from '../services/rfq.service';
import { ViewRfqServiceService } from '../services/view-rfq-service.service';

@Component({
  selector: 'app-buyer-home',
  templateUrl: './buyer-home.component.html',
  styleUrls: ['./buyer-home.component.css']
})
export class BuyerHomeComponent implements OnInit {


  rfq: ViewRfq = null;

  //put this in the account module
  account = this.authService.accountValue;
  currentYear: number;
  viewRfqNormalLoad: ReloadViewRfqs = { reloadCondition: null, reloadRfqId: null };

  constructor( private rfqService: RfqService, public authService: AuthService, private reloader: ViewRfqServiceService,) {

    this.currentYear = new Date().getFullYear();

  }

  ngOnInit(): void {



  }

  OpenRFQModal() {
    const rfqModal = document.getElementById("createfqmodal");
    rfqModal.setAttribute('style', "display:block;");
  }

  loadRfqsNormal() {

    this.viewRfqNormalLoad = { reloadCondition: null, reloadRfqId: null };
    this.viewRfqNormalLoad.reloadCondition = null;
    this.viewRfqNormalLoad.reloadRfqId = null;
    this.reloader.changeNavNewRfq(this.viewRfqNormalLoad);
    this.reloader.changeReloadInProgressRfqs(this.viewRfqNormalLoad);
    this.reloader.changeReloadClosedRfqs(this.viewRfqNormalLoad);
  }

  
}
