import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Account } from '../models/account';
import { Router, ActivatedRoute } from '@angular/router';
import { Role } from 'src/app/models/role';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  account: Account;

  constructor(
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
    ) { 
    this.authService.account.subscribe(x => this.account = x);
  }

  ngOnInit(): void {
      //  get return url from query parameters or default to home page
      if (this.account.account.roles.includes(Role.Buyer) || this.account.account.roles.includes(Role.Admin)) {
        const returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'buyer-home';
        this.router.navigateByUrl(returnUrl);
    } else if (this.account.account.roles.includes(Role.Supplier)) {
        const returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'supplier-home';
        this.router.navigateByUrl(returnUrl);
    }
  }

}
