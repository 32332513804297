<div class="supplierbg" style="padding-top: 20px;">
    <div>
        <ul class="nav nav-tabs d-flex d-sm-flex d-xxl-flex justify-content-center justify-content-sm-center justify-content-xxl-center"
            role="tablist">
            <li class="nav-item" role="presentation"><a class="nav-link {{openTab}}  bluetab" role="tab"
                    data-bs-toggle="tab" href="#tab-1" [routerLink] = "['/supplier-home/view-quote','Open',openQuoteId,'false']" >Open Quotes</a></li>
            <li class="nav-item" role="presentation"><a class="nav-link  {{inProgressTab}} bluetab" role="tab"
                    data-bs-toggle="tab" href="#tab-2" #tab2 [routerLink] = "['/supplier-home/view-quote','InProgress',inProgressQuoteId,'false']" >In Progress
                    Quotes</a></li>
            <li class="nav-item" role="presentation"><a class="nav-link  {{closedTab}} bluetab" role="tab"
                    data-bs-toggle="tab" href="#tab-3" #tab3 [routerLink] = "['/supplier-home/view-quote','Closed',closedQuoteId,'false']" [queryParams] = "{archived: closedQuoteArchived}">Completed
                    Quotes</a></li>
            <li class="nav-item" role="presentation"><a class="nav-link  {{expireTab}} bluetab" role="tab"
                    data-bs-toggle="tab" href="#tab-4" #tab4 [routerLink] = "['/supplier-home/view-quote','Expired',expiredQuoteId,'false']" >Expired Quotes</a>
            </li>
        </ul>
    </div>

    <div class="tab-content">

        <div class="tab-pane {{openTab}}" role="tabpanel" id="tab-1">
            <app-open-quotes #OpenTab [reloadOpenQuoteTab]="reloadOpenQuote" [openQuoteId]="openQuoteId" [state]="state"  [openQuoteNav]="openQuoteNav" ></app-open-quotes>
        </div>

        <div class="tab-pane {{inProgressTab}}" role="tabpanel" id="tab-2">
            <app-in-progress-quotes #IPTab [Role]="Role" [openChat]="openChat" (openChatCond)="triggerChatOpen($event)"
                (quoteEvent)="triggerQid($event)" (changeClosedTab)="changeClosedTab($event) "
                (quoteStatus)="quoteStatusSend($event)" (changeInProgressTab)="changeInProgressTab($event) "
                [reloadInProgressQuoteTab]="reloadInProgressQuotes" [inProgressQuoteId]="inProgressQuoteId" [state]="state" [inProgressQuoteNav]="inProgressQuoteNav">
            </app-in-progress-quotes>
        </div>

        <div class="tab-pane {{closedTab}}" role="tabpanel" id="tab-3">
            <app-completed-quotes #ClosedTab [openChat]="openChat" (openChatCond)="triggerChatOpen($event)"
                (quoteStatus)="quoteStatusSend($event)" (rfqState)="rfqStateSend($event)" (quoteState)="quoteStateSend($event)"
                (rfqPaymentRequirement)="rfqPaymentRequirementSend($event)" (quoteEvent)="triggerQid($event)"
                (changeClosedTab)="changeClosedTab($event) " [reloadClosedQuoteTab]="reloadClosedQuotes"
                [closedQuoteId]="closedQuoteId" [state]="state" [closedQuoteNav]="closedQuoteNav"
                 [closedQuoteArchivedState]="closedQuoteArchivedState" (quoteArchivedState)="setQuoteArchivedState($event)">
            </app-completed-quotes>
        </div>

        <div class="tab-pane {{expireTab}}" role="tabpanel" id="tab-4">
            <app-expired-quotes #ExpiredTab [reloadExpiredQuoteTab]="reloadExpiredQuotes"  (changeExpiredTab)="changeExpiredTab($event) "
            [expiredQuoteId]="expiredQuoteId" [state]="state" [expiredQuoteNav]="expiredQuoteNav"></app-expired-quotes>
        </div>

    </div>

</div>

<div *ngIf="openChat">
    <app-chat [Buyer]="Buyer" [Role]="Role" [Supplier]="Supplier" [sentQID]="sentQID" [openChat]="openChat"
        [rfqStatus]="quoteStatus" (openChatCond)="triggerChatOpen($event)">
    </app-chat>
</div>