  
  <div *ngIf="alerts.length" class=" container" aria-live="assertive" aria-atomic="true">
    <div class="m-3">
        <div *ngFor="let alert of alerts" class="{{cssClasses(alert)}}">
            <a class="close" (click)="removeAlert(alert)">&times;</a>
            <span [innerHTML]="alert.message"></span>
        </div>
    </div>
</div>   

