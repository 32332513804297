<ng-container *ngIf="buyerCompanyDetais  && !dataLoading">
    <main class="main" style="padding-top: 0px; padding-bottom: 10px;">
        <div class="container-fluid d-flex d-xl-flex justify-content-center align-items-center justify-content-sm-center justify-content-xl-center align-items-xl-center greencont"
            style="height: 51px;">
            <h1 class="text-center" style="font-size: 20px;">Company Profile</h1>
        </div>
        <div class="container" style="max-width: 1500px;">
            <div class="row" style="padding-top: 1rem;margin-bottom: 50px;">
                <div class="col-xxl-8 offset-xxl-1">
                    <div class="uirow bg-white rounded shadow-sm">
                        <div class="row g-0" style="margin-bottom: 0.5rem;">
                            <div class="col d-flex d-xxl-flex align-items-center align-items-xxl-center"
                                style="padding: 0.5rem;padding-bottom: 0px;background: var(--shadowgrey);"><i
                                    class="fa fa-user-circle" style="font-size: 38px;color: var(--yellow);"></i>
                                <h1 class="text-nowrap text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel"
                                    style="font-size: 18px;margin-bottom: 0;">Buyer Details</h1>

                                <button data-bs-toggle="modal" data-bs-target="#buyerdetailsEdit"
                                    class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                    role="button"  [disabled]="account.account.roles[0] != 'Admin' "
                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);">
                                    <i class="fa fa-edit" style="font-size: 14px;color: var(--maingreen);"></i> Edit</button>

                                <button class="btn accordion-button" data-bs-toggle="collapse"
                                    data-bs-target=".buyerdetails" type="button" aria-expanded="true" role></button>
                            </div>
                        </div>
                        <div class="row g-0 buyerdetails collapse show"
                            style="padding: 0.5rem;padding-top: 0;margin-bottom: 0.5rem;" aria-expanded="true">
                            <div class="col-xxl-12 uidetailblock">
                                <div>
                                    <div class="row row-cols-3 d-flex d-xxl-flex justify-content-start justify-content-xl-start justify-content-xxl-start rfqdetailrow"
                                        style="max-width: 1099px;">
                                        <div
                                            class="col-xxl-4 d-flex flex-column justify-content-center align-items-lg-start justify-content-xxl-center">
                                            <div style="padding-bottom: 0.5rem;">
                                                <p
                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                    Company Name</p>
                                                <p
                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluehead">
                                                    {{buyerCompanyDetais.name}} <br /></p>
                                            </div>
                                        </div>
                                        <div
                                            class="col-xxl-4 d-flex flex-column justify-content-center align-items-lg-start justify-content-xxl-center">
                                            <img [src]="companyLogo" style="width: 99px;" />
                                        </div>
                                    </div>
                                    <div class="row row-cols-3 d-flex d-xxl-flex justify-content-center justify-content-xxl-start align-items-xxl-end rfqdetailrow"
                                        style="max-width: 1099px;" style="max-width: 1099px;">
                                        <div
                                            class="col-xxl-4 d-flex flex-column justify-content-center align-items-lg-start justify-content-xxl-center">

                                            <div style="padding-bottom: 0.5rem;">
                                                <p
                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                    Enterprise Size</p>
                                                <p
                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                    {{buyerCompanyDetais.companySize}}</p>
                                            </div>
                                            <div style="padding-bottom: 0.5rem;">
                                                <p
                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                    Number of Employees</p>
                                                <p
                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                    {{buyerCompanyDetais.numberOfEmployees}} </p>
                                            </div>
                                            <div style="padding-bottom: 0.5rem;">
                                                <p
                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                    Holding Company</p>
                                                <p
                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                    {{buyerCompanyDetais.holdingCompany}}</p>
                                            </div>
                                            <div style="padding-bottom: 0.5rem;">
                                                <p
                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                    Date of Commencement</p>
                                                <p
                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                    {{buyerCompanyDetais.dateOfBusinessCommencement |
                                                    date:'mediumDate'}}</p>
                                            </div>
                                        </div>
                                        <div
                                            class="col-xxl-3 d-flex flex-column justify-content-center align-items-lg-start justify-content-xxl-center">
                                            <div style="padding-bottom: 0.5rem;">
                                                <p
                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                    Rating</p>
                                                <p
                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                    <i class="fa fa-star"
                                                        style="font-size: 15px;color: var(--yellow);"></i>


                                                    <ng-container *ngIf="buyerCompanyDetais.rating != null">
                                                        {{buyerCompanyDetais.rating | number :'1.0-1'}}
                                                    </ng-container>
                                                    <ng-container *ngIf="buyerCompanyDetais.rating == null">
                                                        --
                                                    </ng-container>
                                                </p>
                                            </div>
                                            <div style="padding-bottom: 0.5rem;">
                                                <p
                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                    Registration Number</p>
                                                <p
                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                    {{buyerCompanyDetais.companyRegistrationNumber}}</p>
                                            </div>
                                            <div style="padding-bottom: 0.5rem;">
                                                <p
                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                    VAT Number</p>
                                                <p
                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                    {{buyerCompanyDetais.vatNumber}}</p>
                                            </div>
                                            <div style="padding-bottom: 0.5rem;">
                                                <p
                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                    Credit Garuntee Status</p>
                                                <p
                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                    {{buyerCompanyDetais.creditGuaranteeStatus}}</p>
                                            </div>
                                            <!-- <div style="padding-bottom: 0.5rem;">
                                            <p
                                                class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                Date of Commencement</p>
                                            <p
                                                class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                123456ewedfg</p>
                                        </div> -->
                                        </div>
                                        <div
                                            class="col-xxl-4 d-flex flex-column justify-content-end align-items-start align-items-lg-start align-items-xxl-end">
                                            <div style="padding-bottom: 0.5rem;">
                                                <p
                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetext">
                                                    Description</p>
                                                <p
                                                    class="d-sm-flex justify-content-sm-start align-items-xxl-center bluetextthin">
                                                    {{buyerCompanyDetais.companyDescription}}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="uirow bg-white rounded shadow-sm">
                        <div class="row g-0 rfqinner" style="padding: 0ox;padding-top: 0;margin-bottom: 0.5rem;">
                            <div class="col">
                                <div>
                                    <ul role="tablist"
                                        class="nav nav-tabs d-flex d-sm-flex d-xxl-flex justify-content-start justify-content-sm-start justify-content-xxl-start">
                                        <li role="presentation" class="nav-item"><a #BTab1 (click) = "changeTab(1)" role="tab" data-bs-toggle="tab"
                                                class="nav-link {{userTab}}  greentab" href="#tab-1"
                                                style="color: var(--darkblue);">Users</a></li>
                                        <li role="presentation" class="nav-item"><a BTab2 (click) = "changeTab(2)" role="tab" data-bs-toggle="tab"
                                                class="nav-link {{requirementsTab}} greentab" href="#tab-2"
                                                style="color: var(--darkblue);">RFQ
                                                Requirements</a></li>
                                        <li role="presentation" class="nav-item"><a BTab3 (click) = "changeTab(3)"  role="tab" data-bs-toggle="tab"
                                                class="nav-link {{preferredSupplierTab}} greentab" href="#tab-3"
                                                style="color: var(--darkblue);">Preferred Suppliers</a></li>
                                        <li class="nav-item" role="presentation"><a BTab4 (click) = "changeTab(4)"  class="nav-link {{creditApplicationTab}}  greentab" role="tab"
                                                data-bs-toggle="tab" href="#tab-4" style="color: var(--darkblue);">Credit Applications</a></li>
                                                
                                    </ul>
                                    <div class="tab-content">
                                        <div role="tabpanel" class="tab-pane {{userTab}}" id="tab-1">
                                            <h1 class="text-center d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center"
                                                style="font-size: 20px;color: var(--white);background: var(--maingreen);padding-top: 0.5rem;padding-bottom: 0.5rem;">
                                                User Management</h1>
                                            <div style="padding: 1rem;padding-top: 0px;">
                                                <div
                                                    class="d-flex d-sm-flex d-md-flex d-xxl-flex align-items-center justify-content-sm-start align-items-sm-center justify-content-md-start justify-content-xxl-start align-items-xxl-center">
                                                    <div class="input-group tablesearch"><input type="text"
                                                            [(ngModel)]="searchUsers"
                                                            (ngModelChange)="searchUserChange()"
                                                            class="bg-light form-control border-0 small"
                                                            placeholder="Search for ..." /><button
                                                            class="btn btn-primary py-0" type="button"><i
                                                                class="fas fa-search"></i></button></div>
                                                    <div class="d-md-flex align-items-md-center" id="modal-open"></div>
                                                    <button data-bs-toggle="modal" data-bs-target="#addnewuser"
                                                        class="btn btn-secondary d-flex d-xxl-flex justify-content-around align-items-center justify-content-xxl-center align-items-xxl-center invoicebtn"
                                                        role="button" [disabled]="account.account.roles[0] != 'Admin' "
                                                        style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                            class="fa fa-plus-circle"
                                                            style="font-size: 14px;color: var(--maingreen);"></i> Add
                                                        User</button>
                                                </div>

                                                <!-- user display with pagination, no search -->
                                                <ng-container *ngIf="stringValidation(searchUserCheck)">
                                                    <div class="row">
                                                        <div class="col-xxl-12">
                                                            <section>
                                                                <div class="table-responsive">
                                                                    <table class="table table-sm my-0 mydatatable">
                                                                        <thead class="buyerimage">
                                                                            <tr class="text-start">
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">NAME
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">EMAIL
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">ROLE
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">TEAM
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">
                                                                                    NOTIFICATION
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">STATUS
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">EDIT
                                                                                </th>
                                                                                <th class="blueheadsmall"></th>
                                                                                <th class="blueheadsmall"></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>

                                                                            <ng-container
                                                                                *ngIf="buyerCompanyDetais.buyerUsers.length > 0">
                                                                                <ng-container
                                                                                    *ngFor="let user of pageOfItems ; let i = index">
                                                                                    <tr class="tablerow"
                                                                                        style="height: 0px;">
                                                                                        <td class="text-center bluetextthin"
                                                                                            style="padding-top: 9px;">
                                                                                            {{user.firstName}}
                                                                                            {{user.lastName}} </td>
                                                                                        <td class="text-center bluetextthin"
                                                                                            style="padding-top: 9px;">
                                                                                            {{user.userName}}
                                                                                        </td>
                                                                                        <td class="text-nowrap text-center bluetextthin"
                                                                                            style="padding-top: 9px;">
                                                                                            {{user.role}}</td>
                                                                                        <td class="text-nowrap text-center bluetextthin"
                                                                                            style="padding-top: 9px;">
                                                                                            {{user.team}}</td>
                                                                                        <td class="text-center"
                                                                                            style="padding-top: 9px;">
                                                                                            <div
                                                                                                class="d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center">
                                                                                                <div class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start"
                                                                                                    style="padding-right: 2px;">
                                                                                                    <ng-container
                                                                                                        *ngIf="!user.emailPreference">
                                                                                                        <i class="fa fa-minus-circle d-xxl-flex justify-content-xxl-center"
                                                                                                            style="/*border-color: var(--maingreen);*/color: var(--bs-gray);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                    </ng-container>
                                                                                                    <ng-container
                                                                                                        *ngIf="user.emailPreference">
                                                                                                        <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                    </ng-container>
                                                                                                    <p
                                                                                                        class="bluetextthin">
                                                                                                        Email</p>
                                                                                                </div>
                                                                                                <!-- <div
                                                                                                    class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start">
                                                                                                    <ng-container
                                                                                                        *ngIf="!user.smsPreference">
                                                                                                        <i class="fa fa-minus-circle d-xxl-flex justify-content-xxl-center"
                                                                                                            style="/*border-color: var(--maingreen);*/color: var(--bs-gray);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                    </ng-container>
                                                                                                    <ng-container
                                                                                                        *ngIf="user.smsPreference">
                                                                                                        <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                    </ng-container>
                                                                                                    <p
                                                                                                        class="bluetextthin">
                                                                                                        SMS</p>
                                                                                                </div> -->
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="text-center bluetextthin"
                                                                                            style="text-align: center;padding-top: 9px;">
                                                                                            <div *ngIf="user.emailConfirmed"
                                                                                                class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start">
                                                                                                <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                    style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                <p class="bluetextthin">
                                                                                                    Active</p>
                                                                                            </div>
                                                                                            <div *ngIf="!user.emailConfirmed"
                                                                                                class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start">
                                                                                                <i class="fa fa-minus-circle d-xxl-flex justify-content-xxl-center"
                                                                                                    style="/*border-color: var(--maingreen);*/color: var(--bs-gray);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                <p class="bluetextthin">
                                                                                                    Pending</p>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td style="text-align: center;">
                                                                                            <button
                                                                                                data-bs-toggle="modal"
                                                                                                (click)="userModalPosition(i)"
                                                                                                [attr.data-bs-target]="'#editUser' + i "
                                                                                                class="btn btn-secondary invoicebtn"
                                                                                                role="button"
                                                                                                [disabled]="account.account.roles[0] != 'Admin' "
                                                                                                style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                                                    class="fa fa-external-link"
                                                                                                    style="font-size: 14px;color: var(--maingreen);"></i></button>

                                                                                            <button
                                                                                                style="display: none;"
                                                                                                #CloseEditUserModal
                                                                                                data-bs-toggle="modal"
                                                                                                [attr.data-bs-target]="'#editUser' + userModalPos"></button>

                                                                                            <button
                                                                                                style="display: none;"
                                                                                                #DeleteUserModal
                                                                                                data-bs-toggle="modal"
                                                                                                [attr.data-bs-target]="'#deleteUser' + userModalPos"></button>
                                                                                        </td>
                                                                                    </tr>

                                                                                    <!-- EDIT USER -->

                                                                                    <div role="dialog" tabindex="-1"
                                                                                        class="modal fade"
                                                                                        data-bs-backdrop="static"
                                                                                        [attr.id]="'editUser' + i">
                                                                                        <div class="modal-dialog modal-dialog-centered"
                                                                                            role="document">
                                                                                            <div class="modal-content">

                                                                                                <app-edit-user
                                                                                                    [editorUsername] = "account.account.userName"
                                                                                                    [profileType]="'Buyer'"
                                                                                                    [greenformheader]="greenformheader"
                                                                                                    [blueformheader]="blueformheader"
                                                                                                    (closeEditUserModalCond)="EditUserModalTrigger($event)"
                                                                                                    (openDeleteUserModal)="openDeleteUserModal($event)"
                                                                                                    [currentUser]="user">
                                                                                                </app-edit-user>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <!-- DELETE USER MODAL -->
                                                                                    <div role="dialog" tabindex="-1"
                                                                                        class="modal fade "
                                                                                        data-bs-backdrop="static"
                                                                                        [attr.id]="'deleteUser' + i">
                                                                                        <div class="modal-dialog modal-dialog-centered"
                                                                                            role="document">
                                                                                            <div class="modal-content">
                                                                                                <div class="modal-header"
                                                                                                    style="background: var(--maingreen);">
                                                                                                    <h1 class="d-xl-flex align-items-xl-center whitehead"
                                                                                                        style="font-size: 16px;margin-bottom: 0;">
                                                                                                        Delete User

                                                                                                    </h1>

                                                                                                </div>
                                                                                                <div class="modal-body">
                                                                                                    <div>
                                                                                                        <div
                                                                                                            class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                                                                                            <div class="col"
                                                                                                                style="padding-left: 0;">
                                                                                                                <p class="d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-center justify-content-md-center justify-content-lg-center blueheadsmall"
                                                                                                                    style="margin-bottom: 0;padding-left: 32px;">

                                                                                                                    Confirm
                                                                                                                    that
                                                                                                                    User
                                                                                                                    {{user.firstName}}
                                                                                                                    {{user.lastName}}
                                                                                                                    Is
                                                                                                                    to
                                                                                                                    be
                                                                                                                    Removed?
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div
                                                                                                    class="modal-footer d-lg-flex align-items-lg-center">

                                                                                                    <a #cancelRfqDismissBtn
                                                                                                        class="btn text-gray-500"
                                                                                                        role="button"
                                                                                                        data-bs-dismiss="modal">Close</a>

                                                                                                    <button
                                                                                                        (click)="deleteUser(user)"
                                                                                                        [disabled]="deletingUser"
                                                                                                        class="btn d-sm-flex d-lg-flex justify-content-sm-center align-items-sm-center justify-content-lg-center align-items-lg-center yellowbtn"
                                                                                                        role="button"
                                                                                                        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Confirm
                                                                                                        <span
                                                                                                            *ngIf="deletingUser"
                                                                                                            class="spinner-border spinner-border-sm mr-1"></span>
                                                                                                    </button>

                                                                                                    <a #cancelRfqBtn
                                                                                                        data-bs-dismiss="modal"
                                                                                                        class="btn d-sm-flex d-lg-flex justify-content-sm-center align-items-sm-center justify-content-lg-center align-items-lg-center yellowbtn"
                                                                                                        role="button"
                                                                                                        style="display: none !important;border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Confirm
                                                                                                        Hidden</a>


                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </ng-container>
                                                                            </ng-container>
                                                                            <!-- <tr class="tablerow" style="height: 0px;">
                                                                        <td class="text-center bluetextthin"
                                                                            style="padding-top: 9px;">Askuus1</td>
                                                                        <td class="text-center bluetextthin"
                                                                            style="padding-top: 9px;">1dgd@gmail.com
                                                                        </td>
                                                                        <td class="text-center bluetextthin"
                                                                            style="padding-top: 9px;">Buyer</td>
                                                                        <td class="text-center bluetextthin"
                                                                            style="padding-top: 9px;">Team Name</td>
                                                                        <td class="text-center"
                                                                            style="padding-top: 9px;">
                                                                            <div
                                                                                class="d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center">
                                                                                <div
                                                                                    class="form-check form-check-inline">
                                                                                    <input type="checkbox"
                                                                                        class="form-check-input"
                                                                                        id="formCheck-8" /><label
                                                                                        class="form-check-label radio bluetext thin"
                                                                                        for="formCheck-1">Email</label>
                                                                                </div>
                                                                                <div
                                                                                    class="form-check form-check-inline">
                                                                                    <input type="checkbox"
                                                                                        class="form-check-input"
                                                                                        id="formCheck-9" /><label
                                                                                        class="form-check-label radio bluetext thin"
                                                                                        for="formCheck-1">SMS</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td class="text-center bluetextthin"
                                                                            style="text-align: center;padding-top: 9px;">
                                                                            <div
                                                                                class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start">
                                                                                <i class="fa fa-minus-circle d-xxl-flex justify-content-xxl-center"
                                                                                    style="/*border-color: var(--maingreen);*/color: var(--bs-gray);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                <p class="bluetextthin">Pending</p>
                                                                            </div>
                                                                        </td>
                                                                        <td style="text-align: center;"><a
                                                                                class="btn btn-secondary invoicebtn"
                                                                                role="button"
                                                                                style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                                    class="fa fa-external-link"
                                                                                    style="font-size: 14px;color: var(--maingreen);"></i></a>
                                                                        </td>
                                                                    </tr> -->
                                                                            <!-- <tr class="tablerow" style="height: 0px;">
                                                                        <td class="text-center bluetextthin"
                                                                            style="padding-top: 9px;">Askuus1</td>
                                                                        <td class="text-center bluetextthin"
                                                                            style="padding-top: 9px;">1dgd@gmail.com
                                                                        </td>
                                                                        <td class="text-center bluetextthin"
                                                                            style="padding-top: 9px;">Buyer</td>
                                                                        <td class="text-center bluetextthin"
                                                                            style="padding-top: 9px;">Team Name</td>
                                                                        <td class="text-center"
                                                                            style="padding-top: 9px;">
                                                                            <div
                                                                                class="d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center">
                                                                                <div
                                                                                    class="form-check form-check-inline">
                                                                                    <input type="checkbox"
                                                                                        class="form-check-input"
                                                                                        id="formCheck-10" /><label
                                                                                        class="form-check-label radio bluetext thin"
                                                                                        for="formCheck-1">Email</label>
                                                                                </div>
                                                                                <div
                                                                                    class="form-check form-check-inline">
                                                                                    <input type="checkbox"
                                                                                        class="form-check-input"
                                                                                        id="formCheck-11" /><label
                                                                                        class="form-check-label radio bluetext thin"
                                                                                        for="formCheck-1">SMS</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td class="text-center bluetextthin"
                                                                            style="text-align: center;padding-top: 9px;">
                                                                            <div
                                                                                class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start">
                                                                                <i class="fa fa-times-circle d-xxl-flex justify-content-xxl-center"
                                                                                    style="/*border-color: var(--maingreen);*/color: var(--bs-red);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                <p class="text-nowrap bluetextthin">Not
                                                                                    Active</p>
                                                                            </div>
                                                                        </td>
                                                                        <td style="text-align: center;"><a
                                                                                class="btn btn-secondary invoicebtn"
                                                                                role="button"
                                                                                style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                                    class="fa fa-external-link"
                                                                                    style="font-size: 14px;color: var(--maingreen);"></i></a>
                                                                        </td>
                                                                    </tr> -->


                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>
                                                    <!-- <nav class="d-flex d-xxl-flex justify-content-end align-items-center justify-content-xxl-end align-items-xxl-center"
                                                    style="height: 0px;padding-top: 0px;margin-top: 2rem;">
                                                    <ul class="pagination pagination-sm">
                                                        <li class="page-item"><a class="page-link"
                                                                aria-label="Previous"><span
                                                                    aria-hidden="true">«</span></a>
                                                        </li>
                                                        <li class="page-item"><a class="page-link">1</a></li>
                                                        <li class="page-item"><a class="page-link">2</a></li>
                                                        <li class="page-item"><a class="page-link">3</a></li>
                                                        <li class="page-item"><a class="page-link">4</a></li>
                                                        <li class="page-item"><a class="page-link">5</a></li>
                                                        <li class="page-item"><a class="page-link"
                                                                aria-label="Next"><span aria-hidden="true">»</span></a>
                                                        </li>
                                                    </ul>
                                                </nav> -->

                                                    <div class="d-flex d-xxl-flex justify-content-end align-items-center justify-content-xxl-end align-items-xxl-center"
                                                        style="height: 0px;padding-top: 0px;margin-top: 2rem;">
                                                        <!-- pagination  -->
                                                        <pagination [items]="buyerCompanyDetais.buyerUsers"
                                                            (changePage)="onChangePage($event)"
                                                            [pageSize]="maxUserListSize"></pagination>

                                                    </div>
                                                </ng-container>

                                                <!-- users display for search -->
                                                <ng-container *ngIf="!stringValidation(searchUserCheck)">
                                                    <div class="row">
                                                        <div class="col-xxl-12">
                                                            <section>
                                                                <div class="table-responsive">
                                                                    <table class="table table-sm my-0 mydatatable">
                                                                        <thead class="buyerimage">
                                                                            <tr class="text-start">
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">NAME
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">EMAIL
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">ROLE
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">TEAM
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">
                                                                                    NOTIFICATION
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">STATUS
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">EDIT
                                                                                </th>
                                                                                <th class="blueheadsmall"></th>
                                                                                <th class="blueheadsmall"></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <ng-container
                                                                                *ngIf="buyerCompanyDetais.buyerUsers.length > 0">
                                                                                <ng-container
                                                                                    *ngIf="(buyerCompanyDetais.buyerUsers | filterPipe: removeSFWhiteSpace(searchUsers)) as result">
                                                                                    <ng-container
                                                                                        *ngFor="let user of result; let i = index">
                                                                                        <tr class="tablerow"
                                                                                            style="height: 0px;">
                                                                                            <td class="text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                {{user.firstName}}
                                                                                                {{user.lastName}} </td>
                                                                                            <td class="text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                {{user.userName}}
                                                                                            </td>
                                                                                            <td class="text-nowrap text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                {{user.role}}</td>
                                                                                            <td class="text-nowrap text-center bluetextthin"
                                                                                                style="padding-top: 9px;">
                                                                                                {{user.team}}</td>
                                                                                            <td class="text-center"
                                                                                                style="padding-top: 9px;">
                                                                                                <div
                                                                                                    class="d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center">
                                                                                                    <div class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start"
                                                                                                        style="padding-right: 2px;">
                                                                                                        <ng-container
                                                                                                            *ngIf="!user.emailPreference">
                                                                                                            <i class="fa fa-minus-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                style="/*border-color: var(--maingreen);*/color: var(--bs-gray);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                        </ng-container>
                                                                                                        <ng-container
                                                                                                            *ngIf="user.emailPreference">
                                                                                                            <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                        </ng-container>
                                                                                                        <p
                                                                                                            class="bluetextthin">
                                                                                                            Email</p>
                                                                                                    </div>
                                                                                                    <!-- <div
                                                                                                        class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start">
                                                                                                        <ng-container
                                                                                                            *ngIf="!user.smsPreference">
                                                                                                            <i class="fa fa-minus-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                style="/*border-color: var(--maingreen);*/color: var(--bs-gray);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                        </ng-container>
                                                                                                        <ng-container
                                                                                                            *ngIf="user.smsPreference">
                                                                                                            <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                                style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                        </ng-container>
                                                                                                        <p
                                                                                                            class="bluetextthin">
                                                                                                            SMS</p>
                                                                                                    </div> -->
                                                                                                </div>
                                                                                            </td>
                                                                                            <td class="text-center bluetextthin"
                                                                                                style="text-align: center;padding-top: 9px;">
                                                                                                <div *ngIf="user.emailConfirmed"
                                                                                                    class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start">
                                                                                                    <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                                                        style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                    <p
                                                                                                        class="bluetextthin">
                                                                                                        Active</p>
                                                                                                </div>
                                                                                                <div *ngIf="!user.emailConfirmed"
                                                                                                    class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start">
                                                                                                    <i class="fa fa-minus-circle d-xxl-flex justify-content-xxl-center"
                                                                                                        style="/*border-color: var(--maingreen);*/color: var(--bs-gray);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                                    <p
                                                                                                        class="bluetextthin">
                                                                                                        Pending</p>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td
                                                                                                style="text-align: center;">
                                                                                                <a data-bs-toggle="modal"
                                                                                                    (click)="userModalPosition(i)"
                                                                                                    [attr.data-bs-target]="'#editUser' + i "
                                                                                                    class="btn btn-secondary invoicebtn"
                                                                                                    role="button"
                                                                                                    style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                                                        class="fa fa-external-link"
                                                                                                        style="font-size: 14px;color: var(--maingreen);"></i></a>

                                                                                                <button
                                                                                                    style="display: none;"
                                                                                                    #CloseEditUserModal
                                                                                                    data-bs-toggle="modal"
                                                                                                    [attr.data-bs-target]="'#editUser' + userModalPos"></button>

                                                                                                <button
                                                                                                    style="display: none;"
                                                                                                    #DeleteUserModal
                                                                                                    data-bs-toggle="modal"
                                                                                                    [attr.data-bs-target]="'#deleteUser' + userModalPos"></button>
                                                                                            </td>
                                                                                        </tr>

                                                                                        <!-- EDIT USER -->

                                                                                        <div role="dialog" tabindex="-1"
                                                                                            class="modal fade"
                                                                                            data-bs-backdrop="static"
                                                                                            [attr.id]="'editUser' + i">
                                                                                            <div class="modal-dialog modal-dialog-centered"
                                                                                                role="document">
                                                                                                <div
                                                                                                    class="modal-content">

                                                                                                    <app-edit-user
                                                                                                    [editorUsername] = "account.account.userName"
                                                                                                        [profileType]="'Buyer'"
                                                                                                        [greenformheader]="greenformheader"
                                                                                                        [blueformheader]="blueformheader"
                                                                                                        (closeEditUserModalCond)="EditUserModalTrigger($event)"
                                                                                                        (openDeleteUserModal)="openDeleteUserModal($event)"
                                                                                                        [currentUser]="user">
                                                                                                    </app-edit-user>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <!-- DELETE USER MODAL -->
                                                                                        <div role="dialog" tabindex="-1"
                                                                                            class="modal fade "
                                                                                            data-bs-backdrop="static"
                                                                                            [attr.id]="'deleteUser' + i">
                                                                                            <div class="modal-dialog modal-dialog-centered"
                                                                                                role="document">
                                                                                                <div
                                                                                                    class="modal-content">
                                                                                                    <div class="modal-header"
                                                                                                        style="background: var(--maingreen);">
                                                                                                        <h1 class="d-xl-flex align-items-xl-center whitehead"
                                                                                                            style="font-size: 16px;margin-bottom: 0;">
                                                                                                            Delete User

                                                                                                        </h1>

                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="modal-body">
                                                                                                        <div>
                                                                                                            <div
                                                                                                                class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                                                                                                <div class="col"
                                                                                                                    style="padding-left: 0;">
                                                                                                                    <p class="d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-center justify-content-md-center justify-content-lg-center blueheadsmall"
                                                                                                                        style="margin-bottom: 0;padding-left: 32px;">

                                                                                                                        Confirm
                                                                                                                        that
                                                                                                                        User
                                                                                                                        {{user.firstName}}
                                                                                                                        {{user.lastName}}
                                                                                                                        Is
                                                                                                                        to
                                                                                                                        be
                                                                                                                        Removed?
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div
                                                                                                        class="modal-footer d-lg-flex align-items-lg-center">

                                                                                                        <a #cancelRfqDismissBtn
                                                                                                            class="btn text-gray-500"
                                                                                                            role="button"
                                                                                                            data-bs-dismiss="modal">Close</a>

                                                                                                        <button
                                                                                                            class="btn d-sm-flex d-lg-flex justify-content-sm-center align-items-sm-center justify-content-lg-center align-items-lg-center yellowbtn"
                                                                                                            role="button"
                                                                                                            [disabled]="deletingUser"
                                                                                                            (click)="deleteUser(user)"
                                                                                                            style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Confirm
                                                                                                            <span
                                                                                                                *ngIf="deletingUser"
                                                                                                                class="spinner-border spinner-border-sm mr-1"></span>
                                                                                                        </button>




                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </ng-container>

                                                                                    <div *ngIf="result.length == 0">
                                                                                        <div
                                                                                            class="d-flex flex-column justify-content-center align-items-center">
                                                                                            <h1 class="text-center text-center bluehead thin"
                                                                                                style="font-size: 16px;margin-top: 1rem; ">
                                                                                                No search results
                                                                                            </h1>

                                                                                        </div>
                                                                                    </div>
                                                                                </ng-container>
                                                                            </ng-container>
                                                                            <!-- <tr class="tablerow" style="height: 0px;">
                                                                    <td class="text-center bluetextthin"
                                                                        style="padding-top: 9px;">Askuus1</td>
                                                                    <td class="text-center bluetextthin"
                                                                        style="padding-top: 9px;">1dgd@gmail.com
                                                                    </td>
                                                                    <td class="text-center bluetextthin"
                                                                        style="padding-top: 9px;">Buyer</td>
                                                                    <td class="text-center bluetextthin"
                                                                        style="padding-top: 9px;">Team Name</td>
                                                                    <td class="text-center"
                                                                        style="padding-top: 9px;">
                                                                        <div
                                                                            class="d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center">
                                                                            <div
                                                                                class="form-check form-check-inline">
                                                                                <input type="checkbox"
                                                                                    class="form-check-input"
                                                                                    id="formCheck-8" /><label
                                                                                    class="form-check-label radio bluetext thin"
                                                                                    for="formCheck-1">Email</label>
                                                                            </div>
                                                                            <div
                                                                                class="form-check form-check-inline">
                                                                                <input type="checkbox"
                                                                                    class="form-check-input"
                                                                                    id="formCheck-9" /><label
                                                                                    class="form-check-label radio bluetext thin"
                                                                                    for="formCheck-1">SMS</label>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="text-center bluetextthin"
                                                                        style="text-align: center;padding-top: 9px;">
                                                                        <div
                                                                            class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start">
                                                                            <i class="fa fa-minus-circle d-xxl-flex justify-content-xxl-center"
                                                                                style="/*border-color: var(--maingreen);*/color: var(--bs-gray);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                            <p class="bluetextthin">Pending</p>
                                                                        </div>
                                                                    </td>
                                                                    <td style="text-align: center;"><a
                                                                            class="btn btn-secondary invoicebtn"
                                                                            role="button"
                                                                            style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                                class="fa fa-external-link"
                                                                                style="font-size: 14px;color: var(--maingreen);"></i></a>
                                                                    </td>
                                                                </tr> -->
                                                                            <!-- <tr class="tablerow" style="height: 0px;">
                                                                    <td class="text-center bluetextthin"
                                                                        style="padding-top: 9px;">Askuus1</td>
                                                                    <td class="text-center bluetextthin"
                                                                        style="padding-top: 9px;">1dgd@gmail.com
                                                                    </td>
                                                                    <td class="text-center bluetextthin"
                                                                        style="padding-top: 9px;">Buyer</td>
                                                                    <td class="text-center bluetextthin"
                                                                        style="padding-top: 9px;">Team Name</td>
                                                                    <td class="text-center"
                                                                        style="padding-top: 9px;">
                                                                        <div
                                                                            class="d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center">
                                                                            <div
                                                                                class="form-check form-check-inline">
                                                                                <input type="checkbox"
                                                                                    class="form-check-input"
                                                                                    id="formCheck-10" /><label
                                                                                    class="form-check-label radio bluetext thin"
                                                                                    for="formCheck-1">Email</label>
                                                                            </div>
                                                                            <div
                                                                                class="form-check form-check-inline">
                                                                                <input type="checkbox"
                                                                                    class="form-check-input"
                                                                                    id="formCheck-11" /><label
                                                                                    class="form-check-label radio bluetext thin"
                                                                                    for="formCheck-1">SMS</label>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="text-center bluetextthin"
                                                                        style="text-align: center;padding-top: 9px;">
                                                                        <div
                                                                            class="d-flex d-xxl-flex justify-content-start justify-content-xxl-start">
                                                                            <i class="fa fa-times-circle d-xxl-flex justify-content-xxl-center"
                                                                                style="/*border-color: var(--maingreen);*/color: var(--bs-red);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                            <p class="text-nowrap bluetextthin">Not
                                                                                Active</p>
                                                                        </div>
                                                                    </td>
                                                                    <td style="text-align: center;"><a
                                                                            class="btn btn-secondary invoicebtn"
                                                                            role="button"
                                                                            style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                                class="fa fa-external-link"
                                                                                style="font-size: 14px;color: var(--maingreen);"></i></a>
                                                                    </td>
                                                                </tr> -->


                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>
                                                    <!-- <nav class="d-flex d-xxl-flex justify-content-end align-items-center justify-content-xxl-end align-items-xxl-center"
                                                style="height: 0px;padding-top: 0px;margin-top: 2rem;">
                                                <ul class="pagination pagination-sm">
                                                    <li class="page-item"><a class="page-link"
                                                            aria-label="Previous"><span
                                                                aria-hidden="true">«</span></a>
                                                    </li>
                                                    <li class="page-item"><a class="page-link">1</a></li>
                                                    <li class="page-item"><a class="page-link">2</a></li>
                                                    <li class="page-item"><a class="page-link">3</a></li>
                                                    <li class="page-item"><a class="page-link">4</a></li>
                                                    <li class="page-item"><a class="page-link">5</a></li>
                                                    <li class="page-item"><a class="page-link"
                                                            aria-label="Next"><span aria-hidden="true">»</span></a>
                                                    </li>
                                                </ul>
                                            </nav> -->

                                                </ng-container>
                                            </div>
                                        </div>
                                        <div role="tabpanel" class="tab-pane {{requirementsTab}}" id="tab-2">
                                            <h1 class="text-center d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center"
                                                style="font-size: 20px;color: var(--white);background: var(--maingreen);padding-top: 0.5rem;padding-bottom: 0.5rem;">
                                                RFQ Requirements</h1>
                                            <div class="row">
                                                <div class="col d-flex d-lg-flex d-xxl-flex justify-content-center align-items-center justify-content-lg-center justify-content-xxl-center align-items-xxl-center"
                                                    style="padding: auto;padding-top: 1rem;padding-bottom: 1rem;padding-left: 1rem;">
                                                    <div class="row g-0 rfqdetailrow"
                                                        style="width: 315px;padding-top: 0;max-width: 315px;">

                                                        <button data-bs-toggle="modal" data-bs-target="#RFQdetails"
                                                            class="btn btn-secondary d-md-flex align-items-md-center invoicebtn"
                                                            role="button"
                                                            [disabled]="account.account.roles[0] != 'Admin' "
                                                            style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);padding-bottom: 3px;margin-left: 0; padding-bottom: 10px;"><i
                                                                class="fa fa-external-link"
                                                                style="font-size: 14px;color: var(--maingreen);"></i><span
                                                                class="bluetext thin"
                                                                style="padding-left: 4px; ">Edit</span></button>

                                                        <div class="col">

                                                            <!-- <div>
                                                            <p
                                                                class="text-nowrap d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                                <input type="checkbox"
                                                                    style="width: 30px;margin: 10px;background: var(--maingreen);" />Vendor
                                                                Requirements <a><i class="fa fa-question-circle"
                                                                        style="color: var(--maingreen);font-size: 12px;"></i></a>
                                                            </p>
                                                        </div>
                                                    </div> -->
                                                            <!-- <div
                                                        class="col d-flex d-md-flex d-xxl-flex justify-content-end align-items-center justify-content-md-end justify-content-xxl-end align-items-xxl-center">
                                                        <a data-bs-toggle="modal" data-bs-target="#RFQdetails"
                                                            class="btn btn-secondary d-md-flex align-items-md-center invoicebtn"
                                                            role="button"
                                                            style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);padding-bottom: 3px;margin-left: 0;"><i
                                                                class="fa fa-external-link"
                                                                style="font-size: 14px;color: var(--maingreen);"></i></a>
                                                    </div> -->
                                                            <!-- <div class="col shadow-sm rounded mb-2"
                                                        style="background: var(--shadowgrey);padding-top: 0.5rem;padding-bottom: 0.5rem;">
                                                        <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                            style="width: 223.391px;margin-left: 50px;">
                                                            <p class="d-sm-flex justify-content-sm-start bluetextthin shift"
                                                                style="margin-bottom: 0;">Vendor Name</p>
                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                style="margin-bottom: 0;">ASKUUUUS</p>
                                                        </div>
                                                        <div class="d-flex d-xxl-flex justify-content-between align-items-center align-items-xxl-center"
                                                            style="width: 223.391px;margin-left: 50px;">
                                                            <p class="d-sm-flex justify-content-sm-start bluetextthin"
                                                                style="margin-bottom: 0;">Vendor Number</p>
                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                style="margin-bottom: 0;">12123323<br /></p>
                                                        </div>
                                                        <div class="d-xxl-flex justify-content-between align-items-xxl-center"
                                                            style="width: 223.391px;"></div> -->
                                                        </div>
                                                        <div class="col">
                                                            <div>
                                                                <p
                                                                    class="text-nowrap d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                                    <ng-container
                                                                        *ngIf="!buyerCompanyDetais.beeCertificate">
                                                                        <i class="fa fa-minus-circle d-xxl-flex justify-content-xxl-center"
                                                                            style="/*border-color: var(--maingreen);*/color: var(--bs-gray);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="buyerCompanyDetais.beeCertificate">
                                                                        <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                    </ng-container>
                                                                    B-BBEE Requirements <a><i
                                                                            class="fa fa-question-circle"
                                                                            style="color: var(--maingreen);font-size: 12px;"></i></a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="col d-flex d-md-flex d-xxl-flex justify-content-end align-items-center justify-content-md-end justify-content-xxl-end align-items-xxl-center">

                                                        </div>
                                                        <form [formGroup]="beePopulateForm">
                                                            <div class="col d-lg-flex align-items-lg-center shadow-sm rounded mb-2"
                                                                style="background: var(--shadowgrey);padding-top: 0.5rem;padding-bottom: 0.5rem;">
                                                                <div class="d-flex d-lg-flex d-xxl-flex flex-row justify-content-center align-items-center align-items-lg-center align-items-xxl-center"
                                                                    style="width: 223.391px;margin-left: 50px;">
                                                                    <p class="d-sm-flex justify-content-sm-start bluetextthin shift"
                                                                        style="margin-bottom: 0;">Level</p>
                                                                    <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                        style="margin-bottom: 0;"></p>
                                                                    <div class="d-flex d-lg-flex" role="group"
                                                                        aria-label="Basic checkbox toggle button group">
                                                                        <div class="btn-group bluebtn" role="group"
                                                                            aria-label="Basic checkbox toggle button group"
                                                                            style="padding-top: 5px;"><input
                                                                                formControlName="beeLevel1P"
                                                                                type="checkbox" id="beeD1"
                                                                                (change)=" beeLevelSelectDisplay(buyerCompanyDetais.beeRequirements)"
                                                                                class="btn-check form-check-input" /><label
                                                                                class="form-label form-check-label btn btn-outline-primary"
                                                                                for="beeD1"
                                                                                style="border-radius: -113px;font-size: 14px;"
                                                                                name="flexRadioDefault1">1</label></div>
                                                                        <div class="btn-group bluebtn" role="group"
                                                                            aria-label="Basic checkbox toggle button group"
                                                                            style="padding-top: 5px;"><input
                                                                                formControlName="beeLevel2P"
                                                                                type="checkbox" id="beeD2"
                                                                                (change)=" beeLevelSelectDisplay(buyerCompanyDetais.beeRequirements)"
                                                                                class="btn-check form-check-input" /><label
                                                                                class="form-label form-check-label btn btn-outline-primary"
                                                                                for="beeD2"
                                                                                style="border-radius: -113px;font-size: 14px;"
                                                                                name="flexRadioDefault1">2</label></div>
                                                                        <div class="d-lg-flex align-items-lg-center btn-group bluebtn"
                                                                            role="group"
                                                                            aria-label="Basic checkbox toggle button group"
                                                                            style="padding-top: 5px;"><input
                                                                                formControlName="beeLevel3P"
                                                                                type="checkbox" id="beeD3"
                                                                                (change)=" beeLevelSelectDisplay(buyerCompanyDetais.beeRequirements)"
                                                                                class="btn-check form-check-input" /><label
                                                                                class="form-label form-check-label btn btn-outline-primary"
                                                                                for="beeD3"
                                                                                style="border-radius: -113px;font-size: 14px;"
                                                                                name="flexRadioDefault1">3</label></div>
                                                                        <div class="btn-group bluebtn" role="group"
                                                                            aria-label="Basic checkbox toggle button group"
                                                                            style="padding-top: 5px;"><input
                                                                                formControlName="beeLevel4P"
                                                                                type="checkbox" id="beeD4"
                                                                                (change)=" beeLevelSelectDisplay(buyerCompanyDetais.beeRequirements)"
                                                                                class="btn-check form-check-input" /><label
                                                                                class="form-label form-check-label btn btn-outline-primary"
                                                                                for="beeD4"
                                                                                style="border-radius: -113px;font-size: 14px;"
                                                                                name="flexRadioDefault1">4</label></div>
                                                                        <div class="btn-group bluebtn" role="group"
                                                                            aria-label="Basic checkbox toggle button group"
                                                                            style="padding-top: 5px;"><input
                                                                                formControlName="beeLevel5P"
                                                                                type="checkbox" id="beeD5"
                                                                                (change)=" beeLevelSelectDisplay(buyerCompanyDetais.beeRequirements)"
                                                                                class="btn-check form-check-input" /><label
                                                                                class="form-label form-check-label btn btn-outline-primary"
                                                                                for="beeD5"
                                                                                style="border-radius: -113px;font-size: 14px;"
                                                                                name="flexRadioDefault1">5</label></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div class="col d-flex d-lg-flex d-xxl-flex justify-content-center align-items-center justify-content-lg-center justify-content-xxl-center align-items-xxl-center"
                                                    style="padding-top: 1rem;padding-bottom: 1rem;">
                                                    <div class="row g-0 d-xxl-flex align-items-xxl-center"
                                                        style="max-width: 300px;">
                                                        <div class="col shadow-sm rounded"
                                                            style="background: var(--shadowgrey);padding: 0;padding-bottom: 0px;padding-top: 0px;">
                                                            <!-- <div>
                                                            <p
                                                                class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                                <input type="checkbox"
                                                                    style="width: 30px;margin: 10px;background: var(--maingreen);" />HACCP
                                                                Cert requrements <a><i class="fa fa-question-circle"
                                                                        style="color: var(--maingreen);font-size: 12px;"></i></a>
                                                            </p>
                                                        </div> -->
                                                            <!-- <div>
                                                            <p
                                                                class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                                <input type="checkbox"
                                                                    style="width: 30px;margin: 10px;background: var(--maingreen);" />SUV
                                                                Requirements <a><i class="fa fa-question-circle"
                                                                        style="color: var(--maingreen);font-size: 12px;"></i></a>
                                                            </p>
                                                        </div> -->
                                                            <div>
                                                                <p
                                                                    class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                                    <ng-container
                                                                        *ngIf="!buyerCompanyDetais.tuvRequirements">
                                                                        <i class="fa fa-minus-circle d-xxl-flex justify-content-xxl-center"
                                                                            style="/*border-color: var(--maingreen);*/color: var(--bs-gray);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="buyerCompanyDetais.tuvRequirements">
                                                                        <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                    </ng-container>

                                                                    TUV Cert Requirements <a><i
                                                                            class="fa fa-question-circle"
                                                                            style="color: var(--maingreen);font-size: 12px;"></i></a>
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p
                                                                    class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                                    <ng-container
                                                                        *ngIf="!buyerCompanyDetais.isoRequirements">
                                                                        <i class="fa fa-minus-circle d-xxl-flex justify-content-xxl-center"
                                                                            style="/*border-color: var(--maingreen);*/color: var(--bs-gray);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="buyerCompanyDetais.isoRequirements">
                                                                        <i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center"
                                                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                    </ng-container>
                                                                    ISO Requirement <a><i class="fa fa-question-circle"
                                                                            style="color: var(--maingreen);font-size: 12px;"></i></a>
                                                                </p>
                                                            </div>
                                                            <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                                style="width: 223.391px;margin-left: 50px;"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div role="tabpanel" class="tab-pane {{preferredSupplierTab}}" id="tab-3">
                                            <div>
                                                <h1 class="text-center d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center"
                                                    style="font-size: 20px;color: var(--white);background: var(--maingreen);padding-top: 0.5rem;padding-bottom: 0.5rem;">
                                                    Suppliers</h1>
                                            </div>
                                            <div style="padding: 1rem;padding-top: 0px;">
                                                <div
                                                    class="d-flex d-sm-flex d-md-flex d-xxl-flex align-items-center justify-content-sm-start align-items-sm-center justify-content-md-start justify-content-xxl-start align-items-xxl-center">
                                                    <div class="input-group tablesearch"><input type="text"
                                                            [(ngModel)]="searchPreferredSuppliers"
                                                            class="bg-light form-control border-0 small"
                                                            placeholder="Search for ..." /><button
                                                            class="btn btn-primary py-0" type="button"><i
                                                                class="fas fa-search"></i></button></div>
                                                    <div class="d-md-flex align-items-md-center" id="modal-open-1">
                                                    </div>
                                                    <button (click)="getInitialSupplierList()"
                                                        [disabled]="openSupplierModal || account.account.roles[0] != 'Admin' "
                                                        class="btn btn-secondary d-flex d-xxl-flex justify-content-around align-items-center justify-content-xxl-center align-items-xxl-center invoicebtn"
                                                        role="button"
                                                        style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                                            class="fa fa-plus-circle"
                                                            style="font-size: 14px;color: var(--maingreen);"></i><span
                                                            *ngIf="openSupplierModal"
                                                            class="spinner-border spinner-border-sm mr-1"></span>Add
                                                        Preferred Supplier</button>

                                                    <button #addSupplierOpenBtn style="display: none !important;"
                                                        data-bs-toggle="modal" data-bs-target="#AddSupplier"></button>
                                                </div>

                                                <!-- display with search -->
                                                <ng-container *ngIf="!stringValidation(searchPreferredSuppliers)">
                                                    <div class="row">
                                                        <div class="col-xxl-12">
                                                            <section>
                                                                <div class="table-responsive">
                                                                    <table class="table table-sm my-0 mydatatable">
                                                                        <thead class="buyerimage">
                                                                            <tr class="text-start">
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">SUPPLIER
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">SERVICES
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">TEAM
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">EMAIL
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">PHONE
                                                                                </th>
                                                                                <th class="blueheadsmall"></th>
                                                                                <th class="text-center blueheadsmall">
                                                                                    REMOVE
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <ng-container
                                                                                *ngIf="(buyerCompanyDetais.preferredSuppliers | filterPipe: removeSFWhiteSpace(searchPreferredSuppliers)) as result">
                                                                                <ng-container
                                                                                    *ngFor="let supplier of result">
                                                                                    <tr class="tablerow"
                                                                                        style="height: 0px;">
                                                                                        <td class="text-center bluetextthin"
                                                                                            style="padding-top: 9px;">
                                                                                            {{supplier.supplierName}}
                                                                                        </td>
                                                                                        <td class="text-center bluetextthin"
                                                                                            style="padding-top: 9px;">
                                                                                            <div
                                                                                                class="d-xxl-flex justify-content-xxl-center">
                                                                                                <div class="row row-cols-2 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-2"
                                                                                                    style="max-width: 230px;">
                                                                                                    <ng-container
                                                                                                        *ngFor="let service of supplier.supplierServices">
                                                                                                        <div
                                                                                                            class="col d-flex justify-content-center align-items-center">
                                                                                                            <p class="text-nowrap text-start"
                                                                                                                style="color: var(--white);font-size: 12px;background: var(--mainblue);border-radius: 73px;padding-left: 5px;padding-right: 5px;">
                                                                                                                {{service}}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </ng-container>

                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="text-nowrap text-center bluetextthin"
                                                                                            style="padding-top: 9px;">
                                                                                            {{supplier.team}}</td>
                                                                                        <td class="text-nowrap text-center bluetextthin"
                                                                                            style="padding-top: 9px;"><a
                                                                                                class="btn btn-secondary invoicebtn"
                                                                                                role="button"
                                                                                                style="font-size: 14px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);padding: 0;">
                                                                                                {{supplier.supplierEmail}} <i
                                                                                                    class="icon ion-android-mail"
                                                                                                    style="font-size: 14px;color: var(--maingreen);"></i></a>
                                                                                        </td>
                                                                                        <td class="text-nowrap text-center bluetextthin"
                                                                                            style="padding-top: 9px;">
                                                                                            {{supplier.supplierContactNumber}}
                                                                                        </td>
                                                                                        <td class="text-center bluetextthin"
                                                                                            style="text-align: center;padding-top: 0;">
                                                                                            <p class="bluetextthin"></p>
                                                                                        </td>
                                                                                        <td style="text-align: center;">
                                                                                            <button
                                                                                                (click)="removePreferedSupplier(supplier.supplierId, supplier.team)"
                                                                                                class="btn btn-secondary invoicebtn"
                                                                                                role="button"
                                                                                                [disabled]="(removingPreferredSupplier && selectedPreferredSupplierId == supplier.supplierId) || account.account.roles[0] != 'Admin' "
                                                                                                style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                                                    class="fa fa-trash"
                                                                                                    style="font-size: 14px;color: var(--bs-red);"></i>
                                                                                                <span
                                                                                                    *ngIf="removingPreferredSupplier && selectedPreferredSupplierId == supplier.supplierId "
                                                                                                    class="spinner-border spinner-border-sm mr-1"></span></button>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-container>

                                                                                <div *ngIf="result.length == 0">
                                                                                    <div
                                                                                        class="d-flex flex-column justify-content-center align-items-center">
                                                                                        <h1 class="text-center text-center bluehead thin"
                                                                                            style="font-size: 16px;margin-top: 1rem; ">
                                                                                            No search results
                                                                                        </h1>

                                                                                    </div>
                                                                                </div>

                                                                            </ng-container>
                                                                            

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>


                                                </ng-container>

                                                <!-- display without search -->
                                                <ng-container *ngIf="stringValidation(searchPreferredSuppliers)">
                                                    <div class="row">
                                                        <div class="col-xxl-12">
                                                            <section>
                                                                <div class="table-responsive">
                                                                    <table class="table table-sm my-0 mydatatable">
                                                                        <thead class="buyerimage">
                                                                            <tr class="text-start">
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">SUPPLIER
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">SERVICES
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">TEAM
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">EMAIL
                                                                                </th>
                                                                                <th class="blueheadsmall"
                                                                                    style="text-align: center;">PHONE
                                                                                </th>
                                                                                <th class="blueheadsmall"></th>
                                                                                <th class="text-center blueheadsmall">
                                                                                    REMOVE
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <ng-container>
                                                                                <ng-container
                                                                                    *ngFor="let supplier of pageOfItemsPS">
                                                                                    <tr class="tablerow"
                                                                                        style="height: 0px;">
                                                                                        <td class="text-center bluetextthin"
                                                                                            style="padding-top: 9px;">
                                                                                            {{supplier.supplierName}}
                                                                                        </td>
                                                                                        <td class="text-center bluetextthin"
                                                                                            style="padding-top: 9px;">
                                                                                            <div
                                                                                                class="d-xxl-flex justify-content-xxl-center">
                                                                                                <div class="row row-cols-2 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-2"
                                                                                                    style="max-width: 230px;">
                                                                                                    <ng-container
                                                                                                        *ngFor="let service of supplier.supplierServices">
                                                                                                        <div
                                                                                                            class="col d-flex justify-content-center align-items-center">
                                                                                                            <p class="text-nowrap text-start"
                                                                                                                style="color: var(--white);font-size: 12px;background: var(--mainblue);border-radius: 73px;padding-left: 5px;padding-right: 5px;">
                                                                                                                {{service}}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </ng-container>

                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="text-nowrap text-center bluetextthin"
                                                                                            style="padding-top: 9px;">
                                                                                            {{supplier.team}}</td>
                                                                                        <td class="text-nowrap text-center bluetextthin"
                                                                                            style="padding-top: 9px;"><a
                                                                                                class="btn btn-secondary invoicebtn"
                                                                                                role="button"
                                                                                                style="font-size: 14px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);padding: 0;">
                                                                                                {{supplier.supplierEmail}} <i
                                                                                                    class="icon ion-android-mail"
                                                                                                    style="font-size: 14px;color: var(--maingreen);"></i></a>
                                                                                        </td>
                                                                                        <td class="text-nowrap text-center bluetextthin"
                                                                                            style="padding-top: 9px;">
                                                                                            {{supplier.supplierContactNumber}}
                                                                                        </td>
                                                                                        <td class="text-center bluetextthin"
                                                                                            style="text-align: center;padding-top: 0;">
                                                                                            <p class="bluetextthin"></p>
                                                                                        </td>
                                                                                        <td style="text-align: center;">
                                                                                            <button
                                                                                                (click)="removePreferedSupplier(supplier.supplierId, supplier.team)"
                                                                                                class="btn btn-secondary invoicebtn"
                                                                                                role="button"
                                                                                                [disabled]="(removingPreferredSupplier && selectedPreferredSupplierId == supplier.supplierId) || account.account.roles[0] != 'Admin' "
                                                                                                style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);"><i
                                                                                                    class="fa fa-trash"
                                                                                                    style="font-size: 14px;color: var(--bs-red);"></i></button>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-container>
                                                                            </ng-container>
                                                                           
                                                                          

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>

                                                    <div class="d-flex d-xxl-flex justify-content-end align-items-center justify-content-xxl-end align-items-xxl-center"
                                                        style="height: 0px;padding-top: 0px;margin-top: 2rem;">
                                                        <pagination [items]="buyerCompanyDetais.preferredSuppliers"
                                                            (changePage)="onChangePagePS($event)"
                                                            [pageSize]="maxUserListSize"></pagination>
                                                    </div>

                                                </ng-container>

                                                <!-- <nav class="d-flex d-xxl-flex justify-content-end align-items-center justify-content-xxl-end align-items-xxl-center"
                                                    style="height: 0px;padding-top: 0px;margin-top: 2rem;">
                                                    <ul class="pagination pagination-sm">
                                                        <li class="page-item"><a class="page-link"
                                                                aria-label="Previous"><span
                                                                    aria-hidden="true">«</span></a>
                                                        </li>
                                                        <li class="page-item"><a class="page-link">1</a></li>
                                                        <li class="page-item"><a class="page-link">2</a></li>
                                                        <li class="page-item"><a class="page-link">3</a></li>
                                                        <li class="page-item"><a class="page-link">4</a></li>
                                                        <li class="page-item"><a class="page-link">5</a></li>
                                                        <li class="page-item"><a class="page-link"
                                                                aria-label="Next"><span aria-hidden="true">»</span></a>
                                                        </li>
                                                    </ul>
                                                </nav> -->



                                            </div>
                                        </div>

                                        <div id="tab-4" class="tab-pane {{creditApplicationTab}}" role="tabpanel">
                                            <div>
                                                <h1 class="text-center d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center" style="font-size: 20px;color: var(--white);background: var(--maingreen);padding-top: 0.5rem;padding-bottom: 0.5rem;">Credit Application Statuses</h1>
                                            </div>
                                            <div style="padding: 1rem;padding-top: 0px;">
                                                <div style="padding: 1rem;padding-top: 0px;">
                                                   
                                                    <div class="d-flex d-sm-flex d-md-flex d-xxl-flex align-items-center justify-content-sm-start align-items-sm-center justify-content-md-start justify-content-xxl-start align-items-xxl-center">
                                                        <div class="input-group tablesearch"><input  [(ngModel)]="searchCreditApplications" class="bg-light form-control border-0 small" type="text" placeholder="Search for ..." /><button class="btn btn-primary py-0" type="button"><i class="fas fa-search"></i></button></div>
                                                        <div id="modal-open-2" class="d-md-flex align-items-md-center"></div>
                                                        <button (click) = "getAvailableCreditSuppliers()"  class="btn btn-secondary d-flex d-xxl-flex justify-content-around align-items-center justify-content-xxl-center align-items-xxl-center invoicebtn" role="button" style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);">
                                                            <i class="fa fa-plus-circle" style="font-size: 14px;color: var(--maingreen);"></i> Add Credit Application</button>

                                                            <button #CreditAppMdl style="display: none;" data-bs-target="#CreditSucess" data-bs-toggle="modal" ></button>
                                                    </div>
                                                    <div *ngIf = "buyerCompanyDetais.creditApplications.length == 0" style="padding-top: 15px;">
                                                        <div>
                                                            <div class="d-flex justify-content-center align-items-center"><i class="fa fa-folder-open-o" style="font-size: 30px;color: var(--maingreen);"></i></div>
                                                        </div>
                                                        <p class="text-center d-flex d-xxl-flex flex-column justify-content-center align-items-center justify-content-xxl-center bluetextthin">You do not have any credit applications.<br />View RFQs to start submitting or add them manually by clicking "Add Credit Application".<br />
                                                            <button [routerLink] = "['/buyer-home/view-rfq','Open',0,'false']" class="btn btn-secondary invoicebtn" type="button" style="font-size: 12px;background: var(--maingreen);border-style: none;width: 127.344px;height: 36px;margin: 10px;">View RFQs</button></p>
                                                    </div>

                                                     <!-- WITH SEARCH-->
                                                     <ng-container *ngIf="!stringValidation(searchCreditApplications)">
                                                        <div  *ngIf = "buyerCompanyDetais.creditApplications.length > 0" class="row">
                                                            <div class="col-xxl-12">
                                                                <section>
                                                                    <div class="table-responsive">
                                                                        <table class="table table-sm my-0 mydatatable">
                                                                            <thead class="buyerimage">
                                                                                <tr class="text-start">
                                                                                    <th class="blueheadsmall" style="text-align: center;">COMPANY</th>
                                                                                    <th class="blueheadsmall" style="text-align: center;">PERIOD</th>
                                                                                    <th class="blueheadsmall" style="text-align: center;">APPLICATION</th>
                                                                                    <th class="blueheadsmall" style="text-align: center;">STATUS</th>
                                                                                    <th class="blueheadsmall"></th>
                                                                                    <th class="text-center blueheadsmall">UPLAODED</th>
                                                                                    <th class="text-center blueheadsmall">ACTIONS</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <ng-container
                                                                                *ngIf="(buyerCompanyDetais.creditApplications | filterPipe: removeSFWhiteSpace(searchCreditApplications)) as result">
                                                                                <ng-container
                                                                                    *ngFor="let creditApp of result">
                                                                                <tr class="tablerow" style="height: 0px;">
                                                                                    <td class="text-center bluetextthin" style="padding-top: 9px;">{{creditApp.supplierCompanyName}}</td>
                                                                                    <td class="text-nowrap text-center bluetextthin" style="padding-top: 9px;">{{creditApp.period}}</td>
                                                                                    <td class="text-nowrap text-center bluetextthin" style="padding-top: 9px;"><button [disabled] = "downloadingApplication" (click) = "downloadCreditApplication(creditApp.supplierId)" class="btn btn-secondary invoicebtn" role="button" style="font-size: 14px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);padding: 0;">{{creditApp.creditAppFileFileName}}
                                                                                         <i  class="fa fa-download" style="font-size: 14px;color: var(--maingreen);"></i> <span *ngIf="downloadingApplication && creditApp.supplierId == applicationSupplierId "
                                                                                        class="spinner-border spinner-border-sm mr-1"></span></button></td>
                                                                                    <td class="text-nowrap text-center bluetextthin" style="padding-top: 9px;">
                                                                                        <div *ngIf = "creditApp.status == 'Pending'" class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"><i class="fa fa-minus-circle d-xxl-flex justify-content-xxl-center" style="/*border-color: var(--maingreen);*/color: var(--bs-gray);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                            <p class="bluetextthin">Pending</p>
                                                                                        </div>
                                                                                        <div *ngIf = "creditApp.status == 'Accepted'"  class="d-flex d-xxl-flex justify-content-center justify-content-lg-center justify-content-xxl-center"><i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center" style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                            <p class="bluetextthin">Accepted</p>
                                                                                        </div>
                                                                                        <div *ngIf = "creditApp.status == 'Declined'"  class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"><i class="fa fa-times-circle d-xxl-flex justify-content-xxl-center" style="/*border-color: var(--maingreen);*/color: var(--bs-red);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                            <p class="text-nowrap bluetextthin">Declined</p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td class="text-center bluetextthin" style="text-align: center;padding-top: 0;">
                                                                                        <p class="bluetextthin"></p>
                                                                                    </td>
                                                                                    <td class="bluetextthin" style="text-align: center;padding-top: 9px;">{{creditApp.createdAt |
                                                                                        date:'mediumDate'}}<br /></td>
                                                                                    <td style="text-align: center;">
                                                                                        <a *ngIf = "creditApp.status == 'Declined'" (click) = "getReapplyCreditSupplier(creditApp.supplierId)" (click) = "setSelectedCreditApplication(creditApp)" class="bluetextthin"  style="text-decoration: underline;  cursor: pointer;">Re-apply</a>
                                                                                        <button #creditReApplicationMdl style = "display: none;" data-bs-target="#ReApplyCredit" data-bs-toggle="modal"></button>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-container>

                                                                            <div *ngIf="result.length == 0">
                                                                                <div
                                                                                    class="d-flex flex-column justify-content-center align-items-center">
                                                                                    <h1 class="text-center text-center bluehead thin"
                                                                                        style="font-size: 16px;margin-top: 1rem; ">
                                                                                        No search results
                                                                                    </h1>

                                                                                </div>
                                                                            </div>

                                                                            </ng-container>
    
                                                                                                                                                                         
                                                                            </tbody>
                                                                        </table>
                                                                    </div>                                       
                                                                </section>
                                                            </div>
                                                           
                                                        </div>
                                                    </ng-container>

                                                    <!-- WITH NO SEARCH -->
                                                    <ng-container *ngIf="stringValidation(searchCreditApplications)">
                                                    <div  *ngIf = "buyerCompanyDetais.creditApplications.length > 0" class="row">
                                                        <div class="col-xxl-12">
                                                            <section>
                                                                <div class="table-responsive">
                                                                    <table class="table table-sm my-0 mydatatable">
                                                                        <thead class="buyerimage">
                                                                            <tr class="text-start">
                                                                                <th class="blueheadsmall" style="text-align: center;">COMPANY</th>
                                                                                <th class="blueheadsmall" style="text-align: center;">PERIOD</th>
                                                                                <th class="blueheadsmall" style="text-align: center;">APPLICATION</th>
                                                                                <th class="blueheadsmall" style="text-align: center;">STATUS</th>
                                                                                <th class="blueheadsmall"></th>
                                                                                <th class="text-center blueheadsmall">UPLAODED</th>
                                                                                <th class="text-center blueheadsmall">ACTIONS</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <ng-container *ngFor = "let creditApp of pageOfItemsCA ">
                                                                            <tr class="tablerow" style="height: 0px;">
                                                                                <td class="text-center bluetextthin" style="padding-top: 9px;">{{creditApp.supplierCompanyName}}</td>
                                                                                <td class="text-nowrap text-center bluetextthin" style="padding-top: 9px;">{{creditApp.period}}</td>
                                                                                <td class="text-nowrap text-center bluetextthin" style="padding-top: 9px;"><button [disabled] = "downloadingApplication" (click) = "downloadCreditApplication(creditApp.supplierId)" class="btn btn-secondary invoicebtn" role="button" style="font-size: 14px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);padding: 0;">{{creditApp.creditAppFileFileName}}
                                                                                     <i  class="fa fa-download" style="font-size: 14px;color: var(--maingreen);"></i> <span *ngIf="downloadingApplication && creditApp.supplierId == applicationSupplierId "
                                                                                    class="spinner-border spinner-border-sm mr-1"></span></button></td>
                                                                                <td class="text-nowrap text-center bluetextthin" style="padding-top: 9px;">
                                                                                    <div *ngIf = "creditApp.status == 'Pending'" class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"><i class="fa fa-minus-circle d-xxl-flex justify-content-xxl-center" style="/*border-color: var(--maingreen);*/color: var(--bs-gray);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                        <p class="bluetextthin">Pending</p>
                                                                                    </div>
                                                                                    <div *ngIf = "creditApp.status == 'Accepted'"  class="d-flex d-xxl-flex justify-content-center justify-content-lg-center justify-content-xxl-center"><i class="fa fa-check-circle d-xxl-flex justify-content-xxl-center" style="border-color: var(--maingreen);color: var(--maingreen);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                        <p class="bluetextthin">Accepted</p>
                                                                                    </div>
                                                                                    <div *ngIf = "creditApp.status == 'Declined'"  class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"><i class="fa fa-times-circle d-xxl-flex justify-content-xxl-center" style="/*border-color: var(--maingreen);*/color: var(--bs-red);font-size: 20px;padding-left: 0;margin-right: 5px;"></i>
                                                                                        <p class="text-nowrap bluetextthin">Declined</p>
                                                                                    </div>
                                                                                </td>
                                                                                <td class="text-center bluetextthin" style="text-align: center;padding-top: 0;">
                                                                                    <p class="bluetextthin"></p>
                                                                                </td>
                                                                                <td class="bluetextthin" style="text-align: center;padding-top: 9px;">{{creditApp.createdAt |
                                                                                    date:'mediumDate'}}<br /></td>
                                                                                <td style="text-align: center;">
                                                                                    <a *ngIf = "creditApp.status == 'Declined'" (click) = "getReapplyCreditSupplier(creditApp.supplierId)" (click) = "setSelectedCreditApplication(creditApp)" class="bluetextthin"  style="text-decoration: underline;  cursor: pointer;">Re-apply</a>
                                                                                    <button #creditReApplicationMdl style = "display: none;" data-bs-target="#ReApplyCredit" data-bs-toggle="modal"></button>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-container>

                                                                                                                                                                     
                                                                        </tbody>
                                                                    </table>
                                                                </div>                                       
                                                            </section>
                                                        </div>
                                                        <div class="d-flex d-xxl-flex justify-content-end align-items-center justify-content-xxl-end align-items-xxl-center"
                                                    style="height: 0px;padding-top: 0px;margin-top: 2rem;">
                                                    <pagination [items]="buyerCompanyDetais.creditApplications"
                                                        (changePage)="onChangePageCA($event)"
                                                        [pageSize]="maxUserListSize"></pagination>
                                                     </div>
                                                    </div>
                                                </ng-container>
                                                    
                                                    <!-- <nav class="d-flex d-xxl-flex justify-content-end align-items-center justify-content-xxl-end align-items-xxl-center" style="height: 0px;padding-top: 0px;margin-top: 2rem;">
                                                        <ul class="pagination pagination-sm">
                                                            <li class="page-item"><a class="page-link" href="#" aria-label="Previous"><span aria-hidden="true">«</span></a></li>
                                                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                            <li class="page-item"><a class="page-link" href="#">4</a></li>
                                                            <li class="page-item"><a class="page-link" href="#">5</a></li>
                                                            <li class="page-item"><a class="page-link" href="#" aria-label="Next"><span aria-hidden="true">»</span></a></li>
                                                        </ul>
                                                    </nav> -->
                                                </div>
                                            </div>
                                        </div> 
                            
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-3">
                    <div class="row g-0 shadow-sm rounded mb-2" style="width: 315px;padding-top: 0;">
                        <div class="col d-flex d-xxl-flex justify-content-start align-items-center justify-content-xxl-start align-items-xxl-center"
                            style="background: var(--shadowgrey);padding: 1rem;padding-bottom: 0px;padding-top: 0px;">
                            <div></div>
                            <p class="d-xxl-flex align-items-xxl-center bluehead"><img src="assets/Contact.png"
                                    style="width: 30px;margin: 10px;" />Contact</p>
                        </div>
                        <div class="col d-flex d-xxl-flex justify-content-end align-items-center justify-content-xxl-end align-items-xxl-center"
                            style="background: var(--shadowgrey);padding: 1rem;padding-bottom: 0px;padding-top: 0px;">
                            <div></div><button data-bs-toggle="modal" data-bs-target="#contactLoc"
                                class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                role="button" [disabled]="account.account.roles[0] != 'Admin' "
                                style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                    class="fa fa-edit"
                                    style="font-size: 14px;color: var(--maingreen);"></i> Edit</button>
                        </div>
                    </div>
                    <div class="row g-0 rfqdetailrow" style="width: 315px;padding-top: 0;">
                        <div class="col shadow-sm rounded mb-2"
                            style="background: var(--shadowgrey);padding: 1rem;padding-bottom: 0px;padding-top: 0px;">
                            <p
                                class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                <i class="fas fa-building d-xxl-flex justify-content-xxl-center"
                                    style="width: 30px;margin: 10px;color: var(--yellow);"></i>Address
                            </p>
                            <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                style="width: 223.391px;margin-left: 50px;">
                                <p class="d-sm-flex justify-content-sm-start bluetextthin shift"
                                    style="margin-bottom: 10px;">
                                    {{buyerCompanyDetais.streetAddress}}<br />{{buyerCompanyDetais.city}}<br />{{buyerCompanyDetais.postalCode}}
                                    <br />{{buyerCompanyDetais.province}}<br />
                                </p>
                            </div>
                        </div>
                        <div class="col shadow-sm rounded mb-2"
                            style="background: var(--shadowgrey);padding: 1rem;padding-bottom: 0px;padding-top: 0px;">
                            <p
                                class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20"
                                    fill="none" class="d-xxl-flex justify-content-xxl-center"
                                    style="width: 30px;margin: 10px;color: var(--yellow);">
                                    <path
                                        d="M2.00333 5.88355L9.99995 9.88186L17.9967 5.8835C17.9363 4.83315 17.0655 4 16 4H4C2.93452 4 2.06363 4.83318 2.00333 5.88355Z"
                                        fill="currentColor"></path>
                                    <path
                                        d="M18 8.1179L9.99995 12.1179L2 8.11796V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V8.1179Z"
                                        fill="currentColor"></path>
                                </svg>Email Address
                            </p>
                            <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                style="width: 223.391px;margin-left: 50px;">
                                <p class="d-sm-flex justify-content-sm-start bluetextthin shift"
                                    style="margin-bottom: 10px;">{{buyerCompanyDetais.companyEmail}}</p>
                            </div>
                            <p
                                class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                <i class="fas fa-phone-alt d-flex d-lg-flex d-xxl-flex justify-content-center justify-content-lg-center justify-content-xxl-center"
                                    style="width: 30px;margin: 10px;color: var(--yellow);"></i>Phone Number
                            </p>
                            <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                style="width: 223.391px;margin-left: 50px;">
                                <p class="d-sm-flex justify-content-sm-start bluetextthin shift"
                                    style="margin-bottom: 10px;">{{buyerCompanyDetais.companyPhoneNumber}}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </main>


    <!-- BUYER PROFILE MODALS START HERE -->


    <!-- BUYER DETAILS -->


    <div role="dialog" tabindex="-1" class="modal fade" id="buyerdetailsEdit">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header" style="background: var(--maingreen);">
                    <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">Edit
                        Buyer Details</h1><button type="button" class="btn-close" data-bs-dismiss="modal" (click) = "resetComapnyLogoPreview()"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="row d-flex d-xxl-flex align-items-center ">
                            <div class="col" style="padding-left: 0;">
                                <p class="d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-center justify-content-md-center justify-content-lg-center justify-content-xl-start blueheadsmall"
                                    style="margin-bottom: 0;padding-left: 32px;">Edit the below fields and click the
                                    save
                                    button when done.</p>
                            </div>
                        </div>
                        <div class="row rfqdetailrow" style="padding-bottom: 5px;padding-top: 5px;">
                            <div class="col-xl-12 col-xxl-12 "
                                style="border-radius: 0px 0px 20px 20px;padding: 16px;padding-bottom: 16px;">
                                <div class="row" style="padding-top: 0.5rem; padding-left: 15px;">
                                    <div class="col-sm-4 d-flex flex-column justify-content-xxl-center">
                                        <p
                                            class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                            Company Logo</p>

                                    </div>
                                    <input #clearCompanyLogoInput type="file" style="display: none;"
                                        (change)="companyLogoSelected($event)" #CompanyLogoUpload
                                        accept="{{requiredFileType}}">
                                    <div class="col d-flex d-xxl-flex align-items-center align-items-xxl-center">

                                        <button *ngIf="buyerCompanyDetais.profilePictureFileName == null"
                                            class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn"
                                            role="button" (click)="CompanyLogoUpload.click()"
                                            [disabled]="companyLogoUploading "
                                            style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);">
                                            <i class="fas fa-upload"
                                                style="font-size: 14px;color: var(--maingreen);"></i> Upload
                                                <span  *ngIf="companyLogoUploading"
                                                class="spinner-border spinner-border-sm mr-1"></span></button>

                                        <button *ngIf="buyerCompanyDetais.profilePictureFileName != null"
                                            (click)="CompanyLogoUpload.click()" [disabled]="companyLogoUploading "
                                            class="btn btn-secondary invoicebtn" role="button"
                                            style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);padding-bottom: 3px;"><i
                                                class="fa fa-edit" style="font-size: 14px;color: var(--maingreen);"></i>
                                            Edit <span  *ngIf="companyLogoUploading"
                                            class="spinner-border spinner-border-sm mr-1"></span></button>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-center" style="padding-top: 1rem;">

                                    <ng-container>
                                        <img [src]="companyLogoPreview" style="width: 99px;" />
                                    </ng-container>
                                                             
                                </div>

                                <p *ngIf="buyerCompanyDetais.profilePictureFileName != null"
                                    class="text-nowrap d-xxl-flex justify-content-center"
                                    style="font-weight: normal;font-size: 12px; padding-top: 2px; "><i
                                        class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center"
                                        style="margin-right: 3px;"></i>{{companyLogoFileNamePreview}}
                                </p>

                                <!-- <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                    <div class="col" style="padding-left: 0;">
                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;padding-left: 30px;">Description</p>
                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;">
                                        </p>
                                        <div style="margin-top: 1rem;width: 100%;">
                                            <div style="height: auto;margin-left: 2rem;margin-top: 1rem;width: 25rem;">
                                                <textarea style="width: 417px;margin-left: 0px;"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <!-- <div class="col-xl-12 col-xxl-12 rfqsearchblock"
                                style="border-radius: 0px 0px 20px 20px;padding: 16px;padding-bottom: 16px;">
                                <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                    <div class="col" style="padding-left: 0;">
                                        <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;padding-left: 30px;">Company Logo</p>
                                        <p class="d-sm-flex justify-content-sm-start bluetext"
                                            style="margin-bottom: 0;">
                                        </p>
                                        <div style="margin-top: 1rem;width: 100%;">
                                            <div style="height: auto;margin-left: 2rem;margin-top: 1rem;width: 25rem;">
                                            </div>
                                        </div>
                                        <div style="margin-top: 1rem;width: 100%;">
                                            <div style="height: auto;margin-left: 2rem;margin-top: 1rem;width: 25rem;">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;">Upload Image</p>
                                                <div class="d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center dragdropblock"
                                                    style="height: 2rem;margin-top: 1rem;width: 100%;border-width: 2px;border-style: dashed;">
                                                    <p class="d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-start justify-content-xxl-center align-items-xxl-center bluetextthin"
                                                        style="margin-bottom: 0;padding: 1rem;">Drag &amp; Drop or <a
                                                            class="btn btn-link btn-sm d-xxl-flex align-items-xxl-start"
                                                            role="button" style="padding: 0px;">Browse</a></p>
                                                </div>
                                                <div class="d-flex justify-content-center" style="padding-top: 1rem;">
                                                    <img src="assets/Slice 12.jpg" style="width: 99px;" />
                                                </div>
                                                <div class="d-flex justify-content-center" style="padding-top: 1rem;">
                                                    <a class="btn d-lg-flex justify-content-center align-items-center yellowbtn"
                                                        role="button"
                                                        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Remove
                                                        Photo</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <!-- <div class="row d-flex d-flex justify-content-center align-items-center rfqdetailrow"
                            style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;">
                            <div class="col">
                                <p class="text-nowrap bluetextthin" style="font-weight: normal;">Want to edit more
                                    fields?
                                </p>
                            </div>
                            <div class="col d-flex d-xxl-flex justify-content-end justify-content-xxl-end">
                                <p class="bluetextthin" style="font-weight: normal;"><a class="text-nowrap bluetextthin"
                                        style="text-decoration:  underline;">Tradely Support</a></p>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="modal-footer d-lg-flex align-items-lg-center"><a class="btn text-gray-500" role="button" (click) = "resetComapnyLogoPreview()"
                        data-bs-dismiss="modal">Cancel</a>

                        <button #closeBuyerDetailsMdl style="display: none; " data-bs-dismiss="modal" ></button>
                        
                        <button (click) =  "uploadCompanyLogo()" [disabled] = "companyLogoUploading"
                        class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn" role="button"
                        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;"  >Save
                        <span *ngIf="companyLogoUploading"
                        class="spinner-border spinner-border-sm mr-1"
                        style="margin-left: 3px;"></span></button>
                    
                    </div>
            </div>
        </div>
    </div>




    <!-- CONTACT DETAILS -->

    <div role="dialog" tabindex="-1" data-bs-backdrop="static" class="modal fade" id="contactLoc">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header" style="background: var(--maingreen);">
                    <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">
                        Edit Contact and Location Details</h1><button (click)=" cancelContactDetailsEdit() "
                        type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                            <div class="col" style="padding-left: 0;">
                                <p class="d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-center justify-content-md-center justify-content-lg-center justify-content-xl-start blueheadsmall"
                                    style="margin-bottom: 0;padding-left: 32px;">Edit the below fields and click the
                                    save
                                    button when done.</p>
                            </div>
                        </div>
                        <form [formGroup]="contactForm">

                            <div class="row rfqdetailrow" style="padding-bottom: 5px;padding-top: 5px;">
                                <div class="col-xl-12 col-xxl-12 rfqsearchblock"
                                    style="border-radius: 0px 0px 20px 20px;padding: 16px;padding-bottom: 16px;">
                                    <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                        <div class="col" style="padding-left: 0;">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 30px;">Company Address</p>
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;">
                                            </p>
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 30px; padding-top: 15px;">Street
                                                Address

                                                <ng-container
                                                    *ngIf="(cf.streetAddress.touched && contactDetailsErrorDisplay &&  stringValidation(cf.streetAddress.value) )">
                                                    <span class="p-1 bluetext thin"
                                                        style="color:#a51d29;">(Required)</span>
                                                </ng-container>

                                                <ng-container
                                                    *ngIf="(cf.streetAddress.touched && contactDetailsErrorDisplay &&  cf.streetAddress.value.length > 100 )">
                                                    <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds Max
                                                        Length)</span>
                                                </ng-container>

                                            </p>
                                            <div
                                                style="height: 2rem;margin-left: 2rem;margin-top: 0.5rem;width: 18rem;">
                                                <input formControlName="streetAddress" placeholder="Street Address"
                                                    type="text" class="form-control" autocomplete="off"
                                                    style="width: 315px;" />
                                            </div>
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 30px; padding-top: 10px;">City

                                                <ng-container
                                                    *ngIf="(cf.city.touched && contactDetailsErrorDisplay &&  stringValidation(cf.city.value) )">
                                                    <span class="p-1 bluetext thin"
                                                        style="color:#a51d29;">(Required)</span>
                                                </ng-container>

                                                <ng-container
                                                    *ngIf="(cf.city.touched && contactDetailsErrorDisplay &&  cf.city.value.length > 50 )">
                                                    <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds Max
                                                        Length)</span>
                                                </ng-container>

                                            </p>
                                            <div
                                                style="height: 2rem;margin-left: 2rem;margin-top: 0.5rem;width: 18rem;">
                                                <input formControlName="city" placeholder="City" type="text"
                                                    class="form-control" autocomplete="off"
                                                    style="width: 315px;height: 2rem;" />
                                            </div>
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 30px; padding-top: 10px;">Postal
                                                Code
                                                <ng-container
                                                    *ngIf="(cf.postalCode.touched && contactDetailsErrorDisplay &&  stringValidation(cf.postalCode.value) )">
                                                    <span class="p-1 bluetext thin"
                                                        style="color:#a51d29;">(Required)</span>
                                                </ng-container>

                                                <ng-container
                                                    *ngIf="(cf.postalCode.touched && contactDetailsErrorDisplay &&  cf.postalCode.value.length > 10 )">
                                                    <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds Max
                                                        Length)</span>
                                                </ng-container>

                                                <ng-container
                                                    *ngIf="(cf.postalCode.touched  && !postalCodeValid(this.cf.postalCode.value))">
                                                    <span class="p-1 bluetext thin"
                                                        style="color:#a51d29;">(Invalid)</span>
                                                </ng-container>


                                            </p>
                                            <div
                                                style="height: 2rem;margin-left: 2rem;margin-top: 0.5rem;width: 18rem;">
                                                <input formControlName="postalCode" placeholder="Postal Code"
                                                    type="text" class="form-control" autocomplete="off"
                                                    style="width: 315px;height: 2rem;" />
                                            </div>
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 30px; padding-top: 10px;">Province

                                                <ng-container
                                                    *ngIf="(cf.province.touched && contactDetailsErrorDisplay &&  stringValidation(cf.province.value) )">
                                                    <span class="p-1 bluetext thin"
                                                        style="color:#a51d29;">(Required)</span>
                                                </ng-container>


                                                <ng-container
                                                    *ngIf="(cf.province.touched && contactDetailsErrorDisplay &&  cf.province.value.length > 50 )">
                                                    <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds Max
                                                        Length)</span>
                                                </ng-container>

                                            </p>
                                            <div
                                                style="height: 2rem;margin-left: 2rem;margin-top: 0.5rem;width: 18rem;">
                                                <select formControlName="province"
                                                    class="form-select d-flex align-items-center form-select"
                                                    style="width: 315px;height: 2rem;">
                                                    <option [disabled]="true" [ngValue]="null" selected="">
                                                        Province </option>
                                                    <option *ngFor="let selectedlocation of provinces"
                                                        [value]="selectedlocation">
                                                        {{selectedlocation}}</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-xxl-12 rfqsearchblock"
                                    style="border-radius: 0px 0px 20px 20px;padding: 16px;padding-bottom: 16px;">
                                    <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                        <div class="col" style="padding-left: 0;">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 30px;">Email Address

                                                <ng-container
                                                    *ngIf="(cf.companyEmail.touched && contactDetailsErrorDisplay &&  stringValidation(cf.companyEmail.value) )">
                                                    <span class="p-1 bluetext thin"
                                                        style="color:#a51d29;">(Required)</span>
                                                </ng-container>

                                                <ng-container
                                                    *ngIf="(cf.companyEmail.touched && contactDetailsErrorDisplay &&  cf.companyEmail.value.length > 50 )">
                                                    <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds Max
                                                        Length)</span>
                                                </ng-container>

                                                <ng-container
                                                    *ngIf="(cf.companyEmail.touched && cf.companyEmail.errors)">
                                                    <span class="p-1 bluetext thin"
                                                        style="color:#a51d29;">(Invalid)</span>
                                                </ng-container>

                                            </p>
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;">
                                            </p>
                                            <div
                                                style="height: 2rem;margin-left: 2rem;margin-top: 0.5rem;width: 18rem;">
                                                <input formControlName="companyEmail" placeholder="Company Email"
                                                    type="text" class="form-control" autocomplete="off"
                                                    style="width: 315px;height: 2rem;" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                        <div class="col" style="padding-left: 0;">
                                            <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;padding-left: 30px;">Phone Number

                                                <ng-container
                                                    *ngIf="(cf.companyPhoneNumber.touched && contactDetailsErrorDisplay &&  stringValidation(cf.companyPhoneNumber.value) )">
                                                    <span class="p-1 bluetext thin"
                                                        style="color:#a51d29;">(Required)</span>
                                                </ng-container>

                                                <ng-container
                                                    *ngIf="(cf.companyPhoneNumber.touched && contactDetailsErrorDisplay &&  cf.companyPhoneNumber.value.length > 15 )">
                                                    <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds Max
                                                        Length)</span>
                                                </ng-container>

                                            </p>
                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                style="margin-bottom: 0;">
                                            </p>
                                            <div
                                                style="height: 2rem;margin-left: 2rem;margin-top: 0.5rem;width: 18rem;">
                                                <input formControlName="companyPhoneNumber" placeholder="Company Number"
                                                    type="text" class="form-control" autocomplete="off"
                                                    style="width: 315px;height: 2rem;" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>

                        <!-- <div class="row d-flex d-xxl-flex justify-content-center align-items-center rfqdetailrow"
                            style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;">
                            <div class="col d-flex d-xl-flex justify-content-center justify-content-xl-center">
                                <p class="text-nowrap bluetextthin" style="font-weight: normal;">Company Details edited
                                </p>
                            </div>
                        </div> -->

                    </div>
                </div>
                <div class="modal-footer d-lg-flex align-items-lg-center"><a (click)=" cancelContactDetailsEdit() "
                        class="btn text-gray-500" role="button" data-bs-dismiss="modal">Cancel</a>

                    <a #editContactDetailsBtn data-bs-dismiss="modal"
                        class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn" role="button"
                        style="display: none !important;border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Save
                        Hidden </a>

                    <button *ngIf="contactDetailsValid() && !cf.companyEmail.errors" [disabled]="editingContactDetails"
                        (click)="contactDetailsEdit()"
                        class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn" role="button"
                        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Save
                        <span *ngIf="editingContactDetails"
                            class="spinner-border spinner-border-sm mr-1"></span></button>

                    <button *ngIf="!contactDetailsValid() || cf.companyEmail.errors" (click)="contactDetailsValidCond()"
                        class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn" role="button"
                        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Save
                        <span *ngIf="editingContactDetails"
                            class="spinner-border spinner-border-sm mr-1"></span></button>

                </div>
            </div>
        </div>
    </div>



    <!-- ADD USER -->

    <div role="dialog" tabindex="-1" class="modal fade" id="addnewuser" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header" style="background: var(--maingreen);">
                    <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">Add
                        User
                    </h1><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="row text-end">
                            <div class="col" style="padding-left: 0;">
                                <p class="text-center blueheadsmall">Complete the form Below and click 'Add User' when
                                    done.</p>
                            </div>
                        </div>
                        <form [formGroup]="addNewUserForm">
                            <div class="row rfqdetailrow">
                                <div class="col d-flex align-items-center">
                                    <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                        First Name

                                        <ng-container
                                            *ngIf="(addUserErrorDisplay &&  stringValidation(nu.firstName.value) )">
                                            <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                                        </ng-container>

                                        <ng-container *ngIf="(addUserErrorDisplay &&  nu.firstName.value.length > 50 )">
                                            <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds Max
                                                Length)</span>
                                        </ng-container>

                                    </p>
                                </div>
                                <div class="col"><input formControlName="firstName" type="text" class="form-control"
                                        autocomplete="off" placeholder="" style="height: 2rem;width: 315px;" /></div>
                            </div>
                            <div class="row rfqdetailrow">
                                <div class="col d-flex align-items-center">
                                    <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                        Last Name

                                        <ng-container
                                            *ngIf="( addUserErrorDisplay &&  stringValidation(nu.lastName.value) )">
                                            <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                                        </ng-container>

                                        <ng-container *ngIf="( addUserErrorDisplay &&  nu.lastName.value.length > 50 )">
                                            <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds Max
                                                Length)</span>
                                        </ng-container>

                                    </p>
                                </div>
                                <div class="col"><input formControlName="lastName" type="text" class="form-control"
                                        autocomplete="off" placeholder="" style="height: 2rem;width: 315px;" /></div>
                            </div>
                            <div class="row rfqdetailrow">
                                <div class="col d-flex align-items-center">
                                    <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                        Email
                                        Address

                                        <ng-container
                                            *ngIf="( addUserErrorDisplay &&  stringValidation(nu.userName.value) )">
                                            <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                                        </ng-container>

                                        <ng-container *ngIf="( addUserErrorDisplay &&  nu.userName.value.length > 50 )">
                                            <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds Max
                                                Length)</span>
                                        </ng-container>

                                        <ng-container *ngIf="( nu.userName.errors && nu.userName.touched )">
                                            <span class="p-1 bluetext thin" style="color:#a51d29;">(Invalid)</span>
                                        </ng-container>

                                    </p>
                                </div>
                                <div class="col">
                                    <input formControlName="userName" type="text" class="form-control"
                                        autocomplete="off" placeholder="" style="height: 2rem;width: 315px;" />
                                </div>
                            </div>
                            <div class="row rfqdetailrow">
                                <div class="col d-flex align-items-center">
                                    <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                        Role

                                        <!-- <ng-container
                                            *ngIf="(nu.role.touched && addUserErrorDisplay &&  stringValidation(nu.role.value) )">
                                            <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                                        </ng-container> -->


                                    </p>
                                </div>
                                <div class="col">

                                    <input formControlName="role" type="text" class="form-control" autocomplete="off"
                                        placeholder="Buyer" style="height: 2rem;width: 315px;" />

                                    <!-- <p class="text-nowrap d-sm-flex justify-content-sm-start bluetextthin"
                                    style="margin-bottom: 0; padding-left: 0px;">Buyer</p> -->

                                    <!-- <select class="form-select" style="width: 315px;height: 2rem;"
                                        formControlName="role">

                                        <option  value=null selected>Buyer</option>
                                        
                                        <option value="Buyer">Buyer</option>
                                        <option value="Supplier">Supplier</option>
                                        <option value="Merchant">Merchant</option> 

                                    </select> -->

                                </div>
                            </div>
                            <div class="row rfqdetailrow">
                                <div class="col d-flex align-items-center">
                                    <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                        Team

                                        <ng-container
                                            *ngIf="( addUserErrorDisplay &&  stringValidation(nu.team.value) )">
                                            <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                                        </ng-container>

                                        <ng-container *ngIf="( addUserErrorDisplay &&  nu.team.value.length > 50 )">
                                            <span class="p-1 bluetext thin" style="color:#a51d29;">(Exceeds Max
                                                Length)</span>
                                        </ng-container>


                                    </p>
                                </div>
                                <div class="col"><input formControlName="team" type="text" class="form-control"
                                        autocomplete="off" placeholder="" style="height: 2rem;width: 315px;" /></div>
                            </div>

                            <div class="row rfqdetailrow">
                                <div class="col d-flex align-items-center">
                                    <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                        Notification Type(s)</p>
                                </div>
                                <div class="col">
                                    <div
                                        class="d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center">
                                        <div class="form-check form-check-inline">
                                            <input type="checkbox" formControlName="emailPreference"
                                                class="form-check-input" id="formCheck-50" /><label
                                                class="form-check-label radio bluetext thin"
                                                for="formCheck-1">Email</label>
                                        </div>
                                        <!-- <div class="form-check form-check-inline">
                                            <input type="checkbox" formControlName="sMSPreference"
                                                class="form-check-input" id="formCheck-12" /><label
                                                class="form-check-label radio bluetext thin"
                                                for="formCheck-1">SMS</label>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="row d-flex d-xxl-flex justify-content-center align-items-center rfqdetailrow"
                            style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;">
                            <div class="col d-flex d-xl-flex justify-content-center justify-content-xl-center">
                                <p class="text-nowrap bluetextthin" style="font-weight: normal;">All fields are required
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-lg-flex align-items-lg-center"><a class="btn text-gray-500" role="button"
                        data-bs-dismiss="modal">Cancel</a>

                    <button *ngIf="userDetailsValid() && !nu.userName.errors" (click)="addNewUser()"
                        [disabled]="addingNewUser"
                        class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn" role="button"
                        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Add User
                        <span *ngIf="addingNewUser" class="spinner-border spinner-border-sm mr-1"></span></button>

                    <button *ngIf="!userDetailsValid() || nu.userName.errors" (click)="userDetailsValidCond()"
                        class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn" role="button"
                        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Add User
                    </button>

                    <button data-bs-dismiss="modal" #CloseAddUserModal
                        class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn" role="button"
                        style="display: none !important ;border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Add
                        User</button>


                </div>
            </div>
        </div>
    </div>


    <!-- EDIT USER TEMP-->

    <!-- <div role="dialog" tabindex="-1" class="modal fade" id="editUser1">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header" style="background: var(--maingreen);">
                    <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">Edit
                        User</h1><button type="button" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="row text-end">
                            <div class="col" style="padding-left: 0;">
                                <p class="text-center blueheadsmall">Edit the fields below and click the save' button
                                    when
                                    done.</p>
                            </div>
                        </div>
                        <div class="row rfqdetailrow">
                            <div class="col d-flex align-items-center">
                                <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">First
                                    Name
                                </p>
                            </div>
                            <div class="col"><input type="text" class="form-control" autocomplete="off" placeholder="s"
                                    style="height: 2rem;width: 315px;" /></div>
                        </div>
                        <div class="row rfqdetailrow">
                            <div class="col d-flex align-items-center">
                                <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">Last
                                    Name
                                </p>
                            </div>
                            <div class="col"><input type="text" class="form-control" autocomplete="off"
                                    placeholder="fsfssf" style="height: 2rem;width: 315px;" /></div>
                        </div>
                        <div class="row rfqdetailrow">
                            <div class="col d-flex align-items-center">
                                <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">Role
                                </p>
                            </div>
                            <div class="col"><select class="form-select" style="width: 315px;height: 2rem;">
                                    <optgroup label="This is a group">
                                        <option value="12" selected>This is item 1</option>
                                        <option value="13">This is item 2</option>
                                        <option value="14">This is item 3</option>
                                    </optgroup>
                                </select></div>
                        </div>
                        <div class="row rfqdetailrow">
                            <div class="col d-flex align-items-center">
                                <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">Email
                                    Adress</p>
                            </div>
                            <div class="col"><input type="text" class="form-control" autocomplete="off"
                                    placeholder="sfafsaf@gdgdgd" style="height: 2rem;width: 315px;" />
                                <div class="d-lg-flex d-xxl-flex justify-content-between align-items-lg-center align-items-xxl-center"
                                    style="padding-top: 8px;"><a
                                        class="btn btn-secondary d-flex d-xxl-flex align-items-center invoicebtn"
                                        role="button"
                                        style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"><i
                                            class="material-icons"
                                            style="font-size: 14px;color: var(--maingreen);">mail</i> Send
                                        Invitation</a>
                                    <div class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                        style="background: var(--shadowgrey);padding: 7px;border-radius: 40px;"><i
                                            class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 0;"></i>
                                        <p
                                            style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;">
                                             Active</p>
                                    </div>
                                    <div class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                        style="background: var(--shadowgrey);padding: 7px;border-radius: 40px;"><i
                                            class="fa fa-minus-circle d-xxl-flex align-items-xxl-center"
                                            style="border-color: var(--maingreen);color: var(--textgrey);font-size: 15px;padding-left: 0;"></i>
                                        <p
                                            style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;">
                                             Pending</p>
                                    </div><a><i class="fa fa-question-circle"
                                            style="color: var(--maingreen);font-size: 12px;"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex d-xxl-flex justify-content-center align-items-center rfqdetailrow"
                            style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;">
                            <div class="col d-flex d-xl-flex justify-content-center justify-content-xl-center">
                                <p class="text-nowrap bluetextthin" style="font-weight: normal;">No Fields Edited</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-lg-flex align-items-lg-center"><a class="btn text-gray-500" role="button"
                        data-bs-dismiss="modal">Cancel</a><a
                        class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn" role="button"
                        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Save</a></div>
            </div>
        </div>
    </div> -->


    <!-- RFQ DETAILS -->

    <div role="dialog" tabindex="-1" class="modal fade" id="RFQdetails" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header" style="background: var(--maingreen);height: 56px;"><img
                        src="assets/Group 28.png" style="width: 42px;margin-right: 10px;" />
                    <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">
                        General
                        RFQ Requirements</h1><button (click)="cancelGeneralRfqDetails()" type="button" class="btn-close"
                        data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <form [formGroup]="generalRfqRequirementsForm">
                            <div class="col">
                                <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                    <div class="col">
                                        <div class="row g-0 d-xxl-flex align-items-xxl-center" style="width: 464px;">
                                            <div class="col">
                                                <!-- <div>
                                            <p
                                                class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                <input type="checkbox"
                                                    style="width: 30px;margin: 10px;background: var(--maingreen);" />HACCP
                                                Cert requrements <a><i class="fa fa-question-circle"
                                                        style="color: var(--maingreen);font-size: 12px;"></i></a>
                                            </p>
                                        </div> -->
                                                <!-- <div>
                                            <p
                                                class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                <input type="checkbox"
                                                    style="width: 30px;margin: 10px;background: var(--maingreen);" />SUV
                                                Requirements <a><i class="fa fa-question-circle"
                                                        style="color: var(--maingreen);font-size: 12px;"></i></a>
                                            </p>
                                        </div> -->
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                        <input type="checkbox" formControlName="tuvRequirements"
                                                            style="width: 30px;margin: 10px;background: var(--maingreen);" />TUV
                                                        Cert Requirements <a><i class="fa fa-question-circle"
                                                                style="color: var(--maingreen);font-size: 12px;"></i></a>
                                                    </p>
                                                </div>
                                                <div>
                                                    <p
                                                        class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext">
                                                        <input type="checkbox" formControlName="isoRequirements"
                                                            style="width: 30px;margin: 10px;background: var(--maingreen);" />ISO
                                                        Requirement <a><i class="fa fa-question-circle"
                                                                style="color: var(--maingreen);font-size: 12px;"></i></a>
                                                    </p>
                                                </div>
                                                <div class="d-flex d-lg-flex d-xxl-flex justify-content-between align-items-center align-items-lg-center align-items-xxl-center"
                                                    style="width: 223.391px;margin-left: 50px;"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                    <div class="col">
                                        <!-- <div class="row g-0 d-xxl-flex align-items-xxl-center" style="width: 464px;">
                                    <div class="col shadow-sm rounded" style="background: var(--shadowgrey);">
                                        <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                            <div class="col" style="padding-left: 0;">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;padding-left: 30px;">Vendor Name</p>
                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;"></p>
                                                <div
                                                    style="height: 2rem;margin-left: 2rem;margin-top: 1rem;width: 18rem;">
                                                    <input type="text" class="form-control" autocomplete="off"
                                                        placeholder="gddsd@gmail.com"
                                                        style="width: 315px;height: 2rem;" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                            <div class="col" style="padding-left: 0;">
                                                <p class="d-flex d-sm-flex align-items-center justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;padding-left: 30px;">Vendor Number</p>
                                                <p class="d-sm-flex justify-content-sm-start bluetext"
                                                    style="margin-bottom: 0;"></p>
                                                <div
                                                    style="height: 2rem;margin-left: 2rem;margin-top: 1rem;width: 18rem;">
                                                    <input type="text" class="form-control" autocomplete="off"
                                                        placeholder="081 222 4442" style="width: 315px;height: 2rem;" />
                                                </div>
                                            </div>
                                            <div class="col" style="padding-top: 10px;"><a
                                                    class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn"
                                                    role="button"
                                                    style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;margin-left: 19px;">Add</a>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                        <p class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext"
                                            style="padding-top: 1rem;"><input type="checkbox"
                                                formControlName="beeRequired"
                                                style="width: 30px;margin: 10px;background: var(--maingreen);" />B-BBEE
                                            Requirement <a><i class="fa fa-question-circle"
                                                    style="color: var(--maingreen);font-size: 12px;"></i></a></p>
                                        <div class="row g-0 d-xxl-flex align-items-xxl-center" style="width: 464px;">
                                            <div class="col shadow-sm rounded" style="background: var(--shadowgrey);">
                                                <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                                    <div class="col d-xxl-flex justify-content-xxl-center">
                                                        <div
                                                            class="d-flex d-lg-flex d-xxl-flex flex-row justify-content-center align-items-center align-items-lg-center align-items-xxl-center">
                                                            <p class="d-sm-flex justify-content-sm-start bluetextthin shift"
                                                                style="margin-bottom: 0;">Level</p>
                                                            <p class="d-sm-flex justify-content-sm-start bluetext"
                                                                style="margin-bottom: 0;"></p>
                                                            <div class="d-flex d-lg-flex" role="group"
                                                                aria-label="Basic checkbox toggle button group">
                                                                <div class="btn-group bluebtn" role="group"
                                                                    aria-label="Basic checkbox toggle button group"
                                                                    style="padding-top: 5px;"><input
                                                                        formControlName="beeLevel1" type="checkbox"
                                                                        id="lvl12" (change)=" beeLevelSelect(1)"
                                                                        class="btn-check form-check-input" /><label
                                                                        class="form-label form-check-label btn btn-outline-primary"
                                                                        for="lvl12"
                                                                        style="border-radius: -113px;font-size: 14px;"
                                                                        name="flexRadioDefault">1</label></div>
                                                                <div class="btn-group bluebtn" role="group"
                                                                    aria-label="Basic checkbox toggle button group"
                                                                    style="padding-top: 5px;"><input
                                                                        formControlName="beeLevel2" type="checkbox"
                                                                        id="lvl22" (change)=" beeLevelSelect(2)"
                                                                        class="btn-check form-check-input" /><label
                                                                        class="form-label form-check-label btn btn-outline-primary"
                                                                        for="lvl22"
                                                                        style="border-radius: -113px;font-size: 14px;"
                                                                        name="flexRadioDefault">2</label></div>
                                                                <div class="d-lg-flex align-items-lg-center btn-group bluebtn"
                                                                    role="group"
                                                                    aria-label="Basic checkbox toggle button group"
                                                                    style="padding-top: 5px;"><input
                                                                        formControlName="beeLevel3" type="checkbox"
                                                                        id="lvl32" (change)=" beeLevelSelect(3)"
                                                                        class="btn-check form-check-input" /><label
                                                                        class="form-label form-check-label btn btn-outline-primary"
                                                                        for="lvl32"
                                                                        style="border-radius: -113px;font-size: 14px;"
                                                                        name="flexRadioDefault">3</label></div>
                                                                <div class="btn-group bluebtn" role="group"
                                                                    aria-label="Basic checkbox toggle button group"
                                                                    style="padding-top: 5px;"><input
                                                                        formControlName="beeLevel4" type="checkbox"
                                                                        id="lvl42" (change)=" beeLevelSelect(4)"
                                                                        class="btn-check form-check-input" /><label
                                                                        class="form-label form-check-label btn btn-outline-primary"
                                                                        for="lvl42"
                                                                        style="border-radius: -113px;font-size: 14px;"
                                                                        name="flexRadioDefault">4</label></div>
                                                                <div class="btn-group bluebtn" role="group"
                                                                    aria-label="Basic checkbox toggle button group"
                                                                    style="padding-top: 5px;"><input
                                                                        formControlName="beeLevel5" type="checkbox"
                                                                        id="lvl52" (change)=" beeLevelSelect(5)"
                                                                        class="btn-check form-check-input" /><label
                                                                        class="form-label form-check-label btn btn-outline-primary"
                                                                        for="lvl52"
                                                                        style="border-radius: -113px;font-size: 14px;"
                                                                        name="flexRadioDefault">5</label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                                    <!-- <div class="col" style="padding-top: 10px;"><a
                                                    class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn"
                                                    role="button"
                                                    style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;margin-left: 19px;">Add</a>
                                            </div> -->
                                                    <div class="col" style="padding-top: 10px;">
                                                        <p class="d-flex d-sm-flex justify-content-center justify-content-sm-center justify-content-lg-center bluetextthin shift"
                                                            style="margin-bottom: 0;">Level {{selectedBeeLevel}}
                                                            Selected </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="modal-footer d-lg-flex align-items-lg-center"><a (click)="cancelGeneralRfqDetails()"
                        class="btn text-gray-500" role="button" data-bs-dismiss="modal">Cancel</a>

                    <button (click)=" editGeneralRfqRequirements()" [disabled]="editGeneralDetails"
                        class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn" role="button"
                        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">
                        <span *ngIf="editGeneralDetails"
                            class="spinner-border spinner-border-sm mr-1"></span>Save</button>

                    <a data-bs-dismiss="modal" #GeneralRfqRequirementBtn
                        class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn" role="button"
                        style="display: none !important;border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Save</a>


                </div>
            </div>
        </div>
    </div>


    <!-- ADD SUPPLIER -->

    <div role="dialog" tabindex="-1" class="modal fade" id="AddSupplier" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header" style="background: var(--maingreen);">
                    <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">Add
                        Preferred Supplier</h1><button type="button" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="row text-end">
                            <div class="col">
                                <p class="text-start blueheadsmall">Search for a preferred supplier and click the save
                                    button when done.</p>
                            </div>
                        </div>


                        <div class="row rfqdetailrow">
                            <div class="col-sm-4 d-flex align-items-center">
                                <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">Select
                                    Supplier
                                    <span *ngIf="gettingSupplierDetails"
                                        class="spinner-border spinner-border-sm mr-1"></span>
                                </p>
                            </div>
                            <div class="col">
                                <div class="dropdown">
                                    <button class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"
                                        type="button" style="width: 100%;">Preferred Supplier</button>
                                    <div class="dropdown-menu" style="padding-top: 0px;width: 306px;">
                                        <input [(ngModel)]="searchSuppliers" type="search" class="form-control"
                                            style="padding-top: 8px;" placeholder="Search Suppliers" />

                                        <ng-container
                                            *ngIf="(initialSuppliers | filterPipe: removeSFWhiteSpace(searchSuppliers)) as result">
                                            <ng-container *ngFor="let supplier of result">
                                                <a class="dropdown-item"
                                                    (click)="getPreferredSupplierSelect(supplier.supplierId)">{{supplier.name}}</a>
                                            </ng-container>
                                            <ng-container *ngIf="result.length == 0">
                                                <p style="padding-top: 2px; padding-left: 2px; color: black;"
                                                    class="bluetext thin">No Search Results</p>
                                            </ng-container>
                                        </ng-container>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <form [formGroup]="addPreferedSupplierForm">

                            <div class="row rfqdetailrow">
                                <div class="col d-flex align-items-center">
                                    <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                        Supplier Name
                                    </p>
                                </div>
                                <div class="col">
                                    <input formControlName="supplierName" type="text" class="form-control"
                                        autocomplete="off" placeholder="" style="height: 2rem;width: 315px;" />
                                </div>
                            </div>

                            <div class="row rfqdetailrow">
                                <div class="col-sm-4 d-flex align-items-center">
                                    <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                        Supplier
                                        Services</p>
                                </div>
                                <div class="col">
                                    <div
                                        class="d-flex d-sm-flex d-xxl-flex flex-row align-items-sm-center justify-content-xxl-center">
                                        <div class="row row-cols-2 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-2"
                                            style="max-width: 230px;">
                                            <div *ngFor="let service of selectedSupplier.supplierServices"
                                                class="col d-flex justify-content-center align-items-center">
                                                <p class="text-nowrap text-start"
                                                    style="color: var(--white);font-size: 12px;background: var(--mainblue);border-radius: 73px;padding-left: 5px;padding-right: 5px;">
                                                    {{service}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row rfqdetailrow">
                                <div class="col d-flex align-items-center">
                                    <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                        Email
                                        Address</p>
                                </div>
                                <div class="col"><input formControlName="supplierEmail" type="text" class="form-control"
                                        autocomplete="off" placeholder="" style="height: 2rem;width: 315px;" />
                                    <!-- <div class="d-lg-flex d-xxl-flex justify-content-start align-items-lg-center align-items-xxl-center"
                                    style="padding-top: 8px;">
                                    <div class="d-flex d-xxl-flex justify-content-center justify-content-xxl-center"
                                        style="background: var(--shadowgrey);padding: 7px;border-radius: 40px;margin-right: 5px;">
                                        <i class="fa fa-check-circle d-xxl-flex align-items-xxl-center"
                                            style="border-color: var(--maingreen);color: var(--maingreen);font-size: 15px;padding-left: 0;"></i>
                                        <p
                                            style="font-size: 12px;color: var(--darkblue);margin-bottom: 0px;font-weight: 600;">
                                             Active</p>
                                    </div>
                                    
                                    <a><i class="fa fa-question-circle"
                                            style="color: var(--maingreen);font-size: 12px;"></i></a>
                                </div> -->
                                </div>
                            </div>
                            <div class="row rfqdetailrow">
                                <div class="col d-flex align-items-center">
                                    <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                        Phone
                                        Number</p>
                                </div>
                                <div class="col">
                                    <input formControlName="supplierContactNumber" type="text" class="form-control"
                                        autocomplete="off" placeholder="" style="height: 2rem;width: 315px;" />
                                </div>
                            </div>



                            <div class="row rfqdetailrow">
                                <div class="col d-flex align-items-center">
                                    <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">
                                        Team

                                        <ng-container
                                            *ngIf="( preferredSupplierCond &&  stringValidation(ps.team.value) )">
                                            <span class="p-1 bluetext thin" style="color:#a51d29;">(Required)</span>
                                        </ng-container>

                                    </p>
                                </div>
                                <div class="col"><select formControlName="team" class="form-select"
                                        style="width: 315px;height: 2rem;">
                                        <option value="" disabled selected></option>
                                        <ng-container *ngFor="let team of selectedSupplier.teams">
                                            <option [value]="team">{{team}}</option>
                                        </ng-container>

                                    </select></div>
                            </div>

                        </form>

                        <!-- <div class="row d-flex d-xxl-flex justify-content-center align-items-center rfqdetailrow"
                            style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;margin-top: 16px;">
                            <div class="col d-flex d-xl-flex justify-content-center justify-content-xl-center">
                                <p class="text-nowrap bluetextthin" style="font-weight: normal;">No Fields Edited</p>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="modal-footer d-lg-flex align-items-lg-center"><a class="btn text-gray-500" role="button"
                        data-bs-dismiss="modal">Cancel</a>

                    <button *ngIf="ps.team.value != ''" (click)=" addPreferredSupplier()"
                        [disabled]="addingPreferredSupplier"
                        class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn" role="button"
                        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Add
                        <span *ngIf="addingPreferredSupplier" class="spinner-border spinner-border-sm mr-1"></span>
                    </button>

                    <a *ngIf="ps.team.value == ''" (click)="addPreferredSupplierValid()"
                        class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn" role="button"
                        style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Add</a>

                    <button #addPreferredSupplierBtn style="display: none;" data-bs-dismiss="modal"></button>

                </div>
            </div>
        </div>
    </div>

     <!-- RE APPLY FOR CREDIT MODAL -->
     <div id="ReApplyCredit" class="modal fade" role="dialog" tabindex="-1"  data-bs-backdrop="static" >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header" style="background: var(--maingreen);">
                    <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">Apply For Credit</h1>
                    <button (click) = " clearCreditApplicationForm()"  class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <!-- <div class="row text-end">
                            <div class="col">
                                <p class="text-center blueheadsmall">Search For Supplier</p>
                            </div>
                        </div> -->
                        <!-- <div class="row rfqdetailrow">
                            <div class="col-sm-4 d-flex align-items-center">
                                <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">Supplier Name</p>
                            </div>
                            <div class="col">
                                <div class="dropdown">
                                    <button class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"
                                        type="button" style="width: 100%;">Select Supplier</button>
                                    <div class="dropdown-menu" style="padding-top: 0px;width: 306px;">
                                        <input [(ngModel)]="searchCreditSuppliers" type="search" class="form-control"
                                            style="padding-top: 8px;" placeholder="Search Suppliers" />

                                        <ng-container
                                            *ngIf="(availableCreditSuppliers | filterPipe: removeSFWhiteSpace(searchCreditSuppliers)) as result">
                                            <ng-container *ngFor="let supplier of result">
                                                <a class="dropdown-item"
                                                    (click)="selectCreditSupplier(supplier)">{{supplier.name}}</a>
                                            </ng-container>
                                            <ng-container *ngIf="result.length == 0">
                                                <p style="padding-top: 2px; padding-left: 2px; color: black;"
                                                    class="bluetext thin">No Search Results</p>
                                            </ng-container>
                                        </ng-container>

                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <ng-container *ngIf = "selectedCreditSupplier != null">
                        <div class="row rfqdetailrow">
                            <div class="col-sm-4 d-flex align-items-center">
                                <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">{{selectedCreditSupplier.name}}</p>
                            </div>
                            <div class="col"></div>
                        </div>
                        <div style="margin-bottom: 0.5rem;">
                            <div class="row g-0">
                                <div class="col d-flex d-xxl-flex align-items-center align-items-xxl-center" style="background: var(--shadowgrey);padding: 0.5rem;padding-bottom: 0px;">
                                    <h1 class="text-nowrap text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel" style="font-size: 18px;margin-bottom: 0;padding: 0;"></h1>
                                    <p class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext"><i class="far fa-credit-card d-xxl-flex justify-content-xxl-center" style="width: 30px;margin: 10px;color: var(--lightblue);"></i><strong>Application Form</strong><br /><br /></p>
                                </div>
                                <div class="col d-flex d-xxl-flex justify-content-end align-items-center justify-content-xxl-end align-items-xxl-center" style="background: var(--shadowgrey);">
                                    <p class="text-nowrap d-xxl-flex" style="font-weight: normal;margin-right: 2rem;font-size: 12px;"><i class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center" style="margin-right: 3px;"></i>{{selectedCreditSupplier.creditAppTemplateFileName}}</p>
                                    <button [disabled] = "downloadingTemplate" (click) = "downloadCreditApplicationTemplate()" class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn" role="button" style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);">
                                         <i  class="fa fa-download" style="font-size: 14px;color: var(--maingreen);"></i>&nbsp;Download
                                        <span *ngIf="downloadingTemplate"
                                        class="spinner-border spinner-border-sm mr-1"></span></button>
                                     
                                </div>
                            </div>
                        </div>
                    </ng-container>
                       
                    </div>
                </div>
                <div class="modal-footer d-lg-flex align-items-lg-center">
                <a (click) = " clearCreditApplicationForm()" class="btn text-gray-500" role="button" data-bs-dismiss="modal">Cancel</a>
           
                <button [disabled] = "selectedCreditSupplier == null"  data-bs-target="#creditAppUploadBuyer" data-bs-toggle="modal" data-bs-dismiss="modal" class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn" role="button" style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Next</button></div>
            </div>
        </div>
    </div>

    <!-- APPLY FOR CREDIT MODAL -->
    <div id="CreditSucess" class="modal fade" role="dialog" tabindex="-1"  data-bs-backdrop="static" >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header" style="background: var(--maingreen);">
                    <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">Apply For Credit</h1>
                    <button (click) = " clearCreditApplicationForm()"  class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="row text-end">
                            <div class="col">
                                <p class="text-center blueheadsmall">Search For Supplier</p>
                            </div>
                        </div>
                        <div class="row rfqdetailrow">
                            <div class="col-sm-4 d-flex align-items-center">
                                <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">Supplier Name</p>
                            </div>
                            <div class="col">
                                <div class="dropdown">
                                    <button class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"
                                        type="button" style="width: 100%;">Select Supplier</button>
                                    <div class="dropdown-menu" style="padding-top: 0px;width: 306px;">
                                        <input [(ngModel)]="searchCreditSuppliers" type="search" class="form-control"
                                            style="padding-top: 8px;" placeholder="Search Suppliers" />

                                        <ng-container
                                            *ngIf="(availableCreditSuppliers | filterPipe: removeSFWhiteSpace(searchCreditSuppliers)) as result">
                                            <ng-container *ngFor="let supplier of result">
                                                <a class="dropdown-item"
                                                    (click)="selectCreditSupplier(supplier)">{{supplier.name}}</a>
                                            </ng-container>
                                            <ng-container *ngIf="result.length == 0">
                                                <p style="padding-top: 2px; padding-left: 2px; color: black;"
                                                    class="bluetext thin">No Search Results</p>
                                            </ng-container>
                                        </ng-container>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf = "selectedCreditSupplier != null">
                        <div class="row rfqdetailrow">
                            <div class="col-sm-4 d-flex align-items-center">
                                <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">{{selectedCreditSupplier.name}}</p>
                            </div>
                            <div class="col"></div>
                        </div>
                        <div style="margin-bottom: 0.5rem;">
                            <div class="row g-0">
                                <div class="col d-flex d-xxl-flex align-items-center align-items-xxl-center" style="background: var(--shadowgrey);padding: 0.5rem;padding-bottom: 0px;">
                                    <h1 class="text-nowrap text-start d-sm-flex d-md-flex d-lg-flex d-xxl-flex justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xxl-start uiblocklabel" style="font-size: 18px;margin-bottom: 0;padding: 0;"></h1>
                                    <p class="d-flex d-sm-flex justify-content-start align-items-center justify-content-sm-start align-items-lg-center align-items-xxl-center bluetext"><i class="far fa-credit-card d-xxl-flex justify-content-xxl-center" style="width: 30px;margin: 10px;color: var(--lightblue);"></i><strong>Application Form</strong><br /><br /></p>
                                </div>
                                <div class="col d-flex d-xxl-flex justify-content-end align-items-center justify-content-xxl-end align-items-xxl-center" style="background: var(--shadowgrey);">
                                    <p class="text-nowrap d-xxl-flex" style="font-weight: normal;margin-right: 2rem;font-size: 12px;"><i class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center" style="margin-right: 3px;"></i>{{selectedCreditSupplier.creditAppTemplateFileName}}</p>
                                    <button [disabled] = "downloadingTemplate" (click) = "downloadCreditApplicationTemplate()" class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn" role="button" style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);">
                                         <i  class="fa fa-download" style="font-size: 14px;color: var(--maingreen);"></i>&nbsp;Download
                                        <span *ngIf="downloadingTemplate"
                                        class="spinner-border spinner-border-sm mr-1"></span></button>
                                     
                                </div>
                            </div>
                        </div>
                    </ng-container>
                       
                    </div>
                </div>
                <div class="modal-footer d-lg-flex align-items-lg-center">
                <a (click) = " clearCreditApplicationForm()" class="btn text-gray-500" role="button" data-bs-dismiss="modal">Cancel</a>
           
                <button [disabled] = "selectedCreditSupplier == null"  data-bs-target="#creditAppUploadBuyer" data-bs-toggle="modal" data-bs-dismiss="modal" class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn" role="button" style="border-style: none;border-radius: 20px;width: 150.0312px;height: 42px;">Next</button></div>
            </div>
        </div>
    </div>

    <!-- CREDIT APPLICATION 2 -->
    <div id="creditAppUploadBuyer" class="modal fade" role="dialog" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header" style="background: var(--maingreen);height: 56px;">
                    <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">Apply For Credit</h1><button (click) = " clearCreditApplicationForm()" class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="col">
                            <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                <div class="col">
                                    <div class="row text-end" style="padding-bottom: 10px;">
                                        <div *ngIf = "selectedCreditSupplier != null" class="col">
                                            <p class="text-center blueheadsmall">Upload your credit application for {{selectedCreditSupplier.name}}</p>
                                        </div>
                                    </div>
                                    <div style="height: auto;margin-left: 2rem;margin-top: 1rem;width: 25rem;">
                                        <p class="d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-start justify-content-xxl-center bluetext" style="margin-bottom: 0;">Credit Application File</p>
                                        <input #clearCreditAppInput type="file" style="display: none;"
                                        (change)="uploadCreditApplicationFile($event)" #CreditAppUpload
                                        accept="{{requiredCRFileType}}">
                                        <div class="d-flex d-xxl-flex justify-content-center align-items-center justify-content-xxl-center align-items-xxl-center">
                                            <p class="d-flex d-sm-flex justify-content-center align-items-center justify-content-sm-start justify-content-xxl-center align-items-xxl-center bluetextthin" style="margin-bottom: 0;padding: 5px;">
                                                <button  (click)="CreditAppUpload.click()" class="btn btn-link btn-sm d-xxl-flex align-items-xxl-start" role="button" style="padding: 0px;">Browse</button></p>
                                        </div>
                    
                                        <div class="d-flex d-xl-flex justify-content-center justify-content-xl-center" style="padding-top: 1rem;"><img src="assets/BrowseFile2.png" style="width: 99px;" /></div>
                                        <div *ngIf = "CreditApplicationFileNamePreview != ''" class="d-xl-flex justify-content-xl-center" style="padding-top: 1rem;">
                                            <div class="row g-0">
                                                <div class="col d-flex d-xxl-flex justify-content-end align-items-center justify-content-xxl-end align-items-xxl-center" style="background: var(--shadowgrey);">
                                                    <p class="text-nowrap d-xxl-flex" style="font-weight: normal;margin-right: 2rem;font-size: 12px;"><i class="fa fa-file-pdf-o d-xxl-flex align-items-xxl-center" style="margin-right: 3px;"></i>{{CreditApplicationFileNamePreview}}</p><a class="btn btn-secondary d-flex d-xxl-flex align-items-center align-items-xxl-center invoicebtn" role="button" style="font-size: 12px;background: rgba(255,255,255,0);border-width: 0px;border-color: var(--shadowgrey);color: var(--darkblue);"></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="col">
                            <div class="row d-flex d-xxl-flex align-items-center rfqdetailrow">
                                <div class="col">
                                    <div class="row text-end" style="padding-bottom: 10px;">
                                        <div class="col">
                                            <p class="text-start blueheadsmall">Select a credit day range</p>
                                        </div>
                                    </div>
                                    <!-- <div class="row text-end" style="padding-bottom: 10px;">
                                        <div class="col">
                                            <p class="text-start bluetextthin"> Please select the range you would like to approve.</p>
                                        </div>
                                    </div> -->
                                    <form [formGroup]="creditApplication">
                                    <div class="row rfqdetailrow" style="padding-bottom: 15px;">
                                        <div class="col d-flex align-items-center">
                                            <p class="d-flex d-sm-flex justify-content-sm-start align-items-lg-end bluetext">Day Range</p>
                                        </div>
                                        <div class="col">
                                            <select formControlName = "dayRange" class="form-select" style="width: 315px;height: 2.25rem;">                                               
                                                    <option value= "" disabled selected>Day Range</option>
                                                    <option value="30 Days">30 Days</option>
                                                    <option value="60 Days">60 Days</option>
                                        </select></div>
                                    </div>
                                </form>
                                </div>
                            </div>
                            <!-- <div class="row text-nowrap d-flex d-xxl-flex justify-content-center align-items-center rfqdetailrow" style="padding-left: 22px;background: var(--shadowgrey);border-radius: 10px;">
                                <div class="col d-flex d-xl-flex justify-content-center justify-content-xl-center">
                                    <p class="text-nowrap bluetextthin" style="font-weight: normal;">You Have Accepted Buyer1&#39;s credit application for 30 day Period</p>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-lg-flex align-items-lg-center"><a (click) = "clearCreditApplicationForm()" class="btn text-gray-500" role="button" data-bs-dismiss="modal">Cancel</a>
            <button [disabled] = "ca.dayRange.value == '' || creditApplicationUpload.file == null || creatingCreditApplication" (click) = "createCreditApplication(creditState)"  class="btn d-lg-flex justify-content-lg-center align-items-lg-center yellowbtn" role="button" style="border-style: none;border-radius: 20px;width: 170.0312px;height: 42px;">
                Confirm Application <span *ngIf="creatingCreditApplication"
                class="spinner-border spinner-border-sm mr-1"></span></button></div>
            
            <button #CreditAppSuccessMdl style="display: none;" data-bs-target="#ApplyCredit" data-bs-toggle="modal" data-bs-dismiss="modal" ></button>
        
        </div>
        </div>
    </div>
    
    <!-- APPLY CREDIT SUCCESS -->
    <div id="ApplyCredit" class="modal fade " role="dialog" tabindex="-1"  data-bs-backdrop="static" >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header" style="background: var(--maingreen);">
                    <h1 class="d-xl-flex align-items-xl-center whitehead" style="font-size: 16px;margin-bottom: 0;">Apply For Credit</h1>
                    <button (click) = "closeCreditAppSuccessModal()" class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="row text-end">
                            <div class="col">
                                <p class="text-start blueheadsmall">Upload Sucesfull<span><i class="fa fa-check-circle" style="color: var(--maingreen);margin-left: 10px;font-size: 18px;"></i></span></p>
                            </div>
                        </div>
                        <div class="row rfqdetailrow">
                            <div class="col">
                                <p *ngIf = "creditState == 'Apply'" class="bluetextthin">Your credit application has been successfully uploaded. You can view the status of your application in the Credit Applications tab in your profile</p>
                                <p *ngIf = "creditState == 'Reapply'" class="bluetextthin">You Have successfully Re-applied For Credit. You can view the status of your application in the Credit Applications tab in your profile</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-lg-flex align-items-lg-center">
                    <a (click) = "closeCreditAppSuccessModal()" class="btn text-gray-500" role="button" data-bs-dismiss="modal">Close</a></div>
            </div>
        </div>
    </div>
    
</ng-container>



<ng-container #loading *ngIf="dataLoading">



    <div class="container-fluid d-flex justify-content-center align-items-center"
        style="padding-bottom:10rem;min-height: 100vh; background-color: var(--mainbackground); ">
        <div class="text-center text-center bluehead"><span class="spinner-border spinner-border-sm mr-1"></span>
            Loading...
        </div>
    </div>

</ng-container>